<script setup lang="ts">
// external
import { computed } from "vue"
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"

// internal
import { TeamList, TeamIconDisplay } from "~/components"
import { Team } from "~/types"

interface Props {
  options: Team[]
  modelValue: Team["uuid"][]|null
  disabled?: boolean
  error?: boolean
  label?: string
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  error: false,
  label: null,
  required: false,
})

const emit = defineEmits([ "update:model-value" ])


const selectedOption = computed({
  get: () => null,
  set: (option) => {
    const payload = props.modelValue ? [
      ...props.modelValue,
      option.uuid,
    ] : [ option.uuid ]
    emit("update:model-value", payload)
  },
})

const selectedOptions = computed(() => props.modelValue?.length ? props.options.filter((option) => props.modelValue.includes(option.uuid)) : [])
const availableOptions = computed(() => props.modelValue?.length ? props.options.filter((option) => !props.modelValue.includes(option.uuid)) : props.options)

const hasError = computed(() => props.error && !selectedOptions.value.length)

const removeOption = (option: Team) => {
  emit("update:model-value", props.modelValue.filter((uuid) => uuid !== option.uuid))
}

</script>

<template>
  <div>
    <div
      v-if="label"
      class="mb-1 text-sm font-medium"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-indigo-500"
      >*</span>
    </div>
    <Listbox
      v-model="selectedOption"
      :disabled="disabled || !availableOptions.length"
      as="div"
    >
      <div class="relative mt-1">
        <ListboxButton
          data-cy-sel="team-selection-button"
          class="btn-listbox-plain"
          :disabled="disabled || !availableOptions.length"
          :class="{'error': hasError}"
        >
          <span class="block text-gray-500 truncate">{{ $t('documentGeneralSettings.selectTeams') }}…</span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              class="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="listbox-options">
            <ListboxOption
              v-for="(team, teamUuidx) in availableOptions"
              :key="team.uuid"
              v-slot="{ active, selected }"
              as="template"
              :value="team"
              :data-cy-sel="'team-selection-option-' + teamUuidx"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex items-center']">
                <TeamIconDisplay
                  :team="team"
                  class="w-4 h-4 mr-2"
                />
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ team.name }}</span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <TeamList
      class="mt-2"
      :teams="selectedOptions"
      :removable="true"
      @remove="removeOption"
    />
  </div>
</template>
