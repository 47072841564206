<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { Ref, computed, inject } from "vue"

import { NodeViewProps, NodeViewWrapper, Range } from "@tiptap/vue-3"

// internal
import { useDocumentStore, usePartyStore, usePusherStore, useSharedStore } from "~/stores"
import { formatAddress } from "~/utils"
import { CrudContext } from "~/types"

const props = defineProps<NodeViewProps>()

const party = computed(() => props.node.attrs)

const { parties } = storeToRefs(usePartyStore())

const documentStore = useDocumentStore()
const { isLockedDocument, mdu, mau } = storeToRefs(documentStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const partyValue = computed(() => parties.value.find((item) => item.ref_uuid === party.value.refUuid))

const formattedAddress = computed(() => formatAddress(partyValue.value?.address || ""))

const textSelectionRange = inject<Ref<Range>>("textSelectionRange", null)

const isSelected = computed(
  () => {
    if (!textSelectionRange.value) return false

    const { from, to } = textSelectionRange.value

    const nodeFrom = props.getPos()
    const nodeTo = nodeFrom + props.node.nodeSize

    return from <= nodeFrom && to >= nodeTo
  },
)
</script>

<template>
  <NodeViewWrapper
    v-cy="`party-node-${party.format}-${partyValue?.name?.replace(/\s+/g, `-`)}`.toLowerCase()"
    :class="[
      !partyValue
        ? 'bg-red-50 border-red-300'
        : 'bg-slate-50 border-slate-300',
      props.selected || isSelected
        ? 'ring ring-slate-200'
        : '',
    ]"
    class="relative items-center inline px-1 -mt-1 leading-tight border rounded-md box-decoration-clone"
  >
    <div
      :data-party-button="props.node.attrs.refUuid"
      data-placement="right"
      :data-template="'partyForm_' + partyValue?.ref_uuid"
      :class="[
        !checkIfDisabledByPusher('editPartyButton_' + partyValue?.ref_uuid) && (mdu?.permissions?.includes('party_update') && (!!mau || mdu?.party_uuid === partyValue?.uuid) || crudContext === CrudContext.template) && !isLockedDocument && partyValue
          ? 'edit-party-button cursor-pointer'
          : 'pointer-events-none',
        'inline break-normal'
      ]"
    >
      <span v-if="partyValue?.entity_name && party.format === 'name'">
        {{ partyValue?.entity_name }}
      </span>

      <span
        v-else-if="partyValue?.address && party.format === 'address'"
        class="whitespace-pre-line"
      >
        {{ partyValue?.address }}
      </span>

      <span v-else-if="partyValue?.address && party.format === 'address:inline'">
        {{ formattedAddress }}
      </span>

      <span v-else-if="partyValue?.name && party.format === 'reference'">
        {{ partyValue?.name }}
      </span>

      <span
        v-else-if="partyValue"
        class="text-slate-500"
      >
        [ {{ partyValue.name }} ⸱ {{ $t('partyFormats.'+party.format) }} ]
      </span>

      <span
        v-else
        class="text-red-700"
      >
        {{ $t('common.n/a(notFound)') }}
      </span>
    </div>
  </NodeViewWrapper>
</template>
