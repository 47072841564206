<script setup lang="ts">
// external
import { ref, onMounted, computed } from "vue"
import { Form, Field } from "vee-validate"
import axios from "axios"
import { router } from "@inertiajs/vue3"
import { pick } from "lodash-es"
import { storeToRefs } from "pinia"

// internal
import { CrudContext, Document, DocumentUser, DocumentUserRoleEnum, Party, PartyEntityType } from "~/types"
import { changedKeys, entityTypeOptions } from "~/utils"
import { SignatoryPopover, UserList, FormInputErrors, SignatorySwitch, RadioGroupPills } from "~/components"
import { useTippies } from "~/composables"
import { useDocumentStore, useUserStore } from "~/stores"

interface Props {
  document: Document
  party: Party
  mdu: DocumentUser
  isSubmitting: boolean
  documentUsers: DocumentUser[]
}

const props = withDefaults(defineProps<Props>(), {})

const documentStore = useDocumentStore()
const { setMdu } = documentStore

const userStore = useUserStore()
const { users, uuidsOfUpdatingUser } = storeToRefs(userStore)
const { updateLocalUser } = userStore

const relevantUsers = computed(() => {
  return users.value?.filter((user) => user.party_uuid === props.party.uuid)
})

const submitButton = ref()
const form = ref()
const addUserPartyUuid = ref<Party["uuid"]>(null)

const partyData = ref({
  entity_name: props.party.entity_name,
  address: props.party.address,
  entity_type: props.party.entity_type,
})

const initialData = ref({
  ...partyData.value,
})

interface addNewUserParams {
  scope: "external" | "internal"
  partyUuid: Party["uuid"]
}

const addNewUser = (params: addNewUserParams) => {
  addUserPartyUuid.value = params.partyUuid
}

const saveStep = () => {
  submitButton.value.click()
}

const submit = async (values) => {
  if (form.value.getMeta().dirty) {
    const url = route("api.documents.parties.update", {
      party: props.party.uuid,
      document: props.document.uuid,
    })

    const payload = pick(values, changedKeys(values, initialData.value))

    try {
      emit("update:is-submitting", true)
      const res = await axios.patch<{data: Party}>(url, payload)

      if (res.data.data) {
        router.get(route("d.setup", props.mdu.uuid), {}, {
          replace: true,
          preserveState: true,
          preserveScroll: true,
          only: [ "party" ],
          onFinish: () => {
            emit("update:is-submitting", false)
            emit("next-step")
          },
        })
      }
    } catch (err) {
      emit("update:is-submitting", false)
    }
  } else {
    emit("next-step")
  }
}

// If signatory switch is hit, update mdu to reflect new roles
const handleUpdateMduRoles = async (roles: DocumentUserRoleEnum[]) => {
  const payload = {
    ...props.mdu,
    roles: roles,
  }
  const updatedUser = await updateLocalUser(payload, CrudContext.document, props.document.uuid, false, false)
  if (updatedUser) {
    setMdu({ ...props.mdu, roles: roles })
    return updatedUser
  }
}

// Check if party has signatories
const hasSignatories = computed<boolean>(() => {
  return users.value?.some((user) => user.party_uuid === props.party.uuid && user.roles?.includes(DocumentUserRoleEnum.signatory))
})

const emit = defineEmits([ "next-step", "update:is-submitting" ])

defineExpose({
  saveStep,
})

const {
  setUpAddSignatoryTippies,
  setUpSignatoryTippies,
} = useTippies(CrudContext.document)

const entityTypeOptionsFiltered = computed<any>(() => {
  return entityTypeOptions.filter((el) => {
    return ![ PartyEntityType.tbd ].includes(el.value)
  })
})

onMounted(() => {
  setUpAddSignatoryTippies()
  setUpSignatoryTippies()
})

</script>
<template>
  <Form
    v-slot="{ errors }"
    ref="form"
    class="w-full space-y-4 text-left"
    :initial-values="initialData"
    @submit="submit"
  >
    <div v-if="!party.entity_type">
      <label
        class="block mb-1 text-sm font-medium truncate"
        for="party-entity-type"
      >
        {{ $t('partyForm.entityType') }}
        <span class="text-indigo-500">
          *
        </span>
      </label>
      <div>
        <Field
          id="party_entity_type"
          v-slot="{ field }"
          v-model="partyData.entity_type"
          name="entity_type"
          rules="required"
        >
          <RadioGroupPills
            v-bind="field"
            :model-value="partyData.entity_type"
            :options="entityTypeOptionsFiltered"
            :sr-label="$t('partyForm.selectEntityType')"
            :grid-class="`inline-flex items-center gap-3 grid-none`"
            :allow-null="true"
            :layout="'light'"
          />
        </Field>
      </div>
      <FormInputErrors
        v-if="errors?.entity_type"
        :errors="[errors?.entity_type]"
      />
    </div>
    <div>
      <label
        class="block mb-1 text-sm font-medium truncate"
        for="party_name"
      >{{ party.entity_type === PartyEntityType.person || partyData.entity_type === PartyEntityType.person ? $t('wizard.partyNamePerson') : $t('wizard.partyName') }}</label>
      <Field
        id="party_name"
        v-model="partyData.entity_name"
        name="entity_name"
        type="text"
        class="input-primary"
        :placeholder="$t(party.entity_type === PartyEntityType.person || partyData.entity_type === PartyEntityType.person ? 'wizard.partyPersonNamePlaceholder' : 'wizard.partyCompanyNamePlaceholder') + '…'"
        :class="[errors.entity_name ? 'input-has-errors' : '']"
        rules="required"
      />
      <FormInputErrors
        v-if="errors?.entity_name"
        :errors="[errors?.entity_name]"
      />
    </div>
    <div>
      <label
        class="block mb-1 text-sm font-medium truncate"
        for="party_address"
      >{{ $t('partyForm.address') }}</label>
      <Field
        v-model="partyData.address"
        as="textarea"
        name="address"
        type="text"
        :placeholder="$t('partyForm.addressPlaceholder') + '…'"
        class="input-primary"
        :class="[errors.address ? 'input-has-errors' : '']"
        rules="required"
      />
      <FormInputErrors
        v-if="errors?.address"
        :errors="[errors?.address]"
      />
    </div>
    <div v-if="party.entity_type === PartyEntityType.business || partyData.entity_type === PartyEntityType.business">
      <label
        class="block mb-1 text-sm font-medium truncate"
        for="signatories"
      >{{ $t('wizard.partySignatories') }}</label>
      <UserList
        class="-mx-6"
        :hide-party-header="true"
        :is-wizard="true"
        add-user-placement="top"
        @add-user="addNewUser($event)"
      />
      <div
        id="partyAndSignatoryPopovers"
        class="hidden"
      >
        <template
          v-for="user in relevantUsers"
          :key="user.uuid"
        >
          <SignatoryPopover
            :user="user"
            scope="external"
            :document="document"
            :hide-party-settings="true"
          />
        </template>
        <SignatoryPopover
          :party-uuid="addUserPartyUuid"
          :document="document"
        />
      </div>
    </div>
    <div v-else-if="party.entity_type === PartyEntityType.person || partyData.entity_type === PartyEntityType.person || !hasSignatories">
      <SignatorySwitch
        :user="mdu"
        :is-loading="uuidsOfUpdatingUser.includes(mdu.uuid)"
        :user-roles="mdu.roles"
        :label="$t('partyForm.setSelfSignatory')"
        @update:roles="handleUpdateMduRoles"
      />
    </div>
    <button
      ref="submitButton"
      type="submit"
      class="sr-only"
    >
      {{ $t('common.next') }}
    </button>
  </Form>
</template>
