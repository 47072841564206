<script setup lang="ts">
// external
import { ref, watch, computed } from "vue"
import { useI18n } from "vue-i18n"

// internal
import { DocumentTable, TabPills, DashboardWidget } from "~/components"

const { t } = useI18n()
const widget = ref()

const documentTabs = [
  { id: "involved", name: t("dashboard.myDocuments.tabs.involved") },
  { id: "last_visited", name: t("dashboard.myDocuments.tabs.last_visited") },
  { id: "created_by_me", name: t("dashboard.myDocuments.tabs.created_by_me") },
  { id: "last_active", name: t("dashboard.myDocuments.tabs.last_active") },
]

const params = ref({
  document_tab: documentTabs[0].id,
  page: 1,
})

const activeDocumentTab = computed({
  get: () => documentTabs.find((tab) => tab.id === params.value.document_tab),
  set: (tab) => {
    params.value.document_tab = tab.id
    params.value.page = 1
  },
})

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const preselectedTabKey = urlParams.get("document_tab")
if (preselectedTabKey) {
  const preselectedTab = documentTabs.find((tab) => tab.id === preselectedTabKey)

  if (preselectedTab) {
    params.value.document_tab = preselectedTab.id
  }
}

const switchPage = (newPage) => {
  params.value.page = newPage
}

let debouncer = null
watch(params.value, () => {
  clearTimeout(debouncer)
  debouncer = setTimeout(() => {
    widget.value.fetchData()
  }, 100)
})

</script>

<template>
  <DashboardWidget
    ref="widget"
    endpoint="api.data.documents.listEntries"
    :extra-params="params"
    :prevent-total-loading="true"
    @filter-changed="switchPage(1)"
  >
    <template #title>
      <span class="dashboard-widget-title">
        {{ $t('dashboard.myDocuments.label') }}
      </span>
    </template>
    <template #options>
      <TabPills
        v-model:activeTab="activeDocumentTab"
        :tabs="documentTabs"
        :use-select-fallback="true"
        class="w-full flex justify-end @md:mb-0 mb-1"
        nav-classes="justify-end"
      />
    </template>

    <template #default="{ data, isLoading }">
      <DocumentTable
        :pagination="data"
        :hide-per-page="true"
        :use-button-pagination="true"
        :hide-tags="true"
        :loading="isLoading"
        class="flex flex-col grow h-full"
        outer-classes="shadow-none"
        @switch-page="switchPage"
      />
    </template>
  </DashboardWidget>
</template>
