<script setup lang="ts">
import { ref } from "vue"
import { storeToRefs } from "pinia"
import { useDocumentStore, usePartyStore, useUserStore } from "~/stores"
import { CrudContext, DocumentUserRoleEnum, Team } from "~/types"
import { SpinLoader } from "~/components"
import { computed } from "vue"
import { ArrowLeftOnRectangleIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

interface Props {
  teams: Team[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const documentStore = useDocumentStore()
const { mau, mdu, currentDocument } = storeToRefs(documentStore)
const { setMdu, getDocumentLockState } = documentStore

const teams = computed<string>(() => {
  const intersectingTeams = props.teams.filter((team) => {
    return currentDocument.value.teams.some((documentTeam: Team) => documentTeam.uuid === team.uuid)
  })
  const teamNames = intersectingTeams.map((team) => team.name)
  let teamNamesString = teamNames.join(", ")
  // Replace last comma with ' or '
  const orTranslated = " " + t("common.or") + " "
  teamNamesString = teamNamesString.replace(/,([^,]*)$/, orTranslated + "$1")
  return teamNamesString || null
})

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const userStore = useUserStore()

const isJoiningDocument = ref(false)
const joinDocument = async () => {
  if (!!mau.value) {
    isJoiningDocument.value = true

    // When an MDU already exists, add the collaborator role to any existing roles
    if (!!mdu.value) {

      // Create payload
      const payload = {
        ...mdu.value,
        roles: [
          ...mdu.value.roles,
          DocumentUserRoleEnum.collaborator,
        ],
      }

      await userStore.updateLocalUser(payload, CrudContext.document, currentDocument.value?.uuid, true)
      await getDocumentLockState(currentDocument.value?.uuid)
      isJoiningDocument.value = false

    } else {


      const newMdu = await userStore.createUser(CrudContext.document, currentDocument.value?.uuid, {
        account_user_uuid: mau.value.uuid,
        party_uuid: parties.value.find((party) => !!party.account_party_uuid)?.uuid || null,
        roles: [ DocumentUserRoleEnum.collaborator ],
      })

      await getDocumentLockState(currentDocument.value?.uuid)
      isJoiningDocument.value = false

      if (newMdu) {
        setMdu(newMdu)
      }

    }

  }
}

</script>
<template>
  <div class="relative bg-indigo-600">
    <div class="flex items-center py-2 space-x-3 text-sm text-white px-7">
      <span v-if="mdu?.roles?.includes(DocumentUserRoleEnum.signatory) && mau?.roles?.map((role) => role.name)?.includes('account-owner')">
        {{ $t('documents.readAccessOwnerAsSignatory') }}
      </span>
      <span v-else-if="mdu?.roles?.includes(DocumentUserRoleEnum.signatory)">
        {{ $t('documents.readAccessAsSignatory', {teams: teams}) }}
      </span>
      <span v-else-if="mau?.roles?.map((role) => role.name)?.includes('account-owner')">
        {{ $t('documents.readAccessOwner') }}
      </span>
      <span v-else>
        {{ $t('documents.readAccess', {teams: teams}) }}
      </span>
      <button
        class="flex items-center gap-2 -my-1 text-indigo-100 join-document-button btn-plain btn-sm hover:bg-indigo-500 hover:text-white focus:ring-indigo-500 focus:ring-offset-0"
        type="button"
        data-cy-sel="join-document-button"
        @click="joinDocument"
      >
        <SpinLoader
          v-if="isJoiningDocument"
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <ArrowLeftOnRectangleIcon
          v-else
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <span v-if="mdu?.roles?.includes(DocumentUserRoleEnum.signatory)">{{ $t('documents.joinAsCollaborator') }}</span>
        <span v-else>{{ $t('documents.joinDocument') }}</span>
      </button>
    </div>
  </div>
</template>
