<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { Cog8ToothIcon, TagIcon } from "@heroicons/vue/24/solid"
import { EyeSlashIcon, PlusIcon, TrashIcon, EllipsisHorizontalIcon } from "@heroicons/vue/24/outline"
import tippy, { roundArrow } from "tippy.js"
import draggable from "vuedraggable"

// internal
import { MagicTableColumn, Metadata } from "~/types"
import { DialogModal, MetadataPopover, InfoBox } from "~/components"
import { focusFirstFocusable, getMetadataDisplayNameByKey } from "~/utils"
import { DragIndicatorIcon } from "~/icons"

interface Props {
  columns: MagicTableColumn[]
  metadata?: Metadata[]
  allowOverflow?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    metadata: null,
    allowOverflow: false,
  },
)

const MAX_COLUMN_LIMIT = 7

const isOpen = ref(false)

const localColumns = computed({
  get: () => JSON.parse(JSON.stringify(props.columns)),
  set: (columns) => {
    emits("update:columns", columns)
  },
})

const addedColumns = computed(() => props.columns.filter((column) => column.active && !column.required))

const emits = defineEmits([ "toggle", "update:columns" ])

const metadataColumns = computed(() => props.columns.filter((column) => column.isMetadata))

const limitReached = computed(() => props.allowOverflow ? false : addedColumns.value.length >= MAX_COLUMN_LIMIT)

const attachableMetadata = computed(() => {
  if (!props.metadata) return []
  const metadataColumnKeys = metadataColumns.value.map((metadataColumn) => metadataColumn.key)
  return props.metadata.filter((metadataEntry) => !metadataColumnKeys.includes(metadataEntry.name))
})

const selectedMetadata = computed({
  get: () => null,
  set: (metadata) => {
    if (metadata) {
      const tmpColumns = JSON.parse(JSON.stringify(localColumns.value))
      tmpColumns.splice(localColumns.value.length - 2, 0, {
        key: metadata.name,
        sortAttribute: metadata.name,
        isMetadata: true,
        required: false,
        active: true,
      })
      localColumns.value = tmpColumns

      addMetadataValueTippy.value?.hide()
    }
  },
})

const removeMetadataColumn = (key) => {
  const columnIndex = localColumns.value.findIndex((entry) => entry.key === key)
  if (columnIndex !== -1) {
    const tmpColumns = JSON.parse(JSON.stringify(localColumns.value))
    tmpColumns.splice(columnIndex, 1)
    localColumns.value = tmpColumns
  }
}

const addMetadataValuePopoverRef = ref()
const addMetadataValueTippy = ref(null)
const showAddMetadataPopover = (e) => {
  addMetadataValueTippy.value = tippy(e.target, {
    content () {
      return addMetadataValuePopoverRef.value
    },
    appendTo: () => { return document.getElementById("metadataArea") },
    animation: "scale",
    allowHTML: true,
    theme: "indigo",
    arrow: roundArrow,
    interactive: true,
    trigger: "manual",
    showOnCreate: true,
    placement: "top",
    onShown () {
      const element = addMetadataValuePopoverRef.value
      focusFirstFocusable(element)
    },
  })
}

</script>
<template>
  <div
    class="relative"
  >
    <button
      class="flex items-center justify-center w-8 h-8 rounded-full btn-sm"
      :class="isOpen ? 'btn-primary' : 'btn-plain text-gray-900 hover:bg-gray-300/40'"
      type="button"
      @click.prevent="isOpen = true"
    >
      <Cog8ToothIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
      <span class="sr-only">{{ $t('magicTable.configurator.configureColumns') }}</span>
    </button>

    <DialogModal
      :show="isOpen"
      max-width="lg"
      :padding="false"
      :show-footer="false"
      :show-close-button="true"
      @close="isOpen = false"
    >
      <template #title>
        <div class="px-6 pt-4">
          <span>{{ $t('magicTable.configurator.configureColumns') }}</span>
        </div>
      </template>
      <template #content>
        <div class="px-6">
          <InfoBox
            v-if="limitReached"
            class="mb-2"
          >
            <span>{{ $t('magicTable.configurator.columnLimitReached') }} ({{ addedColumns.length }}/{{ MAX_COLUMN_LIMIT }})</span>
          </InfoBox>
          <div
            v-if="!allowOverflow"
            class="relative grid w-full h-4 gap-px mb-3 overflow-hidden bg-gray-100 rounded-full"
            :class="'grid-cols-' + (addedColumns.length + 3)"
          >
            <div class="w-full h-full col-span-2 text-gray-200 bg-gray-300" />
            <div
              v-for="column in addedColumns"
              :key="column.key"
              class="w-full h-full flex items-center justify-center text-[10px]"
              :class="column.isMetadata ? 'bg-purple-500' : 'bg-indigo-500'"
            >
              <TagIcon
                v-if="column.isMetadata"
                class="w-3 h-3 text-purple-300"
                aria-hidden="true"
              />
            </div>
            <div class="w-full h-full bg-gray-300 text-gray-600 text-[10px] flex items-center justify-center">
              <EllipsisHorizontalIcon
                class="w-3 h-3"
                aria-hidden="true"
              />
            </div>
          </div>
          <div
            class="space-y-2"
          >
            <draggable
              v-model="localColumns"
              :animation="150"
              :ghost-class="'ghost'"
              group="columns"
              item-key="key"
              class="space-y-2"
            >
              <template #item="{element}">
                <div v-if="!element.required">
                  <SwitchGroup
                    v-if="!element.isMetadata"
                    as="div"
                    class="flex items-center justify-between space-x-2"
                  >
                    <SwitchLabel
                      as="span"
                      class="flex items-center mr-3 space-x-3 text-sm text-gray-500"
                      passive
                    >
                      <div class="text-gray-400 shrink-0 group-hover:text-gray-600 cursor-move">
                        <DragIndicatorIcon class="w-4 h-4 -ml-1" />
                      </div>
                      <span class="whitespace-nowrap">{{ $t('magicTable.columns.' + element.key) }}</span>
                    </SwitchLabel>
                    <Switch
                      v-model="element.active"
                      class="relative inline-flex h-5 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full shrink-0 w-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      :class="[
                        element.active ? 'bg-indigo-600' : 'bg-gray-300',
                        !element.active && limitReached ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                      ]"
                      :disabled="!element.active && limitReached"
                      @update:model-value="$emit('toggle', element.key)"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          element.active ? 'translate-x-4' : 'translate-x-0',
                          'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        ]"
                      />
                    </Switch>
                  </SwitchGroup>
                  <div
                    v-else
                    class="flex items-center justify-between text-sm text-gray-500"
                  >
                    <div class="flex items-center mr-3">
                      <div class="mr-3 text-gray-400 shrink-0 group-hover:text-gray-600 cursor-move">
                        <DragIndicatorIcon class="w-4 h-4 -ml-1" />
                      </div>
                      <TagIcon
                        class="shrink-0 h-3.5 w-3.5 text-purple-500 mr-1"
                        aria-hidden="true"
                      />
                      <span :class="!getMetadataDisplayNameByKey(element.key, metadata, $t) ? 'text-red-500' : ''">{{ getMetadataDisplayNameByKey(element.key, metadata, $t) || $t(`magicTable.columns.missingMetadata`) }}</span>
                    </div>
                    <button
                      type="button"
                      class="relative inline-flex items-center justify-center h-5 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full hover:bg-red-100 hover:text-red-900 shrink-0 w-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      @click="removeMetadataColumn(element.key)"
                    >
                      <TrashIcon
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <div
            v-if="!!metadata?.length"
            id="metadataArea"
          >
            <button
              type="button"
              :disabled="limitReached || !attachableMetadata.length"
              class="btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5 mx-auto mb-3"
              @click.prevent="showAddMetadataPopover"
            >
              <PlusIcon
                class="shrink-0 h-3.5 w-3.5"
                aria-hidden="true"
              />
              {{ $t('magicTable.configurator.addMetadataColumn') }}
            </button>
          </div>
          <div class="hidden">
            <div ref="addMetadataValuePopoverRef">
              <MetadataPopover
                v-model:selected-metadata="selectedMetadata"
                :attachable-metadata="attachableMetadata"
                :is-loading-metadata="false"
                dropdown-position="bottom"
                :is-loading="false"
                :disabled="!attachableMetadata.length"
              />
            </div>
          </div>
          <div
            v-if="!allowOverflow"
            class="flex items-center justify-center py-4 mt-2 space-x-2 text-xs font-normal text-gray-400 border-t border-t-gray-200"
          >
            <EyeSlashIcon
              aria-hidden="true"
              class="w-3.5 h-3.5 shrink-0"
            />
            <span>{{ $t('magicTable.configurator.visibilityHint') }}</span>
          </div>
        </div>
      </template>
    </DialogModal>
  </div>
</template>
