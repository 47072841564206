<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { usePage } from "@inertiajs/vue3"
import { ref, watch, computed, onBeforeMount } from "vue"
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"

// internal
import { DialogModal, CustomTaskForm, TaskEntry, Fireworks, SpinLoader, LoadingPlaceholder, Pagination, TabPills } from "~/components"
import { useTaskStore } from "~/stores"
import { TaskIcon } from "~/icons"
import { AccountUser } from "~/types"
import { useI18n } from "vue-i18n"

const pageProps = computed(() => usePage().props)
const mau = computed<AccountUser>(() => pageProps.value.mau as AccountUser)

const taskStore = useTaskStore()
const { accountUsers, showTaskModal, isLoading, incomingTasks, outgoingCustomTasks, isCreatingTask, showCreationForm } = storeToRefs(taskStore)
const {
  fetchIncomingTasks,
  fetchOutgoingCustomTasks,
  fetchAccountUsers,
  resolveCustomTaskByUuid,
  cancelCustomTaskByUuid,
} = taskStore

const { t } = useI18n()

const taskTabs = [
  {
    id: "incoming",
    name: t("tasks.incoming"),
  },
  {
    id: "outgoing",
    name: t("tasks.outgoing"),
  },
]
const activeTaskTab = ref<{ id: string, name: string }>()
const includeResolved = ref(false)

watch(showTaskModal, (newVal) => {
  if (newVal) {
    fetchAccountUsers()
    activeTaskTab.value = taskTabs[0]
  }
})

watch([ includeResolved, activeTaskTab ], () => {
  loadTasks()
})

const tasksToShow = computed(() => activeTaskTab.value?.id === "incoming" ? incomingTasks.value?.data : outgoingCustomTasks.value?.data)

const taskForm = ref()

const submit = () => {
  taskForm.value.submitButton.click()
  //document.getElementById("taskSubmitButton").click()
}

const loadTasks = (page = 1) => {
  if (activeTaskTab.value?.id === "incoming") {
    fetchIncomingTasks({
      page: page,
      includeResolved: includeResolved.value,
    })
  } else {
    fetchOutgoingCustomTasks({
      page: page,
      includeResolved: includeResolved.value,
    })
  }
}

onBeforeMount(() => {
  Echo.private(`users.${mau.value.user.uuid}`)
    .listen(
      "Pusher\\User\\TaskCreated",
      () => {
        if (showTaskModal.value) {
          loadTasks()
        }
      },
    )

  Echo.private(`users.${mau.value.user.uuid}`)
    .listen(
      "Pusher\\User\\TaskChanged",
      () => {
        if (showTaskModal.value) {
          loadTasks()
        }
      },
    )
})

// Generate random number from 1 to 50
const randomNoOpenTaskMessageNumber = Math.floor(Math.random() * 50) + 1
</script>
<template>
  <DialogModal
    :show="showTaskModal"
    max-width="3xl"
    :show-footer="showCreationForm"
    :show-close-button="true"
    :padding="false"
    @close="showTaskModal = false"
  >
    <template #title>
      <div class="flex items-center justify-between px-6 pt-2 gap-x-5">
        <div>
          {{ showCreationForm ? 'Create new task' : $t('navigation.tasks') }}
        </div>
        <div class="flex items-center gap-x-1">
          <button
            v-if="!showCreationForm"
            type="button"
            class="h-auto py-2 text-gray-400 btn-header hover:text-gray-900"
            @click="showCreationForm = true"
          >
            <TaskIcon
              aria-hidden="true"
              class="w-5 h-5"
            />
            <span>{{ $t('tasks.createTask') }}</span>
          </button>
        </div>
      </div>
    </template>

    <template #content>
      <LoadingPlaceholder
        v-if="isLoading && showCreationForm"
        class="relative w-full h-64 rounded"
      />
      <CustomTaskForm
        v-else-if="showCreationForm"
        ref="taskForm"
        :users="accountUsers"
        @success="showCreationForm = false"
      />
      <div v-else>
        <div
          class="relative border-b border-gray-300"
        >
          <div class="flex items-center gap-4 px-6 pb-4 -mt-2 shrink-0">
            <div class="grow">
              <TabPills
                v-model:active-tab="activeTaskTab"
                :tabs="taskTabs"
              />
            </div>
            <SwitchGroup
              as="div"
              class="flex items-center"
            >
              <SwitchLabel
                as="span"
                class="mr-3 text-sm"
                passive
              >
                <span class="whitespace-nowrap">{{ $t('tasks.showCompleted') }}</span>
              </SwitchLabel>
              <Switch
                v-model="includeResolved"
                :class="[
                  includeResolved ? 'bg-teal-600' : 'bg-gray-300',
                  'relative inline-flex shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    includeResolved ? 'translate-x-4' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                />
              </Switch>
            </SwitchGroup>
          </div>
        </div>
        <LoadingPlaceholder
          v-if="isLoading"
          class="relative w-full h-40 rounded"
        />
        <div
          v-else-if="!tasksToShow?.length"
          class="relative flex flex-col items-center justify-center py-px overflow-hidden grow"
        >
          <div class="flex flex-col items-center justify-center py-8 text-teal-500">
            <div class="flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full relative mx-auto [&amp;>*]:h-12 [&amp;>*]:w-12 ring ring-white [&amp;>*]:text-teal-500/50 bg-gradient-to-br from-gray-100 to-teal-100">
              <TaskIcon
                aria-hidden="true"
              />
            </div>

            <span class="mt-3 text-sm font-semibold text-teal-500">{{ $t('tasks.noOpenTasks') }}</span>

            <div class="px-5 text-sm text-center">
              {{ $t('tasks.noOpenTasksMessages.' + randomNoOpenTaskMessageNumber) }}
            </div>
          </div>
          <Fireworks />
        </div>
        <div
          v-else
          class="p-6 mb-5"
        >
          <div
            class="space-y-4 max-h-[70vh] overflow-y-auto -mx-6 px-6"
          >
            <template v-if="activeTaskTab.id === 'incoming'">
              <TaskEntry
                v-for="task in incomingTasks.data"
                :key="task.uuid"
                :task="task"
                :incoming="true"
                @visit="showTaskModal = false"
                @resolve="resolveCustomTaskByUuid(task.uuid)"
              />
            </template>
            <template v-if="activeTaskTab.id === 'outgoing'">
              <TaskEntry
                v-for="customTask in outgoingCustomTasks.data"
                :key="customTask.uuid"
                :custom-task="customTask"
                :incoming="false"
                @visit="showTaskModal = false"
                @cancel="cancelCustomTaskByUuid(customTask.uuid)"
              />
            </template>
          </div>
          <div class="flex items-center justify-center">
            <Pagination
              class="mt-2 -mb-6 border-t-0"
              :pagination="activeTaskTab.id === 'incoming' ? incomingTasks : outgoingCustomTasks"
              :use-buttons="true"
              :show-per-page="false"
              :show-result-info="false"
              @switch-page="loadTasks"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain focus:ring-teal-500"
          @click="showCreationForm = false"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          type="button"
          class="flex items-center gap-2 btn-teal"
          :disabled="isCreatingTask"
          @click="submit"
        >
          <SpinLoader
            v-if="isCreatingTask"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span>{{ $t('common.create') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
