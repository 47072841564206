<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { DocumentDuplicateIcon } from "@heroicons/vue/24/outline"
import { router } from "@inertiajs/vue3"

// internal
import { DialogModal, SpinLoader, EmptyState, AutomationCreationForm, SubscriptionUpgradeBox } from "~/components"
import { AccountUser, Team, Template, Tag } from "~/types"
import { storeToRefs } from "pinia"
import { useAccountStore } from "~/stores"

interface Props {
  templates: Template[]
  tags?: Tag[]
  mau: AccountUser
  open: boolean
}
withDefaults(
  defineProps<Props>(),
  { },
)

const isSubmitting = ref(false)

const automationCreationForm = ref()

const emit = defineEmits([ "update:open" ])

const close = () => {
  emit("update:open", false)
}

const handleSubmit = async () => {
  isSubmitting.value = true
  try {
    const newUuid = await automationCreationForm.value?.createAutomation()
    if (newUuid) router.visit(route("automations.show", newUuid))
  } finally {
    isSubmitting.value = false
  }
}

const accountStore = useAccountStore()
const { planFeatures } = storeToRefs(accountStore)
const isFeatureLocked = computed(() => !planFeatures.value?.["automations"])
</script>

<template>
  <DialogModal
    id="automationCreationModal"
    :show="open"
    max-width="2xl"
    :padding="false"
    :show-footer="!isFeatureLocked"
    :show-close-button="true"
    @close="close"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ $t('automations.create.titleXlsx') }}
      </div>
    </template>

    <template #content>
      <div>
        <div class="flex flex-col justify-between flex-1">
          <SubscriptionUpgradeBox
            v-if="isFeatureLocked"
            :text="$t('accountSettings.billing.featureRequiresUpgrade')"
            class="absolute inset-0 rounded-lg overflow-hidden"
          />
          <EmptyState
            v-if="!templates.length"
            :hide-button="!mau.permissions.includes('template_manage')"
            @click="() => router.visit(route('templates.index'))"
          >
            <template #icon>
              <DocumentDuplicateIcon
                aria-hidden="true"
              />
            </template>
            <template #call-to-action>
              {{ $t('automations.create.missingTemplates') }}
            </template>
            <template #default>
              {{ $t('templates.empty.title') }}
            </template>
            <template #button-text>
              {{ $t('documents.noTemplates.button') }}
            </template>
          </EmptyState>
          <AutomationCreationForm
            v-else
            ref="automationCreationForm"
            :templates="templates"
            :tag-options="tags"
            :teams="($page.props.teams as Team[])"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div
        class="flex items-center justify-end gap-2"
      >
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="close"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          v-if="templates?.length"
          :disabled="isSubmitting"
          type="button"
          class="flex items-center gap-2 btn-primary"
          @click.prevent="handleSubmit"
        >
          <SpinLoader
            v-if="isSubmitting"
            class="w-5 h-5"
          />
          <span v-if="isSubmitting">{{ $t('common.pleaseWait') }}</span>
          <span v-else>{{ $t('common.continue') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
