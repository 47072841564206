<script setup lang="ts">
// external
import { ref, computed, onMounted } from "vue"
import { storeToRefs } from "pinia"
import { ArrowDownTrayIcon, PaperClipIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/outline"
import { ChevronRightIcon, MinusIcon, PencilIcon } from "@heroicons/vue/24/solid"
// internal
import { DialogModal, EmptyState, FileInput, FormInputErrors, PdfViewer, SpinLoader } from "~/components"
import { Attachment, Document, Template } from "~/types"
import { useAttachmentStore, useDocumentStore, useSharedStore } from "~/stores"
import { cyrb53, formatBytes, getFileTypeIconByMimeType } from "~/utils"
import { SaveIcon } from "~/icons"

interface Props {
  document?: Document
  template?: Template
  disabled?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
    disabled: false,
  },
)

interface DocumentPayload {
  file_uuid: string
}

const documentStore = useDocumentStore()
const { mau, mdu, isLockedDocument } = storeToRefs(documentStore)

const attachmentStore = useAttachmentStore()
const { visibleAttachments, uuidOfIsUpdatingAttachment, deletedAttachments, attachments, backendErrors, isLoadingSaveAttachmentCount, uuidOfIsRemovingAttachment, uuidOfIsLoadingGetAttachmentUrl } = storeToRefs(attachmentStore)
const { fetchAttachments, uploadAttachment, updateAttachment, removeAttachment, getAttachmentUrl } = attachmentStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

// Calculate entity uuid for db calls
const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return props?.[crudContext.value]?.uuid
})

const errorsToShow = computed<Partial<Record<keyof DocumentPayload, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentPayload, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const isLoadingShowAttachment = ref<Attachment["uuid"]>(null)
const attachmentUrl = ref<string>()
const activeAttachmentUuid = ref<Attachment["uuid"]>()
const isVisibleDeletedAttachments = ref<boolean>(false)

// Fetch all available attachments via api.documents.attachments.index onMounted and setAttachments(), all using try/catch
onMounted(async () => {
  fetchAttachments(crudContext.value, entityUuid.value)
})

const handleShowAttachment = async (attachmentUuid: Attachment["uuid"], forceDownload = false): Promise<void> => {
  isLoadingShowAttachment.value = attachmentUuid
  if (!forceDownload) activeAttachmentUuid.value = attachmentUuid
  const url = await getAttachmentUrl(crudContext.value, entityUuid.value, attachmentUuid)
  const attachment = attachments.value.find((attachment) => attachment.uuid === attachmentUuid)
  if (isViewable(attachment?.filename) && !forceDownload) {
    attachmentUrl.value = url
  } else {
    window.open(
      url,
      "_blank", // <- This is what makes it open in a new window.
    )
  }
}

const handleUploadAttachment = async (file_uuid: string, file_name: string): Promise<void> => {
  uploadAttachment(crudContext.value, entityUuid.value, file_uuid, file_name)
}

const activeAttachmentFilename = computed<string>(
  () => {
    const attachment = attachments.value.find((attachment) => attachment.uuid === activeAttachmentUuid.value)
    return attachment?.filename
  },
)

const isViewableActiveAttachment = computed<boolean>(
  () => {
    return isViewable(activeAttachmentFilename.value)
  },
)

const isViewable = (filename: string): boolean => {
  if (!filename) return false
  let extension = filename.split(".").pop()
  // Force lowercase
  extension = extension?.toLowerCase()
  return [ "pdf", "jpg", "jpeg", "png", "gif", "webp" ].includes(extension)
}

const isPdfActiveAttachment = computed<boolean>(
  () => {
    if (!activeAttachmentFilename.value) return false
    let extension = activeAttachmentFilename.value.split(".").pop()
    // Force lowercase
    extension = extension?.toLowerCase()
    return [ "pdf" ].includes(extension)
  },
)

const zoomHalf = ref<boolean>(false)

const isActiveEditAttachment = ref<Attachment["uuid"]>(null)
const attachmentNames = ref<Record<Attachment["uuid"], string>>({})

const handleSetAttachmentName = (attachmentUuid: Attachment["uuid"], name: string): void => {
  attachmentNames.value[attachmentUuid] = name
}

const handleSaveAttachment = async (attachmentUuid: Attachment["uuid"]): Promise<void> => {
  const updatedFilename = attachmentNames.value[attachmentUuid] || attachments.value.find((attachment) => attachment.uuid === attachmentUuid)?.filename
  await updateAttachment(crudContext.value, entityUuid.value, attachmentUuid, { filename: updatedFilename })
  isActiveEditAttachment.value = null
}

</script>

<template>
  <div class="max-w-4xl mx-auto mt-4">
    <template v-if="visibleAttachments?.length">
      <ul
        role="list"
        class="mb-4 border border-gray-200 divide-y divide-gray-200 rounded-md"
      >
        <li
          v-for="attachment in visibleAttachments"
          :key="attachment.uuid"
          class="flex flex-col items-center justify-between w-full gap-4 py-4 pl-4 pr-5 text-sm leading-6 md:flex-row"
        >
          <div class="flex items-center flex-1 w-full md:w-0">
            <div class="relative">
              <PaperClipIcon
                class="w-5 h-5 text-gray-400 shrink-0"
                aria-hidden="true"
              />
              <component
                :is="getFileTypeIconByMimeType(attachment.mime_type)"
                class="absolute w-4 h-4 -right-1.5 -bottom-1.5 shrink-0"
                aria-hidden="true"
              />
            </div>
            <div class="flex-col flex-1 ml-4">
              <div
                class="inline-flex items-center w-full min-w-0 gap-2"
                :class="isActiveEditAttachment === attachment.uuid ? 'bg-white px-1 mb-1 rounded-md border border-gray-200' : ''"
              >
                <input
                  v-if="mau && isActiveEditAttachment === attachment.uuid"
                  :id="'input_documentAttachmentName_'+attachment.uuid"
                  type="text"
                  :value="attachmentNames[attachment.uuid] || attachment.filename"
                  :disabled="uuidOfIsUpdatingAttachment === attachment.uuid"
                  class="min-w-[12rem] sm:text-sm border-0 grow"
                  :placeholder="$t('documents.attachmentNamePlaceholder')+'…'"
                  @change="handleSetAttachmentName(attachment.uuid, ($event.target as HTMLInputElement).value)"
                  @keyup.enter="handleSaveAttachment(attachment.uuid)"
                >
                <span
                  v-else
                  class="font-medium line-clamp-1"
                >
                  {{ attachment.filename }}
                </span>
                <span class="text-gray-400 shrink-0">{{ formatBytes(attachment.file_size) }}</span>
                <button
                  v-if="mau && ((mdu && mdu?.permissions.includes('attachment_update')) || !props.document?.uuid) && !isLockedDocument"
                  class="flex items-center justify-center w-6 h-6 p-0 -ml-1 text-gray-400 rounded-full shrink-0 focus:ring-0 focus:ring-offset-0 btn-plain hover:bg-indigo-100 hover:bg-text-700 hover:ring-indigo-100 hover:text-indigo-500"
                  :disabled="uuidOfIsUpdatingAttachment === attachment.uuid"
                  @click.prevent="() => {
                    if (isActiveEditAttachment === attachment.uuid) {
                      handleSaveAttachment(attachment.uuid)
                    } else {
                      isActiveEditAttachment = attachment.uuid
                    }
                  }"
                >
                  <SpinLoader
                    v-if="uuidOfIsUpdatingAttachment === attachment.uuid && isActiveEditAttachment === attachment.uuid"
                    class="w-3.5 h-3.5 shrink-0"
                    aria-hidden="true"
                  />
                  <template
                    v-else-if="isActiveEditAttachment === attachment.uuid"
                  >
                    <SaveIcon
                      class="w-3.5 h-3.5 text-indigo-500 shrink-0"
                      aria-hidden="true"
                    />
                  </template>
                  <template v-else>
                    <PencilIcon
                      class="w-3.5 h-3.5 shrink-0"
                      aria-hidden="true"
                    />
                  </template>
                </button>
              </div>
              <div class="flex min-w-0 text-xs">
                <span class="text-gray-400 truncate">{{ attachment.sha256 }}</span>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-3 ml-4 shrink-0">
            <button
              :disabled="uuidOfIsLoadingGetAttachmentUrl === attachment.uuid"
              class="flex items-center gap-2 p-0 text-indigo-700 btn-plain focus:ring-0 focus:ring-offset-0 focus:border-0 hover:text-indigo-900"
              @click.prevent="handleShowAttachment(attachment.uuid)"
            >
              <SpinLoader
                v-if="uuidOfIsLoadingGetAttachmentUrl === attachment.uuid"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ isViewable(attachment.filename) ? $t("documents.showAttachment") : $t("documents.downloadAttachment") }}
            </button>
            <button
              v-if="isViewable(attachment.filename)"
              class="flex items-center gap-2 p-0 text-indigo-700 btn-plain focus:ring-0 focus:ring-offset-0 focus:border-0 hover:text-indigo-900"
              @click.prevent="handleShowAttachment(attachment.uuid, true)"
            >
              {{ $t("documents.downloadAttachment") }}
            </button>
            <button
              v-if="mau && ((mdu && mdu?.permissions.includes('attachment_delete')) || !props.document?.uuid) && !isLockedDocument"
              class="flex items-center justify-center w-6 h-6 p-0 text-gray-400 rounded-full btn-plain hover:bg-red-100 hover:bg-text-700 hover:ring-red-100 hover:text-red-500"
              :disabled="uuidOfIsRemovingAttachment === attachment.uuid"
              @click="removeAttachment(crudContext, entityUuid, attachment.uuid)"
            >
              <SpinLoader
                v-if="uuidOfIsRemovingAttachment === attachment.uuid"
                class="flex-none w-4 h-4"
              />
              <TrashIcon
                v-else
                class="flex-none w-4 h-4"
              />
            </button>
          </div>
        </li>
      </ul>
    </template>
    <template v-if="deletedAttachments?.length && !!mau">
      <ul
        role="list"
        class="mb-4 bg-gray-100 border border-gray-200 divide-y divide-gray-200 rounded-md"
      >
        <button
          class="flex items-center gap-2 py-3 pl-4 pr-5 text-sm leading-6 text-indigo-500 hover:text-indigo-700"
          @click="isVisibleDeletedAttachments = !isVisibleDeletedAttachments"
        >
          <ChevronRightIcon
            class="w-4 h-4 transition-transform"
            :class="isVisibleDeletedAttachments ? 'rotate-90' : ''"
          />{{ $t('documents.showDeletedAttachments') }} ({{ deletedAttachments.length }})
        </button>
        <template v-if="isVisibleDeletedAttachments">
          <li
            v-for="attachment in deletedAttachments"
            :key="attachment.uuid"
            class="flex items-center justify-between gap-4 py-4 pl-4 pr-5 text-sm leading-6 opacity-50"
          >
            <div class="flex items-center flex-1 w-0">
              <div class="relative">
                <PaperClipIcon
                  class="w-5 h-5 text-gray-400 shrink-0"
                  aria-hidden="true"
                />
                <component
                  :is="getFileTypeIconByMimeType(attachment.mime_type)"
                  class="absolute w-4 h-4 -right-1.5 -bottom-1.5 shrink-0"
                  aria-hidden="true"
                />
              </div>
              <div class="flex-1 ml-4 truncate">
                <div class="flex min-w-0 gap-2">
                  <span class="font-medium truncate">{{ attachment.filename }}</span>
                  <span class="text-gray-400 shrink-0">2.4mb</span>
                </div>
                <div class="flex min-w-0 text-xs">
                  <span class="text-gray-400 truncate">{{ attachment.sha256 }}</span>
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </template>
    <div
      v-if="mau && (mdu || !props.document?.uuid) && !isLockedDocument"
      class="text-sm"
    >
      <FileInput
        :upload-callback="handleUploadAttachment"
        :form-errors="errorsToShow"
        :multiple="true"
        :accept="'.doc, .docx, application/xml, .xls, .xlsx, .csv, .ppt, .pptx, message/rfc822, .txt, application/pdf, image/*'"
        :allowed-extensions="'/\.(docx?|xml|xlsx?|pptx?|eml|txt|csv|pdf|jpe?g|png|gif)$/i'"
        :empty-list-after-upload="true"
        :parent-pending="isLoadingSaveAttachmentCount > 0"
        :file-type-error="$t('documents.attachmentFileTypeError')"
        :placeholder="$t('documents.fileInputPlaceholder')"
      />
      <FormInputErrors
        v-if="errorsToShow?.file_uuid?.length"
        :errors="errorsToShow?.file_uuid"
      />
    </div>
    <div v-else-if="!visibleAttachments?.length && !deletedAttachments?.length">
      <EmptyState>
        <template #icon>
          <PaperClipIcon
            class="w-12 h-12 mx-auto text-gray-400"
            aria-hidden="true"
          />
        </template>

        {{ $t('documents.noAttachments') }}.
      </EmptyState>
    </div>
  </div>
  <!-- Dialog modal with PDF viewer for attachments -->
  <DialogModal
    v-if="!!(activeAttachmentUuid && isViewableActiveAttachment)"
    :show="!!(activeAttachmentUuid && isViewableActiveAttachment && !uuidOfIsLoadingGetAttachmentUrl)"
    :show-close-button="true"
    :show-footer="false"
    @close="activeAttachmentUuid = ''"
  >
    <template #title>
      <div class="flex items-center gap-4">
        <span class="truncate">{{ activeAttachmentFilename }}</span>
        <button
          class="flex items-center gap-2 p-0 mr-4 text-indigo-700 btn-plain focus:ring-0 focus:ring-offset-0 focus:border-0 hover:text-indigo-900"
          @click.prevent="handleShowAttachment(activeAttachmentUuid, true)"
        >
          <ArrowDownTrayIcon class="w-4 h-4" /> {{ $t("documents.downloadAttachment") }}
        </button>
        <div
          v-if="!isPdfActiveAttachment"
          class="z-10 flex items-center justify-end pr-3 -mt-1 -space-x-px grow"
        >
          <button
            class="btn-plain rounded-l-full px-1.5 py-0.5 flex items-center focus:z-10"
            :class="zoomHalf ? 'pointer-events-none bg-indigo-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'"
            type="button"
            @click.prevent="zoomHalf = true"
          >
            <MinusIcon
              class="w-4 h-4"
              aria-hidden="true"
            />
          </button>
          <button
            class="btn-plain rounded-r-full px-1.5 py-0.5 flex items-center focus:z-10"
            :class="!zoomHalf ? 'pointer-events-none bg-indigo-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'"
            type="button"
            @click.prevent="zoomHalf = false"
          >
            <PlusIcon
              class="w-4 h-4"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="-my-6 min-w-[20rem] min-h-[20rem] flex items-center justify-center">
        <PdfViewer
          v-if="attachmentUrl && isPdfActiveAttachment"
          :key="cyrb53(attachmentUrl)"
          :component-key="cyrb53(attachmentUrl)"
          :src="attachmentUrl"
        />
        <img
          v-else-if="attachmentUrl && !isPdfActiveAttachment"
          :src="attachmentUrl"
          :alt="activeAttachmentFilename"
          class="max-h-full mt-4 mb-8 shadow-xl"
          :class="zoomHalf ? 'max-w-[50%]' : 'max-w-[100%]'"
        >
        <SpinLoader
          v-else
          class="w-6 h-6 text-gray-500"
          aria-hidden="true"
        />
      </div>
    </template>
  </DialogModal>
</template>
