import { storeToRefs } from "pinia"

import { EnvelopeIcon, UserPlusIcon } from "@heroicons/vue/24/outline"
import { Editor, Range } from "@tiptap/core"

// internal
import { useCommentStore, useConfirmationStore, useDocumentStore, useUserStore } from "~/stores"
import { AccountUser, CrudContext, DocumentStage, DocumentUser, DocumentUserRoleEnum } from "~/types"
import i18nInstance from "~/utils/i18n"

export const extractmentionedDocumentUserUuids = (editor: Editor) => {
  const mentions: DocumentUser["uuid"][] = []

  editor.state.doc.descendants(
    (node) => {
      if (node.type.name !== "mention" || !node.attrs.mentionUuid) return

      mentions.push(node.attrs.mentionUuid)
    },
  )

  return mentions
}

interface Props {
  uuid: string
  account_user?: AccountUser
  name: string
}

export const mentionCommand = ({ editor, range, props }: {
  editor: Editor,
  range: Range,
  props: Props,
}) => {
  const documentStore = useDocumentStore()
  const { mdu, currentDocument } = storeToRefs(documentStore)

  const userStore = useUserStore()
  const {
    sendInvitations,
    sendUserInvitation,
    setDocumentUsersForSharing,
  } = userStore

  const confirmationStore = useConfirmationStore()
  const {
    setShowConfirmModal,
    setConfirmOptions,
  } = confirmationStore

  const commentStore = useCommentStore()
  const { setIsActiveAddMentionedUserModal } = commentStore

  const emptyUser: Partial<DocumentUser> = Object.freeze({ roles: [ DocumentUserRoleEnum.collaborator ] })

  const { t } = i18nInstance.global

  // if item is an existing external document user notify the comment creator that the user will not be notified
  const checkIfExternalButStageInDraft = props.uuid && !props.account_user?.uuid && (currentDocument.value?.stage === DocumentStage.draft || currentDocument.value?.stage === DocumentStage.approved_draft)
  const checkIfExternalWithIdButNotInvited = props.uuid && !props.account_user?.uuid && !userStore.userNotifications?.some((item) => item.document_user_uuid === props.uuid)

  if (checkIfExternalButStageInDraft) {

    setConfirmOptions(
      {
        title: t("discussions.userNotNotifiedTitle{name}", { name: props.name }),
        description: t("discussions.userNotNotifiedHint{name}", { name: props.name }),
        buttonText: t("discussions.userNotNotifiedButton"),
        callback: () => {
          insertNode({ editor, range, props })
        },
        cancelCallback: () => {
          return
        },
      },
    )

    setIsActiveAddMentionedUserModal(true)
    setShowConfirmModal(true)
    return
  }
  else if (checkIfExternalWithIdButNotInvited) {
    // if item is not an existing documentUser, display confirmation modal to add the account user
    setConfirmOptions(
      {
        icon: UserPlusIcon,
        title: t("discussions.userNotInvitedTitle{name}", { name: props.name }),
        description: t("discussions.userNotInvitedHint{name}", { name: props.name }),
        buttonText: t("discussions.userNotInvitedButton"),
        callback: async () => {
          const sendUserInvitationRes = await sendUserInvitation(currentDocument.value?.uuid, props.uuid as string)
          if (sendUserInvitationRes) insertNode({ editor, range, props })
          setIsActiveAddMentionedUserModal(false)
        },
        cancelCallback: () => {
          return
        },
      },
    )
  }
  // if item is an existing documentUser and all good, insert node
  else if (props.uuid) {
    insertNode({ editor, range, props })
    return
  }
  // if item is not an existing documentUser, display confirmation modal to add the account user
  else {
    setConfirmOptions(
      {
        icon: EnvelopeIcon,
        title: t("discussions.userNotAddedTitle{name}", { name: props.name }),
        description: t("discussions.userNotAddedHint{name}", { name: props.name }),
        buttonText: t("discussions.userNotAddedButton"),
        callback: async () => {
          setDocumentUsersForSharing([ { ...Object.assign(emptyUser), account_user: props.account_user, party_uuid: mdu.value?.party_uuid || null } ])
          const res = await sendInvitations("", CrudContext.document, currentDocument.value?.uuid, [ DocumentUserRoleEnum.collaborator ])
          if (res) {
            const documentUserUuid = res.find((item) => item.document_user_uuid)?.document_user_uuid
            if (!documentUserUuid) return
            const propsObject = { ...props, mentionUuid: documentUserUuid }
            const argsObject = { editor, range, props: propsObject }
            insertNode(argsObject)
          }
          setDocumentUsersForSharing([])
          setIsActiveAddMentionedUserModal(false)
        },
        cancelCallback: () => {
          return
        },
      },
    )
  }

  setIsActiveAddMentionedUserModal(true)
  setShowConfirmModal(true)
}

const insertNode = ({ editor, range, props }) => {
  // increase range.to by one when the next node is of type "text"
  // and starts with a space character
  const { nodeAfter } = editor.view.state.selection.$to
  const overrideSpace = nodeAfter?.text?.startsWith(" ")

  if (overrideSpace) range.to += 1

  editor
    .chain()
    .focus()
    .insertContentAt(range, [
      {
        type: "mention",
        attrs: props,
      },
    ])
    .run()
}
