<script setup lang="ts">
// external
import { PhotoIcon } from "@heroicons/vue/24/outline"
import { ArrowLeftIcon } from "@heroicons/vue/24/solid"
import { onMounted } from "vue"
import { ref } from "vue"

// internal

interface Props {
  deviceList: MediaDeviceInfo[]
}

withDefaults(
  defineProps<Props>(),
  {
    deviceList: null,
  },
)

const emit = defineEmits( [ "capture", "cancel", "update:is-loading-capture" ])

interface ImageData {
  image: string
  image_orientation: number
}

const mediaStream = ref<MediaStream | null>(null)
const imageData = ref<ImageData | null>(null)
const videoTag = ref<HTMLVideoElement | null>(null)
const isVisibleCameraModal = ref(false)
const selectedDeviceId = ref<string | null>(null)
const isActivatedBrowserImageCaptureFeature = ref(false)

const capture = () => {
  const mediaStreamTrack = mediaStream.value?.getVideoTracks()[0]
  // Return if window.ImageCapture is not supported
  if (!mediaStreamTrack) return
  if ("ImageCapture" in window && isActivatedBrowserImageCaptureFeature.value) {
    const imageCapture = new (window as any).ImageCapture(mediaStreamTrack)
    const reader = new FileReader()
    return imageCapture.takePhoto()
      .then((blob) => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          imageData.value = {
            image: base64data as string,
            image_orientation: 0,
          }
          emit("capture", base64data)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  } else {
    const reader = new FileReader()
    return imageCaptureFallback(mediaStream.value)
      .then((blob) => {
        reader.readAsDataURL(blob as Blob)
        reader.onloadend = () => {
          const base64data = reader.result
          imageData.value = {
            image: base64data as string,
            image_orientation: 0,
          }
          emit("capture", base64data)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

const imageCaptureFallback = (stream: MediaStream) => {
  const video = document.createElement("video")
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  video.srcObject = stream

  return new Promise((resolve, reject) => {
    video.addEventListener("loadeddata", async () => {
      const { videoWidth, videoHeight } = video
      canvas.width = videoWidth
      canvas.height = videoHeight

      try {
        await video.play()
        context.drawImage(video, 0, 0, videoWidth, videoHeight)
        canvas.toBlob(resolve, "image/png")
      } catch (error) {
        reject(error)
      }
    })
  })
}

/* const rotate = () => {
  if (imageData.value) {
    imageData.value.image_orientation += 90
  }
} */

const getMedia = () => {
  navigator.mediaDevices.getUserMedia(
    {
      audio: false,
      video: {
        deviceId: selectedDeviceId.value ? { exact: selectedDeviceId.value } : undefined,
        //height: { min: 320 },
        //width: { min: 320 },
        //aspectRatio: 768 / 168,
      },
    },
  )
    .then((stream) => {
      if (videoTag.value) {
        videoTag.value.srcObject = stream
        videoTag.value.play()
      }
      mediaStream.value = stream
    })
    .catch((err) => {
      console.error(err)
    })
}

const cancel = () => {
  imageData.value = null
  isVisibleCameraModal.value = true
  updateStream()
}

const stopCamera = () => {
  if (mediaStream.value) {
    mediaStream.value.getTracks().forEach((track) => {
      track.stop()
    })
  }
  mediaStream.value = null
}

const updateStream = (deviceId = null) => {
  selectedDeviceId.value = deviceId
  stopCamera()
  getMedia()
}

onMounted(() => {
  getMedia()
})

defineExpose({ cancel })

</script>

<template>
  <div class="pb-20">
    <video
      v-if="!imageData?.image"
      ref="videoTag"
      autoplay
      playsinline
      class="w-full camera-stream rounded-t-xl"
    />
    <div v-else>
      <img
        :src="imageData?.image"
        :style="{ transform: `rotate(${imageData?.image_orientation}deg)` }"
      >
    </div>
    <div class="absolute inset-x-0 flex items-center justify-center gap-2 bottom-6">
      <select
        v-if="deviceList.length > 1"
        class="btn-plain"
        @change="updateStream(($event.target as HTMLInputElement).value)"
      >
        <option
          v-for="device in deviceList"
          :key="device.deviceId"
          :value="device.deviceId"
        >
          {{ device.label }}
        </option>
      </select>
      <button
        class="flex items-center gap-2 hover:text-gray-700 btn-plain"
        @click="$emit('cancel')"
      >
        <ArrowLeftIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        {{ $t('common.back') }}
      </button>
      <button
        class="flex items-center gap-1 btn-primary"
        @click="capture"
      >
        <PhotoIcon class="w-5 h-5" />
        <span>{{ $t('common.capture') }}</span>
      </button>
      <!--<button
        class="inline-flex items-center gap-1 btn-white"
        @click="rotate"
      >
        <ArrowPathRoundedSquareIcon class="w-5 h-5" />
        <span>{{ $t('common.rotate') }}</span>
      </button>-->
    </div>
  </div>
</template>
