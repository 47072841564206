import { MagicTableColumn } from "~/types"

export const documentTableConfigSimple:MagicTableColumn[] = [
  {
    key: "name",
    required: true,
    active: true,
  },
  {
    key: "stage",
    breakpoint: "@2xl",
    required: true,
    active: true,
  },
  {
    key: "teams",
    breakpoint: "@5xl",
    required: false,
    active: true,
  },
  {
    key: "users",
    breakpoint: "@2xl",
    required: false,
    active: true,
  },
  {
    key: "timestamps",
    breakpoint: "@xl",
    required: false,
    active: true,
  },
]

export const documentTableConfigFull:MagicTableColumn[] = [
  {
    key: "select",
    required: true,
    active: true,
  },
  {
    key: "name",
    sortAttribute: "name",
    required: true,
    active: true,
  },
  {
    key: "stage",
    breakpoint: "@lg",
    sortAttribute: "stage",
    required: false,
    active: true,
  },
  {
    key: "teams",
    breakpoint: "@7xl",
    required: false,
    active: true,
  },
  {
    key: "users",
    breakpoint: "@4xl",
    required: false,
    active: true,
  },
  {
    key: "origin",
    breakpoint: "@6xl",
    required: false,
    active: true,
  },
  {
    key: "timestamps",
    breakpoint: "@5xl",
    required: false,
    active: true,
  },
  {
    key: "options",
    required: true,
    active: true,
  },
]
