<script setup lang="ts">
// external
import {
  CheckCircleIcon,
  XCircleIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/vue/24/solid"
import { computed } from "vue"
import { useI18n } from "vue-i18n"

// internal
import { AccountUser, Comment } from "~/types"
import { SpinLoader } from "~/components"


interface Props {
  comment: Comment
  mau?: AccountUser
  loading?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    mau: null,
    loading: false,
  },
)

const { t } = useI18n()

const emit = defineEmits([ "resolve-comment", "show-proposal" ])

const isProposal = computed(() => props.comment.type === "proposal")
const canResolve = computed(() => props.mau?.permissions.includes("comment_resolve_proposal"))

const buttonConfig = computed(() => {
  if (!isProposal.value) {
    if (props.comment.is_resolved) {
      return {
        component: CheckCircleIcon,
        label: t("discussions.resolved"),
        classes: "text-green-600",
      }
    } else {
      return {
        component: CheckCircleIcon,
        label: t("discussions.resolveComment"),
        classes: props.comment?.scope === "internal" ? "text-indigo-300 hover:text-indigo-600 disabled:text-indigo-300" : "text-gray-400 hover:text-indigo-600 disabled:text-gray-400",
      }
    }
  } else {
    if (props.comment.proposal_status === "open") {
      if (canResolve.value) {
        return {
          component: EllipsisHorizontalCircleIcon,
          label: t("discussions.resolveProposal"),
          classes: props.comment?.scope === "internal" ? "text-indigo-300 hover:text-indigo-600 disabled:text-indigo-300" : "text-gray-400 hover:text-indigo-600 disabled:text-gray-400",
        }
      } else {
        return {
          component: EllipsisHorizontalCircleIcon,
          label: t("discussions.viewProposal"),
          classes: props.comment?.scope === "internal" ? "text-indigo-300 hover:text-indigo-600 disabled:text-indigo-300" : "text-gray-400 hover:text-indigo-600 disabled:text-gray-400",
        }
      }
    }
    if (props.comment.proposal_status === "accepted") {
      return {
        component: CheckCircleIcon,
        label: t("proposal.acceptedProposal"),
        classes: "text-green-600",
      }
    } else {
      return {
        component: XCircleIcon,
        label: t("proposal.rejectedProposal"),
        classes: "text-red-600",
      }
    }
  }
})

</script>

<template>
  <button
    type="button"
    data-tippy-help
    :data-tippy-content="buttonConfig.label"
    data-placement="bottom"
    :disabled="loading"
    :class="buttonConfig.classes"
    @click.prevent.stop="() => isProposal ? emit('show-proposal') : emit('resolve-comment')"
  >
    <component
      :is="buttonConfig.component"
      v-if="!loading"
      class="w-5 h-5 shrink-0"
    />
    <div
      v-else
      class="flex items-center w-5 h-5"
    >
      <SpinLoader
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
    </div>
  </button>
</template>
