<script setup lang="ts">
// external
import { ref, computed, watch } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { nextTick } from "vue"
import { useI18n } from "vue-i18n"
import { CheckIcon } from "@heroicons/vue/24/solid"

// internal
import { currencyOptions, formatMultifieldNumber } from "~/utils"
import { CurrencyOption, DynamicFieldSettings, MultifieldFormat, CurrencyDurationOption, MultiFieldType } from "~/types"

interface Props {
  id?: string
  modelValue?: string
  label?: string
  disabled?: boolean
  readonly?: boolean
  lastSaved?: number
  style?: string
  settings?: DynamicFieldSettings
  format?: MultifieldFormat
  hasError?: boolean
  hasDuration?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    id: null,
    modelValue: "",
    label: null,
    disabled: false,
    readonly: false,
    lastSaved: null,
    style: "gray",
    settings: null,
    format: null,
    hasError: false,
    hasDuration: false,
  },
)

const emit = defineEmits([ "update:model-value", "submit-enter", "blur", "focus" ])

const validationError = ref<string>()

const { locale, t } = useI18n()
const stopPropagation = ref<boolean>(false)

watch(() => props.modelValue, (newVal) => {
  stopPropagation.value = true
  if (newVal && newVal.includes(";")) {
    const splitValue = props.modelValue.split(";")
    const newValueCurrency = splitValue[0]
    const newValueAmount = splitValue[1]
    const newDurationAmount = splitValue[2]
    currency.value = newValueCurrency ? currencyOptions.find((el) => el.code === newValueCurrency) : currencyOptions?.[0]
    if (props.hasDuration) duration.value = newDurationAmount ? CurrencyDurationOption?.[newDurationAmount] : CurrencyDurationOption.monthly
    localAmount.value = newValueAmount
  } else {
    currency.value = currencyOptions?.[0]
    if (props.hasDuration) duration.value = CurrencyDurationOption.monthly
    localAmount.value = null
  }
  nextTick(() => stopPropagation.value = false)
})

const localAmount = ref<string>(props.modelValue ? props.modelValue.split(";")?.[1] : null)

const localCurrency = ref<CurrencyOption>(props.modelValue ? currencyOptions.find((el) => el.code === props.modelValue.split(";")?.[0]) : null)

const currency = computed<CurrencyOption>({
  get: () => {
    if (localCurrency.value) return localCurrency.value
    const firstCurrencyInSettingsObject = currencyOptions.find((el) => el.code === props.settings?.currencies?.[0])
    if (firstCurrencyInSettingsObject) return firstCurrencyInSettingsObject
    return currencyOptions?.[0]
  },
  set: (val) => {
    localCurrency.value = val
  },
})

const localDuration = ref<CurrencyDurationOption>(props.modelValue ? CurrencyDurationOption?.[props.modelValue.split(";")?.[2]] : CurrencyDurationOption.monthly)
const duration = computed<CurrencyDurationOption>({
  get: () => {
    if (localDuration.value) return localDuration.value
    return CurrencyDurationOption.monthly
  },
  set: (val) => {
    localDuration.value = val
  },
})

const availableCurrencyOptions = computed<CurrencyOption[]>(() => {
  // If settings.currencies is set, only return options included by their identifier code
  if (props.settings?.currencies) {
    return currencyOptions.filter((el) => props.settings.currencies?.includes(el.code))
  }
  return currencyOptions
})

const durationOptions = computed(() => {
  return Object.values(CurrencyDurationOption).map((option) => {
    return {
      label: t("duration." + option),
      value: option,
    }
  })
})

const handleSubmitEnter = (e: KeyboardEvent) => {
  if (e.key === "Enter") {
    emit("submit-enter")
  }
}

watch(
  [ () => currency.value, () => localAmount.value, () => duration.value ],
  ([ currencyVal, amountVal, durationVal ]) => {
    if (stopPropagation.value) return

    if (amountVal === null || !currencyVal?.code) {
      emit("update:model-value", null)
      return
    }

    amountVal = `${amountVal}`

    let res = null

    if (amountVal && currencyVal.code) res = currencyVal.code + ";" + amountVal
    if (!props.hasDuration) {
      emit("update:model-value", res)
      return
    }
    if (amountVal && durationVal && props.hasDuration) res += ";" + durationVal
    emit("update:model-value", res)

  },
)

const handleFocus = () => {
  emit("focus", {
    target: { id: props.id },
  })
}

const handleBlur = () => {
  emit("blur", {
    target: { id: props.id },
  })
}

const reset = () => {
  localAmount.value = null
  currency.value = currencyOptions?.[0]
  duration.value = CurrencyDurationOption.monthly
}

const formatPlaceholder = computed(() => {
  // Take sample amount of 0 and apply formatMultifieldNumber with settings format
  return formatMultifieldNumber(0, props.format, MultiFieldType.currency, locale.value)
})

defineExpose({ reset })

</script>

<template>
  <div
    class="relative inline-block rounded-md"
    :class="[
      {
        'bg-gray-100' : !props.disabled && props.style === 'gray',
        'bg-white border border-gray-300' : props.style === 'white',
        'hover:bg-gray-100 focus-within:bg-gray-100 focus-within:ring-0' : !props.disabled && !['gray', 'slate'].includes(props.style),
        'text-gray-400 bg-gray-50 cursor-not-allowed' : props.disabled && props.style === 'gray',
      },
      [
        props.style === 'gray'
          ? 'focus-within:ring-2 focus-within:ring-indigo-500 py-1'
          :
            $props.style === 'slate'
              ? 'bg-slate-900 focus-within:bg-slate-950 py-1'
              : 'bg-transparent p-px',
      ]
    ]"
  >
    <label
      v-if="props.label"
      class="text-xs"
    >
      {{ props.label }}
    </label>
    <div
      :id="props.id"
      class="absolute inset-0 inline-flex items-center text-base text-left sm:text-sm"
    >
      <Listbox
        v-model="currency"
        as="div"
        :disabled="disabled"
      >
        <div>
          <ListboxButton
            class="pr-1.5 pl-2 w-[44px] bg-transparent rounded-r-none focus:z-10"
            :class="[props.style === 'plain' ? 'disabled:bg-transparent btn-listbox-plain py-1' : props.style === 'slate' ? 'btn-listbox-slate' : 'btn-listbox-plain', readonly ? 'pr-0 pl-0' : '']"
          >
            <span class="block truncate">
              {{ currency?.code }}
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="left-auto right-auto listbox-options w-fit"
              @blur="handleBlur"
              @focus="handleFocus"
            >
              <ListboxOption
                v-for="option, optionIdx in availableCurrencyOptions"
                :key="option.code"
                v-slot="{ active, selected }"
                as="template"
                :value="option"
              >
                <li>
                  <div :class="[optionIdx === 4 && !props.settings ? 'border-t border-t-slate-700 my-2' : '']" />
                  <div :class="[active ? 'bg-gray-700' : '', 'listbox-option py-1']">
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ option.code }} ({{ option.symbol }})
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-2',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <input
        v-model="localAmount"
        :class="[
          props.style === 'plain' ? 'p-1' : '',
          props.style === 'slate' ? 'placeholder-slate-400' : 'placeholder-gray-400',
        ]"
        class="w-full pr-1.5 text-base text-right bg-transparent border-none outline-none sm:text-sm focus:ring-0 ring-0 disabled:cursor-not-allowed"
        type="number"
        min="-999999999999.99"
        max="999999999999.99"
        step="0.01"
        size="1"
        :lang="locale"
        :placeholder="formatPlaceholder || $t('common.amount')"
        :disabled="disabled"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup.enter="handleSubmitEnter"
      >
      <Listbox
        v-if="hasDuration"
        v-model="duration"
        as="div"
        :disabled="disabled"
      >
        <div>
          <ListboxButton
            class="pr-1.5 pl-2 bg-transparent rounded-l-none focus:z-10"
            :class="[props.style === 'plain' ? 'disabled:bg-transparent btn-listbox-plain py-1' : props.style === 'slate' ? 'btn-listbox-slate' : 'btn-listbox-plain', readonly ? 'pr-0 pl-0' : '']"
          >
            <span class="block">
              {{ $t('duration.' + duration) }}
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="right-0 left-auto listbox-options w-fit"
              @blur="handleBlur"
              @focus="handleFocus"
            >
              <ListboxOption
                v-for="option, optionIdx in durationOptions"
                :key="option.value"
                v-slot="{ active, selected }"
                as="template"
                :value="option.value"
              >
                <li>
                  <div :class="[optionIdx === 4 && !props.settings ? 'border-t border-t-slate-700 my-2' : '']" />
                  <div :class="[active ? 'bg-gray-700' : '', 'listbox-option py-1']">
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ option.label }}
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-2',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div
      class="invisible pl-1.5 pr-3 py-1 inline-block text-base sm:text-sm leading-relaxed whitespace-pre ml-[44px]"
      :class="duration && hasDuration ? 'mr-2' : ''"
    >
      {{ localAmount?.toString() || formatPlaceholder || $t('common.amount') }}
      <span
        v-if="hasDuration"
        class="ml-1"
      >
        {{ duration ? $t(`duration.${duration}`) : '' }}
      </span>
    </div>
    <div
      v-if="validationError"
      class="mt-1 text-xs text-red-500"
    >
      {{ validationError }}
    </div>
  </div>
</template>
