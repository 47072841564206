<script lang="ts" setup>
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { TrashIcon, PencilIcon } from "@heroicons/vue/24/outline"
import { ArrowUturnLeftIcon } from "@heroicons/vue/24/solid"

// internal
import { ConditionOperatorSelect, ConditionValue, ItemCombobox, FormInputErrors, Dropdown, DropdownLink } from "~/components"
import { useDynamicFieldStore } from "~/stores"
import { Condition, ConditionOperator, Rule, DynamicField } from "~/types"
import { MenuButton } from "@headlessui/vue"

interface Props {
  rule: Rule
  ruleIdx: number
  ruleCount?: number
  conditionUuid?: Condition["uuid"]
  isLoadingCondition: boolean
  errors?: Record<string, string[]>
  isEditing?: boolean
  readOnly?: boolean
  style?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    errors: null,
    isEditing: false,
    readOnly: false,
    style: "slate",
    conditionUuid: null,
    ruleCount: 1,
  },
)

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFields } = storeToRefs(dynamicFieldStore)

const selectedDynamicField = computed<DynamicField>(() => {
  // Return array of dynamicFields
  return dynamicFields.value.find((dynamicField) => dynamicField.ref_uuid === props.rule.dynamicFieldRefUuid)
})

const setSelectedDynamicField = (dynamicField: DynamicField, localRuleIdx: number) => {
  const copyOfLocalRule = Object.assign({ ...props.rule })
  // Adjust dynamicFieldRefUuid
  copyOfLocalRule.dynamicFieldRefUuid = dynamicField?.ref_uuid
  // Adjust operator
  copyOfLocalRule.operator = ConditionOperator.EQUAL
  // Adjust matchValue
  copyOfLocalRule.matchValue = ""
  // Set dynamicFieldRefUuid of corresponding rule
  updateRule(localRuleIdx, copyOfLocalRule)
}

const setSelectedOperator = (operator: ConditionOperator, localRuleIdx: number) => {
  const copyOfLocalRule = Object.assign({ ...props.rule })
  // Adjust operator
  copyOfLocalRule.operator = operator
  // Adjust matchValue
  if ([ ConditionOperator.EMPTY, ConditionOperator.NOT_EMPTY ].includes(operator)) {
    copyOfLocalRule.matchValue = ""
  }
  updateRule(localRuleIdx, copyOfLocalRule)
}

const setSelectedMatchValue = (matchValue: string, localRuleIdx: number) => {
  // Set field values of corresponding rule in fieldArray
  updateRule(localRuleIdx, matchValue, "matchValue")
}

const setSelectedParentOperator = (parentOperator: "&&" | "||", localRuleIdx: number) => {
  // Set field values of corresponding rule in fieldArray
  updateRule(localRuleIdx, parentOperator, "parentOperator")
}

const updateRule = (ruleIdx: number, val: any, fieldName: keyof Rule = null) => {
  emit("update:rule", { ruleIdx, val, fieldName })
}

const removeRule = () => {
  emit("remove")
}

const toggleEditing = () => {
  emit("toggle-editing")
}

// Define emit for update:rule type using type definition emit
const emit = defineEmits<{
  (e: "update:rule", payload: { ruleIdx: number, val: any, fieldName: keyof Rule }): void
  (e: "remove"): void
  (e: "toggle-editing"): void
}>()

</script>
<template>
  <div>
    <div
      class="relative text-xs"
      :class="[style === 'slate' ? 'bg-slate-800 text-white px-4 -mx-4' : ' border-b bg-gray-100 text-gray-900 mx-1 px-3 border-l border-r border-gray-300', ruleIdx === 0 && style === 'gray' ? 'rounded-t-md border-t' : '']"
    >
      <div
        v-if="ruleIdx !== 0"
        class="absolute left-2"
        :class="style === 'slate' ? '-top-2.5' : '-top-1.5'"
      >
        <Dropdown
          menu-classes="text-left"
          width="w-fit"
          align="left"
          theme="slate"
        >
          <template #trigger>
            <MenuButton
              class="flex items-center justify-center font-semibold text-teal-500 uppercase rounded-full"
              :class="style === 'slate' ? 'bg-slate-800 ring-1 ring-slate-700 ring-offset-1 ring-offset-slate-800 text-xs h-5 px-2' : 'bg-teal-600 ring-teal-600 ring-1 text-white text-[10px] h-3 px-1'"
            >
              {{ $t('conditions.operators.' + rule.parentOperator) }}
            </MenuButton>
          </template>

          <template #content>
            <DropdownLink
              as="button"
              @click="setSelectedParentOperator(ConditionOperator.AND as '&&' | '||', ruleIdx)"
            >
              <span class="text-xs font-semibold tracking-wide text-teal-500 uppercase">
                {{ $t('conditions.operators.' + ConditionOperator.AND) }}
              </span>
            </DropdownLink>
            <DropdownLink
              as="button"
              @click="setSelectedParentOperator(ConditionOperator.OR as '&&' | '||', ruleIdx)"
            >
              <span class="text-xs font-semibold tracking-wide text-teal-500 uppercase">
                {{ $t('conditions.operators.' + ConditionOperator.OR) }}
              </span>
            </DropdownLink>
          </template>
        </Dropdown>
      </div>
      <div
        class="flex items-center justify-between gap-6 shrink-0"
        :class="style === 'slate' ? 'py-4' : 'py-2'"
      >
        <template v-if="isEditing">
          <div class="flex-grow">
            <div>
              <label
                class="font-medium"
                for="condition-dynamic-field"
              >
                {{ $t('conditions.dataSource') }}
                <span class="text-teal-500">
                  *
                </span>
              </label>
              <div
                class="mt-1"
              >
                <ItemCombobox
                  :selected-item="selectedDynamicField"
                  :disabled="isLoadingCondition"
                  :cancelable="false"
                  layout="slate"
                  @update:selected-item="setSelectedDynamicField($event, ruleIdx)"
                />
              </div>
              <div
                v-if="errors?.[`rules[${ruleIdx}].dynamicFieldRefUuid`] || errors?.[`rules.rules[${ruleIdx}].dynamicFieldRefUuid`]"
              >
                <FormInputErrors
                  layout="slate"
                  :errors="errors?.[`rules[${ruleIdx}].dynamicFieldRefUuid`] || errors?.[`rules.rules[${ruleIdx}].dynamicFieldRefUuid`]"
                />
              </div>
            </div>
            <div
              v-if="selectedDynamicField?.uuid"
              class="mt-2"
            >
              <label
                class="font-medium"
                for="condition-match-value"
              >
                {{ $t('conditions.matchValue') }}
                <span class="text-teal-500">
                  *
                </span>
              </label>

              <div
                class="mt-1 text-sm"
              >
                <div
                  class="relative flex items-center w-full max-w-full"
                >
                  <div>
                    <ConditionOperatorSelect
                      :id="'match-operator_' + conditionUuid + '_' + ruleIdx"
                      :model-value="rule.operator"
                      :type="selectedDynamicField?.type"
                      class="mr-3"
                      :disabled="isLoadingCondition"
                      @update:model-value="(value) => setSelectedOperator(value, ruleIdx)"
                    />
                  </div>
                  <div v-show="![ConditionOperator.EMPTY, ConditionOperator.NOT_EMPTY].includes(rule.operator)">
                    <ConditionValue
                      :model-value="rule.matchValue"
                      :dynamic-field="selectedDynamicField"
                      :has-error="!!(errors?.[`rules[${ruleIdx}].matchValue`] || errors?.[`rules[${ruleIdx}].operator`] || errors?.[`rules.rules[${ruleIdx}].operator`] || errors?.[`rules.rules[${ruleIdx}].matchValue`])"
                      class="grow max-w-[260px]"
                      @update:model-value="(value) => setSelectedMatchValue(value, ruleIdx)"
                    />
                  </div>
                </div>
                <div
                  v-if="errors?.[`rules[${ruleIdx}].matchValue`] || errors?.[`rules[${ruleIdx}].operator`] || errors?.[`rules.rules[${ruleIdx}].operator`] || errors?.[`rules.rules[${ruleIdx}].matchValue`]"
                >
                  <FormInputErrors
                    layout="slate"
                    :errors="errors?.[`rules[${ruleIdx}].matchValue`] || errors?.[`rules.rules[${ruleIdx}].matchValue`]"
                  />
                  <FormInputErrors
                    layout="slate"
                    :errors="errors?.[`rules[${ruleIdx}].operator`] || errors?.[`rules.rules[${ruleIdx}].operator`]"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center flex-grow gap-4 max-w-[80%]">
            <div
              class="max-w-full font-mono tracking-tighter break-words"
              :class="style === 'slate' ? 'font-semibold' : 'font-medium'"
            >
              {{ selectedDynamicField?.name || $t('common.n/a') }}
              <span
                class="font-normal"
                :class="style === 'slate' ? 'text-teal-500' : 'text-teal-700'"
              >{{ $t('conditions.operators.' + rule.operator) }}</span>
              {{ rule.matchValue || $t('common.n/a') }}
            </div>
          </div>
        </template>
        <div
          v-if="!readOnly"
          class="flex items-center gap-1.5"
        >
          <button
            type="button"
            class="flex items-center justify-center w-6 h-6 p-0 rounded-full btn-plain hover:text-white focus:text-white focus:ring-slate-600 focus:ring-offset-0 shrink-0"
            @click="toggleEditing"
          >
            <PencilIcon
              v-if="!isEditing"
              class="w-4 h-4"
            />
            <ArrowUturnLeftIcon
              v-else
              class="w-4 h-4"
            />
          </button>

          <button
            v-if="ruleCount > 1"
            type="button"
            class="flex items-center justify-center w-6 h-6 p-0 text-red-700 rounded-full btn-plain hover:text-red-500 focus:ring-offset-0 focus:ring-red-900 focus:text-red-500 shrink-0"
            @click="removeRule"
          >
            <TrashIcon class="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
