<script setup lang="ts">
// external
import { ref } from "vue"

import { storeToRefs } from "pinia"

import { captureException } from "@sentry/vue"
import { useI18n } from "vue-i18n"

// internal
import { Checkbox, DialogModal, EmptyState, FileInput, SpinLoader, SubscriptionUpgradeBox } from "~/components"
import { useCommentStore, useDocumentStore, useEditorStore, useImageStore, useNotificationStore, useSharedStore, useUserStore } from "~/stores"
import { Comment, CrudContext, JuneEvents, MammothComment, TrackedChange } from "~/types"
import { Editor } from "~/editor"

import { getTiptapJsonFromWordFile } from "./wordImport"
import { JSONContent } from "@tiptap/core"
import { orderBy, uniqBy } from "lodash-es"
import { computed } from "vue"
import { DiffIcon, UnlinkIcon } from "~/icons"
import { LinkIcon, MinusIcon, PlusIcon } from "@heroicons/vue/20/solid"
import { ChatBubbleLeftIcon } from "@heroicons/vue/20/solid"
import { formatDateAndTime, getInitialsFromName, getUserRepresentation, trackEventInJune } from "~/utils"
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { watch } from "vue"

const imageStore = useImageStore()

const notificationStore = useNotificationStore()
const { notify } = notificationStore

const commentStore = useCommentStore()
const { comments } = storeToRefs(commentStore)

const documentStore = useDocumentStore()
const { currentDocument, documentEditorSession, documentEditorSessionObjectSignature, keepAliveDocumentEditorSessionTimerId, mdu } = storeToRefs(documentStore)
const { destroyDocumentEditorSession, createDocumentEditorSession, saveDocumentEditorContent, setIsLoadingEditorSession, removeIsDirtyHandler, setDocumentEditorSession } = documentStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const { t } = useI18n()

const editorStore = useEditorStore()
const { setIsDocxImportModalOpen, setDroppedFile } = editorStore
const { editor, isDocxImportModalOpen, droppedFile } = storeToRefs(editorStore)

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const isImportingDocx = ref(false)

const wordFile = ref(null)

const setFiles = (files) => {
  if (files[0].name.endsWith(".doc")) {
    notify({ type: "error", title: t("editor.onlyDocxSupported") })

    return
  }

  wordFile.value = files[0]
}

const resultComments = ref<MammothComment[]>([])
const resultChanges = ref<TrackedChange[]>([])
const editorContentAfterChanges = ref<JSONContent>(null)
const editorContentBeforeChanges = ref<JSONContent>(null)
const editorContentProposal = ref<JSONContent>(null)
const isActiveReviewResult = ref<boolean>(false)

const handleDocxImport = async () => {

  if (isActiveReviewResult.value) {
    handleConfirmImport()
    return
  }

  isImportingDocx.value = true

  try {
    const {
      tiptapJsonWithChanges,
      tiptapJsonBeforeChanges,
      tiptapJsonProposal,
      changes: foundChanges,
      comments: foundComments,
    } = await getTiptapJsonFromWordFile(
      {
        file: wordFile.value || droppedFile.value,
        imageStore,
        includeComments: includeComments.value,
        includeTrackedChanges: includeTrackedChanges.value,
      },
    )

    editorContentAfterChanges.value = tiptapJsonWithChanges
    editorContentBeforeChanges.value = tiptapJsonBeforeChanges
    editorContentProposal.value = tiptapJsonProposal

    setDroppedFile(null)

    if (((foundComments?.length > 0 && includeComments.value) || (foundChanges?.length > 0 && includeTrackedChanges.value)) && crudContext.value === CrudContext.document) {
      resultComments.value = foundComments
      resultChanges.value = foundChanges

      isActiveReviewResult.value = true
    } else {
      await handleConfirmImport()
    }

  } catch (e) {
    console.error("Error importing docx file: ", e)

    notify({ type: "error", title: t("editor.errorImportingDocx") })

    captureException(e)
  } finally {
    isImportingDocx.value = false
  }
}

const handleConfirmImport = async () => {

  let canExecuteImport = true

  const content = includeTrackedChanges.value ? editorContentBeforeChanges.value : editorContentAfterChanges.value

  // If we are in document context, we need to check for an active editor session, possible create one and store the new content; we also need to emit a warning if there are conditions or comments;

  if (crudContext.value === CrudContext.document) {

    isImportingDocx.value = true

    canExecuteImport = false

    const hasActiveEditorSession = !!(documentEditorSession.value?.uuid && documentEditorSession.value?.object_signature && documentEditorSession.value?.object_signature === documentEditorSessionObjectSignature.value && documentEditorSession.value?.document_user_uuid === mdu.value?.uuid)

    let destroyRes: boolean | void = true

    if (hasActiveEditorSession) {
      destroyRes = await destroyDocumentEditorSession(currentDocument.value?.uuid, documentEditorSession.value.uuid)
      if (keepAliveDocumentEditorSessionTimerId.value) clearInterval(keepAliveDocumentEditorSessionTimerId.value)
    }


    if (destroyRes) {
      setIsLoadingEditorSession(true)
      await createDocumentEditorSession(currentDocument.value?.uuid)
      canExecuteImport = true
    }

  }

  if (!canExecuteImport) return

  let saveEditorContentRes = true
  let saveCommentsRes = true
  let saveProposalsRes = true

  if (crudContext.value === CrudContext.document) {
    saveEditorContentRes = false


    const promiseHolder = []
    // Create new comments
    if (resultComments.value.length && includeComments.value) {
      const filteredComments = resultComments.value.filter((el, idx) => !excludedComments.value?.includes(idx))
      saveCommentsRes = false
      // We first need to sort comments so that those without a parentId come first
      const sortedComments = orderBy(filteredComments, [ "parentId" ], [ "desc" ])
      sortedComments?.map(async (comment) => {
        const previousId = comment.commentId
        let commentPayload: Partial<Comment> = {
          html: comment.html,
          type: "comment",
          scope: "internal_and_external",
        }
        let parentUuid = comment.parentId ?? null
        // If parentUuid is type number or can be parsed to a number, we need to find the right parentUuid
        if (typeof parentUuid === "number" || !isNaN(parseInt(parentUuid))) {
          const matchingComment = sortedComments.find((el) => el.commentId === parentUuid)
          if (matchingComment) {
            parentUuid = getLinkedComment(matchingComment)?.uuid
          }
        }
        if (parentUuid) commentPayload = { ...commentPayload, comment_uuid: parentUuid }
        // Check if the comment has a matching comment in the document
        const matchingComment = getLinkedComment(comment)
        let res = null
        if (matchingComment) {
          // If so, we need to update the existing comment with a new uuid and the new content
          res = await commentStore.updateComment(currentDocument.value?.uuid, matchingComment.uuid, commentPayload)
        } else {
          // Otherwise, create one
          res = commentStore.createComment(currentDocument.value?.uuid, commentPayload)
        }
        promiseHolder.push(res)
        // Once res is resolved, we need to set the id of res to the comments that have previousId set as their parentId
        if (res && previousId) {
          const matchingComment = sortedComments.find((el) => el.parentId === previousId)
          if (matchingComment) {
            matchingComment.parentId = res.uuid
          }
        }
        return res
      })

      const createCommentsRes = await Promise.all(promiseHolder)
      if (createCommentsRes) {
        saveCommentsRes = true
      }
    }

    if (!saveCommentsRes) {
      notify({ type: "error", title: t("editor.errorSavingComments") })
      return
    }

    // Create new proposals
    if (resultChanges.value.length && includeTrackedChanges.value) {
      saveProposalsRes = false
      // We need to reject the existing proposals, since the new import has changed uuids
      const updateExistingProposalsRes = await Promise.all(comments.value?.filter((el) => el.type === "proposal" && !el.is_resolved && ![ "approved", "rejected" ].includes(el.proposal_status)).map((existingProposal) => {
        return commentStore.updateComment(currentDocument.value?.uuid, existingProposal.uuid, { proposal_status: "rejected", is_resolved: true })
      }))

      if (!updateExistingProposalsRes) {
        notify({ type: "error", title: t("editor.errorUpdatingProposals") })
        return
      }

      const filteredChanges = resultChanges.value.filter((el, idx) => !excludedChanges.value?.includes(idx))
      const uniqChanges = uniqBy(filteredChanges, (el) => el.uuid)
      const createProposalsPromiseHolder = []

      // Then, we can create the new proposals
      uniqChanges.map((change) => {
        // remove wrapping "doc" node arround the prosemirror_data payload
        const prosemirror_data = { type: "doc", content: [ change.narrowedParentProposal ] }
        if (!prosemirror_data) {
          console.warn("No prosemirror_data found in change doc node", change)
          return
        }
        const proposalPayload: Partial<Comment> = {
          prosemirror_data: prosemirror_data,
          prosemirror_data_uuid: change.uuid,
          type: "proposal",
          scope: "internal_and_external",
        }
        const res = commentStore.createComment(currentDocument.value?.uuid, proposalPayload)
        createProposalsPromiseHolder.push(res)
      })

      const createProposalsRes = await Promise.all(createProposalsPromiseHolder)
      if (createProposalsRes) {
        saveProposalsRes = true
      }
    }

    if (!saveProposalsRes) {
      notify({ type: "error", title: t("editor.errorSavingProposals") })
      return
    }

  } else {
    trackEventInJune(JuneEvents.EDITOR_DOCX_IMPORTED_TEMPLATE)
  }

  editor.value
    .chain()
    .setMeta("forceDeleteSelectionWithIssues", true)
    .setContent(content, true)
    .fitAllTablesToEditorWidth()
    .run()

  if (crudContext.value === CrudContext.document) {
    saveEditorContentRes = await saveDocumentEditorContent(currentDocument.value?.uuid, documentEditorSession.value.uuid)
  }

  // set editor session to null as it was destroyed in the BE
  if (saveEditorContentRes && documentEditorSession.value?.uuid && documentEditorSession.value?.object_signature && documentEditorSession.value?.object_signature === documentEditorSessionObjectSignature.value && documentEditorSession.value?.document_user_uuid === mdu.value?.uuid) {
    removeIsDirtyHandler()
    setIsLoadingEditorSession(false)
    setDocumentEditorSession(null)
    if (keepAliveDocumentEditorSessionTimerId.value) clearInterval(keepAliveDocumentEditorSessionTimerId.value)
  }

  close()
}

const getLinkedComment = (comment: MammothComment) => {
  let match = comments.value?.find((el) => el.uuid && (el.uuid === (comment.commentId as any) || el.uuid === comment.commentId, 0))
  if (!match) {
    // Try matching by author name and date
    // author name needs to be mapped to getUserRepresentation of document user maped by the respective comment created_by_document_user_uuid or the name resulting from created_by_document_user_first_name and created_by_document_user_last_name
    const authorName = comment.authorName
    const date = comment.date
    match = comments.value?.find(
      (el) => (
        getUserRepresentation(users.value?.find((u) => u.uuid === el.created_by_document_user_uuid)) === authorName ||
      (el.created_by_document_user_first_name && el.created_by_document_user_last_name && `${el.created_by_document_user_first_name} ${el.created_by_document_user_last_name}` === authorName)
      ) &&
      new Date(el.created_at).toISOString() === new Date(date).toISOString(),
    )
    if (!match) {
      // Word makes the date string less precise on save, so we need to strip seconds and compare again
      const res = comments.value?.filter(
        (el) => {
          const dateWithSecondsSetToZero = new Date(el.created_at)
          dateWithSecondsSetToZero.setSeconds(0)
          return (
            (getUserRepresentation(users.value?.find((u) => u.uuid === el.created_by_document_user_uuid)) === authorName ||
        (el.created_by_document_user_first_name && el.created_by_document_user_last_name && `${el.created_by_document_user_first_name} ${el.created_by_document_user_last_name}` === authorName)) &&
        dateWithSecondsSetToZero.toISOString() === new Date(date).toISOString())
        },
      )
      if (res.length > 1) return
      match = res[0]
    }
  }
  return match
}

const changes = computed(() => {
  const resultCopy = [ ...resultChanges.value ]
  // Adjust results so that every item that has a same uuid sibling somewhere in the array is changed to type 'multi'
  resultCopy.forEach((change) => {
    const siblings = resultCopy.filter((el) => el.uuid === change.uuid)
    if (siblings.length > 1) {
      siblings.forEach((sibling) => {
        sibling.type = "multi"
      })
    }
  })
  const uniqChanges = uniqBy(resultCopy, (el) => el.uuid)
  // Wrap parentProposal and narrowedParentProposal in JSONContent
  return uniqChanges.map((change) => {
    return {
      ...change,
      parentProposal: { type: "doc", content: [ change.parentProposal ] },
      narrowedParentProposal: { type: "doc", content: [ change.narrowedParentProposal ] },
    }
  })
})

const close = () => {
  editor.value.commands.focus()
  setIsDocxImportModalOpen(false)
  setDroppedFile(null)
}

const includeTrackedChanges = ref(true)
const includeComments = ref(true)

watch(crudContext, (newVal) => {
  close()
  includeTrackedChanges.value = newVal === CrudContext.template ? false : true
  includeComments.value = newVal === CrudContext.template ? false : true
})

const excludedChanges = ref<number[]>([])
const excludedComments = ref<number[]>([])
const toggleExcludedChange = (changeIdx: number) => {
  if (excludedChanges.value.includes(changeIdx)) {
    excludedChanges.value = excludedChanges.value.filter((el) => el !== changeIdx)
  } else {
    excludedChanges.value = [ ...excludedChanges.value, changeIdx ]
  }
}
const toggleExcludedComment = (commentIdx: number) => {
  if (excludedComments.value.includes(commentIdx)) {
    excludedComments.value = excludedComments.value.filter((el) => el !== commentIdx)
  } else {
    excludedComments.value = [ ...excludedComments.value, commentIdx ]
  }
}

</script>

<template>
  <DialogModal
    :show="isDocxImportModalOpen"
    show-close-button
    :max-width="!isActiveReviewResult ? 'md' : '3xl'"
    @close="close"
  >
    <template #title>
      <h2 class="mb-2 font-semibold text-black">
        {{ $t('editor.importDocX') }}
      </h2>
    </template>

    <template #content>
      <div
        v-if="crudContext === CrudContext.document"
        class="absolute z-50 overflow-hidden rounded-md inset-2 backdrop-blur-sm"
      >
        <SubscriptionUpgradeBox
          :text="$t('accountSettings.billing.featureRequiresUpgrade')"
          class="absolute inset-0"
        />
      </div>
      <div v-if="!isActiveReviewResult">
        <div
          v-if="droppedFile"
          class="mb-4"
        >
          {{ droppedFile.name }}
        </div>

        <FileInput
          v-if="!droppedFile"
          class="text-sm"
          :placeholder="$t('editor.dropWordFile')"
          accept=".docx,.DOCX"
          allowed-extensions="/\.docx$/i"
          :upload-files="false"
          @set-files="setFiles"
        />
        <template v-if="crudContext === CrudContext.document">
          <div class="mt-2">
            <SwitchGroup
              as="div"
              class="flex items-start gap-6 py-2"
            >
              <SwitchLabel
                as="span"
                class="text-sm text-gray-400 grow"
                passive
              >
                <h3 class="mb-1 text-sm font-semibold text-gray-700">
                  {{ $t('documents.importCommentsFromDocx') }}
                </h3>
                {{ $t('documents.importCommentsFromDocxDescription') }}
              </SwitchLabel>
              <Switch
                v-model="includeTrackedChanges"
                :class="[
                  includeTrackedChanges
                    ? 'bg-indigo-600'
                    : 'bg-gray-300',
                  'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    includeTrackedChanges
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                />
              </Switch>
            </SwitchGroup>
          </div>
          <div>
            <SwitchGroup
              as="div"
              class="flex items-start gap-6 py-2"
            >
              <SwitchLabel
                as="span"
                class="text-sm text-gray-400 grow"
                passive
              >
                <h3 class="mb-1 text-sm font-semibold text-gray-700">
                  {{ $t('documents.importTrackedChangesFromDocx') }}
                </h3>
                {{ $t('documents.importTrackedChangesFromDocxDescription') }}
              </SwitchLabel>
              <Switch
                v-model="includeComments"
                :class="[
                  includeComments
                    ? 'bg-indigo-600'
                    : 'bg-gray-300',
                  'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    includeComments
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                />
              </Switch>
            </SwitchGroup>
          </div>
        </template>
      </div>
      <div v-else>
        <div class="px-6 py-4 -mx-6 -my-4 bg-gray-100">
          <h3 class="font-semibold text-black">
            {{ $t('editor.reviewResult') }}
          </h3>
          <div
            v-if="resultComments.length"
            class="flex flex-col space-y-6 divide-y divide-y-gray-400"
          >
            <div
              v-for="comment, commentIdx in resultComments.filter((el) => !el.parentId)"
              :key="commentIdx"
              class="flex items-start gap-4 px-6 pt-4 -mx-6"
            >
              <div class="flex items-center justify-center px-1">
                <Checkbox
                  :checked="!excludedComments.includes(commentIdx)"
                  :value="commentIdx"
                  :element-id="`importComment_${commentIdx}`"
                  class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  @update:checked="toggleExcludedComment(commentIdx)"
                />
              </div>
              <div class="flex flex-col flex-grow">
                <div class="flex items-center gap-2 mb-2 text-xs text-gray-500">
                  <span>
                    <ChatBubbleLeftIcon
                      class="w-3.5 h-3.5 text-yellow-500"
                    />
                  </span>
                  <span class="font-medium">{{ $t('editor.detected') }} {{ $t('editor.comment') }}</span>
                  <span class="truncate">{{ comment.uuid }}</span>
                </div>
                <div
                  class="p-3 pb-4 text-sm text-gray-900 bg-white rounded-md shadow-lg"
                >
                  <div class="flex items-center justify-between gap-2 text-xs">
                    <div class="flex items-center gap-2">
                      <span class="flex items-center justify-center w-8 h-8 text-sm font-medium text-indigo-700 bg-indigo-200 rounded-full">
                        {{ comment.authorInitials.substring(0, 2) }}
                      </span>
                      <span class="font-medium">{{ comment.authorName }}</span>
                      <template v-if="comment.date">
                        <span class="text-gray-500">·</span>
                        <span class="text-gray-500">{{ formatDateAndTime(comment.date) }}</span>
                      </template>
                    </div>

                    <div
                      v-if="getLinkedComment(comment)"
                      class="flex items-center gap-2 text-indigo-500"
                    >
                      <LinkIcon class="w-3.5 h-3.5" />
                      <span>{{ $t('editor.linkedOriginal') }}</span>
                      <span>({{ getLinkedComment(comment).uuid }})</span>
                    </div>
                    <div
                      v-else
                      class="flex items-center gap-2 text-gray-400"
                    >
                      <UnlinkIcon class="w-3.5 h-3.5" />
                      <span>{{ $t('editor.linkedOriginalNotFound') }}</span>
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
                  <div
                    class="pl-6 mt-1 ml-4"
                    :class="resultComments.filter((el) => el.parentId === comment.commentId)?.length ? 'border-l-2 border-gray-200 border-dotted' : ''"
                    v-html="comment.html"
                  />
                  <!-- eslint-enable vue/no-v-html vue/html-self-closing -->
                  <div
                    v-if="resultComments.filter((el) => el.parentId === comment.commentId)?.length"
                    class="relative flex flex-col gap-2 pt-4 pl-6 ml-4"
                  >
                    <div
                      v-for="childComment, childCommentIdx in resultComments.filter((el) => el.parentId === comment.commentId)"
                      :key="childCommentIdx"
                      class="relative text-sm text-gray-900"
                    >
                      <div
                        class="absolute -top-3.5 -left-6 border-l-2 border-gray-200 border-dotted"
                        :class="childCommentIdx === resultComments.filter((el) => el.parentId === comment.commentId)?.length - 1 ? 'h-6' : 'bottom-0'"
                      />
                      <div class="flex items-center justify-between gap-2 text-xs">
                        <div class="relative flex items-center gap-2">
                          <div class="absolute w-6 border-t-2 border-gray-200 border-dotted top-3 -left-6" />
                          <span class="flex items-center justify-center w-6 h-6 text-[10px] font-medium text-indigo-700 bg-indigo-200 rounded-full">
                            {{ childComment.authorInitials }}
                          </span>
                          <span class="font-medium">{{ childComment.authorName }}</span>
                          <template v-if="childComment.date">
                            <span class="text-gray-500">·</span>
                            <span class="text-gray-500">{{ formatDateAndTime(childComment.date) }}</span>
                          </template>
                        </div>
                        <div
                          v-if="getLinkedComment(childComment)"
                          class="flex items-center gap-2 text-indigo-500"
                        >
                          <LinkIcon class="w-3.5 h-3.5" />
                          <span>{{ $t('editor.linkedOriginal') }}</span>
                          <span>({{ getLinkedComment(childComment).uuid }})</span>
                        </div>
                        <div
                          v-else
                          class="flex items-center gap-2 text-gray-400"
                        >
                          <UnlinkIcon class="w-3.5 h-3.5" />
                          <span>{{ $t('editor.linkedOriginalNotFound') }}</span>
                        </div>
                      </div>
                      <!-- eslint-disable vue/no-v-html -->
                      <div
                        class="mt-1 ml-8"
                        v-html="childComment.html"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="changes.length"
            class="flex flex-col space-y-6 divide-y divide-y-gray-400"
          >
            <div class="hidden">
              <Editor
                v-model:editor-content="editorContentProposal"
                :is-order-representation-host="true"
                :document-commenting="false"
                :editing="false"
                :show-toolbar="false"
                document-type="document"
                editor-context="proposal"
                :dynamic-fields="[]"
                :parties="[]"
              />
            </div>
            <div
              v-for="change, changeIdx in changes"
              :key="change.uuid"
              class="flex items-start gap-4 px-6 pt-4 -mx-6"
            >
              <div class="flex items-center justify-center px-1">
                <Checkbox
                  :checked="!excludedChanges.includes(changeIdx)"
                  :value="changeIdx"
                  :element-id="`importChange_${changeIdx}`"
                  class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  @update:checked="toggleExcludedChange(changeIdx)"
                />
              </div>
              <div class="flex flex-col flex-grow">
                <div class="flex items-center gap-2 mb-2 text-xs text-gray-500">
                  <span v-if="change.type === 'insertion'">
                    <PlusIcon
                      class="w-4 h-4 text-green-500"
                    />
                  </span>
                  <span v-else-if="change.type === 'deletion'">
                    <MinusIcon
                      class="w-4 h-4 text-red-500"
                    />
                  </span>
                  <span
                    v-else
                    class="flex items-center"
                  >
                    <PlusIcon
                      class="w-4 h-4 text-green-500"
                    />
                    <MinusIcon
                      class="w-4 h-4 text-red-500"
                    />
                  </span>
                  <span class="font-medium">{{ $t('editor.detected') }} {{ change.type === 'insertion' ? $t('editor.insertion') : change.type === 'deletion' ? $t('editor.deletion') : $t('editor.change') }}</span>
                  <span class="truncate">{{ change.uuid }}</span>
                </div>
                <div
                  class="z-10 bg-white rounded-md shadow-lg"
                >
                  <div class="flex items-center gap-2 px-3 pt-3 text-xs">
                    <span class="flex items-center justify-center w-8 h-8 text-sm font-medium text-indigo-700 bg-indigo-200 rounded-full">
                      {{ getInitialsFromName(change.authorName) }}
                    </span>
                    <span class="font-medium">{{ change.authorName }}</span>
                    <template v-if="change.date">
                      <span class="text-gray-500">·</span>
                      <span class="text-gray-500">{{ formatDateAndTime(change.date) }}</span>
                    </template>
                  </div>
                  <Editor
                    v-model:editor-content="change.narrowedParentProposal"
                    :document-commenting="false"
                    :editing="false"
                    :show-toolbar="false"
                    document-type="document"
                    editor-context="proposal"
                    :dynamic-fields="[]"
                    :parties="[]"
                  />
                </div>
              </div>
            </div>
          </div>
          <EmptyState
            v-if="!changes.length && !resultComments.length"
            class="mt-4"
          >
            <template #icon>
              <DiffIcon class="w-8 h-8 text-indigo-500" />
            </template>
            {{ $t('editor.noChangesFound') }}
          </EmptyState>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-center">
        <button
          type="button"
          class="flex items-center gap-2 btn-primary"
          :disabled="(!(wordFile || droppedFile)) || isImportingDocx"
          @click="handleDocxImport"
        >
          <template v-if="isImportingDocx">
            <SpinLoader
              v-if="isImportingDocx"
              class="w-5 h-5 -ml-1"
            />
            {{ $t('common.loading') }}…
          </template>
          <template v-else>
            {{ $t('editor.import') }}
          </template>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
