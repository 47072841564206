<script setup lang="ts">
// external
import { computed, ref, watch } from "vue"
import { MagnifyingGlassIcon, BarsArrowDownIcon, BarsArrowUpIcon, PlusIcon } from "@heroicons/vue/24/outline"
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { useI18n } from "vue-i18n"

// internal
import { DialogModal, InfoBox, SpinLoader } from "~/components"
import { ContractType } from "~/types"
import { contractTypes as allContractTypes } from "~/utils"

interface Props {
  show: boolean
  contractTypes: ContractType[]
  contractNameBeingAdded?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    contractNameBeingAdded: null,
  },
)

const { t } = useI18n()

const contractTypeNames = computed(() => props.contractTypes.map((entry) => entry.name))

const query = ref("")
const sortDirection = ref<"asc"|"desc">("desc")

const availableContractTypes = computed(() => {
  return [ ...allContractTypes ].filter((entry) => !contractTypeNames.value.includes(entry))
})

const sortedContractTypes = computed(() => {
  if (!sortDirection.value) return [ ...availableContractTypes.value ]

  const tmpContractTypes = [ ...availableContractTypes.value ]

  tmpContractTypes.sort((a, b) => {
    const nameA = t("contractTypes." + a)
    const nameB = t("contractTypes." + b)
    return (nameA > nameB) ? 1 : ((nameB > nameA) ? -1 : 0)
  })

  if (sortDirection.value === "asc") {
    return tmpContractTypes.reverse()
  }
  return tmpContractTypes
})

const filteredContractTypes = computed(() => {
  if (!query.value) return [ ...sortedContractTypes.value ]
  return [ ...sortedContractTypes.value ].filter((entry) => t("contractTypes." + entry).toLowerCase().includes(query.value.toLowerCase()))
})

const toggleSortDirection = () => {
  sortDirection.value = sortDirection.value === "desc" ? "asc" : "desc"
}

defineEmits([ "update:show", "add" ])

watch(() => props.show, (newVal) => {
  if (!newVal) {
    query.value = ""
  }
})
</script>

<template>
  <DialogModal
    :show="show"
    max-width="xl"
    :show-footer="false"
    :show-close-button="true"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <div>
        {{ $t('accountSettings.contractTypes.addContractType') }}
      </div>
    </template>
    <template #content>
      <div
        v-if="availableContractTypes.length"
      >
        <div class="text-sm mb-2">
          {{ $t('accountSettings.contractTypes.selectTypes') }}:
        </div>
        <div class="flex items-center justify-between space-x-1 mb-2">
          <div class="relative grow">
            <input
              v-model="query"
              type="search"
              class="block w-full px-9 min-h-[38px] py-0 text-base sm:text-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none border-0 appearance-none rounded-l-md"
              :class="[!!query ? 'bg-indigo-50 text-indigo-800' : 'bg-gray-100']"
              :placeholder="$t('filter.search') + '…'"
            >
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <MagnifyingGlassIcon
                class="w-4 h-4"
                :class="[!!query ? 'text-indigo-500' : 'text-gray-400']"
              />
            </div>
            <button
              v-if="!!query"
              type="button"
              class="absolute inset-y-0 right-0 pr-3 flex items-center"
              @click="query = null"
            >
              <XCircleIcon
                class="w-4 h-4 text-indigo-500"
              />
            </button>
          </div>
          <div>
            <button
              type="button"
              class="text-sm text-gray-500 min-h-[38px] px-3 h-full flex items-center rounded-r-md bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none"
              @click="toggleSortDirection"
            >
              <BarsArrowDownIcon
                v-if="sortDirection === 'desc' || !sortDirection"
                aria-hidden="true"
                class="w-5 h-5"
              />
              <BarsArrowUpIcon
                v-else-if="sortDirection === 'asc'"
                aria-hidden="true"
                class="w-5 h-5"
              />
            </button>
          </div>
        </div>
        <div
          v-if="!!query && !filteredContractTypes.length"
          class="text-sm text-gray-500 text-center p-5"
        >
          {{ $t('accountSettings.contractTypes.noMatches') }}
        </div>
        <div
          v-if="filteredContractTypes.length"
          class="relative overflow-hidden rounded-md border border-gray-200 divide-y divide-gray-200"
        >
          <div
            v-for="contractType in filteredContractTypes"
            :key="contractType"
            class="px-3 py-2 text-sm flex justify-between items-center"
          >
            <span>{{ $t('contractTypes.' + contractType) }}</span>
            <button
              type="button"
              class="btn-add"
              :disabled="contractNameBeingAdded === contractType"
              @click="$emit('add', contractType)"
            >
              <SpinLoader
                v-if="contractNameBeingAdded === contractType"
                class="w-3 h-3"
              />
              <PlusIcon
                v-else
                aria-hidden="true"
                class="w-3 h-3"
              />
              <span>{{ $t('common.add') }}</span>
            </button>
          </div>
        </div>
        <InfoBox class="mt-5">
          <span>{{ $t('accountSettings.contractTypes.selectionHint') }}</span>
        </InfoBox>
      </div>
      <div
        v-else
      >
        <InfoBox>
          {{ $t('accountSettings.contractTypes.allAdded') }}
        </InfoBox>
      </div>
    </template>
  </DialogModal>
</template>
