<script setup lang="ts">
// external
import { Link } from "@inertiajs/vue3"
import { computed } from "vue"

// internal
import { DocumentCalendarEntry, DocumentCalendarEntryType } from "~/types"

interface Props {
  entry: DocumentCalendarEntry
}

const props = defineProps<Props>()

const colorClass = computed(() => {
  switch (props.entry.type) {
    case DocumentCalendarEntryType.expiration:
    case DocumentCalendarEntryType.duration_end:
      return "bg-red-500"
    case DocumentCalendarEntryType.notice:
      return "bg-yellow-500"
    case DocumentCalendarEntryType.renewal:
      return "bg-green-500"
    case DocumentCalendarEntryType.duration_start:
    case DocumentCalendarEntryType.effective:
      return "bg-blue-500"
    case DocumentCalendarEntryType.terminated:
      return "bg-slate-900"
    default:
      return "bg-gray-500"
  }
})
</script>

<template>
  <Link
    :href="route('documents.show', entry.document_uuid)"
    class="flex items-center group max-w-full"
    :title="entry.document_name"
  >
    <span
      class="flex-none w-2 h-2 mr-1 rounded-full"
      :class="colorClass"
    />
    <span class="flex-auto font-medium text-gray-900 truncate group-hover:text-indigo-600">
      {{ entry.document_name }}
    </span>
  </Link>
</template>
