<script setup lang="ts">

</script>
<template>
  <div>
    <p>{{ $t('lexica.content.automations.intro') }}</p>
    <table class="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
          >
            {{ $t('magicTable.columns.type') }}
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            {{ $t('lexica.content.automations.formatValues') }}
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr class="even:bg-gray-50">
          <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
            {{ $t('multiFieldTypes.date') }}
          </td>
          <td class="px-3 py-4 space-y-1 text-sm text-gray-500 whitespace-nowrap">
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">YYYY-MM-DD</pre>
          </td>
        </tr>
        <tr class="even:bg-gray-50">
          <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
            boolean ({{ $t('multiFieldTypes.bool') }})
          </td>
          <td class="px-3 py-4 space-y-1 text-sm text-gray-500 whitespace-nowrap">
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">true</pre><br>
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">false</pre>
          </td>
        </tr>
        <tr class="even:bg-gray-50">
          <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
            Party::entity_type
          </td>
          <td class="px-3 py-4 space-y-1 text-sm text-gray-500 whitespace-nowrap">
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">person</pre><br>
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">business</pre>
          </td>
        </tr>
        <tr class="even:bg-gray-50">
          <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">
            {{ $t('multiFieldTypes.currency') }}
          </td>
          <td class="px-3 py-4 space-y-1 text-sm text-gray-500 whitespace-nowrap">
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">EUR;1000.00</pre><br>
            <pre class="border border-gray-200 bg-gray-100 rounded-sm px-1 py-0.5 w-auto inline-block">USD;34.99</pre><br>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
