<script setup lang="ts">
// external
import { watch, computed } from "vue"
import { PlayCircleIcon } from "@heroicons/vue/24/outline"
import { router, Link } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"

// internal
import {
  EmptyState,
  MagicTable,
  AutomationTableEntry,
  FilterBubbles,
  FilterHeader,
  FilterBase,
  AutomationFilters,
} from "~/components"
import { useFilters } from "~/composables"
import type { Metadata, Automation, Pagination, Team } from "~/types"

interface Props {
  pagination: Pagination<Automation>
  paginationProp?: string[]
  metadata?: Metadata[]
  teams?: Team[]
  showFilter?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isArchive: false,
    showFilter: true,
  },
)

const { t } = useI18n()

const {
  sortAttribute,
  sortDirection,
  isUpdatingList,
  hasActiveFilters,
  filterData,
  filterBubbles,
  filterValues,
  filterOptions,
  isSidebarOpen,
  setPerPage,
  resetSorting,
  resetFilter,
  clearBubble,
} = useFilters(
  props.isArchive ? "archived_at" : "created_at", "desc",
  props.paginationProp,
  props.showFilter ? [ "query", "teams", "archived", "status" ] : [],
  props.metadata ?? [],
)

if (props.teams?.length) {
  filterOptions.value["teams"] = props.teams.map((team) => {
    return {
      label: team.name,
      value: team.uuid,
    }
  })
  filterOptions.value["teams"].push({ label: t("unassigned"), value: 0 })
}

const columns = computed(() => [
  {
    key: "name",
    sortAttribute: "name",
    required: true,
    active: true,
  },
  {
    key: "status",
    breakpoint: "md",
    required: false,
    active: true,
  },
  {
    key: "team",
    breakpoint: "lg",
    required: false,
    active: true,
  },
  {
    key: "tags",
    breakpoint: "lg",
    required: false,
    active: true,
  },
  {
    key: "creator",
    breakpoint: "lg",
    required: false,
    active: true,
  },
  {
    key: "timestamps",
    breakpoint: "xl",
    required: false,
    active: true,
  },
  {
    key: "options",
    required: true,
    active: true,
  },
])

const sortAttributes = computed(() => {
  return [ "name", "created_at", "updated_at", "archived_at" ]
})

watch(() => filterValues.value, (newVal, oldVal) => {
  if (newVal.archived !== oldVal.archived) {
    sortDirection.value = newVal.archived ? "archived_at" : "created_at"
  }
})

const handleEmptyStateClick = () => {
  if (hasActiveFilters.value) {
    resetFilter()
  } else {
    router.visit(route("automations.index") + "#create")
  }
}
</script>

<template>
  <FilterBase
    :has-active-filters="hasActiveFilters"
    :show-filter="showFilter"
    :is-sidebar-open="isSidebarOpen"
    :filter-count="filterBubbles.length"
    @reset="resetFilter"
    @close="isSidebarOpen = false"
  >
    <template #filter>
      <AutomationFilters
        v-model:filter-values="filterValues"
        :teams="teams"
        :status-options="filterOptions.status"
        :metadata="metadata"
      />
    </template>
    <template #default>
      <FilterHeader
        v-if="showFilter"
        v-model:query="filterValues.query"
        v-model:show-all-filters="isSidebarOpen"
        v-model:sort-attribute="sortAttribute"
        v-model:sort-direction="sortDirection"
        :sort-attributes="sortAttributes"
        list-name="automationList"
        class="mb-4"
        :has-active-filters="hasActiveFilters"
        :filter-bubbles="filterBubbles"
      />

      <FilterBubbles
        v-if="hasActiveFilters"
        class="mb-2"
        :filter-bubbles="filterBubbles"
        @clear="clearBubble"
        @reset="resetFilter"
      />

      <MagicTable
        v-model:sort-attribute="sortAttribute"
        v-model:sort-direction="sortDirection"
        :columns="columns"
        :paginator="props.pagination"
        :loading="isUpdatingList"
        :has-filters="hasActiveFilters"
        :filter-data="filterData"
        storage-key="automationList"
        table-name="automationList"
        :pagination-prop="paginationProp"
        @update:per-page="setPerPage"
        @reset-sorting="resetSorting"
        @reset-filter="resetFilter"
      >
        <template #default="{ entry, activeColumns, isLoading }">
          <AutomationTableEntry
            :columns="activeColumns"
            :automation="entry"
            :loading="isLoading"
            :sort-attribute="sortAttribute"
            :is-archive="filterValues.archived"
          />
        </template>
        <template #empty>
          <EmptyState
            @click="handleEmptyStateClick"
          >
            <template #icon>
              <PlayCircleIcon
                aria-hidden="true"
              />
            </template>
            <template #call-to-action>
              <span v-if="!hasActiveFilters">
                {{ $t('automations.empty.description') }}
              </span>
              <span v-else>
                {{ $t('automations.empty.descriptionFilter') }}
              </span>
            </template>
            <template
              v-if="hasActiveFilters"
              #button-text
            >
              <span>
                {{ $t('automations.empty.buttonFilter') }}
              </span>
            </template>
            <template
              v-else
              #buttons
            >
              <Link
                type="button"
                class="btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 inline-flex items-center gap-1.5"
                :href="route('automations.index') + '#create'"
              >
                <span>{{ $t('automations.create.titleXlsx') }}</span>
              </Link>
            </template>
            <template #default>
              {{ $t('automations.empty.title') }}
            </template>
          </EmptyState>
        </template>
      </MagicTable>
    </template>
  </FilterBase>
</template>
