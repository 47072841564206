<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch } from "vue"
import { ArrowUturnLeftIcon, InformationCircleIcon } from "@heroicons/vue/24/outline"
import { ViewfinderCircleIcon } from "@heroicons/vue/20/solid"

// internal
import { FormInputErrors, MetadataOptionsPopover, SpinLoader } from "~/components"
import { useMetadataStore, useSharedStore } from "~/stores"
import { MetadataValue, Metadata, MetadataType } from "~/types"
import { useI18n } from "vue-i18n"

interface Props {
  clause?: MetadataValue
  metadata?: Metadata
  disabled?: boolean
  entityUuid?: string
  proposedValue?: string|boolean
  isExtraction?: boolean
  label?: string
  disableRemove?: boolean
  usePadding?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    clause: null,
    metadata: null,
    disabled: false,
    entityUuid: null,
    proposedValue: null,
    isExtraction: false,
    label: null,
    disableRemove: false,
    usePadding: true,
  },
)

const emit = defineEmits( [ "focus", "blur", "set-value-source", "close-modal", "update:clause" ] )

const { t } = useI18n()

const metadataStore = useMetadataStore()
const { metadataValueErrorsMap, metadataValueLastSavedMap, uuidsOfUpdatingMetadataValue } = storeToRefs(metadataStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const inputComponent = ref<any>()

const emptyClause = { } as MetadataValue

const localClause = computed<MetadataValue>({
  get: () => {
    return props.clause || emptyClause
  },
  set: async (val) => {
    if (!props.entityUuid) return
    metadataStore.updateLocalMetadataValue(val, crudContext.value, props.entityUuid)
  },
})

const resetToReferenceDynamicField = ref<boolean>(false)
const overrideValue = ref<boolean>(false)

const originalClause = ref<Partial<MetadataValue>>(props.clause)

const proposedValueProp = computed<string | boolean | undefined>(() => {
  if (props.proposedValue !== null) return props.proposedValue
  return undefined
})

const overRideWithExtractionResult = computed<boolean>(() => {
  return props.isExtraction && overrideValue.value && props.proposedValue !== localClause.value?.value
})

const localValue = computed<any>({
  get: () => {
    if (overRideWithExtractionResult.value) return props.proposedValue
    if (!overrideValue.value) return props.proposedValue || localClause.value?.value
    return localClause.value?.value
  },
  set: (val) => {
    const newOverrideValue = val !== props.proposedValue || (props.isExtraction && val !== localClause.value?.value) ? true : false
    overrideValue.value = newOverrideValue
    emit("update:clause", val)
  },
})

if (proposedValueProp.value) localValue.value = proposedValueProp.value

watch(proposedValueProp, (newVal) => {
  if (newVal) localValue.value = newVal
})

const formErrors = computed(() => metadataValueErrorsMap.value[localClause.value.uuid])

const lastSaved = computed<number | undefined>(() => metadataValueLastSavedMap.value[localClause.value?.uuid])
const isLoadingClause = computed<boolean>(() => uuidsOfUpdatingMetadataValue.value.includes(localClause.value?.uuid))
const isHighlightedLastSaved = ref<boolean>(false)

watch(lastSaved, () => {
  isHighlightedLastSaved.value = true
  setTimeout(() => { isHighlightedLastSaved.value = false }, 1500)
})

const handleReset = () => {
  localValue.value = false
  overrideValue.value = false
  if (inputComponent.value) inputComponent.value?.reset()
  if (localClause.value?.reference_dynamic_field_uuid) resetToReferenceDynamicField.value = true
}

const isDisabledResetButton = computed(() => {
  const proposedAndNotReset = (proposedValueProp.value || overRideWithExtractionResult.value) && props.proposedValue !== localClause.value?.value && !resetToReferenceDynamicField.value
  const valueChanged = (localValue.value + "") !== (originalClause?.value + "")
  const check = !proposedAndNotReset || !valueChanged
  return check
})

const name = computed(() =>  {
  const metadata = props.metadata
  if (!metadata) return ""
  return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.display_name : t(`metadata.system.${metadata?.name}.name`)
})
const description = computed(() =>  {
  const metadata = props.metadata
  if (!metadata) return ""
  return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.description : t(`metadata.system.${metadata?.name}.description`)
})

</script>

<template>
  <div
    class="relative text-sm transition-all duration-500 group"
    :class="[
      `metadata_input_wrapper_` + localClause.uuid,
      isHighlightedLastSaved ? 'bg-teal-50' : '',
      usePadding ? 'py-2.5 px-6' : '',
    ]"
  >
    <dt
      class="text-gray-500 flex items-center gap-1.5 justify-between min-h-[28px]"
    >
      <div class="flex flex-grow items-center gap-1.5 truncate">
        <span
          class="font-medium text-purple-800 truncate"
          :title="name"
        >{{ props.label || name }}</span>
        <span
          v-if="localClause.bounding_box || localClause.prosemirror_uuids"
          data-tippy-help
          :data-tippy-content="$t('metadata.hasBoundingBox')"
          data-placement="bottom"
        >
          <ViewfinderCircleIcon
            class="w-3 h-3 text-purple-700"
            aria-hidden="true"
          />
        </span>
      </div>

      <SpinLoader
        v-if="isLoadingClause"
        class="w-4 h-4"
      />
      <MetadataOptionsPopover
        v-else-if="localClause.uuid && !isExtraction"
        :metadata-value="localClause"
        :disabled="isLoadingClause"
        :entity-uuid="entityUuid"
        :disable-remove="disableRemove"
        @set-value-source="$emit('set-value-source', $event)"
        @close-modal="$emit('close-modal')"
      >
        <template
          v-if="!!description"
          #info
        >
          <li class="flex items-start max-w-xs gap-2 p-2 text-xs border-t text-slate-400 border-t-slate-700 first:rounded-t-md last:rounded-b-md">
            <InformationCircleIcon
              class="w-4 h-4 text-gray-500 shrink-0 grow-0"
              aria-hidden="true"
            />
            <div>
              <div class="font-semibold">
                {{ props.label || name }}
              </div>{{ description }}
            </div>
          </li>
        </template>
      </MetadataOptionsPopover>
      <button
        v-else-if="isExtraction"
        :disabled="isDisabledResetButton"
        type="button"
        class="flex items-center p-1 text-purple-400 btn-plain disabled:text-gray-300 hover:text-gray-600 hover:bg-purple-100 focus:bg-purple-100 focus:ring-purple-200"
        data-tippy-help
        data-placement="top"
        :data-tippy-content="$t('aiAnalysis.resetTo', { value: originalClause?.value || $t('common.empty') })"
        @click.prevent.stop="handleReset"
      >
        <ArrowUturnLeftIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
      </button>
    </dt>
    <div
      v-if="formErrors?.value?.length"
      class="flex justify-end"
    >
      <FormInputErrors
        :errors="formErrors?.value"
        align="right"
      />
    </div>
  </div>
</template>
