<script setup lang="ts">
import { PlusIcon } from "@heroicons/vue/24/solid"
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline"

interface Props {
  hideButton?: boolean
  boxed?: boolean
  showPlusIcon?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    hideButton: false,
    boxed: false,
    showPlusIcon: false,
  },
)

const emit = defineEmits([ "click" ])

const emitEmptyStateAction = () => emit("click")
</script>

<template>
  <div
    class="relative py-5 overflow-hidden text-center"
    :class="
      props.boxed ? 'p-6 rounded-md bg-white shadow ring-1 ring-black ring-opacity-5' : ''
    "
  >
    <div
      class="flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full relative mx-auto [&>*]:h-12 [&>*]:w-12 ring ring-white [&>*]:text-indigo-500/50 bg-gradient-to-br from-gray-100 to-indigo-100"
    >
      <slot name="icon">
        <MagnifyingGlassIcon
          aria-hidden="true"
        />
      </slot>
    </div>

    <h3
      class="mt-3 text-sm font-medium text-gray-900"
    >
      <slot />
    </h3>
    <p
      class="mt-1 text-sm text-gray-500"
    >
      <slot name="call-to-action" />
    </p>
    <div
      v-if="!hideButton"
      class="mt-2"
    >
      <slot name="buttons">
        <button
          type="button"
          class="btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 inline-flex items-center gap-1.5"
          @click="emitEmptyStateAction"
        >
          <PlusIcon
            v-if="showPlusIcon"
            class="w-5 h-5 mr-2 -ml-1"
            aria-hidden="true"
          />
          <slot name="button-text" />
        </button>
      </slot>
    </div>
  </div>
</template>
