<script setup lang="ts">
import { CheckIcon, EllipsisHorizontalIcon, XMarkIcon } from "@heroicons/vue/24/solid"

import { CheckpointPass, DocumentStage, UiUser } from "~/types"
import { StageDisplay } from "~/components"
import { getUserRepresentation } from "~/utils"
import { useStages } from "~/composables"
import { computed } from "vue"
import { useDocumentStore } from "~/stores"
import { storeToRefs } from "pinia"


interface Props {
  checkpointPasses: CheckpointPass[]
  combinedUsers: UiUser[]
}
const props = withDefaults(
  defineProps<Props>(),
  {
  },
)

const documentStore = useDocumentStore()
const { currentDocument } = storeToRefs(documentStore)

const stage = computed<DocumentStage>(() => currentDocument.value?.stage)

const { stages } = useStages(stage, props.checkpointPasses)

// Function to sort checkpoint steps by "order" property
const sortCheckpointSteps = (checkpointSteps) => {
  return checkpointSteps.sort((a, b) => a.sortCheckpointSteps - b.sortCheckpointSteps)
}

// Function to return stage name
const getStageByName = (stage: DocumentStage) => stages.value?.find((el) => el.name === stage)

</script>

<template>
  <ul
    v-if="checkpointPasses?.length"
    role="list"
    class="grid grid-cols-1 divide-y"
  >
    <li
      v-for="checkpoint in checkpointPasses"
      :key="checkpoint.uuid"
      class="px-6 py-3"
    >
      <div class="flex items-start pb-1">
        <span class="mr-1 text-sm font-medium">{{ $t('checkpoints.beforeReaching') }}:</span>
        <StageDisplay
          :stage="getStageByName(checkpoint.checkpoint_document_stage)"
          label-classes="ml-1 text-sm font-medium"
        />
        <!-- TO DO: Display result here for finished checkpoints or cancel button -->
      </div>
      <div>
        <div class="relative mt-1">
          <template v-if="checkpoint.checkpoint_pass_steps.length">
            <div
              v-for="(step, stepIdx) in sortCheckpointSteps(
                checkpoint.checkpoint_pass_steps
              )"
              :key="stepIdx"
              class="relative text-sm text-gray-600"
            >
              <template v-if="step.checkpoint_pass_step_approvals?.length">
                <div
                  v-if="
                    stepIdx + 1 < checkpoint.checkpoint_pass_steps?.length &&
                      step.status === 'upcoming'
                  "
                  class="w-0.5 absolute left-1 top-2 bottom-0 bg-gray-200"
                  aria-hidden="true"
                />
                <div
                  v-else-if="
                    stepIdx + 1 < checkpoint.checkpoint_pass_steps?.length &&
                      step.status === 'active'
                  "
                  class="w-0.5 absolute left-1 top-2 bottom-0 bg-indigo-100"
                  aria-hidden="true"
                />
                <div
                  v-else-if="
                    stepIdx + 1 < checkpoint.checkpoint_pass_steps?.length &&
                      step.status === 'approved'
                  "
                  class="w-0.5 absolute left-1 top-2 bottom-0 bg-green-100"
                  aria-hidden="true"
                />
                <div
                  v-else-if="
                    stepIdx + 1 < checkpoint.checkpoint_pass_steps?.length &&
                      step.status === 'rejected'
                  "
                  class="w-0.5 absolute left-1 top-2 bottom-0 bg-red-100"
                  aria-hidden="true"
                />
                <div
                  v-else-if="
                    stepIdx + 1 < checkpoint.checkpoint_pass_steps?.length &&
                      step.status === 'canceled'
                  "
                  class="w-0.5 absolute left-1 top-2 bottom-0 bg-gray-100"
                  aria-hidden="true"
                />
                <span
                  v-if="step.status === 'upcoming'"
                  class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px text-xs font-bold text-white bg-gray-200 rounded-full top-1 ring-2 ring-white"
                />
                <span
                  v-if="step.status === 'active'"
                  class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px text-xs font-bold text-white bg-indigo-100 rounded-full top-1 ring-2 ring-white"
                >
                  <EllipsisHorizontalIcon
                    class="absolute w-4 h-4 text-indigo-700"
                    aria-hidden="true"
                  />
                </span>
                <span
                  v-if="step.status === 'approved'"
                  class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px text-xs font-bold text-white bg-green-100 rounded-full top-1 ring-2 ring-white"
                >
                  <CheckIcon
                    class="w-4 h-4 absolute -bottom-0.5 text-green-700"
                    aria-hidden="true"
                  />
                </span>
                <span
                  v-if="step.status === 'rejected'"
                  class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px text-xs font-bold text-white bg-red-100 rounded-full top-1 ring-2 ring-white"
                >
                  <XMarkIcon
                    class="absolute w-4 h-4 text-red-700"
                    aria-hidden="true"
                  />
                </span>
                <span
                  v-if="step.status === 'canceled'"
                  class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px text-xs font-bold text-white bg-gray-100 rounded-full top-1 ring-2 ring-white"
                >
                  <XMarkIcon
                    class="absolute w-4 h-4 text-gray-700"
                    aria-hidden="true"
                  />
                </span>
                <div class="flex items-center justify-between pl-5">
                  <span
                    :class="
                      step.status === 'active'
                        ? 'text-indigo-700'
                        : step.status === 'approved'
                          ? 'text-green-700'
                          : step.status === 'rejected'
                            ? 'text-red-700'
                            : 'text-gray-500'
                    "
                    class="flex items-center text-xs font-medium uppercase"
                  >
                    {{ $t('checkpoints.step', {number: stepIdx + 1}) }}
                  </span>
                  <span class="flex items-center gap-1 text-xs">
                    <span class="font-medium">{{ $t('checkpoints.policy') }}:</span>
                    <span v-if="step.checkpoint_step_policy === 'one'">
                      {{ $t('checkpoints.policies.' + step.checkpoint_step_policy) }}
                    </span>
                    <span v-else-if="step.checkpoint_step_policy === 'two'">
                      {{ $t('checkpoints.policies.' + step.checkpoint_step_policy) }}
                    </span>
                    <span v-else>
                      {{ $t('checkpoints.policies.' + step.checkpoint_step_policy) }}
                    </span>
                  </span>
                </div>
                <div
                  :class="
                    stepIdx + 1 === checkpoint.checkpoint_pass_steps.length
                      ? 'pb-1'
                      : 'pb-4'
                  "
                  class="pt-1 pl-5"
                >
                  <div
                    :class="
                      step.status === 'active'
                        ? 'bg-indigo-50'
                        : step.status === 'approved'
                          ? 'bg-green-50'
                          : step.status === 'rejected'
                            ? 'bg-red-50'
                            : 'bg-gray-100'
                    "
                    class="flow-root bg-gray-100 rounded-md"
                  >
                    <ul
                      v-if="
                        step.checkpoint_pass_step_approvals &&
                          step.checkpoint_pass_step_approvals.length
                      "
                      role="list"
                    >
                      <li
                        v-for="(approval, approvalIdx) in step.checkpoint_pass_step_approvals"
                        :key="approvalIdx"
                        class="p-2"
                      >
                        <div class="flex items-center space-x-2">
                          <div class="shrink-0">
                            <img
                              class="w-5 h-5 rounded-full"
                              :src="approval.account_user?.profile_photo_url"
                              alt=""
                            >
                          </div>
                          <div class="flex-1 min-w-0">
                            <p class="text-xs text-gray-900 truncate">
                              {{ getUserRepresentation(approval.account_user) }}
                            </p>
                          </div>
                          <div
                            v-if="approval.status === 'approved'"
                            class="flex items-center gap-1 text-xs text-green-700 shrink-0"
                          >
                            <CheckIcon
                              class="w-3 h-3"
                              aria-hidden="true"
                            />
                            {{ $t('checkpoints.approved') }}
                          </div>
                          <div
                            v-else-if="approval.status === 'rejected'"
                            class="flex items-center gap-1 text-xs text-red-700 shrink-0"
                          >
                            <XMarkIcon
                              class="w-3 h-3"
                              aria-hidden="true"
                            />
                            {{ $t('checkpoints.rejected') }}
                          </div>
                          <div
                            v-else-if="
                              step.status === 'active' ||
                                step.status === 'approved' ||
                                step.status === 'rejected' ||
                                step.status === 'cancelled'
                            "
                            class="flex items-center gap-1 text-xs text-indigo-700 shrink-0"
                          >
                            <EllipsisHorizontalIcon
                              class="w-3 h-3"
                              aria-hidden="true"
                            />
                            {{ $t('checkpoints.approvalPending') }}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div
                      v-else
                      class="px-4 py-2 text-sm text-gray-500"
                    >
                      {{ $t('checkpoints.noApprovers') }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <span
                  v-if="checkpoint.checkpoint_pass_steps?.length > 1"
                  class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px -mt-1 text-xs font-bold text-white bg-gray-200 rounded-full top-1/2 ring-2 ring-white group-hover:ring-gray-50"
                />
                <div
                  :class="checkpoint.checkpoint_pass_steps?.length === 1 ? '' : 'pl-5'"
                  class="flex items-center gap-1 text-sm text-gray-400"
                >
                  {{ $t('checkpoints.noApproversDefined', {number: stepIdx + 1}) }}
                </div>
              </template>
            </div>
          </template>
          <div
            v-else
            class="mt-2 text-sm text-gray-400"
          >
            {{ $t('checkpoints.noProcessDefined') }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
