<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Switch,
  SwitchGroup,
} from "@headlessui/vue"
import { ArrowUturnLeftIcon, VariableIcon } from "@heroicons/vue/24/outline"
import { CheckIcon, XMarkIcon, InformationCircleIcon } from "@heroicons/vue/24/solid"
import { ChevronUpDownIcon, ViewfinderCircleIcon } from "@heroicons/vue/20/solid"

// internal
import { AutogrowInput, CurrencyInput, DateInput, DurationInput, FormInputErrors, MetadataOptionsPopover, SpinLoader } from "~/components"
import { useAccountStore, useDynamicFieldStore, useMetadataStore, useSharedStore } from "~/stores"
import { DynamicField, MetadataValue, Metadata, MultiFieldType, MetadataType, SelectOption, MetadataValueSource } from "~/types"
import { convertCurrencyString, formatDateSimple, formatMultifieldNumber, isValidCurrencyString, isValidMultifieldNumber, isValidSingleIntervalISODuration, isoDurationToWords } from "~/utils"
import { useI18n } from "vue-i18n"
import { onMounted } from "vue"

interface Props {
  metadataValue?: MetadataValue
  metadata?: Metadata
  disabled?: boolean
  entityUuid?: string
  proposedValue?: string|boolean
  isExtraction?: boolean
  label?: string
  disableRemove?: boolean
  usePadding?: boolean
  isHighlighted?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    metadataValue: null,
    metadata: null,
    disabled: false,
    entityUuid: null,
    proposedValue: null,
    isExtraction: false,
    label: null,
    disableRemove: false,
    usePadding: true,
    isHighlighted: false,
  },
)

const emit = defineEmits( [ "focus", "blur", "set-value-source", "close-modal", "update:metadata-value" ] )

const { locale, t } = useI18n()

const metadataStore = useMetadataStore()
const { metadataValueErrorsMap, metadataValueLastSavedMap, uuidsOfUpdatingMetadataValue } = storeToRefs(metadataStore)

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFields } = storeToRefs(dynamicFieldStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const accountStore = useAccountStore()
const { contractTypes } = storeToRefs(accountStore)
const { fetchContractTypes } = accountStore

const inputComponent = ref<any>()

const emptyMetadataValue = { }

const localMetadataValue = computed<Partial<MetadataValue>>({
  get: () => {
    return props.metadataValue || emptyMetadataValue
  },
  set: async (val) => {
    if (!props.entityUuid) return
    // If the value differs from the computed_value, ensure that value_source is MetadataValueSource.user
    if (val.computed_value !== val.value) val.value_source = MetadataValueSource.user
    metadataStore.updateLocalMetadataValue(val, crudContext.value, props.entityUuid)
  },
})

const updateLocalMetadataValueField = (field: keyof MetadataValue, val: any) => {
  localMetadataValue.value = {
    ...localMetadataValue.value || {},
    [field]: val,
  }
  localValue.value = val
}

const resetToReferenceDynamicField = ref<boolean>(false)
const overrideValue = ref<boolean>(false)

const referenceDynamicField = computed<DynamicField>(() => {
  if (!props.metadataValue) return null
  return dynamicFields.value.find((el) => el.uuid === props.metadataValue.reference_dynamic_field_uuid)
})

const metadata = computed<Metadata>(() => {
  return localMetadataValue.value.metadata
})

const originalMetadataValue = ref<Partial<MetadataValue>>(props.metadataValue)
const localMetadata = computed<Partial<Metadata>>(() => !!props.metadataValue ? props.metadataValue.metadata : props.metadata)

const validateExtractedResult = (extractedResult: any): boolean => {
  if (localMetadata.value?.value_type === MultiFieldType.currency && !isValidCurrencyString(extractedResult)) return false
  if (localMetadata.value?.value_type === MultiFieldType.currency_duration && !isValidCurrencyString(extractedResult, true)) return false
  if (localMetadata.value?.value_type === MultiFieldType.duration && !isValidSingleIntervalISODuration(extractedResult)) return false
  if (localMetadata.value?.value_type === MultiFieldType.number && !isValidMultifieldNumber(extractedResult)) return false
  if (localMetadata.value?.value_type === MultiFieldType.date && !Date.parse(extractedResult)) return false
  if (localMetadata.value?.value_type === MultiFieldType.select && !localMetadata.value?.select_values?.includes(extractedResult)) return false
  return true
}

const proposedValueProp = computed<string | boolean | undefined>(() => {
  if (props.proposedValue !== null && validateExtractedResult(props.proposedValue)) return props.proposedValue
  return undefined
})

const overRideWithExtractionResult = computed<boolean>(() => {
  return props.isExtraction && overrideValue.value && props.proposedValue !== localMetadataValue.value?.value
})

const isNotValidSingleIntervalISODuration = computed<boolean>(() => {
  return localMetadata.value?.value_type === MultiFieldType.duration && !isValidSingleIntervalISODuration(localMetadataValue.value?.value || props.proposedValue)
})

const localValue = computed<any>({
  get: () => {
    if (isNotValidSingleIntervalISODuration.value) return null
    if (overRideWithExtractionResult.value) return props.proposedValue
    if (!overrideValue.value) return props.proposedValue || localMetadataValue.value?.value
    return localMetadataValue.value?.value
  },
  set: (val) => {
    const newOverrideValue = val !== props.proposedValue || (props.isExtraction && val !== localMetadataValue.value?.value) ? true : false
    overrideValue.value = newOverrideValue
    emit("update:metadata-value", val)
  },
})

if (proposedValueProp.value) localValue.value = proposedValueProp.value

watch(proposedValueProp, (newVal) => {
  if (newVal) localValue.value = newVal
})

const formErrors = computed(() => metadataValueErrorsMap.value[localMetadataValue.value.uuid])

const lastSaved = computed<number | undefined>(() => metadataValueLastSavedMap.value[localMetadataValue.value?.uuid])
const isLoadingMetadataValue = computed<boolean>(() => uuidsOfUpdatingMetadataValue.value.includes(localMetadataValue.value?.uuid))
const isHighlightedLastSaved = ref<boolean>(false)

watch(lastSaved, () => {
  isHighlightedLastSaved.value = true
  setTimeout(() => { isHighlightedLastSaved.value = false }, 1500)
})

const handleReset = () => {
  localValue.value = originalMetadataValue.value?.value
  overrideValue.value = false
  if (inputComponent.value) inputComponent.value?.reset()
  if (localMetadataValue.value?.reference_dynamic_field_uuid) resetToReferenceDynamicField.value = true
}

const isDisabledResetButton = computed(() => {
  const proposedAndNotReset = (proposedValueProp.value || overRideWithExtractionResult.value) && props.proposedValue !== localMetadataValue.value?.value && !resetToReferenceDynamicField.value
  const valueChanged = (localValue.value + "") !== (originalMetadataValue?.value + "")
  const check = !proposedAndNotReset || !valueChanged
  return check
})

const name = computed(() =>  {
  const metadata = localMetadata.value
  if (!metadata) return ""
  return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.display_name : t(`metadata.system.${metadata?.name}.name`)
})
const description = computed(() =>  {
  const metadata = localMetadata.value
  if (!metadata) return ""
  return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.description : t(`metadata.system.${metadata?.name}.description`)
})

const selectOptions = computed<SelectOption[]>(() => {
  const metadata = localMetadata.value
  if (localMetadata.value?.value_type !== MultiFieldType.select) {
    return []
  }

  if (metadata.name === "contract_type") {
    return contractTypes.value.map((option) => {
      return {
        label: t(`contractTypes.${option.name}`),
        value: option.name,
      }
    })
  }

  if (metadata.name === "contract_duration_type") {
    return metadata?.select_values.map((option) => {
      return {
        label: t(`contractDurationTypes.${option}.name`),
        value: option,
      }
    })
  }

  return metadata?.select_values.map((option) => {
    return {
      label: option,
      value: option,
    }
  })
})

const formatPlaceholder = computed(() => {
  // Take sample amount of 0 and apply formatMultifieldNumber with settings format
  return formatMultifieldNumber(0, null, metadata.value?.value_type, locale.value)
})

onMounted(() => {
  fetchContractTypes()
})

</script>

<template>
  <div
    class="relative text-sm transition-all duration-500 group"
    :class="[
      `metadata_input_wrapper_` + localMetadataValue.uuid,
      isHighlightedLastSaved ? 'bg-teal-50' : '',
      usePadding ? 'pt-1.5 pb-2.5 px-6' : ''
    ]"
  >
    <dt
      class="text-gray-500 flex items-center gap-1.5 justify-between min-h-[28px]"
    >
      <div
        class="flex items-center gap-1.5 truncate"
      >
        <span
          class="truncate"
          :title="name"
        >{{ props.label || name }}</span>
        <span
          v-if="localMetadataValue.bounding_box || localMetadataValue.prosemirror_uuids"
          data-tippy-help
          :data-tippy-content="$t('metadata.hasBoundingBox')"
          data-placement="bottom"
        >
          <ViewfinderCircleIcon
            class="w-3 h-3 text-indigo-700"
            aria-hidden="true"
          />
        </span>
      </div>

      <SpinLoader
        v-if="isLoadingMetadataValue"
        class="w-4 h-4"
      />
      <MetadataOptionsPopover
        v-else-if="localMetadataValue.uuid && !isExtraction"
        :metadata-value="metadataValue"
        :disabled="isLoadingMetadataValue"
        :entity-uuid="entityUuid"
        :disable-remove="disableRemove"
        @set-value-source="$emit('set-value-source', $event)"
        @close-modal="$emit('close-modal')"
      >
        <template
          v-if="!!description"
          #info
        >
          <li class="flex items-start max-w-xs gap-2 p-2 text-xs border-t text-slate-400 border-t-slate-700 first:rounded-t-md last:rounded-b-md">
            <InformationCircleIcon
              class="w-4 h-4 text-gray-500 shrink-0 grow-0"
              aria-hidden="true"
            />
            <div>
              <div class="font-semibold">
                {{ props.label || name }}
              </div>{{ description }}
            </div>
          </li>
        </template>
      </MetadataOptionsPopover>
      <button
        v-else-if="isExtraction"
        :disabled="isDisabledResetButton"
        type="button"
        class="flex items-center p-1 text-gray-400 btn-plain disabled:text-gray-300 hover:text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-200"
        data-tippy-help
        data-placement="top"
        :data-tippy-content="$t('aiAnalysis.resetTo', { value: referenceDynamicField?.value || originalMetadataValue?.value || $t('common.empty') })"
        @click.prevent.stop="handleReset"
      >
        <ArrowUturnLeftIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
      </button>
    </dt>
    <div
      v-if="!(props.isExtraction && [MultiFieldType.clause].includes(localMetadata?.value_type))"
      class="w-full space-y-1"
    >
      <dd
        v-if="!localMetadataValue.reference_dynamic_field_uuid"
        class="font-normal text-right text-gray-900 rounded-md min-h-[28px] max-w-[352px]"
        :class="[
          [MultiFieldType.textarea].includes(localMetadata?.value_type) ? '' : 'inline-flex',
          [ MultiFieldType.text, MultiFieldType.number, MultiFieldType.email ].includes(localMetadata?.value_type) ? 'truncate' : '',
          [ MultiFieldType.text, MultiFieldType.number, MultiFieldType.email ].includes(localMetadata?.value_type) ? 'min-w-[12rem]' : '',
          [ MultiFieldType.bool, MultiFieldType.clause].includes(localMetadata?.value_type) ? '' : 'bg-gray-100',
          isHighlighted ? 'shadow-lg' : '',
        ]"
      >
        <!-- Metadata type: text | number | email -->
        <AutogrowInput
          v-if="[ MultiFieldType.text, MultiFieldType.number, MultiFieldType.email ].includes(localMetadata?.value_type)"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          :name="('metadata-input_' + localMetadataValue.uuid)"
          :disabled="disabled"
          :model-value="localValue"
          :placeholder="localMetadata?.value_type === MultiFieldType.number ? formatPlaceholder : $t('metadata.enterValue')+'…'"
          :type="metadata?.type"
          :min="localMetadata?.value_type === MultiFieldType.number ? -999999999999.99 : undefined"
          :max="localMetadata?.value_type === MultiFieldType.number ? 999999999999.99 : undefined"
          :step="localMetadata?.value_type === MultiFieldType.number ? 0.01 : undefined"
          :lang="locale"
          :text-classes="['px-2 py-1', ('metadata-input_' + localMetadataValue.uuid), formErrors?.value?.length ? 'input-has-errors' : '']"
          @input="($event) => updateLocalMetadataValueField('value', ($event.target as HTMLInputElement).value)"
          @focus="$emit('focus', $event)"
          @blur="$emit('blur', $event)"
        />

        <!-- Metadata type: textarea -->
        <div
          v-if="localMetadata?.value_type === MultiFieldType.textarea"
          class="grow-textarea"
          :data-replicated-value="localValue"
        >
          <textarea

            :id="('metadata-input_' + localMetadataValue.uuid)"
            :value="localValue"
            :disabled="disabled"
            class="input-plain"
            :class="{'error': !!formErrors?.value?.length}"
            :placeholder="$t('common.add')+'…'"
            @change="($event) => updateLocalMetadataValueField('value', ($event.target as HTMLTextAreaElement).value)"
          />
        </div>

        <!-- Metadata type: bool -->
        <SwitchGroup
          v-if="[MultiFieldType.bool, MultiFieldType.clause].includes(localMetadata?.value_type)"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          as="div"
          class="flex items-center mt-1"
        >
          <Switch
            :model-value="localValue"
            :disabled="disabled"
            :class="[
              localValue ? [MultiFieldType.clause].includes(localMetadata?.value_type) ? 'bg-purple-600' : 'bg-indigo-600' : 'bg-gray-300',
              'relative inline-flex shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
            @update:model-value="($event) => updateLocalMetadataValueField('value', $event)"
          >
            <span
              aria-hidden="true"
              :class="[
                localValue ? 'translate-x-4' : 'translate-x-0',
                'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </Switch>
        </SwitchGroup>
        <!-- Metadata type: select -->
        <Listbox
          v-if="localMetadata?.value_type === MultiFieldType.select"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          :model-value="localValue"
          as="div"
          :disabled="disabled"
          class="relative max-w-full"
          :class="[{'error': !!formErrors?.value?.length}, ('metadata-input_' + localMetadataValue.uuid)]"
          @update:model-value="($event) => updateLocalMetadataValueField('value', $event)"
        >
          <ListboxButton
            :class="[!disabled ? 'pr-9' : '']"
            class="w-full px-2 font-normal text-left text-gray-900 truncate border-0 btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-offset-0 focus:ring-0 focus:outline-0 focus:border-none"
          >
            <span
              v-if="localValue"
              class="block truncate"
            >
              {{ localMetadata.name === 'contract_type' ? $t(`contractTypes.${localValue}`) : localMetadata.name === 'contract_duration_type' ? $t(`contractDurationTypes.${localValue}.name`) : localValue }}
            </span>
            <span
              v-else
              class="block text-gray-400 truncate"
            >
              {{ $t('dynamicFields.selectOption') }}…
            </span>
            <span
              v-if="localValue && !disabled"
              class="absolute inset-y-0 right-0 flex items-center pr-2.5 cursor-pointer"
              @click.prevent.stop="updateLocalMetadataValueField('value', null)"
            >
              <XMarkIcon
                class="w-4 h-4 text-gray-400 hover:text-gray-600"
                aria-hidden="true"
              />
            </span>
            <span
              v-else
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <ChevronUpDownIcon
                class="w-4 h-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="left-0 right-auto max-w-xs min-w-0 text-left listbox-options"
              @focus="
                $emit('focus', {
                  target: { id: 'metadata-input_' + localMetadataValue.uuid },
                })
              "
              @blur="
                $emit('blur', {
                  target: { id: 'metadata-input_' + localMetadataValue.uuid },
                })
              "
            >
              <div
                v-if="!selectOptions?.length"
                class="px-3 py-2 text-sm text-gray-500"
              >
                {{ $t('common.noOptionsFound') }}.
              </div>
              <ListboxOption
                v-for="option in selectOptions"
                :key="option.value"
                v-slot="{ active, selected }"
                as="template"
                :value="option.value"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <span
                    :class="[
                      selected ? 'font-semibold mr-2' : 'font-normal',
                      'block truncate',
                    ]"
                  >
                    {{ option.label }}
                  </span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </Listbox>
        <!-- Metadata type: date -->
        <DateInput
          v-if="localMetadata?.value_type === MultiFieldType.date || localMetadata?.value_type === ('timestamp' as MultiFieldType)"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          ref="inputComponent"
          :style="'plain'"
          :width="'auto'"
          :model-value="localValue"
          :has-error="!!formErrors?.value?.length"
          :show-day="true"
          :show-month="true"
          :show-year="true"
          :disabled="disabled"
          :class="('metadata-input_' + localMetadataValue.uuid)"
          @update:model-value="($event) => updateLocalMetadataValueField('value', $event)"
        />
        <!-- Metadata type: duration -->
        <DurationInput
          v-if="localMetadata?.value_type === MultiFieldType.duration && (!isNotValidSingleIntervalISODuration || !localMetadataValue?.computed_value)"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          ref="inputComponent"
          :model-value="localValue"
          :has-error="!!formErrors?.value?.length"
          :disabled="disabled"
          class="w-full"
          :style="'plain'"
          :class="('metadata-input_' + localMetadataValue.uuid)"
          @update:model-value="($event) => updateLocalMetadataValueField('value', $event)"
        />
        <div
          v-else-if="isNotValidSingleIntervalISODuration"
          class="px-3 py-1.5"
        >
          {{ localMetadataValue.computed_value ? isoDurationToWords(localMetadataValue.computed_value, t) : $t('common.n/a') }}
        </div>
        <!-- Metadata type: currency -->
        <CurrencyInput
          v-if="[ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(localMetadata?.value_type)"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          ref="inputComponent"
          :model-value="localValue"
          :disabled="disabled"
          :has-error="!!formErrors?.value?.length"
          :class="('metadata-input_' + localMetadataValue.uuid)"
          :style="'plain'"
          class="w-full"
          :has-duration="localMetadata?.value_type === MultiFieldType.currency_duration"
          @update:model-value="($event) => updateLocalMetadataValueField('value', $event)"
        />
      </dd>
      <dd
        v-else
        class="flex-1 w-full text-right truncate"
        :class="disabled ? 'opacity-50' : ''"
      >
        <div
          v-if="referenceDynamicField"
          :id="('metadata-input_' + localMetadataValue.uuid)"
          class="flex items-center justify-start gap-1.5 text-indigo-500"
        >
          <VariableIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          <span
            v-if="referenceDynamicField?.type === MultiFieldType.date && referenceDynamicField?.value"
            class="truncate"
          >
            {{ formatDateSimple(referenceDynamicField.value) }}
          </span>
          <span
            v-else-if="referenceDynamicField?.type === MultiFieldType.currency && referenceDynamicField?.value"
            class="truncate"
          >
            {{ convertCurrencyString(referenceDynamicField.value, null, locale) }}
          </span>
          <span
            v-else-if="referenceDynamicField?.value"
            class="truncate"
          >
            {{ referenceDynamicField.value }}
          </span>
          <span
            v-else
            class="truncate"
          >
            {{ referenceDynamicField.name }}
          </span>
        </div>
        <div
          v-else
          class="text-red-600"
        >
          {{ $t('metadata.referenceDynamicFieldNotFound') }}
        </div>
      </dd>
      <!--<div class="flex items-center gap-2 py-1">
        <span
          v-if="(((metadataValue as MetadataValue)?.value_source === MetadataValueSource.user && (metadataValue as MetadataValue)?.computed_value != (metadataValue as MetadataValue)?.value) || isNotValidSingleIntervalISODuration) && (metadataValue as MetadataValue)?.computed_value"
          class="inline-flex items-center gap-1 text-xs text-indigo-400"
        >
          <BoltIcon class="w-3.5 h-3.5" />
          {{ $t('metadata.generatedValue') }}: {{ computedValueRepresentation }}
        </span>
      </div>-->
    </div>
    <div
      v-if="formErrors?.value?.length"
      class="flex justify-end"
    >
      <FormInputErrors
        :errors="formErrors?.value"
        align="right"
      />
    </div>
  </div>
</template>
