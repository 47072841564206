<script setup lang="ts">
// external
import { computed } from "vue"
import { MenuItem } from "@headlessui/vue"
import { Link } from "@inertiajs/vue3"

// internal
import { MagicTableEntry, TeamIconDisplay, TagList, MagicTableTimestamps } from "~/components"
import { Automation } from "~/types"
import { getTeamByUuid } from "~/utils"
import { useAutomationActions } from "~/Pages/Automations/actions"

interface Props {
  automation: Automation
  columns: any[]
  loading?: boolean
  isArchive?: boolean
  sortAttribute?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    isArchive: false,
    sortAttribute: null,
  },
)

const name = computed(() => props.automation.name ?? "")

const team = computed(() => {
  return getTeamByUuid(props.automation.team_uuid)
})

const {
  confirmAutomationDeletion,
  confirmAutomationRestoration,
} = useAutomationActions()
</script>

<template>
  <MagicTableEntry
    :columns="columns"
    :loading="loading"
  >
    <template #name>
      <div class="flex items-center md:space-x-4">
        <Link
          v-if="!isArchive"
          v-cy="`automation-table-automation-${name.toLowerCase()}`"
          :href="route('automations.show', props.automation.uuid)"
          class="font-semibold truncate hover:text-indigo-700"
        >
          {{
            name ? name : $t('common.undefined')
          }}
        </Link>
        <span
          v-else
          class="font-semibold truncate"
        >{{ name ? name : $t('common.undefined') }}</span>
      </div>
    </template>
    <template #status>
      <span class="text-sm">
        {{ $t('automations.status.' + automation.status) }}
      </span>
    </template>
    <template #team>
      <div
        class="flex items-center shrink-0 text-sm"
      >
        <TeamIconDisplay
          :team="team"
          class="w-4 h-4 mr-2"
        />
        <p class="block text-gray-500 truncate">
          {{
            team?.name
              ? team?.name
              : $t('unassigned')
          }}
        </p>
      </div>
    </template>
    <template #tags>
      <TagList
        v-if="automation.tags?.length"
        :tags="automation.tags"
        class="-mb-1 -mr-1"
      />
      <div
        v-else
        class="text-gray-500"
      >
        -
      </div>
    </template>
    <template #creator>
      <div class="flex items-center space-x-2 truncate">
        <div class="shrink-0">
          <img
            class="w-6 h-6 rounded-full max-w-none ring-2 ring-white"
            :src="automation.created_by_account_user?.profile_photo_url"
          >
        </div>
        <span
          v-if="
            automation.created_by_account_user?.first_name &&
              automation.created_by_account_user?.last_name
          "
          class="shrink-0 leading-5"
        >
          {{ automation.created_by_account_user.first_name }}
          {{ automation.created_by_account_user.last_name }}
        </span>
        <span
          v-else
          class="shrink-0 leading-5"
        >
          {{ automation.created_by_account_user.email }}
        </span>
      </div>
    </template>
    <template #timestamps>
      <MagicTableTimestamps
        :entry="automation"
        :sort-attribute="sortAttribute"
      />
    </template>
    <template #options>
      <div v-if="isArchive">
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-md',
            ]"
            @click="confirmAutomationRestoration(automation.uuid)"
          >
            {{ $t('common.restore') }}
          </button>
        </MenuItem>
      </div>
      <div v-if="!isArchive">
        <MenuItem v-slot="{ active }">
          <Link
            :href="route('automations.show', automation.uuid)"
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-t-md',
            ]"
          >
            {{ $t('common.show') }}<span class="sr-only">, {{ automation.name }}</span>
          </Link>
        </MenuItem>
      </div>
      <div v-if="!isArchive">
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-red-950 text-red-400' : 'text-red-500',
              'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
            ]"
            @click="confirmAutomationDeletion(automation.uuid)"
          >
            {{ $t('common.archive') }}
          </button>
        </MenuItem>
      </div>
    </template>
  </MagicTableEntry>
</template>
