<script setup lang="ts">
// external
import { onMounted } from "vue"
import axios from "axios"

// internal
import { AccountUser, Account } from "~/types"

interface Props {
  mau: AccountUser
  account: Account
  userLang?: string
  preventBoot?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  userLang: "en",
  preventBoot: false,
})

declare const window: any

const PRODUCT_ID = window.location.origin === "https://app.fynk.com" ? "fynk.com" : "stage-fynk.com"

const boot = (token) => {
  if (!!props.mau) {
    window.cello = window.cello || { cmd: [] }

    window.cello.cmd.push(async function (cello) {
      try {
        await cello.boot({
          productId: PRODUCT_ID,
          token: token,
          language: props.userLang.toUpperCase(),
          productUserDetails: {
            firstName: props.mau.first_name,
            lastName: props.mau.last_name,
            fullName: `${props.mau.first_name} ${props.mau.last_name}`,
            email: props.mau.email,
          },
        })
      } catch (error) {
        console.error("Failed to boot cello:", error)
        // Handle the error appropriately
      }
    })
  }
}

onMounted(async () => {
  if (!props.preventBoot) {
    try {
      const response = await axios.post<{ accessToken: string }>(route("api.cello.access-token.fetch", {
        account_user_uuid: props.mau.uuid,
      }))

      if (!!response?.data?.accessToken) {
        boot(response.data.accessToken)
      }
    } catch (error) {
      console.error("Failed to fetch cello token", error)
    }
  }
})

defineExpose({ boot })

</script>

<template>
  <slot />
</template>
