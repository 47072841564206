import { useCommentStore } from "~/stores"
import { Comment } from "~/types"
import renderHighlight from "~/editor/utils/renderHighlight"

export interface HtmlResult {
  element: HTMLElement
  uuid: Comment["uuid"]
  type: "comment" | "proposal" | "active"
}

const highlightClickHandler = (
  uuid: Comment["uuid"],
  commentStore: ReturnType<typeof useCommentStore>,
) => {
  if (!uuid) return

  if (uuid === commentStore.activeCommentProsemirrorDataUuid) {
    commentStore.setActiveCommentProsemirrorDataUuid("")
    commentStore.setHighlightedCommentUuids([])
    return
  }

  const commentWithUuidExists = commentStore.comments?.some((c) => c.prosemirror_data_uuid === uuid)

  if (!commentWithUuidExists) {
    commentStore.setHighlightedCommentUuids([])
  } else {
    commentStore.highlightCommentsInListByProsemirrorDataUuid(uuid)
  }
  commentStore.setIsHighlightedViaClick(true)
}

const markCommentsAndProposalsHtml = (
  container: HTMLDivElement,
  commentStore: ReturnType<typeof useCommentStore>,
) => {

  // Remove all previous highlights
  const highlights = container.querySelectorAll(".highlight-marker")
  highlights.forEach((highlight) => highlight.remove())

  // Check if active element is already included in state storage
  const commentWithUuidExists = commentStore.comments?.some(({ uuid }) => uuid === commentStore.activeCommentProsemirrorDataUuid)

  // Build query and if necessary concatenate active element
  const rawComments: Partial<Comment>[] = commentStore.comments?.slice()

  const query = commentWithUuidExists
    ? rawComments.map(
      (obj: HtmlResult) =>
        obj.uuid === commentStore.activeCommentProsemirrorDataUuid
          ? ({ ...obj, type: "active" as any })
          : obj,
    )
    : rawComments.concat({ uuid: commentStore.activeCommentProsemirrorDataUuid, type: "active" as any })

  // Find HTML elements by data-uuid attribute
  const results: HtmlResult[] = []
  query.forEach((comment: Partial<Comment>) => {
    const element = container.querySelector(`[data-uuid="${comment.prosemirror_data_uuid}"]`) as HTMLDivElement
    if (element) results.push({ element: element, uuid: comment.prosemirror_data_uuid, type: comment.type })
  })

  results.forEach(
    (result) => {
      let relatedComments: Comment[] = []

      if (
        result.uuid
        && [ "comment", "proposal" ].includes(result.type)
      ) {
        relatedComments = commentStore.getCommentsByProsemirrorDataUuid(result.uuid) || []

        if (
          relatedComments.some((comment) => commentStore.highlightedCommentUuids.includes(comment.uuid))
        ) result.type = "active"
      }

      let classList: string[] = null
      let highlight: HTMLDivElement = null

      if ([ "comment", "proposal" ].includes(result.type)) {
        classList = [ "has-highlight" ]
        highlight = renderHighlight(
          result,
          () => highlightClickHandler?.(result.uuid, commentStore),
          relatedComments,
        )
      } else if (result.type === "active") {
        classList = [ "has-highlight", "is-active" ]
        highlight = renderHighlight(
          result,
          () => highlightClickHandler?.(result.uuid, commentStore),
          relatedComments,
        )
      }

      result.element.classList.remove("has-highlight", "is-active")

      if (classList) {
        result.element.classList.add(...classList)
      }

      if (highlight) {
        result.element.appendChild(highlight)
      }

    },
  )

}

export default markCommentsAndProposalsHtml
