<script setup lang="ts">
// external
import { computed, ref, onMounted } from "vue"
import { storeToRefs } from "pinia"
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline"

// internal
import {
  EmptyState,
  MagicTable,
  AiAnalysisTableEntry,
  FilterBubbles,
  FilterHeader,
  FilterBase,
  AiAnalysisReviewModal,
  SpinLoader,
} from "~/components"
import { useFilters } from "~/composables"
import { Document, Metadata, Pagination, Team, AiAnalysis, ImportStatus, AiAnalysisStatus } from "~/types"
import { useAiStore } from "~/stores"

interface Props {
  pagination: Pagination<Document>
  paginationProp?: string[]
  aiAnalyses?: AiAnalysis[]
  metadata?: Metadata[]
  teams?: Team[]
  showFilter?: boolean
  status: ImportStatus
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isArchive: false,
    showFilter: true,
    aiAnalyses: null,
  },
)

const aiStore = useAiStore()
const { showAiAnalysisReviewModal } = storeToRefs(aiStore)
const { setShowAiAnalysisReviewModal } = aiStore

const {
  sortAttribute,
  sortDirection,
  isUpdatingList,
  hasActiveFilters,
  filterData,
  filterBubbles,
  filterValues,
  isSidebarOpen,
  setPerPage,
  resetSorting,
  resetFilter,
  clearBubble,
} = useFilters(
  props.isArchive ? "archived_at" : "created_at", "desc",
  props.paginationProp,
  props.showFilter ? [ "query", "teams", "archived", "status" ] : [],
  props.metadata ?? [],
)

const aiAnalysisUuid = ref(null)
const aiAnalysisDocument = ref<Document>(null)

const columns = computed(() => [
  {
    key: "document",
    required: true,
    active: true,
    sortAttribute: "name",
  },
  {
    key: "status",
    required: false,
    active: true,
  },
  {
    key: "timestamps",
    breakpoint: "xl",
    required: false,
    active: true,
  },
])

const sortAttributes = computed(() => {
  return [ "created_at", "updated_at", "archived_at" ]
})

const handleEmptyStateClick = () => {
  window.location.reload()
}

const startAiAnalysisReview = (data: { document: Document, aiAnalysisUuid: AiAnalysis["uuid"] }) => {
  aiAnalysisDocument.value = data.document
  aiAnalysisUuid.value = data.aiAnalysisUuid
  setShowAiAnalysisReviewModal(true)
}

onMounted(() => {
  if (location.hash === "#review") {

    const firstReviewableAnalysis = props.aiAnalyses.find((entry) => entry.status === AiAnalysisStatus.review)

    if (!!firstReviewableAnalysis) {
      const document = props.pagination.data.find((entry) => entry.uuid === firstReviewableAnalysis.document_uuid)

      if (!!document) {
        startAiAnalysisReview({ document: document, aiAnalysisUuid: firstReviewableAnalysis.uuid })
      }
    }
  }
})
</script>

<template>
  <div>
    <FilterBase
      :has-active-filters="hasActiveFilters"
      :show-filter="showFilter"
      :is-sidebar-open="isSidebarOpen"
      :filter-count="filterBubbles.length"
      @reset="resetFilter"
      @close="isSidebarOpen = false"
    >
      <template #default>
        <FilterHeader
          v-if="showFilter"
          v-model:query="filterValues.query"
          v-model:show-all-filters="isSidebarOpen"
          v-model:sort-attribute="sortAttribute"
          v-model:sort-direction="sortDirection"
          :sort-attributes="sortAttributes"
          class="mb-4"
          :has-active-filters="hasActiveFilters"
          :filter-bubbles="filterBubbles"
        />

        <FilterBubbles
          v-if="hasActiveFilters"
          class="mb-2"
          :filter-bubbles="filterBubbles"
          @clear="clearBubble"
          @reset="resetFilter"
        />

        <MagicTable
          v-model:sort-attribute="sortAttribute"
          v-model:sort-direction="sortDirection"
          :columns="columns"
          :paginator="props.pagination"
          :loading="isUpdatingList"
          :has-filters="hasActiveFilters"
          :filter-data="filterData"
          storage-key="aiAnalysisList"
          table-name="aiAnalysisList"
          :pagination-prop="paginationProp"
          @update:per-page="setPerPage"
          @reset-sorting="resetSorting"
          @reset-filter="resetFilter"
        >
          <template #default="{ entry, activeColumns, isLoading }">
            <AiAnalysisTableEntry
              :columns="activeColumns"
              :entry="entry"
              :loading="isLoading"
              :sort-attribute="sortAttribute"
              :is-archive="filterValues.archived"
              :ai-analyses="props.aiAnalyses"
              @start-analysis="startAiAnalysisReview"
            />
          </template>
          <template #empty>
            <EmptyState
              v-if="status === ImportStatus.CREATED"
              @click="handleEmptyStateClick"
            >
              <template #icon>
                <SpinLoader
                  aria-hidden="true"
                />
              </template>
              <template #call-to-action>
                <span>
                  {{ $t('imports.emptyAnalyses.description') }}
                </span>
              </template>
              <template #button-text>
                <span>
                  {{ $t('imports.emptyAnalyses.button') }}
                </span>
              </template>
              <template #default>
                {{ $t('imports.emptyAnalyses.title') }}
              </template>
            </EmptyState>
            <EmptyState
              v-else-if="status === ImportStatus.DOCUMENTS_CREATED"
              :hide-button="true"
            >
              <template #icon>
                <MagnifyingGlassIcon
                  aria-hidden="true"
                />
              </template>
              <template #call-to-action>
                <span>
                  {{ $t('imports.noDocuments.description') }}
                </span>
              </template>
              <template #default>
                {{ $t('imports.noDocuments.title') }}
              </template>
            </EmptyState>
          </template>
        </MagicTable>
      </template>
    </FilterBase>
    <AiAnalysisReviewModal
      :open="showAiAnalysisReviewModal"
      :document="aiAnalysisDocument"
      :ai-analysis-uuid="aiAnalysisUuid"
      @update:open="setShowAiAnalysisReviewModal($event)"
    />
  </div>
</template>
