<script setup lang="ts">
// external
import { computed } from "vue"
import { zxcvbn } from "@zxcvbn-ts/core"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline"

// internal

interface Props {
  value: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const score = computed(() => {
  const hasValue = props.value && props.value.length > 0

  if (!hasValue) {
    return 0
  }

  return Math.max(zxcvbn(props.value).score + 1 - penalty.value, props.value.length ? 1 : 0)
})

const scoreColor = computed(() => {
  if (score.value >= 5) {
    return "bg-green-400"
  }
  if (score.value >= 3) {
    return "bg-yellow-400"
  }
  return "bg-red-500"
})

const conditions = [
  {
    key: "minLength",
    regex: /.{8,}/, // Ensures password is at least 8 characters long
  },
  {
    key: "lowerChar",
    regex: /(?=.*[a-z])/, // Ensures at least one lowercase letter is present
  },
  {
    key: "upperChar",
    regex: /(?=.*[A-Z])/, // Ensures at least one uppercase letter is present
  },
  {
    key: "number",
    regex: /(?=.*\d)/, // Ensures at least one digit is present
  },
  {
    key: "specialChar",
    regex: /(?=.*[\W_])/, // Ensures at least one special character or underscore is present
  },
]

const penalty = computed(() => {
  let violationCount = 0
  for (const condition of conditions) {
    if (!condition.regex.test(props.value)) {
      violationCount++
    }
  }
  return violationCount
})

</script>

<template>
  <div>
    <div class="relative bg-gray-200 overflow-hidden rounded-full h-2 mb-3">
      <div
        class="absolute left-0 top-0 bottom-0 rounded-full transition-all duration-200"
        :class="scoreColor"
        :style="`width: ${score * 20}%`"
      />
    </div>
    <div>
      <div class="text-xs text-gray-500 font-medium mb-1">
        {{ $t('auth.register.passwordMustContain') }}:
      </div>
      <ul class="space-y-1">
        <li
          v-for="condition in conditions"
          :key="condition.key"
          class="text-xs flex items-center space-x-1"
          :class="condition.regex.test(value) ? 'text-green-600' : 'text-red-500'"
        >
          <CheckCircleIcon
            v-if="condition.regex.test(value)"
            aria-hidden="true"
            class="w-3 h-3"
          />
          <XCircleIcon
            v-else
            aria-hidden="true"
            class="w-3 h-3"
          />
          <span>{{ $t('auth.register.passwordConditions.' + condition.key, 8) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
