<script setup lang="ts">
// external
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import { CheckBadgeIcon, DocumentTextIcon } from "@heroicons/vue/24/outline"
import { ChevronDownIcon } from "@heroicons/vue/24/solid"
import axios from "axios"
import { onMounted, ref, computed } from "vue"

// internal
import { Document, DocumentOrigin, DocumentRevision, DocumentUser, SigningPhase } from "~/types"
import { formatDateAndTime, purifyHtml, getUserRepresentation, cyrb53 } from "~/utils"
import { DiffIcon } from "~/icons"
import { OverlayScrollbar, SpinLoader, PdfViewer } from "~/components"

import HtmlDiff from "htmldiff-js"
import { storeToRefs } from "pinia"
import { useSignatureStore } from "~/stores"

interface Props {
  document: Document
  users: DocumentUser[]
  loadRevisionUuid?: DocumentRevision["uuid"]
  revisions?: DocumentRevision[]
  revisionsLoading?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    revisions: () => [],
    revisionsLoading: false,
  },
)

const signatureStore = useSignatureStore()
const { signingPhases } = storeToRefs(signatureStore)

const loadedRevision = ref<DocumentRevision>(null)

const currentHtmlContent = ref("")
const oldHtmlContent = ref("")
const diffContent = ref("")
const isLoadingFetchRevisions = ref<boolean>(false)

const isPdf = computed(() => props.document?.origin === DocumentOrigin.pdf || props.document?.origin === DocumentOrigin.signed_pdf)
const revisionEndpoint = computed(() => isPdf.value ? "api.documents.document-revisions.document-revision-pdf.show" : "api.documents.document-revisions.document-revision-html.show")
const pdfUrl = ref(null)


const viewRevisionFormat = ref(isPdf.value ? "raw" : "diff")

onMounted(async () => {
  isLoadingFetchRevisions.value = true
  await axios
    .get(
      route(revisionEndpoint.value, {
        document: props.document.uuid,
        document_revision: props.revisions?.[0]?.uuid,
      }),
    )
    .then((res) => {
      if (isPdf.value) {
        pdfUrl.value = res.data.data.pdf_url
      } else {
        currentHtmlContent.value = res.data.data.html
        loadedRevision.value = props.revisions?.[0]
      }
    })
    .catch((err) => {
      console.error(err)
    })
    .finally(() => {
      isLoadingFetchRevisions.value = false
    })
  if (props.loadRevisionUuid) {
    const revision = props.revisions.find((el) => el.uuid === props.loadRevisionUuid)
    if (revision) {
      await loadRevision(revision)
    }
  }

})

const loadRevision = async (revision: DocumentRevision) => {
  isLoadingFetchRevisions.value = true
  return await axios
    .get(
      route(revisionEndpoint.value, {
        document: props.document.uuid,
        document_revision: revision.uuid,
      }),
    )
    .then((res) => {
      if (isPdf.value) {
        pdfUrl.value = res.data.data.pdf_url
      } else {
        oldHtmlContent.value = res.data.data.html
        const htmlDiff = new HtmlDiff(oldHtmlContent.value, currentHtmlContent.value)
        htmlDiff.ignoreWhiteSpaceDifferences = true
        htmlDiff.orphanMatchThreshold = 0
        diffContent.value = htmlDiff.build()
      }
      loadedRevision.value = revision
    })
    .catch((err) => {
      console.error(err)
    })
    .finally(() => {
      isLoadingFetchRevisions.value = false
    })
}

const signingPhaseOfLoadedRevision = computed(() => {
  if (!loadedRevision.value) return null
  const signingPhase = signingPhases.value?.find((el: SigningPhase) => el.document_revision_uuid === loadedRevision.value?.uuid)
  return signingPhase
})

const getUserByUuid = (uuid: DocumentUser["uuid"]) => {
  return props.users.find((el) => el.uuid === uuid)
}
</script>

<template>
  <div class="pb-6 -mt-4 bg-gray-200 rounded-md diff-container">
    <div class="items-center justify-between px-6 py-4 sm:flex">
      <div class="mb-2 text-lg sm:mb-0">
        {{ $t('documentDiff.compareRevisions') }}
      </div>
      <div
        v-if="!isPdf"
        class="grid items-center grid-cols-2 sm:flex"
      >
        <button
          type="button"
          :class="viewRevisionFormat === 'diff' ? 'btn-primary' : 'btn-white'"
          class="flex items-center gap-2 rounded-r-none btn-sm focus:z-10"
          @click.prevent="() => { viewRevisionFormat = 'diff' }"
        >
          <DiffIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('documentDiff.diffView') }}
        </button>
        <button
          type="button"
          :class="viewRevisionFormat === 'raw' ? 'btn-primary' : 'btn-white'"
          class="flex items-center gap-2 -ml-px border-l-0 rounded-l-none btn-sm"
          @click.prevent="() => { viewRevisionFormat = 'raw' }"
        >
          <DocumentTextIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('documentDiff.rawView') }}
        </button>
      </div>
    </div>
    <div class="relative z-10 flex items-center justify-between px-6">
      <div class="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-0">
        <div
          v-if="viewRevisionFormat === 'diff'"
          class="relative hidden h-full text-left sm:block"
        >
          <button
            type="button"
            disabled
            class="flex items-center w-full p-4 text-xs text-left text-gray-700 bg-gray-100 border border-r-0 border-gray-300 shadow-sm rounded-l-md rounded-r-md sm:rounded-r-none"
          >
            <div class="grow">
              <p class="flex items-center">
                <span class="px-1 mr-1 font-medium truncate bg-green-200 rounded-sm">{{ $t('documentDiff.current') }} {{ revisions[0]?.seal ? '{' + revisions[0]?.seal + '}' : '' }}</span>
                <span class="truncate">{{ getUserRepresentation(getUserByUuid(revisions[0]?.created_by_document_user_uuid)) }}</span>
                <span class="ml-1 text-gray-400">· {{ formatDateAndTime(revisions[0]?.created_at) }}</span>
              </p>
            </div>
            <CheckBadgeIcon
              class="w-5 h-5 ml-2 -mr-1"
              aria-hidden="true"
            />
          </button>
        </div>
        <div
          :class="viewRevisionFormat === 'raw' ? 'col-span-2' : ''"
          class="h-full"
        >
          <Menu
            as="div"
            class="relative text-left"
          >
            <div>
              <MenuButton
                :class="viewRevisionFormat === 'raw' ? 'rounded-md' : 'rounded-r-md rounded-l-md sm:rounded-l-none'"
                class="flex items-center w-full p-4 text-xs text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              >
                <div
                  v-if="loadedRevision"
                  class="grow"
                >
                  <p class="flex flex-col justify-between text-left items-left sm:flex-row sm:justify-start">
                    <span
                      v-if="loadedRevision.seal"
                      class="px-1 mr-1 font-medium text-left text-indigo-900 bg-indigo-200 rounded-sm"
                    >{{ loadedRevision.seal }}</span>
                    <span
                      v-else
                      class="px-1 mr-1 font-medium text-left text-indigo-900 bg-green-200 rounded-sm"
                    >{{ $t('documentDiff.current') }}</span>
                    <span class="text-left truncate">{{ getUserRepresentation(getUserByUuid(loadedRevision?.created_by_document_user_uuid)) }}</span>
                    <span class="text-left text-gray-400ml-1"><span class="hidden ml-1 sm:inline">· </span>{{ formatDateAndTime(loadedRevision.created_at) }}</span>
                  </p>
                </div>
                <div
                  v-else
                  class="text-left grow"
                >
                  {{ $t('documentDiff.selectRevision') }}…
                </div>
                <ChevronDownIcon
                  class="w-5 h-5 ml-2 -mr-1"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 w-auto max-w-3xl mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 divide-y divide-gray-100">
                  <MenuItem
                    v-for="revision in revisions"
                    :key="revision.uuid"
                    v-slot="{ active }"
                  >
                    <button
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-xs text-left disabled:opacity-50 disabled:pointer-events-none',
                        revision.uuid === loadedRevision.uuid ? 'opacity-50 pointer-events-none' : '',
                      ]"
                      @click.prevent="loadRevision(revision)"
                    >
                      <p class="flex flex-col justify-between items-left sm:flex-row sm:justify-start">
                        <span
                          v-if="revision.seal"
                          class="px-1 mr-1 font-medium text-left text-indigo-900 bg-indigo-200 rounded-sm"
                        >{{ revision.seal }}</span>
                        <span
                          v-else
                          class="px-1 mr-1 font-medium text-left text-indigo-900 bg-green-200 rounded-sm"
                        >{{ $t('documentDiff.current') }}</span>
                        <span class="text-left truncate">{{ getUserRepresentation(getUserByUuid(revision?.created_by_document_user_uuid)) }}</span>
                        <span class="text-left text-gray-400ml-1"><span class="hidden ml-1 sm:inline">· </span>{{ formatDateAndTime(loadedRevision.created_at) }}</span>
                      </p>
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <div class="px-3 pt-6 overflow-y-hidden">
      <OverlayScrollbar
        tag="div"
        class="h-[70vh]"
      >
        <div v-if="isLoadingFetchRevisions">
          <div class="p-10">
            <SpinLoader class="w-5 h-5 mx-auto" />
            <div class="mx-auto mt-2 text-sm font-medium text-center text-gray-500">
              {{ $t('common.pleaseWait') }}…
            </div>
          </div>
        </div>
        <div
          v-else-if="isPdf && pdfUrl"
          class="px-5 -my-6"
        >
          <PdfViewer
            :key="cyrb53(pdfUrl)"
            :disabled="true"
            :component-key="cyrb53(pdfUrl)"
            :src="pdfUrl"
            :signing-phase="signingPhaseOfLoadedRevision"
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-else-if="viewRevisionFormat === 'diff'"
          class="relative z-0 px-6 py-4 mx-4 mb-0 bg-white shadow-xl ProseMirror diff-content sm:py-10 sm:px-16 rendered-html-container"
          v-html="purifyHtml(diffContent || currentHtmlContent)"
        />
        <div
          v-else-if="viewRevisionFormat === 'raw'"
          class="relative z-0 px-6 py-4 mx-4 mb-0 bg-white shadow-xl ProseMirror diff-content sm:py-10 sm:px-16 rendered-html-container"
          v-html="purifyHtml(oldHtmlContent)"
        />
        <!-- eslint-enable vue/no-v-html -->
      </OverlayScrollbar>
    </div>
  </div>
</template>

<style lang="scss">
.diff-container {
  @apply relative;

  [data-list-uuid] {
    @apply relative pl-[5px] ml-[-5px] rounded-lg;
  }

  ins {
    background-color: #cfc;
    text-decoration: none;
  }

  del {
    color: #999;
    background-color: #fec8c8;
  }

  .signature del {
    @apply hidden;
  }
}
</style>
