<script setup lang="ts">
import { ref, watch } from "vue"

import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"

import { EllipsisHorizontalIcon } from "@heroicons/vue/24/solid"
import { Node as PMNode } from "@tiptap/pm/model"
import { Editor } from "@tiptap/vue-3"
import { isEqual } from "lodash-es"

import { AlphabeticalLowercaseIcon, AlphabeticalUppercaseIcon, BulletListIcon, NestedOrderOffIcon, NestedOrderOnIcon, NumericIcon, OrderedListIcon, ResetIcon, RomanNumeralIcon, RomanNumeralLowerIcon } from "~/icons"
import { useEditorStore } from "~/stores"

import { DelimiterStyle, NumberingStyle, NumberingType } from "./extensions/list/list"
import { getOrderRepresentation } from "./extensions/list/listHelpers"
import { ListKind } from "./extensions/list/prosemirror-flat-list"
import { useLocalStorage } from "@vueuse/core"

interface Props {
  editor: Editor
}

const props = defineProps<Props>()

const { t } = useI18n()

const editorStore = useEditorStore()
const {
  activeListItem,
} = storeToRefs(editorStore)

const activeListItemNumberingKind = ref<ListKind>()

const activeListItemNumberingStyle = ref<NumberingStyle>()

const activeListItemDelimiterStyle = ref<DelimiterStyle>()

const activeListItemNumberingType = ref<NumberingType>()

const activeListItemRepresentationPreview = ref<string[]>([])

const activeListItemShowNestedOrderRepresentation = ref<boolean>()

const setShowNestedOrderRepresentation = (showNestedOrderRepresentation: boolean) => {
  if (!activeListItem.value) return

  const { node, pos } = activeListItem.value

  props.editor
    .chain()
    .command(
      ({ tr, dispatch }) => {
        tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            showNestedOrderRepresentation,
          },
        )

        return dispatch?.(tr)
      },
    )
    .focus()
    .run()
}


watch(
  () => activeListItem.value,
  (data, oldData) => {
    if (isEqual(data?.node?.attrs, oldData?.node?.attrs)) return

    if (!data) return

    activeListItemNumberingKind.value = data.node.attrs.kind

    activeListItemNumberingStyle.value = data.node.attrs.numberingStyle

    activeListItemDelimiterStyle.value = data.node.attrs.delimiterStyle

    activeListItemNumberingType.value = data.node.attrs.numberingType

    activeListItemShowNestedOrderRepresentation.value = data.node.attrs.showNestedOrderRepresentation

    const $pos = props.editor.state.tr.doc.resolve(data.pos)

    if ($pos.parent.type.name === "list") {
      const parentOrderRepresentation = $pos.parent.attrs.orderRepresentation
      const parentDelimiterStyle = $pos.parent.attrs.delimiterStyle

      const getOrderRepresentationArgs: Parameters<typeof getOrderRepresentation>[0] = {
        order: data.node.attrs.order,
        numberingStyle: data.node.attrs.numberingStyle,
        delimiterStyle: data.node.attrs.delimiterStyle,
        parentOrderRepresentation,
        parentDelimiterStyle,
        showNestedOrderRepresentation: data.node.attrs.showNestedOrderRepresentation,
      }

      activeListItemRepresentationPreview.value["dot"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "dot",
        },
      )

      activeListItemRepresentationPreview.value["parenthesis"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "parenthesis",
        },
      )

      activeListItemRepresentationPreview.value["double-parenthesis"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "double-parenthesis",
        },
      )

      activeListItemRepresentationPreview.value["section-mark"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "section-mark",
        },
      )

    } else {
      const getOrderRepresentationArgs: Parameters<typeof getOrderRepresentation>[0] = {
        order: data.node.attrs.order,
        numberingStyle: data.node.attrs.numberingStyle,
        delimiterStyle: data.node.attrs.delimiterStyle,
      }

      activeListItemRepresentationPreview.value["dot"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "dot",
        },
      )

      activeListItemRepresentationPreview.value["parenthesis"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "parenthesis",
        },
      )

      activeListItemRepresentationPreview.value["double-parenthesis"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "double-parenthesis",
        },
      )

      activeListItemRepresentationPreview.value["section-mark"] = getOrderRepresentation(
        {
          ...getOrderRepresentationArgs,
          delimiterStyle: "section-mark",
        },
      )
    }
  },
)

interface NumberingKindOption {
  name: string
  value: ListKind
  icon: any
}

const numberingKindOptions: NumberingKindOption[] = [
  {
    name: t("editor.numberingKind.ordered"),
    value: "ordered",
    icon: OrderedListIcon,
  },
  {
    name: t("editor.numberingKind.bullet"),
    value: "bullet",
    icon: BulletListIcon,
  },
]


interface NumberingStyleOption {
  name: string
  value: NumberingStyle
  icon: any
}

const numberingStyleOptions: NumberingStyleOption[] = [
  {
    name: t("editor.numberingStyle.decimal"),
    value: "decimal",
    icon: NumericIcon,
  },
  {
    name: t("editor.numberingStyle.upperAlpha"),
    value: "upper-alpha",
    icon: AlphabeticalUppercaseIcon,
  },
  {
    name: t("editor.numberingStyle.lowerAlpha"),
    value: "lower-alpha",
    icon: AlphabeticalLowercaseIcon,
  },
  {
    name: t("editor.numberingStyle.upperRoman"),
    value: "upper-roman",
    icon: RomanNumeralIcon,
  },
  {
    name: t("editor.numberingStyle.lowerRoman"),
    value: "lower-roman",
    icon: RomanNumeralLowerIcon,
  },
]

const setNumberingKind = (newKind: ListKind) => {
  setTimeout(
    () => {
      if (!activeListItem.value) return

      const { node, pos } = activeListItem.value

      props.editor
        .chain()
        .command(
          ({ tr, dispatch }) => {
            tr.setNodeMarkup(
              pos,
              null,
              {
                ...node.attrs,
                kind: newKind,
              },
            )

            return dispatch?.(tr)
          },
        )
        .focus()
        .run()
    },
  )
}

const setNumberingStyle = (newStyle: NumberingStyle) => {
  if (!activeListItem.value) return

  const { node, pos } = activeListItem.value

  props.editor
    .chain()
    .command(
      ({ tr, dispatch }) => {
        tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            numberingStyle: newStyle,
          },
        )

        return dispatch?.(tr)
      },
    )
    .focus()
    .run()
}

interface DelimiterStyleOption {
  name: string
  value: DelimiterStyle
  iconRepresentation: string
}

const delimiterStyleOptions: DelimiterStyleOption[] = [
  {
    name: t("editor.delimiterStyle.sectionMark"),
    value: "section-mark",
    iconRepresentation: "§1",
  },
  {
    name: t("editor.delimiterStyle.dot"),
    value: "dot",
    iconRepresentation: "1.",
  },
  {
    name: t("editor.delimiterStyle.parenthesis"),
    value: "parenthesis",
    iconRepresentation: "1)",
  },
  {
    name: t("editor.delimiterStyle.doubleParenthesis"),
    value: "double-parenthesis",
    iconRepresentation: "(1)",
  },
]

const setDelimiterStyle = (newStyle: DelimiterStyle) => {
  if (!activeListItem.value) return

  const { node, pos } = activeListItem.value

  props.editor
    .chain()
    .command(
      ({ tr, dispatch }) => {
        tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            delimiterStyle: newStyle,
          },
        )

        return dispatch?.(tr)
      },
    )
    .focus()
    .run()
}

interface NumberingTypeOptions {
  name: string
  value: NumberingType
  icon: any
}

const numberingTypeOptions: NumberingTypeOptions[] = [
  {
    name: t("editor.numberingType.restart"),
    value: "restart",
    icon: ResetIcon,
  },
  {
    name: t("editor.numberingType.continue"),
    value: "continue",
    icon: EllipsisHorizontalIcon,
  },
]

const setNumberingType = (newType: NumberingType) => {
  if (!activeListItem.value) return

  const { node, pos } = activeListItem.value

  props.editor
    .chain()
    .command(
      ({ tr, dispatch }) => {
        tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            numberingType: newType,
          },
        )

        return dispatch?.(tr)
      },
    )
    .focus()
    .run()
}

/**
 * Applies the numbering to the same list
 * that the currently active list item belongs to.
 *
 * If no config is provided, the config of the currently active list item is used.
 *
 * @param config
 */
const applyNumberingToSameList = (config: Record<string, any> = {}) => {
  if (!Object.keys(config).length) {
    const { node: listNode } = activeListItem.value

    config = {
      numberingStyle: listNode.attrs.numberingStyle,
      delimiterStyle: listNode.attrs.delimiterStyle,
      showNestedOrderRepresentation: listNode.attrs.showNestedOrderRepresentation,
      kind: listNode.attrs.kind,
    }
  }

  const e = props.editor

  e.commands.focus()

  const { lists, rootListData } = getEditorLists()

  const indexOfListContainingRootListNode = lists.findIndex(
    (list) => list.some(
      (i) => i.node.attrs.uuid === rootListData.node.attrs.uuid,
    ),
  )

  if (indexOfListContainingRootListNode === -1) return

  const listContainingRootListNode = lists[indexOfListContainingRootListNode]
  const listStart = listContainingRootListNode[0].pos
  const listEnd = listContainingRootListNode[listContainingRootListNode.length - 1].pos + listContainingRootListNode[listContainingRootListNode.length - 1].node.nodeSize

  const tr = e.state.tr

  e.state.doc.nodesBetween(
    listStart,
    listEnd,
    (node, pos) => {
      const isOrderedList = node.type.name === "list" && node.attrs.kind === "ordered"

      if (!isOrderedList) return

      let isConfigEqual = false

      for (const key in config) {
        if (node.attrs[key] !== config[key]) {
          isConfigEqual = false
          break
        }

        isConfigEqual = true
      }

      if (isConfigEqual) return

      tr.setNodeMarkup(
        pos,
        null,
        {
          ...node.attrs,
          ...(config),
        },
      )
    },
  )

  if (tr.docChanged) e.view.dispatch(tr)
}

/**
 * Applies the numbering to all lists in the document.
 */
const applyNumberingGlobally = () => {
  const { node: listNode } = activeListItem.value
  const e = props.editor

  e.commands.focus()

  const tr = e.state.tr

  e.state.doc.descendants(
    (node, pos) => {
      if (node.type.name !== "list") return

      if (
        node.attrs.numberingStyle === listNode.attrs.numberingStyle
        && node.attrs.delimiterStyle === listNode.attrs.delimiterStyle
        && node.attrs.showNestedOrderRepresentation === listNode.attrs.showNestedOrderRepresentation
        && node.attrs.kind === listNode.attrs.kind
      ) return

      tr.setNodeMarkup(
        pos,
        null,
        {
          ...node.attrs,
          numberingStyle: listNode.attrs.numberingStyle,
          delimiterStyle: listNode.attrs.delimiterStyle,
          showNestedOrderRepresentation: listNode.attrs.showNestedOrderRepresentation,
          kind: listNode.attrs.kind,
        },
      )
    },
  )

  if (tr.docChanged) e.view.dispatch(tr)
}

interface NodeData {
  node: PMNode
  pos: number
}

interface RootListData {
  node: PMNode
  from: number
  to: number

  parent: PMNode
  parentFrom: number
  parentTo: number
}

/**
 * Returns an array of lists and the root list data.
 */
const getEditorLists = () => {
  const { pos, node: listNode } = activeListItem.value
  const e = props.editor

  const $listItemPos = e.state.doc.resolve(pos)

  let rootListData: RootListData = null

  if ($listItemPos.depth !== 0) {
    for (let i = $listItemPos.depth; i >= 0; i--) {
      const node = $listItemPos.node(i)

      if (node.type.name !== "list") continue

      const data: RootListData = {
        node,
        from: $listItemPos.start(i),
        to: $listItemPos.end(i),

        ...(
          i > 0
            ? {
              parent: $listItemPos.node(i - 1),
              parentFrom: $listItemPos.start(i - 1),
              parentTo: $listItemPos.end(i - 1),
            }
            : {
              parent: e.state.doc,
              parentFrom: 0,
              parentTo: e.state.doc.nodeSize,
            }
        ),
      }

      rootListData = data

      break
    }

    if (!rootListData.node) return
  } else {
    rootListData = {
      node: listNode,
      from: $listItemPos.start(),
      to: $listItemPos.end(),

      parent: e.state.doc,
      parentFrom: 0,
      parentTo: e.state.doc.nodeSize,
    }
  }

  let listIndex = 0
  let lists: NodeData[][] = []

  const rootListSiblingsArray = rootListData?.parent?.content

  rootListSiblingsArray.forEach(
    (node, offset) => {
      const isOrderedList = node.type.name === "list" && node.attrs.kind === "ordered"
      const isRestartList = isOrderedList && node.attrs.numberingType === "restart"

      if (!isOrderedList || isRestartList) {
        listIndex += 1
        return
      }

      const nodeFrom = rootListData.parentFrom + offset
      const nodeData = {
        node,
        pos: nodeFrom,
      }

      lists[listIndex] = [
        ...(lists[listIndex] || []),
        nodeData,
      ]
    },
  )

  lists = lists.filter(Boolean)

  return {
    rootListData,
    lists,
  }
}

const applyNumberingToSameLevel = () => {
  const { node: listNode, pos } = activeListItem.value
  const e = props.editor

  e.commands.focus()

  const $listItemPos = e.state.doc.resolve(pos)
  const listItemDepth = $listItemPos.depth

  const parent = $listItemPos.parent

  if (parent.type.name === "list") {
    const parentFrom = $listItemPos.start($listItemPos.depth)

    const tr = e.state.tr

    parent.forEach(
      (node, offset) => {
        if (node.type.name !== "list") return

        if (
          node.attrs.numberingStyle === listNode.attrs.numberingStyle
          && node.attrs.delimiterStyle === listNode.attrs.delimiterStyle
          && node.attrs.showNestedOrderRepresentation === listNode.attrs.showNestedOrderRepresentation
          && node.attrs.kind === listNode.attrs.kind
        ) return

        const pos = parentFrom + offset

        tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            numberingStyle: listNode.attrs.numberingStyle,
            delimiterStyle: listNode.attrs.delimiterStyle,
            showNestedOrderRepresentation: listNode.attrs.showNestedOrderRepresentation,
            kind: listNode.attrs.kind,
          },
        )
      },
    )

    if (tr.docChanged) e.view.dispatch(tr)

    return
  }

  const { lists, rootListData } = getEditorLists()

  const indexOfListContainingRootListNode = lists.findIndex(
    (list) => list.some((listItem) => listItem.node.attrs.uuid === rootListData.node.attrs.uuid),
  )

  if (indexOfListContainingRootListNode === -1) return

  const listContainingRootListNode = lists[indexOfListContainingRootListNode]
  const listStart = listContainingRootListNode[0].pos
  const listEnd = listContainingRootListNode[listContainingRootListNode.length - 1].pos + listContainingRootListNode[listContainingRootListNode.length - 1].node.nodeSize

  const tr = e.state.tr

  parent.nodesBetween(
    listStart,
    listEnd,
    (node, pos) => {
      if (node.type.name !== "list") return

      const nodeDepth = e.state.doc.resolve(pos).depth

      if (nodeDepth !== listItemDepth) return

      if (
        node.attrs.numberingStyle === listNode.attrs.numberingStyle
        && node.attrs.delimiterStyle === listNode.attrs.delimiterStyle
        && node.attrs.showNestedOrderRepresentation === listNode.attrs.showNestedOrderRepresentation
      ) return

      tr.setNodeMarkup(
        pos,
        null,
        {
          ...node.attrs,
          numberingStyle: listNode.attrs.numberingStyle,
          delimiterStyle: listNode.attrs.delimiterStyle,
          showNestedOrderRepresentation: listNode.attrs.showNestedOrderRepresentation,
        },
      )
    },
  )

  if (tr.docChanged) e.view.dispatch(tr)
}

const isAdvanceMenuVisible = useLocalStorage<boolean>("is-advanced-menu-visible", false)

interface PredefinedListFormat {
  title: string,
  values: string[],
  attrs: Record<string, any>,
  isActive?: () => boolean,
  action: () => void,
}

const predefinedListFormats: PredefinedListFormat[] = [
  {
    title: "1.",
    values: [ "(1)", "(1.1)", "(1.1.1)" ],
    attrs: {
      numberingStyle: "decimal",
      delimiterStyle: "double-parenthesis",
      showNestedOrderRepresentation: true,
    },
    isActive () {
      if (!activeListItem.value) return false

      const { node: listNode } = activeListItem.value

      return (
        listNode.attrs.numberingStyle === "decimal"
        && listNode.attrs.delimiterStyle === "double-parenthesis"
        && listNode.attrs.showNestedOrderRepresentation === true
      )
    },
    action () {
      applyNumberingToSameList(this.attrs)
    },
  },
  {
    title: "I.",
    values: [ "I", "I.I", "I.I.I" ],
    attrs: {
      numberingStyle: "upper-roman",
      delimiterStyle: "dot",
      showNestedOrderRepresentation: true,
    },
    isActive () {
      if (!activeListItem.value) return false

      const { node: listNode } = activeListItem.value

      return (
        listNode.attrs.numberingStyle === "upper-roman"
        && listNode.attrs.delimiterStyle === "dot"
        && listNode.attrs.showNestedOrderRepresentation === true
      )
    },
    action () {
      applyNumberingToSameList(this.attrs)
    },
  },
  {
    title: "A.",
    values: [ "(A)", "(A.A)", "(A.A.A)" ],
    attrs: {
      numberingStyle: "upper-alpha",
      delimiterStyle: "double-parenthesis",
      showNestedOrderRepresentation: true,
    },
    isActive () {
      if (!activeListItem.value) return false

      const { node: listNode } = activeListItem.value

      return (
        listNode.attrs.numberingStyle === this.attrs.numberingStyle
        && listNode.attrs.delimiterStyle === "double-parenthesis"
        && listNode.attrs.showNestedOrderRepresentation === true
      )
    },
    action () {
      applyNumberingToSameList(this.attrs)
    },
  },
]
</script>

<template>
  <div
    id="numbering-menu"
    class="flex flex-col text-slate-400 text-xs gap-2 px-2.5 py-2.5 overflow-hidden bg-slate-800 rounded-md shadow-lg flex-nowrap ring ring-opacity-30 ring-slate-500"
  >
    <div class="flex items-center justify-between gap-16">
      <label class="text-[10px] font-medium tracking-wider uppercase text-slate-500 pb-0.5">
        {{ t('editor.selectFormat') }}
      </label>

      <div class="flex items-center gap-2">
        <label
          for="comments"
          class="cursor-pointer text-[10px] font-medium tracking-wider uppercase text-slate-500"
        >
          {{ $t('editor.advancedNumbering') }}
        </label>
        <input
          v-model="isAdvanceMenuVisible"
          type="checkbox"
          class="w-4 h-4 bg-gray-900 border-gray-600 rounded cursor-pointer focus:outline-none focus:ring-offset-slate-800 focus:ring-slate-600"
        >
      </div>
    </div>

    <Transition
      enter-from-class="translate-x-[150%] opacity-0"
      enter-active-class="transition duration-300"
      mode="out-in"
    >
      <section
        v-if="isAdvanceMenuVisible"
        class="grid grid-cols-3 gap-2.5"
      >
        <template v-if="activeListItemNumberingKind === 'ordered'">
          <span class="flex items-center font-semibold">
            {{ $t('editor.format') }}
          </span>

          <section class="flex items-center col-span-2 gap-1 p-1 rounded-lg bg-slate-900">
            <button
              v-for="option in numberingStyleOptions"
              :key="option.value"
              class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center grow gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
              :class="activeListItemNumberingStyle === option.value ? 'bg-slate-700' : 'bg-slate-900'"
              @click="setNumberingStyle(option.value)"
            >
              <component
                :is="option.icon"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </button>
          </section>

          <span class="flex items-center font-semibold">
            {{ $t('editor.continuation') }}
          </span>

          <section class="grid grid-cols-2 col-span-2 gap-1 p-1 rounded-lg bg-slate-900">
            <button
              v-for="option in numberingTypeOptions"
              :key="option.value"
              class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
              :class="activeListItemNumberingType === option.value ? 'bg-slate-700' : 'bg-slate-900'"
              @click="setNumberingType(option.value)"
            >
              <component
                :is="option.icon"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ option.name }}
            </button>
          </section>
          <span class="flex items-center font-semibold">
            {{ $t('editor.nesting') }}
          </span>

          <section class="grid grid-cols-2 col-span-2 gap-1 p-1 rounded-lg bg-slate-900">
            <button
              v-for="option in [true, false]"
              :key="`${option}`"
              class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
              :class="activeListItemShowNestedOrderRepresentation === option ? 'bg-slate-700' : 'bg-slate-900'"
              @click="setShowNestedOrderRepresentation(option)"
            >
              <component
                :is="option ? NestedOrderOnIcon : NestedOrderOffIcon"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ option ? $t('common.on') : $t('common.off') }}
            </button>
          </section>

          <span class="flex items-center font-semibold">
            {{ $t('editor.style') }}
          </span>

          <section class="grid grid-cols-4 col-span-2 gap-1 p-1 rounded-lg bg-slate-900">
            <button
              v-for="option in delimiterStyleOptions"
              :key="option.value"
              class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
              :class="activeListItemDelimiterStyle === option.value ? 'bg-slate-700' : 'bg-slate-900'"
              @click="setDelimiterStyle(option.value)"
            >
              <span class="truncate w-full max-w-[4rem]">
                {{ activeListItemRepresentationPreview[option.value] || option.iconRepresentation }}
              </span>
            </button>
          </section>
        </template>

        <span class="flex items-center font-semibold">
          {{ $t('editor.kind') }}
        </span>

        <section class="flex items-center col-span-2 gap-1 p-1 rounded-lg bg-slate-900">
          <button
            v-for="option in numberingKindOptions"
            :key="option.value"
            class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center grow gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
            :class="activeListItemNumberingKind === option.value ? 'bg-slate-700' : 'bg-slate-900'"
            @click="setNumberingKind(option.value)"
          >
            <component
              :is="option.icon"
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />

            {{ option.name }}
          </button>
        </section>

        <span class="flex items-center font-semibold">
          {{ $t('editor.applyFormat') }}
        </span>

        <section class="grid grid-cols-3 col-span-2 gap-1 p-1 rounded-lg bg-slate-900">
          <button
            class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
            @click="applyNumberingToSameLevel()"
          >
            {{ $t('editor.onSameLevel') }}
          </button>
          <button
            class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
            @click="applyNumberingToSameList()"
          >
            {{ $t('editor.onSameList') }}
          </button>
          <button
            class="text-slate-100 rounded-md hover:bg-slate-700 p-1.5 flex items-center justify-center gap-1 focus:ring-slate-600 focus:outline-none focus:ring-2 focus:z-10"
            @click="applyNumberingGlobally()"
          >
            {{ $t('editor.globally') }}
          </button>
        </section>
      </section>

      <section
        v-else
        class="grid gap-3"
        :class="`grid-cols-${predefinedListFormats?.length || 3}`"
      >
        <article
          v-for="(predefinedListFormat, i) in predefinedListFormats"
          :key="predefinedListFormat.title + i"
          class="flex flex-col p-2 rounded cursor-pointer bg-slate-900"
          :class="{
            'bg-slate-700 outline outline-2 outline-slate-600': predefinedListFormat.isActive?.(),
          }"
          @click="predefinedListFormat.action()"
        >
          <span class="mb-2 font-bold">
            {{ predefinedListFormat.title }}
          </span>

          <span
            v-for="(value, index) of predefinedListFormat.values"
            :key="value"
            :style="{
              marginLeft: `${index * 1}rem`,
            }"
          >
            {{ value }}
          </span>
        </article>
      </section>
    </Transition>
  </div>
</template>
