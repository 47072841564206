import { Extension } from "@tiptap/core"

import { Table } from "./extension-table"
import { TableCell } from "./extension-table-cell"
import { TableHeader } from "./extension-table-header"
import { TableRow } from "./extension-table-row"

export const TableExtension = Extension.create(
  {
    name: "tableExtension",

    addExtensions () {
      return [
        Table.configure(
          {
            resizable: true,
            allowTableNodeSelection: true,
            lastColumnResizable: false,
            cellMinWidth: 50,
          },
        ),
        TableCell.configure(),
        TableHeader.configure(),
        TableRow.configure(),
      ]
    },
  },
)
