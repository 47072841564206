import { AlignmentType, convertInchesToTwip, LevelFormat, ILevelsOptions, IRunOptions, IParagraphOptions } from "docx"
import { DelimiterStyleMap } from "~/utils"
function basicNumberingStyle (indent) {
  return {
    alignment: AlignmentType.START,
    style: {
      paragraph: {
        indent: { left: convertInchesToTwip(indent / 2), hanging: convertInchesToTwip(0.18) },
      },
    },
  }
}

const getDelimiter = (delimiterStyle: string): {
  delimiter: string,
  prefix: string,
  suffix: string,
  finalDelimiter: boolean,
} => {
  let delimiter = "."
  let prefix = ""
  let suffix = ""
  let finalDelimiter = true
  switch (delimiterStyle) {
    case "dot":
      delimiter = "."
      break
    case "parenthesis":
      delimiter = ")"
      break
    case "double-parenthesis":
      delimiter = "."
      prefix = "("
      suffix = ")"
      finalDelimiter = false
      break
    case "section-mark":
      delimiter = "."
      prefix = "§"
      finalDelimiter = false
      break
    default:
      break
  }
  return { delimiter, prefix, suffix, finalDelimiter }
}

const numbered = Array(3)
  .fill([ LevelFormat.DECIMAL, LevelFormat.LOWER_LETTER, LevelFormat.LOWER_ROMAN ])
  .flat()
  .map((format, level) => {
    const delimiterOptions = getDelimiter(DelimiterStyleMap[level % 3])
    return (Object.assign({
      level,
      format,
      text: `${level === 0 ? delimiterOptions.prefix : ""}%${level + 1}${delimiterOptions.finalDelimiter ? delimiterOptions.delimiter : ""}`,
    }, basicNumberingStyle((level + 1) / 2)))},
  )

const bullets = Array(3)
  //.fill([ "●", "○", "■" ])
  .fill([ "●", "●", "●" ])
  .flat()
  .map((text, level) => {
    return (Object.assign({
      level,
      format: LevelFormat.BULLET,
      text,
    }, basicNumberingStyle((level + 1) / 2)))
  })

const legal = Array(3)
  .fill([ LevelFormat.DECIMAL, LevelFormat.LOWER_LETTER, LevelFormat.LOWER_ROMAN ])
  .flat()
  .map((format, level) => {

    const delimiterOptions = getDelimiter(DelimiterStyleMap[level % 3])
    return (Object.assign({
      level,
      isLegalNumberingStyle: true,
      format,
      text: `${level === 0 ? delimiterOptions.prefix : ""}%${level + 1}${delimiterOptions.finalDelimiter ? delimiterOptions.delimiter : ""}`,
    }, basicNumberingStyle((level + 1) / 2)))
  })

const styles: Record<string, ILevelsOptions[]> = {
  numbered,
  bullets,
  legal,
}

const generateStylesWithRunOptions = (
  style: string,
  runOptions: IRunOptions,
  paragraphOptions: IParagraphOptions,
  rootLevelStart: number = null,
) => {
  const styleMap = styles[style]
  if (!styleMap) {
    throw new Error(`Style ${style} not found`)
  }
  return styleMap.map((level) => {
    let start = null
    if (level.level === 0) start = rootLevelStart
    const levelStyle = { ...level.style }
    levelStyle.run = { ...levelStyle.run, ...runOptions }
    levelStyle.paragraph = { ...levelStyle.paragraph, ...paragraphOptions }
    let returnValue = {
      level: level.level,
      format: level.format,
      text: level.text,
      isLegalNumberingStyle: level.isLegalNumberingStyle,
      style: levelStyle,
    }
    if (start) returnValue = { ...returnValue, start } as any
    return returnValue
  })
}

const numberingState = {
  start: 1,
}

export const resetNumberingState = () => {
  numberingState.start = 1
}

export function createNumbering (reference: string, style: string, numberingOptions, runOptions: IRunOptions = {}, paragraphOptions: IParagraphOptions = {}) {
  if (numberingOptions?.numberingType === "restart") resetNumberingState()
  const rootLevelStart = numberingOptions?.numberingType === "continue" ? numberingState.start : null
  const styleMap = generateStylesWithRunOptions(style, runOptions, paragraphOptions, rootLevelStart)
  if (numberingOptions?.numberingType === "continue") numberingState.start += 1
  return {
    reference,
    levels: styleMap,
  }
}
