<script setup lang="ts">
// external
import { computed, ref, onBeforeMount } from "vue"
import { WrenchScrewdriverIcon } from "@heroicons/vue/24/solid"
import { router } from "@inertiajs/vue3"

// internal
import { DashboardCustomWidgetModal, DashboardWidget, DashboardDataDisplay } from "~/components"
import { useDashboardStore } from "~/stores"
import { ChartType, DashboardGridItem } from "~/types"

interface Props {
  item: DashboardGridItem
}
const props = withDefaults(
  defineProps<Props>(),
  {
    item: null,
  },
)

const dashboardStore = useDashboardStore()
const { updateCustomWidget, getCustomWidget } = dashboardStore

const uuid = computed(() => props.item.i.split("_")[1])

const customWidget = ref(null)
const isLoading = ref(false)
const hasError = ref(false)

const showModal = ref(false)

const endpoint = computed(() => {
  if (customWidget.value?.config) {
    if (customWidget.value?.config?.time_metadata_uuid) {
      return route("api.data.documents.valuesByTime", {
        metadata: customWidget.value.config.metadata_uuid,
        time_metadata: customWidget.value.config.time_metadata_uuid,
        time_group: customWidget.value.config.time_group,
      })
    } else {
      return route("api.data.documents.metadataValues", customWidget.value.config.metadata_uuid)
    }
  }
  return ""
})
onBeforeMount(async () => {
  isLoading.value = true
  customWidget.value = await getCustomWidget(uuid.value)
  isLoading.value = false
})

const updateWidget = async (payload) => {
  isLoading.value = true
  customWidget.value = await updateCustomWidget(payload)
  isLoading.value = false
  hasError.value = false
}

const handleClick = ({ event, uniqueEntries, config }) => {
  const clickedSeriesIndex = event.target.parentElement.getAttribute("data:realIndex")

  if (clickedSeriesIndex !== null) {
    if ([ ChartType.donut, ChartType.pie ].includes(config.chart_type)) {
      const clickedValue = uniqueEntries[parseInt(clickedSeriesIndex)]

      const params = clickedValue ? `?${config.metadata_name}=${clickedValue}` : ""

      router.visit(route("documents.index") + params)
    }
  }
}
</script>

<template>
  <div class="w-full h-full">
    <DashboardWidget
      v-if="!customWidget?.config || isLoading || hasError"
      :loading="isLoading"
      :title="customWidget?.name || $t('dashboard.unconfiguredWidget')"
      :box-classes="isLoading ? '' : 'bg-gradient-to-br ' + (hasError ? 'from-white to-red-50' :'from-yellow-100 via-yellow-50 to-yellow-100')"
    >
      <template #default>
        <div
          class="flex flex-col space-y-5 w-full h-full items-center justify-center min-h-[80px]"
        >
          <span
            v-if="hasError"
            class="text-sm font-medium text-red-600 text-center px-2"
          >{{ $t('dashboard.widgetLoadingError') }}</span>
          <button
            class="flex items-center space-x-2"
            :class="hasError ? 'btn-red' : 'btn-yellow'"
            type="button"
            @click="showModal = true"
          >
            <WrenchScrewdriverIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('dashboard.configureWidget') }}</span>
          </button>
        </div>
      </template>
    </DashboardWidget>
    <DashboardWidget
      v-else
      :title="customWidget.name"
      :href="endpoint"
      box-classes="p-2"
      @error="hasError = true"
      @loaded="hasError = false"
    >
      <template #default="{data}">
        <DashboardDataDisplay
          :data="data"
          :config="customWidget.config"
          :name="customWidget.name"
          @chart-click="handleClick"
        />
      </template>
      <template #options>
        <button
          type="button"
          class="btn-sm btn-white"
          @click="showModal = true"
        >
          <WrenchScrewdriverIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </button>
      </template>
    </DashboardWidget>
    <DashboardCustomWidgetModal
      v-if="customWidget"
      v-model:show="showModal"
      :widget="customWidget"
      :item="item"
      @submit="updateWidget"
    />
  </div>
</template>
