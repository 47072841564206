<script setup lang="ts">
// external
import { ref, computed } from "vue"
import { Form, Field } from "vee-validate"
import axios from "axios"
import { router } from "@inertiajs/vue3"
import { LightBulbIcon } from "@heroicons/vue/24/outline"
import { toTypedSchema } from "@vee-validate/zod"
import { z } from "zod"
import { useI18n } from "vue-i18n"
import { pick } from "lodash-es"

// internal
import { Document, DocumentUser, Account, DocumentUserRoleEnum } from "~/types"
import { changedKeys, getUserRepresentation } from "~/utils"
import { FormInputErrors } from "../base"

const { t } = useI18n()

interface Props {
  document: Document
  mdu: DocumentUser
  isSubmitting: boolean
  account: Account
}

const props = withDefaults(defineProps<Props>(), {})

const submitButton = ref()
const form = ref()

const isEditing = ref(false)

const isJustSignatory = computed(() => {
  return !!(props.mdu.roles.length === 1 && props.mdu.roles.includes(DocumentUserRoleEnum.signatory))
})

const userData = ref({
  first_name: props.mdu.first_name || "",
  last_name: props.mdu.last_name || "",
  email: props.mdu.email,
  title: props.mdu.title,
})

const initialData = ref({
  ...userData.value,
})

const userRepresentation = computed(() => {
  return getUserRepresentation(props.mdu)
})

const validationSchema = toTypedSchema(
  z.object({
    first_name: z.string().nonempty(t("common.fieldRequired")),
    last_name: z.string().nonempty(t("common.fieldRequired")),
  }),
)

const saveStep = () => {
  if (!isEditing.value && initialData.value.first_name && initialData.value.last_name) {
    emit("next-step")
    return
  }
  submitButton.value.click()
}

const submit = async (values) => {
  if (form.value.getMeta().dirty) {
    const url = route("api.documents.document-users.update", {
      document_user: props.mdu.uuid,
      document: props.document.uuid,
    })

    const payload = pick(values, changedKeys(values, initialData.value))

    try {
      emit("update:is-submitting", true)
      const res = await axios.patch<{data: DocumentUser}>(url, payload)

      if (res.data.data) {
        router.get(route("d.setup", props.mdu.uuid), {}, {
          replace: true,
          preserveState: true,
          preserveScroll: true,
          only: [ "mdu", "documentUsers" ],
          onFinish: () => {
            emit("update:is-submitting", false)
            emit("next-step")
          },
        })
      }
    } catch (err) {
      emit("update:is-submitting", false)
    }
  } else {
    emit("next-step")
  }
}

const emit = defineEmits([ "next-step", "update:is-submitting" ])

defineExpose({
  saveStep,
})

</script>
<template>
  <div
    v-if="!isEditing && !!(initialData.first_name && initialData.last_name)"
    class="text-sm"
  >
    <h3 class="flex flex-wrap items-center gap-2 mb-1">
      <i18n-t
        keypath="wizard.greeting"
        tag="span"
      >
        <b>{{ userRepresentation }}</b>
      </i18n-t>
      <button
        class="p-0 btn-plain hover:brightness-110"
        :style="`color: ${account.ci_colors.primaryColor}`"
        @click="isEditing = true"
      >
        {{ $t('wizard.notYourName') }}
      </button>
    </h3>

    <span
      v-if="isJustSignatory"
    >
      {{ $t('wizard.invitedSigningExplanation', { inviterName: mdu.user_notifications?.slice(-1)[0].created_by_document_user_name, documentName: document.name }) }}
    </span>
    <span
      v-else
    >
      {{ $t('wizard.invitedCollaborationExplanation', { inviterName: mdu.user_notifications?.slice(-1)[0].created_by_document_user_name, documentName: document.name }) }}
    </span>
  </div>
  <Form
    v-else
    v-slot="{ errors }"
    ref="form"
    class="w-full space-y-4 text-left"
    :validation-schema="validationSchema"
    :initial-values="initialData"
    @submit="submit"
  >
    <div class="text-sm">
      {{ $t('wizard.confirmPersonalInformation') }}
    </div>
    <div>
      <label
        class="block mb-1 text-sm font-medium truncate"
        for="first_name"
      >{{ $t('userForm.firstName') }}</label>
      <Field
        id="first_name"
        v-model="userData.first_name"
        :data-cy-sel="'wizard-user-first-name-input'"
        name="first_name"
        type="text"
        class="input-primary"
        :label="$t('userForm.firstName')"
        :placeholder="$t('userForm.firstNamePlaceholder') + '…'"
        :class="[errors.first_name ? 'input-has-errors' : '']"
        rules="required"
      />
      <FormInputErrors
        v-if="errors?.first_name"
        :errors="[errors?.first_name]"
      />
    </div>
    <div>
      <label
        class="block mb-1 text-sm font-medium truncate"
        for="last_name"
      >{{ $t('userForm.lastName') }}</label>
      <Field
        id="last_name"
        v-model="userData.last_name"
        :data-cy-sel="'wizard-user-last-name-input'"
        name="last_name"
        type="text"
        class="input-primary"
        :label="$t('userForm.lastName')"
        :placeholder="$t('userForm.lastNamePlaceholder') + '…'"
        :class="[errors.last_name ? 'input-has-errors' : '']"
        rules="required"
      />
      <FormInputErrors
        v-if="errors?.last_name"
        :errors="[errors?.last_name]"
      />
    </div>
    <div
      class="p-2 text-sm font-normal text-indigo-500 bg-indigo-100 rounded-md"
    >
      <div class="mb-0.5 flex items-center gap-1 font-semibold">
        <LightBulbIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        {{ $t('wizard.howToInviteOtherUsers') }}
      </div>
      {{ $t('wizard.howToInviteOtherUsersExplanation') }}
    </div>
    <button
      ref="submitButton"
      type="submit"
      class="sr-only"
    >
      {{ $t('common.next') }}
    </button>
  </Form>
</template>
