<script setup lang="ts">
// external
import { Form } from "vee-validate"
import { router, Link } from "@inertiajs/vue3"
import { ref } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { useI18n } from "vue-i18n"

// internal
import { SpinLoader } from "~/components"
import { LaravelUser, AccountUser } from "~/types"
import { useNotificationStore } from "~/stores"
import { setDayjsLocale } from "~/utils/dayjs"
import { localeOptions } from "~/utils/constants"

interface Props {
  user: LaravelUser
  mau: AccountUser
}

const props = withDefaults(defineProps<Props>(), {})

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const { t, locale } = useI18n()

const isSubmitting = ref(false)

const selectedLocaleOption = ref(localeOptions.find((option) => option.value === (props.user?.locale || "en")))

const onSubmit = () => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    const payload = {}

    if (selectedLocaleOption.value.value !== props.user.locale) {
      payload["locale"] = selectedLocaleOption.value.value
    }

    router.patch(route("user-profile.update", props.user.uuid), payload, {
      onSuccess: () => {
        if (locale.value !== selectedLocaleOption.value.value) {
          locale.value = selectedLocaleOption.value.value
          setDayjsLocale(selectedLocaleOption.value.value)
        }

        addToasterNotification({
          title: t("profileSettings.general.updateSuccessTitle"),
          message: t("profileSettings.general.updateSuccessDescription"),
          type: "success",
        })
      },
      onError: () => {
        addToasterNotification({
          title: t("common.errorOccured"),
          message: t("profileSettings.general.updateError"),
          type: "error",
        })
      },
      onFinish: () => {
        isSubmitting.value = false
      },
    })
  }
}
</script>
<template>
  <Form
    class="content-card"
    @submit="onSubmit"
  >
    <div class="p-5 space-y-5">
      <div>
        <div
          class="block mb-1 text-sm"
        >
          {{ $t('profileSettings.general.language') }}:
        </div>
        <Listbox
          v-model="selectedLocaleOption"
          as="div"
          class="max-w-xl"
        >
          <div class="relative mt-1">
            <ListboxButton class="btn-listbox-plain">
              <div
                v-if="selectedLocaleOption"
                class="flex items-center"
              >
                {{ selectedLocaleOption.label }}
              </div>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions class="listbox-options">
                <ListboxOption
                  v-for="localeOption in localeOptions"
                  :key="localeOption.value"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="localeOption"
                >
                  <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                    <div class="flex items-center">
                      <span>{{ localeOption.label }}</span>
                    </div>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <div
          v-if="mau.permissions.includes('account_manage')"
          class="mt-2 text-xs"
        >
          <span>{{ $t('profileSettings.general.languageQuestion') }}</span>
          <Link
            :href="route('account-settings.show')"
            class="ml-1 font-medium link-primary"
          >
            {{ $t('common.clickHere') }}
          </Link>
        </div>
      </div>
    </div>
    <div class="content-card-footer">
      <button
        class="flex items-center space-x-2 btn-primary"
        type="submit"
        :disabled="isSubmitting"
      >
        <SpinLoader
          v-if="isSubmitting"
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <span>{{ $t('common.save') }}</span>
      </button>
    </div>
  </Form>
</template>
