<script setup lang="ts">
// external
import { computed } from "vue"

// internal
import { FilterValues, Metadata, SelectOption, Team } from "~/types"
import {
  TeamFilter,
} from "~/components"

interface Props {
  filterValues: FilterValues
  teams: Team[]
  statusOptions: SelectOption[]
  metadata?: Metadata[]
}
const props = withDefaults(
  defineProps<Props>(),
  {},
)

const localFilterValues = computed({
  get: () => {
    return props.filterValues
  },
  set: (values) => {
    emit("update:filter-values", values)
  },
})

const emit = defineEmits([ "reset", "update:filter-values" ])

</script>

<template>
  <div>
    <h3
      class="mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase"
    >
      {{ $t('filter.archive') }}
    </h3>
    <div>
      <label class="flex items-center space-x-2">
        <input
          v-model="localFilterValues.archived"
          class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          type="checkbox"
          :checked="localFilterValues.archived"
        >
        <span class="text-sm text-gray-600">{{ $t('filter.showOnlyArchived') }}</span>
      </label>
    </div>
  </div>
  <!--<div>
    <h3
      class="mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase"
    >
      {{ $t('filter.labels.status') }}
    </h3>
    <FilterMultiSelect
      v-model:selected-values="localFilterValues.status"
      :options="statusOptions"
    />
  </div>-->
  <div>
    <h3
      class="mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase"
    >
      {{ $t('magicTable.columns.team') }}
    </h3>
    <TeamFilter
      v-model:selected-uuids="localFilterValues.teams"
      :teams="teams"
    />
  </div>
</template>
