<script setup lang="ts">
// external
import { computed, nextTick, ref, toRaw, watch } from "vue"

import { storeToRefs } from "pinia"

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue"
import { BackspaceIcon, VariableIcon, XMarkIcon } from "@heroicons/vue/24/outline"
import { CheckIcon } from "@heroicons/vue/24/solid"
import { Editor } from "@tiptap/core"
import fuzzysort from "fuzzysort"
import { uniq } from "lodash-es"
import { useI18n } from "vue-i18n"

// internal
import { MultiFieldIcon, OverlayScrollbar, PartyEntityTypeDisplayIcon } from "~/components"
import {
  BoldIcon,
  BrushIcon,
  ConditionIcon,
  FormatClearIcon,
  ItalicIcon,
  PlusIcon,
  ResetColorIcon,
  StrikeThroughIcon,
  SubscriptIcon,
  SuperscriptIcon,
  UnderlineIcon,
} from "~/icons"
import { useAccountStore, useConditionStore, useDocumentStore, useDynamicFieldStore, useEditorStore, usePartyStore, useSectionRefStore, useTemplateStore } from "~/stores"
import { CrudContext, DocumentTab, EditorContentTab, MultiFieldType, Party, PartyEntityType, TemplateTab } from "~/types"
import { convertColorToHex, purifyHtml } from "~/utils"

import { AiText } from "./submenus"
import FontSize from "./FontSize.vue"
import { CommandMenuStorage } from "./commandMenu"
import { Mark } from "@tiptap/pm/model"

interface SuggestionProps {
  editor: Editor;
}

const props = defineProps<SuggestionProps>()

const { t } = useI18n()

const editorContext = computed(
  () => props.editor.storage.doc.editorContext,
)

const editorStore = useEditorStore()
const {
  commandMenuActiveSection,
  isDisabledAddConditionButton,
} = storeToRefs(editorStore)
const {
  setCommandMenuActiveSection,
  findUUIDsInRange,
} = editorStore

const documentStore = useDocumentStore()

const templateStore = useTemplateStore()
const accountStore = useAccountStore()
const { account } = storeToRefs(accountStore)

const conditionStore = useConditionStore()
const { conditionsClickHandler } = conditionStore

const storage = props.editor.storage.commandMenu as CommandMenuStorage

const showItems = computed(
  {
    get: () => storage.commandMenu.showItems.value,
    set: (val) => storage.commandMenu.showItems.value = val,
  },
)

const setShowItems = (val: boolean) => showItems.value = val

const setTabKeyToDynamicContent = () => {
  if (editorContext.value === CrudContext.document) {
    documentStore.setActiveTabKey(DocumentTab.documentContent)
    documentStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields })
    setHash(DocumentTab.documentContent)
  } else if (editorContext.value === CrudContext.template) {
    templateStore.setActiveTabKey(TemplateTab.documentContent)
    templateStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields })
    setHash(TemplateTab.documentContent)
  }
}

const setActiveTabKeyToPartiesSection = () => {
  if (editorContext.value === CrudContext.document) {
    documentStore.setActiveTabKey(DocumentTab.parties)
    setHash(DocumentTab.parties)
  } else if (editorContext.value === CrudContext.template) {
    templateStore.setActiveTabKey(TemplateTab.parties)
    setHash(TemplateTab.parties)
  }
}

watch(
  () => commandMenuActiveSection.value,
  (val, oldVal) => {
    if (val === oldVal) return

    props.editor.commands.updateCommandMenu()

    selectedIndex.value = val && val !== oldVal ? 1 : 0

    setTimeout(focusInput, 100)
  },
)

const selectedIndex = ref<number>(0)

/**
 * resets selected index to first selectable item
 */
const resetSelectedIndex = () => {
  let indexToSelect = 0

  for (let i = 0; i < itemsToShow.value.length; i++) {
    const item = itemsToShow.value[i]

    if (item.type === "heading" || item.name === "back") continue

    indexToSelect = i

    break
  }

  selectedIndex.value = indexToSelect
}

const commandMenuRef = ref<HTMLDivElement | null>(null)

const query = ref("")

const resetQuery = () => query.value = ""

const focusInput = async () => {
  const searchInput = commandMenuRef.value.querySelector<HTMLInputElement>(".search-input")
  await nextTick()
  searchInput?.focus()
}

const predefinedColors = uniq([ account.value?.ci_colors?.primaryColor, "#312e81", "#4338ca", "#6366f1" ])

watch(
  () => query.value,
  () => {
    resetSelectedIndex()

    focusInput()
  },
  { flush: "post" },
)

const onKeyDown = ({ event }) => {
  const keyActionMap = {
    ArrowUp: upHandler,
    ArrowDown: downHandler,
    Enter: enterHandler,
    Escape: escapeHandler,
  }

  const action = keyActionMap[event.key]

  if (action) {
    event.preventDefault()
    event.stopPropagation()

    setTimeout(() => action?.())
  }

  return !!action
}

const upHandler = () => {
  if (!itemsToShow.value) return
  selectedIndex.value =
    (selectedIndex.value + itemsToShow.value.length - 1) % itemsToShow.value.length
  if (itemsToShow.value[selectedIndex.value]?.type === "heading") {
    selectedIndex.value =
    (selectedIndex.value + itemsToShow.value.length - 1) % itemsToShow.value.length
  }
}

const downHandler = () => {
  if (!itemsToShow.value) return
  selectedIndex.value = (selectedIndex.value + 1) % itemsToShow.value.length
  if (itemsToShow.value[selectedIndex.value]?.type === "heading") {
    selectedIndex.value =
    (selectedIndex.value + itemsToShow.value.length + 1) % itemsToShow.value.length
  }
}


const selectItem = (index) => {
  const item = toRaw(itemsToShow.value[index])

  if (item?.type === "heading") return

  setTimeout(
    () => {
      if (!item?.command) return

      item.command()
    },
  )
}

const enterHandler = () => selectItem(selectedIndex.value)

const escapeHandler = () => {
  props.editor.chain().focus().hideCommandMenu().run()

  setTimeout(
    () => {
      query.value = ""
      setCommandMenuActiveSection(null)
      resetSelectedIndex()
    },
    500,
  )
}

interface Item {
  name?: string
  icon?: any
  title?: string
  preview?: string
  type?: string
  meta?: string
  uuid?: string
  command?: () => any
  isCreateCommand?: boolean
  highlightedName?: string
  highlightedMeta?: string
}

const sectionRefStore = useSectionRefStore()
const {
  sections,
} = storeToRefs(sectionRefStore)

const partyStore = usePartyStore()
const { setNewParty } = partyStore
const {
  parties,
} = storeToRefs(partyStore)

const sectionListData = computed<Item[]>(
  () => (sections.value || []).map(
    ({
      title,
      uuid,
      preview,
    }) => ({
      name: title,
      meta: preview,
      type: "section",
      command: () => props.editor
        .chain()
        .addSectionRef(uuid)
        .focus()
        .run(),
    }),
  ),
)

const selectedParty = ref<Party>(null)
const resetSelectedParty = () => selectedParty.value = null

const partyListData = computed<Item[]>(
  () => {
    if (selectedParty.value) {
      return formats.value
        .map(
          ({ name, meta }) => {
            return {
              name: name,
              type: "party-format",
              command: () => selectFormat(meta),
            }
          },
        )
    }

    const lowerCaseQuery = query.value.toLowerCase()

    const filteredParties = parties.value?.filter(
      (item) => [
        item.name,
        item.entity_name,
      ].some(
        (val) => (val || "").toLowerCase().includes(lowerCaseQuery),
      ),
    )

    const data: Item[] = filteredParties
      .map(
        (party) => {
          const partyRepresentation = party.entity_name
            ? `${party.name} (${party.entity_name})`
            : party.name

          return {
            name: partyRepresentation,
            meta: party.entity_type,
            type: "party",
            command: () => {
              setTimeout(() => {
                resetSelectedIndex()
                selectedParty.value = toRaw(party) || null
              })
            },
          }
        },
      )

    data.push(
      {
        name: t("commandMenu.createNewParty"),
        isCreateCommand: true,
        command: () => setTimeout(
          () => {
            setActiveTabKeyToPartiesSection()

            newPartyFromSelection()
          },
        ),
        type: "party",
      },
    )

    return data
  },
)

const formats = computed<Partial<Item>[]>(
  () => [
    {
      name: t("partyFormats.name"),
      meta: "name",
    },
    {
      name: t("partyFormats.address:inline"),
      meta: "address:inline",
    },
    {
      name: t("partyFormats.address"),
      meta: "address",
    },
    {
      name: t("partyFormats.reference"),
      meta: "reference",
    },
  ],
)

const selectFormat = (partyFormat: string) => {
  const party = toRaw(selectedParty.value)

  if (!party) return

  const refUuid = party.ref_uuid

  const marks = props.editor.state.selection.$from.marks()
  const copyOfMarks = (marks.map((mark) => {
    return {
      type: mark.type.name,
      attrs: mark.attrs,
    }
  }) || []) as unknown as Mark[]

  props.editor
    .chain()
    .addPartyField(
      {
        refUuid: refUuid,
        format: partyFormat,
      }, null, copyOfMarks)
    .focus()
    .run()
}

const dynamicFieldStore = useDynamicFieldStore()
const { setNewDynamicField } = dynamicFieldStore
const {
  dynamicFields,
} = storeToRefs(dynamicFieldStore)

const dynamicFieldListData = computed<Item[]>(
  () => {
    const data: Item[] = dynamicFields.value?.filter(
      (dynamicField) => dynamicField.name.toLowerCase().includes(query.value.toLowerCase()),
    )
      .map(
        (dynamicField) => {
          return {
            name: dynamicField.name,
            meta: dynamicField.type,
            type: "dynamic-field",
            command: () => {
              setTimeout(
                () => {

                  const marks = props.editor.state.selection.$from.marks()
                  const copyOfMarks = (marks.map((mark) => {
                    return {
                      type: mark.type.name,
                      attrs: mark.attrs,
                    }
                  }) || []) as unknown as Mark[]
                  props.editor
                    .chain()
                    .addDynamicField(dynamicField.ref_uuid, null, copyOfMarks)
                    .focus()
                    .run()
                },
              )
            },
          }
        },
      )

    data.push(
      {
        command: () => setTimeout(
          () => {
            setTabKeyToDynamicContent()

            newDynamicFieldFromSelection()
          },
        ),
        name: t("commandMenu.createNewDynamicField"),
        type: "dynamic-field",
        isCreateCommand: true,
      },
    )

    return data
  },
)

const slashMenuSections = computed<Item[]>(
  () => {
    const sections: Item[] = [
      {
        name: t("commandMenu.generateText"),
        type: "action",
        command: () => {
          setTimeout(
            () => {
              setCommandMenuActiveSection("textCompletion")
              resetSelectedIndex()
            },
          )
        },
      },
    ]

    if (editorContext.value === CrudContext.template) {
      sections.push(
        {
          name: t("editor.addCondition"),
          icon: ConditionIcon,
          type: "action",
          command: () => {
            setTimeout(
              () => triggerConditionTippy(),
            )
          },
        },
      )
    }

    return sections
  },
)

// Reset search term and store it for restoring later when selectedParty changes
const storedSearchQuery = ref<string>("")
watch(
  () => selectedParty.value,
  (val) => {
    if (!val) {
      query.value = storedSearchQuery.value
    } else {
      storedSearchQuery.value = query.value
      resetQuery()
    }
  },
)

const goBack = async () => {
  await nextTick()

  if (showAllGroupType.value) setShowAllGroupType()

  if (selectedParty.value) selectedParty.value = null
  else setCommandMenuActiveSection(null)
}

const inputRef = ref<HTMLInputElement>(null)

const showAllGroupType = ref<string>("")

watch(
  () => showAllGroupType.value,
  (val) => {
    if (!val.trim()) return

    inputRef.value?.focus()
  },
)

const setShowAllGroupType = (type = "") => showAllGroupType.value = type

const groupByType = (input: Item[]): Item[] => {
  const groupedItems: Item[] = []

  const groupAndItemsMap: Record<string, Item[]> = {}

  for (let i = 0; i < input.length; i++) {
    const item = input[i]

    if (!groupAndItemsMap[item.type]) groupAndItemsMap[item.type] = []

    groupAndItemsMap[item.type].push(item)
  }

  Object.keys(groupAndItemsMap)
    .sort((a, b) => a.localeCompare(b))
    .forEach(
      (key) => {
        groupedItems.push(
          {
            title: key,
            type: "heading",
          },
          ...groupAndItemsMap[key],
        )
      },
    )

  return groupedItems
}

const totalMaxItems = 9

const limitGroupItems = (groupedDataWithHeadings: Item[]): Item[] => {
  const groupedLimitedItems: Item[] = []

  const groupAndItemsMap: Record<string, Item[]> = {}
  const groupCountMap: Record<string, number> = {}

  for (let i = 0; i < groupedDataWithHeadings.length; i++) {
    const item = groupedDataWithHeadings[i]

    if (item.type === "heading") continue

    if (!groupAndItemsMap[item.type]) groupAndItemsMap[item.type] = []

    groupAndItemsMap[item.type].push(item)
  }

  for (const [ key, value ] of Object.entries(groupAndItemsMap)) {
    groupCountMap[key] = value.length
  }

  const totalGroups = Object.keys(groupCountMap).length
  const maxItemsPerGroup = Math.floor(totalMaxItems / totalGroups)

  const mapItemsToBeTakenFromGroup: Record<string, number> = {}

  let itemLeft = totalMaxItems

  Object.entries((groupCountMap)).forEach(
    ([ key, value ], idx) => {
      mapItemsToBeTakenFromGroup[key] = value <= maxItemsPerGroup
        ? value
        : idx === totalGroups - 1
          ? itemLeft
          : maxItemsPerGroup

      itemLeft -= mapItemsToBeTakenFromGroup[key]
    },
  )

  Object.keys(groupAndItemsMap)
    .forEach(
      (key) => {
        const items = groupAndItemsMap[key]

        const itemsToBeTaken = mapItemsToBeTakenFromGroup[key]

        for (let i = 0; i < items.length; i++) {
          const item = items[i]

          if (i < itemsToBeTaken || item.isCreateCommand) groupedLimitedItems.push(item)
        }
      },
    )


  return groupedLimitedItems
}

const itemsToShow = computed(
  () => {
    if (commandMenuActiveSection.value === "textCompletion") return []

    let dataArrays: Item[][] | Item[] = []

    if (showAllGroupType.value) {
      switch (showAllGroupType.value) {
        case "party":
          dataArrays.push(partyListData.value)
          break

        case "section":
          dataArrays.push(sectionListData.value)
          break

        case "dynamic-field":
          dataArrays.push(dynamicFieldListData.value)
          break

        case "action":
          dataArrays.push(slashMenuSections.value)
          break

        default:
          break
      }
    } else {
      if (!selectedParty.value) {
        dataArrays.push(slashMenuSections.value)
        dataArrays.push(dynamicFieldListData.value)
      }

      dataArrays.push(partyListData.value)

      if (!selectedParty.value) {
        dataArrays.push(sectionListData.value)
      }
    }

    dataArrays = dataArrays.flat()

    const lowerCaseQuery = query.value.toLowerCase().trim()

    if (lowerCaseQuery) {
      dataArrays = fuzzysort
        .go<Item>(lowerCaseQuery, dataArrays, { keys: [ "name", "meta" ] })
        .map(
          (item) => ({
            ...item.obj,
            item,
            ...(
              item.obj.name
                ? {
                  highlightedName: fuzzysort.highlight(fuzzysort.single(lowerCaseQuery, item.obj.name), "<b>", "</b>"),
                }
                : {}
            ),
            ...(
              item.obj.meta
                ? {
                  highlightedMeta: fuzzysort.highlight(fuzzysort.single(lowerCaseQuery, item.obj.meta), "<b>", "</b>"),
                }
                : {}
            ),
          }),
        )
    }

    if (!showAllGroupType.value) dataArrays = limitGroupItems(dataArrays)

    const groupedDataWithHeadings = groupByType(dataArrays) || []

    if (commandMenuActiveSection.value || selectedParty.value || showAllGroupType.value) {
      groupedDataWithHeadings.unshift(
        {
          name: "back",
          command: () => goBack(),
        },
      )
    }

    return groupedDataWithHeadings
  },
)

watch(
  () => itemsToShow.value,
  resetSelectedIndex,
  { immediate: true },
)

const headingTitles = {
  "action": t("commandMenu.action"),
  "section": t("commandMenu.section"),
  "party": t("commandMenu.party"),
  "dynamic-field": t("commandMenu.dynamicField"),
  "party-format": t("commandMenu.partyFormat"),
}

const setHash = (hash: string) => location.hash = hash

const newDynamicFieldFromSelection = async () => {
  if (!editorContext.value) return

  const { from, to } = props.editor.state.selection

  const selectionText = props.editor.state.doc.textBetween(from, to)

  setNewDynamicField(
    {
      name: selectionText,
      scope: "internal",
      is_mandatory: false,
    },
  )

  if (editorContext.value === CrudContext.document) {
    documentStore.setActiveTabKey(DocumentTab.documentContent)
    documentStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields })
    setHash(DocumentTab.documentContent)
  } else if (editorContext.value === CrudContext.template) {
    templateStore.setActiveTabKey(TemplateTab.documentContent)
    templateStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields })
    setHash(TemplateTab.documentContent)
  }
  await nextTick()
  const newDynamicFieldButton = document.querySelector(".add-dynamic-field-button") as HTMLButtonElement
  newDynamicFieldButton?.click()
}

const triggerConditionTippy = () => {
// Assuming props.editor.state.selection is already available
  const selection = props.editor.state.selection

  const uuidsToUse = findUUIDsInRange(selection.from, selection.to, props.editor.state.doc)
  if (!uuidsToUse.length) return
  // Trigger condition tippy
  conditionsClickHandler(uuidsToUse)
  // Close command menu
  props.editor.chain().focus().hideCommandMenu().run()
}

const newPartyFromSelection = () => {
  if (!editorContext.value) return

  const { from, to } = props.editor.state.selection

  const selectionText = props.editor.state.doc.textBetween(from, to)

  setNewParty(
    {
      name: selectionText,
      scope: "internal_and_external",
    },
  )

  if (editorContext.value === CrudContext.document) {
    documentStore.setActiveTabKey(DocumentTab.parties)
    setHash(DocumentTab.parties)
  } else if (editorContext.value === CrudContext.template) {
    templateStore.setActiveTabKey(TemplateTab.parties)
    setHash(TemplateTab.parties)
  }
  const newPartyButton = document.querySelector(".add-party-button") as HTMLButtonElement
  newPartyButton?.click()
}

const isActiveFormatPainter = ref(false)

/** Enable format painter */
const handleEnableFormatPainter = (once = true) => {

  props.editor.commands.watchFormatPainterState((isEnable: boolean) => {
    isActiveFormatPainter.value = isEnable
  })
  props.editor.commands.enableFormatPainter({
    once: once,
    getChain: () => props.editor.chain(),
  })
}

defineExpose(
  {
    onKeyDown,
    focusInput,
    escapeHandler,
    resetQuery,
    resetSelectedParty,
  },
)
</script>

<template>
  <section
    id="commandMenu"
    ref="commandMenuRef"
    v-cy="`command-menu`"
    class="flex-col text-xs popover popover-slate group/command-menu"
  >
    <button
      class="absolute z-50 p-0.5 border rounded-full shadow-md -right-1 -top-7 bg-slate-900 border-slate-300 border-opacity-70 text-white hover:text-slate-100 flex gap-1 items-center"
      @click="props.editor.chain().focus().hideCommandMenu().run()"
    >
      <XMarkIcon class="w-3 h-3" />
    </button>

    <template v-if="!commandMenuActiveSection">
      <div
        class="flex flex-col p-1.5 rounded-t-md flex-nowrap"
      >
        <section class="flex space-x-px">
          <FontSize
            :editor="props.editor"
          />

          <Popover
            class="relative"
          >
            <PopoverButton
              class="px-1.5 btn-editor-bubble-menu group"
              :title="$t('commandMenu.textColor')"
            >
              <span
                :style="{
                  backgroundColor: editor.getAttributes('textStyle')?.color || 'black',
                }"
                class="inline-block w-4 h-4 rounded-full ring-2 ring-white ring-opacity-20 group-hover:ring-opacity-40"
              />
            </PopoverButton>

            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                class="absolute left-0 z-50 w-64 mt-1 transform border-2 border-white rounded-lg shadow-lg bg-slate-950 border-opacity-20 sm:px-0 lg:max-w-3xl"
              >
                <section class="flex flex-col gap-2 p-2">
                  <section class="flex gap-2">
                    <span
                      v-for="color in predefinedColors"
                      :key="color"
                      :style="{
                        backgroundColor: color,
                      }"
                      class="flex items-center justify-center border-2 border-white rounded-full cursor-pointer w-7 h-7"
                      :class="
                        convertColorToHex(editor.getAttributes('textStyle')?.color || 'rgb(0,0,0)') === color
                          ? ''
                          : 'border-opacity-20'
                      "
                      @click="editor.commands.setColor(color)"
                    >
                      <CheckIcon
                        v-if="convertColorToHex(editor.getAttributes('textStyle')?.color || 'rgb(0,0,0)') === color"
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                    </span>

                    <span
                      class="flex items-center justify-center text-black rounded-full shadow-sm cursor-pointer w-7 h-7 bg-indigo-50 hover:bg-indigo-100"
                      data-tippy-context
                      :data-tippy-content="$t('commandMenu.resetColor')"
                      data-placement="top"
                      @click="editor.commands.setColor(null)"
                    >
                      <ResetColorIcon
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </section>

                  <section>
                    <label
                      for="colorPickerInput"
                      class="block mt-1 text-sm font-medium text-slate-400"
                    >
                      {{ $t('commandMenu.pickColor') }}
                    </label>

                    <section>
                      <div class="flex mt-1 rounded-md shadow-sm">
                        <span
                          class="inline-flex items-center px-3 border border-r-0 text-slate-400 border-slate-800 bg-slate-800 rounded-l-md sm:text-sm"
                        >
                          <input
                            id="colorPickerInput"
                            type="color"
                            class="w-6 h-6 rounded-full bg-slate-800"
                            :value="convertColorToHex(editor.getAttributes('textStyle')?.color || 'rgb(0,0,0)')"
                            @input="editor.commands.setColor(convertColorToHex(($event.target as HTMLInputElement).value))"
                          >
                        </span>
                        <input
                          id="colorHexInput"
                          type="text"
                          name="colorHexInput"
                          class="block w-full min-w-0 flex-1 bg-slate-800 rounded-none rounded-r-md border-0 py-1.5 text-slate-400 ring-1 ring-inset ring-slate-800 placeholder:text-slate-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="www.example.com"
                          :value="convertColorToHex(editor.getAttributes('textStyle')?.color || 'rgb(0,0,0)')"
                          @input="editor.commands.setColor(($event.target as HTMLInputElement).value)"
                        >
                      </div>
                    </section>
                  </section>
                </section>
              </PopoverPanel>
            </transition>
          </Popover>

          <button
            :class="editor.isActive('bold') ? 'is-active' : ''"
            class="btn-editor-bubble-menu"
            :title="$t('editor.bold')"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <BoldIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            :class="editor.isActive('italic') ? 'is-active' : ''"
            class="btn-editor-bubble-menu"
            :title="$t('editor.italic')"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <ItalicIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            :class="editor.isActive('underline') ? 'is-active' : ''"
            class="btn-editor-bubble-menu"
            :title="$t('editor.underline')"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <UnderlineIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            :class="[
              editor.isActive('strike') ? 'is-active' : '',
            ]"
            class="btn-editor-bubble-menu"
            :title="$t('editor.strike')"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <StrikeThroughIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            :class="[
              editor.isActive('subscript') ? 'is-active' : '',
            ]"
            class="btn-editor-bubble-menu"
            @click="editor.chain().focus().toggleSubscript().run()"
          >
            <SubscriptIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            :class="[
              editor.isActive('superscript') ? 'is-active' : '',
            ]"
            class="btn-editor-bubble-menu"
            @click="editor.chain().focus().toggleSuperscript().run()"
          >
            <SuperscriptIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            :class="isActiveFormatPainter ? 'is-active' : ''"
            class="btn-editor-bubble-menu"
            :title="$t('editor.formatPainter')"
            @click="handleEnableFormatPainter(true)"
          >
            <BrushIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <button
            class="btn-editor-bubble-menu"
            @click="editor.chain().focus().clearNodes().unsetAllMarks().run()"
          >
            <FormatClearIcon
              class="mx-auto w-3.5 h-3.5"
            />
          </button>

          <span
            v-if="editorContext === CrudContext.template"
            class="pl-2"
            data-placement="top"
            :data-tippy-content="isDisabledAddConditionButton ? $t('editor.conditionAlreadyExistsInThisContext') : ''"
            data-tippy-help
          >
            <button
              :disabled="isDisabledAddConditionButton"
              class="flex items-center gap-1 text-xs btn-slate btn-sm disabled:cursor-not-allowed disabled:pointer-events-auto disabled:border-slate-600 disabled:bg-slate-800"
              :title="$t('editor.addCondition')"
              @click="triggerConditionTippy"
            >
              <ConditionIcon
                class="mx-auto w-3.5 h-3.5"
              />
              {{ $t('editor.condition') }}
            </button>
          </span>
        </section>
      </div>
    </template>

    <input
      v-if="commandMenuActiveSection !== 'textCompletion'"
      ref="inputRef"
      v-model="query"
      type="text"
      class="w-full p-3 text-xs text-white rounded-t-none input-plain bg-slate-900 placeholder-slate-400 search-input focus:ring-0 focus:bg-slate-950"
      :class="!showItems ? 'rounded-b-md bg-slate-900' : 'rounded-b-none'"
      :placeholder="$t('filter.search')+ '…'"
      @keydown="onKeyDown({ event: $event })"
      @focus="setShowItems(true)"
    >

    <template v-if="itemsToShow.length && showItems">
      <OverlayScrollbar
        theme="light"
        auto-hide="move"
      >
        <div
          class="max-h-60 rounded-b-md"
        >
          <a
            v-for="(item, index) in itemsToShow"
            :key="index"
            v-cy="`command-menu-item`"
            class="flex items-center py-1 text-left group text-slate-300"
            :class="
              [item.name === 'back' ? 'mt-1' : '',
               item?.type === 'heading'
                 ? 'border-t border-t-slate-700 px-2 text-slate-500 pt-1.5 pb-1 mt-1.5 first:mt-0' :
                   index === selectedIndex
                     ? 'bg-slate-700 text-slate-100 hover:cursor-pointer px-1 pr-2 mx-1 rounded-md last:mb-2'
                     : 'hover:text-slate-100 hover:bg-slate-700 hover:cursor-pointer px-1 pr-2 mx-1 rounded-md last:mb-2']
            "
            @mouseenter="item.type !== 'heading' ? selectedIndex = index : null"
            @click.stop.prevent="selectItem(index)"
          >
            <template v-if="item.name === 'back'">
              <span
                class="flex items-center p-1 mr-2 rounded-md shrink-0 text-slate-100 group-hover:bg-slate-800"
                :class="index === selectedIndex ? 'bg-slate-800' : 'bg-slate-900'"
              >
                <BackspaceIcon
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
              </span>

              <span v-cy="`go-back-button`">
                {{ $t('common.back') }}
              </span>
            </template>

            <template v-else-if="item?.type === 'heading'">
              <div class="flex items-center justify-between w-full pr-1.5">
                <span class="text-[10px] font-medium tracking-wider uppercase text-slate-500">
                  {{ headingTitles[item.title] || $t("commandMenu.other") }}
                </span>

                <button
                  v-if="!showAllGroupType"
                  class="hover:text-slate-400"
                  @click.stop.prevent="setShowAllGroupType(item.title)"
                >
                  {{ $t('commandMenu.showAll') }}
                </button>
              </div>
            </template>

            <template v-else-if="item.type === 'section'">
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="rounded-md text-slate-100 py-1 px-1.5 flex items-center shrink-0 mr-2 group-hover:bg-slate-600 text-[9px]"
                :class="index === selectedIndex ? 'bg-slate-600' : 'bg-slate-700'"
                v-html="purifyHtml(item.highlightedName || item.name)"
              />
              <span
                class="flex-shrink max-w-[16rem] truncate whitespace-pre"
                v-html="purifyHtml(item.highlightedMeta || item.meta)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>

            <template v-else-if="item.type === 'party'">
              <span
                class="flex items-center shrink-0 p-1.5 mr-2 rounded-md text-slate-100 group-hover:bg-slate-600"
                :class="index === selectedIndex ? 'bg-slate-600' : 'bg-slate-700'"
              >
                <PlusIcon
                  v-if="item.isCreateCommand"
                  class="w-3.5 h-3.5 shrink-0"
                />

                <PartyEntityTypeDisplayIcon
                  v-else
                  :entity-type="(item.meta as PartyEntityType)"
                  class="w-3.5 h-3.5 shrink-0"
                />
              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-cy="{
                  'party-attribute': item.name.toLowerCase()
                }"
                class="flex-shrink max-w-[16rem] truncate whitespace-pre"
                v-html="purifyHtml(item.highlightedName || item.name)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>

            <template v-else-if="item.type === 'dynamic-field'">
              <span
                class="flex items-center shrink-0 p-1.5 mr-2 rounded-md text-slate-100 group-hover:bg-slate-600"
                :class="index === selectedIndex ? 'bg-slate-600' : 'bg-slate-700'"
              >
                <PlusIcon
                  v-if="item.isCreateCommand"
                  class="w-3.5 h-3.5 shrink-0"
                />

                <MultiFieldIcon
                  v-else-if="Object.keys(MultiFieldType).includes(item.meta)"
                  :title="$t('dynamicFields.form.type') + ': ' + $t('multiFieldTypes.' + item.meta)"
                  class="shrink-0"
                  size-classes="w-3.5 h-3.5"
                  :type="(item.meta as MultiFieldType)"
                />

                <VariableIcon
                  v-else
                  class="shrink-0 w-3.5 h-3.5"
                />

              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="flex-shrink max-w-[16rem] truncate whitespace-pre"
                v-html="purifyHtml(item.highlightedName || item.name)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>

            <template v-else>
              <span
                class="rounded-md text-slate-100  h-[24px] w-[24px] flex items-center justify-center shrink-0 mr-2 group-hover:bg-slate-600 text-xs"
                :class="index === selectedIndex ? 'bg-slate-600' : 'bg-slate-700'"
              >
                <component
                  :is="item.icon"
                  v-if="item.icon"
                  class="w-3.5 h-3.5 shrink-0"
                />
                <template v-else>✨</template>
              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="flex-shrink max-w-[16rem] truncate whitespace-pre"
                v-html="purifyHtml(item.highlightedName || item.name)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>
          </a>
        </div>
      </OverlayScrollbar>
    </template>

    <template v-else-if="!commandMenuActiveSection && showItems">
      <span class="flex items-center w-full px-3 py-2 text-left text-slate-500">
        {{ $t('commandMenu.noResults') }}
      </span>
    </template>

    <AiText
      v-show="commandMenuActiveSection === 'textCompletion'"
      :go-back="goBack"
      :editor="props.editor"
    />
  </section>
</template>

