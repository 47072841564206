<script setup lang="ts">
// external
import { Link } from "@inertiajs/vue3"
import { computed } from "vue"

// internal
import { AiAnalysisStatusDisplay, MagicTableEntry, MagicTableTimestamps } from "~/components"
import { AiAnalysis, Document } from "~/types"

interface Props {
  entry: Document
  columns: any[]
  aiAnalyses?: AiAnalysis[]
  loading?: boolean
  isArchive?: boolean
  sortAttribute?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    isArchive: false,
    aiAnalyses: null,
    sortAttribute: null,
  },
)

const aiAnalysisOfDocument = computed(() => {
  return props.aiAnalyses?.find((aiAnalysis) => aiAnalysis.document_uuid === props.entry.uuid)
})

defineEmits([ "start-analysis" ])

</script>

<template>
  <MagicTableEntry
    :columns="columns"
    :loading="loading"
  >
    <template #document>
      <div class="flex items-center md:space-x-4">
        <Link
          :href="route('documents.show', entry.uuid)"
          class="block max-w-[150px] sm:max-w-[380px] lg:max-w-[500px] 2xl:max-w-[800px] truncate"
        >
          <span>{{ entry.name }}</span>
        </Link>
      </div>
    </template>
    <template #status>
      <AiAnalysisStatusDisplay
        v-if="aiAnalysisOfDocument"
        :ai-analysis="aiAnalysisOfDocument"
        :disabled="entry.is_locked"
        @review="$emit('start-analysis', { document: entry, aiAnalysisUuid: aiAnalysisOfDocument.uuid })"
      />
      <span
        v-else
        class="text-gray-400"
      >
        {{ $t('aiAnalysis.notStarted') }}
      </span>
    </template>
    <template #timestamps>
      <MagicTableTimestamps
        :entry="entry"
        :sort-attribute="sortAttribute"
      />
    </template>
  </MagicTableEntry>
</template>
