<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, watch } from "vue"

interface Props {
  show: boolean
  maxWidth: string
  closeable: boolean
  extraClasses?: string
  id?: string
  mobileFullscreen?: boolean
  forceVisibleSlot?: boolean
  fullsize?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    show: false,
    maxWidth: "fit",
    closeable: true,
    extraClasses: "",
    mobileFullscreen: false,
    forceVisibleSlot: false,
    fullsize: false,
  },
)

const emit = defineEmits([ "close" ])

const close = () => props.closeable && emit("close")

const closeOnEscape = (e) => e.key === "Escape" && props.show && close()

onMounted(() => document.addEventListener("keydown", closeOnEscape))

onBeforeUnmount(() => {
  document.removeEventListener("keydown", closeOnEscape)
  document.body.style.overflow = null
})

const maxWidthClass = computed(() => {
  return {
    "sm": "sm:max-w-sm",
    "md": "sm:max-w-md",
    "lg": "sm:max-w-lg",
    "xl": "sm:max-w-xl",
    "2xl": "sm:max-w-2xl",
    "3xl": "sm:max-w-3xl",
    "4xl": "sm:max-w-4xl",
    "6xl": "sm:max-w-6xl",
    "fit": "max-w-full lg:max-w-fit",
  }[props.maxWidth]
})

watch(
  () => props.show,
  (val) => document.body.style.overflow = val ? "hidden" : null,
)
</script>

<template>
  <teleport to="body">
    <transition leave-active-class="duration-200">
      <div
        v-show="show"
        :id="id ? id : ''"
        class="fixed inset-0 overflow-y-auto z-[10000]"
        :class="fullsize ? 'lg:p-0' : mobileFullscreen ? 'px-0 py-0 lg:px-4 lg:py-6' : 'px-4 py-6'"
        scroll-region
      >
        <transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="show"
            data-cy-sel="modal-backdrop"
            class="fixed inset-0 transition-all transform"
            @click="close"
          >
            <div class="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
        </transition>

        <transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          enter-to-class="translate-y-0 opacity-100 sm:scale-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="translate-y-0 opacity-100 sm:scale-100"
          leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        >
          <div
            v-show="show"
            class="flex items-end justify-center min-h-full text-left sm:items-center"
          >
            <div
              class="w-full transition-all transform bg-white sm:mx-auto"
              :class="[fullsize ? 'h-full lg:absolute inset-0 rounded-none' : maxWidthClass, extraClasses, mobileFullscreen ? 'absolute top-0 lg:top-6 lg:rounded-lg lg:shadow-xl lg:my-6 bottom-0 lg:bottom-auto left-0 lg:left-auto right-0 lg:right-auto overflow-y-auto lg:overflow-y-visible lg:relative' : 'relative rounded-lg shadow-xl']"
            >
              <div
                class="w-full h-full"
                :class="fullsize ? 'max-h-screen flex flex-col' : ''"
              >
                <slot v-if="show || forceVisibleSlot" />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>
