<script setup lang="ts">
// external
import {
  Combobox,
  ComboboxButton,
  ComboboxInput, ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, UserPlusIcon } from "@heroicons/vue/24/solid"
import { computed, ref, watch } from "vue"

// internal
import { UiUser, DocumentUser, DocumentUserRoleEnum, Party } from "~/types"
import { EMAIL_REGEX, getUserRepresentation } from "~/utils"
import { SpinLoader } from "~/components"
import { useAccountStore, useDocumentStore, useUserStore } from "~/stores"
import { storeToRefs } from "pinia"
import { toRaw } from "vue"
import { omit } from "lodash-es"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

interface Props {
  selectedUser?: Partial<UiUser> | Partial<DocumentUser>
  isLoadingAddUser?: boolean
  align?: "top" | "bottom"
  inputClass?: string
  scope?: "internal" | "external"
  defaultSignatory?: boolean
  deactivatedUsers?: DocumentUser[]
  disableEmail?: boolean
  partyUuid?: Party["uuid"]
  addNewLabel?: string
  isAccountParty?: boolean
  isDisabled?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedUser: null,
    isLoadingAddUser: false,
    align: "top",
    inputClass: "",
    scope: "external",
    defaultSignatory: false,
    deactivatedUsers: () => [],
    disableEmail: false,
    partyUuid: null,
    addNewLabel: "userToParty.addNew",
    isAccountParty: false,
    isDisabled: false,
  },
)

const emit = defineEmits([ "update:selected-user" ])


const validateEmail = (str: string) => EMAIL_REGEX.test(str)

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const documentStore = useDocumentStore()
const { mdu } = storeToRefs(documentStore)

const userStore = useUserStore()
const { users, allAccountUsers } = storeToRefs(userStore)

const availableAccountUsers = computed(
  () => {
    if (!allAccountUsers.value?.length) return []

    let relevantAccountUsers = [ ...allAccountUsers.value ]

    // for external users, only members from own party or signatories are relevant
    if (!mau?.value || !props.isAccountParty) {
      relevantAccountUsers = relevantAccountUsers.filter((accountUser) => {
        const documentUserOfAccountUser = users.value?.find((documentUser) => documentUser.account_user?.uuid === accountUser.uuid)
        return accountUser.party_uuid === mdu.value?.party_uuid || documentUserOfAccountUser?.roles.includes(DocumentUserRoleEnum.signatory)
      })
    }

    return relevantAccountUsers
  },
)

const query = ref("")

const selectedUser = computed({
  get: () => props.selectedUser,
  set: (value) => {
    emit("update:selected-user", value)
  },
})

watch(() => props.selectedUser, (newValue) => {
  if (!newValue) query.value = ""
})

const visibleUsers = computed(() => {
  let usersReturned: UiUser[] = []
  if (props.scope === "internal") {
    const usersToMap = availableAccountUsers.value ? [ ...availableAccountUsers.value ] : []
    const accountUserFieldsToOmit = [ "uuid", "permissions", "roles", "account_name", "account_uuid", "created_at", "teams" ]
    usersReturned = usersToMap?.map((el) => {
      return {
        account_user: {
          uuid: el.uuid,
        },
        ...omit(toRaw(el), accountUserFieldsToOmit ),
      } as unknown as UiUser // Casting to unknown necessary because "omit" of roles is not recognized by TS
    })
    // If defaultSignatory, add all existing document users that are not already included in userReturned
    if (props.defaultSignatory) {
      usersReturned = [
        ...usersReturned,
        ...users.value.filter((du) => !usersReturned.some((ur) => ur.account_user?.uuid === du.account_user?.uuid && ur.account_user?.uuid)),
      ]
    }
  }
  else if (props.defaultSignatory) usersReturned = [ ...users.value.filter((du) => !du.party_uuid || du.party_uuid === props.partyUuid) ]
  else usersReturned = [ ...users.value.filter((du) => (du.uuid && !du.account_user?.uuid) && !(du.roles?.includes(DocumentUserRoleEnum.collaborator) || du.roles?.includes(DocumentUserRoleEnum.owner))) ]
  return usersReturned
})

const filteredUsers = computed(() => {
  return query.value === ""
    ? visibleUsers.value?.filter((user) => user.uuid || user.account_user?.uuid || user.document_user_uuid)
    : visibleUsers.value?.filter((user: UiUser) => user.uuid || user.account_user?.uuid || user.document_user_uuid)
      .filter(Boolean)
      .filter((user) => {
        const check = `${user.first_name || ""} ${user.last_name || ""} ${user.email || ""}`
        return check.toLowerCase().includes(query.value.toLowerCase())
      })
})

// Method to check if a visibleUser has a corresponding documentUser with the signatory role
const isSignatory = (documentUser: DocumentUser) => {
  const filteredUser = users.value?.find((du) => (du.uuid && du.uuid === documentUser.uuid && du.email === documentUser.email) || (du.account_user?.uuid && du.account_user?.uuid === documentUser.account_user?.uuid))
  return filteredUser?.roles?.includes(DocumentUserRoleEnum.signatory)
}


const placeholderText = computed(() => {
  let text = t("userToParty.nameOrEmail")
  if (!visibleUsers.value?.length && !props.disableEmail) text = t("userToParty.email")
  return text + "…"
})

const emailExists = computed(() => {
  return users.value?.some((user) => user.email === query.value)
})

const emailExistsForAccountUser = computed(() => {
  return allAccountUsers.value?.some((user) => user.email === query.value)
})

const queryUser = computed(() => query.value === "" || emailExists.value || emailExistsForAccountUser.value ? null : { id: null, email: query.value })

const handleComboboxButtonClick = () => {
  if (!filteredUsers.value?.length) {
    query.value = ""
  }
}

</script>

<template>
  <Combobox
    v-model="selectedUser"
    as="div"
    nullable
    :disabled="isLoadingAddUser || isDisabled"
  >
    <div
      v-cy="`add-user-input`"
      class="relative"
    >
      <ComboboxInput
        v-cy="`user-form-email-input`"
        name="user-form-combobox-input"
        :placeholder="placeholderText"
        :class="inputClass ? inputClass : 'pl-3'"
        class="w-full py-2 pr-10 border-0 rounded input-plain disabled:opacity-70 disabled:pointer-events-none"
        :display-value="
          (user: UiUser) => user?.email ? getUserRepresentation(user) : ''
        "
        :disabled="isLoadingAddUser || isDisabled"
        autocomplete="user-form-combobox-input"
        @change="query = $event.target.value"
      />
      <ComboboxButton
        v-if="(visibleUsers?.length || isLoadingAddUser) && !isDisabled"
        class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
        @click="handleComboboxButtonClick"
      >
        <ChevronUpDownIcon
          v-if="!isLoadingAddUser"
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
        <SpinLoader
          v-else
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
      </ComboboxButton>
    </div>

    <ComboboxOptions
      v-if="queryUser || emailExists || emailExistsForAccountUser || filteredUsers?.length"
      class="mb-1 listbox-options max-w-full"
      :class="props.align === 'bottom' ? 'top-full' : 'bottom-full'"
    >
      <ComboboxOption
        v-if="(queryUser || emailExists || emailExistsForAccountUser) && !disableEmail && !(emailExistsForAccountUser && filteredUsers?.length)"
        v-slot="{ active, selected }"
        :value="queryUser"
        :disabled="!!(!validateEmail(query) || emailExists || emailExistsForAccountUser)"
      >
        <li
          v-cy="`add-user-option-button`"
          :class="[
            'listbox-option flex items-center pr-3',
            active ? 'bg-gray-700' : '',
            filteredUsers?.length ? 'border-b border-b-gray-600' : ''
          ]"
        >
          <UserPlusIcon
            class="hidden w-5 h-5 shrink-0 sm:inline-block"
            aria-hidden="true"
          />
          <span
            :class="['sm:ml-3 truncate', selected && !emailExists && !emailExistsForAccountUser && 'font-semibold']"
            class="flex flex-col"
          >
            <span>{{ $t(props.addNewLabel) }}: "{{ query }}"</span>
            <span
              v-if="emailExists"
              class="text-red-300"
            >{{ $t('userToParty.emailExists') }}</span>
            <span
              v-else-if="emailExistsForAccountUser"
              class="text-red-300"
            >{{ $t('userToParty.emailExistsForAccountUser') }}</span>
            <span
              v-else-if="filteredUsers?.length"
              class="text-yellow-300"
            >{{ $t('userToParty.nameOrEmailWarning') }}…</span>
            <span
              v-else-if="!validateEmail(query)"
              class="text-red-300"
            >{{ $t('userToParty.emailWarning') }}…</span>
            <span
              v-else
              class="text-green-300"
            >{{ $t('userToParty.pressEnterToAdd') }}</span>
          </span>
        </li>
      </ComboboxOption>
      <template v-if="filteredUsers?.length">
        <ComboboxOption
          v-for="(user, userIdx) in filteredUsers"
          :key="userIdx"
          v-slot="{ active, selected, disabled }"
          :value="user"
          as="template"
          :disabled="deactivatedUsers?.some((el) => el?.email && el?.email === user?.email)"
        >
          <li
            v-cy="`add-user-option-button-first-entry`"
            :class="[
              'listbox-option',
              active ? 'bg-gray-700' : '',
              selected ? 'bg-gray-800' : '',
              disabled && !selected ? 'opacity-50' : '',
            ]"
          >
            <div class="flex items-center">
              <img
                :src="user?.profile_photo_url"
                alt=""
                class="w-5 h-5 rounded-full shrink-0"
              >
              <span :class="['ml-3 truncate', selected && 'text-indigo-400 font-semibold']">
                {{ getUserRepresentation(user as UiUser) }}
              </span>
              <div
                v-if="isSignatory(user)"
                class="inline-flex items-center ml-2 px-1 py-0.5 mt-0.5 rounded-md bg-indigo-700 text-white text-xs"
              >
                {{ $t('documentUserRoles.signatory.name') }}
              </div>
            </div>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-400',
              ]"
            >
              <CheckIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </span>
            <span
              v-else-if="disabled"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-green-500',
              ]"
            >
              <CheckIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </span>
          </li>
        </ComboboxOption>
      </template>
      <div
        v-else-if="disableEmail"
        class="flex items-center listbox-option"
      >
        {{ $t('userToParty.noResults') }}
      </div>
    </ComboboxOptions>
  </Combobox>
</template>
