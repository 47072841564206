import { Editor, mergeAttributes, Node } from "@tiptap/core"
import { Node as PMNode } from "@tiptap/pm/model"
import { VueNodeViewRenderer } from "@tiptap/vue-3"
import { throttle } from "lodash-es"

import { useEditorStore } from "~/stores"
import { JuneEvents, TocItem } from "~/types"

import TocNodeView from "./TocNodeView.vue"
import { trackEventInJune } from "~/utils"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    toc: {
      insertToc: () => ReturnType;
    };
  }
}

const tocTypeNames = [ "heading" ]

interface NodeWithPos {
  node: PMNode;
  pos: number;
}

const getTocData = (editor: Editor, editorStore: ReturnType<typeof useEditorStore>) => {
  const tocItems: TocItem[] = [],
    tocTr = editor.state.tr

  const isProposal = editor.storage.doc.editorContext === "proposal"
  if (isProposal) return

  const headingNodesWithPos: NodeWithPos[] = []

  editor.state.doc.descendants(
    (node, pos, parentNode) => {
      if (!tocTypeNames.includes(node.type.name)) return

      headingNodesWithPos.push(
        {
          node,
          pos,
        },
      )

      const id = `heading-${tocItems.length + 1}`

      if (node.attrs.id !== id) {
        tocTr.setNodeMarkup(
          pos,
          undefined,
          {
            ...node.attrs,
            id,
          },
        )
      }

      const parentListNodeOrderRepresentation = parentNode?.attrs?.orderRepresentation

      tocItems.push(
        {
          level: node.attrs.level,
          text: node.textContent,
          id,
          orderRepresentation: parentListNodeOrderRepresentation || "",
        },
      )
    },
  )

  if (tocTr.docChanged) {
    tocTr.setMeta("addToHistory", false)
    editor.view.dispatch(tocTr)
  }


  editorStore.setTocData(tocItems)
}

const throttledGetTocData = throttle(getTocData, 1000)

export const Toc = Node.create({
  name: "toc",

  group: "block",

  atom: true,

  selectable: true,

  parseHTML () {
    return [
      {
        tag: "toc",
      },
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return [ "toc", mergeAttributes(HTMLAttributes) ]
  },

  addNodeView () {
    return VueNodeViewRenderer(TocNodeView)
  },

  addGlobalAttributes () {
    return [
      {
        types: [
          "heading",
        ],
        attributes: {
          id: {
            default: null,
          },
        },
      },
    ]
  },

  addCommands () {
    return {
      insertToc: () => ({ commands }) => {
        trackEventInJune(JuneEvents.EDITOR_TABLE_OF_CONTENTS_INSERTED)
        return commands.insertContent({ type: this.name })
      },
    }
  },

  onUpdate () {
    const editorStore = useEditorStore()
    throttledGetTocData(this.editor, editorStore)
  },

  onCreate () {
    const { editor } = this
    const editorStore = useEditorStore()

    setTimeout(() => getTocData(editor, editorStore))
  },
})
