<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref, toRaw, watch } from "vue"
import { router } from "@inertiajs/vue3"
import { TagIcon, MagnifyingGlassIcon, InformationCircleIcon, CogIcon } from "@heroicons/vue/24/outline"
import { PlusIcon, QuestionMarkCircleIcon, BarsArrowDownIcon, BarsArrowUpIcon, Bars3Icon } from "@heroicons/vue/24/solid"
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { differenceWith } from "lodash-es"
import tippy, { roundArrow } from "tippy.js"
import { useI18n } from "vue-i18n"

// internal
import { ClauseInput, EmptyState, MetadataPopover, MetadataValueComputedInput, MetadataValueInput, OverlayScrollbar, ReferenceQuotation, SkeletonLoader } from "~/components"
import { useConfirmationStore, useDocumentStore, useMetadataStore, useNotificationStore, usePusherStore, useSharedStore } from "~/stores"
import { Document, Metadata, MetadataValue, MetadataValueSource, Template, MetadataType, MultiFieldType, DurationTypeRelatedMetadata } from "~/types"
import { focusFirstFocusable, sortByMetadataName, filterByMetadataName, isValidSingleIntervalISODuration } from "~/utils"
import { onClickOutside } from "@vueuse/core"
import { route } from "ziggy-js"

interface Props {
  document?: Document
  template?: Template
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
  },
)

const emit = defineEmits( [
  "focus",
  "blur",
  "close-modal",
])

const { t } = useI18n()

const { notify } = useNotificationStore()

const documentStore = useDocumentStore()
const { mau, mainContentPdfViewer, mainContentScrollContainer } = storeToRefs(documentStore)

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)
const { setSidebarScrollContainer } = sharedStore

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return props?.[crudContext.value]?.uuid
})

const metadataStore = useMetadataStore()
const { isLoadingMetadataValues, isLoadingMetadata, metadata, metadataValues, prosemirrorUuidBoundingBoxes } = storeToRefs(metadataStore)
const { handleShowBoundingBox, handleHideBoundingBoxes } = metadataStore

const addMetadataValuePopoverRef = ref()
const addMetadataValueTippy = ref(null)
const selectedMetadata = ref<Metadata>(null)
const isSubmittingAddMetadata = ref<boolean>(false)

// Helper to emit pusher relevant events
const handleFocus = (data: Record<string, string>, metadataValue: MetadataValue) => {
  emit("focus", data)
  handleSelectBoundingBox(metadataValue)
}
const handleBlur = (data: string) => {
  handleDeSelectBoundingBox()
  emit("blur", data)
}

const showAddMetadataPopover = (e) => {
  addMetadataValueTippy.value = tippy(e.target, {
    content () {
      return addMetadataValuePopoverRef.value
    },
    appendTo: () => { return document.getElementById("mainContentContainer") },
    animation: "scale",
    allowHTML: true,
    theme: "indigo",
    arrow: roundArrow,
    interactive: true,
    trigger: "manual",
    showOnCreate: true,
    placement: "top",
    onShown () {
      const element = addMetadataValuePopoverRef.value
      focusFirstFocusable(element)
    },
    onHidden () {
      selectedMetadata.value = null
    },
  })
}

const attachedMetadataValues = computed<MetadataValue[]>(() => metadataValues.value.filter((el) => ![ "contract_type" ].includes(el.metadata?.name) && !Object.keys(DurationTypeRelatedMetadata).includes(el.metadata?.name)))

const attachableMetadata = computed<Metadata[]>(() => {
  const filteredMetadata = differenceWith(
    metadata.value?.filter((el) => !Object.keys(DurationTypeRelatedMetadata).includes(el?.name)),
    metadataValues.value,
    ({ uuid }, { metadata }) => {
      return uuid === metadata.uuid
    },
  ).filter((metadataEntry) => metadataEntry.type !== MetadataType.system_autofilled)
  return filteredMetadata
})

interface setValueSourceArgs {
  metadataValueUuid: MetadataValue["uuid"]
  valueSource: MetadataValueSource
}

const handleSetValueSource = async (args: setValueSourceArgs): Promise<MetadataValue | void> => {
  if (!args.metadataValueUuid || !args.valueSource) return

  const rawMetadataValue = toRaw(metadataValues.value.find((el) => el.uuid === args.metadataValueUuid))
  if (!rawMetadataValue) return

  let payload: Partial<MetadataValue> = {
    ...rawMetadataValue,
    value_source: args.valueSource,
  }

  // If type duration and value source user and isValidSingleIntervalISODuration is false, reset to null
  if (rawMetadataValue.metadata?.value_type === MultiFieldType.duration && args.valueSource === MetadataValueSource.user && !isValidSingleIntervalISODuration(rawMetadataValue.value)) {
    payload = {
      ...payload,
      value: null,
    }
  }

  metadataStore.updateLocalMetadataValue(payload, crudContext.value, entityUuid.value)

}

const addMetadataValue = async (metadata: Metadata): Promise<MetadataValue | void> => {

  isSubmittingAddMetadata.value = true

  let payload: Partial<MetadataValue> = {
    metadata_uuid: metadata.uuid,
  }

  // If clause, then we need to set value to true
  if (metadata.value_type === MultiFieldType.clause) {
    payload = {
      ...payload,
      value: true,
    }
  }

  try {

    const createdMetadataValue = await metadataStore.createMetadataValue(crudContext.value, entityUuid.value, payload)
    if (!createdMetadataValue) throw new Error(t("metadata.errors.invalidValue", { value: JSON.stringify({ createdMetadataValue }) }))
    metadataStore.setMetadataValueErrors("0", null)
    selectedMetadata.value = null
    addMetadataValueTippy.value?.hide()
    return createdMetadataValue

  } catch (err) {

    metadataStore.setMetadataValueErrors("0", err.response?.data?.errors || null)
    notify({
      title: t("metadata.errors.add"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })

  } finally {
    isSubmittingAddMetadata.value = false
  }
}

// Constructor for changes on selected metadata
watch(selectedMetadata, () => {
  if (!selectedMetadata.value?.uuid) return
  addMetadataValue(selectedMetadata.value)
})

const metadataScrollContainer = ref()

onMounted(() => {
  setSidebarScrollContainer(metadataScrollContainer.value)
})

const sortDirection = ref<string>("")

const toggleSortDirection = () => {
  if (sortDirection.value === "asc") {
    sortDirection.value = ""
  } else if (sortDirection.value === "desc") {
    sortDirection.value = "asc"
  } else {
    sortDirection.value = "desc"
  }
}

interface MetadataCategory {
  name?: string
  hint?: string
  entries: MetadataValue[]
}

const structure = computed<MetadataCategory[]>(() => {
  if (!metadataValues.value) return []

  const tmpStructure = []

  // Push clauses last
  const clauses = attachedMetadataValues.value.filter((el) => el.metadata?.value_type === MultiFieldType.clause)
  const nonClauses = attachedMetadataValues.value.filter((el) => el.metadata?.value_type !== MultiFieldType.clause)

  if (nonClauses.length) {
    tmpStructure.push({
      name: t("metadata.additionalMetadata"),
      entries: nonClauses,
    })
  }

  if (clauses.length) {
    tmpStructure.push({
      name: t("metadata.clauseMetadata"),
      hint: t("metadata.clauseMetadataHint"),
      entries: clauses,
    })
  }

  return tmpStructure
})

const sortedStructure = computed<MetadataCategory[]>(() => {
  if (!metadataValues.value) return []

  if (!sortDirection.value) {
    return structure.value.map((category) => {
      return {
        name: category.name,
        hint: category.hint,
        entries: sortByMetadataName(category.entries, t, sortDirection.value),
      }
    })
  } else {
    return [ {
      name: null,
      entries: sortByMetadataName(metadataValues.value, t, sortDirection.value),
    } ]
  }
})

const filteredStructure = computed<MetadataCategory[]>(() => {
  if (!metadataValues.value) return []
  return sortedStructure.value.map((category) => {
    return {
      name: category.name,
      hint: category.hint,
      entries: filterByMetadataName(category.entries, t, query.value),
    }
  })
})

const confirmationStore = useConfirmationStore()
const {
  setShowConfirmModal,
  setConfirmOptions,
} = confirmationStore

const handleClickGoToMetadataSettings = () => {
  setConfirmOptions({
    title: t("metadata.manageMetadata"),
    description: t("metadata.manageMetadataDescription"),
    buttonText: t("metadata.manageMetadata"),
    callback: () => {
      const url = route("account-settings.metadata.index")
      router.visit(url)
    },
  })
  setShowConfirmModal(true)
}

const attachableFilteredMetadata = computed(() => {
  return filterByMetadataName(attachableMetadata.value, t, query.value)
})

const query = ref("")

const uuidOfIsActiveBoundingBox = ref<Metadata["uuid"] | string>(null)
const uuidOfIsActiveMetadata = ref<Metadata["uuid"]>(null)

const handleSelectBoundingBox = (metadataValue: MetadataValue) => {
  const uuid = metadataValue.prosemirror_uuids?.length ? metadataValue.prosemirror_uuids[0] : metadataValue.metadata?.uuid
  uuidOfIsActiveBoundingBox.value = uuid
  uuidOfIsActiveMetadata.value = metadataValue.metadata?.uuid
  handleShowBoundingBox(metadataValue, props.document, mainContentPdfViewer.value, mainContentScrollContainer.value, prosemirrorUuidBoundingBoxes.value, setUpDeSelectBoundingBoxOnClickOutSide)
  onClickOutside(metadataScrollContainer.value, () => handleDeSelectBoundingBox())
}

const setUpDeSelectBoundingBoxOnClickOutSide = () => {
  onClickOutside(metadataScrollContainer.value, () => handleDeSelectBoundingBox())
}

const handleDeSelectBoundingBox = () => {
  uuidOfIsActiveBoundingBox.value = null
  uuidOfIsActiveMetadata.value = null
  handleHideBoundingBoxes(props.document, mainContentPdfViewer.value)
}

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="flex items-center justify-between px-6 mt-5"
    >
      <h3
        class="flex items-center justify-between gap-1 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('metadata.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('metadata.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
      <button
        v-if="mau?.permissions.includes('metadata_manage')"
        type="button"
        class="block p-1 text-gray-400 hover:text-indigo-500"
        data-tippy-help
        :data-tippy-content="$t('accountSettings.metadata.manageMetadata')"
        data-placement="bottom"
        @click="handleClickGoToMetadataSettings"
      >
        <CogIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
      </button>
    </div>
    <div class="px-6 py-2 border-b border-b-gray-200">
      <div class="flex items-center justify-between space-x-0.5 mb-2">
        <div class="relative grow">
          <input
            v-model="query"
            type="search"
            class="block w-full px-9 min-h-[28px] py-0 text-base sm:text-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-20 focus:outline-none border-0 appearance-none rounded-l-md"
            :class="[!!query ? 'bg-indigo-50 text-indigo-800' : 'bg-gray-100']"
            :placeholder="$t('filter.search') + '…'"
          >
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <MagnifyingGlassIcon
              class="w-4 h-4"
              :class="[!!query ? 'text-indigo-500' : 'text-gray-400']"
            />
          </div>
          <button
            v-if="!!query"
            type="button"
            class="absolute inset-y-0 right-0 flex items-center pr-3"
            @click="query = null"
          >
            <XCircleIcon
              class="w-4 h-4 text-indigo-500"
            />
          </button>
        </div>
        <button
          type="button"
          class="text-sm text-gray-500 min-h-[28px] px-3 h-full flex items-center rounded-r-md bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-20 focus:outline-none"
          @click="toggleSortDirection"
        >
          <BarsArrowDownIcon
            v-if="sortDirection === 'desc'"
            aria-hidden="true"
            class="w-4 h-4"
          />
          <BarsArrowUpIcon
            v-else-if="sortDirection === 'asc'"
            aria-hidden="true"
            class="w-4 h-4"
          />
          <Bars3Icon
            v-else
            aria-hidden="true"
            class="w-4 h-4"
          />
        </button>
      </div>
    </div>
    <div
      v-if="isLoadingMetadata"
      class="p-6"
    >
      <SkeletonLoader
        :size="'large'"
      />
    </div>
    <template v-else>
      <OverlayScrollbar
        ref="metadataScrollContainer"
        tag="div"
        class="flex-1 pt-3 overflow-y-auto max-h-max"
      >
        <template v-if="metadataValues?.length">
          <template
            v-for="(category, categoryIdx) in filteredStructure"
            :key="categoryIdx"
          >
            <h3
              v-if="category.name && category.entries?.length"
              class="relative px-6 py-2 text-sm font-medium text-center text-indigo-500"
            >
              <div class="absolute left-0 right-0 h-[1px] top-1/2 bg-indigo-500" />
              <span class="relative inline-flex items-center px-2 py-1 space-x-1 bg-white">
                <span>{{ category.name }}</span>
                <span
                  v-if="category.hint"
                  data-tippy-help
                  :data-tippy-content="category.hint"
                  data-placement="bottom"
                >
                  <QuestionMarkCircleIcon
                    class="w-4 h-4 text-indigo-500"
                    aria-hidden="true"
                  />
                </span>
              </span>
            </h3>
            <dl
              class="pr-px divide-y divide-gray-100"
            >
              <div
                v-for="entry in category.entries"
                :key="entry.uuid"
                :class="[
                  uuidOfIsActiveMetadata !== entry.metadata?.uuid && (entry.bounding_box || uuidOfIsActiveBoundingBox !== uuidOfIsActiveMetadata) ? entry.metadata?.value_type === MultiFieldType.clause ? 'hover:bg-purple-50 cursor-pointer' : 'hover:bg-gray-50 cursor-pointer' : '',
                  (uuidOfIsActiveMetadata === entry.metadata?.uuid && entry.quotation) || entry.metadata?.value_type === MultiFieldType.clause ? 'pb-4' : ''
                ]"
                class="relative metadata-entry"
              >
                <div
                  v-if="uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === entry.metadata?.uuid && (entry.bounding_box || uuidOfIsActiveBoundingBox !== uuidOfIsActiveMetadata)"
                  class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4"
                  :class="
                    uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === entry.metadata?.uuid && (entry.bounding_box || uuidOfIsActiveBoundingBox !== uuidOfIsActiveMetadata) ?
                      entry.metadata?.value_type === MultiFieldType.clause ? 'bg-purple-500/10 border-2 border-purple-700' : 'bg-indigo-500/10 border-2 border-indigo-700' : ''"
                />
                <ClauseInput
                  v-if="entry.metadata?.value_type === MultiFieldType.clause"
                  :clause="entry"
                  :metadata="entry.metadata"
                  :entity-uuid="entityUuid"
                  :proposed-value="entry.value"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(entry)"
                />
                <MetadataValueComputedInput
                  v-else-if="[ MetadataValueSource.autofilled, MetadataValueSource.computed ].includes(entry.value_source)"
                  :metadata-value="entry"
                  :entity-uuid="entityUuid"
                  :is-highlighted="!!(uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === entry.metadata?.uuid && (entry.bounding_box || uuidOfIsActiveBoundingBox !== uuidOfIsActiveMetadata))"
                  @set-value-source="handleSetValueSource"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(entry)"
                />
                <MetadataValueInput
                  v-else
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + entry.uuid)"
                  :entity-uuid="entityUuid"
                  :metadata-value="entry"
                  :is-highlighted="!!(uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === entry.metadata?.uuid && (entry.bounding_box || uuidOfIsActiveBoundingBox !== uuidOfIsActiveMetadata))"
                  @focus="handleFocus($event, entry)"
                  @blur="handleBlur"
                  @set-value-source="handleSetValueSource"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(entry)"
                />
                <ReferenceQuotation
                  v-if="(uuidOfIsActiveMetadata === entry.metadata?.uuid && entry.quotation) || entry.metadata?.value_type === MultiFieldType.clause"
                  :metadata="entry.metadata"
                  :item="entry.metadata"
                  :uuid-of-is-active-bounding-box="uuidOfIsActiveMetadata"
                  :quotation="entry.quotation"
                  @click="handleSelectBoundingBox(entry)"
                />
              </div>
            </dl>
          </template>
        </template>
        <template v-if="attachableFilteredMetadata?.length && !!query">
          <h3
            class="relative px-6 py-2 text-sm font-medium text-center text-green-600"
          >
            <div class="absolute left-0 right-0 h-[1px] top-1/2 bg-green-600" />
            <span class="relative inline-block px-2 py-1 bg-white">{{ $t('metadata.availableMetadata') }}</span>
          </h3>
          <dl
            class="divide-y divide-gray-100"
          >
            <div
              v-for="entry in attachableFilteredMetadata"
              :key="entry.uuid"
              class="flex items-center justify-between gap-2 px-6 py-2"
            >
              <div class="flex items-center gap-1.5 truncate text-sm text-gray-500">
                <span
                  class="truncate"
                  :title="(entry.type === MetadataType.account || !!entry.account_metadata_uuid) ? entry.display_name : t('metadata.system.' + entry.name + '.name')"
                >{{ (entry.type === MetadataType.account || !!entry.account_metadata_uuid) ? entry.display_name : t('metadata.system.' + entry.name + '.name') }}</span>
                <span
                  v-if="entry.type !== MetadataType.account || !!entry.description"
                  data-tippy-help
                  :data-tippy-content="(entry.type === MetadataType.account || !!entry.account_metadata_uuid) ? entry.description : t('metadata.system.' + entry.name + '.description')"
                  data-placement="bottom"
                >
                  <InformationCircleIcon
                    class="w-3 h-3 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <button
                type="button"
                class="btn-add"
                :disabled="isSubmittingAddMetadata"
                @click="selectedMetadata = entry"
              >
                <PlusIcon
                  aria-hidden="true"
                  class="w-3 h-3"
                />
                <span>{{ $t('common.add') }}</span>
              </button>
            </div>
          </dl>
        </template>
        <EmptyState
          v-else-if="!metadataValues?.length && !(attachableFilteredMetadata?.length && !!query)"
          :hide-button="true"
          class="my-6"
        >
          <template #icon>
            <TagIcon
              aria-hidden="true"
            />
          </template>
          {{ $t('metadata.noMetadata') }}
        </EmptyState>
        <div
          v-if="!query"
          class="mb-6"
          :class="metadataValues?.length || (attachableFilteredMetadata?.length && !!query) ? 'mt-3 ml-3' : '-mt-9'"
        >
          <button
            type="button"
            :class="metadataValues?.length || (attachableFilteredMetadata?.length && !!query) ? '' : 'mx-auto'"
            class="btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
            @click.prevent="showAddMetadataPopover"
          >
            <PlusIcon class="shrink-0 h-3.5 w-3.5" />
            {{ $t('metadata.addMetadata') }}
          </button>
        </div>
      </OverlayScrollbar>
    </template>

    <div class="hidden">
      <div ref="addMetadataValuePopoverRef">
        <MetadataPopover
          v-model:selected-metadata="selectedMetadata"
          :attachable-metadata="attachableMetadata"
          :is-loading-metadata="isLoadingMetadata"
          :dropdown-position="metadataValues?.length > 6 ? 'top' : 'bottom'"
          :is-loading="isSubmittingAddMetadata"
        />
      </div>
    </div>
  </div>
</template>
