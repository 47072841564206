<script setup lang="ts">
// external
import { SparklesIcon, ArrowPathIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"

// internal
import { AiAnalysis, AiAnalysisStatus } from "~/types"

interface Props {
  aiAnalysis?: AiAnalysis
  aiAnalyses?: AiAnalysis[]
  style?: "dark" | "light"
  hideReview?: boolean
  disabled?: boolean
  allowRestart?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    aiAnalysis: null,
    aiAnalyses: () => [],
    style: "light",
    hideReview: false,
    disabled: false,
    allowRestart: false,
  },
)

const statusStyles = props.style === "dark" ? {
  created: "bg-purple-900 text-white",
  pending: "bg-purple-900 text-white",
  executing: "bg-purple-300 text-purple-900",
  failed: "bg-red-100 text-red-800",
  review: "bg-purple-900 text-white",
  applied: "bg-green-100 text-green-800",
} : {
  created: "bg-gray-100 text-gray-800",
  pending: "bg-purple-100 text-purple-800",
  executing: "bg-purple-600 text-white",
  failed: "bg-red-100 text-red-800",
  review: "bg-purple-600 text-white",
  applied: "bg-green-100 text-green-800",
}

const emit = defineEmits([ "review", "restart" ])

// aiAnalysis or all aiAnylyses in failed applied or review
const isRestartable = computed<boolean>(() => {
  return props.allowRestart && ([ AiAnalysisStatus.failed, AiAnalysisStatus.applied, AiAnalysisStatus.review, AiAnalysisStatus.expired ].includes(props.aiAnalysis?.status) || props.aiAnalyses?.every((aiAnalysis) => [ AiAnalysisStatus.failed, AiAnalysisStatus.applied, AiAnalysisStatus.review, AiAnalysisStatus.expired ].includes(aiAnalysis.status)))
})

const aiAnalysesStatus = computed<AiAnalysisStatus>(() => {
  if (!props.aiAnalyses) return null
  // Case 1: All on review
  if (props.aiAnalyses.every((aiAnalysis) => aiAnalysis.status === AiAnalysisStatus.review)) {
    return AiAnalysisStatus.review
  }
  // Case 2: All expired
  if (props.aiAnalyses.every((aiAnalysis) => aiAnalysis.status === AiAnalysisStatus.expired)) {
    return AiAnalysisStatus.expired
  }
  // Case 2; At least one applied
  if (props.aiAnalyses.some((aiAnalysis) => aiAnalysis.status === AiAnalysisStatus.applied)) {
    return AiAnalysisStatus.applied
  }
  // Case 3: At least one failed
  if (props.aiAnalyses.some((aiAnalysis) => aiAnalysis.status === AiAnalysisStatus.failed)) {
    return AiAnalysisStatus.failed
  }
  // Default: pending
  return AiAnalysisStatus.pending

})

</script>

<template>
  <div class="flex items-center">
    <button
      v-if="aiAnalysis?.status === AiAnalysisStatus.review && !props.hideReview"
      type="button"
      class="btn-ai"
      :disabled="disabled"
      :class="style === 'dark' ? 'py-1 -my-1 bg-purple-900 hover:bg-purple-950 focus:ring-0 focus:ring-offset-purple-600 text-white hover:text-white' : ''"
      @click.prevent="emit('review', aiAnalysis)"
    >
      <SparklesIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
      {{ $t('metadata.reviewAi') }}
    </button>
    <span
      v-else
      :class="[
        statusStyles[aiAnalysis?.status || aiAnalysesStatus],
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
      ]"
    >
      {{ $t('aiAnalysis.status.' + (aiAnalysis?.status || aiAnalysesStatus)) }}
    </span>
    <button
      v-if="isRestartable"
      type="button"
      class="flex items-center p-1.5 ml-1 -my-1 rounded-full btn-sm group btn-plain"
      :class="style === 'dark' ? 'hover:bg-gray-900/40' : 'hover:bg-gray-100'"
      data-tippy-help
      :data-tippy-content="$t('aiAnalysis.startNew')"
      data-placement="bottom"
      @click="$emit('restart')"
    >
      <ArrowPathIcon
        class="w-3.5 h-3.5"
        :class="style === 'dark' ? 'text-white/80 group-hover:text-white' : 'text-gray-400 group-hover:text-gray-900'"
        aria-hidden="true"
      />
    </button>
  </div>
</template>
