<script setup lang="ts">

</script>
<template>
  <div>
    <div class="mb-5">
      {{ $t('lexica.content.teams.intro') }}
    </div>
    <div class="mb-3 font-medium">
      {{ $t('lexica.content.teams.needToKnow') }}
    </div>
    <ul class="list-disc ml-4 space-y-2">
      <li>{{ $t('lexica.content.teams.bullet1') }}</li>
      <li>{{ $t('lexica.content.teams.bullet2') }}</li>
      <li>{{ $t('lexica.content.teams.bullet3') }}</li>
      <li>{{ $t('lexica.content.teams.bullet4') }}</li>
    </ul>
  </div>
</template>
