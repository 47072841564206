<script setup lang="ts">
// external
import axios from "axios"
import { ref, computed } from "vue"
import {
  Combobox,
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
  TransitionRoot,
} from "@headlessui/vue"
import { CheckIcon } from "@heroicons/vue/20/solid"
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline"
import { debounce } from "lodash-es"

// internal
import { SpinLoader } from "~/components"
import { Automation, Import, Template, Document } from "~/types"

interface Props {
  endpoint: string
  placeholder: string
  entity?: Partial<Automation|Import|Template|Document>
  inputExtraClasses?: string
  hasError?: boolean
}

const MIN_SEARCH_TERM_LENGTH = 3

const props = withDefaults(
  defineProps<Props>(),
  {
    entity: null,
    inputExtraClasses: "",
    hasError: false,
  },
)

const localEntity = computed({
  get: () => props.entity,
  set: (entity) => emit("update:entity", entity),
})

const emit = defineEmits([ "update:entity" ])

const query = ref("")
const suggestions = ref([])
const isLoadingSuggestions = ref(false)

const autocomplete = async (term) => {
  query.value = term
  suggestions.value = []

  if (query.value?.length >= MIN_SEARCH_TERM_LENGTH) {
    isLoadingSuggestions.value = true
    const response = await axios.get(props.endpoint, {
      params: {
        query: query.value,
      },
    })

    if (response?.data.data?.length) {
      suggestions.value = response.data.data
    } else {
      suggestions.value = []
    }

    isLoadingSuggestions.value = false
  }
}

const debounceAutocomplete = debounce((term) => autocomplete(term), 300)
</script>

<template>
  <div>
    <Combobox
      v-model="localEntity"
    >
      <div class="relative mt-1">
        <ComboboxInput
          class="pr-8 input-plain"
          :class="[inputExtraClasses, hasError ? 'error' : '']"
          :placeholder="placeholder + '…'"
          @change="debounceAutocomplete($event.target.value)"
        />
        <MagnifyingGlassIcon
          class="w-4 h-4 pointer-events-none absolute right-2.5 top-2 mt-px"
          :class="[hasError ? 'text-red-400' : 'text-gray-400 ']"
          aria-hidden="true"
        />

        <TransitionRoot
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            class="listbox-options max-w-full"
          >
            <div
              v-if="query?.length < MIN_SEARCH_TERM_LENGTH"
              class="relative px-4 py-2 text-sm text-gray-300 cursor-default select-none"
            >
              {{ $t('quickSearch.minChars', {min: MIN_SEARCH_TERM_LENGTH}) }}
            </div>
            <SpinLoader
              v-else-if="isLoadingSuggestions"
              class="mx-auto w-4 h-4 text-gray-500 my-2.5"
            />
            <div
              v-else-if="!suggestions || suggestions.length === 0 && query !== ''"
              class="relative px-4 py-2 text-sm text-gray-300 cursor-default select-none"
            >
              {{ $t('commandMenu.noResults') }}
            </div>

            <ComboboxOption
              v-for="suggestion in suggestions"
              :key="suggestion.uuid"
              v-slot="{ selected, active }"
              as="template"
              :value="suggestion"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex items-center']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ suggestion.entity_name || suggestion.name }}</span>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-indigo-500',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>

