<script setup lang="ts">
import { Stage } from "~/types"

interface Props {
  stage?: Stage
  labelClasses?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    stage: null,
    labelClasses: "ml-1 text-sm text-gray-500",
  },
)
</script>

<template>
  <span class="inline-flex items-center">
    <span
      class="relative flex items-center justify-center w-5 h-5 shrink-0"
      aria-hidden="true"
    >
      <span
        class="absolute w-4 h-4 rounded-full"
        :class="props.stage?.colors?.outer"
      />
      <span
        class="relative block w-2 h-2 rounded-full"
        :class="props.stage?.colors?.inner"
      />
    </span>
    <span :class="labelClasses">
      {{ stage ? $t(`documents.stages.${stage?.name}`) : '' }}
    </span>
  </span>
</template>
