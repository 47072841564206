<script setup lang="ts">
import { storeToRefs } from "pinia"
import { nextTick } from "vue"
import { watch } from "vue"
import { computed } from "vue"
import { SkeletonLoader } from "~/components"
import markCommentsAndProposalsHtml from "~/editor/extensions/markCommentsAndProposalsHtml"
import { useCommentStore } from "~/stores"
import { purifyHtml } from "~/utils"


interface Props {
  id?: string
  isLoading?: boolean
  htmlContent?: string
  zoomLevel?: 75 | 80 | 85 | 90 | 95 | 100
  wrapperClasses?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    uuid: "",
    isLoading: false,
    htmlContent: "",
    zoomLevel: 100,
    wrapperClasses: "shadow-2xl",
  },
)

const commentStore = useCommentStore()
const { activeCommentProsemirrorDataUuid: activeCommentUuid, highlightedCommentUuids } = storeToRefs(commentStore)

const scaleClasses = computed<string>(() => {
  // return CSS-based zoom depending on zoom level prop
  const classString = `scale-${props.zoomLevel} origin-top-left`
  return classString
})


const widthStyleString = computed<string>(() => {
  // calc css style for inverted zoomLevel as over width
  const invertedWidth = 100 / props.zoomLevel * 100
  const style = `width: ${invertedWidth}%`
  return style
})

// Mark Comments and Proposals in HTML content when Mounted
watch([ () => props.htmlContent, () => activeCommentUuid.value, () => highlightedCommentUuids.value ], () => {
  const renderedHtmlContainer = document.getElementById(props.id) as HTMLDivElement
  nextTick(() => markCommentsAndProposalsHtml(renderedHtmlContainer, commentStore))
})

</script>

<template>
  <div
    :id="id"
    class="max-w-4xl pt-4 pb-6 mx-auto"
  >
    <div
      class="relative px-6 py-4 bg-white sm:py-10 sm:px-16"
      :class="wrapperClasses"
    >
      <div class="mx-1 my-4">
        <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
        <div
          v-show="!isLoading"
          class="relative ProseMirror rendered-html-container"
          :class="scaleClasses"
          :style="widthStyleString"
          v-html="purifyHtml(htmlContent)"
        />
        <!-- eslint-enable vue/no-v-html vue/html-self-closing -->

        <SkeletonLoader
          v-if="isLoading"
          size="large"
        />
      </div>
    </div>
  </div>
</template>

