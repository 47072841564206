<script setup lang="ts">
// external
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, LinkIcon, PlusCircleIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { computed, ref, watch } from "vue"

// internal
import { Party } from "~/types"
import { PartyEntityTypeDisplayIcon, SpinLoader } from "~/components"
import { usePartyStore } from "~/stores"
import { storeToRefs } from "pinia"
import { throttle } from "lodash-es"
import { formatAddress } from "~/utils"

interface Props {
  id?: string
  accountParties?: Party[]
  selectedParty?: Party
  layout?: string
  isDisabled?: boolean
  allowDeselect?: boolean
  allowNewParty?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    id: "party-entity-name",
    accountParties: () => [],
    selectedParty: null,
    layout: "default",
    isDisabled: false,
    allowDeselect: false,
    allowNewParty: true,
  },
)

const emit = defineEmits([ "update:selected-party", "cancel" ])

const query = ref("")

const selectedParty = computed<Partial<Party>>({
  get: () => props.selectedParty,
  set: (value) => {
    if (!value?.uuid && !props.allowNewParty) return
    emit("update:selected-party", value)
    query.value = ""
  },
})

const filteredParties = computed(() => {
  return query.value === ""
    ? props.accountParties
    : props.accountParties
      .filter((d) => {
        const check = d.entity_name + " " + d.name || ""
        return (check.toLowerCase().includes(query.value.toLowerCase()))
      })
})

const partyStore = usePartyStore()
const { parties, isLoadingGetPartySuggestions, partySuggestions } = storeToRefs(partyStore)
const { getPartySuggestions } = partyStore

const partyExists = computed(() => {
  return props.accountParties?.some((party) => party.entity_name?.toLowerCase() === query.value?.toLowerCase())
})

const queryParty = computed(() => (query.value === "" || partyExists.value || !props.allowNewParty) ? null : { uuid: "", entity_name: query.value })

const checkIfPartyIsDisabled = (accountParty: Party): boolean => {
  return parties.value.some((el) => el.account_party_uuid === accountParty.uuid)
}

const resetSelectedParty = () => {
  selectedParty.value = null
  query.value = ""
}

const handleComboboxInputEnter = () => {
  if (queryParty.value) {
    selectedParty.value = queryParty.value
  }
}

const handleComboboxButtonClick = () => {
  if (!filteredParties.value?.length) {
    query.value = ""
  }
}

watch(query, (newVal) => {
  const stringLength = newVal.length
  if (newVal && stringLength > 2) {
    handleGetPartySuggestions(newVal)
  }
})

const handleGetPartySuggestions = throttle((query: string) => {
  getPartySuggestions(query)
}, 500)

const checkIfPartySuggestionIsDisabled = (party: Partial<Party>): boolean => {
  return parties.value.some((el) => el.entity_name === party.entity_name)
}

</script>

<template>
  <Combobox
    v-if="!props.isDisabled"
    v-model="selectedParty"
    as="div"
    nullable
  >
    <div class="relative">
      <span
        class="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-500"
      >
        <template v-if="selectedParty?.entity_name || selectedParty?.account_party_uuid">
          <PartyEntityTypeDisplayIcon
            :party="selectedParty"
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </template>
      </span>
      <ComboboxInput
        :id="id"
        :data-cy-sel="`party-form-combobox-input`"
        :name="id || 'party-form-combobox-input'"
        :placeholder="$t('partyForm.selectParty') + '…'"
        :class="[
          selectedParty?.entity_name || selectedParty?.account_party_uuid ? 'pl-8 text-indigo-500' : ' ',
        ]"
        class="w-full px-3 py-2 pr-10 input-plain"
        :display-value="
          (party: Party) => party?.entity_name || ''
        "
        autocomplete="party-form-combobox-input"
        @keyup.enter="handleComboboxInputEnter"
        @change="query = $event.target.value"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
        @click="handleComboboxButtonClick"
      >
        <XMarkIcon
          v-if="(selectedParty?.entity_name || selectedParty?.account_party_uuid) && props.allowDeselect"
          class="w-5 h-5 text-gray-400 hover:text-gray-600"
          aria-hidden="true"
          @click.prevent="resetSelectedParty"
        />
        <SpinLoader
          v-else-if="isLoadingGetPartySuggestions"
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
        <ChevronUpDownIcon
          v-else-if="accountParties?.length > 0"
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
      </ComboboxButton>
      <ComboboxOptions
        v-if="queryParty || partyExists || filteredParties.length > 0"
        class="max-w-full mb-1truncate listbox-options"
      >
        <ComboboxOption
          v-if="(queryParty || partyExists) && allowNewParty"
          v-slot="{ active, selected }"
          :value="queryParty"
          :disabled="partyExists"
        >
          <li
            v-cy="`add-party-option-button`"
            :class="[
              'listbox-option flex items-center',
              active ? 'bg-gray-700' : '',
              filteredParties?.length ? 'border-b border-b-gray-700' : ''
            ]"
          >
            <PlusCircleIcon class="w-5 h-5 shrink-0" />
            <span
              :class="['ml-3 truncate', selected && !partyExists && 'font-semibold']"
              class="flex flex-col"
            >
              <span class="truncate">{{ $t('partyForm.addNew') }}: "{{ query }}"</span>
              <span
                v-if="partyExists"
                class="text-red-300"
              >{{ $t('userToParty.partyExists') }}</span>
              <span
                v-else
                class="text-green-300"
              >{{ $t('userToParty.pressEnterToAdd') }}</span>
            </span>
          </li>
        </ComboboxOption>
        <div
          v-if="filteredParties?.length > 0 && allowNewParty"
          class="flex items-center py-1 text-left group px-3 text-slate-500 pt-2 pb-0.5"
          :class="queryParty ? 'border-t border-t-slate-700' : ''"
        >
          <span class="flex-1 text-[10px] font-medium tracking-wider uppercase text-slate-500">{{ $t('partyForm.internalParties') }}</span>
        </div>
        <ComboboxOption
          v-for="accountParty in filteredParties"
          :key="accountParty.uuid"
          v-slot="{ active, selected, disabled }"
          :value="accountParty"
          as="template"
          :disabled="checkIfPartyIsDisabled(accountParty)"
        >
          <li
            :class="[
              'flex items-center py-1 text-left group text-slate-300 hover:text-slate-100 hover:bg-slate-700 hover:cursor-pointer px-1 mx-2 rounded-md last:mb-2 relative',
              active ? 'bg-slate-700' : '',
              selected ? 'pr-10' : 'pr-2',
              disabled ? 'pointer-events-none opacity-40' : '',
            ]"
          >
            <span class="flex items-center p-2 mr-2 rounded-md shrink-0 text-slate-100 group-hover:bg-slate-600 bg-slate-700">
              <PartyEntityTypeDisplayIcon
                :party="accountParty"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </span>
            <span :class="['flex-1 truncate flex flex-col -space-y-1', selected && 'font-semibold']">
              <span class="text-sm">{{ accountParty.entity_name || accountParty.name }}</span>
              <span
                v-if="accountParty.address"
                class="text-xs opacity-50"
              >{{ formatAddress(accountParty.address) }}</span>
            </span>
            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-500',
              ]"
            >
              <CheckIcon
                class="w-5 h-5 shrink-0"
                aria-hidden="true"
              />
            </span>
            <span
              v-else-if="disabled"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-500',
              ]"
            >
              <LinkIcon
                class="w-5 h-5 shrink-0"
                aria-hidden="true"
              />
            </span>
          </li>
        </ComboboxOption>
        <div
          v-if="partySuggestions.length"
          class="flex items-center py-1 text-left group px-3 text-slate-500 pt-2 pb-0.5 mt-2"
          :class="queryParty ? 'border-t border-t-slate-700' : ''"
        >
          <span class="flex-1 text-[10px] font-medium tracking-wider uppercase text-slate-500">{{ $t('partyForm.recommendations') }}</span>
        </div>
        <ComboboxOption
          v-for="partySuggestionsItem, partySuggestionItemIdx in partySuggestions"
          :key="partySuggestionItemIdx"
          v-slot="{ active, selected, disabled }"
          :value="partySuggestionsItem"
          as="template"
          :disabled="checkIfPartySuggestionIsDisabled(partySuggestionsItem)"
        >
          <li
            :class="[
              'flex items-center py-1 text-left group text-slate-300 hover:text-slate-100 hover:bg-slate-700 hover:cursor-pointer px-1 mx-2 rounded-md last:mb-2 relative',
              active ? 'bg-slate-700' : '',
              selected ? 'pr-10' : 'pr-2',
              disabled ? 'pointer-events-none opacity-40' : '',
            ]"
          >
            <span class="flex items-center p-2 mr-2 rounded-md shrink-0 text-slate-100 group-hover:bg-slate-600 bg-slate-700">
              <PartyEntityTypeDisplayIcon
                :party="partySuggestionsItem"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </span>
            <div :class="['flex-1 truncate flex flex-col -space-y-1', selected && 'font-semibold']">
              <span class="text-sm">{{ partySuggestionsItem.entity_name }}</span>
              <span
                v-if="partySuggestionsItem.address"
                class="text-xs opacity-50"
              >{{ formatAddress(partySuggestionsItem.address) }}</span>
            </div>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-500',
              ]"
            >
              <CheckIcon
                class="w-5 h-5 shrink-0"
                aria-hidden="true"
              />
            </span>
            <span
              v-else-if="disabled"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-gray-500',
              ]"
            >
              <LinkIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
      <div
        v-else-if="accountParties?.length > 0"
        class="left-0 right-auto listbox-options bg-slate-800"
      >
        <div
          class="left-0 px-4 py-2 pb-2 text-sm text-gray-400"
        >
          {{ $t('common.noMatchingResults') }}
        </div>
      </div>
    </div>
  </Combobox>
  <div
    v-else
    :name="id"
    class="relative"
  >
    <span
      class="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-500"
    >
      <template v-if="selectedParty">
        <PartyEntityTypeDisplayIcon
          :party="selectedParty"
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
      </template>
    </span>
    <template
      v-if="accountParties?.length > 0"
    >
      <div
        :class="[ selectedParty ? 'pl-8 text-indigo-500' : ' ' ]"
        class="w-full px-3 py-2 pr-10 input-plain"
      >
        {{ selectedParty?.entity_name || selectedParty?.name || '' }}
      </div>
    </template>
    <div
      v-else
      class="w-full px-3 py-2 pr-10 text-gray-500 input-plain"
    >
      {{ $t('parties.notFound') }}
    </div>
  </div>
</template>
