<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, toRaw, ref } from "vue"
import { TrashIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { sortBy } from "lodash-es"
import draggable from "vuedraggable"

// internal
import { EmptyState, FormInputErrors, SpinLoader } from "~/components"
import { DragIndicatorIcon, SignatureIcon } from "~/icons"
import { useDocumentStore, useNotificationStore, useSharedStore, useUserStore } from "~/stores"
import { Document, DocumentUser, DocumentUserRoleEnum, Party, SignatureSecurityLevel, Template } from "~/types"
import { getUserRepresentation } from "~/utils"

interface Props {
  document: Document
  isLoadingDocumentUsers?: boolean
  documentUsers?: DocumentUser[]
  parties: Party[]
  isLoadingParties: boolean
  isEditable?: boolean
  signatureSecurityLevel?: SignatureSecurityLevel
  sequentialSigning?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    documentUsers: () => [],
    isLoadingDocumentUsers: false,
    parties: () => [],
    isLoadingParties: false,
    isEditable: false,
    signatureSecurityLevel: null,
    sequentialSigning: null,
  },
)
const emit = defineEmits([ "update:sequential-signing" ])

const documentStore = useDocumentStore()
const {
  keysOfIsUpdatingDocumentField,
  keysOfLastSavedDocumentField,
  isSyncingSigningOrder,
  mdu,
  isLockedDocument,
} = storeToRefs(documentStore)
const { syncSigningOrder } = documentStore

const userStore = useUserStore()
const { userErrorsMap } = storeToRefs(userStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const { notify } = useNotificationStore()
const { t } = useI18n()

const localSequentialSigning = computed<boolean>({
  get: () => props.signatureSecurityLevel === SignatureSecurityLevel.QES ? true : props.sequentialSigning,
  set: (val) => {
    emit("update:sequential-signing", val)
  },
})

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const signatories = computed({
  get: () => {
    const filteredValues = props.documentUsers?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory))
    return sortBy(filteredValues, "signing_order")
  },
  set: (val) => {

    val.forEach((el, idx) => {
      const pos = idx + 1
      const documentUserToUpdate = props.documentUsers.find((d) => d.uuid === el.uuid)
      if (!documentUserToUpdate) return
      const newValue = {
        ...documentUserToUpdate,
        uuid: el.uuid,
        signing_order: toRaw(pos),
      }
      userStore.pushOrUpdateUser(newValue)
    })

    syncSigningOrder(entityUuid.value, val.map((signatory) => signatory.uuid))

  },
})

const getPartyByUuid = (uuid: Party["uuid"]) => props.parties?.find((el) => el.uuid === uuid)

const uuidOfIsRemovingUserAsSignatory = ref<DocumentUser["uuid"] | null>(null)
const handleRemoveUserAsSignatory = async (userUuid: DocumentUser["uuid"]) => {
  try {
    uuidOfIsRemovingUserAsSignatory.value = userUuid
    const res = await userStore.removeUserAsSignatory(userUuid, entityUuid.value, crudContext.value)
    if (res) return true
  } catch (err) {
    notify({
      title: t("userSettings.errors.removeAsSignatory"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    uuidOfIsRemovingUserAsSignatory.value = null
  }
}

</script>
<template>
  <div>
    <ul
      v-if="isEditable && signatories?.length > 1"
      role="list"
      class="grid grid-cols-1"
    >
      <li
        class="border-b border-gray-200"
      >
        <SwitchGroup
          v-if="isEditable && !!(props.signatureSecurityLevel !== SignatureSecurityLevel.QES)"
          v-cy="`sequential-signing-switch`"
          as="div"
          class="flex items-center gap-4 px-6 py-3"
          :class="keysOfLastSavedDocumentField?.includes('sequential_signing') ? 'bg-teal-50' : ''"
        >
          <Switch
            v-model="localSequentialSigning"
            :class="[
              localSequentialSigning
                ? 'bg-indigo-600'
                : 'bg-gray-300',
              'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1',
            ]"
          >
            <span
              aria-hidden="true"
              :class="[
                localSequentialSigning
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </Switch>
          <SwitchLabel
            as="span"
            class="text-sm text-gray-400 grow"
            passive
          >
            <h3 class="flex items-center gap-1 text-sm font-semibold text-gray-700">
              {{ $t('signatures.setSignatureOrder') }}
            </h3>
            <span class="truncate">{{ $t('signatures.setSignatureOrderDescription') }}</span>
          </SwitchLabel>
          <SpinLoader
            v-if="keysOfIsUpdatingDocumentField?.includes('sequential_signing')"
            class="h-3.5 w-3.5 text-gray-500"
          />
        </SwitchGroup>
        <div
          v-else
          class="flex items-center gap-4 px-6 py-3"
        >
          <div
            :class="[
              !!(props.signatureSecurityLevel === SignatureSecurityLevel.QES) || sequentialSigning
                ? 'bg-indigo-600'
                : 'bg-gray-300',
              'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed mt-1',
            ]"
          >
            <span
              aria-hidden="true"
              :class="[
                !!(props.signatureSecurityLevel === SignatureSecurityLevel.QES) || sequentialSigning
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </div>
          <span class="text-sm text-gray-400 grow">
            <h3 class="text-sm font-semibold text-gray-700 xs:flex xs:items-center xs:gap-1">
              {{ $t('signatures.setSignatureOrder') }}
              <span
                v-if="!!(props.signatureSecurityLevel === SignatureSecurityLevel.QES)"
                class="block text-indigo-700 xs:inline-block"
              >
                ({{ $t('signatures.requiredForQes') }})
              </span>
            </h3>
            <span class="truncate">{{ $t('signatures.setSignatureOrderDescription') }}</span>
          </span>
        </div>
      </li>
    </ul>
    <ul
      v-if="signatories?.length"
      role="list"
      class="grid grid-cols-1"
    >
      <draggable
        v-model="signatories"
        v-cy="`signatories-list`"
        :disabled="!localSequentialSigning"
        :animation="150"
        :ghost-class="'ghost'"
        group="signatories"
        item-key="uuid"
      >
        <template #item="{element, index}">
          <div
            :class="localSequentialSigning ? 'hover:bg-gray-50 group cursor-pointer bg-white' : ''"
            class="relative border-b group border-b-gray-200"
          >
            <div class="flex items-center justify-between w-full px-6 py-3 space-x-4">
              <div
                v-if="localSequentialSigning"
                class="flex items-center w-8 space-x-2"
              >
                <div class="text-gray-400 shrink-0 group-hover:text-gray-600">
                  <DragIndicatorIcon class="w-4 h-4 -ml-1" />
                </div>
                <div class="font-medium text-right text-gray-400 grow">
                  {{ index+1 }}
                </div>
              </div>
              <div class="relative shrink-0">
                <img
                  class="w-8 h-8 rounded-full"
                  :src="element?.profile_photo_url"
                  alt=""
                >
                <div class="absolute -bottom-1 -right-1 shrink-0 rounded-md bg-white group-hover:bg-gray-50 flex items-center p-0.5">
                  <SignatureIcon
                    class="text-gray-700 w-3.5 h-3.5"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-2">
                  <h3
                    class="text-sm font-medium text-gray-900 truncate"
                  >
                    {{ getUserRepresentation(element) }}
                  </h3>
                </div>
                <p
                  v-if="element.first_name && element.last_name && !element.account_user?.uuid"
                  class="text-xs text-gray-500 truncate"
                >
                  {{ element.email }}
                </p>
                <p
                  v-else
                  class="text-xs text-gray-500 truncate"
                >
                  {{ $t('userSettings.internalUser') }}
                </p>
                <p
                  v-if="element.party_uuid"
                  class="text-xs text-gray-500 truncate"
                >
                  {{ getPartyByUuid(element.party_uuid)?.entity_name ? getPartyByUuid(element.party_uuid)?.entity_name : getPartyByUuid(element.party_uuid)?.name }}
                </p>
                <FormInputErrors
                  v-if="userErrorsMap[element.uuid] && userErrorsMap[element.uuid]?.signing_order?.length"
                  :errors="userErrorsMap[element.uuid]?.signing_order"
                />
              </div>
              <SpinLoader
                v-if="isSyncingSigningOrder || uuidOfIsRemovingUserAsSignatory === element.uuid"
                class="w-3.5 h-3.5 ml-2 text-gray-500"
              />
              <button
                v-else-if="mdu?.permissions?.includes('document_user_delete') && !isLockedDocument"

                class="p-1 text-sm text-gray-400 rounded-full shrink-0 hover:bg-red-200 hover:text-red-700 disabled:hover:bg-transparent disabled:hover:text-gray-400 disabled:cursor-not-allowed disabled:opacity-50"
                @click="handleRemoveUserAsSignatory(element.uuid)"
              >
                <TrashIcon
                  class="w-3.5 h-3.5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </template>
      </draggable>
    </ul>
    <EmptyState
      v-else
      :hide-button="true"
      class="my-6"
    >
      <template #icon>
        <SignatureIcon
          aria-hidden="true"
        />
      </template>
      {{ $t('signatures.noSignatoryFound') }}
    </EmptyState>
    <slot
      name="addButton"
      :sequential-signing="localSequentialSigning"
    />
  </div>
</template>
