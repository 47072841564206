<script setup lang="ts">
// external
import { DocumentDuplicateIcon, UsersIcon, MagnifyingGlassIcon, BarsArrowDownIcon, BarsArrowUpIcon, BackspaceIcon } from "@heroicons/vue/24/outline"
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { ref, watch, computed } from "vue"
import { useI18n } from "vue-i18n"
import { router } from "@inertiajs/vue3"

// internal
import { DialogModal, SpinLoader, DocumentCreateForm, TeamIconDisplay, EmptyState, SubscriptionUpgradeBox } from "~/components"
import { Team, Template, AccountUser, Party, DocumentType } from "~/types"
import { documentTypes } from "~/utils"
import { useAccountStore } from "~/stores"
import { storeToRefs } from "pinia"

interface Props {
  show: boolean
  templates: Template[]
  accountParties: Party[]
  mau: AccountUser
  teams: Team[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const { t } = useI18n()


const accountStore = useAccountStore()
const { planFeatures } = storeToRefs(accountStore)
const canCreateFromScratch = computed(() => planFeatures.value?.["create-document-from-scratch"])
const canCreateFromTemplate = computed(() => planFeatures.value?.["create-document-from-template"])

const tabs = computed(() => {
  const tmpTabs = []
  if (props.mau?.permissions.includes("document_create_from_template") && canCreateFromTemplate.value) {
    tmpTabs.push({ id: "template", name: t("documents.create.fromTemplate"), active: true })
  }
  if (props.mau?.permissions.includes("document_create_from_scratch") && canCreateFromScratch.value) {
    tmpTabs.push({ id: "scratch", name: t("documents.create.draftFromScratch"), active: false })
  }
  return tmpTabs
})

watch(tabs, (newVal) => {
  if (!newVal.length) {
    activeTab.value = null
  } else if (newVal.length === 1) {
    activeTab.value = newVal[0]
  } else {
    activeTab.value = props.templates.length ? tabs.value[0] : tabs.value[1]
  }
})

const query = ref("")
const sortDirection = ref<"asc"|"desc">("desc")

const activeTab = ref(props.templates.length ? tabs.value[0] : tabs.value[1])
const documentCreateForm = ref<any>()
const isSubmittingDocumentCreateForm = ref<boolean>(false)
const isLoadingCreateFromTemplate = ref<string>(null)

const sortedTemplates = computed(() => {
  const tmpTemplates = [ ...props.templates ]

  if (!sortDirection.value) return tmpTemplates

  tmpTemplates.sort((a, b) => {
    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
  })

  if (sortDirection.value === "asc") {
    return tmpTemplates.reverse()
  }
  return tmpTemplates
})

const filteredTemplates = computed(() => {
  if (!query.value) return sortedTemplates.value
  return sortedTemplates.value.filter((entry) => entry.name.toLowerCase().includes(query.value.toLowerCase()))
})


watch(activeTab, () => {
  documentCreateForm.value?.resetDocumentForm()
})

const handleDocumentFromScratch = async (): Promise<void> => {
  isSubmittingDocumentCreateForm.value = true
  try {
    const newId = await documentCreateForm.value?.handleDocumentFromScratch()
    if (newId) router.visit(route("documents.show", newId))
  } finally {
    isSubmittingDocumentCreateForm.value = false
  }
}

const handleDocumentFromTemplate = async (templateUuid: Template["uuid"]) => {
  isLoadingCreateFromTemplate.value = templateUuid
  try {
    const newId = await documentCreateForm.value?.handleDocumentFromTemplate(templateUuid, documentName.value)
    if (newId) router.visit(route("documents.show", newId))
  }
  finally {
    isLoadingCreateFromTemplate.value = null
  }
}

const close = () => {
  emit("close")
  location.hash = ""
  documentCreateForm.value?.resetDocumentForm()
}

const toggleSortDirection = () => {
  sortDirection.value = sortDirection.value === "desc" ? "asc" : "desc"
}

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

const emit = defineEmits([ "close" ])

const selectedTemplate = ref<Template>(null)

const handleSelectTemplate = (template: Template) => {
  selectedTemplate.value = template
  documentName.value = template.name
  goingBack.value = false
}

const handleDeselectTemplate = () => {
  selectedTemplate.value = null
  documentName.value = ""
  goingBack.value = true
}

const goingBack = ref<boolean>(false)
const documentName = ref<string>("")

</script>

<template>
  <DialogModal
    id="documentCreateModal"
    :show="show"
    max-width="2xl"
    :padding="false"
    :show-footer="!!tabs.length"
    :show-close-button="true"
    @close="close"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ $t('documents.create.title') }}
      </div>
    </template>

    <template #content>
      <template v-if="tabs?.length > 1">
        <div class="px-4 sm:hidden">
          <label
            for="tabs"
            class="sr-only"
          >{{ $t('common.selectTab') }}</label>
          <select
            id="tabs"
            v-model="activeTab"
            name="tabs"
            class="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option
              v-for="(tab, tabIdx) in tabs"
              :key="tabIdx"
              :value="tab"
              :selected="tab?.id === activeTab?.id"
            >
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="hidden -mt-2 sm:block">
          <div class="border-b border-gray-300">
            <nav
              class="flex -mb-px"
              aria-label="Tabs"
            >
              <button
                v-for="(tab, tabIdx) in tabs"
                :key="tabIdx"
                v-cy="`document-create-tab-${tab?.id}`"
                :data-cy-sel="`document-create-tab-${tab?.id}`"
                type="button"
                :class="[
                  tab?.id === activeTab?.id
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-400',
                  'w-1/2 py-3 px-1 text-center border-b-2 font-medium text-sm',
                ]"
                :aria-current="tab?.id === activeTab?.id ? 'page' : undefined"
                @click="activeTab = tab"
              >
                {{ tab.name }}
              </button>
            </nav>
          </div>
        </div>
      </template>

      <div
        v-cy="`document-create-modal`"
        class="flex flex-col justify-between flex-1"
      >
        <SubscriptionUpgradeBox
          v-if="!canCreateFromScratch && !canCreateFromTemplate"
          :text="$t('accountSettings.billing.featureRequiresUpgrade')"
          class="absolute inset-0 overflow-hidden rounded-lg"
        />
        <template v-else>
          <div v-show="activeTab?.id === 'template'">
            <div class="overflow-hidden grow">
              <EmptyState
                v-if="!templates.length"
                :hide-button="!mau?.permissions.includes('template_manage')"
                @click="() => router.visit(route('templates.index'))"
              >
                <template #icon>
                  <DocumentDuplicateIcon
                    aria-hidden="true"
                  />
                </template>
                <template #default>
                  {{ $t('documents.noTemplates.title') }}
                </template>
                <template #call-to-action>
                  {{ $t('documents.noTemplates.description') }}
                </template>
                <template #button-text>
                  {{ $t('documents.noTemplates.button') }}
                </template>
              </EmptyState>
              <div
                v-else
                class="w-full max-w-full overflow-x-hidden"
              >
                <div
                  class="relative whitespace-nowrap min-h-[300px]"
                >
                  <div
                    v-if="selectedTemplate"
                    class="absolute inset-0 z-0"
                  >
                    <div
                      class="flex flex-col h-full px-6 py-6 space-y-4"
                    >
                      <div class="px-6 pt-4 -mx-6 -mt-6">
                        <button
                          type="button"
                          :class="[
                            'my-2 flex items-center bg-gray-100 py-1 text-left group text-gray-700 hover:text-gray-900 hover:bg-gray-200 hover:cursor-pointer px-1 pr-2 text-sm rounded-md last:mb-2 relative',
                          ]"
                          @click.prevent="handleDeselectTemplate"
                        >
                          <span
                            class="flex items-center p-1 mr-1 shrink-0"
                          >
                            <BackspaceIcon
                              class="w-4 h-4 shrink-0"
                              aria-hidden="true"
                            />
                          </span>

                          <span>
                            {{ $t('documents.create.backToTemplateSelection') }}
                          </span>
                        </button>
                      </div>
                      <div>
                        <label
                          for="document-name"
                          class="block text-sm font-medium text-gray-900"
                        >
                          {{ $t('documents.create.selectedTemplate') }}
                        </label>
                        <div class="py-3 pl-2 pr-4 mt-1 rounded-md bg-indigo-50 grow">
                          <div class="flex items-center text-indigo-700 justify-items-end">
                            <component
                              :is="getDocumentTypeInfo(selectedTemplate?.document_type || DocumentType.other)?.icon || DocumentDuplicateIcon"
                              class="w-10 h-10 mr-2 text-indigo-400"
                            />
                            <div class="text-sm truncate grow">
                              <p class="flex items-center gap-2 font-medium truncate">
                                {{ selectedTemplate.name }}
                                <SpinLoader
                                  v-if="isLoadingCreateFromTemplate === selectedTemplate.uuid"
                                  class="w-4 h-4 ml-2"
                                />
                              </p>
                              <p
                                v-if="selectedTemplate.description"
                                class="text-sm text-indigo-400 truncate"
                              >
                                {{ selectedTemplate.description }}
                              </p>
                            </div>
                            <div
                              class="flex items-center ml-4 text-sm shrink-0"
                            >
                              <template v-if="selectedTemplate.teams?.length === 1">
                                <TeamIconDisplay
                                  :team="selectedTemplate.teams[0]"
                                  class="w-4 h-4 mr-2"
                                />
                                <p class="block truncate">
                                  {{ selectedTemplate.teams[0].name }}
                                </p>
                              </template>
                              <template v-else-if="selectedTemplate.teams?.length > 1">
                                <p
                                  class="block truncate"
                                  data-tippy-list
                                  data-placement="top"
                                  :data-tippy-content="`${$t('teams.team', selectedTemplate.teams?.length)}:\n${selectedTemplate.teams?.map((team) => team.name).join('\n')}`"
                                >
                                  {{ selectedTemplate.teams?.length }} {{ $t('teams.team', selectedTemplate.teams?.length) }}
                                </p>
                              </template>
                              <template v-else>
                                <p class="block text-indigo-300 truncate">
                                  {{ $t('teams.noTeam') }}
                                </p>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          for="document-name"
                          class="block text-sm font-medium text-gray-900"
                        >
                          {{ $t('documents.create.formFields.name.label') }} <span class="text-indigo-500">*</span>
                        </label>
                        <div class="mt-1">
                          <input
                            id="document-name"
                            v-model="documentName"
                            data-cy-sel="document-name-input"
                            :placeholder="$t('documents.create.formFields.name.placeholder') + '…'"
                            type="text"
                            name="document-name"
                            class="input-plain"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-full transition-transform duration-300 bg-white shadow-lg"
                    :class="selectedTemplate ? '-translate-x-full max-h-[300px] overflow-y-hidden' : 'translate-x-0'"
                  >
                    <div class="flex items-center justify-between px-4 py-3 space-x-1 bg-white">
                      <div class="relative grow">
                        <input
                          v-model="query"
                          type="search"
                          class="block w-full px-9 min-h-[38px] py-0 text-base sm:text-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none border-0 appearance-none rounded-l-md"
                          :class="[!!query ? 'bg-indigo-50 text-indigo-800' : 'bg-gray-100']"
                          :placeholder="$t('filter.search') + '…'"
                        >
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <MagnifyingGlassIcon
                            class="w-4 h-4"
                            :class="[!!query ? 'text-indigo-500' : 'text-gray-400']"
                          />
                        </div>
                        <button
                          v-if="!!query"
                          type="button"
                          class="absolute inset-y-0 right-0 flex items-center pr-3"
                          @click="query = null"
                        >
                          <XCircleIcon
                            class="w-4 h-4 text-indigo-500"
                          />
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          class="text-sm text-gray-500 min-h-[38px] px-3 h-full flex items-center rounded-r-md bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none"
                          @click="toggleSortDirection"
                        >
                          <BarsArrowDownIcon
                            v-if="sortDirection === 'desc' || !sortDirection"
                            aria-hidden="true"
                            class="w-5 h-5"
                          />
                          <BarsArrowUpIcon
                            v-else-if="sortDirection === 'asc'"
                            aria-hidden="true"
                            class="w-5 h-5"
                          />
                        </button>
                      </div>
                    </div>
                    <EmptyState
                      v-if="!filteredTemplates.length"
                      @click="() => query = null"
                    >
                      <template #icon>
                        <DocumentDuplicateIcon
                          aria-hidden="true"
                        />
                      </template>
                      <template #default>
                        {{ $t('templates.empty.title') }}
                      </template>
                      <template #call-to-action>
                        {{ $t('templates.empty.descriptionFilter') }}
                      </template>
                      <template #button-text>
                        {{ $t('templates.empty.buttonFilter') }}
                      </template>
                    </EmptyState>
                    <template v-else>
                      <ul
                        v-cy="`document-create-from-template-list`"
                        role="list"
                        class="relative -space-y-px bg-white rounded-md"
                      >
                        <li
                          v-for="template in filteredTemplates"
                          :key="template.uuid"
                        >
                          <button
                            v-cy="`document-create-from-template-list-template-${template.name.toLowerCase()}`"
                            type="button"
                            :disabled="!!(isLoadingCreateFromTemplate)"
                            :class="[isLoadingCreateFromTemplate && isLoadingCreateFromTemplate !== template.uuid ? 'disabled:opacity-50' : '']"
                            class="relative z-0 block w-full text-left border cursor-pointer group hover:bg-indigo-100 hover:text-indigo-700 hover:z-10 hover:border-indigo-200 disabled:pointer-events-none"
                            @click.prevent="handleSelectTemplate(template)"
                          >
                            <div class="py-3 pl-2 pr-4">
                              <div class="flex items-center justify-items-end">
                                <component
                                  :is="getDocumentTypeInfo(template?.document_type || DocumentType.other)?.icon || DocumentDuplicateIcon"
                                  class="w-10 h-10 mr-2 text-gray-300 group-hover:text-indigo-400"
                                />
                                <div class="text-sm truncate grow">
                                  <p class="flex items-center gap-2 font-medium truncate">
                                    {{ template.name }}
                                    <SpinLoader
                                      v-if="isLoadingCreateFromTemplate === template.uuid"
                                      class="w-4 h-4 ml-2"
                                    />
                                  </p>
                                  <p
                                    v-if="template.description"
                                    class="text-sm text-gray-400 truncate group-hover:text-indigo-400"
                                  >
                                    {{ template.description }}
                                  </p>
                                </div>
                                <div
                                  class="flex items-center ml-4 text-sm shrink-0"
                                >
                                  <template v-if="template.teams?.length === 1">
                                    <TeamIconDisplay
                                      :team="template.teams[0]"
                                      class="w-4 h-4 mr-2"
                                    />
                                    <p class="block truncate">
                                      {{ template.teams[0].name }}
                                    </p>
                                  </template>
                                  <template v-else-if="template.teams?.length > 1">
                                    <p
                                      class="block truncate"
                                      data-tippy-list
                                      data-placement="top"
                                      :data-tippy-content="`${$t('teams.team', template.teams?.length)}:\n${template.teams?.map((team) => team.name).join('\n')}`"
                                    >
                                      {{ template.teams?.length }} {{ $t('teams.team', template.teams?.length) }}
                                    </p>
                                  </template>
                                  <template v-else>
                                    <p class="block text-gray-300 truncate group-hover:text-indigo-300">
                                      {{ $t('teams.noTeam') }}
                                    </p>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </button>
                        </li>
                      </ul>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab?.id === 'scratch'">
            <EmptyState
              v-if="!accountParties.length"
              :hide-button="!mau?.permissions.includes('account_manage')"
              @click="() => router.visit(route('account-settings.parties.create'))"
            >
              <template #icon>
                <UsersIcon
                  aria-hidden="true"
                />
              </template>
              <template #default>
                {{ $t('documents.noAccountParties.title') }}
              </template>
              <template #call-to-action>
                {{ $t('documents.noAccountParties.description') }}
              </template>
              <template #button-text>
                {{ $t('documents.noAccountParties.button') }}
              </template>
            </EmptyState>
            <DocumentCreateForm
              v-else
              ref="documentCreateForm"
              :account-parties="accountParties"
              :teams="teams"
              :is-upload-pdf="false"
              :is-select-template="false"
              :templates="templates"
              @submit="handleDocumentFromScratch"
            />
          </div>
        </template>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="close"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          :disabled="isSubmittingDocumentCreateForm || !!isLoadingCreateFromTemplate || (activeTab?.id === 'template' && !selectedTemplate)"
          :data-cy-sel="`document-create-form-submit-button`"
          type="button"
          class="flex items-center gap-2 btn-primary"
          @click.prevent="activeTab?.id === 'template' ? handleDocumentFromTemplate(selectedTemplate?.uuid) : handleDocumentFromScratch()"
        >
          <SpinLoader
            v-if="isSubmittingDocumentCreateForm"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span v-if="isSubmittingDocumentCreateForm || !!isLoadingCreateFromTemplate">{{ $t('common.pleaseWait') }}…</span>
          <span v-else>{{ $t('documents.empty.button') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
