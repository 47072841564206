<script setup lang="ts">
// external
import { computed } from "vue"
import { storeToRefs } from "pinia"

// internal
import { Metadata, MetadataValue, MultiFieldType } from "~/types"
import { convertCurrencyString, formatMultifieldNumber, formatDateLocalized } from "~/utils"
import { useAccountStore } from "~/stores"
import { useI18n } from "vue-i18n"


interface Props {
  name: Metadata["name"],
  values: MetadataValue[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const { locale } = useI18n()

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const metadataValue = computed(() => props.values.find((metadataValue) => metadataValue.metadata.name === props.name))
const metadataValueType = computed(() => metadataValue.value?.metadata.value_type ?? null)

</script>

<template>
  <div>
    <!-- EMPTY STATE -->
    <span
      v-if="!metadataValue || metadataValue.value === null"
      class="text-gray-400 whitespace-nowrap"
    >{{ $t('common.notSet') }}</span>
    <!-- SPECIAL METADATA -->
    <span
      v-else-if="name === 'contract_type'"
    >{{ $t('contractTypes.' + metadataValue.value) }}</span>
    <span
      v-else-if="name === 'contract_duration_type'"
    >{{ $t(`contractDurationTypes.${metadataValue.value}.name`) }}</span>
    <!-- DIFFERENT TYPE HANDLING -->
    <span
      v-else-if="[MultiFieldType.currency, MultiFieldType.currency_duration].includes(metadataValueType)"
      class="whitespace-nowrap"
    >{{ convertCurrencyString(metadataValue.value, null, locale) }}</span>
    <span v-else-if="[MultiFieldType.bool, MultiFieldType.clause].includes(metadataValueType)">{{ metadataValue.value ? $t('common.yes') : $t('common.no') }}</span>
    <span v-else-if="mau && [MultiFieldType.date, MultiFieldType.timestamp].includes(metadataValueType)">{{ formatDateLocalized(metadataValue.value, mau.user.locale) }}</span>
    <span v-else-if="metadataValueType === MultiFieldType.number">{{ formatMultifieldNumber(metadataValue.value, null, MultiFieldType.number, locale) }}</span>
    <span
      v-else
      class="line-clamp-2"
    >{{ metadataValue.value }}</span>
  </div>
</template>
