<script setup lang="ts">
// external
import { ref, watch, computed } from "vue"
import { PencilIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"

// internal
import { AccountLogoModal, BrandPreview, BrandColor, OnboardingStepContainer } from "~/components"
import { industries } from "~/utils/constants"
import { useAccountStore, useOnboardingStore } from "~/stores"
import { Vue3Lottie } from "vue3-lottie"
import { Customize, BrandIdentity } from "~/lottie"
import { LightBulbIcon } from "@heroicons/vue/24/outline"

const accountStore = useAccountStore()
const { planFeatures } = storeToRefs(accountStore)
const hasBranding = computed(() => planFeatures.value?.["advanced-branding"])

const onboardingStore = useOnboardingStore()
const {
  account,
  companySize,
  industry,
  subIndustry,
  ciColors,
  logoBlob,
  logoImageUrl,
  showBrandingOptions,
} = storeToRefs(onboardingStore)

const showAccountLogoModal = ref(false)
const removeAccountLogoStatus = ref<boolean>(false)

const setAccountLogo = (blob) => {
  logoBlob.value = blob
  const urlCreator = window.URL || window.webkitURL
  logoImageUrl.value = urlCreator.createObjectURL(blob)
  removeAccountLogoStatus.value = false
  setAccountLogoModal(false)
}

const removeAccountLogo = () => {
  logoBlob.value = null
  removeAccountLogoStatus.value = true
  logoImageUrl.value = null
  setAccountLogoModal(false)
}

const setAccountLogoModal = (show) => {
  showAccountLogoModal.value = show
}

const companySizeOptions = [ "1-10", "11-50", "51-200", "201-500", "500+", "set_later" ]

watch(industry, () => {
  subIndustry.value = null
})

const { t } = useI18n()

const sortIndustries = (industryEntries, sortDirection = "desc") => {
  const tmpIndustries = [ ...industryEntries ]
  tmpIndustries.sort((a, b) => {
    const nameA = t("industries." + (a?.key || a))
    const nameB = t("industries." + (b?.key || b))

    if (sortDirection === "desc") {
      return (nameA > nameB) ? 1 : ((nameB > nameA) ? -1 : 0)
    } else {
      return (nameB > nameA) ? 1 : ((nameA > nameB) ? -1 : 0)
    }
  })
  return tmpIndustries
}

const sortedIndustries = computed(() => sortIndustries(industries))
const sortedSubIndustries = computed(() => {
  if (!industry.value) return []
  const subIndustries = industries.find((industryEntry) => industryEntry.key === industry.value).entries
  return sortIndustries(subIndustries)
})

</script>
<template>
  <OnboardingStepContainer v-cy="`onboarding-step-company`">
    <template #default>
      <div class="space-y-8">
        <div>
          <div class="mb-1 text-sm font-medium">
            {{ $t('onboarding.steps.company.companySize') }}
          </div>
          <div class="flex flex-wrap gap-1 sm:gap-2">
            <button
              v-for="sizeOption in companySizeOptions"
              :key="sizeOption"
              type="button"
              class="btn-sm"
              :class="companySize === sizeOption ? 'btn-primary' : 'btn-white'"
              @click="companySize = sizeOption"
            >
              {{ sizeOption === 'set_later' ? $t('onboarding.steps.company.setLater') : sizeOption }}
            </button>
          </div>
        </div>
        <div class="grid sm:grid-cols-2">
          <div>
            <div class="mb-1 text-sm font-medium">
              {{ $t('onboarding.steps.company.industry') }}
            </div>
            <Listbox
              v-model="industry"
            >
              <div class="relative">
                <ListboxButton class="w-full text-left btn-white pr-9">
                  <div
                    v-if="industry"
                    class="truncate"
                  >
                    {{ $t('industries.' + industry) }}
                  </div>
                  <div
                    v-else
                    class="text-gray-500"
                  >
                    {{ $t('onboarding.steps.company.selectIndustry') }}…
                  </div>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      v-if="!industry"
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <XMarkIcon
                      v-else
                      class="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
                      aria-hidden="true"
                      @click.prevent="industry = null"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="left-0 right-auto listbox-options">
                    <ListboxOption
                      v-for="industryOption in sortedIndustries"
                      :key="industryOption.key"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="industryOption.key"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option pr-12']">
                        <div class="truncate">
                          <span>{{ $t('industries.' + industryOption.key) }}</span>
                        </div>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div
            :class="industry ? '' : 'hidden lg:block opacity-0 translate-x-full pointer-events-none'"
            class="mt-5 transition-all sm:ml-5 sm:mt-0"
          >
            <div class="mb-1 text-sm font-medium">
              {{ $t('onboarding.steps.company.subIndustry') }}
            </div>
            <Listbox
              v-model="subIndustry"
            >
              <div class="relative">
                <ListboxButton class="w-full text-left btn-white pr-9">
                  <div
                    v-if="subIndustry"
                    class="truncate"
                  >
                    {{ $t('industries.' + subIndustry) }}
                  </div>
                  <div
                    v-else
                    class="text-gray-500"
                  >
                    {{ $t('onboarding.steps.company.selectSubIndustry') }}…
                  </div>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      v-if="!subIndustry"
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <XMarkIcon
                      v-else
                      class="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
                      aria-hidden="true"
                      @click.prevent="subIndustry = null"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="listbox-options">
                    <ListboxOption
                      v-for="subIndustryOption in sortedSubIndustries"
                      :key="subIndustryOption"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="subIndustryOption"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option pr-12']">
                        <div class="truncate">
                          <span>{{ $t('industries.' + subIndustryOption) }}</span>
                        </div>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
        </div>

        <div v-if="hasBranding">
          <div class="mb-2 text-sm font-medium">
            {{ $t('settings.sections.brandIdentity') }}
          </div>
          <div class="p-3 mt-2 bg-gray-100 rounded-md ">
            <div
              v-if="showBrandingOptions"
              class="p-3 space-y-5 bg-white border border-gray-300 rounded-md shadow-sm"
            >
              <div>
                <div class="flex flex-col items-start max-w-xl">
                  <div
                    class="flex items-center mb-1 text-sm font-medium"
                  >
                    <span class="mr-1">{{ $t('accountSettings.brandIdentity.logo') }}</span>
                  </div>
                  <div class="relative max-w-full p-1 overflow-hidden group">
                    <div
                      v-if="!logoImageUrl"
                      class="flex items-center justify-center px-3 bg-indigo-200 border-white rounded-lg shrink-0 h-14 outline-dashed border-px outline-indigo-200"
                    >
                      <span class="text-xl font-medium text-indigo-700">{{ $t('accountSettings.brandIdentity.noLogoSet') }}</span>
                    </div>
                    <img
                      v-else
                      class="w-auto shrink-0 max-h-14"
                      :src="logoImageUrl"
                      :alt="$t('accountSettings.brandIdentity.logo')"
                    >
                    <button
                      type="button"
                      class="absolute inset-1 min-w-[calc(100%-8px)] block items-center justify-center transition-opacity duration-150 opacity-0 group-hover:opacity-100 bg-gradient-to-br from-gray-700/50 to-gray-800/75"
                      :class="!logoImageUrl ? 'rounded-lg' : ''"
                      @click="setAccountLogoModal(true)"
                    >
                      <PencilIcon
                        aria-hidden="true"
                        class="w-8 h-8 mx-auto text-white"
                      />
                    </button>
                  </div>
                </div>
                <div class="mt-1 text-xs text-gray-400">
                  {{ $t('onboarding.steps.company.helpLogo') }}
                </div>
              </div>
              <div>
                <BrandColor
                  v-model:foreground="ciColors.textColor"
                  v-model:background="ciColors.bgColor"
                  :background-label="$t('accountSettings.brandIdentity.backgroundColor')"
                  :foreground-label="$t('accountSettings.brandIdentity.textColor')"
                  class="max-w-xl"
                />
              </div>
              <div>
                <BrandColor
                  v-model:foreground="ciColors.textOnPrimaryColor"
                  v-model:background="ciColors.primaryColor"
                  :background-label="$t('accountSettings.brandIdentity.primaryColor')"
                  :foreground-label="$t('accountSettings.brandIdentity.textOnPrimaryColor')"
                  :warn-for-contrast-to-white="true"
                  class="max-w-xl"
                />
              </div>
            </div>
            <div v-else>
              <div class="flex items-center gap-3">
                <LightBulbIcon class="flex-shrink-0 w-5 h-5 text-yellow-400" />
                <div class="text-xs text-gray-500">
                  {{ $t('onboarding.steps.company.customizeHint') }}
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center px-3 pt-3 mt-3 -mx-3 border-t border-t-gray-200">
              <SwitchGroup
                as="div"
                class="flex items-center gap-4"
              >
                <Switch
                  v-model="showBrandingOptions"
                  :class="[
                    showBrandingOptions
                      ? 'bg-indigo-600'
                      : 'bg-gray-300',
                    'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      showBrandingOptions
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
                <SwitchLabel
                  as="span"
                  class="text-sm cursor-pointer grow"
                  :class="showBrandingOptions ? 'text-gray-500' : 'text-gray-400 hover:text-gray-500'"
                >
                  <span>{{ $t('onboarding.steps.company.enableCustomizations') }}</span>
                </SwitchLabel>
              </SwitchGroup>
            </div>
          </div>
        </div>

        <AccountLogoModal
          v-model:show="showAccountLogoModal"
          :account="account"
          :remove-account-logo-status="removeAccountLogoStatus"
          @save="setAccountLogo"
          @remove="removeAccountLogo"
        />
      </div>
    </template>
    <template #aside>
      <template v-if="showBrandingOptions">
        <div class="flex flex-col items-center justify-center h-full">
          <div class="relative w-full">
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="block w-full h-full m-12 bg-gray-100 rounded-full max-w-20 max-h-20" />
            </div>
            <Vue3Lottie
              :animation-data="BrandIdentity"
              class="max-w-32"
              aria-hidden="true"
            />
          </div>
          <div class="w-full text-center">
            <div class="grow">
              <h3 class="mt-4 mb-2 text-lg font-semibold text-indigo-600">
                {{ $t('onboarding.steps.company.previewBrand') }}
              </h3>
            </div>
          </div>
          <BrandPreview
            :account-logo-image-url="logoImageUrl"
            :ci-colors="account.ci_colors"
            :is-onboarding="true"
            mail-classes="scale-[40%] origin-top-left w-[250%]"
            wizard-classes="scale-[25%] origin-top-left w-[400%]"
          />
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center w-full h-full">
          <div class="relative w-full">
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="block w-full h-full m-12 bg-gray-100 rounded-full max-w-32 max-h-32" />
            </div>
            <Vue3Lottie
              :animation-data="Customize"
              class="max-w-60"
              aria-hidden="true"
            />
          </div>
          <div class="w-full mt-5 text-center">
            <h3 class="mb-2 text-lg font-semibold text-indigo-600">
              {{ $t('onboarding.steps.company.aboutHeadline') }}
            </h3>
            <div class="text-sm">
              {{ $t('onboarding.steps.company.aboutCopy') }}
            </div>
          </div>
        </div>
      </template>
    </template>
  </OnboardingStepContainer>
</template>
