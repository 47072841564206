<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch, onBeforeMount } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid"
import { usePage } from "@inertiajs/vue3"

// internal
import {
  Fireworks,
  Pagination,
  TaskEntry,
  DashboardWidget,
  LoadingPlaceholder,
} from "~/components"
import { useTaskStore } from "~/stores"
import { TaskIcon } from "~/icons"
import { AccountUser } from "~/types"

const pageProps = computed(() => usePage().props)
const mau = computed<AccountUser>(() => pageProps.value.mau as AccountUser)

const taskStore = useTaskStore()
const {
  showTaskModal,
  showCreationForm,
} = storeToRefs(taskStore)

const incomingTasks = ref(null)
const outgoingCustomTasks = ref(null)

const {
  fetchIncomingTasks,
  fetchOutgoingCustomTasks,
  resolveCustomTaskByUuid,
  cancelCustomTaskByUuid,
} = taskStore

const taskTabs = [ "incoming", "outgoing" ]
const activeTaskTab = ref(taskTabs[0])
const includeResolved = ref(false)

const isIncoming = computed(() => activeTaskTab.value === "incoming")
const isLoading = ref(false)

const pagination = computed(() => {
  return isIncoming.value ? incomingTasks.value : outgoingCustomTasks.value
})

const loadTasks = async (page = 1) => {
  isLoading.value = true
  if (isIncoming.value) {
    const response = await fetchIncomingTasks({
      perPage: 3,
      page: page,
      includeResolved: includeResolved.value,
    })
    if (response) {
      incomingTasks.value = response
    }
  } else {
    const response = await fetchOutgoingCustomTasks({
      perPage: 3,
      page: page,
      includeResolved: includeResolved.value,
    })
    if (response) {
      outgoingCustomTasks.value = response
    }
  }
  isLoading.value = false
}

watch([ includeResolved, isIncoming ], () => {
  loadTasks()
})

// Generate random number from 1 to 50
const randomNoOpenTaskMessageNumber = Math.floor(Math.random() * 50) + 1


onBeforeMount(() => {
  loadTasks()

  Echo.private(`users.${mau.value.user.uuid}`)
    .listen(
      "Pusher\\User\\TaskCreated",
      () => {
        loadTasks()
      },
    )

  Echo.private(`users.${mau.value.user.uuid}`)
    .listen(
      "Pusher\\User\\TaskChanged",
      () => {
        loadTasks()
      },
    )
})

const openCreationForm = () => {
  showCreationForm.value = true
  showTaskModal.value = true
}
</script>

<template>
  <DashboardWidget
    box-classes="flex-col w-full"
  >
    <template #title>
      <Listbox
        v-model="activeTaskTab"
        as="div"
      >
        <div class="relative">
          <ListboxButton class="relative dashboard-widget-title">
            <span class="block mr-6">{{ $t(`tasks.${activeTaskTab}`) }}</span>
            <span class="absolute inset-y-0 flex items-center ml-1 pointer-events-none -right-1">
              <ChevronDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="left-0 right-auto listbox-options">
              <ListboxOption
                v-for="taskTab in taskTabs"
                :key="taskTab"
                v-slot="{ active, selected }"
                as="template"
                :value="taskTab"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <div class="flex items-center whitespace-nowrap">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ $t(`tasks.${taskTab}`) }}</span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-teal-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-4 h-4 -mr-2"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </template>
    <template #options>
      <SwitchGroup
        as="div"
        class="items-center hidden sm:flex"
      >
        <SwitchLabel
          as="span"
          class="mr-3 text-sm text-gray-400"
          passive
        >
          <span class="whitespace-nowrap">{{ $t('tasks.showCompleted') }}</span>
        </SwitchLabel>
        <Switch
          v-model="includeResolved"
          :class="[
            includeResolved ? 'bg-teal-600' : 'bg-gray-300',
            'relative inline-flex shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500',
          ]"
        >
          <span
            aria-hidden="true"
            :class="[
              includeResolved ? 'translate-x-4' : 'translate-x-0',
              'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            ]"
          />
        </Switch>
      </SwitchGroup>
    </template>
    <template #default>
      <LoadingPlaceholder
        v-if="isLoading"
        class="absolute inset-0 rounded-md"
      />
      <div
        v-else-if="pagination?.data?.length"
        class="relative flex flex-col justify-between w-full pt-2 overflow-hidden grow"
      >
        <div class="flex flex-col justify-start h-full px-3 py-1 space-y-2 max-h-fit grow">
          <template v-if="activeTaskTab === 'incoming'">
            <TaskEntry
              v-for="task in incomingTasks.data"
              :key="task.uuid"
              :task="task"
              :incoming="true"
              :minimal="true"
              @resolve="resolveCustomTaskByUuid(task.uuid)"
            />
          </template>
          <template v-if="activeTaskTab === 'outgoing'">
            <TaskEntry
              v-for="customTask in outgoingCustomTasks.data"
              :key="customTask.uuid"
              :custom-task="customTask"
              :incoming="false"
              :minimal="true"
              @cancel="cancelCustomTaskByUuid(customTask.uuid)"
            />
          </template>
        </div>
        <div class="flex items-end justify-between w-full px-3">
          <Pagination
            class="border-t-0 block w-[207px]"
            :pagination="pagination"
            :use-buttons="true"
            :show-per-page="false"
            :show-result-info="false"
            @switch-page="loadTasks"
          />
          <button
            type="button"
            class="mb-3 btn-sm btn-teal shrink-0"
            @click="openCreationForm"
          >
            {{ $t('tasks.createTask') }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="relative flex flex-col items-center justify-center py-px overflow-hidden grow"
      >
        <div class="flex flex-col items-center justify-center py-8 text-teal-500">
          <div class="flex items-center justify-center w-16 h-16 md:w-20 md:h-20 rounded-full relative mx-auto [&amp;>*]:h-12 [&amp;>*]:w-12 ring ring-white [&amp;>*]:text-teal-500/50 bg-gradient-to-br from-gray-100 to-teal-100">
            <TaskIcon
              aria-hidden="true"
            />
          </div>

          <span class="mt-3 text-sm font-semibold text-teal-500">{{ $t('tasks.noOpenTasks') }}</span>

          <div class="px-5 text-sm text-center">
            {{ $t('tasks.noOpenTasksMessages.' + randomNoOpenTaskMessageNumber) }}
          </div>

          <div class="flex items-center justify-center w-full mt-3">
            <button
              type="button"
              class="btn-sm btn-teal"
              @click="openCreationForm"
            >
              {{ $t('tasks.createTask') }}
            </button>
          </div>
        </div>
        <Fireworks />
      </div>
    </template>
  </DashboardWidget>
</template>
