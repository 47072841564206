import { DelimiterStyle, NumberingStyle } from "./list"

export const numberToAlpha = (n: number): string => {
  const ordA = "a".charCodeAt(0)
  const ordZ = "z".charCodeAt(0)
  const len = ordZ - ordA + 1

  let s = ""

  while (n > 0) {
    s = String.fromCharCode(n % len + ordA - 1) + s
    n = Math.floor(n / len)
  }

  return s
}

export const toRoman = (num: number) => {
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  let roman = ""
  let i
  for (i in lookup) {
    while (num >= lookup[i]) {
      roman += i
      num -= lookup[i]
    }
  }
  return roman
}

export const getOrderRepresentation = (
  {
    order,
    numberingStyle,
    delimiterStyle,
    parentOrderRepresentation = "",
    parentDelimiterStyle = "none",
    showNestedOrderRepresentation = false,
  }: {
    order: number
    numberingStyle: NumberingStyle
    delimiterStyle: DelimiterStyle
    parentOrderRepresentation?: string
    parentDelimiterStyle?: DelimiterStyle | "none"
    showNestedOrderRepresentation?: boolean
  },
) => {
  let delimiter = ""
  let prefix = ""
  let suffix = ""
  let finalDelimiter = true
  let finalRepresentation = ""

  switch (delimiterStyle) {
    case "dot":
      delimiter = "."
      break
    case "parenthesis":
      delimiter = ")"
      break
    case "double-parenthesis":
      delimiter = "."
      prefix = "("
      suffix = ")"
      finalDelimiter = false
      break
    case "section-mark":
      delimiter = "."
      prefix = "§"
      finalDelimiter = false
      break
    default:
      break
  }

  switch (numberingStyle) {
    case "decimal":
      finalRepresentation += `${order}${delimiter}`
      break
    case "lower-alpha":
      finalRepresentation += `${numberToAlpha(order)}${delimiter}`
      break
    case "upper-alpha":
      finalRepresentation += `${numberToAlpha(order).toUpperCase()}${delimiter}`
      break
    case "lower-roman":
      finalRepresentation += `${toRoman(order)}${delimiter}`.toLowerCase()
      break
    case "upper-roman":
      finalRepresentation += `${toRoman(order)}${delimiter}`.toUpperCase()
      break
    default:
      break
  }

  if (showNestedOrderRepresentation && parentOrderRepresentation) {
    if (parentOrderRepresentation && parentDelimiterStyle === "double-parenthesis" && (prefix)) {
      parentOrderRepresentation = parentOrderRepresentation.substring(parentOrderRepresentation.indexOf(prefix) + 1, parentOrderRepresentation.length - 1)
      finalRepresentation = `${parentOrderRepresentation}${delimiter}${finalRepresentation}`
    } else if (parentOrderRepresentation && parentDelimiterStyle === "section-mark" && !parentDelimiterStyle.endsWith(delimiter)) {
      finalRepresentation = `${parentOrderRepresentation}${delimiter}${finalRepresentation}`
    } else {
      finalRepresentation = `${parentOrderRepresentation}${finalRepresentation}`
    }
  }

  if (!finalDelimiter) finalRepresentation = finalRepresentation.substring(0, finalRepresentation.length - 1)

  if ((prefix) || (suffix)) {
    finalRepresentation = `${prefix}${finalRepresentation}${suffix}`
  }

  return finalRepresentation.trim()
}
