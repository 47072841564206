<script setup lang="ts">
import { computed } from "vue"

import { NodeViewWrapper, NodeViewProps } from "@tiptap/vue-3"

import { ValueAttrs } from "./value"

const props = defineProps<NodeViewProps>()

const attrs = computed(() => props.node.attrs as ValueAttrs)
</script>

<template>
  <NodeViewWrapper as="span">
    {{ attrs.value }}
  </NodeViewWrapper>
</template>
