<script setup lang="ts">
// external
import { watch, computed } from "vue"
import { DocumentPlusIcon, ArchiveBoxArrowDownIcon, TagIcon, TrashIcon, ArrowRightOnRectangleIcon, UserGroupIcon, HashtagIcon } from "@heroicons/vue/24/outline"
import { router } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"
import { useAccountStore } from "~/stores"
import { storeToRefs } from "pinia"

// internal

const { t } = useI18n()

import {
  EmptyState,
  MagicTable,
  TemplateTableEntry,
  FilterBubbles,
  FilterHeader,
  FilterBase,
  TemplateFilters,
  ManageTeamsModal,
  MagicTableBulkButton,
  ManageTagsModal,
  ManageMetadataModal,
} from "~/components"
import { useFilters, useEntityActions } from "~/composables"
import { Metadata, Pagination, Template, Tag, Team, MagicTableAllState } from "~/types"

interface Props {
  pagination: Pagination<Template>
  paginationProp?: string[]
  metadata?: Metadata[]
  teams?: Team[]
  tags?: Tag[]
  showFilter?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isArchive: false,
    showFilter: true,
  },
)

const {
  sortAttribute,
  sortDirection,
  isUpdatingList,
  hasActiveFilters,
  filterData,
  filterBubbles,
  filterValues,
  filterOptions,
  filterInitialized,
  selectedUuids,
  isSidebarOpen,
  isArchive,
  pinnedFilters,
  allState,
  setPerPage,
  resetSorting,
  resetFilter,
  clearBubble,
  selectEntry,
  togglePin,
  clearSelection,
} = useFilters(
  props.isArchive ? "archived_at" : "updated_at", "desc",
  props.paginationProp,
  props.showFilter ? [ "query", "teams", "archived", "status", "tags", "tag_and" ] : [],
  props.metadata ?? [],
  "templateList",
)

const {
  isSubmitting,
  showManageTeamsModal,
  showManageTagsModal,
  showManageMetadataModal,
  confirmEntityDeletion,
  confirmEntityArchiving,
  confirmEntityRestoration,
  bulkManageRelations,
  bulkManageMetadata,
} = useEntityActions("templates")

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

if (props.teams?.length) {
  filterOptions.value["teams"] = props.teams.map((team) => {
    return {
      label: team.name,
      value: team.uuid,
    }
  })
  filterOptions.value["teams"].push({ label: t("unassigned"), value: 0 })
}
if (props.tags?.length) {
  filterOptions.value["tags"] = props.tags.map((tag) => {
    return {
      label: tag.name,
      value: tag.uuid,
    }
  })
}
filterOptions.value["status"] = [
  {
    label: t("templates.status.draft"),
    value: "draft",
  },
  {
    label: t("templates.status.published"),
    value: "published",
  },
]

const columns = computed(() => [
  {
    key: "select",
    required: true,
    active: true,
  },
  {
    key: "name",
    sortAttribute: "name",
    required: true,
    active: true,
  },
  {
    key: "teams",
    breakpoint: "md",
    required: false,
    active: true,
  },
  {
    key: "status",
    breakpoint: "md",
    required: false,
    active: true,
  },
  {
    key: "creator",
    breakpoint: "lg",
    required: false,
    active: true,
  },
  {
    key: "timestamps",
    breakpoint: "xl",
    required: false,
    active: true,
  },
  {
    key: "options",
    required: true,
    active: true,
  },
])

const sortAttributes = computed(() => {
  return [ "name", "created_at", "updated_at", "archived_at" ]
})

watch(() => filterValues.value, (newVal, oldVal) => {
  if (newVal.archived !== oldVal.archived) {
    sortDirection.value = newVal.archived ? "archived_at" : "updated_at"
  }
})

const handleEmptyStateClick = () => {
  if (hasActiveFilters.value) {
    resetFilter()
  } else {
    router.visit(route("templates.index") + "#create")
  }
}
</script>

<template>
  <FilterBase
    :has-active-filters="hasActiveFilters"
    :show-filter="showFilter"
    :is-sidebar-open="isSidebarOpen"
    :filter-count="filterBubbles.length"
    :show-toggle-controls="true"
    @reset="resetFilter"
    @close="isSidebarOpen = false"
  >
    <template #filter="{ allOpen }">
      <TemplateFilters
        v-if="filterInitialized"
        v-model:filter-values="filterValues"
        :teams="teams"
        :metadata="metadata"
        :tags="tags"
        :filter-options="filterOptions"
        :all-open="allOpen"
        :filter-bubbles="filterBubbles"
        :pinned-filters="pinnedFilters"
        @toggle-pin="togglePin"
      />
    </template>
    <template #default>
      <FilterHeader
        v-if="showFilter"
        v-model:query="filterValues.query"
        v-model:show-all-filters="isSidebarOpen"
        v-model:sort-attribute="sortAttribute"
        v-model:sort-direction="sortDirection"
        :sort-attributes="sortAttributes"
        class="mb-4"
        list-name="templateList"
        :has-active-filters="hasActiveFilters"
        :filter-bubbles="filterBubbles"
      />

      <FilterBubbles
        v-if="hasActiveFilters"
        class="mb-2"
        :filter-bubbles="filterBubbles"
        @clear="clearBubble"
        @reset="resetFilter"
      />

      <MagicTable
        v-model:sort-attribute="sortAttribute"
        v-model:sort-direction="sortDirection"
        v-model:sort-attributes="sortAttributes"
        v-model:all-state="allState"
        storage-key="templateList"
        table-name="templateList"
        :columns="columns"
        :paginator="props.pagination"
        :loading="isUpdatingList"
        :has-filters="hasActiveFilters"
        :filter-data="filterData"
        :selected-uuids="selectedUuids"
        :pagination-prop="paginationProp"
        @update:per-page="setPerPage"
        @reset-sorting="resetSorting"
        @reset-filter="resetFilter"
        @clear-selection="clearSelection"
      >
        <template #bulk>
          <MagicTableBulkButton
            :label="$t('teams.manageTeams')"
            @click="showManageTeamsModal = true"
          >
            <UserGroupIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            :label="$t('tags.manageTags')"
            @click="showManageTagsModal = true"
          >
            <HashtagIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            :label="$t('metadata.manageMetadata')"
            @click="showManageMetadataModal = true"
          >
            <TagIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            v-if="!isArchive"
            :label="$t('common.archive')"
            @click="confirmEntityArchiving({
              entityUuids: selectedUuids,
              all: allState === MagicTableAllState.ALL,
              total: pagination?.meta?.total,
              filterData: filterData,
            })"
          >
            <ArchiveBoxArrowDownIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            v-if="isArchive"
            :label="$t('common.restore')"
            @click="confirmEntityRestoration({
              entityUuids: selectedUuids,
              all: allState === MagicTableAllState.ALL,
              total: pagination?.meta?.total,
              filterData: filterData,
            })"
          >
            <ArrowRightOnRectangleIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            v-if="mau?.permissions.includes('document_delete')"
            :label="$t('common.delete')"
            hover-classes="hover:bg-red-300/30 hover:text-red-600"
            @click="confirmEntityDeletion({
              entityUuids: selectedUuids,
              all: allState === MagicTableAllState.ALL,
              total: pagination?.meta?.total,
              filterData: filterData,
            })"
          >
            <TrashIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
          </MagicTableBulkButton>
        </template>
        <template #default="{ entry, activeColumns, isLoading }">
          <TemplateTableEntry
            :columns="activeColumns"
            :template="entry"
            :loading="isLoading"
            :sort-attribute="sortAttribute"
            :is-archive="isArchive"
            :selected="selectedUuids.includes(entry.uuid)"
            @select="selectEntry"
          />
        </template>
        <template #empty>
          <EmptyState
            @click="handleEmptyStateClick"
          >
            <template #icon>
              <DocumentPlusIcon
                aria-hidden="true"
              />
            </template>
            <template #call-to-action>
              <span v-if="!hasActiveFilters">
                {{ $t('templates.empty.description') }}
              </span>
              <span v-else>
                {{ $t('templates.empty.descriptionFilter') }}
              </span>
            </template>
            <template #button-text>
              <span v-if="!hasActiveFilters">
                {{ $t('templates.empty.button') }}
              </span>
              <span v-else>
                {{ $t('templates.empty.buttonFilter') }}
              </span>
            </template>
            {{ $t('templates.empty.title') }}
          </EmptyState>
        </template>
      </MagicTable>

      <ManageTeamsModal
        v-model:show="showManageTeamsModal"
        :teams="teams"
        :loading="isSubmitting"
        :callback="(selectedTeams, mode) => bulkManageRelations({
          mode: mode,
          entityUuids: selectedUuids,
          teamUuids: selectedTeams.map((team) => team.uuid),
          all: allState === MagicTableAllState.ALL,
          filterData: filterData,
        })"
      />

      <ManageMetadataModal
        v-model:show="showManageMetadataModal"
        :metadata="metadata"
        :loading="isSubmitting"
        :callback="(data) => bulkManageMetadata({
          entityUuids: selectedUuids,
          all: allState === MagicTableAllState.ALL,
          filterData: filterData,
          data: data,
        })"
      />

      <ManageTagsModal
        v-model:show="showManageTagsModal"
        :tags="tags"
        :loading="isSubmitting"
        :callback="(selectedTags, mode) => bulkManageRelations({
          mode: mode,
          entityUuids: selectedUuids,
          tagUuids: selectedTags.map((tag) => tag.uuid),
          all: allState === MagicTableAllState.ALL,
          filterData: filterData,
        })"
      />
    </template>
  </FilterBase>
</template>
