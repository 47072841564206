<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, onBeforeMount, onBeforeUnmount, watch, inject, Ref } from "vue"
import { debounce } from "lodash-es"

import { NodeViewProps, NodeViewWrapper, Range } from "@tiptap/vue-3"

// internal
import { useAccountStore, useDocumentStore, useDynamicFieldStore, usePusherStore, useSharedStore, useTemplateStore } from "~/stores"
import { CrudContext, MultiFieldType } from "~/types"
import { convertCurrencyString, formatDateLocalized, formatMultifieldNumber, isoDurationToWords } from "~/utils"

const props = defineProps<NodeViewProps>()

const editorContext = computed<string>(() => props.extension?.options?.editorContext)

const isEditable = ref(props.editor.isEditable)

const resetEditable = () => isEditable.value = props.editor.isEditable

const debouncedResetEditable = debounce(resetEditable, 500)

onBeforeMount(
  () => {
    props.editor.on(
      "transaction",
      debouncedResetEditable,
    )
  },
)

onBeforeUnmount(
  () => {
    props.editor.off(
      "transaction",
      debouncedResetEditable,
    )
  },
)

const dynamicFieldAttrs = computed(() => props.node.attrs)

const pos = computed(() => props.getPos())

const accountStore = useAccountStore()
const { account } = storeToRefs(accountStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFields } = storeToRefs(dynamicFieldStore)

const { mau, mdu, isLockedDocument, currentDocument } = storeToRefs(useDocumentStore())
const { currentTemplate } = storeToRefs(useTemplateStore())

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const canEdit = computed(() => {
  if (editorContext.value === CrudContext.document) return mdu.value?.permissions.includes("dynamic_field_update")
  return true
})

const dynamicField = computed(() => dynamicFields.value.find(({ ref_uuid }) => ref_uuid === dynamicFieldAttrs.value.refUuid))

const documentLocale = computed(() => {
  const entityLocale = crudContext.value === CrudContext.document ? currentDocument.value?.locale : currentTemplate.value?.locale
  const localeToReturn = entityLocale ? entityLocale : (mau.value?.user?.locale || account.value?.locale)
  return localeToReturn
})

import { useI18n } from "vue-i18n"

import en from "~/locales/dynamicFieldNodes/en.json"
import de from "~/locales/dynamicFieldNodes/de.json"

const locales = { en, de }

const i18nInstance = useI18n(
  {
    locale: documentLocale.value,
    fallbackLocale: "en",
    messages: locales,
    useScope: "local",
    inheritLocale: false,
  },
)

const { t, locale } = i18nInstance

watch(
  () => documentLocale.value,
  () => {
    locale.value = documentLocale.value as "en" | "de"
  },
)

const multiFieldFormat = computed(
  () => {
    const dynamicFieldFormat = dynamicField.value?.format

    const formatToReturn = {
      decimals: dynamicFieldFormat?.decimals ? dynamicFieldFormat?.decimals : ([ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(dynamicField.value?.type)) ? "2" : "0",
      decimalSeparator: dynamicFieldFormat?.decimalSeparator || (locale.value === "de" ? "," : "."),
      thousandsSeparator: dynamicFieldFormat?.thousandsSeparator || (dynamicFieldFormat?.thousandsSeparator === null ? null : (locale.value === "de" ? "." : ",")),
    }

    return formatToReturn
  },
)

const textSelectionRange = inject<Ref<Range>>("textSelectionRange", null)

const isSelected = computed(
  () => {
    if (!textSelectionRange.value) return false

    const { from, to } = textSelectionRange.value

    const nodeFrom = props.getPos()
    const nodeTo = nodeFrom + props.node.nodeSize

    return from <= nodeFrom && to >= nodeTo
  },
)
</script>

<template>
  <NodeViewWrapper class="inline -mt-1">
    <div
      v-cy="`dynamic-field-node-${dynamicField?.ref_uuid}`"
      class="relative items-center inline px-1 leading-tight break-normal border rounded-md box-decoration-clone"
      :class="[
        dynamicFieldAttrs.refUuid === dynamicField?.ref_uuid
          ? 'bg-indigo-50 border-indigo-300'
          : 'bg-red-50 border-red-300',
        isEditable
          ? 'cursor-move'
          : 'cursor-default',
        props.selected || isSelected
          ? 'ring ring-indigo-100'
          : '',
      ]"
      data-drag-handle
      draggable="true"
    >
      <div
        :id="'dynamicFieldButton_' + dynamicField?.ref_uuid + '_' + pos"
        :data-template="editorContext === CrudContext.document ? 'dynamicFieldPopoverForm_' + dynamicField?.ref_uuid : 'dynamicFieldForm_' + dynamicField?.ref_uuid"
        :data-dynamic-field-node="dynamicField?.ref_uuid"
        :data-placement="editorContext === CrudContext.document ? 'top' : 'right'"
        :class="[
          !checkIfDisabledByPusher('dynamicFieldButton_' + dynamicField?.ref_uuid + '_' + pos) && canEdit && !isLockedDocument && dynamicField
            ? 'dynamic-field-button cursor-pointer'
            : isEditable && !isLockedDocument && dynamicField
              ? 'cursor-move'
              : 'pointer-events-none',
          'inline break-normal'
        ]"
      >
        <template v-if="dynamicField?.value !== null && dynamicField?.value !== '' && typeof dynamicField?.value !== 'undefined' && crudContext === CrudContext.document">
          <span v-if="dynamicField.type === MultiFieldType.date">
            {{ formatDateLocalized(dynamicField.value, documentLocale) }}
          </span>
          <span v-else-if="dynamicField.type === MultiFieldType.duration">
            {{ isoDurationToWords(dynamicField.value, t) }}
          </span>
          <span v-else-if="dynamicField.type === MultiFieldType.bool">
            {{ typeof dynamicField.value !== 'undefined' ? dynamicField.value === true ? t('yes') : t('no') : t('undefined') }}
          </span>
          <span v-else-if="[ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(dynamicField.type)">
            {{ dynamicField.value ? convertCurrencyString(dynamicField.value, multiFieldFormat, locale, t) : t('undefined') }}
          </span>
          <span
            v-else-if="dynamicField.type === MultiFieldType.textarea"
            class="break-words whitespace-pre-wrap"
          >
            {{ dynamicField.value }}
          </span>
          <ul v-else-if="dynamicField.type === MultiFieldType.list">
            <li
              v-for="(item, index) in dynamicField.value.split('\n').filter((item: string) => item.trim() !== '')"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
          <span v-else-if="dynamicField.type === MultiFieldType.number">
            {{ formatMultifieldNumber(dynamicField.value, multiFieldFormat, dynamicField.type, locale) }}
          </span>
          <span
            v-else
            class="break-words"
          >
            {{ dynamicField.value }}
          </span>
        </template>
        <span
          v-else-if="dynamicFieldAttrs.refUuid === dynamicField?.ref_uuid"
          class="text-indigo-700"
        >
          {{ dynamicField?.name }}
        </span>
        <span
          v-else
          class="text-red-700"
        >
          {{ t('n/a(notFound)') }}
        </span>
      </div>
    </div>
  </NodeViewWrapper>
</template>
