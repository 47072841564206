import { Node } from "@tiptap/pm/model"
import { DocxSerializerState } from "prosemirror-docx"
import {
  ITableOptions,
  ITableCellOptions,
  ITableRowOptions,
  BorderStyle,
} from "docx"
import * as docx_1 from "docx"

type MutableITableOptions = {
  -readonly [key in keyof ITableOptions]: ITableOptions[key];
}

type MutableITableCellOptions = {
  -readonly [key in keyof ITableCellOptions]: ITableCellOptions[key];
}

const MAX_IMAGE_WIDTH = 1000

export const column = (node: Node, state: DocxSerializerState) => {
  const actualChildren = state.children
  const rows = []
  const columns = []
  // This scales images inside of columns
  state.maxImageWidth = MAX_IMAGE_WIDTH / node.childCount
  node.forEach((column) => {
    state.children = []
    state.renderContent(column)
    const tableCellOpts: MutableITableCellOptions = { children: state.children }
    tableCellOpts.margins = {
      top: 20,
      right: 100,
      bottom: 20,
      left: 100,
    }
    tableCellOpts.verticalAlign = docx_1.VerticalAlign.TOP
    columns.push(new docx_1.TableCell(tableCellOpts))
  })
  const tableRowOptions: ITableRowOptions = {
    children: columns,
  }
  rows.push(new docx_1.TableRow(tableRowOptions))
  state.maxImageWidth = MAX_IMAGE_WIDTH
  const tableOpts: MutableITableOptions = {
    rows,
    width: {
      size: "100%",
    },
    borders: {
      top: {
        style: BorderStyle.NIL,
      },
      bottom: {
        style: BorderStyle.NIL,
      },
      left: {
        style: BorderStyle.NIL,
      },
      right: {
        style: BorderStyle.NIL,
      },
      insideVertical: {
        style: BorderStyle.NIL,
      },
      insideHorizontal: {
        style: BorderStyle.NIL,
      },
    },
  }
  const table = new docx_1.Table(tableOpts)
  actualChildren.push(table)
  // If there are multiple tables, this seperates them
  actualChildren.push(new docx_1.Paragraph(""))
  state.children = actualChildren
}
