<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref } from "vue"

import {
  MenuButton,
} from "@headlessui/vue"
import { FlagIcon, TrashIcon } from "@heroicons/vue/24/outline"
import {
  QuestionMarkCircleIcon,
} from "@heroicons/vue/24/solid"

// internal
import {
  CheckpointModal,
  Dropdown,
  DropdownLink,
  EmptyState,
  OverlayScrollbar,
  SkeletonLoader,
  SpinLoader,
  StageDisplay,
} from "~/components"
import { useStages } from "~/composables"
import { useCheckpointStore } from "~/stores"
import { AccountUser, Checkpoint, CheckpointStep, CrudContext, DocumentStage, DocumentUser, Template } from "~/types"
import { getUserRepresentation } from "~/utils"

interface Props {
  template: Template

  documentUsers?: DocumentUser[]
  isLoadingDocumentUsers?: boolean
  accountUsers?: AccountUser[]
  isLoadingAccountUsers?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    documentUsers: () => [],
    isLoadingDocumentUsers: false,
    accountUsers: null,
    isLoadingAccountUsers: false,
  },
)

const { stages } = useStages(null, null, true)

const checkpointStore = useCheckpointStore()
const {
  activeCheckpoint,
  checkpoints,
  isLoadingCheckpoints,
  isCreatingCheckpoint,
  removingCheckpointUuid,
} = storeToRefs(checkpointStore)
const {
  fetchCheckpoints,
  createCheckpoint,
  setActiveCheckpoint,
  removeCheckpoint,
} = checkpointStore

const handleRemoveCheckpoint = async (templateUuid: Template["uuid"], checkpointUuid: Checkpoint["uuid"]) => {
  await removeCheckpoint(CrudContext.template, templateUuid, checkpointUuid)
  showCheckpointModal.value = false
}

const showCheckpointModal = ref<boolean>(false)

const addNewCheckpoint = async (stage: DocumentStage) => {
  await createCheckpoint(CrudContext.template, props.template.uuid, stage)
  showCheckpointModal.value = true
}

const editCheckpoint = (checkpointUuid: Checkpoint["uuid"]) => {
  setActiveCheckpoint(checkpointUuid)
  showCheckpointModal.value = true
}

const combinedUsers = computed(() => {
  const documentUsersCopy = JSON.parse(JSON.stringify(props.documentUsers))
  const accountUsersCopy = JSON.parse(JSON.stringify(props.accountUsers))
  documentUsersCopy.forEach((el) => {
    el.document_user = el.uuid
    el.uuid = el.account_user?.uuid
  })
  return documentUsersCopy.concat(accountUsersCopy)
})

// Function to sort checkpoint steps by "order" property
const sortCheckpointSteps = (checkpointSteps: CheckpointStep[]) => {
  return checkpointSteps.sort((a, b) => a.order - b.order)
}

// Function to return stage name
const getStageByName = (stage: DocumentStage) => {
  return stages.value.find((el) => el.name === stage)
}

onMounted(() => {
  fetchCheckpoints(CrudContext.template, props.template.uuid)
})
</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="px-6 pt-6 border-b shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('checkpoints.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('checkpoints.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingCheckpoints"
      class="p-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>
    <template v-else>
      <OverlayScrollbar
        ref="checkpointScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <ul
          v-if="checkpoints?.length"
          role="list"
          class="grid grid-cols-1 divide-y dividy-y-gray-200"
        >
          <li
            v-for="(checkpoint, checkpointIdx) in checkpoints"
            :key="checkpointIdx"
            class="relative cursor-pointer group hover:bg-gray-50 last:border-b last:border-b-gray-200"
            @click.prevent="editCheckpoint(checkpoint.uuid)"
          >
            <div class="relative px-6 pt-3 pb-4">
              <div>
                <div class="flex items-center">
                  <span class="mr-1 text-sm font-medium">{{ $t('checkpoints.beforeReaching') }}:</span>
                  <StageDisplay
                    :stage="getStageByName(checkpoint.document_stage)"
                    label-classes="ml-1 text-sm font-medium"
                  />
                </div>
                <div>
                  <div class="relative">
                    <div
                      class="w-0.5 absolute left-1 top-2 bottom-2 bg-gray-200"
                      aria-hidden="true"
                    />
                    <template v-if="checkpoint.checkpoint_steps.length">
                      <div
                        v-for="(step, stepIdx) in sortCheckpointSteps(
                          checkpoint.checkpoint_steps
                        )"
                        :key="stepIdx"
                        class="relative mt-2 text-sm text-gray-600"
                      >
                        <template v-if="step.checkpoint_step_approvers?.length">
                          <span
                            class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px -mt-1 text-xs font-bold text-white bg-gray-200 rounded-full top-1/2 ring-2 ring-white group-hover:ring-gray-50"
                          />
                          <div class="flex items-center gap-1 pl-5">
                            <img
                              v-for="approver in step.checkpoint_step_approvers"
                              :key="approver.uuid"
                              :src="approver.account_user?.profile_photo_url"
                              data-tippy-context
                              :data-tippy-content="getUserRepresentation(approver.account_user)"
                              data-placement="top"
                              class="w-6 h-6 rounded-full"
                              aria-hidden="true"
                            >
                          </div>
                        </template>
                        <template v-else>
                          <span
                            class="absolute left-0 flex items-center justify-center w-2 h-2 ml-px -mt-1 text-xs font-bold text-white bg-gray-200 rounded-full top-1/2 ring-2 ring-white group-hover:ring-gray-50"
                          />
                          <div class="flex items-center gap-1 pl-5 text-sm text-gray-400">
                            {{ $t('checkpoints.noApproversDefined', {number: stepIdx + 1}) }}
                          </div>
                        </template>
                      </div>
                    </template>
                    <div
                      v-else
                      class="mt-2 text-sm text-gray-400"
                    >
                      {{ $t('checkpoints.noProcessDefined') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <EmptyState
          v-else
          :hide-button="true"
          class="my-6"
        >
          <template #icon>
            <FlagIcon
              aria-hidden="true"
            />
          </template>
          {{ $t('checkpoints.empty') }}
        </EmptyState>
      </OverlayScrollbar>
      <div class="px-6 py-4 border-t border-gray-200 shrink-0">
        <dropdown
          menu-classes="relative text-left"
          width="64"
          align="bottom"
        >
          <template #trigger>
            <MenuButton
              :disabled="isCreatingCheckpoint"
              class="inline-flex items-center btn-primary"
            >
              <span v-if="!isCreatingCheckpoint">{{ $t('checkpoints.add') }}</span>
              <span
                v-else
                class="flex items-center gap-2"
              >
                <span class="pointer-events-none">
                  <SpinLoader
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
                {{ $t('common.pleaseWait') }}…
              </span>
            </MenuButton>
          </template>

          <template #content>
            <div class="block px-4 py-2 text-xs text-gray-400">
              {{ $t('checkpoints.trigger') }}
            </div>

            <DropdownLink
              as="button"
              @click="!isCreatingCheckpoint ? addNewCheckpoint(DocumentStage.review) : null"
            >
              <div
                class="flex items-center"
                :class="isCreatingCheckpoint ? 'opacity-50' : ''"
              >
                <span class="mr-1 text-sm whitespace-nowrap">{{ $t('checkpoints.beforeReaching') }}:</span>
                <StageDisplay
                  :stage="getStageByName(DocumentStage.review)"
                  label-classes="ml-1 text-sm font-medium"
                />
              </div>
            </DropdownLink>
            <DropdownLink
              as="button"
              @click="!isCreatingCheckpoint ? addNewCheckpoint(DocumentStage.signing) : null"
            >
              <div
                class="flex items-center"
                :class="isCreatingCheckpoint ? 'opacity-50' : ''"
              >
                <span class="mr-1 text-sm whitespace-nowrap">{{ $t('checkpoints.beforeReaching') }}:</span>
                <StageDisplay
                  :stage="getStageByName(DocumentStage.signing)"
                  label-classes="ml-1 text-sm font-medium"
                />
              </div>
            </DropdownLink>
          </template>
        </dropdown>
      </div>
    </template>
  </div>

  <CheckpointModal
    :show="showCheckpointModal"
    :entity-uuid="props.template.uuid"
    :combined-users="combinedUsers"
    @close="showCheckpointModal = false"
  >
    <template #removeButton>
      <button
        type="button"
        class="flex items-center gap-2 text-gray-400 btn-plain btn-sm hover:text-red-500 hover:bg-red-100"
        @click.prevent="handleRemoveCheckpoint(props.template.uuid, activeCheckpoint.uuid)"
      >
        <TrashIcon
          v-if="activeCheckpoint && removingCheckpointUuid !== activeCheckpoint?.uuid"
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <SpinLoader
          v-else
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <span>
          {{ $t('common.remove') }}
        </span>
      </button>
    </template>
  </CheckpointModal>
</template>
