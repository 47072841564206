import { storeToRefs } from "pinia"
import { Comment } from "~/types"
import { useDocumentStore } from "~/stores"
import { HtmlResult } from "~/editor/extensions/markCommentsAndProposalsHtml"
import { Result } from "~/editor/utils/uuidMarker"

const renderHighlight = (
  result: Result | HtmlResult,
  highlightClickHandler: () => void,
  relatedComments: Comment[] = [],
) => {
  const documentStore = useDocumentStore()
  const { mdu } = storeToRefs(documentStore)

  const { type } = result

  if (![ "comment", "proposal", "new", "active" ].includes(type)) return

  const highlight = document.createElement("div")
  let htmlContent = ""

  if (!mdu.value?.permissions?.includes("comment_create") && type === "new") return highlight

  const isResolved = relatedComments.length && !relatedComments.find((comment) => !comment.is_resolved)

  if (type === "active") {
    htmlContent = `<svg class="mx-auto w-4 h-4" viewBox="0 0 24 24" fill="currentColor"><path d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" /></svg>`
  } else if (isResolved) {
    htmlContent = `<svg class="mx-auto w-4 h-4" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" /></svg>`
  } else if (type === "comment") {
    htmlContent = `<svg class="mx-auto w-4 h-4" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" /></svg>`
  } else if (type === "proposal") {
    htmlContent = `<svg class="mx-auto w-4 h-4" viewBox="0 0 24 24" fill="currentColor"><path fill="currentColor" d="M10 19.11L12.11 17H7v-2h7v.12L16.12 13H7v-2h10v1.12l1.24-1.23c.48-.48 1.11-.75 1.8-.75c.33 0 .66.07.96.19V5a2 2 0 0 0-2-2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h5v-1.89M7 7h10v2H7V7m14.7 7.35l-1 1l-2.05-2.05l1-1a.55.55 0 0 1 .77 0l1.28 1.28c.21.21.21.56 0 .77M12 19.94l6.06-6.06l2.05 2.05L14.06 22H12v-2.06Z"/></svg>`
  } else {
    htmlContent = `<svg class="mx-auto w-4 h-4" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M3.43 2.524A41.29 41.29 0 0110 2c2.236 0 4.43.18 6.57.524 1.437.231 2.43 1.49 2.43 2.902v5.148c0 1.413-.993 2.67-2.43 2.902a41.202 41.202 0 01-5.183.501.78.78 0 00-.528.224l-3.579 3.58A.75.75 0 016 17.25v-3.443a41.033 41.033 0 01-2.57-.33C1.993 13.244 1 11.986 1 10.573V5.426c0-1.413.993-2.67 2.43-2.902z" clip-rule="evenodd" /></svg>`
  }

  if (relatedComments.length > 1) {
    htmlContent += `<span class="text-xs rounded-full absolute bg-white text-center w-4 h-4 mt-0.5">${relatedComments.length}</span>`
  }

  highlight.innerHTML = `<div>` + htmlContent + `</div>`

  highlight.className = "relative hidden transition-opacity duration-300 sm:block highlight-marker"

  if (isResolved) {
    highlight.className += " resolved"
  }

  highlight.addEventListener("click", highlightClickHandler);

  (highlight as any).result = result

  return highlight
}

export default renderHighlight
