import { mergeAttributes, Node } from "@tiptap/core"

export interface FineprintOptions {
  HTMLAttributes: Record<string, any>,
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fineprint: {
      /**
       * Toggle a fineprint
       */
      toggleFineprint: () => ReturnType,

      /**
       * Set a fineprint
       */
      setFineprint: () => ReturnType,
    }
  }
}

export const Fineprint = Node.create<FineprintOptions>({
  name: "fineprint",

  addOptions () {
    return {
      HTMLAttributes: {},
    }
  },

  group: "block",

  content: "inline*",

  parseHTML () {
    return [
      { tag: "div.fineprint" },
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        {
          class: "fineprint",
        },
      ),
      0,
    ]
  },

  addCommands () {
    return {
      setFineprint: () => ({ commands }) => {
        return commands.setNode(this.name)
      },
      toggleFineprint: () => ({ commands }) => {
        return commands.toggleNode(this.name, "paragraph")
      },
    }
  },
})
