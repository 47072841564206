<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from "@headlessui/vue"
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/vue/24/solid"
import { computed, ref, watch } from "vue"
import { AccountUser, CheckpointStepApprover, DocumentUser } from "~/types"
import { SpinLoader } from "~/components"
import { getUserRepresentation } from "~/utils"

interface Props {
  users: (AccountUser|DocumentUser)[]
  alreadySelectedUsers: (CheckpointStepApprover)[]
  autoSelect?: boolean
  loading?: boolean
  placeholder?: string
  clearAfterSelection?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    autoSelect: true,
    placeholder: null,
    clearAfterSelection: true,
  },
)
const emit = defineEmits([ "select" ])

const query = ref("")
const selectedUser = ref(null)
const alreadySelectedUserUuids = computed(() => {
  if (!props.alreadySelectedUsers.length) return []
  return props.alreadySelectedUsers.map((user) => {
    if (user.account_user?.uuid) {
      return user.account_user?.uuid
    }
    return user.uuid
  })
})

const filteredUsers = computed(() => {
  return query.value === ""
    ? props.users.filter((user) => user.uuid)
    : props.users
      .filter((user) => user.uuid)
      .filter((user) => {
        const check = `${user.first_name} ${user.last_name} ${user.email}`
        return check.toLowerCase().includes(query.value.toLowerCase())
      })
})

const select = (user: AccountUser) => {
  emit("select", user)
  if (props.clearAfterSelection) {
    selectedUser.value = null
  }
}

watch(selectedUser, (newVal: AccountUser) => {
  if (props.autoSelect && newVal) {
    select(newVal)
  }
})
</script>
<template>
  <div class="w-full">
    <Combobox
      v-model="selectedUser"
      :disabled="props.loading"
      as="div"
      class="relative w-full"
      nullable
    >
      <ComboboxButton
        :disabled="props.loading"
        class="relative w-full"
      >
        <ComboboxInput
          :placeholder="props.placeholder ?? ($t('userSettings.addUser') + '…')"
          class="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          :class="props.autoSelect ? 'rounded-md' : 'rounded-l-md'"
          :display-value="
            (user: AccountUser | DocumentUser) =>
              user
                ? getUserRepresentation(user)
                : ''
          "
          @change="query = $event.target.value"
        />
        <div
          class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
        >
          <ChevronUpDownIcon
            v-if="!props.loading"
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
          <SpinLoader
            v-else
            class="w-5 h-5 text-gray-400"
          />
        </div>
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredUsers?.length > 0"
        class="listbox-options"
      >
        <ComboboxOption
          v-for="(user, userIdx) in filteredUsers"
          :key="userIdx"
          v-slot="{ active, selected }"
          :value="user"
          as="template"
          :disabled="alreadySelectedUserUuids.includes(user.uuid)"
        >
          <li
            :class="[
              'listbox-option',
              active ? 'bg-gray-700' : '',
              alreadySelectedUserUuids.includes(user.uuid) ? 'opacity-30' : '',
            ]"
          >
            <div class="flex items-center">
              <img
                :src="user?.profile_photo_url"
                alt=""
                class="w-6 h-6 rounded-full shrink-0"
              >
              <span :class="['ml-3 truncate', selected && 'font-semibold']">
                {{ getUserRepresentation(user) }}
              </span>
            </div>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-500',
              ]"
            >
              <CheckIcon
                class="w-5 h-5"
                aria-hidden="true"
              />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>

    <button
      v-if="!props.autoSelect"
      :disabled="props.loading || !selectedUser"
      type="button"
      class="flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border-none rounded-r-sm shadow-sm outline-none hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:pointer-events-none disabled:opacity-50"
      @click.prevent="select(selectedUser)"
    >
      <span v-if="!props.loading">{{ $t('common.add') }}</span>
      <span
        v-else
        class="flex items-center gap-2"
      >
        <span class="pointer-events-none">
          <SpinLoader class="w-5 h-5" />
        </span>
        {{ $t('common.adding') }}…
      </span>
    </button>
  </div>
</template>
