<script setup lang="ts">
import { ArrowUturnLeftIcon } from "@heroicons/vue/24/solid"
import { storeToRefs } from "pinia"
import { useEntityActions } from "~/composables"
import { useDocumentStore } from "~/stores"
import { formatDateSimple } from "~/utils"

const documentStore = useDocumentStore()
const { currentDocument, mau } = storeToRefs(documentStore)

const {
  confirmEntityRestoration,
} = useEntityActions("documents")

</script>
<template>
  <div class="relative bg-slate-600">
    <div class="flex items-center justify-center py-2 space-x-5 px-7">
      <p class="text-sm text-white">
        <span class="inline">
          {{ $t('documents.archivedOn', {date: formatDateSimple(currentDocument.archived_at)}) }}
        </span>
      </p>
      <button
        v-if="!!mau"
        class="flex items-center gap-2 text-white btn-plain btn-sm bg-slate-700 hover:bg-slate-800 focus:ring-slate-800 hover:text-white"
        @click="confirmEntityRestoration([currentDocument.uuid])"
      >
        <ArrowUturnLeftIcon
          class="shrink-0 w-4 h-4"
          aria-hidden="true"
        />
        {{ $t('common.restore') }}
      </button>
    </div>
  </div>
</template>
