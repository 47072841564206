<script setup lang="ts">
import { computed, inject, onBeforeMount, Ref, ref, toRaw, watch } from "vue"

import { storeToRefs } from "pinia"

import { NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"

import { TrashIcon } from "@heroicons/vue/20/solid"
import { debounce, isEqual } from "lodash-es"
import { TippyComponent } from "vue-tippy"
import { sticky } from "tippy.js"

import { LoadingPlaceholder, SpinLoader } from "~/components"
import { FloatLeftIcon, FloatRightIcon, ImageAlignCenterIcon, ImageAlignLeftIcon, ImageAlignRightIcon } from "~/icons"
import { useImageStore } from "~/stores"

import { ImageAttrs } from "./resizableImage"
import { resizableImageActions } from "./resizableImageUtils"

const ActionIconsMap = {
  ImageAlignCenterIcon,
  ImageAlignLeftIcon,
  ImageAlignRightIcon,
  FloatLeftIcon,
  FloatRightIcon,
  TrashIcon,
}

const props = defineProps<NodeViewProps>()

const imageStore = useImageStore()
const {
  images,
  uuidsOfImagesBeingUploaded,
} = storeToRefs(imageStore)
const { getImageIfNeeded } = imageStore
const isVisible = ref<boolean>(false)

watch(
  [
    () => props.node.attrs.refUuid,
    () => props.node.attrs.uuid,
  ],
  ([ refUuid, uuid ]) => refUuid && uuid && getImageIfNeeded(refUuid, uuid),
  {
    immediate: true,
    deep: true,
  },
)

const currentImageDataUrl = computed(() => props.node.attrs.dataUrl || images.value[props.node.attrs.uuid])

const resizableImgRef = ref<HTMLImageElement | null>(null)

const proseMirrorContainerWidth = ref(0)

const imageActionActiveState = ref<Record<string, boolean>>({})

const isCurrentImageBeingUploaded = computed(() => {
  const { uuid, intermediateUuid } = props.node.attrs || {}

  return [
    uuid,
    intermediateUuid,
  ].some((uuid) => uuidsOfImagesBeingUploaded.value.includes(uuid))
})

const setImageActionActiveStates = () => {
  const activeStates: Record<string, boolean> = {}

  for (const { name, isActive } of resizableImageActions) {
    activeStates[name] = !!isActive?.(localAttrs.value)
  }

  imageActionActiveState.value = activeStates
}

const debouncedUpdateAttrs = debounce(
  () => {
    !isEqual(localAttrs.value, props.node.attrs) && props.updateAttributes(localAttrs.value)
  },
  300,
)

const localAttrs = ref<Partial<ImageAttrs>>({})

const updateLocalAttributes = (attrs: Partial<ImageAttrs>) => {
  localAttrs.value = {
    ...toRaw(localAttrs.value || {}),
    ...(attrs || {}),
  }
}

watch(
  () => props.node.attrs,
  (propsAttrs, oldPropsAttrs) => {
    if (isEqual(propsAttrs, oldPropsAttrs)) return

    localAttrs.value = {
      ...propsAttrs,
    }
  },
  {
    immediate: true,
    deep: true,
  },
)

watch(
  () => localAttrs.value,
  (val, oldVal) => {
    if (isEqual(val, oldVal)) return

    setImageActionActiveStates()
    debouncedUpdateAttrs()
  },
  {
    immediate: true,
    deep: true,
  },
)

const imageSetupOnLoad = () => {
  const proseMirrorContainerDiv = props.editor.options.element as HTMLDivElement

  if (proseMirrorContainerDiv) proseMirrorContainerWidth.value = proseMirrorContainerDiv?.clientWidth
}

onBeforeMount(imageSetupOnLoad)

const isHorizontalResizeActive = ref(false)

const lastCursorX = ref(-1)

const activeHandleDir = ref<"left" | "right" | null>(null)

const startHorizontalResize = (e: MouseEvent, dir: "left" | "right") => {
  isHorizontalResizeActive.value = true
  activeHandleDir.value = dir
  lastCursorX.value = e.clientX

  document.addEventListener("mousemove", onHorizontalMouseMove)
  document.addEventListener("mouseup", stopHorizontalResize)
}

const stopHorizontalResize = () => {
  isHorizontalResizeActive.value = false
  lastCursorX.value = -1

  document.removeEventListener("mousemove", onHorizontalMouseMove)
  document.removeEventListener("mouseup", stopHorizontalResize)
}

const onHorizontalResize = (directionOfMouseMove: "right" | "left", clientX: number) => {
  if (!resizableImgRef.value) {
    console.error("Image ref is undefined|null", { resizableImg: resizableImgRef.value })
    return
  }

  let width = -1

  const boundingClientRect = resizableImgRef.value.getBoundingClientRect()

  width = Math.abs(clientX - (activeHandleDir.value === "left" ? boundingClientRect.right : boundingClientRect.left))

  if (width > proseMirrorContainerWidth.value) width = proseMirrorContainerWidth.value

  width = Math.round((width * 100) / proseMirrorContainerWidth.value) / 100

  const widthInPx = Math.round(width * proseMirrorContainerWidth.value * 100) / 100

  updateLocalAttributes(
    {
      width,
      widthInPx,
    },
  )
}

const onHorizontalMouseMove = (e: MouseEvent) => {
  if (!isHorizontalResizeActive.value) return

  const { clientX } = e

  const diff = lastCursorX.value - clientX

  lastCursorX.value = clientX

  if (diff === 0) return

  const directionOfMouseMove: "left" | "right" = diff > 0 ? "left" : "right"

  onHorizontalResize(directionOfMouseMove, clientX)
}

const isFloat = computed<boolean>(() => !!props.node.attrs.dataFloat)

const isAlign = computed<boolean>(() => !!props.node.attrs.dataAlign)

const isEditable = inject<boolean | Ref<boolean>>("isEditable", false)

const handleLoaded = () => {
  isVisible.value = true
}

const imageActionsTippyRef = ref<TippyComponent>(null)

watch(
  () => props.selected,
  (selected) => {
    if (selected) {
      imageActionsTippyRef.value?.show()
    } else {
      imageActionsTippyRef.value?.hide()
    }
  },
)

const body = document.body
</script>

<template>
  <NodeViewWrapper
    as="article"
    class="z-10 flex resizable-image-node-view"
    :class="[
      `${isFloat ? `f-${localAttrs.dataFloat}` : ''}`,
      `${isFloat && localAttrs.dataFloat === 'left' ? 'mr-1' : ''}`,
      `${isFloat && localAttrs.dataFloat === 'right' ? 'ml-1' : ''}`,
      `${isAlign ? `align-${localAttrs.dataAlign}` : ''}`
    ]"
  >
    <div
      class="relative flex w-fit group/image-container"
      draggable="true"
      v-bind="{
        ...(
          isHorizontalResizeActive
            ? {}
            : {
              'data-drag-handle': ''
            }
        )
      }"
    >
      <Tippy
        ref="imageActionsTippyRef"
        :interactive="true"
        trigger="manual"
        :hide-on-click="false"
        :plugins="[
          sticky
        ]"
        :append-to="() => body"
      >
        <template #default>
          <img
            ref="resizableImgRef"
            draggable="true"
            v-bind="{
              src: currentImageDataUrl,
              alt: !localAttrs.dataUrl ? 'Loading...' : localAttrs.alt,
              title: localAttrs.title || 'Image',
              width: localAttrs.width * proseMirrorContainerWidth,
              height: 'auto'
            }"
            :class="
              [
                `${isFloat && `float-${localAttrs.dataFloat}` || ''}`,
                `${isAlign && `align-${localAttrs.dataAlign}` || ''}`,
                props.selected && isEditable ? 'outline outline-black outline-1' : '',
                isVisible ? '' : 'invisible',
              ]
            "
            @load="handleLoaded"
          >
        </template>

        <template #content>
          <section
            v-if="isEditable"
            class="z-10 flex overflow-hidden rounded-md shadow-sm opacity-0"
            :class="{
              'opacity-100': !isHorizontalResizeActive
            }"
          >
            <button
              v-for="imageAction in resizableImageActions"
              :key="imageAction.name"
              data-tippy-help
              :data-tippy-content="$t('commandMenu.imageActions.' + imageAction.name)"
              data-placement="top"
              :content="$t('commandMenu.imageActions.' + imageAction.name)"
              class="inline-flex items-center p-1.5 text-xs font-medium focus:outline-none"
              :class="[{ 'bg-indigo-300 hover:bg-indigo-300': imageActionActiveState[imageAction.name] }, imageAction.name === 'delete' ? 'border-l border-l-indigo-200 bg-red-50 hover:bg-red-100 text-red-700' : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200']"
              @click="imageAction.name === 'delete'
                ? imageAction.delete?.(deleteNode)
                : updateLocalAttributes({ ...imageAction.actionData })
              "
            >
              <component
                :is="ActionIconsMap[imageAction.icon]"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </button>
          </section>
        </template>
      </Tippy>

      <div
        v-if="isCurrentImageBeingUploaded"
        class="absolute inset-0 flex items-center justify-center"
      >
        <div class="flex items-center gap-2 p-2 text-xs text-white rounded-full bg-slate-900/75">
          <SpinLoader
            class="w-4 h-4"
          />
          {{ $t('common.uploading') }}…
        </div>
      </div>

      <LoadingPlaceholder
        v-if="!isVisible"
        class="rounded-lg"
      />

      <div
        v-if="isEditable && props.selected && isVisible"
        class="bottom-right-resize-handle resize-handle"
        title="Resize"
        @mousedown="startHorizontalResize($event, 'right')"
        @mouseup="stopHorizontalResize"
      />

      <div
        v-if="isEditable && props.selected && isVisible"
        class="bottom-left-resize-handle resize-handle"
        title="Resize"
        @mousedown="startHorizontalResize($event, 'left')"
        @mouseup="stopHorizontalResize"
      />
    </div>
  </NodeViewWrapper>
</template>

<style lang="scss">
.resizable-image-node-view {
  @apply relative;

  &.f-left {
    @apply float-left;
  }

  &.f-right {
    @apply float-right;
  }

  &.align-left {
    @apply justify-start;
  }

  &.align-center {
    @apply justify-center;
  }

  &.align-right {
    @apply justify-end;
  }

  .resize-handle {
    @apply absolute -bottom-1 h-2 w-2 border bg-white border-black shadow-xl;
  }

  .bottom-right-resize-handle {
    @apply -right-1 cursor-nwse-resize;
  }

  .bottom-left-resize-handle {
    @apply -left-1 cursor-nesw-resize;
  }
}
</style>
