<script setup lang="ts">
interface Props {
  size?: "small" | "large" | "xl"
  avatar?: boolean
  styles?: string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    size: "small",
    avatar: false,
    styles: "",
  },
)
</script>

<template>
  <div
    class="w-full h-auto"
    :class="props.styles"
  >
    <div
      class="flex-row items-center justify-center w-full h-full space-x-5 flex-0 animate-pulse"
    >
      <div
        v-if="avatar"
        class="hidden w-12 h-12 bg-gray-200 rounded-full"
      />
      <div class="flex flex-col space-y-3">
        <div
          v-if="size === 'xl'"
          class="flex items-center justify-start gap-2"
        >
          <div
            class="w-1/2 h-8 bg-gray-200 rounded-md"
          />
          <div
            class="h-8 bg-gray-200 rounded-md w-[10%]"
          />
        </div>
        <div
          v-if="size === 'xl'"
          class="flex items-center gap-6 py-6"
        >
          <div class="w-12 h-12 bg-gray-200 rounded-full" />
          <div class="flex flex-col flex-grow space-y-2">
            <div class="w-2/3 h-4 bg-gray-200 rounded-md" />
            <div class="w-1/2 h-4 bg-gray-200 rounded-md" />
          </div>
        </div>
        <div
          v-if="size === 'large' || size === 'xl'"
          class="bg-gray-200 rounded-md"
          :class="size === 'large' ? 'w-3/4 h-6' : 'w-1/2 h-8'"
        />
        <div
          v-if="size === 'xl'"
          class="w-full h-4 bg-gray-200 rounded-md"
        />
        <div
          v-if="size === 'xl'"
          class="w-full h-4 bg-gray-200 rounded-md"
        />
        <div
          class="w-2/3 bg-gray-200 rounded-md"
          :class="size === 'xl' ? 'h-4' : 'h-6'"
        />
        <div
          v-if="size !== 'xl'"
          class="w-2/3 h-6 bg-gray-200 rounded-md"
        />
        <div
          v-else
          class="pt-6 "
        >
          <div class="w-1/2 h-8 bg-gray-200 rounded-md" />
        </div>
        <div
          v-if="size === 'large' || size === 'xl'"
          class="bg-gray-200 rounded-md"
          :class="size === 'large' ? 'w-3/4 h-6' : 'w-full h-4'"
        />
        <div
          class="bg-gray-200 rounded-md"
          :class="size === 'xl' ? 'w-3/4 h-4' : 'w-1/2 h-6'"
        />
      </div>
    </div>
  </div>
</template>
