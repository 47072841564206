<script setup lang="ts">
// external
import { computed, ref, toRaw } from "vue"
import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"
import { PlusIcon } from "@heroicons/vue/24/solid"
import { NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"
import { MenuButton } from "@headlessui/vue"
import { ArrowUturnLeftIcon, Bars3CenterLeftIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/vue/24/outline"

// internal
import { Dropdown, DropdownLink, GenerateSignatureBlockDropdown } from "~/components"
import { useDocumentStore, useEditorStore, useNotificationStore, usePartyStore, useSharedStore, useSignatureStore, useTemplateStore } from "~/stores"
import { CrudContext, DocumentTab, EditorContentTab, SignatureBlock as SignatureBlockType, TemplateTab, Party } from "~/types"
import SignatureBlock from "../signatureBlock/SignatureBlock.vue"

import { SignatureContainerAttrs } from "./signatureContainer"

const props = defineProps<NodeViewProps>()

const editorStore = useEditorStore()
const { isEditorEditable, refUuidsOfSignaturesInEditor } = storeToRefs(editorStore)

const { parties } = storeToRefs(usePartyStore())

const documentStore = useDocumentStore()
const { isLockedDocument, mdu, mau } = storeToRefs(documentStore)

const templateStore = useTemplateStore()

const signatureStore = useSignatureStore()
const { signatureBlocks: storeSignatureBlocks } = storeToRefs(signatureStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const { notify } = useNotificationStore()

const { t } = useI18n()

const nodeAttrs = computed(() => props.node.attrs as SignatureContainerAttrs)

interface ExtendedSignatureBlock extends SignatureBlockType {
  party: Party
}

const signatureBlocksToAdd = computed(
  () => structuredClone(toRaw(storeSignatureBlocks.value))
    .map(
      (signatureBlock: SignatureBlockType) => Object.assign(
        {
          party: parties.value.find((p) => p.uuid === signatureBlock.party_uuid),
        },
        signatureBlock,
      ),
    )
    .filter(
      (signatureBlock: ExtendedSignatureBlock) => !signatureBlock.deleted_at && !refUuidsOfSignaturesInEditor.value.includes(signatureBlock.ref_uuid) && !!signatureBlock.party,
    ),
)

const isSignatureBeingDragged = ref(false)

const onDragEnter = () => isSignatureBeingDragged.value = true

const onDragLeave = () => isSignatureBeingDragged.value = false

const onDrop = (e: DragEvent) => {
  e.preventDefault()

  isSignatureBeingDragged.value = false

  const signatureBlock = e.dataTransfer.getData("text")

  let signatureBlockJson

  try {
    signatureBlockJson = JSON.parse(signatureBlock)
  } catch {
    console.warn(`signatureBlock: '${signatureBlock}' is not valid JSON`)
    return false
  }

  if (!signatureBlockJson?.isSignatureBlock) return false

  const {
    refUuid,
  } = signatureBlockJson

  addSignatureBlock(refUuid)
}

const addSignatureContainerWithSignatureBlock = (refUuid: SignatureBlockType["ref_uuid"]) => {
  if (!refUuid) return

  props.editor.commands.insertContentAt(
    props.getPos() + 1,
    [
      {
        type: props.node.type.name,
        attrs: {
          signatureBlocks: [ { refUuid } ],
        },
      },
    ],
  )
}

const signatureBlocks = computed(() => nodeAttrs.value.signatureBlocks || [])

const deleteSignatureBlock = (refUuid: string) => {
  const signatureBlocksCopy = [ ...signatureBlocks.value ]

  const index = signatureBlocksCopy.findIndex((signatureBlock) => signatureBlock.refUuid === refUuid)

  if (index === -1) return

  signatureBlocksCopy.splice(index, 1)

  props.updateAttributes(
    {
      signatureBlocks: signatureBlocksCopy,
    },
  )
}

const addSignatureBlock = async (refUuid: SignatureBlockType["ref_uuid"]) => {

  if (!refUuid) return

  if (nodeAttrs.value.signatureBlocks.length >= 2) {
    notify({
      type: "warning",
      title: t("editor.maxSignaturesPerContainerReached"),
      message: t("editor.maxSignaturesPerContainerReachedHint"),
    })

    addSignatureContainerWithSignatureBlock(refUuid)

    return false
  }

  props.updateAttributes(
    {
      signatureBlocks: [
        ...(signatureBlocks.value || []),
        { refUuid },
      ],
    },
  )

  setTimeout(
    () => editorStore.extractSignaturesFromEditor(props.editor),
  )
}

const contextAwareTabKeyActions: Record<"template" | "document", () => void> = {
  "document": () => {
    documentStore.setActiveTabKey(DocumentTab.documentContent)

    const signatureTab = documentStore.editorContentTabs.find((tab) => tab.id === EditorContentTab.signatureBlocks)

    if (!signatureTab) return

    documentStore.setActiveEditorContentTab(signatureTab)
  },
  "template": () => {
    templateStore.setActiveTabKey(TemplateTab.documentContent)

    const signatureTab = templateStore.editorContentTabs.find((tab) => tab.id === EditorContentTab.signatureBlocks)

    if (!signatureTab) return

    templateStore.setActiveEditorContentTab(signatureTab)
  },
}

const goToDocumentContentTab = () => contextAwareTabKeyActions[crudContext.value]()

const returnToIndividualSignatureBlocks = () => {
  const start = props.getPos()

  const signatureBlocks = nodeAttrs.value.signatureBlocks.map(
    (sB) => (
      {
        attrs: sB,
        type: "signatureBlock",
      }
    ),
  )

  props.editor.commands.insertContentAt(start, signatureBlocks)

  props.deleteNode()
}

const setAlignment = (align: string) => {
  props.updateAttributes(
    {
      ...nodeAttrs.value,
      align,
    },
  )
}
</script>

<template>
  <NodeViewWrapper
    class="relative flex flex-col w-full gap-2 signature-container"
    :class="{
      'border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 transition-shadow duration-200 hover:shadow-md my-4 p-4': isEditorEditable,
      'bg-blue-200': isSignatureBeingDragged,
    }"
    @dragover="onDragEnter"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <!-- action buttons -->
    <section
      v-if="isEditorEditable && !isLockedDocument && !!mau"
      class="flex w-full"
      :class="parties?.length && signatureBlocks?.length ? 'justify-between' : 'justify-end'"
    >
      <Dropdown
        v-if="parties?.length && signatureBlocks.length"
        menu-classes="z-[15] relative"
        align="left"
      >
        <template #trigger>
          <MenuButton
            class="p-1 text-gray-400 transition-all duration-200 rounded-md cursor-pointer hover:text-indigo-400 hover:bg-gray-100"
            data-tippy-context
            data-placement="top"
            :data-tippy-content="$t('documentContent.alignSignatureBlocks')"
          >
            <Bars3CenterLeftIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </MenuButton>
        </template>

        <template #content>
          <DropdownLink
            v-for="alignment in ['left', 'center', 'right', 'justify']"
            :key="alignment+'-align'"
            as="button"
            :class="{
              'bg-gray-600': nodeAttrs.align === alignment,
            }"
            @click="setAlignment(alignment)"
          >
            {{ t(`editor.align${alignment[0].toUpperCase() + alignment.slice(1)}`) }}
          </DropdownLink>
        </template>
      </Dropdown>

      <section class="flex gap-2">
        <span
          v-if="signatureBlocks?.length"
          class="p-1 text-gray-400 transition-all duration-200 rounded-md cursor-pointer hover:text-indigo-400 hover:bg-gray-100"
          data-tippy-context
          data-placement="top"
          :data-tippy-content="$t('documentContent.convertToPlainSignatureBlocks')"
          @click.stop.prevent="returnToIndividualSignatureBlocks"
        >
          <ArrowUturnLeftIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </span>

        <span
          class="p-1 text-gray-400 transition-all duration-200 rounded-md cursor-pointer hover:text-red-400 hover:bg-gray-100"
          data-tippy-context
          data-placement="top"
          :data-tippy-content="$t('common.remove')"
          @click.stop.prevent="props.deleteNode"
        >
          <TrashIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </span>
      </section>
    </section>

    <!-- signature blocks -->
    <section
      v-if="signatureBlocks.length"
      class="flex flex-wrap items-start w-full gap-2"
      :class="{
        'justify-center': nodeAttrs.align === 'center',
        'justify-end': nodeAttrs.align === 'right',
        'justify-between': nodeAttrs.align === 'justify',
      }"
    >
      <SignatureBlock
        v-for="signatureBlock in signatureBlocks"
        :key="signatureBlock.refUuid + '_container-signature-block'"
        :ref-uuid="signatureBlock.refUuid"
        :is-editable="isEditorEditable"
        @delete="deleteSignatureBlock(signatureBlock.refUuid)"
      />
    </section>

    <!-- info + add signature dropdown -->
    <div
      v-if="isEditorEditable && parties?.length && signatureBlocksToAdd?.length"
      menu-classes="relative"
      class="z-50 flex flex-wrap items-center self-end gap-2 mx-auto text-sm text-gray-400 w-fit"
    >
      <div class="flex items-center flex-shrink-0 gap-2">
        <ViewfinderCircleIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        {{ $t('documentContent.dropSignatureBlockHereOr') }}
      </div>
      <div class="flex items-center flex-shrink-0 gap-2">
        <GenerateSignatureBlockDropdown
          :is-editor-node="true"
          :available-signature-blocks="signatureBlocksToAdd"
          @insert-signature-block="addSignatureBlock"
          @manage-signature-blocks="goToDocumentContentTab"
        />
      </div>
    </div>
    <div
      v-else-if="isEditorEditable && !parties?.length"
      class="flex flex-col items-center self-end gap-2 mx-auto text-sm text-gray-400 w-fit"
    >
      <div class="flex-shrink-0">
        {{ $t('documentContent.noSignatureBlocksAndNoParties') }}
      </div>

      <div
        v-if="mdu?.permissions?.includes('party_create') || crudContext === CrudContext.template"
        class="flex-shrink-0"
      >
        <button
          type="button"
          :class="parties?.length ? '' : 'mx-auto'"
          data-template="addPartyForm"
          data-placement="right"
          class="btn-plain add-party-button btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
        >
          <PlusIcon class="shrink-0 h-3.5 w-3.5" />
          {{ $t('userSettings.addParty') }}
        </button>
      </div>
    </div>
    <div
      v-else-if="isEditorEditable && !signatureBlocksToAdd?.length"
      class="flex flex-wrap items-center self-end gap-2 mx-auto text-sm text-gray-400 w-fit"
      :class="signatureBlocks?.length ? '' : 'flex-col'"
    >
      <div class="flex items-center gap-2 flex-shrik-0">
        <ViewfinderCircleIcon class="shrink-0 h-3.5 w-3.5" />
        {{ signatureBlocks?.length ? $t('documentContent.noSignatureBlocksAvailable') : $t('documentContent.noSignatureBlocksAvailableAtAll') }}
      </div>

      <div
        v-if="mdu?.permissions?.includes('signature_block_create') || crudContext === CrudContext.template"
        class="flex-shrink-0"
      >
        <GenerateSignatureBlockDropdown
          :is-editor-node="true"
          @insert-signature-block="addSignatureBlock"
          @manage-signature-blocks="goToDocumentContentTab"
        />
      </div>
    </div>
  </NodeViewWrapper>
</template>
