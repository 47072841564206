import { JSONContent } from "@tiptap/core"
import { v4 as uuidv4 } from "uuid"

// internal
import { TrackedChange, CommentMarker, MammothChild, MammothJSON } from "~/types"
import { DelimiterStyleMap, LevelNumberingStyleMap, hexColor } from "~/utils"
import { handleSvg } from "../utils/handleSvg"

const allowedNodes = [
  "document",
  "paragraph",
  "run",
  "runChild",
  "text",

  "bookmarkStart",
  "break",
  "tab",
  "hyperlink",
  "table",
  "tableRow",
  "tableCell",
  "del",
  "ins",
  "commentRangeStart",
  "commentRangeEnd",
  "commentReference",
] as const

const noContent = [
  "run",
  "hyperlink",
]

const rgx = {
  // paragraph regexes
  "headingStyleIdRegex": /^Heading(\d)+/,
  "headingStyleNameRegex": /^[Hh]eading\s(\d)/i,

  "freshParagraphStyleNameRegex": /footnote text|endnote text|annotation text|Endnote|Footnote/i,

  /* list regexes */

  // delimiterStyle regexes
  "doubleParenthesisRegex": /(?:Winterstein(?:Unterabsatz1|Absatz))|Default text/i,
  "sectionMarkRegex": /WintersteinParagraphberschrift/i,

  // numberingStyle regexes
  "lowerAlpha": /WintersteinUnterabsatz1/i,
}

const unknownNodes: any[] = []

const alignmentAttributeMap: Record<string, string> = {
  "center": "center",
  "left": "left",
  "right": "right",
  "both": "justify",
}

interface Node {
  type: string;
  attrs?: {
    uuid?: string;
    kind?: string;
    level?: number;
    showNestedOrderRepresentation?: boolean;
    numberingType?: string;
    numberingStyle?: string;
    delimiterStyle?: string;
    textAlign?: string;
    hasIndent?: boolean;
  };
  content?: Node[];
  text?: string;
  marks?: any[];
}

const nestLists = (nodes: Node[], currentLevel = 0, parentList: Node[] = []): Node[] => {
  const result: Node[] = [ ...parentList ] // Clone the parent list to avoid direct mutations
  const nestingStack: Node[][] = [] // Use a stack to track nesting levels

  nodes.forEach((node, nodeIdx) => {
    if (node.type === "list") {
      let numberingType = "continue"
      // On root level, if the previous item is not a list, set attrs.numberingType to "restart"
      if (currentLevel === 0 && nodeIdx > 0 && result[result.length - 1].type !== "list") {
        if (result[result.length - 1]?.type === "paragraph" && result[result.length - 1]?.content?.length === 0) {
          numberingType = "continue"
        } else {
          numberingType = "restart"
        }
      }

      const level = node.attrs?.level ?? 0
      const levelModulo = level % 3
      const numberingStyle = node.attrs.numberingStyle || LevelNumberingStyleMap[levelModulo] || "decimal"
      const delimiterStyle = node.attrs.delimiterStyle || DelimiterStyleMap[levelModulo] || "section-mark"
      node = {
        ...node,
        attrs: {
          ...node.attrs,
          numberingType,
          numberingStyle,
          delimiterStyle,
        },
      }

      // Adjust the nesting stack based on the current level
      while (nestingStack.length > level) {
        nestingStack.pop() // Pop until we reach the correct level
      }

      if (level > currentLevel) {
        // If the current level is deeper, push the current list to the stack
        if (nestingStack.length > 0) {
          const lastList = nestingStack[nestingStack.length - 1]
          if (!lastList[lastList.length - 1].content) {
            lastList[lastList.length - 1].content = []
          }
          lastList[lastList.length - 1].content.push(node)
        } else {
          result.push(node)
        }
        nestingStack.push([ node ]) // New level of nesting
      } else {
        // Level is the same or less
        if (nestingStack.length === 0) {
          // If we're at the base level, add directly to the result
          result.push(node)
          nestingStack.push([ node ]) // Start a new level of nesting
        } else {
          // Add the node to the current level's last list
          nestingStack[nestingStack.length - 1].push(node)
          if (nestingStack.length > 1) {
            const parentLevelList = nestingStack[nestingStack.length - 2]
            parentLevelList[parentLevelList.length - 1].content = parentLevelList[parentLevelList.length - 1].content || []
            parentLevelList[parentLevelList.length - 1].content.push(node)
          }
        }
      }
    } else {
      // For non-list nodes, just add them to the nearest list or the result
      if (nestingStack.length > 1 || (nestingStack.length > 0 && node.type === "paragraph" && node.attrs?.hasIndent) || (nestingStack.length > 0 && node.type === "table" && node.attrs?.hasIndent)) {
        const currentList = nestingStack[nestingStack.length - 1]
        currentList[currentList.length - 1].content = currentList[currentList.length - 1].content || []
        currentList[currentList.length - 1].content.push(node)
      } else {
        result.push(node)
      }
    }
  })

  return result
}


const transformImages = async (node: JSONContent, idDataUriMap: Record<string, string>) => {
  if (node.type === "resizableImage" && node.attrs.el) {
    const mimeType = node.attrs.el.contentType
    // Check for gradients if the image is an SVG
    if (mimeType === "image/svg+xml" && await handleSvg(node.attrs.el)) {
      // mark this node for removal
      node.type = "strip_from_content" // Example marker, adjust as needed
      return
    }
    // Ignore x-emf images from Word, as they cannot be handled by Imagick
    if (mimeType === "image/x-emf") {
      // mark this node for removal
      node.type = "strip_from_content" // Example marker, adjust as needed
      return
    }

    const base64Str = await node.attrs.el.readAsBase64String()
    node.attrs.dataUrl = `data:${node.attrs.el.contentType};base64,${base64Str}`
    idDataUriMap[node.attrs.uuid] = node.attrs.dataUrl
  }

  if (!node.content?.length) return

  // Recursively transform child nodes
  await Promise.all(node.content.map((child) => transformImages(child, idDataUriMap)))

  // Filter out marked nodes ('strip_from_content')
  node.content = node.content.filter((child) => child.type !== "strip_from_content")
}

const flattenImages = (node: JSONContent) => {
  if (!node.content?.length) return

  node.content.forEach(flattenImages)

  for (let i = 0; i < node.content.length; i += 1) {
    const child = node.content[i]

    if (!child.content?.length) continue

    if (child.type === "paragraph") {
      for (let j = 0; j < child.content.length; j += 1) {
        const grandChild = child.content[j]

        if (grandChild.type === "resizableImage") {
          node.content.splice(i, 0, grandChild)

          child.content.splice(j, 1)
        }
      }
    }
  }
}

const sanitizeJson = (node: JSONContent): JSONContent => {
  const content = node.content?.filter((n) => n.type !== "text" || n.text !== "") ?? []

  return Object.assign(
    node,
    content.length && {
      content: content
        .filter(
          (n) => {
            const isChildAndParentParagraph = n.type === "paragraph" && node.type === "paragraph"

            return !isChildAndParentParagraph
          },
        )
        .map((n) => sanitizeJson(n)),
    },
  )
}

const changes: TrackedChange[] = []

const commentMarkers: CommentMarker[] = []

const emptyText = { type: "text", text: "" }

const tabText = { type: "text", text: " ".repeat(4) }

let currentUuid = ""

const nodeConverters: Partial<Record<typeof allowedNodes[number], (mammothChild: MammothChild, mammothParent?: MammothChild) => JSONContent>> = {
  document: (): JSONContent => {
    return {
      type: "doc",
    }
  },
  paragraph: (paragraph: MammothChild): JSONContent => {
    const attrs: JSONContent["attrs"] = {}

    let type: "paragraph" | "heading" = "paragraph"

    if (rgx.headingStyleIdRegex.test(paragraph.styleId)) {
      const level = parseInt(paragraph.styleId.match(rgx.headingStyleIdRegex)[1])

      if (typeof level === "number" && level > 0 && level < 7) {
        type = "heading"
        attrs.level = level
      }
    } else if (rgx.headingStyleNameRegex.test(paragraph.styleName)) {
      const level = parseInt(paragraph.styleName.match(rgx.headingStyleNameRegex)[1])

      if (typeof level === "number" && level > 0 && level < 7) {
        type = "heading"
        attrs.level = level
      }
    }

    if (paragraph.alignment) attrs.textAlign = alignmentAttributeMap[paragraph.alignment]

    // Generate a uuid for the paragraph
    attrs.uuid = uuidv4()
    currentUuid = attrs.uuid

    // We need indent information to determine if the paragraph should be appended to a list
    attrs.hasIndent = paragraph.indent?.start ?? false

    let generateParagraphContent = paragraph.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean)

    if (type === "heading") {
      // Filter bold marks from heading content
      generateParagraphContent = generateParagraphContent.filter((n) => n.type !== "text" || n.text !== "").map((n) => {
        if (n.type === "text" && n.marks) {
          n.marks = n.marks.filter((m) => m.type !== "bold")
        }
        return n
      })
    }

    const paragraphContent: JSONContent = {
      type,
      attrs,
      content: generateParagraphContent,
    }

    if (paragraph.numbering) {
      if (!paragraph.children.length) return emptyText

      const firstChild = paragraph.children[0]

      if (firstChild.type === "bookmarkStart" && firstChild.name) [ "id", "uuid" ].forEach((k) => attrs[k] = firstChild.name)

      const paragraphStyleId = paragraph.numbering?.paragraphStyleId

      const isOrdered = paragraph.numbering?.isOrdered ?? false

      const attrsWithExtraUuid = {
        ...attrs,
        uuid: uuidv4(),
      }

      if (isOrdered) {

        let delimiterStyle = ""

        if (rgx.sectionMarkRegex.test(paragraphStyleId)) delimiterStyle = "section-mark"
        else if (rgx.doubleParenthesisRegex.test(paragraphStyleId)) delimiterStyle = "double-parenthesis"

        let numberingStyle = ""

        if (rgx.lowerAlpha.test(paragraphStyleId)) numberingStyle = "lower-alpha"

        const showNestedOrderRepresentation = paragraph.numbering?.isLegalNumberingStyle ?? false

        const level = paragraph.numbering.level ? parseInt(paragraph.numbering.level) : 0

        const returnObject = {
          type: "list",
          attrs: {
            kind: "ordered",
            ...attrsWithExtraUuid,
            level,
            ...(delimiterStyle && { delimiterStyle }),
            ...(numberingStyle && { numberingStyle }),
            ...({ showNestedOrderRepresentation }),
          },
          content: [
            paragraphContent,
          ],
        }

        return returnObject

      } else {
        return {
          type: "list",
          attrs: {
            kind: "bullet",
            level: parseInt(paragraph.numbering.level),
            ...attrsWithExtraUuid,
          },
          content: [
            paragraphContent,
          ],
        }
      }
    }

    return paragraphContent
  },
  run: (run: MammothChild): JSONContent[] => {
    return run.children.map((child) => nodeConverters["runChild"](child, run)).filter(Boolean)
  },
  hyperlink: (hyperlink: MammothChild): JSONContent[] => {
    const content = hyperlink.children.map(convertMammothChildToTiptapJson)?.flat().filter((n) => n.type !== "text" || n.text !== "") ?? []

    return content.map(
      (n) => {
        if (n.type !== "text") return n

        n.marks = (n.marks ?? [])
          .concat(
            [
              {
                type: "link",
                attrs: {
                  href: hyperlink.href || `#${hyperlink.anchor}`,
                },
              },
            ],
          )

        return n
      },
    )
  },

  table: (table: MammothChild): JSONContent => {
    return {
      type: "table",
      content: table.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
      attrs: {
        isBordered: table.isBordered,
      },
    }
  },

  tableRow: (tableRow: MammothChild): JSONContent => {
    return {
      type: "tableRow",
      content: tableRow.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
    }
  },

  tableCell: (tableCell: MammothChild): JSONContent => {
    const attrsObject: Record<string, any> = {
      colspan: tableCell.colSpan,
      rowspan: tableCell.rowSpan,
      colwidth: tableCell.width ? [ tableCell.width ] : null,
    }
    const bgColor = tableCell.bgColor
    if (hexColor(bgColor)) {
      attrsObject.bgColor = "#" + hexColor(bgColor)
    }
    return {
      type: "tableCell",
      attrs: attrsObject,
      content: tableCell.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
    }
  },

  // Note: this should never be called
  ins: (ins: MammothChild): JSONContent => {
    changes.push({
      uuid: uuidv4(),
      type: "insertion",
      authorName: ins.authorName,
      changeId: ins.changeId,
      date: ins.date,
      content: ins.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
    })
    return {
      type: "ins",
      content: ins.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
    }
  },

  // Note: this should never be called
  del: (del: MammothChild): JSONContent => {
    changes.push({
      uuid: uuidv4(),
      type: "deletion",
      authorName: del.authorName,
      changeId: del.changeId,
      date: del.date,
      content: del.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
    })
    return {
      type: "del",
      content: del.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
    }
  },

  commentRangeStart: (child: MammothChild): JSONContent => {
    commentMarkers.push({
      uuid: currentUuid,
      id: child.commentId,
    })
    return emptyText
  },

  commentRangeEnd: (child: MammothChild): JSONContent => {
    commentMarkers.push({
      uuid: currentUuid,
      id: child.commentId,
    })
    return emptyText
  },

  commentReference: (child: MammothChild): JSONContent => {
    commentMarkers.push({
      uuid: currentUuid,
      id: child.commentId,
    })
    return emptyText
  },

  runChild: (child: MammothChild, run: MammothChild): JSONContent => {
    if (child.type === "break" && child.breakType === "line") return { type: "hardBreak" }

    if (child.type === "tab") return tabText

    if (child.type === "image") {
      return {
        type: "resizableImage",
        el: child,
        attrs: {
          el: child,
          uuid: uuidv4(),
          widthInPx: child.width,
          heightInPx: child.height,
        },
      }
    }

    if (child.type === "ins" || child.type === "del") {
      changes.push({
        uuid: currentUuid,
        type: child.type === "ins" ? "insertion" : "deletion",
        authorName: child.authorName,
        authorInitials: child.authorInitials,
        date: child.date,
        content: child.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
      })
      return {
        type: child.type,
        content: child.children.map(convertMammothChildToTiptapJson).flat().filter(Boolean),
      }
    }

    // Note: this should never be called
    if (child.type === "commentRangeStart" || child.type === "commentRangeEnd" || child.type === "commentReference") {
      commentMarkers.push({
        uuid: currentUuid,
        id: child.commentId,
      })
      return emptyText
    }

    if (child.type !== "text") {
      return emptyText
    }

    const marks: JSONContent["marks"] = []

    if (run.isStrikethrough) marks.push({ type: "strike" })
    if (run.isUnderline) marks.push({ type: "underline" })
    if (run.isItalic) marks.push({ type: "italic" })
    if (run.isBold) marks.push({ type: "bold" })
    if (run.verticalAlignment === "superscript") marks.push({ type: "superscript" })
    if (run.verticalAlignment === "subscript") marks.push({ type: "subscript" })
    if (run.fontSize || run.color) {
      let mark = { } as any
      if (run.fontSize) mark = { type: "textStyle", attrs: { ...mark.attrs, fontSize: run.fontSize } }
      if (run.color) mark = { type: "textStyle", attrs: { ...mark.attrs, color: "#" + run.color } }
      if (mark.attrs) marks.push(mark)
    }

    return {
      type: "text",
      marks,
      text: child.value,
    }
  },

  // TODO: implement
  // text: (mammothChild: MammothChild): JSONContent => {
  //   return {
  //     type: "text",
  //     text: mammothChild.value,
  //   }
  // },
  // hyperlink, bookmarkStart, noteReference, note, commentReference, comment, image, table, tableRow, tableCell, break
}

export const convertMammothChildToTiptapJson = (mammothChild: MammothChild): JSONContent => {
  if (!mammothChild) {
    console.warn("mammothChild is undefined")
  }
  const tiptapNodeJson = nodeConverters[mammothChild.type]?.(mammothChild) as JSONContent
  if (tiptapNodeJson) {
    if (noContent.includes(mammothChild.type)) return tiptapNodeJson

    if (tiptapNodeJson.content) {
      tiptapNodeJson.content = tiptapNodeJson.content.filter((n) => n.type !== "text" || n.text !== "")
    }

    return tiptapNodeJson
  } else {
    // unknownNodes.push(
    //   {
    //     type: mammothChild.type,
    //     styleId: mammothChild.styleId,
    //     styleName: mammothChild.styleName,
    //     value: mammothChild.value,
    //   },
    // )

    unknownNodes.push(mammothChild.type)

    return emptyText
  }
}

const enrichInsertionsAndDeletions = (jsonProposal: JSONContent) => {
  changes.forEach((change) => {
    const parentProposal = findParent(jsonProposal, change.uuid)
    const narrowedParentProposal = removeNestedListNodes(parentProposal)
    change.parentProposal = parentProposal
    change.narrowedParentProposal = narrowedParentProposal
  })
}

const findParent = (json: JSONContent, uuid: string): JSONContent => {
  if (!json || !json.content) return undefined

  for (const child of json.content) {
    if (child.attrs?.uuid === uuid) {
      // Found the child node, return its parent
      return child
    }

    const parent = findParent(child, uuid)
    if (parent) return parent
  }

  // No parent found in this branch
  return undefined
}

// Method to traverse a JSONContent tree and remove all nodes of type 'list' that are below the root level
const removeNestedListNodes = (json: JSONContent): JSONContent => {
  if (!json || !json.content) return json
  const contentWithoutListNodes = json.content.filter((child) => child.type !== "list")
  const returnValue = {
    ...json,
    content: contentWithoutListNodes,
  }
  return returnValue
}

export const convertMammothJsonToTiptapJson = async (json: MammothJSON): Promise<{
  json: JSONContent,
  jsonBeforeChanges: JSONContent,
  jsonProposal: JSONContent,
  idDataUriMap: Record<string, string>,
  changes: TrackedChange[],
  commentMarkers: CommentMarker[],
}> => {

  const content = json.children.map(convertMammothChildToTiptapJson).flat()

  const transformedLists = nestLists(content as Node[])

  const finalJson = {
    type: "doc",
    content: transformedLists,
  }

  const idDataUriMap: Record<string, string> = {}

  await transformImages(finalJson, idDataUriMap)

  flattenImages(finalJson)

  const { finalJsonBeforeChanges, finalJsonAfterChanges, finalJsonProposal } = convertTrackedChangesJsonToTiptapJson(finalJson)

  if (unknownNodes.length) console.warn("unknownNodes: ", [ ...new Set(unknownNodes) ])

  enrichInsertionsAndDeletions(finalJsonProposal)

  return {
    json: finalJsonAfterChanges,
    jsonBeforeChanges: finalJsonBeforeChanges,
    jsonProposal: finalJsonProposal,
    idDataUriMap,
    changes,
    commentMarkers,
  }
}

const processTrackedChangesWithMarks = (node: JSONContent): void => {
  if (!node.content) return

  node.content = node.content.flatMap((child: JSONContent): JSONContent[] => {
    if (child.type === "ins") {
      applyMarkToContent(child, { type: "insertion", attrs: { tag: "insertion" } })
      return child.content || []
    } else if (child.type === "del") {
      applyMarkToContent(child, { type: "deletion", attrs: { tag: "deletion" } })
      return child.content || []
    } else {
      processTrackedChangesWithMarks(child)
      return [ child ]
    }
  })
}

const applyMarkToContent = (
  node: JSONContent,
  mark: { type: string, attrs: Record<string, any> },
) => {
  if (node.content) {
    node.content.forEach((child) => applyMarkToContent(child, mark))
  }
  if (node.type === "text") {
    node.marks = node.marks || []
    node.marks.push(mark)
  }
}

const processTrackedChanges = (node: JSONContent, state: "before" | "after"): void => {
  if (!node.content) return

  node.content = node.content.flatMap((child: JSONContent): JSONContent[] => {
    if (state === "before" && child.type === "del") {
      return child.content ? child.content.flatMap((c: JSONContent) => {
        processTrackedChanges(c, state)
        return c
      }) : []
    } else if (state === "after" && child.type === "ins") {
      return child.content ? child.content.flatMap((c: JSONContent) => {
        processTrackedChanges(c, state)
        return c
      }) : []
    } else if (child.type !== "ins" && child.type !== "del") {
      processTrackedChanges(child, state)
      return [ child ]
    }
    return []
  }).filter((child: JSONContent | undefined): child is JSONContent => child !== undefined)
}

const convertTrackedChangesJsonToTiptapJson = (json: JSONContent): {
  finalJsonBeforeChanges: JSONContent,
  finalJsonAfterChanges: JSONContent,
  finalJsonProposal: JSONContent,
} => {
  const jsonBeforeChanges = JSON.parse(JSON.stringify(json))
  const jsonAfterChanges = JSON.parse(JSON.stringify(json))
  const jsonProposal = JSON.parse(JSON.stringify(json))

  processTrackedChanges(jsonBeforeChanges, "before")
  processTrackedChanges(jsonAfterChanges, "after")
  processTrackedChangesWithMarks(jsonProposal)

  return {
    finalJsonBeforeChanges: sanitizeJson(jsonBeforeChanges),
    finalJsonAfterChanges: sanitizeJson(jsonAfterChanges),
    finalJsonProposal: sanitizeJson(jsonProposal),
  }
}
