<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { onBeforeMount } from "vue"
import { router } from "@inertiajs/vue3"

// internal
import { AccountUser } from "~/types"
import { useTaskStore } from "~/stores"
import { TaskIcon } from "~/icons"

const taskStore = useTaskStore()
const { showTaskModal, showCreationForm, taskCount } = storeToRefs(taskStore)

interface Props {
  showText?: boolean
  iconOnly?: boolean
  iconClasses?: string
  mau: AccountUser
}

const props = withDefaults(defineProps<Props>(), {
  showText: false,
  iconOnly: false,
  iconClasses: "w-6 h-6 text-white/50",
})

const openModal = () => {
  showCreationForm.value = false
  showTaskModal.value = true
}

onBeforeMount(() => {
  Echo.private(`users.${props.mau.user.uuid}`)
    .listen(
      "Pusher\\User\\TaskCreated",
      () => {
        router.reload({
          only: [ "openTasks" ],
        })
      },
    )

  Echo.private(`users.${props.mau.user.uuid}`)
    .listen(
      "Pusher\\User\\TaskChanged",
      () => {
        router.reload({
          only: [ "openTasks" ],
        })
      },
    )
})
</script>
<template>
  <button
    type="button"
    class="relative overflow-hidden"
    @click="openModal"
  >
    <div
      class="relative"
    >
      <TaskIcon
        class="shrink-0"
        :class="[iconClasses, {'icon-shimmer': !!taskCount}]"
        aria-hidden="true"
      />
      <span
        v-if="!!taskCount"
        class="absolute left-[13px] -top-[4px] rounded-full text-[8px] text-white bg-teal-600 font-semibold flex w-[13px] h-[13px] items-center justify-center"
      >
        <span v-if="taskCount < 100">{{ taskCount }}</span>
        <span
          v-else
          class="block absolute w-[5px] h-[5px] rounded-full bg-white top-[4px] left-[4px]"
        />
      </span>
    </div>
    <span
      v-if="!iconOnly"
      class="inline-block overflow-x-hidden transition-all duration-500 opacity-0 group-hover:w-auto group-hover:opacity-100 group-hover:ml-3"
      :class="[showText ? 'w-auto opacity-100 ml-3' : 'w-0']"
    >
      {{ $t('navigation.tasks') }}
    </span>
  </button>
</template>
