<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"

import { NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"

// internal
import { useUserStore } from "~/stores"
import { getUserRepresentation } from "~/utils"

const props = defineProps<NodeViewProps>()

const mentionAttrs = computed(() => props.node.attrs)

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const user = computed(() => users.value.find((item) => item.uuid === mentionAttrs.value.mentionUuid))

const userNameToShow = computed<string>(() => getUserRepresentation(user.value))
</script>

<template>
  <NodeViewWrapper class="inline-flex -mt-1">
    <div
      class="relative px-1 leading-snug rounded-md"
      :class="user && mentionAttrs.mentionUuid === user.uuid ? 'bg-indigo-200' : 'bg-red-200'"
    >
      <span
        v-if="user && mentionAttrs.mentionUuid === user.uuid"
        class="text-indigo-700"
      >
        {{ userNameToShow }}
      </span>
      <span
        v-else
        class="text-gray-700"
      >
        {{ $t('common.n/a(notFound)') }}
      </span>
    </div>
  </NodeViewWrapper>
</template>
