<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch, onMounted } from "vue"
import { PlusIcon } from "@heroicons/vue/20/solid"
import { EnvelopeIcon, TrashIcon, UserPlusIcon } from "@heroicons/vue/24/outline"
import { QuestionMarkCircleIcon, LockClosedIcon } from "@heroicons/vue/24/solid"
import { filter, intersection, difference, differenceWith } from "lodash-es"
import { Link, usePage } from "@inertiajs/vue3"

// internal
import { FormInputErrors, SpinLoader, UserPartySettings, UserCombobox, UserDisplay, SharingModalInvitedUser } from "~/components"
import { useUserStore, useSharedStore, usePartyStore, useDocumentStore, useAccountStore } from "~/stores"
import { AccountUser, Document, DocumentUser, DocumentUserRoleEnum, Party, Team, Template } from "~/types"
import { SignatureIcon } from "~/icons"
import { documentUserInvitationStatuses } from "~/utils"

interface Props {
  document?: Document
  scope?: "internal" | "external"
  isActive?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    isActive: false,
    scope: "external",
  },
)

const emit = defineEmits( [ "close", "set-has-external-document-users-invited" ])

const userStore = useUserStore()
const { users, uuidsOfIsLoadingSendInvitation, availableAccountUsers, documentUsersForSharing } = storeToRefs(userStore)
const { getUserNotifications, getUserBackendErrors, sendInvitations, setDocumentUsersForSharing, setDocumentUserForSharing, addDocumentUserForSharing, removeNewUser } = userStore

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const documentStore = useDocumentStore()
const { mau, mdu } = storeToRefs(documentStore)

const accountStore = useAccountStore()
const { planFeatures, accountUsage } = storeToRefs(accountStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const defaultPartyUuid = computed<Party["uuid"]>(() => {
  let returnUuid = null
  if (props.scope === "internal") returnUuid = parties.value?.find((el) => el.account_party_uuid)?.uuid || null
  else if (props.scope === "external") returnUuid = parties.value?.find((el) => !el.account_party_uuid)?.uuid || null
  return returnUuid
})

const predefinedMessage = props.scope === "external" ? (props.document?.external_reviewer_invitation_message) : ""

const message = ref<string>(predefinedMessage)
const messageError = ref<string[]>(null)

const idxOfIsCreatingUser = ref<number[]>([])
const uuidsOfIsRemovingSignatoryAsCollaborator = ref<DocumentUser["uuid"][]>([])

const existingDocumentUsers = computed<DocumentUser[]>(() => {
  return filter(users.value, (user) => {

    const isBeingRemovedAsCollaborator = uuidsOfIsRemovingSignatoryAsCollaborator.value.includes(user.uuid)

    // Check if user is external, i.e. has no account_user?.uuid
    const matchesScope = !!(props.scope === "internal" ? user.account_user?.uuid : !user.account_user?.uuid)

    // Check if user is a collaborator
    const matchesRole = intersection([ DocumentUserRoleEnum.collaborator, DocumentUserRoleEnum.owner ], user.roles || [])?.length > 0

    // If no mau, then mdu must have same party_uuid
    const matchesParty = !!(mau.value ? true : user.party_uuid === mdu.value?.party_uuid)

    return !!(matchesScope && matchesParty && (matchesRole || isBeingRemovedAsCollaborator))
  })
})

const emptyUser: Partial<DocumentUser> = Object.freeze({ roles: [ DocumentUserRoleEnum.collaborator ] })

watch(parties, () => {
  documentUsersForSharing.value = [ { ...Object.assign(emptyUser), party_uuid: defaultPartyUuid.value } ]
})

const deactivatedUsers = computed<DocumentUser[]>(() => [ ...documentUsersForSharing.value as DocumentUser[], ...existingDocumentUsers.value ])

const handleSendInvitations = async () => {
  const sendRes = await sendInvitations(message.value, crudContext.value, entityUuid.value, [ DocumentUserRoleEnum.collaborator ])
  if (!sendRes) return
  if (!!mau.value) emit("set-has-external-document-users-invited")
  emit("close")
}

const validDocumentUsersForSharing = computed<boolean>(() => {
  return documentUsersForSharing.value?.some(
    (el) => el?.email || el?.account_user?.uuid,
  )
})

const handleUpdatePartyOfNewUser = (userIdx: number, partyUuid: Party["uuid"]) => {
  const user = documentUsersForSharing.value[userIdx]
  if (!user) return
  documentUsersForSharing.value[userIdx] = { ...user, party_uuid: partyUuid }
}

const signatoriesWithInvitation = computed<DocumentUser[]>(() => {
  const signatoriesInvited = users.value?.filter((el) => {
    const scope = props.scope === "external" ? !el.account_user?.uuid : !!el.account_user?.uuid
    const role = el?.roles?.includes(DocumentUserRoleEnum.signatory)
    const invitationExistsInStoreData = getUserNotifications(el, documentUserInvitationStatuses)?.length
    const partyMatch = !!(mau?.value ? true : el.party_uuid === mdu.value?.party_uuid)
    return scope && role && invitationExistsInStoreData && partyMatch
  })
  return difference(signatoriesInvited, existingDocumentUsers.value)
})

const remainingTeamMembers = computed<AccountUser[]>(() => {
  // If team_uuid is set on the document, show account users that are on the same team and do not have a documentUser yet
  if (!props[crudContext.value]?.teams?.length || !mau) return
  const teamUuids = props.document?.teams?.map((el) => el.uuid)
  const teamMembers = availableAccountUsers.value?.filter((el) => el?.teams?.some((el) => teamUuids?.includes(el.uuid)))
  const remainingTeamMembers = differenceWith(teamMembers, users.value, (a, b) => a.uuid === b.account_user?.uuid)
  return remainingTeamMembers
})

const readOnlyUsers = computed<DocumentUser[]>(() => {
  const readOnlyUsersToReturn = users.value?.filter((el) => {
    const scope = props.scope === "external" ? !el.account_user?.uuid : !!el.account_user?.uuid
    const role = !el?.roles?.length
    const partyMatch = !!(mau?.value ? true : el.party_uuid === mdu.value?.party_uuid)
    return scope && role && partyMatch
  })
  return readOnlyUsersToReturn
})

// Method to check if a visibleUser has a corresponding documentUser with the signatory role
const isSignatory = (documentUser: DocumentUser) => {
  const filteredUser = users.value?.find((du) => du.uuid && du.uuid === documentUser.uuid && du.email === documentUser.email)
  return filteredUser?.roles?.includes(DocumentUserRoleEnum.signatory)
}

const getParty = (partyUuid: Party["uuid"]) => parties.value?.find((el) => el.uuid === partyUuid)

watch(mdu, (newVal) => {
  if (!newVal) {
    emit("close")
  }
})

const hasSharingPermissions = computed<boolean>(() => {
  if (!props.document) return true
  return !!mdu.value?.permissions?.includes("document_user_create") && mdu.value?.permissions?.includes("document_user_invitation_manage")
})

onMounted(() => setDocumentUsersForSharing([ { ...Object.assign(emptyUser), party_uuid: defaultPartyUuid.value } ]))

const limit = computed(() => planFeatures.value?.["account-users"])
const isLimitExceeded = computed(() => limit.value !== 0 && accountUsage.value?.users > limit.value)

const teamNames = computed<string>(() => {
  const teams = usePage().props.teams as Team[]
  const teamNames = teams?.filter((el) => props.document?.teams?.some((team) => team.uuid === el.uuid))?.map((el) => el.name)
  const teamNamesString = teamNames?.join(", ")
  return teamNamesString
})

</script>

<template>
  <div class="px-6 pb-2">
    <div>
      <div
        class="text-lg"
        data-cy-sel="sharing-modal-header"
      >
        {{ props.scope === "external" ? !mau ? $t('documentSharingModal.manageCollaborators') : $t('documentSharingModal.externalCollaborators') : $t('documentSharingModal.internalAccess') }}
      </div>
    </div>
  </div>
  <div>
    <div
      class="px-6 pt-2 pb-4 space-y-3"
    >
      <div
        v-if="scope === 'internal' && isLimitExceeded"
        class="flex items-center justify-between p-5 space-x-5 rounded bg-exceeded"
      >
        <div class="text-white">
          <h3 class="flex items-center space-x-1">
            <LockClosedIcon class="w-4 h-4" />
            <div class="font-bold">
              {{ $t('accountSettings.billing.planLimitExceeded') }}
            </div>
          </h3>
          <p class="text-sm">
            {{ $t('accountSettings.billing.tooManyAccountUsers', {limit: limit, usage: accountUsage?.users}) }}
          </p>
        </div>
        <div
          v-if="mau?.permissions.includes('account_manage')"
        >
          <Link
            class="inline-block text-white border-0 btn-white whitespace-nowrap bg-white/30 ring-0 hover:text-gray-900"
            :href="route('account-settings.billing.index')"
          >
            {{ $t('accountSettings.billing.manage') }}
          </Link>
          <Link
            :href="route('account-settings.account-users.index')"
            class="block mt-2 text-sm text-center text-white hover:underline"
          >
            {{ $t('accountSettings.billing.manageUsers') }}
          </Link>
        </div>
      </div>
      <template v-else-if="hasSharingPermissions">
        <div
          v-for="user, userIdx in documentUsersForSharing"
          :id="'userShareContainer_' + userIdx"
          :key="userIdx"
          :class="idxOfIsCreatingUser?.length && idxOfIsCreatingUser?.includes(userIdx) ? 'opacity-25' : ''"
        >
          <div class="items-center w-full pt-2 space-y-2 text-sm md:pt-0 md:space-y-0 md:space-x-2 md:flex">
            <div class="grow">
              <h3
                v-if="userIdx === 0"
                class="flex items-center gap-1 mb-3 text-xs font-normal tracking-wider text-gray-500 uppercase"
              >
                {{ props.scope === "external" ? $t('documentSharingModal.inviteCollaborators') : $t('documentSharingModal.addInternalUsers') }}
                <span
                  data-tippy-help
                  :data-tippy-content="props.scope === 'external' ? $t('documentSharingModal.inviteCollaboratorsExplanation') : $t('documentSharingModal.addInternalUsersExplanation')"
                  data-placement="bottom"
                >
                  <QuestionMarkCircleIcon
                    class="w-4 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </h3>
              <div class="relative">
                <UserCombobox
                  :scope="scope"
                  :is-account-party="scope === 'internal'"
                  :deactivated-users="deactivatedUsers"
                  :selected-user="user"
                  align="bottom"
                  class="grow"
                  input-class="pl-12"
                  :disable-email="scope === 'internal'"
                  data-cy-sel="invite-user-combobox"
                  :add-new-label="'userToParty.inviteNew'"
                  @update:selected-user="setDocumentUserForSharing($event, userIdx, defaultPartyUuid)"
                />
                <span
                  v-if="user?.profile_photo_url"
                  class="absolute flex items-center justify-center w-8 h-8 -mt-4 left-2 top-1/2"
                >
                  <img
                    class="w-6 h-6 rounded-full shrink-0"
                    :src="user?.profile_photo_url"
                    alt=""
                  >
                </span>
                <span
                  v-else
                  class="absolute flex items-center justify-center w-8 h-8 -mt-4 left-2 top-1/2"
                >
                  <UserPlusIcon
                    class="w-5 h-5 text-gray-400 shrink-0"
                  />
                </span>
              </div>
            </div>
            <div>
              <h3
                v-if="userIdx === 0"
                class="hidden mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase md:inline-block bottom-full"
              >
                {{ !!mau ? $t('userForm.party') : '&nbsp;' }}
              </h3>
              <div
                class="flex items-center mt-px space-x-2"
              >
                <div
                  v-if="user && isSignatory(user as DocumentUser) && getParty(user.party_uuid) && !!mau"
                  class="inline-flex items-center gap-2 cursor-not-allowed grow"
                >
                  <SignatureIcon class="w-4 h-4 text-indigo-600 shrink-0" />
                  <span class="truncate grow whitespace-nowrap">
                    {{ getParty(user.party_uuid).name || getParty(user.party_uuid).entity_name }}
                  </span>
                  <span
                    data-tippy-help
                    :data-tippy-content="$t('documentSharingModal.signatoryExplanation')"
                    data-placement="bottom"
                  >
                    <QuestionMarkCircleIcon
                      class="w-4 h-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <UserPartySettings
                  v-else-if="!!mau"
                  class="grow"
                  :is-disabled="!!(user?.uuid)"
                  :party-uuid="user?.party_uuid"
                  :hide-label="true"
                  @update:party-uuid="handleUpdatePartyOfNewUser(userIdx, $event)"
                />
                <button
                  v-if="documentUsersForSharing?.length > 1"
                  class="p-0 text-gray-400 btn-plain hover:text-gray-600"
                  @click.prevent="removeNewUser(userIdx)"
                >
                  <TrashIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
          <!--  <div
            v-if="props.scope === 'internal' && user?.email && !user?.account_user?.uuid"
            class="mt-1 text-xs"
          >
            <WarningBox :text="$t('documentSharingModal.userWillBeAddedToAccount')" />
          </div>  -->
        </div>
        <FormInputErrors
          v-if="getUserBackendErrors('0')?.email?.length"
          :errors="getUserBackendErrors('0')?.email"
        />
        <FormInputErrors
          v-if="getUserBackendErrors('0')?.party_uuid?.length"
          :errors="getUserBackendErrors('0')?.party_uuid"
        />
        <FormInputErrors
          v-if="getUserBackendErrors('0')?.roles?.length"
          :errors="getUserBackendErrors('0')?.roles"
        />
        <button
          v-cy="`add-user-from-share-modal-button`"
          type="button"
          class="mt-3 mb-2 btn-plain text-indigo-500 hover:text-indigo-700 border-0 group hover:bg-indigo-100 flex items-center py-1.5 px-2.5"
          @click="addDocumentUserForSharing({ ...Object.assign(emptyUser), party_uuid: defaultPartyUuid })"
        >
          <span class="flex items-center justify-center w-6 h-6 bg-indigo-100 rounded-full group-hover:bg-indigo-200 text-indigo-40 group-hover:text-indigo-600">
            <PlusIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </span>
          <span class="ml-3">{{ props.scope === "internal" ? $t('documentSharingModal.addInternalUser') : !!(mau) ? $t('documentSharingModal.addExternalUser') : $t('documentSharingModal.addUser') }}</span>
        </button>
        <div
          v-if="props.document?.uuid"
          class="mt-3 mb-2"
        >
          <label
            class="flex items-center justify-between gap-2 text-sm font-medium"
            for="message"
          >
            {{ $t('documentSharingModal.message') }}
            <span class="font-normal text-indigo-500">
              {{ $t('documentSharingModal.optional') }}
            </span>
          </label>
          <div
            class="mt-1 grow-textarea"
            :data-replicated-value="message"
          >
            <textarea
              v-model="message"
              :placeholder="$t('documentSharingModal.addMessage') + '…'"
              data-cy-sel="message-textarea"
            />
          </div>
          <div
            v-if="messageError"
            class="flex"
          >
            <FormInputErrors
              :errors="messageError"
              align="right"
            />
          </div>
        </div>
      </template>
      <h3
        v-if="existingDocumentUsers?.length"
        :class="hasSharingPermissions ? 'pt-4' : ''"
        class="flex items-center gap-1.5 mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ props.scope === "external" ? $t('documentSharingModal.invited') : $t('documentSharingModal.usersWithAccess') }}
        <span
          data-tippy-help
          :data-tippy-content="props.scope === 'external' ? $t('documentSharingModal.invitedExplanation') : $t('documentSharingModal.addedExplanation')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
      <div
        v-if="existingDocumentUsers?.length"
        class="text-sm"
      >
        <SharingModalInvitedUser
          v-for="user in existingDocumentUsers"
          :key="user.uuid"
          :user="user"
          :document="props.document"
          @close="$emit('close')"
        />
      </div>

      <h3
        v-if="readOnlyUsers?.length"
        class="flex items-center gap-1.5 mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('documentSharingModal.readOnlyUsers') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('documentSharingModal.readOnlyUsersHint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
      <div
        v-if="readOnlyUsers?.length"
        class="text-sm"
      >
        <SharingModalInvitedUser
          v-for="user in readOnlyUsers"
          :key="user.uuid"
          :user="user"
          :document="props.document"
          @close="$emit('close')"
        />
      </div>
      <div
        v-if="signatoriesWithInvitation?.length"
        class="flex items-center justify-between gap-2 px-3 py-2 mt-2 text-xs text-gray-500 bg-gray-100 rounded-md"
      >
        <div>
          <span class="font-medium">{{ $t('documentSharingModal.signatoriesHaveBeenInvited', signatoriesWithInvitation?.length) }}</span>
          <template v-if="hasSharingPermissions">
            <br>
            {{ $t('documentSharingModal.signatoriesInvitedHint', signatoriesWithInvitation?.length) }}
          </template>
        </div>
        <div class="flex items-center -space-x-1">
          <UserDisplay
            v-if="signatoriesWithInvitation?.length"
            :users="signatoriesWithInvitation"
            size-classes="h-6 w-6"
            ring-class="ring-gray-100"
            :display-email="true"
          />
        </div>
      </div>
      <div
        v-if="remainingTeamMembers?.length && !!mau && props.scope === 'internal'"
        class="flex items-center justify-between gap-2 px-3 py-2 mt-2 text-xs text-gray-500 bg-gray-100 rounded-md"
      >
        <div>
          <span class="font-medium">{{ $t('documentSharingModal.teamMembersHaveAccess', { n: remainingTeamMembers?.length, team: teamNames }) }}</span>
          <template v-if="hasSharingPermissions">
            <br>
            {{ $t('documentSharingModal.teamMembersHaveAccessHint', { team: teamNames }) }}
          </template>
        </div>
        <div class="flex items-center -space-x-1">
          <UserDisplay
            v-if="remainingTeamMembers?.length"
            :users="remainingTeamMembers"
            size-classes="h-6 w-6"
            ring-class="ring-gray-100"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center px-6 py-4 space-x-2 bg-gray-100 sm:justify-end rounded-b-md">
    <button
      v-cy="`sharing-modal-cancel-button`"
      type="button"
      class="hidden sm:inline-block btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
      @click.prevent="$emit('close')"
    >
      {{ $t('common.cancel') }}
    </button>
    <button
      v-if="hasSharingPermissions"
      v-cy="`sharing-modal-send-invitation-button`"
      :disabled="!!(uuidsOfIsLoadingSendInvitation?.length) ||(!validDocumentUsersForSharing)"
      type="button"
      class="inline-flex items-center btn-primary"
      data-cy-sel="sharing-modal-send-invitation-button"
      @click.prevent="handleSendInvitations"
    >
      <div
        v-if="!!(idxOfIsCreatingUser?.length)"
        class="inline-flex items-center gap-x-2"
      >
        <span class="pointer-events-none">
          <SpinLoader class="w-5 h-5 shrink-0" />
        </span>
        <span>{{ $t('documentSharingModal.creatingUsers') }} ({{ idxOfIsCreatingUser?.length }} {{ $t('documentSharingModal.remaining') }})…</span>
      </div>
      <div
        v-else-if="!!(uuidsOfIsLoadingSendInvitation?.length)"
        class="inline-flex items-center gap-x-2"
      >
        <span class="pointer-events-none">
          <SpinLoader class="w-5 h-5 shrink-0" />
        </span>
        <span>{{ $t('documentSharingModal.sendingInvitations') }} ({{ uuidsOfIsLoadingSendInvitation?.length }} {{ $t('documentSharingModal.remaining') }})…</span>
      </div>
      <span
        v-else
        class="inline-flex items-center"
      >
        <EnvelopeIcon
          v-if="document && scope === 'external'"
          class="mr-1.5 h-5 w-5"
        />
        <span>{{ document && scope === "external" ? $t('documentSharingModal.sendInvitations') : $t('documentSharingModal.save') }}</span>
      </span>
    </button>
  </div>
</template>
