<script setup lang="ts">
// external
import { computed } from "vue"

// internal
import { Party } from "~/types"
import { MultiCheckbox } from "~/components"

interface Props {
  accountParties: Party[]
  selectedUuids?: Party["uuid"][]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedUuids: () => [],
  },
)

const emit = defineEmits([ "update:selected-uuids" ])

const options = computed(() => {
  if (!props.accountParties.length) return []

  return props.accountParties.map((party) => {
    return {
      label: party.entity_name,
      value: party.uuid,
    }
  }).sort((a, b) => {
    return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)
  })
})

const localSelectedUuids = computed({
  get: () => props.selectedUuids,
  set: (uuids) => emit("update:selected-uuids", uuids),
})

</script>

<template>
  <div
    class="mt-1 relative space-y-1"
  >
    <MultiCheckbox
      v-model="localSelectedUuids"
      :options="options"
    />
  </div>
</template>

