<script setup lang="ts">
// external
import { Link } from "@inertiajs/vue3"
import { UsersIcon } from "@heroicons/vue/24/outline"

// internal
import { DashboardWidget, EmptyState } from "~/components"

const getFilterParams = (entry) => {
  if (!!entry.party.is_account_party) {
    return `?parties=${entry.party.uuid}`
  } else {
    // replace different types of whitespaces with the "regular" one
    const partyName = entry.party.entity_name.replace(/\s+/g, " ")
    const hash = btoa(partyName)
    return `?external_parties=${hash}`
  }
}
</script>

<template>
  <DashboardWidget
    :title="$t('dashboard.widgets.parties')"
    endpoint="api.data.documents.partiesDocumentCount"
    box-classes="overflow-y-auto max-h-full"
  >
    <template #default="{data}">
      <EmptyState
        v-if="!data || !data.length"
        :hide-button="true"
      >
        <template #icon>
          <UsersIcon
            aria-hidden="true"
          />
        </template>
        <template #default>
          {{ $t('dashboard.partyOverview.emptyTitle') }}
        </template>
        <template #call-to-action>
          <div class="text-sm px-5">
            {{ $t('dashboard.partyOverview.emptyDescription') }}
          </div>
        </template>
      </EmptyState>
      <ul
        v-else
        class="w-full h-full"
      >
        <li
          v-for="entry in data"
          :key="entry.metadata_name"
          class="even:bg-gray-50 hover:bg-indigo-50"
        >
          <Link
            class="flex w-full items-center justify-between text-sm px-3 py-3"
            :href="route('documents.index') + getFilterParams(entry)"
          >
            <span>{{ entry.party.entity_name }}</span>
            <span class="rounded-full text-xs px-2 py-0.5 bg-indigo-500 text-white">{{ entry.count }}</span>
          </Link>
        </li>
      </ul>
    </template>
  </DashboardWidget>
</template>
