<script setup lang="ts">
// external
import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/vue/20/solid"
import { ref, watch } from "vue"

interface Props {
  contractTypes: string[]
  selectedContractTypes?: string[]
}

const props = withDefaults(defineProps<Props>(), {})

const emits = defineEmits([ "update:selected-contract-types" ])

const toggleContractType = (contractType) => {
  const localSelectedContractTypes = [ ... props.selectedContractTypes ]
  const idx = localSelectedContractTypes.indexOf(contractType)

  if (idx !== -1) {
    localSelectedContractTypes.splice(idx, 1)
  } else {
    localSelectedContractTypes.push(contractType)
  }

  emits("update:selected-contract-types", localSelectedContractTypes)
}

const idsOfIsHighlightedContractTypes = ref([])
const idsOfIsRemovedContractTypes = ref([])

watch(() => props.selectedContractTypes, (newValue, oldValue) => {
  // The newly selected contract types should be added to the highlight ids
  // First, find the difference
  const newIds = newValue.filter((id) => !oldValue.includes(id))
  idsOfIsHighlightedContractTypes.value = idsOfIsHighlightedContractTypes.value.concat(newIds)

  const removedIds = oldValue.filter((id) => !newValue.includes(id))
  idsOfIsRemovedContractTypes.value = idsOfIsRemovedContractTypes.value.concat(removedIds)

  // Set timeout to remove the newIds after 2 seconds
  setTimeout(() => {
    idsOfIsHighlightedContractTypes.value = idsOfIsHighlightedContractTypes.value.filter((id) => !newIds.includes(id))
  }, 2000)

  // Set timeout to remove the removedIds after 2 seconds
  setTimeout(() => {
    idsOfIsRemovedContractTypes.value = idsOfIsRemovedContractTypes.value.filter((id) => !removedIds.includes(id))
  }, 2000)
})
</script>
<template>
  <div class="flex flex-wrap gap-1.5">
    <button
      v-for="contractTypeEntry in contractTypes"
      :key="contractTypeEntry"
      type="button"
      class="inline-flex items-center justify-between px-2 py-1 text-xs rounded-full shadow cursor-pointer group/type"
      :class="[
        selectedContractTypes.includes(contractTypeEntry) ? 'bg-green-50' : 'bg-white',
        !idsOfIsHighlightedContractTypes.includes(contractTypeEntry) && selectedContractTypes.includes(contractTypeEntry) ? 'hover:bg-red-100' : '',
        !idsOfIsRemovedContractTypes.includes(contractTypeEntry) && !selectedContractTypes.includes(contractTypeEntry) ? 'hover:bg-green-100' : '',
        idsOfIsRemovedContractTypes.includes(contractTypeEntry) ? 'line-through' : '',
        idsOfIsHighlightedContractTypes.includes(contractTypeEntry) ? 'bellshake' : ''
      ]"
      @click.stop="toggleContractType(contractTypeEntry)"
    >
      <span>{{ $t('contractTypes.' + contractTypeEntry ) }}</span>
      <div class="flex-shrink-0 w-4 h-4 relative ml-1.5 -mr-1">
        <PlusCircleIcon
          aria-hidden="true"
          class="absolute inset-0 w-4 h-4 transition-all duration-150"
          :class="[
            selectedContractTypes.includes(contractTypeEntry) ? 'text-indigo-300 group-hover/type:text-red-500 rotate-45 opacity-0' : 'text-gray-300 group-hover/type:text-green-600',
            !idsOfIsHighlightedContractTypes.includes(contractTypeEntry) && selectedContractTypes.includes(contractTypeEntry) ? 'group-hover/type:opacity-100' : ''
          ]"
        />
        <CheckCircleIcon
          v-if="selectedContractTypes.includes(contractTypeEntry)"
          aria-hidden="true"
          class="absolute inset-0 w-4 h-4 text-green-600 transition-all duration-150"
          :class="[
            selectedContractTypes.includes(contractTypeEntry) ? 'opacity-100' : '',
            !idsOfIsHighlightedContractTypes.includes(contractTypeEntry) && selectedContractTypes.includes(contractTypeEntry) ? 'group-hover/type:opacity-0' : ''
          ]"
        />
      </div>
    </button>
  </div>
</template>
