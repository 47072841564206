<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"

import { ChatBubbleLeftRightIcon } from "@heroicons/vue/24/outline"
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import {
  DiscussionListEntry,
  EmptyState,
  OverlayScrollbar,
  SkeletonLoader,
  TabPills,
} from "~/components"
import { CommentEditor } from "~/editor"
import { useCommentStore, useDocumentStore } from "~/stores"
import { Comment, DiscussionTab } from "~/types"
import { scrollTo } from "~/utils"

const { t } = useI18n()

const commentStore = useCommentStore()
const {
  comments,
  isLoadingComments,
  discussionTabId,
} = storeToRefs(commentStore)
const {
  setDiscussionTabId,
} = commentStore

const documentStore = useDocumentStore()
const {
  currentDocument,
  mdu,
} = storeToRefs(documentStore)

const openDiscussions = computed(() => comments.value.filter((comment) => !comment.is_resolved && !comment.comment_uuid))

const resolvedDiscussions = computed(() => comments.value.filter((comment) => comment.is_resolved && !comment.comment_uuid))

const discussionsToShow = computed(
  () => {
    if (activeDiscussionTab.value.id === DiscussionTab.open) return openDiscussions.value

    return resolvedDiscussions.value
  },
)

const discussionTabs = computed(
  () => {
    return [
      { id: DiscussionTab.open, name: t("discussions.tabs.open"), badge: openDiscussions.value.length },
      { id: DiscussionTab.resolved, name: t("discussions.tabs.resolved"), badge: resolvedDiscussions.value.length, active: !!(resolvedDiscussions.value.length) },
    ]
  },
)

const activeDiscussionTab = computed(
  {
    get: () => discussionTabs.value.find((tab) => tab.id === discussionTabId.value),
    set: (tab) => setDiscussionTabId(tab.id),
  },
)

const canComment = computed(() => mdu.value?.permissions.includes("comment_create"))

const commentEditorRef = ref<InstanceType<typeof CommentEditor> | null>(null)

const commentListScrollContainer = ref(null)

const scrollToNewComment = (createdComment: Comment) => {
  scrollTo(
    commentListScrollContainer.value,
    document.getElementById("comment_" + createdComment.uuid),
  )
}

const isEditorExternal = ref(true)
const handleScopeChange = (isExternal: boolean) => isEditorExternal.value = isExternal
</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="px-6 pt-6 shrink-0">
      <h3
        class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('discussions.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('discussions.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>

    <div
      v-if="isLoadingComments"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>

    <template v-else>
      <div class="px-6 pb-4 border-b border-gray-200 shrink-0">
        <TabPills
          v-model:active-tab="activeDiscussionTab"
          :tabs="discussionTabs"
        />
      </div>

      <OverlayScrollbar
        ref="commentListScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <div
          v-if="discussionsToShow.length"
          class="divide-y divide-gray-200"
        >
          <div
            v-for="discussion in discussionsToShow"
            :key="'open_' + discussion.uuid"
          >
            <DiscussionListEntry
              :document-uuid="currentDocument?.uuid"
              :comment="discussion"
            />
          </div>
        </div>

        <EmptyState
          v-else
          hide-button
          class="my-6"
        >
          <template #icon>
            <ChatBubbleLeftRightIcon
              aria-hidden="true"
            />
          </template>
          {{ $t('discussions.empty') }}
        </EmptyState>
      </OverlayScrollbar>
    </template>

    <div
      v-if="canComment"
      class="border-t border-gray-200 shrink-0"
      :class="isEditorExternal ? 'bg-white' : 'bg-stripes'"
    >
      <div
        class="mx-6 my-5 overflow-hidden border rounded-md"

        :class="isEditorExternal ? 'border-gray-300 focus-within:border-gray-400' : 'border-indigo-300 focus-within:border-indigo-400'"
      >
        <CommentEditor
          ref="commentEditorRef"
          :document-uuid="currentDocument?.uuid"
          class="px-3 py-4 text-sm hyphens-auto focus:outline-none focus:ring-0"
          @saved="scrollToNewComment"
          @update-scope="handleScopeChange"
        />
      </div>
    </div>
  </div>
</template>
