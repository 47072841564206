<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref, watch } from "vue"

import { VariableIcon } from "@heroicons/vue/24/outline"
import { QuestionMarkCircleIcon, BarsArrowDownIcon, BarsArrowUpIcon, Bars3Icon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import {
  ConditionInput,
  DynamicFieldInput,
  DynamicFieldSettingsPopover,
  EmptyState,
  OverlayScrollbar,
  SkeletonLoader,
  TabPills,
} from "~/components"
import { useTippies } from "~/composables"
import { ConditionIcon } from "~/icons"
import { useConditionStore, useDocumentStore, useDynamicFieldStore, usePusherStore, useSharedStore } from "~/stores"
import { CrudContext, DocumentStage, DocumentTab, DynamicContentTab } from "~/types"

interface Props {
  stage: DocumentStage,
}

withDefaults(
  defineProps<Props>(),
  {
  },
)

const { t } = useI18n()

const dynamicFieldStore = useDynamicFieldStore()
const {
  dynamicFields,
  pendingDynamicFields,
  isLoadingDynamicFields,
  dynamicFieldUuidBeingLoaded,
  dynamicFieldsSorted,
  sortDirection,
} = storeToRefs(dynamicFieldStore)
const { toggleSortDirection } = dynamicFieldStore

const sharedStore = useSharedStore()
const { setSidebarScrollContainer } = sharedStore

const conditionStore = useConditionStore()
const { conditions } = storeToRefs(conditionStore)

const documentStore = useDocumentStore()
const {
  mau,
  mdu,
  currentDocument,
  isLockedDocument,
  activeTabKey,
} = storeToRefs(documentStore)
const { setActiveDynamicContentTab } = documentStore

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const emit = defineEmits( [
  "focus",
  "blur",
  "update:dynamic-field-submitting",
  "update:last-saved",
])

const dynamicContentScrollContainer = ref()

const dynamicContentTabs = computed(() => {
  return     [
    { id: DynamicContentTab.dynamicFields, name: t("dynamicFields.tabs." + DynamicContentTab.dynamicFields), badge: dynamicFields.value?.length },
    { id: DynamicContentTab.conditions, name: t("dynamicFields.tabs." + DynamicContentTab.conditions), badge: conditions.value?.length },
  ]
})

const { setUpAddDynamicFieldTippies } = useTippies(CrudContext.document)

watch(activeTabKey, (newVal) => {
  if (newVal === DocumentTab.dynamicContent) {
    setSidebarScrollContainer(dynamicContentScrollContainer.value)
  }
})

onMounted(() => {
  setUpAddDynamicFieldTippies()
  setSidebarScrollContainer(dynamicContentScrollContainer.value)
  setActiveDynamicContentTab(dynamicContentTabs.value[0]?.id)
})

const activeDynamicContentTab = computed(
  {
    get: () => {
      if (documentStore.activeDynamicContentTab) return dynamicContentTabs.value?.find((tab) => tab.id === documentStore.activeDynamicContentTab)
      const tabToReturn = dynamicContentTabs.value[0]
      return tabToReturn
    },
    set: (val) => {
      setActiveDynamicContentTab(val?.id || null)
    },
  },
)

// Helper to emit pusher relevant events
const handleFocus = (data: string) => emit("focus", data)
const handleBlur = (data: string) => emit("blur", data)
</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 shrink-0">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('dynamicFields.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('dynamicFields.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>

      <button
        type="button"
        class="px-1 py-0.5"
        :class="!!sortDirection ? 'btn-primary' : 'btn-plain hover:bg-gray-100'"
        @click="toggleSortDirection"
      >
        <BarsArrowUpIcon
          v-if="sortDirection === 'desc'"
          aria-hidden="true"
          class="w-4 h-4"
        />
        <BarsArrowDownIcon
          v-else-if="sortDirection === 'asc'"
          aria-hidden="true"
          class="w-4 h-4"
        />
        <Bars3Icon
          v-else
          aria-hidden="true"
          class="w-4 h-4"
        />
      </button>
    </div>

    <div
      v-if="isLoadingDynamicFields"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>

    <template v-else>
      <div
        v-if="!!mau"
        class="px-6 pb-4 border-b shrink-0 border-b-gray-200"
      >
        <TabPills
          v-model:active-tab="activeDynamicContentTab"
          :tabs="dynamicContentTabs"
        />
      </div>

      <OverlayScrollbar
        ref="dynamicContentScrollContainer"
        tag="div"
        class="flex-1 max-h-full overflow-y-auto"
      >
        <template v-if="activeDynamicContentTab?.id === DynamicContentTab.dynamicFields">
          <div
            v-if="dynamicFieldsSorted?.length"
            class="border-b border-gray-200 divide-y divide-y-gray-200"
          >
            <template
              v-for="(dynamicField) in dynamicFieldsSorted"
              :key="dynamicField.uuid"
            >
              <DynamicFieldInput
                class="pt-2 pb-5"
                :dynamic-field="dynamicField"
                :loading="dynamicFieldUuidBeingLoaded === dynamicField.uuid"
                :is-loading-dynamic-fields="isLoadingDynamicFields"
                :pending-dynamic-fields="pendingDynamicFields"
                :disabled="checkIfDisabledByPusher('dynamic-field_input_' + dynamicField.ref_uuid) || checkIfDisabledByPusher('dynamic-field_popover_' + dynamicField.ref_uuid)"
                :is-only-display-value="isLockedDocument || currentDocument?.stage === DocumentStage.signed || !mdu?.permissions?.includes('dynamic_field_update')"
                :is-popover="false"
                :is-external="mau ? false : true"
                @focus="handleFocus"
                @blur="handleBlur"
              />
            </template>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <VariableIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('dynamicFields.noDynamicFields') }}
          </EmptyState>
        </template>
        <template v-else-if="activeDynamicContentTab?.id === DynamicContentTab.conditions">
          <div
            v-if="conditions?.length"
            class="border-b divide-y divide-gray-200 border-b-gray-200"
          >
            <ConditionInput
              v-for="(condition) in conditions"
              :key="condition.uuid"
              :condition="condition"
              :dynamic-fields="dynamicFields"
              :is-loading-dynamic-fields="isLoadingDynamicFields"
            />
          </div>

          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <ConditionIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('dynamicFields.noConditions') }}
          </EmptyState>
        </template>
      </OverlayScrollbar>
    </template>
  </div>
  <div
    v-if="!isLockedDocument"
    id="dynamicFieldPopovers"
    class="hidden"
  >
    <DynamicFieldSettingsPopover
      :document="currentDocument"
    />
    <DynamicFieldSettingsPopover
      v-for="dynamicField in dynamicFields.filter(Boolean)"
      :key="dynamicField.uuid"
      :dynamic-field="dynamicField"
      :document="currentDocument"
    />
  </div>
</template>
