<script setup lang="ts">
// external
import { ref, computed, onMounted, watch } from "vue"
import { difference, filter, sortBy } from "lodash-es"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"

// internal
import { SignatureSignatoryDisplay, SkeletonLoader, TabPills, OverlayScrollbar, EmptyState, SignatureOrder } from "~/components"
import { SignatureIcon } from "~/icons"
import { useDocumentStore, useSharedStore, useSignatureStore, useUserStore } from "~/stores"
import { Document, Party, Signature, SignatureBlock, DocumentUser, SigningPhase, DocumentRevision, SignatureTab, DocumentUserRoleEnum, SignatureSecurityLevel, CrudContext, DocumentTab, SignatureBlockStyle } from "~/types"

interface Props {
  document: Document
  signingPhase?: SigningPhase
  signingPhases?: SigningPhase[]
  isLoadingRefreshSigningPhase?: boolean
  isLoadingSigningPhases?: boolean
  isLoadingParties?: boolean
  isLoadingDocumentUsers?: boolean
  parties?: Party[]
  revisions?: DocumentRevision[]
  isLoadingRevisions?: boolean
  signatureSecurityLevel?: SignatureSecurityLevel
  sequentialSigning?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    parties: () => [],
    signingPhase: null,
    signingPhases: () => [],
    isLoadingRefreshSigningPhase: false,
    isLoadingSigningPhases: false,
    isLoadingParties: false,
    isLoadingDocumentUsers: false,
    revisions: () => [],
    isLoadingRevisions: false,
    signatureSecurityLevel: null,
    sequentialSigning: null,
  },
)

const emit = defineEmits( [ "trigger-diff", "update:sequential-signing" ])

const { t } = useI18n()

const documentStore = useDocumentStore()
const { isLockedDocument, mau, mdu, activeTabKey } = storeToRefs(documentStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)
const { setSidebarScrollContainer } = sharedStore

const signatureStore = useSignatureStore()
const { signatures, isLoadingSignatures, signatureBlocks, isLoadingSignatureBlocks } = storeToRefs(signatureStore)
const { checkSignature } = signatureStore

const { users } = storeToRefs(useUserStore())

const inactiveSigningPhases = computed(() => {
  return props.signingPhases.filter((el) => !el.is_active)
})

const signaturesOfActiveSigningPhase = computed<Signature[]>(() => {
  if (!props.signingPhase) return null
  const filteredSignatures = signatures.value.filter((el) => el.signing_phase_uuid === props.signingPhase.uuid)
  return filteredSignatures
})

const nonDeletedSignatureBlocks = computed<SignatureBlock[]>(() => {
  const filteredSignatureBlocks = signatureBlocks.value.filter((el) => !el.deleted_at)
  return filteredSignatureBlocks
})

const signaturesOfInactiveSigningPhases = computed<Signature[]>(() => {
  const filteredSignatures = difference(
    signatures.value,
    signaturesOfActiveSigningPhase.value,
  )
  return filteredSignatures
})

const getSignaturesOfSigningPhase = (signingPhaseUuid: SigningPhase["uuid"], signatureBlockUuid: SignatureBlock["uuid"] = null): Signature[] | null => {
  let filteredSignatures = signatures.value.filter((el) => el.signing_phase_uuid === signingPhaseUuid)
  if (signatureBlockUuid) filteredSignatures = filteredSignatures.filter((el) => el.signature_block_uuid === signatureBlockUuid)
  return filteredSignatures
}

const getPartyByUuid = (uuid: Party["uuid"]) => props.parties?.find((el) => el.uuid === uuid)

const getSignatories = (partyUuid: Party["uuid"]): DocumentUser[] | null => {
  const filteredUsers = users.value?.filter(
    (el) => el.party_uuid === partyUuid && el.roles?.includes(DocumentUserRoleEnum.signatory),
  )
  const orderedUsers = sortBy(filteredUsers, "signing_order")
  return orderedUsers
}

const signatories = computed<DocumentUser[]>(() => {
  const signatories = sortBy(users.value?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory)), "signing_order")
  return signatories
})

const signatureTabs = computed(() => {
  return     [
    { id: SignatureTab.current, name: t("signatures.tabs.current"), badge: signatories.value?.length, active: true },
    { id: SignatureTab.archived, name: t("signatures.tabs.archived"), badge: signaturesOfInactiveSigningPhases.value?.length, active: !!(signaturesOfInactiveSigningPhases.value?.length) },
  ]
})

const activeSignatureTab = ref(signatureTabs.value[0])

const getDocumentUserByUuid = (uuid: DocumentUser["uuid"]) => {
  return users.value?.find((el) => el.uuid === uuid)
}

const getRevision = (revisionUuid: DocumentRevision["uuid"]) => {
  return props.revisions?.find(
    (el) => el.uuid === revisionUuid,
  )
}

const getSignatureBlocksForInactiveSigningPhase = (signingPhaseUuid: SigningPhase["uuid"]): SignatureBlock[] | null => {
  const filteredSignatures = getSignaturesOfSigningPhase(signingPhaseUuid)
  const includedSignatureBlocks = filteredSignatures.map((el) => el.signature_block_uuid)
  const filteredSignatureBlocks = filter(signatureBlocks.value, ({ uuid }) => includedSignatureBlocks.includes(uuid))
  return filteredSignatureBlocks
}

// Trigger function for diff view
const triggerDiff = (revisionUuid: DocumentRevision["uuid"]) => {
  if (!mau.value) return
  emit("trigger-diff", { revisionUuid: revisionUuid })
}

const signatureSettingsScrollContainer = ref()

watch(activeTabKey, (newVal) => {
  if (newVal === DocumentTab.signatures) {
    setSidebarScrollContainer(signatureSettingsScrollContainer.value)
  }
})

onMounted(() => {
  setSidebarScrollContainer(signatureSettingsScrollContainer.value)
})

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <h3 class="flex items-center gap-1 px-6 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
      {{ $t('signatures.title') }}
    </h3>
    <div
      v-if="isLoadingDocumentUsers || isLoadingParties || isLoadingSignatureBlocks || isLoadingSignatures || isLoadingSigningPhases || isLoadingRefreshSigningPhase"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>

    <template v-else>
      <div class="px-6 pb-4 border-b border-gray-200 shrink-0">
        <TabPills
          v-model:active-tab="activeSignatureTab"
          :tabs="signatureTabs"
        />
      </div>
      <OverlayScrollbar
        ref="signatureSettingsScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <template v-if="activeSignatureTab.id === SignatureTab.current">
          <template v-if="!signingPhase && mau">
            <SignatureOrder
              :is-editable="mdu?.permissions?.includes('document_user_update_signing_order') || crudContext === CrudContext.template"
              :document="document"
              :document-users="users"
              :is-loading-document-users="isLoadingDocumentUsers"
              :signature-security-level="signatureSecurityLevel"
              :sequential-signing="sequentialSigning"
              :parties="parties"
              :is-loading-parties="isLoadingParties"
              @update:sequential-signing="emit('update:sequential-signing', $event)"
            />
          </template>
          <div
            v-else-if="signatureBlocks"
            class="relative my-4 space-y-4"
          >
            <div
              v-for="signatureBlock in nonDeletedSignatureBlocks"
              :id="`sidebarSignatureBlock_${ signatureBlock.ref_uuid }`"
              :key="signatureBlock.ref_uuid"
              :data-party-button="getPartyByUuid(signatureBlock.party_uuid)?.ref_uuid"
              :data-ref-uuid="signatureBlock.ref_uuid"
              class="mx-auto shadow-md signature-block"
              :class="signatureBlock.style === SignatureBlockStyle.minimal ? 'signature-block-minimal' : 'signature-block-regular'"
            >
              <template v-if="getSignatories(signatureBlock.party_uuid)?.length">
                <SignatureSignatoryDisplay
                  v-for="signatory in getSignatories(signatureBlock.party_uuid)"
                  :key="signatory.uuid"
                  :signatory="signatory"
                  :signature="checkSignature(signatory.uuid, signatureBlock.uuid)"
                  :party-uuid="signatory.party_uuid"
                  :is-editor-node="isLockedDocument ? true : false"
                  :signature-block="signatureBlock"
                />
              </template>
              <SignatureSignatoryDisplay
                v-else
                :party-uuid="signatureBlock.party_uuid"
                :signature-block="signatureBlock"
              />
            </div>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <SignatureIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('signatures.noSignatoryFound') }}
          </EmptyState>
        </template>
        <template v-else-if="activeSignatureTab.id === SignatureTab.archived">
          <div
            v-if="inactiveSigningPhases?.length && !isLoadingSigningPhases"
            class="mt-4 opacity-50"
          >
            <div
              class="px-6 py-1 text-xs font-medium text-white uppercase bg-gray-500 cursor-pointer"
            >
              <span>
                {{ $t('signatures.archivedSignatures') }} ({{ signaturesOfInactiveSigningPhases?.length }})
              </span>
            </div>

            <div class="py-6 space-y-4">
              <template
                v-for="inactiveSigningPhase in inactiveSigningPhases.slice().reverse()"
                :key="inactiveSigningPhase.uuid"
              >
                <div
                  v-if="getSignatureBlocksForInactiveSigningPhase(inactiveSigningPhase.uuid)?.length"
                  class="space-y-2 text-center"
                >
                  <div
                    class="bg-gray-900 inline-block mx-auto rounded-full px-1.5 py-0.5 text-xs font-medium text-white relative"
                    :class="!!mau ? 'cursor-pointer' : ''"
                    @click.prevent="triggerDiff(inactiveSigningPhase.document_revision_uuid)"
                  >
                    <template v-if="getRevision(inactiveSigningPhase.document_revision_uuid)?.seal">
                      <span class="font-normal">
                        {{ $t('signatures.revision') }}:
                      </span>
                      #{{ getRevision(inactiveSigningPhase.document_revision_uuid)?.seal }}
                    </template>
                  </div>

                  <div
                    v-for="signatureBlock in getSignatureBlocksForInactiveSigningPhase(inactiveSigningPhase.uuid)"
                    :id="`sidebarSignatureBlock_${ signatureBlock.ref_uuid }`"
                    :key="signatureBlock.uuid"
                    :data-party-button="getPartyByUuid(signatureBlock.party_uuid)?.ref_uuid"
                    :data-ref-uuid="signatureBlock.ref_uuid"
                    class="mx-auto shadow-md signature-block"
                  >
                    <template v-if="getSignatories(signatureBlock.party_uuid)?.length">
                      <SignatureSignatoryDisplay
                        v-for="signature in getSignaturesOfSigningPhase(inactiveSigningPhase.uuid, signatureBlock.uuid)"
                        :key="signature.uuid"
                        :signatory="getDocumentUserByUuid(signature.created_by_document_user_uuid)"
                        :signature="signature"
                        :party-uuid="signatureBlock.party_uuid"
                        :is-editor-node="isLockedDocument ? true : false"
                        :signature-block="signatureBlock"
                      />
                    </template>
                    <SignatureSignatoryDisplay
                      v-else
                      :party-uuid="signatureBlock.party_uuid"
                      :signature-block="signatureBlock"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <SignatureIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('signatures.noArchivedFound') }}
          </EmptyState>
        </template>
      </OverlayScrollbar>
    </template>
  </div>
</template>
