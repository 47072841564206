<script setup lang="ts">
// external
import { computed, onBeforeUnmount, onMounted, provide, reactive, ref, toRaw, watch } from "vue"

import { usePage } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"

import InvisibleCharacters from "@tiptap-pro/extension-invisible-characters"
import UniqueID from "@tiptap-pro/extension-unique-id"
//import UniqueID from "@tiptap-pro/extension-unique-id"
import { Placeholder } from "~/editor/extensions"
import { Editor } from "@tiptap/core"
import Bold from "@tiptap/extension-bold"
import Color from "@tiptap/extension-color"
import Document from "@tiptap/extension-document"
import Dropcursor from "@tiptap/extension-dropcursor"
import Gapcursor from "@tiptap/extension-gapcursor"
import HardBreak from "@tiptap/extension-hard-break"
import Heading from "@tiptap/extension-heading"
import History from "@tiptap/extension-history"
import Italic from "@tiptap/extension-italic"
import Link from "@tiptap/extension-link"
import Paragraph from "@tiptap/extension-paragraph"
import Strike from "@tiptap/extension-strike"
import Subscript from "@tiptap/extension-subscript"
import Superscript from "@tiptap/extension-superscript"
import Text from "@tiptap/extension-text"
import TextAlign from "@tiptap/extension-text-align"
import TextStyle from "@tiptap/extension-text-style"
import Typography from "@tiptap/extension-typography"
import Underline from "@tiptap/extension-underline"
import { TextSelection } from "@tiptap/pm/state"
import { EditorContent, JSONContent, KeyboardShortcutCommand, Range, useEditor } from "@tiptap/vue-3"

// internal
import { useEditorStore, useImageStore, useNotificationStore } from "~/stores"
import { CrudContext, DynamicField, JuneEvents, Party } from "~/types"
import { IS_CYPRESS_ENV, checkIfSvgHasLinearGradient, getUuid, isMac, trackEventInJune } from "~/utils"

import DeleteConfirmationModal from "./DeleteConfirmationModal.vue"
import NumberingMenu from "./NumberingMenu.vue"
import {
  AiText,
  ColumnExtension,
  CommandMenu,
  DecorationExtension,
  DynamicFields,
  Fineprint,
  FontSize,
  HighlightCommentsAndProposals,
  HighlightConditions,
  List,
  Multiline,
  PageBreak,
  Parties,
  ResizableImage,
  SearchAndReplace,
  SectionRef,
  SignatureBlock,
  SignatureContainer,
  TableExtension,
  TableMenu,
  Toc,
  getKeyboardEventListenerExtension,
  FormatPainter,
  TrackChangeNew,
} from "./extensions"
import { migrateDocJSON } from "./extensions/list/prosemirror-flat-list"
import { ImageAttrs } from "./extensions/resizableImage/resizableImage"
import { EditorMenuBar } from "./menus"
import { wordFileTypeRegex } from "./wordImport"

interface Props {
  editorContent?: JSONContent;
  editorContentUpdating?: boolean;
  documentCommenting: boolean;
  dynamicFields: DynamicField[];
  parties: Party[];
  documentType: "document" | "template";
  editing: boolean;
  documentEditorSession?: Record<string, unknown>;
  showToolbar: boolean;
  editorContext: "proposal" | "document" | "template";
  autofocusEditor?: boolean
  id?: string
  isOrderRepresentationHost?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    editorContent: null,
    showToolbar: false,
    documentCommenting: true,
    editing: false,
    documentType: "document",
    editorContext: "document",
    documentEditorSession: null,
    dynamicFields: () => [],
    parties: () => [],
    autofocusEditor: false,
    elementId: "",
    isOrderRepresentationHost: false,
  },
)

const pageProps = computed(() => usePage().props)

const { t } = useI18n()

interface Emits {
  (e: "update:editor-content", value: JSONContent): void;
  (e: "update:editor-content-updating", value: boolean): void;
  (e: "mounted", editor: Editor): void;
  (e: "editor-created", editor: Editor): void;
  (e: "editor-focus", id: string): void;
  (e: "editor-blur", id: string): void;
}

const emit = defineEmits<Emits>()

// Make v-model work on editorValue variable (from parent)
const editorValue = computed(
  {
    get: () => {
      if (!props.editorContent) return
      const content = migrateDocJSON(props.editorContent) || props.editorContent

      if (content.content.length === 0) {
        content.content = [
          {
            type: "paragraph",
          },
        ]
      }

      return content
    },
    set: (value) => {
      emit("update:editor-content", value)
    },
  },
)

// Make v-model work on editorContentUpdating variable (from parent)
const editorContentUpdating = computed(
  {
    get: () => props.editorContentUpdating,
    set: (value) => emit("update:editor-content-updating", value),
  },
)

// See if comments are deactivated
const documentCommenting = ref(props.documentCommenting)

const editorStore = useEditorStore()
const { setDroppedFile, setIsDocxImportModalOpen } = editorStore

const imageStore = useImageStore()
const { createImage, getImagesIfNeeded } = imageStore

const notificationStore = useNotificationStore()
const { notify } = notificationStore

const isDeleteConfirmationModalOpen = ref<boolean>(false)

const setIsDeleteConfirmationModalOpen = (isOpen: boolean) => isDeleteConfirmationModalOpen.value = isOpen

const imageUploadProgressData = reactive<Record<string, number>>({})

const updateImageUploadProgress = (intermediateUuid: string, progress: number) => {
  if (!intermediateUuid || typeof progress !== "number") return

  imageUploadProgressData[intermediateUuid] = progress
}

const handleImageUpload = async (file: File) => {
  if (file.type === "image/svg+xml") {
    const svgText = await file.text()

    if (checkIfSvgHasLinearGradient(svgText)) {
      notify(
        {
          title: t("common.errorOccured"),
          message: t("editor.errors.svgFilesWithLinearGradientsNotSupported"),
          type: "error",
        },
      )

      return
    }
  }

  const intermediateUuid = getUuid()

  const fileReader = new FileReader()

  fileReader.addEventListener(
    "load",
    () => {
      const dataUrl = fileReader.result as string

      trackEventInJune(JuneEvents.EDITOR_IMAGE_INSERTED)

      editor.value.commands
        .setImage(
          {
            src: "",
            intermediateUuid,
            uuid: intermediateUuid,
            dataUrl,
          },
        )

      uploadImageFile(file, intermediateUuid)
    },
  )

  fileReader.readAsDataURL(file)
}

const uploadImageFile = async (
  file: File,
  intermediateUuid: string,
) => {
  let createImageRes: Awaited<ReturnType<typeof createImage>>

  let vaporRes: Awaited<ReturnType<typeof Vapor.store<{ uuid: string }>>>

  try {
    imageStore.addUploadingImage(intermediateUuid)

    vaporRes = await Vapor.store(
      file, {
        progress: (progress) => {
          updateImageUploadProgress(intermediateUuid, Math.round(progress * 100) || 100)
        },
      },
    )

    createImageRes = await createImage(vaporRes.uuid)
  } catch (err) {
    updateImageUploadProgress(intermediateUuid, 0)
  } finally {
    imageStore.removeUploadingImage(intermediateUuid)

    if (createImageRes?.ref_uuid && vaporRes?.uuid) {
      editor.value.commands
        .updateImageAttrs(
          intermediateUuid,
          {
            dataUrl: "",
            intermediateUuid: null,
            uuid: vaporRes.uuid,
            refUuid: createImageRes.ref_uuid,
          },
        )
    } else {
      notify(
        {
          title: t("common.errorOccured"),
          message: t("editor.errors.errorUploadingImage"),
          type: "error",
        },
      )

      editor.value.commands
        .removeImage(intermediateUuid)
    }
  }
}

// Build extensions object
interface GetExtensionProps {
  commands: Record<string, KeyboardShortcutCommand>
}

const getAdjustedDocNode = () => {
  const extendedDocumentNode = Document.extend(
    {
      addStorage () {
        return {
          editorContext: props.editorContext,
        }
      },

      content: "(block|signatureContainer|pageBreak|columnBlock)+",
    },
  )

  if (props.editorContext !== "proposal") return extendedDocumentNode.configure()

  return extendedDocumentNode
    .extend(
      {
        content: props.editorContent?.content?.[0]?.type || "paragraph",
      },
    )
    .configure()
}

const shortcut = `${ isMac ? "⌘" : "Ctrl" } + P`

const getExtensions = (
  {
    commands = {},
  }: GetExtensionProps,
) => {
  const editorExtensions = [
    getAdjustedDocNode(),

    Bold.configure(),
    Heading.configure(
      {
        levels: [ 1, 2, 3 ],
      },
    ),
    Gapcursor.configure(),
    Dropcursor.configure(
      {
        color: "rgba(49, 46, 256, 1)",
        width: 2,
      },
    ),
    HardBreak.configure(),
    History.configure(),
    PageBreak.configure(),
    Italic.configure(),
    DynamicFields.configure(
      {
        editorContext: props.editorContext,
      },
    ),
    SectionRef.configure(
      {
        editorContext: props.editorContext,
      },
    ),
    Paragraph.extend(
      {
        addAttributes () {
          return {
            ...this.parent?.(),
          }
        },
      },
    ),
    Multiline.configure(),
    FormatPainter.configure(),
    Parties.configure(
      {
        editorContext: props.editorContext,
      },
    ),
    SignatureBlock.configure(
      {
        editorContext: props.editorContext,
      },
    ),
    SignatureContainer.configure(),
    Strike.configure(),
    TableExtension.configure(),
    TableMenu.configure(),
    Text.configure(),
    TextAlign.configure(
      {
        types: [
          "paragraph",
          "heading",
          "list",
          "fineprint",
        ],
      },
    ),
    Typography.configure(),
    Underline.configure(),
    UniqueID.configure(
      {
        attributeName: "uuid",
        types: [
          "heading",
          "paragraph",
          "list",
          "table",
          "column",
          "columnBlock",
          "fineprint",
        ],
        generateID: getUuid,
      },
    ),
    SearchAndReplace.configure(),
    ...(
      Object.keys(commands).length
        ? [ getKeyboardEventListenerExtension(commands).configure() ]
        : []
    ),
    ResizableImage.configure(
      {
        allowBase64: true,
        inline: false,
        upload: uploadImageFile,
      },
    ),
    DecorationExtension.configure(),
    Subscript.configure(),
    Superscript.configure(),

    // text style extensions
    TextStyle.configure(),
    Color.configure(),
    FontSize.configure(),

    ColumnExtension.configure(),
    ...(
      pageProps.value.mau
        ? [
          Placeholder.configure(
            {
              placeholder: ({ editor }) => {
                if (editor.isEditable) {
                  if (isEditorEmpty.value) {
                    return t("editor.emptyPlaceholder", { shortcut }) + "…"
                  }

                  return t("editor.placeholder", { shortcut }) + "…"
                }

                if (!editor.isEditable && isEditorEmpty.value) return t("editor.turnOnToWrite") + "…"

                return ""
              },
              includeChildren: true,
              showOnlyWhenEditable: false,
            },
          ),
          CommandMenu.configure(),
          InvisibleCharacters.configure(
            {
              visible: false,
            },
          ),
          AiText.configure(
            {
              getAiText: async (context: string) => {
                const res = await editorStore.autocompleteText(context)
                return res
              },
              setIsLoadingAiAutocompletion (val) {
                editorStore.setIsLoadingAiAutocompletion(val)
              },
            },
          ),
        ] : []
    ),
    Toc.configure(),
    List.configure({
      isOrderRepresentationHost: props.isOrderRepresentationHost,
    }),
    Fineprint.configure(),
    TrackChangeNew.configure(
      {
        enabled: props.editorContext === "proposal" ? true : false,
      },
    ),
    Link
      .extend(
        {
          parseHTML () {
            return [
              {
                tag: "a[href]:not([href *= \"javascript:\" i]):not([data-section-ref])",
              },
            ]
          },
        },
      )
      .configure(
        {
          autolink: true,
          openOnClick: false,
          linkOnPaste: true,
          HTMLAttributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      ),
  ]

  // Load comment extension if needed
  if (documentCommenting.value) {
    editorExtensions.push(
      HighlightCommentsAndProposals.configure(
        {
          setIsDeleteConfirmationModalOpen,
        },
      ),
    )
  }

  // Mark smart conditions only when needed
  if (props.editorContext !== "proposal") {
    editorExtensions.push(
      HighlightConditions.configure(
        {
          editorContext: props.editorContext as CrudContext,
          setIsDeleteConfirmationModalOpen,
        },
      ),
    )
  }

  return editorExtensions
}

const editorMenubarRef = ref<InstanceType<typeof EditorMenuBar> | null>()

const isEditable = ref<boolean>(props.editing)

provide("isEditable", isEditable)

const textSelectionRange = ref<Range>(null)

const onTransaction = (e: Editor) => {
  const docContent = e.state.doc.content?.toJSON()

  isEditorEmpty.value = e.state?.doc?.textContent?.length === 0
    && e.state.doc.childCount <= 1
    && docContent?.[0].type === "paragraph"
    && !docContent?.[0].content?.length

  if (!(e.state.selection instanceof TextSelection)) return

  const { from, to } = e.state.selection

  textSelectionRange.value = { from, to }
}

provide("textSelectionRange", textSelectionRange)

const isEditorEmpty = ref<boolean>(false)

const getFiles = (event: ClipboardEvent | DragEvent): FileList => {
  if (event instanceof ClipboardEvent) {
    return event.clipboardData?.files
  } else {
    return event.dataTransfer?.files
  }
}
// Initiate editor
const editor = useEditor(
  {

    onTransaction (tp) {
      const { transaction, editor } = tp

      editorStore.processSignatureOnTransaction(editor)
      if (props.editorContext !== "proposal") editorStore.processIsDirtyOnTransaction(transaction)
      onTransaction(editor)

    },

    extensions: getExtensions({
      commands: {
        "Mod-f": () => {
          editor.value?.commands?.hideCommandMenu()

          editorMenubarRef.value?.showSearchPopover()

          return true
        },
        "Mod-p": () => editor.value.commands.showCommandMenu(false),
        "Escape": () => editor.value.commands.hideCommandMenu?.(),
      },
    }),

    editable: props.editing,

    editorProps: {
      attributes: {
        class: "focus:outline-none",
        ...(
          IS_CYPRESS_ENV
            ? { spellcheck: "false" }
            : {}
        ),
      },
      transformPastedHTML (html) {
        return html.normalize("NFC")
      },
      transformPastedText (text) {
        return text.normalize("NFC")
      },
      ...(
        props.editorContext !== "proposal"
          ? {
            handleDOMEvents: {
              paste: (_, event) => filePasteDropHandler(event),
              drop: (_, event) => filePasteDropHandler(event),
            },
          }
          : {}
      ),
    },

    content: editorValue.value,

    onUpdate ({ editor }) {
      editorValue.value = editor.getJSON()
    },

    onCreate ({ editor: e }) {
      emit("editor-created", e)

      type ImageDetails = Pick<ImageAttrs, "refUuid" | "uuid">

      const imageDetails: ImageDetails[] = []

      e.state.doc.descendants(
        (node) => {
          if (node.type.name !== "resizableImage") return

          const { refUuid, uuid, dataUrl } = node.attrs

          if (dataUrl?.startsWith("data:image/svg+xml")) return

          imageDetails.push({ refUuid, uuid })
        },
      )

      getImagesIfNeeded(imageDetails)

      setTimeout(() => onTransaction(e))
    },

    onFocus () {
      if (!props.elementId) return
      emit("editor-focus", props.elementId)
    },

    onBlur () {
      if (!props.elementId) return
      emit("editor-blur", props.elementId)
    },

    autofocus: props.autofocusEditor,
  },
)

watch(
  () => props.editing,
  () => {
    // Prevent auto-save, as this also triggers a change in ProseMirrorData
    editorContentUpdating.value = true

    if (editor.value) {
      editor.value.setEditable(props.editing)
    } else {
      console.warn("Editor not set properly!")
    }

    isEditable.value = props.editing
  },
)

const editorContentContainer = ref<HTMLElement | null>(null)

onMounted(
  () => {
    emit("mounted", toRaw(editor.value))

    editorStore.setNumberingTippy()

    if (!editor.value) return

    if (props.editorContext !== "proposal") {
      editorStore.setEditorContext(props.editorContext)
      editorStore.setEditor(editor.value)
    }

    if (!IS_CYPRESS_ENV) return

    (editorContentContainer.value as any).editor = editor.value
  },
)

onBeforeUnmount(
  () => {
    if (editor.value) {
      editor.value.destroy()

      if (props.editorContext === "proposal") return

      editorStore.setEditor(null)
      editorStore.setEditorContext(null)
    } else {
      console.warn("Editor not set properly!")
    }
  },
)

const filePasteDropHandler = (event: ClipboardEvent | DragEvent) => {
  const files = getFiles(event)
  if (!files.length) return false
  if (checkIfWordFileWasDropped(files)) {
    event.preventDefault()
    handleWordFileDrop(Array.from(files))
  }
}

const checkIfWordFileWasDropped = (files: FileList) => {
  if (!wordFileTypeRegex.test(files[0].type)) return
  if (files[0].name.endsWith(".doc")) return
  return true
}

const handleWordFileDrop = async (files: File[]) => {
  if (files[0].name.endsWith(".doc")) {
    notify(
      {
        title: t("editor.onlyDocxSupported"),
        type: "error",
      },
    )
    return
  }
  setDroppedFile(files[0])
  setIsDocxImportModalOpen(true)
}

const listener = ref<(e: Event) => void>(null)

onMounted(
  () => {
    listener.value = (e: Event) => {
      setTimeout(
        () => {
          const contentEl = editorContentContainer.value
          const commandMenuEl = document.querySelector("#commandMenu")

          const isTargetContainedByValidEls = [ contentEl, commandMenuEl ].filter(Boolean).some((i) => i.contains(e.target as Node))

          if (e.target === contentEl || isTargetContainedByValidEls) return

          if (!editor.value) return

          editor.value.commands.hideCommandMenu?.()
        },
      )
    }

    document.addEventListener("click", listener.value)
    document.addEventListener("touchstart", listener.value)
  },
)

onBeforeUnmount(
  () => {
    document.removeEventListener("click", listener.value)
    document.removeEventListener("touchstart", listener.value)
  },
)
</script>

<template>
  <template
    v-if="editor && pageProps.mau"
  >
    <Teleport
      v-if="isEditable"
      to="#menuBarHolder"
    >
      <Transition
        name="menubar-transition"
      >
        <EditorMenuBar
          v-show="editing && showToolbar && (documentType === 'template' || documentEditorSession?.uuid)"
          ref="editorMenubarRef"
          :editor="editor"
          :editor-context="editorContext"
          @on-add-image="handleImageUpload"
        >
          <template #saveButton>
            <slot name="saveButton" />
          </template>
        </EditorMenuBar>
      </Transition>
    </Teleport>
  </template>

  <template
    v-if="editor"
  >
    <section class="hidden">
      <NumberingMenu :editor="editor" />
    </section>
  </template>

  <div
    class="max-w-4xl mx-auto"
    :class="
      editorContext !== 'proposal'
        ? 'pt-4 pb-6'
        : ''
    "
  >
    <div
      :id="props.elementId"
      ref="editorContentContainer"
      v-cy="'editor-content-container'"
      :class="[
        documentType === 'document'
          ? 'document-type-document'
          : 'document-type-template',
        editorContext === 'proposal'
          ? 'p-6 document-type-proposal h-full'
          : 'py-4 px-6 sm:py-10 sm:px-16 bg-white shadow-2xl',
      ]"
      class="max-w-full transition-all duration-300"
    >
      <EditorContent
        v-if="editor"
        v-cy="{
          sel: `${props.editorContext}-editor-content`,
          editor
        }"
        :editor="editor"
        :class="
          [
            `${props.editorContext}-editor-content`,
            isEditable ? 'editor-editable' : 'editor-not-editable',
            isEditorEmpty && isEditable && editorContext !== 'proposal'
              ? 'outline-2 outline-gray-300 outline-dashed outline-offset-8 rounded-sm'
              : ''
          ]
        "
      />
    </div>
  </div>

  <DeleteConfirmationModal
    v-if="editor"
    :editor="editor"
    :show="isDeleteConfirmationModalOpen"
    @close="setIsDeleteConfirmationModalOpen(false)"
  />
</template>
