<script setup lang="ts">
// external
import { storeToRefs } from "pinia"

// internal
import { useAccountStore } from "~/stores"
import { SubscriptionType } from "~/types"


const accountStore = useAccountStore()
const {
  currentSubscription,
} = storeToRefs(accountStore)

</script>

<template>
  <div
    v-if="currentSubscription?.object === SubscriptionType.demo"
    class="hidden md:flex items-center justify-center space-x-3"
    aria-hidden="true"
  >
    <div class="hidden lg:block text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-orange-600">
      {{ $t('dashboard.schedulePreText') }}:
    </div>
    <a
      href="https://fynk.pipedrive.com/scheduler/MljdJzCK/fynk-webdemo"
      target="_blank"
      rel="noopener noreferrer"
      class="inline-flex items-center gap-x-1.5 btn-white border-orange-400 hover:bg-orange-50"
    >
      <svg
        class="-ml-0.5 h-5 w-5 text-orange-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
        />
      </svg>
      <div class="font-medium bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-orange-600">{{ $t('notActivated.schedule') }}</div>
    </a>
  </div>
</template>
