<script setup lang="ts">
// external
import { computed, ref, watch, nextTick } from "vue"
import dayjs, { OpUnitType } from "dayjs"

// internal
import DocumentCalendarHeader from "./DocumentCalendarHeader.vue"
import DocumentCalendarMonthView from "./DocumentCalendarMonthView.vue"
import DocumentCalendarYearView from "./DocumentCalendarYearView.vue"
import { DashboardWidget, SubscriptionUpgradeBox } from "~/components"
import { useAccountStore } from "~/stores"
import { storeToRefs } from "pinia"

interface Props {
  view?: OpUnitType
}

const props = withDefaults(
  defineProps<Props>(),
  {
    view: "year",
  },
)

const widget = ref()

const calendarViewDate = ref(new Date())
const calendarDocuments = ref([])
const viewMode = ref(props.view)

const setCalendarData = (response) => {
  calendarDocuments.value = response
}

const fromDate = computed(() => dayjs(calendarViewDate.value).startOf(viewMode.value).format("YYYY-MM-DD"))
const toDate = computed(() => dayjs(calendarViewDate.value).endOf(viewMode.value).format("YYYY-MM-DD"))

watch([ calendarViewDate, viewMode ], () => {
  nextTick(() => {
    widget.value.fetchData()
  })
})

const accountStore = useAccountStore()
const { planFeatures } = storeToRefs(accountStore)
const isFeatureLocked = computed(() => !planFeatures.value?.["calendar"])

</script>

<template>
  <DashboardWidget
    ref="widget"
    :title="$t('dashboard.widgets.documentCalendar')"
    :endpoint="isFeatureLocked ? '' : 'api.data.documents.calendar-dates'"
    :extra-params="{from_date: fromDate, to_date: toDate}"
    box-classes="flex-col w-full overflow-y-auto"
    @loaded="setCalendarData"
  >
    <template #options>
      <DocumentCalendarHeader
        v-if="!isFeatureLocked"
        v-model:date="calendarViewDate"
        v-model:view-mode="viewMode"
      />
    </template>
    <template #default>
      <SubscriptionUpgradeBox
        v-if="isFeatureLocked"
        :text="$t('accountSettings.billing.featureRequiresUpgrade')"
        class="absolute inset-0"
      />
      <DocumentCalendarMonthView
        v-if="viewMode === 'month'"
        :date="calendarViewDate"
        :calendar-documents="calendarDocuments"
      />
      <DocumentCalendarYearView
        v-if="viewMode === 'year'"
        v-model:date="calendarViewDate"
        v-model:view-mode="viewMode"
        :calendar-documents="calendarDocuments"
      />
    </template>
    <template #footer>
      <div class="mt-4 grid grid-cols-2 @lg:grid-cols-3 @3xl:flex @3xl:justify-center @3xl:space-x-4">
        <div class="block relative mx-auto w-full @lg:w-9/12 @3xl:w-auto @3xl:mx-0 @3xl:flex items-center text-xs text-gray-500">
          <span
            class="inline-block w-2 h-2 mr-1 bg-blue-500 rounded-full"
          />
          <span>{{ $t('metadata.system.duration_start_date.name') }}</span>
        </div>
        <div class="block relative mx-auto w-full @lg:w-9/12 @3xl:w-auto @3xl:mx-0 @3xl:flex items-center text-xs text-gray-500">
          <span
            class="inline-block w-2 h-2 mr-1 bg-yellow-500 rounded-full"
          />
          <span>{{ $t('metadata.system.cancellation_notice_date.name') }}</span>
        </div>
        <div class="block relative mx-auto w-full @lg:w-9/12 @3xl:w-auto @3xl:mx-0 @3xl:flex items-center text-xs text-gray-500">
          <span
            class="inline-block w-2 h-2 mr-1 bg-red-500 rounded-full"
          />
          <span>{{ $t('metadata.system.duration_end_date.name') }}</span>
        </div>
        <div class="block relative mx-auto w-full @lg:w-9/12 @3xl:w-auto @3xl:mx-0 @3xl:flex items-center text-xs text-gray-500">
          <span
            class="inline-block w-2 h-2 mr-1 bg-green-500 rounded-full"
          />
          <span>{{ $t('metadata.renewalDate') }}</span>
        </div>
        <div class="block relative mx-auto w-full @lg:w-9/12 @3xl:w-auto @3xl:mx-0 @3xl:flex items-center text-xs text-gray-500">
          <span
            class="inline-block w-2 h-2 mr-1 rounded-full bg-slate-900"
          />
          <span>{{ $t('metadata.system.terminated_date.name') }}</span>
        </div>
      </div>
    </template>
  </DashboardWidget>
</template>
