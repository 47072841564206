<script setup lang="ts">
// external
import { computed } from "vue"
import { LockClosedIcon } from "@heroicons/vue/20/solid"
import { storeToRefs } from "pinia"
import { Link } from "@inertiajs/vue3"

// internal
import { useAccountStore } from "~/stores"

interface Props {
  title?: string
  description?: string
  limit?: number
  count?: number
}

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const props = withDefaults(
  defineProps<Props>(),
  {},
)

enum LimitStatus {
  Good,
  Reached,
  Exceeded,
}

const status = computed(() => {
  if (!!props.count && !!props.limit) {
    if (props.count > props.limit) {
      return LimitStatus.Exceeded
    } else if (props.count === props.limit) {
      return LimitStatus.Reached
    }
  }
  return LimitStatus.Good
})

</script>

<template>
  <div class="@container">
    <div
      v-if="status !== LimitStatus.Good"
      class="py-1.5 px-5 flex items-center justify-center space-x-5 text-white text-sm"
      :class="{
        'bg-reached' : status === LimitStatus.Reached,
        'bg-exceeded' : status === LimitStatus.Exceeded,
      }"
    >
      <div>
        <div class="flex items-center text-sm font-bold">
          <LockClosedIcon
            class="w-3 h-3 mr-1 shrink-0"
            aria-hidden="true"
          />
          <span>{{ title || (status === LimitStatus.Exceeded ? $t('accountSettings.billing.planLimitExceeded') : $t('accountSettings.billing.planLimitReached')) }} ({{ count }}/{{ limit }})</span>
        </div>
        <p
          v-if="description"
          class="mt-2 opacity-90"
        >
          {{ description }}
        </p>
      </div>
      <div
        v-if="mau?.permissions?.includes('account_manage')"
        class="flex items-center space-x-3"
      >
        <Link
          class="inline-block text-white border-0 btn-white btn-sm whitespace-nowrap bg-white/30 ring-0 hover:text-gray-900"
          :href="route('account-settings.billing.index')"
        >
          {{ $t('accountSettings.billing.manage') }}
        </Link>
      </div>
    </div>
  </div>
</template>
