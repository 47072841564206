<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, toRaw } from "vue"

import {
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue"

import { InformationCircleIcon } from "@heroicons/vue/24/outline"

// internal
import { FormInputErrors, OverlayScrollbar, SignatureSecurityLevelSelector, SpinLoader, TeamSelector, TeamList, TagSelector, TagList, DocumentTypeInput, ContractTypeInput } from "~/components"
import { useTemplateStore, useAccountStore } from "~/stores"
import { DocumentType, Party, SignatureSecurityLevel, Tag, Team, Template } from "~/types"

interface Props {
  teams: Team[]
  signatureSecurityLevel?: SignatureSecurityLevel
  documentType?: DocumentType
  teamUuid?: Team["uuid"]
  parties: Party[]
  description?: Template["description"]
  reviewMessage?: Template["external_reviewer_invitation_message"]
  signingMessage?: Template["external_signatory_invitation_message"]
  sequentialSigning?: Template["sequential_signing"]
  canChangeDefaultSignatories?: Template["can_change_default_signatories"]
  documentCreatorIsDefaultSignatory?: Template["document_creator_is_default_signatory"]
  canAddInternalSignatories?: Template["can_add_internal_signatories"]
  errorsToShow?: Partial<Record<keyof Template, string[]>>
}
const props = withDefaults(defineProps<Props>(), {
  signatureSecurityLevel: null,
  documentType: null,
  teamUuid: null,
  description: null,
  reviewMessage: null,
  signingMessage: null,
  sequentialSigning: null,
  canChangeDefaultSignatories: null,
  documentCreatorIsDefaultSignatory: null,
  canAddInternalSignatories: null,
  errorsToShow: null,
})

const emit = defineEmits([ "update:description", "update:sequential-signing", "update:can-change-default-signatories", "update:can-add-internal-signatories", "update:document-creator-is-default-signatory", "update:signature-security-level", "update:document-type", "update:review-message", "update:signing-message" ])

const templateStore = useTemplateStore()
const { currentTemplate, tags, allTags, allTeams, keysOfLastSavedTemplateField, keysOfIsUpdatingTemplateField } = storeToRefs(templateStore)
const { addTag, removeTag, updateTemplate } = templateStore

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const localTeams = ref<Team[]>(null)

const selectedTeams = computed<Team[]>({
  get: () => {
    // Return localTeams.value if array
    if (Array.isArray(localTeams.value)) {
      return localTeams.value
    }
    return currentTemplate.value.teams
  },
  set: (value) => {
    localTeams.value = value
  },
})

const documentTypeSelected = computed<DocumentType>({
  get: () => props.documentType,
  set: (val) => emit("update:document-type", val),
})

const signatureSecurityLevelSelected = computed<SignatureSecurityLevel>({
  get: () => props.signatureSecurityLevel,
  set: (val) => emit("update:signature-security-level", val),
})

const selectedTag = computed({
  get: () => {
    return null
  },
  set: (tag) => {
    addTag(currentTemplate.value.uuid, tag.uuid)
  },
})

const selectedTeam = computed({
  get: () => {
    return null
  },
  set: (team) => {
    toggleTeam(team)
  },
})

const remainingTagOptions = computed(() => {
  if (tags.value.length) {
    const selectedTagUuids = tags.value.map((tag) => tag.uuid)
    return allTags.value.filter((tag) => {
      return !selectedTagUuids.includes(tag.uuid)
    })
  }
  return allTags.value
})

const remainingTeamOptions = computed(() => {
  if (selectedTeams.value.length) {
    const selectedTeamUuids = selectedTeams.value.map((team) => team.uuid)
    return allTeams.value.filter((team) => {
      return !selectedTeamUuids.includes(team.uuid)
    })
  }
  return allTeams.value
})

const removeTagFromTemplate = (tag:Tag) => {
  removeTag(currentTemplate.value.uuid, tag.uuid)
}

const toggleTeam = (team:Team) => {
  const copyOfSelectedTeams = toRaw(selectedTeams.value) || []
  const currentTeamUuids = copyOfSelectedTeams?.map((team) => team.uuid)
  const teamToToggleIndex = currentTeamUuids.indexOf(team.uuid)

  if (teamToToggleIndex !== -1) {
    currentTeamUuids.splice(teamToToggleIndex, 1)
  } else {
    currentTeamUuids.push(team.uuid)
  }

  updateTemplate({
    uuid: currentTemplate.value.uuid,
    team_uuids: currentTeamUuids.length ? currentTeamUuids : null,
  }, [ "team_uuids" ], Date.now())

  // Manipulate localTemplate so that teams are updated
  const newTeams = allTeams.value.filter((team) => currentTeamUuids.includes(team.uuid))
  selectedTeams.value = newTeams || []
}

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 border-b shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('documentGeneralSettings.title') }}
      </h3>
    </div>
    <OverlayScrollbar
      class="flex-1 overflow-y-auto max-h-max"
    >
      <div class="border-b border-gray-200 divide-y divide-y-gray-200">
        <div>
          <DocumentTypeInput
            v-model="documentTypeSelected"
            :is-highlighted="keysOfLastSavedTemplateField?.includes('document_type')"
            :is-loading="keysOfIsUpdatingTemplateField?.includes('document_type')"
          />
          <FormInputErrors
            v-if="errorsToShow?.document_type?.length"
            class="px-6 mb-2"
            :errors="errorsToShow?.document_type"
          />
        </div>

        <div>
          <ContractTypeInput />
        </div>

        <div>
          <SignatureSecurityLevelSelector
            v-model="signatureSecurityLevelSelected"
            :is-highlighted="keysOfLastSavedTemplateField?.includes('signature_security_level')"
            :is-loading="keysOfIsUpdatingTemplateField?.includes('signature_security_level')"
            :inline="true"
            :clearable="true"
          />
          <FormInputErrors
            v-if="errorsToShow?.signature_security_level?.length"
            class="px-6 mb-2"
            :errors="errorsToShow?.signature_security_level"
          />
        </div>

        <div>
          <TeamSelector
            v-model="selectedTeam"
            :is-disabled="!remainingTeamOptions.length"
            :is-highlighted="keysOfLastSavedTemplateField?.includes('team_uuids')"
            :is-loading="keysOfIsUpdatingTemplateField?.includes('team_uuids')"
            :options="remainingTeamOptions"
            :selected-tags="tags"
          >
            <TeamList
              v-if="selectedTeams?.length"
              class="px-6 pb-4 -mt-1"
              :teams="selectedTeams"
              :removable="true"
              @remove="toggleTeam"
            />
          </TeamSelector>
        </div>

        <div>
          <TagSelector
            v-model="selectedTag"
            :is-disabled="!remainingTagOptions.length"
            :options="remainingTagOptions"
            :selected-tags="tags"
            :mau="mau"
          />
          <TagList
            v-if="tags?.length"
            class="px-6 mb-2 -mt-1"
            :tags="tags"
            :removable="true"
            @remove="removeTagFromTemplate"
          />
        </div>

        <div
          class="gap-2 px-6 py-2 text-sm font-medium transition-all duration-500"
          :class="keysOfLastSavedTemplateField?.includes('description') ? 'bg-teal-50' : ''"
        >
          <label
            for="template-description"
            class="flex items-center gap-2 py-2 pr-3 font-semibold text-gray-700 shrink-0"
          >
            {{ $t('templateGeneralSettings.description') }}
            <SpinLoader
              v-if="keysOfIsUpdatingTemplateField?.includes('description')"
              class="w-3 h-3"
            />
          </label>
          <div class="pb-1 -mt-1">
            <div class="relative">
              <textarea
                id="template-description"
                :value="description"
                :placeholder="$t('templateGeneralSettings.descriptionPlaceholder') + '…'"
                type="text"
                name="template-description"
                class="absolute top-0 bottom-0 left-0 right-0 block w-auto -mx-3 placeholder-gray-400 bg-white input-plain hover:bg-gray-100 focus:bg-gray-100"
                @input="$emit('update:description', ($event.target as HTMLInputElement).value)"
              />
              <span
                class="invisible block w-auto p-2 -mx-3 placeholder-gray-400 break-words whitespace-pre-wrap bg-white pointer-events-none input-plain hover:bg-gray-100 focus:bg-gray-100"
              >
                {{ description }}&nbsp;
              </span>
            </div>
          </div>
        </div>

        <div
          class="px-6 py-2 text-sm transition-all duration-500"
          :class="keysOfLastSavedTemplateField.includes('external_reviewer_invitation_message') ? 'bg-teal-50' : ''"
        >
          <dt
            class="flex items-center justify-between gap-4 py-2 text-gray-500 grow"
          >
            <div class="flex items-center gap-1.5 truncate">
              <span
                class="truncate"
                :title="$t('templateGeneralSettings.reviewMessage')"
              >{{ $t('templateGeneralSettings.reviewMessage') }}</span>
              <span
                data-tippy-help
                :data-tippy-content="$t('templateGeneralSettings.reviewMessageHint')"
                data-placement="bottom"
              >
                <InformationCircleIcon
                  class="w-3 h-3 text-gray-400"
                  aria-hidden="true"
                />
              </span>
              <SpinLoader
                v-if="keysOfIsUpdatingTemplateField.includes('external_reviewer_invitation_message')"
                class="w-3 h-3"
              />
            </div>
          </dt>
          <dd class="mb-2">
            <div
              class="grow-textarea"
              :data-replicated-value="reviewMessage"
            >
              <textarea
                :value="reviewMessage"
                :placeholder="$t('common.add') + '…'"
                @input="$emit('update:review-message', ($event.target as HTMLInputElement).value)"
              />
            </div>
            <div
              v-if="errorsToShow?.reviewMessage?.length"
              class="flex"
            >
              <FormInputErrors
                :errors="errorsToShow?.reviewMessage"
                align="right"
              />
            </div>
          </dd>
        </div>

        <div
          class="px-6 py-2 text-sm transition-all duration-500"
          :class="keysOfLastSavedTemplateField.includes('external_signatory_invitation_message') ? 'bg-teal-50' : ''"
        >
          <dt
            class="flex items-center justify-between gap-4 py-2 text-gray-500 grow"
          >
            <div class="flex items-center gap-1.5 truncate">
              <span
                class="truncate"
                :title="$t('templateGeneralSettings.signingMessage')"
              >{{ $t('templateGeneralSettings.signingMessage') }}</span>
              <span
                data-tippy-help
                :data-tippy-content="$t('templateGeneralSettings.signingMessageHint')"
                data-placement="bottom"
              >
                <InformationCircleIcon
                  class="w-3 h-3 text-gray-400"
                  aria-hidden="true"
                />
              </span>
              <SpinLoader
                v-if="keysOfIsUpdatingTemplateField.includes('external_signatory_invitation_message')"
                class="w-3 h-3"
              />
            </div>
          </dt>
          <dd class="mb-2">
            <div
              class="grow-textarea"
              :data-replicated-value="signingMessage"
            >
              <textarea
                :value="signingMessage"
                :placeholder="$t('common.add') + '…'"
                @input="$emit('update:signing-message', ($event.target as HTMLInputElement).value)"
              />
            </div>
            <div
              v-if="errorsToShow?.signingMessage?.length"
              class="flex"
            >
              <FormInputErrors
                :errors="errorsToShow?.signingMessage"
                align="right"
              />
            </div>
          </dd>
        </div>
      </div>
      <ul
        role="list"
        class="grid grid-cols-1 border-b divide-y divide-gray-200 border-b-gray-200"
      >
        <li>
          <SwitchGroup
            as="div"
            class="flex items-start gap-6 px-6 py-4"
            :class="keysOfLastSavedTemplateField?.includes('sequential_signing') ? 'bg-teal-50' : ''"
          >
            <SwitchLabel
              as="span"
              class="text-sm text-gray-400 grow"
              passive
            >
              <h3 class="mb-1 text-sm font-semibold text-gray-700">
                {{ $t('templateGeneralSettings.sequentialSigning') }}
              </h3>
              {{ $t('templateGeneralSettings.sequentialSigningDescription') }}
            </SwitchLabel>
            <Switch
              :model-value="sequentialSigning"
              :class="[
                sequentialSigning
                  ? 'bg-indigo-600'
                  : 'bg-gray-300',
                'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-1',
              ]"
              @update:model-value="$emit('update:sequential-signing', $event)"
            >
              <span
                aria-hidden="true"
                :class="[
                  sequentialSigning
                    ? 'translate-x-5'
                    : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                ]"
              />
            </Switch>
          </SwitchGroup>
        </li>
      </ul>
    </OverlayScrollbar>
  </div>
</template>
