<script setup lang="ts">
// external
import { computed, ref } from "vue"

import { MenuButton } from "@headlessui/vue"
import { ChevronDownIcon } from "@heroicons/vue/20/solid"
import { DocumentTextIcon, MagnifyingGlassIcon, PhotoIcon, PlusIcon, ViewColumnsIcon } from "@heroicons/vue/24/outline"
import { usePage } from "@inertiajs/vue3"
import { Editor } from "@tiptap/core"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"
import { useI18n } from "vue-i18n"

// internal
import { Dropdown, DropdownLink } from "~/components"
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  BulletListIcon,
  FineprintIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ItalicIcon,
  MsWordIcon,
  OrderedListIcon,
  PageBreakIcon,
  ParagraphIcon,
  RedoIcon,
  SignatureIcon,
  StrikeThroughIcon,
  SubscriptIcon,
  SuperscriptIcon,
  TableBorderIcon,
  TableCheckIcon,
  TablePlusIcon,
  TableXIcon,
  TocIcon,
  UnderlineIcon,
  UndoIcon,
} from "~/icons"
import { useEditorStore } from "~/stores"

import SearchAndReplacePopover from "./SearchAndReplacePopover.vue"

interface Props {
  editor: Editor | null
  editorContext: "document" | "template" | "proposal"
}
const props = withDefaults(
  defineProps<Props>(),
  {
    editor: null,
    editorContext: "document",
  },
)

const { t } = useI18n()

const pageProps = computed(() => usePage().props)

const { setIsDocxImportModalOpen } = useEditorStore()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual("sm")

interface Emits {
  (e: "on-add-image", imageFile: File): void
}
const emit = defineEmits<Emits>()

const searchAndReplacePopoverRef = ref<InstanceType<typeof SearchAndReplacePopover>>(null)

const showSearchPopover = () => {
  const isAlreadyOpen = searchAndReplacePopoverRef.value.searchTippy?.[0].state.isShown
  if (isAlreadyOpen) {
    searchAndReplacePopoverRef.value?.resetAndHideSearchPopover()
  } else {
    searchAndReplacePopoverRef.value?.showSearchPopover()
  }
}

defineExpose(
  {
    showSearchPopover,
  },
)

interface Button {
  title: string
  getTitle?: () => string
  isDisabled?: () => boolean
  action?: () => void | boolean
  icon: any
  mobileIcon?: any
  hideOnMobile?: boolean
  isGroup?: boolean
  canBeActive?: boolean
  isActive?: () => boolean
  canDoAction?: () => boolean
  groupMembers?: Button[]
}

const leftButtons: Button[] = [
  {
    title: t("editor.undo"),
    icon: UndoIcon,
    action: () => props.editor.chain().focus().undo().run(),
    isDisabled: () => !props.editor.can().undo(),
  },
  {
    title: t("editor.redo"),
    icon: RedoIcon,
    action: () => props.editor.chain().focus().redo().run(),
    isDisabled: () => !props.editor.can().redo(),
  },
  {
    title: t("editor.headings"),
    icon: ChevronDownIcon,
    mobileIcon: DocumentTextIcon,
    isGroup: true,
    canBeActive: true,
    isActive: () => [ "heading", "fineprint" ].some((nodeType) => props.editor.isActive(nodeType)),
    getTitle: () => {
      if (props.editor.isActive("heading", { level: 1 })) return t("editor.title")
      if (props.editor.isActive("heading", { level: 2 })) return t("editor.heading")
      if (props.editor.isActive("heading", { level: 3 })) return t("editor.subheading")
      if (props.editor.isActive("fineprint")) return t("editor.fineprint")

      return t("editor.text")
    },
    groupMembers: [
      {
        title: t("editor.text"),
        icon: DocumentTextIcon,
        action: () => {
          const selBefore = {
            from: props.editor.state.selection.from,
            to: props.editor.state.selection.to,
          }

          props.editor
            .chain()
            .focus()
            .toggleNode("paragraph", "paragraph")
            .extendMarkRange("textStyle")
            .unsetFontSize()
            .setTextSelection(selBefore)
            .run()
        },
        canBeActive: true,
        isActive: () => props.editor.isActive("paragraph"),
      },
      {
        title: t("editor.documentTitle"),
        icon: Heading1Icon,
        action: () => {
          const selBefore = {
            from: props.editor.state.selection.from,
            to: props.editor.state.selection.to,
          }

          props.editor
            .chain()
            .focus()
            .toggleHeading({ level: 1 })
            .extendMarkRange("textStyle")
            .unsetFontSize()
            .setTextSelection(selBefore)
            .run()
        },
        canBeActive: true,
        isActive: () => props.editor.isActive("heading", { level: 1 }),
      },
      {
        title: t("editor.heading"),
        icon: Heading2Icon,
        action: () => {
          const selBefore = {
            from: props.editor.state.selection.from,
            to: props.editor.state.selection.to,
          }

          props.editor
            .chain()
            .focus()
            .toggleHeading({ level: 2 })
            .extendMarkRange("textStyle")
            .unsetFontSize()
            .setTextSelection(selBefore)
            .run()
        },
        canBeActive: true,
        isActive: () => props.editor.isActive("heading", { level: 2 }),
      },
      {
        title: t("editor.subheading"),
        icon: Heading3Icon,
        action: () => {
          const selBefore = {
            from: props.editor.state.selection.from,
            to: props.editor.state.selection.to,
          }

          props.editor
            .chain()
            .focus()
            .toggleHeading({ level: 3 })
            .extendMarkRange("textStyle")
            .unsetFontSize()
            .setTextSelection(selBefore)
            .run()
        },
        canBeActive: true,
        isActive: () => props.editor.isActive("heading", { level: 3 }),
      },
      {
        title: t("editor.fineprint"),
        icon: FineprintIcon,
        action: () => {
          const selBefore = {
            from: props.editor.state.selection.from,
            to: props.editor.state.selection.to,
          }

          props.editor
            .chain()
            .focus()
            .toggleFineprint()
            .extendMarkRange("textStyle")
            .unsetFontSize()
            .setTextSelection(selBefore)
            .run()
        },
        canBeActive: true,
        isActive: () => props.editor.isActive("fineprint"),
      },
    ],
  },
  {
    title: t("editor.format"),
    icon: ChevronDownIcon,
    mobileIcon: BoldIcon,
    isGroup: true,
    canBeActive: true,
    isActive: () => props.editor.isActive("bold") || props.editor.isActive("underline") || props.editor.isActive("italic") || props.editor.isActive("strike") || props.editor.isActive("subscript") || props.editor.isActive("superscript"),
    groupMembers: [
      {
        title: t("editor.bold"),
        icon: BoldIcon,
        canBeActive: true,
        isActive: () => props.editor.isActive("bold"),
        action: () => props.editor.chain().focus().toggleBold().run(),
        canDoAction: () => props.editor.can().toggleBold(),
      },
      {
        title: t("editor.italic"),
        icon: ItalicIcon,
        canBeActive: true,
        isActive: () => props.editor.isActive("italic"),
        action: () => props.editor.chain().focus().toggleItalic().run(),
        canDoAction: () => props.editor.can().toggleItalic(),
      },
      {
        title: t("editor.underline"),
        icon: UnderlineIcon,
        action: () => props.editor.chain().focus().toggleUnderline().run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("underline"),
        canDoAction: () => props.editor.can().toggleUnderline(),
      },
      {
        title: t("editor.strike"),
        icon: StrikeThroughIcon,
        action: () => props.editor.chain().focus().toggleStrike().run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("strike"),
        canDoAction: () => props.editor.can().toggleStrike(),
      },
      {
        title: t("editor.subscript"),
        icon: SubscriptIcon,
        canBeActive: true,
        isActive: () => props.editor.isActive("subscript"),
        action: () => props.editor.chain().focus().toggleSubscript().run(),
        canDoAction: () => props.editor.can().toggleSubscript(),
      },
      {
        title: t("editor.superscript"),
        icon: SuperscriptIcon,
        canBeActive: true,
        isActive: () => props.editor.isActive("superscript"),
        action: () => props.editor.chain().focus().toggleSuperscript().run(),
        canDoAction: () => props.editor.can().toggleSuperscript(),
      },
    ],
  },
  {
    title: t("editor.align"),
    icon: ChevronDownIcon,
    mobileIcon: AlignLeftIcon,
    isGroup: true,
    canBeActive: false,
    groupMembers: [
      {
        title: t("editor.alignLeft"),
        action: () => props.editor.chain().focus().setTextAlign("left").run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("textAlign", { align: "left" }),
        icon: AlignLeftIcon,
      },
      {
        title: t("editor.alignCenter"),
        action: () => props.editor.chain().focus().setTextAlign("center").run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("textAlign", { align: "center" }),
        icon: AlignCenterIcon,
      },
      {
        title: t("editor.alignRight"),
        action: () => props.editor.chain().focus().setTextAlign("right").run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("textAlign", { align: "right" }),
        icon: AlignRightIcon,
      },
      {
        title: t("editor.alignJustify"),
        action: () => props.editor.chain().focus().setTextAlign("justify").run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("textAlign", { align: "justify" }),
        icon: AlignJustifyIcon,
      },
    ],
  },
  {
    title: t("editor.list"),
    icon: ChevronDownIcon,
    mobileIcon: OrderedListIcon,
    isGroup: true,
    canBeActive: true,
    isActive: () => props.editor.isActive("list"),
    groupMembers: [
      {
        title: t("editor.orderedList"),
        icon: OrderedListIcon,
        canBeActive: true,
        isActive: () => props.editor.isActive("list", { kind: "ordered" }),
        action: () => {
          props
            .editor
            .chain()
            .toggleFlatList("ordered")
            .focus()
            .run()
        },
      },
      {
        title: t("editor.bulletList"),
        icon: BulletListIcon,
        canBeActive: true,
        isActive: () => props.editor.isActive("list", { kind: "bullet" }),
        action: () => {
          props
            .editor
            .chain()
            .toggleFlatList("bullet")
            .focus()
            .run()
        },
      },
    ],
  },
  {
    title: t("editor.insert"),
    icon: ChevronDownIcon,
    mobileIcon: PlusIcon,
    isGroup: true,
    canBeActive: true,
    groupMembers: [
      {
        title: t("editor.table"),
        icon: TablePlusIcon,
        action: () => {
          props.editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        },
        canBeActive: true,
        isActive: () => props.editor.isActive("table"),
      },
      {
        title: t("editor.tableOfContents"),
        icon: TocIcon,
        action: () => props.editor.chain().focus().insertToc().run(),
        canBeActive: true,
        isActive: () => props.editor.isActive("toc"),
      },
      {
        title: t("editor.image"),
        icon: PhotoIcon,
        action: () => document.getElementById("imageUploadInput").click(),
      },
      {
        title: t("editor.pageBreak"),
        icon: PageBreakIcon,
        action: () => props.editor.chain().focus().setPageBreak().run(),
      },
      {
        title: t("editor.columns"),
        icon: ViewColumnsIcon,
        action: () => props.editor.chain().focus().setColumns(3).run(),
      },
      {
        title: t("editor.signatureContainer"),
        icon: SignatureIcon,
        action: () => props.editor.chain().focus().insertSignatureContainer().run(),
      },
    ],
  },
  {
    title: t("editor.table"),
    icon: ChevronDownIcon,
    mobileIcon: TableCheckIcon,
    hideOnMobile: true,
    isGroup: true,
    canBeActive: true,
    isDisabled: () => !props.editor.isActive("table"),
    isActive: () => props.editor.isActive("table"),
    groupMembers: [
      {
        title: t("editor.deleteTable"),
        icon: TableXIcon,
        action: () => props.editor.chain().focus().deleteTable().run(),
        isDisabled: () => !props.editor.can().deleteTable(),
      },
      {
        title: t("editor.autoRepairTables"),
        icon: TableCheckIcon,
        action: () => props.editor.chain().focus().fixTables().run(),
        isDisabled: () => !props.editor.can().deleteTable(),
      },
      {
        title: t("editor.toggleTableBorder"),
        icon: TableBorderIcon,
        action: () => {
          const { isBordered } = props.editor.getAttributes("table") || {}

          return props.editor.chain().focus().updateAttributes("table", { isBordered: !isBordered }).run()
        },
        isDisabled: () => !props.editor.isActive("table"),
      },
    ],
  },
]

const imageUploadInputRef = ref<HTMLInputElement | null>(null)

const handleAddImage = () => {
  const imageFile = imageUploadInputRef.value?.files?.[0]

  if (!imageFile) return

  emit("on-add-image", imageFile)

  imageUploadInputRef.value.value = ""
}

const toggleFormattingMarks = () => {
  props.editor.commands.toggleInvisibleCharacters?.()

  props.editor.commands.focus()
}
</script>

<template>
  <div
    ref="editorToolbarRef"
    v-cy="'editor-menubar'"
    class="z-20 flex flex-wrap w-full text-white bg-indigo-700 shadow-sm"
  >
    <slot name="saveButton" />
    <template v-for="(buttons, index) in [leftButtons]">
      <template
        v-for="button in buttons"
        :key="button.title"
      >
        <button
          v-if="!button.isGroup"
          v-cy="button.title"
          data-tippy-context
          :data-tippy-content="button.title"
          data-placement="top"
          :aria-label="button.title"
          :disabled="!!button?.isDisabled?.()"
          class="btn-editor-toolbar"
          :class="{
            'is-active': !!button.isActive?.()
          }"
          @click="button.action?.()"
        >
          <component
            :is="button.icon"
            class="w-4 h-4 mx-auto"
          />
        </button>

        <Dropdown
          v-else
          :menu-classes="isMobile ? '' : 'relative'"
          :width="isMobile ? 'auto' : 'w-fit'"
          :align="isMobile ? 'full' : 'left'"
        >
          <template #trigger>
            <MenuButton
              v-cy="button.title"
              type="button"
              :aria-label="button.title"
              :disabled="!!button?.isDisabled?.()"
              class="flex items-center h-full gap-1 btn-editor-toolbar"
              :class="{
                'is-active': !!button.isActive?.(),
                'hidden sm:inline-block': button.hideOnMobile,
              }"
            >
              <span :class="button.mobileIcon ? 'hidden sm:inline-block' : ''">{{ button.getTitle?.() || button.title }}</span>
              <span :class="button.mobileIcon ? 'inline-block sm:hidden' : ''">
                <component
                  :is="button.mobileIcon"
                  class="w-4 h-4"
                />
              </span>
              <component
                :is="button.icon"
                class="hidden w-4 h-4 mx-auto md:inline-block"
              />
            </MenuButton>
          </template>

          <template #content>
            <DropdownLink
              v-for="option in button.groupMembers"
              :key="option.title"
              v-cy="option.title"
              as="button"
              :aria-label="option.title"
              :disabled="!!option?.isDisabled?.()"
              :class="{
                'is-active bg-slate-700': !!option.isActive?.()
              }"
              @click="option.action?.()"
            >
              <div class="flex items-center gap-2">
                <component
                  :is="option.icon"
                  class="w-4 h-4 text-indigo-200"
                />
                <span class="text-sm truncate">{{ option.title }}</span>
              </div>
            </DropdownLink>
          </template>
        </Dropdown>
      </template>

      <div
        v-if="index === 0"
        :key="index"
        class="grow"
      />
    </template>

    <button
      v-if="pageProps.mau"
      class="hidden btn-editor-toolbar sm:inline-block"
      :class="{
        'is-active': !!props.editor.storage.invisibleCharacters?.visibility()
      }"
      data-tippy-context
      :data-tippy-content="$t('editor.toggleFormattingMarks')"
      data-placement="top"
      :aria-label="$t('editor.toggleFormattingMarks')"
      @click="toggleFormattingMarks"
    >
      <ParagraphIcon class="w-4 h-4 mx-auto" />
    </button>

    <!-- search and replace button is a special case, thus not including it above -->
    <button
      class="hidden btn-editor-toolbar sm:inline-block"
      data-tippy-context
      :data-tippy-content="$t('editor.searchReplace')"
      data-placement="top"
      :aria-label="$t('editor.searchReplace')"
      @click="showSearchPopover"
    >
      <label
        id="toolbar-search-and-replace-button"
        class="cursor-pointer"
      >
        <MagnifyingGlassIcon class="w-4 h-4 mx-auto" />
      </label>

      <SearchAndReplacePopover
        ref="searchAndReplacePopoverRef"
        :editor="editor"
      />
    </button>

    <button
      class="hidden btn-editor-toolbar sm:inline-block"
      data-tippy-context
      :data-tippy-content="$t('editor.importDocX')"
      data-placement="top"
      @click="setIsDocxImportModalOpen(true)"
    >
      <MsWordIcon class="w-4 h-4 mx-auto" />
    </button>

    <input
      id="imageUploadInput"
      ref="imageUploadInputRef"
      type="file"
      class="hidden"
      accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .psd, .ai, .eps, .raw, .arw, .cr2, .nef, .orf, .rw2, .sr2, .webp, .svg"
      @change="handleAddImage"
    >
  </div>
</template>
