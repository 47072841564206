<script setup lang="ts">
// external
import { computed } from "vue"
import { storeToRefs } from "pinia"

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"

// internal
import { documentTypes } from "~/utils"
import { SpinLoader } from "~/components"
import { useDocumentStore, useSharedStore } from "~/stores"
import { CrudContext, DocumentType } from "~/types"

interface Props {
  modelValue: DocumentType
  isHighlighted: boolean
  isLoading: boolean
  isDisabled?: boolean
  inlineButtonClasses?: string
  chevronClasses?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isDisabled: false,
    inlineButtonClasses: "group btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-0 focus:ring-offset-0 text-gray-900 flex items-center gap-2",
    chevronClasses: "absolute inset-y-0 flex items-center pr-2 transition-all duration-200 scale-0 opacity-0 pointer-events-none -right-7 group-hover:scale-100 group-hover:opacity-100",
  },
)

const emit = defineEmits([ "focus", "blur", "update:model-value" ])

const documentStore = useDocumentStore()
const { mdu } = storeToRefs(documentStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

const selectedValue = computed({
  get: () => {
    return props.modelValue
  },
  set: (val) => {
    emit("update:model-value", val)
  },
})

</script>

<template>
  <Listbox
    :id="'documentTypeSelector'"
    v-model="selectedValue"
    as="div"
    :disabled="isDisabled"
    class="flex items-center justify-between gap-2 px-6 py-2 text-sm transition-all duration-500"
    :class="[isDisabled ? 'mr-2' : '' , isHighlighted ? 'bg-teal-50' : '']"
  >
    <ListboxLabel
      class="flex items-center gap-2 py-2 pr-3 text-gray-500 shrink-0"
    >
      {{ $t('documentGeneralSettings.documentType') }}
      <SpinLoader
        v-if="isLoading"
        class="w-3 h-3"
      />
    </ListboxLabel>
    <div
      class="relative grow max-w-fit"
    >
      <div
        v-if="props.isDisabled || (!mdu?.permissions.includes('document_update') && crudContext !== CrudContext.template)"
        class="flex items-center"
      >
        <div class="flex items-center">
          <span
            class="flex items-center"
          >
            <component
              :is="documentTypes.find((el) => el.type === selectedValue).icon"
              class="w-4 h-4 mr-2 flex-shrink-none"
            />
            <span class="block font-medium truncate">
              {{ selectedValue ? $t(`documentTypes.${selectedValue}.name`) : $t(`documentTypes.${DocumentType.other}.name`) }}
            </span>
          </span>
        </div>
      </div>
      <ListboxButton
        v-else
        v-cy="`document-type-selector`"
        :class="inlineButtonClasses"
      >
        <div class="flex items-center">
          <span
            class="flex items-center"
          >
            <component
              :is="getDocumentTypeInfo(selectedValue || DocumentType.other)?.icon"
              class="w-4 h-4 mr-2 shrink-0 "
              aria-hidden="true"
            />
            <span
              class="block font-medium truncate"
            >
              {{ selectedValue ? $t(`documentTypes.${selectedValue}.name`) : $t(`documentTypes.${DocumentType.other}.name`) }}
            </span>
          </span>
        </div>
        <span
          :class="chevronClasses"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="listbox-options"
          @focus="
            $emit('focus', {
              target: { id: 'documentTypeSelector' },
            })
          "
          @blur="
            $emit('blur', {
              target: { id: 'documentTypeSelector' },
            })
          "
        >
          <ListboxOption
            v-for="documentType in documentTypes"
            :key="documentType.type"
            v-slot="{ active, selected }"
            as="template"
            :value="documentType.type"
          >
            <li
              v-cy="`document-type-selector-${documentType.type}`"
              :class="[active ? 'bg-gray-700' : '', 'listbox-option']"
            >
              <div
                class="flex items-center"
                :class="selected ? 'pr-3' : ''"
              >
                <component
                  :is="documentType.icon"
                  :class="[
                    'h-6 w-6 text-gray-400 mr-2 shrink-0',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ $t(`documentTypes.${documentType.type}.name`) }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
