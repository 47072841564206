<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/20/solid"
import { storeToRefs } from "pinia"
import { useAccountStore, useDocumentStore, useFileStorageStore, useSharedStore } from "~/stores"
import { CrudContext, StoredFile } from "~/types"
import { SpinLoader } from "~/components"
import { getFileTypeIconByMimeType } from "~/utils"

interface Props {
  relatedFiles: StoredFile[]
  entityUuid: string
  scope: "internal" | "internal_and_external"
}

withDefaults(defineProps<Props>(), {
  relatedFiles: () => [],
  entityUuid: null,
  scope: "internal_and_external",
})

const fileStorageStore = useFileStorageStore()
const { uuidOfIsRemovingStoredFile } = storeToRefs(fileStorageStore)
const { handleRemoveCommentFile, handleShowStoredFile } = fileStorageStore

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const documentStore = useDocumentStore()
const { mdu } = storeToRefs(documentStore)

</script>
<template>
  <div
    v-if="relatedFiles?.length > 0"
    class="flex flex-wrap gap-2 mt-1 text-xs font-medium"
  >
    <a
      v-for="file in relatedFiles"
      :key="file.uuid"
      class="relative flex items-center gap-1 px-2 py-1.5 bg-white border rounded-md cursor-pointer group"
      :class="[
        scope === 'internal' ? 'border-indigo-100 hover:bg-indigo-50' : 'border-gray-200 hover:bg-gray-100',
        !file.uuid ? 'hover:bg-white' : ''
      ]"
      @click="handleShowStoredFile(file.uuid, false, crudContext, entityUuid)"
    >
      <component
        :is="getFileTypeIconByMimeType(file.mime_type)"
        class="w-3 h-3 shrink-0"
        aria-hidden="true"
      />
      <span class="truncate max-w-[300px]">
        {{ file.filename }}
      </span>
      <button
        v-if="(!!mau && (mdu && mdu.permissions?.includes('stored_file_delete') || crudContext === CrudContext.template)) || !file.uuid"
        type="button"
        class="absolute text-gray-400 transition-opacity bg-gray-900 rounded-full -top-1 -right-1 hover:bg-gray-950 hover:text-white"
        :class="uuidOfIsRemovingStoredFile !== file.uuid || !uuidOfIsRemovingStoredFile ? 'bg-gray-900 opacity-0 group-hover:opacity-100' : 'bg-white pointer-events-none'"
        @click.prevent.stop="handleRemoveCommentFile(file, crudContext, entityUuid)"
      >
        <span class="sr-only">{{ $t('common.remove') }}</span>
        <XMarkIcon
          v-if="uuidOfIsRemovingStoredFile !== file.uuid || !uuidOfIsRemovingStoredFile"
          class="w-3 h-3"
          aria-hidden="true"
        />
        <SpinLoader
          v-else
          class="w-3 h-3"
        />
      </button>
    </a>
  </div>
</template>
