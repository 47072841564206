import { Editor } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"

import { handleSvg } from "~/editor/utils/handleSvg"
import { JuneEvents } from "~/types"
import { getUuid, trackEventInJune } from "~/utils"

export const imageFileTypeRegex = /^(image)/i

const readFileAndSaveDataUrl = async (file: File): Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader()

  return new Promise(
    (res) => {
      if (!file) res(null)

      reader.addEventListener("load", () => res(reader.result))

      reader.readAsDataURL(file)
    },
  )
}

type uploadFnType = (file: File, intermediateUuid: string) => Promise<void>

const imagePasteDropPluginKey = new PluginKey("image-paste-drop-plugin-key")

export const getImagePasteDropPlugin = (
  editor: Editor,
  upload: uploadFnType,
) => {

  return new Plugin({
    key: imagePasteDropPluginKey,

    props: {
      handlePaste (_, event) {
        const imageFiles = Array.from(event.clipboardData?.items || [])
          .map((item) => item.getAsFile())
          .filter(Boolean)
          .filter(({ type }) => imageFileTypeRegex.test(type))

        trackEventInJune(JuneEvents.EDITOR_IMAGE_PASTED)

        imageFiles.forEach(
          async (file) => {
            if (file.type === "image/svg+xml" && await handleSvg(file)) return

            event.preventDefault()

            const intermediateUuid = getUuid()

            const dataUrl = await readFileAndSaveDataUrl(file)

            editor.commands.setImage(
              {
                src: "",
                intermediateUuid,
                dataUrl: dataUrl as string,
              },
            )

            upload(file, intermediateUuid)
          },
        )

        return false
      },

      handleDrop (view, event) {
        const imageFiles = Array.from(event.dataTransfer?.files || [])
          .filter(Boolean)
          .filter(({ type }) => imageFileTypeRegex.test(type))

        if (!imageFiles.length) return false

        event.preventDefault()

        const coordinates = view.posAtCoords(
          {
            left: event.clientX,
            top: event.clientY,
          },
        )

        if (!coordinates) return false

        trackEventInJune(JuneEvents.EDITOR_IMAGE_DROPPED)

        imageFiles.forEach(
          async (file) => {
            if (file.type === "image/svg+xml" && await handleSvg(file)) return

            const intermediateUuid = getUuid()

            const dataUrl = await readFileAndSaveDataUrl(file)

            editor.commands.setImage({
              src: "",
              intermediateUuid,
              dataUrl: dataUrl as string,
            })

            upload(file, intermediateUuid)
          },
        )

        return true
      },
    },
  })
}
