<script setup lang="ts">
// external
import { computed } from "vue"

// internal
import { CustomWidgetConfig } from "~/types"
import { DashboardDataNumberDisplay } from "~/components"

interface Props {
  data?: any[]
  config: CustomWidgetConfig
}

const props = withDefaults(
  defineProps<Props>(),
  {
    data: null,
  },
)

const transformedData = computed(() => {
  return props.data.reduce((accumulator, currentValue) => {
    const [ currency, amount ] = currentValue.split(";")

    if (!accumulator[currency]) {
      accumulator[currency] = []
    }

    accumulator[currency].push(Number(amount))
    return accumulator
  }, {})
})

</script>

<template>
  <div class="w-full flex flex-col grow">
    <div
      class="w-full grow flex divide-x divide-gray-200 justify-evenly"
    >
      <DashboardDataNumberDisplay
        v-for="(entryData, entryKey) in transformedData"
        :key="entryKey"
        :data="entryData"
        :config="config"
        :title="entryKey + ''"
      />
    </div>
  </div>
</template>
