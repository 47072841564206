import { VueNodeViewRenderer } from "@tiptap/vue-3"
import { mergeAttributes } from "@tiptap/core"
import TiptapImage, { ImageOptions } from "@tiptap/extension-image"

import { getImagePasteDropPlugin } from "./imagePasteDropPlugin"
import ResizableImageNodeView from "./ResizableImageNodeView.vue"

interface ResizableImageOptions extends Partial<ImageOptions> {
  upload: (file: File, intermediateUuid: string) => Promise<void>
}

export interface ImageAttrs {
  src: string;
  alt?: string;
  title?: string;
  refUuid: string
  intermediateUuid: string
  uuid: string
  dataUrl: string
  height: number
  width: number
  widthInPx: number
  dataAlign: string
  dataFloat: string
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    resizableImage: {
      /**
       * Add an image
       */
      setImage: (options: Partial<ImageAttrs>) => ReturnType;

      /**
       * update image url of image with intermediateUuid
       */
      updateImageAttrs: (intermediateUuid: string, updatedAttrs: Record<string, any>) => ReturnType

      /**
       * remove image with intermediateUuid
       */
      removeImage: (intermediateUuid: string) => ReturnType
    };
  }
}

export const ResizableImage = TiptapImage.extend<ResizableImageOptions>({
  name: "resizableImage",

  atom: true,

  addAttributes () {
    return {
      ...(this.parent?.() || {}),
      refUuid: {
        default: null,
        isRequired: true,
        parseHTML: (element) => parseInt(element.getAttribute("data-ref-uuid")),
        renderHTML: (attributes) => {
          if (!attributes.refUuid) return {}

          return {
            "data-ref-uuid": attributes.refUuid,
          }
        },
      },
      intermediateUuid: {
        default: "",
      },
      uuid: {
        default: "",
        isRequired: true,
      },
      dataUrl: {
        default: "",
        rendered: false,
      },
      width: {
        default: 1, // ratio of (image width / prosemirror width)
        parseHTML: (element) => parseFloat(element.getAttribute("data-width")),
        renderHTML: (attributes) => {
          if (!attributes.width) return {}

          return {
            "data-width": attributes.width,
          }
        },
      },
      widthInPx: {
        default: 0,
        parseHTML: (element) => parseFloat(element.getAttribute("data-width-in-px")),
        renderHTML: (attributes) => {
          if (!attributes.widthInPx) return {}

          return {
            "data-width-in-px": attributes.widthInPx,
          }
        },
      },
      alt: {
        default: "",
      },
      dataAlign: {
        default: "left",
      },
      dataFloat: {
        default: "",
      },
    }
  },

  addOptions () {
    return {
      ...(this.parent?.() || {}),
      upload: async () => { /* */ },
    }
  },

  renderHTML ({ HTMLAttributes }) {
    const copyHtmlAttrs = { ...HTMLAttributes }

    if (!copyHtmlAttrs.src) copyHtmlAttrs.isLoading = ""

    const src = copyHtmlAttrs.src || copyHtmlAttrs.dataUrl

    if (![ null, undefined ].includes(copyHtmlAttrs.dataUrl)) delete copyHtmlAttrs.dataUrl

    return [
      "img",
      mergeAttributes(
        this.options.HTMLAttributes,
        {
          ...copyHtmlAttrs,
          src,
          loading: "lazy",
        },
      ),
    ]
  },

  addStorage () {
    return {
      ...(this.parent?.() || {}),
      imageDataUrlMap: {},
    }
  },

  addCommands () {
    return {
      setImage: (options) => ({ commands }) => {
        // const emptyParagraph = {
        //   type: "paragraph",
        //   attrs: {
        //     numbering: false,
        //   },
        // }

        return commands.insertContent(
          [
            {
              type: this.name,
              attrs: {
                ...options,
                width: 1,
              },
            },
          ],
        )
      },

      updateImageAttrs: (intermediateUuid, updatedAttrs) =>
        ({ state, chain }) => {
          const img = { pos: -1, attrs: {} }

          state.doc.descendants(
            (
              { type, attrs },
              pos,
            ) => {
              if (type.name !== "resizableImage" || attrs?.intermediateUuid !== intermediateUuid) return

              img.pos = pos
              img.attrs = attrs
            },
          )

          if (img.pos === -1) return false

          return chain()
            .command(({ tr }) => {
              tr.setNodeMarkup(
                img.pos,
                null,
                {
                  ...img.attrs,
                  ...updatedAttrs,
                },
              )

              return true
            })
            .run()
        },

      removeImage: (intermediateUuid) =>
        ({ state, chain }) => {
          let posOfImage = -1

          state.doc.descendants(
            (
              { type, attrs },
              pos,
            ) => {
              if (type.name !== "resizableImage" || attrs?.intermediateUuid !== intermediateUuid) return

              posOfImage = pos
            },
          )

          if (posOfImage === -1) return false

          return chain()
            .command(({ tr }) => {
              tr.delete(posOfImage, posOfImage + 1)

              return true
            })
            .run()
        },
    }
  },

  addNodeView () {
    return VueNodeViewRenderer(ResizableImageNodeView)
  },

  addProseMirrorPlugins () {
    const { editor } = this
    const { upload } = this.options

    return [
      // causing error: RangeError: Adding different instances of a keyed plugin (plugin$)
      //...(this.parent?.() || []),
      ...(
        upload
          ? [ getImagePasteDropPlugin(editor, upload) ]
          : []
      ),
    ]
  },
})
