<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from "vue"

import { useI18n } from "vue-i18n"

import { MinusIcon, PlusIcon } from "@heroicons/vue/24/solid"

import { Editor, EditorEvents } from "@tiptap/core"

// internal
import { FONT_SIZES } from "../fontSize"

interface Props {
  editor: Editor
}
const props = defineProps<Props>()

const { t } = useI18n()

const activeFontSize = ref<number>(12)

const setFontSize = (fontSize: number | null = null) => {
  const chain = props.editor.chain()

  return fontSize
    ? chain.setFontSize(fontSize).run()
    : chain.unsetFontSize().focus().run()
}

const increaseFontSize = () => {
  const currentFontSize = activeFontSize.value

  if (!currentFontSize) return

  const fontSizeIndex = FONT_SIZES.indexOf(currentFontSize)

  if (fontSizeIndex !== -1) {
    setFontSize(FONT_SIZES[fontSizeIndex + 1])

    return
  }

  const closestLargerFontSize = FONT_SIZES.find((size) => size > currentFontSize)

  setFontSize(closestLargerFontSize)
}

const decreaseFontSize = () => {
  const currentFontSize = activeFontSize.value

  if (!currentFontSize) return

  const fontSizeIndex = FONT_SIZES.indexOf(currentFontSize)

  if (fontSizeIndex !== -1) {
    setFontSize(FONT_SIZES[fontSizeIndex - 1])

    return
  }

  const closestSmallerFontSize = FONT_SIZES.toReversed().find((size) => size < currentFontSize)

  setFontSize(closestSmallerFontSize)
}

const onUpdateHandler = () => activeFontSize.value = props.editor.getAttributes("textStyle")?.fontSize ?? 12

const events: (keyof EditorEvents)[] = [ "update", "selectionUpdate" ]

onMounted(
  () => {
    for (const e of events) props.editor.on(e, onUpdateHandler)

    onUpdateHandler()
  },
)

onBeforeUnmount(() => {
  for (const e of events) props.editor.off(e, onUpdateHandler)
})
</script>

<template>
  <article class="flex items-center space-x-px">
    <button
      class="h-full btn-editor-bubble-menu"
      @click="decreaseFontSize"
    >
      <MinusIcon
        class="text-slate-200 mx-auto w-3.5 h-3.5"
      />
    </button>

    <section class="h-full group">
      <input
        type="number"
        class="block w-8 h-full p-0 text-center input-slate"
        :value="activeFontSize"
        @keypress.enter="setFontSize(($event.target as any).value)"
      >

      <section
        class="absolute z-50 flex-col hidden overflow-y-auto rounded-md max-h-64 bg-slate-800 popover popover-slate group-focus-within:flex"
      >
        <button
          v-if="activeFontSize !== 12"
          class="px-4 py-1 text-white cursor-pointer hover:bg-slate-700"
          @click="setFontSize()"
        >
          {{ t('commandMenu.defaultFontSize') }}
        </button>

        <button
          v-for="size in FONT_SIZES"
          :key="size"
          class="px-4 py-1 text-white cursor-pointer hover:bg-slate-700"
          :class="{
            'bg-slate-600 hover:bg-slate-600': activeFontSize === size
          }"
          @click="setFontSize(size)"
        >
          {{ size }}
        </button>
      </section>
    </section>

    <button
      class="h-full btn-editor-bubble-menu"
      @click="increaseFontSize"
    >
      <PlusIcon
        class="text-slate-200 mx-auto w-3.5 h-3.5"
      />
    </button>
  </article>
</template>
