<script setup lang="ts">
// external
import { toRaw, ref, computed, watch } from "vue"
import { storeToRefs } from "pinia"
import { without } from "lodash-es"
import { useI18n } from "vue-i18n"
import { TrashIcon, EnvelopeIcon } from "@heroicons/vue/24/outline"
import { EyeIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"

// internal
import { useDocumentStore, useNotificationStore, usePartyStore, useSharedStore, useUserStore } from "~/stores"
import { CrudContext, Document, DocumentUser, DocumentUserRoleEnum, DocumentVisit, Party, Template } from "~/types"
import { documentUserInvitationStatuses, formatDateAndTime, formatDateRelative, getUserRepresentation } from "~/utils"
import { FormInputErrors, ResendInvitationButton, SpinLoader, UserPartySettings } from "~/components"
import { SignatureIcon } from "~/icons"

interface Props {
  user: DocumentUser
  document?: Document
}

const props = withDefaults(defineProps<Props>(), {
  user: null,
  document: null,
})

const emit = defineEmits( [ "close" ])

const { t } = useI18n()

const userStore = useUserStore()
const { users, uuidsOfUpdatingUser, userErrorsMap, userUuidBeingRemovedFromEntity, uuidsOfIsLoadingSendInvitation } = storeToRefs(userStore)
const { sendUserInvitation, updateLocalUser, getUserNotifications, removeUserFromEntity } = userStore

const documentStore = useDocumentStore()
const { mau, mdu, documentEditorSession, currentDocument, documentVisits } = storeToRefs(documentStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const { notify } = useNotificationStore()

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const uuidsOfIsRemovingSignatoryAsCollaborator = ref<DocumentUser["uuid"][]>([])
const idxOfIsCreatingUser = ref<number[]>([])

// Method to update the party of an existing document user
const handleUpdatePartyOfExistingUser = async (userUuid: DocumentUser["uuid"], partyUuid: Party["uuid"]) => {
  const user = users.value?.find((el) => el.uuid === userUuid)
  if (!user) return
  const payload = { ...toRaw(user), party_uuid: partyUuid }
  const res = await updateLocalUser(payload, crudContext.value, entityUuid.value, false, true)
  if (res) return true
}

// Check if there is at least one remaining owner
const isLastOwner = computed(() => {
  if (!users.value) return false
  const owners = users.value.filter((el) => el.roles?.includes(DocumentUserRoleEnum.owner))
  return owners.length < 2
})

// Method to toggle the role of an existing document user between owner and collaborator
const toggleOwner = async (userUuid: DocumentUser["uuid"]) => {
  const user = users.value?.find((el) => el.uuid === userUuid)
  if (!user || user?.uuid === mdu.value?.uuid) return
  const isOwner = user.roles?.includes(DocumentUserRoleEnum.owner)
  if (isLastOwner.value && isOwner) return
  const rolesWithoutCollaboratorAndOwner = without(toRaw(user.roles), DocumentUserRoleEnum.collaborator, DocumentUserRoleEnum.owner) || []
  const newRoles = isOwner ? [ ...rolesWithoutCollaboratorAndOwner, DocumentUserRoleEnum.collaborator ] : [ ...rolesWithoutCollaboratorAndOwner, DocumentUserRoleEnum.owner ]
  const payload = { ...toRaw(user), roles: newRoles }
  const res = await updateLocalUser(payload, crudContext.value, entityUuid.value, false, true)
  if (res) return true
}

// Function to remove a user from an entity
const removeUser = async (userUuid: DocumentUser["uuid"]) => {
  if (!entityUuid.value || !crudContext.value) return

  // destroy active editor session
  if (!!documentEditorSession.value && userUuid === mdu.value?.uuid) {
    documentStore.destroyDocumentEditorSession(currentDocument.value.uuid, documentEditorSession.value.uuid)
  }

  // If the user also has the role signatory, remove only the collaborator role
  const signatory = users.value?.find((el) => el.uuid === userUuid && el.roles.includes(DocumentUserRoleEnum.signatory))
  if (signatory) {
    uuidsOfIsRemovingSignatoryAsCollaborator.value.push(signatory.uuid)
    const newRoles = without(toRaw(signatory.roles), DocumentUserRoleEnum.collaborator) || []
    const payload = { ...signatory, roles: newRoles }
    try {
      const res = await updateLocalUser(payload, crudContext.value, entityUuid.value, false, true)
      if (res) uuidsOfIsRemovingSignatoryAsCollaborator.value = without(uuidsOfIsRemovingSignatoryAsCollaborator.value, signatory.uuid)
    } catch (err) {
      userStore.setUserErrors("0", err.response?.data?.errors || null)

      notify({
        title: t("sharingModal.errors.removeUserAsCollaborator"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
      uuidsOfIsRemovingSignatoryAsCollaborator.value = without(uuidsOfIsRemovingSignatoryAsCollaborator.value, signatory.uuid)
    } finally {
      return
    }
  }
  // Otherwise remove the user from the document entirely
  removeUserFromEntity(crudContext.value, entityUuid.value, userUuid)
}

// Method to check if a visibleUser has a corresponding documentUser with the signatory role
const isSignatory = (documentUser: DocumentUser) => {
  const filteredUser = users.value?.find((du) => du.uuid && du.uuid === documentUser.uuid && du.email === documentUser.email)
  return filteredUser?.roles?.includes(DocumentUserRoleEnum.signatory)
}

const getParty = (partyUuid: Party["uuid"]) => parties.value?.find((el) => el.uuid === partyUuid)

watch(mdu, (newVal) => {
  if (!newVal) {
    emit("close")
  }
})

const getUserBackendErrors = (userUuid: DocumentUser["uuid"]) : Partial<Record<keyof DocumentUser, string[]>> => {
  const errors: Partial<Record<keyof DocumentUser, string[]>> = {}
  const backendErrors = userErrorsMap.value[userUuid] || {}
  Object.keys(backendErrors)
    .forEach(
      (key) => {
        errors[key] = [ ...(errors[key] || []), ...(backendErrors[key] || []) ]
      },
    )
  Object.keys(errors)
    .forEach(
      (key) => {
        if (errors[key].length === 0) delete errors[key]
      },
    )
  return errors
}

const userDocumentVisits = computed(() => {
  return documentVisits.value?.filter((el: DocumentVisit) => el.created_by_document_user_uuid === props.user.uuid)
})

</script>
<template>
  <div
    :id="'userInvitedContainer_' + user.uuid"
    :key="user.uuid"
    class="px-6 -mx-6 -mt-2"
    data-cy-sel="invited-users-container"
    :class="!mau && mdu?.uuid === user.uuid ? 'opacity-50 pointer-events-none' : ''"
  >
    <div
      class="relative items-center justify-between w-full py-2 space-x-3 sm:flex"
    >
      <div class="flex items-center space-x-3">
        <img
          class="w-8 h-8 rounded-full shrink-0"
          :src="user?.profile_photo_url"
          alt=""
        >
        <div class="relative flex-1">
          <h3
            class="flex items-center space-x-2"
          >
            <span
              :title="getUserRepresentation(user)"
            >{{ getUserRepresentation(user) + (user.first_name && user.last_name && !user.account_user?.uuid ? ` (${user.email})` : '') }}</span>

            <SpinLoader
              v-if="!uuidsOfIsLoadingSendInvitation?.length && idxOfIsCreatingUser?.length && uuidsOfIsLoadingSendInvitation.includes(user.uuid)"
              class="w-3 h-3 text-gray-400"
            />
          </h3>
          <template v-if="(!!mau || mdu?.uuid !== user.uuid) && crudContext === CrudContext.document">
            <div
              v-if="getUserNotifications(user, documentUserInvitationStatuses)?.length || user.account_user?.uuid"
              class="flex flex-wrap items-center -mt-0.5 gap-1"
            >
              <template v-if="getUserNotifications(user, documentUserInvitationStatuses)?.length">
                <div class="text-xs text-gray-500">
                  {{ $t('documentSharingModal.sent') }}
                  {{
                    formatDateRelative(
                      getUserNotifications(user, documentUserInvitationStatuses)?.[
                        getUserNotifications(user, documentUserInvitationStatuses)?.length-1
                      ]?.created_at)
                  }}
                </div>
                <div
                  v-if="userDocumentVisits?.length"
                  class="ml-1 text-xs text-gray-500"
                  data-tippy-help
                  data-placement="top"
                  :data-tippy-content="$t('documentSharingModal.lastOpenedAt', { date: formatDateAndTime(userDocumentVisits?.[0]?.created_at), count: userDocumentVisits?.length })"
                >
                  <EyeIcon
                    class="w-3 h-3"
                    aria-hidden="true"
                  />
                </div>
                <template v-if="mdu?.permissions?.includes('document_user_invitation_manage')">
                  <ResendInvitationButton
                    :is-sharing-modal="true"
                    :user="user"
                  />
                </template>
              </template>
              <div
                v-else-if="user.account_user?.uuid"
                class="text-xs text-gray-500"
              >
                {{ $t('documentSharingModal.claimedAccess') }}
              </div>
              <template v-if="mdu?.roles?.includes(DocumentUserRoleEnum.owner) && !!user.account_user?.uuid && user.uuid !== mdu?.uuid">
                <button
                  type="button"
                  :disabled="uuidsOfUpdatingUser?.includes(user.uuid) || (user.roles?.includes(DocumentUserRoleEnum.owner) && isLastOwner)"
                  class="px-1 py-0 text-xs text-indigo-500 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
                  @click="toggleOwner(user.uuid)"
                >
                  {{ uuidsOfUpdatingUser?.includes(user.uuid) ? $t('common.pleaseWait')+'…' : user.roles?.includes(DocumentUserRoleEnum.owner) ? $t('documentSharingModal.revokeOwnership') : $t('documentSharingModal.makeOwner') }}
                </button>
              </template>
            </div>
            <div
              v-else-if="!user?.account_user?.uuid && !idxOfIsCreatingUser?.length"
              class="-ml-1 -mt-0.5"
            >
              <button
                v-if="!uuidsOfIsLoadingSendInvitation.includes(user.uuid)"
                type="button"
                class="flex items-center gap-1 px-1 py-0 text-xs text-indigo-500 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
                @click="sendUserInvitation(currentDocument?.uuid, user.uuid)"
              >
                <EnvelopeIcon class="w-3 h-3" />
                {{ $t('documentSharingModal.sendInvitation') }}
              </button>
              <SpinLoader
                v-else
                class="w-3 h-3 text-gray-400"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="flex items-center pl-8 mt-2 mb-3 space-x-3 sm:pl-0 sm:mt-0 sm:mb-0">
        <template v-if="!!mau">
          <div
            v-if="user && isSignatory(user) && getParty(user.party_uuid)"
            class="inline-flex items-center flex-grow gap-2 cursor-not-allowed sm:flex-grow-0"
          >
            <SignatureIcon class="w-4 h-4 text-indigo-600 shrink-0" />
            <span class="grow truncate max-w-[10rem]">
              {{ getParty(user.party_uuid).name || getParty(user.party_uuid).entity_name }}
            </span>
            <span
              data-tippy-help
              :data-tippy-content="$t('documentSharingModal.signatoryExplanation')"
              data-placement="bottom"
            >
              <QuestionMarkCircleIcon
                class="w-4 h-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </div>
          <UserPartySettings
            v-else-if="user.roles?.length"
            class="flex-grow sm:flex-grow-0"
            :user="user"
            :hide-label="true"
            :is-disabled="uuidsOfUpdatingUser.includes(user.uuid) || !mdu?.permissions.includes('document_user_update')"
            :party-uuid="user.party_uuid"
            @update:party-uuid="handleUpdatePartyOfExistingUser(user.uuid, $event)"
          />
        </template>
        <SpinLoader
          v-if="uuidsOfUpdatingUser.includes(user.uuid) || userUuidBeingRemovedFromEntity === user.uuid"
          class="w-4 h-4 text-gray-400"
        />
        <span
          v-else-if="(mau || mdu?.uuid !== user.uuid) && mdu?.permissions?.includes('document_user_delete')"
          data-tippy-help
          :data-tippy-content="user.roles.includes(DocumentUserRoleEnum.owner) ? $t('documentSharingModal.ownersCannotBeRemoved') : ''"
          data-placement="top"
          class="flex items-center"
        >
          <button
            :disabled="user.roles.includes(DocumentUserRoleEnum.owner)"
            class="p-0 text-gray-400 btn-plain hover:text-gray-600"
            @click.prevent="removeUser(user.uuid)"
          >
            <TrashIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </button>
        </span>
      </div>
    </div>
    <FormInputErrors
      v-if="getUserBackendErrors(user.uuid)?.party_uuid?.length"
      :errors="getUserBackendErrors(user.uuid)?.party_uuid"
    />
    <FormInputErrors
      v-if="getUserBackendErrors(user.uuid)?.roles?.length"
      :errors="getUserBackendErrors(user.uuid)?.roles"
    />
  </div>
</template>
