<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"

import { PencilIcon } from "@heroicons/vue/24/solid"

import { Editor as TiptapEditor } from "@tiptap/core"

// internal
import { DialogModal, SpinLoader, WarningBox } from "~/components"
import { CommentEditor, Editor, changeTrack } from "~/editor"
import { useCommentStore, useDocumentStore, useDynamicFieldStore, useEditorStore, usePartyStore, useProposalStore, useUserStore } from "~/stores"
import { Comment, Document, DocumentTab, DocumentUser } from "~/types"
import { usePage } from "@inertiajs/vue3"
import { watch } from "vue"
import { nextTick } from "vue"
import { JSONContent } from "@tiptap/core"
import { isEqual } from "lodash-es"
import { ChatBubbleLeftEllipsisIcon, EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/outline"
import { getUserRepresentation } from "~/utils"
import { Node } from "@tiptap/pm/model"

interface Props {
  document: Document
  isLoadingApplyProposal?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isLoadingApplyProposal: false,
  },
)

const pageProps = usePage().props as any

const isUserInternal = computed(() => !!pageProps.mau)

const emit = defineEmits([ "apply-proposal" ])

const partyStore = usePartyStore()
const {
  parties,
} = storeToRefs(partyStore)

const dynamicFieldStore = useDynamicFieldStore()
const {
  dynamicFields,
} = storeToRefs(dynamicFieldStore)

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const proposalStore = useProposalStore()
const {
  prosemirrorDataUuid,
  showProposalModal,
  proposedEditorContent,
  resolveProposal,
} = storeToRefs(proposalStore)
const {
  setShowProposalModal,
  setProposedEditorContent,
} = proposalStore

const commentStore = useCommentStore()
const {
  createComment,
  updateComment,
  highlightCommentsInListByProsemirrorDataUuid,
  setHighlightedCommentUuids,
  setActiveCommentProsemirrorDataUuid,
} = commentStore

const documentStore = useDocumentStore()
const { mau, mdu, documentEditorSession, isLockedDocument } = storeToRefs(documentStore)
const {
  setActiveTabKey,
} = documentStore

const editorStore = useEditorStore()
const { editor: mainEditor, isDirty } = storeToRefs(editorStore)

const canResolve = computed(() => mau.value?.permissions.includes("comment_resolve_proposal") && !!mdu.value)

const proposedProsemirrorData = computed(
  {
    get: () => proposedEditorContent.value,
    set: (value) => setProposedEditorContent(value),
  },
)

const countOfAddedDynamicFields = ref(0)
const countOfRemovedDynamicFields = ref(0)

const processAddedAndRemovedDynamicFields = () => {
  // Get the current editor instance (proposalEditor)
  const e = proposalEditor.value

  // If the editor instance or the mainEditor is not available, exit the function
  if (!e || !mainEditor.value) return

  // Initialize an object to store the count of dynamic fields in the main editor by their UUID
  const mainEditorDynamicFieldRefUuidCountMap: Record<string, number> = {}

  let mainNode = null

  // Get the content of the proposal editor as JSON
  const proposalContent = e.getJSON()

  // Iterate over the descendants of the document in the main editor to find the matching node
  mainEditor.value.state.doc.descendants(
    (node: Node) => {

      // Get the uuid of the proposal node by either the first content node or the proposal ref_uuid
      const proposalUuid = proposalContent.content[0]?.attrs?.uuid || prosemirrorDataUuid.value

      // If the mainNode is already found or the node's UUID doesn't match the proposal content, skip this iteration
      if (mainNode || node.attrs.uuid !== proposalUuid) return

      // Assign the matching node to mainNode
      mainNode = node
    },
  )

  // If the mainNode is found, iterate over its descendants to count dynamic fields by their UUID
  mainNode?.descendants(
    (node: Node) => {
      // If the node is not of type "dynamicField" or "party", skip this iteration
      if (node.type.name !== "dynamicField" && node.type.name !== "party") return

      // Increment the count of the dynamic field in the map, or initialize it if not present
      if (mainEditorDynamicFieldRefUuidCountMap[node.attrs.refUuid]) {
        mainEditorDynamicFieldRefUuidCountMap[node.attrs.refUuid] += 1
      } else {
        mainEditorDynamicFieldRefUuidCountMap[node.attrs.refUuid] = 1
      }
    },
  )

  // Initialize an object to store the count of dynamic fields in the proposal editor by their reference UUID
  const proposalEditorDynamicFieldRefUuidsCountMap: Record<string, number> = {}

  // Accept all tracked changes in the proposal editor to get a consistent state
  const trackChangesAcceptedState = changeTrack("accept-all", e as any)

  // If track changes return a boolean, exit the function (indicating no changes to track)
  if (typeof trackChangesAcceptedState === "boolean") return

  // Iterate over the descendants of the document in the accepted state to count dynamic fields by their reference UUID
  trackChangesAcceptedState.doc.descendants(
    (node) => {
      // If the node is not of type "dynamicField" or "party", skip this iteration
      if (node.type.name !== "dynamicField" && node.type.name !== "party") return

      // Increment the count of the dynamic field in the map, or initialize it if not present
      if (proposalEditorDynamicFieldRefUuidsCountMap[node.attrs.refUuid]) {
        proposalEditorDynamicFieldRefUuidsCountMap[node.attrs.refUuid] += 1
      } else {
        proposalEditorDynamicFieldRefUuidsCountMap[node.attrs.refUuid] = 1
      }
    },
  )

  // Initialize counters for added and removed dynamic fields
  let addedDynamicFields = 0
  let removedDynamicFields = 0

  // Compare dynamic fields in the proposal editor with those in the main editor to find added fields
  for (const [ refUuid, count ] of Object.entries(proposalEditorDynamicFieldRefUuidsCountMap)) {
    // If the field exists in the proposal editor but not in the main editor, it's added
    if (!mainEditorDynamicFieldRefUuidCountMap[refUuid]) {
      addedDynamicFields += count
    }
    // If the field count is higher in the proposal editor, the difference represents added fields
    else if (mainEditorDynamicFieldRefUuidCountMap[refUuid] < count) {
      addedDynamicFields += count - mainEditorDynamicFieldRefUuidCountMap[refUuid]
    }
  }

  // Compare dynamic fields in the main editor with those in the proposal editor to find removed fields
  for (const [ refUuid, count ] of Object.entries(mainEditorDynamicFieldRefUuidCountMap)) {
    // If the field exists in the main editor but not in the proposal editor, it's removed
    if (!proposalEditorDynamicFieldRefUuidsCountMap[refUuid]) {
      removedDynamicFields += count
    }
    // If the field count is lower in the proposal editor, the difference represents removed fields
    else if (proposalEditorDynamicFieldRefUuidsCountMap[refUuid] < count) {
      removedDynamicFields += count - proposalEditorDynamicFieldRefUuidsCountMap[refUuid]
    }
  }

  // Update the global count of added and removed dynamic fields
  countOfAddedDynamicFields.value = addedDynamicFields
  countOfRemovedDynamicFields.value = removedDynamicFields
}

const close = () => {
  addComment.value = false
  setHighlightedCommentUuids([])
  setActiveCommentProsemirrorDataUuid("")
  setShowProposalModal(false)
}

const proposalEditor = ref<TiptapEditor>(null)

const setEditor = (data: TiptapEditor) => {
  proposalEditor.value = data
  proposalEditor.value.on("update", processAddedAndRemovedDynamicFields)
  countOfAddedDynamicFields.value = 0
  countOfRemovedDynamicFields.value = 0
  processAddedAndRemovedDynamicFields()
}

const proposalButtonChoice = ref<"accept" | "reject">(null)

const handleResolveProposal = async (accept: boolean) => {
  proposalButtonChoice.value = accept ? "accept" : "reject"

  if (accept) {
    const trackChangesAcceptedState = changeTrack("accept-all", proposalEditor.value as any)

    const jsonData = typeof trackChangesAcceptedState !== "boolean"
      ? trackChangesAcceptedState.doc.toJSON()
      : proposalEditor.value.getJSON()

    if (jsonData) {
      emit(
        "apply-proposal",
        {
          data: jsonData,
          uuid: resolveProposal.value.prosemirror_data_uuid,
          commentUuid: resolveProposal.value.uuid,
        },
      )
    } else {
      console.error(
        "Error reading editor JSON content:" + jsonData,
        {
          trackChangesAcceptedState,
          editor: proposalEditor.value,
        },
      )
    }
  } else {
    const updatedComment = await updateComment(
      props.document.uuid,
      resolveProposal.value.uuid,
      {
        proposal_status: "rejected",
      },
    )

    if (!updatedComment) return

    setHighlightedCommentUuids(null)
    setActiveCommentProsemirrorDataUuid("")
    setShowProposalModal(false)
    addComment.value = false
  }

  proposalButtonChoice.value = null
}


const isExternalScope = ref(true)

const isLoadingSubmitProposal = ref<boolean>(false)

const commentContent = computed(() => {
  let content = resolveProposal.value?.html
  if (!content) return ""
  if (resolveProposal.value?.mentioned_document_user_uuids?.length) {
    for (let i = 0; i < resolveProposal.value?.mentioned_document_user_uuids.length; i++) {
      const mentionedUser = users.value.find((user) => user.uuid === resolveProposal.value?.mentioned_document_user_uuids[i])
      if (mentionedUser) {
        content = content.replaceAll(`@${resolveProposal.value?.mentioned_document_user_uuids[i]}`, getUserRepresentation(mentionedUser))
      }
    }
  }

  return content
})

const submitProposal = async () => {
  let mentionedDocumentUserUuids: DocumentUser["uuid"][] | null
  let html: string | null

  if (commentEditor.value) {
    mentionedDocumentUserUuids = commentEditor.value?.mentionedDocumentUserUuids
    html = commentEditor.value?.getEditorHtml()
  }

  isLoadingSubmitProposal.value = true
  const newComment: Partial<Comment> = {}

  newComment.type = "proposal"
  newComment.scope = isUserInternal.value ? (isExternalScope.value ? "internal_and_external" : "internal") : "internal_and_external"
  newComment.prosemirror_data_uuid = prosemirrorDataUuid.value
  if (mentionedDocumentUserUuids) newComment.mentioned_document_user_uuids = mentionedDocumentUserUuids
  if (html) newComment.html = html

  const jsonData = proposalEditor.value.getJSON()

  if (!!jsonData) {
    newComment.prosemirror_data = jsonData

    const createdComment = await createComment(props.document.uuid, newComment)

    if (createdComment) {
      setActiveTabKey(DocumentTab.discussions)
      highlightCommentsInListByProsemirrorDataUuid(createdComment.uuid)
      setShowProposalModal(false)
      addComment.value = false
    }
  } else {
    console.error("Error reading editor JSON content:" + jsonData)
  }

  isLoadingSubmitProposal.value = false
}

const acceptButton = ref()

watch(documentEditorSession, () => {
  nextTick(() => {
    if (acceptButton.value?._tippy) {
      acceptButton.value?._tippy.setContent(acceptButton.value?.getAttribute("data-tippy-content"))
    }
  })
})

const isDisabledAcceptProposal = computed(() => {
  if (!resolveProposal.value) return false
  if (isLockedDocument.value) return true
  if (documentEditorSession.value?.uuid && (documentEditorSession.value?.document_user_uuid !== mdu.value?.uuid || isDirty.value)) return true
  return false
})

const isDisabledSubmitProposal = computed(() => {
  if (isLockedDocument.value) return true
  // Check if JSON data of proposal is different from original
  if (isEqual(proposedProsemirrorData.value, localProposedEditorContent.value)) return true
  return false
})

// Set local copy of proposedEditorContent onMounted
const localProposedEditorContent = ref<JSONContent>(null)
watch(showProposalModal, () => {
  localProposedEditorContent.value = proposedProsemirrorData.value
})

watch(() => resolveProposal.value?.uuid, () => {
  addComment.value = false
})

const commentEditor = ref()
const addComment = ref(false)

</script>
<template>
  <DialogModal
    id="proposalModal"
    :show="showProposalModal"
    :show-close-button="true"
    :max-width="addComment || resolveProposal?.html ? '6xl' : '4xl'"
    :show-header="true"
    :show-footer="true"
    @close="close"
  >
    <template #title>
      <span v-if="!resolveProposal?.uuid">{{ $t('proposal.titleNew') }}</span>
      <span v-else-if="!canResolve && resolveProposal?.uuid">{{ $t('proposal.pendingProposal') }}</span>
      <span v-else-if="resolveProposal?.proposal_status === 'accepted'">{{ $t('proposal.acceptedProposal') }}</span>
      <span v-else-if="resolveProposal?.proposal_status === 'rejected'">{{ $t('proposal.rejectedProposal') }}</span>
      <span v-else>{{ $t('proposal.title') }}</span>
    </template>

    <template
      #content
    >
      <div
        :class="[!canResolve && resolveProposal?.uuid ? '' : 'pb-3', addComment || resolveProposal?.html ? 'lg:flex space-y-8 lg:space-y-0' : '']"
        class="px-6 py-4 -mx-6 -mb-4 bg-gray-100"
      >
        <div class="z-20 flex flex-col gap-4 lg:max-w-2xl xl:max-w-3xl lg:mx-auto grow">
          <div class="flex items-center justify-between gap-4">
            <h3 class="flex items-center space-x-2 text-xs font-normal tracking-wider text-gray-500 uppercase">
              <PencilIcon
                class="w-4 h-4 text-gray-400"
                aria-hidden="true"
              />
              <span>
                {{ $t(resolveProposal?.uuid ? 'proposal.proposedChanges' : 'proposal.yourProposal') }}:
              </span>
            </h3>
            <button
              v-if="!addComment && !resolveProposal?.uuid"
              class="inline-flex items-center gap-1.5 -my-1.5 font-medium text-indigo-500 bg-transparent btn-sm btn-plain hover:text-indigo-700 hover:bg-indigo-100 focus:bg-indigo-100 focus:ring-indigo-100"
              type="button"
              @click.prevent="addComment = true"
            >
              <ChatBubbleLeftEllipsisIcon
                class="w-4 h-4"
                aria-hidden="true"
              />
              {{ $t('proposal.addComment') }}
            </button>
          </div>

          <div
            id="proposalContainer"
            class="z-10 bg-white shadow-lg lg:h-full"
          >
            <Editor
              id="proposalEditor"
              ref="ProposalEditor"
              v-model:editor-content="proposedProsemirrorData"
              :document-commenting="false"
              :editing="true"
              :show-toolbar="false"
              document-type="document"
              editor-context="proposal"
              :dynamic-fields="dynamicFields"
              :parties="parties"
              @mounted="setEditor"
            />
          </div>

          <template
            v-if="isUserInternal && countOfRemovedDynamicFields > 0"
          >
            <WarningBox :text="$t('discussions.removedDynamicFieldsWarning', { count: countOfRemovedDynamicFields })" />
          </template>
        </div>
        <div
          v-show="addComment || resolveProposal?.html"
          class="z-10 flex flex-col gap-4 shrink-0 min-w-96"
        >
          <h3 class="flex items-center space-x-2 text-xs font-normal tracking-wider text-gray-500 uppercase">
            <ChatBubbleLeftEllipsisIcon
              class="w-4 h-4 text-gray-400"
              aria-hidden="true"
            />
            <span>
              {{ $t(resolveProposal?.uuid ? 'proposal.comment' : 'proposal.addComment') }}:
            </span>
          </h3>

          <CommentEditor
            v-if="!resolveProposal?.uuid && addComment"
            ref="commentEditor"
            class="p-3 text-sm bg-white hyphens-auto focus:outline-none focus:ring-0 rounded-t-md"
            :prosemirror-data-uuid="prosemirrorDataUuid"
            :is-only-editor="true"
            :document-uuid="props.document.uuid"
          />
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-else
            class="z-0 h-full p-6 text-sm whitespace-pre-wrap bg-white border-l border-gray-200 border-dashed shadow-lg lg:h-full rounded-l-md lg:rounded-l-none rounded-r-md"
            v-html="commentContent"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </template>

    <template #footer>
      <div
        class="flex items-center space-x-4"
        :class="isUserInternal && !resolveProposal?.uuid ? 'justify-between' : 'justify-end'"
      >
        <div
          v-if="isUserInternal && !resolveProposal?.uuid"
          class="flex justify-end"
        >
          <button
            type="button"
            class="btn-plain"
            :class="isExternalScope ? 'text-indigo-500 hover:text-indigo-700 font-medium bg-transparent hover:bg-indigo-100 focus:bg-indigo-100 focus:ring-indigo-100' : 'text-gray-500 hover:text-gray-700 bg-transparent hover:bg-gray-200 focus:bg-gray-100 focus:ring-gray-100 font-medium'"
            @click.prevent="isExternalScope = !isExternalScope"
          >
            <div
              v-if="isExternalScope"
              class="flex items-center gap-1.5"
            >
              <EyeIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              <span class="block truncate">
                {{ $t('discussions.visibleToExternalUsers') }}
              </span>
            </div>
            <div
              v-else
              class="flex items-center gap-1.5"
            >
              <EyeSlashIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              <span class="block truncate">
                {{ $t('discussions.internalComment') }}
              </span>
            </div>
          </button>
        </div>
        <div class="flex items-center justify-end space-x-2">
          <button
            type="button"
            class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
            @click.prevent="close"
          >
            {{ canResolve && !resolveProposal?.is_resolved ? $t('common.cancel') : $t('common.close') }}
          </button>
          <div
            v-if="(canResolve || !resolveProposal?.uuid) && (!resolveProposal || !resolveProposal.is_resolved)"
            class="flex items-center space-x-2"
          >
            <button
              v-if="resolveProposal?.uuid"
              type="button"
              class="inline-flex items-center gap-2 bg-red-500 btn-primary hover:bg-red-600 focus:ring-red-400"
              @click.prevent="handleResolveProposal(false)"
            >
              <SpinLoader
                v-if="proposalButtonChoice === 'reject'"
                class="w-5 h-5 shrink-0"
              />
              {{ $t('proposal.reject') }}
            </button>
            <button
              ref="acceptButton"
              type="button"
              :data-tippy-help="isDisabledAcceptProposal ? true : null"
              :data-tippy-content="isDisabledAcceptProposal ? $t('discussions.proposalCannotBeAcceptedReasons') : null"
              data-placement="top"
              :disabled="!resolveProposal?.uuid && isDisabledSubmitProposal"
              :class="
                resolveProposal?.uuid
                  ? isDisabledAcceptProposal
                    ? 'opacity-50 bg-green-500 cursor-not-allowed hover:bg-green-500 focus:ring-transparent'
                    : 'bg-green-500 hover:bg-green-600 focus:ring-green-400'
                  : ''
              "
              class="inline-flex items-center gap-2 btn-primary"
              @click.prevent="
                resolveProposal?.uuid ? isDisabledAcceptProposal ? null : handleResolveProposal(true) : submitProposal()
              "
            >
              <SpinLoader
                v-if="isLoadingApplyProposal || proposalButtonChoice === 'accept' || isLoadingSubmitProposal"
                class="w-5 h-5 shrink-0"
              />
              <span v-if="resolveProposal?.uuid">{{ $t('proposal.saveResolve') }}</span>
              <span v-else>{{ $t('proposal.submitProposal') }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
