import type { CommandProps, JSONContent, KeyboardShortcutCommand } from "@tiptap/core"
import { mergeAttributes, Node, findParentNodeClosestToPos, Predicate } from "@tiptap/core"
import type { NodeType, Node as ProseMirrorNode } from "@tiptap/pm/model"
import { NodeSelection, TextSelection } from "@tiptap/pm/state"

import { Column } from "./column"
import { ColumnSelection } from "./columnSelection"
import { buildColumn, buildColumnBlock, buildNColumns, emptyColumn } from "./utils"
import { trackEventInJune } from "~/utils"
import { JuneEvents } from "~/types"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    columnBlock: {
      setColumns: (columns: number) => ReturnType;
      unsetColumns: () => ReturnType;
      insertColumnToRight: () => ReturnType;
    };
  }
}

export interface ColumnBlockOptions {
  nestedColumns: boolean;
  columnType: Node;
}

export const ColumnBlock = Node.create<ColumnBlockOptions>(
  {
    name: "columnBlock",

    content: "column{2,4}",

    isolating: true,

    selectable: true,

    addOptions () {
      return {
        nestedColumns: false,
        columnType: Column,
      }
    },

    parseHTML () {
      return [
        {
          tag: "div.custom-column-block",
        },
      ]
    },

    renderHTML ({ HTMLAttributes }) {
      const attrs = mergeAttributes(
        HTMLAttributes,
        {
          class: "custom-column-block",
        },
      )

      return [
        "div",
        attrs,
        0,
      ]
    },

    addCommands () {
      const unsetColumns =
      () =>
        ({ tr, dispatch }: CommandProps) => {
          try {
            if (!dispatch) {
              return
            }

            // find the first ancestor
            const pos = tr.selection.$from
            const where: Predicate = (node) => {
              if (!this.options.nestedColumns && node.type === this.type) {
                return true
              }

              return node.type === this.type
            }
            const firstAncestor = findParentNodeClosestToPos(pos, where)

            if (firstAncestor === undefined) {
              return
            }

            // find the content inside of all the columns
            let nodes: Array<ProseMirrorNode> = []
            firstAncestor.node.descendants((node, _, parent) => {
              if (parent?.type.name === Column.name) {
                nodes.push(node)
              }
            })
            nodes = nodes.reverse().filter((node) => node.content.size > 0)

            // resolve the position of the first ancestor
            const resolvedPos = tr.doc.resolve(firstAncestor.pos)
            const sel = new NodeSelection(resolvedPos)

            // insert the content inside of all the columns and remove the column layout
            tr = tr.setSelection(sel)
            nodes.forEach((node) => (tr = tr.insert(firstAncestor.pos, node)))
            tr = tr.deleteSelection()
            return dispatch(tr)
          } catch (error) {
            console.error(error)
          }
        }

      const setColumns =
      (n: number, keepContent = false) =>
        ({ tr, dispatch }: CommandProps) => {
          trackEventInJune(JuneEvents.EDITOR_COLUMNS_INSERTED)
          try {
            const { doc, selection } = tr
            if (!dispatch) {
              // console.log("no dispatch")
              return
            }

            const sel = new ColumnSelection(selection)
            sel.expandSelection(doc)

            const { openStart, openEnd } = sel.content()
            if (openStart !== openEnd) {
              console.warn("failed depth check")
              return
            }

            // create columns and put old content in the first column
            let columnBlock
            if (keepContent) {
              const content = sel.content().toJSON()
              const firstColumn = buildColumn(content)
              const otherColumns = buildNColumns(n - 1)
              columnBlock = buildColumnBlock({
                content: [ firstColumn, ...otherColumns ],
              })
            } else {
              const columns = buildNColumns(n)
              columnBlock = buildColumnBlock({ content: columns })
            }
            const newNode = doc.type.schema.nodeFromJSON(columnBlock)
            if (newNode === null) {
              return
            }

            const parent = sel.$anchor.parent.type
            const canAcceptColumnBlockChild = (par: NodeType) => {
              if (!par.contentMatch.matchType(this.type)) {
                return false
              }

              if (!this.options.nestedColumns && par.name === Column.name) {
                return false
              }

              return true
            }
            if (!canAcceptColumnBlockChild(parent)) {
              console.warn("content not allowed")
              return
            }

            tr = tr.setSelection(sel)
            tr = tr.replaceSelectionWith(newNode, false)
            return dispatch(tr)
          } catch (error) {
            console.error(error)
          }
        }

      const insertColumnToRight = () => ({ tr, dispatch, state }: CommandProps) => {
        const [ columnBlock, column ] = [ this.name, "column" ]
          .map(
            (name) => findParentNodeClosestToPos(
              tr.selection.$from,
              (node) => node.type.name === name,
            ),
          )

        if (!columnBlock || !column) return false

        // TODO: Currently always inserting to the outer right, could be replaced with the ability to insert to the right of the current column
        const columnIndex = columnBlock.node.content.childCount - 1

        const { node: columnBlockNode, pos } = columnBlock

        const [ from, to ] = [ pos, pos + columnBlockNode.nodeSize ]

        const jsonParentColumnBlock = columnBlockNode.toJSON() as JSONContent

        if (!jsonParentColumnBlock.content.length) return false

        jsonParentColumnBlock.content.splice(columnIndex + 1, 0, emptyColumn)

        const updatedColumnBlock = state.schema.nodeFromJSON(jsonParentColumnBlock)

        tr = tr.replaceRangeWith(from, to, updatedColumnBlock)

        if (dispatch) {
          const $endPos = tr.doc.resolve(from + updatedColumnBlock.nodeSize)

          const newSel = TextSelection.near($endPos)

          tr.setSelection(newSel)

          return dispatch(tr)
        }

        return false
      }

      return {
        unsetColumns,
        setColumns,
        insertColumnToRight,
      }
    },

    addKeyboardShortcuts () {
      const onTab: KeyboardShortcutCommand = ({ editor }) => {
        if (!editor.isActive("column")) return

        const column = findParentNodeClosestToPos(
          editor.state.selection.$from,
          (node) => node.type.name === "column",
        )

        if (!column) return false

        const { pos, node: columnNode } = column

        const end = pos + columnNode.nodeSize

        const $endPos = editor.state.doc.resolve(end)

        const newSel = TextSelection.near($endPos)

        editor.view.dispatch(editor.state.tr.setSelection(newSel))

        return true
      }

      const onShiftTab: KeyboardShortcutCommand = ({ editor }) => {
        if (!editor.isActive("column")) return

        const column = findParentNodeClosestToPos(
          editor.state.selection.$from,
          (node) => node.type.name === "column",
        )

        if (!column) return false

        const { pos } = column

        const $startPos = editor.state.doc.resolve(pos)

        const newSel = TextSelection.near($startPos, -1)

        editor.view.dispatch(editor.state.tr.setSelection(newSel))

        return true
      }

      return {
        Tab: onTab,
        "Shift-Tab": onShiftTab,
      }
    },
  },
)
