<script setup lang="ts">
// external
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/vue/24/solid"
import { NoSymbolIcon } from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { computed } from "vue"

// internal
import { DashboardWidget } from "~/components"
import { useDashboardStore } from "~/stores"

interface Props {
  title: string,
  endpoint: string
}
defineProps<Props>()

const dashboardStore = useDashboardStore()
const {
  dashboards,
  activeDashboardUuid: activeDashboardUuid,
} = storeToRefs(dashboardStore)

const activeDashboard = computed(() => dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value))

const hasTimeframeFilter = computed(() => !!activeDashboard.value?.filter?.timeframe)

</script>
<template>
  <DashboardWidget
    :title="title"
    :endpoint="endpoint"
  >
    <template #default="{ data }">
      <div
        v-if="data"
        class="flex items-baseline p-5"
      >
        <p class="text-3xl font-semibold text-gray-900">
          {{ data.count }}
        </p>
        <p
          v-if="hasTimeframeFilter && data.delta !== 0"
          :class="[data.delta > 0 ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold']"
        >
          <ArrowUpIcon
            v-if="data.delta > 0"
            class="h-5 w-5 shrink-0 self-center text-green-500"
            aria-hidden="true"
          />
          <ArrowDownIcon
            v-else
            class="h-5 w-5 shrink-0 self-center text-red-500"
            aria-hidden="true"
          />
          <span class="sr-only"> {{ data.delta > 0 ? 'Increased' : 'Decreased' }} by </span>
          {{ data.delta }}
        </p>
      </div>
      <div
        v-else
        class="text-center flex-col flex items-center justify-center"
      >
        <NoSymbolIcon
          class="w-8 h-8 text-gray-200"
          aria-hidden="true"
        />
        <div class="text-gray-300 font-medium">
          {{ $t('dashboard.noData') }}
        </div>
      </div>
    </template>
  </DashboardWidget>
</template>
