<script setup lang="ts">
// external
import { computed } from "vue"
import { Link } from "@inertiajs/vue3"

// internal
import { UiUser, AccountUser, DocumentUser } from "~/types"
import { getUserRepresentation } from "~/utils/helpers"

interface Props {
  users: (UiUser | AccountUser | DocumentUser)[]
  limit?: number
  sizeClasses?: string
  ringClass?: string
  apiRoute?: string
  displayEmail?: boolean
  placement?: "bottom" | "top" | "left" | "right"
  highlights?: boolean[]
  highlightClass?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    limit: 6,
    sizeClasses: "h-6 w-6",
    ringClass: "ring-white",
    highlightClass: "ring-green-500",
    apiRoute: null,
    displayEmail: false,
    placement: "bottom",
    highlights: null,
  },
)

const limitExceeded = computed(() => props.users.length > props.limit)

const usersToDisplay = computed(() => {
  if (!limitExceeded.value) {
    return props.users
  }
  return [ ...props.users ].splice(0, props.limit - 1)
})

const additionalUsers = computed(() => {
  if (limitExceeded.value) {
    return [ ...props.users ].splice(props.limit - 1, props.users.length)
  }
  return []
})

const additionalUsersTippyContent = computed(() => {
  let contentString = ""
  for (const user of additionalUsers.value) {
    contentString += getUserRepresentation(user) + "\n"
  }
  return contentString
})

const getTippyString = (user: UiUser | AccountUser | DocumentUser) => {
  if (props.displayEmail && getUserRepresentation(user) !== user.email) {
    return `${getUserRepresentation(user)} · ${user.email}`
  }
  return getUserRepresentation(user)
}

const getRingClass = (index) => {
  return props.highlights && props.highlights[index] ? props.highlightClass : props.ringClass
}
</script>

<template>
  <div class="flex items-center space-x-2">
    <div class="flex -space-x-1 shrink-0">
      <component
        :is="apiRoute ? Link : 'span'"
        v-for="(
          user, userIdx
        ) in usersToDisplay"
        :key="userIdx"
        :href="apiRoute ? route(apiRoute, user.uuid): undefined"
      >
        <img
          class="rounded-full max-w-none ring-2"
          :class="[ sizeClasses, getRingClass(userIdx) ]"
          :src="user?.profile_photo_url"
          :alt="getTippyString(user)"
          data-tippy-help
          :data-tippy-content="getTippyString(user)"
          :data-placement="placement"
          aria-hidden="true"
        >
      </component>
      <span
        v-if="limitExceeded"
        class="flex items-center justify-center text-white bg-indigo-500 rounded-full cursor-default max-w-none ring-2"
        :class="[ sizeClasses, ringClass ]"
      >
        <span
          class="text-xs font-medium leading-5 shrink-0"
          data-tippy-list
          :data-tippy-content="additionalUsersTippyContent"
          :data-placement="placement"
          aria-hidden="true"
        >+{{ additionalUsers.length }}</span>
      </span>
    </div>
  </div>
</template>
