<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch } from "vue"

import {
  ArrowTopRightOnSquareIcon,
  LinkIcon,
  XMarkIcon,
} from "@heroicons/vue/24/solid"
import {
  ChatBubbleLeftIcon,
} from "@heroicons/vue/24/outline"

// internal
import { CommentFiles, DiscussionListEntryChild, DiscussionResolveButton, DiscussionVisibilityButton } from "~/components"
import { CommentEditor } from "~/editor"
import { useCommentStore, useDocumentStore, useFileStorageStore, useProposalStore, useSharedStore, useUserStore } from "~/stores"
import { Comment, CrudContext, Document, Template } from "~/types"
import { formatDateRelative, getUserRepresentation, formatDateAndTime } from "~/utils"

interface Props {
  documentUuid?: Document["uuid"]
  templateUuid?: Template["uuid"]
  comment: Comment
}

const props = withDefaults(
  defineProps<Props>(),
  {
    documentUuid: null,
    templateUuid: null,
  },
)

const userStore = useUserStore()
const {
  users,
} = storeToRefs(userStore)

const documentStore = useDocumentStore()
const {
  mdu,
  mau,
  currentDocument,
} = storeToRefs(documentStore)

const commentStore = useCommentStore()
const {
  comments,
  highlightedCommentUuids,
  isActiveAddMentionedUserModal,
  isHighlightedViaClick,
} = storeToRefs(commentStore)
const {
  updateComment,
  setHighlightedCommentUuids,
  setActiveCommentProsemirrorDataUuid,
  scrollToCommentInDocument,
  setIsHighlightedViaClick,
} = commentStore

const proposalStore = useProposalStore()
const {
  reviewProposal,
} = proposalStore

const fileStorageStore = useFileStorageStore()
const { storedFiles } = storeToRefs(fileStorageStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed(() => {
  return crudContext.value === CrudContext.document ? props.documentUuid : props.templateUuid
})

const relatedFiles = computed(() => storedFiles.value?.filter((file) => file.comment_uuid === props.comment?.uuid && file.comment_uuid && !file.deleted_at))

const commentCreator = computed(() => {
  // handle edge case, where account user leaves and rejoins document -> all of their old comments will be attributed to them instead of old deleted user
  const creator = users.value.find((user) => !!props.comment.created_by_document_user_account_user?.uuid && user.account_user?.uuid === props.comment.created_by_document_user_account_user?.uuid) ?? null
  if (creator) {
    return creator
  }

  return {
    uuid: props.comment.created_by_document_user_uuid,
    first_name: props.comment.created_by_document_user_first_name,
    last_name: props.comment.created_by_document_user_last_name,
    deleted_at: props.comment.created_by_document_user_deleted_at,
    email: props.comment.created_by_document_user_email,
    profile_photo_url: props.comment.created_by_document_user_profile_photo_url,
  }
})

const canComment = computed(() => mdu.value?.permissions.includes("comment_create"))

const canResolve = computed(() => mau.value?.permissions.includes("comment_resolve_proposal") && !!mdu.value)

const childComments = computed(() => comments.value.filter((comment) => comment.comment_uuid === props.comment.uuid))

const isLoadingResolveComment = ref<boolean>(false)

const handleResolveComment = async () => {
  if (!props.comment.is_resolved) {
    isLoadingResolveComment.value = true
    await updateComment(props.documentUuid, props.comment.uuid, { is_resolved: true })
    isLoadingResolveComment.value = false
  }
  setHighlightedCommentUuids([])
  setActiveCommentProsemirrorDataUuid("")
}

const isHighlighted = computed(() => highlightedCommentUuids.value?.includes(props.comment.uuid))

const isReplying = ref(false)

watch(isHighlighted, (val) => {
  if (!val) {
    setIsHighlightedViaClick(false)
    isReplying.value = false
  }
})

const clickHandler = () => {
  if (!isHighlighted.value) setHighlightedCommentUuids([ props.comment.uuid ])

  if (isHighlighted.value && props.comment.prosemirror_data_uuid) {
    scrollToCommentInDocument(props.comment.prosemirror_data_uuid)
  } else {
    setActiveCommentProsemirrorDataUuid("")
  }
  setIsHighlightedViaClick(true)
}

const clickOutsideHandler = (event: PointerEvent) => {
  // If there is text in the comment editor, we need to return
  if (commentEditorRef.value?.getEditorHtml() !== "<p></p>") return
  if (!isHighlightedViaClick.value || isActiveAddMentionedUserModal.value) return
  const el = document.getElementById(`comment_${props.comment.uuid}`)
  if (el === event.relatedTarget || el.contains(event.relatedTarget as HTMLElement)) return
  setHighlightedCommentUuids([])
  setActiveCommentProsemirrorDataUuid("")
}

const proposalHandler = () => {
  reviewProposal(props.comment)
}

const commentContent = computed(() => {
  let content = props.comment.html
  if (!content) return ""
  if (props.comment.mentioned_document_user_uuids?.length) {
    for (let i = 0; i < props.comment.mentioned_document_user_uuids.length; i++) {
      const mentionedUser = users.value.find((user) => user.uuid === props.comment.mentioned_document_user_uuids[i])
      if (mentionedUser) {
        content = content.replaceAll(`@${props.comment.mentioned_document_user_uuids[i]}`, getUserRepresentation(mentionedUser))
      }
    }
  }

  return content
})

const isCommentCreatedByOwnParty = computed<boolean>(() => {
  if (props.comment?.party_uuid === mdu.value?.party_uuid) return true
  return false
})

const commentEditorRef = ref<InstanceType<typeof CommentEditor> | null>(null)

const isReplyEditorExternal = ref(props.comment?.scope === "internal_and_external")
const handleScopeChange = (isExternal) => isReplyEditorExternal.value = isExternal
</script>
<template>
  <article
    :id="`comment_${comment.uuid}`"
    tabindex="-1"
    class="relative px-4 pt-3 pb-2 comment-outside-click-source focus:outline-none"
    :class="[comment.scope === 'internal' ? 'bg-stripes' : '']"
    @click="clickHandler"
    @blur="clickOutsideHandler"
  >
    <header class="flex items-center justify-between space-x-2">
      <div class="flex items-center space-x-2">
        <div>
          <img
            v-if="commentCreator?.profile_photo_url"
            class="relative z-10 w-8 h-8 rounded-full"
            :class="commentCreator?.deleted_at ? 'grayscale' : ''"
            :src="
              commentCreator
                ?.profile_photo_url
            "
            :alt="getUserRepresentation(commentCreator)"
          >
          <div
            v-else
            class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full"
          >
            <XMarkIcon class="w-4 h-4 text-gray-400" />
          </div>
        </div>
        <div class="flex items-center flex-1 space-x-2 text-gray-900 min-w-0 max-w-[200px] overflow-hyphens">
          <h3
            class="text-xs font-medium line-clamp-2"
            :class="commentCreator?.deleted_at ? 'line-through' : ''"
            :data-tippy-help="!!commentCreator?.deleted_at"
            :data-tippy-content="commentCreator?.deleted_at ? $t('common.deletedAt', {date: formatDateAndTime(commentCreator?.deleted_at)}) : ''"
            data-placement="bottom"
            :title="getUserRepresentation(commentCreator)"
          >
            {{ getUserRepresentation(commentCreator) }}
          </h3>
        </div>

        <template v-if="!!mau">
          <span class="text-xs text-gray-500">·</span>
          <DiscussionVisibilityButton
            :document-uuid="currentDocument?.uuid"
            :comment="comment"
            :mau="mau"
            :mdu="mdu"
          />
        </template>

        <span class="text-xs text-gray-500">·</span>
        <time
          class="ml-2 text-xs text-gray-500"
          :datetime="comment.created_at"
        >
          {{ formatDateRelative(comment.created_at) }}
        </time>
      </div>
      <DiscussionResolveButton
        v-if="mdu?.permissions?.includes('comment_update') && (mau?.uuid || isCommentCreatedByOwnParty)"
        class="ml-2"
        :loading="isLoadingResolveComment"
        :comment="comment"
        :mau="mau"
        @resolve-comment="handleResolveComment()"
        @show-proposal="proposalHandler()"
      />
    </header>

    <section class="relative">
      <!-- eslint-disable vue/no-v-html -->
      <div class="relative pl-8">
        <p
          v-if="comment.type === 'comment'"
          class="px-2 py-1 mt-2 text-sm whitespace-pre-wrap rounded-md comment-content-wrapper overflow-hyphens"
          :class="comment.scope === 'internal' ? 'bg-indigo-50' : ''"
          v-html="commentContent"
        />
        <!-- eslint-enable vue/no-v-html -->
        <CommentFiles
          :related-files="relatedFiles"
          :entity-uuid="entityUuid"
          :scope="comment.scope"
        />
        <div
          v-if="comment.type === 'proposal'"
          class="px-2 py-1 mt-2 text-sm rounded-md"
          :class="comment.scope === 'internal' ? 'bg-indigo-50' : ''"
        >
          <p class="flex items-center text-sm whitespace-pre-wrap">
            <span>{{ $t('discussions.proposedAChange') }}: </span>
            <button
              type="button"
              class="inline-flex items-center font-medium text-indigo-700 hover:text-indigo-900"
              @click.stop.prevent="proposalHandler"
            >
              <ArrowTopRightOnSquareIcon
                class="w-4 h-4 ml-1"
                aria-hidden="true"
              />
              {{
                !comment.is_resolved && canResolve
                  ? $t('discussions.viewResolve')
                  : $t('discussions.view')
              }}
            </button>
          </p>
        </div>
        <div
          class="absolute w-1 border-r-2 border-dotted -top-1 left-3"
          :class="[
            !!childComments.length ? '-bottom-2.5' : canComment && !comment.is_resolved ? '-bottom-6' : 'bottom-0',
            comment.scope === 'internal_and_external' ? 'border-r-gray-200' : 'border-r-indigo-200'
          ]"
        />
      </div>

      <ul
        v-if="!!childComments.length"
        class="my-3 space-y-3"
      >
        <li
          v-for="(childComment, childCommentIndex) in childComments"
          :key="childComment.uuid"
          class="relative"
        >
          <div
            class="absolute top-0 w-1 border-r-2 border-dotted left-3"
            :class="[
              childCommentIndex < childComments.length - 1 ? '-bottom-2.5' : (!comment.is_resolved && canComment) ? '-bottom-[1.75rem]' : 'bottom-[calc(100%-1rem)]',
              comment.scope === 'internal_and_external' ? 'border-r-gray-200' : 'border-r-indigo-200'
            ]"
          />
          <DiscussionListEntryChild
            :parent="comment"
            :comment="childComment"
            :document-uuid="currentDocument?.uuid"
            :is-last="childCommentIndex === childComments.length -1"
          />
        </li>
      </ul>

      <div
        v-if="!!comment.prosemirror_data_uuid"
        class="absolute flex items-center justify-center w-5 h-5 rounded-full left-1.5 top-1 z-20 p-1"
        :class="[isHighlighted ? 'bg-yellow-50 ring-1 ring-yellow-400' : comment.scope === 'internal' ? 'bg-indigo-50' : 'bg-white']"
      >
        <LinkIcon
          :class="[isHighlighted ? 'text-yellow-600' : comment.scope === 'internal' ? 'text-indigo-500' : 'text-gray-500']"
          class="h-3.5 w-3.5"
          aria-hidden="true"
        />
      </div>
    </section>

    <footer
      v-if="!comment.is_resolved && canComment"
      class="relative mt-2 pl-[20px]"
    >
      <div class="relative z-20 flex items-center justify-start w-full pl-1 space-x-2">
        <button
          v-if="!isReplying"
          type="button"
          class="flex items-center gap-1.5 btn-plain btn-sm"
          :class="comment.scope === 'internal' ? 'text-indigo-600 hover:text-indigo-800' : 'text-gray-600 hover:text-gray-800'"
          @click="isReplying = true"
        >
          <ChatBubbleLeftIcon
            aria-hidden="true"
            class="w-4 h-4"
          />
          <span>{{ $t('proposal.reply') }}</span>
        </button>
      </div>
      <div
        v-show="isReplying"
        class="flex mt-1 mb-1 pl-[9px] space-x-2"
        @click.stop
      >
        <img
          class="w-6 h-6 rounded-full"
          :src="(mau ?? mdu)?.profile_photo_url"
          alt=""
        >
        <div class="grow">
          <form
            action="#"
            class="relative"
          >
            <div
              class="overflow-hidden border rounded-md shadow-sm md:container"
              :class="isReplyEditorExternal ? 'border-gray-200' : 'border-indigo-200'"
            >
              <CommentEditor
                ref="commentEditorRef"
                :document-uuid="props.documentUuid"
                :comment-item-uuid="comment.uuid"
                :scope="comment.scope"
                :users="users"
                class="p-2 mr-1 text-sm hyphens-auto focus:outline-none focus:ring-0"
                @blur="clickOutsideHandler"
                @update-scope="handleScopeChange"
              />
            </div>
          </form>
        </div>
      </div>
      <div
        class="w-2 border-t-2 border-dotted absolute top-[15px] left-[17px]"
        :class="comment.scope === 'internal_and_external' ? 'border-t-gray-200' : 'border-t-indigo-200'"
      />
    </footer>
    <div
      v-if="isHighlighted"
      class="absolute top-0 bottom-0 left-0 w-0.5 h-full bg-yellow-400"
    />
  </article>
</template>

<style>
.comment-content-wrapper [data-mention="true"] {
    @apply text-indigo-600 font-medium;
}
</style>
