<script setup lang="ts">
// external
import { computed, ref, watch } from "vue"
import { router } from "@inertiajs/vue3"
import { storeToRefs } from "pinia"
import axios from "axios"

// internal
import { DocumentNotificationModal, DocumentNotificationListEntry } from "~/components"
import { DocumentNotification, Team, Document, Template } from "~/types"
import { useSharedStore, useUserStore } from "~/stores"
import { useDocumentNotificationHelpers } from "~/composables"

interface Props {
  notificationSettings?: DocumentNotification[]
  entityUuid?: Document["uuid"] | Template["uuid"]
  teams?: Team[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    notificationSettings: () => [],
    entityUuid: null,
    teams: null,
  },
)

const userStore = useUserStore()
const { users, allAccountUsers } = storeToRefs(userStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const showModal = ref(false)
const notificationToShow = ref(null)

const openModal = (notificationSetting:DocumentNotification = null) => {
  notificationToShow.value = Object.assign({}, notificationSetting)
  showModal.value = true
}

watch(showModal, (newVal) => {
  if (!newVal) {
    notificationToShow.value = null
  }
})

const notificationSettingsToShow = computed(() => {
  return [
    ...props.notificationSettings,
  ].sort((a, b) => {
    if (a.type < b.type) return -1
    if (a.type > b.type) return 1
    // if types are equal, compare by key
    if (a.key < b.key) return -1
    if (a.key > b.key) return 1
    return 0
  })
})

const {
  getUniqueIdentifier,
  reducePayload,
  isInCurrentContext,
} = useDocumentNotificationHelpers(crudContext.value)

const isDeletingUuid = ref("")
const deleteNotificationSetting = async (notificationSetting: DocumentNotification) => {
  if (!isDeletingUuid.value) {
    isDeletingUuid.value = getUniqueIdentifier(notificationSetting)
    const endpoint = route(`api.${crudContext.value}s.notification-settings.destroy`, {
      [crudContext.value]: props.entityUuid,
      notification_setting: notificationSetting.uuid,
    })

    try {
      await axios.delete(endpoint)

      router.reload({
        only: [ "notificationSettings" ],
        onFinish: () => {
          isDeletingUuid.value = ""
          showModal.value = false
        },
      })
    } catch (e) {
      console.error(e)
      isDeletingUuid.value = ""
    } finally {
    }
  }
}

const isUpdatingUuid = ref("")
const submit = async (notificationSetting: DocumentNotification, isDeactivated = false) => {
  if (!isUpdatingUuid.value) {
    isUpdatingUuid.value = getUniqueIdentifier(notificationSetting)

    const payload = {
      ...reducePayload(notificationSetting),
      is_deactivated: isDeactivated,
    }

    try {
      if (isInCurrentContext(notificationSetting)) {
        await axios.patch(route(`api.${crudContext.value}s.notification-settings.update`, {
          [crudContext.value]: props.entityUuid,
          notification_setting: notificationSetting.uuid,
        }), payload)
      } else {
        await axios.post(route(`api.${crudContext.value}s.notification-settings.store`, props.entityUuid), payload)
      }

      router.reload({
        only: [ "notificationSettings" ],
        onFinish: () => {
          // special case handling, check if toggling of active state worked
          if (isInCurrentContext(notificationSetting) && isDeactivated !== notificationSetting.is_deactivated) {
            const updatedNotificationSetting = props.notificationSettings.find((entry) => entry.key === notificationSetting.key)
            if (isDeactivated !== updatedNotificationSetting.is_deactivated) {
              deleteNotificationSetting(updatedNotificationSetting)
            }
          }
          isUpdatingUuid.value = ""
        },
      })

      showModal.value = false
    } catch (e) {
      console.error(e)
      isUpdatingUuid.value = ""
    }
  }
}
</script>

<template>
  <div>
    <div class="flex flex-col px-4 space-y-4">
      <DocumentNotificationListEntry
        v-for="notificationSetting in notificationSettingsToShow"
        :key="'notificationSetting_' + notificationSetting.uuid"
        :notification-setting="notificationSetting"
        :loading="isUpdatingUuid === getUniqueIdentifier(notificationSetting) || isDeletingUuid === getUniqueIdentifier(notificationSetting)"
        :users="users"
        :all-account-users="allAccountUsers"
        :context="crudContext"
        :teams="teams"
        @edit="openModal"
        @deactivate="submit(notificationSetting, true)"
        @activate="submit(notificationSetting, false)"
        @reset="deleteNotificationSetting"
      />
    </div>
    <DocumentNotificationModal
      v-model:show="showModal"
      :notification="notificationToShow"
      :notification-settings="notificationSettings"
      :teams="teams"
      :context="crudContext"
      :loading="!!isUpdatingUuid || !!isDeletingUuid"
      @submit="submit"
      @deactivate="submit(notificationToShow, true)"
      @activate="submit(notificationToShow, false)"
      @reset="deleteNotificationSetting"
    />
  </div>
</template>
