<script setup lang="ts">
// external
import { MenuItem } from "@headlessui/vue"
import { Link, router } from "@inertiajs/vue3"

// internal
import { MagicTableEntry, TeamList, TagList, MagicTableTimestamps } from "~/components"
import { Template } from "~/types"
import { getUserRepresentation } from "~/utils"
import { useEntityActions } from "~/composables"
import { useTemplateStore, useAccountStore } from "~/stores"
import { storeToRefs } from "pinia"


interface Props {
  template: Template
  columns: any[]
  loading?: boolean
  selected?: boolean
  isArchive?: boolean
  sortAttribute?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    selected: false,
    isArchive: false,
    sortAttribute: null,
  },
)

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const statusStyles = {
  published: "bg-green-100 text-green-800",
  draft: "bg-gray-100 text-gray-800",
}

const {
  confirmEntityDeletion,
  confirmEntityArchiving,
  confirmEntityRestoration,
} = useEntityActions("templates")

const { duplicateTemplate } = useTemplateStore()

const handleDuplicateTemplate = async (templateUuid: Template["uuid"]): Promise<void> => {
  const newTemplateUuid = await duplicateTemplate(templateUuid)
  if (newTemplateUuid) router.visit(route("templates.edit", { template: newTemplateUuid }))
}

defineEmits([ "select" ])
</script>

<template>
  <MagicTableEntry
    :columns="columns"
    :loading="loading"
    :selected="selected"
    @select="$emit('select', template.uuid)"
  >
    <template #name>
      <div>
        <Link
          v-cy="`templates-table-template-${template.name.toLowerCase()}`"
          :href="route('templates.edit', props.template.uuid)"
          class="text-xs @md:text-sm font-medium hover:text-indigo-700"
        >
          {{ template.name }}
        </Link>
        <TagList
          v-if="template.tags?.length"
          class="hidden @lg:flex -mb-1 -mr-1 mt-1"
          :tags="template.tags"
        />
      </div>
    </template>
    <template #teams>
      <TeamList
        v-if="template.teams?.length"
        :teams="template.teams"
      />
      <span
        v-else
        class="text-sm text-gray-400"
      >
        {{ $t('unassigned') }}
      </span>
    </template>
    <template #status>
      <span
        :class="[
          template.published ? statusStyles.published : statusStyles.draft,
          'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        ]"
      >
        {{ template.published ? $t('templates.status.published') : $t('templates.status.draft') }}
      </span>
    </template>
    <template #creator>
      <div class="flex items-center space-x-2 truncate">
        <div class="shrink-0">
          <img
            class="w-6 h-6 rounded-full max-w-none ring-2 ring-white"
            :src="template.created_by_account_user?.profile_photo_url"
          >
        </div>
        <span class="leading-5 shrink-0">
          {{ getUserRepresentation(template.created_by_account_user) }}
        </span>
      </div>
    </template>
    <template #timestamps>
      <MagicTableTimestamps
        :entry="template"
        :sort-attribute="sortAttribute"
      />
    </template>
    <template #options>
      <div>
        <MenuItem v-slot="{ active }">
          <Link
            :href="route('templates.edit', template.uuid)"
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-t-md',
            ]"
          >
            {{ $t('common.edit') }}<span class="sr-only">, {{ template.name }}</span>
          </Link>
        </MenuItem>
      </div>
      <div>
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm',
            ]"
            @click="handleDuplicateTemplate(template.uuid)"
          >
            {{ $t('common.duplicate') }}
          </button>
        </MenuItem>
      </div>
      <div v-if="isArchive">
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
            ]"
            @click="confirmEntityRestoration({ entityUuids: [template.uuid] })"
          >
            {{ $t('common.restore') }}
          </button>
        </MenuItem>
      </div>
      <div v-else>
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-t-md',
            ]"
            @click="confirmEntityArchiving({ entityUuids: [template.uuid] })"
          >
            {{ $t('common.archive') }}
          </button>
        </MenuItem>
      </div>
      <div
        v-if="mau?.permissions.includes('document_delete')"
      >
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-red-950 text-red-400' : 'text-red-500',
              'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
            ]"
            @click="confirmEntityDeletion({ entityUuids: [template.uuid] })"
          >
            {{ $t('common.delete') }}
          </button>
        </MenuItem>
      </div>
    </template>
  </MagicTableEntry>
</template>
