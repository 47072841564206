import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import ValueNodeView from "./ValueNodeView.vue"

export interface ValueAttrs {
  value: Record<string, any>
  meta: Record<string, any>
}

export const ValueNode = Node.create(
  {
    name: "value",

    group: "inline",

    inline: true,

    selectable: false,

    atom: true,

    draggable: true,

    addOptions () {
      return {
        HTMLAttributes: {},

        renderLabel: ({ node }) => node.attrs.value,
      }
    },

    addAttributes () {
      return {
        value: {
          default: "",
        },
        meta: {
          default: {},
        },
      }
    },

    parseHTML () {
      return [
        {
          tag: "span[data-value]",
        },
      ]
    },

    renderHTML ({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes(
          {
            "data-value": "true",
          },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
        this.options.renderLabel(
          {
            options: this.options,
            node,
          },
        ),
      ]
    },

    renderText ({ node }) {
      return this.options.renderLabel({
        options: this.options,
        node,
      })
    },

    addNodeView () {
      return VueNodeViewRenderer(ValueNodeView, {})
    },
  },
)

export default ValueNode
