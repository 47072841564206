
import { Editor, Extension, posToDOMRect } from "@tiptap/core"
import { VueRenderer } from "@tiptap/vue-3"
import { debounce } from "lodash-es"
import tippy, { Instance, Props as TippyProps, sticky } from "tippy.js"

import TableMenuComponent from "./TableMenu.vue"

const isTableMenuOpen = (editor: Editor) => editor.storage.tableMenu.tableMenu.popup[0]?.state.isShown

const processSelectionForTableMenu = (editor: Editor) => {
  if (isTableMenuOpen(editor)) return

  editor.storage.tableMenu.tableMenu?.popup?.[0]?.popperInstance?.update()

  const isTableActive = editor.isActive("table")

  if (!isTableActive || !editor.isEditable) {
    const { tableMenu } = editor.storage
    tableMenu.popup?.[0]?.hide()
    return
  }

  const { tableMenu } = editor.storage
  tableMenu.popup?.[0]?.show()
}

const debouncedProcessSelectionForTableMenu = debounce(processSelectionForTableMenu, 300)

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableMenu: {
      showTableMenu: () => ReturnType
      hideTableMenu: () => ReturnType
      updateTableMenu: () => ReturnType
    }
  }
}

interface TableMenuOptions {
  HTMLAttributes: Record<string, any>
}

interface TableMenuComponent {
  renderer: VueRenderer
  popup: Instance<TippyProps>[]
}

interface TableMenuStorage {
  tableMenu: TableMenuComponent
}

export const TableMenu = Extension.create<TableMenuOptions, TableMenuStorage>(
  {
    name: "tableMenu",

    addStorage () {
      return {
        tableMenu: {
          component: TableMenuComponent,
          renderer: null,
          popup: [],
        },
      }
    },

    onCreate () {
      const { editor } = this

      const getSelectionRect = ({ state, view }: Editor) => {
        const { $from } = state.selection

        let depth: number = undefined

        for (let i = $from.depth; i >= 0; i--) {
          if ($from.node(i).type.name === "tableRow") {
            depth = i
            break
          }
        }

        if (depth === undefined) return posToDOMRect(view, $from.pos, $from.pos)

        const tableNodeStart = $from.start(depth)

        const tableDomNode = view.nodeDOM(tableNodeStart) as HTMLElement

        if (tableDomNode) return tableDomNode.getBoundingClientRect()

        return posToDOMRect(view, $from.pos, $from.pos)
      }


      for (const [ name, { component } ] of Object.entries(this.storage)) {
        const renderer = new VueRenderer(
          component,
          {
            props: {
              editor,
            },
            editor,
          },
        )

        const popup = tippy(
          "body",
          {
            getReferenceClientRect: () => getSelectionRect(editor),
            appendTo: () => document.getElementById("proposalContainer") || document.getElementById("editorContainer"),
            content: renderer.element,
            showOnCreate: false,
            interactive: true,
            trigger: "manual",
            placement: "top-start",
            animation: "shift-toward-subtle",
            theme: "slate",
            maxWidth: "none",
            hideOnClick: false,
            arrow: false,
            sticky: true,
            plugins: [
              sticky,
            ],
            zIndex: 40,
            //duration: 250,
            moveTransition: "transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
          },
        )

        this.storage[name] = {
          ...this.storage[name],
          renderer,
          popup,
        }
      }
    },

    onDestroy () {
      this.storage.tableMenu.popup.filter(Boolean).forEach((popup) => popup.destroy())
    },

    onSelectionUpdate () {
      const { state: { selection: { empty } } } = this.editor

      if (empty) processSelectionForTableMenu(this.editor)

      debouncedProcessSelectionForTableMenu(this.editor)
    },

    addCommands () {
      return {
        showTableMenu: () =>
          () => {
            const { tableMenu } = this.storage

            tableMenu.popup?.[0]?.show()

            return true
          },
        hideTableMenu: () =>
          () => {
            const { tableMenu } = this.storage

            tableMenu.popup?.[0]?.hide()

            return true
          },
        updateTableMenu: () =>
          () => {
            const { tableMenu } = this.storage

            tableMenu.popup[0]?.popperInstance?.update()

            return true
          },
      }
    },
  },
)
