<script setup lang="ts">
// external
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { VariableIcon, BackspaceIcon, IdentificationIcon } from "@heroicons/vue/24/outline"
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { JSONContent } from "@tiptap/core"
import { useI18n } from "vue-i18n"

// internal
import { Condition, DynamicField, Metadata, MultiFieldType, Party } from "~/types"
import { useConditionStore, useDynamicFieldStore, usePartyStore } from "~/stores"
import { ConditionIcon } from "~/icons"
import { MultiFieldIcon } from "~/components"

interface ListItem {
  name: string
  data: JSONContent
  originalDynamicField?: DynamicField
  originalParty?: Party
  originalCondition?: Condition
}

interface Props {
  selectedItem?: DynamicField | Party | Condition | ListItem | JSONContent
  metadata?: Metadata
  layout?: string
  cancelable?: boolean
  disabled?: boolean
  static?: boolean
  includeDynamicFields?: boolean
  includeParties?: boolean
  includeConditions?: boolean
  useNodes?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedItem: null,
    metadata: null,
    layout: "default",
    cancelable: true,
    disabled: false,
    static: false,
    includeDynamicFields: true,
    includeParties: false,
    includeConditions: false,
    useNodes: false,
  },
)

const emit = defineEmits([ "update:selected-item", "cancel" ])

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFields } = storeToRefs(dynamicFieldStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const conditionStore = useConditionStore()
const { conditions } = storeToRefs(conditionStore)

const { t } = useI18n()

const query = ref("")

const selectedItem = computed<ListItem>(
  {
    get: () => {
      if (props.useNodes) {
        return props.selectedItem as ListItem
      } else {
        const lookupUuid = (props.selectedItem as DynamicField | Party | Condition)?.uuid
        return filteredListItems.value.find((item) => item.data.attrs.uuid === lookupUuid)
      }
    },
    set: (value) => {
      if (value?.originalDynamicField) {
        emit("update:selected-item", value.originalDynamicField)
      } else if (value?.originalParty) {
        emit("update:selected-item", value.originalParty)
      } else if (value?.originalCondition) {
        emit("update:selected-item", value.originalCondition)
      }
      else {
        emit("update:selected-item", value)
      }
      query.value = ""
    },
  },
)

const checkReferenceCasting = (dynamicFieldType: MultiFieldType, metadataType: MultiFieldType) => {
  if (!metadataType) return true

  if (
    dynamicFieldType === metadataType
    // allow converting select -> text
    || dynamicFieldType === MultiFieldType.select && metadataType === MultiFieldType.text
    // allow converting email -> text
    || dynamicFieldType === MultiFieldType.email && metadataType === MultiFieldType.text
    // allow converting list -> textarea
    || dynamicFieldType === MultiFieldType.list && metadataType === MultiFieldType.textarea
    // allow converting text -> textarea
    || dynamicFieldType === MultiFieldType.text && metadataType === MultiFieldType.textarea
    // allow converting select -> textarea
    || dynamicFieldType === MultiFieldType.select && metadataType === MultiFieldType.textarea
    // allow converting email -> textarea
    || dynamicFieldType === MultiFieldType.email && metadataType === MultiFieldType.textarea
  ) return true

  return false
}

const fieldsOfParty = [
  "name",
  "address",
  "address:inline",
  "reference",
]

const partiesToShow = computed(
  () => {
    const aggregatedParties: ListItem[] = []

    if (!props.includeParties) return aggregatedParties

    for (const party of parties.value) {
      for (const property of fieldsOfParty) {
        let valueToPush: ListItem = {
          name: `${party.name}: ${t(`partyFormats.${property}`)}`,
          data: {
            type: "party",
            attrs: {
              uuid: party.uuid,
              refUuid: party.ref_uuid,
              format: property,
            },
          },
        }
        if (!props.useNodes) {
          valueToPush = {
            ...valueToPush,
            originalParty: party,
          }
        }
        aggregatedParties.push(valueToPush)
      }
    }

    return aggregatedParties
  },
)

const conditionsToShow = computed(
  () => {
    const aggregatedConditions: ListItem[] = []

    if (!props.includeConditions) return aggregatedConditions

    for (const condition of conditions.value) {
      let valueToPush: ListItem = {
        name: condition.name,
        data: {
          type: "condition",
          attrs: {
            uuid: condition.uuid,
          },
        },
      }
      if (!props.useNodes) {
        valueToPush = {
          ...valueToPush,
          originalCondition: condition,
        }
      }
      aggregatedConditions.push(valueToPush)
    }

    return aggregatedConditions
  },
)

const dynamicFieldsToShow = computed(
  () => {

    if (!props.includeDynamicFields) return []

    const aggregatedDynamicFields: ListItem[] = []

    const metadataValueType = props.metadata?.value_type
    const filteredDynamicFields = dynamicFields.value?.filter((d) => checkReferenceCasting(d.type, metadataValueType))

    for (const dynamicField of filteredDynamicFields) {
      let valueToPush: ListItem = {
        name: dynamicField.name,
        data: {
          type: "dynamicField",
          attrs: {
            uuid: dynamicField.uuid,
            refUuid: dynamicField.ref_uuid,
          },
        },
      }
      if (!props.useNodes) {
        valueToPush = {
          ...valueToPush,
          originalDynamicField: dynamicField,
        }
      }
      aggregatedDynamicFields.push(valueToPush)
    }

    return aggregatedDynamicFields
  },
)

const filteredListItems = computed(
  () => {
    const allItems = [
      ...partiesToShow.value,
      ...conditionsToShow.value,
      ...dynamicFieldsToShow.value,
    ]

    if (!query.value.trim()) return allItems

    const lowerCaseQuery = query.value.toLowerCase()

    return allItems.filter(
      (item) => item.name.toLowerCase().includes(lowerCaseQuery),
    )
  }
  ,
)

const itemsAvailable = computed(
  () => {
    // Depending on if dynamicFields, parties and conditions are included, return true if any has a length
    return (props.includeDynamicFields && dynamicFieldsToShow.value.length > 0)
      || (props.includeParties && partiesToShow.value.length > 0)
      || (props.includeConditions && conditionsToShow.value.length > 0)
  },
)

const handleComboboxButtonClick = () => {
  if (!filteredListItems.value?.length) {
    query.value = ""
  }
}

</script>

<template>
  <Combobox
    v-model="selectedItem"
    as="div"
    nullable
    :class="static ? '' : 'relative'"
  >
    <a
      v-if="props.cancelable"
      :class="[
        'my-2 flex items-center py-1 text-left group text-slate-300 hover:text-slate-100 hover:bg-slate-700 hover:cursor-pointer px-1 mx-2 rounded-md last:mb-2 relative',
      ]"
      @click.prevent="$emit('cancel')"
    >
      <span
        class="flex items-center p-1 mr-2 rounded-md shrink-0 text-slate-100 bg-slate-900 group-hover:bg-slate-800"
      >
        <BackspaceIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
      </span>

      <span>
        {{ $t('common.back') }}
      </span>
    </a>

    <div class="relative">
      <span
        v-if="selectedItem"
        class="absolute inset-y-0 left-0 flex items-center pl-3"
        :class="[
          props.disabled ? 'text-gray-400' : 'text-indigo-500',
        ]"
      >
        <IdentificationIcon
          v-if="selectedItem.originalParty"
          aria-hidden="true"
          class="w-4 h-4 shrink-0"
        />
        <ConditionIcon
          v-else-if="selectedItem.originalCondition"
          aria-hidden="true"
          class="w-4 h-4 shrink-0"
        />
        <MultiFieldIcon
          v-else-if="selectedItem.originalDynamicField"
          :type="selectedItem.originalDynamicField.type"
          class="shrink-0"
        />
        <VariableIcon
          v-else
          aria-hidden="true"
          class="w-4 h-4 shrink-0"
        />
      </span>
      <template v-if="itemsAvailable">
        <ComboboxInput
          :placeholder="!includeParties && !includeConditions ? $t('dynamicFields.select') + '…' : $t('common.select') + '…'"
          :class="[
            layout === 'slate' ? 'py-2 px-3 input-slate bg-slate-900 text-slate-200 border-0 placeholder-slate-500 focus:ring-0 focus:bg-slate-950' : 'input-plain py-2 px-3',
            selectedItem ? 'pl-8 text-indigo-500' : '',
            static ? 'rounded-none' : '',
          ]"
          :disabled="props.disabled"
          class="w-full pr-10 search-input"
          :display-value="(item: ListItem) => item?.name || ''"
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
          @click="handleComboboxButtonClick"
        >
          <ChevronUpDownIcon
            class="w-5 h-5"
            :class="layout === 'slate' ? 'text-slate-500' : 'text-gray-400'"
            aria-hidden="true"
          />
        </ComboboxButton>
      </template>
      <div
        v-else
        :class="[
          static ? 'p-3' : '',
          layout === 'slate' ? 'input-plain bg-slate-800 rounded-b-md text-slate-400 placeholder-slate-300 focus:ring-0 focus:bg-slate-900' : 'input-primary py-2 px-3 text-gray-500',
          selectedItem ? 'pl-8 text-indigo-500' : ' ',
        ]"
        class="pr-10 border-0 rounded-none"
      >
        {{ !includeParties && !includeConditions ? $t('dynamicFields.notFound') : $t('common.noMatchingResults') }}
        <slot name="addButton" />
      </div>
    </div>

    <ComboboxOptions
      v-if="filteredListItems.length > 0"
      :static="props.static"
      :class="[!props.static ? '' : 'relative rounded-t-none mt-0', !props.static && layout === 'slate' ? 'border border-slate-700 hover:border-slate-600 shadow-lg left-0' : '']"
      class="p-0 listbox-options bg-slate-800"
    >
      <div
        :class="static ? 'border-t border-t-slate-600' : ''"
        class="flex items-center py-1 text-left group px-3 text-slate-500 pt-2 pb-0.5"
      >
        <span class="flex-1 text-[10px] font-medium tracking-wider uppercase text-slate-500">
          {{ !includeParties && !includeConditions ? $t('dynamicFields.availableFields') : $t('common.availableOptions') }}</span>
      </div>
      <ComboboxOption
        v-for="(item, itemIdx) in filteredListItems"
        :key="item.name + itemIdx"
        v-slot="{ active, selected }"
        :value="item"
        as="template"
        :disabled="false"
      >
        <li
          :class="[
            'flex items-center py-1 text-left group text-slate-300 hover:text-slate-100 hover:bg-slate-700 hover:cursor-pointer px-1 mx-2 rounded-md last:mb-2 relative',
            active ? 'bg-slate-700' : '',
            selected ? 'pr-10' : 'pr-2'
          ]"
        >
          <span
            v-if="item.originalDynamicField"
            :title="$t('dynamicFields.form.type') + ': ' + $t('multiFieldTypes.' + item.originalDynamicField.type)"
            class="flex items-center p-1 mr-2 rounded-md shrink-0 text-slate-100 group-hover:bg-slate-600 bg-slate-700"
          >
            <MultiFieldIcon
              :type="item.originalDynamicField.type"
              size-classes="w-3 h-3"
            />
          </span>
          <span
            v-else
            class="flex items-center p-1 mr-2 rounded-md shrink-0 text-slate-100 group-hover:bg-slate-600 bg-slate-700"
          >
            <ConditionIcon
              v-if="item.originalCondition"
              class="w-3 h-3"
            />
            <IdentificationIcon
              v-else-if="item.originalParty"
              class="w-3 h-3"
            />
            <VariableIcon
              v-else
              class="w-3 h-3"
            />
          </span>
          <span :class="['flex-1 truncate text-sm', selected && 'font-semibold']">
            {{ item.name }}
          </span>

          <span
            v-if="selected"
            :class="[
              'absolute inset-y-0 right-0 flex items-center pr-4',
              active ? 'text-white' : 'text-indigo-500',
            ]"
          >
            <CheckIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
          </span>
        </li>
      </ComboboxOption>
    </ComboboxOptions>
    <div
      v-else-if="itemsAvailable"
      :class="!static ? 'left-0 right-auto' : 'relative rounded-t-none p-0'"
      class="listbox-options bg-slate-800"
    >
      <div
        class="px-4 text-sm"
        :class="props.layout === 'slate' ? 'text-slate-400 py-2 px-2 -mt-1 -mb-1 border border-slate-700 shadow-lg rounded-md' : 'text-gray-400 left-0 py-2'"
      >
        {{ $t('common.noMatchingResults') }}
        <slot name="explanation" />
        <slot name="addButton" />
      </div>
    </div>
  </Combobox>
</template>
