<script setup lang="ts">
// external
import { onMounted, computed } from "vue"
import { storeToRefs } from "pinia"
import { MenuButton } from "@headlessui/vue"
import { EllipsisHorizontalIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { TrashIcon } from "@heroicons/vue/24/outline"
import { PlusIcon } from "@heroicons/vue/20/solid"

// internal
import { CrudContext, Document, DocumentOrigin, DocumentRelationshipRole, DocumentType, LinkedDocument, Template } from "~/types"
import { Dropdown, DropdownLink, EmptyState, OverlayScrollbar, SkeletonLoader, SpinLoader, StageDisplay, LinkedDocumentPopover, DialogModal } from "~/components"
import { ImportFileIcon, LinkIcon, PDFIcon } from "~/icons"
import { documentTypes, formatDateRelative } from "~/utils"
import { useStages } from "~/composables"
import { useConfirmationStore, useDocumentStore, useLinkedDocumentStore, useSharedStore, useTemplateStore } from "~/stores"
import { useI18n } from "vue-i18n"
import { Link } from "@inertiajs/vue3"

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const documentStore = useDocumentStore()
const { mau, mdu, currentDocument } = storeToRefs(documentStore)

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const linkedDocumentStore = useLinkedDocumentStore()
const { linkedDocuments, uuidOfIsRemovingLinkedDocument, isLoadingLinkedDocuments, isVisibleLinkedDocumentsModal } = storeToRefs(linkedDocumentStore)
const { fetchLinkedDocuments, setIsVisibleLinkedDocumentsModal } = linkedDocumentStore

const { t } = useI18n()

const confirmationStore = useConfirmationStore()
const {
  setShowConfirmModal,
  setConfirmOptions,
} = confirmationStore

interface MappedLinkedDocument {
  uuid: Document["uuid"]
  relationship_type: string
  role: DocumentRelationshipRole
  linked_document: Document
}

const mappedLinkedDocuments = computed<MappedLinkedDocument[]>(() => {
  return linkedDocuments.value.map((linkedDocument) => {
    // Determine role based on if the currentDocument is controller or peripheral
    let role: DocumentRelationshipRole
    let documentToUse: Document
    if (linkedDocument.controller_document?.uuid === currentDocument.value?.uuid) {
      role = DocumentRelationshipRole.peripheral
      documentToUse = linkedDocument.peripheral_document
    } else {
      role = DocumentRelationshipRole.controller
      documentToUse = linkedDocument.controller_document
    }
    return {
      uuid: linkedDocument.uuid,
      relationship_type: linkedDocument.relationship_type,
      role: role,
      linked_document: documentToUse,
    }
  })
})

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return crudContext.value === CrudContext.document ? currentDocument.value?.uuid : currentTemplate.value?.uuid
})

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

const { stages } = useStages(null, null, true)

const getStageByName = (stage) => stages.value.find((el) => el.name === stage)

const confirmDeleteLinkedDocument = (callback: () => void) => {
  setConfirmOptions({
    title: t("linkedDocuments.confirmDelete"),
    description: crudContext.value === CrudContext.template ? t("linkedDocuments.confirmDeleteDescriptionTemplate") : t("linkedDocuments.confirmDeleteDescriptionDocument"),
    buttonText: t("linkedDocuments.confirmDeleteButton"),
    callback: callback,
    cancelCallback: () => { return false },
  })
  setShowConfirmModal(true)
}

// Function to remove a linked document from an entity
const removeLinkedDocument = (e: PointerEvent, linkedDocumentUuid: LinkedDocument["uuid"]) => {
  e.preventDefault()
  e.stopImmediatePropagation()

  if (entityUuid.value) {

    confirmDeleteLinkedDocument(async () => {

      linkedDocumentStore.removeLinkedDocumentFromEntity(entityUuid.value, linkedDocumentUuid)

    })
  }
}

const getOpposityRelationshipRole = (role: DocumentRelationshipRole) => {
  return role === DocumentRelationshipRole.controller ? DocumentRelationshipRole.peripheral : DocumentRelationshipRole.controller
}

onMounted(async () => {
  fetchLinkedDocuments(entityUuid.value)
})

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="px-6 pt-6 shrink-0"
      :class="!linkedDocuments?.length ? 'border-b border-b-gray-200' : ''"
    >
      <h3
        class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('linkedDocuments.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('linkedDocuments.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingLinkedDocuments"
      class="px-6 pt-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>
    <template v-else-if="!linkedDocuments?.length">
      <EmptyState
        :hide-button="true"
        class="my-6"
      >
        <template #icon>
          <LinkIcon
            aria-hidden="true"
          />
        </template>
        {{ $t('linkedDocuments.empty') }}
      </EmptyState>
      <div
        v-if="!!mau && !currentDocument?.archived_at && (crudContext === CrudContext.template || true || mdu?.permissions?.includes('linked_document_create'))"
        class="mb-6 text-center -mt-9"
      >
        <div class="relative inline-block mx-auto text-left">
          <button
            type="button"
            class="btn-plain mx-auto btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
            data-template="addLinkedDocumentForm"
            @click="setIsVisibleLinkedDocumentsModal(true)"
          >
            <PlusIcon class="shrink-0 h-3.5 w-3.5" />
            {{ $t('linkedDocuments.addLinkedDocument') }}
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <OverlayScrollbar
        ref="linkedDocumentsScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <ul
          role="list"
          class="mb-4 border-t border-gray-200 divide-y divide-gray-200"
        >
          <li
            v-for="linkedDocument in mappedLinkedDocuments"
            :key="linkedDocument.uuid"
          >
            <Link
              class="flex items-center justify-between gap-3 px-6 py-4 hover:bg-gray-100 group"
              :href="route('documents.show', {document: linkedDocument.linked_document.uuid})"
            >
              <div class="flex flex-col flex-1 w-full md:w-0">
                <div class="flex items-center mb-2 text-xs font-medium tracking-wide text-indigo-600 uppercase group-hover:text-indigo-700">
                  <div class="p-1 flex-none rounded-full text-indigo-600 bg-indigo-100 group-hover:bg-indigo-200 group-hover:text-indigo-700 mr-1.5 grow-0">
                    <LinkIcon
                      class="w-3 h-3"
                      aria-hidden="true"
                    />
                  </div>
                  {{ $t(`linkedDocuments.documentRelationships.${linkedDocument.relationship_type}.${getOpposityRelationshipRole(linkedDocument.role)}`) }}
                </div>
                <div class="flex items-center">
                  <div class="relative">
                    <component
                      :is="getDocumentTypeInfo(linkedDocument.linked_document?.document_type || DocumentType.other)?.icon"
                      class="w-8 h-8"
                      aria-hidden="true"
                    />
                    <div
                      v-if="linkedDocument.linked_document?.origin === DocumentOrigin.pdf"
                      class="w-4 h-4 absolute -left-0.5 -bottom-1 text-red-500 bg-white"
                    >
                      <PDFIcon
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      v-if="linkedDocument.linked_document?.origin === DocumentOrigin.signed_pdf"
                      class="w-4 h-4 absolute -left-0.5 -bottom-1 text-gray-500 bg-white"
                    >
                      <ImportFileIcon
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div
                    class="flex flex-col flex-1 ml-2"
                  >
                    <span class="text-xs font-medium line-clamp-2">{{ linkedDocument.linked_document.name }}</span>

                    <span
                      class="flex items-center text-xs opacity-50"
                    >
                      <span class="truncate">
                        {{ $t('linkedDocuments.updatedAt', {time: formatDateRelative(linkedDocument.linked_document?.updated_at)}) }}
                      </span>

                      <StageDisplay
                        :stage="getStageByName(linkedDocument.linked_document?.stage)"
                        label-classes="ml-1 text-xs"
                        class="ml-2"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="relative"
                @click="$event => $event.stopImmediatePropagation()"
              >
                <Dropdown
                  menu-classes="text-left"
                  width="w-fit"
                  align="right"
                >
                  <template
                    #trigger="{open}"
                  >
                    <MenuButton
                      class="btn-plain group-hover:scale-100 group-hover:opacity-100 scale-0 opacity-0 transition-all hover:bg-gray-200 rounded-full p-0.5 text-gray-400 hover:text-gray-900 focus:text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:bg-gray-200"
                      :class="{ 'scale-100 opacity-100 bg-gray-200 text-gray-900': open }"
                    >
                      <EllipsisHorizontalIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </template>

                  <template #content>
                    <DropdownLink
                      as="button.prevent"
                      :disabled="uuidOfIsRemovingLinkedDocument === linkedDocument.uuid"
                      :icon="true"
                      @click="removeLinkedDocument($event, linkedDocument.uuid)"
                    >
                      <TrashIcon
                        v-if="uuidOfIsRemovingLinkedDocument !== linkedDocument.uuid"
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <SpinLoader
                        v-else
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <span class="flex-grow whitespace-nowrap">
                        {{ $t('common.remove') }}
                      </span>
                    </DropdownLink>
                  </template>
                </Dropdown>
              </div>
            </Link>
          </li>
        </ul>
      </OverlayScrollbar>
    </template>

    <div
      v-if="linkedDocuments?.length && !!mau && !currentDocument?.archived_at && (crudContext === CrudContext.template || true || mdu?.permissions?.includes('linked_document_create'))"
      class="px-6 py-4 border-t border-gray-200 shrink-0"
    >
      <div class="relative inline-flex">
        <button
          class="items-center btn-primary"
          @click="setIsVisibleLinkedDocumentsModal(true)"
        >
          <span>{{ $t('linkedDocuments.addLinkedDocument') }}</span>
        </button>
      </div>
    </div>

    <DialogModal
      id="linkedDocumentsModal"
      :show="isVisibleLinkedDocumentsModal"
      max-width="xl"
      :closeable="true"
      :padding="false"
      :show-footer="false"
      :show-close-button="true"
      @close="setIsVisibleLinkedDocumentsModal(false)"
    >
      <template
        #content
      >
        <div
          v-cy="`linked-documents-modal`"
        >
          <LinkedDocumentPopover
            :document="currentDocument"
            :template="currentTemplate"
          />
        </div>
      </template>
    </DialogModal>
  </div>
</template>
