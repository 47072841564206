import { Node } from "@tiptap/pm/model"
import * as docx_1 from "docx"
import { DocxSerializerState } from "prosemirror-docx"

export const generateToc = (state: DocxSerializerState, node: Node) => {

  node

  //const tableOfContentsLabel = t("editor.tableOfContents")
  const tableOfContentsLabel = "Table of Contents"

  /* const characterStyle = new docx_1.StyleForCharacter({
    id: "TOC_Character",
    name: "TOC",
    run: configureDefaultRunOptionsForNode(state, node),
  }) */

  const styleLevel3 = new docx_1.StyleLevel("TOC3", 3)
  const styleLevel2 = new docx_1.StyleLevel("TOC2", 2)
  const styleLevel1 = new docx_1.StyleLevel("TOC1", 1)

  const styleLevels = [ styleLevel1, styleLevel2, styleLevel3 ]

  const tableOfContents = new docx_1.TableOfContents(tableOfContentsLabel, {
    hyperlink: true,
    headingStyleRange: "1-3",
    stylesWithLevels: styleLevels,
  })

  state.children.push(tableOfContents)
}
