<script setup lang="ts">
// external
import { storeToRefs } from "pinia"

import { NodeViewWrapper } from "@tiptap/vue-3"

// internal
import { useEditorStore } from "~/stores"

const editorStore = useEditorStore()
const { tocData } = storeToRefs(editorStore)

const scrollToHeading = (e: MouseEvent, id: string) => {
  if (!id) return

  const el = document.getElementById(id)

  if (!el) return

  e.preventDefault()
  e.stopPropagation()

  el.scrollIntoView(
    {
      behavior: "smooth",
      block: "start",
    },
  )
}
</script>

<template>
  <NodeViewWrapper class="toc">
    <ul
      class="toc__list"
      :data-label="$t('editor.tableOfContents')"
    >
      <li
        v-for="(heading, index) in tocData"
        :key="index"
        class="toc__item"
        :class="
          [
            heading.level
              ? `toc__item--${heading.level}`
              : ''
          ]
        "
      >
        <a
          :href="`#${heading.id}`"
          @click="scrollToHeading($event, heading.id)"
        >
          <span class="tabular-nums">
            {{ heading.orderRepresentation }}
          </span>

          {{ heading.text }}
        </a>
      </li>
    </ul>
  </NodeViewWrapper>
</template>

<style lang="scss">
.toc {
  @apply py-4 border-t border-b border-gray-200;

  &__list {
    @apply list-none p-0 flex flex-col gap-1;

    &::before {
      @apply block text-xs font-medium tracking-wider text-gray-500 uppercase mb-1.5;
      content: attr(data-label);
    }
  }

  &__item {
    a {
      @apply text-gray-900;
    }

    a:hover {
      @apply text-gray-700;
    }

    &--2 {
      padding-left: 1ch;
    }

    &--3 {
      padding-left: 2ch;
    }
  }
}
</style>
