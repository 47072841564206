<script setup lang="ts">
// external
import { watch, onMounted, computed } from "vue"
import { storeToRefs } from "pinia"
import { BellSnoozeIcon, Cog6ToothIcon, EyeIcon } from "@heroicons/vue/24/outline"
import { CheckCircleIcon } from "@heroicons/vue/20/solid"
import { Link } from "@inertiajs/vue3"

// internal
import { DialogModal, EmptyState, LoadingPlaceholder, SpinLoader, NotificationEntry } from "~/components"
import { useNotificationStore, useAccountStore } from "~/stores"

const notificationStore = useNotificationStore()
const { showNotificationModal, notifications, isMarkingAllAsRead, isLoadingNotifications, unreadNotificationsCount, paginationMeta } = storeToRefs(notificationStore)
const { markAllAsRead, fetchNotifications, subscribeToPusher, markAsRead } = notificationStore

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

watch(showNotificationModal, (newVal) => {
  if (newVal) {
    fetchNotifications()
  }
})

onMounted(() => {
  subscribeToPusher(mau.value.user.uuid)
})

const currentPage = computed(() => paginationMeta.value?.current_page || 1)
const showLoadMore = computed(() => paginationMeta.value.current_page < paginationMeta.value.last_page)

</script>
<template>
  <DialogModal
    :show="showNotificationModal"
    max-width="2xl"
    :show-footer="false"
    :show-close-button="true"
    :padding="false"
    @close="showNotificationModal = false"
  >
    <template #title>
      <div class="flex items-center px-6 pt-2 gap-x-5">
        <div>{{ $t('navigation.notifications') }}</div>
        <div class="flex items-center gap-x-1">
          <Link
            class="text-gray-400 btn-header hover:text-gray-900"
            :href="route('user-profile.notification.index')"
            data-tippy-help
            :data-tippy-content="$t('common.goToSettings')"
            data-placement="bottom"
            @click="showNotificationModal = false"
          >
            <Cog6ToothIcon
              aria-hidden="true"
              class="w-5 h-5"
            />
          </Link>
          <button
            v-if="notifications?.length"
            type="button"
            class="text-gray-400 btn-header hover:text-gray-900"
            data-tippy-help
            :data-tippy-content="$t('notifications.markAllAsRead')"
            data-placement="bottom"
            :disabled="isMarkingAllAsRead || isLoadingNotifications || !unreadNotificationsCount"
            @click="markAllAsRead"
          >
            <SpinLoader
              v-if="isMarkingAllAsRead"
              class="w-4 h-4 text-gray-900"
            />
            <template v-else>
              <EyeIcon
                aria-hidden="true"
                class="w-5 h-5"
              />
              <CheckCircleIcon
                aria-hidden="true"
                class="absolute w-3 h-3 text-indigo-700 bottom-2 right-1.5"
              />
            </template>
          </button>
        </div>
      </div>
    </template>

    <template #content>
      <div
        v-if="isLoadingNotifications && !notifications.length"
        class="px-6 pb-4 space-y-2"
      >
        <LoadingPlaceholder
          v-for="n in 5"
          :key="n"
          class="relative w-full h-10 rounded-md"
        />
      </div>
      <div v-else-if="!notifications?.length">
        <EmptyState>
          <template #icon>
            <BellSnoozeIcon
              aria-hidden="true"
            />
          </template>

          {{ $t('notifications.empty') }}
        </EmptyState>
      </div>
      <div
        v-else
        class="pb-5 -mt-2"
      >
        <NotificationEntry
          v-for="notification in notifications"
          :key="'notification_' + notification.uuid"
          :notification="notification"
          @read="markAsRead(notification.uuid)"
        />

        <div
          v-if="showLoadMore"
          class="flex items-center justify-center w-full mt-4 -mb-1"
        >
          <button
            type="button"
            class="flex items-center space-x-2 btn-sm btn-white"
            :disabled="isLoadingNotifications"
            @click="fetchNotifications(currentPage + 1)"
          >
            <SpinLoader
              v-if="isLoadingNotifications"
              class="w-4 h-4 text-gray-900"
            />
            <span>{{ $t('notifications.loadMore') }}</span>
          </button>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
