<script setup lang="ts">

// internal
import { SelectOption } from "~/types"

interface Props {
  modelValue: string[]
  options: SelectOption[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const emit = defineEmits([ "update:model-value" ])

const check = (value: string) => {
  const updatedValue = props.modelValue ? [ ...props.modelValue ] : []
  if (!updatedValue.includes(value)) {
    updatedValue.push(value)
  } else {
    updatedValue.splice(updatedValue.indexOf(value), 1)
  }
  emit("update:model-value", updatedValue)
}

</script>

<template>
  <button
    v-for="option in options"
    :key="option.value"
    type="button"
    :class="modelValue.includes(option.value.toString()) ? 'bg-indigo-50 text-indigo-800' : 'hover:bg-gray-100 text-gray-600'"
    class="flex items-center w-full py-1 px-2 rounded-md cursor-pointer text-sm"
    @click="check(option.value.toString())"
  >
    <span class="text-left">
      {{ option.label }}
    </span>
  </button>
</template>
