<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import { SpinLoader } from "~/components"
import { useConfirmationStore, useDocumentStore } from "~/stores"
import { WorkingCopyIcon } from "~/icons"

const documentStore = useDocumentStore()
const {
  currentDocument,
  activeWorkingCopy,
  isLoadingWorkingCopy,
  documentEditorSession,
} = storeToRefs(documentStore)
const {
  createWorkingCopy,
  publishWorkingCopy,
  destroyDocumentEditorSession,
} = documentStore

const confirmationStore = useConfirmationStore()
const {
  setShowConfirmModal,
  setConfirmOptions,
} = confirmationStore

const { t } = useI18n()

const activeWorkingCopyExistsAndIsUnpublished = computed(() => {
  return !!(activeWorkingCopy.value && !activeWorkingCopy.value.is_published)
})

const switchActive = computed({
  get: () => activeWorkingCopyExistsAndIsUnpublished.value,
  set: async (newVal) => {
    if (newVal === true && !activeWorkingCopyExistsAndIsUnpublished.value) {

      // destroy active documentEditorSession first (if available)
      if (!!documentEditorSession.value) await destroyDocumentEditorSession(currentDocument.value?.uuid, documentEditorSession.value.uuid)
      await createWorkingCopy(currentDocument.value?.uuid)
    } else if (newVal === false && activeWorkingCopyExistsAndIsUnpublished.value) {
      setConfirmOptions({
        title: t("common.areYouSure"),
        description: t("documents.workingCopyConfirm"),
        buttonText: t("documents.publishChanges"),
        callback: async () => {
          isLoadingWorkingCopy.value = true

          // destroy active documentEditorSession first (if available)
          if (!!documentEditorSession.value) await destroyDocumentEditorSession(currentDocument.value?.uuid, documentEditorSession.value.uuid)

          await publishWorkingCopy(
            currentDocument.value?.uuid,
            activeWorkingCopy.value.uuid,
          )
        },
        cancelCallback: () => {
          switchActive.value = true
          return false
        },
      })
      setShowConfirmModal(true)
    }
  },
})

</script>

<template>
  <SwitchGroup
    as="div"
    class="flex items-center"
  >
    <SwitchLabel
      v-if="isLoadingWorkingCopy"
      as="span"
      class="mr-3 text-sm text-gray-400"
      passive
    >
      <SpinLoader class="inline-block w-4 h-4 text-gray-400 xl:hidden" />
      <span class="hidden font-medium xl:inline">{{ $t('common.pleaseWait') }}…</span>
    </SwitchLabel>
    <SwitchLabel
      v-else-if="activeWorkingCopyExistsAndIsUnpublished"
      as="span"
      class="inline-flex items-center gap-2 mr-3 text-sm text-gray-600"
      passive
    >
      <span
        class="inline-block xl:hidden"
        data-tippy-help
        :data-tippy-content="$t('documents.workingCopyOn')"
        data-placement="bottom"
      >
        <WorkingCopyIcon
          class="w-4 h-4 text-indigo-600"
          aria-hidden="true"
        />
      </span>
      <span class="hidden font-medium xl:inline-block">{{ $t("documents.workingCopyOn") }}</span>
      <span
        data-tippy-help
        :data-tippy-content="$t('documents.workingCopyHint')"
        data-placement="bottom"
        class="hidden xl:inline-block"
      >
        <QuestionMarkCircleIcon
          class="w-4 h-4 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </SwitchLabel>
    <SwitchLabel
      v-else
      as="span"
      class="inline-flex items-center gap-2 mr-3 text-sm text-gray-400"
      passive
    >
      <span
        class="inline-block xl:hidden"
        data-tippy-help
        :data-tippy-content="$t('documents.workingCopyOff')"
        data-placement="bottom"
      >
        <WorkingCopyIcon
          class="w-4 h-4 text-gray-400"
          aria-hidden="true"
        />
      </span>
      <span class="hidden font-medium xl:inline-block">{{ $t("documents.workingCopyOff") }}</span>
      <span
        class="hidden xl:inline-block"
        data-tippy-help
        :data-tippy-content="$t('documents.workingCopyHint')"
        data-placement="bottom"
      >
        <QuestionMarkCircleIcon
          class="w-4 h-4 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </SwitchLabel>
    <Switch
      v-model="switchActive"
      :disabled="isLoadingWorkingCopy"
      :class="[
        activeWorkingCopyExistsAndIsUnpublished ? 'bg-indigo-600' : 'bg-gray-300',
        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
      ]"
    >
      <span
        class="relative flex items-center justify-center w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow ring-0"
        :class="[activeWorkingCopyExistsAndIsUnpublished ? 'translate-x-5' : 'translate-x-0']"
      >
        <SpinLoader
          v-if="isLoadingWorkingCopy"
          class="w-3 h-3 text-gray-400"
        />
        <template v-else>
          <span
            :class="[
              activeWorkingCopyExistsAndIsUnpublished
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
            ]"
            aria-hidden="true"
          >
            <svg
              class="w-3 h-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span
            :class="[
              activeWorkingCopyExistsAndIsUnpublished
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
            ]"
            aria-hidden="true"
          >
            <svg
              class="w-3 h-3 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path
                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
              />
            </svg>
          </span>
        </template>

      </span>
    </Switch>
  </SwitchGroup>
</template>
