<script setup lang="ts">
// external
import { computed, ref, watch, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import { CalendarDaysIcon, CheckCircleIcon } from "@heroicons/vue/24/outline"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"

// internal
import { DocumentNotificationTriggerTimeCondition } from "~/types"
import { RadioGroupPills, DateInput, DurationInput } from "~/components"

interface Props {
  triggerTime?: string
  hasError?: boolean
  triggerTimeCondition?: DocumentNotificationTriggerTimeCondition
}

const props = withDefaults(
  defineProps<Props>(),
  {
    hasError: false,
    triggerTime: null,
    triggerTimeCondition: null,
  },
)

const { t } = useI18n()

const localTriggerTime = computed({
  get: () => props.triggerTime,
  set: (triggerTime) => emits("update:trigger-time", triggerTime),
})
const localTriggerTimeCondition = computed({
  get: () => props.triggerTimeCondition,
  set: (triggerTimeCondition) => emits("update:trigger-time-condition", triggerTimeCondition),
})

const emits = defineEmits([ "update:trigger-time", "update:trigger-time-condition" ])

enum TriggerTimeOption {
  date = "date",
  condition = "condition",
}

const timeTriggerOptions = [
  {
    title: t("documentNotifications.triggerTimeOptions.date"),
    value: TriggerTimeOption.date,
    name: TriggerTimeOption.date,
    icon: CalendarDaysIcon,
  },
  {
    title: t("documentNotifications.triggerTimeOptions.condition"),
    value: TriggerTimeOption.condition,
    name: TriggerTimeOption.condition,
    icon: CheckCircleIcon,
  },
]

const selectedTriggerTimeOption = ref(props.triggerTime ? TriggerTimeOption.date : TriggerTimeOption.condition)

watch(selectedTriggerTimeOption, (newVal) => {
  if (newVal === TriggerTimeOption.date) {
    localTriggerTimeCondition.value = null
    localTriggerTime.value = getTodaysDate()
  } else if (newVal === TriggerTimeOption.condition) {
    localTriggerTime.value = null
    localTriggerTimeCondition.value = {
      type: "before",
      interval: "P2W",
    }
  }
})

const getTodaysDate = () => {
  const today = new Date()
  return today.toISOString().split("T")[0]
}

enum TriggerTimeConditionTypeOption {
  before = "before",
  after = "after",
}


onMounted(() => {
  if (localTriggerTimeCondition.value && !localTriggerTimeCondition.value.type) {
    localTriggerTimeCondition.value.type = TriggerTimeConditionTypeOption.before
  }
})

</script>

<template>
  <fieldset
    class="relative"
  >
    <h3 class="relative flex items-center w-full h-px px-4 mt-4 mb-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-white">
      <span class="px-2 py-0.5 text-indigo-500 bg-white">{{ $t('documentNotifications.triggerTime') }}</span>
    </h3>
    <div class="px-6 py-2">
      <RadioGroupPills
        v-model="selectedTriggerTimeOption"
        :not-localized="true"
        :sr-label="$t('documentNotifications.recipients')"
        :disabled="false"
        grid-class="grid grid-cols-3 gap-2 grow min-w-[9rem] mb-3"
        :options="timeTriggerOptions"
      />
      <div v-if="selectedTriggerTimeOption === TriggerTimeOption.date">
        <DateInput
          v-model="localTriggerTime"
          class="flex w-auto min-h-[36px]"
          :show-day="true"
          :show-month="true"
          :show-year="true"
        />
      </div>
      <div v-else-if="selectedTriggerTimeOption === TriggerTimeOption.condition">
        <div class="flex items-start space-x-3">
          <DurationInput
            v-model="localTriggerTimeCondition.interval"
            :clearable="false"
          />
          <Listbox
            v-model="localTriggerTimeCondition.type"
            as="div"
            class="relative"
          >
            <ListboxButton
              class="btn-listbox-plain"
              :class="{'error': hasError}"
            >
              <div
                class="flex items-center"
              >
                <span class="block truncate">
                  {{ $t('documentNotifications.triggerTimeConditionTypes.' + localTriggerTimeCondition.type) }}
                </span>
              </div>
              <span
                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <ChevronUpDownIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions class="z-20 listbox-options">
                <ListboxOption
                  v-for="conditionType in TriggerTimeConditionTypeOption"
                  :key="conditionType"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="conditionType"
                >
                  <li
                    :class="[
                      active ? 'bg-gray-700' : '',
                      'listbox-option',
                    ]"
                  >
                    <div class="flex items-center">
                      <span
                        :class="[
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate',
                        ]"
                      >
                        {{ $t('documentNotifications.triggerTimeConditionTypes.' + conditionType) }}
                      </span>
                    </div>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5"
                        aria-hidden="true"
                      />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </Listbox>
        </div>
      </div>
    </div>
  </fieldset>
</template>
