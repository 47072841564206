<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useAccountStore, useAiStore, useDocumentStore, useMetadataStore, usePartyStore } from "~/stores"
import { AiAnalysis, AiAnalysisStatus, CrudContext } from "~/types"
import { showLoadingIndicator } from "~/utils"
import { CheckBadgeIcon, SparklesIcon } from "@heroicons/vue/24/outline"
import { AiAnalysisReviewModal, AiAnalysisStatusDisplay } from "~/components"
import { computed, watch } from "vue"
import { useI18n } from "vue-i18n"
import { ref } from "vue"

interface Props {
  aiAnalysis?: AiAnalysis
  aiAnalyses?: AiAnalysis[]
  isMinimal?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    aiAnalysis: null,
    aiAnalyses: null,
    isMinimal: false,
  },
)

const aiStore = useAiStore()
const { analysis, showAiAnalysisReviewModal } = storeToRefs(aiStore)
const { createAiAnalysis, setShowAiAnalysisReviewModal } = aiStore

const documentStore = useDocumentStore()
const { currentDocument } = storeToRefs(documentStore)

const accountStore = useAccountStore()
const { planFeatures, accountUsage } = storeToRefs(accountStore)

const { t } = useI18n()

const aiAnalysis = computed(() => props.aiAnalysis || analysis.value || props.aiAnalyses?.find((el) => [ AiAnalysisStatus.created, AiAnalysisStatus.executing, AiAnalysisStatus.pending ].includes(el.status)))
const firstFinishedAnalysis = computed(() => props.aiAnalyses?.find((el) => [ AiAnalysisStatus.review, AiAnalysisStatus.failed, AiAnalysisStatus.expired ].includes(el.status)))

// we need this in the context of onboarding
const emit = defineEmits([ "applied" ])

const reloadData = () => {
  if (!currentDocument.value) return
  useDocumentStore().fetchDocument(currentDocument.value.uuid)
  usePartyStore().fetchAllParties(CrudContext.document, currentDocument.value.uuid)
  useMetadataStore().fetchMetadataValues(CrudContext.document, currentDocument.value.uuid)
  // we need this in the context of onboarding
  emit("applied")
}

let loaderInterval: NodeJS.Timeout | null = null
let messageInterval: NodeJS.Timeout | null = null

const loaderWidth = ref<number>(0)

// Method to adjust loader percentage width every 500ms
// based on expected 7 minutes time, relative to created_at of analysis
const adjustLoaderPercentage = () => {
  const createdAt = new Date(aiAnalysis.value?.created_at)
  const now = new Date()
  const diff = now.getTime() - createdAt.getTime()
  const percentage = diff / 420000 * 100
  const roundedPercentage = Math.ceil(percentage * 100) / 100
  // Clear interval after 7 minutes
  if (roundedPercentage >= 100) return clearInterval(loaderInterval)
  if (roundedPercentage < loaderWidth.value) return
  loaderWidth.value = roundedPercentage
  loaderWidth.value = roundedPercentage
}

const randomizer = ref<number>(1)
// Correctly initialize messageNumber to range between 1 and 175
const messageNumber = ref<number>(Math.floor(Math.random() * 175) + 1)

const randomLoadingMessage = computed(() => {
  return t("loadingMessages.Loading" + messageNumber.value)
})

const changeModuloNumber = () => {
  // Ensure modulo operation is correctly applied for a range between 1 and 175
  randomizer.value = (Math.floor((Date.now() - Date.parse(aiAnalysis.value?.created_at)) / 5000) % 100 + 1)
  toggleLoadingMessageAnimationClass()
}

watch(randomizer, () => {
  messageNumber.value = Math.floor(Math.random() * 175) + 1
})

const toggleLoadingMessageAnimationClass = () => {
  const loadingMessage = document.getElementById("loadingMessage")
  if (loadingMessage) {
    loadingMessage.classList.toggle("animate")
    loadingMessage.classList.toggle("animate")
  }
}

// Watch analysis for status changes and clear Interval when status reaches review
watch(aiAnalysis, (newAnalysis) => {
  if (!newAnalysis) return
  if ([ AiAnalysisStatus.created, AiAnalysisStatus.executing, AiAnalysisStatus.pending ].includes(newAnalysis.status)) {
    showLoadingIndicator("aiAnalysisLoader")
    loaderInterval = setInterval(adjustLoaderPercentage, 100)
    messageInterval = setInterval(changeModuloNumber, 5000)
  }
  else if (newAnalysis.status === AiAnalysisStatus.review || firstFinishedAnalysis.value) {
    clearInterval(loaderInterval)
    clearInterval(messageInterval)
    messageNumber.value = Math.floor(Math.random() * 100) + 1
    loaderWidth.value = 100
  }
}, { immediate: true })

const isAiFeatureLimitLocked = computed(() => accountUsage.value && planFeatures.value?.["ai-extraction"] !== 0 && accountUsage.value.aiAnalyses >= planFeatures.value?.["ai-extraction"])
</script>
<template>
  <div
    v-if="!isAiFeatureLimitLocked && !(props.isMinimal && aiAnalysis?.status === AiAnalysisStatus.review)"
    class="relative overflow-hidden transition-all"
    :class="isMinimal ? 'h-[4px] min-h-[4px] bg-gradient-to-br from-indigo-800 to-indigo-600' : 'h-[36px] min-h-[36px] bg-gradient-to-br from-purple-800 to-purple-600'"
  >
    <div
      class="shimmer w-[200vw] light"
    />
    <div
      :class="(aiAnalysis.status === AiAnalysisStatus.review || (!aiAnalysis && firstFinishedAnalysis.status === AiAnalysisStatus.review)) ? 'opacity-0' : 'opacity-20'"
      class="absolute top-0 bottom-0 left-0 transition-all duration-500 bg-white pointer-events-none"
      :style="{width: loaderWidth + '%'}"
    />
    <div
      v-if="!isMinimal"
      class="relative flex items-center justify-center py-2 space-x-3 text-sm text-white px-7"
    >
      <span
        v-if="![ AiAnalysisStatus.review ].includes(aiAnalysis?.status)"
        id="loadingMessage"
        class="flex items-center animate"
      >
        <SparklesIcon
          class="w-4 h-4 mr-2 shrink-0"
          aria-hidden="true"
        />
        {{ $t('aiAnalysis.title') }}:
        <span class="ml-1">
          {{ randomLoadingMessage }}
        </span>
        <span
          id="aiAnalysisLoader"
          class="inline-block w-2 text-left"
        />
      </span>
      <span
        v-else-if="aiAnalysis"
        class="flex items-center gap-2"
      >
        <CheckBadgeIcon
          aria-hidden="true"
          class="w-5 h-5 shrink-0"
        />
        <span class="mr-1">
          {{ $t('documents.aiAnalysisCompleted') }}
        </span>
        <AiAnalysisStatusDisplay
          v-if="aiAnalysis"
          :ai-analysis="aiAnalysis"
          :style="'dark'"
          :allow-restart="false"
          @review="setShowAiAnalysisReviewModal(true)"
          @restart="createAiAnalysis(currentDocument?.uuid)"
        />
      </span>
    </div>
  </div>
  <AiAnalysisReviewModal
    :open="showAiAnalysisReviewModal"
    :ai-analysis-uuid="aiAnalysis?.uuid"
    :document="currentDocument"
    @applied="reloadData"
    @update:open="setShowAiAnalysisReviewModal"
  />
</template>

<style scoped>

#loadingMessage.animate {
  animation: change-opacity 5s infinite alternate;
}

@keyframes change-opacity {
  0% {opacity: 0}
  10% {opacity: 1}
  90% {opacity: 1}
  100% {opacity: 0}
}
</style>
