<script setup lang="ts">
import { ref, watch } from "vue"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/vue/24/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { Link } from "@inertiajs/vue3"
import { Pagination } from "~/types"

interface Props {
  pagination: Pagination<any>
  filterData?: any
  only?: string[]
  showPerPage?: boolean
  showResultInfo?: boolean
  useButtons?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    only: () => [ "pagination" ],
    showPerPage: true,
    showResultInfo: true,
    useButtons: false,
  },
)

const perPageOptions = ref([
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
])

const preSelectedPerPage = perPageOptions.value.find((perPageOption) => perPageOption.value === props.pagination.meta.per_page)

const perPage = ref(preSelectedPerPage)

const emit = defineEmits([ "update:per-page", "switch-page" ])

watch(perPage, (newVal) => {
  emit("update:per-page", newVal)
})
</script>

<template>
  <div
    class="py-3 border-t border-gray-200 @container flex items-center justify-center"
    :class="[!showPerPage && !showResultInfo ? '' : '@lg:justify-between']"
  >
    <div
      v-if="showResultInfo"
      class="hidden @xl:block w-56"
    >
      <p
        v-if="!pagination?.meta?.total"
        class="text-sm text-gray-500"
      >
        {{ $t('pagination.noResults') }}
      </p>
      <p
        v-else
        class="text-sm text-gray-500"
      >
        {{ $t('pagination.results', {
          from: pagination.meta.from || 0,
          to: pagination.meta.to || 0,
          total: pagination.meta.total
        }) }}
      </p>
    </div>
    <div class="relative z-0 flex rounded-md divide-x divide-white w-[207px]">
      <component
        :is="(pagination.links.first && pagination.meta.last_page > 1 && pagination.meta.current_page !== 1) ? (useButtons ? 'button' : Link) : 'span'"
        :href="useButtons ? undefined : pagination.links.first"
        :type="useButtons ? 'button' : undefined"
        :data="filterData"
        class="relative inline-flex items-center p-2.5 rounded-l-md bg-gray-100 text-sm font-medium text-gray-500"
        :class="(pagination.links.first && pagination.meta.last_page > 1 && pagination.meta.current_page !== 1) ? 'hover:bg-gray-200 duration-150 transition-colors focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500' : 'opacity-50 pointer-events-none cursor-default'"
        preserve-state
        preserve-scroll
        :only="only"
        @click="$emit('switch-page', 1)"
      >
        <span class="sr-only">{{ $t('pagination.firstPage') }}</span>
        <ChevronDoubleLeftIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
      </component>
      <component
        :is="pagination.links.prev ? (useButtons ? 'button' : Link) : 'span'"
        :href="useButtons ? undefined : pagination.links.prev"
        :type="useButtons ? 'button' : undefined"
        :data="filterData"
        class="-ml-px relative inline-flex items-center p-2.5 bg-gray-100 text-sm font-medium text-gray-500"
        :class="pagination.links.prev ? 'hover:bg-gray-200 duration-150 transition-colors focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500' : 'opacity-50 pointer-events-none cursor-default'"
        preserve-state
        preserve-scroll
        :only="only"
        @click="$emit('switch-page', pagination.meta.current_page - 1)"
      >
        <span class="sr-only">{{ $t('pagination.previous') }}</span>
        <ChevronLeftIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
      </component>
      <span
        class="relative inline-flex items-center justify-center px-1 py-2 -ml-px text-sm font-medium text-gray-500 bg-gray-100 cursor-default page-indicator"
      >
        <span class="-my-3">
          <span>{{ pagination.meta.current_page }}</span>
          <span class="text-gray-400 ml-0.5 tracking-widest">/{{ pagination.meta.last_page }}</span>
        </span>
      </span>
      <component
        :is="pagination.links.next ? (useButtons ? 'button' : Link) : 'span'"
        :href="useButtons ? undefined : pagination.links.next"
        :type="useButtons ? 'button' : undefined"
        :data="filterData"
        class="-ml-px relative inline-flex items-center p-2.5 bg-gray-100 text-sm font-medium text-gray-500"
        :class="pagination.links.next ? 'hover:bg-gray-200 duration-150 transition-colors focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500' : 'opacity-50 pointer-events-none cursor-default'"
        preserve-state
        preserve-scroll
        :only="only"
        @click="$emit('switch-page', pagination.meta.current_page + 1)"
      >
        <span class="sr-only">{{ $t('pagination.next') }}</span>
        <ChevronRightIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
      </component>
      <component
        :is="(pagination.links.last && pagination.meta.last_page > 1 && pagination.meta.current_page !== pagination.meta.last_page) ? (useButtons ? 'button' : Link) : 'span'"
        :href="useButtons ? undefined : pagination.links.last"
        :type="useButtons ? 'button' : undefined"
        :data="filterData"
        class="-ml-px relative inline-flex items-center p-2.5 rounded-r-md bg-gray-100 text-sm font-medium text-gray-500"
        :class="(pagination.links.last && pagination.meta.last_page > 1 && pagination.meta.current_page !== pagination.meta.last_page) ? 'hover:bg-gray-200 duration-150 transition-colors focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500' : 'opacity-50 pointer-events-none cursor-default'"
        preserve-state
        preserve-scroll
        :only="only"
        @click.prevent="$emit('switch-page', pagination.meta.last_page)"
      >
        <span class="sr-only">{{ $t('pagination.lastPage') }}</span>
        <ChevronDoubleRightIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
      </component>
    </div>
    <div
      v-if="showPerPage"
      class="items-center w-56 justify-end hidden @md:flex"
    >
      <span class="mr-3 text-sm text-gray-500">
        {{ $t('pagination.itemsPerPage') }}:
      </span>
      <Listbox
        v-model="perPage"
        class="w-18"
        as="div"
      >
        <div class="relative">
          <ListboxButton class="btn-listbox-plain">
            <span>{{ perPage.label }}</span>
            <span class="absolute inset-y-0 flex items-center pointer-events-none right-1">
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="absolute z-10 mb-1 bottom-full listbox-options">
              <ListboxOption
                v-for="option in perPageOptions"
                :key="option.label"
                v-slot="{ active, selected }"
                as="template"
                :value="option"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <div class="flex items-center">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ option.label }}</span>
                  </div>
                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
  </div>
</template>

<style scoped>
.page-indicator {
   min-width: 4rem;
}
</style>
