<script setup lang="ts">
// external
import { BookmarkIcon } from "@heroicons/vue/24/outline"
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/vue/24/solid"
import { onBeforeMount, ref } from "vue"
import { storeToRefs } from "pinia"

// internal
import { useBookmarkStore } from "~/stores"
import { BookmarkModal, SpinLoader } from "~/components"

interface Props {
  listName: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const showModal = ref(false)

const bookmarkStore = useBookmarkStore()
const { isFetchingBookmarks, bookmarks } = storeToRefs(bookmarkStore)
const { fetchBookmarks } = bookmarkStore

onBeforeMount(() => {
  fetchBookmarks(props.listName)
})
</script>

<template>
  <div>
    <button
      type="button"
      class="flex items-center space-x-2 btn-plain rounded-l-none"
      data-tippy-help
      data-placement="top"
      :data-tippy-content="$t('bookmarks.bookmarkedSearches')"
      @click="showModal = true"
    >
      <SpinLoader
        v-if="isFetchingBookmarks"
        class="w-4 h-4 -mx-1 my-0.5"
      />
      <BookmarkIcon
        v-else-if="!bookmarks.length"
        aria-hidden="true"
        class="w-4 h-4 -mx-1 my-0.5"
      />
      <BookmarkIconSolid
        v-else
        aria-hidden="true"
        class="w-4 h-4 -mx-1 my-0.5 text-indigo-300"
      />
      <span
        v-if="bookmarks.length"
        class="rounded-full w-3 h-3 bg-indigo-500 flex absolute top-5 left-4 items-center justify-center text-white text-[8px]"
      >
        <span>{{ bookmarks.length }}</span>
      </span>
    </button>
    <BookmarkModal
      v-model:show="showModal"
      :list-name="listName"
    />
  </div>
</template>
