<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"

import { PencilIcon, CheckIcon } from "@heroicons/vue/24/outline"

// internal
import { useDashboardStore } from "~/stores"
import { AccountUser, DashboardTab } from "~/types"
import { DashboardSelect, DashboardModal, BookDemoCta } from "~/components"


interface Props {
  mau: AccountUser,
}
const props = withDefaults(
  defineProps<Props>(),
  {},
)

const dashboardToEdit = ref(null)
const showDashboardModal = ref(false)

const displayName = computed(() => props.mau.first_name)

const dashboardStore = useDashboardStore()
const { isEditMode, dashboards, activeDashboardUuid, showMenu, activeTabKey } = storeToRefs(dashboardStore)
const { toggleEditMode } = dashboardStore

</script>

<template>
  <div class="relative z-[101] -mr-2">
    <div class="flex items-center justify-center space-x-3 md:justify-between">
      <div>
        <div class="items-center hidden w-full md:flex grow">
          <div class="relative inline-block">
            <div class="flex items-center">
              <h1
                class="text-base font-bold text-gray-900 sm:truncate"
              >
                {{ $t('dashboard.welcomeBack', { name: displayName }) }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div
        class="items-center flex space-x-1.5"
      >
        <BookDemoCta class="pr-5" />
        <DashboardSelect
          @create="() => {
            dashboardToEdit = null
            showDashboardModal = true
          }"
          @edit="() => {
            dashboardToEdit = dashboards.find((dashboard) => dashboard.uuid === activeDashboardUuid)
            if (!isEditMode) {
              toggleEditMode(DashboardTab.general)
            } else {
              activeTabKey = DashboardTab.general
              showMenu = true
            }
          }"
        />
        <div class="hidden lg:block">
          <button
            v-if="isEditMode"
            type="button"
            class="btn-header-green"
            data-tippy-help
            :data-tippy-content="$t('dashboard.endEditMode')"
            data-placement="bottom"
            @click="toggleEditMode(DashboardTab.widgets)"
          >
            <CheckIcon
              aria-hidden="true"
              class="w-5 h-5 -mx-1.5"
            />
            <span class="sr-only">
              {{ $t('dashboard.endEditMode') }}
            </span>
          </button>
          <button
            v-else
            type="button"
            class="btn-header"
            data-tippy-help
            :data-tippy-content="$t('dashboard.editLayout')"
            data-placement="bottom"
            @click="toggleEditMode(DashboardTab.widgets)"
          >
            <PencilIcon
              aria-hidden="true"
              class="w-5 h-5 -mx-1.5"
            />
            <span class="sr-only">
              {{ $t('dashboard.editLayout') }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <DashboardModal
      v-if="showDashboardModal"
      v-model:show="showDashboardModal"
      :dashboard="dashboardToEdit"
    />
  </div>
</template>
