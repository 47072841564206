<script setup lang="ts">
// external
import { computed } from "vue"

// internal
import { Metadata, MetadataType } from "~/types"

interface Props {
  category: Metadata["type"]
}

const props = withDefaults(defineProps<Props>(), {})

const translationKey = computed(() => {
  switch (props.category) {
    case MetadataType.account:
      return "account"
    case MetadataType.reference:
      return "reference"
    default:
      return "system"
  }
})

</script>
<template>
  <span
    class=" text-xs inline-block py-0.5 px-2 rounded-md"
    :class="category === MetadataType.account ? ' text-green-800 bg-green-100' : category === MetadataType.reference ? 'text-indigo-800 bg-indigo-100' : 'text-yellow-800 bg-yellow-100'"
  >{{ $t('accountSettings.metadata.tabs.' + translationKey) }}</span>
</template>
