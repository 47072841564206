<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref } from "vue"

import { PlusIcon } from "@heroicons/vue/24/solid"

// internal
import { SpinLoader } from "~/components"
import { useDocumentStore } from "~/stores"
import { Document, DocumentUser, Party, UiUser } from "~/types"

interface Props {
  document: Document
  parties?: Party[]
  isLoadingParties?: boolean
  accountParties?: Party[]
  combinedUsers: UiUser[]
  users?: DocumentUser[]
  isConfirmModal?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    parties: () => [],
    isLoadingParties: false,
    accountParties: () => [],
    users: () => [],
    isConfirmModal: false,
  },
)

const emit = defineEmits( [ "close" ])

const documentStore = useDocumentStore()
const { isLockedDocument, mdu } = storeToRefs(documentStore)
const { updateDocument } = documentStore

const addNewPartySubmitting = ref<boolean>()

const isLoading = ref<boolean>(false)

const setDocumentHasConfirmed = async (): Promise<string | void> => {
  if (!isLoading.value) {
    isLoading.value = true
    await updateDocument({
      uuid: props.document.uuid,
      has_parties_confirmed: true,
    }, [ "has_parties_confirmed" ], Date.now())

    isLoading.value = false

    emit("close")
  }

}

</script>

<template>
  <div
    v-cy="`manage-parties-and-signatories-modal`"
    class="px-6 pb-4"
  >
    <div>
      <div class="text-lg font-medium text-gray-900">
        {{ $t('userSettings.partyTitle') }}
      </div>
    </div>
  </div>

  <slot name="inner" />

  <div class="flex items-center justify-between px-4 py-4 space-x-4 bg-gray-100 rounded-b-lg xs:px-6">
    <template v-if="mdu?.permissions?.includes('party_create') && !isLockedDocument">
      <button
        data-placement="left"
        data-template="addPartyForm"
        :disabled="addNewPartySubmitting"
        class="flex items-center gap-2 add-party-button btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
      >
        <template v-if="!addNewPartySubmitting">
          <PlusIcon
            class="w-5 h-5 shrink-0"
          />
          <span class="truncate">{{ $t('userSettings.addParty') }}</span>
        </template>
        <template
          v-else
        >
          <SpinLoader class="w-5 h-5 text-gray-700" />
          <span>{{ $t('common.pleaseWait') }}…</span>
        </template>
      </button>
    </template>
    <button
      :data-cy-sel="`confirm-parties-modal-confirm-button`"
      type="button"
      :disabled="isLoading"
      class="inline-flex items-center gap-2 bg-green-500 btn-primary hover:bg-green-600 focus:ring-green-400"
      @click.prevent="setDocumentHasConfirmed"
    >
      <SpinLoader
        v-if="isLoading"
        class="w-5 h-5 shrink-0"
      />
      <span>{{ $t('userSettings.confirm') }}</span>
    </button>
  </div>
</template>
