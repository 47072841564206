<script setup lang="ts">
// external
import { ref, computed, watch } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"

// internal
import { convertIsoDurationToLowestUnit } from "~/utils"

interface Props {
  id?: string
  modelValue?: string
  label?: string
  disabled?: boolean
  readonly?: boolean
  clearable?: boolean
  lastSaved?: number
  style?: string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    id: null,
    modelValue: "",
    label: null,
    disabled: false,
    readonly: false,
    clearable: true,
    lastSaved: null,
    style: "gray",
  },
)

const emit = defineEmits([ "update:model-value", "submit-enter", "blur", "focus" ])

const validationError = ref<string>()
const hasError = computed<boolean>(() => !!validationError.value)

enum DurationOptionEnum {
  day = "D",
  week = "W",
  month = "M",
  year = "Y",
}

interface DurationOptionLabel {
  type: DurationOptionEnum
}

const durationOptions: DurationOptionLabel[] = [
  {
    type: DurationOptionEnum.day,
  },
  {
    type: DurationOptionEnum.week,
  },
  {
    type: DurationOptionEnum.month,
  },
  {
    type: DurationOptionEnum.year,
  },
]

const modelValueHelper = computed<string>(() => convertIsoDurationToLowestUnit(props.modelValue))
watch(modelValueHelper, (newVal) => {
  if (newVal && newVal !== "null") {
    const newDuration = newVal.substring(1, newVal.length - 1)
    const newDurationInterval = (newVal.substring(newVal.length - 1, newVal.length) as DurationOptionEnum)
    const newDurationString = `P${ newDuration ? newDuration : "" }${ newDurationInterval ? newDurationInterval : "" }`
    if (!validate(newDurationString)) return
    duration.value = newDuration
    durationInterval.value = newDurationInterval
  } else {
    duration.value = null
    durationInterval.value = null
  }
})

const localDuration = ref<string>(props.modelValue ? convertIsoDurationToLowestUnit(props.modelValue).substring(1, convertIsoDurationToLowestUnit(props.modelValue).length - 1) : null)
const duration = computed<string>({
  get: () => {
    if (localDuration.value?.startsWith("T")) return localDuration.value.substring(1, localDuration.value.length)
    if (localDuration.value) return localDuration.value
    return null
  },
  set: (val) => {
    localDuration.value = val
  },
})
const durationInput = ref<HTMLInputElement>()
const validDurationString = ref<string>( convertIsoDurationToLowestUnit(props.modelValue) || null)
const localDurationInterval = ref<DurationOptionEnum>(props.modelValue ? ( convertIsoDurationToLowestUnit(props.modelValue).substring( convertIsoDurationToLowestUnit(props.modelValue).length - 1,  convertIsoDurationToLowestUnit(props.modelValue).length) as DurationOptionEnum) : null)
const durationInterval = computed<DurationOptionEnum>({
  get: () => {
    if (localDurationInterval.value) return localDurationInterval.value
    return DurationOptionEnum.month
  },
  set: (val) => {
    localDurationInterval.value = val
  },
})

const validate = (validationString = "") => {
  let durationString = validationString
  if (!durationString) durationString = `P${ duration.value ? duration.value : "" }${ durationInterval.value ? durationInterval.value : "" }`
  const valid = isValidSingleIntervalISODuration(durationString)
  if (!valid && durationString.length > 1 && duration.value) {
    validationError.value =  "Invalid duration"
    return false
  }
  validationError.value = null
  validDurationString.value = durationString === "P" || !duration.value ? null : durationString
  return true
}

const isValidSingleIntervalISODuration = (duration: string): boolean => {
  if (duration === "P") return true
  const singleIntervalIsoDurationRegex = /^P(?:[1-9]\d*Y|[1-9]\d*M|[1-9]\d*W|[1-9]\d*D)$/
  return singleIntervalIsoDurationRegex.test(duration)
}

const handleSubmitEnter = (e: KeyboardEvent) => {
  if (e.key === "Enter") {
    if (!validate()) return
    emit("submit-enter")
  }
}

watch(duration, () => {
  if (!validate()) return
  emit("update:model-value", validDurationString.value)
})

watch(durationInterval, () => {
  if (!validate()) return
  emit("update:model-value", validDurationString.value)
})

const handleFocus = () => {
  emit("focus", {
    target: { id: props.id },
  })
}

const handleBlur = () => {
  emit("blur", {
    target: { id: props.id },
  })
}

const reset = () => {
  durationInterval.value = null
  duration.value = null
}

defineExpose({ reset })

</script>

<template>
  <div class="relative font-normal">
    <label
      v-if="props.label"
      class="text-xs"
    >{{ props.label }}</label>
    <div
      :id="props.id"
      class="duration-input flex items-center text-base text-left rounded-md sm:text-sm"
      :class="
        [
          {
            'error' : hasError,
            'text-slate-400 cursor-not-allowed' : props.disabled && props.style === 'slate',
            'focus-within:bg-gray-100 focus-within:ring-0' : !props.disabled && props.style !== 'gray',
            'text-gray-400 bg-gray-50 cursor-not-allowed' : props.disabled && props.style === 'gray',
            'bg-gray-100' : !props.disabled && props.style === 'gray',
            'bg-white border border-gray-300' : props.style === 'white'
          },
          props.style === 'gray' ?
            'focus-within:ring-2 focus-within:ring-indigo-500 p-0'
            :
            props.style === 'slate' ?
              'bg-slate-900 focus-within:bg-slate-950 p-0' :
              'bg-transparent p-0 hover:bg-gray-100 disabled:hover:bg-transparent',
          props.readonly ? 'cursor-auto hover:bg-transparent justify-start' : 'justify-end',
        ]
      "
    >
      <input
        v-if="!props.readonly"
        ref="durationInput"
        v-model="duration"
        size="3"
        maxlength="3"
        :class="[
          hasError ? 'bg-red-100 text-red-500' : '',
          props.style === 'plain' ? 'p-1' : '',
          $props.style === 'slate' ? 'placeholder-slate-400' : 'placeholder-gray-400',
        ]"
        class="text-base text-right bg-transparent border-none outline-none grow sm:text-sm focus:ring-0 ring-0"
        type="text"
        :placeholder="$t('common.durationPlaceholder')"
        :disabled="disabled"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup.enter="handleSubmitEnter"
      >
      <span
        v-else
        class="pr-1"
      >
        {{ duration }}
      </span>
      <Listbox
        v-model="durationInterval"
        as="div"
        :disabled="disabled"
        class="h-full"
      >
        <div>
          <ListboxButton
            class="bg-transparent rounded-l-none focus:z-10"
            :class="[
              props.style === 'slate' ? 'btn-listbox-slate' : 'btn-listbox-plain',
              props.style === 'plain' ? 'py-1 disabled:bg-transparent' : '',
              readonly ? 'pr-0 pl-0 disabled:cursor-text' : 'pr-9',
            ]"
          >
            <span
              v-if="durationInterval"
              class="block truncate"
              :class="[hasError ? 'text-red-500' : '', disabled && modelValue ? 'text-gray-900' : '']"
            >
              {{ $t('duration.' + durationInterval, parseInt(duration) || 0) }}
            </span>
            <span
              v-else
              class="block truncate"
              :class="[hasError ? 'text-red-500' : 'text-gray-400']"
            >
              {{ $t('common.unit') }}
            </span>
            <template v-if="!disabled">
              <span
                v-if="!clearable || !localDurationInterval"
                class="absolute inset-y-0 right-0 flex items-center pr-2.5 pointer-events-none"
              >
                <ChevronUpDownIcon
                  :class="[style === 'gray' ? 'w-5 h-5' : 'w-4 h-4', hasError ? 'text-red-500' : 'text-gray-400']"
                  aria-hidden="true"
                />
              </span>
              <span
                v-else-if="clearable && !readonly"
                class="absolute inset-y-0 right-0 flex items-center pr-2.5 cursor-pointer"
                @click.prevent="reset"
              >
                <XMarkIcon
                  class="w-4 h-4 text-gray-400 hover:text-gray-600"
                  aria-hidden="true"
                />
              </span>
            </template>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="listbox-options"
              @blur="handleBlur"
              @focus="handleFocus"
            >
              <ListboxOption
                v-for="option in durationOptions"
                :key="option.type"
                v-slot="{ active, selected }"
                as="template"
                :value="option.type"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                  >
                    {{ $t('duration.' + option.type, 2) }}
                  </span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
  </div>
</template>
