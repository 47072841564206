<script setup lang="ts">
// external
import { computed, ref } from "vue"

import { storeToRefs } from "pinia"

import { TrashIcon } from "@heroicons/vue/24/outline"

// internal
import { SignatureSignatoryDisplay, SpinLoader } from "~/components"
import { useDocumentStore, usePartyStore, usePusherStore, useSharedStore, useSignatureStore, useTemplateStore, useUserStore } from "~/stores"
import { CrudContext, Document, DocumentUser, DocumentUserRoleEnum, Party, SignatureBlock, SignatureBlockStyle, Template } from "~/types"
import { sortBy } from "lodash-es"
import { DragIndicatorIcon, DefaultSignatureBlockIcon, MinimalSignatureBlockIcon } from "~/icons"

interface Props {
  refUuid: SignatureBlock["ref_uuid"];
  isEditable: boolean;
}
const props = defineProps<Props>()

interface Emits {
  (e: "delete"): void
}
const emit = defineEmits<Emits>()

const { parties } = storeToRefs(usePartyStore())


const signatureStore = useSignatureStore()
const { checkSignature, updateSignatureBlock } = signatureStore
const { signatureBlocks, uuidsOfIsUpdatingSignatureBlock } = storeToRefs(signatureStore)

const { users } = storeToRefs(useUserStore())

const signatureBlock = computed(() => signatureBlocks.value?.find((el: SignatureBlock) => el.ref_uuid === props.refUuid && !el.deleted_at))

const partyDetails = computed<Party>(() => parties?.value.find((el) => el.uuid === signatureBlock.value?.party_uuid))

const signatories = computed<DocumentUser[]>(
  () => {
    const signatories = users.value
      ?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory) && el.party_uuid === partyDetails.value?.uuid)
      .filter(Boolean)

    return sortBy(signatories, "signing_order")
  },
)

const documentStore = useDocumentStore()
const { isLockedDocument, mdu } = storeToRefs(documentStore)

const templateStore = useTemplateStore()

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const isEnabled = computed(
  () => {
    return (
      !checkIfDisabledByPusher("editPartyButton_" + partyDetails.value?.ref_uuid)
      && (mdu.value?.permissions?.includes("party_update") || crudContext.value === CrudContext.template)
      && !isLockedDocument.value
    )
  },
)

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return crudContext.value === CrudContext.document
    ? documentStore.currentDocument?.uuid
    : templateStore.currentTemplate?.uuid
})

const toggleSignatureBlockStyle = async () => {
  const currentStyle = signatureBlock.value?.style
  const newStyle = currentStyle === SignatureBlockStyle.minimal ? SignatureBlockStyle.regular : SignatureBlockStyle.minimal
  await updateSignatureBlock(crudContext.value, entityUuid.value, signatureBlock.value, newStyle)
}

const hoverActive = ref<boolean>(false)
const hoverTimeout = ref<NodeJS.Timeout>(null)

const setHoverActive = () => {
  hoverActive.value = true
  if (hoverTimeout.value) clearTimeout(hoverTimeout.value)
}

const delayHoverInactive = () => {
  hoverTimeout.value = setTimeout(() => hoverActive.value = false, 300)
}

</script>

<template>
  <article
    :id="'signatureBlockNode_' + signatureBlock?.ref_uuid"
    :data-party-button="partyDetails?.ref_uuid"
    :data-ref-uuid="signatureBlock?.ref_uuid"
    data-placement="right"
    :data-template="'partyForm_' + partyDetails?.ref_uuid"
    :class="[
      signatureBlock?.style === SignatureBlockStyle.minimal ? 'signature-block-minimal' : 'signature-block-regular',
      isEnabled
        ? 'edit-party-button cursor-pointer'
        : 'pointer-events-none'
    ]"
    class="relative signature-block hover:z-20 group/signature-block hover:bg-white"
    @mouseenter="setHoverActive"
    @mouseleave="delayHoverInactive"
  >
    <div
      v-if="isEditable && !isLockedDocument"
      class="absolute p-1 text-indigo-600 transition-all duration-200 scale-0 bg-indigo-100 rounded-full shadow-xl opacity-0 cursor-move hover:bg-indigo-200 hover:text-indigo-700 group-hover/signature-block:scale-100 group-hover/signature-block:opacity-100 -left-7"
      data-drag-handle
      draggable="true"
    >
      <DragIndicatorIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
    </div>

    <span
      v-if="isEditable && !isLockedDocument"
      :class="hoverActive ? 'scale-100 opacity-100' : ''"
      class="absolute p-1 text-red-600 transition-all duration-200 scale-0 bg-red-100 rounded-full shadow-xl opacity-0 cursor-pointer hover:bg-red-200 -right-7 group-hover/signature-block:scale-100 group-hover/signature-block:opacity-100 hover:text-red-700 top-7"
    >
      <TrashIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
        @click.stop.prevent="emit('delete')"
      />
    </span>

    <span
      v-if="isEditable && !isLockedDocument"
      :class="hoverActive ? 'scale-100 opacity-100' : ''"
      class="absolute p-1 text-indigo-600 transition-all duration-200 scale-0 bg-indigo-100 rounded-full shadow-xl opacity-0 cursor-pointer hover:bg-indigo-200 hover:text-indigo-700 group-hover/signature-block:scale-100 group-hover/signature-block:opacity-100 -right-7"
    >
      <SpinLoader
        v-if="uuidsOfIsUpdatingSignatureBlock.includes(signatureBlock?.uuid)"
        class="w-4 h-4 shrink-0"
      />
      <MinimalSignatureBlockIcon
        v-else-if="signatureBlock?.style === SignatureBlockStyle.regular"
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
        @click.stop.prevent="toggleSignatureBlockStyle"
      />
      <DefaultSignatureBlockIcon
        v-else
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
        @click.stop.prevent="toggleSignatureBlockStyle"
      />
    </span>
    <template v-if="signatories?.length">
      <SignatureSignatoryDisplay
        v-for="signatory in signatories"
        :key="signatory.uuid"
        :signatory="signatory"
        :signature="checkSignature(signatory.uuid, signatureBlock?.uuid)"
        :is-editor-node="true"
        :party-uuid="signatory.party_uuid"
        :signature-block="signatureBlock"
      />
    </template>

    <SignatureSignatoryDisplay
      v-else-if="signatureBlock?.party_uuid"
      :party-uuid="signatureBlock.party_uuid"
      :is-editor-node="true"
      :signature-block="signatureBlock"
    />
  </article>
</template>
