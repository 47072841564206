<script setup lang="ts">
// external
import { useClipboard } from "@vueuse/core"
import { ClipboardDocumentListIcon, ClipboardDocumentCheckIcon } from "@heroicons/vue/24/outline"

interface Props {
  input: string,
}

withDefaults(defineProps<Props>(), {})
const { copy, copied, isSupported } = useClipboard()
</script>

<template>
  <button
    v-if="isSupported"
    type="button"
    @click="copy(input)"
  >
    <ClipboardDocumentListIcon
      v-if="!copied"
      class="w-4 h-4"
      aria-hidden="true"
    />
    <div
      v-else
      class="relative"
    >
      <ClipboardDocumentCheckIcon
        class="w-4 h-4"
        aria-hidden="true"
      />
      <div
        class="absolute bg-green-500 text-white text-xs h-4 px-1 top-0 right-full mr-1 rounded"
      >
        {{ $t('common.copied') }}
      </div>
    </div>
  </button>
</template>
