<script setup lang="ts">
// external
import { computed } from "vue"
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"

// internal
import { OptionList } from "~/components"
import { SelectOption } from "~/types"

interface Props {
  options: SelectOption[]
  modelValue: SelectOption["value"][]|null
  disabled?: boolean
  error?: boolean
  placeholder?: string
  label?: string
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  error: false,
  placeholder: null,
  label: null,
  required: false,
})

const emit = defineEmits([ "update:model-value" ])


const selectedOption = computed({
  get: () => null,
  set: (option) => {
    const payload = props.modelValue ? [
      ...props.modelValue,
      option.value,
    ] : [ option.value ]
    emit("update:model-value", payload)
  },
})

const selectedOptions = computed(() => props.modelValue?.length ? props.options.filter((option) => props.modelValue.includes(option.value)) : [])
const availableOptions = computed(() => props.modelValue?.length ? props.options.filter((option) => !props.modelValue.includes(option.value)) : props.options)

const hasError = computed(() => props.error && !selectedOptions.value.length)

const removeOption = (option) => {
  emit("update:model-value", props.modelValue.filter((value) => value !== option.value))
}

</script>

<template>
  <div class="text-sm">
    <div
      v-if="label"
      class="mb-1 text-sm font-medium"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-indigo-500"
      >*</span>
    </div>
    <Listbox
      v-model="selectedOption"
      as="div"
      :disabled="disabled || !availableOptions.length"
    >
      <div class="relative mt-1">
        <ListboxButton
          class="btn-listbox-plain"
          :class="{'error': hasError}"
        >
          <div class="flex items-center">
            <span
              class="block truncate"
              :class="[hasError ? 'text-red-400' : 'text-gray-500']"
            >{{ placeholder || $t('accountSettings.metadata.selectOptions') }}…</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              class="w-5 h-5"
              :class="[hasError ? 'text-red-400' : 'text-gray-400']"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="listbox-options">
            <ListboxOption
              v-for="option in availableOptions"
              :key="option.value"
              v-slot="{ active, selected }"
              as="template"
              :value="option"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option py-1']">
                <div class="flex items-center">
                  <component
                    :is="option.icon"
                    v-if="option.icon"
                    class="w-5 h-5 mr-2"
                    aria-hidden="true"
                  />
                  <span
                    v-else-if="option.color"
                    class="block w-3 h-3 mr-2 rounded-full"
                    :style="{'background-color': option.color}"
                  />
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                  >
                    {{ option.label }}
                  </span>
                </div>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <OptionList
      class="mt-2"
      :options="selectedOptions"
      @remove="removeOption"
    />
  </div>
</template>
