<script setup lang="ts">
interface Props {
  errors?: string[],
  align?: string,
  layout?: string
}
const props = withDefaults(defineProps<Props>(), {
  align: "left",
  layout: "default",
})
</script>

<template>
  <ul
    v-if="props.errors && props.errors?.length > 0"
    :class="[
      layout === 'slate' ? 'text-red-400 mt-1.5 pl-px' : 'text-red-700 mt-1',
      align === 'right' ? 'text-right' : '',
      'text-xs max-w-fit'
    ]"
  >
    <li
      v-for="(error, errorIdx) in errors"
      :key="errorIdx"
    >
      {{ error === 'Required' ? $t('common.required') : error }}
    </li>
  </ul>
</template>
