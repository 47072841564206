<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { onMounted, ref } from "vue"

import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"

// internal
import {
  ConditionInput,
  EmptyState,
  OverlayScrollbar,
  SkeletonLoader,
} from "~/components"
import { ConditionIcon } from "~/icons"
import { useConditionStore, useSharedStore } from "~/stores"

const sharedStore = useSharedStore()
const { setSidebarScrollContainer } = sharedStore

const conditionStore = useConditionStore()
const { conditions, isLoadingConditions } = storeToRefs(conditionStore)

const conditionScrollContainer = ref()

onMounted(() => {
  setSidebarScrollContainer(conditionScrollContainer.value)
})

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="shrink-0 px-6 pt-6"
      :class="!conditions?.length ? 'border-b border-b-gray-200' : ''"
    >
      <h3
        class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('conditions.title') }}

        <span
          data-tippy-help
          :data-tippy-content="$t('conditions.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>

    <div
      v-if="isLoadingConditions"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>

    <template v-else>
      <OverlayScrollbar
        ref="conditionScrollContainer"
        tag="div"
        class="flex-1 max-h-full overflow-y-auto"
      >
        <div
          v-if="conditions?.length"
          class="border-gray-200 divide-y divide-gray-200 border-y"
        >
          <ConditionInput
            v-for="(condition) in conditions"
            :key="condition.uuid"
            :condition="condition"
          />
        </div>

        <EmptyState
          v-else
          :hide-button="true"
          class="my-6"
        >
          <template #icon>
            <ConditionIcon
              aria-hidden="true"
            />
          </template>
          {{ $t('dynamicFields.noConditions') }}
        </EmptyState>
      </OverlayScrollbar>
    </template>
  </div>
</template>
