<script setup lang="ts">
// external
import { Form, Field } from "vee-validate"
import { router } from "@inertiajs/vue3"
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { computed, watch } from "vue"
import axios from "axios"

// internal
import { InfoBox, SpinLoader, WarningBox } from "~/components"
import { LaravelUser, AccountUser, UserEmailChangeVerification } from "~/types"
import { useNotificationStore } from "~/stores"

interface Props {
  user: LaravelUser
  mau: AccountUser
  hasAtLeastOneAccountUserWithSso?: boolean
  userEmailChangeVerification?: UserEmailChangeVerification
}

const props = withDefaults(defineProps<Props>(), {
  hasAtLeastOneAccountUserWithSso: false,
})

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const { t } = useI18n()

const isSubmitting = ref(false)

const localEmail = ref(props.user.email)

watch(() => props.user, (newVal) => {
  if (newVal.email !== localEmail.value) {
    localEmail.value = newVal.email
  }
})

const requirePasswordForEmailChange = computed(() => {
  return localEmail.value !== props.user.email
})

const onSubmit = (values) => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    const payload = {}

    if (!!values?.email && values?.email !== props.user.email && !!values?.password) {
      payload["email"] = values.email
      payload["password"] = values.password
    }

    router.post(route("update-email"), payload, {
      onSuccess: () => {
        addToasterNotification({
          title: t("profileSettings.general.updateSuccessTitle"),
          message: t("profileSettings.general.updateSuccessDescription"),
          type: "success",
        })
      },
      onError: () => {
        addToasterNotification({
          title: t("common.errorOccured"),
          message: t("profileSettings.general.updateError"),
          type: "error",
        })
      },
      onFinish: () => {
        isSubmitting.value = false
      },
    })
  }
}

const isResending = ref(false)
const resendConfirmationEmail = async () => {
  if (!!props.userEmailChangeVerification && !isResending.value) {
    try {
      isResending.value = true
      await axios.post(route("api.update-email-resend", props.userEmailChangeVerification.uuid))
      isResending.value = false
      addToasterNotification({
        title: t("profileSettings.general.resendSuccessTitle"),
        message: t("profileSettings.general.resendSuccessDescription"),
        type: "success",
      })
    } catch (e) {
      addToasterNotification({
        title: t("common.errorOccured"),
        message: t("profileSettings.general.updateError"),
        type: "error",
      })
      console.error(e)
    }
  }
}
</script>
<template>
  <Form
    v-slot="{ errors }"
    class="content-card"
    @submit="onSubmit"
  >
    <div class="p-5 space-y-5">
      <InfoBox
        v-if="userEmailChangeVerification"
        class="text-sm max-w-xl"
      >
        {{ $t('profileSettings.general.verifyEmailText', { email: userEmailChangeVerification.pending_email }) }}
      </InfoBox>
      <div v-else>
        <label
          for="userEmail"
          class="block mb-1 text-sm"
        >{{ $t('userForm.email') }}:</label>
        <Field
          id="userEmail"
          v-model="localEmail"
          name="email"
          type="email"
          class="max-w-xl input-plain"
          :class="[errors.email ? 'error' : '']"
          rules="required|email"
          :placeholder="$t('userForm.emailPlaceholder') + '…'"
          :value="props.user.email"
        />
        <WarningBox
          v-if="hasAtLeastOneAccountUserWithSso"
          class="max-w-xl mt-3"
        >
          <div>{{ $t('userForm.emailSsoWarning') }}</div>
        </WarningBox>
      </div>
      <div
        v-if="requirePasswordForEmailChange"
      >
        <div
          class="inline-block p-3 border border-yellow-400 rounded-md"
          :class="errors.password ? 'border-red-400 bg-red-50' : 'border-yellow-400 bg-yellow-50'"
        >
          <label
            for="userPassword"
            class="block mb-1 text-sm text-gray-900 font-md"
          >{{ $t('userForm.passwordSecurityHint') }}</label>
          <Field
            id="userPassword"
            name="password"
            type="password"
            class="max-w-xl input-secondary"
            :class="[errors.password ? 'input-has-errors' : '']"
            rules="required"
            :placeholder="$t('userForm.passwordPlaceholder') + '…'"
          />
        </div>
      </div>
    </div>
    <div class="content-card-footer">
      <button
        v-if="!!userEmailChangeVerification"
        class="flex items-center space-x-2 btn-primary"
        type="button"
        :disabled="isResending"
        @click="resendConfirmationEmail"
      >
        <SpinLoader
          v-if="isResending"
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <span>{{ $t('documentSharingModal.invitationResend') }}</span>
      </button>
      <button
        v-else
        class="flex items-center space-x-2 btn-primary"
        type="submit"
        :disabled="isSubmitting"
      >
        <SpinLoader
          v-if="isSubmitting"
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <span>{{ $t('common.save') }}</span>
      </button>
    </div>
  </Form>
</template>
