<script setup lang="ts">
import { ref, onBeforeUnmount } from "vue"
import { Cropper, CircleStencil } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import { TrashIcon, ArrowUpTrayIcon } from "@heroicons/vue/24/solid"

import { DialogModal } from "~/components"
import { AccountUser } from "~/types"
import { getMimeType } from "~/utils"

interface Props {
  show: boolean
  accountUser: AccountUser
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const image = ref(props.accountUser.profile_photo_url ?? null)
const imageType = ref(null)
const cropper = ref(null)
const file = ref(null)

const crop = () => {
  if (image.value) {
    const { canvas } = cropper.value.getResult()
    canvas.toBlob((blob) => {
    // Do something with blob: upload to a server, download and etc.
      emit("save", blob)
    }, imageType.value)
  }
}

const loadImage = (event) => {
  // Reference to the DOM input element
  const { files } = event.target
  // Ensure that you have a file before attempting to read it
  if (files && files[0]) {
    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (image.value) {
      URL.revokeObjectURL(image.value)
    }
    // 2. Create the blob link to the file to optimize performance:
    const blob = URL.createObjectURL(files[0])

    // 3. The steps below are designated to determine a file mime type to use it during the
    // getting of a cropped image from the canvas. You can replace it them by the following string,
    // but the type will be derived from the extension and it can lead to an incorrect result:
    //
    // this.image = {
    //    src: blob;
    //    type: files[0].type
    // }

    // Create a new FileReader to read this image binary data
    const reader = new FileReader()
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (e) => {
      // Note: arrow function used here, so that "this.image" refers to the image of Vue component
      image.value = blob
      imageType.value = getMimeType(e.target.result, files[0].type)
    }
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsArrayBuffer(files[0])
  }
}

onBeforeUnmount(() => {
  // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
  if (image.value) {
    URL.revokeObjectURL(image.value)
  }
})

const emit = defineEmits([ "update:show", "save", "remove" ])
</script>
<template>
  <DialogModal
    :show="props.show"
    max-width="md"
    @close="$emit('update:show', false)"
  >
    <template #title>
      {{ $t('profileSettings.accounts.updateProfilePicture') }}
    </template>

    <template #content>
      <div class="-mx-6 -mb-4 max-h-[80vh] max-w-[100vw]">
        <Cropper
          v-if="image"
          ref="cropper"
          class="cropper"
          :src="image"
          :stencil-component="CircleStencil"
        />
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-center space-x-5">
        <button
          class="flex items-center gap-2 text-gray-400 btn-plain hover:text-red-500 hover:bg-red-100"
          @click="emit('remove')"
        >
          <TrashIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          <span class="hidden sm:block">
            {{ $t('common.remove') }}
          </span>
        </button>
        <button
          class="flex items-center gap-2 text-gray-400 btn-plain hover:text-white hover:bg-indigo-500"
          @click="file.click()"
        >
          <ArrowUpTrayIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          <span class="hidden sm:block whitespace-nowrap">
            {{ $t('profileSettings.accounts.uploadImage') }}
          </span>
        </button>
        <button
          class="btn-primary"
          @click="crop"
        >
          {{ $t('common.save') }}
        </button>
        <input
          ref="file"
          type="file"
          accept="image/*"
          class="absolute top-0 opacity-0 -left-96"
          @change="loadImage($event)"
        >
      </div>
    </template>
  </DialogModal>
</template>
