<script setup lang="ts">
// external
import { computed } from "vue"
import { MenuItem } from "@headlessui/vue"
import { Link } from "@inertiajs/vue3"

// internal
import { MagicTableEntry, TeamIconDisplay, MagicTableTimestamps } from "~/components"
import { Import } from "~/types"
import { getTeamByUuid } from "~/utils"
import { useImportActions } from "~/Pages/Imports/actions"

interface Props {
  import: Import
  columns: any[]
  loading?: boolean
  isArchive?: boolean
  sortAttribute?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    isArchive: false,
    sortAttribute: null,
  },
)

const name = computed(() => props.import.name ?? "")

const team = computed(() => {
  return getTeamByUuid(props.import.team_uuid)
})

const {
  confirmImportDeletion,
  confirmImportRestoration,
} = useImportActions()
</script>

<template>
  <MagicTableEntry
    :columns="columns"
    :loading="loading"
  >
    <template #name>
      <div class="flex items-center md:space-x-4">
        <Link
          v-if="!isArchive"
          v-cy="`import-table-import-${name.toLowerCase()}`"
          :href="route('imports.show', props.import.uuid)"
          class="font-semibold truncate hover:text-indigo-700"
        >
          {{
            name ? name : $t('common.undefined')
          }}
        </Link>
        <span
          v-else
          class="font-semibold truncate"
        >{{ name ? name : $t('common.undefined') }}</span>
      </div>
    </template>
    <template #status>
      <div
        class="flex items-center flex-shrink-0 text-sm"
      >
        <span>
          {{ $t(`imports.status.${ props.import.status || 'created' }` ) }}
        </span>
      </div>
    </template>
    <template #team>
      <div
        class="flex items-center text-sm capitalize shrink-0"
      >
        <TeamIconDisplay
          :team="team"
          class="w-4 h-4 mr-2"
        />
        <p class="block text-gray-500 truncate">
          {{
            team?.name
              ? team?.name
              : $t('unassigned')
          }}
        </p>
      </div>
    </template>
    <template #creator>
      <div class="flex items-center space-x-2 truncate">
        <div class="shrink-0">
          <img
            class="w-6 h-6 rounded-full max-w-none ring-2 ring-white"
            :src="props.import.created_by_account_user?.profile_photo_url"
          >
        </div>
        <span
          v-if="
            props.import.created_by_account_user?.first_name &&
              props.import.created_by_account_user?.last_name
          "
          class="leading-5 shrink-0"
        >
          {{ props.import.created_by_account_user.first_name }}
          {{ props.import.created_by_account_user.last_name }}
        </span>
        <span
          v-else
          class="leading-5 shrink-0"
        >
          {{ props.import.created_by_account_user.email }}
        </span>
      </div>
    </template>
    <template #timestamps>
      <MagicTableTimestamps
        :entry="props.import"
        :sort-attribute="sortAttribute"
      />
    </template>
    <template #options>
      <div v-if="isArchive">
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-md',
            ]"
            @click="confirmImportRestoration(props.import.uuid)"
          >
            {{ $t('common.restore') }}
          </button>
        </MenuItem>
      </div>
      <div v-if="!isArchive">
        <MenuItem v-slot="{ active }">
          <Link
            :href="route('imports.show', props.import.uuid)"
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-t-md',
            ]"
          >
            {{ $t('common.show') }}<span class="sr-only">, {{ props.import.name }}</span>
          </Link>
        </MenuItem>
      </div>
      <div v-if="!isArchive">
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-red-950 text-red-400' : 'text-red-500',
              'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
            ]"
            @click="confirmImportDeletion(props.import.uuid)"
          >
            {{ $t('common.archive') }}
          </button>
        </MenuItem>
      </div>
    </template>
  </MagicTableEntry>
</template>
