<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, inject, Ref } from "vue"
import { useI18n } from "vue-i18n"

import { NodeViewProps, NodeViewWrapper, Range } from "@tiptap/vue-3"

// internal
import { SectionRefIcon } from "~/icons"
import { useConditionStore, useSectionRefStore } from "~/stores"

import { SectionRefAttrs } from "./sectionRef"

const { t } = useI18n()

const props = defineProps<NodeViewProps>()

const sectionRefStore = useSectionRefStore()
const { sections } = storeToRefs(sectionRefStore)

const conditionStore = useConditionStore()
const { conditions } = storeToRefs(conditionStore)

const nodeAttrs = computed(() => props.node.attrs as SectionRefAttrs)

const activeSection = computed(
  () => sections.value?.find((item) => item?.uuid === nodeAttrs.value.uuid),
)

const sectionRefOrderString = computed<string>(
  () => {
    if (!activeSection.value) return t("common.n/a(notFound)")

    const isSectionInactive = conditions.value
      ?.some(({ is_active, uuids }) => !is_active && uuids?.includes(nodeAttrs.value.uuid))

    return isSectionInactive
      ? t("common.n/a(inactive)")
      : activeSection.value.title
  },
)

const invalidValues = [ t("common.n/a(notFound)"), t("common.n/a(inactive)") ] as (string)[]

const textSelectionRange = inject<Ref<Range>>("textSelectionRange", null)

const isSelected = computed(
  () => {
    if (!textSelectionRange.value) return false

    const { from, to } = textSelectionRange.value

    const nodeFrom = props.getPos()
    const nodeTo = nodeFrom + props.node.nodeSize

    return from <= nodeFrom && to >= nodeTo
  },
)
</script>

<template>
  <NodeViewWrapper
    class="inline-flex -mt-1"
    :class="
      [
        props.selected || isSelected
          ? 'ring ring-slate-200'
          : '',
      ]
    "
  >
    <div
      v-cy="`section-ref-${sectionRefOrderString}`"
      data-tippy-context
      data-placement="top"
      :data-tippy-content="activeSection?.preview || sectionRefOrderString"
      :class="
        invalidValues.includes(sectionRefOrderString)
          ? 'bg-red-50 border-red-300'
          : 'bg-gray-50 border-gray-300'
      "
      class="relative flex items-center pl-5 pr-1 leading-tight border rounded-md"
    >
      <div
        contenteditable="false"
        draggable="true"
        :class="
          invalidValues.includes(sectionRefOrderString)
            ? 'text-red-700'
            : 'text-gray-700'
        "
        class="absolute left-0.5 cursor-move"
        data-drag-handle
      >
        <SectionRefIcon class="w-3.5 h-3.5" />
      </div>

      <a :href="`#${nodeAttrs.uuid}`">
        {{ sectionRefOrderString }}
      </a>
    </div>
  </NodeViewWrapper>
</template>
