<script setup lang="ts">
// external
import { computed, ref, watch } from "vue"
import { PlusIcon, MinusIcon } from "@heroicons/vue/20/solid"
import { StarIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import { FilterValues, Team, Stage, Metadata, MultiFieldType, Import, Automation, Tag, Party, SelectOption, MetadataType, FilterBubble } from "~/types"
import {
  TeamFilter,
  StageFilter,
  ImportFilter,
  TagFilter,
  AutomationFilter,
  OriginFilter,
  FilterCollapsible,
} from "~/components"
import MetadataFilter from "./MetadataFilter.vue"
import AccountPartyFilter from "./AccountPartyFilter.vue"
import AutocompleteFilter from "./AutocompleteFilter.vue"
import { sortByMetadataName } from "~/utils"

interface Props {
  filterValues: FilterValues
  teams: Team[]
  stages: Stage[]
  accountParties: Party[]
  imports: Import[]
  automations: Automation[]
  tags: Tag[]
  metadata?: Metadata[]
  filterOptions: Record<string, SelectOption[]>
  allOpen?: boolean
  filterBubbles?: FilterBubble[]
  pinnedFilters?: string[]
}
const props = withDefaults(
  defineProps<Props>(),
  {
    allOpen: false,
    filterBubbles: null,
    pinnedFilters: () => [],
  },
)

const localFilterValues = computed({
  get: () => {
    return props.filterValues
  },
  set: (values) => {
    emit("update:filter-values", values)
  },
})

const { t } = useI18n()

const metadataFilter = computed(() => {
  const filteredMetadata = props.metadata?.filter((metadataEntry) => ![ MultiFieldType.textarea, MultiFieldType.list, MultiFieldType.duration ].includes(metadataEntry.value_type))
  return sortByMetadataName(filteredMetadata, t)
})

const emit = defineEmits([ "reset", "update:filter-values", "toggle-pin" ])

const showMetadataFilter = ref(props.allOpen)

watch(() => props.allOpen, (newVal) => {
  showMetadataFilter.value = newVal
})

const getFilterCount = (filterKey) => {
  if (!props.filterBubbles?.length) return 0

  const bubbles = props.filterBubbles.filter((bubble) => bubble.key === filterKey)
  return bubbles.length
}

</script>

<template>
  <div
    class="mb-5 -m-3 rounded bg-indigo-50 shadow"
    :class="{'hidden': !pinnedFilters.length}"
  >
    <div class="px-3 py-2 text-sm font-medium flex items-center space-x-1 border-b border-indigo-100">
      <StarIcon
        aria-hidden="true"
        class="w-4 h-4 text-indigo-300"
      />
      <span>{{ $t('filter.pinned') }}</span>
    </div>
    <div
      id="pinnedFilters"
      class="relative space-y-3 p-3"
    />
  </div>
  <FilterCollapsible
    :label="$t('filter.archive')"
    :all-open="allOpen"
    :pinned="pinnedFilters.includes('archived')"
    :count="getFilterCount('archived')"
    @pin="$emit('toggle-pin', 'archived')"
  >
    <label class="flex items-center space-x-2">
      <input
        v-model="localFilterValues.archived"
        class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        type="checkbox"
        :checked="localFilterValues.archived"
      >
      <span class="text-sm text-gray-600">{{ $t('filter.showOnlyArchivedDocuments') }}</span>
    </label>
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('filter.expired')"
    :all-open="allOpen"
    :pinned="pinnedFilters.includes('expired')"
    :count="getFilterCount('expired')"
    @pin="$emit('toggle-pin', 'expired')"
  >
    <label class="flex items-center space-x-2">
      <input
        v-model="localFilterValues.expired"
        class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        type="checkbox"
        :checked="localFilterValues.expired"
      >
      <span class="text-sm text-gray-600">{{ $t('filter.showOnlyExpiredDocuments') }}</span>
    </label>
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('filter.labels.origins')"
    :all-open="allOpen"
    :count="getFilterCount('origins')"
    :pinned="pinnedFilters.includes('origins')"
    @pin="$emit('toggle-pin', 'origins')"
  >
    <OriginFilter
      v-model:selected-uuids="localFilterValues.origins"
      :options="filterOptions.origins"
    />
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('magicTable.columns.teams')"
    :all-open="allOpen"
    :count="getFilterCount('teams')"
    :pinned="pinnedFilters.includes('teams')"
    @pin="$emit('toggle-pin', 'teams')"
  >
    <TeamFilter
      v-model:selected-uuids="localFilterValues.teams"
      :teams="teams"
    />
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('magicTable.columns.stage')"
    :all-open="allOpen"
    :count="getFilterCount('stages')"
    :pinned="pinnedFilters.includes('stages')"
    @pin="$emit('toggle-pin', 'stages')"
  >
    <StageFilter
      v-model:selected-stages="localFilterValues.stages"
      :stages="stages"
    />
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('magicTable.columns.party')"
    :all-open="allOpen"
    :count="getFilterCount('parties')"
    :pinned="pinnedFilters.includes('parties')"
    @pin="$emit('toggle-pin', 'parties')"
  >
    <AccountPartyFilter
      v-model:selected-uuids="localFilterValues.parties"
      :account-parties="accountParties"
    />
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('filter.labels.external_parties')"
    :all-open="allOpen"
    :count="getFilterCount('external_parties')"
    :pinned="pinnedFilters.includes('external_parties')"
    @pin="$emit('toggle-pin', 'external_parties')"
  >
    <AutocompleteFilter
      v-model:selected-uuids="localFilterValues.external_parties"
      :endpoint="route('api.party-suggestions')"
      :placeholder="$t('filter.searchForParty')"
      :use-base64="true"
    />
  </FilterCollapsible>
  <FilterCollapsible
    :label="$t('filter.labels.document_users')"
    :all-open="allOpen"
    :count="getFilterCount('document_users')"
    :pinned="pinnedFilters.includes('document_users')"
    @pin="$emit('toggle-pin', 'document_users')"
  >
    <AutocompleteFilter
      v-model:selected-uuids="localFilterValues.document_users"
      :endpoint="route('api.document-user-suggestions')"
      :placeholder="$t('filter.searchForUser')"
    />
  </FilterCollapsible>
  <FilterCollapsible
    v-if="imports?.length"
    :label="$t('magicTable.columns.import')"
    :all-open="allOpen"
    :count="getFilterCount('imports')"
    :pinned="pinnedFilters.includes('imports')"
    @pin="$emit('toggle-pin', 'imports')"
  >
    <ImportFilter
      v-model:selected-uuids="localFilterValues.imports"
      :imports="imports"
    />
  </FilterCollapsible>
  <FilterCollapsible
    v-if="automations?.length"
    :label="$t('magicTable.columns.automation')"
    :all-open="allOpen"
    :count="getFilterCount('automations')"
    :pinned="pinnedFilters.includes('automations')"
    @pin="$emit('toggle-pin', 'automations')"
  >
    <AutomationFilter
      v-model:selected-uuids="localFilterValues.automations"
      :automations="automations"
    />
  </FilterCollapsible>
  <FilterCollapsible
    v-if="tags?.length"
    :label="$t('settings.sections.tags')"
    :all-open="allOpen"
    :count="getFilterCount('tags') + getFilterCount('tag_and')"
    :pinned="pinnedFilters.includes('tags')"
    @pin="$emit('toggle-pin', 'tags')"
  >
    <TagFilter
      v-model:selected-uuids="localFilterValues.tags"
      v-model:and-operator="localFilterValues.tag_and"
      :tags="tags"
    />
  </FilterCollapsible>
  <template v-if="metadataFilter?.length">
    <button
      type="button"
      class="flex items-center space-x-1 text-sm text-left text-indigo-500 pt-5"
      @click="showMetadataFilter = !showMetadataFilter"
    >
      <MinusIcon
        v-if="showMetadataFilter"
        aria-hidden="true"
        class="w-4 h-4"
      />
      <PlusIcon
        v-else
        aria-hidden="true"
        class="w-4 h-4"
      />
      <span>{{ showMetadataFilter ? $t('filter.hideMetadataFilter') :$t('filter.showMetadataFilter') }}</span>
    </button>
    <div
      v-show="showMetadataFilter"
      class="space-y-3"
    >
      <FilterCollapsible
        v-for="metadataEntry in metadataFilter"
        :key="metadataEntry.name"
        :label="metadataEntry.type === MetadataType.account || metadataEntry.account_metadata_uuid ? metadataEntry.display_name : $t(`metadata.system.${metadataEntry.name}.name`)"
        :all-open="allOpen"
        :count="getFilterCount(metadataEntry.name)"
        :pinned="pinnedFilters.includes(metadataEntry.name)"
        @pin="$emit('toggle-pin', metadataEntry.name)"
      >
        <MetadataFilter
          v-model="localFilterValues[metadataEntry.name]"
          :metadata="metadataEntry"
          :options="filterOptions[metadataEntry.name]"
        />
      </FilterCollapsible>
    </div>
  </template>
</template>
