import { Extension } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { Decoration, DecorationSet } from "@tiptap/pm/view"

/**
 * This extension allows you to add a placeholder to your editor.
 * A placeholder is a text that appears when the editor or a node is empty.
 * @see https://www.tiptap.dev/api/extensions/placeholder
 */
const Placeholder = Extension.create({
  name: "placeholder",
  addOptions () {
    return {
      emptyEditorClass: "is-editor-empty",
      emptyNodeClass: "is-empty",
      placeholder: "Write something …",
      showOnlyWhenEditable: true,
      showOnlyCurrent: true,
      includeChildren: false,
    }
  },
  addProseMirrorPlugins () {
    return [
      new Plugin({
        key: new PluginKey("placeholder"),
        props: {
          decorations: ({ doc, selection }) => {
            const active = this.editor.isEditable || !this.options.showOnlyWhenEditable
            const { anchor } = selection
            const decorations = []
            if (!active) {
              return null
            }
            const isEmptyDoc = this.editor.isEmpty
            doc.descendants((node, pos) => {
              const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize
              const isEmpty = !node.isLeaf && node.content.size === 0
              if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                const classes = [ this.options.emptyNodeClass ]
                if (isEmptyDoc) {
                  classes.push(this.options.emptyEditorClass)
                }
                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                  class: classes.join(" "),
                  "data-placeholder": typeof this.options.placeholder === "function"
                    ? this.options.placeholder({
                      editor: this.editor,
                      node,
                      pos,
                      hasAnchor,
                    })
                    : this.options.placeholder,
                })
                decorations.push(decoration)
              }
              return this.options.includeChildren
            })
            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})

export { Placeholder, Placeholder as default }
