<script setup lang="ts">
// external
import { computed, ref, nextTick } from "vue"
import { Link, router } from "@inertiajs/vue3"
import axios from "axios"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon, ArrowDownTrayIcon, ArrowUpTrayIcon } from "@heroicons/vue/24/solid"
import { CheckCircleIcon } from "@heroicons/vue/24/outline"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { storeToRefs } from "pinia"

// internal
import { FileInput, TeamIconDisplay, SpinLoader, LexicaButton, SubscriptionUpgradeBox } from "~/components"
import { Template, Team, Automation } from "~/types"
import { dataURLtoFile } from "~/utils"
import { RequestPayload } from "@inertiajs/core"
import { useAccountStore } from "~/stores"

interface Props {
  automation: Automation
  template: Template
  teams: Team[]
}
const props = withDefaults(
  defineProps<Props>(),
  {},
)

const accountStore = useAccountStore()
const { planFeatures, accountUsage } = storeToRefs(accountStore)

const isUpdating = ref(false)
const isLoadingXlsx = ref(false)
const isStarting = ref(false)

const selectedTeam = computed<Team>({
  get: () => props.automation.team_uuid ? props.teams.find((team) => props.automation.team_uuid === team.uuid) : null,
  set: (value) => {
    updateAutomation({
      team_uuid: value?.uuid || null,
    })
  },
})

const downloadXlsxTemplate = async () => {
  isLoadingXlsx.value = true
  const res = await axios.get(route("api.automations.xlsx-export", props.automation.uuid))
  isLoadingXlsx.value = false

  if (res.data?.data?.xlsx_data) {
    const url = window.URL.createObjectURL(new Blob([ dataURLtoFile(res.data.data.xlsx_data, props.template.name + ".xlsx") ]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", props.template.name + ".xlsx") //or any other extension
    document.body.appendChild(link)
    link.click()
  }
}

const validationErrors = ref(null)
const isValidatingXlsx = ref(false)
const fileName = ref(props.automation.filename || null)
const fileUrl = ref(props.automation.filename ? props.automation.xlsx_url : null)
const fileInput = ref()

const hasValidFile = computed(() => !!fileUrl.value)
const validateXlsxFile = async (fileUuid, filename) => {
  isValidatingXlsx.value = true

  fileName.value = null
  fileUrl.value = null

  try {
    await axios.post(route("api.automations.xlsx-validate", props.automation.uuid), {
      xlsx_uuid: fileUuid,
      filename: filename,
    })

    router.reload({
      only: [ "automation" ],
      onSuccess: () => {
        validationErrors.value = null
        fileName.value = props.automation.filename || null
        fileUrl.value = props.automation.xlsx_url || null

        isValidatingXlsx.value = false
      },
    })
  } catch (e) {

    isValidatingXlsx.value = false
    validationErrors.value = e?.response?.data
  }

}

const uploadNewFile = () => {
  fileUrl.value = null
  validationErrors.value = null

  nextTick(() => {
    fileInput.value.triggerClick()
  })
}

const filteredValidationErrors = computed(() => {
  if (!validationErrors.value?.length) return null

  return validationErrors.value.map((entry) => {
    for (const key in entry) {
      if (entry[key] === null) {
        delete entry[key]
      }
    }
    return entry
  })
})

const updateAutomation = async (payload: RequestPayload) => {
  isUpdating.value = true
  router.patch(route("automations.update", props.automation.uuid), payload, {
    onFinish: () => {
      isUpdating.value = false
    },
  })
}

const startAutomation = async () => {
  isStarting.value = true
  await axios.post(route("api.automations.xlsx-import", props.automation.uuid))

  router.reload({
    only: [ "automation", "documents" ],
    onFinish: () => {
      isStarting.value = false
    },
  })
}

const limitReached = computed(() => planFeatures.value?.documents !== 0 && accountUsage.value?.documents >= planFeatures.value?.documents)

</script>

<template>
  <div
    class="px-6 py-6 max-w-screen-2xl"
  >
    <div class="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-5">
      <div class="relative flex flex-col content-card">
        <div class="flex items-center px-5 py-2 text-sm font-medium content-card-header">
          <span class="flex items-center justify-center w-5 h-5 mr-2 text-xs font-bold text-white bg-indigo-400 rounded-full">
            <span>1</span>
          </span>
          <span>{{ $t('automations.automationSteps.prepareData') }}</span>
          <LexicaButton
            class="ml-2"
            topic="automations"
          />
        </div>
        <div class="p-5 grow">
          <div class="flex items-center mb-4 space-x-4">
            <span class="text-sm font-medium">{{ $t('magicTable.columns.team') }}:</span>
            <Listbox
              v-model="selectedTeam"
              as="div"
              class="grow"
              :disabled="isUpdating"
            >
              <div class="relative">
                <ListboxButton class="btn-listbox-plain">
                  <TeamIconDisplay
                    v-if="selectedTeam"
                    :team="selectedTeam"
                    class="w-4 h-4 mr-2"
                  />
                  <span
                    v-if="!selectedTeam"
                    class="block text-gray-500 truncate"
                  >{{ $t('documentGeneralSettings.selectTeams') }}…</span>
                  <span v-else>{{ selectedTeam.name }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="listbox-options">
                    <ListboxOption
                      v-for="team in teams"
                      :key="team.uuid"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="team"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex items-center']">
                        <TeamIconDisplay
                          :team="team"
                          class="w-4 h-4 mr-2"
                        />
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ team.name }}</span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <button
              v-if="selectedTeam"
              :disabled="isUpdating"
              @click="selectedTeam = null"
            >
              <XMarkIcon
                aria-hidden="true"
                class="w-5 h-5 -ml-2 text-gray-500"
              />
              <span class="sr-only">{{ $t('common.remove') }}</span>
            </button>
          </div>
          <div class="flex items-center space-x-4 text-sm">
            <span class="font-medium">{{ $t('magicTable.columns.template') }}:</span>
            <Link
              :href="route('templates.edit', template.uuid)"
              class="font-medium text-indigo-600"
            >
              {{ template.name }}
            </Link>
          </div>
          <button
            type="button"
            class="flex items-center justify-center w-full mt-5 space-x-2 btn-primary"
            @click="downloadXlsxTemplate"
          >
            <ArrowDownTrayIcon
              aria-hidden="true"
              class="w-4 h-4 shrink-0"
            />
            <span>{{ $t('automations.automationSteps.downloadXLSX') }}</span>
          </button>
        </div>
      </div>
      <div class="relative flex flex-col content-card">
        <div class="flex items-center px-5 py-2.5 text-sm font-medium content-card-header">
          <span class="flex items-center justify-center w-5 h-5 mr-2 text-xs font-bold text-white bg-indigo-400 rounded-full">
            <span>2</span>
          </span>
          <span>{{ $t('automations.automationSteps.uploadData') }}</span>
        </div>
        <div
          class="p-5 text-sm grow"
          :class="{'bg-red-50' : !!filteredValidationErrors}"
        >
          <div
            v-if="isValidatingXlsx"
            class="flex flex-col items-center justify-center w-full h-full"
          >
            <SpinLoader class="w-12 h-12 mx-auto mb-5 text-gray-500" />
            <div class="text-center">
              {{ $t('automations.validatingFile') }}…
            </div>
          </div>
          <div
            v-else-if="filteredValidationErrors"
            class="flex flex-col justify-between w-full h-full"
          >
            <div class="mb-5 font-medium text-red-800">
              {{ $t('automations.validationErrors.title') }}
            </div>
            <div class="space-y-2">
              <div
                v-for="(errorRow, errorRowIndex) in filteredValidationErrors"
                :key="'error_column_' + errorRowIndex"
                class="flex w-full space-x-2"
              >
                <div class="w-8 shrink-0">
                  {{ errorRowIndex + 1 }}
                </div>
                <div class="grow">
                  <div
                    v-for="(errorColumn, errorColumnKey) in errorRow"
                    :key="errorColumnKey"
                  >
                    {{ errorColumnKey }}: {{ $t('automations.validationErrors.codes.' + errorColumn) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center w-full mt-5">
              <button
                type="button"
                class="inline-flex items-center mx-auto space-x-2 btn-white"
                @click="uploadNewFile"
              >
                <ArrowUpTrayIcon
                  aria-hidden="true"
                  class="w-4 h-4 shrink-0"
                />
                <span>{{ $t('automations.uploadNewFile') }}</span>
              </button>
            </div>
          </div>

          <div
            v-else-if="hasValidFile"
            class="flex flex-col items-center justify-center w-full h-full"
          >
            <div class="flex items-center justify-center space-x-2">
              <CheckCircleIcon class="w-6 h-6 text-green-600 shrink-0" />
              <a
                class="font-medium text-green-600"
                :href="fileUrl || undefined"
                :download="fileName"
              >
                {{ fileName }}
              </a>
            </div>
            <div class="flex items-center justify-center w-full mt-3">
              <button
                type="button"
                class="inline-flex items-center mx-auto space-x-2 btn-plain"
                @click="uploadNewFile"
              >
                <ArrowUpTrayIcon
                  aria-hidden="true"
                  class="w-4 h-4 shrink-0"
                />
                <span>{{ $t('automations.uploadNewFile') }}</span>
              </button>
            </div>
          </div>
          <FileInput
            v-else
            ref="fileInput"
            :upload-callback="validateXlsxFile"
            :placeholder="$t('automations.automationSteps.dropXLSX')"
            accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            allowed-extensions="/\.xlsx$/i"
            @set-files="isValidatingXlsx = true"
          />
        </div>
      </div>
      <div class="relative flex flex-col content-card">
        <div class="flex items-center px-5 py-2.5 text-sm font-medium content-card-header">
          <span class="flex items-center justify-center w-5 h-5 mr-2 text-xs font-bold text-white bg-indigo-400 rounded-full">
            <span>3</span>
          </span>
          <span>{{ $t('automations.automationSteps.startAutomation') }}</span>
        </div>
        <div class="relative flex items-center justify-center w-full grow">
          <SubscriptionUpgradeBox
            v-if="limitReached"
            :text="$t('accountSettings.billing.documentCreationNotPossible', {limit: planFeatures?.documents})"
          />
          <button
            v-else
            type="button"
            class="flex items-center m-5 space-x-2 btn-primary"
            :disabled="isStarting || validationErrors || !hasValidFile"
            @click="startAutomation"
          >
            <SpinLoader
              v-if="isStarting"
              class="w-5 h-5 shrink-0"
            />
            <span>{{ $t('automations.automationSteps.startAutomation') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
