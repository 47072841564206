<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { Link } from "@inertiajs/vue3"

// internal
import { DialogModal } from "~/components"
import { useAccountStore } from "~/stores"


interface Props {
  show: boolean
  count: number
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const accountStore = useAccountStore()
const { planFeatures, accountUsage } = storeToRefs(accountStore)

defineEmits([ "update:show" ])
</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="xl"
    :padding="false"
    :show-close-button="true"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ $t('documents.limitExceedModal.title') }}
      </div>
    </template>
    <template #content>
      <div class="px-6 pb-6 space-y-4 text-sm">
        <p>{{ $t('documents.limitExceedModal.description') }}</p>
        <div class="relative grid grid-cols-2 overflow-hidden border border-gray-200 rounded bg-gray-50">
          <span class="p-2">{{ $t('documents.limitExceedModal.planLimit') }}:</span><span class="p-2">{{ planFeatures?.documents }}</span>
          <span class="p-2">{{ $t('documents.limitExceedModal.currentlyActive') }}:</span><span class="p-2">{{ accountUsage?.documents }}</span>
          <span class="p-2">{{ $t('documents.limitExceedModal.aboutToAdd') }}:</span><span class="p-2">{{ count }}</span>
          <span class="p-2 font-medium text-red-500 bg-red-100">{{ $t('documents.limitExceedModal.overLimit') }}:</span><span class="p-2 font-medium text-red-500 bg-red-100">{{ (accountUsage?.documents + count - planFeatures?.documents) }}</span>
        </div>
        <p class="font-medium">
          {{ $t('accountSettings.billing.upgradeOrContact') }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="$emit('update:show', false)"
        >
          {{ $t('common.cancel') }}
        </button>

        <a
          href="mailto:sales@fynk.com"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
        >
          {{ $t('lexica.contactSales') }}
        </a>
        <Link
          class="btn-primary"
          :href="route('account-settings.billing.index')"
          target="_blank"
        >
          {{ $t('accountSettings.billing.manage') }}
        </Link>
      </div>
    </template>
  </DialogModal>
</template>
