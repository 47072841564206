<script setup lang="ts">
// external
import { ref, onMounted, computed } from "vue"
import { useI18n } from "vue-i18n"
import axios from "axios"
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"

// internal
import { Metadata, MetadataType } from "~/types"
import { MultiFieldIcon, OnboardingStepContainer, LoadingPlaceholder } from "~/components"
import { Recommendations } from "~/lottie"
import { useOnboardingStore } from "~/stores"
import { Vue3Lottie } from "vue3-lottie"

const onboardingStore = useOnboardingStore()
const {
  contractTypes,
} = storeToRefs(onboardingStore)

const { t } = useI18n()

const schema = ref<Metadata[]>(null)
const isLoadingSchema = ref(false)

const specificMetadata = computed(() => {
  if (!schema.value) return []
  return schema.value.filter((metadata) => metadata.type === MetadataType.reference && metadata.value_type !== "clause" && !!metadata.relevant_contract_types?.length)
})

const otherReferenceMetadata = computed(() => {
  if (!schema.value) return []
  return schema.value.filter((metadata) => metadata.type === MetadataType.reference && metadata.value_type !== "clause" && !metadata.relevant_contract_types?.length)
})

const loadSchema = async () => {
  if (!isLoadingSchema.value && contractTypes.value.length) {
    isLoadingSchema.value = true
    schema.value = null
    try {
      const res = await axios.post(route("api.data.account.metadata-preview"), {
        contract_types: contractTypes.value,
      })

      if (res?.data?.data) {
        schema.value = res.data.data
      }
    } catch (err) {
      console.error(err)
    } finally {
      isLoadingSchema.value = false
    }
  }
}

onMounted(() => {
  loadSchema()
})

const relevantContractTypeList = (relevantContractTypes: string[]) => {
  return relevantContractTypes
    .filter((entry) => contractTypes.value.includes(entry))
    .map((entry) => t("contractTypes." + entry))
    .join("\n")
}

</script>
<template>
  <OnboardingStepContainer v-cy="`onboarding-step-recommendations`">
    <template #default>
      <template v-if="isLoadingSchema">
        <div
          class="grid gap-2 mb-5 text-sm sm:grid-cols-2"
        >
          <LoadingPlaceholder
            v-for="n in 10"
            :key="'loader' + n"
            class="relative w-full h-6 rounded-sm"
          />
        </div>
      </template>
      <template v-else>
        <template v-if="specificMetadata?.length">
          <div class="mb-1 font-semibold text-indigo-600 sm:text-lg">
            {{ $t('onboarding.steps.recommendations.headline') }}
          </div>
          <div class="mb-5 text-indigo-600">
            {{ $t('onboarding.steps.recommendations.specificList') }}
          </div>

          <div
            class="grid gap-3 mb-5 text-sm sm:grid-cols-2"
          >
            <div
              v-for="metadata in specificMetadata"
              :key="metadata.name"
            >
              <div
                class="inline-flex items-start justify-start w-auto max-w-full space-x-2 cursor-help"
                data-tippy-help
                data-tippy-list
                :data-tippy-content="`${$t('metadata.system.'+metadata.name+'.name')} (${$t('multiFieldTypes.'+metadata.value_type)})\n\nRelevant for: \n${relevantContractTypeList(metadata.relevant_contract_types) }`"
                data-placement="top"
              >
                <div class="flex items-center justify-center w-5 h-5 text-indigo-500 bg-gray-100 rounded-full shrink-0">
                  <MultiFieldIcon
                    :type="metadata.value_type"
                    size-classes="w-3 h-3"
                  />
                </div>
                <div class="truncate">
                  {{ $t(`metadata.system.${metadata.name}.name`) }}
                </div>
              </div>
            </div>
          </div>
        </template>


        <div
          v-if="otherReferenceMetadata.length"
          class="flex items-center space-x-5"
        >
          <div class="mb-3 text-indigo-700">
            {{ $t('onboarding.steps.recommendations.generalList') }}
          </div>
        </div>
        <div
          class="grid gap-2 text-sm sm:grid-cols-2"
        >
          <div
            v-for="metadata in otherReferenceMetadata"
            :key="metadata.name"
            class="inline-flex items-center justify-start w-full space-x-2"
          >
            <div class="flex items-center justify-center w-5 h-5 text-gray-500 bg-gray-100 rounded-full shrink-0">
              <MultiFieldIcon
                :type="metadata.value_type"
                size-classes="w-3 h-3"
              />
            </div>
            <div class="grow">
              {{ $t(`metadata.system.${metadata.name}.name`) }}
            </div>
          </div>
        </div>
      </template>
    </template>
    <template #aside>
      <div class="relative w-full">
        <div class="absolute inset-0 flex items-center justify-center">
          <div class="block w-full h-full m-12 bg-gray-100 rounded-full max-w-32 max-h-32" />
        </div>
        <Vue3Lottie
          :animation-data="Recommendations"
          class="max-w-60"
          aria-hidden="true"
        />
      </div>
      <div class="w-full mt-5 text-center">
        <h3 class="mb-2 text-lg font-semibold text-indigo-600">
          {{ $t('onboarding.steps.recommendations.aboutHeadline') }}
        </h3>
        <div class="mb-2 text-sm">
          {{ $t('onboarding.steps.recommendations.aboutCopy') }}
        </div>
        <a
          class="inline-flex items-center space-x-2 text-indigo-400 btn-plain hover:text-indigo-600 btn-sm"
          target="_blank"
          href="https://help.fynk.com/en/articles/126993-using-metadata"
        >
          <ArrowTopRightOnSquareIcon
            aria-hidden="true"
            class="w-3 h-3"
          />
          <span>{{ $t('onboarding.steps.recommendations.learnMore') }}</span>
        </a>
      </div>
    </template>
  </OnboardingStepContainer>
</template>
