<script setup lang="ts">
// external
import { Form, Field } from "vee-validate"
import { ref, watch, onBeforeMount, computed } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, InformationCircleIcon } from "@heroicons/vue/20/solid"
import { storeToRefs } from "pinia"
import { TrashIcon, StarIcon } from "@heroicons/vue/24/outline"
import { StarIcon as StarIconSolid, BuildingOffice2Icon, UserIcon, UserGroupIcon } from "@heroicons/vue/24/solid"

// internal
import { DialogModal, SpinLoader, TeamIconDisplay } from "~/components"
import { useDashboardStore } from "~/stores"
import { Dashboard, DashboardScope } from "~/types"

interface Props {
  show: boolean
  dashboard?: Dashboard
}

const props = withDefaults(
  defineProps<Props>(),
  {
    dashboard: null,
  },
)

const dashboardStore = useDashboardStore()
const { mau, teamOptions, dashboards } = storeToRefs(dashboardStore)
const { createOrUpdateDashboard, deleteDashboard, setActiveDashboardUuid } = dashboardStore

const scopeOptions = ref([ DashboardScope.personal, DashboardScope.team ])
const selectedScope = ref(props.dashboard?.scope ?? DashboardScope.personal)
const selectedTeam = ref(props.dashboard?.scope === DashboardScope.team ? teamOptions.value.find((team) => team.uuid === props.dashboard.dashboardable_uuid) : teamOptions.value[0])
const isSubmitting = ref(false)
const isDefault = ref(false)
const submitButton = ref()

const dashboardToEdit = ref(props.dashboard ? { ...props.dashboard } : null)

const emit = defineEmits([ "update:show", "update:edit-index" ])

watch(() => props.show, (newVal) => {
  if (!newVal) {
    selectedScope.value = scopeOptions.value[0]
  } else {
    isDefault.value = !!mau.value?.dashboard_uuid && mau.value?.dashboard_uuid === props.dashboard.uuid
  }
})

const onSubmit = async (values, { resetForm }) => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    let payload

    if (!dashboardToEdit.value || (dashboardToEdit.value.uuid === "personal" || dashboardToEdit.value.uuid === "account")) {

      let dashboardableUuid = mau.value.uuid

      if (selectedScope.value === DashboardScope.account) {
        dashboardableUuid = mau.value.account_uuid
      } else if (selectedScope.value === DashboardScope.team) {
        dashboardableUuid = selectedTeam.value.uuid
      }

      payload = {
        ...values,
        scope: selectedScope.value,
        dashboardable_uuid: dashboardableUuid,
        layout: dashboardToEdit.value?.layout ?? null,
        filter: dashboardToEdit.value?.filter ?? null,
        is_default: !!isDefault.value,
      }
    } else {
      payload = {
        uuid: props.dashboard.uuid,
        scope: props.dashboard.scope,
        dashboardable_uuid: props.dashboard.scope === DashboardScope.team ? selectedTeam.value.uuid : props.dashboard.dashboardable_uuid,
        name: values.name,
        is_default: !!isDefault.value,
      }
    }

    const dashboard = await createOrUpdateDashboard(payload)

    if (dashboard) {
      setActiveDashboardUuid(dashboard.uuid)

      // new dashboard is default
      if (isDefault.value && (!props.dashboard || (dashboardToEdit.value.uuid === "personal" || dashboardToEdit.value.uuid === "account"))) {
        mau.value.dashboard_uuid = dashboard.uuid

      // existing dashboard...
      } else if (props.dashboard && props.dashboard.uuid === dashboard.uuid) {
        // ...is no longer default -> remove default
        if (mau.value.dashboard_uuid === dashboard.uuid && !isDefault.value) {
          mau.value.dashboard_uuid = null

        // ...is the new default -> set default
        } else if (isDefault.value) {
          mau.value.dashboard_uuid = dashboard.uuid
        }
      }
    }

    resetForm()
    isSubmitting.value = false
    close()
  }
}

const close = () => {
  emit("update:show", false)
  emit("update:edit-index", null)
}

const submitHelper = () => {
  submitButton.value.click()
}

const removeDashboard = async () => {
  if (!!props.dashboard) {
    await deleteDashboard(props.dashboard.uuid)
    close()
  }
}

const showRemoveButton = computed(() => {
  if (!props.dashboard) {
    return false
  }

  const numberOfAccountDashboards = dashboards.value.filter((dashboard) => dashboard.scope === DashboardScope.account).length
  const numberOfPersonalDashboards = dashboards.value.filter((dashboard) => dashboard.scope === DashboardScope.personal).length

  // dont allow to remove last personal/account dashboard
  if (props.dashboard.scope === DashboardScope.account && numberOfAccountDashboards === 1) {
    return false
  }
  if (props.dashboard.scope === DashboardScope.personal && numberOfPersonalDashboards === 1) {
    return false
  }

  return true
})

onBeforeMount(() => {
  if (mau.value?.roles[0].name === "account-owner") {
    scopeOptions.value.push(DashboardScope.account)
  }
  isDefault.value = !!mau.value?.dashboard_uuid && !!props.dashboard && mau.value?.dashboard_uuid === props.dashboard.uuid
})
</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="lg"
    :padding="false"
    :show-close-button="true"
    @close="close"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ !!dashboardToEdit ? $t('dashboard.editDashboard') : $t('dashboard.createDashboard') }}
      </div>
    </template>
    <template #content>
      <div class="px-6 pb-6">
        <Form
          v-slot="{ errors }"
          class="space-y-5"
          @submit="onSubmit"
        >
          <div>
            <label
              for="dashboardName"
              class="block mb-1 text-sm"
            >{{ $t('dashboard.form.name') }} <span class="text-indigo-500">*</span></label>
            <Field
              id="dashboardName"
              name="name"
              type="text"
              class="input-plain"
              :class="[errors.name ? 'error' : '']"
              rules="required"
              :value="!!dashboardToEdit ? (dashboardToEdit.name ? dashboardToEdit.name : $t('dashboard.tabs.' + dashboardToEdit.key)): ''"
              :placeholder="$t('dashboard.form.namePlaceholder') + '…'"
            />
            <button
              ref="submitButton"
              type="submit"
              class="hidden"
            />
          </div>
          <div>
            <div
              class="block mb-1 text-sm"
            >
              {{ $t('dashboard.form.scope') }} <span class="text-indigo-500">*</span>
            </div>
            <Listbox
              v-model="selectedScope"
              :disabled="!!dashboardToEdit"
              as="div"
            >
              <div class="relative mt-1">
                <ListboxButton class="btn-listbox-plain">
                  <div
                    class="flex items-center"
                  >
                    <BuildingOffice2Icon
                      v-if="selectedScope === DashboardScope.account"
                      aria-hidden="true"
                      class="w-4 h-4 mr-2 text-indigo-500 shrink-0"
                    />
                    <UserGroupIcon
                      v-if="selectedScope === DashboardScope.team"
                      aria-hidden="true"
                      class="w-4 h-4 mr-2 text-indigo-500 shrink-0"
                    />
                    <UserIcon
                      v-if="selectedScope === DashboardScope.personal"
                      aria-hidden="true"
                      class="w-4 h-4 mr-2 text-indigo-500 shrink-0"
                    />
                    <span>{{ $t('dashboard.scopes.' + selectedScope + '.title') }}</span>
                  </div>

                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="listbox-options">
                    <ListboxOption
                      v-for="scope in scopeOptions"
                      :key="scope"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="scope"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center']">
                          <BuildingOffice2Icon
                            v-if="scope === DashboardScope.account"
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-indigo-400 shrink-0"
                          />
                          <UserGroupIcon
                            v-if="scope === DashboardScope.team"
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-indigo-400 shrink-0"
                          />
                          <UserIcon
                            v-if="scope === DashboardScope.personal"
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-indigo-400 shrink-0"
                          />
                          <div>
                            <span>{{ $t('dashboard.scopes.' + scope + '.title') }}</span>
                            <p class="text-xs font-normal text-gray-400">
                              {{ $t('dashboard.scopes.' + scope + '.description') }}
                            </p>
                          </div>
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div v-if="selectedScope === DashboardScope.team">
            <div
              class="block mb-1 text-sm"
            >
              {{ $t('dashboard.form.team') }} <span class="text-indigo-500">*</span>
            </div>
            <Listbox
              v-model="selectedTeam"
              as="div"
            >
              <div class="relative mt-1">
                <ListboxButton class="btn-listbox-plain">
                  <div
                    v-if="selectedTeam"
                    class="flex items-center"
                  >
                    <TeamIconDisplay
                      :team="selectedTeam"
                      class="w-4 h-4 mr-2"
                    />
                    <span>{{ selectedTeam.name }}</span>
                  </div>
                  <span
                    v-else
                    class="block text-gray-500 truncate"
                  >{{ $t('dashboard.form.selectTeam') }}…</span>

                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="listbox-options">
                    <ListboxOption
                      v-for="team in teamOptions"
                      :key="'team_' + team.uuid"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="team"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                          <TeamIconDisplay
                            :team="team"
                            class="w-4 h-4 mr-2"
                          />
                          <span>{{ team.name }}</span>
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div>
            <button
              class="flex items-center text-sm"
              type="button"
              @click="isDefault = !isDefault"
            >
              <StarIconSolid
                v-if="isDefault"
                aria-hidden="true"
                class="w-5 h-5 text-yellow-500"
              />
              <StarIcon
                v-if="!isDefault"
                aria-hidden="true"
                class="w-5 h-5 text-gray-400"
              />
              <span class="ml-2 mr-1">{{ $t('dashboard.isDefault') }}</span>
              <span
                data-tippy-help
                :data-tippy-content="$t('dashboard.defaultInfo')"
                data-placement="bottom"
              >
                <InformationCircleIcon
                  class="w-4 h-4 text-indigo-400"
                  aria-hidden="true"
                />
              </span>
            </button>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div
        class="flex items-center space-x-2"
        :class="[showRemoveButton ? 'justify-between' : 'justify-end']"
      >
        <button
          v-if="showRemoveButton"
          type="button"
          class="flex items-center gap-2 text-gray-400 btn-plain btn-sm hover:text-red-500 hover:bg-red-100"
          @click.prevent="removeDashboard"
        >
          <TrashIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          <span>
            {{ $t('common.remove') }}
          </span>
        </button>
        <div class="flex items-center justify-end gap-2">
          <button
            type="button"
            class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
            @click.prevent="close"
          >
            {{ $t('common.cancel') }}
          </button>
          <button
            type="button"
            class="flex items-center gap-2 btn-primary"
            :disabled="isSubmitting"
            @click="submitHelper"
          >
            <SpinLoader
              v-if="isSubmitting"
              class="w-5 h-5 shrink-0"
            />
            <span>{{ !!dashboardToEdit ? $t('common.save') : $t('common.create') }}</span>
          </button>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
