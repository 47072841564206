<script setup lang="ts">

import { SignalSlashIcon } from "@heroicons/vue/24/outline"

const reload = () => {
  window.location.reload()
}

interface Props {
  disableReload?: boolean
}

withDefaults(
  defineProps<Props>(),
  {
    disableReload: false,
  },
)

</script>

<template>
  <div
    class="inset-x-0 top-0 bg-red-600 shadow-xl"
  >
    <div class="flex items-center justify-center py-2 text-sm font-medium text-center text-white px-7">
      <div class="flex items-center">
        <SignalSlashIcon class="w-4 h-4 mr-2" />
        {{ $t('common.attemptingToReconnect') }}
        <span
          id="pusherConnectionStatusLoader"
          class="w-2 text-left"
        />
      </div>
      <button
        type="button"
        data-tippy-help
        :data-tippy-content="disableReload ? $t('common.locked.unsavedChanges') : ''"
        data-placement="bottom"
        class="ml-3 text-red-300 btn-red btn-sm"
        :class="disableReload ? 'cursor-not-allowed opacity-50 bg-none hover:bg-none' : 'hover:text-white hover:bg-white/10 focus:ring-white/20'"
        @click="() => !disableReload ? reload : null"
      >
        {{ $t('common.locked.reloadPage') }}
      </button>
    </div>
  </div>
</template>
