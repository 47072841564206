<script setup lang="ts">
// external
import { ref, computed, watch } from "vue"
import axios from "axios"
import { ArrowUturnLeftIcon, SparklesIcon, TrashIcon } from "@heroicons/vue/24/outline"
import { router } from "@inertiajs/vue3"
import { marked } from "marked"
import { computedAsync, onClickOutside } from "@vueuse/core"

// internal
import { AiAnalysisPartyDisplay, AiAnalysisReviewSuggestion, DialogModal, DocumentRenderedHtml, LoadingPlaceholder, MetadataPopover, MetadataValueInput, OverlayScrollbar, PdfViewer, SpinLoader, TabPills, SubscriptionUpgradeBox, AutogrowInput, LocaleSelector } from "~/components"
import { Document, AiAnalysis, ProposedChanges, ProposedMetadataChange, MetadataValue, DocumentType, ProposedChangeTab, DocumentOrigin, DocumentContentType, ProposedPartyChange, BoundingBox, MultiFieldType, DurationTypeRelatedMetadata } from "~/types"
import { useAccountStore, useAiStore, useDocumentStore, useMetadataStore, usePusherStore } from "~/stores"
import { toRaw } from "vue"
import { storeToRefs } from "pinia"
import { intersection, sortBy } from "lodash-es"
import { Metadata } from "~/types"
import { cyrb53, documentTypes, focusFirstFocusable, purifyHtml } from "~/utils"
import { useI18n } from "vue-i18n"
import { PlusIcon } from "@heroicons/vue/24/solid"
import tippy, { roundArrow } from "tippy.js"

interface Props {
  document?: Document
  aiAnalysisUuid?: AiAnalysis["uuid"]
  open: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {},
)

const isSubmitting = ref(false)
const isLoading = ref(false)
const proposedChanges = ref<ProposedChanges>(null)
const originalProposedChanges = ref<ProposedChanges>(null)

const emit = defineEmits([ "update:open", "applied", "discard" ])

const { t } = useI18n()

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const metadataStore = useMetadataStore()
const { isLoadingMetadataValues, isLoadingMetadata, metadataValues, metadata, uuidsOfUpdatingMetadataValue } = storeToRefs(metadataStore)
const { handleShowBoundingBox, handleHideBoundingBoxes } = metadataStore

const documentStore = useDocumentStore()
const { pdfUrl, isLoadingPdfUrl, htmlContent, isLoadingHtmlContent, isLockedDocument, currentDocument } = storeToRefs(documentStore)
const { setPdfUrl, fetchPdfUrl, fetchHtmlContent } = documentStore

const accountStore = useAccountStore()
const { planFeatures, accountUsage } = storeToRefs(accountStore)

const aiStore = useAiStore()
const { isLoadingGetAnalysis } = storeToRefs(aiStore)
const { setShowAiAnalysisReviewModal } = aiStore

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)
const helper = ref(false)

const proposedMetadata = computed(() => {
  helper.value
  if (!proposedChanges.value?.schema_metadata) return []
  // Return only proposedChanges for matching metadata that are not MultiFieldType.clause
  const filteredMetadata = proposedChanges.value?.schema_metadata?.filter((el) => metadata.value.some((metadata) => metadata.name === el.name && metadata.value_type !== MultiFieldType.clause))
  return filteredMetadata
})

const clauseChangeTrigger = ref(false)

const proposedClauses = computed(() => {
  if (!proposedChanges.value?.schema_metadata) return []
  clauseChangeTrigger.value
  // Return only proposedChanges for matching metadata that are MultiFieldType.clause
  const filteredClauses = proposedChanges.value?.schema_metadata?.filter((el) => metadata.value.some((metadata) => metadata.name === el.name && metadata.value_type === MultiFieldType.clause) && el.value)
  return filteredClauses
})

const proposedDates = computed(() => {
  if (!originalProposedChanges.value?.contract_duration) return []
  return originalProposedChanges.value?.contract_duration
})
const groupedProposedDates = computed<ProposedMetadataChange[]>(() => {
  const sanitizedResults = sanitizeResults(proposedDates.value)
  return sanitizedResults
})

const proposedChangeTabs = computed(() => {
  return     [
    { id: ProposedChangeTab.all, name: t("aiAnalysis.tabs." + ProposedChangeTab.all) },
    { id: ProposedChangeTab.parties, name: t("aiAnalysis.tabs." + ProposedChangeTab.parties), badge: proposedChanges.value?.parties?.length },
    { id: ProposedChangeTab.dates, name: t("aiAnalysis.tabs." + ProposedChangeTab.dates), badge: proposedDates.value?.length },
    { id: ProposedChangeTab.metadata, name: t("aiAnalysis.tabs." + ProposedChangeTab.metadata), badge: proposedMetadata.value?.length ? proposedMetadata.value?.length : 0 },
    { id: ProposedChangeTab.clauses, name: t("aiAnalysis.tabs." + ProposedChangeTab.clauses), badge: proposedClauses.value?.length ? proposedClauses.value?.length : 0 },
  ]
})

const activeProposedChangeTab = ref(proposedChangeTabs.value[0])

const close = () => {
  emit("update:open", false)
}

const updateIntro = (value: string) => {
  proposedChanges.value.intro = value
}

const updateDocumentLanguage = (value: string) => {
  proposedChanges.value.document_language = value
}

const updateMetadataValue = (metadataValueKey: Metadata["name"], value: any, group = "schema_metadata") => {
  if (!metadataValueKey) return
  if (!proposedChanges.value?.schema_metadata) return

  if (proposedChanges.value[metadataValueKey]) {
    if (proposedChanges.value[metadataValueKey].value !== value) {
      proposedChanges.value[metadataValueKey].quotation = null
      proposedChanges.value[metadataValueKey].bounding_box = null
    }
    proposedChanges.value[metadataValueKey].value = value
  } else {
    let tmpGroup = JSON.parse(JSON.stringify(proposedChanges.value[group]))

    const localIndexOfMetadataValue = tmpGroup.findIndex((el) => el.name === metadataValueKey)

    if (localIndexOfMetadataValue !== -1) {
      if (tmpGroup[localIndexOfMetadataValue].value !== value) {
        tmpGroup[localIndexOfMetadataValue].quotation = null
        tmpGroup[localIndexOfMetadataValue].bounding_box = null
      }
      tmpGroup[localIndexOfMetadataValue].value = value

      // filter out duplicate entries
      if (tmpGroup.filter((el) => el.name === metadataValueKey).length > 1) {
        tmpGroup = tmpGroup.filter((el) => el.name !== metadataValueKey || (el.name === metadataValueKey && el.value === value))
      }

      proposedChanges.value[group] = tmpGroup
    }

  }

  if (metadataValueKey.endsWith("_clause")) clauseChangeTrigger.value = !clauseChangeTrigger.value
  helper.value = !helper.value

  return
}

const updateParty = (value: ProposedPartyChange, partyIdx: number) => {
  if (!!proposedChanges.value?.parties[partyIdx]) {
    proposedChanges.value.parties[partyIdx] = value
  }
}

const resetParty = (partyIdx: number) => {
  if (!proposedChanges.value?.parties) return

  const localParty = { ...proposedChanges.value.parties?.[partyIdx] }

  proposedChanges.value.parties[partyIdx] = {
    name: localParty.name,
    address: "",
    entity_type: localParty.entity_type,
    entityType: localParty.entityType,
    entity_name: "",
    entityName: "",
    account_party_uuid: null,
    accountPartyUuid: null,
  }

}

const recommendedMetadataValuesResidual = computed<MetadataValue[]>(() => {
  const filteredRes = metadataValues.value.filter((el: MetadataValue) => el.based_on_contract_type && el.reference_dynamic_field_uuid)
  const sortedRes = sortBy(filteredRes, (el) => el.metadata.type)
  return sortedRes
})

const handleSubmit = async () => {
  isSubmitting.value = true
  try {
    await axios.patch(route("api.documents.ai-analyses.update", {
      document: props.document?.uuid,
      ai_analysis: props.aiAnalysisUuid,
    }),
    {
      reviewed_changes: proposedChanges.value,
      status: "reviewed",
    })

    router.reload({
      only: [ "aiAnalyses", "aiAnalysis", "document" ],
      onFinish: () => {
        isSubmitting.value = false
        setShowAiAnalysisReviewModal(false)
        emit("applied")
        close()
      },
    })

  } catch (e) {
    console.error(e)
  } finally {
    isSubmitting.value = false
  }
}

const getMetadataValueByKey = (key: string) => {
  if (!metadataValues.value) return null
  const checkForMetadataValue = metadataValues.value?.find((el: MetadataValue) => el.metadata && el.metadata.name === key)
  if (checkForMetadataValue) return checkForMetadataValue
}

const getMetadataByKey = (key: string) => {
  if (!metadata.value) return null
  const checkForMetadata = metadata.value?.find((el: Metadata) => el.name === key)
  return checkForMetadata ?? null
}

const isLoadingSaveValues = computed<boolean>(() => {
  if (!proposedChanges.value || !proposedChanges.value?.schema_metadata) return false
  return !!(intersection(uuidsOfUpdatingMetadataValue.value, proposedChanges.value?.schema_metadata?.map((el) => getMetadataValueByKey(el.name)?.uuid))?.length > 0)
})

const checkAllIfDisabledByPusher = computed<boolean>(() => {
  let disabled = false
  if (!proposedChanges.value || !proposedChanges.value?.schema_metadata) return disabled
  proposedChanges.value?.schema_metadata?.forEach((el) => {
    const check = checkIfDisabledByPusher("metadata-input_" + getMetadataValueByKey(el.name))
    if (check) disabled = true
  })
  return disabled
})

const parties = computed(() => {
  return proposedChanges.value.parties
})

const loadDetails = async () => {
  if (!props.document?.uuid) return
  isLoading.value = true
  try {
    const apiRoute = route("api.documents.ai-analyses.show", {
      document: props.document?.uuid,
      ai_analysis: props.aiAnalysisUuid,
    })
    const res = await axios.get<{ data: AiAnalysis }>(apiRoute)

    if (res.data?.data?.proposed_changes) {
      const resultChanges = res.data.data.proposed_changes
      proposedChanges.value = resultChanges
      originalProposedChanges.value = resultChanges
      // check if all proposed metadata still exists in account, otherwise remove from payload
      if (proposedChanges.value.schema_metadata) {
        const filteredMetadata = proposedChanges.value.schema_metadata.filter((el) => metadata.value.find((metadata) => metadata.name === el.name))
        proposedChanges.value.schema_metadata = filteredMetadata
      }
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}

const isDiscarding = ref(false)
const discardAnalysis = async () => {
  isDiscarding.value = true
  try {
    await axios.delete(route("api.documents.ai-analyses.destroy", {
      document: props.document?.uuid,
      ai_analysis: props.aiAnalysisUuid,
    }))
    router.reload({
      only: [ "aiAnalyses", "aiAnalysis", "document" ],
      onFinish: () => {
        aiStore.setAnalysis(null)
        isLoading.value = false
        close()
      },
    })
  } catch (e) {
    console.error(e)
  } finally {
    isDiscarding.value = false
  }
}

watch(() => props.open, async (newVal, oldVal) => {
  if (!props.document?.uuid) setPdfUrl(null)
  if (newVal && !oldVal && props.aiAnalysisUuid && props.document?.uuid) {
    loadDetails()
    if (props.document?.content_type === DocumentContentType.pdf || [ DocumentOrigin.signed_pdf, DocumentOrigin.pdf ].includes(props.document?.origin)) {
      if (props.document?.uuid !== currentDocument.value?.uuid) await fetchPdfUrl(props.document?.uuid)
    }
    else await fetchHtmlContent(props.document?.uuid)
  } else if (!newVal && oldVal) {
    proposedChanges.value = null
  }
  if (!newVal) {
    uuidOfIsActiveBoundingBox.value = null
    uuidOfIsActiveMetadata.value = null
    handleHideBoundingBoxes(props.document, aiAnalysisPdfViewer.value)
  }
})

const removeParty = (partyIdx) => {
  proposedChanges.value.parties.splice(partyIdx, 1)
}
const addParty = () => {
  if (!proposedChanges.value.parties) proposedChanges.value.parties = []
  proposedChanges.value.parties.push({
    name: t("aiAnalysis.newParty"),
    address: "",
    entity_type: null,
    entityType: null,
    entity_name: "",
    entityName: "",
    account_party_uuid: null,
    accountPartyUuid: null,
  })
}
const resetField = (field, value = null) => {
  proposedChanges.value[field] = value
}

const showSummary = computedAsync<string>(() => !!proposedChanges.value?.summary ? marked.parse(proposedChanges.value?.summary) : "")
const isVisibleSummary = ref<boolean>(false)

const addMetadataValueToAiAnalysisPopoverRef = ref()
const addMetadataValueTippy = ref(null)
const selectedMetadata = ref<Metadata>(null)
const isSubmittingAddMetadata = ref<boolean>(false)

const showAddMetadataPopover = (e) => {
  addMetadataValueTippy.value = tippy(e.target, {
    content () {
      return addMetadataValueToAiAnalysisPopoverRef.value
    },
    appendTo: (ref) => { return ref.closest("#aiAnalysisReviewModal") || document.getElementById("mainContentContainer") },
    animation: "scale",
    allowHTML: true,
    theme: "indigo",
    arrow: roundArrow,
    interactive: true,
    trigger: "manual",
    showOnCreate: true,
    placement: "top",
    onShown () {
      const element = addMetadataValueToAiAnalysisPopoverRef.value
      focusFirstFocusable(element)
    },
    onHidden () {
      selectedMetadata.value = null
    },
  })
}

const addDateMetadataValueToAiAnalysisPopoverRef = ref()
const addDateMetadataValueTippy = ref(null)
const selectedDateMetadata = ref<Metadata>(null)
const isSubmittingAddDateMetadata = ref<boolean>(false)

const showAddDatePopover = (e) => {
  addDateMetadataValueTippy.value = tippy(e.target, {
    content () {
      return addDateMetadataValueToAiAnalysisPopoverRef.value
    },
    appendTo: (ref) => { return ref.closest("#aiAnalysisReviewModal") || document.getElementById("mainContentContainer") },
    animation: "scale",
    allowHTML: true,
    theme: "indigo",
    arrow: roundArrow,
    interactive: true,
    trigger: "manual",
    showOnCreate: true,
    placement: "top",
    onShown () {
      const element = addDateMetadataValueToAiAnalysisPopoverRef.value
      focusFirstFocusable(element)
    },
    onHidden () {
      selectedDateMetadata.value = null
    },
  })
}

const attachableMetadata = computed<Metadata[]>(() => {
  if (!metadata.value || !proposedChanges.value?.schema_metadata) return []
  const filteredMetadata = metadata.value.filter((el: Metadata) => !proposedChanges.value?.schema_metadata?.find((metadata) => metadata.name === el.name) && !Object.values(DurationTypeRelatedMetadata).includes(el.name as any))
  return filteredMetadata
})

const attachableDateMetadata = computed<Metadata[]>(() => {
  if (!metadata.value || !proposedChanges.value?.contract_duration) return []
  const filteredMetadata = metadata.value.filter((el: Metadata) => !proposedChanges.value?.contract_duration?.find((metadata) => metadata.name === el.name) && Object.values(DurationTypeRelatedMetadata).includes(el.name as any))
  return filteredMetadata
})

const hasNoProposedChanges = computed(() => {
  return !proposedChanges.value || (Array.isArray(proposedChanges.value) && !proposedChanges.value.length)
})

const aiAnalysisPdfViewer = ref<InstanceType<typeof PdfViewer>>()
const aiAnalysisPreviewScrollContainer = ref<InstanceType<typeof OverlayScrollbar>>()

const addMetadataValue = async (metadata: Metadata): Promise<MetadataValue | void> => {
  if (!metadata) return
  if (!proposedChanges.value.schema_metadata) proposedChanges.value.schema_metadata = []
  proposedChanges.value.schema_metadata.push({
    name: metadata.name,
    value: null,
    bounding_box: null,
    quotation: null,
    section: null,
  })
  selectedMetadata.value = null
  addMetadataValueTippy.value?.hide()
}

const addDateMetadataValue = async (metadata: Metadata): Promise<MetadataValue | void> => {
  if (!metadata) return
  if (!proposedChanges.value.contract_duration) proposedChanges.value.contract_duration = []
  proposedChanges.value.contract_duration.push({
    name: metadata.name,
    value: null,
    bounding_box: null,
    quotation: null,
    section: null,
  })
  selectedDateMetadata.value = null
  addDateMetadataValueTippy.value?.hide()
}

const sortedProposedMetadata = computed(() => {
  helper.value
  const sortedMetadata: ProposedMetadataChange[] = []

  // Add 'contract_type' first if it exists
  if (proposedMetadata.value?.find((el) => el.name === "contract_type")) {
    sortedMetadata.push(proposedMetadata.value?.find((el) => el.name === "contract_type"))
  }

  // Add the rest of the keys
  proposedMetadata.value?.forEach((el) => {
    // If el.value is number, cast it to string
    if (el.value && typeof el.value === "number") el.value = el.value + ""
    if (el.name !== "contract_type") sortedMetadata.push(el)
  })

  return sortedMetadata
})

const sortedProposedClauses = computed(() => {
  const sortedClauses: ProposedMetadataChange[] = []

  // Return only clauses that were found
  proposedClauses.value?.forEach((el) => {
    if (el.value) sortedClauses.push(el)
  })

  return sortedClauses
})

const applyChangesDisabledMessage = computed<string>(() => {
  if (isLockedDocument.value) return t("aiAnalysis.lockedDocument")
  if (hasNoProposedChanges.value) return t("aiAnalysis.noChangesDetected")
  if (checkAllIfDisabledByPusher.value) return t("aiAnalysis.disabledByPusher")
  return ""
})

// Constructor for changes on selected metadata / date metadata

watch(selectedMetadata, () => {
  if (!selectedMetadata.value?.uuid) return
  addMetadataValue(selectedMetadata.value)
})

watch(selectedDateMetadata, () => {
  if (!selectedDateMetadata.value?.uuid) return
  addDateMetadataValue(selectedDateMetadata.value)
})

const uuidOfIsActiveBoundingBox = ref<Metadata["uuid"] | string>(null)
const uuidOfIsActiveMetadata = ref<Metadata["uuid"]>(null)

const boundingBoxes = computed<{ key: Metadata["name"], boundingBox: BoundingBox }[]>(() => {
  if (!proposedChanges.value?.schema_metadata && !proposedChanges.value?.contract_duration) return []
  const filteredMetadataWithBoundingBoxes = (proposedChanges.value?.schema_metadata?.filter((el) => el.bounding_box) || []).concat((proposedChanges.value?.contract_duration?.filter((el) => el.bounding_box) || []))
  // For each bounding_box inside el.bounding_box, return an object with key and boundingBox
  const mappedBoxes = []
  for (const metadata of filteredMetadataWithBoundingBoxes) {
    for (const boundingBox of metadata.bounding_box) {
      mappedBoxes.push({
        key: metadata.name,
        boundingBox,
      })
    }
  }
  return mappedBoxes
})

const prosemirrorUuidBoundingBoxes = computed<{ key: Metadata["name"], prosemirrorUuid: string }[]>(() => {
  if (!proposedChanges.value?.schema_metadata) return []
  const filteredMetadataWithProsemirrorUuids = proposedChanges.value?.schema_metadata?.filter((el) => el.prosemirror_uuids).concat(proposedChanges.value?.contract_duration?.filter((el) => el.prosemirror_uuids))
  // For each prosemirror_uuids inside el.prosemirror_uuids, return an object with key and prosemirrorUuids
  const mappedUuids = []
  for (const metadata of filteredMetadataWithProsemirrorUuids) {
    for (const prosemirrorUuid of metadata.prosemirror_uuids) {
      mappedUuids.push({
        key: metadata.name,
        prosemirrorUuid: prosemirrorUuid,
      })
    }
  }
  return mappedUuids
})

const handleSelectBoundingBox = (entry) => {
  const uuid = entry.prosemirror_uuids?.length ? entry.prosemirror_uuids[0] : getMetadataByKey(entry.name)?.uuid
  uuidOfIsActiveBoundingBox.value = uuid
  uuidOfIsActiveMetadata.value = getMetadataByKey(entry.name)?.uuid
  const metadata = getMetadataByKey(entry.name)
  // Enrich entry with metadata
  entry = { ...entry, metadata: toRaw(metadata) }
  handleShowBoundingBox(entry, props.document, aiAnalysisPdfViewer.value, aiAnalysisPreviewScrollContainer.value, prosemirrorUuidBoundingBoxes.value, setUpDeSelectBoundingBoxOnClickOutSide)
}

const setUpDeSelectBoundingBoxOnClickOutSide = () => {
  onClickOutside(aiAnalysisPreviewScrollContainer.value, () => handleDeSelectBoundingBox())
}

const handleDeSelectBoundingBox = () => {
  uuidOfIsActiveBoundingBox.value = null
  uuidOfIsActiveMetadata.value = null
  handleHideBoundingBoxes(props.document, aiAnalysisPdfViewer.value)
}

const isAiFeatureLimitLocked = computed(() => accountUsage.value && planFeatures.value?.["ai-extraction"] !== 0 && accountUsage.value.aiAnalyses >= planFeatures.value?.["ai-extraction"])

const sanitizeResults = (proposedChangeEntry: ProposedMetadataChange[]): ProposedMetadataChange[] => {
  const groupedChanges = proposedChangeEntry.reduce((acc, change) => {
    // Check if the array already has the key
    if (!acc[change.name]) {
      acc[change.name] = [] // Create a new array if the key does not exist
    }
    acc[change.name].push(change) // Push the current change into the appropriate array
    return acc
  }, {})

  return Object.values(groupedChanges)
}

const getItem = (entry: Metadata["name"] | Metadata["name"][]) => {
  return Array.isArray(entry) ? entry[0] : entry
}
</script>

<template>
  <DialogModal
    :show="open"
    max-width="6xl"
    :padding="true"
    :show-footer="!isAiFeatureLimitLocked"
    :show-header="true"
    :show-close-button="true"
    :show-full-size-button="true"
    @close="close"
  >
    <template #title>
      <span>{{ $t('aiAnalysis.reviewHeadline') }}</span>
    </template>

    <template #content="contentProps">
      <div
        v-if="isLoading || isLoadingMetadata || isLoadingMetadataValues || isLoadingGetAnalysis || isDiscarding"
        class="w-full h-full lg:flex lg:space-x-3"
      >
        <div class="lg:w-[40%] hidden lg:flex gap-3 justify-evenly flex-col items-stretch">
          <LoadingPlaceholder
            v-for="n in 5"
            :key="'loading_' + n"
            class="relative inset-auto w-full h-10"
          />
        </div>
        <LoadingPlaceholder
          class="relative w-full grow"
        />
      </div>
      <div v-else-if="hasNoProposedChanges">
        <span class="text-sm text-gray-500">{{ $t('aiAnalysis.noChangesDetected') }}</span>
      </div>
      <div
        v-else-if="proposedChanges"
        id="aiAnalysisReviewModal"
        class="relative h-full -mx-6 -mb-4 text-sm bg-white border-t border-b border-gray-200 min-h-[70vh] lg:flex"
        :class="[isAiFeatureLimitLocked ? 'overflow-hidden rounded-b-lg' : '', contentProps.isActiveFullsize ? '' : 'lg:max-h-[75vh]']"
      >
        <OverlayScrollbar
          ref="leftScrollContainer"
          tag="div"
          class="lg:w-[40%] border-r border-r-gray-200 overflow-y-auto"
        >
          <div class="divide-y divide-gray-200">
            <div class="max-w-full py-3 pl-6 overflow-x-scroll sm:max-w-none sm:overflow-x-auto">
              <TabPills
                v-model:active-tab="activeProposedChangeTab"
                :tabs="proposedChangeTabs"
              />
            </div>

            <div
              v-show="activeProposedChangeTab.id === ProposedChangeTab.all"
            >
              <div
                class="flex items-center gap-2 px-6 pt-4 text-sm font-medium"
              >
                <SparklesIcon class="w-4 h-4 text-purple-500" /> {{ $t('aiAnalysis.general') }}
              </div>
              <div class="divide-y divide-gray-200 min-h-[1rem]">
                <div
                  v-if="proposedChanges.intro !== undefined"
                >
                  <div class="flex items-center justify-between gap-2 px-6 py-2 text-sm">
                    <label class="pt-2 pr-3 text-gray-500 shrink-0">
                      {{ $t('documentGeneralSettings.intro') }}:
                    </label>

                    <button
                      type="button"
                      class="flex items-center p-1 text-gray-400 btn-plain disabled:text-gray-300 hover:text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-200"
                      data-tippy-help
                      data-placement="top"
                      :disabled="!proposedChanges.intro"
                      :data-tippy-content="$t('aiAnalysis.resetTo', { value: $t('common.empty') })"
                      @click="resetField('intro')"
                    >
                      <ArrowUturnLeftIcon
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                    </button>
                  </div>

                  <div
                    v-if="proposedChanges.intro !== undefined"
                    class="px-3 mb-3"
                  >
                    <div
                      class="grow-textarea"
                      :data-replicated-value="proposedChanges.intro"
                    >
                      <textarea
                        :value="proposedChanges.intro"
                        class="input-plain"
                        :placeholder="$t('common.add')+'…'"
                        @change="($event) => updateIntro(($event.target as HTMLTextAreaElement).value)"
                      />
                    </div>
                  </div>
                  <div
                    v-else
                    class="px-6 pb-6 text-gray-300"
                  >
                    {{ $t('common.empty') }}
                  </div>
                </div>
                <div v-if="proposedChanges.document_language !== undefined">
                  <div class="flex items-center justify-between gap-2 px-6 py-2 text-sm">
                    <label class="py-2 pr-3 text-gray-500 shrink-0">
                      {{ $t('aiAnalysis.documentLanguage') }}:
                    </label>
                    <div class="flex items-center justify-end gap-3 grow">
                      <LocaleSelector
                        :model-value="proposedChanges.document_language"
                        :disabled="checkAllIfDisabledByPusher"
                        @update:model-value="updateDocumentLanguage($event)"
                      />
                      <button
                        type="button"
                        class="flex items-center p-1 text-gray-400 btn-plain hover:bg-gray-10 disabled:text-gray-300 hover:text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-200"
                        data-tippy-help
                        data-placement="top"
                        :data-tippy-content="$t('aiAnalysis.resetTo', { value: $t(`common.empty`) })"
                        @click="resetField('document_language', null)"
                      >
                        <ArrowUturnLeftIcon
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="proposedChanges.contract_jurisdiction !== undefined">
                  <div class="flex items-center justify-between gap-2 px-6 py-2 text-sm">
                    <label class="py-2 pr-3 text-gray-500 shrink-0">
                      {{ $t('aiAnalysis.jurisdiction') }}:
                    </label>
                    <div class="flex items-center justify-end gap-3 grow">
                      <AutogrowInput
                        v-model="proposedChanges.contract_jurisdiction"
                        :disabled="checkAllIfDisabledByPusher"
                        :placeholder="$t('common.add')+'…'"
                      />
                      <button
                        type="button"
                        class="flex items-center p-1 text-gray-400 btn-plain hover:bg-gray-10 disabled:text-gray-300 hover:text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-200"
                        data-tippy-help
                        data-placement="top"
                        :disabled="(proposedChanges.document_type as DocumentType) === DocumentType.other"
                        :data-tippy-content="$t('aiAnalysis.resetTo', { value: $t(`common.empty`) })"
                        @click="resetField('document_type', DocumentType.other)"
                      >
                        <ArrowUturnLeftIcon
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="proposedChanges.parties"
              v-show="activeProposedChangeTab.id === ProposedChangeTab.parties || activeProposedChangeTab.id === ProposedChangeTab.all"
            >
              <div
                class="flex items-center gap-2 px-6 pt-4 text-sm font-medium"
              >
                <SparklesIcon class="w-4 h-4 text-purple-500" /> {{ $t('aiAnalysis.parties') }}
              </div>
              <div
                v-if="proposedChanges.parties?.length > 0"
                class="p-4 space-y-4"
              >
                <div
                  v-for="(party, partyIdx) in parties"
                  :key="partyIdx"
                >
                  <AiAnalysisPartyDisplay
                    :party="party"
                    :party-idx="partyIdx"
                    @update:party="($event) => updateParty($event, partyIdx)"
                    @remove-party="removeParty(partyIdx)"
                    @reset-party="resetParty(partyIdx)"
                  />
                </div>
              </div>

              <button
                type="button"
                :class="proposedChanges.parties?.length > 0 ? '' : 'mt-2'"
                class="ml-4 mb-4 btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
                @click.prevent="addParty"
              >
                <PlusIcon class="shrink-0 h-3.5 w-3.5" />
                {{ $t('userSettings.addParty') }}
              </button>
            </div>
            <div
              v-if="proposedDates?.length"
              v-show="activeProposedChangeTab.id === ProposedChangeTab.dates || activeProposedChangeTab.id === ProposedChangeTab.all"
              class="pb-4 border-b-0"
            >
              <div
                class="flex items-center gap-2 px-6 pt-4 text-sm font-medium"
              >
                <SparklesIcon class="w-4 h-4 text-purple-500" /> {{ $t('aiAnalysis.dates') }}
              </div>

              <dl
                class="divide-y divide-gray-200 min-h-[1rem]"
              >
                <AiAnalysisReviewSuggestion
                  v-for="entry in groupedProposedDates"
                  :key="getItem(entry).name"
                  :bounding-box="entry.bounding_box"
                  :item="getItem(entry)"
                  :metadata="getMetadataByKey(getItem(entry).name)"
                  :options="Array.isArray(entry) ? entry : []"
                  :uuid-of-is-active-metadata="uuidOfIsActiveMetadata"
                  :uuid-of-is-active-bounding-box="uuidOfIsActiveBoundingBox"
                  @select-bounding-box="handleSelectBoundingBox"
                  @deselect-bounding-box="handleDeSelectBoundingBox"
                  @update-value="updateMetadataValue(getItem(entry).name, $event, 'contract_duration')"
                />
              </dl>
              <button
                type="button"
                class="mt-2 ml-4 btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
                @click.prevent="showAddDatePopover"
              >
                <PlusIcon class="shrink-0 h-3.5 w-3.5" />
                {{ $t('metadata.addDate') }}
              </button>
            </div>

            <div
              v-if="proposedMetadata?.length || proposedChanges.document_type !== undefined"
              v-show="activeProposedChangeTab.id === ProposedChangeTab.metadata || activeProposedChangeTab.id === ProposedChangeTab.all"
              class="pb-4 border-b-0"
            >
              <div
                class="flex items-center gap-2 px-6 pt-4 text-sm font-medium"
              >
                <SparklesIcon class="w-4 h-4 text-purple-500" /> {{ $t('aiAnalysis.metadata') }}
              </div>

              <dl
                class="divide-y divide-gray-200 min-h-[1rem]"
              >
                <div
                  v-if="proposedChanges.document_type !== undefined"
                >
                  <div class="flex items-center justify-between gap-2 px-6 py-2 text-sm">
                    <label class="py-2 pr-3 text-gray-500 shrink-0">
                      {{ $t('documents.create.formFields.documentType') }}:
                    </label>
                    <div class="flex items-center justify-end gap-3 grow">
                      <div class="relative flex items-center gap-2 text-gray-900 grow max-w-fit">
                        <component
                          :is="getDocumentTypeInfo((proposedChanges.document_type as DocumentType) || DocumentType.other)?.icon"
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                        <span
                          class="block font-medium truncate"
                        >
                          {{ proposedChanges.document_type ? $t(`documentTypes.${(proposedChanges.document_type as DocumentType)}.name`) : $t(`documentTypes.${DocumentType.other}.name`) }}
                        </span>
                      </div>
                      <button
                        type="button"
                        class="flex items-center p-1 text-gray-400 btn-plain hover:bg-gray-10 disabled:text-gray-300 hover:text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-200"
                        data-tippy-help
                        data-placement="top"
                        :disabled="(proposedChanges.document_type as DocumentType) === DocumentType.other"
                        :data-tippy-content="$t('aiAnalysis.resetTo', { value: $t(`common.empty`) })"
                        @click="resetField('document_type', DocumentType.other)"
                      >
                        <ArrowUturnLeftIcon
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="proposedChanges.contract_type !== undefined"
                >
                  <div class="flex items-center justify-between gap-2 px-6 py-2 text-sm">
                    <label class="py-2 pr-3 text-gray-500 shrink-0">
                      {{ $t('documentGeneralSettings.contractType') }}:
                    </label>
                    <div class="flex items-center justify-end gap-3 grow">
                      <div class="relative flex items-center gap-2 text-gray-900 grow max-w-fit">
                        <span
                          class="block font-medium truncate"
                        >
                          {{ $t(`contractTypes.${proposedChanges.contract_type}`) }}
                        </span>
                      </div>
                      <button
                        type="button"
                        class="flex items-center p-1 text-gray-400 btn-plain hover:bg-gray-10 disabled:text-gray-300 hover:text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-200"
                        data-tippy-help
                        data-placement="top"
                        :disabled="(proposedChanges.document_type as DocumentType) === DocumentType.other"
                        :data-tippy-content="$t('aiAnalysis.resetTo', { value: $t(`common.empty`) })"
                        @click="resetField('document_type', DocumentType.other)"
                      >
                        <ArrowUturnLeftIcon
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <AiAnalysisReviewSuggestion
                  v-for="entry in sortedProposedMetadata"
                  :key="getItem(entry).name"
                  :bounding-box="entry.bounding_box"
                  :item="getItem(entry)"
                  :metadata="getMetadataByKey(getItem(entry).name)"
                  :options="Array.isArray(entry) ? entry : []"
                  :uuid-of-is-active-metadata="uuidOfIsActiveMetadata"
                  :uuid-of-is-active-bounding-box="uuidOfIsActiveBoundingBox"
                  @select-bounding-box="handleSelectBoundingBox"
                  @deselect-bounding-box="handleDeSelectBoundingBox"
                  @update-value="updateMetadataValue(getItem(entry).name, $event)"
                />
              </dl>

              <div
                v-if="recommendedMetadataValuesResidual.length"
              >
                <div
                  class="flex items-center justify-between px-6 pt-6 shrink-0"
                >
                  <h3
                    class="flex items-center gap-1 mb-3 text-sm font-medium"
                  >
                    {{ $t('metadata.skippedLinked') }}:
                  </h3>
                </div>

                <dl
                  class="divide-y divide-gray-200"
                >
                  <template
                    v-for="recommendedMetadataValue in recommendedMetadataValuesResidual"
                    :key="recommendedMetadataValue.uuid"
                  >
                    <MetadataValueInput
                      :metadata-value="recommendedMetadataValue"
                      :is-extraction="true"
                      :disabled="true"
                    />
                  </template>
                </dl>
              </div>
              <button
                type="button"
                class="mt-2 ml-4 btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
                @click.prevent="showAddMetadataPopover"
              >
                <PlusIcon class="shrink-0 h-3.5 w-3.5" />
                {{ $t('metadata.addMetadata') }}
              </button>
            </div>

            <div
              v-if="sortedProposedClauses?.length"
              v-show="activeProposedChangeTab.id === ProposedChangeTab.clauses || activeProposedChangeTab.id === ProposedChangeTab.all"
              class="pb-4 border-b-0"
            >
              <div
                class="flex items-center gap-2 px-6 pt-4 text-sm font-medium"
              >
                <SparklesIcon class="w-4 h-4 text-purple-500" /> {{ $t('aiAnalysis.clauses') }}
              </div>

              <dl
                class="divide-y divide-gray-200 min-h-[1rem]"
              >
                <AiAnalysisReviewSuggestion
                  v-for="entry in sortedProposedClauses"
                  :key="getItem(entry).name"
                  :bounding-box="entry.bounding_box"
                  :item="getItem(entry)"
                  :metadata="getMetadataByKey(getItem(entry).name)"
                  :options="Array.isArray(entry) ? entry : []"
                  :uuid-of-is-active-metadata="uuidOfIsActiveMetadata"
                  :uuid-of-is-active-bounding-box="uuidOfIsActiveBoundingBox"
                  @select-bounding-box="handleSelectBoundingBox"
                  @deselect-bounding-box="handleDeSelectBoundingBox"
                  @update-value="updateMetadataValue(getItem(entry).name, $event)"
                />
              </dl>
            </div>
          </div>
        </OverlayScrollbar>
        <div class="absolute hidden lg:block inset-y-0 right-0 w-[60%] bg-gray-100">
          <OverlayScrollbar
            ref="aiAnalysisPreviewScrollContainer"
            tag="div"
            class="flex-1 h-full px-6 overflow-y-auto max-h-max"
          >
            <div class="sticky top-0 z-10 flex items-center justify-between px-3 pt-3 pb-3 -mx-3 -mb-6 space-x-5 bg-gray-100">
              <div class="text-xs font-medium text-gray-500 truncate">
                {{ props.document?.name }}
              </div>
              <div class="flex items-center -space-x-px shrink-0">
                <button
                  class="z-0 text-xs rounded-r-none btn-white btn-sm focus:z-10"
                  :class="!isVisibleSummary ? 'text-indigo-500 bg-indigo-50 hover:bg-indigo-50' : ''"
                  @click="isVisibleSummary = false"
                >
                  {{ $t('documents.mainDocument') }}
                </button>
                <button
                  class="z-0 flex items-center gap-1 text-xs rounded-l-none btn-white btn-sm focus:z-10"
                  :class="isVisibleSummary ? 'text-indigo-500 bg-indigo-50 hover:bg-indigo-50' : ''"
                  @click="isVisibleSummary = true"
                >
                  <SparklesIcon class="w-3 h-3" />
                  {{ $t('aiAnalysis.summary') }}
                </button>
              </div>
            </div>
            <div
              v-if="showSummary && isVisibleSummary"
              class="relative p-12 my-6 overflow-hidden whitespace-pre-wrap bg-white shadow-xl summary-container"
            >
              <div class="relative pl-6 border-l-2 border-purple-500 border-dotted">
                <span class="absolute flex items-center justify-center w-6 h-6 text-white bg-purple-500 rounded-full -left-3 -top-0.5">
                  <SparklesIcon class="w-4 h-4" />
                </span>
                <span class="inline-block mb-3 text-xs font-medium text-purple-500 uppercase">
                  {{ $t('aiAnalysis.summary') }}
                </span>
                <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
                <div
                  v-html="purifyHtml(showSummary)"
                />
                <!-- eslint-enable vue/no-v-html vue/html-self-closing -->
              </div>
            </div>
            <div
              v-else-if="pdfUrl"
              class="relative h-full"
            >
              <PdfViewer
                :id="'aiAnalysisPdfViewer'"
                :key="cyrb53(pdfUrl)"
                ref="aiAnalysisPdfViewer"
                :component-key="cyrb53(pdfUrl)"
                :src="pdfUrl"
                :bounding-boxes="boundingBoxes"
              />
            </div>
            <DocumentRenderedHtml
              v-else-if="htmlContent"
              :id="'aiReviewRenderedHtmlContainer'"
              :is-loading="isLoadingHtmlContent"
              :html-content="htmlContent"
              :zoom-level="75"
              wrapper-classes="shadow-xl"
            />
            <div
              v-else-if="isLoadingPdfUrl || isLoadingHtmlContent"
              class="flex items-center justify-center h-full min-h-full p-12"
            >
              <SpinLoader class="w-5 h-5" />
            </div>
          </OverlayScrollbar>
        </div>
        <SubscriptionUpgradeBox
          v-if="isAiFeatureLimitLocked"
          :text="$t('accountSettings.billing.featureRequiresUpgrade')"
          class="absolute inset-0"
        />
      </div>
    </template>

    <template
      v-if="!isAiFeatureLimitLocked"
      #footer
    >
      <div class="flex items-center justify-between gap-2">
        <button
          type="button"
          class="flex items-center gap-2 mr-4 text-gray-400 btn-plain hover:text-red-500 hover:bg-red-100 focus:ring-red-100"
          :disabled="isDiscarding || isLoading"
          @click.prevent="discardAnalysis"
        >
          <SpinLoader
            v-if="isDiscarding"
            class="w-5 h-5 shrink-0"
          />
          <TrashIcon
            v-else
            aria-hidden="true"
            class="w-4 h-4 shrink-0"
          />
          {{ $t('aiAnalysis.discard') }}
        </button>

        <div class="flex items-center gap-4">
          <button
            type="button"
            class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
            @click.prevent="close"
          >
            {{ $t('common.close') }}
          </button>
          <button
            :disabled="isSubmitting || isLoadingSaveValues || hasNoProposedChanges || checkAllIfDisabledByPusher || isLockedDocument || isDiscarding"
            type="button"
            class="flex items-center gap-2 btn-primary"
            data-tippy-help
            :data-tippy-content="applyChangesDisabledMessage"
            data-placement="top"
            @click.prevent="handleSubmit"
          >
            <SpinLoader
              v-if="isSubmitting || isLoadingSaveValues"
              class="w-5 h-5 shrink-0"
            />
            <span v-if="isSubmitting || isLoadingSaveValues">{{ $t('common.pleaseWait') }}…</span>
            <span v-else>{{ $t('common.confirmResults') }}</span>
          </button>
        </div>
      </div>
      <div class="hidden">
        <div ref="addMetadataValueToAiAnalysisPopoverRef">
          <MetadataPopover
            v-model:selected-metadata="selectedMetadata"
            :attachable-metadata="attachableMetadata"
            :is-loading-metadata="isLoadingMetadata"
            :dropdown-position="sortedProposedMetadata.length > 1 ? 'top' : 'bottom'"
            :is-loading="isSubmittingAddMetadata"
          />
        </div>
        <div ref="addDateMetadataValueToAiAnalysisPopoverRef">
          <MetadataPopover
            v-model:selected-metadata="selectedDateMetadata"
            :attachable-metadata="attachableDateMetadata"
            :is-loading-metadata="isLoadingMetadata"
            :dropdown-position="proposedDates.length > 1 ? 'top' : 'bottom'"
            :is-loading="isSubmittingAddDateMetadata"
          />
        </div>
      </div>
    </template>
  </DialogModal>
</template>
