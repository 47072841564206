<script setup lang="ts">
import { computed } from "vue"

interface Props {
  checked: boolean | any[]
  value?: any
  elementId?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    checked: false,
    value: null,
    elementId: "",
  },
)

const emit = defineEmits([ "update:checked" ])

const proxyChecked = computed({
  get () {
    return props.checked
  },

  set (val) {
    emit("update:checked", val)
  },
})
</script>

<template>
  <input
    :id="elementId"
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    class="w-4 h-4 mx-auto rounded input-radio-primary"
  >
</template>
