<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch } from "vue"
import { ArrowDownIcon, InformationCircleIcon } from "@heroicons/vue/24/outline"
import { toTypedSchema } from "@vee-validate/zod"
import { useField, useForm } from "vee-validate"
import { z } from "zod"
import { LinkIcon } from "@heroicons/vue/20/solid"
import { EyeIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"

// internal
import { DurationInput, DynamicFieldInputElement, FormInputErrors, LoadingPlaceholder, SpinLoader } from "~/components"
import { useDynamicFieldStore, useDocumentStore, useAccountStore, useSharedStore, useTemplateStore } from "~/stores"
import { CrudContext, Document, DynamicField, DynamicFieldAutofillType, MultiFieldType } from "~/types"
import { convertCurrencyString, formatDateSimple, formatMultifieldNumber, getDefaultMultiFieldFormat, validateMultifieldValue } from "~/utils"
import { onClickOutside } from "@vueuse/core"

interface Props {
  dynamicField: DynamicField
  disabled?: boolean
  loading?: boolean
  isOnlyDisplayValue?: boolean
  pendingDynamicFields?: DynamicField[]
  isLoadingDynamicFields?: boolean
  isPopover?: boolean
  isExternal?: boolean
  isWizard?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    disabled: false,
    loading: false,
    pendingDynamicFields: () => [],
    isLoadingDynamicFields: false,
    isOnlyDisplayValue: false,
    isPopover: false,
    isExternal: false,
    isWizard: false,
  },
)

defineEmits( [ "focus", "blur" ])

const { t } = useI18n()

const accountStore = useAccountStore()
const { account } = storeToRefs(accountStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const documentStore = useDocumentStore()
const { currentDocument, mau } = storeToRefs(documentStore)

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const dynamicFieldStore = useDynamicFieldStore()
const { buttonPopover, dynamicFieldErrorsMap, uuidsOfUpdatingDynamicField, dynamicFieldLastSavedMap, uuidOfLastHighlightedDynamicField } = storeToRefs(dynamicFieldStore)
const { jumpToDynamicField, countOfIsValueUsedInDocument }  = dynamicFieldStore

const hideTippy = (event: PointerEvent) => {
  let target = event?.target as HTMLElement
  if (!target) target = document.getElementById("dynamicFieldPopoverForm_" + props.dynamicField.uuid)
  if (!target) return
  const popper = target.closest("[data-tippy-root") as any
  popper?._tippy?.hide()
}

const handleJumpToDynamicField = (dynamicFieldRefUuid: DynamicField["ref_uuid"], e, triggerClick = true) => {
  if (!props.isPopover) {
    buttonPopover.value?.[0]?.hide()
  } else {
    hideTippy(e)
  }
  jumpToDynamicField(
    dynamicFieldRefUuid,
    triggerClick,
  )
}

const formErrors = computed(() => dynamicFieldErrorsMap.value[localDynamicField.value.uuid])
// const lastSaved = computed<number | undefined>(() => dynamicFieldLastSavedMap.value[localDynamicField.value?.uuid])
const isLoadingDynamicField = computed<boolean>(() => uuidsOfUpdatingDynamicField.value.includes(localDynamicField.value?.uuid))

const entityUuid = computed<Document["uuid"]>(() => {
  return currentDocument.value?.uuid
})

const localDynamicField = computed<Partial<DynamicField>>({
  get: () => {
    return props.dynamicField
  },
  set: (val) => {
    setTimeout(
      async () => {
        const isValid = await validate(
          {
            mode: "validated-only",
          },
        )
        if (!isValid.valid) return
        if (props.dynamicField?.uuid) {
          dynamicFieldStore.updateLocalDynamicField(val, CrudContext.document, entityUuid.value)
          return
        }
      },
    )
  },
})

const updateFieldValue = (fieldName: keyof DynamicField, val: any) => {
  if (fieldName === "value" && localDynamicField.value?.value === val) return
  setFieldValue(fieldName as any, val)

  localDynamicField.value = {
    ...localDynamicField.value || {},
    [fieldName]: val,
  }
}

const handleSetAutofillValue = (val: DynamicFieldAutofillType) => {
  setFieldValue("value", null)
  setFieldValue("autofill_type", val)
  localDynamicField.value = {
    ...localDynamicField.value || {},
    value: null,
    autofill_type: val,
  }
}

const locale = computed(() => {
  const entityLocale = crudContext.value === CrudContext.document ? currentDocument.value?.locale : currentTemplate.value?.locale
  const localeToReturn = entityLocale ? entityLocale : (mau.value?.user?.locale || account.value?.locale)
  return localeToReturn
})

const multiFieldFormat = computed(
  () => {
    const dynamicFieldFormat = props.dynamicField?.format

    const formatToReturn = {
      decimals: dynamicFieldFormat?.decimals ? dynamicFieldFormat?.decimals : ([ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(props.dynamicField?.type)) ? "2" : "0",
      decimalSeparator: dynamicFieldFormat?.decimalSeparator || (locale.value === "de" ? "," : "."),
      thousandsSeparator: dynamicFieldFormat?.thousandsSeparator || (locale.value === "de" ? "." : ","),
    }

    return formatToReturn
  },
)

const defaultFormat = computed(() => getDefaultMultiFieldFormat(localDynamicField.value?.type, locale.value))

const dynamicFieldSchema = z
  .object(
    {
      value: z.any().nullable().optional(),
      autofill_type: z.nativeEnum(DynamicFieldAutofillType).nullable().optional(),
    },
  ).refine( (input) => {
    // Validate value based on type
    if (!validateMultifieldValue(props.dynamicField.type, input.value, multiFieldFormat.value || defaultFormat.value)) return false
    return true
  }, (input) => {
    if ( !validateMultifieldValue(props.dynamicField.type, input.value, multiFieldFormat.value || defaultFormat.value)) {
      return {
        message: t(`dynamicFields.errors.wrongFormat.${props.dynamicField.type}`),
        path: [ "value" ],
      }
    }
  })

 type DynamicFieldValidatedType = z.infer<typeof dynamicFieldSchema>

const dynamicFieldValidator = toTypedSchema(dynamicFieldSchema)

const { errors, setValues, setFieldValue, validate } = useForm<DynamicFieldValidatedType>(
  {
    validationSchema: dynamicFieldValidator,
  },
)
const formValidationErrors = ref<Partial<Record<keyof DynamicField, string[]>>>({})
const backendErrors = computed(() => dynamicFieldErrorsMap.value[localDynamicField.value.uuid])

watch(
  () => localDynamicField.value,
  (newVal) => {
    if (!newVal) return
    if (localDynamicField.value?.uuid) setValues(newVal)
  },
  {
    deep: true,
    immediate: true,
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof DynamicField, string[]>>,
      )
  },
)

const value = useField<any>("value")
useField<DynamicFieldAutofillType>("autofill_type")

const errorsToShow = computed<Partial<Record<keyof DynamicField, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DynamicField, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const hideWithTimeout = (e) => {
  if (props.pendingDynamicFields?.length) return
  setTimeout(() => {
    if (!props.isPopover) {
      buttonPopover.value?.[0]?.hide()
    } else {
      hideTippy(e)
    }
  })
}

const handleEnter = (e: KeyboardEvent) => {
  if (props.pendingDynamicFields?.length) { handleJumpToDynamicField(props.pendingDynamicFields[0].ref_uuid, e, true) }
  else { hideWithTimeout(e) }
}

const removeHighlight = (e: PointerEvent) => {
  if (uuidOfLastHighlightedDynamicField.value !== localDynamicField.value?.ref_uuid) return
  if (props.isPopover) return
  const popover = document.getElementById(`dynamicFieldPopoverForm_` + props.dynamicField.ref_uuid)
  const nodes = document.querySelectorAll(`[data-dynamic-field-node="${props.dynamicField.ref_uuid}"]`)
  const nodeArray = Array.from(nodes)
  const allElements = [ ...nodeArray, popover ]
  const isInside = allElements.some((element) => element?.contains(e.target as Node) || element === e.target)
  if (isInside) return
  jumpToDynamicField(null)
}

const dynamicFieldInputWrapper = ref(null)

onClickOutside(dynamicFieldInputWrapper, removeHighlight)

</script>

<template>
  <div
    :id="`dynamicField${ !isPopover ? 'Input' : 'Popover' }Form_` + dynamicField.ref_uuid"
    ref="dynamicFieldInputWrapper"
    v-cy="isPopover ? `dynamic-field-input-popover` : `dynamic-field-input-form`"
    :class="[
      `dynamic-field_${ !isPopover ? 'input' : 'popover' }_wrapper_` + dynamicField.ref_uuid,
      !isPopover ? 'px-6 dynamic-field_input_wrapper' : 'popover popover-primary bg-white min-w-0 max-w-[95vw] lg:max-w-md'
    ]"
    class="relative flex flex-col transition-colors"
    @click.prevent="!isPopover && !isWizard ? handleJumpToDynamicField(localDynamicField.ref_uuid, $event, false) : null"
  >
    <LoadingPlaceholder
      v-if="loading"
    />
    <div class="relative pb-2 pr-10">
      <p
        v-if="!isPopover"
        class="pt-2 m-0 pb-0.5 text-sm font-medium overflow-hyphens"
        :class="isExternal ? '' : 'text-indigo-800'"
      >
        {{ dynamicField.name }}
        <span
          v-if="dynamicField.is_mandatory && dynamicField.scope === 'internal'"
          class="text-indigo-500"
        >
          *
        </span>
      </p>

      <label
        v-if="!isOnlyDisplayValue && !(!isPopover && !isExternal && !localDynamicField.question)"
        :id="`description_dynamicField_${ !isPopover ? 'input' : 'popover' }_` + dynamicField.uuid"
        :for="`dynamic-field_${ !isPopover ? 'input' : 'popover' }_` + dynamicField.uuid"
        :class="[!isPopover ? 'text-xs text-gray-400' : 'pl-3 pt-2 text-sm font-medium text-gray-700', 'block relative overflow-hyphens']"
      >
        {{ isExternal && localDynamicField.question_external ? localDynamicField.question_external : localDynamicField.question || localDynamicField.name }}
      </label>
      <slot name="loader">
        <span
          v-if="isLoadingDynamicField"
          class="absolute inset-y-0 flex items-center pointer-events-none right-2"
        >
          <SpinLoader class="w-4 h-4 text-indigo-700" />
        </span>
      </slot>
    </div>
    <div
      v-if="!isOnlyDisplayValue"
      v-cy="`dynamic-field-input-holder`"
      :class="isPopover ? 'px-3 pb-3' : 'inline-flex'"
    >
      <DynamicFieldInputElement
        :model-value="value.value.value"
        :dynamic-field-ref-uuid="localDynamicField.ref_uuid"
        :dynamic-field-type="localDynamicField.type"
        :dynamic-field-autofill-type="localDynamicField.autofill_type"
        :has-error="!!(formErrors?.value?.length)"
        :dynamic-field-last-saved-map="dynamicFieldLastSavedMap"
        :select-values="localDynamicField.select_values"
        :disabled="disabled"
        :settings="localDynamicField.settings"
        :format="localDynamicField.format"
        :is-popover="isPopover"
        :is-external="isExternal"
        @update:model-value="($event) => updateFieldValue('value', $event)"
        @set-autofill-value="($event) => handleSetAutofillValue($event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @enter="handleEnter"
      />
    </div>
    <div
      v-else
      class="text-sm"
      :class="!localDynamicField.value ? 'text-gray-400' : ''"
    >
      <span v-if="localDynamicField.type === MultiFieldType.date">
        {{ localDynamicField.value ? formatDateSimple(localDynamicField.value) : $t('common.undefined') }}
      </span>
      <span v-else-if="localDynamicField.type === MultiFieldType.bool">
        {{ localDynamicField.value ? localDynamicField.value === true ? $t('common.yes') : $t('common.no') : $t('common.undefined') }}
      </span>
      <span v-else-if="localDynamicField.type === MultiFieldType.duration">
        <DurationInput
          v-if="localDynamicField?.value"
          :model-value="localDynamicField?.value"
          :disabled="true"
          :readonly="true"
          :class="('dynamicField-input_' + localDynamicField?.uuid)"
          :style="'plain'"
        />
        <span v-else>n/a</span>
      </span>
      <span v-else-if="[ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(localDynamicField.type)">
        {{ localDynamicField.value ? convertCurrencyString(localDynamicField.value, localDynamicField.format, locale) : $t('common.undefined') }}
      </span>
      <span v-else-if="localDynamicField.type === MultiFieldType.number">
        {{ localDynamicField.value ? formatMultifieldNumber(localDynamicField.value, localDynamicField.format, MultiFieldType.number, locale) : $t('common.undefined') }}
      </span>
      <span
        v-else-if="localDynamicField.type === MultiFieldType.textarea"
        class="whitespace-pre-wrap"
      >{{ localDynamicField.value || $t('common.undefined') }}</span>
      <ul v-else-if="localDynamicField.type === MultiFieldType.list">
        <li
          v-for="(item, index) in localDynamicField.value.split('\n').filter((item: string) => item.trim() !== '')"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
      <span v-else>
        {{ localDynamicField.value || $t('common.undefined') }}
      </span>
    </div>
    <FormInputErrors
      v-if="errorsToShow?.value?.length"
      :class="props.isPopover ? 'p-4 pt-0' : 'pt-2'"
      :errors="errorsToShow?.value"
    />
    <FormInputErrors
      v-if="errorsToShow?.autofill_type?.length"
      :class="props.isPopover ? 'p-4 pt-0' : 'pt-2'"
      :errors="errorsToShow?.autofill_type"
    />
    <div
      v-if="!!mau && mau?.permissions?.includes('dynamic_field_manage') && errorsToShow?.value?.length && dynamicField.type === MultiFieldType.number"
      class="text-xs text-gray-500 max-w-fit"
    >
      <span class="font-medium">{{ $t('dynamicFields.errors.wrongFormat.pleaseNote') }}:</span>
      {{ $t('dynamicFields.errors.wrongFormat.numberHint') }}
    </div>

    <div
      v-if="!!mau && ((dynamicField.scope === 'internal_and_external' || countOfIsValueUsedInDocument(dynamicField.uuid)) && !isWizard && !isPopover)"
      class="flex items-center gap-2 mt-3.5"
    >
      <span
        v-if="dynamicField.scope === 'internal_and_external'"
        data-tippy-help
        data-placement="bottom"
        :data-tippy-content="$t('dynamicFields.externalScope')"
        class="inline-flex items-center gap-1 text-xs text-indigo-400"
      >
        <EyeIcon class="w-3.5 h-3.5" />
        {{ $t('dynamicFields.external') }}
      </span>
      <span
        v-if="countOfIsValueUsedInDocument(dynamicField.uuid) && !isWizard && !isPopover"
        class="inline-flex items-center gap-1 text-xs text-indigo-400"
        data-tippy-help
        :data-tippy-content="$t('dynamicFields.usedInDocument', countOfIsValueUsedInDocument(dynamicField.uuid))"
        data-placement="bottom"
      >
        <LinkIcon class="w-3.5 h-3.5" />
        {{ $t('dynamicFields.reference', countOfIsValueUsedInDocument(dynamicField.uuid)) }}
      </span>
    </div>
    <div
      v-if="isPopover && pendingDynamicFields.length"
      class="flex items-center border-t border-t-gray-200"
    >
      <button
        v-cy="`popover-show-next-dynamic-field-button`"
        type="button"
        :class="
          dynamicField.value
            ? 'bg-indigo-600 hover:bg-indigo-700 text-white focus:ring-offset-2 focus:ring-2 focus:ring-indigo-500'
            : 'bg-gray-100 text-indigo-700'
        "
        class="w-full flex items-center gap-1 px-2 pt-2.5 pb-2.5 border border-transparent shadow-sm justify-center rounded-b relative text-sm font-medium outline-none focus:outline-none"
        @click.prevent="
          handleJumpToDynamicField(pendingDynamicFields[0].ref_uuid, $event, true)
        "
      >
        <ArrowDownIcon
          v-if="dynamicField.value"
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <InformationCircleIcon
          v-else
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <span v-if="dynamicField.value">
          {{ $t('documentStatusBanner.showNext', {count: pendingDynamicFields.length}) }}
        </span>
        <span v-else>{{ $t('dynamicFields.fillOutPrompt') }}</span>
      </button>
    </div>
  </div>
</template>
