// external
import { Node, Range, mergeAttributes, wrappingInputRule } from "@tiptap/core"
import { Node as PMNode } from "@tiptap/pm/model"
import { NodeSelection, Plugin, PluginKey, Selection, Transaction } from "@tiptap/pm/state"
import { InputRuleMatch, VueNodeViewRenderer } from "@tiptap/vue-3"

// internal
import { getOrderRepresentation } from "./listHelpers"
import {
  ListKind,
  backspaceCommand,
  createDedentListCommand,
  createIndentListCommand,
  createMoveListCommand,
  createSplitListCommand,
  createWrapInListCommand,
  deleteCommand,
  parseInteger,
} from "./prosemirror-flat-list"

import ListNodeView from "./ListNodeView.vue"
import { useEditorStore, useSectionRefStore } from "~/stores"

export function isBlockNodeSelection (selection: Selection): boolean {
  return Boolean((selection as unknown as NodeSelection).node?.type?.isBlock)
}

export interface NumberingOptions {
  delimiterStyle: DelimiterStyle
  numberingStyle: NumberingStyle
  numberingType: NumberingType
  order: number
  orderRepresentation: string
  parentOrderRepresentation?: string
  parentDelimiterStyle?: DelimiterStyle
}

export type NumberingType = "continue" | "restart"

export type NumberingStyle = "decimal" | "lower-alpha" | "upper-alpha" | "lower-roman" | "upper-roman"

export type DelimiterStyle = "dot" | "parenthesis" | "double-parenthesis" | "section-mark"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    list: {
      splitList: () => ReturnType;
      indentList: () => ReturnType;
      dedentList: () => ReturnType;
      setNumberingStyle: (style: NumberingStyle) => ReturnType;
      setDelimiterStyle: (style: DelimiterStyle) => ReturnType;
      setNumberingType: (type: NumberingType) => ReturnType;
      moveList: (direction: "up" | "down") => ReturnType;
      wrapInFlatList: (kind: ListKind) => ReturnType;
      toggleFlatList: (kind: ListKind) => ReturnType;
    };
  }
}

export const getAdjustedTrForLists = (tr: Transaction, matchWithHost = false, isHost = false) => {
  let lastZeroDepthOrder = 0

  const uuidsNestedOrderRepresentationMap: Record<string, string> = {}

  tr.doc.descendants(
    (node, pos) => {
      if (node.type.name !== "list") return
      if (node.attrs.kind === "bullet") return

      const { numberingType } = node.attrs

      if (matchWithHost) {
        const uuid = node.attrs.uuid
        const nestedOrderRepresentation = useEditorStore().idsNestedOrderRepresentationMap[uuid]
        uuidsNestedOrderRepresentationMap[uuid] = nestedOrderRepresentation

        tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            orderRepresentation: nestedOrderRepresentation,
          },
        )
        return
      }

      let orderRepresentation = ""
      let nestedOrderRepresentation = ""

      const parent = tr.doc.resolve(pos).parent

      if (parent.type.name === "doc") {
        const newOrder = numberingType === "continue"
          ? (lastZeroDepthOrder || 0) + 1
          : 1

        lastZeroDepthOrder = newOrder

        const getOrderRepresentationParams: Parameters<typeof getOrderRepresentation>[0] = {
          order: newOrder,
          numberingStyle: node.attrs.numberingStyle,
          delimiterStyle: node.attrs.delimiterStyle,
        }

        orderRepresentation = getOrderRepresentation(getOrderRepresentationParams)
        nestedOrderRepresentation = orderRepresentation
        uuidsNestedOrderRepresentationMap[node.attrs.uuid] = nestedOrderRepresentation

        const newAttrs = {
          order: newOrder,
          orderRepresentation,
        }

        if (
          node.attrs.order === newAttrs.order
          && node.attrs.orderRepresentation === newAttrs.orderRepresentation
        ) return

        if (!isHost) tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            ...newAttrs,
          },
        )
      } else {
        interface NodeData {
          node: PMNode;
          offset: number;
          index: number;
        }

        const listNodes: NodeData[] = []

        parent.forEach(
          (node, offset, index) => {
            if (node.type.name !== "list") return
            if (node.attrs.kind === "bullet") return

            listNodes.push({ node, offset, index })
          },
        )

        const childIndex = listNodes.findIndex((listNode) => listNode.node.attrs.uuid === node.attrs.uuid)

        const listNodeBefore = listNodes[childIndex - 1]

        const newOrder = numberingType === "continue" && listNodeBefore
          ? listNodeBefore.node.attrs.order + 1
          : 1

        const parentOrderRepresentation = parent.attrs.orderRepresentation
        const parenteNestedOrderRepresentation = uuidsNestedOrderRepresentationMap[parent.attrs.uuid]
        const parentDelimiterStyle = parent.attrs.delimiterStyle

        const getOrderRepresentationParams: Parameters<typeof getOrderRepresentation>[0] = {
          order: newOrder,
          numberingStyle: node.attrs.numberingStyle,
          delimiterStyle: node.attrs.delimiterStyle,
          parentOrderRepresentation,
          parentDelimiterStyle,
          showNestedOrderRepresentation: node.attrs.showNestedOrderRepresentation,
        }

        orderRepresentation = getOrderRepresentation(getOrderRepresentationParams)
        nestedOrderRepresentation = getOrderRepresentation(
          {
            ...getOrderRepresentationParams,
            showNestedOrderRepresentation: true,
            parentOrderRepresentation: parenteNestedOrderRepresentation || parentOrderRepresentation,
          },
        )
        uuidsNestedOrderRepresentationMap[node.attrs.uuid] = nestedOrderRepresentation

        const newAttrs = {
          order: newOrder,
          orderRepresentation,
        }

        if (
          node.attrs.order === newAttrs.order
          && node.attrs.orderRepresentation === newAttrs.orderRepresentation
        ) return

        if (!isHost) tr.setNodeMarkup(
          pos,
          null,
          {
            ...node.attrs,
            ...newAttrs,
          },
        )
      }
    },
  )

  return {
    tr,
    uuidsNestedOrderRepresentationMap,
  }
}

export const List = Node.create(
  {
    name: "list",

    group: "flatList block",

    content: "block+",

    defining: true,

    selectable: false,

    addAttributes () {
      return {
        kind: {
          default: "bullet", // "bullet" | "ordered"
          keepOnSplit: true,
          renderHTML (attributes) {
            return {
              "data-list-kind": attributes.kind,
            }
          },
        },
        numberingType: {
          default: "continue", // "restart" | "continue"
          keepOnSplit: false,
          renderHTML (attributes) {
            return {
              "data-list-numbering-type": attributes.numberingType,
            }
          },
        },
        numberingStyle: {
          default: "decimal", // 'decimal' | 'lower-alpha' | 'upper-alpha' | 'lower-roman' | 'upper-roman'
          keepOnSplit: true,
          renderHTML (attributes) {
            return {
              "data-list-numbering-style": attributes.numberingStyle,
            }
          },
        },
        delimiterStyle: {
          default: "dot", // 'dot' | 'parenthesis' | 'double-parenthesis' | 'section-mark'
          keepOnSplit: true,
          renderHTML (attributes) {
            return {
              "data-list-delimiter-style": attributes.delimiterStyle,
            }
          },
        },
        order: {
          default: null,
          keepOnSplit: false,
          renderHTML (attributes) {
            return {
              "data-list-order": attributes.order,
            }
          },
        },
        orderRepresentation: {
          default: "",
          keepOnSplit: false,
          renderHTML (attributes) {
            return {
              "data-list-order-representation": attributes.orderRepresentation,
            }
          },
        },
        showNestedOrderRepresentation: {
          default: true,
          keepOnSplit: true,
          renderHTML (attributes) {
            return {
              "data-list-show-nested-order-representation": `${attributes.showNestedOrderRepresentation}`,
            }
          },
        },
      }
    },

    addOptions () {
      return {
        isOrderRepresentationHost: false,
      }
    },

    parseHTML () {
      return [
        {
          tag: "div[data-list-kind]",
          getAttrs: (element) => {
            if (typeof element === "string") return {}

            return {
              kind: (element.getAttribute("data-list-kind") || "bullet"),
              numberingType: (element.getAttribute("data-list-numbering-type") || "continue"),
              numberingStyle: (element.getAttribute("data-list-numbering-style") || "decimal"),
              delimiterStyle: (element.getAttribute("data-list-delimiter-style") || "dot"),
              order: parseInt(element.getAttribute("data-list-order")) || null,
              orderRepresentation: (element.getAttribute("data-list-order-representation") || ""),
              showNestedOrderRepresentation: (element.getAttribute("data-list-show-nested-order-representation") || "") === "true",
            }
          },
        },
        {
          tag: "ul > li",
          getAttrs: () => {
            return {
              kind: "bullet",
            }
          },
        },
        {
          tag: "ol > li",
          getAttrs: (element) => {
            if (typeof element === "string") {
              return {
                kind: "ordered",
              }
            }

            return {
              kind: "ordered",
              order: parseInteger(element.getAttribute("data-list-order")),
            }
          },
        },
      ]
    },

    renderHTML ({ HTMLAttributes }) {
      const mergedAttrs = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)

      delete mergedAttrs["data-uuid"]

      return [
        "div",
        mergedAttrs,
        0,
      ]
    },

    onCreate () {
      const sectionRefStore = useSectionRefStore()

      const isProposal = this.editor.storage.doc.editorContext === "proposal"
      const isOrderRepresentationHost = this.options.isOrderRepresentationHost
      const matchWithHost = isProposal && !isOrderRepresentationHost

      const { uuidsNestedOrderRepresentationMap, tr } = getAdjustedTrForLists(this.editor.state.tr, matchWithHost, isOrderRepresentationHost)

      if (isOrderRepresentationHost) {
        useEditorStore().setIdsNestedOrderRepresentationMap(uuidsNestedOrderRepresentationMap)
      }

      this.editor.view.dispatch(tr)

      sectionRefStore.setUuidsNestedOrderRepresentationMap(uuidsNestedOrderRepresentationMap)

      sectionRefStore.extractSectionsFromEditor(this.editor)
    },

    addNodeView () {
      return VueNodeViewRenderer(ListNodeView)
    },

    addInputRules () {
      const bulletRegexp = /^\s?([*+-])\s$/
      const orderedRegexp = /^\s?(\d+\.|§?\s?\d+)\s$/

      const editor = this.editor

      return [
        // for bullet list
        wrappingInputRule(
          {
            find: (text) => {
              if (!text.match(bulletRegexp)) return null

              const { $from } = editor.state.selection

              const parentsParent = $from.node($from.depth - 1)

              if (parentsParent.type.name === "list") {
                editor
                  .chain()
                  .wrapInFlatList("bullet")
                  .run()

                setTimeout(
                  () => {
                    const fromNew = editor.state.selection.from

                    editor
                      .chain()
                      .deleteRange(
                        {
                          from: fromNew - 1,
                          to: fromNew + 1,
                        },
                      )
                      .focus(fromNew - 1)
                      .run()
                  },
                )

                return null
              }

              const result: InputRuleMatch = {
                index: text.search(bulletRegexp),
                text: text,
                match: text.match(bulletRegexp),
              }

              return result
            },
            type: this.type,
            getAttributes: () => ({ kind: "bullet" }),
          },
        ),

        // for ordered list
        wrappingInputRule(
          {
            find: (text) => {
              const match = text.match(orderedRegexp)

              if (!match) return null

              const { $from } = editor.state.selection

              const parentsParent = $from.node($from.depth - 1)

              if (parentsParent.type.name === "list") {
                editor
                  .chain()
                  .wrapInFlatList("ordered")
                  .run()

                setTimeout(
                  () => {
                    const fromNew = editor.state.selection.from

                    editor
                      .chain()
                      .deleteRange(
                        {
                          from: fromNew - 3,
                          to: fromNew + 1,
                        },
                      )
                      .focus(fromNew - 3)
                      .run()
                  },
                )

                return null
              }

              const result: InputRuleMatch = {
                index: text.search(orderedRegexp),
                text: text,
                match: text.match(orderedRegexp),
              }

              return result
            },
            type: this.type,
            getAttributes: (match) => {
              const order = parseInteger(match[0].match(/\d+/)[0])

              return {
                kind: "ordered",
                ...(order !== null && { order }),
                ...(match[0].startsWith("§") && { delimiterStyle: "section-mark" }),
              }
            },
          },
        ),
      ]
    },

    addCommands () {
      const splitListCommand = createSplitListCommand()

      return {
        splitList: () => ({ state, dispatch, view }) => {
          return splitListCommand(state, dispatch, view)
        },
        indentList: () => ({ state, dispatch, view }) => {
          const { from, to } = state.selection

          return createIndentListCommand({ from, to })(state, dispatch, view)
        },
        dedentList: () => ({ state, dispatch, view }) => {
          const { from, to } = state.selection

          return createDedentListCommand({ from, to })(state, dispatch, view)
        },
        setNumberingStyle: (style) => ({ chain }) => {
          return chain().updateAttributes("list", { numberingStyle: style }).run()
        },
        setDelimiterStyle: (style) => ({ chain }) => {
          return chain().updateAttributes("list", { delimiterStyle: style }).run()
        },
        setNumberingType: (type) => ({ chain }) => {
          return chain().updateAttributes("list", { numberingType: type }).run()
        },
        moveList: (direction) => ({ state, dispatch, view }) => {
          const moveListCommand = createMoveListCommand(direction)

          return moveListCommand(state, dispatch, view)
        },
        wrapInFlatList: (kind) => ({ state, view, dispatch }) => {
          const wrapInListCommand = createWrapInListCommand({ kind })

          return wrapInListCommand(state, dispatch, view)
        },
        toggleFlatList: (kind) => ({ chain, editor, state }) => {
          const isListActive = editor.isActive("list")
          const isListOfSameKind = editor.getAttributes("list").kind === kind

          const { $from, $to } = state.selection

          const start = $from.start($from.depth)
          const end = $to.end($to.depth)

          let listNodeWithParentListNodesFirstChildNotListNode = null

          for (let i = $from.depth; i > 0; i -= 1) {
            const node = $from.node(i)

            if (node.type.name !== "list") continue

            const firstChild = node.firstChild

            if (firstChild.type.name !== "list") continue

            listNodeWithParentListNodesFirstChildNotListNode = {
              node: $from.node(i + 1),
              depth: i + 1,
              from: $from.start(i + 1),
              to: $from.end(i + 1),
            }
          }

          let jsonContent = state.doc.content.cut(start - 1, end).toJSON()

          if (!jsonContent) return false

          if (isListActive) {
            if (isListOfSameKind) {
              let unlistedJsonContent = []

              jsonContent.forEach(
                (item) => {
                  if (item.type !== "list") return item

                  unlistedJsonContent = [ ...unlistedJsonContent, ...(item.content || []) ]
                },
              )

              jsonContent = unlistedJsonContent

              const isParentList = $from.parent.type.name === "list"

              const offset = isParentList ? 1 : 2

              const range: Range = listNodeWithParentListNodesFirstChildNotListNode
                ? {
                  from: listNodeWithParentListNodesFirstChildNotListNode.from - offset,
                  to: listNodeWithParentListNodesFirstChildNotListNode.to,
                } : {
                  from: start - offset,
                  to: end,
                }

              return chain()
                .insertContentAt(
                  range,
                  jsonContent,
                )
                .setTextSelection(
                  {
                    from: start - offset,
                    to: end - offset,
                  },
                )
                .run()
            }

            return chain()
              .updateAttributes("list", { kind })
              .run()
          } else {
            return chain()
              .wrapInFlatList(kind)
              .run()
          }
        },
      }
    },

    addKeyboardShortcuts () {
      return {
        "Enter": () => this.editor.commands.splitList(),

        "Tab": () => this.editor.commands.indentList(),
        "Mod-]": () => this.editor.commands.indentList(),

        "Shift-Tab": () => this.editor.commands.dedentList(),
        "Mod-[": () => this.editor.commands.dedentList(),

        "Delete": ({ editor }) => {
          const { state, view: { dispatch } } = editor

          return deleteCommand(state, dispatch)
        },

        "Backspace": ({ editor }) => {
          const { state, view: { dispatch } } = editor

          return backspaceCommand(state, dispatch)
        },

        "Alt-ArrowUp": () => this.editor.commands.moveList("up"),
        "Alt-ArrowDown": () => this.editor.commands.moveList("down"),
      }
    },

    addProseMirrorPlugins () {
      const { setUuidsNestedOrderRepresentationMap, extractSectionsFromEditor } = useSectionRefStore()

      const editor = this.editor

      return [
        new Plugin(
          {
            key: new PluginKey("listPluginKey"),

            appendTransaction (transactions, oldState, newState) {
              const docChanges = transactions.some((transaction) => transaction.docChanged)
              && !oldState.doc.eq(newState.doc)

              if (!docChanges) return

              if (editor.storage.doc.editorContext === "proposal") return

              const tr = newState.tr

              const {
                uuidsNestedOrderRepresentationMap,
                tr: adjustedTr,
              } = getAdjustedTrForLists(tr)

              setUuidsNestedOrderRepresentationMap(uuidsNestedOrderRepresentationMap)

              extractSectionsFromEditor(editor)

              return adjustedTr
            },
          },
        ),
      ]
    },
  },
)
