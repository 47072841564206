<script setup lang="ts">
// external
import { ref, computed, watch } from "vue"
import { Form } from "vee-validate"
import { cloneDeep } from "lodash-es"

// internal
import { DialogModal, SpinLoader, DocumentNotificationRecipients, DocumentNotificationTrigger, DocumentNotificationTriggerTime, DocumentNotificationRecurringCondition, InfoBox } from "~/components"
import { CrudContext, Document, DocumentNotification, Template, DocumentNotificationType, Team } from "~/types"
import { useDocumentNotificationHelpers } from "~/composables"

interface Props {
  show: boolean
  notification?: DocumentNotification
  notificationSettings?: DocumentNotification[]
  entityUuid?: Document["uuid"] | Template["uuid"]
  context?: CrudContext
  loading?: boolean
  teams?: Team[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    notification: null,
    context: null,
    entityUuid: null,
    loading: false,
  },
)

const recipients = ref()

const emptyNotification:Partial<DocumentNotification> = Object.freeze({
  key: null,
  recipients: {
    document_user_uuids: null,
    exclude_document_user_uuids: null,
    roles: null,
    scope: {
      internal: true,
      external: true,
    },
    team_uuids: null,
  },
  trigger_time_date: null,
  trigger_time_condition: {
    interval: null,
    recurring: null,
    type: null,
  },
  is_deactivated: false,
})

const localNotification = ref({ ...emptyNotification })

const availableTriggers = computed(() => props.notificationSettings.map((setting) => setting.key))

const emits = defineEmits([ "update:show", "submit", "reset", "deactivate", "activate" ])

const submit = async () => {
  const isValid = recipients.value ? recipients.value.validate() : true
  if (isValid) {
    emits("submit", localNotification.value)
  }
}

watch(() => props.notification, (newVal) => {
  if (newVal) {
    localNotification.value = cloneDeep({
      ...emptyNotification,
      ...newVal,
    })
  } else {
    localNotification.value = cloneDeep({ ...emptyNotification })
  }
})

const { isInCurrentContext } = useDocumentNotificationHelpers(props.context)

</script>

<template>
  <DialogModal
    :show="show"
    max-width="2xl"
    :padding="false"
    :show-footer="true"
    :show-close-button="true"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ !!notification ? $t('documentNotifications.edit') : $t('documentNotifications.create') }}
      </div>
    </template>

    <template #content>
      <Form
        v-slot="{ errors }"
        as="div"
        class="pb-4"
      >
        <InfoBox
          v-if="!!localNotification.uuid && !isInCurrentContext(localNotification)"
          class="mx-6 mb-3"
        >
          <span>{{ $t('documentNotifications.inheritedFrom.' + localNotification.notification_settable_type) }}</span>
        </InfoBox>

        <DocumentNotificationTrigger
          v-model:notification-key="localNotification.key"
          :available-triggers="availableTriggers"
          :trigger-type="localNotification.type"
          :selectable="!notification"
          :has-error="!!errors.key"
        />

        <DocumentNotificationRecipients
          v-if="localNotification.type !== DocumentNotificationType.recurring"
          ref="recipients"
          v-model:recipients="localNotification.recipients"
          v-model:is-deactivated="localNotification.is_deactivated"
          :context="context"
          :teams="teams"
        />

        <DocumentNotificationTriggerTime
          v-if="localNotification.type === DocumentNotificationType.reminder"
          v-model:trigger-time="localNotification.trigger_time_date"
          v-model:trigger-time-condition="localNotification.trigger_time_condition"
        />
        <DocumentNotificationRecurringCondition
          v-else-if="localNotification.type === DocumentNotificationType.recurring"
          v-model:recurring-condition="localNotification.recurring_condition"
        />
      </Form>
    </template>

    <template #footer>
      <div class="gap-2 sm:flex sm:items-center sm:justify-between">
        <div>
          <button
            v-if="isInCurrentContext(notification)"
            class="btn-plain hover:bg-gray-200 focus:ring-gray-200"
            :disabled="loading"
            @click="$emit('reset', notification)"
          >
            {{ notification.is_deactivated ? $t('documentNotifications.activate') : $t('documentNotifications.resetToDefault') }}
          </button>
          <button
            v-if="!isInCurrentContext(notification)"
            class="btn-plain hover:bg-gray-200 focus:ring-gray-200"
            :disabled="loading"
            @click="notification.is_deactivated ? $emit('activate', notification) : $emit('deactivate', notification)"
          >
            {{ notification.is_deactivated ? $t('documentNotifications.activate') : $t('documentNotifications.deactivate') }}
          </button>
        </div>
        <div class="gap-2 sm:flex sm:items-center sm:justify-end">
          <button
            type="button"
            class="btn-plain hover:bg-gray-200 focus:ring-gray-200"
            @click.prevent="$emit('update:show', false)"
          >
            {{ $t('common.cancel') }}
          </button>
          <button
            type="button"
            class="flex items-center justify-center w-full gap-2 sm:w-auto btn-primary"
            :disabled="loading"
            @click.prevent="submit()"
          >
            <SpinLoader
              v-if="loading"
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('common.save') }}</span>
          </button>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
