<script setup lang="ts">
// external
import { computed } from "vue"
import { groupBy, pull } from "lodash-es"
import { Link } from "@inertiajs/vue3"

// internal
import { Document, DocumentStage } from "~/types"
import { formatDateRelative } from "~/utils"
import { TeamList, StageDisplay } from "~/components"
import { useStages } from "~/composables"

interface Props {
  documents: Document[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const { stages } = useStages(null, null, true)

const statusValuesFull = Object.values(DocumentStage)
const statusValues = pull(statusValuesFull as string[], "approved_draft", "approved_review")

const documentsByStage = computed<Record<string, Document[]>>(() => {
  return groupBy(props.documents, (el: Document) => { return el.stage.includes("approved_") ? el.stage.replace("approved_", "") : el.stage })
})

const getStage = (name) => {
  return stages.value.find((stage) => stage.name === name)
}

</script>

<template>
  <div
    class="relative min-h-[calc(100vh-300px)]"
  >
    <div
      class="lg:grid lg:grid-cols-4 lg:divide-x lg:divide-x-gray-200 inset-0 absolute"
    >
      <div
        v-for="status, statusIdx in statusValues"
        :key="statusIdx"
        class="col-span-1 lg:px-4 text-sm first:pl-0 last:pr-0 pb-5 lg:mb-0"
      >
        <h3
          class="flex items-center gap-1 mb-2 text-xs font-normal tracking-wider text-gray-500 uppercase"
        >
          <StageDisplay
            :stage="getStage(status)"
            label-classes="ml-1 text-xs text-gray-500 uppercase"
          />
          <span>({{ documentsByStage[status]?.length || "0" }})</span>
        </h3>

        <div class="space-y-4">
          <div
            v-for="(document) in documentsByStage[status]"
            :key="document.uuid"
            class="p-4 text-sm bg-white rounded-md shadow"
          >
            <div class="flex items-center justify-between gap-2">
              <Link
                :href="route('documents.show', document.uuid)"
                class="truncate hover:text-gray-600"
              >
                <span>
                  {{ document.name }}
                </span>
              </Link>
            </div>
            <div class="flex items-center justify-between mt-1">
              <div
                class="shrink-0 flex items-center text-sm"
              >
                <TeamList
                  v-if="document.teams?.length"
                  :teams="document.teams"
                />
                <span
                  v-else
                  class="text-sm text-gray-400"
                >
                  {{ $t('unassigned') }}
                </span>
              </div>
              <span class="text-gray-400">
                <time
                  :datetime="document.updated_at"
                >{{
                  formatDateRelative(document.updated_at)
                }}</time>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
