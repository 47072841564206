<script setup lang="ts">
// external
import { ref } from "vue"
import { EnvelopeIcon, GlobeEuropeAfricaIcon } from "@heroicons/vue/24/outline"

// internal
import { AccountColorSettings } from "~/types"
import { MailMockup, WizardMockup } from "~/components"


interface Props {
  accountLogoImageUrl: string|null,
  ciColors: AccountColorSettings
  accountEmailSignature?: string|null
  mailClasses?: string
  wizardClasses?: string
  isOnboarding?: boolean
}

withDefaults(defineProps<Props>(), {
  accountEmailSignature: "",
  mailClasses: "scale-[66%] origin-top-left w-[150%]",
  wizardClasses: "scale-[50%] origin-top-left w-[200%]",
  isOnboarding: false,
})

const previewMode = ref<"mail" | "wizard">("mail")

</script>
<template>
  <div
    :class="isOnboarding? 'max-h-[600px] relative overflow-hidden w-full' : ''"
  >
    <div
      class="flex items-center mb-2 text-sm font-medium"
      :class="isOnboarding ? 'justify-center' : ''"
    >
      <span v-if="!isOnboarding">{{ $t('accountSettings.brandIdentity.previewMode') }}:</span>
      <button
        type="button"
        :class="{ 'text-indigo-700': previewMode === 'mail', 'text-gray-400': previewMode !== 'mail' }"
        class="flex items-center gap-1 ml-2 btn btn-plain btn-sm focus:ring-0 focus:ring-offset-0"
        @click="previewMode = 'mail'"
      >
        <EnvelopeIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
        {{ $t('accountSettings.brandIdentity.emailPreview') }}
      </button>
      <button
        type="button"
        :class="{ 'text-indigo-700': previewMode === 'wizard', 'text-gray-400': previewMode !== 'wizard' }"
        class="flex items-center gap-1 btn btn-plain btn-sm focus:ring-0 focus:ring-offset-0"
        @click="previewMode = 'wizard'"
      >
        <GlobeEuropeAfricaIcon
          class="w-4 h-4"
          aria-hidden="true"
        />
        {{ $t('accountSettings.brandIdentity.wizardPreview') }}
      </button>
    </div>
    <div
      v-if="previewMode === 'mail'"
      class="overflow-hidden shadow-xl rounded-xl"
      :class="mailClasses"
    >
      <MailMockup
        :account-logo-image-url="accountLogoImageUrl"
        :ci-colors="ciColors"
        :account-email-signature="accountEmailSignature"
      />
    </div>
    <div
      v-else-if="previewMode === 'wizard'"
      class="overflow-hidden shadow-xl rounded-xl"
      :class="wizardClasses"
    >
      <WizardMockup
        :account-logo-image-url="accountLogoImageUrl"
        :ci-colors="ciColors"
        :account-email-signature="accountEmailSignature"
      />
    </div>
  </div>
</template>
