<script setup lang="ts">
// external
import { computed } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"

// internal
import { SpinLoader, TeamIconDisplay } from "~/components"
import { AccountUser, DocumentUser, Team } from "~/types"

interface Props {
  modelValue: Team | null
  options: Team[]
  isHighlighted?: boolean
  loading?: boolean
  disabled?: boolean
  mdu?: DocumentUser
  mau?: AccountUser
  clearable?: boolean
  inline?: boolean
  selectedTeams?: Team[]
}

const props = withDefaults(defineProps<Props>(), {
  mdu: null,
  isDisabled: false,
  isHighlighted: false,
  loading: false,
  disabled: false,
  clearable: false,
  inline: true,
  selectedTeams: null,
})

const emit = defineEmits([ "focus", "blur", "update:model-value" ])

const selectedValue = computed({
  get: () => {
    return props.modelValue
  },
  set: (val) => {
    emit("update:model-value", val)
  },
})

const sortedOptions = computed(() => {
  return [ ...props.options ].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
})

const isDisabled = computed(() => props.disabled || !sortedOptions.value?.length)

</script>
<template>
  <div
    class="transition-all duration-500 max-w-full"
    :class="[isHighlighted ? 'bg-teal-50' : '']"
  >
    <Listbox
      :id="'teamSelector'"
      v-model="selectedValue"
      as="div"
      :disabled="isDisabled"
      class="px-6 py-2 text-sm "
      :class="inline ? 'flex justify-between items-center space-x-2' : 'block'"
    >
      <ListboxLabel
        :class="inline ? 'shrink-0 text-gray-500' : 'block font-medium'"
        class="flex items-center gap-2 py-2 pr-3 shrink-0"
      >
        <slot name="label">
          {{ $t('settings.sections.teams') }}
        </slot>
        <SpinLoader
          v-if="loading"
          class="w-3 h-3"
        />
      </ListboxLabel>
      <div
        v-if="!isDisabled"
        :class="inline ? 'grow max-w-fit' : 'w-full'"
        class="relative"
      >
        <ListboxButton
          :class="[!inline && (!selectedValue || clearable && selectedValue) ? 'pr-9' : '', inline ? 'group disabled:opacity-100 btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-0 focus:ring-offset-0 text-gray-900 flex items-center gap-2' : 'group btn-listbox-plain']"
        >
          <div class="flex items-center">
            <span
              class="block font-normal text-gray-400 truncate"
            >
              {{ options.length ? $t('documentGeneralSettings.selectTeams')+"…" : selectedTeams?.length ? '' : $t('teams.noTeams') }}
            </span>
          </div>
          <span
            v-if="clearable && selectedValue"
            :class=" inline ? '-right-5' : 'right-0 pr-2'"
            class="absolute inset-y-0 flex items-center transition-all duration-200 scale-0 opacity-0 pointer-events-none group-hover:scale-100 group-hover:opacity-100"
            @click.prevent="selectedValue = null"
          >
            <XMarkIcon
              class="w-4 h-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
          <span
            v-else
            :class=" inline ? '-right-5' : 'right-0 pr-2'"
            class="absolute inset-y-0 flex items-center transition-all duration-200 scale-0 opacity-0 pointer-events-none group-hover:scale-100 group-hover:opacity-100"
          >
            <ChevronUpDownIcon
              class="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="listbox-options max-w-[340px]"
            @focus="
              $emit('focus', {
                target: { id: 'documentTeamSelector' },
              })
            "
            @blur="
              $emit('blur', {
                target: { id: 'documentTeamSelector' },
              })
            "
          >
            <div
              v-if="!sortedOptions?.length"
              class="px-3 py-2 text-sm text-gray-500"
            >
              {{ $t('common.noOptionsFound') }}.
            </div>
            <ListboxOption
              v-for="team in sortedOptions"
              :key="team.uuid"
              v-slot="{ active, selected }"
              as="template"
              :value="team"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                <div class="flex items-center">
                  <TeamIconDisplay
                    :team="team"
                    class="w-4 h-4 mr-2"
                  />
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                  >
                    {{ team.name }}
                  </span>
                </div>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-indigo-500',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <slot />
  </div>
</template>
