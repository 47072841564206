<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, nextTick, ref, toRaw, watch, onBeforeMount } from "vue"
import { useI18n } from "vue-i18n"

import { CheckIcon, TrashIcon } from "@heroicons/vue/24/solid"
import { toTypedSchema } from "@vee-validate/zod"
import { useForm } from "vee-validate"
import { z } from "zod"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"

// internal
import { MobileClosePopoverButton, OverlayScrollbar, SignatoryForm, SpinLoader } from "~/components"
import { useDocumentStore, useNotificationStore, useSharedStore, useSignatureStore, useUserStore } from "~/stores"
import { Document, DocumentUser, Party, Template } from "~/types"
import { CrudContext, DocumentStage, DocumentUserRoleEnum } from "~/types/enums"
import { formatTime, hideTippiesViaSelector } from "~/utils"

interface Props {
  id?: string
  partyUuid?: Party["uuid"]
  user?: DocumentUser
  document?: Document
  template?: Template
  hidePartySettings?: boolean
  scope?: "internal" | "external"
}

const props = withDefaults(
  defineProps<Props>(),
  {
    partyUuid: null,
    user: null,
    document: null,
    template: null,
    hidePartySettings: false,
    scope: null,
  },
)
const { notify } = useNotificationStore()
const { t } = useI18n()

const userStore = useUserStore()
const { uuidsOfUpdatingUser, userLastSavedMap, userUuidBeingRemovedFromEntity } = storeToRefs(userStore)

const documentStore = useDocumentStore()
const { mdu, isLockedDocument } = storeToRefs(documentStore)

const signatureStore = useSignatureStore()
const { preemptiveSignatures } = storeToRefs(signatureStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual("md")

const signatoryForm = ref<any>()

const lastSaved = computed<number | undefined>(() => userLastSavedMap.value[localUser.value?.uuid])

const isLoadingCreateUser = ref<boolean>(false)
const isUserLoading = computed<boolean>(() => uuidsOfUpdatingUser.value.includes(localUser.value?.uuid) || isLoadingCreateUser.value)

const emptyUser: Partial<DocumentUser> =  Object.freeze({ roles: [ DocumentUserRoleEnum.collaborator ] })
const localNewUser = ref<Partial<DocumentUser>>({ ...emptyUser })
const relatedUser = computed<Partial<DocumentUser>>({
  get: () => {
    return props.user || { ...emptyUser }
  },
  set: (val) => {
    localNewUser.value = val
    if (val.uuid && !val.account_user?.uuid) localUser.value = val
  },
})

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const localUser = computed<Partial<DocumentUser>>(
  {
    get: () => {
      let dataToReturn: Partial<DocumentUser> = {}

      if (props.user?.uuid) {
        dataToReturn = props.user || { ...emptyUser }
      } else {
        dataToReturn = localNewUser.value
      }

      return dataToReturn
    },
    set: (val) => {
      setTimeout(
        async () => {
          await validate(
            {
              mode: "validated-only",
            },
          )
        },
      )
      localNewUser.value = val
    },
  },
)

const documentUserSchema = z
  .object(
    {
      email: z.string().email().optional(),
      first_name: z.string().nullable().optional(),
      last_name: z.string().nullable().optional(),
      mobile_phone: z.string().nullable().optional(),
      party_uuid: z.string().optional(),
      account_user: z.object({
        uuid: z.string().nullable().optional(),
      }).nullable().optional(),
      roles: z.array(z.nativeEnum(DocumentUserRoleEnum)).optional(),
    },
  ).refine( (input) => {

    // allows phone to be optional only when no signatory
    if ( input.roles?.includes(DocumentUserRoleEnum.signatory) && input.mobile_phone === undefined ) return false
    // make e-mail required for non-account-users
    if ( !input.account_user?.uuid && !input.email ) return false
    // make party uuid required in not passed via prop
    if ( !input.party_uuid && !props.partyUuid ) return false
    return true

  }, (input) => {
    if ( input.roles?.includes(DocumentUserRoleEnum.signatory) && input.mobile_phone === undefined ) {
      return {
        message: t("userForm.errors.signatoryPhone"),
        path: [ "mobile_phone" ],
      }
    } else if (!props.partyUuid && !input.party_uuid) {
      return {
        message: t("userForm.errors.party"),
        path: [ "party_uuid" ],
      }
    } else {
      return {
        message: t("userForm.errors.email"),
        path: [ "email" ],
      }
    }
  } )

type DocumentUserValidatedType = z.infer<typeof documentUserSchema>

const documentUserValidator = toTypedSchema(documentUserSchema)

const { setValues, setFieldValue, validate } = useForm<DocumentUserValidatedType>(
  {
    validationSchema: documentUserValidator,
  },
)

const hasPreemptiveSignature = computed<boolean>(() => {
  if (preemptiveSignatures.value?.some((el) => el.document_user_uuid === props.user?.uuid)) return true
  return false
})

const canBeRemoved = computed<boolean>(() => {
  if (!mdu.value?.permissions?.includes("document_user_delete") && crudContext.value === CrudContext.document) return false
  if (isLockedDocument.value) return false
  if (hasPreemptiveSignature.value) return false
  return true
})

watch(
  () => localUser.value,
  (newVal) => {
    if (!newVal) return
    if (localUser.value?.uuid) setValues(newVal)
  },
  {
    deep: true,
    immediate: true,
  },
)

// Function to create / add a new user
const createUser = async () => {
  if (props.user?.uuid) return

  const entityUuid = props?.[crudContext.value]?.uuid

  const validated = await signatoryForm.value?.exposeValidate("force")
  if (!validated) return

  // Deliver entire user as payload for external users, and only role/party/signatory for internal users
  const payload = !localUser.value.account_user?.uuid
    ? toRaw({ ...localUser.value, party_uuid: localUser.value.party_uuid || props.partyUuid })
    : toRaw({
      account_user_uuid: localUser.value.account_user?.uuid,
      roles: localUser.value.roles,
      party_uuid: localUser.value.party_uuid || props.partyUuid,
    })

  try {
    isLoadingCreateUser.value = true

    const createdUser = await userStore.createUser(crudContext.value, entityUuid, payload)

    if (!createdUser) throw new Error(t("userSettings.errors.invalidUser", { string: JSON.stringify({ createdUser }) }))

    hideTippiesViaSelector(".add-signatory-button", false)

    localUser.value = emptyUser
    localNewUser.value = emptyUser

    userStore.setUserErrors("0", null)
    nextTick(() => signatoryForm.value?.resetForm())

  } catch (err) {
    userStore.setUserErrors("0", err.response?.data?.errors || null)

    notify({
      title: t("userSettings.errors.addUser"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingCreateUser.value = false
  }
}

// Function to remove a user from an entity
const handleRemoveUserAsSignatory = async (userUuid: DocumentUser["uuid"]) => {
  hideTippiesViaSelector("#editSignatoryButton_" + userUuid, true)
  try {
    const res = userStore.removeUserAsSignatory(userUuid, entityUuid.value, crudContext.value)
    if (res) return true
  } catch (err) {
    notify({
      title: t("userSettings.errors.deassignRole"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  }
}

onBeforeMount(() => {
  // Set field values
  setFieldValue("roles", localUser.value?.roles || [ DocumentUserRoleEnum.signatory ])
})

const resetForm = () => {
  localUser.value = { ...emptyUser }
  signatoryForm.value?.resetForm()
}

defineExpose({ resetForm })

</script>

<template>
  <div
    :id="
      id
        ? id
        : !props.user?.uuid
          ? 'addSignatoryForm'
          : 'signatoryForm_' + props.user?.uuid
    "
    v-cy="`user-popover`"
  >
    <div class="popover popover-primary popover-mobile-fullscreen md:min-w-[22rem] md:max-w-[26rem]">
      <MobileClosePopoverButton
        :label="$t('popovers.editSignatory')"
      />
      <component
        :is="isMobile ? OverlayScrollbar : 'div'"
        tag="div"
        class="flex-1 h-full"
      >
        <div class="flex flex-col gap-3 px-4 pt-3 pb-5">
          <SignatoryForm
            ref="signatoryForm"
            v-model:user="relatedUser"
            class="grid grid-cols-1 gap-y-2"
            :scope="scope"
            :party-uuid="props.partyUuid"
            :document="props.document"
            :template="props.template"
            :is-disabled="isLockedDocument || props.document?.stage === DocumentStage.signed || hasPreemptiveSignature"
            :hide-party-settings="hidePartySettings"
            @hide-popover="hideTippiesViaSelector('.add-signatory-button',false)"
            @reset-form="resetForm"
          />
        </div>
      </component>

      <div
        v-if="!(isLockedDocument || props.document?.stage === DocumentStage.signed)"
        class="flex items-center justify-end gap-2 p-3 bg-gray-100 border-t border-gray-200 rounded-b-md"
      >
        <template
          v-if="(props.user?.uuid || (localUser?.uuid && !localUser?.account_user?.uuid))"
        >
          <button
            v-if="canBeRemoved"
            :disabled="!!(props.user?.uuid && userUuidBeingRemovedFromEntity === props.user?.uuid)"
            type="button"
            class="flex items-center gap-2 mr-2 text-gray-400 btn-plain btn-sm hover:text-red-500 hover:bg-red-100 focus:ring-red-100"
            @click.prevent="handleRemoveUserAsSignatory(props.user?.uuid)"
          >
            <TrashIcon
              v-if="userUuidBeingRemovedFromEntity !== props.user?.uuid"
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <SpinLoader
              v-else
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span>
              {{ $t('common.remove') }}
            </span>
          </button>
        </template>
        <button
          v-if="((!props.user?.uuid && !localUser.uuid) || (!localUser.uuid && localUser.account_user?.uuid))"
          v-cy="`user-form-submit-button`"
          :disabled="isUserLoading"
          type="button"
          class="inline-flex items-center gap-2 btn-primary"
          @click.prevent="createUser()"
        >
          <SpinLoader
            v-if="isUserLoading"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span v-if="isUserLoading">{{ $t('common.saving') }}…</span>
          <span v-else>{{ $t('common.save') }}</span>
        </button>
        <span
          v-else-if="isUserLoading"
          class="flex items-center justify-center gap-2 text-sm font-medium text-indigo-700 pointer-events-none btn-plain"
        >
          <SpinLoader
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.saving') }}…
        </span>
        <span
          v-else-if="lastSaved"
          class="flex items-center justify-center gap-2 text-sm font-medium text-gray-500 pointer-events-none btn-plain"
        >
          <CheckIcon
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.lastSavedAt', {time: formatTime(lastSaved)}) }}
        </span>
        <span
          v-else
          class="flex items-center justify-center text-sm font-medium text-gray-500 pointer-events-none btn-plain"
        >
          {{ $t('common.noUnsavedChanges') }}
        </span>
      </div>
    </div>
  </div>
</template>
