<script lang="ts" setup>
// external
import { CameraIcon, ViewfinderCircleIcon, PhotoIcon } from "@heroicons/vue/24/outline"
import { ArrowUpTrayIcon } from "@heroicons/vue/24/solid"
import { ref, onMounted, computed, watch, onBeforeUnmount } from "vue"
import { Cropper, RectangleStencil } from "vue-advanced-cropper"
import { trace } from "potrace"
import { debounce } from "lodash-es"
import { useI18n } from "vue-i18n"

// internal
import { CameraCapture, SpinLoader } from "~/components"
import { getMimeType } from "~/utils"
import { useNotificationStore } from "~/stores"

const { t } = useI18n()
const { notify } = useNotificationStore()

interface Props {
  color: string
}

const props = withDefaults(defineProps<Props>(), {
  color: "#4f46e5",
})

const image = ref(null)
const imageType = ref(null)
const cropper = ref(null)
const file = ref(null)
const isVisibleCameraCapture = ref(false)
const cameraCapture = ref(null)
const deviceList = ref<MediaDeviceInfo[]>([])

const color = computed(() => {
  return props.color
})

const tracingThreshold = ref(null)

interface PotraceParams {
  background: string
  color: string
  threshold?: number
}

// Compute potrace props depending on the color
const potraceParams = computed<PotraceParams>(() => {
  let returnObject: PotraceParams = {
    background: "transparent",
    color: color.value,
  }
  if (tracingThreshold.value) {
    returnObject = {
      ...returnObject,
      threshold: tracingThreshold.value,
    }
  }
  return returnObject
})

// Watch for color change and if the image is traced, retrace it
watch(color, () => {
  if (isTraced.value) getPotraceSvg(storeOriginal.value)
})

const convertSvgToPng = (svgString: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create a canvas element
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    if (!ctx) {
      reject("Unable to get canvas context")
      return
    }

    // Create an image to load the SVG
    const img = new Image()
    img.onload = () => {
      // Set canvas size to match SVG
      canvas.width = img.width
      canvas.height = img.height

      // Draw the SVG onto the canvas
      ctx.drawImage(img, 0, 0)

      // Convert canvas to PNG URL
      const pngUrl = canvas.toDataURL("image/png")
      resolve(pngUrl)
    }

    img.onerror = (e) => {
      reject("Error loading SVG as image: " + e)
    }

    // Set the source of the image to the SVG data
    img.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgString)
  })
}

const isLoadingCapture = ref(false)

const getPotraceSvg = (base64data: string) => {
  isLoadingCapture.value = true
  trace(base64data, potraceParams.value, (err, svg) => {
    if (err) {
      notify({
        title: t("signaturePad.errors.errorTracingImage"),
        message: err?.message,
        type: "error",
      })
      console.error(err)
    } else {
      convertSvgToPng(svg).then((pngUrl) => {
        image.value = pngUrl
        isLoadingCapture.value = false
      })
    }
  })
}

const crop = () => {
  if (image.value) {
    const { canvas } = cropper.value.getResult()
    canvas.toBlob((blob: Blob) => {
      emit("change", blob)
    }, imageType.value || "image/png")
  }
}

const clear = () => {
  image.value = null
  imageType.value = null
  isTraced.value = false
  isLoadingCapture.value = false
  cameraCapture.value?.cancel()
}

const imageCapturedViaWebcam = ref<boolean>(false)

const handleCapture = (base64data) => {
  isTraced.value = true
  storeOriginal.value = base64data
  getPotraceSvg(base64data)
  isVisibleCameraCapture.value = false
  imageCapturedViaWebcam.value = true
}

const loadImage = async (event) => {
  imageCapturedViaWebcam.value = false
  // Reference to the DOM input element
  const { files } = event.target
  // Ensure that you have a file before attempting to read it
  if (files && files[0]) {
    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (image.value) {
      URL.revokeObjectURL(image.value)
    }
    // 2. Create the blob link to the file to optimize performance:
    const blob = URL.createObjectURL(files[0])

    // 3. The steps below are designated to determine a file mime type to use it during the
    // getting of a cropped image from the canvas. You can replace it them by the following string,
    // but the type will be derived from the extension and it can lead to an incorrect result:
    //
    // this.image = {
    //    src: blob;
    //    type: files[0].type
    // }

    // Create a new FileReader to read this image binary data
    try {
      const arrayBuffer = await readFileAsArrayBuffer(files[0])
      image.value = blob
      imageType.value = getMimeType(arrayBuffer, files[0].type)
    } catch (error) {
      console.error("Error reading file:", error)
    }
  }
}

const readFileAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsArrayBuffer(file)
  })
}

const handleLoadImage = async (event) => {
  await loadImage(event)
  if ([ "image/jpeg", "image/png" ].includes(imageType.value)) {
    traceUploadedImage()
  }
}

const isTraced = ref(false)
const storeOriginal = ref(null)
const storeOriginalType = ref(null)

const traceUploadedImage = () => {
  storeOriginal.value = image.value
  storeOriginalType.value = imageType.value
  getPotraceSvg(image.value)
  isTraced.value = true
  imageType.value = "image/png"
}

const revertTracedImage = () => {
  image.value = storeOriginal.value
  imageType.value = storeOriginalType.value
  isTraced.value = false
}

const showCameraOption = ref(false)

const getDevices = async () => {
  /* Make sure mediaDevices.getUserMedia() did run before. */
  const mediaDevices = await navigator.mediaDevices.enumerateDevices()
  return mediaDevices.filter( ( device ) => device.kind === "videoinput" )
}

// Watch tracingThreshold and debounce re-trace on change
watch(tracingThreshold, debounce(() => {
  if (isTraced.value) getPotraceSvg(storeOriginal.value)
}, 500))

const handleGetDevices = () => {
  getDevices()
    .then((devices) => {
      deviceList.value = devices
    })
    .catch((err) => {
      console.error(err)
    })
}

onMounted(() => {
  showCameraOption.value =  "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices
  if (showCameraOption.value) {
    handleGetDevices()
    navigator.mediaDevices.addEventListener("devicechange", handleGetDevices)
  }
})

onBeforeUnmount(() => {
  navigator.mediaDevices.removeEventListener("devicechange", handleGetDevices)
})

defineExpose({ clear })
const emit = defineEmits([ "change" ])

</script>
<template>
  <div
    v-if="image"
    class="relative"
  >
    <div
      class="cropper-container"
    >
      <Cropper
        v-if="image"
        ref="cropper"
        class="cropper"
        background-class="cropper-background"
        foreground-class="cropper-foreground"
        :src="image"
        :stencil-component="RectangleStencil"
        :stencil-props="{
          minAspectRatio: 2 / 1,
          maxAspectRatio: 768 / 168,
          class: 'cropper-stencil',
        }"
        :image-restriction="'fit-area'"
        :default-boundaries="'fit'"
        @change="crop"
      />
    </div>
    <div
      v-if="isTraced"
      class="absolute inset-x-0 bottom-0 flex items-center justify-center gap-3 py-3 pointer-events-none"
    >
      <div class="items-center hidden text-white bg-black bg-opacity-50 pointer-events-auto sm:flex hover:text-white btn-plain hover:bg-opacity-60">
        <label class="flex items-center gap-2">
          <span>{{ $t('signaturePad.tracingThreshold') }}</span>
          <input
            v-model.number="tracingThreshold"
            type="range"
            min="0"
            max="255"
            step="1"
            class="w-32 h-2"
          >
          <button
            class="p-0 text-white hover:text-white focus:ring-offset-0 text-opacity-80 btn-plain focus:ring-0 hover:text-opacity-100 focus:text-opacity-100"
            type="button"
            @click="tracingThreshold = null"
          >
            {{ $t('signaturePad.reset') }}
          </button>
        </label>
      </div>

      <button
        class="flex items-center gap-2 text-white bg-black bg-opacity-50 pointer-events-auto btn-plain hover:text-white hover:bg-opacity-60"
        @click="revertTracedImage"
      >
        <PhotoIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        {{ $t('signaturePad.backToOriginal') }}
      </button>
    </div>
  </div>
  <div
    v-else-if="isLoadingCapture"
    class="flex items-center justify-center w-full py-20 rounded-xl"
  >
    <SpinLoader class="w-12 h-12 text-gray-400" />
  </div>
  <div v-else-if="isVisibleCameraCapture">
    <CameraCapture
      ref="cameraCapture"
      v-model:is-loading-capture="isLoadingCapture"
      :device-list="deviceList"
      @capture="handleCapture"
      @cancel="isVisibleCameraCapture = false"
    />
  </div>
  <div
    v-else
    class="inset-0 items-center justify-center gap-2 p-6 md:absolute md:flex"
  >
    <button
      class="flex items-center justify-center w-full gap-2 py-4 text-base border-2 border-gray-300 border-dashed md:text-sm md:w-auto hover:border-gray-400 hover:text-gray-700 btn-plain md:py-2"
      @click="file.click()"
    >
      <ArrowUpTrayIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
      {{ $t('signaturePad.uploadImage') }}
    </button>
    <button
      v-if="showCameraOption && deviceList.length > 0"
      class="flex items-center justify-center w-full gap-2 py-4 mt-2 text-base border-2 border-gray-300 border-dashed md:text-sm md:w-auto hover:border-gray-400 hover:text-gray-700 btn-plain md:py-2 md:mt-0"
      @click="isVisibleCameraCapture = true"
    >
      <CameraIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
      {{ $t('signaturePad.captureWithCamera') }}
    </button>
    <input
      ref="file"
      type="file"
      accept="image/jpeg,image/png"
      class="absolute top-0 opacity-0 -left-96"
      @change="handleLoadImage"
    >
  </div>
  <div
    v-if="image && !imageCapturedViaWebcam"
    class="absolute inset-0 flex items-end justify-center gap-2 py-3 pointer-events-none"
  >
    <button
      v-if="!isTraced"
      class="flex items-center gap-2 pointer-events-auto btn-primary"
      @click="traceUploadedImage"
    >
      <ViewfinderCircleIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
      {{ $t('signaturePad.traceImage') }}
    </button>
  </div>
</template>

<style lang="scss">
  .cropper-container {
    width: 100%;
    max-height: 50vh;
    overflow: hidden;
    position: relative
  }
  .cropper {
    max-height: 50vh;
  }
  .cropper-background {
    background-image: none!important;
  }
  .cropper-foreground {
    @apply opacity-100 bg-opacity-100;
  }
  .cropper-stencil {
    @apply bg-white;
  }
</style>
