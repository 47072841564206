<script setup lang="ts">

</script>
<template>
  <div>
    <div class="mb-5">
      {{ $t('lexica.content.tags.intro') }}
    </div>
    <div class="mb-3 font-medium">
      {{ $t('lexica.content.tags.needToKnow') }}
    </div>
    <ul class="list-disc ml-4 space-y-2">
      <li>{{ $t('lexica.content.tags.bullet1') }}</li>
      <li>{{ $t('lexica.content.tags.bullet2') }}</li>
      <li>{{ $t('lexica.content.tags.bullet3') }}</li>
    </ul>
  </div>
</template>
