<script setup lang="ts">
// external
import { computed } from "vue"

// internal
import { Tag } from "~/types"
import { TagDisplay } from "~/components"

interface Props {
  tags: Tag[],
  removable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  removable: false,
})

const sortedTags = computed(() => {
  return [ ...props.tags ].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
})

defineEmits([ "remove" ])
</script>
<template>
  <div
    class="flex flex-wrap gap-1"
  >
    <TagDisplay
      v-for="tag in sortedTags"
      :key="'tag' + tag.uuid"
      :tag="tag"
      class="max-w-full"
      :removable="!!removable"
      @remove="$emit('remove', tag)"
    />
  </div>
</template>
