<script setup lang="ts">

</script>
<template>
  <div>
    <div>{{ $t('lexica.content.roles.intro') }}</div>
    <div class="mt-3 space-y-5">
      <div>
        <h3 class="text-base font-medium text-sky-500">
          Owner
        </h3>
        <p>{{ $t('lexica.content.roles.ownerDescription') }}</p>
      </div>
      <div>
        <h3 class="text-base font-medium text-sky-500">
          Admin
        </h3>
        <p>{{ $t('lexica.content.roles.adminDescription') }}</p>
      </div>
      <div>
        <h3 class="text-base font-medium text-sky-500">
          Editor
        </h3>
        <p>{{ $t('lexica.content.roles.editorDescription') }}</p>
      </div>
      <div>
        <h3 class="text-base font-medium text-sky-500">
          Manager
        </h3>
        <p>{{ $t('lexica.content.roles.managerDescription') }}</p>
      </div>
    </div>
  </div>
</template>
