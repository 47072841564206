<script setup lang="ts">
// external
import { ref, watch } from "vue"
import { PlusIcon, MinusIcon } from "@heroicons/vue/20/solid"
import { StarIcon as StarIconSolid } from "@heroicons/vue/24/solid"
import { StarIcon as StarIconOutline } from "@heroicons/vue/24/outline"

interface Props {
  label: string
  allOpen?: boolean
  initialOpen?: boolean
  disabled?: boolean
  count?: number
  pinned?: boolean
  pinnable?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    allOpen: false,
    initialOpen: false,
    disabled: false,
    count: 0,
    pinned: false,
    pinnable: true,
  },
)

const isOpen = ref(props.pinned || props.initialOpen || props.count > 0 ? true : props.allOpen)

const toggle = () => {
  if (!props.disabled) {
    isOpen.value = !isOpen.value
  }
}

watch(() => props.allOpen, (newVal) => {
  isOpen.value = newVal
})

defineEmits([ "pin" ])

</script>
<template>
  <Teleport
    to="#pinnedFilters"
    :disabled="!pinned"
  >
    <div>
      <div
        class="relative -m-2 p-2 rounded"
        :class="isOpen ? 'bg-white shadow' : ''"
      >
        <button
          type="button"
          class="w-full text-sm font-normal flex items-center space-x-2 justify-between group"
          :class="isOpen ? 'text-gray-900' : 'text-gray-500'"
          @click="toggle"
        >
          <span class="text-left">{{ label }}</span>
          <div class="flex items-center">
            <div
              v-if="count > 0"
              class="rounded-full w-[14px] h-[14px] bg-gray-200 text-gray-500 text-[10px] flex items-center justify-center font-medium mr-3"
            >
              <span>{{ count }}</span>
            </div>
            <button
              v-if="pinnable"
              class="relative w-0 overflow-hidden h-5 group-hover:w-5 transition-all duration-100 delay-200 group-hover:mr-2 mr-0 opacity-0 group-hover:opacity-100"
              type="button"
              @click.stop="$emit('pin')"
            >
              <StarIconSolid
                v-if="pinned"
                aria-hidden="true"
                class="w-4 h-4 text-indigo-300"
              />
              <StarIconOutline
                v-else
                aria-hidden="true"
                class="w-4 h-4 text-gray-500"
              />
              <span class="sr-only">Pin filter to top</span>
            </button>
            <MinusIcon
              v-if="isOpen"
              aria-hidden="true"
              class="w-4 h-4"
            />
            <PlusIcon
              v-else
              aria-hidden="true"
              class="w-4 h-4"
            />
          </div>
        </button>
        <div
          v-if="isOpen"
          class="mt-2"
        >
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>
