<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { router } from "@inertiajs/vue3"
import { storeToRefs } from "pinia"
import { DocumentPlusIcon } from "@heroicons/vue/24/outline"

// internal
import { DashboardGraphWidget, EmptyState } from "~/components"
import { useAccountStore, useDashboardStore } from "~/stores"
import defaultOptions from "../chartPresets"

const widgetData = ref(null)

const dashboardStore = useDashboardStore()
const { mau } = storeToRefs(dashboardStore)

const accountStore = useAccountStore()
const { accountUsage } = storeToRefs(accountStore)

const setData = (data) => {
  widgetData.value = data
}

const labels = computed(() => {
  if (!widgetData.value) return []
  return widgetData.value.map((dataEntry) => dataEntry.name)
})

const series = computed(() => {
  if (!widgetData.value) return []
  return widgetData.value.map((dataEntry) => dataEntry.count)
})

const handleDocumentsByTemplateGraphClick = ({ event }) => {
  const clickedTemplate = widgetData.value[event.target.parentElement.getAttribute("data:realIndex")]
  if (clickedTemplate && mau.value?.permissions.includes("template_manage")) {
    router.visit(route("templates.edit", clickedTemplate.uuid))
  }
}

</script>

<template>
  <DashboardGraphWidget
    :title="$t('dashboard.widgets.documentsByTemplate')"
    endpoint="api.data.documents.byTemplate"
    type="donut"
    :series="series"
    :options="{
      ...defaultOptions.donut,
      labels: labels,
    }"
    box-classes="p-2"
    @click="handleDocumentsByTemplateGraphClick"
    @loaded="setData"
  >
    <template #empty>
      <EmptyState
        v-if="!accountUsage.templates"
        :hide-button="true"
      >
        <template #icon>
          <DocumentPlusIcon
            aria-hidden="true"
          />
        </template>
        <template #call-to-action>
          {{ $t('templates.empty.description') }}
        </template>
        <template #default>
          {{ $t('templates.empty.title') }}
        </template>
      </EmptyState>
      <EmptyState
        v-else-if="!accountUsage.documents"
        :hide-button="true"
      >
        <template #icon>
          <DocumentPlusIcon
            aria-hidden="true"
          />
        </template>
        <template #call-to-action>
          {{ $t('documents.empty.description') }}
        </template>
        <template #default>
          {{ $t('documents.empty.title') }}
        </template>
      </EmptyState>
    </template>
  </DashboardGraphWidget>
</template>
