<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onBeforeMount, ref, toRaw, watch } from "vue"

import { LockClosedIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { toTypedSchema } from "@vee-validate/zod"
import { intersection, isEqual, omit } from "lodash-es"
import { useField, useForm } from "vee-validate"
import { z } from "zod"

// internal
import { FormInputErrors, UserPartySettings, UserCombobox } from "~/components"
import { useDocumentStore, usePartyStore, useSharedStore, useUserStore } from "~/stores"
import { CrudContext, Document, DocumentUser, DocumentUserRoleEnum, Party, Template, UiUser } from "~/types"
import { EMAIL_REGEX, changedKeys, getUserRepresentation } from "~/utils"

interface Props {
  partyUuid?: Party["uuid"]
  user?: Partial<DocumentUser>
  document?: Document
  template?: Template
  isDisabled?: boolean
  parentErrors?: Partial<Record<keyof DocumentUser, string[]>>
  hidePartySettings?: boolean
  scope?: "internal" | "external"
}

const props = withDefaults(
  defineProps<Props>(),
  {
    partyUuid: null,
    user: null,
    document: null,
    template: null,
    isDisabled: false,
    parentErrors: null,
    hidePartySettings: false,
    scope: "external",
  },
)

const emit = defineEmits([ "update:user", "hide-popover", "reset-form" ])

const validateEmail = (str: string) => EMAIL_REGEX.test(str)

const documentStore = useDocumentStore()
const { mau } = storeToRefs(documentStore)

const userStore = useUserStore()
const { userErrorsMap, availableAccountUsers, users } = storeToRefs(userStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const formValidationErrors = ref<Partial<Record<keyof DocumentUser, string[]>>>({})
const backendErrors = computed(() => userErrorsMap.value[localUser.value.uuid])

const isAccountParty = computed(() => !!(parties.value?.find((party) => party.uuid === props.partyUuid)?.account_party_uuid))

const errorsToShow = computed<Partial<Record<keyof DocumentUser, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentUser, string[]>> = {}

    if (props.parentErrors) {
      Object.keys(props.parentErrors)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(props.parentErrors[key] || []) ]
          },
        )
    }

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const emitHideAndReset = ref(false)

const selectedUser = ref<Partial<UiUser>>({ ...toRaw(props.user) } || null)

const emptyUser: Partial<DocumentUser> = Object.freeze({ roles: [ DocumentUserRoleEnum.signatory ] })

const localNewUser = ref<Partial<DocumentUser>>({ ...emptyUser })

const filteredUserFromAccountUserUuid = computed(() => {
  if (!selectedUser.value?.account_user?.uuid) return null
  return users.value?.find((el) => el.account_user?.uuid && el.account_user?.uuid === selectedUser.value?.account_user?.uuid)
})

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const localUser = computed<Partial<DocumentUser>>(
  {
    get: () => {
      let dataToReturn: Partial<DocumentUser> = {}
      if (props.user?.uuid) {
        dataToReturn = props.user || { ...emptyUser }
      } else {
        dataToReturn = localNewUser.value
      }
      return dataToReturn
    },
    set: async (val) => {
      setTimeout(
        async () => {
          if ((!props.user?.uuid && !localUser.value?.uuid) || (!props.partyUuid && !filteredUserFromAccountUserUuid.value?.party_uuid && !selectedUser.value?.party_uuid && !val.party_uuid)) return
          const isValid = await validate(
            {
              mode: "validated-only",
            },
          )
          // removed to enable making a user signatory with one click
          // if (!props.user?.uuid && localUser.value?.account_user?.uuid) return
          const payloadKeys = changedKeys({ ...toRaw(localUser.value) }, val)
          const checkIntersection = intersection(payloadKeys, Object.keys(isValid.errors))
          // Stop update if not valid and the payload includes invalid fields
          if (!isValid.valid && checkIntersection?.length) return
          // We need a leading debounce if we want to update the signatory status and close/reset the popover
          const updateRes = await userStore.updateLocalUser(val, crudContext.value, entityUuid.value, false, emitHideAndReset.value)
          // If we are updating an existing documentUser we need to close the popover and reset the form
          if (updateRes && emitHideAndReset.value) {
            emit("hide-popover")
            emit("reset-form")
            emitHideAndReset.value = false
          }
        },
      )

      localNewUser.value = val
    },
  },
)

const documentUserSchema = z
  .object(
    {
      email: z.string().email().optional(),
      first_name: z.string().nullable().optional(),
      last_name: z.string().nullable().optional(),
      //mobile_phone: z.string().nullable().optional(),
      party_uuid: z.string().nullable().optional(),
      account_user: z.object({
        uuid: z.string().nullable().optional(),
      }).nullable().optional(),
      roles: z.array(z.nativeEnum(DocumentUserRoleEnum)).optional(),
    },
  ).refine( (input) => {

    // make e-mail required for non-account-users
    if ( !input.account_user?.uuid && !selectedUser.value?.email && !input.email ) return false
    // make party uuid required in not passed via prop or on existing user
    if ( !input.party_uuid && !props.partyUuid && !filteredUserFromAccountUserUuid.value?.party_uuid ) return false

    return true

  }, (input) => {
    if (!input.account_user?.uuid && !selectedUser.value?.email && !input.email) {
      return {
        message: "E-mail address is required",
        path: [ "email" ],
      }
    } else if (!input.party_uuid && !props.partyUuid) {
      return {
        message: "Party is required",
        path: [ "party_uuid" ],
      }
    }
  } )

type DocumentUserValidatedType = z.infer<typeof documentUserSchema>

const documentUserValidator = toTypedSchema(documentUserSchema)

const { errors, setValues, setFieldValue, validate, resetForm: resetValidateForm } = useForm<DocumentUserValidatedType>(
  {
    validationSchema: documentUserValidator,
  },
)

const updateFieldValue = (fieldName: keyof DocumentUser, val: any) => {
  setFieldValue(fieldName as any, val)
  localUser.value = {
    ...localUser.value,
    [fieldName]: val,
  }
}

watch(
  () => localUser.value,
  (newVal, oldVal) => {
    if (!newVal) return
    if (localUser.value?.uuid) setValues(newVal)
    if (isEqual(newVal, oldVal)) return
    emit("update:user", newVal)
  },
  {
    deep: true,
    immediate: true,
  },
)

watch(
  ()  => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof DocumentUser, string[]>>,
      )
  },
)

const first_name = useField<string>("first_name")
const last_name = useField<string>("last_name")
const email = useField<string>("email")
const party_uuid = useField<string>("party_uuid")

const localAccountUser = computed(() => {
  if (!localUser.value?.account_user?.uuid) return null
  return availableAccountUsers.value?.find((el) => el.uuid === localUser.value.account_user?.uuid) || localUser.value
})

if (!props.user?.uuid) {
  watch(
    () => selectedUser.value,
    () => {
      const filteredUserFromAccountUserUuid = users.value?.find((el) => el.account_user?.uuid && el.account_user?.uuid === selectedUser.value?.account_user?.uuid)
      if (selectedUser.value?.uuid || filteredUserFromAccountUserUuid) {
        const existingRoles = filteredUserFromAccountUserUuid?.roles || selectedUser.value?.roles || []
        const existingUuid = filteredUserFromAccountUserUuid?.uuid || selectedUser.value?.uuid
        localUser.value = {
          ...omit(toRaw({
            ...selectedUser.value,
            ...{ party_uuid: props.partyUuid || filteredUserFromAccountUserUuid?.party_uuid },
            ...{ uuid: existingUuid },
          }), selectedUser.value?.account_user?.uuid ? [ "roles", "email" ] : [ "roles" ]),
          ...{ roles: [ ...existingRoles, DocumentUserRoleEnum.signatory ] },
        }
        emitHideAndReset.value = true
      } else if (selectedUser.value?.account_user?.uuid) {
        localUser.value = {
          ...omit(toRaw({
            ...selectedUser.value,
            ...{ party_uuid: props.partyUuid || filteredUserFromAccountUserUuid?.party_uuid },
            ...{ account_user: selectedUser.value.account_user },
          }), [ "roles", "email", "uuid" ]),
          ...{ roles: [ DocumentUserRoleEnum.signatory ] },
        }
      } else if (validateEmail(selectedUser.value?.email)) {
        localUser.value = { ...emptyUser }
        updateFieldValue("email", selectedUser.value.email)
      } else {
        localUser.value = { ...emptyUser }
      }
    },
  )
}

const deactivatedUsers = computed(() => {
  if (!!mau.value) {
    return users.value?.filter((el) => ((el.roles && el.roles?.includes(DocumentUserRoleEnum.signatory))))
  }
  return users.value?.filter((el) => (props.partyUuid && el.party_uuid && el.party_uuid !== props.partyUuid) || (el.roles && el.roles?.includes(DocumentUserRoleEnum.signatory)))
})

onBeforeMount(() => {
  // Set field values
  setFieldValue("roles", toRaw(localUser.value?.roles) || [ DocumentUserRoleEnum.signatory ])
})

const resetForm = () => {
  localUser.value = Object.assign({}, emptyUser)
  localNewUser.value = Object.assign({}, emptyUser)
  selectedUser.value = null
  resetValidateForm()
}


const exposeValidate = async (mode: "force" | "validated-only"): Promise<boolean> => {
  const isValid = await validate(
    {
      mode: mode,
    },
  )
  return !!(isValid.valid)
}

const handleUpdatePartyUuid = (partyUuid: string) => {
  updateFieldValue("party_uuid", partyUuid)
}

defineExpose( { resetForm, exposeValidate, updateFieldValue } )

</script>

<template>
  <div>
    <div
      v-if="localUser?.account_user?.uuid || localAccountUser?.uuid"
      class="flex items-center space-x-3 min-w-[16em]"
    >
      <div class="block text-sm font-medium text-gray-700 grow">
        {{ getUserRepresentation(localAccountUser) }}

        <span class="block font-normal text-gray-400">
          {{ localAccountUser?.email }}
        </span>
      </div>
      <img
        class="w-10 h-10 rounded-full shrink-0"
        :src="localAccountUser?.profile_photo_url"
        alt=""
      >

      <span
        v-if="!user.account_user?.uuid"
        class="flex items-center cursor-pointer"
        @click.prevent="selectedUser = null"
      >
        <XMarkIcon
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </div>
    <div v-else>
      <div class="grid grid-cols-2 gap-y-2 gap-x-2">
        <div class="col-span-2">
          <component
            :is="props.user.uuid ? 'div' : 'label'"
            :for="props.user.uuid ? '' : 'email'"
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t('userForm.email') }} <span
              v-if="crudContext !== CrudContext.template"
              class="text-indigo-500"
            >*</span>
          </component>
          <div class="relative z-10 mt-1">
            <div class="flex items-center gap-2">
              <UserCombobox
                v-if="!props.user.uuid"
                v-model:selected-user="selectedUser"
                :is-disabled="isDisabled"
                :scope="isAccountParty || scope === 'internal' ? 'internal' : 'external'"
                :is-account-party="isAccountParty"
                :default-signatory="true"
                align="bottom"
                name="email"
                class="grow"
                :deactivated-users="deactivatedUsers"
                :party-uuid="props.partyUuid"
              />
              <span
                v-else
                type="text"
                class="relative pr-10 truncate grow input-plain"
                :class="{ 'input-has-errors': errorsToShow?.email?.length }"
              >
                {{ email.value.value }}
                <span class="absolute inset-y-0 flex items-center right-2">
                  <LockClosedIcon class="w-4 h-4 shrink-0" />
                </span>
              </span>
              <slot name="deleteButton" />
            </div>

            <FormInputErrors
              v-if="errorsToShow?.email?.length"
              :errors="errorsToShow?.email"
            />
          </div>
        </div>

        <div class="col-span-1">
          <label
            :for="props.user.uuid ? 'signatory-first-name_'+props.user.uuid : 'signatory-first-name'"
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t('userForm.firstName') }}
          </label>
          <div class="mt-1">
            <input
              :id="props.user.uuid ? 'signatory-first-name_'+props.user.uuid : ''"
              v-cy="`user-form-first-name-input`"
              :value="first_name.value.value"
              name="signatory-first-name"
              :disabled="isDisabled"
              :placeholder="$t('userForm.firstNamePlaceholder') + '…'"
              autocomplete="signatory-first-name"
              type="text"
              class="input-plain"
              :class="{ 'input-has-errors': errorsToShow?.first_name?.length }"
              @input="($event) => updateFieldValue('first_name', ($event.target as HTMLInputElement).value)"
            >
          </div>
          <FormInputErrors
            v-if="errorsToShow?.first_name?.length"
            :errors="errorsToShow?.first_name"
          />
        </div>

        <div class="col-span-1">
          <label
            :for="props.user.uuid ? 'signatory-last-name_'+props.user.uuid : 'signatory-last-name'"
            class="block text-sm font-medium text-gray-700"
          >
            {{ $t('userForm.lastName') }}
          </label>
          <div class="mt-1">
            <input
              :id="props.user.uuid ? 'signatory-last-name_'+props.user.uuid : ''"
              v-cy="`user-form-last-name-input`"
              :value="last_name.value.value"
              :disabled="isDisabled"
              name="signatory-last-name"
              :placeholder="$t('userForm.lastNamePlaceholder') + '…'"
              autocomplete="signatory-last-name"
              type="text"
              class="input-plain"
              :class="{ 'input-has-errors': errorsToShow?.last_name?.length }"
              @input="(ev) => updateFieldValue('last_name', (ev.target as HTMLInputElement).value)"
            >
          </div>
          <FormInputErrors
            v-if="errorsToShow?.last_name?.length"
            :errors="errorsToShow?.last_name"
          />
        </div>
      </div>
    </div>

    <UserPartySettings
      v-if="parties?.length && (props.user?.uuid || !props.partyUuid) && !hidePartySettings"
      :party-uuid="party_uuid.value.value"
      :user="localUser"
      :is-disabled="isDisabled"
      @update:party-uuid="handleUpdatePartyUuid"
    >
      <template #errors>
        <FormInputErrors
          v-if="errorsToShow?.party_uuid?.length"
          :errors="errorsToShow?.party_uuid"
        />
      </template>
    </UserPartySettings>

    <div
      v-if="isDisabled"
      class=" border-t border-t-gray-200 -mx-4 pt-4 bg-gray-100 -mb-5 pb-5 rounded-b-md px-4 text-xs texr-gray-500 flex items-center gap-1.5 text-gray-400"
    >
      <LockClosedIcon
        class="w-3 h-3"
        aria-hidden="true"
      />
      {{ !!mau ? $t('userForm.signatoryDisabledExplanation') : $t('userForm.signatoryDisabledExplanationExternal') }}
    </div>
  </div>
</template>
