<script setup lang="ts">
// external
import { CheckCircleIcon, FlagIcon } from "@heroicons/vue/24/solid"
import { computed } from "vue"

// internal
import { PDFIcon, ImportFileIcon } from "~/icons"
import { documentTypes } from "~/utils"
import { SectionHeading, FormInputErrors, AutogrowInput } from "~/components"
import { UiUser, DocumentStage, Stage, Document, DocumentUser, AccountUser, DocumentType, DocumentOrigin } from "~/types"


interface Props {
  stages: Stage[]
  combinedUsers: UiUser[]
  currentStage: DocumentStage
  loading: boolean
  disabled: boolean
  documentName?: string
  documentType?: DocumentType
  documentOrigin: DocumentOrigin
  keysOfIsUpdatingDocumentField?: (keyof Document)[]
  keysOfLastSavedDocumentField?: (keyof Document)[]
  errorsToShow?: Partial<Record<keyof Document, string[]>>
  mdu?: DocumentUser
  mau?: AccountUser
}

const emit = defineEmits([ "focus", "blur", "update:document-name" ])

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    disabled: false,
    keysOfIsUpdatingDocumentField: () => [],
    keysOfLastSavedDocumentField: () => [],
    errorsToShow: null,
    documentName: "",
    documentType: DocumentType.other,
  },
)

const name = computed<string>({
  get: () => props.documentName || "",
  set: (value) => emit("update:document-name", value),
})

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

</script>

<template>
  <SectionHeading :show-demo-cta="false">
    <template #title>
      <div class="flex items-center gap-2">
        <div class="relative hidden w-12 h-12 -ml-2 text-gray-300 shrink-0 md:block">
          <component
            :is="getDocumentTypeInfo(props.documentType || DocumentType.other)?.icon"
            class="w-12 h-12"
            aria-hidden="true"
          />
          <div
            v-if="documentOrigin === 'pdf'"
            class="w-4 h-4 absolute -left-0.5 -bottom-1 text-red-500 bg-white"
          >
            <PDFIcon
              aria-hidden="true"
            />
          </div>
          <div
            v-if="documentOrigin === 'signed_pdf'"
            class="w-4 h-4 absolute -left-0.5 -bottom-1 text-slate-500 bg-white"
          >
            <ImportFileIcon
              aria-hidden="true"
            />
          </div>
        </div>
        <div class="block">
          <div
            id="documentNameInput"
            class="relative md:-top-0.5 inline-flex items-center overflow-hidden md:flex max-w-[calc(100vw-140px)] md:max-w-[calc(100vw-520px)] lg:max-w-[calc(100vw-620px)]"
            data-cy-sel="document-name-input-container"
          >
            <AutogrowInput
              v-if="mau"
              id="input_documentName"
              v-model="name"
              :disabled="disabled"
              :loading="keysOfIsUpdatingDocumentField?.includes('name')"
              :highlight="keysOfLastSavedDocumentField?.includes('name')"
              :readonly="!mdu?.permissions.includes('document_update')"
              text-classes="font-bold min-w-[20px]"
              :placeholder="$t('documents.namePlaceholder')+'…'"
              :w-fit="true"
              @focus="$emit('focus', $event)"
              @blur="$emit('blur', $event)"
            />
            <span
              v-else
              class="text-sm font-bold grow line-clamp-1"
              data-cy-sel="document-name"
            >
              {{ name }}
            </span>
            <FormInputErrors
              v-if="errorsToShow?.name?.length"
              class="relative ml-2 -top-0.5"
              :errors="errorsToShow?.name"
            />
          </div>
          <nav
            v-if="!!mau"
            id="stagesContainer"
            class="flex"
            aria-label="Stages"
          >
            <ol
              role="list"
              class="items-center hidden -mt-1 md:flex"
            >
              <li
                v-for="(step, stepIdx) in stages"
                :key="stepIdx"
                :class="[
                  stepIdx > 0 &&
                    step.name !== DocumentStage.approved_draft &&
                    step.name !== DocumentStage.approved_review
                    ? 'lg:ml-3'
                    : '',
                  step.name === currentStage
                    ? '' : 'hidden lg:block'
                ]
                "
              >
                <div
                  v-if="
                    step.name === currentStage &&
                      step.name !== DocumentStage.approved_draft &&
                      step.name !== DocumentStage.approved_review
                  "
                  :id="'stageIndicator_' + step.name"
                  class="flex items-center gap-2"
                  aria-current="step"
                  tabindex="0"
                >
                  <span
                    class="relative flex items-center justify-center w-5 h-5 shrink-0"
                    aria-hidden="true"
                  >
                    <span
                      class="absolute w-4 h-4 rounded-full"
                      :class="step.colors.outer"
                    />
                    <span
                      class="relative block w-2 h-2 rounded-full"
                      :class="step.colors.inner"
                    />
                  </span>
                  <span class="text-sm font-medium text-gray-900">
                    {{ $t(`documents.stages.${step.name}`) }}
                  </span>
                </div>
                <div
                  v-else-if="
                    (step.name === DocumentStage.review &&
                      step.status !== 'complete' &&
                      stages[stepIdx + 1]?.name === DocumentStage.approved_review &&
                      stages[stepIdx - 1]?.status !== 'incomplete') ||
                      (step.name === DocumentStage.draft &&
                        step.status !== 'complete' &&
                        stages[stepIdx + 1]?.name === DocumentStage.approved_draft &&
                        stages[stepIdx - 1]?.status !== 'incomplete')
                  "
                  class="flex items-center gap-2"
                  aria-current="step"
                >
                  <span
                    class="relative flex items-center justify-center w-5 h-5 shrink-0"
                    aria-hidden="true"
                  >
                    <span class="absolute w-4 h-4 bg-indigo-200 rounded-full" />
                    <span class="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                  </span>
                  <span class="text-sm font-medium text-indigo-600">
                    {{ $t(`documents.stages.${step.name}`) }}
                  </span>
                </div>
                <div
                  v-else-if="
                    step.name !== DocumentStage.approved_draft &&
                      step.name !== DocumentStage.approved_review &&
                      step.status === 'complete'
                  "
                >
                  <span class="flex items-center gap-2">
                    <span class="relative flex items-center justify-center w-5 h-5 shrink-0">
                      <CheckCircleIcon
                        class="w-4 h-4 text-gray-300"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="text-sm font-medium text-gray-400">
                      {{ $t(`documents.stages.${step.name}`) }}
                    </span>
                  </span>
                </div>
                <div
                  v-else-if="
                    step.name === DocumentStage.approved_draft || step.name === DocumentStage.approved_review
                  "
                >
                  <span class="relative flex items-center gap-2 pl-6 ml-2">
                    <span
                      :class="
                        step.status !== 'active' && step.status !== 'complete'
                          ? 'border-t-gray-300'
                          : step.status !== 'active' &&
                            !(
                              step.name === DocumentStage.approved_draft &&
                              currentStage === DocumentStage.approved_draft
                            ) &&
                            !(
                              step.name === DocumentStage.approved_review &&
                              currentStage === DocumentStage.approved_review
                            )
                            ? 'border-t-gray-400'
                            : 'border-t-indigo-400'
                      "
                      class="absolute left-0 w-5 border-t-2 border-dotted top-1/2"
                    />
                    <span
                      :id="'stageIndicator_' + step.name"
                      :class="
                        step.status !== 'active' && step.status !== 'complete'
                          ? 'text-gray-400'
                          : step.status !== 'active' &&
                            !(
                              step.name === DocumentStage.approved_draft &&
                              currentStage === DocumentStage.approved_draft
                            ) &&
                            !(
                              step.name === DocumentStage.approved_review &&
                              currentStage === DocumentStage.approved_review
                            )
                            ? 'text-gray-400'
                            : 'text-indigo-500 bg-indigo-100'
                      "
                      class="rounded-full p-1.5 -my-1"
                      :data-tippy-content="
                        $t('documents.checkpointBefore') + ': ' + $t(`documents.stages.${stages?.[stepIdx + 1]?.name}`)
                      "
                      data-tippy-context
                      data-placement="bottom"
                      tabindex="0"
                    >
                      <FlagIcon
                        class="h-3.5 w-3.5"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                </div>
                <div v-else>
                  <div class="flex items-center gap-2">
                    <div
                      class="relative flex items-center justify-center w-5 h-5 shrink-0"
                      aria-hidden="true"
                    >
                      <div class="w-2 h-2 bg-gray-300 rounded-full" />
                    </div>
                    <p class="text-sm font-medium text-gray-400">
                      {{ $t(`documents.stages.${step.name}`) }}
                    </p>
                  </div>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </template>
    <template #action-items>
      <div class="flex items-center gap-2 ml-4">
        <slot name="action-items" />
      </div>
    </template>
  </SectionHeading>
</template>
