<script setup lang="ts">
import { ArrowRightOnRectangleIcon, TrashIcon, PencilIcon } from "@heroicons/vue/24/outline"
import { HomeIcon } from "@heroicons/vue/24/solid"
import { Party, PartyEntityType } from "~/types"
import { changedKeys, entityTypeOptions, formatAddress } from "~/utils"
import { AccountPartyCombobox, FormInputErrors, PartyEntityTypeDisplayIcon, RadioGroupPills } from "~/components"
import { ref } from "vue"
import { z } from "zod"
import { toTypedSchema } from "@vee-validate/zod"
import { useField, useForm } from "vee-validate"
import { useI18n } from "vue-i18n"
import { computed } from "vue"
import { watch } from "vue"
import { toRaw } from "vue"
import { intersection } from "lodash-es"
import { usePartyStore } from "~/stores"
import { storeToRefs } from "pinia"

const { t } = useI18n()

interface Props {
  party: Partial<Party>
  partyIdx: string | number
}

const props = withDefaults(
  defineProps<Props>(),
  {
    party: null,
  },
)

const emit = defineEmits([ "update:party", "remove-party", "reset-party" ])

const partyStore = usePartyStore()
const { accountParties } = storeToRefs(partyStore)

const accountParty = computed(() => {
  if (!accountParties.value) return
  if (props.party?.account_party_uuid) {
    return accountParties.value?.find((el) => el.uuid === props.party?.account_party_uuid)
  }
  return null
})

const isActiveEditMode = ref(false)

// Prepare validation and error handling
// for updates on the party

const formValidationerrors = ref<Partial<Record<keyof Party, string[]>>>({})

const partySchema = z
  .object(
    {
      name: z.string().optional(),
      entity_name: z.string().nullable().optional(),
      address: z.string().nullable().optional(),
      entity_type: z.nativeEnum(PartyEntityType).nullable().optional(),
      account_party_uuid: z.string().nullable().optional(),
    },
  ).refine( (input) => {

    // Entity type required when account party is not set
    if ( typeof input.entity_type === "undefined" && !input.account_party_uuid) return false

    // Entity name required when name is empty
    if ( !input.entity_name && !input.name && !input.account_party_uuid) return false

    return true

  }, (input) => {

    if (typeof input.entity_type === "undefined" && !input.account_party_uuid) {
      return {
        message: t("partyForm.errors.entityType"),
        path: [ "entity_type" ],
      }
    }
    if ( !input.entity_name && !input.name && !input.account_party_uuid) {
      return {
        message: t("partyForm.errors.entityName"),
        path: [ "entity_name" ],
      }
    }
  } )

type PartyValidatedType = z.infer<typeof partySchema>


const partyValidator = toTypedSchema(partySchema)

const { errors, setValues, setFieldValue, validate } = useForm<PartyValidatedType>(
  {
    validationSchema: partyValidator,
  },
)

const errorsToShow = computed<Partial<Record<keyof Party, string[]>>>(
  () => {
    const errors: Partial<Record<keyof Party, string[]>> = {}

    if (formValidationerrors.value) {
      Object.keys(formValidationerrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationerrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationerrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof Party, string[]>>,
      )
  },
)

// Party form fields
const name = useField<string>("name")
const entity_name = useField<string>("entity_name")
const address = useField<string>("address")
const entity_type = useField<PartyEntityType>("entity_type")
const account_party_uuid = useField<string>("account_party_uuid")

const localParty = computed<Partial<Party>>({
  get: () => {
    return props.party
  },
  set: (val) => {
    setTimeout(
      async () => {
        const isValid = await validate(
          {
            mode: "validated-only",
          },
        )
        const payloadKeys = changedKeys({ ...toRaw(localParty.value) }, val)
        const checkIntersection = intersection(payloadKeys, Object.keys(isValid.errors))
        // Stop update if not valid and the payload includes invalid fields
        if (!isValid.valid && checkIntersection?.length) return
        emit("update:party", {
          ...val,
        })
      },
    )
  },
})

const updatePartyFieldValue = (fieldName: string, val: any) => {
  setFieldValue(fieldName as any, val)
  localParty.value = {
    ...localParty.value,
    [fieldName]: val,
  }
}

watch(
  () => localParty.value,
  (newVal) => {
    if (!newVal) return
    setValues(newVal)
  },
  {
    deep: true,
    immediate: true,
  },
)

const resetParty = () => {
  emit("reset-party")
}

const handleUpdateSelectedParty = (party: Party) => {
  if (!party?.uuid && !party?.entity_name) {
    resetParty()
    return
  }
  if (party?.uuid && !accountParties.value?.some((el) => el.uuid === party?.uuid)) return
  if (party?.uuid) {
    updatePartyFieldValue("account_party_uuid", party?.uuid)
  } else {
    updatePartyFieldValue("entity_name", party?.entity_name)
    if (party?.address) {
      updatePartyFieldValue("address", party?.address)
    }
  }
}

const selectedParty = computed<Party | null>(() => {
  let partyToReturn = null
  if (account_party_uuid.value.value) {
    const accountParty = accountParties.value?.find((el) => el.uuid === account_party_uuid.value.value)
    partyToReturn = (accountParty as Party)
  } else {
    partyToReturn = localParty.value
  }
  return partyToReturn
})

const entityTypeOptionsToOmit = [ PartyEntityType.tbd ]

const entityTypeOptionsFiltered = computed<any>(() => {
  return entityTypeOptions.filter((el) => {
    return !entityTypeOptionsToOmit.includes(el.value)
  }).map((el) => {
    if (el.value === PartyEntityType.tbd) {
      return { ...el, value: null }
    } else {
      return el
    }
  })
})

</script>

<template>
  <div
    class="relative flex items-start p-3 space-x-3 bg-white border border-gray-300 rounded-lg shadow-sm group"
    :class="isActiveEditMode ? 'border-indigo-600' : ''"
  >
    <div
      v-if="!isActiveEditMode"
      class="truncate grow"
    >
      <h3 class="text-indigo-600 font-medium uppercase text-xs tracking-wide rounded-full p-0.5 flex justify-between items-center">
        <div class="p-1 flex-none rounded-full text-indigo-100 bg-indigo-500 mr-1.5 grow-0">
          <PartyEntityTypeDisplayIcon
            :party="party"
            class="w-3 h-3"
          />
        </div>
        <span class="truncate grow">
          {{ party.name }}
        </span>
        <span
          v-if="party.account_party_uuid"
          class="inline-flex items-center gap-1 text-xs font-normal tracking-normal text-gray-500 normal-case"
        >
          <HomeIcon
            class="w-3.5 h-3.5"
          />
          {{ $t('partyForm.accountPartyLabel') }}
        </span>
        <span
          v-else
          class="inline-flex items-center gap-1 text-xs font-normal tracking-normal text-gray-500 normal-case"
        >
          <ArrowRightOnRectangleIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.external') }}
        </span>
        <button
          type="button"
          class="shrink-0 p-1 ml-3 -my-0.5 text-sm text-gray-500 rounded-full hover:bg-indigo-200 hover:text-indigo-700"
          @click.prevent="isActiveEditMode = true"
        >
          <PencilIcon
            aria-hidden="true"
            class="w-4 h-4"
          />
        </button>
        <button
          type="button"
          class="shrink-0 p-1 ml-2 -my-0.5 text-sm text-gray-500 rounded-full hover:bg-red-200 hover:text-red-700"
          @click.prevent="emit('remove-party', partyIdx)"
        >
          <TrashIcon
            aria-hidden="true"
            class="w-4 h-4"
          />
        </button>
      </h3>
      <div
        class="text-sm"
      >
        <div v-if="!accountParty">
          <p class="font-medium text-gray-900 whitespace-normal">
            {{ party.entity_name || '[ Name ]' }}
          </p>
          <p class="text-xs font-normal text-gray-500 truncate">
            {{ party.address ? formatAddress(party.address) : '[ Address ]' }}
          </p>
        </div>
        <div v-else>
          <p class="font-medium text-gray-900 whitespace-normal">
            {{ accountParty.entity_name || '[ Name ]' }}
          </p>
          <p class="text-xs font-normal text-gray-500 truncate">
            {{ accountParty.address ? formatAddress(accountParty.address) : '[ Address ]' }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="grid space-y-3 grow"
    >
      <div class="flex items-center justify-between gap-4 p-3 -mx-3 -mt-3 bg-indigo-600 rounded-t-md">
        <div class="relative grow">
          <input
            id="party-name"
            :data-cy-sel="`party-name-input`"
            :value="name.value.value"
            :placeholder="$t('partyForm.titlePlaceholder')"
            type="text"
            name="party-name"
            class="inset-x-0 p-1 font-medium text-white placeholder-indigo-300 bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 input-plain"
            :class="{ 'input-has-errors': errorsToShow?.name?.length }"
            @input="($event) => updatePartyFieldValue('name', ($event.target as HTMLInputElement).value)"
          >
        </div>
        <button
          type="button"
          class="btn-primary btn-sm flex items-center gap-1.5"
          @click.prevent="isActiveEditMode = false"
        >
          {{ $t('common.done') }}
        </button>
      </div>
      <div v-if="!accountParty">
        <label
          class="font-medium"
          for="party-entity-type"
        >
          {{ $t('partyForm.entityType') }}
        </label>
        <div class="mt-1">
          <RadioGroupPills
            :model-value="entity_type.value.value"
            :options="entityTypeOptionsFiltered"
            :sr-label="$t('partyForm.selectEntityType')"
            :grid-class="`grid grid-cols-${entityTypeOptionsFiltered.length} gap-3`"
            :disabled="!!accountParty"
            :allow-null="true"
            @update:model-value="($event) => updatePartyFieldValue('entity_type', $event)"
          />
        </div>
        <FormInputErrors
          v-if="errorsToShow?.entity_type?.length"
          :errors="errorsToShow?.entity_type"
        />
      </div>
      <div>
        <label
          class="font-medium"
          for="party-entity-name"
        >
          {{ $t('partyForm.companyName') }}
        </label>
        <div class="mt-1">
          <input
            v-if="props.party?.uuid && !props.party?.account_party_uuid"
            id="party-entity-name"
            :data-cy-sel="`party-entity-name-input`"
            :value="entity_name.value.value"
            :disabled="!!props.party?.account_party_uuid"
            autocomplete="party-entity-name"
            :placeholder="$t('partyForm.companyNamePlaceholder') + '…'"
            type="text"
            name="party-entity-name"
            class="input-plain"
            :class="[errorsToShow?.entity_name?.length ? 'input-has-errors' : '']"
            @input="($event) => updatePartyFieldValue('entity_name', ($event.target as HTMLInputElement).value)"
          >
          <AccountPartyCombobox
            v-else
            :data-cy-sel="`party-entity-name-input`"
            :account-parties="accountParties"
            :selected-party="selectedParty"
            :is-disabled="!!(props.party?.uuid)"
            :allow-deselect="true"
            @update:selected-party="handleUpdateSelectedParty"
          />
        </div>
        <FormInputErrors
          v-if="errorsToShow?.entity_name?.length"
          :errors="errorsToShow?.entity_name"
        />
      </div>
      <div v-if="!accountParty">
        <label
          class="mt-1 font-medium"
          for="party-address"
        >
          {{ $t('partyForm.companyAddress') }}
        </label>
        <div
          class="mt-1 grow-textarea"
          :data-replicated-value="address.value.value"
        >
          <textarea
            id="party-address"
            :data-cy-sel="`party-address-input`"
            :value="address.value.value"
            autocomplete="party-address"
            :placeholder="$t('partyForm.companyAddressPlaceholder') + '…'"
            name="party-address"
            :class="[errorsToShow?.address?.length ? 'input-has-errors' : '']"
            class="input-plain"
            @input="($event) => updatePartyFieldValue('address', ($event.target as HTMLTextAreaElement).value)"
          />
        </div>
        <FormInputErrors
          v-if="errorsToShow?.address?.length"
          :errors="errorsToShow?.address"
        />
      </div>
    </div>
  </div>
</template>
