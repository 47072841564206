<script setup lang="ts">
// external
import { computed } from "vue"

// internal
import { DocumentNotificationRecurringCondition } from "~/types"
import { DurationInput } from "~/components"

interface Props {
  hasError?: boolean
  recurringCondition?: DocumentNotificationRecurringCondition
}

const props = withDefaults(
  defineProps<Props>(),
  {
    hasError: false,
    triggerTime: null,
    recurringCondition: null,
  },
)

const localRecurringCondition = computed({
  get: () => props.recurringCondition,
  set: (recurringCondition) => emits("update:recurring-condition", recurringCondition),
})

const emits = defineEmits([ "update:recurring-condition" ])

</script>

<template>
  <fieldset
    class="relative"
  >
    <h3 class="relative flex items-center w-full h-px px-4 mt-4 mb-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-white">
      <span class="px-2 py-0.5 text-indigo-500 bg-white">{{ $t('documentNotifications.recurringInterval') }}</span>
    </h3>
    <div class="px-6 py-2">
      <DurationInput
        v-model="localRecurringCondition.interval"
        class="flex w-auto"
        :clearable="false"
      />
    </div>
  </fieldset>
</template>
