<script setup lang="ts">
// external
import { ref, computed } from "vue"
import { storeToRefs } from "pinia"
import { ArchiveBoxIcon, ArrowDownTrayIcon, ArrowTopRightOnSquareIcon, TrashIcon, EyeIcon } from "@heroicons/vue/24/outline"
import { EllipsisHorizontalIcon, PencilIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
// internal
import { AutogrowInput, Dropdown, EmptyState, FileInput, FormInputErrors, OverlayScrollbar, SkeletonLoader, SpinLoader } from "~/components"
import { StoredFile, Document, Template } from "~/types"
import { useFileStorageStore, useDocumentStore, useSharedStore, useCommentStore } from "~/stores"
import { formatBytes, getFileTypeIconByMimeType } from "~/utils"
import { SaveIcon } from "~/icons"
import { MenuButton } from "@headlessui/vue"
import { DropdownLink } from "./base"

interface Props {
  document?: Document
  template?: Template
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
  },
)

interface DocumentPayload {
  file_uuid: string
}

const documentStore = useDocumentStore()
const { mau, mdu } = storeToRefs(documentStore)

const fileStorageStore = useFileStorageStore()
const { visibleStoredFiles, uuidOfIsUpdatingStoredFile, storedFiles, backendErrors, isLoadingSaveStoredFileCount, isLoadingStoredFiles, uuidOfIsRemovingStoredFile, isActiveEditStoredFile, uuidOfIsLoadingGetStoredFileUrl  } = storeToRefs(fileStorageStore)
const { uploadStoredFile, updateStoredFile, removeStoredFile, handleShowStoredFile, isViewable } = fileStorageStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const commentStore = useCommentStore()
const { comments } = storeToRefs(commentStore)

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return props?.[crudContext.value]?.uuid
})

const errorsToShow = computed<Partial<Record<keyof DocumentPayload, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentPayload, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const handleUploadStoredFile = async (file_uuid: string, file_name: string): Promise<void> => {
  uploadStoredFile(crudContext.value, entityUuid.value, file_uuid, file_name)
}

const storedFileNames = ref<Record<StoredFile["uuid"], string>>({})

const handleSetStoredFileName = (storedFileUuid: StoredFile["uuid"], name: string): void => {
  storedFileNames.value[storedFileUuid] = name
}

const handleSaveStoredFile = async (storedFileUuid: StoredFile["uuid"]): Promise<void> => {
  const updatedFilename = storedFileNames.value[storedFileUuid] || storedFiles.value.find((storedFile) => storedFile.uuid === storedFileUuid)?.filename
  const payload = { filename: updatedFilename }
  await updateStoredFile(crudContext.value, entityUuid.value, storedFileUuid, payload)
  isActiveEditStoredFile.value = null
}

const optionsDropdown = ref<any>()

const close = (idx: number) => {
  optionsDropdown.value?.[idx]?.closeWorkaround()
}

const checkIfFileIsVisibleExternally = (storedFileUuid: StoredFile["uuid"]): boolean => {
  const storedFile = storedFiles.value?.find((storedFile) => storedFile.uuid === storedFileUuid)
  if (!storedFile?.comment_uuid) return false
  const comment = comments.value?.find((comment) => comment.uuid === storedFile.comment_uuid)
  if (!comment) return false
  if (comment.comment_uuid) {
    const parentComment = comments.value?.find((c) => c.uuid === comment.comment_uuid)
    if (!parentComment) return false
    return !!(parentComment.scope === "internal_and_external" && comment.scope === "internal_and_external")
  }
  return comment.scope === "internal_and_external"
}

const getFileNameWithoutExtention = (fileName) => {
  const parts = fileName.split(".")
  parts.pop()
  return parts.join()
}
const getExtension = (fileName) => {
  const parts = fileName.split(".")
  return parts.pop()
}

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="px-6 pt-6 shrink-0"
      :class="!visibleStoredFiles?.length ? 'border-b border-b-gray-200' : ''"
    >
      <h3
        class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('fileStorage.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('fileStorage.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <OverlayScrollbar
      ref="fileStorageScrollContainer"
      tag="div"
      class="flex-1 overflow-y-auto max-h-max"
    >
      <div
        v-if="isLoadingStoredFiles"
        class="px-6 pt-6"
      >
        <SkeletonLoader
          size="large"
        />
      </div>
      <template v-else-if="visibleStoredFiles?.length">
        <ul
          role="list"
          class="border-t border-b border-gray-200 divide-y divide-gray-200"
        >
          <li
            v-for="storedFile, storedFileUuidx in visibleStoredFiles"
            :key="storedFile.uuid"
          >
            <a
              class="flex flex-col items-center justify-between w-full gap-3 px-6 py-2 text-sm leading-6 text-left cursor-pointer group md:flex-row hover:bg-gray-100"
              @click.prevent="handleShowStoredFile(storedFile.uuid, false, crudContext, entityUuid)"
            >
              <div class="flex items-center flex-1 truncate md:w-0">
                <component
                  :is="getFileTypeIconByMimeType(storedFile.mime_type)"
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
                <div class="flex flex-col flex-1 ml-2.5 truncate">
                  <div
                    class="inline-flex items-center min-w-0 gap-2"
                    :class="isActiveEditStoredFile === storedFile.uuid ? 'bg-white px-1 mb-1 rounded-md border border-gray-200' : ''"
                    @click="$event => {
                      if(!!mau || isActiveEditStoredFile !== storedFile.uuid) return
                      $event.stopImmediatePropagation()
                    }"
                  >
                    <template
                      v-if="mau && isActiveEditStoredFile === storedFile.uuid"
                    >
                      <AutogrowInput
                        :id="'input_documentStoredFileName_'+storedFile.uuid"
                        :model-value="getFileNameWithoutExtention(storedFileNames[storedFile.uuid] || storedFile.filename)"
                        :loading="uuidOfIsUpdatingStoredFile === storedFile.uuid"
                        text-classes="min-w-[11rem]"
                        :placeholder="$t('fileStorage.storedFileNamePlaceholder')+'…'"
                        :stop-propagation="true"
                        @update:model-value="handleSetStoredFileName(storedFile.uuid, `${$event}.${getExtension(storedFileNames[storedFile.uuid] || storedFile.filename)}`)"
                        @enter="() => handleSaveStoredFile(storedFile.uuid)"
                      />
                      <span class="text-sm">.{{ getExtension(storedFileNames[storedFile.uuid] || storedFile.filename) }}</span>
                    </template>

                    <span
                      v-else
                      class="text-xs font-medium truncate"
                    >
                      {{ storedFile.filename }}
                    </span>
                    <button
                      v-if="mau && ((mdu && mdu?.permissions.includes('stored_file_update')) || !props.document?.uuid) && isActiveEditStoredFile === storedFile.uuid"
                      class="shrink-0 flex items-center justify-center w-6 h-6 p-0 -ml-1 text-gray-400 rounded-full focus:ring-0 focus:ring-offset-0 btn-plain hover:bg-indigo-100 hover:bg-text-700 hover:ring-indigo-100 hover:text-indigo-500"
                      :disabled="uuidOfIsUpdatingStoredFile === storedFile.uuid"
                      @click.prevent="() => handleSaveStoredFile(storedFile.uuid)"
                    >
                      <SpinLoader
                        v-if="uuidOfIsUpdatingStoredFile === storedFile.uuid && isActiveEditStoredFile === storedFile.uuid"
                        class="w-3.5 h-3.5 shrink-0"
                        aria-hidden="true"
                      />
                      <SaveIcon
                        v-else
                        class="w-3.5 h-3.5 text-indigo-500 shrink-0"
                        aria-hidden="true"
                      />
                    </button>
                    <span
                      v-else-if="checkIfFileIsVisibleExternally(storedFile.uuid)"
                      class="-ml-0.5"
                      data-tippy-help
                      data-placement="top"
                      :data-tippy-content="$t('fileStorage.visibleExternally')"
                    >
                      <EyeIcon
                        class="w-3.5 h-3.5 shrink-0"
                      />
                    </span>
                  </div>
                  <span class="text-xs text-gray-400 shrink-0">{{ formatBytes(storedFile.file_size) }}</span>
                </div>
              </div>
              <div
                class="relative"
                @click="$event => $event.stopImmediatePropagation()"
              >
                <Dropdown
                  ref="optionsDropdown"
                  menu-classes="text-left"
                  width="w-fit"
                  align="right"
                >
                  <template #trigger>
                    <MenuButton
                      class="btn-plain group-hover:bg-gray-100 group-hover:hover:bg-gray-200 group-hover:text-gray-500 rounded-full p-0.5 hover:bg-gray-100 text-gray-400 hover:text-gray-900 focus:text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:bg-gray-200"
                    >
                      <EllipsisHorizontalIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </template>

                  <template #content>
                    <DropdownLink
                      v-if="isViewable(storedFile.filename)"
                      as="button"
                      :disabled="uuidOfIsLoadingGetStoredFileUrl === storedFile.uuid"
                      :icon="true"
                      @click="handleShowStoredFile(storedFile.uuid, false, crudContext, entityUuid)"
                    >
                      <ArrowTopRightOnSquareIcon
                        v-if="uuidOfIsLoadingGetStoredFileUrl !== storedFile.uuid"
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <SpinLoader
                        v-else
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <span class="flex-grow whitespace-nowrap">
                        {{ $t('fileStorage.show') }}
                      </span>
                    </DropdownLink>
                    <DropdownLink
                      as="button"
                      :disabled="uuidOfIsLoadingGetStoredFileUrl === storedFile.uuid"
                      :icon="true"
                      @click="handleShowStoredFile(storedFile.uuid, true, crudContext, entityUuid)"
                    >
                      <ArrowDownTrayIcon
                        v-if="uuidOfIsLoadingGetStoredFileUrl !== storedFile.uuid"
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <SpinLoader
                        v-else
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <span class="flex-grow whitespace-nowrap">
                        {{ $t('fileStorage.download') }}
                      </span>
                    </DropdownLink>
                    <DropdownLink
                      v-if="!!mau && ((mdu && mdu?.permissions.includes('stored_file_update')) || !props.document?.uuid)"
                      as="button"
                      :disabled="uuidOfIsUpdatingStoredFile === storedFile.uuid || isActiveEditStoredFile === storedFile.uuid"
                      :icon="true"
                      @click="() => {
                        isActiveEditStoredFile = storedFile.uuid
                        close(storedFileUuidx)
                      }"
                    >
                      <PencilIcon
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <span class="flex-grow whitespace-nowrap">
                        {{ $t('common.edit') }}
                      </span>
                    </DropdownLink>
                    <DropdownLink
                      v-if="!!mau && ((mdu && mdu?.permissions.includes('stored_file_delete')) || !props.document?.uuid)"
                      as="button"
                      :disabled="uuidOfIsRemovingStoredFile === storedFile.uuid"
                      :icon="true"
                      @click="removeStoredFile(crudContext, entityUuid, storedFile.uuid)"
                    >
                      <TrashIcon
                        v-if="uuidOfIsRemovingStoredFile !== storedFile.uuid"
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <SpinLoader
                        v-else
                        class="w-4 h-4 mr-2 shrink-0"
                        aria-hidden="true"
                      />
                      <span class="flex-grow whitespace-nowrap">
                        {{ $t('common.remove') }}
                      </span>
                    </DropdownLink>
                  </template>
                </Dropdown>
              </div>
            </a>
          </li>
        </ul>
      </template>
      <div
        v-if="!!mau && ((mdu && mdu.permissions?.includes('stored_file_create')) || !props.document?.uuid)"
        class="p-6 text-sm"
      >
        <FileInput
          :upload-callback="handleUploadStoredFile"
          :form-errors="errorsToShow"
          :multiple="true"
          :accept="'.doc, .docx, application/xml, .xls, .xlsx, .csv, .ppt, .pptx, message/rfc822, .txt, application/pdf, image/*, .eml, .rtf, .msg, application/vnd.ms-outlook, .zip, application/x-zip, application/zip'"
          :allowed-extensions="'/\.(docx?|xml|xlsx?|pptx?|eml|txt|csv|pdf|jpe?g|png|gif|msg|zip|rtf)$/i'"
          :empty-list-after-upload="true"
          :parent-pending="isLoadingSaveStoredFileCount[0] > 0"
          :file-type-error="$t('fileStorage.storedFileFileTypeError')"
          :placeholder="$t('documents.fileInputPlaceholder')"
        />
        <FormInputErrors
          v-if="errorsToShow?.file_uuid?.length"
          :errors="errorsToShow?.file_uuid"
        />
      </div>
      <div v-else-if="!visibleStoredFiles?.length">
        <EmptyState>
          <template #icon>
            <ArchiveBoxIcon
              class="w-12 h-12 mx-auto text-gray-400"
              aria-hidden="true"
            />
          </template>

          {{ $t('fileStorage.noStoredFiles') }}.
        </EmptyState>
      </div>
    </OverlayScrollbar>
  </div>
</template>
