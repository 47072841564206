import { Node } from "@tiptap/pm/model"
import { DocxSerializerState } from "prosemirror-docx"
import {
  IBorderOptions,
  ITableOptions,
  ITableBordersOptions,
  BorderStyle,
  ITableCellOptions,
  ITableRowOptions,
} from "docx"
import * as docx_1 from "docx"

type MutableITableOptions = {
  -readonly [key in keyof ITableOptions]: ITableOptions[key];
}

type MutableITableCellOptions = {
  -readonly [key in keyof ITableCellOptions]: ITableCellOptions[key];
}


const MAX_IMAGE_WIDTH = 1000

export const table = (node: Node, state: DocxSerializerState) => {
  const isBordered = node.attrs.isBordered
  const borderSetting: IBorderOptions = {
    style: BorderStyle.SINGLE,
    size: 10,
    color: "D1D5DB",
  }
  const borderStyles: ITableBordersOptions = {
    top: borderSetting,
    bottom: borderSetting,
    left: borderSetting,
    right: borderSetting,
    insideHorizontal: borderSetting,
    insideVertical: borderSetting,
  }
  const actualChildren = state.children
  const rows = []
  node.content.forEach(({ content: rowContent }) => {
    const cells = []
    // Check if all cells are headers in this row
    let tableHeader = true
    rowContent.forEach((cell) => {
      if (cell.type.name !== "tableHeader") {
        tableHeader = false
      }
    })
    // This scales images inside of tables
    state.maxImageWidth = MAX_IMAGE_WIDTH / rowContent.childCount
    rowContent.forEach((cell) => {
      let _a, _b
      state.children = []
      if (tableHeader) {
        state.addRunOptions({
          bold: true,
        })
      }
      state.renderContent(cell, { spacing: { after: 140, before: 60 } })
      const tableCellOpts: MutableITableCellOptions = { children: state.children }
      const colspan = (_a = cell.attrs.colspan) !== null && _a !== void 0 ? _a : 1
      const rowspan = (_b = cell.attrs.rowspan) !== null && _b !== void 0 ? _b : 1
      if (colspan > 1)
        tableCellOpts.columnSpan = colspan
      if (rowspan > 1)
        tableCellOpts.rowSpan = rowspan
      if (tableHeader) {
        tableCellOpts.shading = {
          fill: "F3F4F6",
        }
      }
      if (cell.attrs.bgColor) {
        tableCellOpts.shading = {
          fill: cell.attrs.bgColor,
        }
      }
      if (cell.attrs.colwidth?.length) {
        tableCellOpts.width = {
          // 1 / 96 dpi * 72 points per inch * 20 twips per point = ~15
          size: cell.attrs.colwidth[0] * 15,
          type: docx_1.WidthType.DXA,
        }
      }
      tableCellOpts.margins = {
        top: 20,
        right: 100,
        bottom: 20,
        left: 100,
      }
      tableCellOpts.verticalAlign = docx_1.VerticalAlign.TOP
      cells.push(new docx_1.TableCell(tableCellOpts))
    })
    const tableRowOptions: ITableRowOptions = {
      children: cells,
      tableHeader: tableHeader,
    }
    rows.push(new docx_1.TableRow(tableRowOptions))
  })
  state.maxImageWidth = MAX_IMAGE_WIDTH
  const tableOpts: MutableITableOptions = {
    rows,
    width: {
      size: "100%",
    },
  }
  if (isBordered) {
    tableOpts.borders = borderStyles
  }
  const table = new docx_1.Table(tableOpts)
  actualChildren.push(table)
  // If there are multiple tables, this seperates them
  actualChildren.push(new docx_1.Paragraph(""))
  state.children = actualChildren
}
