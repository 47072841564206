<script setup lang="ts">
// external
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { DocumentDuplicateIcon, HomeIcon } from "@heroicons/vue/24/outline"
import { CheckIcon, ChevronUpDownIcon, QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { computed, onMounted, ref, watch } from "vue"
import axios from "axios"
import { z } from "zod"
import { useField, useForm } from "vee-validate"
import { toTypedSchema } from "@vee-validate/zod"
import { useI18n } from "vue-i18n"

// internal
import { FileInput, FormInputErrors } from "~/components"
import { Team, Document, Party, Template, DocumentType } from "~/types"
import { useNotificationStore } from "~/stores"
import { documentTypes } from "~/utils"

interface Props {
  accountParties: Party[]
  teams?: Team[]
  templates?: Template[]
  isUploadPdf?: boolean
  isSelectTemplate?: boolean
  isExtended?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    teams: () => [],
    templates: () => [],
    isUploadPdf: false,
    isSelectTemplate: false,
    isExtended: false,
  },
)

const { notify } = useNotificationStore()
const { t } = useI18n()

const documentFormSchema = z
  .object(
    {
      name: z.string().optional(),
      account_party_uuid: z.string().nullable().optional(),
      template_uuid: z.string().optional(),
      pdf_uuid: z.string().optional(),
      pdf_file_name: z.string().optional(),
    },
  ).refine( (input) => {
    // name is required if no template or file upload
    if ( !props.isUploadPdf && !input.name ) return false
    // file required when "isUploadPdf"
    if ( props.isUploadPdf && (!input.pdf_uuid || !input.pdf_file_name)) return false
    // party uuid is required when uploading a signed pdf
    if ( (!props.isUploadPdf || props.isExtended) && !input.account_party_uuid ) return false
    return true
  }, (input) => {
    if ( !props.isUploadPdf && !input.name ) {
      return {
        message: t("documents.create.errors.name"),
        path: [ "name" ],
      }
    } else if (props.isUploadPdf && (!input.pdf_uuid || !input.pdf_file_name)) {
      return {
        message: t("documents.create.errors.pdf_uuid"),
        path: [ "pdf_uuid" ],
      }
    } else if ((!props.isUploadPdf || props.isExtended) && !input.account_party_uuid) {
      return {
        message: t("documents.create.errors.account_party_uuid"),
        path: [ "account_party_uuid" ],
      }
    }
  })

type DocumentFormValidatedType = z.infer<typeof documentFormSchema>

const documentFormValidator = toTypedSchema(documentFormSchema)

const { errors, setFieldValue, resetForm, validate } = useForm<DocumentFormValidatedType>(
  {
    validationSchema: documentFormValidator,
  },
)

const formValidationErrors = ref<Partial<Record<keyof DocumentFormValidatedType, string[]>>>({})
const backendErrors = ref<Partial<Record<keyof DocumentFormValidatedType, string[]>>>({})

const name = useField<string>("name")
const account_party_uuid = useField<Party["uuid"]>("account_party_uuid")
const template_uuid = useField<Template["uuid"]>("template_uuid")
const { resetField: resetTemplateField } = template_uuid
const pdf_uuid = useField<string>("pdf_uuid")
const pdf_file_name = useField<string>("pdf_file_name")

const accountPartySelected = computed<Party>(() => props.accountParties.find((el) => el.uuid === account_party_uuid?.value?.value) )
const templateSelected = computed<Template>(() => props.templates.find((el) => el.uuid === template_uuid?.value?.value) )

const emptyDocumentForm = Object.freeze<Partial<DocumentFormValidatedType>>({ })

const localDocumentForm = ref<Partial<DocumentFormValidatedType>>((!props.isUploadPdf || props.isExtended) ? { ...emptyDocumentForm, account_party_uuid: props.accountParties[0]?.uuid } : { ...emptyDocumentForm })

const updateFieldValue = (fieldName: keyof DocumentFormValidatedType, val: any) => {
  setFieldValue(fieldName as any, val)
  localDocumentForm.value = {
    ...localDocumentForm.value || {},
    [fieldName]: val,
  }
}

const errorsToShow = computed<Partial<Record<keyof DocumentFormValidatedType, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentFormValidatedType, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof Document, string[]>>,
      )
  },
)

const handleDocumentFromScratch = async (): Promise<void> => {
  const isValid = await validate(
    {
      mode: "force",
    },
  )
  if (!isValid.valid) return
  const payload = {
    name: name.value.value,
    account_party_uuid: account_party_uuid.value.value,
  }
  const submitRoute = route("api.document-init.create-document-from-scratch")
  return axios.post(submitRoute, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: t("documents.create.errors.creation"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  })
}

const handleDocumentFromTemplate = async (
  templateUuid: Template["uuid"],
  name = "" as Document["name"],
): Promise<Document["uuid"] | void> => {
  const payload: Record<string, string> = {
    template_uuid: templateUuid,
  }
  // Add name if not empty
  if (name) payload.name = name
  const apiUrl = route("api.document-init.create-document-from-template")
  return axios.post(apiUrl, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: t("documents.create.errors.creation"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  })
}

const handleDocumentFromPdf = async (): Promise<void> => {
  const isValid = await validate(
    {
      mode: "force",
    },
  )
  if (!isValid.valid) return

  const payload = {
    account_party_uuid: account_party_uuid.value.value,
    pdf_uuid: pdf_uuid.value.value,
    pdf_file_name: pdf_file_name.value.value,
    template_uuid: template_uuid.value.value,
  }
  const apiUrl = route("api.document-init.create-document-from-pdf")
  return axios.post(apiUrl, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: t("documents.create.errors.creation"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  })
}

const handleDocumentImport = async (): Promise<void> => {
  const isValid = await validate(
    {
      mode: "force",
    },
  )
  if (!isValid.valid) return

  const payload = {
    account_party_uuid: account_party_uuid.value.value,
    template_uuid: template_uuid.value.value,
    pdf_uuid: pdf_uuid.value.value,
    pdf_file_name: pdf_file_name.value.value,
  }
  const apiUrl = route("api.document-init.create-document-from-signed-pdf")
  return axios.post(apiUrl, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: t("documents.create.errors.import"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  })
}

const handleFileUploadSuccess = (file_uuid: string, file_name: string) => {
  updateFieldValue("pdf_uuid", file_uuid)
  updateFieldValue("pdf_file_name", file_name)
}

const resetDocumentForm = () => {
  resetForm()
  if (!props.isUploadPdf || props.isExtended) setFieldValue("account_party_uuid", props.accountParties[0]?.uuid)
  localDocumentForm.value = (!props.isUploadPdf || props.isExtended) ? { ...emptyDocumentForm, account_party_uuid: props.accountParties[0]?.uuid } : { ...emptyDocumentForm }
}

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

onMounted(() => {
  resetDocumentForm()
})

const emit = defineEmits([ "submit" ])

// When enter is pressed, submit the form
const handleEnter = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    emit("submit")
  }
}

defineExpose({ resetDocumentForm, handleDocumentFromScratch, handleDocumentFromPdf, handleDocumentFromTemplate, handleDocumentImport })

</script>
<template>
  <div
    class="flex flex-col h-full px-6 py-6 space-y-4 grow"
  >
    <div
      v-if="isUploadPdf"
      class="text-sm grow"
    >
      <div class="pb-1">
        <label class="block text-sm font-medium text-gray-700">
          {{ $t('documents.create.formFields.fileUpload') }}  <span class="text-indigo-500">*</span>
        </label>
      </div>
      <FileInput
        :upload-callback="handleFileUploadSuccess"
        :form-errors="errorsToShow"
      />
      <FormInputErrors
        v-if="errorsToShow?.pdf_uuid?.length"
        :errors="errorsToShow?.pdf_uuid"
      />
      <FormInputErrors
        v-if="errorsToShow?.pdf_file_name?.length"
        :errors="errorsToShow?.pdf_file_name"
      />
    </div>
    <div v-if="!isUploadPdf">
      <label
        for="document-name"
        class="block text-sm font-medium text-gray-900"
      >
        {{ $t('documents.create.formFields.name.label') }} <span class="text-indigo-500">*</span>
      </label>
      <div class="mt-1">
        <input
          id="document-name"
          data-cy-sel="document-name-input"
          :value="name.value.value"
          :placeholder="$t('documents.create.formFields.name.placeholder') + '…'"
          type="text"
          name="document-name"
          class="input-plain"
          :class="[errorsToShow?.name?.length ? 'input-has-errors' : '']"
          @input="($event) => updateFieldValue('name', ($event.target as HTMLInputElement).value)"
          @keydown="handleEnter"
        >
      </div>
      <FormInputErrors
        v-if="errorsToShow?.name?.length"
        :errors="errorsToShow?.name"
      />
    </div>
    <div v-if="isExtended">
      <Listbox
        :model-value="template_uuid.value.value"
        as="div"
        :disabled="!templates?.length"
        @update:model-value="($event) => updateFieldValue('template_uuid', $event)"
      >
        <ListboxLabel
          class="flex items-center gap-1 text-sm font-medium text-gray-700"
        >
          {{ $t('documents.create.formFields.copySettings') }}
          <span
            data-tippy-help
            :data-tippy-content="$t('documents.selectTemplateInfo')"
            data-placement="bottom"
          >
            <QuestionMarkCircleIcon
              class="w-4 h-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxLabel>
        <div class="relative mt-1">
          <ListboxButton
            class="relative flex items-center py-2 pl-3 pr-10 text-left input-plain focus:outline-none focus:ring-2"
          >
            <div
              v-if="templateSelected"
              class="flex items-center"
            >
              <component
                :is="getDocumentTypeInfo(templateSelected?.document_type || DocumentType.other)?.icon || DocumentDuplicateIcon"
                class="w-4 h-4 mr-2"
              />
              <span class="block truncate">
                {{ templateSelected?.name }}
              </span>
            </div>
            <div
              v-else
              class="text-gray-500"
            >
              <span
                v-if="!templates?.length"
                class="block truncate"
              >
                {{ $t('documents.create.errors.noTemplates') }}…
              </span>
              <span
                v-else
                class="block truncate"
              >
                {{ $t('documents.create.formFields.selectTemplate') }}…
              </span>
            </div>
            <span
              v-if="!templateSelected"
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
            <span
              v-else
              class="absolute inset-y-0 flex items-center cursor-pointer right-2"
              @click.prevent="resetTemplateField()"
            >
              <XMarkIcon
                class="w-5 h-5 text-gray-400 hover:text-gray-600"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="listbox-options">
              <ListboxOption
                v-for="template in templates"
                :key="template.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="template.uuid"
              >
                <li
                  :class="[
                    active ? 'bg-gray-700' : '',
                    'listbox-option',
                  ]"
                >
                  <div class="flex items-center">
                    <component
                      :is="getDocumentTypeInfo(template?.document_type || DocumentType.other)?.icon || DocumentDuplicateIcon"
                      class="w-4 h-4 mr-2 text-gray-500 shrink-0"
                    />
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ template.name }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <FormInputErrors
        v-if="errorsToShow?.template_uuid?.length"
        :errors="errorsToShow?.template_uuid"
      />
    </div>

    <div v-if="isExtended || !isUploadPdf">
      <Listbox
        :model-value="account_party_uuid.value.value"
        as="div"
        @update:model-value="($event) => updateFieldValue('account_party_uuid', $event)"
      >
        <ListboxLabel
          class="block text-sm font-medium text-gray-700"
        >
          {{ $t('documents.create.formFields.myParty') }}  <span class="text-indigo-500">*</span>
        </ListboxLabel>
        <div class="relative mt-1">
          <ListboxButton
            class="relative flex items-center py-2 pl-3 pr-10 text-left input-plain focus:outline-none focus:ring-2"
          >
            <div
              v-if="accountPartySelected"
              class="flex items-center max-w-full min-w-0"
            >
              <HomeIcon
                class="w-4 h-4 mr-2"
              />
              <span class="block truncate">
                {{ accountPartySelected?.entity_name }}
              </span>
            </div>
            <div
              v-else
              class="text-gray-500"
            >
              <span class="block truncate">
                {{ $t('documents.create.formFields.selectParty') }}…
              </span>
            </div>
            <span
              v-if="!accountPartySelected"
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
            <span
              v-else
              class="absolute inset-y-0 right-0 flex items-center pr-2"
              @click.prevent="updateFieldValue('account_party_uuid', null)"
            >
              <XMarkIcon
                class="w-5 h-5 text-gray-400 hover:text-gray-600"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="z-20 max-w-full listbox-options">
              <ListboxOption
                v-for="accountParty in accountParties"
                :key="accountParty.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="accountParty.uuid"
              >
                <li
                  :class="[
                    active ? 'bg-gray-700' : '',
                    'listbox-option',
                  ]"
                >
                  <div class="flex items-center max-w-full min-w-0">
                    <HomeIcon
                      class="w-4 h-4 mr-2 text-gray-500 shrink-0"
                    />
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ accountParty.entity_name }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <FormInputErrors
        v-if="errorsToShow?.account_party_uuid?.length"
        :errors="errorsToShow?.account_party_uuid"
      />
    </div>
  </div>
</template>
