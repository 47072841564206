<script setup lang="ts">
// external
import { computed, watch, onMounted } from "vue"
import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/vue/24/outline"
import { XCircleIcon, XMarkIcon } from "@heroicons/vue/24/solid"

// internal
import { ToasterNotification } from "~/types"
import { useNotificationStore } from "~/stores"

interface Props {
  notification: ToasterNotification
  show: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    show: false,
  },
)

interface Emits {
  (event: "update:show", val: boolean): void
}

const { removeToasterNotification } = useNotificationStore()

const emit = defineEmits<Emits>()

const show = computed(
  {
    get: () => props.show,
    set: (v) => emit("update:show", v),
  },
)

watch(show, () => setTimeout(() => show.value = false, 6000))

let selfDestruct = null

onMounted(() => {
  if (!props.notification?.uuid) return

  selfDestruct = setTimeout(() => {
    removeToasterNotification(props.notification.uuid)
  }, props.notification.duration)
})

const close = () => {
  show.value = false

  if (!props.notification?.uuid) return

  clearTimeout(selfDestruct)

  removeToasterNotification(props.notification.uuid)
}

const reload = () => location.reload()
</script>

<template>
  <transition
    enter-active-class="transition duration-300 ease-out transform"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition duration-300 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="show"
      class="w-full max-w-sm overflow-hidden rounded-lg shadow-xl pointer-events-auto bg-slate-900"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="shrink-0">
            <XCircleIcon
              v-if="notification.type === 'error'"
              class="w-6 h-6 text-red-600"
              aria-hidden="true"
            />

            <CheckCircleIcon
              v-else
              class="w-6 h-6 text-green-600"
              aria-hidden="true"
            />
          </div>

          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-slate-50">
              {{ notification.title }}
            </p>

            <p class="mt-1 text-sm text-slate-400">
              {{ notification.message }}
            </p>

            <div v-if="notification.type === 'error'">
              <button
                type="button"
                class="relative flex items-center gap-1 mt-3 mb-1 overflow-hidden text-white btn-sm hover:text-white btn-plain bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-slate-900 focus:ring-offset-2 focus:ring-slate-700"
                @click="reload"
              >
                <div class="z-0 w-full shimmer -left-full light" />

                <ArrowPathIcon
                  class="w-4 h-4"
                  aria-hidden="true"
                />

                {{ $t('common.locked.reloadPage') }}
              </button>
            </div>
          </div>

          <div class="flex ml-4 shrink-0">
            <button
              class="inline-flex rounded-md text-slate-500 hover:text-slate-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-700"
              @click="close"
            >
              <span class="sr-only">
                {{ $t('common.close') }}
              </span>

              <XMarkIcon
                class="w-5 h-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
