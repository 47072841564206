<script setup lang="ts">
// external
import { computed } from "vue"
import { MagnifyingGlassIcon, FunnelIcon, QueueListIcon, BarsArrowUpIcon, BarsArrowDownIcon } from "@heroicons/vue/24/outline"
import { FunnelIcon as FunnnelIconSolid } from "@heroicons/vue/24/solid"
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon } from "@heroicons/vue/20/solid"
import { useI18n } from "vue-i18n"

// internal
import { KanbanIcon } from "~/icons"
import { BookmarkButton } from "~/components"
import { FilterBubble, Metadata } from "~/types"
import { getMetadataDisplayNameByKey } from "~/utils"

interface Props {
  query: any
  listName?: string
  viewMode?: string | null
  showAllFilters?: boolean
  hasActiveFilters?: boolean
  showFilterButton?: boolean
  filterBubbles?: FilterBubble[]
  sortAttributes?: string[]
  sortAttribute?: string
  sortDirection?: string
  metadata?: Metadata[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    viewMode: null,
    showAllFilters: false,
    hasActiveFilters: false,
    showFilterButton: true,
    listName: null,
    filterBubbles: null,
    sortAttributes: null,
    sortAttribute: null,
    sortDirection: null,
    metadata: null,
  },
)

const emit = defineEmits([ "update:show-all-filters", "update:view-mode", "update:query", "update:sort-attribute", "update:sort-direction" ])

const localQuery = computed({
  get: () => {
    return props.query
  },
  set: (query) => {
    emit("update:query", query)
  },
})

const localSortAttribute = computed({
  get: () => {
    return props.sortAttribute
  },
  set: (sortAttribute) => {
    emit("update:sort-attribute", sortAttribute)
  },
})

const numberOfActiveFilters = computed(() => {
  if (!props.filterBubbles?.length) return 0
  return props.filterBubbles.filter((bubble) => bubble.key !== "query").length
})

const { t } = useI18n()

const getDisplayName = (sortAttribute) => {
  if (props.metadata) {
    const metadataEntry = props.metadata.find((entry) => entry.name === sortAttribute)

    if (metadataEntry) {
      return getMetadataDisplayNameByKey(sortAttribute, props.metadata, t)
    }
  }

  return t("magicTable.columns." + sortAttribute)
}
</script>

<template>
  <div class="bg-white rounded-md shadow">
    <div class="flex items-stretch divide-x divide-gray-200">
      <div v-if="showFilterButton">
        <button
          ref="button"
          type="button"
          class="relative flex items-center gap-2 rounded-r-none disabled:opacity-50 focus:z-10"
          :class="showAllFilters ? 'btn-primary' : 'btn-plain'"
          data-tippy-help
          :data-tippy-content="$t('filter.toggle')"
          data-placement="top"
          @click.prevent="$emit('update:show-all-filters', !showAllFilters)"
        >
          <FunnelIcon
            v-if="!numberOfActiveFilters"
            class="w-4 h-4 my-0.5 -mx-0.5"
            aria-hidden="true"
          />
          <FunnnelIconSolid
            v-else
            class="w-4 h-4 my-0.5 -mx-0.5 text-indigo-300"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t('filter.toggle') }}</span>
          <span
            v-if="numberOfActiveFilters"
            class="rounded-full w-3 h-3 flex absolute top-5 left-[26px] items-center justify-center text-[8px]"
            :class="showAllFilters ? 'text-indigo-500 bg-white' : 'bg-indigo-500 text-white'"
          >
            <span>{{ numberOfActiveFilters }}</span>
          </span>
        </button>
      </div>

      <div
        v-if="!!viewMode"
        class="items-center hidden md:flex"
      >
        <button
          type="button"
          :class="viewMode === 'table' ? 'btn-primary' : 'btn-plain'"
          class="rounded-none focus:z-10"
          data-tippy-help
          :data-tippy-content="$t('filter.tableView')"
          data-placement="top"
          @click.prevent="$emit('update:view-mode', 'table')"
        >
          <QueueListIcon
            class="w-4 h-4 my-0.5 -mx-0.5"
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          :class="viewMode === 'kanban' ? 'btn-primary' : 'btn-plain'"
          class="rounded-none focus:z-10"
          data-tippy-help
          :data-tippy-content="$t('filter.kanbanView')"
          data-placement="top"
          @click.prevent="$emit('update:view-mode', 'kanban')"
        >
          <KanbanIcon
            class="w-4 h-4 my-0.5 -mx-0.5"
            aria-hidden="true"
          />
        </button>
      </div>

      <div
        class="relative grow"
      >
        <input
          v-model="localQuery"
          type="search"
          class="block w-full px-9 min-h-[38px] py-0 text-base sm:text-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none border-0 appearance-none"
          :class="[!!localQuery ? 'bg-indigo-50 text-indigo-800' : 'bg-white', {'rounded-l-md': !viewMode && !showFilterButton}]"
          :placeholder="$t('filter.search') + '…'"
        >
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <MagnifyingGlassIcon
            class="w-4 h-4"
            :class="!!localQuery ? 'text-indigo-500' : 'text-gray-400'"
          />
        </div>
        <button
          v-if="!!localQuery"
          type="button"
          class="absolute inset-y-0 right-0 flex items-center pr-3"
          @click="localQuery = null"
        >
          <XCircleIcon
            class="w-4 h-4 text-indigo-500"
          />
        </button>
      </div>

      <div
        v-if="sortAttributes?.length && !!sortAttribute"
        class="items-stretch hidden sm:flex"
      >
        <Listbox
          v-model="localSortAttribute"
          as="div"
          class="h-full"
        >
          <div class="relative h-full">
            <ListboxButton
              v-if="sortAttributes.length > 1"
              class="flex items-center h-full px-3 text-sm text-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none"
            >
              <div
                class="flex items-center"
              >
                <span>{{ getDisplayName(localSortAttribute) }}</span>
              </div>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions class="listbox-options -right-10">
                <ListboxOption
                  v-for="sortAttributeEntry in sortAttributes"
                  :key="sortAttributeEntry"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="sortAttributeEntry"
                >
                  <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                      <span>{{ getDisplayName(sortAttributeEntry) }}</span>
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-2',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <button
          type="button"
          class="rounded-none btn-plain"
          @click="$emit('update:sort-direction', sortDirection === 'asc' ? 'desc' : 'asc')"
        >
          <BarsArrowUpIcon
            v-if="sortDirection === 'asc'"
            aria-hidden="true"
            class="w-4 h-4 my-0.5 -mx-1.5"
          />
          <BarsArrowDownIcon
            v-else-if="sortDirection === 'desc'"
            aria-hidden="true"
            class="w-4 h-4 my-0.5 -mx-1.5"
          />
        </button>
      </div>

      <div v-if="listName">
        <BookmarkButton
          :list-name="listName"
        />
      </div>
    </div>
  </div>
</template>
