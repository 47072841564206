<script setup lang="ts">
import { computed } from "vue"
import { storeToRefs } from "pinia"

import { entityTypeOptions } from "~/utils"
import { CrudContext, PartyEntityType } from "~/types"
import { useSharedStore } from "~/stores"

defineEmits([ "select-entity-type" ])

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityTypeOptionsToOmitWhenDocument = [
  PartyEntityType.tbd,
]

const entityTypeOptionsFiltered = computed<any>(() => {
  if (crudContext.value === CrudContext.document) {
    return entityTypeOptions.filter((el) => {
      return !entityTypeOptionsToOmitWhenDocument.includes(el.value)
    })
  } else {
    return entityTypeOptions
  }
})

</script>
<template>
  <div class="grid grid-cols-1 mt-1 -mx-4 -mb-4 -space-y-px">
    <button
      v-for="option, optionIdx in entityTypeOptionsFiltered"
      :key="option.value"
      :data-cy-sel="'party-entity-type-' + option.value"
      :class="[optionIdx === entityTypeOptionsFiltered.length - 1 ? 'rounded-b-md hover:rounded-b-md focus:rounded-b-md' : '', 'focus:z-10 hover:border-t-indigo-200 hover:bg-indigo-50 focus:border-t-indigo-200 focus:bg-indigo-50 border-t-gray-200 relative flex items-center cursor-pointer border-t p-3 focus:outline-none group']"
      @click="$emit('select-entity-type', option.value)"
    >
      <span
        class="flex-none group-hover:text-indigo-600 group-focus:text-indigo-600 text-gray-300 rounded-md flex items-center p-0.5 shrink-0 cursor-pointer border-0 justify-center"
        aria-hidden="true"
      >
        <component
          :is="option.icon"
          class="w-6 h-6"
        />
      </span>
      <div class="flex flex-col ml-3 text-sm text-left text-gray-900 group-hover:text-indigo-900 group-focus:text-indigo-900">
        <span class="font-medium">{{ $t(option.title) }}</span>
        <span class="text-xs text-gray-500 group-hover:text-indigo-900 group-focus:text-indigo-900">{{ $t(option.description) }}</span>
      </div>
    </button>
  </div>
</template>
