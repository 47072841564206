<script setup lang="ts">
// external
import { DocumentDuplicateIcon, UsersIcon } from "@heroicons/vue/24/outline"
import { ref, watch } from "vue"
import { router } from "@inertiajs/vue3"

// internal
import { DialogModal, SpinLoader, EmptyState, FormInputErrors, SubscriptionUpgradeBox } from "~/components"
import { Party, GlobalTemplate } from "~/types"
import { useAccountStore, useTemplateStore } from "~/stores"
import { localeOptions, purifyHtml, usecases } from "~/utils"
import { storeToRefs } from "pinia"
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckCircleIcon, CheckIcon, ChevronUpDownIcon, HomeIcon } from "@heroicons/vue/24/solid"
import { useField } from "vee-validate"
import { computed } from "vue"
import { onMounted } from "vue"

interface Props {
  showThemeGallery?: boolean
  accountParties?: Party[]
}
const props = withDefaults(
  defineProps<Props>(),
  {
    showThemeGallery: false,
    accountParties: () => [],
  },
)

const templateStore = useTemplateStore()
const { globalTemplates } = storeToRefs(templateStore)
const { createTemplateFromGlobalTemplate } = templateStore

const showCreationForm = ref<boolean>(false)
const templateCreateForm = ref<any>()
const isSubmittingTemplateCreateForm = ref<boolean>(false)
const selectedGlobalTemplate = ref<GlobalTemplate["uuid"]>(null)
const accountPartyError = ref<boolean>(false)

const account_party_uuid = useField<Party["uuid"]>("account_party_uuid")
const { handleChange: handleChangeAccountPartyUuid } = account_party_uuid
const accountPartySelected = computed<Party>(() => props.accountParties.find((el) => el.uuid === account_party_uuid?.value?.value) )

const getLocaleIcon = (locale: string) => {
  const localeOption = localeOptions.find((el) => el.value === locale)
  if (!localeOption) return null
  return localeOption.icon
}

const selectGlobalTemplate = (templateUuid: GlobalTemplate["uuid"]) => {
  if (selectedGlobalTemplate.value === templateUuid) {
    selectedGlobalTemplate.value = null
    return
  }
  selectedGlobalTemplate.value = templateUuid
}

const handleCreateTemplateFromGlobalTemplate = async (): Promise<void> => {
  if (!selectedGlobalTemplate.value) return
  if (!accountPartySelected.value) {
    accountPartyError.value = true
    return
  }
  isSubmittingTemplateCreateForm.value = true
  try {
    const newUuid = await createTemplateFromGlobalTemplate(selectedGlobalTemplate.value, accountPartySelected.value.uuid)
    if (newUuid) router.visit(route("templates.edit", newUuid))
  } finally {
    isSubmittingTemplateCreateForm.value = false
  }
}

watch(showCreationForm, (newVal) => {
  if (!newVal) {
    location.hash = ""
    templateCreateForm.value?.resetTemplateForm()
  } else {
    location.hash = "#create"
  }
})

watch(() => props.accountParties, () => {
  account_party_uuid.setValue(props.accountParties[0]?.uuid)
})

onMounted(() => {
  if (props.accountParties?.length) {
    account_party_uuid.setValue(props.accountParties[0]?.uuid)
  }
})

const selectedLocale = ref<string>(null)
const selectedUseCase = ref<string>(null)

const accountStore = useAccountStore()
const { planFeatures, mau } = storeToRefs(accountStore)
const isFeatureLocked = computed(() => !planFeatures.value?.["create-template"])

const filteredGlobalTemplates = computed(() => {
  if (!selectedUseCase.value && !selectedLocale.value) return globalTemplates.value
  return globalTemplates.value.filter((template) => {
    if (selectedUseCase.value && !template.use_cases?.includes(selectedUseCase.value)) return false
    if (selectedLocale.value && template.locale !== selectedLocale.value) return false
    return true
  })
})

defineEmits([ "close" ])

</script>
<template>
  <DialogModal
    id="themeGalleryModal"
    :show="showThemeGallery"
    max-width="6xl"
    :closeable="true"
    :padding="false"
    :show-footer="!isFeatureLocked"
    :show-close-button="true"
    @close="$emit('close')"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ $t('templates.gallery') }}
      </div>
    </template>

    <template #content>
      <div class="grid md:grid-cols-3 md:border-b md:border-t md:border-gray-300">
        <div class="flex flex-col p-6 space-y-6 bg-gray-200 md:border-r md:border-gray-300">
          <div>
            <h3 class="flex items-center gap-1 mb-1 text-xs font-normal tracking-wider text-gray-500 uppercase">
              {{ $t('templates.filterByUseCases') }}
            </h3>
            <div class="flex flex-col items-start justify-start -mx-6">
              <button
                type="button"
                class="flex items-center w-full gap-2 px-6 text-sm font-medium text-indigo-500 rounded-none hover:text-indigo-500 btn-plain focus:bg-gray-100 focus:ring-0 focus:ring-offset-0"
                :class="selectedUseCase === null ? '' : 'hover:bg-gray-100'"
                @click="selectedUseCase = null"
              >
                <span class="text-left truncate grow">
                  {{ $t('templates.showAll') }}
                </span>
                <CheckCircleIcon
                  v-if="selectedUseCase === null"
                  class="flex-shrink-0 w-5 h-5 text-indigo-500"
                  aria-hidden="true"
                />
              </button>
              <button
                v-for="usecase, usecaseIdx in usecases"
                :key="usecaseIdx"
                type="button"
                class="flex items-center w-full gap-2 px-6 text-sm font-medium text-gray-500 rounded-none hover:text-indigo-500 hover:bg-gray-100 btn-plain focus:bg-gray-100 focus:ring-0 focus:ring-offset-0"
                :class="selectedUseCase === usecase.id ? 'bg-gray-50' : ''"
                @click="selectedUseCase = usecase.id"
              >
                <component
                  :is="usecase.icon"
                  aria-hidden="true"
                  class="w-5 h-5"
                />
                <span class="text-left truncate grow">
                  {{ $t('onboarding.usecases.' + usecase.id + '.title') }}
                </span>
                <CheckCircleIcon
                  v-if="selectedUseCase === usecase.id"
                  class="flex-shrink-0 w-5 h-5 text-indigo-500"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <div>
            <h3 class="flex items-center gap-1 mb-1 text-xs font-normal tracking-wider text-gray-500 uppercase">
              {{ $t('templates.filterByLanguage') }}
            </h3>
            <div class="flex flex-col items-start justify-start -mx-6">
              <button
                type="button"
                class="flex items-center w-full gap-2 px-6 text-sm font-medium text-indigo-500 rounded-none hover:text-indigo-500 btn-plain focus:bg-gray-100 focus:ring-0 focus:ring-offset-0"
                :class="selectedLocale === null ? '' : 'hover:bg-gray-100'"
                @click="selectedLocale = null"
              >
                <span class="text-left truncate grow">
                  {{ $t('templates.showAll') }}
                </span>
                <CheckCircleIcon
                  v-if="selectedLocale === null"
                  class="flex-shrink-0 w-5 h-5 text-indigo-500"
                  aria-hidden="true"
                />
              </button>
              <button
                v-for="locale, localeIdx in localeOptions"
                :key="localeIdx"
                type="button"
                class="flex items-center w-full gap-2 px-6 text-sm font-medium text-gray-500 rounded-none hover:text-indigo-500 hover:bg-gray-100 btn-plain focus:bg-gray-100 focus:ring-0 focus:ring-offset-0"
                :class="selectedLocale === locale.value ? 'bg-gray-50' : ''"
                @click="selectedLocale = locale.value"
              >
                <div class="flex items-center gap-2 text-left truncate grow">
                  <span>{{ locale.icon }}</span>
                  <span class="truncate">{{ locale.label }}</span>
                </div>
                <CheckCircleIcon
                  v-if="selectedLocale === locale.value"
                  class="flex-shrink-0 w-5 h-5 text-indigo-500"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-between md:col-span-2">
          <SubscriptionUpgradeBox
            v-if="isFeatureLocked"
            :text="$t('accountSettings.billing.featureRequiresUpgrade')"
            class="absolute inset-0 overflow-hidden rounded-lg"
          />
          <EmptyState
            v-if="!accountParties.length"
            class="pb-5 grow"
            :hide-button="!mau?.permissions.includes('party_manage')"
            @click="() => router.visit(route('account-settings.parties.create'))"
          >
            <template #icon>
              <UsersIcon
                aria-hidden="true"
              />
            </template>
            <template #call-to-action>
              {{ $t('templates.noAccountParties.description') }}
            </template>
            <template #default>
              {{ $t('templates.noAccountParties.title') }}
            </template>
            <template #button-text>
              {{ $t('templates.noAccountParties.button') }}
            </template>
          </EmptyState>
          <EmptyState
            v-else-if="!filteredGlobalTemplates.length"

            class="flex flex-col items-center justify-center pb-5 grow"
          >
            <template #icon>
              <DocumentDuplicateIcon
                aria-hidden="true"
              />
            </template>
            <template #call-to-action>
              {{ $t('templates.empty.descriptionFilter') }}
            </template>
            <template #default>
              {{ $t('templates.empty.title') }}
            </template>
          </EmptyState>
          <div
            v-else
            class="p-6 bg-gray-100 grow"
          >
            <div
              :data-cy-sel="`theme-gallery-modal`"
              class="grid grid-cols-3 gap-6"
            >
              <button
                v-for="(template, templateIdx) in filteredGlobalTemplates"
                :key="template.uuid"
                :data-cy-sel="`global-template-${templateIdx}`"
                type="button"
                class="flex flex-col text-left group"
                @click.prevent="selectGlobalTemplate(template.uuid)"
              >
                <div
                  class="flex items-center justify-between w-full mb-2 text-xs font-medium truncate group-hover:text-indigo-700"
                  :class="selectedGlobalTemplate === template.uuid ? 'text-indigo-700' : ''"
                >
                  <span class="grow">
                    {{ template.name }}
                  </span>
                  <span class="shrink-0">
                    {{ getLocaleIcon(template.locale) }}
                  </span>
                </div>
                <div
                  class="relative w-full px-4 pt-4 overflow-hidden transition-all rounded-md shadow h-28 bg-gradient-to-b from-slate-300 to-slate-400"
                  :class="selectedGlobalTemplate === template.uuid ? 'ring-offset-2 ring-offset-gray-100 ring-2 ring-indigo-500' : ''"
                >
                  <div
                    v-if="!template.html"
                    class="flex items-center justify-center w-full h-full text-gray-500 bg-white bg-opacity-50 rounded-t"
                  >
                    <SpinLoader
                      class="w-5 h-5"
                      aria-hidden="true"
                    />
                  </div>
                  <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
                  <div
                    v-else
                    class="absolute inset-y-0 inset-x-3.5"
                  >
                    <div
                      class="ProseMirror rendered-html-container absolute origin-top-left scale-[.20] w-[500%] bg-white p-12 mx-auto shadow-xl rounded-t-2xl transition-all group-hover:opacity-100 group-hover:top-2"
                      :class="selectedGlobalTemplate === template.uuid ? 'opacity-100 top-2' : 'opacity-80 top-4'"
                      v-html="purifyHtml(template.html)"
                    />
                  </div>
                <!-- eslint-enable vue/no-v-html vue/html-self-closing -->
                </div>
                <div class="text-xs text-gray-400 grow">
                  {{ template.description }}
                </div>
              </button>
            </div>
          </div>

          <div
            v-if="!isFeatureLocked && accountParties.length > 1 && filteredGlobalTemplates.length"
            class="px-6 bg-gray-100 md:pb-6"
          >
            <Listbox
              :model-value="account_party_uuid.value.value"
              as="div"
              class="p-4 bg-white border border-gray-200 rounded-md shadow"
              @update:model-value="($event) => handleChangeAccountPartyUuid($event)"
            >
              <ListboxLabel
                class="block text-sm font-medium text-gray-700"
              >
                {{ $t('documents.create.formFields.selectAccountParty') }} <span class="text-indigo-500">*</span>
              </ListboxLabel>
              <div class="relative mt-1">
                <ListboxButton
                  class="btn-listbox-plain"
                >
                  <div
                    v-if="accountPartySelected"
                    class="flex items-center"
                  >
                    <HomeIcon
                      class="w-4 h-4 mr-2"
                    />
                    <span class="block truncate">
                      {{ accountPartySelected?.entity_name }}
                    </span>
                  </div>
                  <div
                    v-else
                    class="text-gray-500"
                  >
                    <span class="block truncate">
                      {{ $t('documents.create.formFields.selectParty') }}...
                    </span>
                  </div>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <ChevronUpDownIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>
                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="z-20 listbox-options">
                    <ListboxOption
                      v-for="accountParty in accountParties"
                      :key="accountParty.uuid"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="accountParty.uuid"
                    >
                      <li
                        :class="[
                          active ? 'bg-gray-700' : '',
                          'listbox-option',
                        ]"
                      >
                        <div class="flex items-center">
                          <HomeIcon
                            class="w-4 h-4 mr-2 text-gray-500 shrink-0"
                          />
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ accountParty.entity_name }}
                          </span>
                        </div>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <FormInputErrors
              v-if="accountPartyError"
              :errors="[ $t('common.required') ]"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="$emit('close')"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          v-if="globalTemplates?.length"
          v-cy="`create-from-global-template-button`"
          :disabled="isSubmittingTemplateCreateForm || !accountPartySelected || !selectedGlobalTemplate"
          type="button"
          class="flex items-center gap-2 btn-primary"
          @click.prevent="handleCreateTemplateFromGlobalTemplate"
        >
          <SpinLoader
            v-if="isSubmittingTemplateCreateForm"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span v-if="isSubmittingTemplateCreateForm">{{ $t('common.pleaseWait') }}…</span>
          <span v-else>{{ $t('templates.selectGlobalTemplate') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
