<script setup lang="ts">
// external
import { Link, usePage } from "@inertiajs/vue3"
import { ref, onBeforeMount, computed } from "vue"
import axios from "axios"
import { useI18n } from "vue-i18n"

// internal
import { DashboardWidget, EmptyState, MultiFieldIcon } from "~/components"
import { ContractType, MetadataType, MultiFieldType } from "~/types"

const { t } = useI18n()

const contractTypes = computed(() => usePage().props.contractTypes as ContractType[])
const contractTypeNames = computed(() => contractTypes.value.map((entry) => entry.name))

const isLoadingSchema = ref(false)
const schema = ref(null)
const loadSchema = async () => {
  if (!isLoadingSchema.value && contractTypeNames.value.length) {
    isLoadingSchema.value = true
    schema.value = null
    try {
      const res = await axios.post(route("api.data.account.metadata-preview"), {
        contract_types: contractTypeNames.value,
      })

      if (res?.data?.data) {
        schema.value = res.data.data
      }
    } catch (err) {
      console.error(err)
    } finally {
      isLoadingSchema.value = false
    }
  }
}

onBeforeMount(() => {
  loadSchema()
})

const getName = (metadataName) => {
  if (schema.value?.length) {
    const metadata = schema.value.find((entry) => entry.name === metadataName)

    if (metadata) {
      return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.display_name : t(`metadata.system.${metadata?.name}.name`)
    }
    return t(`metadata.system.${metadataName}.name`)
  }

}
</script>

<template>
  <DashboardWidget
    :title="$t('dashboard.widgets.clauses')"
    endpoint="api.data.documents.clauseValueCount"
    box-classes="overflow-y-auto max-h-full"
    :loading="isLoadingSchema"
  >
    <template #default="{data}">
      <EmptyState
        v-if="!data || !data.length"
        :hide-button="true"
      >
        <template #icon>
          <MultiFieldIcon
            :type="MultiFieldType.clause"
            size-classes="w-10 h-10"
          />
        </template>
        <template #default>
          {{ $t('dashboard.clauseOverview.emptyTitle') }}
        </template>
        <template #call-to-action>
          <div class="text-sm px-5">
            {{ $t('dashboard.clauseOverview.emptyDescription') }}
          </div>
        </template>
      </EmptyState>
      <ul
        v-else
        class="w-full h-full"
      >
        <li
          v-for="entry in data"
          :key="entry.metadata_name"
          class="even:bg-gray-50 hover:bg-indigo-50"
        >
          <Link
            class="flex w-full items-center justify-between text-sm px-3 py-3"
            :href="route('documents.index') + `?${entry.metadata_name}=true`"
          >
            <span>{{ getName(entry.metadata_name) }}</span>
            <span class="rounded-full text-xs px-2 py-0.5 bg-indigo-500 text-white">{{ entry.count }}</span>
          </Link>
        </li>
      </ul>
    </template>
  </DashboardWidget>
</template>
