<script setup lang="ts">
//external
import { computed } from "vue"

// internal
import { SelectOption } from "~/types"
import { MultiCheckbox } from "~/components"

interface Props {
  selectedUuids?: string[]
  options: SelectOption[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedUuids: () => [],
  },
)

const emit = defineEmits([ "update:selected-uuids" ])

const localSelectedUuids = computed({
  get: () => props.selectedUuids,
  set: (uuids) => emit("update:selected-uuids", uuids),
})

</script>

<template>
  <div
    class="mt-1 relative space-y-1"
  >
    <MultiCheckbox
      v-model="localSelectedUuids"
      :options="options"
    />
  </div>
</template>
