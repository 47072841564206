import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import SignatureContainerNode from "./SignatureContainerNodeView.vue"
import { trackEventInJune } from "~/utils"
import { JuneEvents } from "~/types"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    signatureContainer: {
      insertSignatureContainer: (refUuids?: string[]) => ReturnType;
    };
  }
}

export interface SignatureContainerAttrs {
  signatureBlocks: { refUuid: string }[]
  align: "left" | "center" | "right" | "justify"
}

export const SignatureContainer = Node.create(
  {
    name: "signatureContainer",

    selectable: false,

    draggable: true,

    atom: true,

    allowGapCursor: false,

    addNodeView () {
      return VueNodeViewRenderer(SignatureContainerNode)
    },

    addOptions () {
      return {
        HTMLAttributes: {},
      }
    },

    addAttributes () {
      return {
        signatureBlocks: {
          default: [], // [ { signatureBlockUuid: number }, ...]
          parseHTML: (element) => {
            const jsonStr = element.getAttribute("ref-uuids")

            if (!jsonStr) return []

            return JSON.parse(jsonStr) || []
          },
          renderHTML: (attributes) => {
            if (!attributes.signatureBlocks) return {}

            return {
              "ref-uuids": JSON.stringify(attributes.signatureBlocks || []),
            }
          },
        },
        align: {
          default: "left", // left, center, right, justify
          parseHTML: (element) => element.getAttribute("data-align") || "left",
          renderHTML: (attributes) => {
            if (!attributes.align) return {}

            return {
              "data-align": attributes.align,
            }
          },
        },
      }
    },

    parseHTML () {
      return [
        {
          tag: "div[data-signature-container]",
        },
      ]
    },

    renderHTML ({ node, HTMLAttributes }) {
      return [
        "div",
        mergeAttributes(
          {
            "data-signature-blocks": JSON.stringify(node.attrs.signatureBlocks || []),
          },
          HTMLAttributes || {},
        ),
        `SignatureBlock ref uuids: ${node.attrs.signatureBlocks.join(", ")}`,
      ]
    },

    addCommands () {
      return {
        insertSignatureContainer: (signatureBlocks = []) =>
          ({ commands }) => {
            trackEventInJune(JuneEvents.EDITOR_SIGNATURE_CONTAINER_INSERTED)
            return commands.insertContent(
              [
                {
                  type: this.name,
                  attrs: {
                    signatureBlocks,
                  },
                },
              ],
            )
          },
      }
    },
  },
)
