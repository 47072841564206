<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, watch } from "vue"

import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"

import { TextSelection } from "@tiptap/pm/state"

// internal
import { useEditorStore } from "~/stores"

const props = defineProps<NodeViewProps>()

const editorStore = useEditorStore()
const {
  numberingTippy,
  activeListItem,
} = storeToRefs(editorStore)

const orderRepresentationStyles = computed(
  () => {
    const jsonNode = props.node.toJSON()

    const fontSizeNode = jsonNode.content?.[0]?.attrs?.fontSize
    const fontSizeMark = jsonNode.content?.[0]?.content?.[0]?.marks?.find((mark) => mark.type === "textStyle")?.attrs.fontSize

    const fontSize = fontSizeMark || fontSizeNode

    const styles: Partial<CSSStyleDeclaration> = {
      color: jsonNode.content?.[0]?.content?.[0]?.marks?.find((mark) => mark.type === "textStyle")?.attrs.color || "currentColor",
      ...(fontSize && { fontSize: fontSize + "pt" }),
      ...(
        jsonNode.content?.[0]?.content?.[0]?.marks?.find((mark) => mark.type === "bold")
          ? { fontWeight: "700" }
          : {}
      ),
      ...(
        jsonNode.content?.[0]?.content?.[0]?.marks?.find((mark) => mark.type === "italic")
          ? { fontStyle: "italic" }
          : {}
      ),
    }

    return styles
  },
)

watch(
  () => props.node,
  (updatedNode) => {
    if (activeListItem.value?.node.attrs.uuid !== updatedNode.attrs.uuid) return

    setActiveListItemData()
  },
  {
    deep: true,
  },
)

const setActiveListItemData = () => {
  editorStore.setActiveListItem(
    {
      pos: props.getPos(),
      node: props.node,
    },
  )
}

const openNumberingTippyAndFocusListItem = (e: MouseEvent) => {
  if (!props.editor.isEditable) return

  const pos = props.getPos()

  const $pos = props.editor.state.doc.resolve(pos)

  const newSel = TextSelection.near($pos)

  props.editor.commands.command(({ tr, dispatch }) => dispatch?.(tr.setSelection(newSel)))

  const target = e.target as HTMLDivElement

  const content = document.getElementById("numbering-menu")

  numberingTippy.value?.[0].setProps(
    {
      getReferenceClientRect: () => target.getBoundingClientRect(),
      ...(
        content
          ? { content }
          : {}
      ),
    },
  )

  numberingTippy.value?.[0].show()

  setActiveListItemData()
}

const tagOfNumbering = computed(
  () => {
    const { firstChild } = props.node

    if (!firstChild) return "p"

    if (firstChild.type.name === "heading") return "h" + firstChild.attrs.level

    if (firstChild.type.name === "list") return "no-show"

    return "p"
  },
)
</script>

<template>
  <NodeViewWrapper
    v-bind="{
      'data-list-kind': props.node.attrs.kind,
      'data-list-order': props.node.attrs.order,
      'data-list-numbering-style': props.node.attrs.numberingStyle,
      'data-list-delimiter-style': props.node.attrs.delimiterStyle,
    }"
    :id="props.node.attrs.uuid"
    class="list"
    as="div"
    :data-list-uuid="props.node.attrs.uuid"
  >
    <component
      :is="tagOfNumbering"
      v-if="tagOfNumbering !== 'no-show'"
      class="absolute z-10 pr-1 -translate-x-full select-none tabular-nums lining-nums"
      :class="{
        'cursor-pointer': props.editor.isEditable
      }"
      contenteditable="false"
      numbering-representation
      :style="orderRepresentationStyles"
      @click="openNumberingTippyAndFocusListItem"
    >
      {{
        props.node.attrs.kind === 'ordered'
          ? props.node.attrs.orderRepresentation
          : '•'
      }}
    </component>

    <NodeViewContent
      as="div"
      class="list-content"
    />
  </NodeViewWrapper>
</template>
