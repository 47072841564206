import { router } from "@inertiajs/vue3"
import { ref } from "vue"
import { useConfirmationStore, useNotificationStore } from "~/stores"
import { Automation } from "~/types"

export function useAutomationActions () {
  const confirmationStore = useConfirmationStore()
  const { setShowConfirmModal, setConfirmOptions } = confirmationStore

  const notificationStore = useNotificationStore()
  const { addToasterNotification } = notificationStore

  const isSubmitting = ref(false)
  const localAutomationUuid = ref("")

  const confirmAutomationDeletion = (automationUuid: Automation["uuid"]) => {
    localAutomationUuid.value = automationUuid

    setConfirmOptions({
      title: "Confirm automation archiving",
      description: "Are you sure you want to archive this automation?",
      buttonText: "Archive automation",
      callback: archiveAutomation,
    })
    setShowConfirmModal(true)
  }

  const confirmAutomationRestoration = (automationUuid: Automation["uuid"]) => {
    localAutomationUuid.value = automationUuid

    setConfirmOptions({
      title: "Confirm automation restoration",
      description: "Are you sure you want to restore this automation?",
      buttonText: "Restore automation",
      callback: restoreAutomation,
    })
    setShowConfirmModal(true)
  }

  const restoreAutomation = () => {
    if (!isSubmitting.value && localAutomationUuid.value) {
      isSubmitting.value = true

      router.post(route("automations.restore", localAutomationUuid.value),
        {},
        {
          onSuccess: () => {
            addToasterNotification({
              title: "Automation restored",
              message: "Automation was restored successfully",
              type: "success",
            })
          },
          onError: () => {
            addToasterNotification({
              title: "Error occured",
              message: "Automation could not be restored, please try again",
              type: "error",
            })
          },
          onFinish: () => {
            isSubmitting.value = false
            localAutomationUuid.value = null
          },
        })
    }
  }

  const archiveAutomation = () => {
    if (!isSubmitting.value && localAutomationUuid.value) {
      isSubmitting.value = true

      router.delete(route("automations.destroy", {
        automation: localAutomationUuid.value,
      }),
      {
        onSuccess: () => {
          addToasterNotification({
            title: "Automation archived",
            message: "Automation was archived successfully",
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: "Error occured",
            message: "Automation could not be archived, please try again",
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          localAutomationUuid.value = null
        },
      })
    }
  }

  return {
    isSubmitting,

    confirmAutomationDeletion,
    confirmAutomationRestoration,
    archiveAutomation,
    restoreAutomation,
  }
}
