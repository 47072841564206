<script setup lang="ts">
// external
import { computed } from "vue"
import { XCircleIcon } from "@heroicons/vue/20/solid"

// internal
import { Tag } from "~/types"
import { hexToGrayscaleValue } from "~/utils"

interface Props {
  tag: Tag,
  removable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  removable: false,
})

const textColor = computed(() => hexToGrayscaleValue(props.tag.color) > 155 ? "text-gray-900" : "text-white")

defineEmits([ "remove" ])
</script>
<template>
  <div
    class="inline-flex items-center justify-between px-1.5 py-px space-x-1 text-xs rounded-full select-none"
    :class="textColor"
    :style="`background-color: ${tag.color}`"
  >
    <span class="whitespace-nowrap truncate">{{ tag.name }}</span>
    <button
      v-if="removable"
      type="button"
      @click="$emit('remove', tag)"
    >
      <XCircleIcon
        aria-hidden="true"
        class="w-4 h-4 -mr-1"
      />
      <span class="sr-only">{{ $t('common.remove') }}</span>
    </button>
  </div>
</template>
