<script setup lang="ts">
// external
import { computed } from "vue"
import {
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/vue/24/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"

// internal
import { localeOptions } from "~/utils"
import { SpinLoader } from "~/components"

interface Props {
  modelValue: string
  loading?: boolean
  position?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  position: "left",
})

const emits = defineEmits([ "update:model-value" ])

const selectedLocale = computed({
  get: () => localeOptions.find((locale) => locale.value === props.modelValue),
  set: (locale) => {
    emits("update:model-value", locale)
  },
})

</script>
<template>
  <Listbox
    v-model="selectedLocale"
    as="div"
  >
    <div class="relative font-medium">
      <ListboxButton class="p-1 pr-6 pl-1.5 text-xs bg-transparent btn-listbox-plain text-gray-500 hover:text-gray-600 group">
        <div
          v-if="selectedLocale"
          class="flex items-center gap-2"
        >
          <span>{{ selectedLocale.icon }}</span>
          <span class="hidden sm:inline">{{ selectedLocale.label }}</span>
        </div>
        <span
          v-else
          class="block text-gray-500 truncate"
        >{{ $t('documentGeneralSettings.selectLocale') }}…</span>

        <span
          class="absolute inset-y-0 right-0 flex items-center pr-1 transition-all duration-300 pointer-events-none group-hover:opacity-100 group-focus:opacity-100"
          :class="loading ? 'opacity-100' : 'opacity-0'"
        >
          <SpinLoader
            v-if="loading"
            class="w-3 h-3 text-gray-500"
            aria-hidden="true"
          />
          <ChevronUpDownIcon
            v-else
            class="w-4 h-4 text-gray-500"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="listbox-options"
          :class="{
            'left-0 right-auto': position === 'left',
            'right-0 left-auto': position === 'right',
            'left-0 right-auto top-auto bottom-full mb-1 mt-auto': position === 'top',
          }"
        >
          <ListboxOption
            v-for="localeOption in localeOptions"
            :key="localeOption.value"
            v-slot="{ active, selected }"
            as="template"
            :value="localeOption.value"
          >
            <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center gap-2 pr-3']">
                <span>{{ localeOption.icon }}</span> <span>{{ localeOption.label }}</span>
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-gray-500',
                  'absolute inset-y-0 right-0 flex items-center pr-3',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
          <slot name="info" />
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
