<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, nextTick, ref, toRaw, watch, onBeforeMount, onBeforeUnmount } from "vue"
import { useI18n } from "vue-i18n"
import { z } from "zod"
import { toTypedSchema } from "@vee-validate/zod"
import { useField, useForm, useFieldArray } from "vee-validate"
import { intersection, uniq, without } from "lodash-es"
import { TippyComponent } from "vue-tippy"
import { TrashIcon, PlusCircleIcon } from "@heroicons/vue/24/outline"
import { ArrowLeftIcon, CheckIcon } from "@heroicons/vue/24/solid"
import { Editor as TipTapEditor } from "@tiptap/core"
import { Instance, Props as TippyProps } from "tippy.js"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"

// internal
import { ItemCombobox, FormInputErrors, OverlayScrollbar, SpinLoader, MobileClosePopoverButton, ConditionRule } from "~/components"
import { useConditionStore, useConfirmationStore, useDynamicFieldStore, useNotificationStore } from "~/stores"
import { Condition, ConditionOperator, Rule, MultiFieldType, Template } from "~/types"
import { changedKeys, formatTime, validateMultifieldValue, parseRulesFromSymfonyExpression, getDefaultMultiFieldFormat } from "~/utils"
import { ConditionIcon } from "~/icons"
import { PlusIcon } from "@heroicons/vue/20/solid"

interface Props {
  tippyInstance?: Instance<TippyProps>[]
  template?: Template
  editor: Partial<TipTapEditor>
}
const props = withDefaults(
  defineProps<Props>(),
  {
    tippyInstance: () => [],
    template: null,
  },
)

const { t, locale } = useI18n()
const { notify } = useNotificationStore()

const confirmationStore = useConfirmationStore()
const {
  setShowConfirmModal,
  setConfirmOptions,
} = confirmationStore

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFields } = storeToRefs(dynamicFieldStore)

const conditionStore = useConditionStore()
const { activeConditions, conditionLastSavedMap, conditions, conditionTippy, conditionErrorsMap, uuidsOfUpdatingCondition, /* conditionLastSavedMap, */ conditionUuidBeingRemoved } = storeToRefs(conditionStore)
const { removeConditionFromEntity, setNewCondition } = conditionStore

const isLoadingCreateCondition = ref<boolean>(false)
const isLoadingCondition = computed<boolean>(() => uuidsOfUpdatingCondition.value.includes(localCondition.value?.uuid) || isLoadingCreateCondition.value)

const emptyCondition: Partial<Condition> = Object.freeze({
  name: t("conditions.untitled"),
  operator: ConditionOperator.EQUAL,
  expression: "",
})

const emptyRule: Rule = Object.freeze({
  dynamicFieldRefUuid: null,
  operator: ConditionOperator.EQUAL,
  matchValue: "",
  parentOperator: ConditionOperator.AND,
})

const localNewCondition = ref<Partial<Condition>>({ ...emptyCondition })

const entityUuid = computed<Template["uuid"]>(() => props.template?.uuid)

const idxOfIsEditingRule = ref<number>(0)

const activeConditionFiltered = computed(
  () => conditions.value?.find((el) => intersection(el.uuids, activeConditions.value?.[0]?.uuids)?.length > 0),
)

const localCondition = computed<Partial<Condition>>(
  {
    get: () => {
      if (activeConditionFiltered.value?.uuid) return activeConditionFiltered.value || { ...emptyCondition }
      return localNewCondition.value
    },
    set: (val) => {
      setTimeout(
        async () => {
          if (!activeConditionFiltered.value?.uuid) return
          const isValid = await validate(
            {
              mode: "force",
            },
          )
          const payloadKeys = changedKeys({ ...toRaw(localCondition.value) }, val)
          const checkIntersection = intersection(payloadKeys, Object.keys(isValid.errors))
          const rulesErrorFound = Object.keys(isValid.errors).find((key) => key.startsWith("rules"))
          // Stop update if not valid and the payload includes invalid fields
          if (!payloadKeys.length || (!isValid.valid && (checkIntersection?.length || rulesErrorFound))) return
          conditionStore.updateLocalCondition(val, entityUuid.value)
        },
      )
      if (!activeConditionFiltered.value?.uuid) localNewCondition.value = val
    },
  },
)

const updateFieldValue = (fieldName: keyof Condition, val: any) => {
  setFieldValue(fieldName as any, val)
  localCondition.value = {
    ...localCondition.value || {},
    [fieldName]: val,
  }
}

const refineRuleValidation = (input) => {
  const selectedDynamicField = dynamicFields.value.find((field) => field.ref_uuid === input.dynamicFieldRefUuid)
  const isSimpleOperator = input.operator === ConditionOperator.EMPTY || input.operator === ConditionOperator.NOT_EMPTY
  const hasMatchValue = !!input.matchValue || (selectedDynamicField?.type === MultiFieldType.bool && input.matchValue !== "") || isSimpleOperator
  if (!hasMatchValue) return false
  const dynamicFieldFound = dynamicFields.value.find((field) => field.ref_uuid === input.dynamicFieldRefUuid)
  if (!dynamicFieldFound) return false
  const validatedMultiFieldFormat = validateMultifieldValue(dynamicFieldFound.type, input.matchValue, dynamicFieldFound.format || getDefaultMultiFieldFormat(selectedDynamicField?.type, locale.value))
  if (!validatedMultiFieldFormat && !isSimpleOperator) return false
  return true
}

const refineRuleReturnMessage = (rules) => {
  for (let index = 0; index < rules.length; index++) {
    const input = rules[index]
    const selectedDynamicField = dynamicFields.value.find((field) => field.ref_uuid === input.dynamicFieldRefUuid)
    const isSimpleOperator = input.operator === ConditionOperator.EMPTY || input.operator === ConditionOperator.NOT_EMPTY
    const hasMatchValue = !!(input.matchValue || (selectedDynamicField?.type === MultiFieldType.bool && input.matchValue !== "") || isSimpleOperator)

    if (!hasMatchValue) {
      return {
        message: t("dynamicFields.errors.noMatchValue"),
        path: [ `rules[${index}].matchValue` ],
      }
    }

    if (!selectedDynamicField) {
      return {
        message: t("dynamicFields.errors.dynamicFieldNotFound"),
        path: [ `rules[${index}].dynamicFieldRefUuid` ],
      }
    }

    const validatedMultiFieldFormat = validateMultifieldValue(selectedDynamicField.type, input.matchValue, selectedDynamicField.format || getDefaultMultiFieldFormat(selectedDynamicField?.type, locale.value))
    if (!validatedMultiFieldFormat && !isSimpleOperator) {
      return {
        message: t("dynamicFields.errors.wrongFormat." + selectedDynamicField.type),
        path: [ `rules[${index}].matchValue` ],
      }
    }
  }

  return null
}

const conditionSchema = z
  .object(
    {
      name: z.string().min(1, t("common.required")),
      expression: z.string().min(1, t("common.required")),
      rules: z.array(
        z.object(
          {
            parentOperator: z.string().nullable().optional(),
            operator: z.string().min(1, t("common.required")),
            matchValue: z.string().nullable().optional(),
            dynamicFieldRefUuid: z.string().nullable(),
          }),
      )
        //.catchall(z.string().min(1, t("common.required")))
        .refine( (input) => {

          return input.every((rule) => refineRuleValidation(rule))

        }, (input) => {

          return refineRuleReturnMessage(input)

        }),
    },
  )

type ConditionValidatedType = z.infer<typeof conditionSchema>

const conditionValidator = toTypedSchema(conditionSchema)

const { errors, setValues, setFieldValue, validate, resetForm } = useForm<ConditionValidatedType>(
  {
    validationSchema: conditionValidator,
  },
)
const formValidationErrors = ref<Partial<Record<keyof Condition, string[]>>>({})
const backendErrors = computed(() => conditionErrorsMap.value[localCondition.value.uuid])

const name = useField<string>("name")
const expression = useField<string>("expression")
const { remove: removeRuleField, update: updateRuleField, replace: replaceRuleFields } = useFieldArray<Rule>("rules")

interface UiCondition extends Condition {
  rules: Rule[]
}

const errorsToShow = computed<Partial<Record<keyof UiCondition, string[]>>>(
  () => {
    const errors: Partial<Record<keyof Condition, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const hasErrors = computed<boolean>(() => !!Object.keys(errorsToShow.value).length && !selectedCondition.value?.uuid)

const isVisibleExpertMode = ref<boolean>(false)

// Store a new condition
const createCondition = async () => {

  if (localCondition.value.uuid) return

  if (isActiveSelectExistingCondition.value && selectedCondition.value) {
    handleUpdateUuidsOfExistingCondition(selectedCondition.value)
    return
  }

  const isValid = await validate(
    {
      mode: "force",
    },
  )

  if (!isValid.valid) return

  try {
    isLoadingCreateCondition.value = true

    const payload = {
      ...toRaw(localCondition.value),
      ...{ uuids: activeConditions.value?.[0]?.uuids },
    }

    const createdCondition = await conditionStore.createCondition(entityUuid.value, payload)

    if (!createdCondition) throw new Error(t("conditions.errors.invalid", { string: JSON.stringify({ createdCondition }) }))

    conditionTippy.value?.forEach((tippy: TippyComponent) => tippy.hide())

    localCondition.value = Object.assign({}, emptyCondition)
    setNewCondition(Object.assign({}, emptyCondition))
    conditionStore.setConditionErrors("0", null)
    nextTick(() => {
      resetForm()
      // Set field values
      setFieldValue("name", localCondition.value?.name || "")
      setFieldValue("expression", localCondition.value?.expression || "")
    })

    nextTick(() => localCondition.value = emptyCondition)
  } catch (err) {
    conditionStore.setConditionErrors(
      "0",
      err.response?.data?.errors || null,
    )

    notify(
      {
        title: t("conditions.errors.add"),
        message: err.response?.data?.message || err.message,
        type: "error",
      },
    )
  } finally {
    isLoadingCreateCondition.value = false
  }
}

const confirmRemoveUuidFromCondition = (callback: () => void) => {
  setConfirmOptions({
    title: t("conditions.removeUuid"),
    description: t("conditions.confirmRemoveOnlyOneUuid"),
    buttonText: t("conditions.confirmRemoveUuidButton"),
    callback: callback,
    cancelCallback: () => { return false },
  })
  setShowConfirmModal(true)
}

const handleUpdateUuidsOfExistingCondition = async (condition: Condition, action = "add", forceUuid = null) => {
  const updateUuids = forceUuid ? [ forceUuid ] : activeConditions.value?.[0]?.uuids
  try {
    isLoadingCreateCondition.value = true
    // Update the selected condition via conditionStore to add the uuids of the currently activeCondition
    const uuids = action === "add" ? uniq([ ...condition.uuids, ...updateUuids ]) : without(condition.uuids, ...updateUuids)
    const payload = {
      ...condition,
      ...{ uuids },
    }
    await conditionStore.updateLocalCondition(payload, entityUuid.value)
    localCondition.value = emptyCondition
    isActiveSelectExistingCondition.value = false
    conditionTippy.value?.forEach((tippy: TippyComponent) => tippy.hide())
  } catch (err) {
    notify(
      {
        title: t("conditions.errors.update"),
        message: err.response?.data?.message || err.message,
        type: "error",
      },
    )
  } finally {
    isLoadingCreateCondition.value = false
  }
}

const handleClickRemoveCondition = (conditionUuid: Condition["uuid"]) => {
  // Get tippy reference node
  const reference = conditionTippy.value?.[0]?.reference
  // Get first parent of reference that has data-uuid or data-list-uuid set
  const referenceUuid = reference?.closest("[data-uuid], [data-list-uuid]")?.getAttribute("data-uuid") || reference?.closest("[data-uuid], [data-list-uuid]")?.getAttribute("data-list-uuid")
  // Check if popover was triggered from sidebar, by looking for an reference element id and seeing if it starts with "condition_input_"
  const isSidebar = reference?.id?.startsWith("condition_input_")
  // If there are multiple uuids concerned, confirm if only the current UUID should be removed
  if (activeConditions.value?.[0]?.uuids?.length > 1 && referenceUuid && !isSidebar) {
    confirmRemoveUuidFromCondition(async () => {
      await handleUpdateUuidsOfExistingCondition(conditions.value?.find((el) => el.uuid === conditionUuid), "remove", referenceUuid)
      setShowConfirmModal(false)
    })
    return
  } else {
    removeCondition(conditionUuid)
  }
}

// Function to remove a dynamic field from an entity
const removeCondition = async (conditionUuid: Condition["uuid"]) => {
  if (!entityUuid.value) return

  await removeConditionFromEntity(entityUuid.value, conditionUuid)

  conditionTippy.value?.forEach((tippy: TippyComponent) => tippy.hide())

  props.editor?.commands.directDecoration()
}

const isActiveAddNewCondition = ref<boolean>(false)
const isActiveSelectExistingCondition = ref<boolean>(false)
const selectedCondition = ref<Condition | null>(null)
const setCondition = (condition: Condition) => {
  selectedCondition.value = condition
}

const toggleIsActiveSelectExistingCondition = () => {
  isActiveSelectExistingCondition.value = true
  setCondition(null)
}

const goToPreSelectionStep = () => {
  isActiveSelectExistingCondition.value = false
  isActiveAddNewCondition.value = false
  selectedCondition.value = null
  setCondition(null)
}

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual("md")

const lastSaved = computed<number | undefined>(() => {
  return conditionLastSavedMap.value[localCondition.value?.uuid] || null
})

const showPreSelectionStep = computed<boolean>(() => {
  const check = (!isActiveSelectExistingCondition.value && !isActiveAddNewCondition.value && !selectedCondition.value?.uuid && !localCondition.value?.uuid)
  return check
})

// Compute expression based on stores rules according to Symfony expression language
const localExpression = computed({
  get: () => {
    return localCondition.value?.expression
  },
  set: (val) => {
    setFieldValue("expression", val)
    localCondition.value = {
      ...localCondition.value || {},
      expression: val,
    }
  },
})

onBeforeMount(() => {
  // Reset empty condition on load
  setNewCondition(Object.assign({}, emptyCondition))
  // Set field values
  setFieldValue("name", localCondition.value?.name || "")
  setFieldValue("expression", localCondition.value?.expression || "")
})

onBeforeUnmount(() => {
  setNewCondition(Object.assign({}, emptyCondition))
})

const setAndPopulateRuleFields = (expression: string) => {
  if (!expression) {
    replaceRuleFields([ Object.assign({}, emptyRule) ])
    localRules.value = [ Object.assign({}, emptyRule) ]
    return
  }
  // Break expression down into rules
  // Step 1, break down into rules by finding Symfony expression AND or OR statements
  const rules = parseRulesFromSymfonyExpression(expression)
  // For each rule, set the values
  // Replace fieldArray
  replaceRuleFields(rules)
  localRules.value = rules
}

const localRules = ref<Rule[]>([ Object.assign({}, emptyRule) ])

const addRule = () => {
  // add a rule to localRules
  localRules.value?.push(Object.assign({}, emptyRule))
  idxOfIsEditingRule.value = localRules.value?.length - 1
}

interface RuleUpdate {
  ruleIdx: number
  val: any
  fieldName: keyof Rule
}

const updateRule = (args: RuleUpdate) => {
  const updatedRule = args.fieldName ? {
    ...localRules.value?.[args.ruleIdx],
    [args.fieldName]: args.val,
  } : args.val
  // Update corresponding field in fieldArray
  updateRuleField(args.ruleIdx, updatedRule)
  localRules.value[args.ruleIdx] = updatedRule
}

const removeRule = (ruleIdx: number) => {
  // Remove correspnding field from fieldArray
  removeRuleField(ruleIdx)
  localRules.value?.splice(ruleIdx, 1)
}

const setIdxOfIsEditingRule = (ruleIdx: number) => {
  if (idxOfIsEditingRule.value === ruleIdx) idxOfIsEditingRule.value = null
  else idxOfIsEditingRule.value = ruleIdx
}

watch(activeConditionFiltered, (newVal, oldVal) => {
  // See if ID has changed
  if (newVal?.uuid === oldVal?.uuid) return
  // Reset active step
  isActiveSelectExistingCondition.value = false
  isActiveAddNewCondition.value = false
  selectedCondition.value = null
  // If empty, then idxOfIsEditingRule should be 0, otherwise null
  idxOfIsEditingRule.value = !newVal?.uuid ? 0 : null
})

watch(
  () => localCondition.value,
  (newVal, oldVal) => {
    if (!newVal) return
    if (localCondition.value?.uuid) setValues(newVal)
    else if (localCondition.value) {
      setFieldValue("name", localCondition.value?.name || "")
      setFieldValue("expression", localCondition.value?.expression || "")
    }
    // If expression has changed, populate rule fields
    if (newVal?.expression !== oldVal?.expression) {
      if (newVal?.expression) setAndPopulateRuleFields(newVal?.expression)
      else setAndPopulateRuleFields("")
    }
  },
  {
    deep: true,
    immediate: true,
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof Condition, string[]>>,
      )
  },
)

const assembleRuleString = (rule: Rule, index: number) => {
  const dynamicFieldRefUuid = rule.dynamicFieldRefUuid
  const operator = rule.operator
  let matchValue = rule.matchValue || ""
  // For is empty and is not empty, match_value should be null and the operator should be === and !==
  /* if ([ ConditionOperator.EMPTY, ConditionOperator.NOT_EMPTY ].includes(rule.operator)) {
    matchValue = "NULL"
  } */
  const parentOperator = rule.parentOperator

  // Escape matchValue
  if (typeof matchValue === "string") {
    matchValue = matchValue.replace(/"/g, "\\\"")
  }

  // multifield_compare("equal", dynamic_field["134-3453-3453"], "true")

  let ruleString = `multifield_compare("${operator}", dynamic_field["${dynamicFieldRefUuid}"], "${matchValue}")`

  // Prepend parent_operator for all but the first rule
  if (index !== 0 && parentOperator) {
    ruleString = `${parentOperator} ${ruleString}`
  }

  return ruleString
}

watch(localRules, (newVal) => {
  replaceRuleFields(newVal)

  const rulesString = localRules.value?.map((rule: any, index: number) => {

    return assembleRuleString(rule, index)

  }).join(" ")
  localExpression.value = rulesString
}, { deep: true })

</script>

<template>
  <div
    id="conditionPopover"
    class="relative min-w-fit"
    :data-cy-sel="`condition-popover`"
  >
    <div
      class="flex flex-col border-none rounded-md popover popover-slate bg-slate-800 popover-mobile-fullscreen"
    >
      <MobileClosePopoverButton
        :label="$t('popovers.editParty')"
      />
      <component
        :is="isMobile ? OverlayScrollbar : 'div'"
        v-if="showPreSelectionStep"
        tag="div"
        class="flex-1 h-full p-4"
      >
        <div class="text-[10px] font-medium tracking-wider uppercase text-slate-500 pb-0.5">
          {{ $t('conditions.selectNewOrExisting') }}
        </div>
        <div class="grid grid-cols-1 mt-1 -mx-4 -mb-4 -space-y-px">
          <button
            :data-cy-sel="'condition-type-new'"
            class="relative flex items-center px-3 py-2 border-t cursor-pointer focus:z-10 hover:bg-slate-700 focus:bg-slate-900 border-t-slate-700 focus:outline-none group"
            @click="toggleIsActiveSelectExistingCondition"
          >
            <span
              class="flex items-center shrink-0 p-1.5 rounded-md text-slate-100 bg-slate-700 group-hover:bg-slate-600"
              aria-hidden="true"
            >
              <ConditionIcon
                class="w-4 h-4"
              />
            </span>
            <div class="flex flex-col ml-2 text-xs text-left text-slate-400 group-hover:text-slate-300 group-focus:text-slate-300">
              <span class="font-medium">{{ $t('conditions.selectExisting') }}</span>
            </div>
          </button>
          <button
            :data-cy-sel="'condition-type-new'"
            class="relative flex items-center px-3 py-2 border-t cursor-pointer focus:z-10 hover:bg-slate-700 focus:bg-slate-900 border-t-slate-700 focus:outline-none group"
            @click="isActiveAddNewCondition = true"
          >
            <span
              class="flex items-center shrink-0 p-1.5 rounded-md text-slate-100 bg-slate-700 group-hover:bg-slate-600"
              aria-hidden="true"
            >
              <PlusCircleIcon
                class="w-4 h-4"
              />
            </span>
            <div class="flex flex-col ml-2 text-xs text-left text-slate-400 group-hover:text-slate-300 group-focus:text-slate-300">
              <span class="font-medium">{{ $t('conditions.addNew') }}</span>
            </div>
          </button>
        </div>
      </component>
      <component
        :is="isMobile ? OverlayScrollbar : 'div'"
        v-else-if="isActiveSelectExistingCondition"
        tag="div"
        class="flex-col flex-1 h-full rounded-t-md sm:max-w-[26rem]"
      >
        <div class="text-[10px] font-medium tracking-wider uppercase text-slate-500 pb-1.5 px-4 pt-4">
          {{ $t('conditions.useExistingCondition') }}
        </div>
        <div class="px-4 pb-4">
          <ItemCombobox
            :selected-item="selectedCondition"
            :disabled="isLoadingCondition"
            :cancelable="false"
            layout="slate"
            :include-conditions="true"
            :include-dynamic-fields="false"
            @update:selected-item="setCondition($event)"
          />
        </div>
      </component>
      <component
        :is="isMobile ? OverlayScrollbar : 'div'"
        v-else
        tag="div"
        class="flex-col flex-1 h-full rounded-t-md sm:w-[26rem]"
      >
        <div class="flex flex-col gap-3 p-4 pt-3 text-xs">
          <div
            v-if="isVisibleExpertMode"
            class="p-2 mb-2 font-mono text-xs text-purple-800 rounded-md bg-purple-50"
          >
            {{ expression.value.value }}
          </div>
          <div>
            <label
              class="font-medium"
              for="party-name"
            >
              {{ $t('conditions.name') }}
              <span class="text-teal-500">
                *
              </span>
            </label>
            <div
              class="mt-1"
            >
              <input
                id="condition-name"
                :data-cy-sel="`condition-name-input`"
                :value="name.value.value"
                :placeholder="$t('conditions.namePlaceholder')+ '…'"
                type="text"
                name="condition-name"
                class="input-slate"
                :class="{ 'input-has-errors': errorsToShow?.name?.length }"
                @input="($event) => updateFieldValue('name', ($event.target as HTMLInputElement).value)"
              >
            </div>
            <div
              v-if="errorsToShow?.name"
              class="mt-0.5"
            >
              <FormInputErrors
                layout="slate"
                :errors="errorsToShow?.name"
              />
            </div>
          </div>
          <div>
            <div
              v-for="rule, ruleIdx in localRules"
              :key="ruleIdx"
              class="relative px-4 -mx-4 border-b border-slate-700"
              :class="ruleIdx === 0 ? 'border-t' : ''"
            >
              <ConditionRule
                :rule="rule"
                :rule-idx="ruleIdx"
                :rule-count="localRules?.length"
                :condition-uuid="localCondition.uuid"
                :is-loading-condition="isLoadingCondition"
                :is-editing="idxOfIsEditingRule === ruleIdx"
                :errors="errorsToShow"
                @update:rule="updateRule"
                @remove="removeRule"
                @toggle-editing="setIdxOfIsEditingRule(ruleIdx)"
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              class="flex items-center w-auto gap-1 text-xs btn-slate btn-sm disabled:text-slate-500"
              :disabled="hasErrors"
              @click="addRule"
            >
              <PlusIcon class="w-4 h-4" />
              {{ $t('conditions.addRule') }}
            </button>
          </div>
        </div>
      </component>

      <div
        class="flex items-center gap-3 p-3 text-center border-t border-slate-700 bg-slate-900 rounded-b-md"
        :class="isActiveSelectExistingCondition || isActiveAddNewCondition || localCondition.uuid ? 'justify-between' : 'justify-center'"
      >
        <button
          v-if="(isActiveSelectExistingCondition || isActiveAddNewCondition) && !localCondition.uuid"
          type="button"
          class="flex items-center gap-2 btn-plain hover:text-white focus:ring-0 focus:ring-offset-0 focus:border-slate-600 focus:text-white hover:bg-slate-800 focus:bg-slate-950 focus:ring-slate-700"
          @click.prevent="goToPreSelectionStep"
        >
          <ArrowLeftIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.back') }}
        </button>
        <button
          v-else-if="localCondition.uuid"
          type="button"
          class="flex items-center gap-2 btn-plain hover:text-red-500 focus:ring-0 focus:ring-offset-0 focus:border-slate-600 focus:text-white hover:bg-slate-800 focus:bg-slate-950 focus:ring-slate-700"
          :disabled="conditionUuidBeingRemoved === localCondition?.uuid"
          @click.prevent="handleClickRemoveCondition(localCondition.uuid)"
        >
          <TrashIcon
            v-if="conditionUuidBeingRemoved !== localCondition?.uuid"
            class="w-4 h-4 shrink-0"
          />
          <SpinLoader
            v-else
            class="w-4 h-4 shrink-0"
          />
          {{ $t('common.delete') }}
        </button>
        <button
          v-if="!localCondition.uuid && (isActiveSelectExistingCondition || isActiveAddNewCondition)"
          type="button"
          class="btn-slate"
          :disabled="isLoadingCreateCondition || hasErrors"
          @click.prevent="createCondition"
        >
          <span
            v-if="isLoadingCreateCondition"
            class="pointer-events-none"
          >
            <SpinLoader class="w-5 h-5 shrink-0" />
          </span>
          <span v-if="isLoadingCreateCondition">{{ $t('common.saving') }}…</span>
          <span v-else>{{ $t('common.save') }}</span>
        </button>
        <span
          v-else-if="isLoadingCondition"
          class="flex items-center justify-center gap-2 text-sm font-medium pointer-events-none text-slate-700 btn-plain"
        >
          <SpinLoader class="w-5 h-5 shrink-0" />
          {{ $t('common.saving') }}…
        </span>
        <span
          v-else-if="lastSaved"
          class="flex items-center justify-center gap-2 text-sm font-medium pointer-events-none text-slate-500 btn-plain"
        >
          <CheckIcon
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.lastSavedAt', {time:formatTime(lastSaved)}) }}
        </span>
        <span
          v-else-if="!showPreSelectionStep"
          class="flex items-center justify-center text-sm font-medium truncate pointer-events-none text-slate-500 btn-plain"
        >
          {{ $t('common.noUnsavedChanges') }}
        </span>
      </div>
    </div>
  </div>
</template>
