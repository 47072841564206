<script setup lang="ts">
// external
import { computed } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CurrencyOption } from "~/types"
import { CheckIcon } from "@heroicons/vue/24/solid"
import { PlusIcon } from "@heroicons/vue/20/solid"

// internal
import { currencyOptions } from "~/utils"

interface Props {
  modelValue: string | null
  options: string[]
  isDisabled?: boolean
  selectedCurrencies?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  selectedCurrencies: null,
})

const emit = defineEmits([ "update:model-value" ])

const selectedValue = computed({
  get: () => {
    return currencyOptions.find((el) => el.code === props.modelValue)
  },
  set: (val) => {
    emit("update:model-value", val)
  },
})

const selectableCurrencies = computed<CurrencyOption[]>(() => {
  return currencyOptions.filter((el) => !props.selectedCurrencies?.includes(el.code))
})

</script>
<template>
  <Listbox
    :id="'currencySelector'"
    v-model="selectedValue"
    as="div"
    :disabled="isDisabled"
    class="flex items-center justify-between px-1 space-x-2 text-sm transition-all duration-500"
  >
    <div
      class="relative grow max-w-fit"
    >
      <ListboxButton
        class="flex items-center shrink-0 gap-0.5 px-1 py-0 text-xs btn-plain focus:ring-0 focus:bg-indigo-100"
        :class="isDisabled ? 'text-gray-400 pointer-events-none' : 'text-indigo-500 hover:text-indigo-700'"
      >
        <PlusIcon
          v-if="!isDisabled && options.length"
          class="w-4 h-4"
          aria-hidden="true"
        />
        {{ !isDisabled && options.length ? $t('dynamicFields.addCurrency') : selectedCurrencies?.length ? '' : $t('dynamicFields.noCurrencies') }}
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="listbox-options"
        >
          <ListboxOption
            v-for="currency in selectableCurrencies"
            :key="currency.code"
            v-slot="{ active, selected }"
            as="template"
            :value="currency.code"
          >
            <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
              <div class="flex items-center">
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ currency.code }} ({{ currency.symbol }})
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
