<script setup lang="ts">
// external
import { computed } from "vue"
import { EllipsisHorizontalIcon } from "@heroicons/vue/24/solid"
import { Menu, MenuButton, MenuItems } from "@headlessui/vue"

// internal
import { LoadingPlaceholder, MetadataValueDisplay } from "~/components"
import { MagicTableColumn } from "~/types"


interface Props {
  columns: MagicTableColumn[],
  loading?: boolean
  selected?: boolean
  segmentClasses?: string
  entry?: any
  additionalSegmentClasses?: string
  allowOverflow?: boolean
  index?: number
  total?: number
  showOptions?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    selected: false,
    entry: null,
    segmentClasses: "relative py-4 text-sm",
    additionalSegmentClasses: "",
    allowOverflow: false,
    index: 0,
    total: 0,
    showOptions: true,
  },
)

const emits = defineEmits([ "column-click", "select", "edit-metadata" ])

const getVisibilityClassesForColumn = (column) => {
  if (props.allowOverflow) return ""
  if (!column.breakpoint) {
    return ""
  }
  return `hidden ${column.breakpoint}:table-cell`
}

const menuPositionClasses = computed(() => {
  if (
    (props.total > 3 && props.index > props.total - 3) ||
    (props.total === 3 && props.index === 2)
  ) {
    return "bottom-full mb-1 origin-bottom-right"
  }
  return "top-full mt-1 origin-top-right"
})

const editMetadata = (columnKey) => {
  if (props.entry.metadataValues) {
    const metadataValue = props.entry.metadataValues.find((metadataValue) => metadataValue.metadata.name === columnKey)

    emits("edit-metadata", {
      name: columnKey,
      uuid: props.entry.uuid,
      value: metadataValue ? metadataValue.value : null,
    })
  }
}
</script>

<template>
  <td
    v-for="( column, columnIndex ) in props.columns"
    :key="column.key"
    :class="[
      segmentClasses,
      additionalSegmentClasses,
      { 'w-5' : column.key === 'select'},
      { 'w-16' : column.key === 'options'},
      getVisibilityClassesForColumn(column),
      columnIndex === 0 ? 'px-4' : 'px-2.5',
      column.extraClasses,
      allowOverflow && columnIndex === props.columns.length - 1 ? 'sticky right-0 bg-white group-hover:bg-gray-50 z-10' : '',
      allowOverflow && columnIndex === 0 ? 'sticky left-0 bg-white z-20 group-hover:bg-gray-50' : '',
    ]"
    @click.stop="$emit('column-click', column.key)"
  >
    <div
      v-if="selected && column.key === 'select'"
      class="absolute left-0 w-1 bg-indigo-200 -top-px -bottom-px"
    />
    <div
      v-if="column.key === 'select'"
      class="flex items-center"
    >
      <div
        v-if="allowOverflow"
        class="absolute top-0 bottom-0 w-3 pointer-events-none left-shadow left-full bg-gradient-to-r to-transparent from-gray-500/10"
      />
      <input
        type="checkbox"
        class="w-4 h-4 text-indigo-500 border-gray-300 rounded focus:ring-indigo-500"
        :checked="selected"
        @change="$emit('select')"
      >
    </div>
    <div
      v-else-if="column.key === 'options'"
      class="flex items-center justify-center"
    >
      <div
        v-if="allowOverflow"
        class="absolute top-0 bottom-0 w-3 pointer-events-none right-shadow right-full bg-gradient-to-r from-transparent to-gray-500/10"
      />
      <Menu
        v-if="showOptions"
        as="div"
        class="relative z-30 inline-block text-left"
        :class="loading ? 'opacity-0' : ''"
      >
        <div>
          <MenuButton
            class="btn-menu"
            data-cy-sel="magic-table-options-button"
          >
            <div class="sr-only">
              {{ $t('magicTable.columns.options') }}
            </div>
            <EllipsisHorizontalIcon
              class="w-5 h-5 text-gray-900"
              aria-hidden="true"
            />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 z-20 w-56 overflow-hidden divide-y rounded-md divide-slate-800 bg-slate-900 focus:outline-none"
            :class="menuPositionClasses"
            data-cy-sel="magic-table-options-items"
          >
            <slot
              name="options"
            />
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <MetadataValueDisplay
      v-else-if="!!entry && column.isMetadata"
      :name="column.key"
      :values="entry.metadataValues"
      @click="editMetadata(column.key)"
    />
    <slot
      v-else
      :name="column.key"
    />
    <LoadingPlaceholder
      v-if="loading"
      class="inset-1.5 rounded-md"
    />
  </td>
</template>
