<script setup lang="ts">
import {
  ExclamationTriangleIcon,
} from "@heroicons/vue/24/outline"

interface Props {
  text?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)
</script>
<template>
  <div class="rounded-md bg-red-100 shadow-sm text-red-700 px-3 py-2 flex items-center space-x-2">
    <div class="w-5">
      <ExclamationTriangleIcon
        class="h-5 w-5"
        aria-hidden="true"
      />
    </div>
    <div class="text-sm">
      <slot>{{ props.text }}</slot>
    </div>
  </div>
</template>
