<script setup lang="ts">
// external
import { XCircleIcon } from "@heroicons/vue/24/solid"
import { ref, nextTick } from "vue"

interface Props {
  showFilter?: boolean
  isSidebarOpen?: boolean
  hasActiveFilters?: boolean
  filterCount?: number,
  showToggleControls?: boolean,
}
withDefaults(
  defineProps<Props>(),
  {
    showFilter: false,
    isSidebarOpen: false,
    filterCount: 0,
    hasActiveFilters: false,
    showToggleControls: false,
  },
)

defineEmits([ "reset", "close" ])

const allOpen = ref(false)

const openAll = () => {
  allOpen.value = false
  nextTick(() => {
    allOpen.value = true
  })
}
const closeAll = () => {
  allOpen.value = true
  nextTick(() => {
    allOpen.value = false
  })
}


</script>

<template>
  <div
    class="relative"
    :class="{'flex items-start w-full' : showFilter, 'overflow-x-hidden sm:overflow-x-visible' : isSidebarOpen}"
  >
    <div
      v-if="showFilter"
      class="relative space-y-4 transition-all duration-200"
      :class="isSidebarOpen ? 'filter-bar-open sm:w-64 flex-none opacity-100 mr-6' : 'h-0 overflow-hidden w-0 flex-shrink opacity-0 mr-0 pointer-events-none'"
    >
      <div>
        <div class="flex items-center justify-between pb-2 text-sm font-medium border-b border-b-gray-200">
          <span class="py-0.5">{{ $t('filter.filter', 2) }}</span>
          <button
            type="button"
            class="flex items-center space-x-1 text-gray-500"
            @click="$emit('close')"
          >
            <span class="inline sm:hidden font-normal">{{ $t('common.close') }}</span>
            <XCircleIcon
              class="w-5 h-5 text-gray-400"
            />
          </button>
        </div>
        <div
          v-if="showToggleControls"
          class="flex items-center space-x-3 pb-2 mt-2"
        >
          <button
            type="button"
            class="text-xs link-primary"
            @click="openAll"
          >
            {{ $t('filter.openAll') }}
          </button>
          <button
            type="button"
            class="text-xs link-primary"
            @click="closeAll"
          >
            {{ $t('filter.closeAll') }}
          </button>
        </div>
      </div>
      <div class="w-full sm:w-64">
        <div class="relative z-0 space-y-3">
          <slot
            name="filter"
            :all-open="allOpen"
          />
        </div>
      </div>
    </div>
    <div
      class="grow flex flex-col"
    >
      <slot />
    </div>
  </div>
</template>
<style>
@media all and (max-width: 639px) {
  .filter-bar-open {
  width: calc(100vw - 63px);
}
}

</style>
