<script setup lang="ts">
// external
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { computed, ref, watch } from "vue"
import axios from "axios"
import { z } from "zod"
import { useField, useForm } from "vee-validate"
import { toTypedSchema } from "@vee-validate/zod"
import { Link } from "@inertiajs/vue3"
import { storeToRefs } from "pinia"

// internal
import { FormInputErrors, TeamIconDisplay, TagDisplay, WarningBox } from "~/components"
import { Team, Document, Template, Tag } from "~/types"
import { useNotificationStore, useAccountStore } from "~/stores"

interface Props {
  teams?: Team[]
  templates: Template[]
  tagOptions?: Tag[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    teams: () => [],
  },
)

const { notify } = useNotificationStore()
const { mau } = storeToRefs(useAccountStore())

const automationFormSchema = z
  .object(
    {
      name: z.string(),
      template: z.string(),
      team_uuid: z.string().nullable().optional(),
      tag_uuids: z.array(z.string()).nullable().optional(),
    },
  )

type AutomationFormValidatedType = z.infer<typeof automationFormSchema>

const automationFormValidator = toTypedSchema(automationFormSchema)

const { errors, setFieldValue, resetForm, validate } = useForm<AutomationFormValidatedType>(
  {
    validationSchema: automationFormValidator,
  },
)

const formValidationErrors = ref<Partial<Record<keyof AutomationFormValidatedType, string[]>>>({})
const backendErrors = ref<Partial<Record<keyof AutomationFormValidatedType, string[]>>>({})

const name = useField<string>("name")
const template = useField<Template["uuid"]>("template")
const teamUuid = useField<Team["uuid"]>("team_uuid")
const tagUuids = useField<Tag["uuid"][]>("tag_uuids")

const selectedTemplate = computed<Template>(() => props.templates.find((el) => el.uuid === template?.value?.value) )
const selectedTeam = computed<Team>(() => props.teams.find((el) => el.uuid === teamUuid?.value?.value) )
const selectedTags = computed<Tag[]>(() => props.tagOptions.filter((el) => tagUuids?.value?.value?.includes(el.uuid) ) )

const remainingTagOptions = computed<Tag[]>(() => props.tagOptions.filter((el) => !tagUuids?.value?.value?.includes(el.uuid) ) )

const tagToAdd = computed({
  get: () => null,
  set: (tagUuid) => {
    localAutomationForm.value.tag_uuids.push(tagUuid)
    setFieldValue("tag_uuids", [ ...localAutomationForm.value.tag_uuids ])
  },
})

const removeTag = (tag) => {
  const idx = localAutomationForm.value.tag_uuids.findIndex((tagUuid) => tagUuid === tag.uuid)
  if (idx !== -1) {
    localAutomationForm.value.tag_uuids.splice(idx, 1)
    setFieldValue("tag_uuids", [ ...localAutomationForm.value.tag_uuids ])
  }
}

const emptyAutomationForm = Object.freeze<Partial<AutomationFormValidatedType>>({
  template: props.templates[0].uuid,
  team_uuid: null,
  tag_uuids: [],
})

const localAutomationForm = ref<Partial<AutomationFormValidatedType>>({ ...emptyAutomationForm })

const updateFieldValue = (fieldName: keyof AutomationFormValidatedType, val: any) => {
  setFieldValue(fieldName as any, val)
  localAutomationForm.value = {
    ...localAutomationForm.value || {},
    [fieldName]: val,
  }
}

const errorsToShow = computed<Partial<Record<keyof AutomationFormValidatedType, string[]>>>(
  () => {
    const errors: Partial<Record<keyof AutomationFormValidatedType, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof Document, string[]>>,
      )
  },
)

const createAutomation = async (): Promise<void> => {
  const isValid = await validate(
    {
      mode: "force",
    },
  )
  if (!isValid.valid) return
  const payload = {
    name: name.value.value,
    template_uuid: template.value.value,
  }

  if (teamUuid.value.value) {
    payload["team_uuid"] = teamUuid.value.value
  }

  if (tagUuids.value.value?.length) {
    payload["tag_uuids"] = tagUuids.value.value
  }

  const submitRoute = route("api.automation-init.create-automation")
  return axios.post(submitRoute, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: "Automation could not be created",
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  })
}

const resetAutomationForm = () => {
  localAutomationForm.value = { ...emptyAutomationForm }
  resetForm()
}

defineExpose({ resetAutomationForm, createAutomation })

</script>
<template>
  <div class="flex flex-col h-full px-6 pb-6 space-y-4 grow">
    <div class="text-sm text-gray-500">
      {{ $t('automations.create.automationText') }}
    </div>
    <div>
      <label
        for="automation-name"
        class="block mb-1 text-sm font-medium text-gray-900"
      >
        {{ $t('automations.automationName') }} <span class="text-indigo-500">*</span>
      </label>
      <div>
        <input
          id="automation-name"
          :value="name.value.value"
          :placeholder="$t('automations.automationNamePlaceholder') + '…'"
          type="text"
          name="automation-name"
          class="input-plain"
          :class="[errorsToShow?.name?.length ? 'error' : '']"
          @input="($event) => updateFieldValue('name', ($event.target as HTMLInputElement).value)"
        >
      </div>
      <FormInputErrors
        v-if="errorsToShow?.name?.length"
        :errors="errorsToShow?.name"
      />
    </div>

    <div>
      <Listbox
        :model-value="template.value.value"
        as="div"
        @update:model-value="($event) => updateFieldValue('template', $event)"
      >
        <ListboxLabel
          class="block mb-1 text-sm font-medium text-gray-900"
        >
          {{ $t('magicTable.columns.template') }} <span class="text-indigo-500">*</span>
        </ListboxLabel>
        <div class="relative">
          <ListboxButton
            class="btn-listbox-plain"
          >
            <div
              v-if="selectedTemplate"
              class="flex items-center"
            >
              <span class="block truncate">
                {{ selectedTemplate.name }}
              </span>
            </div>
            <div
              v-else
              class="text-gray-500"
            >
              <span class="block truncate">
                {{ $t('documents.create.formFields.selectTemplate') }}…
              </span>
            </div>
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="z-20 listbox-options">
              <ListboxOption
                v-for="templateOption in templates"
                :key="templateOption.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="templateOption.uuid"
              >
                <li
                  :class="[
                    active ? 'bg-gray-700' : '',
                    'listbox-option',
                  ]"
                >
                  <div class="flex items-center">
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ templateOption.name }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <FormInputErrors
        v-if="errorsToShow?.template?.length"
        :errors="errorsToShow?.template"
      />
      <WarningBox
        v-if="selectedTemplate && (selectedTemplate.missing_signature_security_level || selectedTemplate.has_checkpoints)"
        class="mt-3"
      >
        <div>
          {{ $t('automations.templateIssues') }}
        </div>
        <ul class="list-disc ml-8 my-3">
          <li v-if="selectedTemplate.missing_signature_security_level">
            {{ $t('automations.templateMissingSecurityLevel') }}
          </li>
          <li v-if="selectedTemplate.has_checkpoints">
            {{ $t('automations.templateHasCheckpoints') }}
          </li>
        </ul>
        <div
          v-if="mau?.permissions.includes('template_manage')"
          class="w-full flex items-center justify-end"
        >
          <Link
            :href="route('templates.edit', selectedTemplate.uuid)"
            class="btn-white btn-sm"
          >
            {{ $t('automations.goToTemplate') }}
          </Link>
        </div>
      </WarningBox>
    </div>

    <div>
      <span
        class="block mb-1 text-sm font-medium text-gray-900"
      >{{ $t('magicTable.columns.team') }}</span>
      <div class="flex items-center space-x-4">
        <Listbox
          :model-value="teamUuid.value.value"
          as="div"
          class="grow"
          @update:model-value="($event) => updateFieldValue('team_uuid', $event)"
        >
          <div class="relative">
            <ListboxButton class="btn-listbox-plain">
              <TeamIconDisplay
                v-if="selectedTeam"
                :team="selectedTeam"
                class="w-4 h-4 mr-2"
              />
              <span
                v-if="!selectedTeam"
                class="block text-gray-500 truncate"
              >{{ $t('documentGeneralSettings.selectTeams') }}…</span>
              <span v-else>{{ selectedTeam.name }}</span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions class="listbox-options">
                <ListboxOption
                  v-for="teamOption in teams"
                  :key="teamOption.uuid"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="teamOption.uuid"
                >
                  <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex items-center']">
                    <TeamIconDisplay
                      :team="teamOption"
                      class="w-4 h-4 mr-2"
                    />
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ teamOption.name }}</span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <button
          v-if="selectedTeam"
          @click="teamUuid.value.value = null"
        >
          <XMarkIcon
            aria-hidden="true"
            class="w-5 h-5 -ml-2 text-gray-500"
          />
          <span class="sr-only">{{ $t('common.remove') }}</span>
        </button>
      </div>
    </div>

    <div v-if="tagOptions.length">
      <span
        class="block mb-1 text-sm font-medium text-gray-900"
      >{{ $t('magicTable.columns.tags') }}</span>
      <Listbox
        v-model="tagToAdd"
        :disabled="!remainingTagOptions.length"
        as="div"
      >
        <div class="relative">
          <ListboxButton class="btn-listbox-plain">
            <span
              class="block text-gray-500 truncate"
            >{{ $t('tags.selectTags') }}…</span>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="listbox-options">
              <ListboxOption
                v-for="tagOption in remainingTagOptions"
                :key="tagOption.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="tagOption.uuid"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex items-center']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ tagOption.name }}</span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <div
        v-if="selectedTags?.length"
        class="flex flex-wrap mt-2 -mb-1"
      >
        <TagDisplay
          v-for="tag in selectedTags"
          :key="'tag' + tag.uuid"
          :tag="tag"
          :removable="true"
          class="mb-1 mr-1"
          @remove="removeTag"
        />
      </div>
    </div>
  </div>
</template>
