<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"

import { FlagIcon } from "@heroicons/vue/24/outline"
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"

// internal
import { DocumentCheckpointsList, EmptyState, OverlayScrollbar, SkeletonLoader, TabPills } from "~/components"
import { useCheckpointStore } from "~/stores"
import { CheckpointTab, UiUser } from "~/types"

interface Props {
  combinedUsers?: UiUser[]

}
withDefaults(
  defineProps<Props>(),
  {
    combinedUsers: () => [],
  },
)

const { t } = useI18n()

const checkpointStore = useCheckpointStore()
const {
  checkpointPasses,
  isLoadingCheckpointPasses,
  readyCheckpoints,
} = storeToRefs(checkpointStore)

const activeCheckpoints = computed(() => {
  return checkpointPasses.value.filter((el) => el.status === "active")
})

const upcomingCheckpoints = computed(() => {
  return checkpointPasses.value.filter((el) => el.status === "upcoming")
})

const finishedCheckpoints = computed(() => {
  return checkpointPasses.value.filter(
    (el) =>
      el.status === "approved" || el.status === "rejected" || el.status === "canceled",
  )
})

const checkpointTabs = computed(() => {
  return     [
    { id: CheckpointTab.active, name: t("checkpoints.tabs.active"), badge: activeCheckpoints.value?.length + readyCheckpoints.value?.length },
    { id: CheckpointTab.upcoming, name: t("checkpoints.tabs.upcoming"), badge: upcomingCheckpoints.value?.length, active: !!(upcomingCheckpoints.value?.length) },
    { id: CheckpointTab.finished, name: t("checkpoints.tabs.finished"), badge: finishedCheckpoints.value?.length, active: !!(finishedCheckpoints.value?.length) },
  ]
})

const activeCheckpointTab = ref(checkpointTabs.value[0])

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="px-6 pt-6 shrink-0"
      :class="!checkpointPasses?.length ? 'border-b border-b-gray-200' : ''"
    >
      <h3
        class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('checkpoints.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('checkpoints.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingCheckpointPasses"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>
    <template v-else-if="!checkpointPasses?.length">
      <EmptyState
        :hide-button="true"
        class="my-6"
      >
        <template #icon>
          <FlagIcon
            aria-hidden="true"
          />
        </template>
        {{ $t('checkpoints.empty') }}
      </EmptyState>
    </template>
    <template v-else>
      <div class="px-6 pb-4 border-b border-gray-200 shrink-0">
        <TabPills
          v-model:active-tab="activeCheckpointTab"
          :tabs="checkpointTabs"
        />
      </div>
      <OverlayScrollbar
        ref="checkpointScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <template v-if="activeCheckpointTab.id === CheckpointTab.active">
          <div v-if="activeCheckpoints?.length || readyCheckpoints?.length">
            <div
              v-if="activeCheckpoints?.length"
              class="mb-2"
            >
              <div class="px-6 py-1 text-xs font-medium text-white uppercase bg-green-500">
                {{ $t('checkpoints.tabs.active') }} ({{ activeCheckpoints?.length }})
              </div>

              <DocumentCheckpointsList
                v-if="activeCheckpoints?.length"
                :checkpoint-passes="activeCheckpoints"
                :combined-users="combinedUsers"
              />
            </div>
            <div
              v-if="readyCheckpoints?.length"
            >
              <div class="px-6 py-1 text-xs font-medium text-white uppercase bg-indigo-500">
                {{ $t('checkpoints.ready') }} ({{ readyCheckpoints?.length }})
              </div>

              <DocumentCheckpointsList
                :checkpoint-passes="readyCheckpoints"
                :combined-users="combinedUsers"
              />
            </div>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <FlagIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('checkpoints.noActive') }}
          </EmptyState>
        </template>
        <template v-if="activeCheckpointTab.id === CheckpointTab.upcoming">
          <div
            v-if="upcomingCheckpoints?.length && !isLoadingCheckpointPasses"
          >
            <div class="px-6 py-1 text-xs font-medium text-white uppercase bg-gray-400">
              {{ $t('checkpoints.tabs.upcoming') }} ({{ upcomingCheckpoints?.length }})
            </div>

            <DocumentCheckpointsList
              v-if="upcomingCheckpoints?.length"
              :checkpoint-passes="upcomingCheckpoints"
              :combined-users="combinedUsers"
            />
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <FlagIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('checkpoints.noUpcoming') }}
          </EmptyState>
        </template>
        <template v-if="activeCheckpointTab.id === CheckpointTab.finished">
          <div
            v-if="finishedCheckpoints?.length && !isLoadingCheckpointPasses"
          >
            <div
              class="flex items-center gap-1 px-6 py-1 text-xs font-medium text-white uppercase bg-gray-400"
            >
              <span>
                {{ $t('checkpoints.previousCheckpoints') }} ({{ finishedCheckpoints?.length }})
              </span>
            </div>

            <DocumentCheckpointsList
              :checkpoint-passes="finishedCheckpoints"
              :combined-users="combinedUsers"
            />
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <FlagIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('checkpoints.noFinished') }}
          </EmptyState>
        </template>
      </OverlayScrollbar>
    </template>
  </div>
</template>
