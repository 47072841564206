<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 612 270"
  ><path
    d="M288.7 190h-26.6v-74.5h-10.7V93.1h10.7v-5.4c0-26 12.8-38.1 39.4-37.3v24.5c-9.2 1.1-12.8 4.6-12.8 12.8v5.4h3.9l8.9 22.4h-12.8V190h0zm81.5-96.9h28.3l-54.6 141.1h-28.5l18.2-46.5-34-94.6h28.3l19.7 57.6 22.6-57.6zm57.4 0V105c7.1-11.1 14.2-15.3 26.4-15.3 9.2 0 18 3.3 23.9 9 6.3 6.3 8.6 14 8.6 29.9V190h-26.6v-60.1c0-5.4-.6-8.6-2.3-11.3-2.5-4-7.5-6.5-12.8-6.5-10.3 0-17.2 7.8-17.2 19V190H401V93.1h26.6zm93.2 96.9h-26.6V50.4h26.6v80l27-37.3h30.8l-32.9 46.5 32.9 50.4h-30.8l-27-40.2V190h0z"
    fill="#111827"
  /><linearGradient
    gradientUnits="userSpaceOnUse"
    x1="141.8"
    y1="103.1"
    x2="141.8"
    y2="103.1"
  ><stop
    offset="0"
    stop-color="#848dc6"
  /><stop
    offset="1"
    stop-color="#30347e"
  /></linearGradient><linearGradient
    id="B"
    gradientUnits="userSpaceOnUse"
    x1="112.173"
    y1="162.996"
    x2="49.621"
    y2="54.653"
  ><stop
    offset="0"
    stop-color="#848dc6"
  /><stop
    offset="1"
    stop-color="#30347e"
  /></linearGradient><path
    fill="url(#B)"
    d="M90.6 175.4l15.2-15.8c.2-.2.3-.5.3-.8V107c0-.2 0-.3-.1-.5l-33-64c-.6-1.1-2.2-.6-2 .6l17.7 131.7c.1.9 1.2 1.3 1.9.6z"
  /><linearGradient
    id="C"
    gradientUnits="userSpaceOnUse"
    x1="32.968"
    y1="205.296"
    x2="108.211"
    y2="205.296"
  ><stop
    offset="0"
    stop-color="#848dc6"
  /><stop
    offset="1"
    stop-color="#30347e"
  /></linearGradient><path
    fill="url(#C)"
    d="M104.4 168.6l-71.1 71.9c-.9.9.2 2.3 1.3 1.7l73.1-41.2a1.1 1.1 0 0 0 .6-1l-1.9-30.7c-.2-1-1.3-1.4-2-.7z"
  /><linearGradient
    id="D"
    gradientUnits="userSpaceOnUse"
    x1="148.617"
    y1="178.791"
    x2="73.25"
    y2="48.251"
  ><stop
    offset="0"
    stop-color="#848dc6"
  /><stop
    offset="1"
    stop-color="#30347e"
  /></linearGradient><path
    fill="url(#D)"
    d="M113.3 197.3l-6.9-167.5c-.1-1.2 1.6-1.6 2.1-.4l54.2 138.2c.2.5 0 1-.4 1.3L115 198.2c-.7.4-1.7-.1-1.7-.9z"
  /><linearGradient
    id="E"
    gradientUnits="userSpaceOnUse"
    x1="181.959"
    y1="113.489"
    x2="150.243"
    y2="145.206"
  ><stop
    offset="0"
    stop-color="#848dc6"
  /><stop
    offset="1"
    stop-color="#30347e"
  /></linearGradient><path
    fill="url(#E)"
    d="M163.7 156.2l8.6-51.7c.2-1-1-1.7-1.8-1L150.7 120c-.3.3-.5.7-.3 1.2l11.2 35.2c.4 1.1 1.9.9 2.1-.2z"
  /><linearGradient
    id="F"
    gradientUnits="userSpaceOnUse"
    x1="195.369"
    y1="123.935"
    x2="176.726"
    y2="142.577"
  ><stop
    offset="0"
    stop-color="#848dc6"
  /><stop
    offset="1"
    stop-color="#30347e"
  /></linearGradient><path
    fill="url(#F)"
    d="M173 138.2l3.5-30.8c.1-.9 1.3-1.3 1.9-.6l31.9 36.5c.7.8 0 1.9-1 1.8l-35.4-5.8c-.6 0-1-.5-.9-1.1z"
  /></svg>
</template>
