<script setup lang="ts">
// external
import { onMounted, ref } from "vue"
import { storeToRefs } from "pinia"

// internal
import { OnboardingStepContainer } from "~/components"
import { useOnboardingStore } from "~/stores"
import { LegalEntity } from "~/lottie"
import { Vue3Lottie } from "vue3-lottie"
import { LightBulbIcon } from "@heroicons/vue/24/outline"

interface Props {
  hasClickedNextStepOnParty?: boolean
}

withDefaults(defineProps<Props>(), {
  hasClickedNextStepOnParty: false,
})

const onboardingStore = useOnboardingStore()
const {
  partyName,
  partyAddress,
} = storeToRefs(onboardingStore)


const partyNameInput = ref()
const partyAddressInput = ref()
onMounted(() => {
  setTimeout(() => {
    if (!partyName.value) {
      partyNameInput.value.focus()
    } else if (!partyAddress.value) {
      partyAddressInput.value.focus()
    }
  }, 500)
})

</script>
<template>
  <OnboardingStepContainer v-cy="`onboarding-step-party`">
    <template #default>
      <div class="space-y-8">
        <div>
          <div class="mb-2 text-sm font-medium">
            {{ $t('onboarding.steps.party.name') }} <span class="text-indigo-500">*</span>
          </div>
          <div>
            <input
              ref="partyNameInput"
              v-model="partyName"
              v-cy="`input-party-name`"
              type="text"
              class="input-primary"
              :class="{ 'input-has-errors': hasClickedNextStepOnParty && !partyName}"
              :placeholder="$t('onboarding.steps.party.namePlaceholder') + '…'"
            >
          </div>
          <div
            v-if="hasClickedNextStepOnParty && !partyName"
            class="p-2 my-2 text-xs text-red-500 bg-red-100 rounded-md"
          >
            {{ $t('onboarding.steps.party.addressError') }}
          </div>
          <div class="flex items-center gap-3 p-3 mt-2 text-xs text-gray-500 bg-gray-100 rounded-md">
            <LightBulbIcon class="flex-shrink-0 w-5 h-5 text-yellow-400" />
            <span>{{ $t('onboarding.steps.party.nameHint') }}</span>
          </div>
        </div>
        <div>
          <div class="mb-1 text-sm font-medium">
            {{ $t('onboarding.steps.party.address') }} <span class="text-indigo-500">*</span>
          </div>
          <div>
            <textarea
              ref="partyAddressInput"
              v-model="partyAddress"
              v-cy="`input-party-address`"
              class="input-primary"
              :class="{ 'input-has-errors': hasClickedNextStepOnParty && !partyAddress}"
              :placeholder="$t('onboarding.steps.party.addressPlaceholder')"
              rows="4"
            />
          </div>
          <div
            v-if="hasClickedNextStepOnParty && !partyAddress"
            class="p-2 my-2 text-xs text-red-500 bg-red-100 rounded-md"
          >
            {{ $t('onboarding.steps.party.addressError') }}
          </div>
          <div class="flex items-center gap-3 p-3 mt-2 text-xs text-gray-500 bg-gray-100 rounded-md">
            <LightBulbIcon class="flex-shrink-0 w-5 h-5 text-yellow-400" />
            <span>{{ $t('onboarding.steps.party.addressHint') }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #aside>
      <div class="flex flex-col items-center justify-center w-full h-full">
        <div class="relative w-full">
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="block w-full h-full m-12 bg-gray-100 rounded-full max-w-32 max-h-32" />
          </div>
          <Vue3Lottie
            :animation-data="LegalEntity"
            class="max-w-72"
            aria-hidden="true"
          />
        </div>
        <div class="w-full mt-5 text-center">
          <h3 class="mb-2 text-lg font-semibold text-indigo-600">
            {{ $t('onboarding.steps.party.aboutHeadline') }}
          </h3>
          <div class="text-sm">
            {{ $t('onboarding.steps.party.aboutCopy') }}
          </div>
        </div>
      </div>
    </template>
  </OnboardingStepContainer>
</template>
