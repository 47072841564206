import { Node as PMNode } from "@tiptap/pm/model"

import { Comment } from "~/types"

interface ExtraProps {
  is_resolved_proxy?: boolean
  is_resolved?: boolean
  is_active?: boolean
  uuid?: string
  id?: number
}

type Item = Partial<ExtraProps & Comment>

export interface Result {
  type: string
  number: number
  from: number
  to: number
  node: PMNode
  item: Item
}

const nodesToIgnore: string[] = [
  "orderedList",
  "bulletList",
  "listItem",
  "tableRow",
  "tableHeader",
  "tableCell",
  "column",
]

export default class UuidMarker {
  private doc: PMNode

  private results: Result[]

  private number: number

  constructor (doc: PMNode) {
    this.doc = doc
    this.results = []
    this.number = 0
  }

  record (
    type: string,
    number: number,
    from: number,
    to: number,
    node: PMNode,
    item: Item,
  ): void {
    // Prevent duplicate entries (e.g. when there are 2 comments with the same UUID)
    const check = this.results.find((el) => el.from === from)

    if (check && item?.is_resolved === false) {
      // If there are duplicate entries but at least one of the is NOT resolved (for comments), map them accordingly
      this.results.find((el) => el.from === from).item.is_resolved_proxy = false
    } else {
      this.results.push({
        type,
        number,
        from,
        to,
        node,
        item,
      })
    }
  }

  checkDescendants (doc: PMNode, query, scannedNodes: string[]): void {
    doc.descendants(
      (node, pos) => {
        const $pos = doc.resolve(pos)

        let check = []

        if (nodesToIgnore.includes(node.type.name)) return

        if (scannedNodes.includes(node.type.name)) {
          if (
            !node.attrs.uuid ||
            (
              scannedNodes.includes("list") &&
              node.type.name !== "list" &&
              (node.type.name !== "paragraph" && node.type.name !== "fineprint") &&
              $pos.parent.type.name === "list"
            )) return
          if (
            (node.type.name === "paragraph" || node.type.name === "fineprint") &&
            $pos.parent.type.name === "list" &&
            scannedNodes.includes("list")
          ) {
            // In this case, we have to stop and return if there are any siblings of the same parent
            // because that means that the paragraph is nested (alone) within a list items and the condition
            // if any should be applied to the list item instead
            const siblings = []
            $pos.parent?.descendants((node) => {
              if (node.attrs?.uuid) siblings.push(node)
            })
            if (siblings.length < 2) return
          }

          check = query.filter((el) => el.prosemirror_data_uuid === node.attrs.uuid || el.uuids?.includes(node.attrs.uuid))

          if (check.length) {
            check.forEach(
              (item) => {
                this.number += 1
                this.record(item.type, this.number, pos + 1, pos + 1 + node.nodeSize, node, item)
              },
            )
          } else {
            this.number += 1
            this.record("new", this.number, pos + 1, pos + 1 + node.nodeSize, node, null)
          }
        }
      },
    )
  }

  scan (query, scannedNodes: string[]) {
    this.checkDescendants(this.doc, query, scannedNodes)
    return this
  }

  getResults () {
    return this.results
  }
}
