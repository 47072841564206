<script setup lang="ts">
// external
import { computed, ref } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  MenuButton,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { CalendarDaysIcon } from "@heroicons/vue/24/outline"

// internal
import { RadioGroupPills, DateInput, DurationInput, CurrencyInput, Dropdown, DropdownLink } from "~/components"
import { DynamicField, DynamicFieldSettings, MultiFieldType, DynamicFieldAutofillType, MultifieldFormat } from "~/types"
import { useI18n } from "vue-i18n"
import { formatMultifieldNumber } from "~/utils"

interface Props {
  modelValue: any
  dynamicFieldRefUuid?: DynamicField["ref_uuid"]
  dynamicFieldType: DynamicField["type"]
  dynamicFieldAutofillType?: DynamicFieldAutofillType
  disabled?: boolean
  hasError?: boolean
  dynamicFieldLastSavedMap?: Record<DynamicField["uuid"], number>
  selectValues?: string[]
  isPopover?: boolean
  isExternal?: boolean
  settings?: DynamicFieldSettings
  format?: MultifieldFormat
}

const props = withDefaults(
  defineProps<Props>(),
  {
    disabled: false,
    hasError: false,
    dynamicFieldLastSavedMap: null,
    dynamicFieldAutofillType: null,
    selectValues: () => [],
    isPopover: false,
    isExternal: false,
    dynamicFieldRefUuid: () => (Math.floor(Math.random() * 90000) + 10000) + "",
    settings: null,
    format: null,
  },
)

const emit = defineEmits( [ "focus", "blur", "update:model-value", "set-autofill-value", "enter" ])

const { locale } = useI18n()

const handleUpdateRadioGroupPills = (value: any) => {
  emit("update:model-value", value)
  setTimeout(() => { emit("enter") }, 300)
}

const handleTextareaEnter = () => {
  emit("enter")
}

const handleSetAutofillValue = (autofillType: DynamicFieldAutofillType) => {
  if (!autofillType) {
    emit("set-autofill-value", null)
    return
  }
  emit("set-autofill-value", autofillType)
  setTimeout(() => { emit("enter") }, 300)
}

const dateInput = ref()

const setDateInputValueToToday = () => {
  const value = new Date().toISOString().slice(0, 10)
  dateInput.value?.setValue(value)
}

const formatPlaceholder = computed(() => {
  // Take sample amount of 0 and apply formatMultifieldNumber with settings format
  return formatMultifieldNumber(0, props.format, props.dynamicFieldType, locale.value)
})

</script>

<template>
  <!-- Dynamic Field Type: text -->
  <input
    v-if="props.dynamicFieldType === MultiFieldType.text"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :value="props.modelValue"
    type="text"
    :placeholder="$t('dynamicFields.textPlaceholder') + '…'"
    :disabled="disabled"
    :name="'dynamic-field_' + props.dynamicFieldRefUuid"
    :class="['dynamic-field_' + props.dynamicFieldRefUuid, {'error': hasError}, isExternal ? 'input-primary' : 'input-plain']"
    @input="($event) => $emit('update:model-value', ($event.target as HTMLInputElement).value)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @keyup.enter="$emit('enter', $event)"
  >

  <!-- Dynamic Field Type: textarea (multi-line text) -->
  <div
    v-else-if="props.dynamicFieldType === MultiFieldType.textarea"
    class="grow-textarea"
    :data-replicated-value="props.modelValue"
  >
    <textarea
      :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
      :value="props.modelValue"
      :placeholder="$t('dynamicFields.textPlaceholder') + '…'"
      :disabled="disabled"
      :name="'dynamic-field_' + props.dynamicFieldRefUuid"
      :class="['dynamic-field_' + props.dynamicFieldRefUuid, {'error': hasError}, isExternal ? 'input-primary' : 'input-plain']"
      @input="($event) => $emit('update:model-value', ($event.target as HTMLTextAreaElement).value)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @keydown.meta.enter.exact="handleTextareaEnter"
    />
  </div>

  <!-- Dynamic Field Type: number -->
  <input
    v-else-if="props.dynamicFieldType === MultiFieldType.number"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :value="props.modelValue"
    type="number"
    min="-999999999999.99"
    max="999999999999.99"
    step="0.01"
    :lang="locale"
    :placeholder="formatPlaceholder || $t('dynamicFields.numberPlaceholder') + '…'"
    :disabled="disabled"
    :name="'dynamic-field_' + props.dynamicFieldRefUuid"
    :class="['dynamic-field_' + props.dynamicFieldRefUuid, {'error': hasError}, isExternal ? 'input-primary' : 'input-plain']"
    @input="($event) => $emit('update:model-value', ($event.target as HTMLInputElement).value)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @keyup.enter="$emit('enter')"
  >

  <!-- Dynamic Field Type: e-mail -->
  <input
    v-else-if="props.dynamicFieldType === MultiFieldType.email"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :value="props.modelValue"
    type="email"
    :placeholder="$t('dynamicFields.emailPlaceholder') + '…'"
    :disabled="disabled"
    :name="'dynamic-field_' + props.dynamicFieldRefUuid"
    :class="['dynamic-field_' + props.dynamicFieldRefUuid, {'error': hasError}, isExternal ? 'input-primary' : 'input-plain']"
    @input="($event) => $emit('update:model-value', ($event.target as HTMLInputElement).value)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @keyup.enter="$emit('enter', $event)"
  >

  <!-- Dynamic Field Type: date -->
  <template
    v-else-if="props.dynamicFieldType === MultiFieldType.date"
  >
    <div class="relative flex items-center">
      <DateInput
        v-if="!dynamicFieldAutofillType"
        :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
        ref="dateInput"
        :style="isExternal ? 'white' : 'gray'"
        :width="'auto'"
        :model-value="props.modelValue"
        :show-day="true"
        :has-error="hasError"
        :show-month="true"
        :show-year="true"
        :disabled="disabled"
        :class="['dynamic-field_' + props.dynamicFieldRefUuid, 'inline-block mr-3']"
        :last-saved="dynamicFieldLastSavedMap[props.dynamicFieldRefUuid]"
        @update:model-value="($event) => $emit('update:model-value', $event)"
        @submit-enter="$emit('enter', $event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
      <Dropdown
        menu-classes="text-left w-full"
        width="full"
        align="right"
      >
        <template #trigger>
          <MenuButton
            :class="!dynamicFieldAutofillType ? 'p-1 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:rounded-md focus:ring-indigo-500' : 'btn-plain bg-gray-100 focus:ring-0 focus:ring-offset-0 pr-8 px-3 py-1.5 w-full'"
            class="flex items-center text-sm gap-1.5 text-indigo-500 hover:text-indigo-700"
          >
            <template v-if="!dynamicFieldAutofillType">
              <CalendarDaysIcon class="flex-shrink-0 w-3 h-3" />
              <span class="truncate">{{ $t('dynamicFields.autofill') }}…</span>
            </template>
            <template v-else>
              <div class="flex items-center gap-1.5 truncate">
                <template v-if="dynamicFieldAutofillType === DynamicFieldAutofillType.document_created_date">
                  <CalendarDaysIcon
                    class="flex-shrink-0 w-3 h-3"
                    aria-hidden="true"
                  />
                  <span class="truncate">{{ $t(`dynamicFields.documentCreatedDate`) }}</span>
                </template>
                <template v-else-if="dynamicFieldAutofillType === DynamicFieldAutofillType.document_review_date">
                  <CalendarDaysIcon
                    class="flex-shrink-0 w-3 h-3"
                    aria-hidden="true"
                  />
                  <span class="truncate">{{ $t(`dynamicFields.documentReviewDate`) }}</span>
                </template>
                <template v-else-if="dynamicFieldAutofillType === DynamicFieldAutofillType.document_signing_date">
                  <CalendarDaysIcon
                    class="flex-shrink-0 w-3 h-3"
                    aria-hidden="true"
                  />
                  <span class="truncate">{{ $t(`dynamicFields.documentSigningDate`) }}</span>
                </template>
              </div>
              <button
                type="button"
                class="absolute inset-y-0 flex items-center text-gray-400 hover:text-gray-600 right-2"
                @click.prevent="handleSetAutofillValue(null)"
              >
                <XMarkIcon

                  class="w-4 h-4"
                  aria-hidden="true"
                />
              </button>
            </template>
          </MenuButton>
        </template>

        <template #content>
          <DropdownLink
            as="button"
            :icon="true"
            @click="setDateInputValueToToday"
          >
            <CalendarDaysIcon
              class="w-4 h-4 mr-2 shrink-0"
              aria-hidden="true"
            /> {{ $t('dynamicFields.setToToday') }}
          </DropdownLink>
          <DropdownLink
            as="button"
            :icon="true"
            @click="handleSetAutofillValue(DynamicFieldAutofillType.document_created_date)"
          >
            <CalendarDaysIcon
              class="w-4 h-4 mr-2 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('dynamicFields.documentCreatedDate') }}</span>
          </DropdownLink>
          <DropdownLink
            as="button"
            :icon="true"
            @click="handleSetAutofillValue(DynamicFieldAutofillType.document_review_date)"
          >
            <CalendarDaysIcon
              class="w-4 h-4 mr-2 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('dynamicFields.documentReviewDate') }}</span>
          </DropdownLink>
          <DropdownLink
            as="button"
            :icon="true"
            @click="handleSetAutofillValue(DynamicFieldAutofillType.document_signing_date)"
          >
            <CalendarDaysIcon
              class="w-4 h-4 mr-2 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('dynamicFields.documentSigningDate') }}</span>
          </DropdownLink>
        </template>
      </dropdown>
    </div>
  </template>

  <!-- Dynamic Field Type: list -->
  <div
    v-else-if="props.dynamicFieldType === MultiFieldType.list"
    class="grow-textarea"
    :data-replicated-value="props.modelValue"
  >
    <textarea
      :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
      :value="props.modelValue"
      :placeholder="$t('dynamicFields.textPlaceholder') + '…'"
      :disabled="disabled"
      :name="'dynamic-field_' + props.dynamicFieldRefUuid"
      :class="['dynamic-field_' + props.dynamicFieldRefUuid, {'error': hasError}, isExternal ? 'input-primary' : 'input-plain']"
      @input="($event) => $emit('update:model-value', ($event.target as HTMLTextAreaElement).value)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @keydown.meta.enter.exact="handleTextareaEnter"
    />
  </div>

  <!-- Dynamic Field Type: bool (yes/no, true/false) -->
  <div
    v-else-if="props.dynamicFieldType === MultiFieldType.bool"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :class="[
      'dynamic-field_' + props.dynamicFieldRefUuid,
      'relative mt-1',
      disabled ? 'cursor-not-allowed' : '',
      {'error': hasError}
    ]"
  >
    <div class="flex items-center">
      <RadioGroupPills
        :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
        :model-value="props.modelValue"
        :allow-null="true"
        :disabled="disabled"
        :aria-describedby="'description_' + props.dynamicFieldRefUuid"
        grid-class="grid grid-cols-2 gap-3"
        :options="[
          { title: 'common.yes', value: true },
          { title: 'common.no', value: false },
        ]"
        :sr-label="$t('dynamicFields.selectOption')"
        @update:model-value="handleUpdateRadioGroupPills"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
    </div>
  </div>

  <!-- Dynamic Field Type: select -->
  <Listbox
    v-else-if="props.dynamicFieldType === MultiFieldType.select"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :model-value="props.modelValue"
    as="div"
    :disabled="disabled"
    :class="['dynamic-field_' + props.dynamicFieldRefUuid, {'error': hasError}, 'w-full max-w-full']"
    @update:model-value="($event) => $emit('update:model-value', $event)"
  >
    <div class="relative w-full">
      <ListboxButton
        data-tabindex="1"
        :class="[{'error': hasError}, isExternal ? 'btn-white text-left pr-12' : 'btn-listbox-plain w-auto max-w-full truncate']"
      >
        <span
          v-if="props.modelValue"
          class="block truncate"
        >
          {{ props.modelValue }}
        </span>
        <span
          v-else
          class="block truncate"
          :class="[hasError ? 'text-red-400' : 'text-gray-400']"
        >
          {{ $t('dynamicFields.selectOption') }}…
        </span>
        <span
          v-if="!props.modelValue"
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 "
            :class="[hasError ? 'text-red-400' : 'text-gray-400']"
            aria-hidden="true"
          />
        </span>
        <span
          v-else-if="!disabled"
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 cursor-pointer"
          @click.prevent="$emit('update:model-value', null)"
        >
          <XMarkIcon
            class="w-5 h-5 text-gray-400 hover:text-gray-600"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="listbox-options"
          :class="isPopover ? '' : 'left-0'"
          @focus="
            $emit('focus', {
              target: { id: 'dynamic-field_' + props.dynamicFieldRefUuid },
            })
          "
          @blur="
            $emit('blur', {
              target: { id: 'dynamic-field_' + props.dynamicFieldRefUuid },
            })
          "
        >
          <ListboxOption
            v-for="option in props.selectValues"
            :key="option"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
              <span
                :class="[
                  selected ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]"
              >
                {{ option }}
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <!-- Dynamic Field Type: duration -->
  <DurationInput
    v-else-if="props.dynamicFieldType === MultiFieldType.duration"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :model-value="props.modelValue"
    :disabled="disabled"
    :has-error="hasError"
    :style="isExternal ? 'white' : 'gray'"
    :class="['dynamic-field_' + props.dynamicFieldRefUuid]"
    :last-saved="dynamicFieldLastSavedMap[props.dynamicFieldRefUuid]"
    @update:model-value="($event) => $emit('update:model-value', $event)"
    @submit-enter="$emit('enter', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />

  <!-- Dynamic Field Type: currency -->
  <CurrencyInput
    v-else-if="props.dynamicFieldType === MultiFieldType.currency || props.dynamicFieldType === MultiFieldType.currency_duration"
    :id="`dynamic-field_${ isPopover ? 'popover' : 'input' }_` + props.dynamicFieldRefUuid"
    :model-value="props.modelValue"
    :settings="props.settings"
    :format="props.format"
    :disabled="disabled"
    :has-error="hasError"
    :style="isExternal ? 'white' : 'gray'"
    :class="['dynamic-field_' + props.dynamicFieldRefUuid]"
    :last-saved="dynamicFieldLastSavedMap[props.dynamicFieldRefUuid]"
    :has-duration="props.dynamicFieldType === MultiFieldType.currency_duration"
    @update:model-value="($event) => $emit('update:model-value', $event)"
    @submit-enter="$emit('enter', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>
