<script setup lang="ts">
// external
import { Link } from "@inertiajs/vue3"
import { NoSymbolIcon } from "@heroicons/vue/24/outline"

// internal
import { DialogModal } from "~/components"
import { Template, Document } from "~/types"

interface Props {
  show: boolean
  templates?: Template[]
  documents?: Document[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    templates: null,
    documents: null,
  },
)


defineEmits([ "close" ])
</script>

<template>
  <DialogModal
    :show="props.show"
    :show-close-button="true"
    max-width="xl"
    @close="$emit('close')"
  >
    <template #title>
      <div class="flex items-start justify-start gap-3">
        <span class="flex items-center justify-center w-8 h-8 bg-indigo-100 rounded-full">
          <NoSymbolIcon
            aria-hidden="true"
            class="w-6 h-6 text-indigo-600"
          />
        </span>
        <span class="font-medium">
          {{ $t('blockedResourceModal.title') }}
        </span>
      </div>
    </template>
    <template #content>
      <div
        v-if="templates?.length || documents?.length"
        class="space-y-4"
      >
        <p class="text-sm">
          {{ $t('blockedResourceModal.description') }}
        </p>
        <div
          v-for="template in templates"
          :key="template.uuid"
        >
          <Link
            :href="route('templates.edit', template.uuid)"
            class="text-sm font-medium text-indigo-500 duration-150 hover:text-indigo-600 transition-color"
          >
            {{ template.name }}
          </Link>
        </div>
        <div
          v-for="document in documents"
          :key="document.uuid"
        >
          <Link
            :href="route('documents.show', document.uuid)"
            class="text-sm font-medium text-indigo-500 duration-150 hover:text-indigo-600 transition-color"
          >
            {{ document.name }}
          </Link>
        </div>
        <div class="text-sm">
          {{ $t('blockedResourceModal.info') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
          @click.prevent="$emit('close')"
        >
          {{ $t('common.close') }}
        </button>
      </div>
    </template>
  </DialogModal>
</template>
