<script setup lang="ts">
// external
import { Form, Field } from "vee-validate"
import { router } from "@inertiajs/vue3"
import { ref, nextTick } from "vue"
import { useI18n } from "vue-i18n"

// internal
import { PasswordScore, SpinLoader } from "~/components"
import { useNotificationStore } from "~/stores"
import { PASSWORD_REGEX } from "~/utils"

interface Props {
  passwordError?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    passwordError: false,
  },
)

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const { t } = useI18n()

const isSubmitting = ref(false)

const onSubmit = (values, { resetForm }) => {
  if (!isSubmitting.value) {
    isSubmitting.value = true
    router.post(route("update-password"), values, {
      onSuccess: () => {
        nextTick(() => {
          if (!props.passwordError) {
            addToasterNotification({
              title: t("profileSettings.general.updatePasswordSuccessTitle"),
              message: t("profileSettings.general.updatePasswordSuccessDescription"),
              type: "success",
            })
            resetForm()
          } else {
            addToasterNotification({
              title: t("common.errorOccured"),
              message: t("profileSettings.general.passwordNoMatch"),
              type: "error",
            })
          }

        })
      },
      onError: () => {
        addToasterNotification({
          title: t("common.errorOccured"),
          message: t("profileSettings.general.updatePasswordError"),
          type: "error",
        })
      },
      onFinish: () => {
        isSubmitting.value = false
      },
    })
  }
}
</script>
<template>
  <Form
    v-slot="{ errors, values }"
    class="content-card"
    @submit="onSubmit"
  >
    <div class="p-5 space-y-5">
      <div>
        <label
          for="currentPassword"
          class="block mb-1 text-sm"
        >{{ $t('profileSettings.general.currentPassword') }}:</label>
        <Field
          id="currentPassword"
          name="current"
          type="password"
          class="max-w-xl input-plain"
          :class="[errors.current ? 'error' : '']"
          rules="required"
          :placeholder="$t('profileSettings.general.currentPasswordPlaceholder') + '…'"
          autocomplete="off"
        />
      </div>
      <div>
        <label
          for="newPassword"
          class="block mb-1 text-sm"
        >{{ $t('profileSettings.general.newPassword') }}:</label>
        <Field
          id="newPassword"
          name="new"
          type="password"
          class="max-w-xl input-plain"
          :class="[errors.new ? 'error' : '']"
          :rules="{required: true, regex: PASSWORD_REGEX }"
          :placeholder="$t('profileSettings.general.newPasswordPlaceholder') + '…'"
          autocomplete="off"
        />
        <PasswordScore
          v-if="!!values.new"
          :value="values.new"
          class="max-w-xl mt-2"
        />
      </div>
      <div>
        <label
          for="confirmPassword"
          class="block mb-1 text-sm"
        >{{ $t('profileSettings.general.confirmPassword') }}:</label>
        <Field
          id="confirmPassword"
          name="new_confirmation"
          type="password"
          class="max-w-xl input-plain"
          :class="[errors.new_confirmation ? 'error' : '']"
          rules="required|confirmed:@new"
          :placeholder="$t('profileSettings.general.confirmPasswordPlaceholder') + '…'"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="content-card-footer">
      <button
        class="flex items-center space-x-2 btn-primary"
        type="submit"
        :disabled="isSubmitting"
      >
        <SpinLoader
          v-if="isSubmitting"
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <span>{{ $t('common.save') }}</span>
      </button>
    </div>
  </Form>
</template>
