<script setup lang="ts">
// external
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { computed } from "vue"

// internal
import { DocumentUser, Party } from "~/types"
import { PartyEntityTypeDisplayIcon } from "~/components"
import { usePartyStore } from "~/stores"
import { storeToRefs } from "pinia"

interface Props {
  partyUuid?: Party["uuid"]
  isDisabled?: boolean
  user?: Partial<DocumentUser>
  hideLabel?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    partyUuid: null,
    user: null,
    hideLabel: false,
  },
)

const emit = defineEmits( [ "update:party-uuid" ])

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const partyUuid = computed({
  get: () => props.partyUuid,
  set: (value) => {
    emit("update:party-uuid", value)
  },
})

const party = computed(() => parties.value?.find((el) => el.uuid === partyUuid.value))
</script>

<template>
  <Listbox
    v-bind="!parties?.length ? {
      'data-tippy-help': true,
      'data-tippy-content': $t('userForm.noParties'),
      'data-placement': 'top',
    } : {}"
    v-model="partyUuid"
    as="div"
    :disabled="isDisabled || !parties?.length"
  >
    <ListboxLabel
      v-if="!props.hideLabel"
      class="block text-sm font-medium text-gray-700"
      :for="`user-party-dropdown-button`"
    >
      {{ $t('userForm.party') }} <span class="text-indigo-500">*</span>
    </ListboxLabel>
    <div
      class="relative"
      :class="!props.hideLabel ? 'mt-1' : ''"
    >
      <ListboxButton
        :id="`user-party-dropdown-button`"
        v-cy="`user-party-dropdown-button`"
        :class="[
          partyUuid ? '' : 'text-gray-500',
        ]"
        class="max-w-xs truncate btn-listbox-plain"
        :disabled="isDisabled || !parties?.length"
      >
        <PartyEntityTypeDisplayIcon
          v-if="party"
          :party="party"
          class="w-4 h-4 mr-2 shrink-0"
        />
        <span class="truncate grow whitespace-nowrap">
          {{ party ? (party.name || party.entity_name) : $t('partyForm.selectParty') + '…' }}
        </span>
        <span
          v-if="!isDisabled"
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>
      <slot name="errors" />

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="max-w-xs listbox-options w-max">
          <ListboxOption
            v-for="option in parties"
            :key="option.uuid"
            v-slot="{ active, selected }"
            as="template"
            :value="option.uuid"
          >
            <li
              v-cy="`user-party-dropdown-option`"
              :class="[active ? 'bg-gray-700' : '', 'listbox-option px-3 py-2 text-sm']"
            >
              <div class="flex items-start gap-2.5">
                <div
                  :class="[
                    selected
                      ? 'text-indigo-400'
                      : 'text-gray-200',
                    'mt-0.5'
                  ]"
                >
                  <PartyEntityTypeDisplayIcon
                    :party="option"
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </div>
                <div class="truncate">
                  <p
                    :class="[
                      selected && active
                        ? 'font-semibold'
                        : selected
                          ? 'font-semibold text-indigo-400'
                          : 'font-normal text-gray-200',
                      'truncate',
                    ]"
                  >
                    {{
                      option.entity_name && option.entity_name !== option.name
                        ? option.name + " (" + option.entity_name + ")"
                        : option.name
                    }}
                  </p>
                </div>
                <span
                  v-if="selected"
                  :class="active ? 'text-white' : 'text-indigo-400'"
                >
                  <CheckIcon
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
