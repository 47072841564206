<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { XMarkIcon } from "@heroicons/vue/24/solid"

// internal
import { useDocumentStore, useFileStorageStore, useSharedStore, useUserStore } from "~/stores"
import { Comment, CrudContext, Document, Template } from "~/types"
import { formatDateRelative, getUserRepresentation, formatDateAndTime } from "~/utils"
import { CommentFiles, DiscussionVisibilityButton } from "~/components"

interface Props {
  parent: Comment,
  comment: Comment,
  documentUuid?: Document["uuid"]
  templateUuid?: Template["uuid"]
  isLast: boolean,
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isLast: false,
    documentUuid: null,
    templateUuid: null,
  },
)

const userStore = useUserStore()
const {
  users,
} = storeToRefs(userStore)

const documentStore = useDocumentStore()
const {
  mau,
  mdu,
} = storeToRefs(documentStore)

const fileStorageStore = useFileStorageStore()
const { storedFiles } = storeToRefs(fileStorageStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed(() => {
  return crudContext.value === CrudContext.document ? props.documentUuid : props.templateUuid
})

const relatedFiles = computed(() => storedFiles.value?.filter((file) => file.comment_uuid === props.comment?.uuid && file.comment_uuid && !file.deleted_at))

const commentCreator = computed(() => {
  let creator = users.value.find((user) => user.uuid === props.comment.created_by_document_user_uuid) ?? null
  if (creator) {
    return creator
  }
  creator = users.value.find((user) => user.account_user?.uuid === props.comment.created_by_document_user_account_user?.uuid) ?? null
  if (creator) {
    return creator
  }

  return {
    uuid: props.comment.created_by_document_user_uuid,
    first_name: props.comment.created_by_document_user_first_name,
    last_name: props.comment.created_by_document_user_last_name,
    deleted_at: props.comment.created_by_document_user_deleted_at,
    email: props.comment.created_by_document_user_email,
    profile_photo_url: props.comment.created_by_document_user_profile_photo_url,
  }
})

const commentContent = computed(() => {
  let content = props.comment.html
  if (!content) return ""
  if (props.comment.mentioned_document_user_uuids?.length) {
    for (let i = 0; i < props.comment.mentioned_document_user_uuids.length; i++) {
      const mentionedUser = users.value.find((user) => user.uuid === props.comment.mentioned_document_user_uuids[i])
      if (mentionedUser) {
        content = content.replaceAll(`@${props.comment.mentioned_document_user_uuids[i]}`, getUserRepresentation(mentionedUser))
      }
    }
  }

  return content
})

const parentIsInternal = computed(() => props.parent.scope === "internal")

</script>

<template>
  <article
    class="relative py-1 text-sm pl-7"
  >
    <header class="flex items-center justify-between space-x-2">
      <div class="flex items-center space-x-2">
        <div>
          <img
            v-if="commentCreator?.profile_photo_url"
            class="relative z-10 w-6 h-6 rounded-full"
            :class="commentCreator?.deleted_at ? 'grayscale' : ''"
            :src="
              commentCreator
                ?.profile_photo_url
            "
            :alt="getUserRepresentation(commentCreator)"
          >
          <div
            v-else
            class="flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full"
          >
            <XMarkIcon class="w-4 h-4 text-gray-400" />
          </div>
        </div>
        <div class="flex items-center flex-1 space-x-2 text-gray-900 min-w-0 max-w-[200px] overflow-hyphens">
          <h3
            class="text-xs font-medium line-clamp-1"
            :class="commentCreator.deleted_at ? 'line-through' : ''"
            :data-tippy-help="!!commentCreator.deleted_at"
            :data-tippy-content="commentCreator.deleted_at ? $t('common.deletedAt', {date: formatDateAndTime(commentCreator.deleted_at)}) : ''"
            data-placement="bottom"
            :title="getUserRepresentation(commentCreator)"
          >
            {{ getUserRepresentation(commentCreator) }}
          </h3>
        </div>

        <template v-if="!!mau">
          <span class="text-xs text-gray-500">·</span>
          <DiscussionVisibilityButton
            :parent-is-internal="parentIsInternal"
            :document-uuid="documentUuid"
            :comment="comment"
            :mau="mau"
            :mdu="mdu"
          />
        </template>

        <span class="text-xs text-gray-500">·</span>
        <time
          class="ml-2 text-xs text-gray-500"
          :datetime="comment.created_at"
        >
          {{ formatDateRelative(comment.created_at) }}
        </time>
      </div>
    </header>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="px-2 py-1 mt-2 ml-6 text-sm whitespace-pre-wrap rounded-md comment-content-wrapper overflow-hyphens"
      :class="comment.scope === 'internal' || parent.scope === 'internal' ? 'bg-indigo-50' : ''"
      v-html="commentContent"
    />
    <!-- eslint-enable vue/no-v-html -->
    <CommentFiles
      :related-files="relatedFiles"
      :entity-uuid="entityUuid"
      :scope="parentIsInternal ? 'internal' : comment.scope"
      class="ml-6"
    />
    <div
      class="w-2 border-t-2 border-dotted absolute top-[15px] left-[17px] "
      :class="comment.scope === 'internal_and_external' ? 'border-t-gray-200' : 'border-t-indigo-200'"
    />
  </article>
</template>
