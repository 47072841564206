import { ImageAttrs } from "./resizableImage"

interface ResizableImageAction {
  name: string,
  icon: string

  actionData?: Partial<ImageAttrs>
  isActive?: (attrs: Record<string, any>) => boolean
  delete?: (d: () => void) => void
}

export const resizableImageActions: ResizableImageAction[] = [
  {
    name: "alignLeft",
    actionData: {
      dataAlign: "left",
      dataFloat: null,
    },
    icon: "ImageAlignLeftIcon",
    isActive: (attrs) => attrs.dataAlign === "left",
  },
  {
    name: "alignCenter",
    actionData: {
      dataAlign: "center",
      dataFloat: null,
    },
    icon: "ImageAlignCenterIcon",
    isActive: (attrs) => attrs.dataAlign === "center",
  },
  {
    name: "alignRight",
    actionData: {
      dataAlign: "right",
      dataFloat: null,
    },
    icon: "ImageAlignRightIcon",
    isActive: (attrs) => attrs.dataAlign === "right",
  },
  {
    name: "floatLeft",
    actionData: {
      dataAlign: null,
      dataFloat: "left",
    },
    icon: "FloatLeftIcon",
    isActive: (attrs) => attrs.dataFloat === "left",
  },
  {
    name: "floatRight",
    actionData: {
      dataAlign: null,
      dataFloat: "right",
    },
    icon: "FloatRightIcon",
    isActive: (attrs) => attrs.dataFloat === "right",
  },
  {
    name: "delete",
    icon: "TrashIcon",
    delete: (deleteNode) => deleteNode(),
  },
]
