<script setup lang="ts">
// external
import { ref, watch, computed } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { storeToRefs } from "pinia"
import { Link } from "@inertiajs/vue3"

// internal
import { DialogModal, InfoBox, SpinLoader, TeamList, TeamIconDisplay } from "~/components"
import { Team } from "~/types"
import { useAccountStore } from "~/stores"

interface Props {
  show: boolean
  loading?: boolean
  teams?: Team[],
  preselectedTeams?: Team[],
  callback: (selectedTeams:Team[], mode:string) => void
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    teams: () => [],
    preselectedTeams: () => [],
  },
)

const { mau } = storeToRefs(useAccountStore())

const selectedTeam = computed({
  get: () => null,
  set: (team) => {
    selectedTeams.value.push(team)
  },
})
const selectedTeams = ref([ ...props.preselectedTeams ])

const remainingTeamOptions = computed(() => {
  let teams = [ ...props.teams ]
  if (selectedTeams.value.length) {
    const selectedTeamUuids = selectedTeams.value.map((team) => team.uuid)
    teams = teams.filter((team) => {
      return !selectedTeamUuids.includes(team.uuid)
    })
  }
  return teams.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
})

defineEmits([ "update:show" ])

watch(() => props.show, (newVal) => {
  if (!newVal) {
    selectedTeams.value = []
  }
})

const removeTeam = (team: Team) => {
  const idx = selectedTeams.value.findIndex((entry) => entry.uuid === team.uuid)

  if (idx !== -1) {
    selectedTeams.value.splice(idx, 1)
  }
}

const modes = [ "sync", "add", "remove" ]
const selectedMode = ref(modes[0])
</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="lg"
    :padding="false"
    :show-close-button="true"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <div class="px-6 pt-4">
        <span>{{ $t('teams.manageTeams') }}</span>
      </div>
    </template>
    <template #content>
      <InfoBox
        v-if="!teams?.length"
        class="mx-6 mb-5"
      >
        {{ $t('teams.adminHint') }}
      </InfoBox>
      <div
        v-else
        class="px-6 pb-5"
      >
        <RadioGroup v-model="selectedMode">
          <div class="mb-3 -space-y-px bg-white rounded-md">
            <RadioGroupOption
              v-for="(mode, modeIdx) in modes"
              :key="mode"
              v-slot="{ checked, active }"
              as="template"
              :value="mode"
            >
              <div :class="[modeIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', modeIdx === modes.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200', 'relative flex cursor-pointer border p-4 focus:outline-none']">
                <span
                  :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-600' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']"
                  aria-hidden="true"
                >
                  <span class="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span class="flex flex-col ml-3">
                  <RadioGroupLabel
                    as="span"
                    :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']"
                  >{{ $t(`teams.manage.modes.${mode}.title`) }}</RadioGroupLabel>
                  <RadioGroupDescription
                    as="span"
                    :class="[checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm']"
                  >{{ $t(`teams.manage.modes.${mode}.description`) }}</RadioGroupDescription>
                </span>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <Listbox
          v-model="selectedTeam"
          :disabled="!remainingTeamOptions.length"
          as="div"
        >
          <div class="relative mt-1">
            <ListboxButton class="btn-listbox-plain">
              <span
                class="block text-gray-500 truncate"
              >{{ $t('documentGeneralSettings.selectTeams') }}…</span>

              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions class="listbox-options">
                <ListboxOption
                  v-for="team in remainingTeamOptions"
                  :key="'team_' + team.uuid"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="team"
                >
                  <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                    <div class="flex items-center">
                      <TeamIconDisplay
                        :team="team"
                        class="w-4 h-4 mr-2"
                      />
                      <span
                        :class="[
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate',
                        ]"
                      >
                        {{ team.name }}
                      </span>
                    </div>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-indigo-500',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <TeamList
          v-if="selectedTeams.length"
          class="mt-3"
          :teams="selectedTeams"
          :removable="true"
          @remove="removeTeam"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="$emit('update:show', false)"
        >
          {{ $t('common.cancel') }}
        </button>
        <Link
          v-if="!teams?.length && mau?.permissions?.includes('account_manage')"
          :href="route('account-settings.teams.index')"
          class="flex items-center gap-2 btn-primary"
        >
          <span>{{ $t('common.goToSettings') }}</span>
        </Link>
        <button
          v-else
          :disabled="loading || !selectedTeams.length"
          type="button"
          class="flex items-center gap-2 btn-primary"
          @click.prevent="callback(selectedTeams, selectedMode)"
        >
          <SpinLoader
            v-if="loading"
            class="w-5 h-5 shrink-0"
          />
          <span>{{ $t('common.confirm') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
