<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, ArrowUturnLeftIcon } from "@heroicons/vue/20/solid"
import { useI18n } from "vue-i18n"
import { computed, watch, ref } from "vue"

// internal
import { useDashboardStore, useAccountStore } from "~/stores"
import { useStages } from "~/composables"
import {
  SelectToList,
  SelectTeamToList,
} from "~/components"
import { emptyFilter } from "~/stores/dashboardStore"
import { DashboardScope, SelectOption } from "~/types"
import { onMounted } from "vue"

const { t } = useI18n()

const dashboardStore = useDashboardStore()
const {
  teamOptions,
  tagOptions,
  dashboards,
  activeDashboardUuid,
} = storeToRefs(dashboardStore)
const { resetFilter, createOrUpdateDashboard } = dashboardStore

const accountStore = useAccountStore()
const { contractTypes } = storeToRefs(accountStore)
const { fetchContractTypes } = accountStore

const timeframes = [ 1, 7, 30, 60, 90, 365 ]

const activeDashboard = computed(() => dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value))

const dashboardFilter = computed({
  get: () => activeDashboard.value?.filter || emptyFilter,
  set: (filter) => {
    dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value).filter = filter
  },
})

const timeframeOptions = computed(() => {
  const options = timeframes.map((days) => {
    return {
      label: t("day", days),
      value: days,
    }
  })
  options.unshift({
    label: t("dashboard.noLimit"),
    value: null,
  })
  return options
})

const { stages } = useStages()

const stageOptions = computed(() => stages.value.map((stage) => {
  return {
    label: t(`documents.stages.${stage.name}`),
    value: stage.name,
    color: stage.colors.hex,
  }
}))

const activeTimeframe = computed({
  get: () => timeframeOptions.value.find((option) => option.value === dashboardFilter.value.timeframe),
  set: (timeframeOption) => dashboardFilter.value.timeframe = timeframeOption.value,
})

const numberOfActiveFilters = computed(() => {
  let count = 0

  for (const filterKey in dashboardFilter.value) {
    const filter = dashboardFilter.value[filterKey]

    if (Array.isArray(emptyFilter[filterKey])) {
      count += filter.length
    } else if (filter !== null) {
      count++
    }
  }

  return count
})

const isCreatingOrUpdating = ref(false)
watch(numberOfActiveFilters, async () => {
  if (!isCreatingOrUpdating.value) {
    isCreatingOrUpdating.value = true
    await createOrUpdateDashboard(activeDashboard.value)
    isCreatingOrUpdating.value = false
  }
})

const contractTypeOptions = computed<SelectOption[]>(() => {
  return contractTypes.value.map((contractType) => {
    return {
      label: t(`contractTypes.${contractType.name}`),
      value: contractType.name,
    }
  }).sort((a, b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)
})

onMounted(() => {
  fetchContractTypes()
})

</script>
<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 border-b shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('documentGeneralSettings.title') }}
      </h3>
    </div>

    <div class="px-6 py-4">
      <div
        v-if="!!activeDashboard"
        class="space-y-3"
      >
        <SelectToList
          v-model="dashboardFilter.stages"
          :options="stageOptions"
          :placeholder="$t('filter.selectStages')"
          :label="$t('filter.labels.stages')"
        />
        <SelectTeamToList
          v-if="teamOptions.length && activeDashboard.scope !== DashboardScope.team"
          v-model="dashboardFilter.teams"
          :options="teamOptions"
          :label="$t('filter.labels.teams')"
        />
        <SelectToList
          v-if="tagOptions.length"
          v-model="dashboardFilter.tags"
          :options="tagOptions"
          :placeholder="$t('tags.selectTags')"
          :label="$t('filter.labels.tags')"
        />
        <SelectToList
          v-if="contractTypeOptions.length"
          v-model="dashboardFilter.contract_type"
          :options="contractTypeOptions"
          :placeholder="$t('accountSettings.metadata.selectContractTypes')"
          :label="$t('settings.sections.contractTypes')"
        />
      </div>
      <div class="items-center justify-between pt-2 mt-2 space-y-5 sm:flex sm:space-y-0 sm:space-x-5">
        <div class="flex items-center justify-between w-full space-x-2">
          <span class="text-sm">{{ $t('dashboard.timeLimit') }}:</span>
          <Listbox
            v-model="activeTimeframe"
            as="div"
          >
            <div class="relative">
              <ListboxButton class="relative btn-listbox-plain">
                <span class="block whitespace-nowrap">{{ activeTimeframe.label }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions class="listbox-options">
                  <ListboxOption
                    v-for="timeframe in timeframeOptions"
                    :key="timeframe.value"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="timeframe"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                      <div class="flex items-center whitespace-nowrap">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ timeframe.label }}</span>
                      </div>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-indigo-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="w-4 h-4 -mr-2"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
      </div>

      <div class="flex justify-end mt-5">
        <button
          type="button"
          class="flex items-center space-x-1 text-sm font-medium text-indigo-500"
          @click="resetFilter"
        >
          <ArrowUturnLeftIcon
            aria-hidden="true"
            class="w-4 h-4"
          />
          <span>{{ $t('documents.empty.buttonFilter') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
