// external
import { computed } from "vue"
import { AccountUser } from "~/types"
import { usePage } from "@inertiajs/vue3"

// internal
// https://vitejs.dev/guide/assets
import coview from "~/integrations/coview?url"
import june from "~/integrations/june?url"
import clarity from "~/integrations/clarity?url"


export const launchIntegrations = () => {
  /* Co-View */
  loadIntegration({ src: coview })

  /* JUNE */
  loadIntegration({ src: june })

  /* CLARITY */
  loadIntegration({ src: clarity })

  // LAUNCHED WITHIN PROVIDER:
  /* INTERCOM */
  //loadIntegration(intercom)
  /* PYNE */
  // loadIntegration(pyne)
}

export const loadIntegration = ({ src = null, loadAsync = true, blockInternally = true, callback = undefined }) => {

  const pageProps = computed(() => usePage().props)
  const mau = computed<AccountUser>(() => pageProps.value.mau as AccountUser)
  const isProduction = computed<boolean>(() => window.location.origin === "https://app.fynk.com")
  const isImpersonator = computed<boolean>(() => !!pageProps.value.impersonator)

  if (
    isProduction.value &&
    !isImpersonator.value &&
    !!mau.value &&
    (!blockInternally || !mau.value?.email?.endsWith("@fynk.com"))
  ) {
    const scriptTag = document.createElement("script")
    scriptTag.src = src
    // https://sentry.io/answers/uncaught-syntax-error-cannot-use-import-statement-outside-a-module/
    scriptTag.type = "module"
    scriptTag.async = loadAsync

    if (callback) { window.addEventListener("load", function () { callback() }, false) }

    document.head.appendChild(scriptTag)
  }

}
