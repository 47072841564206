<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref, toRaw, watch } from "vue"

import { BoltIcon, EllipsisHorizontalIcon, PencilIcon, TrashIcon } from "@heroicons/vue/24/solid"
import tippy, { Instance, Props as TippyProps, roundArrow, sticky } from "tippy.js"

// internal
import { ItemCombobox, SpinLoader } from "~/components"
import { LinkIcon, UnlinkIcon } from "~/icons"
import { useDocumentStore, useMetadataStore, useSharedStore, useTemplateStore } from "~/stores"
import { CrudContext, DocumentTab, DynamicField, EditorContentTab, MetadataType, MetadataValue, MetadataValueSource, MultiFieldType, TemplateTab } from "~/types"
import { VariableIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"
import { convertCurrencyString, focusFirstFocusable, isoDurationToWords } from "~/utils"
import { useI18n } from "vue-i18n"

interface Props {
  disabled?: boolean
  metadataValue: MetadataValue
  entityUuid?: string
  disableRemove?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    disabled: false,
    entityUuid: null,
    disableRemove: false,
  },
)

const emit = defineEmits( [ "set-value-source", "close-modal" ] )

const documentStore = useDocumentStore()
const { documentEditorSession, documentEditorSessionObjectSignature, mdu, mau } = storeToRefs(documentStore)

const templateStore = useTemplateStore()

const metadataStore = useMetadataStore()
const { metadataValueUuidBeingRemovedFromEntity } = storeToRefs(metadataStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const metadataOptionsPopoverRef = ref()
const metadataOptionsPopoverTippy = ref<Instance<TippyProps>[]>(null)
const optionButtonRef = ref()
const isVisibleMetadataOptionsPopover = ref<boolean>(false)
const selectedDynamicField = ref<DynamicField>(null)
const showSelectItemCombobox = ref<boolean>(false)
const isLoadingLinkMetadataToDynamicField = ref<boolean>(false)

const hasActiveEditorSession = computed(() => {
  return !!(crudContext.value !== CrudContext.document || (documentEditorSession.value?.uuid && documentEditorSession.value?.object_signature === documentEditorSessionObjectSignature.value && documentEditorSession.value?.document_user_uuid === mdu.value?.uuid))
})

const canEditDynamicFields = computed(
  () => crudContext.value === CrudContext.template
    ? true
    : mau.value?.permissions.includes("dynamic_field_manage") && mau.value?.permissions.includes("document_editor_session_manage") && mdu.value?.permissions?.includes("dynamic_field_create"),
)

const linkMetadataToDynamicField = async (dynamicField: DynamicField): Promise<MetadataValue | void> => {
  if (!dynamicField.uuid) return
  isLoadingLinkMetadataToDynamicField.value = true
  const payload: Partial<MetadataValue> = {
    ...toRaw(props.metadataValue),
    reference_dynamic_field_uuid: dynamicField.uuid,
    value_source: MetadataValueSource.dynamic_field,
  }
  try {
    const res = await metadataStore.updateMetadataValue(crudContext.value, props.entityUuid, payload, props.metadataValue, Date.now(), [ "reference_dynamic_field_uuid", "value_source" ])
    if (res) { showSelectItemCombobox.value = false }
  } finally {
    isLoadingLinkMetadataToDynamicField.value = false
    selectedDynamicField.value = null
  }

}

const showMetadataOptionsPopover = () => {
  metadataOptionsPopoverTippy.value = tippy([ optionButtonRef.value ], {
    content () {
      return metadataOptionsPopoverRef.value
    },
    appendTo: () => { return document.getElementById("mainContentContainer") },
    animation: "scale",
    allowHTML: true,
    theme: "slate",
    arrow: roundArrow,
    interactive: true,
    trigger: "manual",
    showOnCreate: true,
    placement: "bottom",
    sticky: true,
    plugins: [
      sticky,
    ],
    onShow () {
      isVisibleMetadataOptionsPopover.value = true
    },
    onShown () {
      const element = metadataOptionsPopoverRef.value
      focusFirstFocusable(element)
    },
    onHidden () {
      selectedDynamicField.value = null
      isVisibleMetadataOptionsPopover.value = false
      metadataOptionsPopoverTippy.value = null
    },
  })
}

const unlinkMetadataFromDynamicField = async (): Promise<MetadataValue | void> => {
  isLoadingLinkMetadataToDynamicField.value = true
  const payload: Partial<MetadataValue> = {
    ...toRaw(props.metadataValue),
    reference_dynamic_field_uuid: null,
    value_source: MetadataValueSource.user,
  }
  try {
    await metadataStore.updateMetadataValue(crudContext.value, props.entityUuid, payload, props.metadataValue, Date.now(), [ "reference_dynamic_field_uuid", "value_source" ])
  } finally {
    isLoadingLinkMetadataToDynamicField.value = false
  }
}

const removeMetadataValue = async (): Promise<MetadataValue | void> => {
  metadataStore.removeMetadataValueFromEntity(crudContext.value, props.entityUuid, props.metadataValue.uuid)
}

const handleShowSelectItemCombobox = () => {
  showSelectItemCombobox.value = true
  setTimeout(() => {
    const searchInput = metadataOptionsPopoverRef.value.querySelector(".search-input")
    if (searchInput) searchInput.focus()
  })
}

const handleCancelSelectDynamicField = () => {
  selectedDynamicField.value = null
  showSelectItemCombobox.value = false
}

const setHash = (hash: string) => location.hash = hash

const setTabKeyToEditorContentBlocks = () => {
  if (crudContext.value === CrudContext.document) {
    documentStore.setActiveTabKey(DocumentTab.documentContent)
    documentStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields })
    setHash(DocumentTab.documentContent)
  } else if (crudContext.value === CrudContext.template) {
    templateStore.setActiveTabKey(TemplateTab.documentContent)
    templateStore.setActiveEditorContentTab({ id: EditorContentTab.dynamicFields })
    setHash(TemplateTab.documentContent)
  }
  emit("close-modal")
  metadataOptionsPopoverTippy.value?.[0]?.destroy()
}

// Constructor for changes on selected dynamic field
watch(selectedDynamicField, () => {
  if (!selectedDynamicField.value?.uuid) return
  linkMetadataToDynamicField(selectedDynamicField.value)
})

const { t, locale } = useI18n()

const computedValueRepresentation = computed(() => {
  if (props.metadataValue?.computed_value === null) return t("common.n/a")
  if (props.metadataValue?.metadata?.value_type === MultiFieldType.duration) return isoDurationToWords(props.metadataValue?.computed_value, t)
  if ([ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(props.metadataValue?.metadata?.value_type)) return convertCurrencyString(props.metadataValue?.computed_value, null, locale.value)
  return props.metadataValue?.computed_value
})

</script>
<template>
  <button
    ref="optionButtonRef"
    type="button"
    :disabled="disabled"
    class="btn-plain transition-all hover:bg-gray-200 rounded-full p-0.5 text-gray-400 hover:text-gray-900 focus:text-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:bg-gray-200"
    :class="metadataOptionsPopoverTippy ? '' : 'opacity-0 scale-0 group-hover:scale-100 group-hover:opacity-100'"
    @click.prevent.stop="showMetadataOptionsPopover"
  >
    <EllipsisHorizontalIcon
      class="w-5 h-5 shrink-0"
    />
  </button>
  <div
    class="hidden"
  >
    <div
      ref="metadataOptionsPopoverRef"
      class="popover popover-slate flex-col text-sm min-w-[16rem] max-w-sm"
    >
      <ul v-if="!showSelectItemCombobox">
        <template
          v-if="metadataValue.reference_dynamic_field_uuid"
        >
          <li class="first:rounded-t-md last:rounded-b-md hover:bg-slate-700 focus:bg-slate-700">
            <button
              type="button"
              class="w-full p-2"
              @click="unlinkMetadataFromDynamicField"
            >
              <div class="flex items-center gap-2">
                <SpinLoader
                  v-if="isLoadingLinkMetadataToDynamicField"
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <UnlinkIcon
                  v-else
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <span class="text-sm truncate">
                  {{ $t('metadata.unlink') }}
                </span>
              </div>
            </button>
          </li>
          <li
            v-if="[ MetadataType.system_autofilled, MetadataType.system_computed ].includes(metadataValue.metadata.type) && (metadataValue.value_source !== MetadataValueSource.computed && metadataValue.value_source !== MetadataValueSource.autofilled)"
          >
            <button
              type="button"
              class="w-full p-2"
              @click="$emit('set-value-source', { metadataValueUuid: metadataValue.uuid, valueSource: MetadataValueSource.computed })"
            >
              <div class="flex items-center gap-2">
                <BoltIcon
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <span class="text-sm truncate">
                  {{ $t('metadata.useGenerated') }}: <span class="font-normal">{{ computedValueRepresentation ? computedValueRepresentation : $t('common.n/a') }}
                  </span>
                </span>
              </div>
            </button>
          </li>
        </template>
        <template v-else>
          <li
            v-if="[ MetadataType.system_autofilled, MetadataType.system_computed ].includes(metadataValue.metadata.type) && (metadataValue.value_source === MetadataValueSource.computed || metadataValue.value_source === MetadataValueSource.autofilled)"
            class="first:rounded-t-md last:rounded-b-md hover:bg-slate-700 focus:bg-slate-700"
          >
            <button
              type="button"
              class="w-full p-2"
              @click="$emit('set-value-source', { metadataValueUuid: metadataValue.uuid, valueSource: MetadataValueSource.user })"
            >
              <div class="flex items-center gap-2">
                <PencilIcon
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <span class="text-sm truncate">
                  {{ $t('metadata.setManually') }}
                </span>
              </div>
            </button>
          </li>
          <li
            v-if="metadataValue.metadata?.value_type !== MultiFieldType.clause"
            class="first:rounded-t-md last:rounded-b-md hover:bg-slate-700 focus:bg-slate-700"
          >
            <button
              type="button"
              class="w-full p-2"
              @click.prevent="handleShowSelectItemCombobox"
            >
              <div class="flex items-center gap-2">
                <SpinLoader
                  v-if="isLoadingLinkMetadataToDynamicField"
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <LinkIcon
                  v-else
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <span class="text-sm truncate">
                  {{ $t('metadata.link') }}
                </span>
              </div>
            </button>
          </li>
          <li
            v-if="[ MetadataType.system_autofilled, MetadataType.system_computed ].includes(metadataValue.metadata.type) && (metadataValue.value_source !== MetadataValueSource.computed && metadataValue.value_source !== MetadataValueSource.autofilled)"
            class="first:rounded-t-md last:rounded-b-md hover:bg-slate-700 focus:bg-slate-700"
          >
            <button
              type="button"
              class="w-full p-2"
              @click="$emit('set-value-source', { metadataValueUuid: metadataValue.uuid, valueSource: MetadataValueSource.computed })"
            >
              <div class="flex items-center gap-2">
                <BoltIcon
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <span class="text-sm truncate">
                  {{ $t('metadata.useGenerated') }}: <span class="font-normal">{{ metadataValue.computed_value ? metadataValue.computed_value : $t('common.n/a') }}
                  </span>
                </span>
              </div>
            </button>
          </li>
          <li
            v-if="!disableRemove"
            class="first:rounded-t-md last:rounded-b-md hover:bg-slate-700 focus:bg-slate-700"
          >
            <button
              type="button"
              class="w-full p-2"
              @click="removeMetadataValue"
            >
              <div class="flex items-center gap-2">
                <TrashIcon
                  v-if="metadataValueUuidBeingRemovedFromEntity !== metadataValue.uuid"
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <SpinLoader
                  v-else
                  class="flex-none w-4 h-4 text-gray-500"
                />
                <span
                  v-if="metadataValueUuidBeingRemovedFromEntity !== metadataValue.uuid"
                  class="text-sm truncate"
                >
                  {{ metadataValue.metadata?.value_type === MultiFieldType.clause ? $t('metadata.removeClauseMarker') : $t('metadata.removeMetadata') }}
                </span>
                <span
                  v-else
                >
                  {{ $t('common.pleaseWait') }}…
                </span>
              </div>
            </button>
          </li>
          <slot name="info" />
        </template>
      </ul>
      <template v-else>
        <ItemCombobox
          v-model:selected-item="selectedDynamicField"
          :metadata="metadataValue.metadata"
          layout="slate"
          :static="true"
          @cancel="handleCancelSelectDynamicField"
        >
          <template #explanation>
            <div class="pt-2 text-sm text-gray-400">
              {{ $t("metadata.onlyShowingCompatibleDynamicFields") }}
            </div>
          </template>
          <template
            v-if="canEditDynamicFields"
            #addButton
          >
            <div class="pt-2 pb-0.5 text-sm text-gray-400">
              <div
                v-if="!hasActiveEditorSession"
                class="text-indigo-300"
              >
                {{ $t('metadata.noActiveEditorSession') }}
              </div>
              <button
                v-else
                class="flex items-center gap-1 p-0 text-sm text-indigo-400 btn-plain hover:text-indigo-300 focus:ring-offset-slate-800 focus:ring-slate-700 disabled:cursor-not-allowed"
                @click="setTabKeyToEditorContentBlocks"
              >
                <VariableIcon
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
                {{ $t('metadata.manageDynamicFields') }}
              </button>
            </div>
          </template>
        </ItemCombobox>
      </template>
    </div>
  </div>
</template>
