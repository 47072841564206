<script setup lang="ts">
import { computed } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"

import { ConditionOperator, MultiFieldType } from "~/types"

interface Props {
  modelValue?: ConditionOperator
  type: MultiFieldType | "parent"
  disabled?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: ConditionOperator.EQUAL,
    disabled: false,
  },
)

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:model-value", value),
})

const selectOptions = computed(() => {
  const options = []

  if (props.type === "parent") {
    options.push({
      label: "AND",
      value: ConditionOperator.AND,
    })
    options.push({
      label: "OR",
      value: ConditionOperator.OR,
    })

    return options
  }

  options.push({
    label: "=",
    value: ConditionOperator.EQUAL,
  })

  if (
    props.type !== MultiFieldType.bool
  ) {
    options.push({
      label: "≠",
      value: ConditionOperator.NOT_EQUAL,
    })
  }

  options.push({
    label: "is empty",
    value: ConditionOperator.EMPTY,
  })

  options.push({
    label: "is not empty",
    value: ConditionOperator.NOT_EMPTY,
  })

  // For text, email and textarea we include starts with and ends with
  if (
    props.type === MultiFieldType.text ||
    props.type === MultiFieldType.email ||
    props.type === MultiFieldType.textarea ||
    props.type === MultiFieldType.number
  ) {
    options.push({
      label: "starts with",
      value: ConditionOperator.STARTS_WITH,
    })
    options.push({
      label: "ends with",
      value: ConditionOperator.ENDS_WITH,
    })
    options.push({
      label: "contains",
      value: ConditionOperator.CONTAINS,
    })
    options.push({
      label: "does not contain",
      value: ConditionOperator.NOT_CONTAINS,
    })
  }

  if (
    props.type === MultiFieldType.date ||
    props.type === MultiFieldType.number ||
    props.type === MultiFieldType.currency ||
    props.type === MultiFieldType.currency_duration ||
    props.type === MultiFieldType.duration
  ) {
    options.push({
      label: ">",
      value: ConditionOperator.GREATER_THAN,
    })
    options.push({
      label: "<",
      value: ConditionOperator.LESS_THAN,
    })

    options.push({
      label: "≥",
      value: ConditionOperator.GREATER_THAN_OR_EQUAL,
    })

    options.push({
      label: "≤",
      value: ConditionOperator.LESS_THAN_OR_EQUAL,
    })
  }

  return options
})

const selectedLabel = computed(() => selectOptions.value?.find((option) => option.value === props.modelValue)?.label ?? null)

const emit = defineEmits([ "update:model-value" ])

</script>

<template>
  <Listbox
    v-model="value"
    as="div"
    :disabled="disabled"
  >
    <div class="relative">
      <ListboxButton
        class="shrink-0 btn-listbox-slate bg-slate-900"
        :class="[ConditionOperator.EMPTY, ConditionOperator.NOT_EMPTY, ConditionOperator.STARTS_WITH, ConditionOperator.ENDS_WITH, ConditionOperator.CONTAINS, ConditionOperator.NOT_CONTAINS].includes(value) ? 'w-48' : 'w-16'"
      >
        <span
          class="block truncate"
        >{{ selectedLabel ?? $t('conditions.selectOperator') + '…' }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 text-slate-500"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="left-0 right-auto listbox-options">
          <ListboxOption
            v-for="option in selectOptions"
            :key="option.value"
            v-slot="{ active, selected }"
            as="template"
            :value="option.value"
          >
            <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
              <span
                :class="[
                  selected ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]"
              >
                {{ option.label }}
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-teal-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
