<script setup lang="ts">
// external
import { DocumentPlusIcon, ArchiveBoxArrowDownIcon, ArrowRightStartOnRectangleIcon, TrashIcon, TagIcon, UserGroupIcon, HashtagIcon, ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline"
import { ref, computed, watch } from "vue"
import { router } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"

// internal
import {
  EmptyState,
  MagicTable,
  FilterBubbles,
  FilterHeader,
  KanbanView,
  FilterBase,
  DocumentFilters,
  MagicTableBulkButton,
  ManageTagsModal,
  ManageTeamsModal,
  ManageMetadataModal,
  DocumentExportModal,
} from "~/components"
import { useFilters, useStages, useEntityActions } from "~/composables"
import { MagicTableColumn, Metadata, Pagination, Team, Automation, Import, Tag, Document, Party, DocumentOrigin, JuneEvents, MagicTableAllState } from "~/types"
import { DocumentLimitExceedModal, DocumentTableEntry } from "."
import { documentTableConfigFull, documentTableConfigSimple } from "~/components/document/documentTableConfigs"
import { useAccountStore } from "~/stores"
import { storeToRefs } from "pinia"
import { trackEventInJune } from "~/utils"

interface Props {
  pagination?: Pagination<Document>
  paginationProp?: string[]
  showFilter?: boolean
  teamOptions?: Team[]
  accountPartyOptions?: Party[]
  teams?: Team[]
  automationOptions?: Automation[]
  importOptions?: Import[]
  tagOptions?: Tag[]
  metadata?: Metadata[]
  resource?: Record<any, any>
  meta?: Record<any, any>
  useFullConfig?: boolean
  useButtonPagination?: boolean
  hidePerPage?: boolean
  hideTags?: boolean
  tableClasses?: string
  outerClasses?: string
  loading?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    paginationProp: () => {
      return [ "pagination" ]
    },
    showFilter: false,
    useFullConfig: false,
    useButtonPagination: false,
    hidePerPage: false,
    hideTags: false,
    tableClasses: "",
    outerClasses: "shadow",
    loading: false,
  },
)

const {
  isSubmitting,
  showManageTeamsModal,
  showManageTagsModal,
  showManageMetadataModal,
  showLimitExceedModal,
  localEntityUuids,
  confirmEntityDeletion,
  confirmEntityArchiving,
  confirmEntityRestoration,
  bulkManageRelations,
  bulkManageMetadata,
} = useEntityActions("documents")

const { t } = useI18n()

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const {
  sortAttribute,
  sortDirection,
  isUpdatingList,
  hasActiveFilters,
  filterData,
  filterBubbles,
  filterValues,
  filterOptions,
  allState,
  filterInitialized,
  selectedUuids,
  isSidebarOpen,
  isArchive,
  pinnedFilters,
  setPerPage,
  resetSorting,
  resetFilter,
  clearBubble,
  selectEntry,
  togglePin,
  clearSelection,
} = useFilters (
  "updated_at",
  "desc",
  props.paginationProp,
  props.showFilter ? [ "query", "teams", "imports", "automations", "tags", "stages", "archived", "expired", "parties", "external_parties", "document_users", "origins", "tag_and" ] : [],
  props.metadata ?? [],
  "documentList",
)

const { stages } = useStages(null, null, true)

const viewMode = ref<"table" | "kanban">("table")

watch(viewMode, (newVal) => {
  if (newVal === "kanban") {
    trackEventInJune(JuneEvents.TABLE_KANBAN_USED)
  }
})

filterOptions.value["stages"] = stages.value.map((stage) => {
  return {
    label: t("documents.stages." + stage.name),
    value: stage.name,
  }
})
if (props.teamOptions?.length) {
  filterOptions.value["teams"] = props.teamOptions.map((team) => {
    return {
      label: team.name,
      value: team.uuid,
    }
  })
  filterOptions.value["teams"].push({ label: t("unassigned"), value: 0 })
}
if (props.accountPartyOptions?.length) {
  filterOptions.value["parties"] = props.accountPartyOptions.map((party) => {
    return {
      label: party.entity_name,
      value: party.uuid,
    }
  })
}
if (props.importOptions?.length) {
  filterOptions.value["imports"] = props.importOptions.map((el) => {
    return {
      label: el.name,
      value: el.uuid,
    }
  })
}
if (props.automationOptions?.length) {
  filterOptions.value["automations"] = props.automationOptions.map((el) => {
    return {
      label: el.name,
      value: el.uuid,
    }
  })
}
if (props.tagOptions?.length) {
  filterOptions.value["tags"] = props.tagOptions.map((tag) => {
    return {
      label: tag.name,
      value: tag.uuid,
    }
  })
}
filterOptions.value["origins"] = Object.values(DocumentOrigin).map((origin) => {
  return {
    label: t("documentOrigins." + origin),
    value: origin,
  }
})

const columns = computed<MagicTableColumn[]>(() => {
  if (!props.useFullConfig) {
    return documentTableConfigSimple
  }
  return documentTableConfigFull
})

const sortAttributes = ref([ "name", "stage", "created_at", "updated_at", "archived_at" ])

const handleEmptyStateClick = () => {
  if (hasActiveFilters.value) {
    resetFilter()
  } else {
    router.visit(route("documents.index") + "#create")
  }
}

const switchPage = (page) => {
  emit("switch-page", page)
}

const emit = defineEmits([ "switch-page" ])

const editMetadataData = ref(null)
const editMetadata = (data) => {
  editMetadataData.value = data
  showManageMetadataModal.value = true
}
watch(showManageMetadataModal, (newVal) => {
  if (!newVal) {
    editMetadataData.value = null
  }
})

const showExportModal = ref(false)
</script>

<template>
  <FilterBase
    :has-active-filters="hasActiveFilters"
    :show-filter="showFilter"
    :is-sidebar-open="isSidebarOpen"
    :filter-count="filterBubbles.length"
    :show-toggle-controls="useFullConfig"
    @reset="resetFilter"
    @close="isSidebarOpen = false"
  >
    <template #filter="{ allOpen }">
      <DocumentFilters
        v-if="filterInitialized"
        v-model:filter-values="filterValues"
        :teams="teamOptions"
        :stages="stages"
        :account-parties="accountPartyOptions"
        :imports="importOptions"
        :automations="automationOptions"
        :tags="tagOptions"
        :metadata="metadata"
        :filter-options="filterOptions"
        :all-open="allOpen"
        :filter-bubbles="filterBubbles"
        :pinned-filters="pinnedFilters"
        @toggle-pin="togglePin"
      />
    </template>
    <template #default>
      <FilterHeader
        v-if="showFilter"
        v-model:query="filterValues.query"
        v-model:view-mode="viewMode"
        v-model:show-all-filters="isSidebarOpen"
        v-model:sort-attribute="sortAttribute"
        v-model:sort-direction="sortDirection"
        class="mb-4"
        list-name="documentList"
        :has-active-filters="hasActiveFilters"
        :filter-bubbles="filterBubbles"
        :sort-attributes="sortAttributes"
        :metadata="metadata"
      />

      <FilterBubbles
        v-if="hasActiveFilters"
        class="mb-2"
        :filter-bubbles="filterBubbles"
        @clear="clearBubble"
        @reset="resetFilter"
      />

      <MagicTable
        v-if="viewMode === 'table'"
        v-model:sort-attribute="sortAttribute"
        v-model:sort-direction="sortDirection"
        v-model:sort-attributes="sortAttributes"
        v-model:all-state="allState"
        :storage-key="useFullConfig ? 'documentList' : 'dashboardWidgetDocumentList'"
        table-name="documentList"
        :columns="columns"
        :paginator="props.pagination"
        :loading="isUpdatingList || loading"
        :has-filters="hasActiveFilters"
        :filter-data="filterData"
        :selected-uuids="selectedUuids"
        :pagination-prop="paginationProp"
        :hide-per-page="hidePerPage"
        :table-classes="useFullConfig ? '' : props.pagination?.data?.length === props.pagination?.meta?.per_page ? 'grow' : ''"
        :outer-classes="outerClasses"
        :use-button-pagination="useButtonPagination"
        :metadata="metadata"
        :allow-overflow="useFullConfig"
        @update:per-page="setPerPage"
        @reset-sorting="resetSorting"
        @reset-filter="resetFilter"
        @switch-page="switchPage"
        @clear-selection="clearSelection"
      >
        <template #bulk>
          <MagicTableBulkButton
            :label="$t('teams.manageTeams')"
            @click="showManageTeamsModal = true"
          >
            <UserGroupIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            :label="$t('tags.manageTags')"
            @click="showManageTagsModal = true"
          >
            <HashtagIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            :label="$t('metadata.manageMetadata')"
            @click="showManageMetadataModal = true"
          >
            <TagIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>

          <MagicTableBulkButton
            v-if="mau?.permissions.includes('document_export')"
            :label="$t('common.export')"
            @click="showExportModal = true"
          >
            <ArrowTopRightOnSquareIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            v-if="!isArchive"
            :label="$t('common.archive')"
            @click="confirmEntityArchiving({
              entityUuids: selectedUuids,
              all: allState === MagicTableAllState.ALL,
              total: pagination?.meta?.total,
              filterData: filterData,
            })"
          >
            <ArchiveBoxArrowDownIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            v-if="isArchive"
            :label="$t('common.restore')"
            @click="confirmEntityRestoration({
              entityUuids: selectedUuids,
              all: allState === MagicTableAllState.ALL,
              total: pagination?.meta?.total,
              filterData: filterData,
            })"
          >
            <ArrowRightStartOnRectangleIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
          <MagicTableBulkButton
            v-if="mau?.permissions.includes('document_delete')"
            :label="$t('common.delete')"
            hover-classes="hover:bg-red-300/30 hover:text-red-600"
            @click="confirmEntityDeletion({
              entityUuids: selectedUuids,
              all: allState === MagicTableAllState.ALL,
              total: pagination?.meta?.total,
              filterData: filterData,
            })"
          >
            <TrashIcon
              aria-hidden="true"
            />
          </MagicTableBulkButton>
        </template>
        <template #default="{ entry, activeColumns, isLoading, total, index }">
          <DocumentTableEntry
            :columns="activeColumns"
            :document="entry"
            :total="total"
            :index="index"
            :loading="isLoading"
            :hide-tags="hideTags"
            :is-archive="isArchive"
            :selected="selectedUuids.includes(entry.uuid)"
            :sort-attribute="sortAttribute"
            :allow-overflow="useFullConfig"
            @select="selectEntry"
            @delete="confirmEntityDeletion({ entityUuids: [entry.uuid] })"
            @archive="confirmEntityArchiving({ entityUuids: [entry.uuid] })"
            @restore="confirmEntityRestoration({ entityUuids: [entry.uuid] })"
            @edit-metadata="editMetadata"
          />
        </template>
        <template #empty>
          <slot name="empty">
            <EmptyState
              @click="handleEmptyStateClick"
            >
              <template #icon>
                <DocumentPlusIcon
                  aria-hidden="true"
                />
              </template>
              <template #call-to-action>
                <span v-if="!hasActiveFilters">
                  {{ $t('documents.empty.description') }}
                </span>
                <span v-else>
                  {{ $t('documents.empty.descriptionFilter') }}
                </span>
              </template>
              <template #button-text>
                <span v-if="!hasActiveFilters">
                  {{ $t('documents.empty.button') }}
                </span>
                <span v-else>
                  {{ $t('documents.empty.buttonFilter') }}
                </span>
              </template>
              {{ $t('documents.empty.title') }}
            </EmptyState>
          </slot>
        </template>
      </MagicTable>

      <KanbanView
        v-else-if="viewMode === 'kanban'"
        :documents="pagination.data"
        class="mt-4"
      />

      <template
        v-if="useFullConfig"
      >
        <ManageTeamsModal
          v-model:show="showManageTeamsModal"
          :teams="teams"
          :loading="isSubmitting"
          :callback="(selectedTeams, mode) => bulkManageRelations({
            mode: mode,
            entityUuids: selectedUuids,
            teamUuids: selectedTeams.map((team) => team.uuid),
            all: allState === MagicTableAllState.ALL,
            filterData: filterData,
          })"
        />

        <ManageTagsModal
          v-model:show="showManageTagsModal"
          :tags="tagOptions"
          :loading="isSubmitting"
          :callback="(selectedTags, mode) => bulkManageRelations({
            mode: mode,
            entityUuids: selectedUuids,
            tagUuids: selectedTags.map((tag) => tag.uuid),
            all: allState === MagicTableAllState.ALL,
            filterData: filterData,
          })"
        />

        <DocumentExportModal
          v-model:show="showExportModal"
          :all="allState === MagicTableAllState.ALL"
          :uuids="selectedUuids"
          :filter-data="filterData"
          :total="pagination?.meta?.total"
        />

        <ManageMetadataModal
          v-model:show="showManageMetadataModal"
          :metadata="metadata"
          :edit-metadata-data="editMetadataData"
          :loading="isSubmitting"
          :callback="(data) => bulkManageMetadata({
            entityUuids: editMetadataData ? [editMetadataData.uuid] : selectedUuids,
            all: allState === MagicTableAllState.ALL,
            filterData: filterData,
            data: data,
          })"
        />

        <DocumentLimitExceedModal
          v-model:show="showLimitExceedModal"
          :count="localEntityUuids.length"
        />
      </template>
    </template>
  </FilterBase>
</template>
