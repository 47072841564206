<script setup lang="ts">
import { computed } from "vue"
import { ChevronUpDownIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/20/solid"

interface Props {
  attribute: string
  activeAttribute: string | null
  direction: string | null
  initialDirection?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    activeAttribute: null,
    initialDirection: "desc",
    direction: null,
  },
)

const emit = defineEmits([ "change:direction", "change:attribute", "reset" ])

const isActive = computed(() => {
  return props.attribute === props.activeAttribute
})

const clickHandler = () => {
  if (isActive.value) {
    if (props.direction === props.initialDirection) {
      emit("change:direction", props.initialDirection === "desc" ? "asc" : "desc")
    } else {
      emit("reset")
    }
  } else {
    emit("change:attribute", props.attribute)
  }
}
</script>

<template>
  <span
    class="flex items-center space-x-1 cursor-pointer"
    @click="clickHandler"
  >
    <span class="text-xs text-gray-900 whitespace-nowrap">
      <slot />
    </span>
    <template v-if="isActive">
      <ChevronDownIcon
        v-if="direction === 'desc'"
        class="w-4 h-4 text-indigo-600"
      />
      <ChevronUpIcon
        v-else
        class="w-4 h-4 text-indigo-600"
      />
    </template>
    <ChevronUpDownIcon
      v-else
      class="w-4 h-4 text-gray-500"
    />
  </span>
</template>
