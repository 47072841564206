<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref, toRaw } from "vue"
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import { MetadataValueInput, OverlayScrollbar, SkeletonLoader, SpinLoader } from "~/components"
import { useDocumentStore, useMetadataStore, useNotificationStore, usePusherStore, useSharedStore } from "~/stores"
import { Document, Metadata, MetadataValue, MetadataValueSource, Template, DurationTypeRelatedMetadata, DocumentNotification, Team } from "~/types"
import { onClickOutside } from "@vueuse/core"

interface Props {
  document?: Document
  template?: Template
  notificationSettings: DocumentNotification[]
  teams: Team[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
  },
)

const emit = defineEmits( [
  "focus",
  "blur",
  "close-modal",
])

const { t } = useI18n()

const { notify } = useNotificationStore()

const documentStore = useDocumentStore()
const { mainContentPdfViewer, mainContentScrollContainer } = storeToRefs(documentStore)

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)
const { setSidebarScrollContainer } = sharedStore

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return props?.[crudContext.value]?.uuid
})

const metadataStore = useMetadataStore()
const { isLoadingMetadata, isLoadingMetadataValues, isLoadingCalendarData, metadata, metadataValues, prosemirrorUuidBoundingBoxes } = storeToRefs(metadataStore)
const { handleShowBoundingBox, handleHideBoundingBoxes } = metadataStore

const isSubmittingAddMetadata = ref<boolean | string>(false)

// Helper to emit pusher relevant events
const handleFocus = (data: Record<string, string>, metadataValue: MetadataValue) => {
  emit("focus", data)
  handleSelectBoundingBox(metadataValue)
}
const handleBlur = (data: string) => {
  handleDeSelectBoundingBox()
  emit("blur", data)
}

const effectiveDate = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.effective_date
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.effective_date
  })
})

const durationStartDate = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.duration_start_date
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.duration_start_date
  })
})

const durationEndDate = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.duration_end_date
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.duration_end_date
  })
})

const renewalDuration = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.renewal_duration
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.renewal_duration
  })
})

const durationTerm = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.duration_term
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.duration_term
  })
})

const noticePeriod = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.cancellation_notice_period
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.cancellation_notice_period
  })
})

const expirationDate = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.expiration_date
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.expiration_date
  })
})

const terminatedDate = computed<MetadataValue | Metadata | null>(() => {
  return metadataValues.value?.find((metadataValue) => {
    return metadataValue.metadata?.name === DurationTypeRelatedMetadata.terminated_date
  }) || metadata.value?.find((metadata) => {
    return metadata.name === DurationTypeRelatedMetadata.terminated_date
  })
})

const addMetadataValue = async (
  metadata: Metadata,
  valueSource: MetadataValueSource = null,
  skipLoaderUuid = false,
  value: string | null = null,
): Promise<MetadataValue | void> => {

  // Return if there is already a metadata value for this metadata
  if (metadataValues.value?.some((metadataValue) => {
    return metadataValue?.uuid === metadata?.uuid
  })) return

  if (!skipLoaderUuid) isSubmittingAddMetadata.value = metadata?.uuid

  let payload: Partial<MetadataValue> = {
    metadata_uuid: metadata?.uuid,
  }

  if (valueSource) {
    payload = {
      ...payload,
      value_source: valueSource,
    }
  }

  if (value) {
    payload = {
      ...payload,
      value: value,
    }
  }

  try {

    const createdMetadataValue = await metadataStore.createMetadataValue(crudContext.value, entityUuid.value, payload)
    return createdMetadataValue

  } catch (err) {
    notify({
      title: t("metadata.addMetadataValueErrorTitle"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isSubmittingAddMetadata.value = false
  }
}

const datesScrollContainer = ref()

onMounted(() => {
  setSidebarScrollContainer(datesScrollContainer.value)
})

interface setValueSourceArgs {
  metadataValueUuid: MetadataValue["uuid"]
  valueSource: MetadataValueSource
}

const handleSetValueSource = async (args: setValueSourceArgs): Promise<MetadataValue | void> => {
  if (!args.metadataValueUuid || !args.valueSource) return

  const rawMetadataValue = toRaw(metadataValues.value.find((el) => el.uuid === args.metadataValueUuid))
  if (!rawMetadataValue) return


  const payload: Partial<MetadataValue> = {
    ...rawMetadataValue,
    value_source: args.valueSource,
  }

  metadataStore.updateLocalMetadataValue(payload, crudContext.value, entityUuid.value)

}

const hasPermissionToAddMetadata = computed(() => {
  // return hasPermissionToAddMetadata
  return true
})

const uuidOfIsActiveBoundingBox = ref<Metadata["uuid"]>(null)

const handleSelectBoundingBox = (metadataValue: MetadataValue) => {
  const uuid = metadataValue.prosemirror_uuids?.length ? metadataValue.prosemirror_uuids[0] : metadataValue.metadata?.uuid
  uuidOfIsActiveBoundingBox.value = uuid
  handleShowBoundingBox(metadataValue, props.document, mainContentPdfViewer.value, mainContentScrollContainer.value, prosemirrorUuidBoundingBoxes.value, setUpDeSelectBoundingBoxOnClickOutSide)
  onClickOutside(datesScrollContainer.value, () => handleDeSelectBoundingBox())
}

const setUpDeSelectBoundingBoxOnClickOutSide = () => {
  onClickOutside(datesScrollContainer.value, () => handleDeSelectBoundingBox())
}

const handleDeSelectBoundingBox = () => {
  uuidOfIsActiveBoundingBox.value = null
  handleHideBoundingBoxes(props.document, mainContentPdfViewer.value)
}

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 border shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('dates.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('dates.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingMetadata || isLoadingCalendarData"
      class="p-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>
    <template v-else>
      <OverlayScrollbar
        ref="datesScrollContainer"
        tag="div"
        class="flex-1 pb-6 overflow-y-auto max-h-max"
      >
        <dl>
          <div>
            <label class="relative flex items-center justify-center w-full h-px px-4 mt-6 mb-4 text-sm font-medium text-white bg-indigo-500">
              <span class="px-2 py-0.5 text-indigo-500 bg-white">
                {{ $t('metadata.contractTimeline') }}
              </span>
            </label>
            <div class="divide-y divide-gray-100">
              <div
                class="relative metadata-entry"
                :class="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox !== (effectiveDate as MetadataValue)?.metadata?.uuid && (effectiveDate as MetadataValue)?.bounding_box ? 'hover:bg-gray-50 cursor-pointer' : ''"
              >
                <div
                  v-if="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (effectiveDate as MetadataValue)?.metadata?.uuid && (effectiveDate as MetadataValue).bounding_box"
                  class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4 bg-indigo-500/10 border-2 border-indigo-700"
                />
                <MetadataValueInput
                  v-if="(effectiveDate as MetadataValue)?.metadata"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + effectiveDate?.uuid)"
                  :entity-uuid="entityUuid"
                  :label="$t('metadata.effectiveDate')"
                  :metadata-value="(effectiveDate as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (effectiveDate as MetadataValue).metadata?.uuid && (effectiveDate as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (effectiveDate as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(effectiveDate as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === effectiveDate?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(effectiveDate as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === effectiveDate?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addEffectiveDate') }}
                </button>
              </div>
              <div
                class="relative metadata-entry"
                :class="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox !== (durationStartDate as MetadataValue)?.metadata?.uuid && (durationStartDate as MetadataValue)?.bounding_box ? 'hover:bg-gray-50 cursor-pointer' : ''"
              >
                <div
                  v-if="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (durationStartDate as MetadataValue)?.metadata?.uuid && (durationStartDate as MetadataValue).bounding_box"
                  class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4 bg-indigo-500/10 border-2 border-indigo-700"
                />
                <MetadataValueInput
                  v-if="(durationStartDate as MetadataValue)?.metadata"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + durationStartDate?.uuid)"
                  :entity-uuid="entityUuid"
                  :label="$t('metadata.startDate')"
                  :metadata-value="(durationStartDate as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (durationStartDate as MetadataValue).metadata?.uuid && (durationStartDate as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (durationStartDate as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(durationStartDate as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === durationStartDate?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(durationStartDate as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === durationStartDate?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addStartDate') }}
                </button>
              </div>
              <div
                class="relative metadata-entry"
                :class="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox !== (durationTerm as MetadataValue)?.metadata?.uuid && (durationTerm as MetadataValue)?.bounding_box ? 'hover:bg-gray-50 cursor-pointer' : ''"
              >
                <div
                  v-if="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (durationTerm as MetadataValue)?.metadata?.uuid && (durationTerm as MetadataValue).bounding_box"
                  class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4 bg-indigo-500/10 border-2 border-indigo-700"
                />
                <MetadataValueInput
                  v-if="(durationTerm as MetadataValue)?.metadata?.uuid"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + durationTerm.uuid)"
                  :entity-uuid="entityUuid"
                  :metadata-value="(durationTerm as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (durationTerm as MetadataValue).metadata?.uuid && (durationTerm as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (durationTerm as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(durationTerm as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === durationTerm?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(durationTerm as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === durationTerm?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addDurationTerm') }}
                </button>
              </div>
              <div
                class="relative metadata-entry"
                :class="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox !== (durationEndDate as MetadataValue)?.metadata?.uuid && (durationEndDate as MetadataValue)?.bounding_box ? 'hover:bg-gray-50 cursor-pointer' : ''"
              >
                <div
                  v-if="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (durationEndDate as MetadataValue)?.metadata?.uuid && (durationEndDate as MetadataValue).bounding_box"
                  class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4 bg-indigo-500/10 border-2 border-indigo-700"
                />
                <MetadataValueInput
                  v-if="(durationEndDate as MetadataValue)?.metadata?.uuid"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + durationEndDate?.uuid)"
                  :entity-uuid="entityUuid"
                  :label="!(renewalDuration as MetadataValue)?.metadata?.uuid ? $t('metadata.endDate') : $t('metadata.endRenewalDate')"
                  :metadata-value="(durationEndDate as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (durationEndDate as MetadataValue).metadata?.uuid && (durationEndDate as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (durationEndDate as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(durationEndDate as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === durationEndDate?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(durationEndDate as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === durationEndDate?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addEndDate') }}
                </button>
              </div>
              <div
                class="relative metadata-entry"
                :class="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox !== (renewalDuration as MetadataValue)?.metadata?.uuid && (renewalDuration as MetadataValue)?.bounding_box ? 'hover:bg-gray-50 cursor-pointer' : ''"
              >
                <div
                  v-if="uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (renewalDuration as MetadataValue)?.metadata?.uuid && (renewalDuration as MetadataValue).bounding_box"
                  class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4 bg-indigo-500/10 border-2 border-indigo-700"
                />
                <MetadataValueInput
                  v-if="(renewalDuration as MetadataValue)?.metadata"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + renewalDuration?.uuid)"
                  :entity-uuid="entityUuid"
                  :metadata-value="(renewalDuration as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (renewalDuration as MetadataValue).metadata?.uuid && (renewalDuration as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (renewalDuration as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(renewalDuration as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === renewalDuration?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(renewalDuration as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === renewalDuration?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addRenewalDuration') }}
                </button>
              </div>
            </div>
            <label class="relative flex items-center justify-center w-full h-px px-4 mt-6 mb-4 text-sm font-medium text-white bg-indigo-500">
              <span class="px-2 py-0.5 text-indigo-500 bg-white">
                {{ $t('metadata.contractTermination') }}
              </span>
            </label>
            <div class="divide-y divide-gray-100">
              <div class="relative metadata-entry">
                <!-- (noticePeriod as MetadataValue)?.value_source === MetadataValueSource.user -->
                <MetadataValueInput
                  v-if="(noticePeriod as MetadataValue)?.metadata?.uuid"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + noticePeriod?.uuid)"
                  :entity-uuid="entityUuid"
                  :metadata-value="(noticePeriod as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (noticePeriod as MetadataValue).metadata?.uuid && (noticePeriod as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (noticePeriod as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(noticePeriod as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === noticePeriod?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(noticePeriod as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === noticePeriod?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addNoticePeriod') }}
                </button>
              </div>
              <div class="relative metadata-entry">
                <MetadataValueInput
                  v-if="(terminatedDate as MetadataValue)?.metadata"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + terminatedDate?.uuid)"
                  :entity-uuid="entityUuid"
                  :metadata-value="(terminatedDate as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (terminatedDate as MetadataValue).metadata?.uuid && (terminatedDate as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (terminatedDate as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(terminatedDate as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === terminatedDate?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(terminatedDate as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === terminatedDate?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addTerminatedDate') }}
                </button>
              </div>
            </div>
            <label class="relative flex items-center justify-center w-full h-px px-4 mt-6 mb-4 text-sm font-medium text-white bg-indigo-500">
              <span class="px-2 py-0.5 text-indigo-500 bg-white">
                {{ $t('metadata.contractExpiration') }}
              </span>
            </label>
            <div class="divide-y divide-gray-100">
              <div class="relative metadata-entry">
                <MetadataValueInput
                  v-if="(expirationDate as MetadataValue)?.metadata"
                  :disabled="isLoadingMetadataValues || checkIfDisabledByPusher('metadata-input_' + expirationDate?.uuid)"
                  :entity-uuid="entityUuid"
                  :metadata-value="(expirationDate as MetadataValue)"
                  :is-highlighted="!!(uuidOfIsActiveBoundingBox && uuidOfIsActiveBoundingBox === (expirationDate as MetadataValue).metadata?.uuid && (expirationDate as MetadataValue).bounding_box)"
                  @set-value-source="handleSetValueSource"
                  @focus="handleFocus($event, (expirationDate as MetadataValue))"
                  @blur="handleBlur"
                  @close-modal="$emit('close-modal')"
                  @click="handleSelectBoundingBox(expirationDate as MetadataValue)"
                />
                <button
                  v-else-if="hasPermissionToAddMetadata"
                  type="button"
                  :disabled="isSubmittingAddMetadata === expirationDate?.uuid"
                  class="inline-flex items-center gap-1.5 my-3 ml-4 font-medium text-indigo-600 btn-plain btn-sm focus:ring-0 focus:ring-offset-0 focus:bg-indigo-50 hover:text-indigo-800"
                  @click.prevent="addMetadataValue(expirationDate as Metadata)"
                >
                  <SpinLoader
                    v-if="isSubmittingAddMetadata === expirationDate?.uuid"
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <PlusIcon
                    v-else
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  {{ $t('metadata.addExpirationDate') }}
                </button>
              </div>
            </div>
          </div>
        </dl>
      </OverlayScrollbar>
    </template>
  </div>
</template>
