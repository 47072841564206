import { toRefs } from "vue"

import { mergeAttributes, Node } from "@tiptap/core"
import { PluginKey } from "@tiptap/pm/state"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import { useSectionRefStore } from "~/stores"
import { formatNumber, trackEventInJune } from "~/utils"

import SectionRefNode from "./SectionRefNodeView.vue"
import { JuneEvents } from "~/types"

export const SectionRefPluginKey = new PluginKey("sectionRef")

// Do we need the whole section - or create seperate 'services' for things like this
/* const parser = new DOMParser(); */

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    sectionRef: {
      addSectionRef: (uuid: string) => ReturnType;
    };
  }
}

export interface SectionRefAttrs {
  uuid: string;
}

export const SectionRef = Node.create(
  {
    name: "sectionref",

    group: "inline",

    inline: true,

    selectable: false,

    atom: true,

    draggable: true,

    addNodeView () {
      return VueNodeViewRenderer(SectionRefNode, {})
    },

    addOptions () {
      return {
        HTMLAttributes: {},

        renderLabel ({ node }) {

          const { uuid } = node.attrs
          const { sections } = toRefs(useSectionRefStore())

          const search = sections.value?.find((item) => item.uuid === uuid)

          if (search) return formatNumber(search)

          return "n/a"
        },
      }
    },

    addAttributes () {
      return {
        uuid: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-uuid"),
          renderHTML: (attributes) => {
            if (!attributes.uuid) {
              return {}
            }
            return {
              "data-uuid": attributes.uuid,
            }
          },
        },
      }
    },

    parseHTML () {
      return [
        {
          tag: "a[data-section-ref]",
          getAttrs (node) {
            const element = node as HTMLElement

            return {
              uuid: element.getAttribute("href")?.replace("#", ""),
            }
          },
        },
      ]
    },

    renderHTML ({ node }) {
      return [
        "a",
        mergeAttributes({ "href": "#" + node.attrs.uuid, "data-section-ref": true }),
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ]
    },

    renderText ({ node }) {
      return this.options.renderLabel({
        options: this.options,
        node,
      })
    },

    addCommands () {
      return {
        addSectionRef: (uuid: string) => ({ commands }) => {
          trackEventInJune(JuneEvents.EDITOR_SECTION_REF_INSERTED)
          return commands
            .insertContent(
              [
                {
                  type: this.name,
                  attrs: {
                    uuid,
                  },
                },
              ],
            )
        },
      }
    },
  },
)
