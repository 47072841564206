<script setup lang="ts">
// external
import { computed } from "vue"
import { storeToRefs } from "pinia"

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline"

// internal
import { signatureSecurityLevels } from "~/utils"
import { SpinLoader } from "~/components"
import { useAccountStore, useDocumentStore, useSharedStore } from "~/stores"
import { CrudContext, SignatureSecurityLevel } from "~/types"

interface Props {
  modelValue?: SignatureSecurityLevel
  isHighlighted: boolean
  isLoading: boolean
  isDisabled?: boolean
  clearable?: boolean
  inline?: boolean
  inlineButtonClasses?: string
  chevronClasses?: string
  placeholderClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  isDisabled: false,
  clearable: false,
  inline: true,
  inlineButtonClasses: "group btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-0 focus:ring-offset-0 text-gray-900 flex items-center gap-2",
  chevronClasses: "absolute inset-y-0 flex items-center pr-2 transition-all duration-200 scale-0 opacity-0 pointer-events-none -right-7 group-hover:scale-100 group-hover:opacity-100",
  placeholderClasses: "block font-normal text-gray-400 truncate",
})

const emit = defineEmits([ "focus", "blur", "update:model-value" ])

const documentStore = useDocumentStore()
const { mdu } = storeToRefs(documentStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const accountStore = useAccountStore()
const { planFeatures } = storeToRefs(accountStore)

const selectedValue = computed({
  get: () => {
    return props.modelValue || SignatureSecurityLevel.SES
  },
  set: (val) => {
    emit("update:model-value", val)
  },
})


const hasQes = computed(() => planFeatures.value?.["qes-signatures"])

// Limit display of QES signature security level to certain account IDs
const filteredSignatureSecurityLevels = computed(() => {
  if (hasQes.value) return signatureSecurityLevels
  return signatureSecurityLevels.filter((el) => el.key !== SignatureSecurityLevel.QES)
})

</script>
<template>
  <Listbox
    :id="'documentSignatureSecurityLevelSelector'"
    v-model="selectedValue"
    as="div"
    :disabled="isDisabled"
    class="px-6 py-2 text-sm transition-all duration-500"
    :class="[isDisabled ? 'mr-2' : '' , isHighlighted ? 'bg-teal-50' : '', inline ? 'flex justify-between items-center gap-2' : '']"
  >
    <ListboxLabel
      :class="inline ? 'shrink-0 text-gray-500' : 'block font-medium'"
      class="flex items-center gap-2 py-2 pr-3 shrink-0"
    >
      <slot name="label">
        {{ $t('signatures.signatureSecurity') }}
      </slot>
      <SpinLoader
        v-if="isLoading"
        class="w-3 h-3"
      />
    </ListboxLabel>
    <div
      :class="inline ? 'grow max-w-fit' : 'w-full'"
      class="relative"
    >
      <div
        v-if="props.isDisabled || (!mdu?.permissions.includes('document_update') && crudContext !== CrudContext.template)"
        class="flex items-center"
      >
        <div class="flex items-center">
          <span
            v-if="signatureSecurityLevels.find((el) => el.key === selectedValue)"
            class="flex items-center"
          >
            <component
              :is="signatureSecurityLevels.find((el) => el.key === selectedValue).icon"
              class="w-4 h-4 mr-2 flex-shrink-none"
            />
            <span class="block font-medium truncate">
              {{ signatureSecurityLevels.find((el) => el.key === selectedValue).name }}
            </span>
          </span>
          <span
            v-else
            class="flex items-center font-normal text-gray-400 normal-case truncate"
          >
            <ExclamationTriangleIcon
              class="w-4 h-4 mr-2"
              aria-hidden="true"
            />
            {{ $t('common.notSet') }}
          </span>
        </div>
      </div>
      <ListboxButton
        v-else
        v-cy="`signature-security-level-selector`"
        :class="[!inline && (!selectedValue || clearable && selectedValue) ? 'pr-9' : '', inline ? inlineButtonClasses : 'group btn-listbox-plain']"
      >
        <div class="flex items-center">
          <span
            v-if="signatureSecurityLevels.find((el) => el.key === selectedValue)"
            class="flex items-center text-gray-900"
          >
            <component
              :is="signatureSecurityLevels.find((el) => el.key === selectedValue).icon"
              class="w-4 h-4 mr-2 flex-shrink-none"
            />
            <span class="block truncate">
              {{ signatureSecurityLevels.find((el) => el.key === selectedValue).name }}
            </span>
          </span>
          <span
            v-else
            :class="placeholderClasses"
          >
            {{ $t('signatures.selectLevel') }}…
          </span>
        </div>
        <span
          v-if="clearable && selectedValue"
          class="absolute inset-y-0 -right-7 flex items-center pr-2.5 cursor-pointer transition-all duration-200 scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100"
          @click.prevent="selectedValue = null"
        >
          <XMarkIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
        <span
          v-else
          :class="chevronClasses"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="listbox-options"
          @focus="
            $emit('focus', {
              target: { id: 'documentSignatureSecurityLevelSelector' },
            })
          "
          @blur="
            $emit('blur', {
              target: { id: 'documentSignatureSecurityLevelSelector' },
            })
          "
        >
          <ListboxOption
            v-for="securityLevel in filteredSignatureSecurityLevels"
            :key="securityLevel.key"
            v-slot="{ active, selected }"
            as="template"
            :value="securityLevel.key"
          >
            <li
              v-cy="`signature-security-level-${securityLevel.key}`"
              :class="[active ? 'bg-gray-700' : '', 'listbox-option']"
            >
              <div
                class="flex items-center"
                :class="selected ? 'pr-3' : ''"
              >
                <component
                  :is="securityLevel.icon"
                  :class="[
                    'mr-2 h-4 w-4 shrink-0',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ securityLevel.name }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
