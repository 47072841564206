<script setup lang="ts">
// external
import { computed } from "vue"
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { PencilIcon, LockClosedIcon } from "@heroicons/vue/24/outline"

// internal
import { SpinLoader } from "~/components"
import { DocumentEditorSession, DocumentUser } from "~/types"

interface Props {
  pendingDocumentEditorSession?: boolean
  documentEditorSession?: DocumentEditorSession
  mdu?: DocumentUser
  isActiveEditMode?: boolean
  disabled?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    pendingDocumentEditorSession: false,
    documentEditorSession: null,
    mdu: null,
    isActiveEditMode: false,
    disabled: false,
  },
)

const emit = defineEmits(
  [
    "update:isActiveEditMode",
  ],
)

const isActiveEditMode = computed(
  {
    get: () => props.isActiveEditMode,
    set: (value) => emit("update:isActiveEditMode", value),
  },
)

const documentEditorSessionUuid = computed<DocumentEditorSession["uuid"]>(
  () => {
    if (!props.mdu?.uuid || props.documentEditorSession?.document_user_uuid !== props.mdu?.uuid) return null
    return props.documentEditorSession?.uuid
  },
)

</script>

<template>
  <SwitchGroup
    as="div"
    class="flex items-center"
  >
    <SwitchLabel
      as="span"
      class="inline-flex items-center gap-2 mr-3 text-sm"
      :class="[
        documentEditorSessionUuid
          ? 'text-gray-600'
          : 'text-gray-400'
      ]"
    >
      <span
        class="font-medium"
      >
        {{
          documentEditorSessionUuid
            ? $t('documents.editMode.on')
            : $t('documents.editMode.off')
        }}
      </span>
      <span
        v-if="!documentEditorSessionUuid"
        data-tippy-help
        :data-tippy-content="$t('documents.editMode.hint')"
        data-placement="bottom"
      >
        <QuestionMarkCircleIcon
          class="w-4 h-4 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </SwitchLabel>

    <Switch
      v-if="!disabled"
      v-model="isActiveEditMode"
      :disabled="pendingDocumentEditorSession"
      :class="[
        documentEditorSessionUuid ? 'bg-yellow-300' : 'bg-gray-300',
        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300',
      ]"
      data-cy-sel="document-edit-switch"
    >
      <span
        :class="[
          documentEditorSessionUuid ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]"
      >
        <span
          :class="[
            documentEditorSessionUuid
              ? 'opacity-0 ease-out duration-100'
              : 'opacity-100 ease-in duration-200',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <LockClosedIcon
            v-if="!pendingDocumentEditorSession"
            class="w-3 h-3 text-gray-400"
          />
          <SpinLoader
            v-else
            class="w-3 h-3 text-gray-400"
          />
        </span>
        <span
          :class="[
            documentEditorSessionUuid
              ? 'opacity-100 ease-in duration-200'
              : 'opacity-0 ease-out duration-100',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <PencilIcon
            v-if="!pendingDocumentEditorSession"
            class="w-3 h-3 text-gray-900"
          />
          <SpinLoader
            v-else
            class="w-3 h-3 text-gray-400"
          />
        </span>
      </span>
    </Switch>
  </SwitchGroup>
</template>
