import { Ref, ref, toRefs } from "vue"

import { Editor, Extension, isNodeSelection, posToDOMRect } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { VueRenderer } from "@tiptap/vue-3"
import { throttle } from "lodash-es"
import tippy, { Instance, Props as TippyProps, sticky } from "tippy.js"

import { useEditorStore } from "~/stores"

import CommandMenuComponent from "./CommandMenu.vue"

const isCommandMenuOpen = (editor: Editor) => editor.storage.commandMenu.commandMenu.popup[0]?.state.isShown

const processSelectionForCommandMenu = (editor: Editor) => {
  if (isCommandMenuOpen(editor) && !editor.storage.commandMenu.commandMenu.wasOpenedAutomatically) return

  editor.storage.commandMenu.commandMenu?.popup?.[0]?.popperInstance?.update()

  const { view, state: { doc, selection } } = editor

  const { from, to, empty } = selection

  const isEmptyTextBlock = !doc.textBetween(from, to).trim().length

  const isCertainNodesActive = editor.isActive("horizontalRule")
    || editor.isActive("resizableImage")

  const hasEditorFocus = view.hasFocus()

  if (!hasEditorFocus || empty || isEmptyTextBlock || !editor.isEditable || isCertainNodesActive) {
    const { commandMenu } = editor.storage
    commandMenu.popup?.[0]?.hide()
    const ref = commandMenu.renderer?.ref as InstanceType<typeof CommandMenuComponent>
    ref?.resetQuery()
    ref?.resetSelectedParty()
    return
  }

  editor.storage.commandMenu.wasOpenedAutomatically = true
  editor.storage.commandMenu.showItems = false
  const { commandMenu } = editor.storage
  commandMenu.popup?.[0]?.show()

}

const debouncedProcessSelectionForCommandMenu = throttle(processSelectionForCommandMenu, 300)

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    commandMenu: {
      showCommandMenu: (wasOpenedAutomatically: boolean) => ReturnType
      hideCommandMenu: () => ReturnType
      updateCommandMenu: () => ReturnType
    }
  }
}

interface CommandMenuOptions {
  HTMLAttributes: Record<string, any>
}

interface CommandMenuComponent {
  renderer: VueRenderer
  popup: Instance<TippyProps>[]
  wasOpenedAutomatically: boolean
  component: typeof CommandMenuComponent
  showItems: Ref<boolean>
}

export interface CommandMenuStorage {
  commandMenu: CommandMenuComponent
}

export const commandMenuPluginKey = new PluginKey("commandMenu")

export const CommandMenu = Extension.create<CommandMenuOptions, CommandMenuStorage>(
  {
    name: "commandMenu",

    addStorage () {
      return {
        commandMenu: {
          component: CommandMenuComponent,
          renderer: null,
          popup: [],
          wasOpenedAutomatically: false,
          showItems: ref(false),
        },
      }
    },

    onCreate () {
      const { editor } = this

      const editorStore = useEditorStore()
      const { commandMenuActiveSection: currentMenuItem } = toRefs(editorStore)

      const getSelectionRect = ({ state, view }: Editor) => {
        const { from, to, $to } = state.selection

        if (isNodeSelection(state.selection)) {
          const node = view.nodeDOM(from) as HTMLElement

          if (node) return node.getBoundingClientRect()
        }

        const parentNodeFrom = $to.start($to.depth)

        if (parentNodeFrom && currentMenuItem.value === "textCompletion") {
          const node = view.domAtPos(parentNodeFrom)

          if (node) return (node.node as HTMLElement).getBoundingClientRect()
        }

        return posToDOMRect(view, from, to)
      }

      for (const [ name, { component } ] of Object.entries(this.storage)) {
        const renderer = new VueRenderer(
          component,
          {
            props: {
              editor,
            },
            editor,
          },
        )

        const popup = tippy(
          "body",
          {
            getReferenceClientRect: () => getSelectionRect(editor),
            appendTo: () => document.getElementById("proposalContainer") || document.getElementById("editorContainer"),
            content: renderer.element,
            showOnCreate: false,
            interactive: true,
            trigger: "manual",
            placement: "bottom",
            // animation: "shift-toward-subtle",
            animation: "fade",
            theme: "slate",
            maxWidth: "none",
            hideOnClick: false,
            arrow: false,
            sticky: true,
            popperOptions: {
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: [],
                  },
                },
              ],
            },
            plugins: [
              sticky,
            ],
            zIndex: 40,
            duration: [ 0, 0 ],
            onHidden: () => {
              editorStore.setCommandMenuActiveSection("")
            },
            // moveTransition: "transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)",
          },
        )

        this.storage[name] = {
          ...this.storage[name],
          renderer,
          popup,
        }
      }
    },

    onSelectionUpdate () {
      const { state: { selection: { empty } } } = this.editor

      if (empty) processSelectionForCommandMenu(this.editor)

      debouncedProcessSelectionForCommandMenu(this.editor)
    },

    onDestroy () {
      this.storage.commandMenu.popup.filter(Boolean).forEach((popup) => popup.destroy())
    },

    addCommands () {
      return {
        showCommandMenu: (wasOpenedAutomatically: boolean) =>
          () => {
            this.storage.commandMenu.wasOpenedAutomatically = wasOpenedAutomatically
            this.storage.commandMenu.showItems.value = !wasOpenedAutomatically

            const { commandMenu } = this.storage

            commandMenu.popup[0].show()

            const ref = commandMenu.renderer?.ref as InstanceType<typeof CommandMenuComponent>

            if (!wasOpenedAutomatically) ref?.focusInput()

            return true
          },
        hideCommandMenu: () =>
          () => {
            const { commandMenu } = this.storage

            commandMenu.popup?.[0]?.hide()

            const ref = commandMenu.renderer?.ref as InstanceType<typeof CommandMenuComponent>
            ref?.resetQuery()
            ref?.resetSelectedParty()

            return true
          },
        updateCommandMenu: () =>
          () => {
            const { commandMenu } = this.storage

            commandMenu.popup[0]?.popperInstance?.update()

            return true
          },
      }
    },

    addKeyboardShortcuts () {
      const { editor } = this
      return {
        "Escape": () => editor.commands.hideCommandMenu(),
      }
    },

    addProseMirrorPlugins () {
      const { editor } = this

      const keysToHandle = [
        "ArrowUp",
        "ArrowDown",
        "Enter",
        "Escape",
      ]

      return [
        new Plugin({
          key: commandMenuPluginKey,
          props: {
            handleKeyDown (_, event) {
              if (!keysToHandle.includes(event.key) || !isCommandMenuOpen(editor)) return false

              const ref = (editor.storage.commandMenu as CommandMenuStorage).commandMenu.renderer?.ref as InstanceType<typeof CommandMenuComponent>

              return !!ref?.onKeyDown({ event })
            },
          },
        }),
      ]
    },
  },
)
