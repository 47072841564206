<script setup lang="ts">
import { storeToRefs } from "pinia"
import { nextTick, ref, watch } from "vue"
import { PencilSquareIcon } from "@heroicons/vue/24/outline"

import { LoadingPlaceholder, SpinLoader } from "~/components"
import { CommentEditor } from "~/editor"
import { useCommentStore, useProposalStore } from "~/stores"
import { Document, DocumentStage, DocumentUser } from "~/types"

interface Props {
  document: Document
  mdu?: DocumentUser
  stage: DocumentStage
}

const props = withDefaults(
  defineProps<Props>(),
  {
    mdu: null,
    document: null,
  },
)

const commentStore = useCommentStore()
const { isCreatingComment, commentTippyProsemirrorDataUuid } = storeToRefs(commentStore)

const proposalStore = useProposalStore()
const { startNewProposal } = proposalStore

const commentEditor = ref(null)

const handleShowProposalModal = () => {
  startNewProposal(commentTippyProsemirrorDataUuid.value)
}

watch(commentTippyProsemirrorDataUuid, async (newValue) => {
  if (newValue) {
    await nextTick()
    commentEditor.value.focus()
  }
})
</script>

<template>
  <div
    id="commentPopover"
    class="relative min-w-max popover popover-secondary"
  >
    <div class="relative">
      <LoadingPlaceholder
        v-if="isCreatingComment"
        class="flex items-center justify-center gap-2 text-sm font-medium text-gray-500"
      >
        <SpinLoader
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        {{ $t('discussions.savingComment') }}…
      </LoadingPlaceholder>
      <div class="relative flex items-start space-x-3">
        <div class="flex-1 min-w-0">
          <form
            id="commentForm"
            action="#"
            class="relative text-sm"
          >
            <div>
              <CommentEditor
                ref="commentEditor"
                class="min-w-full p-3 text-sm bg-white hyphens-auto max-w-min focus:outline-none focus:ring-0 rounded-t-md"
                :prosemirror-data-uuid="commentTippyProsemirrorDataUuid"
                :document-uuid="props.document.uuid"
              >
                <template #secondary-action>
                  <button
                    v-if="stage !== DocumentStage.signing && stage !== DocumentStage.signed"
                    type="button"
                    class="flex items-center gap-1 text-gray-500 bg-transparent btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-100"
                    @click.prevent="handleShowProposalModal"
                  >
                    <PencilSquareIcon
                      class="w-4 h-4 flex-shrink-none"
                      aria-hidden="true"
                    />
                    {{ $t('discussions.proposeChanges') }}
                  </button>
                </template>
              </CommentEditor>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
