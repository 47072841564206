import { Extension } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { Decoration, DecorationSet } from "@tiptap/pm/view"
import { JuneEvents } from "~/types"
import { splitStringIntoStringsOfFiveWords, trackEventInJune } from "~/utils"

const loadingTexts = [
  "Loading.",
  "Loading..",
  "Loading...",
]

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    aiText: {
      writeDelayedText: (text: string) => ReturnType
      acceptAiAutocompletion: () => ReturnType
      rejectAiAutocompletion: () => ReturnType
    }
  }
}

interface AiTextOptions {
  HTMLAttributes: Record<string, any>
  writeDelay: number
  getAiText: (context: string) => Promise<string>
  setIsLoadingAiAutocompletion: (val: boolean) => void
}

interface AiTextStorage {
  decoSet: DecorationSet
  aiCompletionRes: string
}

export const AiText = Extension.create<AiTextOptions, AiTextStorage>(
  {
    name: "aiText",

    priority: 1000,

    addOptions () {
      return {
        writeDelay: 100, // in ms
        HTMLAttributes: {},
        getAiText: async () => "",
        setIsLoadingAiAutocompletion: () => {
          //
        },
      }
    },

    addStorage () {
      return {
        decoSet: DecorationSet.empty,
        aiCompletionRes: "",
      }
    },

    addCommands () {
      return {
        writeDelayedText: (text) =>
          ({ editor }) => {
            if (!text.trim()) return false

            const fiveWordStrings = splitStringIntoStringsOfFiveWords(text)

            for (let i = 0; i < fiveWordStrings.length; i++) {
              setTimeout(
                () => {
                  editor.commands.insertContent(fiveWordStrings[i])
                },
                i * this.options.writeDelay,
              )
            }

            return true
          },
        acceptAiAutocompletion: () => ({ editor, chain }) => {
          const { from, to } = editor.state.selection
          const lastTwoChars = editor.state.doc.textBetween(from - 2, from)

          if (from !== to || lastTwoChars !== "??" || !this.storage.aiCompletionRes) return false

          return chain()
            .deleteRange(
              {
                from: from - 2,
                to,
              },
            )
            .writeDelayedText(this.storage.aiCompletionRes)
            .run()
        },
        rejectAiAutocompletion: () => ({ editor, chain }) => {
          const { from, to } = editor.state.selection
          const lastTwoChars = editor.state.doc.textBetween(from - 2, from)

          if (from !== to || lastTwoChars !== "??") return false

          this.storage.aiCompletionRes = ""
          this.storage.decoSet = DecorationSet.empty

          return chain()
            .deleteRange(
              {
                from: from - 2,
                to,
              },
            )
            .run()
        },
      }
    },

    onSelectionUpdate () {
      const { editor } = this

      const { selection: { $from, $to }, doc } = editor.state

      const lastTwoChars = doc.textBetween($from.pos - 2, $from.pos)

      if ($from.pos !== $to.pos || lastTwoChars !== "??") {
        this.storage.decoSet = DecorationSet.empty
        this.storage.aiCompletionRes = ""

        return
      }

      const start = $from.start(0)

      const contextText = editor.state.doc.textBetween(start, $from.pos - 2, "\n")

      let showLoadingtext = true
      let lastIndex = 2

      const keepShowingLoadingText = async () => {
        if (!showLoadingtext) return

        const loadingDecoSet = DecorationSet.create(
          editor.state.doc,
          [
            Decoration.inline(
              $from.pos - 2,
              $from.pos,
              {
                class: "ai-autocomplete-suggestion",
                "data-ai-autocomplete-suggestion": loadingTexts[lastIndex],
              },
            ),
          ],
        )

        editor.commands.directDecoration()

        this.storage.decoSet = loadingDecoSet

        lastIndex = lastIndex + 1 >= loadingTexts.length ? 0 : lastIndex + 1

        await new Promise((r) => setTimeout(r, 300))

        keepShowingLoadingText()
      }

      keepShowingLoadingText()

      this.storage.decoSet = DecorationSet.create(
        editor.state.doc,

        [
          Decoration.inline(
            $from.pos - 2,
            $from.pos,
            {
              class: "ai-autocomplete-suggestion",
              "data-ai-autocomplete-suggestion": "Loading...",
            },
          ),
        ],
      )

      editor.commands.directDecoration()

      this.options.getAiText(contextText)
        .then(
          (aiText) => {
            trackEventInJune(JuneEvents.EDITOR_AI_TEXT_COMPLETED)
            const newDecoset = DecorationSet.create(
              editor.state.doc,
              [
                Decoration.inline(
                  $from.pos - 2,
                  $from.pos,
                  {
                    class: "ai-autocomplete-suggestion",
                    "data-ai-autocomplete-suggestion": aiText,
                  },
                ),
              ],
            )

            this.storage.decoSet = newDecoset
            this.storage.aiCompletionRes = aiText

            editor.chain().directDecoration().focus().run()
          },
        )
        .finally(
          () => showLoadingtext = false,
        )
    },

    addKeyboardShortcuts () {
      const { editor } = this

      return {
        "Tab": () => editor.commands.acceptAiAutocompletion(),
        "Escape": () => editor.commands.rejectAiAutocompletion(),
      }
    },

    addProseMirrorPlugins () {
      const getDecoset = () => this.storage.decoSet

      const setDecoset = (decoSet: DecorationSet) => this.storage.decoSet = decoSet

      return [
        new Plugin(
          {
            key: new PluginKey("aiText"),
            state: {
              init () {
                getDecoset()
              },
              apply (tr) {
                const mappedDecoset = getDecoset().map(tr.mapping, tr.doc)

                setDecoset(mappedDecoset)

                return getDecoset()
              },
            },
            props: {
              decorations () {
                return getDecoset()
              },
            },
          },
        ),
      ]
    },
  },
)
