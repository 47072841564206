<script setup lang="ts">
// external
import { onMounted, ref } from "vue"

import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"

// internal
import {
  OverlayScrollbar,
  NotificationList,
} from "~/components"
import { useSharedStore } from "~/stores"
import { Template, Document, DocumentNotification, Team, CrudContext } from "~/types"

interface Props {
  template?: Template
  document?: Document
  notificationSettings: DocumentNotification[]
  teams?: Team[]
}

withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
  },
)

const sharedStore = useSharedStore()
const { setSidebarScrollContainer, crudContext } = sharedStore

const notificationScrollContainer = ref()

onMounted(() => {
  setSidebarScrollContainer(notificationScrollContainer.value)
})

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="px-6 pt-6 border-b shrink-0 border-b-gray-200"
    >
      <h3
        class="flex items-center gap-1 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('documents.tabs.notifications') }}

        <span
          data-tippy-help
          :data-tippy-content="crudContext === CrudContext.template ? $t('documentNotifications.templateHint') : $t('documentNotifications.documentHint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>

    <OverlayScrollbar
      ref="notificationScrollContainer"
      tag="div"
      class="flex-1 max-h-full overflow-y-auto"
    >
      <NotificationList
        class="mt-3"
        :notification-settings="notificationSettings"
        :entity-uuid="crudContext === CrudContext.template ? template.uuid : document.uuid"
        :teams="teams"
      />
    </OverlayScrollbar>
  </div>
</template>
