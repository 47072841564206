<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, nextTick, ref, toRaw, watch, onBeforeMount, onBeforeUnmount } from "vue"
import { useI18n } from "vue-i18n"

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { PlusIcon } from "@heroicons/vue/20/solid"
import { TrashIcon } from "@heroicons/vue/24/outline"
import { ArrowLeftIcon, CheckIcon, LockClosedIcon } from "@heroicons/vue/24/solid"
import { toTypedSchema } from "@vee-validate/zod"
import { find, intersection, remove, set, without } from "lodash-es"
import tippy, { DelegateInstance, Props as TippyProps, roundArrow } from "tippy.js"
import { useField, useForm } from "vee-validate"
import { z } from "zod"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"

// internal
import { AccountPartyCombobox, FormInputErrors, MobileClosePopoverButton, OverlayScrollbar, PartyEntityTypeSelector, PartyPopoverSignatory, RadioGroupPills, SignatoryForm, SpinLoader, UserCombobox } from "~/components"
import { UnlinkIcon } from "~/icons"
import { useConfirmationStore, useDocumentStore, useEditorStore, useNotificationStore, usePartyStore, useSharedStore, useSignatureStore, useTemplateStore, useUserStore } from "~/stores"
import { AccountUser, CrudContext, Document, DocumentContentType, DocumentStage, DocumentUser, DocumentUserRoleEnum, DocumentVariable, Party, PartyEntityType, SignatureBlock, Template, UiUser } from "~/types"
import { EMAIL_REGEX, changedKeys, documentUserInvitationStatuses, entityTypeOptions, focusFirstFocusable, formatTime, getUserRepresentation, hideTippiesViaSelector } from "~/utils"

interface Props {
  party?: Party
  combinedUsers: UiUser[]
  isLoadingUsers?: boolean
  template?: Template
  document?: Document
  documentUsers?: DocumentUser[]
  accountParties?: Party[]
  elementId?: string
  activeTab?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    party: null,
    template: null,
    document: null,
    isLoadingUsers: false,
    documentUsers: () => [],
    accountUsers: () => [],
    accountParties: () => [],
    elementId: null,
    activeTab: null,
  },
)

const validateEmail = (str: string) => EMAIL_REGEX.test(str)

const { notify } = useNotificationStore()
const { t } = useI18n()

const partyStore = usePartyStore()
const { partyErrorsMap, uuidsOfUpdatingParty, partyLastSavedMap, partyUuidBeingRemovedFromEntity, newParty, triggerPartyPopoverTab } = storeToRefs(partyStore)
const { removePartyFromEntity, updateNewParty, setNewParty } = partyStore

const userStore = useUserStore()
const { uuidsOfUpdatingUser, userNotifications, userLastSavedMap, userUuidBeingRemovedFromEntity, users, documentUserLastUpdatedViaPusher } = storeToRefs(userStore)
const { createSignatureBlockIfNeeded } = userStore

const templateStore = useTemplateStore()

const documentStore = useDocumentStore()
const { mdu, mau, currentDocument, isLockedDocument, documentVariables } = storeToRefs(documentStore)
const { showReferenceAlert } = documentStore

const signatureStore = useSignatureStore()
const { signatureBlocks, preemptiveSignatures } = storeToRefs(signatureStore)
const { removeSignatureBlock } = signatureStore

const editorStore = useEditorStore()
const { isDirty } = storeToRefs(editorStore)

const confirmationStore = useConfirmationStore()
const {
  setShowConfirmModal,
  setConfirmOptions,
} = confirmationStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual("md")

const lastSaved = computed<number | undefined>(() => {
  return Math.max(partyLastSavedMap.value[localParty.value?.uuid] || 0, userLastSavedMap.value[relatedSignatory.value?.uuid] || 0) || null
})

const preventPartyUpdateCalls = ref<boolean>(false)
const addSignatoryForm = ref<any>()
const addAccountPartyUuid = ref<Party["uuid"]>(null)
const addUserTippy = ref(null)
const selectedUser = ref<UiUser>(null)
const isSubmittingAddUser = ref<boolean>(false)
const addUserToPartyPopoverRef = ref()
const deAssigningUserFromParty = ref<AccountUser["uuid"]>(null)
const isUpdatingRoles = ref<AccountUser["uuid"]>(null)
const isLoadingCreateParty = ref<boolean>(false)
const isLoadingCreateUser = ref<boolean>(false)
const isLoadingParty = computed<boolean>(() => {
  return uuidsOfUpdatingParty.value.includes(localParty.value?.uuid) || uuidsOfUpdatingUser.value.includes(relatedSignatory.value?.uuid) || isLoadingCreateParty.value || isLoadingCreateUser.value
})

const isMandatoryAddress = computed<boolean>(() => {
  const check = crudContext.value === CrudContext.document && localParty.value?.scope === "internal" && documentVariables.value?.some((variable: DocumentVariable) => variable.field === "address" && variable.class === "App\\Models\\Party" && variable.uuid === localParty.value?.uuid)
  return check
})

const isMandatoryEntityName = computed<boolean>(() => {
  const check = crudContext.value === CrudContext.document && localParty.value?.scope === "internal" && documentVariables.value?.some((variable: DocumentVariable) => variable.field === "entity_name" && variable.class === "App\\Models\\Party" && variable.uuid === localParty.value?.uuid)
  return check
})

// Calculate entity uuid for db calls
const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return props?.[crudContext.value]?.uuid
})

// Prepare validation and error handling
// for updates on the party

const formValidationerrors = ref<Partial<Record<keyof Party, string[]>>>({})
const backenderrors = computed(() => partyErrorsMap.value[localParty.value.uuid])

const partySchema = z
  .object(
    {
      name: z.string().optional(),
      entity_name: z.string().nullable().optional(),
      address: z.string().nullable().optional(),
      scope: z.string().optional(),
      entity_type: z.nativeEnum(PartyEntityType).nullable().optional(),
      account_party_uuid: z.string().nullable().optional(),
    },
  ).refine( (input) => {
    if ( !input.name && (crudContext.value === CrudContext.template || (crudContext.value === CrudContext.document && props.party?.uuid)) && !addAccountPartyUuid.value) return false

    // Entity type required when account party is not set
    if ( (typeof input.entity_type === "undefined" || (crudContext.value === CrudContext.document && !input.entity_type)) && !addAccountPartyUuid.value) return false

    // Entity name required when name is empty
    if ( !input.entity_name && !input.name && crudContext.value === CrudContext.document && !addAccountPartyUuid.value) return false

    // Address and name required when not editable externally
    if ( ((crudContext.value === CrudContext.document && localParty.value?.scope === "internal") || isMandatoryEntityName.value) && !input.entity_name) return false
    if ( ((crudContext.value === CrudContext.document && localParty.value?.scope === "internal") || isMandatoryAddress.value) && !input.address) return false

    return true

  }, (input) => {
    if ( !input.name && (crudContext.value === CrudContext.template || (crudContext.value === CrudContext.document && props.party?.uuid)) && !addAccountPartyUuid.value ) {
      return {
        message: t("partyForm.errors.reference"),
        path: [ "name" ],
      }
    }
    // This is displayed when adding a party (business) to a document, as the name is taken from the entity name
    if ( !input.entity_name && !input.name && crudContext.value === CrudContext.document && !addAccountPartyUuid.value) {
      return {
        message: t("partyForm.errors.entityName"),
        path: [ "entity_name" ],
      }
    }
    if ( (typeof input.entity_type === "undefined" || (crudContext.value === CrudContext.document && !input.entity_type)) && !addAccountPartyUuid.value) {
      return {
        message: t("partyForm.errors.entityType"),
        path: [ "entity_type" ],
      }
    }
    if ( isMandatoryEntityName.value && !input.entity_name) {
      return {
        message: t("partyForm.errors.entityName"),
        path: [ "entity_name" ],
      }
    }
    if ( isMandatoryAddress.value && !input.address) {
      return {
        message: t("partyForm.errors.address"),
        path: [ "address" ],
      }
    }
  } )

type PartyValidatedType = z.infer<typeof partySchema>


const partyValidator = toTypedSchema(partySchema)

const { errors, setValues, setFieldValue, validate, resetForm } = useForm<PartyValidatedType>(
  {
    validationSchema: partyValidator,
  },
)

const errorsToShow = computed<Partial<Record<keyof Party, string[]>>>(
  () => {
    const errors: Partial<Record<keyof Party, string[]>> = {}

    if (backenderrors.value) {
      Object.keys(backenderrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backenderrors.value[key] || []) ]
          },
        )
    }

    if (formValidationerrors.value) {
      Object.keys(formValidationerrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationerrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationerrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof Party, string[]>>,
      )
  },
)

// Party form fields
const name = useField<string>("name")
const entity_name = useField<string>("entity_name")
const address = useField<string>("address")
const scope = useField<string>("scope")
const entity_type = useField<PartyEntityType>("entity_type")
useField<string>("account_party_uuid")

const isActiveEditName = ref<boolean>(false)

const emptyParty: Partial<Party> = Object.freeze({
  scope: "internal_and_external",
})

const localParty = computed<Partial<Party>>({
  get: () => {
    if (props.party?.uuid) return props.party || { ...emptyParty }
    return newParty.value
  },
  set: (val) => {
    setTimeout(
      async () => {
        if (!props.party?.uuid) return
        const isValid = await validate(
          {
            mode: "validated-only",
          },
        )
        const payloadKeys = changedKeys({ ...toRaw(localParty.value) }, val)
        const checkIntersection = intersection(payloadKeys, Object.keys(isValid.errors))
        // Stop update if not valid and the payload includes invalid fields
        if (!payloadKeys.length || (!isValid.valid && checkIntersection?.length)) return
        if (preventPartyUpdateCalls.value) return
        partyStore.updateLocalParty(val, crudContext.value, entityUuid.value)
      },
    )
    if (!props.party?.uuid) updateNewParty(val)
  },
})

const updatePartyFieldValue = (fieldName: string, val: any) => {
  setFieldValue(fieldName as any, val)
  localParty.value = {
    ...localParty.value,
    [fieldName]: val,
  }
}

watch(
  () => localParty.value,
  (newVal) => {
    if (!newVal) return
    if (localParty.value?.uuid) setValues(newVal)
  },
  {
    deep: true,
    immediate: true,
  },
)

// Prepare and handle adding/editing a party that is person
// i.e. adding/editing a document user at the same time

const emptyUser: Partial<DocumentUser> = Object.freeze({ })
const localNewUser = ref<Partial<DocumentUser>>({ ...emptyUser })
const relatedCount = computed<number>(() => {
  return (props.documentUsers)?.filter((el) => el.party_uuid && el.party_uuid === props.party?.uuid && el.roles?.includes(DocumentUserRoleEnum.signatory))?.length
})
const relatedSignatory = computed<Partial<DocumentUser>>({
  get: () => {
    const returnValue = localParty.value?.entity_type === PartyEntityType.business || props.party?.entity_type === PartyEntityType.business ? null : (props.documentUsers)?.find((el) => el.party_uuid && el.party_uuid === props.party?.uuid && el.roles?.includes(DocumentUserRoleEnum.signatory)) || localNewUser.value
    return returnValue || { ...emptyUser }
  },
  set: (val) => {
    localNewUser.value = val
  },
})

const entityName = computed(() => {
  if (relatedSignatory.value.first_name || relatedSignatory.value.last_name) {
    return getUserRepresentation(relatedSignatory.value)
  } else if (localNewUser.value.first_name || localNewUser.value.last_name) {
    return getUserRepresentation(localNewUser.value)
  } else if (relatedSignatory.value.email || localNewUser.value.email) {
    return relatedSignatory.value.email || localNewUser.value.email
  }
  return localParty.value?.entity_name || ""
})

watch(entityName, (newVal, oldVal) => {
  // Skip if caused by a pusher event
  if (documentUserLastUpdatedViaPusher.value === relatedSignatory.value?.uuid) return
  // Do not update if no change
  if (newVal === oldVal) return
  if (localParty.value?.entity_type === PartyEntityType.business || entity_type.value.value === PartyEntityType.business || (crudContext.value === CrudContext.document && isLockedDocument.value)) return
  updatePartyFieldValue("entity_name", newVal)
})

const isLockedRemoveParty = computed<boolean>(() => {
  const check =
  !!(signatureBlocks.value?.some((el) => el.party_uuid === localParty.value?.uuid && !el.deleted_at) || isLockedDocument.value || isDirty.value)
  return check
})

// Prepare adding a party that is a business
// and potentially adding one or more document
// users at the same time

const assignSignatoryCollection = ref<UiUser[]>([])
const addSignatoryCollection = ref<UiUser[]>([])

const deactivatedUsers = computed<DocumentUser[]>(() => {
  if (!props.party?.uuid) {
    return [ ...addSignatoryCollection.value, ...assignSignatoryCollection.value ]
  } else {
    return (props.combinedUsers as UiUser[])?.filter((el) => el.party_uuid && el.roles?.includes(DocumentUserRoleEnum.signatory)) || []
  }
})

// Watcher to reset form depending on dropdown choice
// when adding a new party

watch(() => addAccountPartyUuid, () => {
  if (!localParty.value.uuid) localParty.value = Object.assign({}, emptyParty)
  addSignatoryCollection.value = []
  assignSignatoryCollection.value = []
})

// Watchers and computeds for scope and entity name

const partyExternalScope = computed({
  get: () => scope.value.value === "internal_and_external",
  set: (val: boolean) => {
    updatePartyFieldValue("scope", val ? "internal_and_external" : "internal")
  },
})

// Computed related account party info

const accountParty = computed(() => {
  if (!props.accountParties) return
  if (props.party?.account_party_uuid) {
    return props.accountParties.find((el) => el.uuid === props.party?.account_party_uuid)
  } else {
    return props.accountParties.find((el) => el.uuid === addAccountPartyUuid.value)
  }
})

const signatories = computed(() => {
  return (props.combinedUsers as DocumentUser[])?.filter(
    (el) =>
      el.party_uuid &&
      el.party_uuid === localParty.value.uuid &&
      el.roles?.includes(DocumentUserRoleEnum.signatory),
  ).concat(assignSignatoryCollection.value).concat(addSignatoryCollection.value)
})

const isVisibleUserForm = computed(() => {
  const checkEntityType = (props.party?.entity_type !== PartyEntityType.business && localParty.value?.entity_type !== PartyEntityType.business) && (localParty.value?.entity_type || isLoadingCreateParty.value) && localParty.value?.entity_type !== PartyEntityType.tbd && accountParty.value?.entity_type !== PartyEntityType.business
  if (!checkEntityType) return false
  const checkAssignedUsers = signatories.value?.length < 2
  if (!checkAssignedUsers) return false
  const newPartyOrAssignedUser = !props.party?.uuid || (props.party?.uuid && signatories.value?.length)
  if (!newPartyOrAssignedUser) return false
  return true
})

const showAddUserToPartyPopover = (e) => {
  addUserTippy.value = tippy(e.target, {
    content () {
      return addUserToPartyPopoverRef.value
    },
    animation: "scale",
    allowHTML: true,
    theme: "indigo",
    arrow: roundArrow,
    interactive: true,
    trigger: "manual",
    showOnCreate: true,
    appendTo: ((ref) => ref.closest(".popover")) || "parent",
    onShown () {
      const element = addUserToPartyPopoverRef.value
      focusFirstFocusable(element)
    },
    onHidden () {
      selectedUser.value = null
    },
  })
}

// Function to wait until all collected user assign/add tasks have been completed
const executeAddTasks = async (partyUuid: Party["uuid"]) => {

  const returnEvents = []

  await Promise.all(
    addSignatoryCollection.value.map(
      async (el) => {
        const returnEvent = await addSignatoryToParty(el, partyUuid, true)
        returnEvents.push(returnEvent)
      },
    ),
  )

  if (addSignatoryCollection.value?.length > 0) await createSignatureBlockIfNeeded(crudContext.value, entityUuid.value, { roles: [ DocumentUserRoleEnum.signatory ] }, partyUuid)

  return returnEvents
}

const executeAssignTasks = async (partyUuid: Party["uuid"]) => {
  const returnEvents = []

  await Promise.all(
    assignSignatoryCollection.value.map(
      async (el) => {
        const returnEvent = await assignSignatoryToParty(el, partyUuid)
        returnEvents.push(returnEvent)
      },
    ),
  )

  return returnEvents
}

const resetExecutionCollections = () => {
  // Reset collection elements
  addSignatoryCollection.value = []
  assignSignatoryCollection.value = []
}

// Function to create / add a new party
const createParty = async () => {

  if (localParty.value.uuid) return

  const entityUuid = props?.[crudContext.value]?.uuid

  // When no title / reference was specified, use the entity name instead
  if (!localParty.value.name && localParty.value.entity_name) updatePartyFieldValue("name", localParty.value.entity_name)

  const isValid = await validate(
    {
      mode: "force",
    },
  )
  if (!isValid.valid) {
    addSignatoryForm.value?.exposeValidate("force")
    return
  }

  const payloadEntityType = [ PartyEntityType.business, PartyEntityType.person ].includes(entity_type.value.value) ? entity_type.value.value : null

  const payload = addAccountPartyUuid.value ?
    {
      name: localParty.value?.name || accountParty.value?.entity_name,
      account_party_uuid: addAccountPartyUuid.value,
    }
    : toRaw({
      ...localParty.value,
      entity_type: payloadEntityType,
    })

  // Remove empty account_party_uuid property from payload
  if (!payload.account_party_uuid) delete payload.account_party_uuid

  try {
    isLoadingCreateParty.value = true

    const createdParty = await partyStore.createParty(crudContext.value, entityUuid, payload)

    if (!createdParty) throw new Error(t("partyForm.errors.invalidParty", { string: JSON.stringify({ createdParty }) }))

    addSignatoryForm.value?.updateFieldValue("party_uuid", createdParty.uuid)
    if (localParty.value?.entity_type !== PartyEntityType.business && (localNewUser.value.email || localNewUser.value.account_user?.uuid)) {
      const validated = await addSignatoryForm.value?.exposeValidate("force")
      if (!validated) return
      if (localNewUser.value.uuid) collectAssignSignatoryToParty(localNewUser.value as UiUser)
      else collectAddSignatoryToParty(localNewUser.value as UiUser)
    }

    await Promise.all([ executeAddTasks(createdParty.uuid), executeAssignTasks(createdParty.uuid) ])

    hideTippiesViaSelector(".add-party-button")

    resetExecutionCollections()
    localParty.value = Object.assign({}, emptyParty)
    setNewParty(Object.assign({}, emptyParty))
    addAccountPartyUuid.value = null
    partyStore.setPartyErrors("0", null)
    nextTick(() => {
      resetForm()
      setFieldValue("scope", localParty.value?.scope || "internal_and_external")
    })

  } catch (err) {
    partyStore.setPartyErrors("0", err.response?.data?.errors || null)

    notify({
      title: t("userSettings.errors.addParty"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingCreateParty.value = false
  }
}

// Function to assign a user to a party
const assignSignatoryToParty = async (user: UiUser, partyUuid?: Party["uuid"]) => {

  isSubmittingAddUser.value = true

  const newRoles = user.roles ? [ ...user.roles, DocumentUserRoleEnum.signatory ] : [ DocumentUserRoleEnum.signatory ]

  const payload: Partial<DocumentUser> = {
    roles: newRoles,
    party_uuid: partyUuid || localParty.value.uuid,
  }

  try {

    const updatedUser = await userStore.updateUser(crudContext.value, entityUuid.value, payload, user, Date.now(), [ "roles", "party_uuid" ])
    if (!updatedUser) throw new Error(t("userSettings.errors.invalidUser", { string: JSON.stringify({ updatedUser }) }))
    userStore.setUserErrors(user.uuid, null)
    partyStore.setPartyLastSaved(partyUuid)
    selectedUser.value = null
    addUserTippy.value?.hide()
    return updatedUser

  } catch (err) {
    userStore.setUserErrors(user.uuid, err.response?.data?.errors || null)
    notify({
      title: t("userSettings.errors.assignUser"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isSubmittingAddUser.value = false
  }
}

// Function to collect users to assign them to a new party later
const collectAssignSignatoryToParty = (user: UiUser, stopHide = false) => {
  assignSignatoryCollection.value.push(user)
  if (stopHide) return
  addUserTippy.value?.hide()
  selectedUser.value = null
}

// Function to add user to a template/document and assign a party
const addSignatoryToParty = async (user: UiUser, partyUuid?: Party["uuid"], skipCreateSignatureBlock = false) => {
  isSubmittingAddUser.value = true

  const rolesObject = [ DocumentUserRoleEnum.signatory ]
  if (user.roles?.includes(DocumentUserRoleEnum.collaborator)) rolesObject.push(DocumentUserRoleEnum.collaborator)

  // Deliver entire user as payload for external users, and only role/party for internal users
  let payload: Partial<DocumentUser> = {
    party_uuid: partyUuid || localParty.value.uuid,
    roles: rolesObject,
  }

  if (!user.account_user?.uuid) {
    payload = {
      ...payload,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      mobile_phone: user.mobile_phone,
    }
  }

  if (user.account_user?.uuid) {
    payload = { ...payload, account_user_uuid: user.account_user?.uuid }
  }

  try {
    const createdUser = await userStore.createUser(crudContext.value, entityUuid.value, payload, skipCreateSignatureBlock)
    if (!createdUser) throw new Error(t("userSettings.errors.invalidUser", { string: JSON.stringify({ createdUser }) }))
    userStore.setUserErrors("0", null)
    selectedUser.value = null
    addUserTippy.value?.hide()
    localNewUser.value = emptyUser
    return createdUser

  } catch (err) {

    userStore.setUserErrors("0", err.response?.data?.errors || null)
    notify({
      title: t("userSettings.errors.addUser"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })

  } finally {
    isSubmittingAddUser.value = false
  }
}

// Function to collect users to add to a template/document and assign them to a new party later
const collectAddSignatoryToParty = (user: UiUser) => {
  addSignatoryCollection.value.push(user)
  addUserTippy.value?.hide()
  selectedUser.value = null
}

// Function to de-assign a user from a party (for both new and existing parties)
const deAssignSignatoryFromParty = async (user: UiUser) => {
  if (!props.party?.uuid) {
    // New party being created
    remove(assignSignatoryCollection.value, (el) => el.document_user_uuid === user.document_user_uuid)
    remove(addSignatoryCollection.value, (el) => (el.email && el.email === user.email) || (el.uuid && el.uuid === user.uuid))
    relatedSignatory.value = { ...emptyUser }
    addSignatoryForm.value?.resetForm()
  } else {
    // When we are removing from a single user form being displayed, we use user.uuid directly
    // When we are removing from a list of assigned users (combined user object), we use user.document_user_uuid
    const documentUserUuid = user.document_user_uuid ? user.document_user_uuid : user.uuid
    // Existing party being edited
    deAssigningUserFromParty.value = documentUserUuid
    const res = await handleRemoveUserAsSignatory(documentUserUuid)
    if (res) {
      deAssigningUserFromParty.value = null
      relatedSignatory.value = { ...emptyUser }
    }
  }
}

const hasPreemptiveSignature = computed<boolean>(() => {
  if (preemptiveSignatures.value?.some((el) => el.document_user_uuid === relatedSignatory.value?.uuid)) return true
  return false
})

const checkIfRemovable = (userUuid: DocumentUser["uuid"]): boolean => {
  if (!mdu.value?.permissions?.includes("document_user_delete") && crudContext.value === CrudContext.document) return false
  if (isLockedDocument.value) return false
  if (preemptiveSignatures.value?.some((el) => el.document_user_uuid === userUuid)) return false
  return true
}

// Function to remove a user from an entity
const handleRemoveUserAsSignatory = async (userUuid: DocumentUser["uuid"]) => {
  if (!userUuid) return
  try {
    isUpdatingRoles.value = userUuid
    const res = await userStore.removeUserAsSignatory(userUuid, entityUuid.value, crudContext.value)
    if (res) return true
  } catch (err) {
    notify({
      title: t("userSettings.errors.deassignRole"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isUpdatingRoles.value = null
  }
}

// Function to change roles of an assigned user (for both new and existing parties)
const handleUpdateRoles = async (data: DocumentUserRoleEnum[], user: UiUser) => {
  if (!props.party?.uuid) {
    // New party being created
    set(find(assignSignatoryCollection.value, { document_user_uuid: user.document_user_uuid }), "roles", data)
    set(find(addSignatoryCollection.value, (el) => { return (el.email && el.email === user.email) || (el.uuid && el.uuid === user.uuid) }), "roles", data)
  } else {
    const payload = { roles: data }
    try {
      isUpdatingRoles.value = user.uuid || user.document_user_uuid
      const updatedUser = await userStore.updateUser(crudContext.value, entityUuid.value, payload, user, Date.now(), [ "roles" ])
      if (!updatedUser) throw new Error(t("userSettings.errors.invalidUser", { string: JSON.stringify({ updatedUser }) }))
      userStore.setUserErrors(user.uuid, null)
      partyStore.setPartyLastSaved(props.party?.uuid)
      return updatedUser
    } catch (err) {
      userStore.setUserErrors(user.uuid, err.response?.data?.errors || null)
      notify({
        title: t("userSettings.errors.roles"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
    } finally {
      isUpdatingRoles.value = ""
    }
  }
}

// Constructor for changes on selected user
watch(selectedUser, (newVal) => {

  if (!newVal) return

  if (!(selectedUser.value?.uuid || selectedUser.value?.document_user_uuid) && !selectedUser.value?.email) return
  // When adding a new party
  if (!props.party?.uuid) {

    // User (internal or external) is already added to the template / document
    // User therefor only needs to be assigned to the party

    // Check if there is a document user for the account_user?.uuid of the selectedUser
    const documentUserFound = users.value?.find((el) => el.account_user?.uuid && el.account_user?.uuid === selectedUser.value?.account_user?.uuid)
    if (documentUserFound) selectedUser.value.uuid = documentUserFound.uuid

    if ((selectedUser.value?.uuid && selectedUser.value?.account_user?.uuid) || selectedUser.value?.document_user_uuid) {
      collectAssignSignatoryToParty(selectedUser.value)

    // User (internal) needs to be added to the document
    // AND assigned to the party
    } else if (selectedUser.value.uuid && !selectedUser.value.account_user?.uuid) {
      collectAddSignatoryToParty(selectedUser.value)

    // User (external) needs to be invited
    // AND assigned to the party
    } else if (validateEmail(selectedUser.value.email)) {
      selectedUser.value.roles = [ DocumentUserRoleEnum.signatory ]
      collectAddSignatoryToParty(selectedUser.value)
    }

  }

  // When managing an existing party
  else {

    // User (internal or external) is already added to the template / document
    // User therefor only needs to be assigned to the party

    // Check if there is a document user for the account_user?.uuid of the selectedUser
    const documentUserFound = users.value?.find((el) => !!el.account_user?.uuid && el.account_user?.uuid === selectedUser.value?.account_user?.uuid)
    if (documentUserFound) selectedUser.value = { ...selectedUser.value, uuid: documentUserFound.uuid, roles: documentUserFound.roles }

    if (selectedUser.value?.uuid || selectedUser.value?.document_user_uuid) {
      assignSignatoryToParty(selectedUser.value)

    // User (internal) needs to be added to the document
    // AND assigned to the party
    } else if (selectedUser.value.uuid && !selectedUser.value.account_user?.uuid) {
      addSignatoryToParty(selectedUser.value)

    // User (external) needs to be invited
    // AND assigned to the party
    } else if (validateEmail(selectedUser.value.email)) {
      addSignatoryToParty(selectedUser.value)
    }

  }
  selectedUser.value = null
})

const removeAssignedUsers = async (): Promise<boolean | void> => {
  const entity = props.document || props.template
  let state = true
  if (!signatories.value?.length) return state
  // Needs to be reversed to avoid automatic entity_name changes to party
  await Promise.all(
    signatories.value?.map(async (user) => {
      deAssigningUserFromParty.value = user.document_user_uuid || user.uuid
      const newRoles = without(user.roles || [], DocumentUserRoleEnum.signatory)
      const payload = { ...user, party_uuid: null, roles: newRoles, uuid: user.document_user_uuid || user.uuid }
      const lookup = documentUserInvitationStatuses
      const userNotInvited = !userNotifications.value?.some((el) => el.document_user_uuid === payload.uuid && lookup.includes(el.notification_type))
      try {
        if (
          (crudContext.value === CrudContext.template || userNotInvited) && payload.uuid && !newRoles.length) {
          return await userStore.removeUserFromEntity(crudContext.value, entity.uuid, payload.uuid)
        } else {
          return await userStore.updateLocalUser(payload, crudContext.value, entity.uuid, true)
        }
      } catch (err) {
        console.error(err)
        state = false
      } finally {
        deAssigningUserFromParty.value = null
      }
    }),
  )
  return state
}

const removeSignatureBlocks = async (): Promise<boolean | void> => {
  const entity = props.document || props.template
  let state = true
  const signatureBlocksOfParty = signatureBlocks.value?.filter((el: SignatureBlock) => el.party_uuid === localParty.value.uuid && !el.deleted_at)
  if (signatureBlocksOfParty?.length) {
    signatureBlocksOfParty.forEach(async (sb: SignatureBlock) => {
      try {
        await removeSignatureBlock(crudContext.value, entity.uuid, sb.uuid)
      } catch (err) {
        console.error(err)
        state = false
      }
    })
  }
  return state
}

const confirmDeleteParty = (callback: () => void) => {
  setConfirmOptions({
    title: t("partyForm.confirmDelete"),
    description: crudContext.value === CrudContext.template ? t("partyForm.confirmDeleteDescriptionTemplate") : t("partyForm.confirmDeleteDescriptionDocument"),
    buttonText: t("partyForm.confirmDeleteButton"),
    callback: callback,
    cancelCallback: () => { return false },
  })
  setShowConfirmModal(true)
}

// Function to remove a party from an entity
const removeParty = async (partyUuid: Party["uuid"]) => {
  const partyRefUuid = localParty.value?.ref_uuid
  const entity = props.document || props.template
  //if ( props.documentUsers.some((el) => el.party_uuid === partyUuid) ) return
  if ( signatureBlocks.value?.some((el) => el.party_uuid === partyUuid && !el.deleted_at) ) return

  if (entity) {

    if (crudContext.value === CrudContext.document && currentDocument.value?.content_type === DocumentContentType.prosemirror_data) {
      const isPartyReferencedInDocumentContent = await documentStore.checkForReferences("party", partyUuid)
      if (isPartyReferencedInDocumentContent) {
        showReferenceAlert("party", partyRefUuid)
        return
      }
    } else if (crudContext.value === CrudContext.template) {
      const isPartyReferencedInTemplateContent = templateStore.checkForReferences("party", localParty.value?.ref_uuid)
      if (isPartyReferencedInTemplateContent) {
        showReferenceAlert("party", partyRefUuid)
        return
      }
    }

    confirmDeleteParty(async () => {

      preventPartyUpdateCalls.value = true

      const removeAssignedUsersHandler = await removeAssignedUsers()
      if (!removeAssignedUsersHandler) return
      const removeSignatureBlocksHandler = await removeSignatureBlocks()
      if (!removeSignatureBlocksHandler) return

      removePartyFromEntity(crudContext.value, entity.uuid, partyUuid)

      // ugly hack to destroy tippy after DOM is gone
      document.querySelectorAll(".edit-party-button_" + partyUuid).forEach((node) => {
        interface TippyNode extends Element {
          _tippy: DelegateInstance<TippyProps>
        }

        const tippyNode = node as TippyNode
        if (tippyNode._tippy) {
          tippyNode._tippy.destroy()
        }
      })

    })
  }
}

const removeButton = ref()

watch(isLockedRemoveParty, () => {
  nextTick(() => {
    if (removeButton.value?._tippy) {
      removeButton.value?._tippy.setContent(removeButton.value?.getAttribute("data-tippy-content"))
    }
  })
})

const showPreSelectionStep = computed<boolean>(() => {
  const check = (crudContext.value === CrudContext.document && !addAccountPartyUuid.value && !entity_type.value.value && !props.party?.uuid) || (crudContext.value === CrudContext.template && !entity_type.value.value && !props.party?.uuid)
  return check
})

const entityTypeOptionsToOmit = crudContext.value === CrudContext.template ? [ ] : [ PartyEntityType.tbd ]

const entityTypeOptionsFiltered = computed<any>(() => {
  return entityTypeOptions.filter((el) => {
    return !entityTypeOptionsToOmit.includes(el.value)
  }).map((el) => {
    if (el.value === PartyEntityType.tbd) {
      return { ...el, value: null }
    } else {
      return el
    }
  })
})

const handleChangeEntityType = (val: PartyEntityType) => {
  if (val === PartyEntityType.tbd || val === PartyEntityType.person) {
    updatePartyFieldValue("entity_name", "")
    updatePartyFieldValue("address", "")
  }
  updatePartyFieldValue("entity_type", val)
}

const handleUpdateSelectedParty = (party: Party) => {
  if (!party) {
    addAccountPartyUuid.value = null
    updatePartyFieldValue("account_party_uuid", null)
    updatePartyFieldValue("entity_name", "")
    return
  }
  if (party.uuid && !props.accountParties?.some((el) => el.uuid === party.uuid)) return
  if (party.uuid) {
    addAccountPartyUuid.value = party.uuid
    updatePartyFieldValue("account_party_uuid", party.uuid)
  } else {
    addAccountPartyUuid.value = null
    updatePartyFieldValue("account_party_uuid", null)
    // making a normal party out of an account party
    let changedParty: Partial<Party> = {
      ...localParty.value,
      entity_name: party.entity_name,
    }
    setFieldValue("entity_name", party.entity_name)
    if (party.address) {
      changedParty = { ...changedParty, address: party.address }
      setFieldValue("address", party.address)
    }
    if (props.party?.uuid && props.party?.account_party_uuid) {
      changedParty = {
        ...changedParty,
        account_party_uuid: null,
        entity_type: PartyEntityType.business,
      }
      setFieldValue("entity_type", PartyEntityType.business)
    }
    localParty.value = changedParty
  }
}

const selectedParty = computed<Party | null>(() => {
  let partyToReturn = null
  if (addAccountPartyUuid.value) {
    const accountParty = props.accountParties?.find((el) => el.uuid === addAccountPartyUuid.value)
    partyToReturn = (accountParty as Party)
  } else {
    partyToReturn = localParty.value
  }
  return partyToReturn
})

const partyPopoverTabs = computed(() => {
  const tabsToReturn = [
    { id: "details", name: t("partyForm.tabs.details") },
  ]
  if (localParty.value?.entity_type !== PartyEntityType.person || signatories.value?.length > 1) {
    tabsToReturn.push({ id: "signatories", name: t("partyForm.tabs.signatories") })
  }
  return tabsToReturn
})

watch(partyPopoverTabs, () => {
  if (partyPopoverTabs.value.length === 1) {
    activePartyPopoverTab.value = partyPopoverTabs.value[0]
  }
})

const activePartyPopoverTab = ref<Record<string, string>>(partyPopoverTabs.value[0])

watch(triggerPartyPopoverTab, () => {
  activePartyPopoverTab.value = partyPopoverTabs.value.find((el) => el.id === triggerPartyPopoverTab.value) || partyPopoverTabs.value[0]
})

onBeforeMount(() => {
  // Reset empty party on load
  setNewParty(Object.assign({}, emptyParty))
  // Set field values
  setFieldValue("scope", localParty.value?.scope || "internal_and_external")
})

onBeforeUnmount(() => {
  setNewParty(Object.assign({}, emptyParty))
})

</script>

<template>
  <div
    :id="
      props.elementId ?
        props.elementId :
        !party?.uuid
          ? 'addPartyForm'
          : 'partyForm_' + party?.ref_uuid
    "
    :data-cy-sel="`party-popover`"
  >
    <div
      class="popover popover-primary popover-mobile-fullscreen"
    >
      <MobileClosePopoverButton
        :label="$t('popovers.editParty')"
      />
      <component
        :is="isMobile ? OverlayScrollbar : 'div'"
        v-if="showPreSelectionStep"
        tag="div"
        class="flex-1 h-full p-4 sm:max-w-[26rem]"
      >
        <div class="text-[10px] font-medium tracking-wider uppercase text-gray-500 pb-0.5">
          {{ $t('partyForm.selectEntityType') }}
        </div>
        <PartyEntityTypeSelector
          @select-entity-type="($event) => updatePartyFieldValue('entity_type', $event)"
        />
      </component>
      <div
        v-else
        class="sm:w-[26rem] h-full"
      >
        <component
          :is="isMobile ? OverlayScrollbar : 'div'"
          tag="div"
          class="flex-1 h-full"
        >
          <div
            v-if="partyPopoverTabs.length > 1"
            class="px-4 py-2 bg-white border-b border-gray-300 sm:bg-gray-100 rounded-t-md sm:p-0"
          >
            <nav
              class="flex space-x-2 lg:space-x-0 lg:-mb-px"
              aria-label="Tabs"
            >
              <button
                v-for="tab in partyPopoverTabs"
                :key="tab.id"
                v-cy="`party-popover-tab-${tab.id}`"
                type="button"
                :class="[
                  activePartyPopoverTab.id === tab.id
                    ? 'bg-indigo-600 text-white border-indigo-500 lg:text-indigo-600'
                    : 'border-transparent text-indigo-900 hover:text-indigo-950 lg:text-gray-500 lg:hover:text-gray-700 hover:border-gray-400',
                  'lg:w-1/2 py-3 whitespace-nowrap px-3 pt-3.5 lg:px-1 bg-indigo-100 lg:bg-transparent rounded-full lg:rounded-none text-center lg:border-b-2 font-medium text-sm',
                ]"
                @click="activePartyPopoverTab = tab"
              >
                {{ tab.name }}
              </button>
            </nav>
          </div>
          <div class="flex flex-col gap-3 p-4 pt-3">
            <template v-if="activePartyPopoverTab.id === 'details'">
              <template v-if="mau">
                <!-- Party reference / title -->
                <div
                  v-if="crudContext === CrudContext.template || (localParty.name && !isLoadingCreateParty)"
                >
                  <template v-if="crudContext === CrudContext.template || ((!props.party?.uuid || isActiveEditName) && localParty.name && !isLoadingCreateParty)">
                    <label
                      class="font-medium"
                      for="party-name"
                    >
                      {{ $t('partyForm.title') }}
                      <span class="text-indigo-500">
                        *
                      </span>
                    </label>
                    <div
                      class="mt-1"
                    >
                      <input
                        id="party-name"
                        :data-cy-sel="`party-name-input`"
                        :value="name.value.value"
                        :placeholder="$t('partyForm.titlePlaceholder')"
                        type="text"
                        name="party-name"
                        class="input-plain"
                        :class="{ 'input-has-errors': errorsToShow?.name?.length }"
                        @input="($event) => updatePartyFieldValue('name', ($event.target as HTMLInputElement).value)"
                      >
                    </div>
                  </template>
                  <div
                    v-else-if="localParty.name && !isLoadingCreateParty"
                    class="flex items-center justify-between gap-2 py-1"
                  >
                    <span class="font-medium text-indigo-700">{{ name.value.value || $t('common.undefined') }}</span>
                    <button
                      v-if="!(crudContext === CrudContext.document && !localParty.entity_type)"
                      type="button"
                      class="px-1 py-0 text-xs text-indigo-500 shrink-0 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
                      @click="isActiveEditName = true"
                    >
                      {{ $t('partyForm.editReference') }}
                    </button>
                  </div>
                  <FormInputErrors
                    v-if="errorsToShow?.name?.length"
                    :errors="errorsToShow?.name"
                  />
                </div>
                <!-- Entity type -->
                <div v-if="!accountParty && party?.uuid">
                  <label
                    class="font-medium"
                    for="party-entity-type"
                  >
                    {{ $t('partyForm.entityType') }}
                    <span class="text-indigo-500">
                      *
                    </span>
                  </label>
                  <div class="mt-1">
                    <RadioGroupPills
                      :model-value="entity_type.value.value"
                      :options="entityTypeOptionsFiltered"
                      :sr-label="$t('partyForm.selectEntityType')"
                      :grid-class="`grid grid-cols-${entityTypeOptionsFiltered.length} gap-3`"
                      :disabled="!!(accountParty) || (localParty?.entity_type === PartyEntityType.business && relatedCount>1)"
                      :allow-null="true"
                      @update:model-value="($event) => handleChangeEntityType($event)"
                    />
                  </div>
                  <FormInputErrors
                    v-if="errorsToShow?.entity_type?.length"
                    :errors="errorsToShow?.entity_type"
                  />
                  <div
                    v-else-if="!!(accountParty) || (localParty?.entity_type === PartyEntityType.business && relatedCount>1)"
                    class="mt-1.5 flex items-center gap-1.5 text-xs text-gray-400"
                  >
                    <LockClosedIcon
                      class="w-3 h-3"
                      aria-hidden="true"
                    />
                    {{ $t('partyForm.entityTypeDisabledExplanation') }}
                  </div>
                </div>
                <!-- Editing options (when adding an external party) -->
                <div v-if="!accountParty?.uuid && !(crudContext === CrudContext.document && !localParty.entity_type)">
                  <label
                    class="font-medium"
                    for="is-changeable"
                  >
                    {{ $t('partyForm.editingOptions') }}
                  </label>

                  <div class="py-1">
                    <SwitchGroup
                      as="div"
                      class="flex items-start"
                    >
                      <Switch
                        v-model="partyExternalScope"
                        :class="[
                          partyExternalScope ? 'bg-indigo-600' : 'bg-gray-300',
                          'mt-1 relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow pointer-events-none ring-0"
                          :class="[ partyExternalScope ? 'translate-x-5' : 'translate-x-0' ]"
                        />
                      </Switch>
                      <SwitchLabel
                        as="span"
                        class="ml-2 text-xs"
                        passive
                      >
                        <p class="font-medium text-indigo-600">
                          {{ $t('partyForm.dataByExternal') }}
                        </p>
                        <span class="text-gray-500">
                          {{ $t('partyForm.dataByExternalDescription') }}
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <FormInputErrors
                      v-if="errorsToShow?.scope?.length"
                      :errors="errorsToShow?.scope"
                    />
                  </div>
                </div>
              </template>

              <!-- Add/Edit business (entity) party -->
              <div
                v-if="(localParty?.entity_type === PartyEntityType.business || relatedCount > 1) && !(crudContext === CrudContext.document && !localParty.entity_type)"
                class="space-y-3"
              >
                <div>
                  <label
                    class="font-medium"
                    for="party-entity-name"
                  >
                    {{ $t('partyForm.companyName') }}
                    <span
                      v-if="isMandatoryEntityName"
                      class="text-indigo-500"
                    >*</span>
                  </label>
                  <div class="mt-1">
                    <AccountPartyCombobox
                      :id="`party-entity-name`"
                      :data-cy-sel="`party-entity-name-input`"
                      :account-parties="accountParties"
                      :selected-party="selectedParty"
                      :is-disabled="isLockedDocument"
                      :allow-new-party="true"
                      @update:selected-party="handleUpdateSelectedParty"
                    />
                  </div>
                  <FormInputErrors
                    v-if="errorsToShow?.entity_name?.length"
                    :errors="errorsToShow?.entity_name"
                  />
                </div>
                <div v-if="!accountParty">
                  <label
                    class="mt-1 font-medium"
                    for="party-address"
                  >
                    {{ $t('partyForm.companyAddress') }}
                    <span
                      v-if="isMandatoryAddress"
                      class="text-indigo-500"
                    >*</span>
                  </label>
                  <div
                    class="mt-1 grow-textarea"
                    :data-replicated-value="address.value.value"
                  >
                    <textarea
                      :data-cy-sel="`party-address-input`"
                      :value="address.value.value"
                      :disabled="isLockedDocument"
                      autocomplete="party-address"
                      :placeholder="$t('partyForm.companyAddressPlaceholder') + '…'"
                      name="party-address"
                      :class="[errorsToShow?.address?.length ? 'input-has-errors' : '']"
                      class="input-plain"
                      @input="($event) => updatePartyFieldValue('address', ($event.target as HTMLTextAreaElement).value)"
                    />
                  </div>
                  <FormInputErrors
                    v-if="errorsToShow?.address?.length"
                    :errors="errorsToShow?.address"
                  />
                </div>
              </div>
              <div
                v-if="!relatedSignatory?.uuid && party?.uuid && localParty?.entity_type === PartyEntityType.person && !(crudContext === CrudContext.document && !localParty.entity_type)"
                class="h-full"
              >
                <div
                  v-if="props.party?.uuid && !props.party?.account_party_uuid && localParty?.entity_name"
                  class="mb-2"
                >
                  <label
                    class="font-medium"
                    for="party-entity-name"
                  >
                    {{ $t('wizard.partyNamePerson') }}
                    <span
                      v-if="isMandatoryEntityName"
                      class="text-indigo-500"
                    >*</span>
                  </label>
                  <div class="text-xs font-normal text-gray-500">
                    {{ !!mau ? $t('partyForm.companyNameWhenPersonHint') : $t('partyForm.companyNameWhenPersonHintExternal') }}
                  </div>
                  <div class="mt-1">
                    <input
                      id="party-entity-name"
                      :data-cy-sel="`party-entity-name-input`"
                      :value="entity_name.value.value"
                      :disabled="isLockedDocument"
                      autocomplete="party-entity-name"
                      :placeholder="$t('partyForm.companyNamePlaceholder') + '…'"
                      type="text"
                      name="party-entity-name"
                      class="input-plain"
                      :class="[errorsToShow?.entity_name?.length ? 'input-has-errors' : '']"
                      @input="($event) => updatePartyFieldValue('entity_name', ($event.target as HTMLInputElement).value)"
                    >
                  </div>
                </div>
                <label
                  class="font-medium"
                  for="party-assigned-user"
                >
                  {{ $t('partyForm.signatory') }}
                </label>
                <button
                  v-if="crudContext === CrudContext.template || (!isLockedDocument && currentDocument?.stage !== DocumentStage.signed && !!mdu && mdu?.permissions?.includes('document_user_create'))"
                  type="button"
                  class="flex items-center justify-center w-full h-full gap-1 p-2 mt-2.5 text-sm font-medium text-indigo-500 bg-indigo-100 rounded-md hover:bg-indigo-200 hover:cursor-pointer hover:text-indigo-600"
                  @click.prevent="showAddUserToPartyPopover"
                >
                  <PlusIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                  {{ $t('userSettings.addSignatory') }}
                </button>
                <div
                  v-else-if="mdu?.permissions?.includes('document_user_create')"
                  class="mt-2 text-xs texr-gray-500 flex items-center gap-1.5 text-gray-400"
                >
                  <LockClosedIcon
                    class="w-3 h-3"
                    aria-hidden="true"
                  />
                  {{ !!mau ? $t('userForm.signatoryDisabledExplanation') : $t('userForm.signatoryDisabledExplanationExternal') }}
                </div>
              </div>

              <!-- Add/Edit person (individual) party -->

              <div v-show="isVisibleUserForm && !(crudContext === CrudContext.document && !localParty.entity_type)">
                <SignatoryForm
                  ref="addSignatoryForm"
                  v-model:user="relatedSignatory"
                  class="grid grid-cols-1 gap-y-2"
                  :party-uuid="localParty.uuid"
                  :document="document"
                  :template="template"
                  :hide-party-settings="true"
                  :is-disabled="isLockedDocument || hasPreemptiveSignature"
                >
                  <template
                    v-if="checkIfRemovable(relatedSignatory?.uuid) && (mdu?.permissions?.includes('document_user_delete') || crudContext === CrudContext.template) && relatedSignatory?.uuid"
                    #deleteButton
                  >
                    <button
                      class="p-1 text-gray-400 rounded-full shrink-0 btn-plain hover:text-red-700 disabled:pointer-events-none disabled:opacity-50"
                      :disabled="(!!(relatedSignatory.uuid && deAssigningUserFromParty === relatedSignatory.uuid))"
                      :data-tippy-help="true"
                      :data-tippy-content="$t('userForm.removeSignatory')"
                      data-placement="top"
                      @click="deAssignSignatoryFromParty(relatedSignatory as UiUser)"
                    >
                      <UnlinkIcon
                        v-if="deAssigningUserFromParty !== relatedSignatory.uuid"
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                      <SpinLoader
                        v-else
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                    </button>
                  </template>
                </SignatoryForm>
                <div class="mt-2">
                  <label
                    class="font-medium text-gray-700"
                    for="party-address"
                  >
                    {{ $t('partyForm.address') }}
                    <span
                      v-if="isMandatoryAddress"
                      class="text-indigo-500"
                    >*</span>
                  </label>
                  <div
                    class="mt-1 grow-textarea"
                    :data-replicated-value="address.value.value"
                  >
                    <textarea
                      :value="address.value.value"
                      :disabled="isLockedDocument"
                      autocomplete="party-address"
                      :placeholder="$t('partyForm.addressPlaceholder') + '…'"
                      name="party-address"
                      :class="[errorsToShow?.address?.length ? 'input-has-errors' : '']"
                      class="input-plain"
                      @input="($event) => updatePartyFieldValue('address', ($event.target as HTMLTextAreaElement).value)"
                    />
                  </div>
                  <FormInputErrors
                    v-if="errorsToShow?.address?.length"
                    :errors="errorsToShow?.address"
                  />
                </div>
              </div>
            </template>
            <template v-if="activePartyPopoverTab.id === 'signatories'">
              <!-- Assigned users (only for business entities) -->
              <div v-if="(party?.entity_type === PartyEntityType.business || localParty?.entity_type === PartyEntityType.business || (!party?.entity_type && ((!localParty?.entity_type && !isLoadingCreateParty) || localParty?.entity_type === PartyEntityType.tbd)) || accountParty?.entity_type === PartyEntityType.business || relatedCount>1) && !(crudContext === CrudContext.document && !localParty.entity_type)">
                <label
                  v-if="crudContext === CrudContext.template"
                  class="font-medium"
                  for="party-assigned-users"
                >
                  {{ $t('partyForm.signatories') }}
                  <div class="text-xs font-normal text-gray-500">
                    {{ $t('partyForm.signatoriesDescription') }}
                  </div>
                </label>
                <label
                  v-else
                  class="font-medium"
                  for="party-assigned-users"
                >
                  {{ $t('partyForm.signatories') }}
                </label>
                <div class="mt-1 text-sm flex flex-col py-1.5">
                  <ul
                    v-if="signatories?.length"
                    class="mb-2"
                  >
                    <PartyPopoverSignatory
                      v-for="user, userIdx in signatories"
                      :key="userIdx"
                      :user="user"
                      :is-disabled="preemptiveSignatures?.some((el) => el.document_user_uuid === user.document_user_uuid)"
                      :is-updating-roles="isUpdatingRoles"
                      @update:roles="handleUpdateRoles($event, user as UiUser)"
                    >
                      <template
                        v-if="(!isLockedDocument && currentDocument?.stage !== DocumentStage.signed && !!mdu && mdu?.permissions?.includes('document_user_delete')) || crudContext === CrudContext.template"
                        #deleteButton
                      >
                        <button
                          class="p-1 text-sm text-gray-400 rounded-full shrink-0 hover:bg-red-200 hover:text-red-700 disabled:hover:bg-transparent disabled:hover:text-gray-400 disabled:cursor-not-allowed disabled:opacity-50"
                          :disabled="(!checkIfRemovable(user.document_user_uuid) || !!(user.uuid && deAssigningUserFromParty === user.uuid))"
                          @click="deAssignSignatoryFromParty(user as UiUser)"
                        >
                          <TrashIcon
                            v-if="deAssigningUserFromParty !== user.document_user_uuid"
                            class="w-3.5 h-3.5"
                          />
                          <SpinLoader
                            v-else
                            class="w-3.5 h-3.5"
                          />
                        </button>
                      </template>
                    </PartyPopoverSignatory>
                  </ul>
                  <div v-if="crudContext === CrudContext.template || (!isLockedDocument && currentDocument?.stage !== DocumentStage.signed && !!mdu && mdu?.permissions?.includes('document_user_create'))">
                    <button
                      :data-cy-sel="`add-signatory-button-popover`"
                      type="button"
                      class="flex items-center justify-center w-full h-full gap-1 p-2 text-sm font-medium text-indigo-500 bg-indigo-100 rounded-md hover:bg-indigo-200 hover:cursor-pointer hover:text-indigo-600"
                      @click.prevent="showAddUserToPartyPopover"
                    >
                      <PlusIcon
                        class="w-4 h-4 shrink-0"
                        aria-hidden="true"
                      />
                      {{ $t('userSettings.addSignatory') }}
                    </button>
                  </div>
                  <div
                    v-else-if="mdu?.permissions?.includes('document_user_create')"
                    class="mt-2 text-xs texr-gray-500 flex items-center gap-1.5 text-gray-400"
                  >
                    <LockClosedIcon
                      class="w-3 h-3"
                      aria-hidden="true"
                    />
                    {{ !!mau ? $t('userForm.signatoryDisabledExplanation') : $t('userForm.signatoryDisabledExplanationExternal') }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </component>
      </div>
      <div
        v-if="!showPreSelectionStep"
        class="flex items-center justify-between gap-2 p-3 bg-gray-100 border-t border-gray-200 rounded-b-md"
      >
        <button
          v-if="!party?.uuid && (addAccountPartyUuid || entity_type.value.value)"
          type="button"
          class="flex items-center gap-2 btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="() => { addAccountPartyUuid = null; updatePartyFieldValue('entity_type', null) }"
        >
          <ArrowLeftIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('partyForm.selectEntityType') }}
        </button>
        <span v-else />
        <div class="flex items-center justify-end gap-2">
          <template
            v-if="localParty?.uuid && !isLockedDocument && (crudContext === CrudContext.template || mdu?.permissions?.includes('party_delete'))"
          >
            <div
              ref="removeButton"
              :data-tippy-help="isLockedRemoveParty ? true : null"
              :data-tippy-content="isLockedRemoveParty ? isDirty ? $t('common.notDeletableWhileIsDirtyEditorContent') : $t('partyForm.partyNotDeletableWithSignatureBlocks') : null"
              data-placement="top"
              :class="isLockedRemoveParty ? 'hover:cursor-not-allowed' : ''"
            >
              <button
                type="button"
                :disabled="isLockedRemoveParty"
                class="flex items-center gap-2 mr-2 text-gray-400 btn-plain btn-sm hover:text-red-500 hover:bg-red-100 focus:ring-red-100"
                @click.prevent="removeParty(localParty?.uuid)"
              >
                <TrashIcon
                  v-if="partyUuidBeingRemovedFromEntity !== localParty?.uuid || userUuidBeingRemovedFromEntity !== relatedSignatory?.uuid"
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
                <SpinLoader
                  v-else
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
                <span>
                  {{ $t('common.remove') }}
                </span>
              </button>
            </div>
          </template>
          <button
            v-if="!localParty.uuid"
            :data-cy-sel="'party-popover-submit-button'"
            :disabled="isLoadingParty"
            type="button"
            class="inline-flex items-center gap-2 btn-primary"
            @click.prevent="createParty()"
          >
            <SpinLoader
              v-if="isLoadingParty"
              class="w-5 h-5 shrink-0"
            />
            <span>
              {{ isLoadingParty ? $t('common.saving') + '…' : $t('common.save') }}
            </span>
          </button>
          <span
            v-else-if="isLoadingParty"
            class="flex items-center justify-center gap-2 text-sm font-medium text-indigo-700 pointer-events-none btn-plain"
          >
            <SpinLoader class="w-5 h-5 shrink-0" />
            {{ $t('common.saving') }}…
          </span>
          <span
            v-else-if="lastSaved"
            class="flex items-center justify-center gap-2 text-sm font-medium text-gray-500 pointer-events-none btn-plain"
          >
            <CheckIcon
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
            {{ $t('common.lastSavedAt', {time:formatTime(lastSaved)}) }}
          </span>
          <span
            v-else
            class="flex items-center justify-center text-sm font-medium text-gray-500 truncate pointer-events-none btn-plain"
          >
            {{ $t('common.noUnsavedChanges') }}
          </span>
        </div>
      </div>
    </div>
    <div class="hidden">
      <div ref="addUserToPartyPopoverRef">
        <div
          class="min-w-[18rem] bg-gray-100 border-2 border-indigo-900 rounded-md divide-y divide-gray-100 shadow-lg ring ring-3 ring-indigo-500 ring-opacity-30"
        >
          <UserCombobox
            v-model:selected-user="selectedUser"
            align="bottom"
            :default-signatory="true"
            :scope="(addAccountPartyUuid && !party?.uuid) || party?.account_party_uuid ? 'internal' : 'external'"
            :is-account-party="!!((addAccountPartyUuid && !party?.uuid) || party?.account_party_uuid)"
            :deactivated-users="deactivatedUsers"
            :is-loading-add-user="isSubmittingAddUser"
            :party-uuid="localParty?.uuid || null"
          />
        </div>
      </div>
    </div>
  </div>
</template>
