<script setup lang="ts">
import { ref, watch } from "vue"

import { DocumentUser } from "~/types"
import { getUserRepresentation } from "~/utils"

interface Props {
  items: DocumentUser[]
  command: (...args: any[]) => any
}
const props = defineProps<Props>()

const selectedIndex = ref<number>(0)

watch(
  () => props.items,
  () => selectedIndex.value = 0,
  { immediate: true, deep: true },
)

const onKeyDown = ({ event }) => {
  const keyActionMap = {
    ArrowUp: upHandler,
    ArrowDown: downHandler,
    Enter: enterHandler,
  }

  const action = keyActionMap[event.key]

  action?.()

  return !!action
}

const upHandler = () => {
  selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length
}

const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length
}

const enterHandler = () => {
  selectItem(selectedIndex.value)
}

const selectItem = (index) => {
  const item = props.items[index]

  if (!item) return

  props.command({ mentionUuid: item.uuid, uuid: item.uuid, account_user: item.account_user, name: getUserRepresentation(item) })
}

defineExpose(
  {
    onKeyDown,
  },
)
</script>

<template>
  <div
    class="bg-white border-2 border-indigo-900 divide-y divide-gray-100 rounded-md shadow-lg max-w-max ring ring-3 ring-indigo-500 ring-opacity-30"
  >
    <div v-if="items?.length > 0">
      <a
        v-for="(item, index) in items"
        :key="index"
        class="flex items-center w-full px-2 py-1 text-left text-gray-900 cursor-pointer"
        :class="
          [index === selectedIndex
             ? 'text-indigo-50 bg-indigo-900 hover:text-indigo-50 hover:bg-indigo-900'
             : 'hover:text-black hover:bg-indigo-200',
           index === items.length - 1
             ? 'rounded-b-sm'
             : '']
        "
        @click="selectItem(index)"
      >
        <span class="flex-1">
          {{ getUserRepresentation(item) }}
        </span>
      </a>
    </div>
    <div
      v-else
      class="px-2 py-1 text-gray-500"
    >
      {{ $t('discussions.noUsersFound') }}.
    </div>
  </div>
</template>
