<script setup lang="ts">
// external
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import VueApexCharts from "vue3-apexcharts"

// internal
import { DashboardWidget, EmptyState } from "~/components"
import { locales } from "~/libs/vue3-apexcharts/locales"

interface Props {
  series: any[]
  type: string
  options?: any
  title?: string
  boxClasses?: string
  loading?: boolean
  endpoint?: string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    options: {},
    boxClasses: "p-2",
    loading: false,
    endpoint: null,
  },
)

const { locale } = useI18n()

const optionsToUse = computed(() => {

  const options = { ...props.options }

  if (!options.chart) {
    options.chart = {}
  }

  options.chart.locales = [ ...locales ]
  options.chart.defaultLocale = locale.value

  return options
})


const hasNoData = computed(() => {
  if (!props.series.length) return true

  let dataFound = false
  for (const seriesEntry of props.series) {
    if (seriesEntry?.data?.length) {
      const entriesWithData = seriesEntry.data.filter((entry) => {
        return entry !== 0
      })
      if (entriesWithData.length) {
        dataFound = true
      }
    } else {
      if (seriesEntry !== 0) {
        dataFound = true
      }
    }
  }

  return !dataFound
})

const emit = defineEmits([ "click", "loaded" ])

const clickHandler = (event, chartContext, config) => {
  emit("click", { event, chartContext, config })
}

const loadedHandler = (res) => {
  emit("loaded", res)

  // Manually set title attribute
  setTimeout(() => {
    const elements = document.querySelectorAll(".apexcharts-legend-series")
    elements.forEach(function (element) {
      const seriesName = element.textContent
      if (seriesName) {
        element.setAttribute("title", seriesName)
      }
    })
  }, 500)
}
</script>
<template>
  <DashboardWidget
    v-cy="`dashboard-graph-widget`"
    class="dashboard-graph-widget"
    :title="props.title"
    :box-classes="boxClasses"
    :endpoint="endpoint"
    @loaded="loadedHandler"
  >
    <div
      v-if="hasNoData"
      v-cy="`dashboard-graph-widget-no-data`"
      class="text-center flex-col flex items-center justify-center"
    >
      <slot name="empty">
        <EmptyState
          :hide-button="true"
        >
          {{ $t('dashboard.noData') }}
        </EmptyState>
      </slot>
    </div>
    <VueApexCharts
      v-else
      v-cy="`apexcharts-container`"
      class="w-full h-full"
      width="100%"
      height="100%"
      :type="type"
      :options="optionsToUse"
      :series="props.series"
      @click="clickHandler"
    />
  </DashboardWidget>
</template>
