<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"

import { MenuButton, Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import {
  ChevronDoubleRightIcon,
  CloudArrowDownIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  TrashIcon,
  ChatBubbleLeftEllipsisIcon,
  CloudArrowUpIcon,
  PencilIcon,
  XCircleIcon,
  CheckCircleIcon,
  Bars3BottomLeftIcon,
  ArrowUturnLeftIcon,
  DocumentMagnifyingGlassIcon,
  GlobeAltIcon,
  LinkIcon,
  CodeBracketIcon,
  EnvelopeOpenIcon,
  ShareIcon,
} from "@heroicons/vue/24/solid"
import {
  VariableIcon,
  IdentificationIcon,
  UserIcon,
  UserPlusIcon,
  CheckIcon,
  ArrowRightIcon,
} from "@heroicons/vue/20/solid"
import {
  ExclamationTriangleIcon,
  RssIcon,
  BellIcon,
  BellSlashIcon,
} from "@heroicons/vue/24/outline"

// internal
import { Dropdown, DropdownLink, SpinLoader, SkeletonLoader, OverlayScrollbar, EmptyState, TabPills } from "~/components"
import { useAttachmentStore, useDocumentStore, usePartyStore, useUserStore } from "~/stores"
import { DocumentRevision, DocumentStage, Activity, Party, Stage, PartyEntityType, UserNotification, DocumentUserRoleEnum, NotificationType, EmailLog, UserNotificationChannel, Attachment, ActivityTab, DocumentUser, AccountUser } from "~/types"
import { formatDateRelative, getUserRepresentation, formatDateAndTime } from "~/utils"
import { ImportIcon, SignatureIcon } from "~/icons"
import { difference } from "lodash-es"
import { useI18n } from "vue-i18n"
import { DocumentVisit } from "~/types"

interface Props {
  stages: Stage[]
}
withDefaults(
  defineProps<Props>(),
  {},
)

const emit = defineEmits([ "trigger-diff"  ])

const userStore = useUserStore()
const { users, userNotifications, allAccountUsers } = storeToRefs(userStore)

const documentStore = useDocumentStore()
const {
  currentDocument,
  revisions,
  activities,
  isLoadingActivities,
  isLoadingPdfDownload,
  mau,
  documentVisits,
} = storeToRefs(documentStore)
const { getPdfDownload, getHTML, setActiveActivityTab } = documentStore

const attachmentStore = useAttachmentStore()
const { attachments } = storeToRefs(attachmentStore)

const getAttachmentInfo = (attachmentUuid: Attachment["uuid"]) => attachments.value?.find((el) => el.uuid === attachmentUuid)

const { parties } = storeToRefs(usePartyStore())

const activitiesByRevision = computed<Activity[]>(() => {
  const groupedActivities: Activity[] = []

  if (!activities.value?.length) return groupedActivities

  // add an additional Activity before the first item
  if (activities.value?.length > 0) {
    const firstActivity = activities.value[0]
    const newActivity: Activity = {
      uuid: "",
      type: "document_revision_ended",
      document_revision_uuid: firstActivity.document_revision_uuid,
      document_revision_seal: firstActivity.document_revision_seal,
      created_at: firstActivity.created_at,
      historic_created_by_name: null,
      historic_model_name: null,
    }
    groupedActivities.push(newActivity)
  }
  // Order documentVisits by created_at and only keep the first occurence of each created_by_document_user_uuid
  const uniqDocumentVisits = []
  const copyOfDocumentVisitsOrderedByCreatedAt = [ ...documentVisits.value ]
  for (let i = 0; i < copyOfDocumentVisitsOrderedByCreatedAt?.length; i++) {
    if (!uniqDocumentVisits.find((el) => el.created_by_document_user_uuid === documentVisits.value[i].created_by_document_user_uuid)) {
      uniqDocumentVisits.push(documentVisits.value[i])
    }
  }

  for (let i = 0; i < activities.value.length; i++) {

    const currentActivity = activities.value[i]
    const nextActivity = activities.value[i + 1]

    const visitsToPush = uniqDocumentVisits.filter((el) => new Date(el.created_at).getTime() > new Date(activities.value[i].created_at).getTime())
    // If there is a uniqueDocumentVisits element with a later created_at timestamp than the current activity, add it to the groupedActivities
    if (visitsToPush.length > 0) {
      // Iterate through visitsToPush
      for (let j = 0; j < visitsToPush.length; j++) {
        const visit = visitsToPush[j]
        const documentUser = users.value?.find((el) => el.uuid === visit.created_by_document_user_uuid)
        const documentUserName = documentUser ? getUserRepresentation(documentUser) : ""
        if (documentUserName) {
          const activityToPush = {
            uuid: "",
            type: "document_user_visited",
            document_revision_uuid: currentActivity.document_revision_uuid,
            document_revision_seal: currentActivity.document_revision_seal,
            created_at: visit.created_at,
            historic_created_by_name: documentUserName,
            historic_model_name: null,
          }
          groupedActivities.push(activityToPush)
        }
        // Remove the visit from the uniqDocumentVisits array
        uniqDocumentVisits.splice(uniqDocumentVisits.findIndex((el) => el.uuid === visit.uuid), 1)
      }
    }

    groupedActivities.push(currentActivity)

    if (nextActivity && currentActivity.document_revision_uuid !== nextActivity.document_revision_uuid) {
      const newActivity: Activity = {
        uuid: "",
        type: "document_revision_ended",
        document_revision_uuid: nextActivity.document_revision_uuid,
        document_revision_seal: nextActivity.document_revision_seal,
        created_at: nextActivity.created_at,
        historic_created_by_name: null,
        historic_model_name: null,
      }
      groupedActivities.push(newActivity)
    }
  }
  return groupedActivities
})

const colors = computed(() => {

  const colorArray = []

  const colorHex = [
    "#06b6d4",
    "#3b82f6",
    "#8b5cf6",
    "#d946ef",
    "#f43f5e",
    "#f59e0b",
    "#84cc16",
    "#10b981",
    "#06b6d4",
  ]
  if (revisions.value?.length) {
    revisions.value.forEach((revision: DocumentRevision, revisionIdx: number) => {
      let selectedColor = 0
      if ((revisionIdx + 1) > colorHex.length) {
        selectedColor = (revisionIdx + 1) % colorHex.length
      } else {
        selectedColor = revisionIdx
      }
      colorArray.push({
        revision_uuid: revision.uuid,
        color: colorHex[selectedColor],
      })
    })
  }

  return colorArray
})

const filterColor = (revisionUuid: DocumentRevision["uuid"]) => {
  const check = colors.value.find((el) => el.revision_uuid === revisionUuid)
  return check?.color
}

// Trigger function for diff view
const triggerDiff = (revisionUuid: DocumentRevision["uuid"]) => {
  emit("trigger-diff", { revisionUuid })
}

const getPartyByUuid = (uuid: Party["uuid"]) => parties.value?.find((el) => el.uuid === uuid)

interface NotificationDetail {
  userRepresentation: string
  notification_type: NotificationType
  message: string
  emailLog: EmailLog
  channels: UserNotificationChannel[]
}

const getNotificationDetails = (userNotificationUuid: UserNotification["uuid"]): NotificationDetail => {
  const notification = userNotifications.value?.find((el) => el.uuid === userNotificationUuid)
  let user:AccountUser|DocumentUser = users.value?.find((el) => el.uuid === notification?.document_user_uuid || el.account_user?.uuid === notification?.account_user_uuid)

  if (!user) {
    user = allAccountUsers.value?.find((au) => au.uuid === notification?.account_user_uuid)
  }

  const notificationDetailToReturn = {
    userRepresentation: user ? getUserRepresentation(user) : "",
    notification_type: notification?.notification_type,
    message: notification?.message || "",
    emailLog: notification?.email_log,
    channels: notification?.channels,
  }
  return notificationDetailToReturn
}

const isDev = import.meta.env.DEV && !!mau

const uuidsOfIsVisibleMessage = ref<Activity["uuid"][]>([])

const handleShowMessage = (activityItemUuid: Activity["uuid"]) => {
  if (uuidsOfIsVisibleMessage.value.includes(activityItemUuid)) {
    uuidsOfIsVisibleMessage.value.splice(uuidsOfIsVisibleMessage.value.indexOf(activityItemUuid), 1)
  } else {
    uuidsOfIsVisibleMessage.value.push(activityItemUuid)
  }
}

const { t } = useI18n()

const groupAccessByUser = ref<boolean>(false)

const accessList = computed(() => {
  let listToReturn = []
  if (documentVisits.value?.length) {
    documentVisits.value.forEach((visit: DocumentVisit) => {
      let user: Partial<DocumentUser> = users.value?.find((el) => el.uuid === visit.created_by_document_user_uuid)
      if (!user) {
        user = {
          uuid: visit.created_by_account_user?.uuid,
          profile_photo_url: visit.created_by_account_user?.profile_photo_url,
          first_name: visit.created_by_account_user?.first_name,
          last_name: visit.created_by_account_user?.last_name,
        }
      }
      const userName = user ? getUserRepresentation(user) : "n/a"
      const formattedDate = formatDateAndTime(visit.created_at)
      listToReturn.push({
        userUuid: user.uuid,
        formattedDate: formattedDate,
        userRepresentation: userName,
        profilePicture: user.profile_photo_url,
      })
    })
  }
  listToReturn = listToReturn.slice().reverse()
  if (groupAccessByUser.value) {
    const groupedAccessList = []
    const uniqueUserUuids = []
    listToReturn.forEach((el) => {
      if (!uniqueUserUuids.includes(el.userUuid)) {
        uniqueUserUuids.push(el.userUuid)
        const userVisits = listToReturn.filter((visit) => visit.userUuid === el.userUuid)
        groupedAccessList.push({
          userUuid: el.userUuid,
          visits: userVisits,
          userRepresentation: el.userRepresentation,
          profilePicture: el.profilePicture,
        })
      }
    })
    return groupedAccessList
  }
  return listToReturn
})

const activityTabs = computed(() => {
  return     [
    { id: ActivityTab.activityLog, name: t("activity.tabs." + ActivityTab.activityLog) },
    { id: ActivityTab.accessLog, name: t("activity.tabs." + ActivityTab.accessLog) },
  ]
})

const activeActivityTab = computed(
  {
    get: () => {
      if (documentStore.activeActivityTab) return activityTabs.value?.find((tab) => tab.id === documentStore.activeActivityTab)
      const tabToReturn = activityTabs.value[0]
      return tabToReturn
    },
    set: (val) => {
      setActiveActivityTab(val?.id || null)
    },
  },
)

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 shrink-0">
      <h3
        class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('activity.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('activity.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingActivities"
      class="p-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>
    <template v-else>
      <div
        v-if="!!mau"
        class="px-6 pb-4 border-b shrink-0 border-b-gray-200"
      >
        <TabPills
          v-model:active-tab="activeActivityTab"
          :tabs="activityTabs"
        />
      </div>
      <OverlayScrollbar
        ref="activityListScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <template v-if="activeActivityTab.id === ActivityTab.activityLog">
          <div
            v-if="activities?.length"
            class="flow-root p-4 pt-3"
          >
            <ul
              role="list"
              class="-mb-8"
            >
              <li
                v-for="(activityItem, activityItemIdx) in activitiesByRevision"
                :key="activityItem.uuid"
                class="list-item"
              >
                <div class="relative pb-2">
                  <span
                    v-if="activityItemIdx + 1 < activitiesByRevision.length"
                    class="absolute w-6 h-full top-3 left-1 opacity-20"
                    :style="
                      'background-color: ' + filterColor(activityItem.document_revision_uuid)
                    "
                    aria-hidden="true"
                  />
                  <div
                    v-if="activityItem.type === 'document_revision_ended'"
                    class="flex items-center gap-2"
                  >
                    <div
                      class="cursor-pointer rounded-full ml-1 pl-1.5 pr-0.5 py-0.5 text-xs font-medium text-white relative inline-flex items-center gap-1"
                      :style="
                        'background-color: ' + filterColor(activityItem.document_revision_uuid)
                      "
                    >
                      <button
                        type="button"
                        :class="!!mau ? 'cursor-pointer' : 'cursor-default'"
                        @click.prevent="triggerDiff(activityItem.document_revision_uuid)"
                      >
                        <span class="font-normal">
                          {{ $t('activity.revision') }}:
                        </span>
                        {{ `${activityItem.document_revision_seal ? '#'+activityItem.document_revision_seal : $t('activity.currentRevision')}` }}
                      </button>
                      <dropdown
                        menu-classes="text-left z-20"
                        width="52"
                        align="default"
                      >
                        <template #trigger>
                          <MenuButton
                            class="p-1 text-xs rounded-full hover:bg-white hover:bg-opacity-20"
                          >
                            <EllipsisHorizontalIcon class="w-3 h-3" />
                          </MenuButton>
                        </template>

                        <template #content>
                          <DropdownLink
                            as="button.prevent"
                            :icon="true"
                            @click="triggerDiff(activityItem.document_revision_uuid)"
                          >
                            <DocumentMagnifyingGlassIcon
                              class="w-4 h-4 mr-2 shrink-0"
                              aria-hidden="true"
                            />
                            <span>{{ $t('documents.showRevision') }}</span>
                          </DropdownLink>
                          <DropdownLink
                            as="button.prevent"
                            :icon="true"
                            @click="getPdfDownload(currentDocument.uuid, activityItem.document_revision_uuid)"
                          >
                            <CloudArrowDownIcon
                              v-if="!isLoadingPdfDownload[activityItem.document_revision_uuid]"
                              class="w-4 h-4 mr-2 shrink-0"
                              aria-hidden="true"
                            />
                            <SpinLoader
                              v-else
                              class="w-4 h-4 mr-2"
                            />
                            <span v-if="!isLoadingPdfDownload[activityItem.document_revision_uuid]">{{ $t('documents.downloadPdf') }}</span>
                            <span v-else>{{ $t('documents.generatingPdf') }}…</span>
                          </DropdownLink>

                          <DropdownLink
                            v-if="isDev"
                            as="button.prevent"
                            :icon="true"
                            @click="getHTML(activityItem.document_revision_uuid)"
                          >
                            <CodeBracketIcon
                              class="w-4 h-4 mr-2 shrink-0"
                              aria-hidden="true"
                            />

                            <span> {{ $t('documents.showHTML') }} (dev only)</span>
                          </DropdownLink>
                        </template>
                      </dropdown>
                    </div>
                  </div>
                  <div
                    v-else
                    class="relative flex items-start space-x-3 rounded-md"
                  >
                    <div class="relative px-1 top-1">
                      <div
                        class="flex items-center justify-center w-6 h-6 text-white rounded-full"
                        :style="
                          'background-color: ' + filterColor(activityItem.document_revision_uuid)
                        "
                      >
                        <StarIcon
                          v-if="activityItem.type.startsWith('document_created')"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <ImportIcon
                          v-if="activityItem.type === 'imported_signed_pdf'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <FlagIcon
                          v-else-if="
                            activityItem.type === 'document_stage_changed' &&
                              (activityItem.document_stage === DocumentStage.approved_review ||
                                activityItem.document_stage === DocumentStage.approved_draft)
                          "
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <LinkIcon
                          v-else-if="['attachment_created', 'attachment_deleted', 'attachment_updated'].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <ChevronDoubleRightIcon
                          v-else-if="activityItem.type === 'document_stage_changed'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <EnvelopeIcon
                          v-else-if="['user_notified' ].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <EyeIcon
                          v-else-if="activityItem.type === 'document_user_visited'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <UserIcon
                          v-else-if="activityItem.type === 'document_user_changed'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <GlobeAltIcon
                          v-else-if="activityItem.type === 'document_changed'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <TrashIcon
                          v-else-if="activityItem.type === 'document_user_deleted'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <ChatBubbleLeftEllipsisIcon
                          v-else-if="['comment_created', 'comment_changed', 'comment_resolved'].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <XCircleIcon
                          v-else-if="['proposal_rejected'].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <CheckCircleIcon
                          v-else-if="['proposal_accepted'].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <Bars3BottomLeftIcon
                          v-else-if="['proposal_created'].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <UserPlusIcon
                          v-else-if="activityItem.type === 'document_user_created'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <IdentificationIcon
                          v-else-if="['party_created', 'party_changed', 'party_deleted' ].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <VariableIcon
                          v-else-if="['dynamic_field_created', 'dynamic_field_changed' ].includes(activityItem.type)"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <EyeSlashIcon
                          v-else-if="activityItem.type === 'working_copy_created'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <ShareIcon
                          v-else-if="activityItem.type === 'working_copy_published'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <SignatureIcon
                          v-else-if="activityItem.type === 'signature_block_signed' || activityItem.type === 'signing_phase_created'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <CloudArrowUpIcon
                          v-else-if="activityItem.type === 'document_pdf_changed'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <PencilIcon
                          v-else-if="activityItem.type === 'document_prosemirror_data_changed'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                        <ArrowUturnLeftIcon
                          v-else-if="activityItem.type === 'signing_phase_reverted'"
                          class="w-3 h-3 text-current"
                          aria-hidden="true"
                        />
                      </div>

                      <span
                        class="absolute -bottom-1 -right-1 bg-gray-100 rounded-tl px-0.5 py-px"
                      />
                    </div>
                    <div class="flex-1 min-w-0 pb-1 text-xs">
                      <div>
                        <span
                          class="text-gray-400 cursor-pointer"
                          data-tippy-help
                          :data-tippy-content="formatDateAndTime(activityItem.created_at)"
                          data-placement="bottom"
                          data-tippy-apend-to="body"
                        >
                          {{ formatDateRelative(activityItem.created_at) }}
                        </span>
                        <p class="text-gray-900">
                          <span
                            v-if="
                              activityItem.type === 'document_stage_changed' &&
                                activityItem.document_stage === DocumentStage.approved_draft
                            "
                          >
                            {{ $t('activity.checkpointBeforeFinished', {stage: $t('documents.stages.' + DocumentStage.review)}) }}
                          </span>
                          <span
                            v-else-if="
                              activityItem.type === 'document_stage_changed' &&
                                activityItem.document_stage === DocumentStage.approved_review
                            "
                          >
                            {{ $t('activity.checkpointBeforeFinished', {stage: $t('documents.stages.' + DocumentStage.signing)}) }}
                          </span>
                          <span v-else-if="activityItem.type === 'document_stage_changed'">
                            {{ $t('activity.stageChangedTo') }}:
                            <span class="font-medium">
                              {{ $t('documents.stages.' + activityItem.document_stage) }}
                            </span>
                          </span>
                          <span v-else>
                            <span
                              v-if="activityItem.historic_created_by_name && activityItem.type !== 'user_notified'"
                              class="mr-1 font-medium"
                            >
                              {{ activityItem.historic_created_by_name }}
                            </span>
                            <span
                              v-if="activityItem.type"
                            >
                              {{ $t('activity.actions.' + activityItem.type) }}.
                            </span>
                          </span>
                        </p>
                      </div>
                      <div
                        v-if="activityItem.document_activity_details?.length > 0 || ['document_user_deleted', 'user_notified', 'document_user_created', 'document_user_changed', 'document_user_deleted', 'party_created', 'party_changed', 'party_deleted', 'document_changed' ].includes(activityItem.type)"
                        class="mt-1 text-gray-500 bg-white border border-gray-200 rounded-md"
                      >
                        <div
                          v-if="['party_created', 'party_changed', 'party_deleted' ].includes(activityItem.type)"
                          class="px-2 py-1 text-xs truncate border-b whitespace-nowrap border-b-gray-200"
                        >
                          <span class="font-medium text-yellow-700">
                            {{ activityItem.historic_model_name }}
                          </span>
                        </div>
                        <div
                          v-else-if="['user_notified'].includes(activityItem.type)"
                          class="relative py-1 pl-10 pr-2 text-xs truncate whitespace-nowrap"
                        >
                          <div class="absolute flex items-center justify-center w-6 h-6 -mt-3.5 text-gray-300 top-1/2 left-2">
                            <EnvelopeOpenIcon
                              v-if="getNotificationDetails(activityItem.document_activityable_uuid)?.emailLog?.delivery_status === 'open' && getNotificationDetails(activityItem.document_activityable_uuid)?.channels?.includes(UserNotificationChannel.mail)"
                              class="w-5.5 h-5.5"
                              aria-hidden="true"
                            />
                            <EnvelopeIcon
                              v-else-if="getNotificationDetails(activityItem.document_activityable_uuid)?.channels?.includes(UserNotificationChannel.mail)"
                              class="w-5.5 h-5.5"
                              aria-hidden="true"
                            />
                            <BellIcon
                              v-else-if="getNotificationDetails(activityItem.document_activityable_uuid)?.channels?.includes(UserNotificationChannel.database)"
                              class="w-5.5 h-5.5"
                              aria-hidden="true"
                            />
                            <BellSlashIcon
                              v-else
                              class="w-5.5 h-5.5"
                              aria-hidden="true"
                            />
                            <template v-if="getNotificationDetails(activityItem.document_activityable_uuid)?.channels?.includes(UserNotificationChannel.mail)">
                              <div
                                v-if="getNotificationDetails(activityItem.document_activityable_uuid)?.emailLog?.delivery_status === 'pending'"
                                class="absolute flex items-center p-0.5 text-gray-500 -right-1 -bottom-1.5 cursor-pointer"
                                data-tippy-help
                                :data-tippy-content="$t('documentNotifications.mailLog.pending')"
                                data-placement="bottom"
                                data-tippy-append-to="body"
                              >
                                <CheckIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                              </div>
                              <div
                                v-else-if="getNotificationDetails(activityItem.document_activityable_uuid)?.emailLog?.delivery_status === 'delivery'"
                                class="absolute flex items-center p-0.5 -space-x-2 text-gray-500 -right-1 -bottom-1.5 cursor-pointer"
                                data-tippy-help
                                :data-tippy-content="$t('documentNotifications.mailLog.delivery')"
                                data-placement="bottom"
                                data-tippy-append-to="body"
                              >
                                <CheckIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                                <CheckIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                              </div>
                              <div
                                v-else-if="getNotificationDetails(activityItem.document_activityable_uuid)?.emailLog?.delivery_status === 'open'"
                                class="absolute flex items-center p-0.5 -space-x-2 text-indigo-500 -right-1 -bottom-1.5 cursor-pointer"
                                data-tippy-help
                                :data-tippy-content="$t('documentNotifications.mailLog.open')"
                                data-placement="bottom"
                                data-tippy-append-to="body"
                              >
                                <CheckIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                                <CheckIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                              </div>
                              <div
                                v-else-if="['bounce', 'complaint'].includes(getNotificationDetails(activityItem.document_activityable_uuid)?.emailLog?.delivery_status)"
                                class="absolute flex items-center p-0.5 text-red-500 -right-1 -bottom-1.5 cursor-pointer"
                                data-tippy-help
                                :data-tippy-content="$t('documentNotifications.mailLog.error')"
                                data-placement="bottom"
                                data-tippy-append-to="body"
                              >
                                <ExclamationTriangleIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                              </div>
                              <div
                                v-else
                                class="absolute flex items-center p-0.5 text-gray-500 -right-1 -bottom-1.5 cursor-pointer"
                                data-tippy-help
                                :data-tippy-content="$t('documentNotifications.mailLog.processing')"
                                data-placement="bottom"
                                data-tippy-append-to="body"
                              >
                                <EllipsisHorizontalIcon
                                  class="w-3 h-3"
                                  aria-hidden="true"
                                />
                              </div>
                            </template>
                          </div>
                          <span class="font-medium text-indigo-700">
                            {{ getNotificationDetails(activityItem.document_activityable_uuid)?.userRepresentation }}
                          </span>
                          <div
                            class="whitespace-pre-line"
                          >
                            {{ $t(`documentNotifications.triggers.${getNotificationDetails(activityItem.document_activityable_uuid)?.notification_type}`) }}
                          </div>
                        </div>
                        <div
                          v-else-if="['document_user_deleted'].includes(activityItem.type)"
                          class="px-2 py-1 text-xs truncate whitespace-nowrap"
                        >
                          <span class="font-medium text-indigo-700">
                            {{ activityItem.historic_model_name }}
                          </span>
                        </div>
                        <div
                          v-else-if="['document_user_created', 'document_user_changed'].includes(activityItem.type)"
                          class="px-2 py-1 text-xs truncate border-b whitespace-nowrap border-b-gray-200"
                        >
                          <span class="font-medium text-indigo-700">
                            {{ activityItem.historic_model_name }}
                          </span>
                        </div>
                        <div
                          v-else-if="['dynamic_field_created', 'dynamic_field_changed' ].includes(activityItem.type)"
                          class="px-2 py-1 text-xs truncate border-b whitespace-nowrap border-b-gray-200"
                        >
                          <span class="font-medium text-indigo-700">
                            {{ activityItem.historic_model_name }}
                          </span>
                        </div>
                        <ul
                          v-if="activityItem.document_activity_details?.length > 0"
                          class="flex flex-col gap-1 p-2 text-xs"
                        >
                          <li
                            v-for="(
                              changeItem, changeItemIdx
                            ) in activityItem.document_activity_details?.filter((el) => {
                              return !['is_signatory'].includes(el.field)
                            })"
                            :key="changeItemIdx"
                          >
                            <div
                              v-if="['document_user_created', 'document_user_changed', 'document_changed' ].includes(activityItem.type)"
                              class="flex flex-col"
                            >
                              <span
                                v-if="
                                  !['party_uuid', 'roles', 'is_signatory'].includes(changeItem.field)
                                "
                                class="flex items-center gap-1 shrink-0"
                              >
                                <span v-if="changeItem.field === 'locale'">{{ $t('profileSettings.general.language') }}: </span>
                                <span v-if="changeItem.field === 'title'">{{ $t('userForm.title') }}: </span>
                                <span v-if="changeItem.field === 'first_name'">{{ $t('userForm.firstName') }}: </span>
                                <span v-if="changeItem.field === 'last_name'">{{ $t('userForm.lastName') }}: </span>
                                <span v-if="changeItem.field === 'email'">{{ $t('userForm.email') }}: </span>
                                <span
                                  v-if="changeItem.old_value"
                                  class="text-gray-900 truncate"
                                >{{ changeItem.old_value }}</span>
                                <span
                                  v-else-if="!['document_user_created'].includes(activityItem.type)"
                                  class="text-gray-400"
                                >{{ $t('common.empty') }}</span>
                                <ArrowRightIcon
                                  v-if="!['document_user_created'].includes(activityItem.type)"
                                  class="w-3 h-3 text-gray-900"
                                />
                                <span
                                  v-if="changeItem.new_value"
                                  class="text-gray-900 truncate"
                                >{{ changeItem.new_value }}</span>
                                <span
                                  v-else
                                  class="text-gray-400"
                                >{{ $t('common.empty') }}</span>
                              </span>
                              <span
                                v-else-if="changeItem.field === 'party_uuid'"
                                class="flex items-center gap-1 shrink-0"
                              >
                                <span>
                                  {{ $t('userForm.party') }}:
                                </span>
                                <span
                                  v-if="changeItem.old_value && !['document_user_created'].includes(activityItem.type)"
                                  class="text-yellow-700 truncate"
                                >
                                  {{
                                    getPartyByUuid(changeItem.old_value)?.entity_name
                                      ? getPartyByUuid(changeItem.old_value)?.entity_name
                                      : getPartyByUuid(changeItem.old_value)?.name
                                  }}
                                </span>
                                <span
                                  v-else-if="!['document_user_created'].includes(activityItem.type)"
                                  class="text-gray-400"
                                >{{ $t('common.empty') }}</span>
                                <ArrowRightIcon
                                  v-if="!['document_user_created'].includes(activityItem.type)"
                                  class="w-3 h-3 text-gray-900"
                                />
                                <span class="text-yellow-700 truncate">
                                  {{
                                    getPartyByUuid(changeItem.new_value)?.entity_name
                                      ? getPartyByUuid(changeItem.new_value)?.entity_name
                                      : getPartyByUuid(changeItem.new_value)?.name
                                  }}
                                </span>
                              </span>
                              <span
                                v-else-if="changeItem.field === 'roles'"
                                class="flex flex-col items-start gap-1"
                              >
                                <span v-if="changeItem.field === 'roles' && difference(changeItem.new_value ? JSON.parse(changeItem.new_value) : [], changeItem.old_value ? JSON.parse(changeItem.old_value) : [])?.includes(DocumentUserRoleEnum.collaborator)">
                                  {{ $t('activity.setAsCollaborator') }}
                                </span>
                                <span v-if="changeItem.field === 'roles' && difference(changeItem.new_value ? JSON.parse(changeItem.new_value) : [], changeItem.old_value ? JSON.parse(changeItem.old_value) : [])?.includes(DocumentUserRoleEnum.signatory)">
                                  {{ $t('activity.setAsSignatory') }}
                                </span>
                                <span v-if="changeItem.field === 'roles' && difference(changeItem.new_value ? JSON.parse(changeItem.new_value) : [], changeItem.old_value ? JSON.parse(changeItem.old_value) : [])?.includes(DocumentUserRoleEnum.owner)">
                                  {{ $t('activity.setAsOwner') }}
                                </span>
                                <span v-if="changeItem.field === 'roles' && difference(changeItem.old_value ? JSON.parse(changeItem.old_value) : [], changeItem.new_value ? JSON.parse(changeItem.new_value) : [])?.includes(DocumentUserRoleEnum.collaborator)">
                                  {{ $t('activity.unsetAsCollaborator') }}
                                </span>
                                <span v-if="changeItem.field === 'roles' && difference(changeItem.old_value ? JSON.parse(changeItem.old_value) : [], changeItem.new_value ? JSON.parse(changeItem.new_value) : [])?.includes(DocumentUserRoleEnum.signatory)">
                                  {{ $t('activity.unsetAsSignatory') }}
                                </span>
                                <span v-if="changeItem.field === 'roles' && difference(changeItem.old_value ? JSON.parse(changeItem.old_value) : [], changeItem.new_value ? JSON.parse(changeItem.new_value) : [])?.includes(DocumentUserRoleEnum.owner)">
                                  {{ $t('activity.unsetAsOwner') }}
                                </span>
                                <span v-if="changeItem.field === 'roles' && !(changeItem.new_value ? JSON.parse(changeItem.new_value) : [])?.length">
                                  {{ $t('activity.addedAsCCUser') }}
                                </span>
                              </span>
                            </div>
                            <div
                              v-else-if="['dynamic_field_created', 'dynamic_field_changed' ].includes(activityItem.type)"
                              class="flex flex-col"
                            >
                              <span v-if="changeItem.field === 'format'">
                                {{ $t('activity.formatChanged') }}
                              </span>
                              <span
                                v-else
                                class="flex items-center gap-1 shrink-0"
                              >
                                <span
                                  v-if="changeItem.field === 'name' ||
                                    changeItem.field === 'type' ||
                                    changeItem.field === 'scope' ||
                                    changeItem.field === 'value' ||
                                    changeItem.field === 'select_values' ||
                                    changeItem.field === 'is_mandatory' ||
                                    changeItem.field === 'question' ||
                                    changeItem.field === 'question_external'"
                                >{{ $t('activity.fields.' + changeItem.field) }} </span>
                                <span
                                  v-if="changeItem.old_value"
                                  class="text-gray-900 truncate"
                                >{{ changeItem.old_value }}</span>
                                <span
                                  v-else-if="activityItem.type !== 'dynamic_field_created'"
                                  class="text-gray-400"
                                >{{ $t('common.empty') }}</span>
                                <ArrowRightIcon
                                  v-if="activityItem.type !== 'dynamic_field_created'"
                                  class="w-3 h-3 text-gray-900"
                                />
                                <span
                                  v-if="changeItem.new_value"
                                  class="text-gray-900 truncate"
                                >{{ changeItem.new_value }}</span>
                                <span
                                  v-else
                                  class="text-gray-400"
                                >{{ $t('common.empty') }}</span>
                              </span>
                            </div>
                            <div
                              v-else-if="['party_created', 'party_changed' ].includes(activityItem.type)"
                              class="flex flex-col"
                            >
                              <span
                                v-if="!['entity_type', 'scope' ].includes(changeItem.field)"
                                class="flex items-center gap-1 shrink-0"
                              >
                                <span v-if="changeItem.field === 'address'">
                                  {{ $t('activity.fields.address') }}
                                </span>
                                <span v-if="changeItem.field === 'name'">
                                  {{ $t('activity.fields.reference') }}
                                </span>
                                <span v-if="changeItem.field === 'entity_name'">
                                  {{ $t('activity.fields.name') }}
                                </span>
                                <span v-if="changeItem.field === 'entity_type'">
                                  {{ $t('activity.fields.entity_type') }}
                                </span>
                                <span
                                  v-if="changeItem.old_value"
                                  class="text-gray-900 truncate"
                                >
                                  {{ changeItem.old_value }}
                                </span>
                                <span
                                  v-else
                                  class="text-gray-400"
                                >
                                  {{ $t('common.empty') }}
                                </span>
                                <ArrowRightIcon class="w-3 h-3 text-gray-900" />
                                <span class="text-gray-900 truncate">
                                  {{ changeItem.new_value }}
                                </span>
                              </span>
                              <span v-else>
                                <span
                                  v-if="changeItem.new_value === 'internal'"
                                  class="flex items-center gap-1 shrink-0"
                                >
                                  <span>
                                    {{ $t('activity.editing') }}
                                  </span>
                                  <span class="text-gray-900">
                                    {{ $t('activity.onlyInternally') }}
                                  </span>
                                </span>
                                <span
                                  v-else-if="changeItem.new_value === 'internal_and_external'"
                                  class="flex items-center gap-1 shrink-0"
                                >
                                  <span>
                                    {{ $t('activity.editing') }}
                                  </span>
                                  <span class="text-gray-900">
                                    {{ $t('activity.internallyExternally') }}
                                  </span>
                                </span>
                                <span
                                  v-else-if="changeItem.new_value === PartyEntityType.business"
                                  class="flex items-center gap-1 shrink-0"
                                >
                                  <span>
                                    {{ $t('activity.fields.type') }}
                                  </span>
                                  <span class="text-gray-900">
                                    {{ $t('userSettings.business') }}
                                  </span>
                                </span>
                                <span
                                  v-else
                                  class="flex items-center gap-1 shrink-0"
                                >
                                  <span>
                                    {{ $t('activity.fields.type') }}
                                  </span>
                                  <span class="text-gray-900">
                                    {{ $t('userSettings.person') }}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </li>
                        </ul>
                        <div
                          v-if="['user_notified'].includes(activityItem.type) && getNotificationDetails(activityItem.document_activityable_uuid)?.message"
                          class="relative p-2 border-t cursor-pointer rounded-b-md border-t-gray-200 hover:bg-gray-100"
                          @click="handleShowMessage(activityItem.uuid)"
                        >
                          <span
                            class="pr-8 whitespace-pre-line"
                            :class="uuidsOfIsVisibleMessage.includes(activityItem.uuid) ? 'line-clamp-none' : 'line-clamp-2'"
                          >
                            {{ getNotificationDetails(activityItem.document_activityable_uuid)?.message }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-else-if="activityItem.type.startsWith('attachment') && getAttachmentInfo(activityItem.document_activityable_uuid)"
                        class="mt-1 text-gray-500 bg-gray-100 rounded-md"
                      >
                        <div
                          class="px-2 py-1 text-xs font-medium text-indigo-700"
                        >
                          {{ getAttachmentInfo(activityItem.document_activityable_uuid)?.filename }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <RssIcon
                class="w-12 h-12 mx-auto text-gray-400"
                aria-hidden="true"
              />
            </template>
            {{ $t('activity.empty') }}
          </EmptyState>
        </template>
        <template v-else-if="activeActivityTab.id === ActivityTab.accessLog">
          <div class="border-b bg-gray-50 border-b-gray-200">
            <SwitchGroup
              as="div"
              class="flex items-center gap-4 px-6 py-3"
            >
              <Switch
                v-model="groupAccessByUser"
                :class="[
                  groupAccessByUser
                    ? 'bg-indigo-600'
                    : 'bg-gray-300',
                  'relative inline-flex shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    groupAccessByUser
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                />
              </Switch>
              <SwitchLabel
                as="span"
                class="text-sm text-gray-400 grow"
                passive
              >
                <span class="flex items-center gap-1 text-sm font-semibold text-gray-700">
                  {{ $t('activity.groupAccessByUser') }}
                </span>
              </SwitchLabel>
            </SwitchGroup>
          </div>
          <div
            v-if="accessList?.length > 0 && groupAccessByUser"
            class="text-xs divide-y divide-gray-200"
          >
            <div
              v-for="access, accessIdx in accessList"
              :key="accessIdx"
              class="flex items-start py-2.5 px-6"
            >
              <div class="flex items-center gap-2">
                <img
                  :src="access.profilePicture"
                  class="w-4 h-4 rounded-full"
                >
                <span class="font-medium">
                  {{ access.userRepresentation }}
                </span>
              </div>
              <div
                class="flex flex-col items-end flex-grow gap-2"
              >
                <span
                  v-for="visit, visitIdx in access.visits"
                  :key="visitIdx"
                >{{ visit.formattedDate }}</span>
              </div>
            </div>
          </div>
          <div
            v-else-if="accessList?.length"
            class="text-xs divide-y divide-gray-200"
          >
            <div
              v-for="access, accessIdx in accessList"
              :key="accessIdx"
              class="flex flex-col px-6 py-2.5"
            >
              <div class="flex items-center gap-2">
                <img
                  :src="access.profilePicture"
                  class="w-4 h-4 rounded-full"
                >
                <span
                  class="font-medium truncate"
                  data-tippy-help
                  :data-tippy-content="access.userRepresentation"
                  data-placement="bottom"
                  data-tippy-apend-to="body"
                >
                  {{ access.userRepresentation }}
                </span>
                <span class="flex-grow text-right">
                  {{ access.formattedDate }}
                </span>
              </div>
            </div>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <EyeIcon
                class="w-12 h-12 mx-auto text-gray-400"
                aria-hidden="true"
              />
            </template>
            {{ $t('activity.emptyAccess') }}
          </EmptyState>
        </template>
      </OverlayScrollbar>
    </template>
  </div>
</template>
