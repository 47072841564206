<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref, watch, toRaw, computed } from "vue"
import { useAccountStore } from "~/stores"
import { validateDateViaDayJs } from "~/utils"

interface Props {
  id?: string
  modelValue?: string
  label?: string
  showDay: boolean
  showMonth: boolean
  showYear: boolean
  style?: string
  width?: string
  disabled?: boolean
  lastSaved?: number
  hasError?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    id: null,
    modelValue: "",
    label: null,
    showDay: true,
    showMonth: true,
    showYear: true,
    style: "gray",
    width: "full",
    disabled: false,
    lastSaved: null,
    hasError: false,
  },
)

const emit = defineEmits([ "update:model-value", "submit-enter", "focus", "blur" ])

const validationError = ref<string>()

const validateDay = (input: string) => {
  if (!(/^(0?[1-9]|[1-2][0-9]|3[0-1])$/.test(input))) {
    validationError.value =  "Invalid day"
    return false
  }
  validationError.value = ""
  return true
}
const validateMonth = (input: string) => {
  if (!(/^(0?[1-9]|1[0-2])$/.test(input))) {
    validationError.value =  "Invalid month"
    return false
  }
  validationError.value = ""
  return true
}
const validateYear = (input: string) => {
  if (!(/^\d{4}$/.test(input))) {
    validationError.value =  "Invalid year"
    return false
  }
  validationError.value = ""
  return true
}

const modelValueHelper = computed<string>(() => props.modelValue)
watch(modelValueHelper, (newVal) => {
  if (newVal && newVal !== "null") {
    if (props.modelValue && Number.isNaN(Date.parse(props.modelValue))) return
    year.value = toRaw(props.modelValue)?.substring(0, 4) || ""
    month.value = toRaw(props.modelValue)?.substring(5, 7) || ""
    day.value = toRaw(props.modelValue)?.substring(8, 10) || ""
  } else {
    year.value = ""
    month.value = ""
    day.value = ""
  }
})

const localYear = ref<string>(toRaw(props.modelValue)?.substring(0, 4) || "")
const localMonth = ref<string>(toRaw(props.modelValue)?.substring(5, 7) || "")
const localDay = ref<string>(toRaw(props.modelValue)?.substring(8, 10) || "")

const year = computed<string>({
  get: () => { return localYear.value || "" },
  set: (val) => { localYear.value = val },
})
const month = computed<string>({
  get: () => { return localMonth.value || "" },
  set: (val) => { localMonth.value = val },
})
const day = computed<string>({
  get: () => { return localDay.value || "" },
  set: (val) => { localDay.value = val },
})
const yearInput = ref<HTMLInputElement>()
const monthInput = ref<HTMLInputElement>()
const dayInput = ref<HTMLInputElement>()

const lastSaved = computed<number>(() => props.lastSaved)

watch(lastSaved, (newVal) => {
  if (!newVal) return
  if (props.modelValue && Number.isNaN(Date.parse(props.modelValue))) return
  year.value = toRaw(props.modelValue)?.substring(0, 4) || ""
  month.value = toRaw(props.modelValue)?.substring(5, 7) || ""
  day.value = toRaw(props.modelValue)?.substring(8, 10) || ""
})

watch(year, (newVal: string, oldVal: string) => {
  if (parseInt(newVal) > 9999) year.value = oldVal
})

const updateDay = () => {
  if (day.value === undefined || day.value === "" || (parseInt(day.value, 10) < 4 && dayInput.value.value.toString().length < 2)) return
  if (!validateDay(day.value)) return
  if (props.showMonth && locale.value === "de") monthInput.value.select()
  else if (props.showYear) yearInput.value.select()
  else if (props.showMonth) monthInput.value.select()
}

const updateMonth = () => {
  if (month.value === undefined || month.value === "" || (parseInt(month.value, 10) < 2 && monthInput.value.value.toString().length < 2)) return
  if (!validateMonth(month.value)) return
  if (props.showYear && locale.value === "de") yearInput.value.select()
  else if (props.showDay) dayInput.value.select()
  else if (props.showYear) yearInput.value.select()
}

const updateDayOnBackspace = (e: KeyboardEvent) => {
  if (locale.value === "de") return
  if ((e?.key === "Backspace" || e?.key === "Delete") && day.value.toString() === "") {
    if (props.showMonth) monthInput.value.select()
  }
}

const updateMonthOnBackSpace = (e: KeyboardEvent) => {
  if (locale.value === "en") return
  if ((e?.key === "Backspace" || e?.key === "Delete") && month.value.toString() === "") {
    if (props.showDay) dayInput.value.select()
  }
}

const updateYearOnBackSpace = (e: KeyboardEvent) => {
  if ((e?.key === "Backspace" || e?.key === "Delete") && year.value.toString() === "") {
    if (props.showMonth && locale.value === "de") monthInput.value.select()
    else if (props.showDay && locale.value === "en") dayInput.value.select()
  }
}

const handleSubmitEnter = (e: KeyboardEvent) => {
  if (e?.key === "Enter") {
    const timestamp = Date.parse(`${year.value.toString().padStart(4, "0")}-${month.value}-${day.value}`)
    if (Number.isNaN(timestamp)) {
      validationError.value =  "Invalid date"
      return
    }
    emit("submit-enter")
  }
}

const handleTab = (e: KeyboardEvent, field: string) => {
  // Prevent default, unless we are tabbing forward from year or backward from day/month depending on locale
  if (
    !(field === "year" && !e.shiftKey) &&
    !(field === "day" && locale.value === "de" && e.shiftKey) &&
    !(field === "month" && locale.value === "en" && e.shiftKey) ) {
    e.preventDefault()
  }
  if (field === "day") {
    if (locale.value === "de") {
      if (!e.shiftKey) monthInput.value.focus()
    } else {
      if (e.shiftKey) monthInput.value.focus()
      else yearInput.value.focus()
    }
  } else if (field === "month") {
    if (locale.value === "de") {
      if (e.shiftKey) dayInput.value.focus()
      else yearInput.value.focus()
    } else {
      if (!e.shiftKey) dayInput.value.focus()
    }
  } else if (field === "year") {
    if (locale.value === "de") {
      if (e.shiftKey) monthInput.value.focus()
    } else {
      if (e.shiftKey) dayInput.value.focus()
    }
  }
}

const updateValue = (e: KeyboardEvent = null) => {
  if (e?.key === "Tab" || e?.key === "Shift") return
  if (!year.value && !month.value && !day.value) {
    emit("update:model-value", null)
  }
  if (!year.value || yearInput.value.value.toString().length < 4) return
  const validationString = `${year.value.toString().padStart(4, "0")}-${month.value}-${day.value}`
  const timestamp = Date.parse(validationString)
  if (!validateYear(year.value)) return
  if (Number.isNaN(timestamp)) return
  const isValidDayJsDate = validateDateViaDayJs(validationString)
  if (!isValidDayJsDate) return
  const modelValueString = `${year.value ? year.value.toString().padStart(4, "0") : "0000"}-${month.value}-${day.value}`
  // Check regex for YYYY-MM-DD or YYYY-M-D
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(modelValueString)) return
  emit("update:model-value", modelValueString)
}

const handleFocus = () => {
  emit("focus", {
    target: { id: props.id },
  })
}

const handleBlurPadStart = (field: string) => {
  emit("blur", {
    target: { id: props.id },
  })
  if (field === "year" && year.value) year.value = year.value.toString().padStart(4, "0")
  if (field === "month" && month.value) month.value = month.value.toString().padStart(2, "0")
  if (field === "day" && day.value) day.value = day.value.toString().padStart(2, "0")
}

const reset = () => {
  year.value = ""
  month.value = ""
  day.value = ""
  updateValue()
}

const setValue = (date: string) => {
  year.value = date.substring(0, 4) || ""
  yearInput.value.value = year.value
  month.value = date.substring(5, 7) || ""
  day.value = date.substring(8, 10) || ""
  updateValue()
}

const accountStore = useAccountStore()
const { mau, account } = storeToRefs(accountStore)

const locale = computed(() => {
  const localeToReturn =  (mau.value?.user?.locale || account.value?.locale)
  return localeToReturn
})

defineExpose({ reset, setValue })

</script>

<template>
  <div class="font-normal">
    <label
      v-if="props.label"
      class="text-xs"
    >{{ props.label }}</label>
    <div
      :id="props.id"
      class="text-sm text-left rounded-md"
      :class="
        [
          {
            'focus-within:ring-red-500' : props.hasError,
            'hover:bg-gray-100 focus-within:bg-gray-100 focus-within:ring-0' : !props.disabled && !['gray', 'slate'].includes(props.style),
            'text-gray-400 bg-gray-50 cursor-not-allowed' : props.disabled && props.style === 'gray',
            'text-slate-400 bg-slate-900 cursor-not-allowed' : props.disabled && props.style === 'slate',
            'bg-gray-100' : !props.disabled && props.style === 'gray',
            'bg-slate-900' : !props.disabled && props.style === 'slate',
            'bg-white border-gray-300 border py-0.5 px-0' : props.style === 'white',
            'p-0' : ['plain', 'slate'].includes(props.style),
          },
          ['gray', 'slate'].includes(props.style) ?
            'focus-within:ring-2 focus-within:ring-indigo-500 p-0 overflow-hidden'
            :
            'p-0 overflow-hidden',
          props.width === 'full' ?
            'grid grid-cols-12' : 'flex items-center']
      "
      @keyup.capture="updateValue($event)"
    >
      <input
        v-if="showDay"
        ref="dayInput"
        v-model="day"
        size="2"
        maxlength="2"
        :data-tabindex="locale === 'de' ? 1 : 2"
        :class="
          [
            locale === 'de' ? 'order-1' : 'order-3',
            props.width === 'full' ?
              '-mr-1.5 col-span-3':'w-12 -mx-1',
            props.style === 'slate' ? 'py-2' : props.style === 'plain' ? 'py-1' : 'py-1.5',
            props.style === 'slate' ? 'placeholder-slate-500' : 'placeholder-gray-400',
            'text-center px-0 outline-none border-none focus:ring-0 ring-0  text-base sm:text-sm bg-transparent disabled:cursor-not-allowed'
          ]
        "
        type="text"
        placeholder="dd"
        :disabled="disabled"
        @keyup="updateDayOnBackspace"
        @keydown.tab="handleTab($event, 'day')"
        @input="updateDay"
        @focus="handleFocus"
        @blur="handleBlurPadStart('day')"
      >
      <span
        v-if="showDay && showMonth"
        class="z-0 flex items-center justify-center order-2 h-full text-base text-gray-300 pointer-events-none select-none"
      >/</span>
      <input
        v-if="showMonth"
        ref="monthInput"
        v-model="month"
        size="2"
        maxlength="2"
        :data-tabindex="locale === 'de' ? 2 : 1"
        :class="
          [
            locale === 'de' ? 'order-3' : 'order-1',
            props.width === 'full' ?
              '-mr-1.5 col-span-3':'w-12 -mx-1',
            ['plain', 'slate'].includes(props.style) ? 'py-1' : 'py-1.5',
            props.style === 'slate' ? 'placeholder-slate-500' : 'placeholder-gray-400',
            'text-center px-0 outline-none border-none focus:ring-0 ring-0 text-base sm:text-sm bg-transparent disabled:cursor-not-allowed'
          ]
        "
        type="text"
        placeholder="mm"
        :disabled="disabled"
        @keyup="updateMonthOnBackSpace"
        @keydown.tab="handleTab($event, 'month')"
        @input="updateMonth"
        @focus="handleFocus"
        @blur="handleBlurPadStart('month')"
      >
      <span
        v-if="showYear && (showDay || showMonth)"
        class="z-0 flex items-center justify-center order-4 h-full text-base text-gray-300 pointer-events-none select-none"
      >/</span>
      <input
        v-if="showYear"
        ref="yearInput"
        v-model="year"
        size="4"
        maxlength="4"
        :class="
          [
            props.width === 'full' ?
              '-mr-1.5 col-span-3':'w-16 -mx-1',
            ['plain', 'slate'].includes(props.style) ? 'py-1' : 'py-1.5',
            props.style === 'slate' ? 'placeholder-slate-500' : 'placeholder-gray-400',
            'order-5 text-center px-0 outline-none border-none focus:ring-0 ring-0 text-base sm:text-sm bg-transparent disabled:cursor-not-allowed'
          ]
        "
        type="text"
        placeholder="yyyy"
        :disabled="disabled"
        @keyup="updateYearOnBackSpace"
        @keyup.enter="handleSubmitEnter"
        @keydown.tab="handleTab($event, 'year')"
        @blur="handleBlurPadStart('year')"
        @focus="handleFocus"
      >
    </div>
    <span
      v-if="validationError"
      class="text-xs text-red-500"
    >
      {{ validationError }}
    </span>
  </div>
</template>
