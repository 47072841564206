<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { PlusIcon } from "@heroicons/vue/20/solid"
import { EnvelopeIcon, UserIcon } from "@heroicons/vue/24/outline"
import { ArrowRightOnRectangleIcon, CheckIcon, EyeIcon, HomeIcon } from "@heroicons/vue/24/solid"
import { sortBy } from "lodash-es"

// internal
import { PartyEntityTypeDisplayIcon, ResendInvitationButton, SpinLoader } from "~/components"
import { useDocumentStore, usePartyStore, usePusherStore, useSharedStore, useUserStore } from "~/stores"
import { CrudContext, DocumentStage, DocumentUser, DocumentUserRoleEnum, DocumentVisit, Party, PartyEntityType, Template } from "~/types"
import { documentUserInvitationStatuses, formatAddress, formatDateAndTime, formatDateRelative, getUserRepresentation } from "~/utils"
import { SignatureIcon } from "~/icons"

interface Props {
  template?: Template
  isConfirmModal?: boolean
  hidePartyHeader?: boolean
  isWizard?: boolean
  addUserPlacement?: string
}

withDefaults(
  defineProps<Props>(),
  {
    template: null,
    isConfirmModal: false,
    hidePartyHeader: false,
    isWizard: false,
    addUserPlacement: null,
  },
)

defineEmits([ "add-user" ])

const documentStore = useDocumentStore()
const { currentDocument, mau, mdu, isLockedDocument, documentVisits } = storeToRefs(documentStore)

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const userStore = useUserStore()
const { users, uuidsOfIsLoadingSendInvitation } = storeToRefs(userStore)
const { getUserNotifications, sendUserInvitation } = userStore

const partyStore = usePartyStore()
const {
  parties,
} = storeToRefs(partyStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const getAssignedUsers = (partyUuid: Party["uuid"]) => {
  const relevantUsers = users.value?.length ? [ ...users.value.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory)) ] : []
  const filteredByPartyUsers = relevantUsers.filter((el) => el.party_uuid === partyUuid)
  const orderedBySigningOrderUsers = sortBy(filteredByPartyUsers, "signing_order")
  return orderedBySigningOrderUsers
}

const checkIfDocumentUserCanEditParty = (party: Party) => {
  return !checkIfDisabledByPusher("editPartyButton_" + party.ref_uuid) &&
    !isLockedDocument.value && (crudContext.value === CrudContext.template || mdu.value?.permissions?.includes("party_update")) &&
    (!!mau.value || party.uuid === mdu.value?.party_uuid)
}

const checkIfDocumentUserCanEditUser = (user: DocumentUser) => {
  return (crudContext.value === CrudContext.template || mdu.value?.permissions?.includes("document_user_update")) && (!!mau.value || mdu.value?.party_uuid === user.party_uuid) && !isLockedDocument.value && !checkIfDisabledByPusher("editSignatoryButton_" + user.uuid)
}

const checkIfAddUserButtonIsShown = (party: Party) => {
  if (isLockedDocument.value) return false
  if (currentDocument.value?.stage === DocumentStage.signed) return false
  if (currentDocument.value?.archived_at) return false
  return (party.entity_type === PartyEntityType.business || !getAssignedUsers(party.uuid)?.length) &&
    (crudContext.value === CrudContext.template || mdu.value?.permissions?.includes("document_user_create")) &&
    (!!mau.value || party.uuid === mdu.value?.party_uuid)
}

const signatoriesWithoutParty = computed(() => {
  return users.value?.filter((user) => {
    const partyUuids = parties.value.map((party) => party.uuid)
    const isSignatory = user.roles?.includes(DocumentUserRoleEnum.signatory)
    return (!user.party_uuid || !partyUuids.some((partyUuid) => partyUuid === user.party_uuid)) && isSignatory
  })
})

const isInSigningStage = computed(() => {
  return currentDocument.value?.stage === DocumentStage.signing
})

const getDocumentVisits = (userUuid: DocumentUser["uuid"]) => {
  return documentVisits.value?.filter((el: DocumentVisit) => el.created_by_document_user_uuid === userUuid)
}

</script>

<template>
  <ul
    v-cy="isConfirmModal ? `user-list-modal` : `user-list`"
    role="list"
    class="grid grid-cols-1 divide-y divide-y-gray-200"
  >
    <li
      v-for="party in parties.slice().reverse()"
      :key="party.uuid"
      class="relative text-sm last:mb-6"
    >
      <component
        :is="!isLockedDocument ? 'button' : 'div'"
        v-if="!hidePartyHeader"
        :id="!isLockedDocument ? 'editPartyButton_' + party.ref_uuid : ''"
        :data-placement="!isConfirmModal ? 'left' : 'right'"
        :data-template="!isLockedDocument ? 'partyForm_' + party.ref_uuid : ''"
        class="relative w-full px-6 py-4 text-left group focus:ring-0 focus:outline-none"
        :class="[
          'edit-party-button_' + party.ref_uuid,
          checkIfDocumentUserCanEditParty(party)
            ? 'edit-party-button cursor-pointer hover:bg-gray-100 focus:bg-gray-100'
            : 'pointer-events-none',
          checkIfDisabledByPusher('editPartyButton_' + party.ref_uuid)
            ? 'cursor-not-allowed opacity-50 pointer-events-auto'
            : '',
        ]"
      >
        <div>
          <h3 class="text-indigo-600 font-medium uppercase text-xs tracking-wide rounded-full p-0.5 flex justify-between items-center">
            <div class="p-1 flex-none rounded-full text-indigo-100 bg-indigo-500 mr-1.5 grow-0">
              <PartyEntityTypeDisplayIcon
                :party="party"
                class="w-3 h-3"
              />
            </div>
            <span class="truncate grow">
              {{ party.name }}
            </span>
            <span
              v-if="party.is_ready_for_signing"
              class="whitespace-nowrap inline-flex gap-1 normal-case tracking-normal font-normal items-center px-1 py-0.5 rounded-md bg-green-600 text-white text-xs"
            >
              <CheckIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('userSettings.readyForSigning') }}
            </span>
            <span
              v-else-if="party.account_party_uuid && !!(mau)"
              class="inline-flex items-center gap-1 text-xs font-normal tracking-normal text-gray-500 normal-case whitespace-nowrap"
            >
              <HomeIcon
                class="shrink-0 w-3.5 h-3.5"
              />
              {{ $t('partyForm.accountPartyLabel') }}
            </span>
            <span
              v-else-if="!!(mau)"
              class="inline-flex items-center gap-1 text-xs font-normal tracking-normal text-gray-500 normal-case whitespace-nowrap"
            >
              <ArrowRightOnRectangleIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('userSettings.externalParty') }}
            </span>
          </h3>
          <template
            v-if="(party.entity_type === PartyEntityType.business || getAssignedUsers(party.uuid)?.length > 1)"
          >
            <div
              class="text-sm"
            >
              <div>
                <p class="font-medium text-gray-900 overflow-hyphens">
                  {{ party.entity_name || '[ Name ]' }}
                </p>
                <p class="text-xs font-normal text-gray-500">
                  {{ party.address ? formatAddress(party.address) : $t('userSettings.addressPlaceholder') }}
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="getAssignedUsers(party.uuid)[0]?.uuid">
            <div
              class="flex items-center max-w-full min-w-0 space-x-3 text-gray-900 overflow-hyphens"
            >
              <div class="relative shrink-0">
                <img
                  class="w-8 h-8 rounded-full"
                  :src="getAssignedUsers(party.uuid)[0]?.profile_photo_url"
                  alt=""
                >
                <div class="absolute -bottom-1 -right-1 shrink-0 rounded-md bg-white group-hover:bg-gray-50 flex items-center p-0.5">
                  <SignatureIcon
                    class="text-gray-700 w-3.5 h-3.5"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div class="min-w-0 grow">
                <h3 class="truncate">
                  {{ getUserRepresentation(getAssignedUsers(party.uuid)[0]) }}
                </h3>
                <div
                  class="text-xs text-gray-500 truncate"
                  :title="getAssignedUsers(party.uuid)[0]?.email"
                >
                  {{ getAssignedUsers(party.uuid)[0]?.email }}
                </div>
                <div
                  class="text-xs text-gray-500 line-clamp-3 overflow-hyphens"
                >
                  {{ party.address ? formatAddress(party.address) : $t('userSettings.addressPlaceholder') }}
                </div>
                <template v-if="(!!mau || (mdu?.uuid !== getAssignedUsers(party.uuid)[0]?.uuid)) && !isConfirmModal && crudContext === CrudContext.document && isInSigningStage">
                  <div
                    v-if="getUserNotifications(getAssignedUsers(party.uuid)[0], documentUserInvitationStatuses)?.length"
                    class="flex items-center gap-1 pointer-events-auto"
                  >
                    <div class="text-xs text-gray-500">
                      <div>
                        {{ $t('documentSharingModal.sent') }}
                        {{
                          formatDateRelative(
                            getUserNotifications(getAssignedUsers(party.uuid)[0], documentUserInvitationStatuses)?.[
                              getUserNotifications(getAssignedUsers(party.uuid)[0], documentUserInvitationStatuses)?.length-1
                            ]?.created_at)
                        }}
                      </div>
                    </div>
                    <template v-if="mdu?.uuid && mdu?.permissions?.includes('document_user_invitation_manage')">
                      <ResendInvitationButton :user="getAssignedUsers(party.uuid)[0]" />
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="party.entity_name"
              class="flex items-center max-w-full min-w-0 space-x-3 text-gray-900 overflow-hyphens"
            >
              <div class="min-w-0 grow">
                <h3 class="truncate">
                  {{ party.entity_name }}
                </h3>
                <div
                  class="text-xs text-gray-500 line-clamp-3 overflow-hyphens"
                >
                  {{ party.address || "[ Address ]" }}
                </div>
              </div>
            </div>
            <div>
              <div
                v-if="checkIfAddUserButtonIsShown(party)"
                class="inline-flex items-center mt-2 font-medium text-gray-500 group-hover:text-gray-900"
              >
                <span class="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full">
                  <PlusIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </span>
                <span class="ml-3">{{ $t('userSettings.addSignatory') }}</span>
              </div>
              <div
                v-else-if="!party.entity_name"
                :class="['py-2 -mx-3 px-3 inline-flex items-center text-gray-500 group-hover:text-gray-900']"
              >
                <span
                  class="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full"
                >
                  <UserIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </span>
                <span class="ml-3 text-gray-400">{{ $t('userSettings.noSignatoryAdded') }}</span>
              </div>
            </div>
          </template>
        </div>
      </component>
      <ul
        v-if="party.entity_type === PartyEntityType.business || isWizard || getAssignedUsers(party.uuid)?.length > 1"
        role="list"
      >
        <li
          v-for="user in getAssignedUsers(party.uuid)"
          :id="!isLockedDocument ? 'editSignatoryButton_' + user.uuid : ''"
          :key="user.uuid"
          :data-placement="!isConfirmModal ? 'left' : 'right'"
          :data-template="!isLockedDocument ? 'signatoryForm_' + user.uuid : ''"
          :class="[
            'edit-signatory-button_' + user.uuid,
            isWizard ? 'mx-3 rounded-md' : '',
            checkIfDisabledByPusher('editSignatoryButton_' + user.uuid)
              ? 'cursor-not-allowed opacity-50 pointer-events-none'
              : checkIfDocumentUserCanEditUser(user) ? 'hover:bg-gray-100 cursor-pointer edit-signatory-button' : 'pointer-events-none',
          ]"
          class="relative group"
        >
          <div
            class="flex items-center w-full py-2 space-x-3"
            :class="isWizard ? 'px-3' : 'px-6'"
          >
            <div class="relative shrink-0">
              <img
                class="w-8 h-8 rounded-full"
                :src="user?.profile_photo_url"
                alt=""
              >
              <div class="absolute -bottom-1 -right-1 shrink-0 rounded-md bg-white group-hover:bg-gray-50 flex items-center p-0.5">
                <SignatureIcon
                  class="text-gray-700 w-3.5 h-3.5"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div class="grow">
              <h3 class="break-all line-clamp-1">
                {{ getUserRepresentation(user) }}
              </h3>

              <div
                v-if="!user.account_user?.uuid"
                class="text-xs text-gray-500 break-all"
              >
                {{ user.email }}
              </div>
              <div
                v-else
                class="text-xs text-gray-500"
              >
                {{ $t('userSettings.internalUser') }}
              </div>

              <template v-if="(!!mau || (mdu?.uuid !== user.uuid)) && !isConfirmModal && crudContext === CrudContext.document && isInSigningStage">
                <div
                  v-if="getUserNotifications(user, documentUserInvitationStatuses)?.length"
                  class="flex items-center gap-1 pointer-events-auto"
                >
                  <div class="text-xs text-gray-500">
                    <div>
                      {{ $t('documentSharingModal.sent') }}
                      {{
                        formatDateRelative(
                          getUserNotifications(user, documentUserInvitationStatuses)?.[
                            getUserNotifications(user, documentUserInvitationStatuses)?.length-1
                          ]?.created_at)
                      }}
                    </div>
                  </div>
                  <div
                    v-if="getDocumentVisits(user.uuid)?.length"
                    class="ml-1 text-xs text-gray-500"
                    data-tippy-help
                    data-placement="top"
                    :data-tippy-content="$t('documentSharingModal.lastOpenedAt', { date: formatDateAndTime(getDocumentVisits(user.uuid)?.[0]?.created_at), count: getDocumentVisits(user.uuid)?.length })"
                  >
                    <EyeIcon
                      class="w-3 h-3"
                      aria-hidden="true"
                    />
                  </div>
                  <template v-if="mdu?.uuid && mdu?.permissions?.includes('document_user_invitation_manage')">
                    <ResendInvitationButton :user="user" />
                  </template>
                </div>
                <div
                  v-else-if="!user?.account_user?.uuid"
                  class="-ml-1 pointer-events-auto"
                >
                  <button
                    v-if="!uuidsOfIsLoadingSendInvitation.includes(user.uuid)"
                    type="button"
                    class="flex items-center gap-1 px-1 py-0 text-xs text-indigo-500 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
                    @click="sendUserInvitation(currentDocument?.uuid, user.uuid)"
                  >
                    <EnvelopeIcon class="w-3 h-3" />
                    {{ $t('documentSharingModal.sendInvitation') }}
                  </button>
                  <SpinLoader
                    v-else
                    class="w-3 h-3 text-gray-400"
                  />
                </div>
              </template>
            </div>
          </div>
        </li>
        <div
          v-if="checkIfAddUserButtonIsShown(party)"
          class="flex items-center px-3 py-1"
        >
          <button
            data-cy-sel="userlist-add-signatory-button"
            type="button"
            class="add-signatory-button btn-plain border-0 hover:bg-gray-100 flex items-center py-1.5 px-3"
            :data-placement="
              addUserPlacement ? addUserPlacement :
              !isConfirmModal ? 'left' : 'bottom'
            "
            data-template="addSignatoryForm"
            @click.prevent="$emit('add-user',{ partyUuid: party.uuid })"
          >
            <span class="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full">
              <PlusIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
            </span>
            <span class="ml-3">{{ $t('userSettings.addSignatory') }}</span>
          </button>
        </div>
      </ul>
    </li>

    <ul
      v-if="signatoriesWithoutParty?.length > 0 && !!mau"
      role="list"
      :class="!isConfirmModal ? 'mt-4' : ''"
      class="pt-1 pb-2 mx-6 mb-4 rounded-md border-t-white pt bg-gray-50"
    >
      <h3 class="pt-3 pl-6 text-gray-600 font-medium uppercase text-xs tracking-wide rounded-full p-0.5 pr-2 inline-flex justify-start items-center shrink-0">
        <div class="p-1 rounded-full text-gray-100 bg-gray-400 ml-0.5 mr-1.5">
          <SignatureIcon class="w-3 h-3" />
        </div>
        <span>
          {{ $t('userSettings.noPartyAssigned') }}
        </span>
      </h3>
      <li
        v-for="user in signatoriesWithoutParty"
        :id="'editSignatoryButton_' + user.uuid"
        :key="user.uuid"
        :data-placement="
          !isConfirmModal ? 'left' : 'bottom'
        "
        :data-template="'signatoryForm_' + user.uuid"
        :class="[
          'editSignatoryButton_' + user.uuid,
          isWizard ? 'mx-3 rounded-md' : '',
          checkIfDisabledByPusher('editSignatoryButton_' + user.uuid)
            ? 'bg-gray-50 cursor-not-allowed opacity-50 pointer-events-none'
            : checkIfDocumentUserCanEditUser(user) ? 'hover:bg-gray-100 cursor-pointer' : 'pointer-events-none',
        ]"
        class="relative text-sm group edit-signatory-button"
      >
        <div
          class="flex items-center w-full py-2 space-x-3"
          :class="isWizard ? 'px-3' : 'px-6'"
        >
          <img
            class="w-8 h-8 rounded-full shrink-0"
            :src="user?.profile_photo_url"
            alt=""
          >
          <div class="truncate grow">
            <h3 class="truncate">
              {{ getUserRepresentation(user) }}
            </h3>
          </div>
        </div>
      </li>
    </ul>
  </ul>
</template>
