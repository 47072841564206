<script setup lang="ts">
// external
import { storeToRefs } from "pinia"

// internal
import { usecases } from "~/utils"
import { OnboardingStepContainer, OnboardingUsecaseSelector } from "~/components"
import { Configure } from "~/lottie"
import { useOnboardingStore } from "~/stores"
import { Vue3Lottie } from "vue3-lottie"

const onboardingStore = useOnboardingStore()
const {
  selectedUsecaseIds,
  contractTypes,
} = storeToRefs(onboardingStore)

</script>
<template>
  <OnboardingStepContainer
    v-cy="`onboarding-step-usecases`"
    :limit-height="true"
  >
    <template #default>
      <div class="relative -mx-4 -my-4 space-y-2">
        <OnboardingUsecaseSelector
          v-for="usecase in usecases"
          :key="usecase.id"
          v-model:selected-usecase-ids="selectedUsecaseIds"
          v-model:selected-contract-types="contractTypes"
          :usecase-id="usecase.id"
          :collapsible="false"
        />
      </div>
    </template>
    <template #aside>
      <div class="flex flex-col items-center justify-center w-full h-full">
        <div class="relative w-full">
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="block w-full h-full m-12 bg-gray-100 rounded-full max-w-32 max-h-32" />
          </div>
          <Vue3Lottie
            :animation-data="Configure"
            class="max-w-72"
            aria-hidden="true"
          />
        </div>
        <div class="w-full mt-6 text-center">
          <h3 class="mb-2 text-lg font-semibold text-indigo-600">
            {{ $t('onboarding.steps.usecases.aboutHeadline') }}
          </h3>
          <div class="mb-4 text-sm">
            {{ $t('onboarding.steps.usecases.aboutCopy') }}
          </div>
        </div>
      </div>
    </template>
  </OnboardingStepContainer>
</template>
