<script setup lang="ts">
// external
import { computed } from "vue"
import { Link } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"

// internal
import { CustomWidgetConfig, MultiFieldType, WidgetNumberMetric } from "~/types"
import { formatMultifieldNumber, addNumberSeparators } from "~/utils"

interface Props {
  data?: any[]
  config: CustomWidgetConfig
  title?: string
  showUsageLink?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    data: null,
    title: "",
    showUsageLink: false,
  },
)

const { t, locale } = useI18n()

const getMedian = (numbers) => {
  const sorted = numbers.slice().sort((a, b) => a - b)
  const middle = Math.floor(sorted.length / 2)

  // If the array has an odd length, return the middle number
  if (sorted.length % 2 === 1) {
    return sorted[middle]
  }

  // If the array has an even length, return the average of the two middle numbers
  return (sorted[middle - 1] + sorted[middle]) / 2
}

const numbers = computed(() => {
  const numberData = props.data.map((entry) => parseFloat(entry))

  const sum = numberData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const average = sum / numberData.length
  const median = getMedian(numberData)
  const lowest = numberData.sort((a, b) => a - b)[0]
  const highest = numberData.sort((a, b) => a - b).pop()

  return {
    sum,
    average,
    median,
    lowest,
    highest,
  }
})

const metricsToShow = computed(() => !props.config.number_metrics?.length ?  Object.keys(WidgetNumberMetric) as WidgetNumberMetric[] : props.config.number_metrics)

const maxRangeValue = 999999999

const getDisplayNumber = (value) => {
  if (props.config.value_type === MultiFieldType.currency) return formatMultifieldNumber(value, null, MultiFieldType.currency, locale.value)
  if (props.config.value_type === MultiFieldType.duration) return t("day", Math.round(value))
  return addNumberSeparators(value)
}
</script>

<template>
  <div
    class="w-full p-3"
  >
    <div
      v-if="title"
      class="mb-2 text-sm font-medium text-center"
    >
      {{ title }}
    </div>
    <div class="space-y-2">
      <div
        v-if="metricsToShow.length === 1"
        class="text-center"
      >
        <div class="text-xl font-semibold">
          {{ getDisplayNumber(numbers[metricsToShow[0]]) }}
        </div>
        <div class="text-sm ">
          <Link
            v-if="config.value_type !== MultiFieldType.duration && (metricsToShow[0] === WidgetNumberMetric.highest || metricsToShow[0] === WidgetNumberMetric.lowest)"
            :href="route('documents.index') + `?${config.metadata_name}=${numbers[metricsToShow[0]]}%2C${numbers[metricsToShow[0]]}`"
            class="text-indigo-500"
          >
            {{ $t('dashboard.numbers.' + metricsToShow[0]) }}
          </Link>
          <span
            v-else
            class="text-gray-500"
          >
            {{ $t('dashboard.numbers.' + metricsToShow[0]) }}</span>
        </div>
      </div>
      <div
        v-else-if="metricsToShow.length"
      >
        <div
          v-for="numberMetric in metricsToShow"
          :key="numberMetric"
          class="flex items-baseline justify-between space-x-2 text-sm"
        >
          <Link
            v-if="config.value_type !== MultiFieldType.duration && (numberMetric === WidgetNumberMetric.highest || numberMetric === WidgetNumberMetric.lowest)"
            :href="route('documents.index') + `?${config.metadata_name}=${numbers[numberMetric]}%2C${numbers[numberMetric]}`"
            class="text-indigo-500"
          >
            {{ $t('dashboard.numbers.' + numberMetric) }}
          </Link>
          <span v-else>{{ $t('dashboard.numbers.' + numberMetric) }}</span>
          <span
            class="h-px border-b border-gray-400 border-dotted grow"
          />
          <span>{{ getDisplayNumber(numbers[numberMetric]) }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="showUsageLink"
      class="mt-2 text-xs text-center"
    >
      <span
        v-if="config.value_type === MultiFieldType.duration"
        class="text-gray-500"
      >
        <span>{{ $t('dashboard.numbers.inUse', data.length) }}</span>
      </span>
      <Link
        v-else
        :href="route('documents.index') + `?${config.metadata_name}=-${maxRangeValue}%2C${maxRangeValue}`"
        class="text-indigo-500"
      >
        <span>{{ $t('dashboard.numbers.inUse', data.length) }}</span>
      </Link>
    </div>
  </div>
</template>
