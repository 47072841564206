<script setup lang="ts">
// external
import { EllipsisHorizontalIcon } from "@heroicons/vue/24/solid"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { storeToRefs } from "pinia"

// internal
import { TeamList, UserDisplay, SpinLoader } from "~/components"
import { DocumentNotification, Team, Document, Template, CrudContext, DocumentUser, DocumentNotificationType, AccountUser } from "~/types"
import { isoDurationToWords, formatDateLocalized, getFilteredRecipients } from "~/utils"
import { useAccountStore } from "~/stores"
import { useDocumentNotificationHelpers } from "~/composables"
import { BellAlertIcon, BellSnoozeIcon } from "@heroicons/vue/24/outline"
import { GlobeAltIcon, HomeIcon } from "@heroicons/vue/20/solid"

interface Props {
  notificationSetting: DocumentNotification
  entityUuid?: Document["uuid"] | Template["uuid"]
  context?: CrudContext
  teams?: Team[]
  users?: DocumentUser[]
  loading?: boolean
  alwaysShowOptionButton?: boolean
  allAccountUsers?: AccountUser[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    context: null,
    entityUuid: null,
    teams: () => [],
    users: () => [],
    allAccountUsers: () => [],
    loading: false,
    alwaysShowOptionButton: false,
  },
)

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const getTeams = (teamUuids: Team["uuid"][]) => {
  return props.teams.filter((team) => teamUuids.includes(team.uuid))
}

const { hasSpecificRecipients, isInCurrentContext } = useDocumentNotificationHelpers(props.context)

defineEmits([ "edit", "reset", "activate", "deactivate" ])

</script>

<template>
  <div
    class="bg-gray-100 rounded-md group"
    :class="{'bg-gray-50' : notificationSetting.is_deactivated}"
  >
    <div class="flex items-center justify-between p-3 space-x-3">
      <div
        class="flex items-center justify-center flex-shrink-0 w-8 h-8 text-black rounded-full text"
        :class="!notificationSetting.is_deactivated ? 'bg-gradient-to-br from-yellow-100 to-yellow-300' : 'bg-gradient-to-br from-gray-100 to-gray-300'"
      >
        <BellAlertIcon
          v-if="!notificationSetting.is_deactivated"
          class="w-5 h-5 bellshake"
        />
        <BellSnoozeIcon
          v-else
          class="w-5 h-5"
        />
      </div>
      <div
        :class="{'opacity-50' : notificationSetting.is_deactivated}"
        class="flex-grow"
      >
        <div class="text-xs text-gray-500">
          <button
            type="button"
            class="block w-full text-left text-gray-500"
            :disabled="notificationSetting.is_deactivated"
            @click="$emit('edit', notificationSetting)"
          >
            <i18n-t
              scope="global"
              :keypath="(notificationSetting.type === DocumentNotificationType.recurring && notificationSetting.recurring_condition.interval) ? 'documentNotifications.labelRecurring' : 'documentNotifications.labelOnce'"
              tag="span"
            >
              <span>{{ $t('documentNotifications.types.' + notificationSetting.type + 'Label') }}</span>
              <span v-if="notificationSetting.type === DocumentNotificationType.reminder && notificationSetting.trigger_time_date && mau?.user?.locale">
                {{ formatDateLocalized(notificationSetting.trigger_time_date, mau.user.locale) }}
              </span>
              <span v-else-if="notificationSetting.type === DocumentNotificationType.reminder && notificationSetting.trigger_time_condition.interval">
                {{ $t('documentNotifications.triggerTimeConditionTypes.' + notificationSetting.trigger_time_condition.type, {interval: isoDurationToWords(notificationSetting.trigger_time_condition.interval, $t)}) }}
              </span>
              <span v-else>{{ $t('documentNotifications.when') }}</span>
              <kbd class="px-1 py-0.5 text-xs font-medium tracking-tighter text-indigo-700 rounded bg-indigo-100 box-decoration-clone">{{ $t('documentNotifications.triggerLabels.' + notificationSetting.key) }}</kbd>
              <kbd
                v-if="notificationSetting.type === DocumentNotificationType.recurring && notificationSetting.recurring_condition.interval"
                class="px-1 py-0.5 text-xs font-medium tracking-tighter text-yellow-700 rounded bg-yellow-100 box-decoration-clone"
              >
                {{ $t('documentNotifications.triggerTimeConditionTypes.recurring', {interval: isoDurationToWords(notificationSetting.recurring_condition.interval, $t)}) }}
              </kbd>
            </i18n-t>
          </button>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between gap-3 p-3 border-t border-t-gray-200">
      <span class="flex-grow-0 flex-shrink-0 text-xs text-gray-500">
        {{ $t('documentNotifications.recipients') }}:
      </span>
      <div class="flex items-center justify-start flex-grow">
        <div
          v-if="notificationSetting.type === DocumentNotificationType.recurring"
          class="rounded-full px-2 py-0.5 text-xs bg-gradient-to-br from-green-100 to-green-200 shadow"
        >
          {{ $t('documentNotifications.taskRecipients') }}
        </div>
        <UserDisplay
          v-else-if="context === CrudContext.document"
          :users="getFilteredRecipients({
            users: users,
            recipients: notificationSetting.recipients,
            allAccountUsers: allAccountUsers,
          })"
        />
        <div
          v-else-if="!hasSpecificRecipients(notificationSetting)"
          class="rounded-full px-2 py-0.5 text-xs bg-gradient-to-br from-white-100 to-gray-50 shadow"
        >
          {{ $t('documentNotifications.allDocumentUsers') }}
        </div>
        <div
          v-else
          class="space-y-1"
        >
          <div
            v-if="notificationSetting.recipients.roles?.length"
            class="flex flex-wrap items-center gap-1"
          >
            <span
              v-for="role in notificationSetting.recipients.roles"
              :key="role"
              class="rounded-full px-2 py-0.5 text-xs bg-gradient-to-br from-gray-100 to-gray-200 shadow"
            >{{ $t('documentNotifications.roles.' + role) }}</span>

            <TeamList
              v-if="notificationSetting.recipients.team_uuids?.length"
              extra-classes="rounded-full py-0.5 text-xs bg-gradient-to-br from-gray-100 to-gray-200 shadow"
              :teams="getTeams(notificationSetting.recipients.team_uuids)"
            />

            <div
              v-if="hasSpecificRecipients(notificationSetting) && notificationSetting.recipients.scope.internal"
              class="flex items-center justify-center w-4 h-4 text-xs font-bold text-white rounded-full cursor-default from-indigo-400 to-indigo-800 bg-gradient-to-br"
              data-tippy-help
              :data-tippy-content="$t('documentNotifications.scopes.internal')"
              data-placement="top"
            >
              <HomeIcon class="w-3 h-3" />
            </div>
            <div
              v-if="hasSpecificRecipients(notificationSetting) && notificationSetting.recipients.scope.external"
              class="flex items-center justify-center w-4 h-4 text-xs font-bold text-white rounded-full cursor-default from-teal-400 to-teal-800 bg-gradient-to-br"
              data-tippy-help
              :data-tippy-content="$t('documentNotifications.scopes.external')"
              data-placement="top"
            >
              <GlobeAltIcon class="w-3 h-3" />
            </div>
          </div>
        </div>
      </div>

      <Menu
        v-slot="{ open }"
        as="div"
        class="relative inline-block text-left"
      >
        <SpinLoader
          v-if="loading"
          class="w-5 h-5 m-2 text-gray-500"
        />
        <div
          v-else
        >
          <MenuButton
            class="btn-menu"
            :class="[alwaysShowOptionButton ? '' : 'transition-all scale-0 opacity-0 group-hover:opacity-100 group-hover:scale-100', open ? 'opacity-100 scale-100' : '']"
          >
            <div class="sr-only">
              {{ $t('magicTable.columns.options') }}
            </div>
            <EllipsisHorizontalIcon
              class="w-5 h-5 text-gray-900"
              aria-hidden="true"
            />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 z-20 w-56 mt-1 overflow-hidden origin-top-right divide-y rounded-md divide-slate-800 bg-slate-900 focus:outline-none"
            data-cy-sel="magic-table-options-items"
          >
            <MenuItem
              v-if="!notificationSetting.is_deactivated"
              v-slot="{ active }"
            >
              <button
                :class="[
                  active ? 'bg-slate-700 text-white' : 'text-slate-300',
                  'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
                ]"
                @click="$emit('edit', notificationSetting)"
              >
                {{ $t('common.edit') }}
              </button>
            </MenuItem>
            <MenuItem
              v-if="isInCurrentContext(notificationSetting)"
              v-slot="{ active }"
            >
              <button
                :class="[
                  active ? 'bg-slate-700 text-white' : 'text-slate-300',
                  'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
                ]"
                @click="$emit('reset', notificationSetting)"
              >
                {{ notificationSetting.is_deactivated ? $t('documentNotifications.activate') : $t('documentNotifications.resetToDefault') }}
              </button>
            </MenuItem>
            <MenuItem
              v-if="!isInCurrentContext(notificationSetting)"
              v-slot="{ active }"
            >
              <button
                :class="[
                  active ? 'bg-slate-700 text-white' : 'text-slate-300',
                  'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
                ]"
                @click="notificationSetting.is_deactivated ? $emit('activate', notificationSetting) : $emit('deactivate', notificationSetting)"
              >
                {{ notificationSetting.is_deactivated ? $t('documentNotifications.activate') : $t('documentNotifications.deactivate') }}
              </button>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>
