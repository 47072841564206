<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, watch, toRaw } from "vue"
import { useI18n } from "vue-i18n"
import { PlusIcon } from "@heroicons/vue/20/solid"
import { ArrowLeftOnRectangleIcon, TrashIcon, UserPlusIcon } from "@heroicons/vue/24/outline"
import { QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { without, filter, intersection, find } from "lodash-es"

// internal
import { FormInputErrors, SpinLoader, UserPartySettings, UserCombobox, OverlayScrollbar, SkeletonLoader, EmptyState } from "~/components"
import { useNotificationStore, useUserStore, useSharedStore, usePartyStore, useDocumentStore, useTemplateStore } from "~/stores"
import { DocumentUser, DocumentUserRoleEnum, Party } from "~/types"
import { getUserRepresentation } from "~/utils"
import { SignatureIcon } from "~/icons"

const { t } = useI18n()

const userStore = useUserStore()
const { uuidsOfUpdatingUser, isLoadingUsers, userUuidBeingRemovedFromEntity, users, userErrorsMap } = storeToRefs(userStore)
const { updateLocalUser, removeUserFromEntity } = userStore

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const documentStore = useDocumentStore()
const { mau, mdu } = storeToRefs(documentStore)

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const { notify } = useNotificationStore()

const defaultPartyUuid = computed<Party["uuid"]>(() => {
  return parties.value?.[0]?.uuid || null
})

const uuidsOfIsRemovingSignatoryAsCollaborator = ref<DocumentUser["uuid"][]>([])

const getUserBackendErrors = (userUuid: DocumentUser["uuid"]) : Partial<Record<keyof DocumentUser, string[]>> => {
  const errors: Partial<Record<keyof DocumentUser, string[]>> = {}
  const backendErrors = userErrorsMap.value[userUuid] || {}
  Object.keys(backendErrors)
    .forEach(
      (key) => {
        errors[key] = [ ...(errors[key] || []), ...(backendErrors[key] || []) ]
      },
    )
  Object.keys(errors)
    .forEach(
      (key) => {
        if (errors[key].length === 0) delete errors[key]
      },
    )
  return errors
}

const isActiveAddDocumentUser = ref<boolean>(false)
const isCreatingUser = ref<boolean>(false)

const existingDocumentUsers = computed<DocumentUser[]>(() => {
  return filter(users.value, (user) => {

    const isBeingRemovedAsCollaborator = uuidsOfIsRemovingSignatoryAsCollaborator.value.includes(user.uuid)

    // Check if user is a collaborator
    const matchesRole = intersection([ DocumentUserRoleEnum.collaborator, DocumentUserRoleEnum.owner ], user.roles || [])?.length > 0

    return !!((matchesRole || isBeingRemovedAsCollaborator))
  })
})

const emptyUser: Partial<DocumentUser> = Object.freeze({ roles: [ DocumentUserRoleEnum.collaborator ] })

const userToAdd = ref<DocumentUser>({ ...Object.assign(emptyUser), party_uuid: defaultPartyUuid.value })

const setUserToAdd = (documentUser: DocumentUser) => {
  // If there is an existing documentUser (with a matching account_user?.uuid or email), update the uuid property
  const existingDocumentUser = find(users.value, (user) => (user.account_user?.uuid && user.account_user?.uuid === documentUser?.account_user?.uuid) || user.email === documentUser?.email)
  if (existingDocumentUser && documentUser) {
    documentUser.uuid = existingDocumentUser.uuid
    documentUser.party_uuid = existingDocumentUser.party_uuid
  }
  userToAdd.value = !documentUser?.party_uuid ? { ...documentUser, party_uuid: defaultPartyUuid.value } : { ...documentUser }
}

watch(parties, () => {
  userToAdd.value = { ...Object.assign(emptyUser), party_uuid: defaultPartyUuid.value }
})

const deactivatedUsers = computed<DocumentUser[]>(() => [ ...[ userToAdd.value ], ...existingDocumentUsers.value ])

// Function to create / add a new user
const createUser = async (user: Partial<DocumentUser>): Promise<DocumentUser | void> => {

  // Make sure somebody without an account users cannot add users to another party
  if (!mau.value && user.party_uuid && user.party_uuid !== mdu.value?.party_uuid) return
  let partyUuid = null
  if (!mau.value) partyUuid = mdu.value?.party_uuid
  else partyUuid = user.party_uuid

  // Deliver entire user as payload for external users, and only role/party/signatory for internal users
  const payload = !user.account_user?.uuid
    ? toRaw({
      email: user.email,
      roles: [ DocumentUserRoleEnum.collaborator ],
      party_uuid: partyUuid,
    })
    : toRaw({
      account_user_uuid: user.account_user?.uuid,
      roles: [ DocumentUserRoleEnum.collaborator ],
      party_uuid: partyUuid,
    })

  try {

    const createdUser = await userStore.createUser(crudContext.value, currentTemplate.value?.uuid, payload)

    if (!createdUser) throw new Error(t("userSettings.errors.invalidUser", { string: JSON.stringify({ createdUser }) }))

    userStore.setUserErrors("0", null)

    return createdUser

  } catch (err) {
    userStore.setUserErrors("0", err.response?.data?.errors || null)

    notify({
      title: t("userSettings.errors.addUser"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  }
}

const handleUpdatePartyOfNewUser = ( partyUuid: Party["uuid"]) => {
  const user = userToAdd.value
  if (!user) return
  userToAdd.value = { ...user, party_uuid: partyUuid }
}

const removeNewUser = () => {
  isActiveAddDocumentUser.value = false
  userToAdd.value = { ...Object.assign(emptyUser), party_uuid: defaultPartyUuid.value }
}

const handleAddUser = async (): Promise<DocumentUser | void> => {
  let res: DocumentUser | void
  if (!userToAdd.value?.email && !userToAdd.value?.account_user?.uuid) return
  isCreatingUser.value = true
  // If there is no document user, newly create, otherwise update role
  if (!userToAdd.value?.uuid) {
    res = await createUser(userToAdd.value)
    if (!res) return
  } else {
    res = await handleUpdateRoleOfExistingUser(userToAdd.value?.uuid)
    if (!res) return
  }

  if (!res) return
  isActiveAddDocumentUser.value = false
  userToAdd.value = { ...Object.assign(emptyUser), party_uuid: defaultPartyUuid.value }
  isCreatingUser.value = false
  return res
}

// Method to update the role of an existing document user
const handleUpdateRoleOfExistingUser = async (userUuid: DocumentUser["uuid"]) => {
  const user = users.value?.find((el) => el.uuid === userUuid)
  if (!user) return
  const newRoles = !user.roles?.includes(DocumentUserRoleEnum.collaborator) && !user.roles?.includes(DocumentUserRoleEnum.owner) ? [ ...user.roles, DocumentUserRoleEnum.collaborator ] : [ ...user.roles ]
  const payload = { ...toRaw(user), roles: newRoles }
  const res = await updateLocalUser(payload, crudContext.value, currentTemplate.value?.uuid, false, true)
  if (res) return res
}

// Method to update the party of an existing document user
const handleUpdatePartyOfExistingUser = async (userUuid: DocumentUser["uuid"], partyUuid: Party["uuid"]) => {
  const user = users.value?.find((el) => el.uuid === userUuid)
  if (!user) return
  const payload = { ...toRaw(user), party_uuid: partyUuid }
  const res = await updateLocalUser(payload, crudContext.value, currentTemplate.value?.uuid, false, true)
  if (res) return true
}

// Function to remove a user from an entity
const removeUser = async (userUuid: DocumentUser["uuid"]) => {
  if (!currentTemplate.value) return

  // If the user also has the role signatory, remove only the collaborator role
  const signatory = users.value?.find((el) => el.uuid === userUuid && el.roles.includes(DocumentUserRoleEnum.signatory))
  if (signatory) {
    uuidsOfIsRemovingSignatoryAsCollaborator.value.push(signatory.uuid)
    const newRoles = without(toRaw(signatory.roles), DocumentUserRoleEnum.collaborator) || []
    const payload = { ...signatory, roles: newRoles }
    try {
      const res = await updateLocalUser(payload, crudContext.value, currentTemplate.value?.uuid, false, true)
      if (res) uuidsOfIsRemovingSignatoryAsCollaborator.value = without(uuidsOfIsRemovingSignatoryAsCollaborator.value, signatory.uuid)
    } catch (err) {
      userStore.setUserErrors("0", err.response?.data?.errors || null)

      notify({
        title: t("sharingModal.errors.removeUserAsCollaborator"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
      uuidsOfIsRemovingSignatoryAsCollaborator.value = without(uuidsOfIsRemovingSignatoryAsCollaborator.value, signatory.uuid)
    } finally {
      return
    }
  }
  // Otherwise remove the user from the document entirely
  removeUserFromEntity(crudContext.value, currentTemplate.value?.uuid, userUuid)
}

// Method to check if a visibleUser has a corresponding documentUser with the signatory role
const isSignatory = (documentUser: DocumentUser) => {
  const filteredUser = users.value?.find((du) => du.uuid && du.uuid === documentUser.uuid && du.email === documentUser.email)
  return filteredUser?.roles?.includes(DocumentUserRoleEnum.signatory)
}

const getParty = (partyUuid: Party["uuid"]) => parties.value?.find((el) => el.uuid === partyUuid)

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      class="px-6 border-b shrink-0 border-b-gray-200"
    >
      <h3
        class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('sharingSettings.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('sharingSettings.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingUsers"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>
    <template v-else>
      <OverlayScrollbar
        ref="sharingScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <ul
          v-if="existingDocumentUsers.length"
          role="list"
          class="px-6 pt-2 pb-4 space-y-2"
        >
          <div
            v-for="user in existingDocumentUsers"
            :id="'userInvitedContainer_' + user.uuid"
            :key="user.uuid"
            class="px-6 -mx-6 text-sm"
            data-cy-sel="invited-users-container"
            :class="!mau && mdu?.uuid === user.uuid ? 'opacity-50 pointer-events-none' : ''"
          >
            <div class="flex items-center gap-3">
              <img
                class="w-8 h-8 rounded-full shrink-0"
                :src="user?.profile_photo_url"
                alt=""
              >
              <div class="grow">
                <h3
                  class="font-medium break-all"
                >
                  <span class="truncate">{{ getUserRepresentation(user) }}</span>
                </h3>

                <div
                  class="relative text-xs pb-1.5"
                >
                  <div
                    v-if="user && isSignatory(user) && getParty(user.party_uuid)"
                    class="flex items-center gap-2 text-gray-600 cursor-not-allowed mt-0.5"
                  >
                    <SignatureIcon class="w-4 h-4 text-indigo-600 shrink-0" />
                    <span class="truncate max-w-[10rem]">
                      {{ getParty(user.party_uuid).name || getParty(user.party_uuid).entity_name }}
                    </span>
                    <span
                      data-tippy-help
                      :data-tippy-content="$t('documentSharingModal.signatoryExplanation')"
                      data-placement="bottom"
                    >
                      <QuestionMarkCircleIcon
                        class="w-4 h-4 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <UserPartySettings
                    v-else
                    :user="user"
                    class="mt-1"
                    :hide-label="true"
                    :disabled="uuidsOfUpdatingUser.includes(user.uuid)"
                    :party-uuid="user.party_uuid"
                    @update:party-uuid="handleUpdatePartyOfExistingUser(user.uuid, $event)"
                  />
                </div>
              </div>
              <SpinLoader
                v-if="uuidsOfUpdatingUser.includes(user.uuid) || userUuidBeingRemovedFromEntity === user.uuid"
                class="w-4 h-4 text-gray-400"
              />
              <button
                v-else-if="mau || mdu?.uuid !== user.uuid"
                class="p-0 text-gray-400 btn-plain hover:text-gray-600"
                @click.prevent="removeUser(user.uuid)"
              >
                <TrashIcon
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
              </button>
            </div>
            <FormInputErrors
              v-if="getUserBackendErrors(user.uuid)?.party_uuid?.length"
              :errors="getUserBackendErrors(user.uuid)?.party_uuid"
            />
            <FormInputErrors
              v-if="getUserBackendErrors(user.uuid)?.roles?.length"
              :errors="getUserBackendErrors(user.uuid)?.roles"
            />
          </div>
        </ul>
        <EmptyState
          v-else
          :hide-button="true"
          class="mt-6"
        >
          <template #icon>
            <ArrowLeftOnRectangleIcon
              aria-hidden="true"
            />
          </template>
          {{ $t('sharingSettings.noUsers') }}
        </EmptyState>
        <div
          v-if="isActiveAddDocumentUser"
          class="p-4 mx-6 bg-white border border-gray-200 rounded-md shadow"
        >
          <div
            :class="isCreatingUser ? 'opacity-25' : ''"
          >
            <div class="mb-2 space-y-2 text-sm">
              <div class="relative">
                <UserCombobox
                  :scope="'internal'"
                  :is-account-party="true"
                  :deactivated-users="deactivatedUsers"
                  :selected-user="userToAdd"
                  align="bottom"
                  class="grow"
                  input-class="pl-12"
                  :disable-email="false"
                  :add-new-label="'userToParty.addNew'"
                  @update:selected-user="setUserToAdd($event)"
                />
                <span
                  v-if="userToAdd?.profile_photo_url"
                  class="absolute flex items-center justify-center w-8 h-8 -mt-4 left-2 top-1/2"
                >
                  <img
                    class="w-6 h-6 rounded-full shrink-0"
                    :src="userToAdd?.profile_photo_url"
                    alt=""
                  >
                </span>
                <span
                  v-else
                  class="absolute flex items-center justify-center w-8 h-8 -mt-4 left-2 top-1/2"
                >
                  <UserPlusIcon
                    class="w-5 h-5 text-gray-400 shrink-0"
                  />
                </span>
              </div>
              <div>
                <div
                  v-if="userToAdd && isSignatory(userToAdd) && getParty(userToAdd.party_uuid)"
                  class="inline-flex items-center gap-2 cursor-not-allowed grow"
                >
                  <SignatureIcon class="w-4 h-4 text-indigo-600 shrink-0" />
                  <span class="truncate grow whitespace-nowrap">
                    {{ getParty(userToAdd.party_uuid).name || getParty(userToAdd.party_uuid).entity_name }}
                  </span>
                  <span
                    data-tippy-help
                    :data-tippy-content="$t('documentSharingModal.signatoryExplanation')"
                    data-placement="bottom"
                  >
                    <QuestionMarkCircleIcon
                      class="w-4 h-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <UserPartySettings
                  v-else
                  class="grow"
                  :disabled="!!(userToAdd?.uuid)"
                  :party-uuid="userToAdd?.party_uuid"
                  :hide-label="true"
                  @update:party-uuid="handleUpdatePartyOfNewUser($event)"
                />
              </div>
            </div>
          </div>
          <FormInputErrors
            v-if="getUserBackendErrors('0')?.email?.length"
            :errors="getUserBackendErrors('0')?.email"
          />
          <FormInputErrors
            v-if="getUserBackendErrors('0')?.party_uuid?.length"
            :errors="getUserBackendErrors('0')?.party_uuid"
          />
          <FormInputErrors
            v-if="getUserBackendErrors('0')?.roles?.length"
            :errors="getUserBackendErrors('0')?.roles"
          />
          <div class="flex items-center justify-end">
            <button
              type="button"
              class="ml-2 text-gray-500 btn-plain hover:text-gray-700"
              @click="removeNewUser"
            >
              {{ $t('common.cancel') }}
            </button>
            <button
              type="button"
              class="btn-primary"
              :disabled="isCreatingUser"
              @click="handleAddUser"
            >
              {{ $t('common.save') }}
            </button>
          </div>
        </div>
        <div
          v-if="!isActiveAddDocumentUser"
          class="mb-6"
          :class="existingDocumentUsers.length ? 'ml-3 -mt-2' : '-mt-4'"
        >
          <button
            type="button"
            :class="existingDocumentUsers.length ? '' : 'mx-auto'"
            class="btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
            @click.prevent="isActiveAddDocumentUser = true"
          >
            <PlusIcon class="shrink-0 h-3.5 w-3.5" />
            {{ $t('sharingSettings.addUserWithAutoAccess') }}
          </button>
        </div>
      </OverlayScrollbar>
    </template>
  </div>
</template>
