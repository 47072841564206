<script setup lang="ts">
// external
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue"
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid"
import { DocumentDuplicateIcon, DocumentTextIcon, FolderIcon } from "@heroicons/vue/24/outline"
import { router, Link } from "@inertiajs/vue3"
import axios, { AxiosError } from "axios"
import { throttle } from "lodash-es"
import { computed, ref, watch } from "vue"

// internal
import { SpinLoader, SearchAndCommandResult } from "~/components"
import { DocumentSnippet, Template, DocumentOrigin, AccountUser } from "~/types"
interface Props {
  searchActive?: boolean
  mau: AccountUser
}

interface SearchResult {
  uuid: string
  document?: DocumentSnippet
  template?: Template
  quick_search?: SearchResult
  origin?: DocumentOrigin
}

interface SearchLink {
  url: string | null
  label: string
  active: boolean
}

interface SearchMetaInformation {
  current_page: number
  from: number
  last_page: number
  links: SearchLink[]
  path: string
  per_page: number
  to: number
  total: number
}

const props = withDefaults(defineProps<Props>(), {
  searchActive: false,
})

const emit = defineEmits([ "update:searchActive", "update:showConfirmModal" ])

const open = computed(
  {
    get: () => props.searchActive,
    set: (value: boolean) => emit("update:searchActive", value),
  },
)

const results = ref<SearchResult[]>(null)
const searchMetaInformation = ref<SearchMetaInformation>(null)
const query = ref<string>("")
const searchInProgress = ref<boolean>(false)
const isFetched = ref<boolean>(false)

const search = throttle(
  async (query: string): Promise<void> => {
    searchInProgress.value = true

    const url = `${route("api.quick-search")}?query=${query}`

    try {
      const res = await axios.get(url)

      results.value = res.data.data

      searchMetaInformation.value = res.data.meta
    } catch (err) {
      console.error(err)
    } finally {
      searchInProgress.value = false
    }
  },
  500,
)

watch(
  () => query.value,
  (newVal) => newVal?.length >= 3
    ? search(newVal)
    : results.value = [],
)

const recentSearches = ref<SearchResult[]>()

const recentSearchesUpdating = ref<boolean>(false)

const fetchRecentSearches = async (): Promise<SearchResult[] | void> => {
  recentSearchesUpdating.value = true

  try {
    const url = route("api.quick-search-matches.index")

    const fetchRecentSearchesRes = await axios.get<{ data: SearchResult[] }>(url)

    recentSearches.value = fetchRecentSearchesRes.data.data

    return fetchRecentSearchesRes.data.data
  } catch (err) {
    console.error(err)
  } finally {
    recentSearchesUpdating.value = false
  }
}

const storeRecentSearch = async (quickSearchUuid: SearchResult["uuid"]) => {
  const url = route(
    "api.quick-search-matches.store",
    {
      quick_search_uuid: quickSearchUuid,
    },
  )

  try {
    await axios.post(url)

    fetchRecentSearches()
  } catch (err) {
    console.error(err)
  }
}

const deleteRecentSearchItem = async (quickSearchUuid: number) => {
  const url = route("api.quick-search-matches.destroy", { quick_search_match: quickSearchUuid })

  try {
    await axios.delete(url)

    const indexOfDeleted = recentSearches.value.findIndex((el) => el.uuid === quickSearchUuid)
    recentSearches.value.splice(indexOfDeleted, 1)
    fetchRecentSearches()
  } catch (err) {
    console.error(err as AxiosError)
  }
}

const quickActions = [
  { key: "createNewDocument", icon: DocumentTextIcon, shortcut: "N", url: route("documents.index") + "#create" },
]

if (props.mau.permissions.includes("template_manage")) {
  quickActions.push({ key: "createNewTemplate", icon: DocumentDuplicateIcon, shortcut: "F", url: route("templates.index") + "#create" })
}

const onSelect = async (item: SearchResult) => {
  if (item.quick_search) {
    const visitUrl = route(`${item.quick_search.document ? "documents.show" : "templates.edit"}`, item.quick_search.document?.uuid || item.quick_search.template?.uuid)

    router.visit(visitUrl, { preserveState: false })

    open.value = false

    return
  }

  await storeRecentSearch(item.uuid)

  const visitUrl = route(`${item.document ? "documents.show" : "templates.edit"}`, item.document?.uuid || item.template?.uuid)

  router.visit(visitUrl, { preserveState: false })

  open.value = false
}

watch(
  () => open.value,
  async (newVal, oldVal) => {
    if (newVal === false || newVal === oldVal || isFetched.value) return

    await fetchRecentSearches()

    isFetched.value = true
  },
)
</script>

<template>
  <TransitionRoot
    :show="open"
    as="template"
    appear
    @after-leave="query = ''"
  >
    <Dialog
      as="div"
      class="relative z-50"
      @close="open = false"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity bg-black bg-opacity-70" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 p-4 overflow-y-auto sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="max-w-2xl mx-auto overflow-hidden transition-all transform bg-gray-900 divide-y divide-gray-500 shadow-2xl divide-opacity-20 rounded-xl"
          >
            <Combobox @update:model-value="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon
                  class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="w-full h-12 pr-4 text-white placeholder-gray-400 bg-transparent border-0 pl-11 focus:ring-0 sm:text-sm"
                  :placeholder="$t('filter.search') + '…'"
                  @change="query = $event.target.value"
                />
              </div>

              <ComboboxOptions
                v-if="query === '' || results?.length > 0"
                static
                class="overflow-y-auto divide-y divide-gray-500 max-h-80 scroll-py-2 divide-opacity-20"
              >
                <li class="p-2">
                  <h2
                    v-if="query === ''"
                    class="px-3 mt-4 mb-1 text-xs font-semibold text-gray-200"
                  >
                    {{ $t('quickSearch.recentSearches') }}
                  </h2>
                  <div
                    v-else-if="(results?.length > 0)"
                    class="px-3 pt-4 pb-2 text-xs font-semibold text-gray-200"
                  >
                    {{ $t('quickSearch.showingResults', {count: results.length, total: searchMetaInformation.total}) }}:
                  </div>
                  <ul
                    v-if="recentSearches?.length || results?.length"
                    class="text-sm text-gray-400"
                  >
                    <ComboboxOption
                      v-for="result in query === '' ? recentSearches : results"
                      :key="result.uuid"
                      v-slot="{ active }"
                      :value="result"
                      as="template"
                    >
                      <SearchAndCommandResult
                        :result="result"
                        :active="active"
                        :query="query"
                        @delete="deleteRecentSearchItem"
                      />
                    </ComboboxOption>
                  </ul>
                  <div v-else-if="!isFetched">
                    <span
                      class="flex items-center gap-2 px-3 pb-3 text-xs text-gray-500"
                    >
                      <span class="pointer-events-none">
                        <SpinLoader class="w-3 h-3 text-gray-500" />
                      </span>
                      {{ $t('common.pleaseWait') }}…
                    </span>
                  </div>
                  <div
                    v-else
                    class="px-3 pb-3 text-xs text-gray-500"
                  >
                    {{ $t('quickSearch.noRecent') }}
                  </div>
                </li>
                <li
                  v-if="query === ''"
                  class="p-2"
                >
                  <ul class="text-sm text-gray-400">
                    <ComboboxOption
                      v-for="action in quickActions"
                      :key="action.shortcut"
                      v-slot="{ active }"
                      :value="action"
                      as="template"
                    >
                      <li
                        :class="['cursor-default select-none items-center rounded-md px-3 py-2', active && 'bg-gray-800 text-white']"
                      >
                        <Link
                          :href="action.url"
                          class="flex items-center space-x-3"
                        >
                          <component
                            :is="action.icon"
                            :class="['h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-500']"
                            aria-hidden="true"
                          />
                          <span class="flex-auto truncate">
                            {{ $t('quickSearch.' + action.key) }}
                          </span>
                          <span class="flex-none text-xs font-semibold text-gray-400">
                            <kbd class="font-sans">
                              ⌘{{ action.shortcut }}
                            </kbd>
                          </span>
                        </Link>
                      </li>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>

              <div
                v-else-if="query !== '' && query.length < 3"
                class="flex items-center justify-center gap-3 px-6 py-14 sm:px-14"
              >
                <p class="text-sm text-gray-500">
                  {{ $t('quickSearch.minChars', {min: 3}) }}
                </p>
              </div>

              <div
                v-else-if="searchInProgress"
                class="flex items-center justify-center gap-3 px-6 py-14 sm:px-14"
              >
                <SpinLoader
                  class="w-6 h-6 text-gray-500"
                  aria-hidden="true"
                />
                <p class="text-sm text-gray-500">
                  {{ $t('common.searching') }}…
                </p>
              </div>

              <div
                v-else-if="query !== '' && !searchInProgress && results?.length === 0"
                class="px-6 text-center py-14 sm:px-14"
              >
                <FolderIcon
                  class="w-6 h-6 mx-auto text-gray-500"
                  aria-hidden="true"
                />
                <p class="mt-4 text-sm text-gray-200">
                  {{ $t('quickSearch.noResults') }}
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
