<script setup lang="ts">
// external
import { computed } from "vue"
import { storeToRefs } from "pinia"
import { XMarkIcon } from "@heroicons/vue/24/solid"
import {
  InformationCircleIcon,
  SquaresPlusIcon,
  FunnelIcon,
} from "@heroicons/vue/24/outline"

// internal
import { DashboardGeneralSettings, DashboardWidgetSettings, DashboardFilterSettings } from "~/components"
import { useDashboardStore } from "~/stores"
import { DashboardTab } from "~/types"
import { purifyHtml } from "~/utils"

const dashboardStore = useDashboardStore()
const { dashboards, activeDashboardUuid, showMenu, activeTabKey } = storeToRefs(dashboardStore)

const activeDashboard = computed(() => dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value))

const close = () => {
  showMenu.value = false
}

interface Tab {
  key: DashboardTab
  iconIsComponent: boolean
  icon: any
  addClasses?: string
  badgeNumber?: number
  badgeClasses?: string
}

const numberOfActiveFilters = computed(() => {
  let count = 0

  if (activeDashboard.value?.filter) {
    for (const filterKey in activeDashboard.value.filter) {
      const filter = activeDashboard.value.filter[filterKey]

      if (Array.isArray(activeDashboard.value.filter[filterKey])) {
        count += filter.length
      } else if (filter !== null) {
        count++
      }
    }
  }

  return count
})

const tabs = computed<Tab[]>(() => {
  return [
    { key: DashboardTab.widgets, iconIsComponent: true, icon: SquaresPlusIcon },
    { key: DashboardTab.general, iconIsComponent: true, icon: InformationCircleIcon },
    { key: DashboardTab.filter, iconIsComponent: true, icon: FunnelIcon, badgeNumber: numberOfActiveFilters.value, badgeClasses: "bg-indigo-600" },
  ]
})

const emits = defineEmits([ "drag", "drag-end" ])

const drag = (widgetKey) => {
  emits("drag", widgetKey)
}

const dragEnd = (widgetKey) => {
  emits("drag-end", widgetKey)
}

</script>

<template>
  <aside
    id="dashboardMenu"
    class="w-full fixed hidden sm:flex bottom-0 top-0 pt-14 lg:pt-16 z-20 transition-all sm:w-[28rem] sm:max-w-[28rem] bg-white sm:border-l border-gray-200"
    :class="showMenu ? 'shadow right-0' : '-right-[28rem]'"
  >
    <div class="flex w-full items-center sm:items-start sm:flex-none sm:w-[3rem] sm:h-full sm:border-r sm:border-r-gray-200 bg-white">
      <nav
        class="flex w-full origin-top-left sm:flex-col shrink-0"
        aria-label="Tabs"
      >
        <template
          v-for="tab in tabs"
          :key="tab.key"
        >
          <button
            v-cy="`dashboard-tab-${tab.key}`"
            data-tippy-context
            :data-tippy-content="$t('dashboard.tabs.' + tab.key)"
            data-placement="left"
            type="button"
            class="relative h-12 -mr-px text-sm font-medium text-center border-t-2 border-b grow sm:border-t-0 sm:border-r-2 sm:flex-auto focus:outline-none border-b-gray-200"
            :class="[
              tab.key === activeTabKey
                ? 'border-t-indigo-500 sm:border-r-indigo-500 text-indigo-600 bg-indigo-100'
                : 'text-gray-500 border-r-transparent hover:text-gray-700 hover:bg-gray-100 hover:border-r-gray-400',
              tab.addClasses ? tab.addClasses : '',
              tab.key === DashboardTab.widgets ? 'hidden lg:block' : 'block'
            ]"
            :aria-current="tab.key === activeTabKey ? 'page' : undefined"
            @click="activeTabKey = tab.key"
          >
            <component
              :is="tab.icon"
              v-if="tab.iconIsComponent"
              class="w-6 h-6 mx-auto"
              aria-hidden="true"
            />
            <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
            <svg
              v-else
              class="w-6 h-6 mx-auto fill-current"
              viewBox="0 0 24 24"
              v-html="purifyHtml(tab.icon as unknown as string)"
            ></svg>
            <!-- eslint-enable vue/no-v-html vue/html-self-closing -->
            <span
              v-if="tab.badgeNumber"
              :class="[
                tab.badgeClasses ? tab.badgeClasses : 'bg-red-600'
              ]"
              class="absolute flex items-center justify-center w-3 h-3 text-[9px] font-medium text-white rounded-full top-1/2 right-1/2 -mr-4 -mt-3"
            >
              {{ tab.badgeNumber }}
            </span>
          </button>
        </template>
      </nav>
    </div>
    <div
      class="translate-x-full z-30 sm:z-20 fixed inset-0 bottom-12 sm:bottom-auto sm:static sm:inset-auto sm:translate-x-0 transition-all duration-300 sm:h-full sm:max-w-full sm:w-[25rem]"
    >
      <div
        v-if="activeTabKey === DashboardTab.widgets"
        class="relative h-full"
      >
        <DashboardWidgetSettings
          @drag="drag"
          @drag-end="dragEnd"
        />
      </div>
      <div
        v-if="activeTabKey === DashboardTab.general"
        class="relative h-full"
      >
        <DashboardGeneralSettings
          :dashboard="activeDashboard"
        />
      </div>
      <div
        v-if="activeTabKey === DashboardTab.filter"
        class="relative h-full"
      >
        <DashboardFilterSettings />
      </div>
    </div>
    <div
      class="absolute mr-3 space-y-2 right-full top-20"
    >
      <button
        v-if="showMenu"
        type="button"
        class="flex items-center justify-center w-10 h-10 rounded-full shadow bg-white/80 hover:bg-gray-100"
        @click="close"
      >
        <span class="sr-only">{{ $t('common.close') }}</span>
        <XMarkIcon
          aria-hidden="true"
          class="w-5 h-5 text-gray-900"
        />
      </button>
    </div>
  </aside>
</template>
