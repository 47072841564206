<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, toRaw } from "vue"

import {
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue"
import { uniq, without } from "lodash-es"

// internal
import { useDocumentStore } from "~/stores"
import { DocumentUser, DocumentUserRoleEnum } from "~/types"
import { SpinLoader } from ".."
// import { documentUserRoles } from "~/utils"
// import { useDocumentStore } from "~/stores"

interface Props {
  userRoles?: DocumentUser["roles"]
  user?: Partial<DocumentUser>
  isDisabled?: boolean
  label?: string
  isLoading?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    userRoles: () => [],
    user: null,
    isDisabled: false,
    label: null,
    isLoading: false,
  },
)

const documentStore = useDocumentStore()
const { isLockedDocument } = storeToRefs(documentStore)

const emit = defineEmits([ "update:roles" ])

const setSwitchRole = (role: DocumentUserRoleEnum, value: boolean) => {
  let newValue = []
  if (value) {
    newValue = uniq([ ...toRaw(props.userRoles), role ])
  } else {
    newValue = without(props.userRoles, role)
  }
  emit("update:roles", newValue)
}

const isSignatory = computed<boolean>(() => props.userRoles?.includes(DocumentUserRoleEnum.signatory))

</script>

<template>
  <div>
    <label
      :for="'signatoryRoleSwitch_'+(props.user?.uuid || 'new')"
      class="block mt-2 mb-1 text-sm font-medium text-gray-700"
    >
      {{ $t('documentUserRoles.signatory.name') }}
    </label>
    <ul
      role="list"
      class="grid grid-cols-1 bg-gray-100 divide-y divide-gray-200 rounded-md"
    >
      <li>
        <div
          v-if="isLockedDocument || isDisabled"
          class="flex items-center px-3 py-2 space-x-3 opacity-50 cursor-not-allowed"
        >
          <div
            :class="[
              isSignatory
                ? 'bg-indigo-600'
                : 'bg-gray-300',
              'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
          >
            <span
              aria-hidden="true"
              :class="[
                isSignatory
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0',
              ]"
            />
          </div>
          <span class="flex items-center justify-between gap-3 p-0 text-sm text-gray-700 grow">
            <span>{{ props.label ? label : $t('documentUserRoles.signatory.description') }}</span>
            <SpinLoader
              v-if="props.isLoading"
              class="w-4 h-4 text-gray-700"
            />
          </span>
        </div>
        <SwitchGroup
          v-else
          v-cy="`user-form-signatory-switch`"
          as="div"
          class="flex items-center px-3 py-2 space-x-3"
        >
          <Switch
            :id="'signatoryRoleSwitch_'+(props.user?.uuid || 'new')"
            :model-value="!!(isSignatory)"
            :class="[
              isSignatory
                ? 'bg-indigo-600'
                : 'bg-gray-300',
              'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
            @update:model-value="setSwitchRole(DocumentUserRoleEnum.signatory, $event)"
          >
            <span
              aria-hidden="true"
              :class="[
                isSignatory
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]"
            />
          </Switch>
          <SwitchLabel
            as="span"
            class="flex items-center justify-between gap-3 p-0 text-sm text-gray-700 grow"
            passive
          >
            <span>{{ props.label ? label : $t('documentUserRoles.signatory.description') }}</span>
            <SpinLoader
              v-if="props.isLoading"
              class="w-4 h-4 text-gray-700"
            />
          </SwitchLabel>
        </SwitchGroup>
      </li>
    </ul>
    <slot name="errors" />
  </div>
</template>
