<script setup lang="ts">
//external
import { computed } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"

// internal
import { Import } from "~/types"
import { OptionList } from "~/components"

interface Props {
  imports: Import[]
  selectedUuids?: Import["uuid"][]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedUuids: () => [],
  },
)

const emit = defineEmits([ "update:selected-uuids" ])

const selectedImports = computed({
  get: () => props.imports.filter((el) => props.selectedUuids.includes(el.uuid)),
  set: (newVal) => {
    const uuids = newVal.length ? newVal.map((el) => el.uuid) : []
    emit("update:selected-uuids", uuids)
  },
})

const selectedOptions = computed(() => selectedImports.value.map((el) => {
  return {
    label: el.name,
    value: el.uuid,
  }
}))

const removeOption = (option) => {
  emit("update:selected-uuids", props.selectedUuids.filter((value) => value !== option.value))
}

</script>

<template>
  <div>
    <Listbox
      v-model="selectedImports"
      multiple
      as="div"
    >
      <div class="relative mt-1">
        <ListboxButton class="btn-listbox-plain">
          <span
            class="block text-gray-500 truncate"
          >{{ $t('imports.selectImports') }}…</span>

          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              class="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="listbox-options max-w-full">
            <ListboxOption
              v-for="el in imports"
              :key="'import_' + el.uuid"
              v-slot="{ active, selected }"
              as="template"
              :value="el"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                  <span>{{ el.name }}</span>
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-indigo-500',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <OptionList
      v-if="selectedOptions.length"
      class="mt-2 max-w-full"
      :options="selectedOptions"
      @remove="removeOption"
    />
  </div>
</template>
