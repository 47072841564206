<script setup lang="ts">
// external
import {
  Combobox,
  ComboboxButton,
  ComboboxInput, ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue"
import { ArrowRightOnRectangleIcon, CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { computed, ref } from "vue"
import { Link } from "@inertiajs/vue3"
import { storeToRefs } from "pinia"

// internal
import { MultiFieldIcon, SpinLoader } from "~/components"
import { Metadata } from "~/types"
import { useDocumentStore } from "~/stores"
import { sortByMetadataName, filterByMetadataName } from "~/utils"
import { useI18n } from "vue-i18n"

interface Props {
  attachableMetadata?: Metadata[]
  isLoadingMetadata?: boolean
  selectedMetadata?: Metadata
  dropdownPosition?: "top" | "bottom"
  isLoading?: boolean
  disabled?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    attachableMetadata: () => [],
    isLoadingMetadata: false,
    selectedMetadata: null,
    position: "bottom",
    isLoading: false,
    disabled: false,
  },
)

const emit = defineEmits([ "update:selected-metadata" ])

const query = ref("")

const selectedMetadata = computed({
  get: () => props.selectedMetadata,
  set: (value) => {
    emit("update:selected-metadata", value)
    query.value = ""
  },
})

const { t } = useI18n()

const filteredAttachableMetadata = computed(() => {
  return filterByMetadataName(sortByMetadataName(props.attachableMetadata, t), t, query.value)
})

const documentStore = useDocumentStore()
const { mau } = storeToRefs(documentStore)

</script>

<template>
  <div
    class="min-w-[18rem] bg-gray-100 border-2 border-indigo-900 rounded-md divide-y divide-gray-100 shadow-lg ring ring-3 ring-indigo-500 ring-opacity-30"
  >
    <Combobox
      v-model="selectedMetadata"
      as="div"
      nullable
      :disabled="isLoading || disabled"
    >
      <div class="relative">
        <ComboboxInput
          :placeholder="$t('accountSettings.metadata.enterName') + '…'"
          class="w-full py-2 pl-3 pr-10 border-0 rounded input-primary"
          :display-value="
            (metadata: Metadata) => metadata?.display_name
              ? metadata?.display_name
              : metadata?.name || ''
          "
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none"
          :disabled="isLoading || disabled"
        >
          <SpinLoader
            v-if="isLoading"
            class="w-5 h-5 text-gray-400"
          />
          <ChevronUpDownIcon
            v-else
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>
      </div>

      <div v-show="!isLoading">
        <ComboboxOptions
          v-if="filteredAttachableMetadata.length > 0"
          :class="dropdownPosition === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'"
          class="listbox-options"
          static
        >
          <ComboboxOption
            v-for="metadata in filteredAttachableMetadata"
            :key="metadata.uuid"
            v-slot="{ active, selected }"
            :value="metadata"
            as="template"
            :disabled="false"
          >
            <li
              :class="[
                'listbox-option pr-11 cursor-pointer',
                active ? 'bg-gray-700' : '',
                false ? 'opacity-25' : '',
              ]"
            >
              <div class="flex items-center text-sm">
                <span :class="[selected && 'font-semibold']">
                  {{ metadata.type.includes('system') ? $t(`metadata.system.${metadata.name}.name`) : metadata.display_name }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                  active ? 'text-white' : 'text-indigo-500',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
              <span
                v-else
                class="absolute inset-y-0 flex items-center right-4"
                :title="$t('dynamicFields.form.type') + ': ' + $t('multiFieldTypes.' + metadata.value_type)"
              >
                <MultiFieldIcon
                  :type="metadata.value_type"
                  class="shrink-0"
                  :class="active ? 'text-slate-100' : 'text-slate-600'"
                />
              </span>
            </li>
          </ComboboxOption>
          <div
            v-if="mau?.permissions.includes('metadata_manage')"
            class="px-5 py-2 text-sm border-t border-t-slate-700"
          >
            <Link
              :href="route('account-settings.metadata.index')"
              class="flex items-center gap-1 font-medium text-indigo-400 hover:text-indigo-300"
            >
              <ArrowRightOnRectangleIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('accountSettings.metadata.manageMetadata') }}
            </Link>
          </div>
        </ComboboxOptions>
        <div
          v-else
          class="mt-2 listbox-options top-full"
        >
          <div class="px-4 py-2 text-sm text-gray-400">
            <div>{{ $t('common.noMatchingResults') }}</div>
          </div>
          <div
            v-if="mau?.permissions.includes('metadata_manage')"
            class="px-4 py-2 text-sm border-t border-t-slate-700"
          >
            <Link
              :href="route('account-settings.metadata.index')"
              class="flex items-center gap-1 font-medium text-indigo-400 hover:text-indigo-300"
            >
              <ArrowRightOnRectangleIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('accountSettings.metadata.manageMetadata') }}
            </Link>
          </div>
        </div>
      </div>
    </Combobox>
  </div>
</template>
