<script setup lang="ts">
import { computed } from "vue"
import { AccountColorSettings } from "~/types"
import { hexToGrayscaleValue } from "~/utils"

interface Props {
  accountLogoImageUrl?: string
  ciColors: AccountColorSettings
  accountEmailSignature?: string
}

const props = withDefaults(defineProps<Props>(), {
  accountLogoImageUrl: null,
})

const illustrationColor = computed<"dark" | "light">(() => {
  const contrast = Math.abs(hexToGrayscaleValue(props.ciColors.bgColor) - hexToGrayscaleValue("#FFFFFF"))
  return contrast < 60 ? "dark" : "light"
})

</script>

<template>
  <div class="mail-mockup">
    <table
      class="wrapper"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tr>
        <td align="center">
          <table
            class="content"
            width="100%"
            cellpadding="0"
            cellspacing="0"
            role="presentation"
          >
            <tr>
              <td class="header">
                <template v-if="!accountLogoImageUrl">
                  <div style="display: inline-block;">
                    <img
                      src="https://fynk.com/images/fynk-logo.svg"
                      class="logo"
                      height="100"
                      alt="fynk"
                    >
                  </div>
                </template>
              </td>
            </tr>
            <!-- Email Body -->
            <tr>
              <td
                class="body"
                width="100%"
                cellpadding="0"
                cellspacing="0"
                style="border: hidden !important;"
              >
                <table
                  class="inner-body"
                  align="center"
                  width="570"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                >
                  <!-- Body content -->
                  <tr>
                    <td class="content-cell">
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                      >
                        <template v-if="accountLogoImageUrl">
                          <tr>
                            <td
                              align="center"
                              class="logo-wrapper"
                            >
                              <img
                                :src="accountLogoImageUrl"
                                height="100"
                                class="logo"
                                alt=""
                              >
                            </td>
                          </tr>
                          <tr />
                        </template>
                        <td
                          align="center"
                          class="hero-wrapper"
                          :style="`background: ${ciColors.bgColor}; color: ${ciColors.textColor}`"
                        >
                          <img
                            :src="illustrationColor === 'light' ? 'https://fynk.com/images/fynk-email-default-icon.svg' : 'https://fynk.com/images/fynk-email-default-icon-dark.svg'"
                            class="illustration"
                            height="80"
                            alt=""
                          >

                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nec sagittis mauris. Mauris suscipit interdum lorem et porttitor. Integer quis cursus metus.

                          <table
                            class="action"
                            align="center"
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                          >
                            <tr>
                              <td align="center">
                                <table
                                  width="100%"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <tr>
                                    <td align="center">
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        role="presentation"
                                      >
                                        <tr>
                                          <td>
                                            <span
                                              class="button"
                                              :style="`background-color: ${ciColors.primaryColor}; border-bottom-color: ${ciColors.primaryColor}; border-left-color: ${ciColors.primaryColor}; border-right-color: ${ciColors.primaryColor}; border-top-color: ${ciColors.primaryColor}; color: ${ciColors.textOnPrimaryColor}`"
                                              target="_blank"
                                              rel="noopener"
                                            >Button</span>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>


                          <table
                            class="panel"
                            :style="`border-color: rgba(0,0,0,0.35);`"
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                          >
                            <tr>
                              <td
                                class="panel-content"
                                :style="`background-color: rgba(0,0,0,0.1); color: ${ciColors.textColor}`"
                              >
                                <table
                                  width="100%"
                                  cellpadding="0"
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <tr>
                                    <td class="panel-item">
                                      Lorem panel ipsum.
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td class="content-cell">
                      <table
                        class="panel"
                        :style="`border-color: rgba(0,0,0,0.35);`"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                      >
                        <tr>
                          <td
                            class="panel-content"
                            :style="`background-color: rgba(0,0,0,0.1); color: #000`"
                          >
                            <table
                              width="100%"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                            >
                              <tr>
                                <td class="panel-item">
                                  Lorem panel ipsum.
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td class="content-cell">
                      <table
                        class="subcopy"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                      >
                        <tr>
                          <td>
                            <p>
                              Aliquam ultrices tortor sed nulla vestibulum, nec porta ante euismod. Ut elementum turpis et velit aliquam porta. Donec congue dictum dui at porttitor. Morbi felis enim, condimentum sed mi a, rutrum consectetur justo. Curabitur a rhoncus sapien, et finibus purus. Vivamus quis ante ac justo dignissim laoreet nec at eros. Mauris at ullamcorper est.
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td class="content-cell">
                      <table
                        class="footer"
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                      >
                        <tr>
                          <td>
                            <p class="subcopy">
                              Aliquam ultrices tortor sed nulla vestibulum, nec porta ante euismod. Ut elementum turpis et velit aliquam porta. Donec congue dictum dui at porttitor. Morbi felis enim, condimentum sed mi a, rutrum consectetur justo. Curabitur a rhoncus sapien, et finibus purus. Vivamus quis ante ac justo dignissim laoreet nec at eros. Mauris at ullamcorper est.
                            </p>
                          </td>
                        </tr>
                        <tr v-if="accountEmailSignature">
                          <td>
                            <hr class="">
                            <p class="subcopy">
                              {{ accountEmailSignature }}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <table
            class="footer"
            align="center"
            width="570"
            cellpadding="0"
            cellspacing="0"
            role="presentation"
          >
            <tr>
              <td
                class="content-cell"
                align="center"
              >
                <p class="sub">
                  Lorem ipsum dolor sit amet.
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>

/* Base */

.mail-mockup {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    position: relative;
    -webkit-text-size-adjust: none;
    background-color: #FFFFFF;
    color: #6B7280;
    height: 100%;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    width: 100% !important;


    p,
    ul,
    ol,
    blockquote {
        line-height: 1.4;
        text-align: left;
    }

    a {
        color: #6366F1;
    }

    a img {
        border: none;
    }

    /* Typography */

    h1 {
        color: #111827;
        font-size: 22px;
        font-weight: bold;
        margin-top: 0;
        text-align: left;
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0;
        text-align: left;
    }

    h3 {
        font-size: 14px;
        font-weight: bold;
        margin-top: 0;
        text-align: left;
    }

    p {
        font-size: 16px;
        line-height: 1.5em;
        margin-top: 0;
        text-align: left;
    }

    p.sub {
        font-size: 12px;
    }

    img {
        max-width: 100%;
    }

    /* Layout */

    .wrapper {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        background-color: #F4F4F4;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .content {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    /* Header */

    .header {
        padding: 25px 0;
        text-align: center;
    }

    .header a {
        color: #312E81;
        font-size: 19px;
        font-weight: bold;
        text-decoration: none;
    }

    /* Logo */

    .logo {
        height: 75px;
        max-height: 75px;
        width: 170px;
        max-width: 170px;
        margin: 0 auto;
    }

    /* Body */

    .body {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        background-color: #F4F4F4;
        border-bottom: 1px solid #F4F4F4;
        border-top: 1px solid #F4F4F4;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .inner-body {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 570px;
        background-color: #FFFFFF;
        border-radius: 2px;
        box-shadow: 0 2px 0 rgba(0, 0, 150, 0.025), 2px 4px 0 rgba(0, 0, 150, 0.015);
        margin: 0 auto;
        padding: 0;
        width: 570px;
    }

    /* Logo & illustration */

    .logo-wrapper {
        max-width: 100%;
        text-align: center;
        padding-bottom: 15px;
    }

    .logo {
        height: 100px;
        width: auto;
        max-width: 100%;
    }

    .hero-wrapper {
        padding-top: 15px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 15px;
        color: #FFF;
    }

    .illustration {
        height: 80px;
        max-width: 240px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    /* Subcopy */

    .subcopy {
        /* margin-top: 25px; */
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .subcopy table {
        padding-top: 15px;
    }

    .subcopy p {
        font-size: 14px;
    }

    /* Footer */

    .footer {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 570px;
        margin: 0 auto 25px auto;
        padding: 0 0 25px 0;
        text-align: center;
        width: 570px;
    }

    .footer p {
        color: #9ca3af;
        font-size: 12px;
        text-align: center;
    }

    .footer a {
        color: #A5B4FC;
        text-decoration: underline;
    }

    /* Tables */

    .table table {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        margin: 30px auto;
        width: 100%;
    }

    .table th {
        border-bottom: 1px solid #E5E7EB;
        margin: 0;
        padding-bottom: 8px;
    }

    .table td {
        color: #6B7280;
        font-size: 15px;
        line-height: 18px;
        margin: 0;
        padding: 10px 0;
    }

    .content-cell {
        max-width: 100vw;
        padding: 25px;
        padding-bottom: 0px;
    }

    /* Buttons */

    .action {
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        margin: 30px auto 20px;
        padding: 0;
        text-align: center;
        width: 100%;
    }

    .button {
        -webkit-text-size-adjust: none;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        overflow: hidden;
        text-decoration: none;
        border-bottom-width: 8px;
        border-top-width: 8px;
        border-left-width: 18px;
        border-right-width: 18px;
        border-style: solid;
        box-shadow: 0 0 2px rgba(0, 0, 150, 0.2);
        font-weight: 700;
    }

    .button-blue,
    .button-primary {
        background-color: #4f46e5;
        border-bottom: 8px solid #4f46e5;
        border-left: 18px solid #4f46e5;
        border-right: 18px solid #4f46e5;
        border-top: 8px solid #4f46e5;
        border-radius: 8px;
    }

    .button-blue:hover,
    .button-primary:hover {
      background-color: #4338ca;
      border-bottom-color: #4338ca;
      border-left-color: #4338ca;
      border-right-color: #4338ca;
      border-top-color: #4338ca;
    }

    .button-green,
    .button-success {
        background-color: #16a34a;
        border-bottom: 8px solid #16a34a;
        border-left: 18px solid #16a34a;
        border-right: 18px solid #16a34a;
        border-top: 8px solid #16a34a;
    }

    .button-green:hover,
    .button-success:hover {
      background-color: #15803d;
      border-bottom-color: #15803d;
      border-left-color: #15803d;
      border-right-color: #15803d;
      border-top-color: #15803d;
    }

    .button-red,
    .button-error {
        background-color: #dc2626;
        border-bottom: 8px solid #dc2626;
        border-left: 18px solid #dc2626;
        border-right: 18px solid #dc2626;
        border-top: 8px solid #dc2626;
    }

    .button-red:hover,
    .button-error:hover {
      background-color: #b91c1c;
      border-bottom-color: #b91c1c;
      border-left-color: #b91c1c;
      border-right-color: #b91c1c;
      border-top-color: #b91c1c;
    }

    /* Panels */

    .panel {
        border-left: #2d3748 solid 4px;
        margin: 21px 0;
    }

    .panel-content {
        background-color: #EEF2FF;
        color: #6B7280;
        padding: 5px;
        padding-left: 15px;
    }

    .panel-content p {
        color: #6B7280;
    }

    .panel-item {
        padding: 0;
        white-space: pre-wrap;
    }

    .panel-item p:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        line-height: 0;
    }

    .subcopy {
      margin-bottom: 5px;
      white-space: pre-wrap;
    }
}

</style>
