import { toRaw, toRefs, watch } from "vue"

import { Extension } from "@tiptap/core"
import { Node as PMNode } from "@tiptap/pm/model"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { Decoration, DecorationSet } from "@tiptap/pm/view"
import { intersection, isEqual } from "lodash-es"

import { useConditionStore } from "~/stores"
import { Condition, CrudContext } from "~/types"

import UuidMarker from "../utils/uuidMarker"

const highlightClasses = "condition-marker"

const renderHighlight = (
  result,
  active,
  clickCallback: () => void,
) => {
  const highlight = document.createElement("div")

  const htmlContent = "<div><div><div class=\"click-listener\"><svg viewBox=\"0 0 24 24\"><path fill=\"currentColor\" d=\"M13 8v8a3 3 0 0 1-3 3H7.83a3.001 3.001 0 1 1 0-2H10a1 1 0 0 0 1-1V8a3 3 0 0 1 3-3h3V2l5 4-5 4V7h-3a1 1 0 0 0-1 1zM5 19a1 1 0 1 0 0-2 1 1 0 0 0 0 2z\"/></svg></div></div></div>"

  highlight.innerHTML = htmlContent

  highlight.className = active
    ? `${highlightClasses} condition-marker-active`
    : `${highlightClasses} condition-marker-inactive`;

  (highlight as any).result = result

  highlight.querySelector("div.click-listener")?.addEventListener("click", clickCallback)

  return highlight
}

// nodes that could have conditions
const conditionalNodes: string[] = [
  "heading",
  "paragraph",
  "fineprint",
  "list",
  "table",
  "columnBlock",
]

interface MarkConditionsProps {
  doc: PMNode
  editorContext: CrudContext
  conditions: Partial<Condition>[]
  activeConditions: Partial<Condition>[]
  clickCallback: (uuids: string[], forceUuid: boolean, attachPopoverToSidebar?: boolean, conditionUuid?: Condition["uuid"]) => boolean
  setConditionsDecoSet: (decoSet: DecorationSet) => void
}

const markConditions = ({
  doc,
  editorContext,
  conditions = [],
  activeConditions = [],
  clickCallback,
  setConditionsDecoSet,
}: MarkConditionsProps) => {

  const decorations = []

  // Build query by concatenating all uuids that are not yet included in the state storage
  // First, check each uuid included in activeConditions if array or the one if it's an object
  const uuidsToCheck = activeConditions.map((el) => el.uuids).flat()
  // Then, check for which uuid a conditions.some check returns true and build an array of uuids to concat for the rest
  const uuidsToConcat = uuidsToCheck.filter((uuid) => !conditions.some((el) => el.uuids?.includes(uuid))) || []
  // Concatenate where necessary
  const query = uuidsToConcat.length && Array.isArray(activeConditions) ? conditions.concat(activeConditions.filter((el) => intersection(uuidsToConcat, el.uuids)?.length > 0)) : conditions
  const results = new UuidMarker(doc)
    .scan(query, conditionalNodes)
    .getResults()

  results.forEach(
    (result) => {
      let active = true

      active = result.type !== "new" && result.item?.is_active

      if (editorContext === CrudContext.template || result.type !== "new") {
        decorations.push(
          Decoration.widget(
            result.from,
            renderHighlight(result, active, () => clickCallback([ result.node.attrs.uuid ], true)),
            {
              key: "condition-" + result.number,
              side: -1,
              ignoreSelection: true,
            },
          ),
        )
      }

      if (result.type !== "new") {
        decorations.push(
          Decoration.node(
            result.from - 1,
            result.from - 1 + result.node.nodeSize,
            {
              class: active
                ? "has-condition condition-active"
                : "has-condition condition-inactive",
            },
          ),
        )
      }
    },
  )

  const decoSet = DecorationSet.create(doc, decorations)
  setConditionsDecoSet(decoSet)

  return decoSet
}

interface HighlightConditionsOptions {
  restricted: boolean
  setIsDeleteConfirmationModalOpen: (isOpen: boolean) => void
  editorContext: CrudContext
}

export const HighlightConditions = Extension.create<HighlightConditionsOptions>({
  name: "highlightConditions",

  addOptions () {
    return {
      restricted: false,
      setIsDeleteConfirmationModalOpen: () => {
        //
      },
      editorContext: CrudContext.template,
    }
  },

  addStorage () {
    return {
      decoSet: DecorationSet.empty,
    }
  },

  addProseMirrorPlugins () {
    const { editor } = this
    const { setIsDeleteConfirmationModalOpen } = this.options

    const setConditionsDecoSet = (decorations) => editor.storage.highlightConditions.decoSet = decorations

    const { editorContext } = this.options

    const conditionStore = useConditionStore()
    const { conditions } = toRefs(conditionStore)
    const { conditionsClickHandler } = conditionStore

    watch(
      [ () => conditionStore.conditions, () => conditionStore.activeConditions ],
      (val, oldVal) => {
        if (isEqual(val, oldVal)) return
        editor.commands.directDecoration()
      },
      { deep: true },
    )

    return [
      new Plugin({
        key: new PluginKey(this.name),

        state: {
          init (_, { doc }) {
            const res = markConditions(
              {
                doc: doc,
                editorContext: editorContext,
                conditions: toRaw(conditionStore.conditions || []).slice(),
                activeConditions: conditionStore.activeConditions,
                clickCallback: conditionsClickHandler,
                setConditionsDecoSet,
              },
            )
            return res
          },

          apply (transaction, oldDecoSet) {
            const directDecoration = transaction.getMeta("directDecoration")
            if (directDecoration) {
              const res = markConditions(
                {
                  doc: transaction.doc,
                  editorContext: editorContext,
                  conditions: toRaw(conditionStore.conditions || []).slice(),
                  activeConditions: conditionStore.activeConditions,
                  clickCallback: conditionsClickHandler,
                  setConditionsDecoSet,
                },
              )
              return res
            }
            if (transaction.docChanged) return DecorationSet.empty
            return oldDecoSet || editor.storage.highlightConditions.decoSet
          },
        },

        props: {
          decorations (state) {
            const decos = this.getState(state)
            setConditionsDecoSet(decos)
            return decos
          },
        },

        filterTransaction (tr, oldState) {
          if (!tr.docChanged || tr.getMeta("forceDeleteSelectionWithIssues")) return true

          const oldStateNodes = []

          oldState.doc.descendants(
            (node, pos) => {
              if (![ "heading", "paragraph", "fineprint", "list", "table", "columnBlock" ].includes(node.type.name)) return

              oldStateNodes.push(
                {
                  from: pos,
                  to: pos + node.nodeSize,
                  uuid: node.attrs.uuid,
                },
              )
            },
          )

          const newStateNodes = []

          tr.doc.descendants((node, pos) => {
            if (![ "heading", "paragraph", "fineprint", "list", "table", "columnBlock" ].includes(node.type.name)) return

            newStateNodes.push(
              {
                from: pos,
                to: pos + node.nodeSize,
                uuid: node.attrs.uuid,
              },
            )
          })

          if (oldStateNodes.length === newStateNodes.length) return true

          const deletedNodes = []

          for (const nodeWithRange of oldStateNodes) {
            const { uuid } = nodeWithRange

            const isNodeInNewState = newStateNodes
              .findIndex(({ uuid: nodeId }) => uuid === nodeId) !== -1

            if (!isNodeInNewState) deletedNodes.push(nodeWithRange)
          }

          if (!deletedNodes.length) return true

          const deletedNodesWithConditions = deletedNodes.filter(
            ({ uuid }) => conditions.value?.some((c) => c.uuids?.includes(uuid)),
          )

          const isEmpty = !deletedNodesWithConditions.length

          if (isEmpty) return true

          setIsDeleteConfirmationModalOpen(true)

          return false
        },

      }),
    ]
  },
})

export default HighlightConditions
