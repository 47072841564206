<script setup lang="ts">
// external
import { ref, computed } from "vue"

// internal
import { OptionList } from "~/components"

interface Props {
  modelValue: string[]|string
  disabled?: boolean
  required?: boolean
  error?: boolean
  buttonText?: string
  placeholder?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  required: false,
  error: false,
  buttonText: null,
  placeholder: null,
})

const emit = defineEmits([ "update:model-value" ])

const optionText = ref("")

const selectedOptions = computed<string[]>({
  get: () => Array.isArray(props.modelValue) ? props.modelValue : [ props.modelValue ],
  set: (options) => emit("update:model-value", [ ...options ]),
})

const addOption = (e) => {
  e.preventDefault()
  if (!!optionText.value && !selectedOptions.value.includes(optionText.value)) {
    selectedOptions.value.push(optionText.value.replace(",", "").trim())
    optionText.value = ""
  }
}

const removeOption = (optionText) => {
  selectedOptions.value.splice(selectedOptions.value.indexOf(optionText), 1)
}

const hasError = computed(() => props.error && !selectedOptions.value.length)

</script>

<template>
  <div class="text-sm">
    <div class="flex items-center space-x-1">
      <input
        v-model="optionText"
        type="text"
        class="relative z-10 rounded-r-none input-plain"
        :placeholder="placeholder || $t('accountSettings.metadata.addOption') + '…'"
        :class="[hasError ? 'error' : '']"
        :disabled="disabled"
        maxlength="255"
        @keyup.enter.stop.prevent="addOption"
        @keyup.,.stop.prevent="addOption"
      >
      <button
        type="button"
        class="relative min-h-[36px] inline-flex items-center py-2 px-3 text-xs font-semibold text-gray-900 bg-gray-100 rounded-r-md sm:text-sm hover:bg-gray-200 focus:z-10 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 disabled:text-gray-400 disabled:bg-gray-50 disabled:cursor-not-allowed"
        :disabled="disabled"
        @click="addOption"
      >
        <span class="block -my-px">{{ buttonText || $t('common.add') }}</span>
      </button>
    </div>

    <OptionList
      class="mt-2"
      :options="selectedOptions"
      @remove="removeOption"
    />
  </div>
</template>
