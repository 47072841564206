<script setup lang="ts">
// external
import { computed } from "vue"
import { CalendarIcon, ArrowPathIcon, ArchiveBoxArrowDownIcon } from "@heroicons/vue/20/solid"

// internal
import { formatDateRelative, formatDateAndTime } from "~/utils"

interface Props {
  entry: any
  sortAttribute?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    sortAttribute: null,
  },
)

const timestamps = [  "updated_at", "created_at", "archived_at" ]

const entryTimestamps = computed(() => {
  return timestamps.filter((timestamp) => props.entry.hasOwnProperty(timestamp) && !!props.entry[timestamp])
})
</script>
<template>
  <div class="flex flex-col space-y-1 text-gray-500 text-xs justify-start">
    <div
      v-for="timestamp in entryTimestamps"
      :key="timestamp"
      class="flex items-center space-x-1 whitespace-nowrap cursor-default"
      data-tippy-list
      :data-tippy-content="`${$t('magicTable.columns.' + timestamp)}:\n${formatDateAndTime(entry[timestamp])}`"
      data-placement="left"
    >
      <component
        :is="timestamp === 'created_at' ? CalendarIcon : timestamp === 'updated_at' ? ArrowPathIcon : ArchiveBoxArrowDownIcon"
        aria-hidden="true"
        class="w-4 h-4"
      />
      <time
        :datetime="entry[timestamp]"
      >{{
        formatDateRelative(entry[timestamp])
      }}</time>
    </div>
  </div>
</template>
