<script setup lang="ts">
// external
import { ref, computed } from "vue"
import axios from "axios"
import { useI18n } from "vue-i18n"

// internal
import { SpinLoader, FileInput, FormInputErrors, DialogModal } from "~/components"
import { useDocumentStore, useNotificationStore } from "~/stores"
import { storeToRefs } from "pinia"

interface Props {
  disabled?: boolean
}

withDefaults(
  defineProps<Props>(),
  {
    disabled: false,
  },
)

interface DocumentPayload {
  pdf_uuid: string
}

const { notify } = useNotificationStore()
const { t } = useI18n()

const documentStore = useDocumentStore()
const { currentDocument, isPdfImportModalOpen } = storeToRefs(documentStore)
const { setIsPdfImportModalOpen, fetchPdfUrl } = documentStore

const backendErrors = ref<Partial<Record<keyof DocumentPayload, string[]>>>({})

const errorsToShow = computed<Partial<Record<keyof DocumentPayload, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentPayload, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const pdfUuid = ref<string>()
const pdfFileName = ref<string>()

const handleFileUploadSuccess = (file_uuid: string, file_name: string) => {
  pdfUuid.value = file_uuid
  pdfFileName.value =  file_name
}

const isLoadingSaveRevision = ref<boolean>(false)

const handleUploadRevision = async (): Promise<void> => {
  isLoadingSaveRevision.value = true
  const payload = {
    pdf_uuid: pdfUuid.value,
  }
  const apiUrl = route("api.documents.document-pdf.update", currentDocument.value?.uuid)
  return axios.patch(apiUrl, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    fetchPdfUrl(currentDocument.value?.uuid)
    setIsPdfImportModalOpen(false)
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: t("documents.fileUploadError"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  }).finally(() => {
    isLoadingSaveRevision.value = false
  })
}

const close = () => {
  setIsPdfImportModalOpen(false)
}
</script>

<template>
  <DialogModal
    :show="isPdfImportModalOpen"
    show-close-button
    max-width="md"
    @close="close"
  >
    <template #title>
      <h2 class="mb-2 font-semibold text-black">
        {{ $t('documents.importPdfRevision') }}
      </h2>
    </template>

    <template #content>
      <FileInput
        :upload-callback="handleFileUploadSuccess"
        :form-errors="errorsToShow"
      />
      <FormInputErrors
        v-if="errorsToShow?.pdf_uuid?.length"
        :errors="errorsToShow?.pdf_uuid"
      />
    </template>

    <template #footer>
      <div class="flex items-center justify-center">
        <button
          type="button"
          :disabled="!(pdfUuid) || !(pdfFileName)"
          class="flex items-center gap-2 btn-primary"
          @click="handleUploadRevision"
        >
          <SpinLoader
            v-if="isLoadingSaveRevision"
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          <span v-if="!isLoadingSaveRevision">{{ $t('common.upload') }}</span>
          <span v-else>{{ $t('common.pleaseWait') }}…</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
