<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref, watch, nextTick, toRaw } from "vue"

import { MenuButton, Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { PlusIcon } from "@heroicons/vue/20/solid"
import { VariableIcon, IdentificationIcon as IdentificationIconOutline } from "@heroicons/vue/24/outline"
import { CheckIcon, EllipsisHorizontalIcon, LinkIcon, QuestionMarkCircleIcon, TrashIcon } from "@heroicons/vue/24/solid"
import { omit, sortBy } from "lodash-es"
import { useI18n } from "vue-i18n"
import draggable from "vuedraggable"

// internal
import { Dropdown, DropdownLink, EmptyState, GenerateSignatureBlockDropdown, MultiFieldIcon, OverlayScrollbar, PartyEntityTypeDisplayIcon, SignatureSignatoryDisplay, SkeletonLoader, SpinLoader, TabPills } from "~/components"
import { DragIndicatorIcon, SignatureIcon, DuplicateIcon, MinimalSignatureBlockIcon, DefaultSignatureBlockIcon } from "~/icons"
import { useConfirmationStore, useDocumentStore, useDynamicFieldStore, useEditorStore, useNotificationStore, usePartyStore, usePdfBrickStore, usePusherStore, useSharedStore, useSignatureStore, useTemplateStore, useUserStore } from "~/stores"
import { CrudContext, Document, DocumentContentType, DocumentUser, DocumentUserRoleEnum, DynamicField, EditorContentTab, Party, SignatureBlock, SignatureBlockStyle, Template } from "~/types"
import { IS_CYPRESS_ENV } from "~/utils"

const pdfBrickStore = usePdfBrickStore()

const { t } = useI18n()

const { notify } = useNotificationStore()

const signatureStore = useSignatureStore()
const { signatureBlockUuidBeingRemoved, signatureBlocks, isLoadingSignatureBlocks, uuidsOfIsUpdatingSignatureBlock } = storeToRefs(signatureStore)
const { checkSignature, checkIfSignatureBlockPlacedInDocument, updateSignatureBlock } = signatureStore

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const documentStore = useDocumentStore()
const { prosemirrorData: documentProsemirrorData, currentDocument, mdu, mau, isLockedDocument } = storeToRefs(documentStore)

const templateStore = useTemplateStore()
const { prosemirrorData: templateProsemirrorData } = storeToRefs(templateStore)

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFields: storeDynamicFields, isLoadingDynamicFields, dynamicFieldUuidBeingRemoved } = storeToRefs(dynamicFieldStore)
const { removeDynamicField, pushOrUpdateDynamicField, syncDynamicFieldOrder, setDataTransferData, clearDataTransferData, setDynamicFieldOrderToDocumentOrder } = dynamicFieldStore

const editorStore = useEditorStore()
const { isDirty } = storeToRefs(editorStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)
const { setSidebarScrollContainer } = sharedStore

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const partyStore = usePartyStore()
const { parties, isLoadingParties } = storeToRefs(partyStore)

const editorContentBlocksScrollContainer = ref()

const prosemirrorData = crudContext.value === CrudContext.document
  ? documentProsemirrorData
  : templateProsemirrorData

const signatureBlockPlacedInDocumentSwitch = ref<boolean>(false)

const dynamicFields = computed({
  get: () => {
    return sortBy(storeDynamicFields.value, "order")
  },
  set: (val) => {

    val.forEach((el, idx) => {
      const pos = idx + 1
      const dynamicFieldToUpdate = storeDynamicFields.value?.find((d: DynamicField) => d.uuid === el.uuid)
      if (!dynamicFieldToUpdate) return
      const newValue = {
        ...dynamicFieldToUpdate,
        uuid: el.uuid,
        order: toRaw(pos),
      }
      pushOrUpdateDynamicField(newValue)
    })

    syncDynamicFieldOrder(crudContext.value, entityUuid.value, val.map((dynamicField) => dynamicField.uuid))

  },
})

const isLoadingSetDynamicFieldOrderToDocumentOrder = ref(false)

const handleSetDynamicFieldOrderToDocumentOrder = async () => {
  isLoadingSetDynamicFieldOrderToDocumentOrder.value = true
  await setDynamicFieldOrderToDocumentOrder(crudContext.value, entityUuid.value)
  isLoadingSetDynamicFieldOrderToDocumentOrder.value = false
}

watch(prosemirrorData, () => signatureBlockPlacedInDocumentSwitch.value = !signatureBlockPlacedInDocumentSwitch.value)

// Calculate entity uuid for db calls
const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return crudContext.value === CrudContext.document
    ? documentStore.currentDocument?.uuid
    : templateStore.currentTemplate?.uuid
})

const signatureBlocksToShow = computed(
  () => signatureBlocks.value?.filter((el: SignatureBlock) => !el.deleted_at) || [],
)

const mapSignatureBlockUuidsPlaceInDocument = computed(
  () => {
    const map = new Map()

    signatureBlocksToShow.value.forEach(
      (el: SignatureBlock) => map.set(el.uuid, checkIfSignatureBlockPlacedInDocument(el.uuid, el.ref_uuid)),
    )

    return Object.fromEntries(map) as Record<SignatureBlock["uuid"], boolean>
  },
)

const editorContentTabs = computed(
  () => {
    let tabs = [
      { id: EditorContentTab.signatureBlocks, name: t("documentContent.tabs.signatureBlocks"), badge: signatureBlocks.value?.filter((el) => !el.deleted_at)?.length },
    ]

    if (currentDocument.value?.content_type !== DocumentContentType.pdf) {
      tabs = [
        ...tabs,
        { id: EditorContentTab.dynamicFields, name: t("documentContent.tabs.dynamicFields"), badge: dynamicFields.value?.length },
        { id: EditorContentTab.parties, name: t("documentContent.tabs.parties"), badge: parties.value?.length },
      ]
    }

    if (!mau.value && crudContext.value === CrudContext.document) {
      // strip dynamicFields and parties
      tabs = tabs.filter((tab) => tab.id !== EditorContentTab.dynamicFields && tab.id !== EditorContentTab.parties)
    }

    return tabs
  },
)

watch(
  () => editorContentTabs.value,
  (val) => {
    if (!val) return

    if (crudContext.value === CrudContext.template) {
      templateStore.setEditorContentTabs(val)
    } else {
      documentStore.setEditorContentTabs(val)
    }
  },
  {
    immediate: true,
    deep: true,
  },
)

const activeEditorContentTab = computed(
  {
    get: () => {
      const store = crudContext.value === CrudContext.template
        ? templateStore
        : documentStore

      if (store.activeEditorContentTab) return store.activeEditorContentTab

      const tabToReturn = editorContentTabs.value[0]

      store.setActiveEditorContentTab(tabToReturn)

      return tabToReturn
    },
    set: (val) => {
      const store = crudContext.value === CrudContext.template
        ? templateStore
        : documentStore

      store.setActiveEditorContentTab(val)
    },
  },
)

onMounted(
  () => {
    if (crudContext.value === CrudContext.template) {
      templateStore.setActiveEditorContentTab(editorContentTabs.value[0])
    } else {
      documentStore.setActiveEditorContentTab(editorContentTabs.value[0])
    }
    setSidebarScrollContainer(editorContentBlocksScrollContainer.value)
  },
)

const getSignatories = (partyUuid: Party["uuid"]): DocumentUser[] | null => {
  const filteredUsers = users.value?.filter(
    (el) => el.party_uuid === partyUuid && el.roles?.includes(DocumentUserRoleEnum.signatory),
  )
  const orderedUsers = sortBy(filteredUsers, "signing_order")
  return orderedUsers
}

const relevantContentType = computed<"pdf" | "prosemirror_data">(() => {
  if (currentDocument.value && currentDocument.value?.content_type === DocumentContentType.pdf) return currentDocument.value?.content_type
  return "prosemirror_data"
})

const setDynamicFieldDataOnDrag = (ev: DragEvent, dynamicFieldUuid: DynamicField["uuid"], refUuid: DynamicField["ref_uuid"]) => {

  let data: Record<string, any>

  if (relevantContentType.value === "pdf") {
    data = {
      documentUuid: currentDocument.value?.uuid,
      pdfBrickableUuid: dynamicFieldUuid,
      pdfBrickableType: "App\\Models\\DynamicField",
      elementId: (ev.target as HTMLElement).id,
      offsetX: ev.offsetX,
      offsetY: ev.offsetY,
      uuid: dynamicFieldUuid,
      isDynamicField: true,
    }
  }
  else if (relevantContentType.value === "prosemirror_data") {
    data = {
      refUuid: refUuid,
      isDynamicField: true,
    }
  }

  if (data) ev?.dataTransfer?.setData("text/plain", JSON.stringify(data))

  if (isActiveDragToReorder.value) setDataTransferData(JSON.stringify(data))

  return true
}

const setPartyDataOnDrag = (ev: DragEvent, partyUuid: Party["uuid"], refUuid: Party["ref_uuid"], format: string) => {

  let data: Record<string, any>

  if (relevantContentType.value === "pdf") {
    data = {
      documentUuid: currentDocument.value?.uuid,
      pdfBrickableUuid: partyUuid,
      pdfBrickableType: "App\\Models\\Party",
      elementId: (ev.target as HTMLElement).id,
      offsetX: ev.offsetX,
      offsetY: ev.offsetY,
      uuid: partyUuid,
      isParty: true,
      format: format,
    }
  }
  else if (relevantContentType.value === "prosemirror_data") {
    data = {
      refUuid: refUuid,
      isParty: true,
      format: format,
    }
  }

  if (data) ev?.dataTransfer?.setData("text/plain", JSON.stringify(data))
}

const setSignatureBlockDataOnDrag = (ev: DragEvent, signatureBlock: SignatureBlock) => {
  let data: Record<string, any>

  if (relevantContentType.value === "pdf") {
    data = {
      documentUuid: currentDocument.value?.uuid,
      pdfBrickableUuid: signatureBlock.uuid,
      pdfBrickableType: "App\\Models\\SignatureBlock",
      elementId: (ev.target as HTMLElement).id,
      offsetX: ev.offsetX,
      offsetY: ev.offsetY,
      uuid: signatureBlock.uuid,
      isDynamicField: false,
    }
  } else if (relevantContentType.value === "prosemirror_data") {
    data = {
      refUuid: signatureBlock.ref_uuid,
      isSignatureBlock: true,
    }
  }

  if (data) ev?.dataTransfer?.setData("text/plain", JSON.stringify(data))
}

const removeSignatureBlock = async (signatureBlockUuid: SignatureBlock["uuid"]) => {
  const removeSignatureBlockRes = await signatureStore.removeSignatureBlock(crudContext.value, entityUuid.value, signatureBlockUuid)
  return removeSignatureBlockRes
}

const handleUpdateSignatureBlock = async (e: PointerEvent, signatureBlock: SignatureBlock, style: SignatureBlockStyle) => {
  await updateSignatureBlock(crudContext.value, entityUuid.value, signatureBlock, style)
}


const confirmationStore = useConfirmationStore()
const {
  setShowConfirmModal,
  setConfirmOptions,
} = confirmationStore

const handleRemoveSignatureBlock = async (signatureBlockUuid: SignatureBlock["uuid"]) => {
  setConfirmOptions({
    title: t("signatures.removeSignatureBlockConfirmTitle"),
    description: t("signatures.removeSignatureBlockConfirmDescription"),
    buttonText: t("common.remove"),
    callback: async () => {
      await removeSignatureBlock(signatureBlockUuid)
    },
  })
  setShowConfirmModal(true)
}

const removeButton = ref()

watch(isDirty, () => {
  nextTick(() => {
    if (removeButton.value?._tippy) {
      removeButton.value?._tippy.setContent(removeButton.value?.getAttribute("data-tippy-content"))
    }
  })
})

const checkIfDocumentUserCanEditParty = (party: Party) => {
  return !checkIfDisabledByPusher("editPartyButton_" + party.ref_uuid) &&
    !isLockedDocument.value && (crudContext.value === CrudContext.template || mdu.value?.permissions?.includes("party_update")) &&
    (!!mau.value || party.uuid === mdu.value?.party_uuid)
}

const addSignatureToPdfForCypressTest = (signatureBlock: SignatureBlock) => {
  if (IS_CYPRESS_ENV) {
    pdfBrickStore.createPdfBrick(currentDocument.value?.uuid, {
      //pdf_brickable_uuid: signatureBlock.uuid,
      signature_block_uuid: signatureBlock.uuid,
      pdf_brickable_type: "App\\Models\\SignatureBlock",
      page: 1,
      x: 90,
      y: 300,
    })
  }
}

const getPartyByUuid = (partyUuid: Party["uuid"]) => {
  return parties.value?.find((el) => el.uuid === partyUuid)
}

const isEnabledPartyPopover = (partyUuid: Party["uuid"]) => {
  return (
    !checkIfDisabledByPusher("editPartyButton_" + partyUuid)
    && (mdu.value?.permissions?.includes("party_update") || crudContext.value === CrudContext.template)
    && !isLockedDocument.value
  )
}

const isActiveDragToReorder = ref(false)

watch(isActiveDragToReorder, (val) => {
  if (!val) clearDataTransferData()
})

const isLoadingDuplicateDynamicField = ref(false)

const duplicateDynamicField = async (dynamicFieldUuid: DynamicField["uuid"]) => {
  isLoadingDuplicateDynamicField.value = true
  // Copy new object with all properties of dynamicField
  const dynamicField = dynamicFields.value?.find((el) => el.uuid === dynamicFieldUuid) as DynamicField
  // Remove uuid and created_at and updated_at
  const fieldsToOmit = [ "uuid", "created_at", "updated_at", "ref_uuid" ]
  const newDynamicField = { ...omit(toRaw(dynamicField), fieldsToOmit) }
  // Strip autofill_type if empty
  if (!newDynamicField.autofill_type) delete newDynamicField.autofill_type
  // Append incrementing counter "Copy (n)" to name, but only if there is no existing dynamic field with the same name
  let counter = 0
  let newDynamicFieldName = newDynamicField.name
  while (dynamicFields.value.some((el) => el.name === newDynamicFieldName)) {
    newDynamicFieldName = newDynamicFieldName.replace(/ \(Copy \d+\)$/, "")
    counter++
    newDynamicFieldName = `${newDynamicFieldName} (Copy ${counter})`
  }
  newDynamicField.name = newDynamicFieldName
  try {
    await dynamicFieldStore.createDynamicField(crudContext.value, entityUuid.value, newDynamicField)
  } catch (err) {
    notify({
      title: t("dynamicFields.errors.duplicate"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingDuplicateDynamicField.value = false
  }
}

const idxOfIsOpenDropdown = ref<number | null>(null)
const signatureBlockUuidOfIsOpenDropdown = ref<SignatureBlock["uuid"] | null>(null)

</script>

<template>
  <div class="flex flex-col h-full max-h-full bg-indigo-50">
    <div class="px-6 pt-6 shrink-0">
      <h3 class="flex items-center gap-1 mb-4 text-xs font-medium tracking-wider text-indigo-700 uppercase">
        {{ $t('documentContent.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('documentContent.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-indigo-300"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>

    <div
      v-if="isLoadingDynamicFields || isLoadingParties || isLoadingSignatureBlocks"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>

    <template v-else>
      <div class="px-6 pb-4 border-b shrink-0 border-b-indigo-100">
        <TabPills
          v-model:active-tab="activeEditorContentTab"
          :tabs="editorContentTabs"
          :style="'indigo'"
        />
      </div>

      <OverlayScrollbar
        ref="editorContentBlocksScrollContainer"
        tag="div"
        class="flex-1 max-h-full overflow-y-auto"
      >
        <template v-if="activeEditorContentTab.id === EditorContentTab.dynamicFields">
          <div
            v-if="dynamicFields?.length > 0"
          >
            <draggable
              v-model="dynamicFields"
              v-cy="`dynamic-fields-list`"
              :disabled="!isActiveDragToReorder"
              :animation="150"
              :ghost-class="'ghost'"
              group="dynamicFields"
              item-key="uuid"
              class="relative z-10 grid grid-cols-1 gap-4 p-6"
              @end="clearDataTransferData"
            >
              <template #item="{element: dynamicField, index}">
                <div class="pointer-events-none -ml-0.5">
                  <div
                    :id="`draggableDynamicField_${ dynamicField.ref_uuid }`"
                    draggable="true"
                    class="pointer-events-auto inline-flex items-center max-w-full gap-1 px-2 transition-transform pl-1 py-0.5 text-sm bg-indigo-50 border border-indigo-300 rounded-md shadow-md hover:bg-indigo-100 text-indigo-700 group cursor-pointer"
                    :class="[
                      idxOfIsOpenDropdown === index ? 'relative z-10' : '',
                      CrudContext.template === crudContext ? 'pr-8' : '',
                      isActiveDragToReorder ? 'translate-x-8' : 'translate-x-2'
                    ]"
                    :data-dynamic-field-button="dynamicField.ref_uuid"
                    data-placement="left"
                    :data-template="'dynamicFieldForm_' + dynamicField.ref_uuid"
                    @dragstart="($event) => setDynamicFieldDataOnDrag($event, dynamicField.uuid, dynamicField.ref_uuid)"
                  >
                    <span
                      v-if="isActiveDragToReorder"
                      class="absolute -ml-6 text-right text-indigo-300 pointer-events-none"
                    >{{ index+1 }}</span>
                    <DragIndicatorIcon class="w-4 h-4 text-indigo-300 cursor-move shrink-0 group-hover:text-indigo-500" />
                    <span
                      class="flex items-center justify-center w-5 shrink-0"
                      data-tippy-help
                      data-tippy-append-to="body"
                      :data-tippy-content="$t('dynamicFields.form.type') + ': ' + $t('multiFieldTypes.' + dynamicField.type)"
                      data-placement="top"
                    >
                      <MultiFieldIcon
                        class="text-indigo-300 group-hover:text-indigo-500"
                        aria-hidden="true"
                        :type="dynamicField.type"
                      />
                    </span>
                    <span
                      class="truncate"
                      :title="dynamicField.name"
                    >
                      {{ dynamicField.name }}
                    </span>
                    <Dropdown
                      v-if="CrudContext.template === crudContext"
                      width="w-fit"
                      menu-classes="absolute z-10 right-0 inset-y-0"
                      @click.stop.prevent="true"
                    >
                      <template #trigger>
                        <MenuButton
                          class="flex items-center h-full p-0 px-1 text-indigo-300 rounded-l-none rounded-r-md btn-plain hover:bg-indigo-200 focus:bg-indigo-200 hover:text-indigo-600 focus:text-indigo-600"
                          @click.stop.prevent="() => {
                            idxOfIsOpenDropdown = index
                          }"
                        >
                          <EllipsisHorizontalIcon
                            class="w-4 h-4 shrink-0"
                            aria-hidden="true"
                          />
                        </MenuButton>
                      </template>
                      <template #content>
                        <DropdownLink
                          as="button"
                          :disabled="isLoadingDuplicateDynamicField"
                          :icon="true"
                          @click="duplicateDynamicField(dynamicField.uuid)"
                        >
                          <DuplicateIcon
                            v-if="!isLoadingDuplicateDynamicField"
                            class="shrink-0 w-4 h-4 mr-1.5"
                            aria-hidden="true"
                          />
                          <SpinLoader
                            v-else
                            class="w-4 h-4 shrink-0 mr-1.5"
                            aria-hidden="true"
                          />
                          <span class="flex-grow whitespace-nowrap">
                            {{ $t('common.duplicate') }}
                          </span>
                        </DropdownLink>
                        <DropdownLink
                          as="button"
                          :disabled="isLoadingDuplicateDynamicField"
                          :icon="true"
                          @click="removeDynamicField(dynamicField.uuid, documentStore.currentDocument || templateStore.currentTemplate)"
                        >
                          <TrashIcon
                            v-if="dynamicFieldUuidBeingRemoved !== dynamicField.uuid"
                            class="shrink-0 w-4 h-4 mr-1.5"
                            aria-hidden="true"
                          />
                          <SpinLoader
                            v-else
                            class="w-4 h-4 shrink-0 mr-1.5"
                            aria-hidden="true"
                          />
                          <span class="flex-grow whitespace-nowrap">
                            {{ $t('common.remove') }}
                          </span>
                        </DropdownLink>
                      </template>
                    </Dropdown>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <VariableIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('dynamicFields.noDynamicFields') }}
          </EmptyState>

          <div
            v-if="mdu?.permissions?.includes('dynamic_field_create') || crudContext === CrudContext.template"
            class="mb-6"
            :class="dynamicFields.length ? 'ml-6' : '-mt-9'"
          >
            <button
              type="button"
              data-template="dynamicFieldForm"
              data-placement="left"
              :class="dynamicFields?.length ? '' : 'mx-auto'"
              class="btn-plain add-dynamic-field-button btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
            >
              <PlusIcon class="shrink-0 h-3.5 w-3.5" />
              {{ $t('dynamicFields.addDynamicField') }}
            </button>
          </div>
          <div
            v-if="(mdu?.permissions?.includes('dynamic_field_update') || crudContext === CrudContext.template) && dynamicFields?.length > 1"
            class="py-6 border-t border-t-indigo-100"
          >
            <div class="flex flex-col gap-3 px-6">
              <SwitchGroup
                as="div"
                class="flex items-center"
              >
                <SwitchLabel
                  as="span"
                  class="inline-flex items-center flex-grow gap-2 text-sm text-indigo-400"
                  passive
                >
                  <span>
                    {{ $t('dynamicFields.enableDragToReorder') }}
                  </span>
                  <span
                    data-tippy-help
                    :data-tippy-content="$t('dynamicFields.enableDragToReorderHint')"
                    data-placement="bottom"
                  >
                    <QuestionMarkCircleIcon
                      class="w-4 h-4 text-indigo-300"
                      aria-hidden="true"
                    />
                  </span>
                </SwitchLabel>
                <Switch
                  v-model="isActiveDragToReorder"
                  :class="[
                    isActiveDragToReorder ? 'bg-indigo-600' : 'bg-gray-300',
                    'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  ]"
                >
                  <span
                    class="relative flex items-center justify-center w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow ring-0"
                    :class="[isActiveDragToReorder ? 'translate-x-5' : 'translate-x-0']"
                  >
                    <span
                      :class="[
                        isActiveDragToReorder
                          ? 'opacity-0 ease-out duration-100'
                          : 'opacity-100 ease-in duration-200',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      ]"
                      aria-hidden="true"
                    >
                      <svg
                        class="w-3 h-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 12 12"
                      >
                        <path
                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      :class="[
                        isActiveDragToReorder
                          ? 'opacity-100 ease-in duration-200'
                          : 'opacity-0 ease-out duration-100',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      ]"
                      aria-hidden="true"
                    >
                      <svg
                        class="w-3 h-3 text-indigo-600"
                        fill="currentColor"
                        viewBox="0 0 12 12"
                      >
                        <path
                          d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                        />
                      </svg>
                    </span>

                  </span>
                </Switch>
              </SwitchGroup>
              <div class="flex items-center flex-grow gap-2 text-sm text-indigo-400">
                <span class="flex items-center flex-grow gap-2">
                  {{ $t('dynamicFields.autoOrderLabel') }}
                  <span
                    data-tippy-help
                    :data-tippy-content="$t('dynamicFields.autoOrderHint')"
                    data-placement="bottom"
                  >
                    <QuestionMarkCircleIcon
                      class="w-4 h-4 text-indigo-300"
                      aria-hidden="true"
                    />
                  </span>
                </span>
                <button
                  class="flex items-center gap-2 btn-primary btn-sm"
                  type="button"
                  :disable="isLoadingSetDynamicFieldOrderToDocumentOrder"
                  @click="handleSetDynamicFieldOrderToDocumentOrder"
                >
                  <SpinLoader
                    v-if="isLoadingSetDynamicFieldOrderToDocumentOrder"
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                  {{ $t('dynamicFields.autoOrderFields') }}
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="activeEditorContentTab.id === EditorContentTab.parties">
          <div
            v-if="parties?.length > 0"
            class="grid grid-cols-1 gap-4 p-6"
          >
            <div
              v-for="party in parties"
              :key="party.uuid"
              class="space-y-2"
            >
              <div>
                <div
                  :id="`draggableParty_${ party.ref_uuid }_name`"
                  draggable="true"
                  :data-placement="!isLockedDocument ? 'left' : ''"
                  :data-template="!isLockedDocument ? 'partyForm_' + party.ref_uuid : ''"
                  :class="[
                    'edit-party-button_' + party.ref_uuid,
                    checkIfDocumentUserCanEditParty(party)
                      ? 'edit-party-button'
                      : '',
                    checkIfDisabledByPusher('editPartyButton_' + party.ref_uuid)
                      ? 'cursor-not-allowed opacity-50'
                      : '',
                  ]"
                  class="inline-flex items-center max-w-full gap-1 px-2 py-0.5 text-sm border border-slate-400 rounded-md shadow-md cursor-move bg-slate-50 hover:bg-slate-100 group"
                  @dragstart="($event) => setPartyDataOnDrag($event, party.uuid, party.ref_uuid, 'name')"
                >
                  <DragIndicatorIcon class="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-600" />
                  <PartyEntityTypeDisplayIcon
                    :party="party"
                    class="w-4 h-4 mr-1 shrink-0 text-slate-700"
                  />
                  <span>{{ party.name }} ⸱ <span class="font-semibold">{{ $t('partyFormats.name') }}</span></span>
                </div>
              </div>
              <div>
                <div
                  :id="`draggableParty_${ party.ref_uuid }_address`"
                  draggable="true"
                  :data-placement="!isLockedDocument ? 'left' : ''"
                  :data-template="!isLockedDocument ? 'partyForm_' + party.ref_uuid : ''"
                  :class="[
                    'edit-party-button_' + party.ref_uuid,
                    checkIfDocumentUserCanEditParty(party)
                      ? 'edit-party-button'
                      : '',
                    checkIfDisabledByPusher('editPartyButton_' + party.ref_uuid)
                      ? 'cursor-not-allowed opacity-50'
                      : '',
                  ]"
                  class="inline-flex items-center max-w-full gap-1 px-2 py-0.5 text-sm border border-slate-400 rounded-md shadow-md cursor-move bg-slate-50 hover:bg-slate-100 group"
                  @dragstart="($event) => setPartyDataOnDrag($event, party.uuid, party.ref_uuid, 'address')"
                >
                  <DragIndicatorIcon class="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-600" />
                  <PartyEntityTypeDisplayIcon
                    :party="party"
                    class="w-4 h-4 mr-1 shrink-0 text-slate-700"
                  />
                  <span>{{ party.name }} ⸱ <span class="font-semibold">{{ $t('partyFormats.address') }}</span></span>
                </div>
              </div>
              <div>
                <div
                  :id="`draggableParty_${ party.ref_uuid }_address-inline`"
                  draggable="true"
                  :data-placement="!isLockedDocument ? 'left' : ''"
                  :data-template="!isLockedDocument ? 'partyForm_' + party.ref_uuid : ''"
                  :class="[
                    'edit-party-button_' + party.ref_uuid,
                    checkIfDocumentUserCanEditParty(party)
                      ? 'edit-party-button'
                      : '',
                    checkIfDisabledByPusher('editPartyButton_' + party.ref_uuid)
                      ? 'cursor-not-allowed opacity-50'
                      : '',
                  ]"
                  class="inline-flex items-center max-w-full gap-1 px-2 py-0.5 text-sm border border-slate-400 rounded-md shadow-md cursor-move bg-slate-50 hover:bg-slate-100 group"
                  @dragstart="($event) => setPartyDataOnDrag($event, party.uuid, party.ref_uuid, 'address:inline')"
                >
                  <DragIndicatorIcon class="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-600" />
                  <PartyEntityTypeDisplayIcon
                    :party="party"
                    class="w-4 h-4 mr-1 shrink-0 text-slate-700"
                  />
                  <span>{{ party.name }} ⸱ <span class="font-semibold">{{ $t('partyFormats.address:inline') }}</span></span>
                </div>
              </div>
              <div>
                <div
                  :id="`draggableParty_${ party.ref_uuid }_reference`"
                  draggable="true"
                  :data-placement="!isLockedDocument ? 'left' : ''"
                  :data-template="!isLockedDocument ? 'partyForm_' + party.ref_uuid : ''"
                  :class="[
                    'edit-party-button_' + party.ref_uuid,
                    checkIfDocumentUserCanEditParty(party)
                      ? 'edit-party-button'
                      : '',
                    checkIfDisabledByPusher('editPartyButton_' + party.ref_uuid)
                      ? 'cursor-not-allowed opacity-50'
                      : '',
                  ]"
                  class="inline-flex items-center max-w-full gap-1 px-2 py-0.5 text-sm border border-slate-400 rounded-md shadow-md cursor-move bg-slate-50 hover:bg-slate-100 group"
                  @dragstart="($event) => setPartyDataOnDrag($event, party.uuid, party.ref_uuid, 'reference')"
                >
                  <DragIndicatorIcon class="w-4 h-4 shrink-0 text-slate-400 group-hover:text-slate-600" />
                  <PartyEntityTypeDisplayIcon
                    :party="party"
                    class="w-4 h-4 mr-1 shrink-0 text-slate-700"
                  />
                  <span>{{ party.name }} ⸱ <span class="font-semibold">{{ $t('partyFormats.reference') }}</span></span>
                </div>
              </div>
            </div>
          </div>

          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <IdentificationIconOutline
                aria-hidden="true"
              />
            </template>
            {{ $t('documentContent.noPartiesAdded') }}
          </EmptyState>

          <div
            v-if="mdu?.permissions?.includes('party_create') || crudContext === CrudContext.template"
            class="mb-6"
            :class="parties.length ? 'ml-6' : '-mt-9'"
          >
            <button
              type="button"
              :class="parties?.length ? '' : 'mx-auto'"
              data-template="addPartyForm"
              data-placement="left"
              class="btn-plain add-party-button btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
            >
              <PlusIcon class="shrink-0 h-3.5 w-3.5" />

              {{ $t('userSettings.addParty') }}
            </button>
          </div>
        </template>
        <template v-else-if="activeEditorContentTab.id === EditorContentTab.signatureBlocks">
          <div
            v-if="signatureBlocksToShow?.length > 0"
            class="relative mt-4 space-y-4"
            :class=" signatureBlockUuidOfIsOpenDropdown ? 'z-40' : ''"
          >
            <div
              v-for="signatureBlock in signatureBlocksToShow"
              :id="`draggableSignatureBlock_${ signatureBlock.ref_uuid }`"
              :key="signatureBlock.uuid"
              :data-ref-uuid="signatureBlock.ref_uuid"
              :data-party-button="getPartyByUuid(signatureBlock.party_uuid)?.ref_uuid"
              data-placement="left"
              :data-template="'partyForm_' + getPartyByUuid(signatureBlock.party_uuid)?.ref_uuid"
              draggable="true"
              data-uuid="-1"
              data-x="0"
              data-y="0"
              data-pdf-x="0"
              data-pdf-y="0"
              :class="[
                mapSignatureBlockUuidsPlaceInDocument[signatureBlock.uuid] ? 'opacity-50 pointer-events-none' : isEnabledPartyPopover(signatureBlock.party_uuid) ? 'edit-party-button cursor-pointer' : '',
                signatureBlock?.style === SignatureBlockStyle.minimal ? 'signature-block-minimal' : 'signature-block-regular',
                signatureBlockUuidOfIsOpenDropdown === signatureBlock.uuid ? 'z-10' : '',
              ]"
              class="relative signature-block ml-14"
              data-cy-sel="draggable-signature-block"
              @dragstart="($event) => setSignatureBlockDataOnDrag($event, signatureBlock)"
            >
              <div
                v-if="IS_CYPRESS_ENV"
                data-cy-sel="place-signature-in-pdf-hack"
                class="absolute top-0 left-0 w-px h-px"
                @click="addSignatureToPdfForCypressTest(signatureBlock)"
              />
              <div
                v-if="!checkIfSignatureBlockPlacedInDocument(signatureBlock.uuid, signatureBlock.ref_uuid)"
                class="absolute pr-2 text-gray-400 cursor-move -left-6 top-2"
                data-cy-sel="signature-block-drag-handle"
              >
                <DragIndicatorIcon
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
              </div>
              <div
                v-else
                class="absolute pr-2 text-indigo-500 -left-6 top-2"
              >
                <LinkIcon
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
              </div>
              <div
                v-if="(crudContext === CrudContext.template || mdu?.permissions?.includes('signature_block_delete')) && !checkIfSignatureBlockPlacedInDocument(signatureBlock.uuid, signatureBlock.ref_uuid)"
                class="absolute pl-2 cursor-pointer -right-6 top-2"
              >
                <Dropdown
                  :menu-classes="signatureBlockUuidOfIsOpenDropdown === signatureBlock.uuid ? 'z-30 relative' : ''"
                  width="w-fit"
                  align="right"
                  @click.stop.prevent="true"
                >
                  <template #trigger>
                    <MenuButton
                      :disabled="signatureBlockUuidBeingRemoved === signatureBlock.uuid"
                      class="text-gray-400 hover:text-gray-700"
                      @click.stop.prevent="() => {
                        signatureBlockUuidOfIsOpenDropdown = signatureBlock.uuid
                      }"
                    >
                      <template
                        v-if="signatureBlockUuidBeingRemoved !== signatureBlock.uuid"
                      >
                        <EllipsisHorizontalIcon
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                      </template>
                      <template
                        v-else
                      >
                        <SpinLoader
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                      </template>
                    </MenuButton>
                  </template>

                  <template #content>
                    <div class="border-b border-b-gray-700">
                      <div class="flex items-center py-1 text-left group px-3 pt-2 pb-0.5">
                        <span class="flex-1 text-[10px] font-medium tracking-wider uppercase text-gray-500">{{
                          $t('signatures.signatureBlockStyle') }}
                        </span>
                      </div>
                      <DropdownLink
                        as="button"
                        :disabled="uuidsOfIsUpdatingSignatureBlock?.includes(signatureBlock.uuid)"
                        :class="signatureBlock.style === SignatureBlockStyle.regular ? 'pointer-events-none cursor-default' : ''"
                        @click="($event) => {
                          if (signatureBlock.style !== SignatureBlockStyle.regular) handleUpdateSignatureBlock($event, signatureBlock, SignatureBlockStyle.regular)
                        }"
                      >
                        <div
                          class="flex items-center gap-2"
                          :class="uuidsOfIsUpdatingSignatureBlock?.includes(signatureBlock.uuid) ? 'opacity-50' : ''"
                        >
                          <SpinLoader
                            v-if="uuidsOfIsUpdatingSignatureBlock?.includes(signatureBlock.uuid)"
                            class="w-4 h-4 text-gray-500"
                            aria-hidden="true"
                          />

                          <DefaultSignatureBlockIcon
                            v-else
                            class="flex-none w-4 h-4 text-gray-500"
                            aria-hidden="true"
                          />
                          <span class="flex-grow">{{ $t('signatures.applyDefaultStyle') }}</span>
                          <CheckIcon
                            v-if="signatureBlock.style === SignatureBlockStyle.regular"
                            class="w-4 h-4 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                      </DropdownLink>
                      <DropdownLink
                        as="button"
                        :disabled="uuidsOfIsUpdatingSignatureBlock?.includes(signatureBlock.uuid)"
                        :class="signatureBlock.style === SignatureBlockStyle.minimal ? 'pointer-events-none cursor-default' : ''"
                        @click="signatureBlock.style !== SignatureBlockStyle.minimal ? handleUpdateSignatureBlock($event, signatureBlock, SignatureBlockStyle.minimal) : null"
                      >
                        <div
                          class="flex items-center gap-2"
                          :class="uuidsOfIsUpdatingSignatureBlock?.includes(signatureBlock.uuid) ? 'opacity-50' : ''"
                        >
                          <SpinLoader
                            v-if="uuidsOfIsUpdatingSignatureBlock?.includes(signatureBlock.uuid)"
                            class="w-4 h-4 text-gray-500"
                            aria-hidden="true"
                          />

                          <MinimalSignatureBlockIcon
                            v-else
                            class="flex-none w-4 h-4 text-gray-500"
                            aria-hidden="true"
                          />
                          <span class="flex-grow">{{ $t('signatures.applyMinimalStyle') }}</span>
                          <CheckIcon
                            v-if="signatureBlock.style === SignatureBlockStyle.minimal"
                            class="w-4 h-4 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                      </DropdownLink>
                    </div>
                    <DropdownLink
                      ref="removeButton"
                      as="button"
                      :disabled="isDirty"
                      data-tippy-help
                      :data-tippy-content="isDirty ? $t('common.notDeletableWhileIsDirtyEditorContent') : ''"
                      :data-placement="'top'"
                      @click="handleRemoveSignatureBlock(signatureBlock.uuid)"
                    >
                      <div
                        class="flex items-center gap-2"
                        :class="signatureBlockUuidBeingRemoved === signatureBlock.uuid ? 'opacity-50' : ''"
                      >
                        <TrashIcon
                          class="flex-none w-4 h-4 text-gray-500"
                          aria-hidden="true"
                        />
                        {{ $t('common.remove') }}
                      </div>
                    </DropdownLink>
                  </template>
                </Dropdown>
              </div>
              <div
                v-if="getSignatories(signatureBlock.party_uuid).length"
              >
                <SignatureSignatoryDisplay
                  v-for="signatory in getSignatories(signatureBlock.party_uuid)"
                  :key="signatory.uuid"
                  :signatory="signatory"
                  :signature="checkSignature(signatory.uuid, signatureBlock.uuid)"
                  :party-uuid="signatory.party_uuid"
                  :signature-block="signatureBlock"
                />
              </div>
              <SignatureSignatoryDisplay
                v-else
                :party-uuid="signatureBlock.party_uuid"
                :signature-block="signatureBlock"
              />
            </div>
          </div>
          <EmptyState
            v-else
            :hide-button="true"
            class="my-6"
          >
            <template #icon>
              <SignatureIcon
                aria-hidden="true"
              />
            </template>
            {{ $t('documentContent.noSignatureBlocksAdded') }}
          </EmptyState>

          <div
            v-if="mdu?.permissions?.includes('signature_block_create') || crudContext === CrudContext.template"
            class="mb-6"
            :class="signatureBlocksToShow?.length ? 'mt-6 ml-8 relative -left-px' : '-mt-9'"
          >
            <GenerateSignatureBlockDropdown
              :id="`generateSignatureBlockDropdown`"
              @click="($event) => {
                signatureBlockUuidOfIsOpenDropdown = null
              }"
            />
          </div>
        </template>
      </OverlayScrollbar>
    </template>
  </div>
</template>
