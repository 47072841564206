<script setup lang="ts">
import { computed } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { DynamicField, MultiFieldType } from "~/types"
import { CurrencyInput, DateInput, DurationInput, RadioGroupPills } from "~/components"
import { formatMultifieldNumber, getDefaultMultiFieldFormat } from "~/utils"
import { useI18n } from "vue-i18n"

const { locale } = useI18n()

interface Props {
  modelValue?: any
  dynamicField: DynamicField
  hasError?: boolean
  disabled?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: null,
    disabled: false,
    hasError: false,
  },
)

const value = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:model-value", value + "")
  },
})

const emit = defineEmits([ "update:model-value" ])

const defaultFormat = computed(() => getDefaultMultiFieldFormat(props.dynamicField?.type, locale.value))

const formatPlaceholder = computed(() => {
  // Take sample amount of 0 and apply formatMultifieldNumber with settings format
  return formatMultifieldNumber(0, props.dynamicField?.format || defaultFormat.value, props.dynamicField?.type, locale.value)
})

</script>

<template>
  <div>
    <div
      v-if="dynamicField.type === MultiFieldType.bool"
      :class="{'error' : props.hasError}"
    >
      <label>
        <RadioGroupPills
          :model-value="value"
          :disabled="false"
          :allow-null="true"
          grid-class="grid grid-cols-2 gap-3"
          :layout="'slate'"
          :options="[
            { title: 'common.yes', value: 'true' },
            { title: 'common.no', value: 'false' },
          ]"
          :sr-label="$t('conditions.enterMatchValue')"
          :aria-describedby="''"
          @update:model-value="value = $event"
        />
      </label>
    </div>

    <DateInput
      v-else-if="dynamicField.type === MultiFieldType.date"
      v-model.lazy="value"
      width="auto"
      :style="'slate'"
      :has-error="hasError"
      :show-day="true"
      :show-month="true"
      :show-year="true"
      :disabled="disabled"
    />
    <CurrencyInput
      v-else-if="dynamicField.type === MultiFieldType.currency || dynamicField.type === MultiFieldType.currency_duration"
      v-model="value"
      :has-error="hasError"
      :settings="dynamicField.settings"
      :style="'slate'"
      :disabled="disabled"
      :has-duration="dynamicField.type === MultiFieldType.currency_duration"
    />
    <DurationInput
      v-else-if="dynamicField.type === MultiFieldType.duration"
      v-model="value"
      :has-error="hasError"
      :style="'slate'"
      :disabled="disabled"
    />
    <input
      v-else-if="dynamicField.type === MultiFieldType.text || dynamicField.type === MultiFieldType.email || dynamicField.type === MultiFieldType.textarea"
      v-model="value"
      type="text"
      :class="props.hasError ? 'focus:ring-red-500' : ''"
      class="input-slate"
      :placeholder="$t('conditions.enterMatchValue') + '…'"
      :disabled="disabled"
    >
    <input
      v-else-if="dynamicField.type === MultiFieldType.number"
      v-model="value"
      type="number"
      min="-999999999999.99"
      max="999999999999.99"
      step="0.01"
      :lang="locale"
      :placeholder="formatPlaceholder || $t('dynamicFields.numberPlaceholder') + '…'"
      :disabled="disabled"
      :class="props.hasError ? 'focus:ring-red-500' : ''"
      class="input-slate"
    >
    <Listbox
      v-else-if="dynamicField.type === MultiFieldType.select"
      v-model="value"
      :disabled="disabled"
      :class="{'error': hasError}"
    >
      <div class="relative">
        <ListboxButton
          class="btn-listbox-slate bg-slate-900 hover:bg-slate-950"
        >
          <div
            v-if="value"
            class="block truncate"
          >
            <span class="block truncate">
              {{ value }}
            </span>
          </div>
          <div
            v-else
            class="text-gray-500"
          >
            <span class="block truncate">
              {{ $t('conditions.selectValue') }}…
            </span>
          </div>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none"
          >
            <ChevronUpDownIcon
              class="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>
        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="left-0 right-auto max-w-full listbox-options">
            <ListboxOption
              v-for="option in dynamicField.select_values"
              :key="option"
              v-slot="{ active, selected }"
              as="template"
              :value="option"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ option }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-teal-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>
