import { Extension } from "@tiptap/core"
import { debounce } from "lodash-es"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    decorationExtension: {
      /**
       * @description run tr with `directDecoration=true` meta
       */
      directDecoration: () => ReturnType
    }
  }
}

export const DecorationExtension = Extension.create({
  name: "decorationExtension",

  addStorage () {
    return {
      debouncedDirectDecoration: undefined,
    }
  },

  onBeforeCreate () {
    this.storage.debouncedDirectDecoration = debounce(() => {
      if (this.editor) {
        this.editor.commands.directDecoration()
      }
    }, 500)
  },

  onTransaction ({ transaction }) {
    if (transaction.docChanged && !transaction.getMeta("directDecoration") && !transaction.getMeta("trackChanges")) {
      this.storage.debouncedDirectDecoration()
    }
    return true
  },

  addCommands () {
    return {
      directDecoration: () => ({ tr, dispatch }) => {
        tr.setMeta("directDecoration", true)
        return dispatch?.(tr)
      },
    }
  },
})
