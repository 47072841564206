<script setup lang="ts">
interface Props {
  closeFunction: () => void
}

const props = withDefaults(defineProps<Props>(), {

})

const close = () => {
  props.closeFunction()
}

defineExpose({
  close,
})
</script>

<template>
  <div
    class="hidden close-helper"
  />
</template>
