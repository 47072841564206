<script setup lang="ts">
// external
import { computed } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { CheckCircleIcon, FunnelIcon, MinusCircleIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"

// internal
import { RadioGroupPills, TeamList, UserDisplay, WarningBox } from "~/components"
import { DocumentUserRoleEnum, Team, DocumentNotificationRecipients, CrudContext } from "~/types"
import { useUserStore } from "~/stores"
import { getUserRepresentation, getFilteredRecipients } from "~/utils"

interface Props {
  recipients?: DocumentNotificationRecipients
  isDeactivated?: boolean
  context?: CrudContext
  teams?: Team[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    recipients: null,
    isDeactivated: false,
    context: null,
  },
)

const { t } = useI18n()
const userStore = useUserStore()
const { users, allAccountUsers } = storeToRefs(userStore)

const localRecipients = computed({
  get: () => props.recipients,
  set: (recipients) => {
    emits("update:recipients", recipients)
  },
})


const emits = defineEmits([ "update:recipients" ])

enum RecipientOption {
  filtered = "filtered",
  all = "all",
}

const defaultRecipients:DocumentNotificationRecipients = Object.freeze({
  document_user_uuids: null,
  exclude_document_user_uuids: null,
  roles: null,
  scope: {
    internal: true,
    external: true,
  },
  team_uuids: null,
})

const recipientOptions = [
  {
    title: t("documentNotifications.recipientOptions.all"),
    value: RecipientOption.all,
    name: RecipientOption.all,
    icon: CheckCircleIcon,
  },
  {
    title: t("documentNotifications.recipientOptions.filtered"),
    value: RecipientOption.filtered,
    name: RecipientOption.filtered,
    icon: FunnelIcon,
  },
]

const selectedRecipientOption = computed({
  get: () => {
    if (localRecipients.value === null) {
      return RecipientOption.all
    } else {
      return RecipientOption.filtered
    }
  },
  set: (recipientOption) => {
    if (recipientOption === RecipientOption.all) {
      localRecipients.value = null
    } else {
      localRecipients.value = {
        ...defaultRecipients,
        roles: [ DocumentUserRoleEnum.owner, DocumentUserRoleEnum.collaborator ],
      }
    }
  },
})

/* ROLE MANAGEMENT */
const setRole = (role:DocumentUserRoleEnum) => {
  if (!localRecipients.value?.roles) {
    localRecipients.value.roles = [ role ]
  } else {
    const idx = localRecipients.value.roles.findIndex((existingRole) => existingRole === role)
    if (idx !== -1) {
      localRecipients.value.roles.splice(idx, 1)
    } else {
      localRecipients.value.roles.push(role)
    }
  }
}

/* TEAM MANAGEMENT */
const remainingTeamOptions = computed(() => {
  if (localRecipients.value?.team_uuids?.length) {
    const selectedTeamUuids = localRecipients.value.team_uuids
    return props.teams.filter((team) => {
      return !selectedTeamUuids.includes(team.uuid)
    })
  }
  return props.teams
})

const selectedTeam = computed({
  get: () => {
    return null
  },
  set: (team: Team) => {
    if (!localRecipients.value?.team_uuids) {
      localRecipients.value.team_uuids = []
    }
    localRecipients.value.team_uuids.push(team.uuid)
  },
})

const selectedTeams = computed(() => {
  if (!localRecipients.value?.team_uuids?.length || !props.teams?.length) {
    return []
  }
  return props.teams.filter((team) => localRecipients.value.team_uuids.includes(team.uuid))
})

const removeTeam = (team: Team) => {
  const idx = localRecipients.value.team_uuids.indexOf(team.uuid)

  if (idx !== -1) {
    localRecipients.value.team_uuids.splice(idx, 1)
  }
}

/* USER MANAGEMENT */
const remainingUserOptions = computed(() => {
  if (localRecipients.value?.document_user_uuids?.length) {
    const selectedUserUuids = localRecipients.value.document_user_uuids
    return users.value.filter((user) => {
      return !selectedUserUuids.includes(user.uuid)
    })
  }
  return users.value
})

const selectedUser = computed({
  get: () => {
    return null
  },
  set: (user) => {
    removeExcludedUser(user)
    if (!localRecipients.value?.document_user_uuids) {
      localRecipients.value.document_user_uuids = []
    }
    localRecipients.value.document_user_uuids.push(user.uuid)
  },
})

const selectedUsers = computed(() => {
  if (!localRecipients.value?.document_user_uuids?.length || !users.value?.length) {
    return []
  }
  return users.value.filter((user) => localRecipients.value.document_user_uuids.includes(user.uuid))
})

const removeUser = (user) => {
  if (localRecipients.value.document_user_uuids?.length) {
    const idx = localRecipients.value.document_user_uuids.indexOf(user.uuid)

    if (idx !== -1) {
      localRecipients.value.document_user_uuids.splice(idx, 1)
    }
  }
}

/* EXLUDED USER MANAGEMENT */
const remainingExcludedUserOptions = computed(() => {
  if (localRecipients.value?.exclude_document_user_uuids?.length) {
    const selectedExcludedUserUuids = localRecipients.value.exclude_document_user_uuids
    return users.value.filter((user) => {
      return !selectedExcludedUserUuids.includes(user.uuid)
    })
  }
  return users.value
})

const selectedExcludedUser = computed({
  get: () => {
    return null
  },
  set: (user) => {
    removeUser(user)
    if (!localRecipients.value?.exclude_document_user_uuids) {
      localRecipients.value.exclude_document_user_uuids = []
    }
    localRecipients.value.exclude_document_user_uuids.push(user.uuid)
  },
})

const selectedExcludedUsers = computed(() => {
  if (!localRecipients.value?.exclude_document_user_uuids?.length || !users.value?.length) {
    return []
  }
  return users.value.filter((user) => localRecipients.value.exclude_document_user_uuids.includes(user.uuid))
})

const removeExcludedUser = (user) => {
  if (localRecipients.value.exclude_document_user_uuids?.length) {
    const idx = localRecipients.value.exclude_document_user_uuids.indexOf(user.uuid)

    if (idx !== -1) {
      localRecipients.value.exclude_document_user_uuids.splice(idx, 1)
    }
  }
}

const hasValidRoles = computed(() => !!localRecipients.value?.roles.length)
const hasValidScope = computed(() => localRecipients.value?.scope.internal || localRecipients.value?.scope.external)
const validate = () => {
  if (!localRecipients.value) {
    return true
  }
  return hasValidRoles.value && hasValidScope.value
}

defineExpose({ validate })

const previewUserList = computed(() => {
  if (localRecipients.value === null) return users.value
  if (!users.value.length || props.context !== CrudContext.document) return []
  return getFilteredRecipients({
    users: users.value,
    allAccountUsers: allAccountUsers.value,
    recipients: localRecipients.value,
  })
})

</script>

<template>
  <fieldset
    class="relative"
  >
    <h3 class="relative flex items-center w-full h-px px-4 mt-4 mb-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-white">
      <span class="px-2 py-0.5 text-indigo-500 bg-white"> {{ $t('documentNotifications.recipients') }}</span>
    </h3>
    <div class="px-6 py-2">
      <RadioGroupPills
        v-model="selectedRecipientOption"
        :not-localized="true"
        :sr-label="$t('documentNotifications.recipients')"
        :disabled="false"
        :grid-class="'grid grid-cols-3 gap-2 grow min-w-[9rem]'"
        :options="recipientOptions"
      />
      <div
        v-if="selectedRecipientOption === RecipientOption.filtered"
        class="mt-5 space-y-4"
      >
        <div class="rounded-md bg-gray-50">
          <div class="px-5 py-4 gap-5 sm:grid w-full sm:grid-cols-2">
            <div>
              <div class="mb-1 text-sm font-medium">
                {{ $t('documentNotifications.recipientCategories.roles') }} <span class="text-indigo-500">*</span>
              </div>
              <div
                v-for="role in DocumentUserRoleEnum"
                :key="role"
              >
                <label
                  class="inline-flex items-center justify-start space-x-2 text-sm"
                  :class="{ 'text-red-500' : !hasValidRoles}"
                >
                  <input
                    :name="role"
                    type="checkbox"
                    class="w-4 h-4 rounded input-radio-primary"
                    :checked="localRecipients.roles?.includes(role)"
                    @change="setRole(role)"
                  >
                  <span>{{ $t('documentNotifications.roles.' + role) }}</span>
                </label>
              </div>
            </div>
            <div>
              <div class="mb-1 text-sm font-medium">
                {{ $t('documentNotifications.scope') }} <span class="text-indigo-500">*</span>
              </div>
              <div>
                <label
                  class="inline-flex items-center justify-start space-x-2 text-sm"
                  :class="{ 'text-red-500' : !hasValidScope}"
                >
                  <input
                    v-model="localRecipients.scope.internal"
                    type="checkbox"
                    class="w-4 h-4 rounded input-radio-primary"
                    :checked="localRecipients.scope.internal"
                  >
                  <span>{{ $t('documentNotifications.scopes.internal') }}</span>
                </label>
              </div>
              <div>
                <label
                  class="inline-flex items-center justify-start space-x-2 text-sm"
                  :class="{ 'text-red-500' : !hasValidScope}"
                >
                  <input
                    v-model="localRecipients.scope.external"
                    type="checkbox"
                    class="w-4 h-4 rounded input-radio-primary"
                    :checked="localRecipients.scope.external"
                  >
                  <span>{{ $t('documentNotifications.scopes.external') }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="false"
          class="rounded-md bg-gray-50"
        >
          <div class="px-5 py-4">
            <Listbox
              v-model="selectedTeam"
              :disabled="!remainingTeamOptions.length"
              as="div"
              class="max-w-sm"
            >
              <div class="relative mt-1">
                <ListboxButton
                  class="btn-white pr-9"
                  :disabled="!remainingTeamOptions.length"
                >
                  <span class="block text-gray-500 truncate">{{ $t('documentGeneralSettings.selectTeams') }}…</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="listbox-options">
                    <ListboxOption
                      v-for="team in remainingTeamOptions"
                      :key="team.uuid"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="team"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ team.name }}</span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <TeamList
              v-if="selectedTeams.length"
              class="mt-3"
              extra-classes="shadow"
              :teams="selectedTeams"
              :removable="true"
              @remove="removeTeam"
            />
          </div>
        </div>

        <div
          v-if="context === CrudContext.document"
          class="rounded-md bg-gray-50"
        >
          <div class="px-5 py-4 gap-5 sm:grid w-full sm:grid-cols-2">
            <div>
              <div class="mb-2 text-sm font-medium">
                {{ $t('documentNotifications.includeSpecificUsers') }}:
              </div>
              <Listbox
                v-model="selectedUser"
                :disabled="!remainingUserOptions.length"
                as="div"
                class="max-w-sm"
              >
                <div class="relative mt-1">
                  <ListboxButton
                    class="btn-white pr-9"
                    :disabled="!remainingUserOptions.length"
                  >
                    <span class="block text-gray-500 truncate">{{ $t('documentNotifications.selectUser') }}…</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions class="listbox-options">
                      <ListboxOption
                        v-for="user in remainingUserOptions"
                        :key="user.uuid"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="user"
                      >
                        <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2 items-center']">
                          <img
                            class="w-5 h-5 rounded-full max-w-none"
                            :src="user.profile_photo_url"
                            :alt="getUserRepresentation(user)"
                          >
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ getUserRepresentation(user) }}</span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <div class="mt-3 space-y-2">
                <div
                  v-for="user in selectedUsers"
                  :key="user.uuid"
                  class="flex items-center space-x-2"
                >
                  <img
                    class="w-6 h-6 rounded-full max-w-none"
                    :src="user.profile_photo_url"
                    :alt="getUserRepresentation(user)"
                  >
                  <div class="text-sm">
                    {{ getUserRepresentation(user) }}
                  </div>
                  <button
                    type="button"
                    @click="removeUser(user)"
                  >
                    <MinusCircleIcon
                      aria-hidden="true"
                      class="w-4 h-4 text-red-500"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div class="mb-2 text-sm font-medium">
                {{ $t('documentNotifications.excludeSpecificUsers') }}:
              </div>
              <Listbox
                v-model="selectedExcludedUser"
                :disabled="!remainingExcludedUserOptions.length"
                as="div"
                class="max-w-sm"
              >
                <div class="relative mt-1">
                  <ListboxButton
                    class="btn-white pr-9"
                    :disabled="!remainingExcludedUserOptions.length"
                  >
                    <span class="block text-gray-500 truncate">{{ $t('documentNotifications.selectUser') }}…</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions class="listbox-options">
                      <ListboxOption
                        v-for="user in remainingExcludedUserOptions"
                        :key="user.uuid"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="user"
                      >
                        <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2 items-center']">
                          <img
                            class="w-5 h-5 rounded-full max-w-none"
                            :src="user.profile_photo_url"
                            :alt="getUserRepresentation(user)"
                          >
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ getUserRepresentation(user) }}</span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <div class="mt-3 space-y-2">
                <div
                  v-for="user in selectedExcludedUsers"
                  :key="user.uuid"
                  class="flex items-center space-x-2"
                >
                  <img
                    class="w-6 h-6 rounded-full max-w-none"
                    :src="user.profile_photo_url"
                    :alt="getUserRepresentation(user)"
                  >
                  <div class="text-sm">
                    {{ getUserRepresentation(user) }}
                  </div>
                  <button
                    type="button"
                    @click="removeExcludedUser(user)"
                  >
                    <MinusCircleIcon
                      aria-hidden="true"
                      class="w-4 h-4 text-red-500"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="context === CrudContext.document"
          class="mt-3 rounded-md px-5 py-4 bg-indigo-50"
        >
          <div class="mb-2 text-sm font-medium text-indigo-600">
            {{ $t('documentNotifications.basedOnSelection') }}
          </div>
          <UserDisplay
            v-if="previewUserList.length"
            :users="previewUserList"
            :limit="10"
          />
          <WarningBox
            v-else
            class="text-sm"
          >
            {{ $t('documentNotifications.noUsersMatch') }}
          </WarningBox>
        </div>
      </div>
    </div>
  </fieldset>
</template>
