import { Editor, mergeAttributes, Node } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { Range, VueNodeViewRenderer } from "@tiptap/vue-3"

import PartyNodeView from "./PartyNodeView.vue"
import { Mark } from "@tiptap/pm/model"
import { trackEventInJune } from "~/utils"
import { JuneEvents } from "~/types"

export const PartiesPluginKey = new PluginKey("parties")

export const PartyDropPluginKey = new PluginKey("partiesDropPlugin")

export const getPartyDropPlugin = (editor: Editor) => {
  return new Plugin(
    {
      key: PartyDropPluginKey,
      props: {
        handleDrop (view, event) {
          if (!event || !editor.isEditable) return false

          const coordinates = view.posAtCoords(
            {
              left: event.clientX,
              top: event.clientY,
            },
          )

          const party = event.dataTransfer.getData("text")

          if (!party) return false

          let partyJson: { isParty: boolean, refUuid: string, format: string }

          try {
            partyJson = JSON.parse(party)
          } catch {
            // No valid JSON, so we can't handle this drop
            return false
          }

          if (!partyJson?.isParty) return false

          event.preventDefault()

          // editor.commands.insertContentAt(coordinates.pos, { type: "text", text: " " })
          const marks = view.state.doc.resolve(coordinates.pos).marks() || []

          trackEventInJune(JuneEvents.EDITOR_PARTY_DROPPED)

          setTimeout(
            () => {
              editor
                .chain()
                .focus()
                .insertContentAt(
                  coordinates.pos,
                  [
                    {
                      type: "party",
                      attrs: {
                        refUuid: partyJson.refUuid,
                        format: partyJson.format,
                      },
                      marks: marks?.map((mark) => ({ type: mark.type.name, attrs: mark.attrs })), // Map ProseMirror marks to the format expected by TipTap's insertContent
                    },
                  ],
                )
                .run()
            },
          )

          return true
        },
      },
    },
  )
}

interface PartyAttrs {
  refUuid: string
  format: string
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    parties: {
      addPartyField: (attrs: PartyAttrs, range?: Range, marks?: Mark[]) => ReturnType;
    };
  }
}

export const Parties = Node.create(
  {
    name: "party",

    addNodeView () {
      return VueNodeViewRenderer(PartyNodeView)
    },

    addProseMirrorPlugins () {
      const { editor } = this

      return [
        getPartyDropPlugin(editor),
      ]
    },

    addOptions () {
      return {
        HTMLAttributes: {},
        renderLabel ({ node }) {
          if (!node.attrs.label) return `~P${node.attrs.refUuid}`
          return `${node.attrs.label}`
        },
      }
    },

    group: "inline",

    inline: true,

    selectable: true,

    atom: true,

    draggable: true,

    addAttributes () {
      return {
        refUuid: {
          default: null,
          parseHTML: (element) => parseInt(element.getAttribute("data-ref-uuid") || ""),
          renderHTML: (attributes) => {
            if (!attributes.refUuid) return {}

            return {
              "data-ref-uuid": attributes.refUuid,
            }
          },
        },
        format: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-format"),
          renderHTML: (attributes) => {
            if (!attributes.format) return {}

            return {
              "data-format": attributes.format,
            }
          },
        },
      }
    },

    parseHTML () {
      return [
        {
          tag: "span[data-party]",
        },
      ]
    },

    renderHTML ({ HTMLAttributes }) {
      return [
        "span",

        mergeAttributes(
          {
            "data-party": "true",
          },
          this.options.HTMLAttributes,
          HTMLAttributes,
        ),
      ]
    },

    renderText ({ node }) {
      return this.options.renderLabel(
        {
          options: this.options,
          node,
        },
      )
    },

    addCommands () {
      return {
        addPartyField: (attrs, range, marks = []) => ({ chain }) => {
          const nodeObject: any = {
            type: this.name,
            attrs,
          }
          if (marks.length) nodeObject.marks = marks
          const content = [ nodeObject ]
          if (range) {
            return chain()
              .focus()
              .insertContentAt(range, content)
              .run()
          }

          trackEventInJune(JuneEvents.EDITOR_PARTY_INSERTED)

          return chain()
            .focus()
            .insertContent(content)
            .run()
        },
      }
    },
  },
)
