<script setup lang="ts">
// external
import { Ref, computed, inject, ref, watch } from "vue"

import { NodeViewProps, NodeViewWrapper, Range, NodeViewContent } from "@tiptap/vue-3"

import { TrashIcon, PlusIcon } from "@heroicons/vue/24/outline"
import { TransitionRoot } from "@headlessui/vue"

const props = defineProps<NodeViewProps>()

const addColToRight = () => props.editor.chain().insertColumnToRight().focus().run()

const isEditable = inject<boolean>("isEditable", false)

const textSelectionRange = inject<Ref<Range>>("textSelectionRange", null)

const isSelected = computed(
  () => {
    if (!textSelectionRange.value) return false

    const { from, to } = textSelectionRange.value

    const nodeFrom = props.getPos()
    const nodeTo = nodeFrom + props.node.nodeSize

    return nodeFrom <= from && to <= nodeTo
  },
)

const isSelectedDelay = ref<boolean>(false)

watch(
  isSelected,
  (value) => {
    setTimeout(() => {
      isSelectedDelay.value = value
    }, 100)
  },
)

const countOfColumnsInParentColumnBlock = computed(
  () => {
    const pos = props.getPos()
    const resolvedPos = props.editor.state.doc.resolve(pos)
    const parentColumnBlock = resolvedPos.node(resolvedPos.depth)
    return parentColumnBlock.childCount
  },
)

</script>

<template>
  <NodeViewWrapper class="relative custom-column">
    <TransitionRoot
      :show="isSelectedDelay && isEditable"
      enter="transition-all duration-150"
      enter-from="translate-y-full opacity-0"
      enter-to="translate-y-0 opacity-100"
      leave="transition-all duration-150"
      leave-from="opacity-100 translate-y-0"
      leave-to="opacity-0 translate-y-full"
      as="div"
      class="absolute right-0 z-10 text-right -top-8"
    >
      <div
        v-if="(countOfColumnsInParentColumnBlock > 2 || countOfColumnsInParentColumnBlock < 4)"
      >
        <button
          v-if="countOfColumnsInParentColumnBlock > 2"
          class="p-1 text-xs font-medium text-indigo-700 bg-indigo-100 border border-transparent cursor-pointer rounded-l-md hover:bg-indigo-200 focus:outline-none"
          :class="countOfColumnsInParentColumnBlock < 4 ? '' : 'rounded-r-md'"
          data-tippy-help
          data-placement="top"
          :data-tippy-content="$t('editor.columnLayout.deleteColumn')"
          data-tippy-append-to="body"
          tabindex="-1"
          @click.stop.prevent="props.deleteNode()"
        >
          <TrashIcon
            class="flex-shrink-0 w-4 h-4"
            aria-hidden="true"
          />
        </button>

        <button
          v-if="countOfColumnsInParentColumnBlock < 4"
          class="p-1 text-xs font-medium text-indigo-700 bg-indigo-100 border border-transparent cursor-pointer rounded-r-md hover:bg-indigo-200 focus:outline-none"
          :class="countOfColumnsInParentColumnBlock > 2 ? '' : 'rounded-l-md'"
          data-tippy-help
          data-placement="top"
          :data-tippy-content="$t('editor.columnLayout.addColToRight')"
          data-tippy-append-to="body"
          tabindex="-1"
          @click.stop.prevent="addColToRight"
        >
          <PlusIcon
            class="flex-shrink-0 w-4 h-4"
            aria-hidden="true"
          />
        </button>
      </div>
    </TransitionRoot>

    <NodeViewContent class="column-content" />
  </NodeViewWrapper>
</template>
