<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, toRaw, watch, onBeforeMount, onMounted } from "vue"
import { useI18n } from "vue-i18n"

import { ArrowDownIcon, CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { toTypedSchema } from "@vee-validate/zod"
import { useField, useForm } from "vee-validate"
import { z } from "zod"

// internal
import { DocumentCombobox, FormInputErrors, SpinLoader } from "~/components"
import { useLinkedDocumentStore, useNotificationStore, useSharedStore } from "~/stores"
import { Document, LinkedDocument, Template } from "~/types"
import { DocumentRelationshipRole, DocumentRelationship } from "~/types/enums"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"

interface Props {
  document?: Document
  template?: Template
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
  },
)
const { notify } = useNotificationStore()
const { t } = useI18n()

const emptyLinkedDocument: Partial<LinkedDocument> =  Object.freeze({ })

const linkedDocumentStore = useLinkedDocumentStore()
const { linkedDocuments, uuidsOfUpdatingLinkedDocument } = storeToRefs(linkedDocumentStore)
const { setIsVisibleLinkedDocumentsModal, getAffiliatedPartyDocuments } = linkedDocumentStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const deactivatedDocumentUuids = computed(() => {
  // Return an array all uuids in controller_document and peripheral_document of each linked document
  const results = linkedDocuments.value?.map((linkedDocument) => {
    return [ linkedDocument.controller_document?.uuid, linkedDocument.peripheral_document?.uuid ]
  }).flat()
  // add props.document uuid if not present
  if (props.document?.uuid && !results.includes(props.document?.uuid)) results.push(props.document?.uuid)
  return results
})

const isLoadingCreateLinkedDocument = ref<boolean>(false)
const isLinkedDocumentLoading = computed<boolean>(() => uuidsOfUpdatingLinkedDocument.value.includes(localLinkedDocument.value?.uuid) || isLoadingCreateLinkedDocument.value)

const selectedDocument = ref<Partial<Document>>(null)

const formValidationErrors = ref<Partial<Record<keyof LinkedDocument, string[]>>>({})

const errorsToShow = computed<Partial<Record<keyof LinkedDocument, string[]>>>(
  () => {
    const errors: Partial<Record<keyof LinkedDocument, string[]>> = {}

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const localLinkedDocument = ref<Partial<LinkedDocument>>(Object.assign({}, emptyLinkedDocument))

const linkedDocumentSchema = z
  .object(
    {
      uuid: z.string().nullable().optional(),
      created_by_account_user: z.object({
        uuid: z.string().nullable().optional(),
      }).nullable().optional(),
      controller_document: z.object(
        {
          uuid: z.string().nullable().optional(),
        },
      ).nullable().optional(),
      peripheral_document: z.object(
        {
          uuid: z.string().nullable().optional(),
        },
      ).nullable().optional(),
      relationship_type: z.nativeEnum(DocumentRelationship).nullable().optional(),
    },
  ).refine((input) => {
    if (!input.controller_document && !input.peripheral_document) return false
    if (!input.relationship_type) return false
    return true
  }, (input) => {
    if ( !input.controller_document && !input.peripheral_document) {
      return {
        message: t("linkedDocuments.errors.document"),
        path: [ "document" ],
      }
    }
    if ( !input.relationship_type) {
      return {
        message: t("linkedDocuments.errors.relationship"),
        path: [ "relationship_type" ],
      }
    }
  })

type LinkedDocumentValidatedType = z.infer<typeof linkedDocumentSchema>

const linkedDocumentValidator = toTypedSchema(linkedDocumentSchema)

const { errors, setValues, setFieldValue, validate } = useForm<LinkedDocumentValidatedType>(
  {
    validationSchema: linkedDocumentValidator,
  },
)

const updateFieldValue = (fieldName: keyof LinkedDocument, val: any) => {
  setFieldValue(fieldName as any, val)
  localLinkedDocument.value = {
    ...localLinkedDocument.value,
    [fieldName]: val,
  }
}

watch(
  ()  => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof LinkedDocument, string[]>>,
      )
  },
)

useField<number>("controller_document")
useField<number>("peripheral_document")
useField<string>("relationship_type")

// Function to create / add a new linked document
const createLinkedDocument = async () => {

  // Validate
  const isValid = await validate(
    {
      mode: "force",
    },
  )

  if (!isValid.valid) return

  const entityUuid = props?.[crudContext.value]?.uuid

  const payload = toRaw(localLinkedDocument.value)

  try {
    isLoadingCreateLinkedDocument.value = true

    interface MappedPayload {
      relationship_type: DocumentRelationship
      controller_document_uuid?: number
      peripheral_document_uuid?: number
    }

    // Mapped payload so that controller_document and periphal_document are flattend
    let mappedPayload: MappedPayload = {
      relationship_type: payload?.relationship_type,
    }

    if (payload?.controller_document?.uuid && payload?.controller_document?.uuid !== props.document?.uuid) {
      mappedPayload = {
        ...mappedPayload,
        controller_document_uuid: payload?.controller_document?.uuid as any,
      }
    } else if (payload?.peripheral_document?.uuid  && payload?.peripheral_document?.uuid !== props.document?.uuid) {
      mappedPayload = {
        ...mappedPayload,
        peripheral_document_uuid: payload?.peripheral_document?.uuid as any,
      }
    }

    const createdLinkedDocument = await linkedDocumentStore.createLinkedDocument(entityUuid, mappedPayload)

    if (!createdLinkedDocument) throw new Error(t("linkedDocuments.errors.invalidLinkedDocument", { string: JSON.stringify({ createdLinkedDocument: createdLinkedDocument }) }))

    setIsVisibleLinkedDocumentsModal(false)

    localLinkedDocument.value = Object.assign({}, emptyLinkedDocument)
    linkedDocumentStore.setLinkedDocumentErrors("0", null)
    selectedDocument.value = null

  } catch (err) {
    linkedDocumentStore.setLinkedDocumentErrors("0", err.response?.data?.errors || null)

    notify({
      title: t("linkedDocuments.errors.addLinkedDocument"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingCreateLinkedDocument.value = false
  }
}

watch(
  () => selectedDocument.value,
  () => {
    if (selectedDocument.value?.uuid) {
      const existingUuid = selectedDocument.value?.uuid
      localLinkedDocument.value = {
        ...localLinkedDocument.value,
        [localRelationshipTargetKey.value]: { uuid: existingUuid },
        [localRelationshipOppositeKey.value]: { uuid: props.document?.uuid },
      }
      setFieldValue(localRelationshipTargetKey.value, { uuid: existingUuid })
      setFieldValue(localRelationshipOppositeKey.value, { uuid: props.document?.uuid })
    } else {
      localLinkedDocument.value = {
        ...localLinkedDocument.value,
        [localRelationshipTargetKey.value]: null,
        [localRelationshipOppositeKey.value]: null,
      }
      setFieldValue(localRelationshipTargetKey.value, null)
      setFieldValue(localRelationshipOppositeKey.value, null)
    }
  },
)

onBeforeMount(() => {
  // Set field values
  setValues(localLinkedDocument.value)
})

onMounted(() => {
  getAffiliatedPartyDocuments(props.document?.uuid || props.template?.uuid)
})

const localRelationshipTargetKey = ref<"controller_document" | "peripheral_document">("peripheral_document")
const localRelationshipOppositeKey = ref<"controller_document" | "peripheral_document">("peripheral_document")

const handleUpdateRelationship = (data: RelationshipOption) => {

  // Depend target key for document depending on relationship and role
  localRelationshipTargetKey.value = data?.role === DocumentRelationshipRole.controller ? "peripheral_document" : "controller_document"
  localRelationshipOppositeKey.value = data?.role === DocumentRelationshipRole.controller ? "controller_document" : "peripheral_document"

  if (!data) {
    updateFieldValue("relationship_type", null)
    updateFieldValue(localRelationshipTargetKey.value, null)
    updateFieldValue(localRelationshipOppositeKey.value, null)
    return
  }
  updateFieldValue("relationship_type", data.relationship)
  updateFieldValue(localRelationshipTargetKey.value, { uuid: selectedDocument.value?.uuid })
  updateFieldValue(localRelationshipOppositeKey.value, { uuid: props.document?.uuid })
}

interface RelationshipOption {
  label: string
  relationship: DocumentRelationship
  role: DocumentRelationshipRole
}

const selectOptions = computed<RelationshipOption[]>(() => {
  // Return an option for each combination of DocumentRelationship and DocumentRelationshipRole
  // Depending of relationship field value and role, we need to return the right string
  const returnOptions: RelationshipOption[] = []
  Object.keys(DocumentRelationship).map((relationship) => {
    const options = Object.keys(DocumentRelationshipRole).map((role) => {
      return {
        label: t(`linkedDocuments.documentRelationships.${relationship}.${role}`),
        relationship: (relationship as DocumentRelationship),
        role: (role as DocumentRelationshipRole),
      }
    })
    returnOptions.push(...options)
  })
  return returnOptions
})

const selectedRelationship = computed(() => {
  const role = localRelationshipTargetKey.value === "controller_document" ? DocumentRelationshipRole.peripheral : DocumentRelationshipRole.controller
  return selectOptions.value.find((option) => option.relationship === localLinkedDocument.value?.relationship_type && option.role === role)
})

</script>

<template>
  <div
    id="addLinkedDocumentForm"
    v-cy="`linked-document-popover`"
  >
    <div class="px-6 pb-2">
      <div>
        <div
          class="text-lg"
          data-cy-sel="linked-document-modal-header"
        >
          {{ $t('linkedDocuments.addLinkedDocument') }}
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col gap-3 px-6 pt-2 pb-6">
        <div>
          <div class="px-6 mb-3 -mx-6 border-b border-b-gray-200" />
          <div class="px-6 pt-3 pb-6 -mx-6 -mt-3 -mb-6 bg-gray-100">
            <div class="pb-2 text-sm font-medium text-indigo-700">
              <div class="flex-1 text-[10px] font-medium tracking-wider uppercase text-slate-500">
                {{ $t('linkedDocuments.currentDocument') }}
              </div> {{ document.name }}
            </div>
            <div>
              <div class="mt-1">
                <Listbox
                  id="linkedDocument_relationship_0"
                  :model-value="selectedRelationship"
                  as="div"
                  class="relative"
                  :class="[{'error': !!(errorsToShow?.relationship_type?.length)}]"
                  @update:model-value="handleUpdateRelationship"
                >
                  <ListboxButton
                    :class="selectedRelationship ? 'pr-9' : ''"
                    class="w-full pl-3 pr-8 font-normal text-left btn-white focus:ring-1 focus:ring-offset-0 focus:border-indigo-500"
                  >
                    <span
                      v-if="selectedRelationship"
                      class="block truncate"
                    >
                      {{ selectedRelationship?.label }}
                    </span>
                    <span
                      v-else
                      class="block text-gray-500 truncate"
                    >
                      {{ $t('linkedDocuments.selectRelationship') }}…
                    </span>
                    <span
                      v-if="selectedRelationship"
                      class="absolute inset-y-0 right-0 flex items-center pr-2.5 cursor-pointer"
                      @click.prevent="handleUpdateRelationship(null)"
                    >
                      <XMarkIcon
                        class="w-4 h-4 text-gray-400 hover:text-gray-600"
                        aria-hidden="true"
                      />
                    </span>
                    <span
                      v-else
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <ChevronUpDownIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>
                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="inset-x-0 min-w-0 text-left max-w-none listbox-options"
                    >
                      <div
                        v-if="!selectOptions?.length"
                        class="px-3 py-2 text-sm text-gray-500"
                      >
                        {{ $t('common.noOptionsFound') }}.
                      </div>
                      <ListboxOption
                        v-for="option, optionIdx in selectOptions"
                        :key="optionIdx"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="option"
                      >
                        <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                          <span
                            :class="[
                              selected ? 'font-semibold mr-2' : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ option?.label }}
                          </span>

                          <span
                            v-if="selected"
                            :class="[
                              active ? 'text-white' : 'text-indigo-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon
                              class="w-5 h-5 shrink-0"
                              aria-hidden="true"
                            />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </Listbox>
              </div>
              <FormInputErrors
                v-if="errorsToShow?.relationship_type?.length"
                :errors="errorsToShow?.relationship_type"
              />
            </div>
            <div class="flex items-center justify-center px-6 py-1.5 -mx-6">
              <ArrowDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div>
              <div class="relative z-10 mt-1">
                <div class="flex items-center gap-2">
                  <DocumentCombobox
                    id="linkedDocument_0"
                    v-model:selected-document="selectedDocument"
                    align="bottom"
                    class="flex-grow max-w-full"
                    :static="true"
                    :deactivated-document-ids="deactivatedDocumentUuids"
                    :document-ids-to-exclude="[props.document?.uuid]"
                  />
                  <slot name="deleteButton" />
                </div>

                <FormInputErrors
                  v-if="errorsToShow?.controller_document?.length"
                  :errors="errorsToShow?.controller_document"
                />
                <FormInputErrors
                  v-if="errorsToShow?.peripheral_document?.length"
                  :errors="errorsToShow?.peripheral_document"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-end gap-2 p-3 bg-gray-100 border-t border-gray-200 rounded-b-md">
        <button
          v-cy="`linked-document-form-submit-button`"
          :disabled="isLinkedDocumentLoading"
          type="button"
          class="inline-flex items-center gap-2 btn-primary"
          @click.prevent="createLinkedDocument()"
        >
          <SpinLoader
            v-if="isLinkedDocumentLoading"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span v-if="isLinkedDocumentLoading">{{ $t('common.saving') }}…</span>
          <span v-else>{{ $t('common.save') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
