
<script setup lang="ts">
// external
import { ref } from "vue"
import { Vue3Lottie } from "vue3-lottie"
import { PlusIcon } from "@heroicons/vue/20/solid"

// internal
import { Onboarding, Scan, Success } from "~/lottie"
import { OnboardingStepContainer } from "~/components"
import { ArrowLongLeftIcon } from "@heroicons/vue/24/outline"

interface Props {
  hasAiAnalysis?: boolean
  isReadyAiAnalysis?: boolean
  isAppliedAiAnalysis?: boolean
  isSkippedAiAnalysis?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasAiAnalysis: false,
  isReadyAiAnalysis: false,
  isAppliedAiAnalysis: false,
})

const goingBack = ref(false)

const toggleAiAnalysisIntro = () => {
  emit("set-is-skipped-ai-analysis", !props.isSkippedAiAnalysis)
  goingBack.value = !goingBack.value
}

const emit = defineEmits([ "start", "set-is-skipped-ai-analysis" ])
</script>
<template>
  <OnboardingStepContainer
    v-cy="`onboarding-step-intro`"
  >
    <template #default>
      <div class="overflow-x-hidden">
        <Transition
          mode="out-in"
          :name="goingBack ? 'slide-fade' : 'slide-fade-back'"
        >
          <div
            v-if="hasAiAnalysis && !isSkippedAiAnalysis"
            class="flex flex-col items-center justify-center h-full space-y-5 text-center"
          >
            <div class="flex flex-col items-center justify-center w-full h-full mx-auto space-y-5">
              <div class="flex flex-col items-center justify-center">
                <Vue3Lottie
                  v-if="!isReadyAiAnalysis"
                  :animation-data="Scan"
                  class="max-w-48"
                  aria-hidden="true"
                />
                <Vue3Lottie
                  v-else
                  :animation-data="Success"
                  :loop="false"
                  class="max-w-48"
                  aria-hidden="true"
                />
                <div class="max-w-lg px-4 pb-4 text-base text-center">
                  <template
                    v-if="!isReadyAiAnalysis"
                  >
                    <h3
                      class="text-lg font-semibold text-indigo-500"
                    >
                      {{ $t('auth.registerShowcase.importActiveTitle') }}
                    </h3>
                    <div
                      class="mb-3 -mt-1 text-base text-indigo-500"
                    >
                      {{ $t('auth.registerShowcase.approxMinutes') }}
                    </div>
                  </template>
                  <h3
                    v-else
                    class="mb-2 -mt-6 text-lg font-semibold text-indigo-500"
                  >
                    {{ $t('auth.registerShowcase.importReadyTitle') }}
                  </h3>
                  <div v-if="!isReadyAiAnalysis">
                    {{ $t('auth.registerShowcase.importActiveOnboardingIntro') }}
                  </div>
                  <div v-else>
                    {{ $t('auth.registerShowcase.importReadyOnboardingIntro') }}
                  </div>
                  <div class="flex items-center justify-center gap-3 mt-6 min-h-12">
                    <slot name="aiAnalysisButton" />
                    <button
                      v-cy="`toggle-onboarding-button`"
                      type="button"
                      :class="isReadyAiAnalysis ? 'text-base font-semibold transition-all ease-in-out btn-white hover:scale-105 hover:shadow-md' : 'text-base font-semibold transition-all ease-in-out btn-primary hover:scale-105 hover:shadow-md'"
                      @click="toggleAiAnalysisIntro"
                    >
                      <span>{{ $t('onboarding.finishMyAccount') }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="space-y-5 text-center"
          >
            <div class="relative w-full">
              <div class="absolute inset-0 flex items-center justify-center">
                <div class="block w-full h-full m-12 bg-gray-100 rounded-full max-w-60 max-h-60" />
              </div>
              <Vue3Lottie
                :animation-data="Onboarding"
                class="max-w-72"
                aria-hidden="true"
              />
            </div>
            <div
              v-if="!isAppliedAiAnalysis"
              class="mx-auto max-w-96"
            >
              <h3 class="mb-2 text-lg font-semibold text-indigo-600">
                {{ $t('onboarding.steps.intro.headline') }}
              </h3>
              <p class="text-sm">
                {{ $t('onboarding.steps.intro.copy') }}
              </p>
            </div>
            <div
              v-else
              class="mx-auto max-w-96"
            >
              <h3 class="mb-2 text-lg font-semibold text-indigo-600">
                {{ $t('onboarding.steps.intro.headlineAppliedAiAnalysis') }}
              </h3>
              <p class="text-sm">
                {{ $t('onboarding.steps.intro.copyAppliedAiAnalysis') }}
              </p>
            </div>
            <div class="flex items-center justify-center gap-3 min-h-12 pb-2">
              <button
                v-if="hasAiAnalysis"
                v-cy="`get-started-button`"
                type="button"
                class="flex items-center gap-1 text-base font-semibold transition-all ease-in-out btn-plain hover:bg-gray-100"
                @click="toggleAiAnalysisIntro"
              >
                <ArrowLongLeftIcon
                  class="w-5 h-5"
                  aria-hidden="true"
                />
                <span>{{ $t('onboarding.goBack') }}</span>
              </button>
              <button
                v-cy="`get-started-button`"
                type="button"
                class="text-base font-semibold transition-all ease-in-out btn-primary hover:scale-105 hover:shadow-md"
                @click="$emit('start')"
              >
                <span>{{ $t('onboarding.getStarted') }}</span>
              </button>
            </div>
          </div>
        </Transition>
      </div>
      <div
        class="absolute items-center justify-center hidden w-20 h-20 -ml-10 text-gray-300 rounded-full bg-gradient-to-r from-gray-100 to-30% to-gray-50 sm:flex left-full top-1/2 -mt-10"
      >
        <PlusIcon
          class="w-10 h-10"
          aria-hidden="true"
        />
      </div>
    </template>
    <template #aside>
      <div class="flex flex-col items-center justify-center h-full space-y-5 text-center">
        <div class="flex flex-col items-center justify-center w-full h-full mx-auto space-y-5">
          <div class="flex items-center justify-center">
            <img
              class="w-full rounded-full shadow-lg max-w-32 ring-4 ring-white"
              :src="asset('img/presle.jpeg')"
              alt="Markus Presle"
            >
          </div>
          <div class="px-5 text-gray-600">
            <h3 class="mb-2 font-semibold">
              {{ $t('onboarding.steps.intro.scheduleHeadline') }}
            </h3>
            <p class="mb-4 text-sm">
              {{ $t('onboarding.steps.intro.scheduleCopy') }}
            </p>
            <a
              href="https://fynk.pipedrive.com/scheduler/MljdJzCK/fynk-webdemo"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-flex items-center gap-x-1.5 btn-white"
            >
              <svg
                class="-ml-0.5 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>
              {{ $t('notActivated.schedule') }}
            </a>
          </div>
        </div>
      </div>
    </template>
  </OnboardingStepContainer>
</template>
