<script setup lang="ts">
// external
import { BellIcon } from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { watch, ref } from "vue"

// internal
import { useNotificationStore } from "~/stores"

const notificationStore = useNotificationStore()
const { unreadNotificationsCount, showNotificationModal } = storeToRefs(notificationStore)

interface Props {
  showText?: boolean
  iconOnly?: boolean
  iconClasses?: string
}

withDefaults(defineProps<Props>(), {
  showText: false,
  iconOnly: false,
  iconClasses: "w-6 h-6 text-white/50",
})

const isShaking = ref(false)

watch(unreadNotificationsCount, (newVal, oldVal) => {
  if (newVal > oldVal) {
    shakeBell()
  }
})

const shakeBell = () => {
  isShaking.value = true
  setTimeout(() => {
    isShaking.value = false
  }, 500)
}

</script>
<template>
  <button
    type="button"
    class="relative overflow-hidden"
    @click="showNotificationModal = true"
  >
    <div
      class="relative"
    >
      <BellIcon
        class="shrink-0"
        :class="[iconClasses, {'bellshake': isShaking}]"
        aria-hidden="true"
      />
      <span
        v-if="!!unreadNotificationsCount"
        class="absolute left-[13px] -top-[4px] rounded-full text-[8px] text-white bg-indigo-400 font-semibold flex w-[13px] h-[13px] items-center justify-center"
      >
        <span v-if="unreadNotificationsCount < 100">{{ unreadNotificationsCount }}</span>
        <span
          v-else
          class="block absolute w-[5px] h-[5px] rounded-full bg-white top-[4px] left-[4px]"
        />
      </span>
    </div>
    <span
      v-if="!iconOnly"
      class="inline-block overflow-x-hidden transition-all duration-500 opacity-0 group-hover:w-auto group-hover:opacity-100 group-hover:ml-3"
      :class="[showText ? 'w-auto opacity-100 ml-3' : 'w-0']"
    >
      {{ $t('navigation.notifications') }}
    </span>
  </button>
</template>
