<script setup lang="ts">
import { computed } from "vue"

import { MenuItem } from "@headlessui/vue"
import { Link } from "@inertiajs/vue3"

interface Props {
  href?: string,
  as?: string,
  icon?: boolean,
}

const props = withDefaults(
  defineProps<Props>(),
  {
    href: "#",
    as: "div",
    icon: false,
  },
)

const emit = defineEmits([ "click" ])

const emitClick = (e: PointerEvent) => emit("click", e)

const commonClasses = " px-4 py-2 focus:outline-none focus:bg-white focus:bg-opacity-20 transition hover:bg-opacity-10 hover:bg-white text-sm disabled:hover:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed"

const classes = computed(() => {
  return props.icon
    ? "group flex items-center"
    : "block"
})
</script>

<template>
  <MenuItem
    v-slot="{ active, disabled }"
    as="div"
  >
    <button
      v-if="props.as === ('button')"
      :disabled="disabled"
      type="button"
      :class="[
        active ? 'bg-white bg-opacity-10 text-white' : 'text-gray-200',
        'w-full text-left',
        commonClasses,
        classes,
      ]"
      @click="emitClick($event)"
    >
      <slot />
    </button>

    <button
      v-else-if="props.as === ('button.prevent')"
      type="button"
      :class="[
        active ? 'bg-white bg-opacity-10 text-white' : 'text-gray-200',
        'w-full text-left',
        commonClasses,
        classes,
      ]"
      :disabled="disabled"
      @click.prevent="emitClick($event)"
    >
      <slot />
    </button>

    <a
      v-else-if="as === 'a'"
      :href="!disabled ? href : ''"
      :class="[
        active ? 'bg-white bg-opacity-10 text-white' : 'text-gray-200',
        commonClasses,
        classes,
      ]"
    >
      <slot />
    </a>

    <Link
      v-else
      :href="href"
      :class="[
        active ? 'block bg-white bg-opacity-10 text-white' : 'text-gray-200',
        commonClasses,
        classes,
      ]"
    >
      <slot />
    </Link>
  </MenuItem>
</template>
