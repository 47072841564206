<script setup lang="ts">
// external
import { ref } from "vue"
import { Form, Field } from "vee-validate"
import { router } from "@inertiajs/vue3"

// internal
import { DialogModal, PasswordScore, SpinLoader } from "~/components"
import { DocumentRevision, DocumentUser } from "~/types"
import { NotificationIcon, PieChartIcon, SecurityCheckIcon, ESignatureIcon } from "~/icons"
import { useDocumentStore } from "~/stores"
import { CloudArrowDownIcon, LockClosedIcon } from "@heroicons/vue/24/solid"
import { SparklesIcon, UserCircleIcon } from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { PASSWORD_REGEX } from "~/utils"

interface Props {
  show: boolean
  mdu: DocumentUser
  token: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

defineEmits([ "update:show" ])

const benefits = computed(() => {

  let benefits = [

    {
      key: "reminders",
      icon: NotificationIcon,
    },
    {
      key: "centralized",
      icon: SecurityCheckIcon,
    },
    {
      key: "analyze",
      icon: PieChartIcon,
    },
  ]

  if (!accountIdentified.value) {
    benefits = [
      {
        key: "signature",
        icon: ESignatureIcon,
      },
      ...benefits,
    ]
  } else {
    benefits = [
      {
        key: "signatureExistingAccount",
        icon: ESignatureIcon,
      },
      ...benefits,
    ]
  }

  return benefits
})

const documentStore = useDocumentStore()
const { getPdfDownload } = documentStore
const { isLoadingPdfDownload, currentDocument, revisions } = storeToRefs(documentStore)

const currentRevision = computed<DocumentRevision>(() => revisions.value?.length ? revisions.value[0] : null)

const showForm = ref(false)
const password = ref(null)
const confirmPassword = ref(null)
const isSubmitting = ref(false)

// TODO: check if account exists
const accountIdentified = ref(false)

const onSubmit = async () => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    router.post(route("api.document-user.account-create", props.mdu.uuid), {
      token: props.token,
      password: password.value,
    }, {
      onFinish: () => {
        isSubmitting.value = false
      },
    })
  }
}
</script>
<template>
  <DialogModal
    :show="show"
    :padding="false"
    :show-header="false"
    :show-footer="false"
    :show-close-button="true"
    max-width="4xl"
    :closeable="true"
    @close="$emit('update:show', false)"
  >
    <template #content>
      <div
        v-cy="`plg-modal`"
        class="-mt-4"
      >
        <div class="p-6">
          <h1 class="text-3xl font-bold text-center">
            {{ $t('plg.modal.headline') }}
          </h1>
          <h2 class="text-3xl font-bold text-center text-gray-300">
            {{ $t('plg.modal.cta') }}
          </h2>
        </div>
        <div class="grid items-start w-full md:grid-cols-2">
          <div class="order-2 p-10 pt-0 space-y-6 md:pt-6 md:order-1">
            <div
              v-for="benefit in benefits"
              :key="benefit.key"
              class="flex items-center space-x-6"
            >
              <component
                :is="benefit.icon"
                aria-hidden="true"
                class="w-10 h-10 text-indigo-300 shrink-0"
              />
              <div class="grow">
                <h4 class="text-sm font-medium">
                  {{ $t(`plg.benefits.${benefit.key}.title`) }}
                </h4>
                <p class="text-sm text-gray-600">
                  {{ $t(`plg.benefits.${benefit.key}.description`) }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="order-1 p-10 pt-0 md:order-2 lg:pl-0"
          >
            <div class="p-6 bg-gray-100 rounded-md">
              <template v-if="!accountIdentified">
                <h2 class="mb-2 text-xl font-semibold">
                  {{ $t('plg.modal.subHeadline') }}
                </h2>
                <p class="text-sm">
                  {{ $t('plg.modal.copy') }}
                </p>
              </template>
              <template v-else>
                <h2 class="mb-2 text-xl font-semibold">
                  <i18n-t
                    keypath="plg.modal.accountExists"
                    tag="span"
                  >
                    <span class="px-1 py-0.5 rounded-md bg-indigo-100 text-indigo-800">finmatics</span>
                  </i18n-t>
                </h2>
                <div
                  v-if="accountIdentified"
                  class="flex items-center gap-2 my-5 text-xl text-indigo-500"
                >
                  <UserCircleIcon
                    class="w-6 h-6 shrink-0"
                    aria-hidden="true"
                  />
                  <span class="break-all">knut{{ mdu.email }}</span>
                </div>
                <p class="text-sm">
                  <i18n-t
                    keypath="plg.modal.accountExistsCopy"
                    tag="span"
                  >
                    <span class="px-1 py-0.5 rounded-md bg-indigo-100 text-indigo-800">finmatics.com</span>
                  </i18n-t>
                </p>
              </template>
              <template
                v-if="!showForm"
              >
                <div
                  v-if="!accountIdentified"
                  class="flex items-center gap-2 my-5 text-xl text-indigo-500"
                >
                  <UserCircleIcon
                    class="w-6 h-6 shrink-0"
                    aria-hidden="true"
                  />
                  <span class="break-all">knut{{ mdu.email }}</span>
                </div>
                <div class="my-5 text-xs text-gray-600">
                  <i18n-t
                    keypath="plg.modal.tosAgreement"
                    tag="span"
                  >
                    <span v-if="!accountIdentified">"{{ $t('plg.modal.button') }}"</span>
                    <span v-else>"{{ $t('plg.modal.joinAccount') }}"</span>
                    <a
                      :href="$t('auth.register.privacyLink')"
                      class="underline link-primary"
                      target="_blank"
                    >{{ $t('plg.modal.privacyPolicy') }}</a>
                    <a
                      :href="$t('auth.register.termsLink')"
                      class="underline link-primary"
                      target="_blank"
                    >{{ $t('plg.modal.terms') }}</a>
                  </i18n-t>
                </div>
                <button
                  type="button"
                  class="flex items-center justify-center w-full gap-2 text-base text-center btn-primary"
                  @click="showForm = true"
                >
                  <LockClosedIcon
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                  <span v-if="!accountIdentified">{{ $t('plg.modal.button') }}</span>
                  <span v-else>{{ $t('plg.modal.joinAccount') }}</span>
                </button>
              </template>
              <Form
                v-else
                v-slot="{ errors }"
                class="mt-5 space-y-4"
                @submit="onSubmit"
              >
                <div>
                  <label
                    for="password"
                    class="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {{ $t('auth.login.password') }}
                  </label>
                  <Field
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    class="block w-full mt-1 input-primary"
                    :class="[errors.password ? 'error' : '']"
                    required
                    autocomplete="new-password"
                    :placeholder="$t('auth.login.passwordPlaceholder') + '…'"
                    :rules="{required: true, regex: PASSWORD_REGEX }"
                  />
                  <PasswordScore
                    v-if="!!password"
                    :value="password"
                    class="max-w-xl mt-2"
                  />
                </div>

                <div>
                  <label
                    for="password_confirmation"
                    class="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {{ $t('auth.register.confirmPassword') }}
                  </label>
                  <Field
                    id="password_confirmation"
                    v-model="confirmPassword"
                    name="password_confirmation"
                    type="password"
                    class="block w-full mt-1 input-primary"
                    :class="[errors.password_confirmation ? 'error' : '']"
                    required
                    autocomplete="new-password"
                    :placeholder="$t('auth.register.confirmPasswordPlaceholder') + '…'"
                    rules="required|confirmed:@password"
                  />
                </div>
                <button
                  type="submit"
                  class="flex items-center justify-center w-full space-x-2 text-base text-center btn-primary"
                  :disabled="isSubmitting"
                >
                  <SpinLoader
                    v-if="isSubmitting"
                    class="w-4 h-4 shrink-0"
                  />
                  <SparklesIcon
                    v-else
                    class="w-4 h-4 shrink-0"
                  />
                  <span>{{ $t('plg.modal.createAccount') }}</span>
                </button>
              </Form>
              <div>
                <div class="relative my-6 border-t border-t-gray-200">
                  <span class="absolute inset-x-0 inset-y-0 -mt-3.5 text-center">
                    <span class="px-3 text-sm text-gray-500 bg-gray-100">{{ $t("common.or") }}</span>
                  </span>
                </div>
                <a
                  class="flex items-center justify-center w-full gap-2 text-base text-center cursor-pointer btn-white"
                  @click.prevent="getPdfDownload(currentDocument.uuid)"
                >
                  <CloudArrowDownIcon
                    v-if="!isLoadingPdfDownload[currentRevision?.uuid]"
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                  <SpinLoader
                    v-else
                    class="w-4 h-4 shrink-0"
                  />
                  <span v-if="!isLoadingPdfDownload[currentRevision?.uuid]">{{ $t('plg.modal.downloadCopy') }}</span>
                  <span v-else>{{ $t('documents.generatingPdf') }}…</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
