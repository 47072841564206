<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref, watch } from "vue"
import { Link } from "@inertiajs/vue3"
import { Form, Field } from "vee-validate"
import { TrashIcon } from "@heroicons/vue/24/outline"

// internal
import { DialogModal, CopyButton } from "~/components"
import { useBookmarkStore } from "~/stores"

interface Props {
  show: boolean
  listName: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const bookmarkStore = useBookmarkStore()
const { bookmarks, isCreatingBookmark, isDeletingBookmark } = storeToRefs(bookmarkStore)
const { createBookmark, deleteBookmark } = bookmarkStore

const name = ref("")

const emit = defineEmits([ "update:show" ])

watch(() => props.show, (newVal) => {
  if (!newVal) {
    name.value = ""
  }
})

const create = async (values, { resetForm }) => {
  await createBookmark({
    ...values,
    list_name: props.listName,
    parameters: window.location.search.length ? window.location.search : "?",
  })
  resetForm()
  emit("update:show", false)
}
</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="lg"
    :padding="false"
    :show-close-button="true"
    :show-footer="false"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ $t('bookmarks.manageBookmarks') }}
      </div>
    </template>
    <template #content>
      <div class="px-6 pb-6">
        <div class="mb-1 text-sm font-medium">
          {{ $t('bookmarks.createNew') }}:
        </div>
        <Form
          v-slot="{ errors }"
          class="flex items-center space-x-2"
          @submit="create"
        >
          <Field
            v-model="name"
            name="name"
            class="input-plain grow"
            :placeholder="$t('bookmarks.namePlaceholder') + '…'"
            type="text"
            rules="required"
            :class="[errors.name ? 'error' : '']"
          />
          <button
            type="submit"
            class="btn-primary"
            :disabled="isCreatingBookmark"
          >
            {{ $t('common.save') }}
          </button>
        </Form>
        <div class="mt-5">
          <div class="text-sm font-medium">
            {{ $t('bookmarks.bookmarkedSearches') }}:
          </div>
          <div
            v-if="bookmarks.length"
            class="divide-y divide-gray-200"
          >
            <div
              v-for="bookmark in bookmarks"
              :key="'bookmark_' + bookmark.uuid"
              class="flex items-center justify-between space-x-2 text-sm"
            >
              <Link
                :href="route('documents.index') + bookmark.parameters"
                class="py-2 text-sm text-indigo-500"
              >
                {{ bookmark.name }}
              </Link>
              <div class="flex items-center space-x-5">
                <CopyButton
                  :input="route('documents.index') + bookmark.parameters"
                  class=""
                />
                <button
                  class="p-0 text-gray-400 btn-plain hover:text-gray-600"
                  type="button"
                  :disabled="isDeletingBookmark"
                  @click="deleteBookmark(bookmark.uuid)"
                >
                  <TrashIcon
                    aria-hidden="true"
                    class="w-4 h-4"
                  />
                  <span class="sr-only">{{ $t('common.remove') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mt-2 text-sm text-gray-500"
          >
            {{ $t('bookmarks.noBookmarks') }}
          </div>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
