<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { nextTick, onBeforeMount, ref, shallowRef, watch, computed, onMounted, onBeforeUnmount } from "vue"
import { ArrowPathIcon, SquaresPlusIcon } from "@heroicons/vue/24/solid"
import { XMarkIcon } from "@heroicons/vue/20/solid"
import { GridLayout, GridItem } from "grid-layout-plus"

// internal
import {
  DashboardWidgetCreatedDocuments,
  DashboardWidgetDocuments,
  DashboardWidgetDocumentsByStage,
  DashboardWidgetDocumentsByTeam,
  DashboardWidgetDocumentsByTemplate,
  DashboardWidgetCustom,
  DashboardWidgetSignedDocuments,
  DashboardWidgetTasks,
  DashboardWidgetUpcomingWorkload,
  DocumentCalendar,
  DashboardWidgetContractValues,
  DashboardWidgetClauses,
  DashboardWidgetParties,
  LoadingPlaceholder,
} from "~/components"
import { useDashboardStore } from "~/stores"
import { DashboardScope, DashboardTab } from "~/types"
import { DragIndicatorIcon } from "~/icons"

const allWidgets = shallowRef({
  "tasks": {
    config: {
      w: 3,
      h: 4,
      minH: 4,
      maxH: 6,
      minW: 3,
    },
    component: DashboardWidgetTasks,
    scopes: [
      DashboardScope.personal,
    ],
  },
  "documentsByStage": {
    config: {
      w: 3,
      h: 4,
      minH: 3,
      maxH: 4,
      minW: 3,
    },
    component: DashboardWidgetDocumentsByStage,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "documentsByTeam": {
    config: {
      w: 3,
      h: 4,
      minH: 3,
      maxH: 4,
      minW: 3,
    },
    component: DashboardWidgetDocumentsByTeam,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
    ],
  },
  "documentsByTemplate": {
    config: {
      w: 3,
      h: 4,
      minH: 3,
      maxH: 4,
      minW: 3,
    },
    component: DashboardWidgetDocumentsByTemplate,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "documentList": {
    config: {
      w: 9,
      h: 7,
      minH: 7,
      maxH: 7,
      minW: 3,
    },
    component: DashboardWidgetDocuments,
    scopes: [
      DashboardScope.personal,
    ],
  },
  "documentCalendar": {
    config: {
      w: 9,
      h: 8,
      minH: 4,
      minW: 3,
    },
    component: DocumentCalendar,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "upcomingWorkload": {
    config: {
      w: 3,
      h: 4,
      minH: 4,
      maxH: 4,
      minW: 3,
    },
    component: DashboardWidgetUpcomingWorkload,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "contractValues": {
    config: {
      w: 3,
      h: 4,
      minH: 4,
      maxH: 8,
      minW: 3,
    },
    component: DashboardWidgetContractValues,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "createdDocuments": {
    config: {
      w: 2,
      h: 2,
      minH: 2,
      maxH: 2,
      minW: 2,
    },
    component: DashboardWidgetCreatedDocuments,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "signedDocuments": {
    config: {
      w: 2,
      h: 2,
      minH: 2,
      maxH: 2,
      minW: 2,
    },
    component: DashboardWidgetSignedDocuments,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "clauses": {
    config: {
      w: 3,
      h: 4,
      minH: 4,
      maxH: 12,
      maxW: 6,
      minW: 2,
    },
    component: DashboardWidgetClauses,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "parties": {
    config: {
      w: 3,
      h: 4,
      minH: 4,
      maxH: 12,
      maxW: 6,
      minW: 2,
    },
    component: DashboardWidgetParties,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
  "custom": {
    config: {
      w: 3,
      h: 4,
      minH: 3,
      maxH: 12,
      minW: 2,
    },
    component: DashboardWidgetCustom,
    scopes: [
      DashboardScope.personal,
      DashboardScope.account,
      DashboardScope.team,
    ],
  },
})

const dashboardStore = useDashboardStore()
const { isEditMode, widgetsToAdd, dashboards, activeDashboardUuid: activeDashboardUuid, isSwitchingDashboard, dropKey } = storeToRefs(dashboardStore)
const { setLayout, setAllWidgets, restoreDefaultLayout, createOrUpdateDashboard, createCustomWidget, deleteCustomWidget, toggleEditMode } = dashboardStore

const activeDashboard = computed(() => dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value))

const layout = computed({
  get: () => activeDashboard.value?.layout ?? [],
  set: (layout) => setLayout(layout),
})


const removeWidget = async (key) => {
  if (key.includes("custom")) {
    const uuid = key.split("_")[1]
    await deleteCustomWidget(uuid)
  }
  const index = layout.value.map((item) => item.i).indexOf(key)
  const tmpLayout = [ ...layout.value ]
  tmpLayout.splice(index, 1)

  setLayout(tmpLayout)

  refreshStage()
}

const addWidget = async (key, replaceKey = null) => {
  let customWidgetUuid = null
  if (key === "custom") {
    const customWidget = await createCustomWidget({
      dashboard_uuid: activeDashboardUuid.value,
    })
    if (customWidget) {
      customWidgetUuid = customWidget.uuid
    }
  }

  const newWidgetHeight = allWidgets.value[key].config.h

  const tmpLayout = [ ...layout.value ]

  for (const widget of tmpLayout) {
    widget.y = widget.y + newWidgetHeight
  }

  const widgetData = {
    x: 0, //(layout.value.length * 2) % (12),
    y: 0, //layout.value.length + (200), // puts it at the bottom
    w: allWidgets.value[key].config.w,
    h: allWidgets.value[key].config.h,
    i: customWidgetUuid ? key + `_${customWidgetUuid}` : key,
  }

  if (!!replaceKey) {
    const replaceElementIndex = tmpLayout.findIndex((widget) => widget.i === replaceKey)

    if (replaceElementIndex !== -1) {
      widgetData.x = tmpLayout[replaceElementIndex].x
      widgetData.y = tmpLayout[replaceElementIndex].y

      tmpLayout[replaceElementIndex].i = widgetData.i
    }
  } else {
    tmpLayout.push(widgetData)
  }

  setLayout(tmpLayout)

  await createOrUpdateDashboard(activeDashboard.value, true)

  refreshStage()
}

watch(isEditMode, async (newVal) => {
  isDraggable.value = newVal
  isResizable.value = newVal
})

watch(widgetsToAdd.value, async (newVal) => {
  if (newVal.length === 1) {
    if (activeDashboardUuid.value === "personal" || activeDashboardUuid.value === "account") {
      await createOrUpdateDashboard(activeDashboard.value)
    }
    addWidget(newVal[0])
    widgetsToAdd.value.pop()
  }
})

setAllWidgets(Object.keys(allWidgets.value).map((widgetKey) => {
  return {
    key: widgetKey,
    scopes: allWidgets.value[widgetKey].scopes,
  }
}))

const isDraggable = ref(false)
const isResizable = ref(false)

onBeforeMount(() => {
  isDraggable.value = isEditMode.value
  isResizable.value = isEditMode.value
})

const refreshStage = async () => {
  // little workaorund to make newly added components interactive
  const wasDraggableBefore = isDraggable.value
  const wasResizableBefore = isDraggable.value
  isDraggable.value = false
  isResizable.value = false
  await nextTick(() => {
    isDraggable.value = wasDraggableBefore
    isResizable.value = wasResizableBefore
    window.dispatchEvent(new Event("resize"))
  })
}

const getKey = (key) => {
  return key.includes("custom") ? "custom" : key
}

const wrapper = ref<HTMLElement>()
const gridLayout = ref()

onMounted(() => {
  document.addEventListener("dragover", syncMousePosition)
})

onBeforeUnmount(() => {
  document.removeEventListener("dragover", syncMousePosition)
})

const mouseAt = { x: -1, y: -1 }

const syncMousePosition = (event: MouseEvent) => {
  mouseAt.x = event.clientX
  mouseAt.y = event.clientY
}

const dragItem = { x: -1, y: -1, w: 2, h: 2, i: "" }

const checkIfValidPosition = () => {
  const parentRect = wrapper.value?.getBoundingClientRect()
  const menuRect = document.getElementById("dashboardMenu")?.getBoundingClientRect()

  if (!parentRect || !gridLayout.value) return false

  const mouseInGrid =
      mouseAt.x > parentRect.left &&
      mouseAt.x < parentRect.right &&
      mouseAt.y > parentRect.top &&
      mouseAt.y < parentRect.bottom

  const mouseOverMenu =
      mouseAt.x > menuRect.left &&
      mouseAt.x < menuRect.right &&
      mouseAt.y > menuRect.top &&
      mouseAt.y < menuRect.bottom

  return mouseInGrid && !mouseOverMenu
}

let dragDebouncer = null
const drag = (widgetKey) => {
  clearTimeout(dragDebouncer)
  dragDebouncer = setTimeout(() => {
    const isValidPosition = checkIfValidPosition()
    const parentRect = wrapper.value?.getBoundingClientRect()

    if (!isValidPosition) return

    if (isValidPosition && !layout.value.find((item) => item.i === dropKey.value)) {
      layout.value.push({
        x: (layout.value.length * 2) % 12,
        y: layout.value.length + 12, // puts it at the bottom
        w: allWidgets.value[widgetKey].config.w,
        h: allWidgets.value[widgetKey].config.h,
        i: dropKey.value,
      })
    }

    const index = layout.value.findIndex((item) => item.i === dropKey.value)

    if (index !== -1) {
      const item = gridLayout.value.getItem(dropKey.value)

      if (!item) return

      try {
        item.wrapper.style.display = "none"
      } catch (e) {}

      Object.assign(item.state, {
        top: mouseAt.y - parentRect.top,
        left: mouseAt.x - parentRect.left,
      })
      const newPos = item.calcXY(mouseAt.y - parentRect.top, mouseAt.x - parentRect.left)

      if (isValidPosition) {
        dragItem.i = dropKey.value
        dragItem.w = allWidgets.value[widgetKey].config.w
        dragItem.h = allWidgets.value[widgetKey].config.h
        dragItem.x = layout.value[index].x
        dragItem.y = layout.value[index].y
        gridLayout.value.dragEvent("dragstart", dropKey.value, newPos.x, newPos.y, dragItem.h, dragItem.w)
      } else {
        gridLayout.value.dragEvent("dragend", dropKey.value, newPos.x, newPos.y, dragItem.h, dragItem.w)
        setLayout(layout.value.filter((item) => item.i !== dropKey.value))
      }
    }
  }, 10)
}

const dragEnd = (widgetKey) => {
  const isValidPosition = checkIfValidPosition()

  if (!isValidPosition) {
    setLayout(layout.value.filter((item) => item.i !== dropKey.value))
    return
  }

  gridLayout.value.dragEvent("dragend", widgetKey, dragItem.x, dragItem.y, dragItem.h, dragItem.w)

  addWidget(widgetKey, dropKey.value)
  setLayout(layout.value.filter((item) => item.i !== dropKey.value))
  gridLayout.value.dragEvent("dragend", dragItem.i, dragItem.x, dragItem.y, dragItem.h, dragItem.w)

  const item = gridLayout.value.getItem(dropKey.value)

  if (!item) return

  try {
    item.wrapper.style.display = ""
  } catch (e) {}
}

defineExpose({
  drag,
  dragEnd,
})

</script>

<template>
  <div
    id="dashboardGrid"
    ref="wrapper"
    class="relative min-h-full grow"
  >
    <LoadingPlaceholder
      v-if="isSwitchingDashboard"
      class="absolute rounded-lg shadow inset-8"
    />
    <div
      v-else-if="!layout.length && !isEditMode"
      class="flex items-center justify-center w-full h-full px-10 py-20"
    >
      <div class="flex flex-col items-center justify-center space-y-4 text-center">
        <div class="text-xl">
          {{ $t('dashboard.empty.title') }}
        </div>
        <div class="max-w-2xl text-sm">
          {{ $t('dashboard.empty.description') }}
        </div>
        <div class="flex flex-col items-center justify-center space-y-3">
          <button
            type="button"
            class="items-center hidden space-x-2 btn-white lg:flex"
            @click="toggleEditMode(DashboardTab.widgets)"
          >
            <SquaresPlusIcon
              aria-hidden="true"
              class="w-5 h-5"
            />
            <span>{{ $t('dashboard.addWidgets') }}</span>
          </button>
          <button
            type="button"
            class="flex items-center space-x-2 btn-primary"
            @click="restoreDefaultLayout"
          >
            <ArrowPathIcon
              aria-hidden="true"
              class="w-5 h-5"
            />
            <span>{{ $t('dashboard.empty.button') }}</span>
          </button>
        </div>
      </div>
    </div>
    <GridLayout
      v-else
      ref="gridLayout"
      v-model:layout="layout"
      :class="isEditMode ? 'show-grid' : 'hide-grid'"
      :row-height="50"
      :margin="[30, 30]"
      :is-resizable="isResizable"
      :is-draggable="isDraggable"
      :responsive="true"
      :breakpoints="{
        lg: 1900,
        md: 1600,
        sm: 1300,
        xs: 500,
        xxs: 0
      }"
      :cols="{
        lg: 15,
        md: 12,
        sm: 9,
        xs: 6,
        xxs: 1
      }"
    >
      <GridItem
        v-for="item in layout"
        :key="item.i"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :min-h="allWidgets[getKey(item.i)]?.config.minH"
        :max-h="allWidgets[getKey(item.i)]?.config.maxH"
        :min-w="allWidgets[getKey(item.i)]?.config.minW"
        :max-w="allWidgets[getKey(item.i)]?.config.maxW"
        :i="item.i"
        class="hover:z-20"
        :class="{'overflow-hidden': isEditMode}"
      >
        <div
          v-if="item.i === 'drop'"
          class="absolute inset-0"
        />
        <template v-else>
          <div
            class="hidden lg:flex absolute inset-0 lg:z-40 flex-col overflow-hidden transition-opacity duration-200 border rounded-md ring-2 ring-white"
            :class="[isEditMode ? 'opacity-100 bg-slate-700/30 translate-x-0' : 'opacity-0 pointer-events-none', item.i.includes('custom') ? 'border-yellow-300' : 'border-indigo-300']"
          >
            <div
              class="px-2 py-2.5 text-sm flex items-center justify-between"
              :class="[item.i.includes('custom') ? 'bg-yellow-100 text-yellow-600' : 'bg-indigo-50 text-indigo-700']"
            >
              <DragIndicatorIcon
                class="w-4 h-4 cursor-move shrink-0"
                :class="item.i.includes('custom') ? 'text-yellow-400 hover:text-yellow-500' : 'text-indigo-300 hover:text-indigo-500'"
              />
              <span>{{ item.i.includes('custom') ? $t('dashboard.widgets.custom') : $t('dashboard.widgets.' + item.i) }}</span>
              <button
                type="button"
                class="relative z-50"
                @click="removeWidget(item.i)"
              >
                <XMarkIcon
                  aria-hidden="true"
                  class="w-4 h-4"
                  :class="item.i.includes('custom') ? 'text-yellow-400 hover:text-yellow-500' : 'text-indigo-300 hover:text-indigo-500'"
                />
                <span class="sr-only">{{ $t('common.remove') }}</span>
              </button>
            </div>

            <div
              class="relative flex items-center justify-center w-full overflow-hidden grow rounded-b-md"

              :class="[item.i.includes('custom') ? 'bg-yellow-200/20' : 'bg-indigo-200/20']"
            />
          </div>
          <component
            :is="allWidgets[getKey(item.i)].component"
            :item="item"
            :class="isEditMode ? 'shadow-none' : ''"
          />
        </template>
      </GridItem>
    </GridLayout>
  </div>
</template>

<style>
.vgl-layout.show-grid {
  min-height: calc(100vh - 201px);
}

.vgl-item--dragging > div{
  @apply ring-0;
}

.show-grid::before {
    content: '';
    background-size: calc(calc(100% - 15px) / 15) 80px;
    background-image: linear-gradient(
            to right,
            #e9e9e9 1px,
            transparent 1px
    ),
    linear-gradient(to bottom, #e9e9e9 1px, transparent 1px);
    height: calc(100% - 29px);
    width: calc(100% - 15px);
    position: absolute;
    background-repeat: repeat;
    margin:15px;
}

@media all and (max-width: 1955px) {
  .show-grid::before {
    background-size: calc(calc(100% - 15px) / 12) 80px;
  }
}

@media all and (max-width: 1655px) {
  .show-grid::before {
    background-size: calc(calc(100% - 15px) / 9) 80px;
  }
}

@media all and (max-width: 1355px) {
  .show-grid::before {
    background-size: calc(calc(100% - 15px) / 6) 80px;
  }
}

@media all and (max-width: 1055px) {
  .show-grid::before {
    background-size: calc(calc(100% - 15px) / 3) 80px;
  }
}

@media all and (max-width: 991px) {
  .show-grid::before {
    display: none !important;
  }
}

.hide-grid .vgl-item {
  transition: none !important;
}
</style>
