<script setup lang="ts">
import { computed } from "vue"
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue"

interface Props {
  options: any[]
  srLabel?: string
  gridClass?: string
  disabled?: boolean
  modelValue?: boolean | string
  id?: string
  ariaDescribedby?: string
  allowNull?: boolean
  notLocalized?: boolean
  layout?: string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    id: null,
    ariaDescribedby: "",
    options: null,
    srLabel: "",
    gridClass: "grid grid-cols-3 gap-3 sm:grid-cols-4",
    disabled: false,
    allowNull: false,
    notLocalized: false,
    layout: "default",
  },
)

const emit = defineEmits([ "update:model-value", "focus", "blur" ])

const selected = computed({
  get: () => props.modelValue ?? ( props.allowNull ? null : props.options[0].value),
  set: (val) => {
    emit("update:model-value", val)
  },
})

const handleFocus = () => {
  emit("focus", {
    target: { id: props.id },
  })
}

const handleBlur = () => {
  emit("blur", {
    target: { id: props.id },
  })
}
</script>

<template>
  <RadioGroup
    :id="id"
    v-model="selected"
    :aria-describedby="ariaDescribedby"
  >
    <RadioGroupLabel class="sr-only">
      {{ srLabel }}
    </RadioGroupLabel>
    <div :class="gridClass">
      <RadioGroupOption
        v-for="option in options"
        :key="option.name"
        v-slot="{ active, checked }"
        as="template"
        :value="option.value"
        :disabled="option.disabled || disabled"
        @focus="handleFocus"
        @blur="handleBlur"
      >
        <div
          :class="[
            !option.disabled && !disabled
              ? 'cursor-pointer focus:outline-none'
              : 'opacity-50 cursor-not-allowed',
            active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
            layout === 'slate' ? 'ring-offset-slate-800' : '',
            layout === 'light' ? 'text-sm' : '',
            checked
              ? 'bg-indigo-600 text-white hover:bg-indigo-700'
              : layout === 'default' ? 'bg-gray-100 text-gray-900 hover:bg-gray-50'
                : layout === 'light' ? 'bg-white text-gray-900 border border-gray-300 hover:border-gray-500'
                  : 'bg-slate-700 text-slate-100 hover:bg-slate-600',
            'rounded-md py-2 px-2 flex items-center justify-center font-medium sm:flex-1 text-xs',
          ]"
        >
          <RadioGroupLabel
            as="p"
            class="flex items-center"
          >
            <component
              :is="option.icon"
              v-if="option.icon"
              class="w-4 h-4 mr-1 shrink-0"
              aria-hidden="true"
            />
            {{ props.notLocalized ? option.title : $t(option.title) }}
          </RadioGroupLabel>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>
