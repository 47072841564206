<script setup lang="ts">
// external
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import VueApexCharts from "vue3-apexcharts"

// internal
import { CustomWidgetConfig, ChartType, MultiFieldType } from "~/types"
import defaultOptions from "./chartPresets"
import { locales } from "~/libs/vue3-apexcharts/locales"
import { DashboardDataNumberDisplay, DashboardDataNumberCurrencyDisplay, EmptyState } from "~/components"
import { formatMultifieldNumber } from "~/utils"

interface Props {
  data?: any
  config: CustomWidgetConfig
  name?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    data: null,
    name: "",
  },
)

const isCurrencyField = computed(() => props.config.value_type === MultiFieldType.currency || props.config.value_type === MultiFieldType.currency_duration)

const hasNoData = computed(() => {
  if (isCurrencyField.value && props.config.time_metadata_uuid) {
    return !props.data.series?.length
  }
  return !props.data || !props.data.length
})

const uniqueEntries = computed(() => {
  if (isCurrencyField.value && props.config.time_metadata_uuid) {
    return []
  }
  return [ ...new Set(props.data) ]
})

const { t, locale } = useI18n()

const labels = computed(() => {
  if (isCurrencyField.value && props.config.time_metadata_uuid) {
    return props.data.intervals
  }
  if (isCurrencyField.value) {
    return getCurrencyData(props.data).currencies
  }
  if (props.config.value_type === MultiFieldType.bool || props.config.value_type === MultiFieldType.clause) {
    return uniqueEntries.value.map((entry) => {
      return entry === true ? t("common.yes") : t("common.no")
    })
  }
  if (props.config.metadata_name === "contract_type") {
    return uniqueEntries.value.map((entry) => t(`contractTypes.${entry}`))
  } else if (props.config.metadata_name === "contract_duration_type") {
    return uniqueEntries.value.map((entry) => t(`contractDurationTypes.${entry}.name`))
  }
  return uniqueEntries.value
})

const series = computed(() => {
  let seriesData = props.data

  if (isCurrencyField.value && props.config.time_metadata_uuid) {
    return props.data.series
  } else if (isCurrencyField.value) {
    seriesData = getCurrencyData(props.data).values
  } else if (props.config.chart_type !== ChartType.line) {
    seriesData = uniqueEntries.value.map((entry) => countOccurrences(props.data, entry))
  }

  if (props.config.chart_type === ChartType.bar || props.config.chart_type === ChartType.line) {
    return [ {
      name: isCurrencyField.value ? t("common.total") : t("magicTable.columns.documents"),
      data: seriesData,
    } ]
  }
  return seriesData
})

const countOccurrences = (arr, value) => {
  return arr.filter((str) => str === value).length
}

const getCurrencyData = (input) => {
  const accumulator = {}

  input.forEach((entry) => {
    const [ currency, value ] = entry.split(";")
    if (!accumulator[currency]) {
      accumulator[currency] = 0
    }
    accumulator[currency] += parseFloat(value)
  })

  const currencies = Object.keys(accumulator)
  const values = Object.values(accumulator)

  return { currencies, values }
}

const options = computed(() => {

  const opitonsToUse = {
    ...defaultOptions[props.config.chart_type],
    labels: labels.value,
    chart: {
      locales: [ ...locales ],
      defaultLocale: locale.value,
    },
  }

  if (props.config.colors?.length) {
    opitonsToUse.colors = props.config.colors
  }

  if (props.config.chart_type === ChartType.bar) {
    opitonsToUse.xaxis = {
      ...opitonsToUse.xaxis,
      categories: labels.value,
    }
  }

  if (props.config.chart_type === ChartType.line && isCurrencyField.value) {
    opitonsToUse.yaxis.labels = {
      ...opitonsToUse.yaxis.labels,
      formatter: (value) => {
        return formatMultifieldNumber(value, null, MultiFieldType.currency_duration, locale.value)
      },
    }
    opitonsToUse.tooltip.y = {
      ...opitonsToUse.tooltip.y,
      formatter: (value) => {
        return formatMultifieldNumber(value, null, MultiFieldType.currency_duration, locale.value)
      },
    }
  }

  return opitonsToUse
})

const emit = defineEmits([ "chart-click" ])

const clickHandler = (event) => {
  emit("chart-click", { event, config: props.config, uniqueEntries: uniqueEntries.value })
}
</script>

<template>
  <div
    class="flex items-center justify-center w-full h-full grow"
  >
    <div
      v-if="hasNoData"
      v-cy="`dashboard-graph-widget-no-data`"
      class="flex flex-col items-center justify-center text-center"
    >
      <EmptyState
        :hide-button="true"
      >
        {{ $t('dashboard.noData') }}
      </EmptyState>
    </div>
    <template v-else>
      <DashboardDataNumberCurrencyDisplay
        v-if="config.chart_type === ChartType.number && isCurrencyField"
        :data="data"
        :config="config"
      />
      <DashboardDataNumberDisplay
        v-else-if="config.chart_type === ChartType.number"
        :data="data"
        :config="config"
      />
      <VueApexCharts
        v-else
        v-cy="`apexcharts-container`"
        class="w-full h-full grow"
        width="100%"
        height="100%"
        :type="config.chart_type"
        :options="options"
        :series="series"
        @click="clickHandler"
      />
    </template>
  </div>
</template>
