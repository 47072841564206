<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, nextTick, ref, toRaw, watch, onBeforeMount, onBeforeUnmount } from "vue"
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core"
import { useI18n } from "vue-i18n"
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { TrashIcon } from "@heroicons/vue/24/outline"
import { CheckIcon, ChevronUpDownIcon, PlusIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"
import { TrashIcon as TrashSmallIcon } from "@heroicons/vue/20/solid"
import { toTypedSchema } from "@vee-validate/zod"
import { intersection, isEmpty, isEqual, without } from "lodash-es"
import { useField, useForm } from "vee-validate"
import { z } from "zod"

// internal
import { CurrencyDisplay, CurrencySelector, DynamicFieldInputElement, FormInputErrors, MobileClosePopoverButton, MultiFieldIcon, OverlayScrollbar, RadioGroupPills, SpinLoader } from "~/components"
import { useDocumentStore, useDynamicFieldStore, useEditorStore, useNotificationStore, useSharedStore, useTemplateStore } from "~/stores"
import { CrudContext, Document, DynamicField, MultiFieldType, Template, DocumentStage, DynamicFieldAutofillType } from "~/types"
import { changedKeys, currencyOptions, formatTime, hideTippiesViaSelector, validateMultifieldValue, getDefaultMultiFieldFormat } from "~/utils"

const dynamicFieldAnswerTypes = without(Object.values(MultiFieldType), MultiFieldType.timestamp, MultiFieldType.list, MultiFieldType.clause)

interface Props {
  dynamicField?: DynamicField
  template?: Template
  document?: Document
}

const props = withDefaults(
  defineProps<Props>(),
  {
    dynamicField: null,
    template: null,
    document: null,
  },
)

const { t } = useI18n()

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const documentStore = useDocumentStore()
const { isLockedDocument, mau, mdu, currentDocument } = storeToRefs(documentStore)

const { notify } = useNotificationStore()

const dynamicFieldStore = useDynamicFieldStore()
const { dynamicFieldErrorsMap, uuidsOfUpdatingDynamicField, dynamicFieldLastSavedMap, dynamicFieldUuidBeingRemoved, newDynamicField } = storeToRefs(dynamicFieldStore)
const { removeDynamicField, updateNewDynamicField, setNewDynamicField } = dynamicFieldStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const editorStore = useEditorStore()
const { isDirty } = storeToRefs(editorStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual("md")

const formErrors = computed(() => dynamicFieldErrorsMap.value[localDynamicField.value.uuid])
const lastSaved = computed<number | undefined>(() => dynamicFieldLastSavedMap.value[localDynamicField.value?.uuid])

const isLoadingCreateDynamicField = ref<boolean>(false)
const isLoadingDynamicField = computed<boolean>(() => uuidsOfUpdatingDynamicField.value.includes(localDynamicField.value?.uuid) || isLoadingCreateDynamicField.value)

const showExternalQuestion = ref<boolean>(false)

const hideListbox = ref<boolean>(false)

const emptyDynamicField = Object.freeze<Partial<DynamicField>>(
  {
    scope: "internal",
    is_mandatory: true,
    value: null,
  },
)

const locale = computed(() => currentDocument.value?.locale || currentTemplate.value?.locale || mau.value?.user?.locale)

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const localDynamicField = computed<Partial<DynamicField>>({
  get: () => {
    if (props.dynamicField?.uuid) return props.dynamicField || { ...emptyDynamicField }
    return newDynamicField.value
  },
  set: (val) => {
    setTimeout(
      async () => {
        if (!props.dynamicField?.uuid) return
        const isValid = await validate(
          {
            mode: "force",
          },
        )
        const payloadKeys = changedKeys({ ...toRaw(localDynamicField.value) }, val)
        const checkIntersection = intersection(payloadKeys, Object.keys(isValid.errors))
        // Stop update if not valid and the payload includes invalid fields
        if (!isValid.valid && checkIntersection?.length) return
        dynamicFieldStore.updateLocalDynamicField(val, crudContext.value, entityUuid.value)
      },
    )
    if (!props.dynamicField?.uuid) updateNewDynamicField(val)
  },
})

const updateFieldValue = (fieldName: keyof DynamicField, val: any) => {
  setFieldValue(fieldName as any, val)
  localDynamicField.value = {
    ...localDynamicField.value || {},
    [fieldName]: val,
  }
}

const handleSetAutofillValue = (val: DynamicFieldAutofillType) => {
  setFieldValue("value", null)
  setFieldValue("autofill_type", val)
  localDynamicField.value = {
    ...localDynamicField.value || {},
    value: null,
    autofill_type: val,
  }
}

const newDynamicFieldSelectOption = ref<string>("")

const dynamicFieldSchema = z
  .object(
    {
      name: z.string().nonempty(t("common.required")),
      type: z.nativeEnum(MultiFieldType),
      autofill_type: z.nativeEnum(DynamicFieldAutofillType).nullable().optional(),
      is_mandatory: z.boolean().nullable().optional(),
      question: z.string().nullable().optional(),
      question_external: z.string().nullable().optional(),
      select_values: z.array(z.string()).nullable().optional(),
      scope: z.string().nonempty(t("common.required")),
      value: z.any().nullable().optional(),
      format: z.object(
        {
          decimals: z.string().nullable().optional(),
          decimalSeparator: z.string().nullable().optional(),
          thousandsSeparator: z.string().nullable().optional(),
        },
      ).nullable().optional(),
    },
  ).refine( (input) => {

    // Type is mandatory
    if (!input.type || input.type === undefined) return false

    // Validate value based on type
    if (!!input.value && !validateMultifieldValue(input.type, input.value, localDynamicField.value?.format || defaultFormat.value) && crudContext.value !== CrudContext.document) return false

    // allows select values to be optional only when type is not select
    if ( input.type === MultiFieldType.select && input.select_values === undefined ) return false

    return true

  }, (input) => {
    if (!input.type || input.type === undefined) {
      return {
        message: t("dynamicFields.errors.type"),
        path: [ "type" ],
      }
    }
    else if (!!input.value && !validateMultifieldValue(input.type, input.value, localDynamicField.value?.format || defaultFormat.value) && crudContext.value !== CrudContext.document) {
      return {
        message: t("dynamicFields.errors.format"),
        path: [ "value" ],
      }
    } else if (input.type === MultiFieldType.select && input.select_values === undefined ) {
      return {
        message: t("dynamicFields.errors.options"),
        path: [ "select_values" ],
      }
    }
  })

type DynamicFieldValidatedType = z.infer<typeof dynamicFieldSchema>

const dynamicFieldValidator = toTypedSchema(dynamicFieldSchema)

const { errors, setValues, setFieldValue, validate, resetForm } = useForm<DynamicFieldValidatedType>(
  {
    validationSchema: dynamicFieldValidator,
  },
)
const formValidationErrors = ref<Partial<Record<keyof DynamicField, string[]>>>({})
const backendErrors = computed(() => dynamicFieldErrorsMap.value[localDynamicField.value.uuid])

watch(
  () => localDynamicField.value,
  (newVal) => {
    if (!newVal) return
    if (localDynamicField.value?.uuid) setValues(newVal)
  },
  {
    deep: true,
    immediate: true,
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof DynamicField, string[]>>,
      )
  },
)

const name = useField<string>("name")
const type = useField<MultiFieldType>("type")
const scope = useField<"internal" | "internal_and_external">("scope")
const select_values = useField<string[]>("select_values")
const is_mandatory = useField<boolean>("is_mandatory")
const question = useField<string>("question")
const question_external = useField<string>("question_external")
const value = useField<any>("value")
useField<DynamicField["format"]>("format")
useField<DynamicFieldAutofillType>("autofill_type")

const errorsToShow = computed<Partial<Record<keyof DynamicField, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DynamicField, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

// Store a new Dynamic Field
const createDynamicField = async () => {
  if (localDynamicField.value.uuid) return

  const entityUuid = props?.[crudContext.value]?.uuid

  const isValid = await validate(
    {
      mode: "force",
    },
  )

  if (!isValid.valid) return

  try {
    isLoadingCreateDynamicField.value = true

    // Throw error if no type is present and include information about crudContext and payload
    if (!localDynamicField.value.type) throw new Error("Cannot create dynamic field without type: " + JSON.stringify({ crudContext: crudContext.value, payload: localDynamicField.value }))

    const createdDynamicField = await dynamicFieldStore.createDynamicField(crudContext.value, entityUuid, localDynamicField.value)

    if (!createdDynamicField) throw new Error(t("dynamicFields.errors.invalid", { content: JSON.stringify({ createdDynamicField }) }))

    hideTippiesViaSelector(".add-dynamic-field-button")

    localDynamicField.value = Object.assign({}, emptyDynamicField)
    setNewDynamicField(Object.assign({}, emptyDynamicField))
    dynamicFieldStore.setDynamicFieldErrors("0", null)
    await nextTick()
    resetForm()
    setFieldValue("scope", localDynamicField.value?.scope || "internal")
    setFieldValue("is_mandatory", typeof localDynamicField.value?.is_mandatory !== "undefined" ? localDynamicField.value?.is_mandatory : true)
    resetFormatObject()
    showExternalQuestion.value = false
    // Hack to prevent listbox caching
    hideListbox.value = true
    await nextTick()
    hideListbox.value = false

  } catch (err) {
    dynamicFieldStore.setDynamicFieldErrors("0", err.response?.data?.errors || null)

    notify({
      title: t("dynamicFields.errors.add"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingCreateDynamicField.value = false
  }
}

// Add an answer option for "select" type Dynamic Fields
const addDynamicFieldSelectOption = (): void => {
  if (newDynamicFieldSelectOption.value !== "") {
    const selectOptionToAdd: string = newDynamicFieldSelectOption.value

    if (!localDynamicField.value.select_values) {
      updateFieldValue("select_values",
        new Array(selectOptionToAdd),
      )
    } else {
      updateFieldValue("select_values", [
        ...localDynamicField.value?.select_values,
        selectOptionToAdd,
      ])
    }
  }
  newDynamicFieldSelectOption.value = ""
}

// Remove an answer option for "select" type Dynamic Fields
const removeDynamicFieldSelectOption = (option: string) => {
  const rawDynamicField: Partial<DynamicField> = toRaw(localDynamicField.value || {})
  const updatedSelectValues = without(rawDynamicField.select_values, option)
  const needsValueReset = updatedSelectValues?.length && !updatedSelectValues?.includes(localDynamicField.value.value)
  const newProperties =
  needsValueReset ?
    { select_values: updatedSelectValues, value: null } :
    { select_values: updatedSelectValues }
  if (needsValueReset) setFieldValue("value", null)
  setFieldValue("select_values", updatedSelectValues)
  localDynamicField.value = {
    ...localDynamicField.value,
    ...newProperties,
  }
  if (localDynamicField.value.select_values?.length) return
  updateFieldValue("select_values", null)
}

// Reset default value when type changes
watch(type.value, () => {
  updateFieldValue("value", null)
})

// Handle decimals, decimal separator and thousands separator
const decimals = ref<string>("")
const decimalSeparator = ref<string>("")
const thousandsSeparator = ref<string>("")

onBeforeMount(() => {
  // Reset empty dynamic field on load
  setNewDynamicField(Object.assign({}, emptyDynamicField))
  // Set field values
  setFieldValue("scope", localDynamicField.value?.scope || "internal")
  setFieldValue("is_mandatory", typeof localDynamicField.value?.is_mandatory !== "undefined" ? localDynamicField.value?.is_mandatory : true)
  // Set decimals, decimal separator and thousands separator
  resetFormatObject()
})

onBeforeUnmount(() => {
  // Reset empty dynamic field on unmount
  setNewDynamicField(Object.assign({}, emptyDynamicField))
})

const defaultFormat = computed(() => getDefaultMultiFieldFormat(localDynamicField.value?.type, locale.value))

const resetFormatObject = (useDefault = false, force = false) => {
  if (force) {
    decimals.value = ""
    decimalSeparator.value = ""
    thousandsSeparator.value = ""
    return
  }
  decimals.value = localDynamicField.value.format?.decimals || (useDefault ? defaultFormat.value?.decimals : undefined)
  decimalSeparator.value = localDynamicField.value.format?.decimalSeparator || ((useDefault ? defaultFormat.value?.decimalSeparator : undefined))
  thousandsSeparator.value = localDynamicField.value.format?.thousandsSeparator || ((useDefault ? defaultFormat.value?.thousandsSeparator : undefined))
}

// Compute "format" object from decimals, decimal separator and thousands separator, leave out empty values
const formatObject = computed(() => {

  if (!decimals.value && !decimalSeparator.value && !thousandsSeparator.value) return null

  const format: Partial<DynamicField["format"]> = {}

  if (decimals.value) format.decimals = decimals.value
  if (decimalSeparator.value) format.decimalSeparator = decimalSeparator.value
  // We also need to set this when empty
  format.thousandsSeparator = thousandsSeparator.value || null

  return format
})

const handleSwitchNumberNotation = (format: "DE" | "EN" | "default") => {
  if (format === "default") {
    decimals.value = "0"
    thousandsSeparator.value = null
    decimalSeparator.value = null
  } else if (format === "DE") {
    decimalSeparator.value = ","
    thousandsSeparator.value = "."
  } else {
    decimalSeparator.value = "."
    thousandsSeparator.value = ","
  }
}

const handleSetDecimals = (d: string) => {
  decimals.value = d
}

const numberingFormatOptions = computed(() => {
  // Format options based on number of decimal places set, default 2
  const defaultTitle = "1000"
  const deTitle = decimals.value === "0" ? "1.000" : decimals.value === "1" ? "1.000,0" : "1.000,00"
  const enTitle = decimals.value === "0" ? "1,000" : decimals.value === "1" ? "1,000.0" : "1,000.00"
  return [
    { value: "default", title: defaultTitle },
    { value: "DE", title: deTitle },
    { value: "EN", title: enTitle },
  ]
})

const selectedNumberingFormatOption = computed(() => {
  const checkValue = !thousandsSeparator.value ? "default" : decimalSeparator.value === "," ? "DE" : "EN"
  return numberingFormatOptions.value.find((option) => option.value === checkValue)
})

// Watch for changes in settings object
watch(formatObject, (newVal) => {
  if (isEqual(newVal, localDynamicField.value?.format)) return
  updateFieldValue("format", toRaw(newVal))
}, { deep: true })

const removeCurrencyFromSettings = (currency: string) => {
  const newCurrencies = localDynamicField.value?.settings?.currencies?.filter((el) => el !== currency) || null
  // If currencies are null and there are no other keys in settings, set settings to null
  if (!newCurrencies?.length && Object.keys(localDynamicField.value?.settings || {}).length < 2) {
    updateFieldValue("settings", null)
    return
  }
  const newFieldValue = localDynamicField.value?.settings ? {
    ...localDynamicField.value?.settings,
    currencies: newCurrencies,
  } : {
    currencies: newCurrencies,
  }
  updateFieldValue("settings", newFieldValue)
}

const selectedCurrency = computed({
  get: () => {
    return null
  },
  set: (setting) => {
    addCurrencyToSettings(setting)
  },
})

const addCurrencyToSettings = (currency: string) => {
  const newCurrencies = localDynamicField.value?.settings?.currencies ? [
    ...localDynamicField.value?.settings?.currencies,
    currency,
  ] : [
    currency,
  ]
  const newFieldValue = localDynamicField.value?.settings ? {
    ...localDynamicField.value?.settings,
    currencies: newCurrencies,
  } : {
    currencies: newCurrencies,
  }
  updateFieldValue("settings", newFieldValue)
}

const remainingCurrencyOptions = computed(() => {
  if (!localDynamicField.value?.settings?.currencies) return currencyOptions?.map((el) => el.code)
  return currencyOptions.filter((el) => !localDynamicField.value?.settings?.currencies?.includes(el.code))?.map((el) => el.code)
})

const resetSettingsObject = (settings: Partial<DynamicField["settings"]>) => {
  updateFieldValue("settings", settings)
}

const modelValueCurrency = computed(() => {
  if (!localDynamicField.value?.value || (localDynamicField.value?.type !== MultiFieldType.currency && localDynamicField.value?.type !== MultiFieldType.currency_duration)) return null
  const splitValue = localDynamicField.value?.value.split(";")
  return splitValue?.[0]
})

</script>

<template>
  <div
    :id="dynamicField?.ref_uuid ? 'dynamicFieldForm_' + dynamicField?.ref_uuid : 'dynamicFieldForm'"
    class="popover popover-primary popover-mobile-fullscreen md:max-w-[26rem]"
  >
    <MobileClosePopoverButton
      :label="$t('popovers.editDynamicField')"
    />
    <component
      :is="isMobile ? OverlayScrollbar : 'div'"
      tag="div"
      class="flex-1 h-full"
    >
      <div class="flex flex-col gap-3 p-4 pt-3">
        <div>
          <label
            class="font-medium"
            for="dynamic-field-name"
          >
            {{ $t('dynamicFields.form.name') }} <span class="text-indigo-500">*</span>
          </label>
          <div class="relative mt-1">
            <input
              id="dynamic-field-name"
              :value="name.value.value"
              :placeholder="$t('dynamicFields.form.namePlaceholder') + '…'"
              type="text"
              name="dynamic-field-name"
              maxlength="255"
              class="input-plain"
              :class="[{ 'input-has-errors': errorsToShow?.name?.length, 'pl-8': dynamicField?.uuid }, 'block w-full rounded-md shadow-sm sm:text-sm']"
              @input="($event) => updateFieldValue('name', ($event.target as HTMLInputElement).value)"
            >
            <div
              v-if="dynamicField?.uuid"
              class="flex items-center gap-1.5 absolute inset-y-0 left-0 px-2.5 text-xs uppercase tracking-tight"
              data-tippy-help
              :data-tippy-content="$t('dynamicFields.form.type') + ': ' + $t('multiFieldTypes.' + dynamicField.type)"
              data-placement="bottom"
            >
              <MultiFieldIcon
                class="shrink-0"
                :type="type.value.value"
              />
            </div>
          </div>
          <FormInputErrors
            v-if="errorsToShow?.name?.length"
            :errors="errorsToShow?.name"
          />
        </div>

        <div v-if="!dynamicField?.uuid || type.value.value === MultiFieldType.select">
          <Listbox
            v-if="!dynamicField?.uuid && !hideListbox"
            :model-value="type.value.value"
            as="div"
            @update:model-value="($event) => updateFieldValue('type', $event)"
          >
            <ListboxLabel class="font-medium">
              {{ $t('dynamicFields.form.type') }} <span class="text-indigo-500">*</span>
            </ListboxLabel>
            <div class="relative mt-1">
              <ListboxButton
                :class="[formErrors?.type?.length ? 'input-has-errors' : '']"
                class="py-2 pl-3 pr-10 input-plain"
              >
                <div class="flex items-center">
                  <MultiFieldIcon
                    v-if="type.value.value"
                    class="shrink-0 mr-1.5"
                    :type="type.value.value"
                  />
                  <span
                    class="block truncate"
                    :class="type.value.value ? '' : 'text-gray-500'"
                  >{{
                    type.value.value
                      ? $t('multiFieldTypes.' + type.value.value)
                      : $t('accountSettings.metadata.selectAnswerType') + '…'
                  }}</span>
                </div>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="listbox-options"
                >
                  <ListboxOption
                    v-for="dynamicFieldAnswerType in dynamicFieldAnswerTypes"
                    :key="dynamicFieldAnswerType"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="dynamicFieldAnswerType"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option py-1']">
                      <div class="flex items-center">
                        <MultiFieldIcon
                          :type="dynamicFieldAnswerType"
                          class="mr-2 shrink-0"
                          size-classes="w-3 h-3"
                        />
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >
                          {{ $t('multiFieldTypes.' + dynamicFieldAnswerType) }}
                        </span>
                      </div>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-indigo-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="w-5 h-5 shrink-0"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
            <FormInputErrors
              v-if="errorsToShow?.type?.length"
              :errors="errorsToShow?.type"
            />
          </Listbox>

          <div v-if="type.value.value === 'select'">
            <ul
              v-if="select_values?.value?.value?.length > 0"
              role="list"
              class="py-1 pr-3 mt-2 bg-gray-200 rounded-t-md"
            >
              <li
                v-for="(
                  dynamicFieldSelectOption, dynamicFieldSelectOptionIdx
                ) in select_values?.value?.value"
                :key="dynamicFieldSelectOptionIdx"
                class="ml-6 list-disc list-outside"
              >
                <div class="flex items-center justify-between gap-2">
                  <div class="relative grow">
                    &nbsp;
                    <span
                      class="absolute top-0 left-0 right-0 text-sm text-gray-900 truncate"
                    >{{ dynamicFieldSelectOption }}</span>
                  </div>
                  <button
                    class="p-1 text-sm text-gray-400 rounded-full shrink-0 hover:bg-red-200 hover:text-red-700"
                    @click="
                      removeDynamicFieldSelectOption(
                        dynamicFieldSelectOption
                      )
                    "
                  >
                    <TrashIcon
                      class="w-4 h-4 shrink-0"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </li>
            </ul>
            <div
              v-else
              class="px-3 py-2 mt-2 text-sm bg-gray-200 rounded-t-md"
            >
              {{ $t('common.noOptionsAdded') }}
            </div>
            <div class="flex shadow-sm">
              <input
                id="newDynamicFieldAnswerTypeSelectOption"
                v-model="newDynamicFieldSelectOption"
                type="text"
                maxlength="255"
                name="newDynamicFieldAnswerTypeSelectOption"
                class="block w-full border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 rounded-bl-md sm:text-sm"
                :placeholder="$t('dynamicFields.addOption') + '…'"
                @keypress.enter="addDynamicFieldSelectOption()"
              >
              <button
                :disabled="!newDynamicFieldSelectOption"
                type="button"
                class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-br-md bg-gray-50 hover:bg-gray-100 disabled:bg-gray-50 disabled:text-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                @click="addDynamicFieldSelectOption()"
              >
                <PlusIcon
                  :class="!newDynamicFieldSelectOption ? 'text-gray-300' : ''"
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>{{ $t('common.add') }}</span>
              </button>
            </div>
          </div>
          <FormInputErrors
            v-if="errorsToShow?.select_values?.length"
            :errors="errorsToShow?.select_values"
          />
        </div>
        <div
          v-if="[ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(localDynamicField.type)"
        >
          <div class="flex items-center justify-between gap-3">
            <label
              class="font-medium"
              for="format"
            >
              {{ $t('dynamicFields.currencies') }}
            </label>
            <button
              v-if="[ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(localDynamicField.type) && isEmpty(localDynamicField.settings)"
              type="button"
              class="px-1 py-0 text-xs text-indigo-500 shrink-0 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
              @click="resetSettingsObject({ currencies: [ currencyOptions?.[0]?.code ] })"
            >
              {{ $t('dynamicFields.customizeCurrencies') }}
            </button>
            <CurrencySelector
              v-else
              v-model="selectedCurrency"
              :is-disabled="!remainingCurrencyOptions.length"
              :options="remainingCurrencyOptions"
              :selected-currencies="localDynamicField.settings?.currencies"
            />
          </div>
          <div
            v-if="!isEmpty(localDynamicField.settings) && localDynamicField.settings?.currencies"
            class="flex flex-wrap mt-1"
          >
            <CurrencyDisplay
              v-for="currency in localDynamicField.settings?.currencies"
              :key="'currency_' + currency"
              :currency="currency"
              class="mb-1 mr-1"
              :removable="currency !== modelValueCurrency"
              @remove="removeCurrencyFromSettings"
            />
          </div>
        </div>
        <div
          v-if="[ MultiFieldType.number, MultiFieldType.currency, MultiFieldType.currency_duration ].includes(localDynamicField.type)"
        >
          <div class="flex items-center justify-between gap-3">
            <label
              class="font-medium"
              for="format"
            >
              {{ $t('common.format') }}
            </label>
            <button
              v-if="[ MultiFieldType.number, MultiFieldType.currency, MultiFieldType.currency_duration ].includes(localDynamicField.type) && isEmpty(localDynamicField.format)"
              type="button"
              class="px-1 py-0 text-xs text-indigo-500 shrink-0 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
              @click="resetFormatObject(true)"
            >
              {{ $t('dynamicFields.customizeFormat') }}
            </button>
            <button
              v-else-if="!isEmpty(localDynamicField.format)"
              class="flex items-center gap-1 px-1 py-0 text-xs text-indigo-500 shrink-0 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
              type="button"
              @click.prevent="resetFormatObject(false, true)"
            >
              <TrashSmallIcon
                class="w-3 h-3 shrink-0"
                aria-hidden="true"
              />
              {{ $t('dynamicFields.resetFormat') }}
            </button>
          </div>
          <div
            v-if="!isEmpty(localDynamicField.format)"
            class="flex items-center gap-4 px-2 py-1 pl-1 mt-1 btn-white hover:bg-white"
          >
            <div class="flex items-center gap-2 grow">
              <RadioGroupPills
                :not-localized="true"
                :sr-label="$t('dynamicFields.customizeFormat')"
                :disabled="false"
                :grid-class="'grid grid-cols-3 gap-2 grow min-w-[9rem]'"
                :options="numberingFormatOptions"
                :model-value="selectedNumberingFormatOption.value"
                @update:model-value="($event) => handleSwitchNumberNotation($event)"
              />
            </div>
            <div class="relative flex items-center w-auto gap-2">
              <div
                class="text-xs font-medium"
              >
                {{ $t('dynamicFields.form.decimals') }}
              </div>
              <input
                :value="localDynamicField.format?.decimals"
                class="min-w-0 px-1.5 text-xs font-medium text-center input-plain"
                type="text"
                maxlength="1"
                pattern="\d+"
                size="1"
                @input="($event) => handleSetDecimals(($event.target as HTMLInputElement).value)"
              >
            </div>
          </div>
        </div>

        <div v-if="type.value.value && crudContext === CrudContext.template">
          <label
            class="font-medium"
            for="dynamic-field-value"
          >
            {{ $t('common.defaultValue') }}
          </label>
          <div class="mt-1 max-w-[382px]">
            <DynamicFieldInputElement
              :model-value="value.value.value"
              :dynamic-field-ref-uuid="localDynamicField.ref_uuid"
              :settings="localDynamicField.settings"
              :format="localDynamicField.format || defaultFormat"
              :dynamic-field-type="type.value.value"
              :dynamic-field-autofill-type="localDynamicField.autofill_type"
              :has-error="!!(formErrors?.value?.length)"
              :dynamic-field-last-saved-map="dynamicFieldLastSavedMap"
              :select-values="localDynamicField.select_values"
              :disabled="isLockedDocument"
              @set-autofill-value="($event) => handleSetAutofillValue($event)"
              @update:model-value="($event) => updateFieldValue('value', $event)"
            />
          </div>
          <FormInputErrors
            v-if="errorsToShow?.value?.length"
            :errors="errorsToShow?.value"
          />
        </div>

        <div>
          <label class="flex items-center gap-2 font-medium">
            <span>
              {{ $t('dynamicFields.form.visibility') }} <span class="text-indigo-500">*</span>
            </span>
            <span
              data-tippy-help
              :data-tippy-content="$t('dynamicFields.form.visibilityHint')"
              data-placement="bottom"
            >
              <QuestionMarkCircleIcon
                class="w-4 h-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </label>
          <fieldset class="mt-1">
            <div class="flex items-center gap-3">
              <div class="inline-flex items-center">
                <input
                  :id="'dynamicFieldInternal' + dynamicField?.uuid"
                  value="internal"
                  :name="'dynamicFieldExternal' + dynamicField?.uuid"
                  type="radio"
                  :checked="(scope?.value?.value === 'internal')"
                  :class="[formErrors?.scope?.length ? 'input-has-errors' : '']"
                  class="input-radio-primary"
                  @change="($event) => updateFieldValue('scope', ($event.target as HTMLInputElement).value)"
                >
                <label
                  :for="'dynamicFieldInternal' + dynamicField?.uuid"
                  class="input-radio-primary-label"
                >
                  {{ $t('dynamicFields.form.internal') }}
                </label>
              </div>
              <div class="inline-flex items-center">
                <input
                  :id="'dynamicFieldExternal' + dynamicField?.uuid"
                  value="internal_and_external"
                  :name="'dynamicFieldExternal' + dynamicField?.uuid"
                  type="radio"
                  :checked="(scope?.value?.value === 'internal_and_external')"
                  :class="[formErrors?.scope?.length ? 'input-has-errors' : '']"
                  class="input-radio-primary"
                  @change="($event) => updateFieldValue('scope', ($event.target as HTMLInputElement).value)"
                >
                <label
                  :for="'dynamicFieldExternal' + dynamicField?.uuid"
                  class="input-radio-primary-label"
                >
                  {{ $t('dynamicFields.form.internalExternal') }}
                </label>
              </div>
            </div>
            <FormInputErrors
              v-if="errorsToShow?.scope?.length"
              :errors="errorsToShow?.scope"
            />
          </fieldset>
        </div>

        <div>
          <label class="font-medium">{{ $t('dynamicFields.form.mandatory') }}</label>
          <div
            class="relative flex items-center mt-1"
          >
            <div class="flex items-center">
              <input
                :id="'dynamicFieldMandatory' + dynamicField?.uuid"
                :value="is_mandatory?.value?.value"
                :checked="!!(is_mandatory?.value?.value)"
                :aria-describedby="
                  'dynamicFieldMandatory' + dynamicField?.uuid + 'LabelDescription'
                "
                :name="'dynamicFieldMandatory' + dynamicField?.uuid"
                type="checkbox"
                class="w-4 h-4 mx-auto rounded input-radio-primary"
                :class="[formErrors?.is_mandatory?.length ? 'input-has-errors' : '']"
                @change="($event) => updateFieldValue('is_mandatory', ($event.target as HTMLInputElement).checked)"
              >
            </div>
            <div class="ml-2 input-radio-primary-label">
              <label
                :id="'dynamicFieldMandatory' + dynamicField?.uuid + 'Label'"
                class="cursor-pointer"
                :for="'dynamicFieldMandatory' + dynamicField?.uuid"
              >{{ $t('dynamicFields.form.mandatory') }}</label>
              <span
                :id="'dynamicFieldMandatory' + dynamicField?.uuid + 'LabelDescription'"
                class="text-indigo-300"
              ><span class="sr-only">{{ $t('dynamicFields.form.mandatory') }}</span> ({{ $t('dynamicFields.form.mustBeAnswered') }})</span>
            </div>
          </div>
          <FormInputErrors
            v-if="errorsToShow?.is_mandatory?.length"
            :errors="errorsToShow?.is_mandatory"
          />
        </div>

        <div>
          <label
            class="font-medium"
            :for="'dynamicFieldQuestionInternal' + dynamicField?.uuid"
          >
            {{ $t('dynamicFields.form.question') }}
          </label>
          <div class="mt-1">
            <input
              :id="'dynamicFieldQuestionInternal' + dynamicField?.uuid"
              :value="question?.value?.value"
              type="text"
              maxlength="255"
              :name="'dynamicFieldQuestionInternal' + dynamicField?.uuid"
              class="input-plain"
              :class="[formErrors?.question?.length ? 'input-has-errors' : '']"
              :placeholder="$t('dynamicFields.form.questionPlaceholder') + '…'"
              @input="($event) => updateFieldValue('question', ($event.target as HTMLInputElement).value)"
            >
          </div>
          <FormInputErrors
            v-if="errorsToShow?.question?.length"
            :errors="errorsToShow?.question"
          />
          <button
            v-if="!showExternalQuestion && !question_external?.value?.value"
            type="button"
            class="p-0 mt-1 text-xs font-medium text-gray-400 btn-plain hover:text-indigo-700"
            @click.prevent="showExternalQuestion = !showExternalQuestion"
          >
            {{ $t('dynamicFields.form.addQuestion') }}
          </button>
        </div>

        <div v-if="showExternalQuestion || question_external?.value?.value">
          <label
            class="font-medium"
            :for="'dynamicFieldQuestionExternal' + dynamicField?.uuid"
          >
            {{ $t('dynamicFields.form.questionExternal') }}
          </label>
          <div class="mt-1">
            <input
              :id="'dynamicFieldQuestionExternal' + dynamicField?.uuid"
              :value="question_external?.value?.value"
              type="text"
              maxlength="255"
              :name="'dynamicFieldQuestionExternal' + dynamicField?.uuid"
              class="input-plain"
              :class="[formErrors?.question_external?.length ? 'input-has-errors' : '']"
              :placeholder="$t('dynamicFields.form.questionPlaceholder') + '…'"
              @input="($event) => updateFieldValue('question_external', ($event.target as HTMLInputElement).value)"
            >
          </div>
          <FormInputErrors
            v-if="errorsToShow?.question_external?.length"
            :errors="errorsToShow?.question_external"
          />
        </div>
      </div>
    </component>

    <div class="flex items-center justify-end gap-2 p-3 bg-gray-100 border-t border-gray-200 rounded-b-md">
      <template
        v-if="localDynamicField?.uuid && !isLockedDocument"
      >
        <div
          :data-tippy-help="isDirty ? true : null"
          :data-tippy-content="isDirty ? $t('common.notDeletableWhileIsDirtyEditorContent') : null"
          data-placement="top"
          :class="isDirty ? 'hover:cursor-not-allowed' : ''"
        >
          <button
            v-if="(mdu?.permissions?.includes('dynamic_field_delete') || crudContext === CrudContext.template) && !isLockedDocument && props.document?.stage !== DocumentStage.signed"
            type="button"
            :disabled="isDirty"
            class="flex items-center gap-2 text-gray-400 btn-plain btn-sm hover:text-red-500 hover:bg-red-100"
            @click.prevent="removeDynamicField(localDynamicField?.uuid, document || template)"
          >
            <TrashIcon
              v-if="dynamicFieldUuidBeingRemoved !== localDynamicField?.uuid"
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <SpinLoader
              v-else
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span>
              {{ $t('common.remove') }}
            </span>
          </button>
        </div>
      </template>
      <button
        v-if="!dynamicField?.uuid"
        :disabled="isLoadingCreateDynamicField"
        type="button"
        class="inline-flex items-center gap-2 btn-primary"
        @click.prevent="createDynamicField"
      >
        <SpinLoader
          v-if="isLoadingCreateDynamicField"
          class="w-5 h-5 shrink-0"
        />
        <span v-if="isLoadingCreateDynamicField">{{ $t('common.saving') }}…</span>
        <span v-else>{{ $t('common.save') }}</span>
      </button>
      <span
        v-else-if="isLoadingDynamicField"
        class="flex items-center justify-center gap-2 text-sm font-medium text-indigo-700 pointer-events-none btn-plain"
      >
        <SpinLoader class="w-5 h-5 shrink-0" />
        {{ $t('common.saving') }}…
      </span>
      <span
        v-else-if="lastSaved"
        class="flex items-center justify-center gap-2 text-sm font-medium text-gray-500 pointer-events-none btn-plain"
      >
        <CheckIcon
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        {{ $t('common.lastSavedAt', {time: formatTime(lastSaved)}) }}
      </span>
      <span
        v-else
        class="flex items-center justify-center text-sm font-medium text-gray-500 pointer-events-none btn-plain"
      >
        {{ $t('common.noUnsavedChanges') }}
      </span>
    </div>
  </div>
</template>
