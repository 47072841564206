<script setup lang="ts">
// external
import {
  EyeSlashIcon,
  EyeIcon,
} from "@heroicons/vue/24/outline"
import { ref, computed } from "vue"

// internal
import { AccountUser, Comment, Document, DocumentUser } from "~/types"
import { SpinLoader } from "~/components"
import { useCommentStore } from "~/stores"

interface Props {
  documentUuid?: Document["uuid"]
  comment: Comment
  mau?: AccountUser
  mdu?: DocumentUser
  parentIsInternal?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    documentUuid: null,
    mau: null,
    mdu: null,
    parentIsInternal: false,
  },
)

const commentStore = useCommentStore()

const isLoading = ref(false)

const canChangeVisibility = computed(() => {
  return !!props.comment.created_by_document_user_account_user?.uuid
    && !props.parentIsInternal
    && props.mau
    && props.mdu
    && props.mdu?.permissions?.includes("comment_create")
    && (props.mau?.roles?.map((role) => role.name)?.includes("account-owner") || props.comment.created_by_document_user_uuid === props.mdu.uuid || props.comment.created_by_document_user_account_user?.uuid === props.mau?.uuid)
})

const changeVisibility = async () => {
  if (!isLoading.value) {
    isLoading.value = true
    await commentStore.updateComment(
      props.documentUuid,
      props.comment.uuid,
      {
        scope: props.comment.scope === "internal" ? "internal_and_external" : "internal",
      },
    )
    isLoading.value = false
  }
}
</script>

<template>
  <button
    type="button"
    class="flex items-center justify-center p-1 -m-1 rounded-full"
    :class="comment.scope === 'internal_and_external' && !parentIsInternal ? 'text-gray-500 hover:bg-gray-100 disabled:hover:bg-transparent' : 'text-indigo-500 bg-indigo-50 hover:bg-indigo-100 disabled:hover:bg-indigo-50'"
    :disabled="isLoading || !canChangeVisibility"
    @click="changeVisibility"
  >
    <SpinLoader
      v-if="isLoading"
      class="w-4 h-4"
    />
    <span
      v-else-if="comment.scope === 'internal' || parentIsInternal"
      data-tippy-help
      :data-tippy-content="parentIsInternal && comment.scope === 'internal_and_external' ? $t('discussions.internalCommentDueToParent') : $t('discussions.internalComment')"
      data-placement="bottom"
    >
      <EyeSlashIcon
        aria-hidden="true"
        class="w-4 h-4"
      />
    </span>
    <span
      v-else-if="comment.scope === 'internal_and_external'"
      data-tippy-help
      :data-tippy-content="$t('discussions.visibleToExternalUsers')"
      data-placement="bottom"
    >
      <EyeIcon
        aria-hidden="true"
        class="w-4 h-4"
      />
    </span>
  </button>
</template>
