<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { nextTick, onBeforeUnmount, ref, computed, watch } from "vue"
import { useI18n } from "vue-i18n"

import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"
import { EditorContent, JSONContent, useEditor } from "@tiptap/vue-3"

// internal
import { useCommentStore, useDocumentStore, useFileStorageStore, useNotificationStore, useSharedStore, useUserStore } from "~/stores"
import { Comment, Document as DocumentType, DocumentTab, DocumentUser, Template, CrudContext, UiStoredFile } from "~/types"
import { Mention, extractmentionedDocumentUserUuids, getKeyboardEventListenerExtension, renderMentionSuggestions } from "./extensions"
import { CommentFiles, FileInput, SpinLoader } from "~/components"
import { isMac } from "~/utils"
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/outline"
import { PaperClipIcon } from "@heroicons/vue/20/solid"

interface Props {
  documentUuid?: DocumentType["uuid"]
  templateUuid?: Template["uuid"]
  class?: string
  commentItemUuid?: Comment["uuid"] | null
  prosemirrorDataUuid?: string | null
  scope?: "internal" | "internal_and_external"
  isOnlyEditor?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  class: "",
  commentItemUuid: null,
  prosemirrorDataUuid: null,
  scope: null,
  documentUuid: null,
  templateUuid: null,
  isOnlyEditor: false,
})

const { t } = useI18n()

const userStore = useUserStore()
const {
  users,
  availableAccountUsers,
} = storeToRefs(userStore)

const emit = defineEmits(
  [
    "blur",
    "saved",
    "update-scope",
  ],
)

const commentStore = useCommentStore()

const notificationStore = useNotificationStore()
const { notify } = notificationStore

const {
  createComment,
  highlightCommentsInListByProsemirrorDataUuid,
  setHighlightedCommentUuids,
} = commentStore

const documentStore = useDocumentStore()
const { mau, mdu, setActiveTabKey, getMainEditor } = documentStore

const fileStorageStore = useFileStorageStore()
const { backendErrors, isLoadingSaveStoredFileCount, commentFiles } = storeToRefs(fileStorageStore)
const { uploadStoredFile } = fileStorageStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed<DocumentType["uuid"] | Template["uuid"]>(() => {
  return crudContext.value === CrudContext.document ? props.documentUuid : props.templateUuid
})

interface DocumentPayload {
  file_uuid: string
}

const errorsToShow = computed<Partial<Record<keyof DocumentPayload, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentPayload, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const fileInput = ref<any>()

// Make v-model work on editorValue variable (from parent)
const editorValue = ref<JSONContent>(null)

const editorId = "commentEditor_" + Math.floor(Math.random() * 100000)

const mentionedDocumentUserUuids = ref<DocumentUser["uuid"][]>([])

const isExternal = ref(props.scope ? props.scope === "internal_and_external" : true)

const isExternalUserMentioned = computed(() => {
  if (mentionedDocumentUserUuids.value.length === 0) return false

  let result = false
  for (const mentionedUuid of mentionedDocumentUserUuids.value) {
    const user = users.value.find((u) => u.uuid === mentionedUuid)

    if (user?.account_user?.uuid === null || user?.account_user?.uuid === undefined) {
      result = true
    }
  }
  return result
})

// Build extensions object
const editorExtensions = [
  Document.configure(),
  Paragraph.configure(),
  Placeholder.configure(
    {
      placeholder: t("discussions.addComment") + "…",
      emptyNodeClass: "text-gray-600",
    },
  ),
  Mention.configure(
    {
      suggestion: {
        items: ({ query }) => {
          // Map availableAccountUsers to documentUser typing
          const mappedAvailableAccountUsers = availableAccountUsers.value.filter((item) => {
            return !users.value?.some((user) => user.account_user?.uuid === item.uuid)
          }).map((item) => {
            const documentUser: DocumentUser = {
              uuid: null,
              account_user: {
                uuid: item.uuid,
              },
              first_name: item.first_name,
              last_name: item.last_name,
              email: item.email,
              profile_photo_url: item.profile_photo_url,
              roles: null,
              permissions: null,
            }
            return documentUser
          })
          const mentionSuggestions = (users.value || [])?.concat(mappedAvailableAccountUsers)
          return mentionSuggestions
            .filter((item) => {
              // internal child comments cant mention external users
              if (props.scope === "internal" && !item.account_user?.account_uuid) return false
              if (!!item.deleted_at) return false

              const firstNameCheck = item.first_name?.toLowerCase().startsWith(query.toLowerCase())
              const lastNameCheck = item.last_name?.toLowerCase().startsWith(query.toLowerCase())
              const emailCheck = item.email?.toLowerCase().startsWith(query.toLowerCase())
              return !!(firstNameCheck || lastNameCheck || emailCheck)
            },
            )
            .slice(0, 10)
        },
        render: () => renderMentionSuggestions(editorId),
      },
    },
  ),
  Text.configure(),
  getKeyboardEventListenerExtension(
    {
      "Mod-Enter": ({ editor }) => {
        saveComment()
        editor.chain().clearContent().focus("start").run()
        return true
      },
    },
  ).configure(),
]
// Initiate editor
const commentEditor = useEditor(
  {
    extensions: editorExtensions,
    editorProps: {
      attributes: {
        class: props.class,
      },
    },
    content: editorValue.value,
    onUpdate ({ editor }) {
      editorValue.value = editor.getJSON()

      mentionedDocumentUserUuids.value = extractmentionedDocumentUserUuids(editor)

      isCommentEmpty.value = !editor.getText().trim().length
    },
    onBlur ({ event }) {
      emit("blur", event)
    },
  },
)

const isCommentEmpty = ref<boolean>(true)

const isSavingComment = ref(false)

const saveComment = async () => {
  if (!commentEditor.value.getText().trim()) return

  isSavingComment.value = true

  const newComment: Partial<Comment> = {
    html: commentEditor.value.getHTML(),
    type: "comment",
    scope: isExternal.value || !documentStore.mau ? "internal_and_external" : "internal",
    ...(props.commentItemUuid ? { comment_uuid: props.commentItemUuid } : { }),
    ...(props.prosemirrorDataUuid ? { prosemirror_data_uuid: props.prosemirrorDataUuid } : { }),
    ...(mentionedDocumentUserUuids.value.length ? { mentioned_document_user_uuids: mentionedDocumentUserUuids.value } : { }),
  }

  const createdComment = await createComment(props.documentUuid, newComment)

  isSavingComment.value = false

  if (!createdComment) return

  const savedFiles = await handleUploadStoredFiles(createdComment.uuid)

  if (!savedFiles) {
    notify({
      type: "error",
      title: t("fileStorage.storedFileCommentUploadErrorTitle"),
      message: t("fileStorage.storedFileCommentUploadErrorMessage"),
    })
  }

  commentEditor.value.chain().clearContent().focus("start").run()

  // Clear comment files
  commentFiles.value = []

  setActiveTabKey(DocumentTab.discussions)

  if (createdComment.uuid) {
    highlightCommentsInListByProsemirrorDataUuid(createdComment.uuid)
  } else {
    setHighlightedCommentUuids(createdComment.comment_uuid ? [ createdComment.comment_uuid ] : [ createdComment.uuid ])
  }

  emit("saved", createdComment)

  await nextTick()

  getMainEditor()?.commands.directDecoration()
}

const focus = () => commentEditor.value.commands.focus()

onBeforeUnmount(() => commentEditor.value?.destroy())

const getEditorHtml = () => commentEditor.value.getHTML()

defineExpose(
  {
    focus,
    saveComment,
    isCommentEmpty,
    mentionedDocumentUserUuids,
    getEditorHtml,
  },
)

const previousScopeState = ref(false)

const handleSetCommentFiles = (file_uuid: string, file_name: string, mime_type: string) => {
  const file = {
    uuid: "",
    sha256: null,
    file_uuid: file_uuid,
    filename: file_name,
    file_size: null,
    url: null,
    deleted_at: null,
    comment_uuid: props.commentItemUuid || "",
    mime_type: mime_type,
  }
  commentFiles.value?.push(file)
}

const updateFileInCommentFiles = (file: UiStoredFile) => {
  const index = commentFiles.value.findIndex((f) => (f.file_uuid && f.file_uuid === file.file_uuid) || (f.uuid && f.uuid === file.uuid))
  if (index !== -1) {
    commentFiles.value[index] = file
  }
}

const handleUploadStoredFiles = async (commentUuid: Comment["uuid"]): Promise<boolean> => {
  if (commentFiles.value.length === 0) return true

  // Filter for parent comment
  const filteredCommentFiles = commentFiles.value.filter((file) => file.comment_uuid === (props.commentItemUuid || 0))

  if (filteredCommentFiles?.length === 0) return true

  const promises = filteredCommentFiles.map((file) => {
    if (file.file_uuid && file.filename) {
      return uploadStoredFile(crudContext.value, entityUuid.value, file.file_uuid, file.filename, commentUuid, props.commentItemUuid || "")
    }
  })

  const results = await Promise.all(promises)

  results.forEach((result) => {
    if (result) {
      updateFileInCommentFiles(result)
    }
  })

  return results.every((result) => result)

}

watch(isExternalUserMentioned, (newVal) => {
  if (newVal) {
    previousScopeState.value = isExternal.value
    isExternal.value = true
  } else {
    isExternal.value = previousScopeState.value
  }
})


watch(isExternal, (newVal) => emit("update-scope", newVal))
watch(() => props.scope, (newVal) => isExternal.value = newVal === "internal_and_external")
</script>

<template>
  <div
    :id="editorId"
    class="relative text-sm comment-editor"
    :class="[
      isOnlyEditor ? 'shadow-lg border-l border-dashed border-gray-200 z-0 lg:pt-1.5 lg:h-full rounded-l-md lg:rounded-l-none rounded-r-md' : 'rounded-md',
      isExternal ? 'bg-white' : 'bg-indigo-50 internal-mode'
    ]"
  >
    <FileInput
      v-if="(mdu && mdu.permissions?.includes('stored_file_create') && !!mau) || crudContext === CrudContext.template"
      ref="fileInput"
      :upload-callback="handleSetCommentFiles"
      :form-errors="errorsToShow"
      :multiple="true"
      :global="true"
      :accept="'.doc, .docx, application/xml, .xls, .xlsx, .csv, .ppt, .pptx, message/rfc822, .txt, application/pdf, image/*, .eml, .rtf, .msg, application/vnd.ms-outlook, .zip, application/x-zip, application/zip'"
      :allowed-extensions="'/\.(docx?|xml|xlsx?|pptx?|eml|txt|csv|pdf|jpe?g|png|gif|msg|zip|rtf)$/i'"
      :empty-list-after-upload="true"
      :parent-pending="isLoadingSaveStoredFileCount[commentItemUuid || 0] > 0"
      :file-type-error="$t('fileStorage.storedFileFileTypeError')"
      :placeholder="$t('documents.fileInputPlaceholder')"
    />
    <EditorContent
      v-if="commentEditor"
      class="p-2"
      :editor="commentEditor"
    />
    <CommentFiles
      :related-files="commentFiles.filter((f) => f.comment_uuid === (commentItemUuid || 0))"
      :entity-uuid="entityUuid"
      :scope="isExternal ? 'internal_and_external' : 'internal'"
      class="ml-2"
    />
    <div
      v-if="!props.isOnlyEditor"
      class="flex items-center p-2 py-2.5 space-x-2 rounded-b-md"
      :class="documentStore.mau ? 'justify-between' : 'justify-end'"
    >
      <div class="flex items-center gap-0.5">
        <button
          v-if="(mdu && mdu.permissions?.includes('stored_file_create') && !!mau) || crudContext === CrudContext.template"
          type="button"
          class="py-1 px-1.5 font-medium text-gray-500 bg-transparent btn-plain hover:text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-100"
          @click.prevent="fileInput.triggerClick()"
        >
          <PaperClipIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </button>
        <button
          v-if="documentStore.mau && scope !== 'internal'"
          type="button"
          class="px-2 py-1 btn-plain"
          :disabled="isExternalUserMentioned"
          :class="!isExternal ? 'text-indigo-500 hover:text-indigo-700 font-medium bg-transparent hover:bg-indigo-100 focus:bg-indigo-100 focus:ring-indigo-100' : 'text-gray-500 hover:text-gray-700 bg-transparent hover:bg-gray-100 focus:bg-gray-100 focus:ring-gray-100 font-medium'"
          @click.prevent="isExternal = !isExternal"
        >
          <div
            v-if="isExternal"
            class="flex items-center gap-1.5"
          >
            <EyeIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span class="block truncate">
              {{ $t('discussions.visibleToExternalUsers') }}
            </span>
          </div>
          <div
            v-else
            class="flex items-center gap-1.5"
          >
            <EyeSlashIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span class="block truncate">
              {{ $t('discussions.internalComment') }}
            </span>
          </div>
        </button>
        <span
          v-else-if="scope === 'internal'"
          class="px-2 py-1 text-indigo-400 font-medium flex items-center  gap-1.5"
        >
          <EyeSlashIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          <span class="block truncate">
            {{ $t('discussions.internalCommentShort') }}
          </span>
        </span>
      </div>
      <slot name="secondary-action" />
      <button
        class="btn-white btn-sm flex items-center space-x-1.5"
        type="button"
        @click="saveComment"
      >
        <SpinLoader
          v-if="isSavingComment"
          class="w-3 h-3"
        />
        <span>{{ $t('common.save') }}</span>
        <kbd
          v-if="!isSavingComment"
          class="items-center hidden px-1 font-sans text-xs font-medium text-gray-400 bg-gray-100 rounded"
          :class="!commentItemUuid ? 'lg:inline-flex' : ''"
        >
          {{ isMac ? '⌘' : '^' }} + ↵
        </kbd>
      </button>
    </div>
  </div>
</template>

<style>
.comment-editor .ProseMirror {
  min-height: auto;
}
.comment-editor .ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.comment-editor .ProseMirror :is(p, .list):not(:first-child) {
    margin-top: 0rem;
}
.comment-editor.internal-mode .ProseMirror p.is-editor-empty:first-child::before {
  @apply text-indigo-400;
}
</style>
