<script setup lang="ts">
// external
import { ref, watch } from "vue"
import { Form, Field } from "vee-validate"
import { router, Link } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"
import { ArrowDownOnSquareIcon } from "@heroicons/vue/24/solid"
import axios from "axios"

// internal
import { LaravelUser } from "~/types"
import { useConfirmationStore, useNotificationStore } from "~/stores"
import { SpinLoader, InfoBox } from "~/components"


interface Props {
  user: LaravelUser
  qrCode?: string
  recoveryCodes?: string[]
  setupKey?: string
  preventDisable?: boolean
  showDashboardButton?: boolean
  has2faConfirmed?: boolean
  has2faEnabled?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    preventDisable: false,
    showDashboardButton: false,
  },
)
const isChanging2fa = ref(false)

const confirmationStore = useConfirmationStore()
const { setShowConfirmModal, setConfirmOptions } = confirmationStore

const { t } = useI18n()

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const enable2fa = () => {
  isChanging2fa.value = true
  router.post(route("two-factor.enable"), {}, {
    onSuccess: () => {
      addToasterNotification({
        title: t("profileSettings.general.enableSuccessTitle"),
        message: t("profileSettings.general.enableSuccessDescription"),
        type: "success",
      })
    },
    onError: () => {
      addToasterNotification({
        title: t("common.errorOccured"),
        message: t("profileSettings.general.enableError"),
        type: "error",
      })
    },
    onFinish: () => {
      isChanging2fa.value = false
    },
  })
}

const disable2fa = () => {
  isChanging2fa.value = true
  router.delete(route("two-factor.disable"), {
    onSuccess: () => {
      addToasterNotification({
        title: t("profileSettings.general.disableSuccessTitle"),
        message: t("profileSettings.general.disableSuccessDescription"),
        type: "success",
      })
    },
    onError: () => {
      addToasterNotification({
        title: t("common.errorOccured"),
        message: t("profileSettings.general.disableError"),
        type: "error",
      })
    },
    onFinish: () => {
      isChanging2fa.value = false
    },
  })
}

const confirm2faDisable = () => {
  setConfirmOptions({
    title: t("profileSettings.general.confirmDisableTitle"),
    description: t("profileSettings.general.confirmDisableDescription"),
    buttonText: t("common.disable"),
    callback: disable2fa,
  })
  setShowConfirmModal(true)
}

const toggle2fa = () => {
  if (props.has2faEnabled === true) {
    confirm2faDisable()
  }
  else {
    enable2fa()
  }
}

const confirmError = ref(null)
const confirm2fa = (code) => {
  router.post(route("two-factor.confirm", code ), {}, {
    onError: (err) => {
      confirmError.value = err
    },
  })
}

const downloadRecoveryCodes = () => {
  if (props.recoveryCodes?.length) {
    const text = props.recoveryCodes.join("\n")

    const element = document.createElement("a")
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text))
    element.setAttribute("download", "fynk_recovery_codes.txt")

    element.style.display = "none"
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }
}

watch(() => props.recoveryCodes, (newVal) => {
  // mark recovery codes as seen
  if (props.has2faConfirmed && newVal?.length) {
    axios.patch(route("api.users.update", props.user.uuid), {
      recovery_codes_seen: true,
    })
  }
}, {
  immediate: true,
})
</script>

<template>
  <div class="content-card">
    <div class="p-5 space-y-5">
      <div
        v-if="has2faConfirmed"
      >
        <div
          v-if="props.recoveryCodes?.length"
          class="max-w-xl"
        >
          <div class="mb-3 text-sm">
            {{ $t('profileSettings.general.recoveryCodes') }}:
          </div>
          <div class="relative p-2 text-sm bg-yellow-100 rounded-md border-2 border-yellow-400">
            <code>
              <ul class="space-y-1">
                <li
                  v-for="code in props.recoveryCodes"
                  :key="code"
                >
                  {{ code }}
                </li>
              </ul>
            </code>
            <button
              type="button"
              class="absolute top-1 right-1"
              @click="downloadRecoveryCodes"
            >
              <ArrowDownOnSquareIcon
                aria-hidden="true"
                class="w-5 h-5 text-yellow-700"
              />
            </button>
          </div>
          <InfoBox class="mt-2 block">
            <span>{{ $t('profileSettings.general.recoveryCodesInfo') }}</span>
          </InfoBox>
          <Link
            v-if="showDashboardButton"
            :href="route('dashboard')"
            class="w-full btn-primary mt-5 block text-center"
          >
            {{ $t('common.continue') }}
          </Link>
        </div>
        <div
          v-else
          class="max-w-xl text-sm"
        >
          <span>{{ $t('profileSettings.general.twoFactorConfirmed') }}</span>

          <Link
            v-if="showDashboardButton"
            :href="route('dashboard')"
            class="w-full btn-primary mt-5 block text-center"
          >
            {{ $t('common.continue') }}
          </Link>
        </div>
      </div>
      <div
        v-else-if="has2faEnabled"
      >
        <Form
          v-slot="{ errors }"
          class="max-w-xl"
          @submit="confirm2fa"
        >
          <div class="mb-5 text-sm">
            {{ $t('profileSettings.general.qrInfo') }}:
          </div>

          <div>
            <div
              class="w-full mb-5"
            >
              <div class="flex flex-col items-center justify-center">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="w-[192px]"
                  v-html="qrCode"
                />
                <!-- eslint-enable vue/no-v-html -->
                <code
                  class="mb-5 text-sm text-center text-500"
                >
                  {{ setupKey }}
                </code>
              </div>


              <div
                v-if="confirmError"
                class="mb-5 text-sm text-red-500"
              >
                {{ $t('profileSettings.general.confirmError') }}
              </div>

              <Field
                id="code"
                name="code"
                type="text"
                class="block w-full mb-5 input-plain"
                :class="[errors.code || !!confirmError ? 'error' : '']"
                maxlength="6"
                required
                :placeholder="$t('profileSettings.general.verificationCodePlaceholder')"
                rules="required"
                autocomplete="off"
              />

              <button
                type="submit"
                class="w-full btn-primary"
              >
                {{ $t('common.confirm') }}
              </button>
            </div>
          </div>
        </Form>
      </div>
      <p
        v-else-if="!has2faEnabled"
        class="max-w-xl text-sm"
      >
        {{ $t('profileSettings.general.twoFactorDescription') }}
      </p>
    </div>
    <div
      v-if="!preventDisable || !has2faEnabled"
      class="content-card-footer"
    >
      <button
        class="flex items-center space-x-2"
        :class="has2faEnabled ? 'btn-red' : 'btn-primary'"
        type="button"
        :disabled="isChanging2fa"
        @click="toggle2fa"
      >
        <SpinLoader
          v-if="isChanging2fa"
          class="shrink-0 w-5 h-5"
          aria-hidden="true"
        />
        <span>{{ has2faEnabled ? $t('common.disable') : $t('common.enable') }}</span>
      </button>
    </div>
  </div>
</template>
