<script setup lang="ts">
// external
import { computed } from "vue"
import { DocumentDuplicateIcon, XMarkIcon } from "@heroicons/vue/24/outline"

// internal
import { StageDisplay, TeamList } from "~/components"
import { ImportFileIcon, PDFIcon } from "~/icons"
import { DocumentSnippet, Template, DocumentOrigin, DocumentType } from "~/types"
import { documentTypes, formatDateRelative } from "~/utils"
import { useStages } from "~/composables"

interface SearchResult {
  uuid: string
  document?: DocumentSnippet
  template?: Template
  quick_search?: SearchResult
  origin?: DocumentOrigin
}

interface Props {
  result: SearchResult
  active?: boolean
  query?: string
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
  query: "",
})

defineEmits([ "delete" ])

const { stages } = useStages(null, null, true)

const stage = computed(() => {
  return stages.value.find((stage) => stage.name === props.result.document?.stage)
})

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

</script>

<template>
  <li
    :class="['flex cursor-pointer select-none items-center rounded-md px-3 py-2 relative group', active && 'bg-gray-800 text-white']"
  >
    <template
      v-if="(result.document || result.quick_search?.document)"
    >
      <div class="relative">
        <component
          :is="getDocumentTypeInfo(result.document?.document_type || result.quick_search?.document?.document_type || DocumentType.other)?.icon"
          class="w-8 h-8"
          aria-hidden="true"
        />
        <div
          v-if="result.document?.origin === 'pdf' || result.quick_search?.document?.origin === 'pdf'"
          class="w-4 h-4 absolute -left-0.5 -bottom-1 text-red-500 bg-gray-800"
        >
          <PDFIcon
            aria-hidden="true"
          />
        </div>
        <div
          v-if="result.document?.origin === 'signed_pdf' || result.quick_search?.document?.origin === 'signed_pdf'"
          class="w-4 h-4 absolute -left-0.5 -bottom-1 text-gray-500 bg-gray-800"
        >
          <ImportFileIcon
            aria-hidden="true"
          />
        </div>
      </div>
    </template>
    <DocumentDuplicateIcon
      v-else
      :class="['w-8 h-8 flex-none', active ? 'text-white' : 'text-gray-500']"
      aria-hidden="true"
    />
    <span class="flex-auto ml-3 truncate">
      <span
        v-if="result.quick_search"
        class="flex"
      >
        {{ result.quick_search.document?.name || result.quick_search.template?.name }}
      </span>
      <span
        v-else
        class="flex"
      >
        {{ result.document?.name || result.template?.name }}
      </span>
      <span
        v-if="query !== ''"
        class="flex items-center text-xs opacity-50"
      >
        <span class="truncate">
          {{ $t('quickSearch.updatedAt', {time: formatDateRelative(result.document?.updated_at || result.template?.updated_at)}) }}
        </span>
        <span class="inline-flex items-center gap-1 ml-3">
          <TeamList
            v-if="result.document?.teams?.length || result.template?.teams?.length"
            :teams="result.document?.teams || result.template?.teams"
            :layout="'gray'"
          />
          <span
            v-else
            class="text-sm text-gray-400"
          >
            {{ $t('unassigned') }}
          </span>
        </span>
        <StageDisplay
          v-if="result.document"
          :stage="stage"
          label-classes="ml-1 text-xs text-gray-400"
          class="ml-2"
        />
      </span>
    </span>
    <span
      v-if="active"
      class="flex-none ml-3 text-gray-400"
    >
      {{ $t('common.jumpTo') }}…
    </span>
    <button
      v-if="query === ''"
      type="button"
      class="ml-3 text-gray-400 hover:text-gray-200"
      @click.prevent="$emit('delete', result.uuid)"
    >
      <XMarkIcon
        class="w-5 h-5 shrink-0"
        aria-hidden="true"
      />
    </button>
  </li>
</template>
