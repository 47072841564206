<script setup lang="ts">
// external
import { computed, ref, watch } from "vue"
import { storeToRefs } from "pinia"

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"

// internal
import { SpinLoader } from "~/components"
import { useAccountStore, useDocumentStore, useMetadataStore, useSharedStore, useTemplateStore } from "~/stores"
import { ContractType, CrudContext, MetadataValue } from "~/types"
import { useI18n } from "vue-i18n"
import { onMounted } from "vue"
import { purifyHtml } from "~/utils"
import { onClickOutside } from "@vueuse/core"
import { uniq } from "lodash-es"

interface Props {
  id?: string
  isDisabled?: boolean
  inlineButtonClasses?: string
  chevronClasses?: string
}

withDefaults(
  defineProps<Props>(),
  {
    id: "contractTypeSelector",
    isDisabled: false,
    inlineButtonClasses: "group btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-0 focus:ring-offset-0 text-gray-900 flex items-center gap-2",
    chevronClasses: "absolute inset-y-0 flex items-center pr-2 transition-all duration-200 scale-0 opacity-0 pointer-events-none -right-7 group-hover:scale-100 group-hover:opacity-100",
  },
)

const accountStore = useAccountStore()
const { contractTypes, mau } = storeToRefs(accountStore)
const { fetchContractTypes } = accountStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const metadataStore = useMetadataStore()
const { metadata, metadataValues, uuidsOfUpdatingMetadataValue, metadataValueLastSavedMap, metadataValueUuidBeingRemovedFromEntity } = storeToRefs(metadataStore)

const templateStore = useTemplateStore()
const documentStore = useDocumentStore()

const isLoading = computed(() => {
  return uuidsOfUpdatingMetadataValue.value?.includes(contractTypeMetadataValue.value?.uuid) || isLoadingOverride.value || (metadataValueUuidBeingRemovedFromEntity.value && metadataValueUuidBeingRemovedFromEntity.value === contractTypeMetadataValue.value?.uuid)
})

const { t } = useI18n()

const locale = computed(() => mau.value.user.locale)

const entityUuid = computed(() => {
  return crudContext.value === CrudContext.document ? documentStore.currentDocument?.uuid : templateStore.currentTemplate?.uuid
})

const contractTypeMetadataValue = computed<MetadataValue | null>(() => {
  return metadataValues.value.find((el) => el.metadata?.name === "contract_type") || null
})

defineEmits([ "focus", "blur" ])


const lastSaved = computed<number | undefined>(() => metadataValueLastSavedMap.value[contractTypeMetadataValue.value?.uuid])
const isHighlightedLastSaved = ref<boolean>(false)

watch(lastSaved, () => {
  isHighlightedLastSaved.value = true
  setTimeout(() => { isHighlightedLastSaved.value = false }, 1500)
})

onMounted(() => {
  fetchContractTypes()
})

const query = ref<string>("")

const selectOptions = computed<ContractType[]>(() => {
  if (!contractTypes.value) return []
  // For every contract type, build an array of it's name, it's localized name (if no duplicate), it's aliases and it's localized aliases excluding duplicates
  const searchObject = contractTypes.value.map((el) => {
    const name = el.name
    const nameLocalized = t(`contractTypes.${name}`)
    const aliasesLocalized = el.aliases?.map((alias) => t(`contractTypes.aliasesHolder.${alias}`))
    const aliasesOriginalFiltered = el.aliases?.filter((alias) => !aliasesLocalized?.includes(alias))
    const aliases = aliasesLocalized?.concat(aliasesOriginalFiltered || [])
    return {
      ...el,
      search: [ name, nameLocalized, ...aliases ].join(" ").toLowerCase(),
    }
  })
  // Filter the searchObject by the query if the query is longer than 2 characters
  const filteredByQuery = query.value.length > 0 ? searchObject.filter((el) => el.search.includes(query.value.toLowerCase())) : searchObject
  return filteredByQuery
})

const localMetadataValue = computed<Partial<MetadataValue>>({
  get: () => {
    return contractTypeMetadataValue.value
  },
  set: async (val) => {
    if (!entityUuid.value) return
    metadataStore.updateLocalMetadataValue(val, crudContext.value, entityUuid.value)
  },
})

const isLoadingOverride = ref(false)

const updateLocalMetadataValueField = async (field: keyof MetadataValue, val: any) => {
  // If there is no localMetadataValue, we need to add it first
  let res = null
  try {
    isLoadingOverride.value = true
    if (!contractTypeMetadataValue.value?.uuid) {
      const contractTypeMetadata = metadata.value.find((el) => el.name === "contract_type")
      const payload = {
        metadata_uuid: contractTypeMetadata?.uuid,
        value: val,
      }
      res = await metadataStore.createMetadataValue(crudContext.value, entityUuid.value, payload)
    } else {
      localMetadataValue.value = {
        ...localMetadataValue.value || res || {},
        [field]: val,
      }
    }
  } finally {
    isLoadingOverride.value = false
  }
}

const handleComboboxButtonClick = () => {
  if (!selectOptions.value?.length) {
    query.value = ""
  }
}

const selectedItem = computed<ContractType>(
  {
    get: () => {
      return selectOptions.value.find((el) => el.name === localMetadataValue.value?.value) || null
    },
    set: (value) => {
      updateLocalMetadataValueField("value", value)
      hideDropdown()
    },
  },
)

const highlightedName = (name: string) => {
  // Translate the name
  name = t(`contractTypes.${name}`)
  // Join aliases with ", " and highlight anysubstr that matches the query in text-yellow-200
  return purifyHtml(name.replace(new RegExp(query.value, "gi"), (match) => `<span class="text-yellow-200">${match}</span>`))
}

const highlightedAliases = (name: string, aliases: string[] | undefined) => {
  if (!aliases && locale.value === "en") return ""
  // Build an array of translated aliases and original English translations
  const aliasesLocalized = aliases.map((alias) => t(`contractTypes.aliasesHolder.${alias}`)) || []
  // If my locale is not EN, I need to add the original English translations as well
  const aliasesOriginal = locale.value === "en" ? [] : aliases || []
  aliases = aliasesLocalized.concat(aliasesOriginal)
  // If the locale is not EN, I need to add the English translation of the name as well
  if (locale.value !== "en") {
    const nameLocalized = t(`contractTypes.enAliases.${name}`)
    aliases = (aliases || [])?.concat(nameLocalized)
  }
  // Filter only elements that contain the query
  aliases = uniq(aliases.filter((alias) => alias.toLowerCase().includes(query.value?.toLowerCase())))
  // If the query is less than 3 characters, just join aliases
  let returnAliases = aliases.join(", ")
  // Join aliases with ", " and highlight anysubstr that matches the query in text-yellow-200
  returnAliases = query.value?.length > 1 ? returnAliases.replace(new RegExp(query.value, "gi"), (match) => `<span class="text-yellow-200">${match}</span>`) : null
  // If there are no aliases, return nothing
  if (!returnAliases) return ""
  return purifyHtml(t("contractTypes.aliases") + ": " + returnAliases)
}

const overrideDropdown = ref(false)

const triggerDropdown = () => {
  overrideDropdown.value = true
}

const contractTypeComponentWrapper = ref()
const comboboxInput = ref()
const isEmptyComboboxInput = ref(false)

onMounted(() => {
  // Watcher on the comboboxInput so that isEmptyComboboxInput is updated
  const firstInput = comboboxInput.value.querySelector("input")
  firstInput?.addEventListener("input", () => {
    isEmptyComboboxInput.value = !firstInput?.value
  })

})

const hideDropdown = async () => {
  query.value = ""
  overrideDropdown.value = false
}

onClickOutside(contractTypeComponentWrapper, hideDropdown)

</script>

<template>
  <Combobox
    :id="id"
    v-model="selectedItem"
    as="div"
    nullable
    class="relative max-w-full px-6 py-2 text-sm transition-all duration-500"
    :class="[isDisabled ? 'mr-2' : '' , isHighlightedLastSaved ? 'bg-teal-50' : '']"
  >
    <div ref="contractTypeComponentWrapper">
      <div class="flex items-center justify-between gap-2 group">
        <div class="flex-grow text-gray-500 shrink-0">
          {{ $t('documentGeneralSettings.contractType') }}
        </div>
        <div
          ref="comboboxInput"
          class="relative w-auto"
        >
          <ComboboxInput
            :placeholder="$t('common.select') + '…'"
            :disabled="isDisabled || isLoading"
            :display-value="(item: ContractType) => item?.name ? $t(`contractTypes.${item?.name}`) : ''"
            class="absolute inset-0 py-1 font-medium text-right bg-white min-w-px hover:bg-gray-100 focus:bg-gray-100 input-plain"
            @focus="triggerDropdown"
            @keydown.tab="() => {
              overrideDropdown ? hideDropdown() : null
            }"
            @change="query = $event.target.value"
            @keyup.enter="(e) => { hideDropdown(); e.target.blur() }"
          />
          <span class="px-3 py-1 font-medium text-right bg-white opacity-0 pointer-events-none min-w-px hover:bg-gray-100 focus:bg-gray-100 input-plain">
            {{ query || (overrideDropdown && (!selectedItem?.name || isEmptyComboboxInput) ? $t('common.select') + '…' : null) || (selectedItem?.name ? $t(`contractTypes.${selectedItem?.name}`) : $t('common.select') + '…') }}
          </span>
        </div>
        <div
          v-if="isLoading"
          class="absolute inset-y-0 flex items-center justify-center right-0.5 focus:outline-none group-hover:scale-100"
        >
          <SpinLoader
            class="w-4 h-4"
          />
        </div>
        <ComboboxButton
          v-else
          class="absolute inset-y-0 flex items-center px-1 transition-all duration-200 scale-0 opacity-0 right-px focus:outline-none group-hover:scale-100 group-hover:opacity-100 hover:opacity-100 hover:scale-100"
          @click="handleComboboxButtonClick"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>
      </div>
      <ComboboxOptions
        :static="overrideDropdown"
        class="w-auto min-w-0 top-full left-6 right-6 max-w-none listbox-options"
        @focus="
          $emit('focus', {
            target: { id: 'contractTypeSelector' },
          })
        "
        @blur="
          $emit('blur', {
            target: { id: 'contractTypeSelector' },
          })
        "
      >
        <template
          v-if="selectOptions.length > 0"
        >
          <ComboboxOption
            v-for="option in selectOptions"
            :key="option.uuid"
            v-slot="{ active, selected }"
            as="template"
            :value="option.name"
          >
            <li
              v-cy="`contract-type-selector-${option.name}`"
              :class="[active ? 'bg-gray-700' : '', 'listbox-option']"
            >
              <div
                :class="selected ? 'pr-3' : ''"
              >
                <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                  v-html="highlightedName(option.name)"
                />
                <div
                  class="text-xs text-gray-400"
                  v-html="highlightedAliases(option.name, option.aliases)"
                />
              <!-- eslint-enable vue/no-v-html vue/html-self-closing -->
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ComboboxOption>
        </template>
        <div
          v-else
          class="block px-4 py-2 text-sm text-gray-400"
        >
          {{ $t('common.noMatchingResults') }}
        </div>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>
