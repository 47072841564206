import { mergeAttributes, Node } from "@tiptap/core"
import { Node as PMNode } from "@tiptap/pm/model"
import { PluginKey } from "@tiptap/pm/state"
import Suggestion from "@tiptap/suggestion"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import MentionNode from "./MentionNode.vue"
import { mentionCommand } from "./mentionUtils"

export const MentionPluginKey = new PluginKey("mention")

interface MentionOptions {
  HTMLAttributes: Record<string, any>
  renderLabel: ({ node, options }: { node: PMNode, options: Record<string, any> }) => string
  suggestion: any
}

export const Mention = Node.create<MentionOptions>({
  name: "mention",

  addNodeView () {
    return VueNodeViewRenderer(MentionNode, {})
  },

  addOptions () {
    return {
      HTMLAttributes: {},

      renderLabel ({ node }) {
        return `@${node.attrs.mentionUuid}`
      },

      suggestion: {
        char: "@",

        pluginKey: MentionPluginKey,

        allowSpaces: true,

        command: mentionCommand,

        allow: ({ editor, range }) => {
          const $from = editor.state.doc.resolve(range.from)
          const type = editor.schema.nodes[this.name]
          const allow = !!$from.parent.type.contentMatch.matchType(type)
          return allow
        },
      },
    }
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  draggable: true,

  addAttributes () {
    return {
      mentionUuid: {
        default: null,
        parseHTML: (element) => parseInt(element.getAttribute("data-mention-uuid")),
        renderHTML: (attributes) => {
          if (!attributes.mentionUuid) return {}

          return {
            "data-mention-uuid": attributes.mentionUuid,
          }
        },
      },

    }
  },

  parseHTML () {
    return [
      {
        tag: "span[data-mention]",
      },
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({ "data-mention": "true" }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ]
  },

  renderText ({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addKeyboardShortcuts () {
    return {
      Backspace: ({ editor }) => editor.commands.command(({ tr, state }) => {
        let isMention = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) return false

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name !== this.name) return

          isMention = true
          tr.insertText(this.options.suggestion.char || "", pos, pos + node.nodeSize)

          return false
        })

        return isMention
      }),
    }
  },

  addProseMirrorPlugins () {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})
