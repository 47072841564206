<script setup lang="ts">
// external
import { Editor } from "@tiptap/core"
import { useI18n } from "vue-i18n"

// internal
import { DialogModal } from "~/components"
import { useNotificationStore } from "~/stores"

interface Props {
  show: boolean
  editor: Editor
}

const props = withDefaults(
  defineProps<Props>(),
  {
    show: false,
  },
)

const { notify } = useNotificationStore()
const { t } = useI18n()

const emit = defineEmits([ "close" ])

const close = () => {
  props.editor.commands.focus()

  emit("close")
}

const deleteContent = () => {
  props.editor
    .chain()
    .command(({ tr, dispatch }) => {
      tr
        .setMeta("forceDeleteSelectionWithIssues", true)
        .deleteSelection()

      return dispatch?.(tr)
    })
    .focus()
    .run()

  notify(
    {
      title: t("deleteConfirmationModal.success"),
      message: t("deleteConfirmationModal.successDescription"),
      type: "success",
    },
  )

  close()
}
</script>

<template>
  <DialogModal
    :show="show"
    :show-close-button="true"
    max-width="md"
    @close="close"
  >
    <template #title>
      <div class="flex items-start">
        <span class="font-medium">
          {{ $t('deleteConfirmationModal.title') }}
        </span>
      </div>
    </template>

    <template #content>
      <span class="text-sm">
        {{ $t('deleteConfirmationModal.description') }}
      </span>
    </template>

    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
          @click.prevent="close"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          type="button"
          class="inline-flex items-center gap-2 text-white bg-red-500 btn-plain hover:text-white hover:bg-red-600 focus:ring-red-600"
          @click.prevent="deleteContent"
        >
          {{ $t('deleteConfirmationModal.deleteAnyway') }}
        </button>
      </div>
    </template>
  </DialogModal>
</template>
