<script setup lang="ts">
// external
import { computed } from "vue"
import { storeToRefs } from "pinia"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import { ChevronDownIcon, PlusIcon, BuildingOffice2Icon, UserIcon, UserGroupIcon } from "@heroicons/vue/20/solid"
import { CheckIcon, StarIcon } from "@heroicons/vue/24/solid"

// internal
import { useDashboardStore } from "~/stores"
import { DashboardScope } from "~/types"

const dashboardStore = useDashboardStore()
const { dashboards, activeDashboardUuid: activeDashboardUuid, mau } = storeToRefs(dashboardStore)
const { setActiveDashboardUuid } = dashboardStore

defineEmits([ "create", "edit" ])

const filteredDashboards = computed(() => {
  return dashboards.value.filter((dashboard) => dashboard.scope !== DashboardScope.account || mau.value.permissions.includes("account_manage"))
})

const activeDashboard = computed(() => {
  return dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value)
})

</script>
<template>
  <div class="inline-flex items-stretch rounded-full">
    <button
      v-if="!!activeDashboard"
      type="button"
      class="relative inline-flex items-center px-3 py-2 space-x-2 text-xs font-semibold text-gray-900 bg-gray-100 rounded-l-full sm:text-sm hover:bg-gray-200 focus:z-10"
      data-tippy-help
      :data-tippy-content="$t('dashboard.editDashboard')"
      data-placement="bottom"
      @click="$emit('edit')"
    >
      <div class="relative">
        <BuildingOffice2Icon
          v-if="activeDashboard.scope === DashboardScope.account"
          class="w-4 h-4 text-indigo-500 shrink-0"
          aria-hidden="true"
        />
        <UserGroupIcon
          v-if="activeDashboard.scope === DashboardScope.team"
          class="w-4 h-4 text-indigo-500 shrink-0"
          aria-hidden="true"
        />
        <UserIcon
          v-if="activeDashboard.scope === DashboardScope.personal"
          class="w-4 h-4 text-indigo-500 shrink-0"
          aria-hidden="true"
        />
        <StarIcon
          v-if="!!mau?.dashboard_uuid && mau.dashboard_uuid === activeDashboard.uuid"
          class="absolute w-3 h-3 text-yellow-500 top-2 left-2"
          aria-hidden="true"
        />
      </div>
      <div class="truncate min-w-0 relative sm:max-w-xs max-w-[110px]">
        <span>{{ activeDashboard.name ? activeDashboard.name : $t('dashboard.tabs.' + activeDashboard.key) }}</span>
      </div>
    </button>
    <Menu
      v-if="!!activeDashboard"
      as="div"
      class="relative block h-full ml-px"
    >
      <MenuButton class="relative inline-flex items-center py-2 pl-2 pr-3 text-xs font-semibold text-gray-900 bg-gray-100 rounded-r-full sm:text-sm hover:bg-gray-200 focus:z-10">
        <span class="sr-only">{{ $t('dashboard.openDashboardMenu') }}</span>
        <ChevronDownIcon
          class="w-5 h-5"
          aria-hidden="true"
        />
      </MenuButton>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <MenuItems class="fixed md:absolute top-[52px] md:top-auto right-3 left-3 md:left-auto md:right-0 z-10 mt-2 md:w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div
            v-for="(dashboard, dashboardIdx) in filteredDashboards"
            :key="dashboard.uuid"
          >
            <MenuItem
              v-slot="{ active }"
            >
              <button
                type="button"
                :class="[
                  active ? 'bg-indigo-50 text-indigo-900' : 'text-gray-900',
                  dashboardIdx === 0 ? 'rounded-t-md' : '',
                  'group flex w-full items-center px-5 py-2 text-sm space-x-2 relative',
                ]"
                @click="setActiveDashboardUuid(dashboard.uuid)"
              >
                <div class="relative">
                  <BuildingOffice2Icon
                    v-if="dashboard.scope === DashboardScope.account"
                    class="w-4 h-4 text-indigo-500 shrink-0"
                    aria-hidden="true"
                  />
                  <UserGroupIcon
                    v-if="dashboard.scope === DashboardScope.team"
                    class="w-4 h-4 text-indigo-500 shrink-0"
                    aria-hidden="true"
                  />
                  <UserIcon
                    v-if="dashboard.scope === DashboardScope.personal"
                    class="w-4 h-4 text-indigo-500 shrink-0"
                    aria-hidden="true"
                  />
                  <StarIcon
                    v-if="!!mau?.dashboard_uuid && mau.dashboard_uuid === dashboard.uuid"
                    class="absolute w-3 h-3 text-yellow-500 top-2 left-2"
                    aria-hidden="true"
                  />
                </div>

                <span class="pr-8 truncate">{{ (dashboard.uuid !== "personal" && dashboard.uuid !== "account") ? dashboard.name : $t('dashboard.tabs.' + dashboard.key) }}</span>
                <span
                  v-if="activeDashboard.uuid === dashboard.uuid"
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-500"
                >
                  <CheckIcon
                    class="w-5 h-5 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </button>
            </MenuItem>
          </div>
          <div class="border-t border-gray-100">
            <MenuItem
              v-slot="{ active }"
            >
              <button
                type="button"
                class="flex items-center justify-center w-full px-5 py-2 space-x-1 text-sm font-medium text-indigo-500 rounded-b-md"
                :class="active ? 'bg-indigo-50' : ''"
                @click="$emit('create')"
              >
                <PlusIcon
                  aria-hidden="true"
                  class="w-4 h-4"
                />
                <span>{{ $t('dashboard.createDashboard') }}</span>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </menu>
  </div>
</template>
