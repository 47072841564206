<script setup lang="ts">
// external
import { parse } from "tinyduration"
import { computed } from "vue"

// internal

interface Props {
  value: string
}
const props = withDefaults(
  defineProps<Props>(),
  {},
)

const durationObject = computed(() => parse(props.value))

const lessThanOneWeek = computed(() => {
  if (!durationObject.value) return false
  return !durationObject.value.years && !durationObject.value.months && !durationObject.value.weeks
})

</script>

<template>
  <div class="relative flex flex-wrap space-x-2">
    <template v-if="!!durationObject">
      <div v-if="!!durationObject.years">
        {{ durationObject.years + ' ' + $t('duration.Y', durationObject.years) }}
      </div>
      <div v-if="!!durationObject.months">
        {{ durationObject.months + ' ' + $t('duration.M', durationObject.months) }}
      </div>
      <div v-if="!!durationObject.weeks">
        {{ durationObject.weeks + ' ' + $t('duration.W', durationObject.weeks) }}
      </div>
      <div v-if="!!durationObject.days">
        {{ durationObject.days + ' ' + $t('duration.D', durationObject.days) }}
      </div>

      <div v-if="lessThanOneWeek && !!durationObject.hours">
        {{ durationObject.hours + ' ' + $t('duration.H', durationObject.hours) }}
      </div>
      <div v-if="lessThanOneWeek && !!durationObject.minutes">
        {{ durationObject.minutes + ' ' + $t('duration.MM', durationObject.minutes) }}
      </div>
      <div v-if="lessThanOneWeek && !!durationObject.seconds">
        {{ durationObject.seconds + ' ' + $t('duration.S', durationObject.seconds) }}
      </div>
    </template>
  </div>
</template>
