<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref } from "vue"
import { MenuButton } from "@headlessui/vue"
import {
  BuildingOffice2Icon,
  CogIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  HomeIcon,
  PlayCircleIcon,
  GiftIcon,
} from "@heroicons/vue/24/outline"
import { ChevronUpDownIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/solid"
import { Link, router, usePage } from "@inertiajs/vue3"

// internal
import {
  ApplicationLogoWhite,
  Dropdown,
  DropdownLink,
  NotificationButton,
  TaskButton,
} from "~/components"
import { useAccountStore } from "~/stores"
import { Account, AccountUser, LaravelUser, SubscriptionType } from "~/types"
import { formatDateLocalized, logout } from "~/utils"
import { ImportIcon } from "~/icons"
import { FunctionalComponent } from "vue"

interface Props {
  searchActive?: boolean
  account?: Account
  allAccounts: Account[]
  mau?: AccountUser
  open?: boolean
}

const pageProps = computed(() => usePage().props)

const mu = computed(() => pageProps.value.mu as LaravelUser)

const props = withDefaults(defineProps<Props>(), {
  searchActive: false,
  open: false,
  account: null,
  mau: null,
})

const navigation = computed(() => {
  const navigation = [
    {
      key: "dashboard",
      href: route("dashboard"),
      icon: HomeIcon,
      activeIfUrlStartsWith: "/dashboard",
    },
    {
      key: "documents",
      href: route("documents.index"),
      icon: DocumentTextIcon,
      activeIfUrlStartsWith: "/documents",
    },
    /* {
      key: "library",
      href: route("paragraphs.index"),
      icon: ClauseLibraryIcon,
      activeIfUrlStartsWith: "/clause-library",
    }, */
  ]

  if (props.mau?.permissions.includes("template_manage")) {
    navigation.splice(2, 0, {
      key: "templates",
      href: route("templates.index"),
      icon: DocumentDuplicateIcon,
      activeIfUrlStartsWith: "/templates",
    })
  }

  if (props.mau?.permissions.includes("import_manage")) {
    navigation.push({
      key: "imports",
      href: route("imports.index"),
      icon: (ImportIcon as unknown as FunctionalComponent),
      activeIfUrlStartsWith: "/imports",
    })
  }

  if (props.mau?.permissions.includes("automation_manage")) {
    navigation.push({
      key: "automations",
      href: route("automations.index"),
      icon: PlayCircleIcon,
      activeIfUrlStartsWith: "/automations",
    })
  }

  if (props.mau?.permissions.includes("account_manage")) {
    navigation.push({
      key: "settings",
      href: route("account-settings.show"),
      icon: CogIcon,
      activeIfUrlStartsWith: "/account-settings",
    })
  }
  return navigation
})

const emit = defineEmits([ "update:search-active", "update:open", "update:show-notification-modal" ])

const setSearch = (isSearchVisible:boolean) => {
  emit("update:search-active", isSearchVisible)
}

const isSamlUser = computed(() => pageProps.value.sso)

const currentlyActiveTab = ref(false)
const switchToAccount = (account: Account) => {
  currentlyActiveTab.value = true
  accountStore.$reset()
  if (account.sso_enforced) {
    router.post(route("logout.sso-login", account.uuid))
  } else {
    router.put(route("switch-account", account.uuid), {}, {
      preserveState: false,
      onFinish: () => {
        setTimeout(() => {
          currentlyActiveTab.value = false
        }, 2000)
      },
    })
  }
}

const closeNavigation = () => {
  if (props.open) {
    emit("update:open", false)
  }
}

const accountStore = useAccountStore()
const {
  currentSubscription,
} = storeToRefs(accountStore)
const {
  setMau,
} = accountStore

onMounted(() => {
  setMau(props.mau)
})

Echo.private(`users.${mu.value.uuid}`)
  .listen(
    "Pusher\\User\\AccountSwitched",
    () => {
      if (!currentlyActiveTab.value) {
        router.visit(route("dashboard"))
      }
    },
  )

Echo.private(`users.${mu.value.uuid}`)
  .listen(
    "Pusher\\User\\LoggedOut",
    () => {
      location.reload()
    },
  )

Echo.private(`users.${mu.value.uuid}`)
  .listen(
    "Pusher\\User\\RoleChanged",
    () => {
      location.reload()
    },
  )

Echo.private(`users.${mu.value.uuid}`)
  .listen(
    "Pusher\\User\\ImpersonationStarted",
    () => {
      window.location.href = "/dashboard"
    },
  )

if (pageProps.value.impersonator) {
  Echo.private(`users.${pageProps.value.impersonator.uuid}`)
    .listen(
      "Pusher\\User\\ImpersonationStopped",
      () => {
        window.location.href = "/dashboard"
      },
    )
}

</script>

<template>
  <!-- Navigation sidebar -->
  <div
    class="fixed inset-y-0 z-30 flex flex-col overflow-hidden transition-all duration-300 md:w-14 hover:w-56 group"
    :class="[open ? 'w-56' : 'w-0']"
  >
    <div
      class="fixed inset-0 transition-opacity duration-500 bg-black"
      :class="[open ? 'opacity-70' : 'opacity-0 pointer-events-none group-hover:opacity-70']"
      @click="closeNavigation"
    />

    <div
      class="relative flex flex-col flex-1 pt-5 pb-4 bg-gray-900 border-r shadow border-r-gray-900"
    >
      <div
        class="flex items-center -ml-1 overflow-x-hidden group-hover:w-auto group-hover:shrink-0"
        :class="[open ? 'w-auto shrink-0' : '']"
      >
        <Link
          :href="route('dashboard')"
          class="focus:outline-none focus:bg-black focus:bg-opacity-20 px-3.5 w-full"
        >
          <ApplicationLogoWhite class="h-12" />
        </Link>
      </div>


      <component
        :is="props.mau?.permissions.includes('account_manage') ? Link : 'div'"
        v-if="currentSubscription?.object === SubscriptionType.demo && account.trial_ends_at"
        :href="props.mau?.permissions.includes('account_manage') ? route('account-settings.billing.index') : undefined"
        class="flex relative items-center justify-start px-1.5"
      >
        <div class="text-[12px] px-2 py-0.5 uppercase inline-flex text-center font-bold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-orange-600">
          Trial
        </div>
        <span
          class="flex-1 min-w-0 overflow-x-hidden text-left transition-all duration-300 group-hover:opacity-100 group-hover:w-auto"
          :class="[open ? 'opacity-100 w-auto' : 'opacity-0 w-0']"
        >
          <span class="block ml-0 text-xs font-medium text-orange-400 truncate">
            {{ $t('common.untilDate', {date: formatDateLocalized(account.trial_ends_at, mau.user.locale)}) }}
          </span>
        </span>
      </component>

      <component
        :is="props.mau?.permissions.includes('account_manage') ? Link : 'div'"
        v-if="currentSubscription?.object === SubscriptionType.limited"
        :href="props.mau?.permissions.includes('account_manage') ? route('account-settings.billing.index') : undefined"
        class="flex relative items-center justify-start px-1.5"
      >
        <div class="text-[11px] mt-px px-2 py-0.5 uppercase inline-flex text-center font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-400 to-slate-400">
          Free
        </div>
        <span
          v-if="account.trial_ends_at"
          class="flex-1 min-w-0 overflow-x-hidden text-left transition-all duration-300 group-hover:opacity-100 group-hover:w-auto"
          :class="[open ? 'opacity-100 w-auto' : 'opacity-0 w-0']"
        >
          <span class="block ml-0 text-xs font-medium text-gray-400 truncate">
            {{ $t('common.expiredDate', {date: formatDateLocalized(account.trial_ends_at, mau.user.locale)}) }}
          </span>
        </span>
      </component>

      <div class="flex-1 h-0 mt-1 overflow-y-auto">
        <!-- Primary nav -->

        <nav
          v-cy="`sidebar-nav`"
          class="mt-2"
        >
          <Link
            v-for="item in navigation"
            :key="item.key"
            v-cy="`sidebar-nav-item-${item.key.toLowerCase()}`"
            :href="item.href"
            :class="[
              $page.url.startsWith(item.activeIfUrlStartsWith)
                ? 'bg-indigo-800 bg-opacity-70 border-indigo-900 text-indigo-100'
                : 'border-transparent text-gray-200 hover:bg-black hover:bg-opacity-40 hover:text-gray-100',
              'group flex items-center px-4 py-2.5 text-sm font-medium focus:outline-none focus:bg-black focus:bg-opacity-40 focus:text-gray-100',
            ]"
          >
            <component
              :is="item.icon"
              class="w-6 h-6 shrink-0"
              :class="[$page.url.startsWith(item.activeIfUrlStartsWith) ? 'text-white' : 'text-white/50']"
              aria-hidden="true"
            />
            <span
              class="inline-block overflow-x-hidden transition-all duration-500 opacity-0 group-hover:w-auto group-hover:ml-3 group-hover:opacity-100"
              :class="[open ? 'w-auto opacity-100 ml-3' : 'w-0']"
            >
              {{ $t(`navigation.entries.${item.key}`) }}
            </span>
          </Link>
        </nav>

        <!-- Sidebar Search -->
        <div class="relative">
          <button
            type="button"
            class="w-full border-t border-t-gray-800 border-transparent text-gray-200 hover:bg-black hover:bg-opacity-20 hover:text-gray-100 group flex items-center px-4 py-2.5 text-sm font-medium focus:outline-none focus:bg-black focus:bg-opacity-20 focus:text-gray-100"
            aria-hidden="true"
            @click.prevent="setSearch(true)"
          >
            <MagnifyingGlassIcon
              class="w-6 h-6 text-white opacity-50 shrink-0"
              aria-hidden="true"
            />
            <span
              class="inline-flex flex-row items-center gap-2 overflow-x-hidden transition-all duration-500 opacity-0 group-hover:w-auto group-hover:ml-3 group-hover:opacity-100"
              :class="[open ? 'w-auto opacity-100 ml-3' : 'w-0']"
            >
              <span>
                {{ $t('navigation.entries.search') }}
              </span>
              <span class="inline-flex flex-row gap-1">
                <kbd
                  v-for="key in ['⌘', 'K']"
                  :key="key"
                  class="inline-flex items-center px-1 font-sans text-sm font-medium text-gray-500 border border-gray-700 rounded "
                >
                  {{ key }}
                </kbd>
              </span>
            </span>
          </button>
        </div>
      </div>


      <div class="flex flex-col w-full gap-3 px-2 py-1 shrink-0">
        <!-- Cello -->
        <div
          class="relative -mx-2"
        >
          <button
            class="cello-launcher cello-announcements w-full max-h-[44px] relative"
            :class="['border-transparent text-gray-200 hover:bg-black hover:bg-opacity-40 hover:text-gray-100',
                     'group flex items-center px-4 py-2.5 text-sm font-medium focus:outline-none focus:bg-black focus:bg-opacity-40 focus:text-gray-100',
            ]"
          >
            <GiftIcon
              class="w-6 h-6 shrink-0"
              :class="'text-white/50'"
              aria-hidden="true"
            />
            <span
              class="whitespace-nowrap inline-block overflow-x-hidden transition-all duration-500 opacity-0 group-hover:w-auto group-hover:ml-3 group-hover:opacity-100"
              :class="[open ? 'w-auto opacity-100 ml-3' : 'w-0']"
            >
              {{ $t('navigation.cello') }}
            </span>
          </button>
        </div>

        <!-- Tasks -->
        <div
          v-if="!!mau"
          class="-mx-2"
        >
          <TaskButton
            class="w-full border-transparent text-gray-200 hover:bg-black hover:bg-opacity-40 hover:text-gray-100 group flex items-center px-4 py-2.5 text-sm font-medium focus:outline-none focus:bg-black focus:bg-opacity-40 focus:text-gray-100"
            :mau="mau"
            :show-text="open"
          />
        </div>


        <!-- Notifications -->
        <div
          class="-mx-2"
        >
          <NotificationButton
            class="w-full border-transparent text-gray-200 hover:bg-black hover:bg-opacity-40 hover:text-gray-100 group flex items-center px-4 py-2.5 text-sm font-medium focus:outline-none focus:bg-black focus:bg-opacity-40 focus:text-gray-100"
            :show-text="open"
          />
        </div>

        <!-- Accounts Dropdown -->
        <dropdown
          v-if="!!account"
          menu-classes="relative text-left"
          theme="light"
          width="52"
          align="bottom"
          :auto-close="true"
        >
          <template #trigger>
            <MenuButton
              class="w-full text-sm font-medium text-indigo-600 border border-transparent rounded-md group-hover:border-indigo-900 hover:bg-indigo-900 hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-800"
            >
              <span class="flex items-center justify-between w-full group">
                <span class="flex items-center justify-between min-w-0 space-x-2 h-9">
                  <span class="flex-none">
                    <BuildingOffice2Icon
                      class="h-6 w-6 ml-1.5 text-indigo-300"
                      aria-hidden="true"
                    />
                  </span>
                  <span
                    class="flex-1 min-w-0 overflow-x-hidden text-left transition-all duration-300 group-hover:opacity-100 group-hover:w-auto"
                    :class="[open ? 'opacity-100 w-auto' : 'opacity-0 w-0']"
                  >
                    <span class="block text-sm font-medium text-indigo-400 truncate">
                      {{ account.name }}
                    </span>
                  </span>
                </span>
                <ChevronUpDownIcon
                  class="ml-2 mr-2 text-indigo-400 transition-all duration-300 shrink-0 group-hover:opacity-100 group-hover:h-5 group-hover:w-5"
                  :class="[open ? 'h-5 w-5 opacity-100' : 'opacity-0 w-0 h-0']"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
          </template>

          <template #content>
            <!-- Account Management -->
            <div class="block px-4 py-2 text-xs text-indigo-400">
              {{ $t('navigation.manageAccount') }}
            </div>

            <!-- Account Settings -->
            <DropdownLink
              v-if="props.mau?.permissions.includes('account_manage')"
              :href="route('account-settings.show', account.uuid)"
            >
              {{ $t('navigation.accountSettings') }}
            </DropdownLink>

            <DropdownLink
              v-if="!isSamlUser"
              :href="route('accounts.create')"
            >
              {{ $t('navigation.createAccount') }}
            </DropdownLink>

            <div
              v-if="!isSamlUser"
              class="py-1 border-t border-t-indigo-800"
            >
              <!-- Account Switcher -->
              <div class="block px-4 py-2 text-xs text-indigo-400">
                {{ $t('navigation.switchAccount') }}
              </div>

              <DropdownLink
                v-for="(loopAccount, loopAccountIdx) in allAccounts"
                :key="loopAccountIdx"
                as="button"
                :disabled="loopAccount.suspended_at !== null"
                @click="switchToAccount(loopAccount)"
              >
                <div
                  v-if="loopAccount.suspended_at === null"
                  class="flex items-center justify-between"
                >
                  <div>
                    <div class="truncate w-full max-w-[150px]">
                      {{ loopAccount.name }}
                    </div>
                    <div
                      v-if="loopAccount.sso_enforced"
                      class="-mt-1 text-xs opacity-70"
                    >
                      {{ $t('navigation.ssoRequired') }}
                    </div>
                  </div>
                  <svg
                    v-if="loopAccount.uuid === account.uuid"
                    class="w-5 h-5 text-green-400"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </DropdownLink>
            </div>
          </template>
        </dropdown>

        <!-- Profile dropdown -->
        <dropdown
          v-if="!!mau"
          menu-classes="w-full relative text-left"
          theme="light"
          width="52"
          align="bottom"
          :auto-close="true"
        >
          <template #trigger>
            <MenuButton
              class="w-full text-sm font-medium text-indigo-600 border border-transparent rounded-md group-hover:border-indigo-900 hover:bg-indigo-900 hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-800"
            >
              <span class="flex items-center justify-between w-full group">
                <span class="flex items-center justify-between min-w-0 space-x-2 h-9">
                  <span class="flex-none">
                    <img
                      class="h-6 w-6 ml-1.5 rounded-full"
                      :src="mau.profile_photo_url"
                      :alt="mau.first_name"
                    >
                  </span>
                  <span
                    class="flex-1 min-w-0 overflow-x-hidden text-left transition-all duration-300 group-hover:opacity-100 group-hover:w-auto"
                    :class="[open ? 'opacity-100 w-auto' : 'opacity-0 w-0']"
                  >
                    <span class="block text-sm font-medium text-indigo-400 truncate">
                      {{ mau.first_name }}
                    </span>
                  </span>
                </span>
                <ChevronUpDownIcon
                  class="ml-2 mr-2 text-indigo-400 transition-all duration-300 shrink-0 group-hover:opacity-100 group-hover:h-5 group-hover:w-5"
                  :class="[open ? 'h-5 w-5 opacity-100' : 'opacity-0 w-0 h-0']"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
          </template>

          <template #content>
            <div
              v-if="isSamlUser"
              class="block px-2 py-1 m-2 text-xs text-center text-indigo-500 bg-indigo-100 rounded-sm"
            >
              {{ $t('navigation.loggedInSaml') }}
            </div>

            <!-- Account Management -->
            <div class="block px-4 py-2 text-xs text-indigo-400">
              {{ $t('navigation.manageProfile') }}
            </div>

            <DropdownLink :href="route('user-profile.index')">
              {{ $t('navigation.profile') }}
            </DropdownLink>

            <DropdownLink
              v-if="mau?.user.role.display_name === 'superadmin'"
              :href="route('nova.pages.home')"
            >
              Admin
            </DropdownLink>

            <div class="py-1">
              <!-- Authentication -->
              <DropdownLink
                as="button"
                @click="logout"
              >
                {{ $t('navigation.logout') }}
              </DropdownLink>
            </div>
          </template>
        </dropdown>
      </div>
    </div>
  </div>
</template>
