import i18nInstance from "~/utils/i18n"
import { abbreviate, addNumberSeparators } from "~/utils"
import { DEFAULT_CHART_COLORS } from "~/utils/constants"

const { t } = i18nInstance.global

const MAX_CHARS_LABEL = 16

export default {
  "donut": {
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "left",
      fontSize: "12px",
      fontFamily: `Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji`,
      fontWeight: 400,
      offsetX: -30,
      offsetY: -20,
      markers: {
        width: 10,
        height: 10,
        offsetX: 0,
        offsetY: 1,
        radius: 2,
      },
      itemMargin: {
        vertical: 1,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "75%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              fontFamily: `Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji`,
              fontWeight: 400,
              offsetY: 16,
              formatter: function (seriesName) {
                if (seriesName.length > MAX_CHARS_LABEL) {
                  seriesName = seriesName.substring(0, MAX_CHARS_LABEL) + "…"
                }
                return seriesName
              },
            },
            value: {
              show: true,
              fontSize: "26px",
              fontFamily: `Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji`,
              fontWeight: 600,
              offsetY: -16,
              formatter: (val) => {
                return val
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: t("common.total"),
              fontSize: "12px",
              fontFamily: `Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji`,
              fontWeight: 400,
              color: "#111827",
              formatter: (w) => {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              },
            },
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: DEFAULT_CHART_COLORS,
  },
  "pie": {
    legend: {
      position: "left",
      fontSize: "12px",
      fontFamily: `Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji`,
      fontWeight: 400,
      offsetX: -30,
      offsetY: -20,
      markers: {
        width: 10,
        height: 10,
        offsetX: 0,
        offsetY: 1,
        radius: 2,
      },
      itemMargin: {
        vertical: 1,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  },
  "bar": {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return abbreviate(value)
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return abbreviate(value)
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return addNumberSeparators(value)
        },
      },
    },
    colors: DEFAULT_CHART_COLORS,
  },
  "line":
  {
    chart: {
      type: "line",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.ceil(val).toFixed(0)
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      shared: true,
      y: {
        formatter: function (val) {
          return Math.ceil(val).toFixed(0)
        },
      },
    },
  },

}
