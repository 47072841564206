export const locales = [
  {
    "name": "de",
    "options": {
      "months": [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ],
      "shortMonths": [ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
      "days": [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ],
      "shortDays": [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa" ],
      "toolbar": {
        "exportToSVG": "SVG speichern",
        "exportToPNG": "PNG speichern",
        "exportToCSV": "CSV speichern",
        "menu": "Menü",
        "selection": "Auswahl",
        "selectionZoom": "Zoom auf Auswahl",
        "zoomIn": "Vergrößern",
        "zoomOut": "Verkleinern",
        "pan": "Verschieben",
        "reset": "Zoom zurücksetzen",
      },
    },
  },
  {
    "name": "en",
    "options": {
      "months": [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
      "shortMonths": [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
      "days": [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
      "shortDays": [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
      "toolbar": {
        "exportToSVG": "Download SVG",
        "exportToPNG": "Download PNG",
        "exportToCSV": "Download CSV",
        "menu": "Menu",
        "selection": "Selection",
        "selectionZoom": "Selection Zoom",
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "pan": "Panning",
        "reset": "Reset Zoom",
      },
    },
  },
]
