<script setup lang="ts">
// external
import { computed } from "vue"
import { EllipsisHorizontalIcon, InformationCircleIcon, FlagIcon, CalendarDaysIcon, SparklesIcon, ArrowTopRightOnSquareIcon, ArrowUturnLeftIcon } from "@heroicons/vue/24/outline"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { router } from "@inertiajs/vue3"

// internal
import { Notification, NotificationType } from "~/types"
import { formatDateRelative, formatDateAndTime, getUserRepresentation } from "~/utils"
import { useNotificationStore } from "~/stores"
import { SignatureIcon, TaskIcon } from "~/icons"
import { storeToRefs } from "pinia"

interface Props {
  notification: Notification
}

const props = withDefaults(defineProps<Props>(), {})

const notificationStore = useNotificationStore()
const { showNotificationModal } = storeToRefs(notificationStore)
const { markAsRead, deactivateNotificationType } = notificationStore

const isRead = computed(() => props.notification.is_read || !!props.notification.read_at)

const notificationLink = computed(() => {
  if (props.notification.type === NotificationType.DOCUMENT_EXPORT_COMPLETED) {
    return route("account-settings.document-exports.index")
  } else if (props.notification.data?.import_uuid) {
    return route("imports.show", props.notification.data?.import_uuid)
  } else if (!!props.notification.data?.comment_uuid) {
    return route("documents.show", props.notification.data.document_uuid) + "#comment_" + props.notification.data.comment_uuid
  } else if (!!props.notification.data?.document_uuid) {
    return route("documents.show", props.notification.data.document_uuid)
  } else if (!!props.notification.custom_task) {
    return null
  } else {
    return route("dashboard")
  }
})

const clickHandler = async () => {
  if (!isRead.value) {
    await markAsRead(props.notification.uuid)
  }

  if (notificationLink.value) {
    router.visit(notificationLink.value)
  }

  showNotificationModal.value = false
}

const notificationData = computed(() => {
  return {
    document_name: props.notification.document?.name ?? null,
    import_name: props.notification.import?.name ?? null,
    document_export_name: props.notification.document_export?.name ?? null,
    task_name: props.notification.custom_task?.title ?? null,
    created_by_name: getUserRepresentation(props.notification.custom_task ? props.notification.custom_task.created_by_account_user : props.notification.created_by_account_user),
  }
})

</script>
<template>
  <div
    class="relative group flex items-center justify-between py-2.5 pl-6 pr-3 transition-colors duration-75 border-t border-t-gray-100"
    :class="isRead ? 'hover:bg-gray-50' : 'bg-indigo-50 hover:bg-indigo-100'"
  >
    <button
      class="absolute inset-0"
      type="button"
      @click="clickHandler"
    >
      <div
        v-if="!isRead"
        class="absolute top-0 bottom-0 left-0 w-1 h-full bg-indigo-500"
      />
    </button>
    <div class="relative flex items-center h-full mr-5 pointer-events-none grow">
      <div class="flex items-center justify-center w-8 h-8 mr-4 bg-gray-100 rounded-full group-hover:shadow shrink-0">
        <img
          v-if="!!notification.created_by_account_user?.profile_photo_url &&
            [
              NotificationType.COMMENT_MENTION,
              NotificationType.COMMENT_CREATED,
              NotificationType.COMMENT_REPLIED,
              NotificationType.COMMENT_PROPOSAL_REJECTED,
              NotificationType.DOCUMENT_INVITED,
              NotificationType.DOCUMENT_INVITED_CC,
            ].includes(notification.type)
          "
          :src="notification.created_by_account_user.profile_photo_url"
          class="w-4 h-4 rounded-full"
        >
        <TaskIcon
          v-else-if="[
            NotificationType.CUSTOM_TASK_RESOLVED,
            NotificationType.CUSTOM_TASK
          ].includes(notification.type)"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <FlagIcon
          v-else-if="notification.type === NotificationType.CHECKPOINT_APPROVAL_REQUIRED"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <ArrowTopRightOnSquareIcon
          v-else-if="notification.type === NotificationType.DOCUMENT_EXPORT_COMPLETED"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <SparklesIcon
          v-else-if="notification.type === NotificationType.IMPORT_STATUS_AI_ANALYSES_REVIEW"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <CalendarDaysIcon
          v-else-if="[
            NotificationType.DOCUMENT_EXPIRING_REMINDER,
            NotificationType.DOCUMENT_RENEWAL_REMINDER,
            NotificationType.DOCUMENT_EFFECTIVE_REMINDER,
            NotificationType.DOCUMENT_CANCELLATION_NOTICE_PERIOD_REMINDER,
          ].includes(notification.type)"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <SignatureIcon
          v-else-if="[
            NotificationType.PARTY_READY_FOR_SIGNING,
            NotificationType.DOCUMENT_MOVED_TO_SIGNING_PHASE,
            NotificationType.DOCUMENT_FULLY_SIGNED,
          ].includes(notification.type)"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <ArrowUturnLeftIcon
          v-else-if="notification.type === NotificationType.DOCUMENT_SIGNING_PHASE_REVERTED"
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
        <InformationCircleIcon
          v-else
          aria-hidden="true"
          class="w-4 h-4"
          :class="isRead ? 'text-gray-400' : 'text-indigo-300'"
        />
      </div>
      <div
        class="text-sm line-clamp-2"
      >
        {{ $t('notifications.copyText.' + notification.type, notificationData) }}
      </div>
    </div>
    <div class="flex flex-col items-end lg:flex-row lg:items-center lg:gap-1 shrink-0">
      <time
        :datetime="notification.created_at"
        class="pr-2 text-xs"
        :class="isRead ? 'text-gray-400' : 'text-indigo-400'"
        :title="formatDateAndTime(notification.created_at)"
      >{{
        formatDateRelative(notification.created_at)
      }}</time>
      <Menu
        as="div"
        class="relative inline-block text-left"
      >
        <div>
          <MenuButton
            class="p-1 text-gray-400 btn-menu hover:text-gray-900"
          >
            <div class="sr-only">
              {{ $t('magicTable.columns.options') }}
            </div>
            <EllipsisHorizontalIcon
              class="w-4 h-4"
              aria-hidden="true"
            />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 z-20 w-64 mt-1 overflow-hidden origin-top-right divide-y rounded-md divide-slate-800 bg-slate-900 focus:outline-none"
          >
            <MenuItem
              v-if="!isRead"
              v-slot="{ active }"
            >
              <button
                :class="[
                  active ? 'bg-slate-700 text-white' : 'text-slate-300',
                  'group flex w-full items-center px-5 py-2 text-sm rounded-t-md text-left',
                ]"
                @click="markAsRead(notification.uuid)"
              >
                {{ $t('notifications.markAsRead') }}
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                :class="[
                  active ? 'bg-slate-700 text-white' : 'text-slate-300',
                  'group flex w-full items-center px-5 py-2 text-sm rounded-t-md text-left',
                ]"
                @click="deactivateNotificationType(notification.type)"
              >
                {{ $t('notifications.deactivateThisType') }}
              </button>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>
