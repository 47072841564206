<script setup lang="ts">
//external
import { computed } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"

// internal
import { Tag } from "~/types"
import { TagList, Checkbox } from "~/components"

interface Props {
  tags: Tag[]
  selectedUuids?: Tag["uuid"][]
  andOperator?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedUuids: () => [],
    andOperator: false,
  },
)

const emits = defineEmits([ "update:selected-uuids", "update:and-operator" ])

const selectedTags = computed({
  get: () => props.tags.filter((tag) => props.selectedUuids.includes(tag.uuid)),
  set: (newVal) => {
    const uuids = newVal.length ? newVal.map((tag) => tag.uuid) : []
    emits("update:selected-uuids", uuids)
  },
})

const localAndOperator = computed({
  get: () => props.andOperator,
  set: (value) => emits("update:and-operator", value),
})

const removeTag = (tag) => {
  console.log(tag)
  emits("update:selected-uuids", props.selectedUuids.filter((uuid) => uuid !== tag.uuid))
}

</script>

<template>
  <Listbox
    v-model="selectedTags"
    multiple
    as="div"
  >
    <div class="relative mt-1">
      <ListboxButton class="btn-listbox-plain">
        <span
          class="block text-gray-500 truncate"
        >{{ $t('tags.selectTags') }}…</span>

        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronUpDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="listbox-options max-w-full">
          <ListboxOption
            v-for="tag in tags"
            :key="'tag_' + tag.uuid"
            v-slot="{ active, selected }"
            as="template"
            :value="tag"
          >
            <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                <span>{{ tag.name }}</span>
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon
                  class="w-5 h-5 shrink-0"
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <TagList
    v-if="selectedTags.length"
    class="mt-2"
    :tags="selectedTags"
    :removable="true"
    @remove="removeTag"
  />

  <label class="flex items-center mt-2 space-x-2">
    <Checkbox
      v-model="localAndOperator"
      class="mx-0 shrink-0"
      :checked="localAndOperator"
    />
    <span class="grow text-sm text-gray-600">{{ $t('filter.labels.tag_and') }}</span>
  </label>
</template>
