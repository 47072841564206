import { Editor, mergeAttributes, Node } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

import SignatureBlockNodeView from "./SignatureBlockNodeView.vue"
import { trackEventInJune } from "~/utils"
import { JuneEvents } from "~/types"

const SignatureBlockDropPluginKey = new PluginKey("signatureBlockDropPlugin")

export const getSignatureBlockDropPlugin = (editor: Editor) => {
  return new Plugin({
    key: SignatureBlockDropPluginKey,
    props: {
      handleDrop (view, event) {
        if (!event || !editor.isEditable) return false

        const coordinates = view.posAtCoords(
          {
            left: event.clientX,
            top: event.clientY,
          },
        )

        const signatureBlock = event.dataTransfer.getData("text")

        if (!signatureBlock) return false

        let signatureBlockJson: { isSignatureBlock: boolean, refUuid: string }

        try {
          signatureBlockJson = JSON.parse(signatureBlock)
        } catch {
          // No valid JSON, so we can't handle this drop
          return false
        }

        if (!signatureBlockJson?.isSignatureBlock) return false

        event.preventDefault()

        const posAfterDroppedSignatureBlock = coordinates.pos + 2

        const { refUuid } = signatureBlockJson

        const schemaHasSignatureContainer = !!editor.schema.nodes.signatureContainer

        const content = schemaHasSignatureContainer
          ? {
            type: "signatureContainer",
            attrs: {
              signatureBlocks: [
                {
                  refUuid,
                },
              ],
            },
          } : {
            type: "signatureBlock",
            attrs: {
              refUuid,
            },
          }

        trackEventInJune(JuneEvents.EDITOR_SIGNATURE_BLOCK_DROPPED)

        editor
          .chain()
          .insertContentAt(coordinates.pos, content)
          .focus(posAfterDroppedSignatureBlock)
          .run()

        return true
      },
    },
  })
}

export const SignatureBlock = Node.create(
  {
    name: "signatureBlock",

    group: "block",

    selectable: false,

    draggable: true,

    atom: true,

    allowGapCursor: false,

    addNodeView () {
      return VueNodeViewRenderer(SignatureBlockNodeView)
    },

    addOptions () {
      return {
        HTMLAttributes: {},
        renderLabel ({ node }) {
          return `{{*SIGNATURE_BLOCK:${node.attrs.refUuid}*}}`
        },
      }
    },

    addAttributes () {
      return {
        refUuid: {
          default: null,
          parseHTML: (element) => parseInt(element.getAttribute("ref-uuid")),
          renderHTML: (attributes) => {
            if (!attributes.refUuid) return {}

            return {
              "ref-uuid": attributes.refUuid,
            }
          },
        },
      }
    },

    parseHTML () {
      return [
        {
          tag: "div[data-signature-block]",
        },
      ]
    },

    renderHTML ({ node }) {
      return [
        "div",
        mergeAttributes(
          {
            "data-signature-block": node.attrs.refUuid,
          },
        ),
        this.options.renderLabel(
          {
            options: this.options,
            node,
          },
        ),
      ]
    },

    renderText ({ node }) {
      return this.options.renderLabel(
        {
          options: this.options,
          node,
        },
      )
    },

    addProseMirrorPlugins () {
      const { editor } = this
      return [
        getSignatureBlockDropPlugin(editor),
      ]
    },
  },
)
