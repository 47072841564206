<script setup lang="ts">
// external
import {
  Combobox,
  ComboboxButton,
  ComboboxInput, ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, LinkIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { computed, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import { throttle } from "lodash-es"
import axios from "axios"

// internal
import { Document, DocumentSnippet, CrudContext, DocumentType } from "~/types"
import { PartyEntityTypeDisplayIcon, SpinLoader } from "~/components"
import { useDocumentStore, useLinkedDocumentStore, useSharedStore, useTemplateStore } from "~/stores"
import { documentTypes } from "~/utils"
import { ImportFileIcon, PDFIcon } from "~/icons"

interface Props {
  selectedDocument?: Partial<Document>
  isLoadingAddDocument?: boolean
  align?: "top" | "bottom"
  inputClass?: string
  scope?: "internal" | "external"
  deactivatedDocumentUuids?: Document["uuid"][]
  documentUuidsToExclude?: Document["uuid"][]
  static?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedDocument: null,
    isLoadingAddDocument: false,
    align: "top",
    inputClass: "",
    scope: "external",
    deactivatedDocumentUuids: () => [],
    documentUuidsToExclude: () => [],
    static: false,
  },
)

const emit = defineEmits([ "update:selected-document" ])

const documentStore = useDocumentStore()
const { currentDocument } = storeToRefs(documentStore)

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed(() => {
  return crudContext.value === CrudContext.document ? currentDocument.value?.uuid : currentTemplate.value?.uuid
})

const linkedDocumentStore = useLinkedDocumentStore()
const { affiliatedPartyDocuments, isLoadingAffiliatedPartyDocuments, isLoadingSearchDocuments } = storeToRefs(linkedDocumentStore)
const { getAffiliatedPartyDocuments } = linkedDocumentStore

const query = ref("")

const selectedDocument = computed({
  get: () => props.selectedDocument,
  set: (value) => {
    emit("update:selected-document", value)
    query.value = ""
  },
})


const handleComboboxButtonClick = () => {
  if (query.value === "" && !filteredResults.value?.length) {
    getAffiliatedPartyDocuments(entityUuid.value)
  }
  query.value = ""
}

interface SearchLink {
  url: string | null
  label: string
  active: boolean
}

interface SearchMetaInformation {
  current_page: number
  from: number
  last_page: number
  links: SearchLink[]
  path: string
  per_page: number
  to: number
  total: number
}

const results = ref<DocumentSnippet[]>(null)
const searchMetaInformation = ref<SearchMetaInformation>(null)
const searchInProgress = ref<boolean>(false)

const search = throttle(
  async (query: string): Promise<void> => {
    searchInProgress.value = true

    const url = `${route("api.document-search")}?query=${query}`

    try {
      if (query?.length < 3) return
      const res = await axios.get(url)

      results.value = res.data.data

      searchMetaInformation.value = res.data.meta
    } catch (err) {
      console.error(err)
    } finally {
      searchInProgress.value = false
    }
  },
  500,
)

const filteredResults = computed(() => {
  return results.value?.filter((result) => !props.documentUuidsToExclude.includes(result.uuid))
})

const resetSelectedDocument = () => {
  selectedDocument.value = null
}

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

watch(
  () => query.value,
  (newVal) => newVal?.length >= 3
    ? search(newVal)
    : results.value = [],
)

</script>

<template>
  <Combobox
    v-model="selectedDocument"
    as="div"
    nullable
    :disabled="isLoadingAddDocument"
  >
    <div
      class="relative flex items-center gap-2"
    >
      <div
        v-if="selectedDocument?.name"
        class="absolute inset-y-0 z-20 flex items-center left-1"
      >
        <component
          :is="getDocumentTypeInfo(selectedDocument?.document_type || DocumentType.other)?.icon"
          aria-hidden="true"
          class="w-6 h-6"
        />
        <div
          v-if="selectedDocument?.origin === 'pdf'"
          class="w-2.5 h-2.5 absolute left-0 bottom-0.5 text-red-500 bg-white"
        >
          <PDFIcon
            aria-hidden="true"
          />
        </div>
        <div
          v-if="selectedDocument?.origin === 'signed_pdf'"
          class="w-2.5 h-2.5 absolute left-0 bottom-0.5 text-gray-500 bg-white"
        >
          <ImportFileIcon
            aria-hidden="true"
          />
        </div>
      </div>
      <ComboboxInput
        v-cy="`document-combobox-input`"
        name="document-combobox-input"
        :placeholder="$t('documents.enterDocumentOrPartyName') + '...'"
        :class="[
          inputClass ? inputClass : 'pl-3',
          selectedDocument?.name ? 'pl-8' : '',
          props.static ? 'z-10 input-primary' : 'border-0 rounded input-plain',
        ]"
        class="w-full py-2 pr-10 disabled:opacity-70 disabled:pointer-events-none"
        :display-value="
          (document: Document) => document?.name ? document.name : ''
        "
        :disabled="isLoadingAddDocument"
        autocomplete="document-combobox-input"
        @change="query = $event.target.value"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 z-10 flex items-center px-2 rounded-r-md focus:outline-none"
        @click="handleComboboxButtonClick"
      >
        <XMarkIcon
          v-if="selectedDocument"
          class="w-5 h-5 text-gray-400 hover:text-gray-600"
          aria-hidden="true"
          @click.prevent="resetSelectedDocument"
        />
        <ChevronUpDownIcon
          v-else-if="!props.static && (!isLoadingAddDocument && !searchInProgress && !isLoadingSearchDocuments && !isLoadingAffiliatedPartyDocuments)"
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
        <SpinLoader
          v-else-if="isLoadingAddDocument || searchInProgress || isLoadingSearchDocuments || isLoadingAffiliatedPartyDocuments"
          class="w-5 h-5 text-gray-400"
          aria-hidden="true"
        />
      </ComboboxButton>
    </div>

    <ComboboxOptions
      v-if="!static || query || (static && !query && !selectedDocument)"
      class="mb-1 listbox-options"
      :class="[
        props.align === 'bottom' ? 'top-full' : 'bottom-full',
        props.static ? 'relative z-0 bg-white ring-gray-300 shadow-none mt-2' : ''
      ]"
      :static="props.static"
    >
      <template v-if="filteredResults?.length || affiliatedPartyDocuments?.length">
        <template v-if="isLoadingAffiliatedPartyDocuments || isLoadingSearchDocuments">
          <div
            class="flex items-center justify-center gap-2 listbox-option"
          >
            <SpinLoader
              class="w-4 h-4 text-gray-400"
              aria-hidden="true"
            />
            {{ $t('common.loading') }}…
          </div>
        </template>
        <template v-else>
          <div
            v-if="filteredResults?.length"
            class="flex items-center py-1 text-left group px-3 text-slate-500 pt-2 pb-0.5"
          >
            <span class="flex-1 text-[10px] font-medium tracking-wider uppercase text-slate-500">
              {{ $t('linkedDocuments.yourSearch') }}</span>
          </div>
          <ComboboxOption
            v-for="(result, resultIdx) in filteredResults"
            :key="'result_'+resultIdx"
            v-slot="{ active, selected, disabled }"
            :value="result"
            as="template"
            :disabled="deactivatedDocumentUuids.includes(result?.uuid)"
          >
            <li
              :class="[
                static ? 'listbox-option text-gray-700 cursor-pointer pr-4' : 'listbox-option',
                active ? static ? 'bg-gray-100 text-gray-800' : 'bg-gray-700' : '',
                selected ? static ? 'bg-gray-200 text-gray-900' : 'bg-gray-800' : '',
                disabled && !selected ? 'opacity-50 cursor-not-allowed' : '',
              ]"
            >
              <div class="flex items-center">
                <div
                  class="absolute inset-y-0 flex items-center left-2.5"
                >
                  <component
                    :is="getDocumentTypeInfo(result?.document_type || DocumentType.other)?.icon"
                    aria-hidden="true"
                    class="w-6 h-6"
                  />
                  <div
                    v-if="result?.origin === 'pdf'"
                    class="w-2.5 h-2.5 absolute left-0 bottom-2.5 text-red-500"
                    :class="static ? 'bg-white' : 'bg-gray-800'"
                  >
                    <PDFIcon
                      aria-hidden="true"
                    />
                  </div>
                  <div
                    v-if="result?.origin === 'signed_pdf'"
                    class="w-2.5 h-2.5 absolute left-0 bottom-2.5 text-gray-500"
                    :class="static ? 'bg-white' : 'bg-gray-800'"
                  >
                    <ImportFileIcon
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div
                  :class="[
                    'ml-7 flex-grow',
                    selected && 'text-indigo-400',
                  ]"
                >
                  <div class="text-xs font-medium line-clamp-2">
                    {{ result?.name }}
                  </div>

                  <div
                    v-if="result?.parties?.length && result?.parties.length < 5"
                    class="flex items-center max-w-full min-w-0 gap-2 mt-0.5"
                  >
                    <span
                      v-for="(party, partyIdx) in result?.parties"
                      :key="partyIdx"
                      class="flex items-center text-xs"
                      :title="party.entity_name ? party.entity_name : party.name"
                    >
                      <PartyEntityTypeDisplayIcon
                        :party="party"
                        class="w-3 h-3 mr-1 shrink-0"
                      />
                      <span class="overflow-hyphens line-clamp-1">{{ party.entity_name ? party.entity_name : party.name }}</span>
                    </span>
                  </div>
                </div>

                <span
                  v-if="selected"
                  :class="[
                    'ml-4 flex items-center',
                    active ? 'text-white' : 'text-indigo-400',
                  ]"
                >
                  <CheckIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </span>
                <span
                  v-else-if="disabled"
                  :class="[
                    'ml-4 flex items-center',
                  ]"
                >
                  <span class="flex items-center gap-2 font-medium text-[10px] uppercase text-gray-500 bg-gray-200 rounded-md px-1 py-0.5">
                    <LinkIcon
                      class="w-3 h-3 shrink-0"
                      aria-hidden="true"
                    />
                    {{ $t('linkedDocuments.linked') }}
                  </span>
                </span>
              </div>
            </li>
          </ComboboxOption>
          <template v-if="affiliatedPartyDocuments?.length > 0">
            <div class="flex items-center py-1 text-left group px-3 text-slate-500 pt-2 pb-0.5">
              <span class="flex-1 text-[10px] font-medium tracking-wider uppercase text-slate-500">
                {{ $t('linkedDocuments.recommendations') }}</span>
            </div>
            <ComboboxOption
              v-for="(document, documentIdx) in affiliatedPartyDocuments"
              :key="'recommendation_'+documentIdx"
              v-slot="{ active, selected, disabled }"
              :value="document"
              as="template"
              :disabled="deactivatedDocumentUuids.includes(document.uuid)"
            >
              <li
                :class="[
                  static ? 'listbox-option text-gray-700 cursor-pointer' : 'listbox-option',
                  active ? static ? 'bg-gray-100 text-gray-800' : 'bg-gray-700' : '',
                  selected ? static ? 'bg-gray-200 text-gray-900' : 'bg-gray-800' : '',
                  disabled && !selected ? 'opacity-50 cursor-not-allowed' : '',
                ]"
              >
                <div class="flex items-center">
                  <div
                    class="absolute inset-y-0 flex items-center left-2.5"
                  >
                    <component
                      :is="getDocumentTypeInfo(document?.document_type || DocumentType.other)?.icon"
                      aria-hidden="true"
                      class="w-6 h-6"
                    />
                    <div
                      v-if="document?.origin === 'pdf'"
                      class="w-2.5 h-2.5 absolute left-0 bottom-2.5 text-red-500"
                      :class="static ? 'bg-white' : 'bg-gray-800'"
                    >
                      <PDFIcon
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      v-if="document?.origin === 'signed_pdf'"
                      class="w-2.5 h-2.5 absolute left-0 bottom-2.5 text-gray-500"
                      :class="static ? 'bg-white' : 'bg-gray-800'"
                    >
                      <ImportFileIcon
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div
                    :class="[
                      'ml-7 truncate',
                      selected && 'text-indigo-400',
                    ]"
                  >
                    <div class="text-xs font-medium line-clamp-2">
                      {{ document?.name }}
                    </div>

                    <div
                      v-if="document?.parties?.length && document?.parties.length < 5"
                      class="flex items-center max-w-full min-w-0 gap-2 mt-0.5"
                    >
                      <span
                        v-for="(party, partyIdx) in document?.parties"
                        :key="partyIdx"
                        class="flex items-center text-xs"
                        :title="party.entity_name ? party.entity_name : party.name"
                      >
                        <PartyEntityTypeDisplayIcon
                          :party="party"
                          class="w-3 h-3 mr-1 shrink-0"
                        />
                        <span class="overflow-hyphens line-clamp-1">{{ party.entity_name ? party.entity_name : party.name }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  v-if="disabled"
                  :class="[
                    'absolute flex items-center inset-y-0 right-0 pr-4',
                  ]"
                >
                  <span class="flex items-center gap-2 font-medium text-[10px] uppercase text-gray-500 bg-gray-200 rounded-md px-1 py-0.5">
                    <LinkIcon
                      class="w-3 h-3 shrink-0"
                      aria-hidden="true"
                    />
                    {{ $t('linkedDocuments.linked') }}
                  </span>
                </span>
              </li>
            </ComboboxOption>
          </template>
        </template>
      </template>
      <div
        v-else
        class="flex items-center listbox-option"
        :class="props.static ? 'text-gray-300' : ''"
      >
        {{ query ? $t('linkedDocuments.noResults') : $t('linkedDocuments.noRecommendations') }}.
      </div>
    </comboboxoptions>
  </Combobox>
</template>
