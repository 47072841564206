<template>
  <svg
    class="block w-auto"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 612 270"
    enable-background="new 0 0 612 270"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M290.1,188h-26.6v-74.5h-10.7V91.1h10.7v-5.4c0-26,12.8-38.1,39.4-37.3v24.5c-9.2,1.1-12.8,4.6-12.8,12.8
v5.4h3.9l8.9,22.4h-12.8V188L290.1,188z"
      />
      <path
        fill="currentColor"
        d="M371.6,91.1h28.3l-54.6,141.1h-28.5l18.2-46.5l-34-94.6h28.3l19.7,57.6L371.6,91.1z"
      />
      <path
        fill="currentColor"
        d="M429,91.1V103c7.1-11.1,14.2-15.3,26.4-15.3c9.2,0,18,3.3,23.9,9c6.3,6.3,8.6,14,8.6,29.9V188h-26.6v-60.1
c0-5.4-0.6-8.6-2.3-11.3c-2.5-4-7.5-6.5-12.8-6.5c-10.3,0-17.2,7.8-17.2,19V188h-26.6V91.1H429z"
      />
      <path
        fill="currentColor"
        d="M522.2,188h-26.6V48.4h26.6v80l27-37.3H580l-32.9,46.5L580,188h-30.8l-27-40.2V188L522.2,188z"
      />
    </g>
    <path
      fill="currentColor"
      d="M143.2,101.1"
    />
    <path
      fill="currentColor"
      d="M92,173.4l15.2-15.8c0.2-0.2,0.3-0.5,0.3-0.8V105
c0-0.2,0-0.3-0.1-0.5l-33-64c-0.6-1.1-2.2-0.6-2,0.6l17.7,131.7C90.2,173.7,91.3,174.1,92,173.4z"
    />
    <path
      fill="currentColor"
      d="M105.8,166.6l-71.1,71.9c-0.9,0.9,0.2,2.3,1.3,1.7
l73.1-41.2c0.4-0.2,0.6-0.6,0.6-1l-1.9-30.7C107.6,166.3,106.5,165.9,105.8,166.6z"
    />
    <path
      fill="currentColor"
      d="M114.7,195.3l-6.9-167.5c-0.1-1.2,1.6-1.6,2.1-0.4
l54.2,138.2c0.2,0.5,0,1-0.4,1.3l-47.3,29.3C115.7,196.6,114.7,196.1,114.7,195.3z"
    />
    <path
      fill="currentColor"
      d="M165.1,154.2l8.6-51.7c0.2-1-1-1.7-1.8-1
L152.1,118c-0.3,0.3-0.5,0.7-0.3,1.2l11.2,35.2C163.4,155.5,164.9,155.3,165.1,154.2z"
    />
    <path
      fill="currentColor"
      d="M174.4,136.2l3.5-30.8c0.1-0.9,1.3-1.3,1.9-0.6
l31.9,36.5c0.7,0.8,0,1.9-1,1.8l-35.4-5.8C174.7,137.3,174.3,136.8,174.4,136.2z"
    />
  </svg>
</template>
