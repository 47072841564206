<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { Menu, MenuButton, MenuItems } from "@headlessui/vue"
import { ChevronDownIcon } from "@heroicons/vue/24/solid"
import CloseHelper from "./CloseHelper.vue"

interface Props {
  align?: "left" | "right" | "center" | "bottom" | "full" | "default",
  contentClasses?: string,
  menuClasses?: string,
  width?: "w-fit" | "auto" | "52" | "56" | "64" | "72" | "full" | "",
  theme?: "light" | "dark" | "slate",
  autoClose?: boolean,
  overrideClose?: boolean,
}

const props = withDefaults(
  defineProps<Props>(),
  {
    align: "right",
    contentClasses: "py-1",
    menuClasses: "relative inline-block text-left",
    width: "56",
    theme: "dark",
    autoClose: false,
    overrideClose: false,
  },
)

const widthClass = computed(() => {
  return {
    "w-fit": "w-fit",
    "auto": "w-auto",
    "52": "w-52",
    "56": "w-56",
    "64": "w-64",
    "72": "w-72",
    "full": "w-full",
  }[props.width.toString()]
})

const alignmentClasses = computed(() => {
  switch (props.align) {
    case "left":
      return "origin-top-left left-0"
    case "right":
      return "origin-top-right right-0"
    case "center":
      return "origin-top left-12 right-12"
    case "bottom":
      return "origin-bottom bottom-10 right-0 left-0"
    case "full":
      return "origin-top left-1 right-1"
    case "default":
    default:
      return "origin-top"
  }
})

const closeHelper = ref()

let closeDelay = null
const closeWorkaround = () => {
  closeDelay = setTimeout(() => {
    closeHelper.value?.close()
  }, 100)
}
const clearDelay = () => {
  clearTimeout(closeDelay)
}

defineExpose({ closeWorkaround })
</script>

<template>
  <Menu
    v-slot="{ open, close }"
    as="div"
    :class="[menuClasses]"
    @mouseleave="autoClose ? closeWorkaround() : () => {}"
  >
    <slot
      name="trigger"
      :open="open"
    >
      <MenuButton
        class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
      >
        Options
        <ChevronDownIcon
          class="w-5 h-5 ml-2 -mr-1"
          aria-hidden="true"
        />
      </MenuButton>
    </slot>

    <CloseHelper
      ref="closeHelper"
      :close-function="close"
    />

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div v-show="open || overrideClose">
        <MenuItems
          static
          class="absolute z-10 mt-1 divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          :class="[
            theme === 'light' ? 'bg-indigo-900 text-white divide-indigo-800' : theme === 'slate' ? 'bg-slate-900 border border-slate-700 hover:border-slate-600 shadow-lg' : 'bg-gray-900 text-white divide-gray-800' ,
            widthClass,
            alignmentClasses,
          ]"
          @mouseleave="autoClose ? close() : () => {}"
          @mouseenter="autoClose ? clearDelay() : () => {}"
        >
          <div :class="contentClasses">
            <slot
              name="content"
              :open="open"
            />
          </div>
        </MenuItems>
      </div>
    </transition>
  </Menu>
</template>
