import "@tiptap/extension-text-style"

import { Extension } from "@tiptap/core"

export interface FontSizeOptions {
  types: string[],
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the font size
       */
      setFontSize: (fontSize: number) => ReturnType,
      /**
       * Unset the font size
       */
      unsetFontSize: () => ReturnType,
    }
  }
}

export const FONT_SIZES = [ 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72 ]

export const FontSize = Extension.create<FontSizeOptions>(
  {
    name: "fontSize",

    addOptions () {
      return {
        types: [ "textStyle" ],
      }
    },

    addGlobalAttributes () {
      return [
        {
          types: this.options.types,
          attributes: {
            fontSize: {
              default: null,
              parseHTML: (element) => parseInt(element.style.fontSize?.replace("pt", "")),
              renderHTML: (attributes) => {
                if (!attributes.fontSize) return {}

                return {
                  style: `font-size: ${attributes.fontSize}pt`,
                }
              },
            },
          },
        },
      ]
    },

    addCommands () {
      return {
        setFontSize: (fontSize) => ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontSize })
            .run()
        },

        unsetFontSize: () => ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontSize: null })
            .removeEmptyTextStyle()
            .run()
        },
      }
    },
  },
)
