<script setup lang="ts">
// external
import { CloudArrowUpIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"

// internal
import { useDocumentStore, useEditorStore } from "~/stores"
import { DocumentContentType, DocumentOrigin } from "~/types"
import { storeToRefs } from "pinia"
import { PdfImportModal } from "~/components"
import { WordImportModal } from "~/editor"

interface Props {
  disabled?: boolean
}

withDefaults(
  defineProps<Props>(),
  {
    disabled: false,
  },
)

const documentStore = useDocumentStore()
const { currentDocument } = storeToRefs(documentStore)
const { setIsPdfImportModalOpen } = documentStore

const editorStore = useEditorStore()
const { setIsDocxImportModalOpen } = editorStore

const isPdf = computed(() => [ DocumentOrigin.pdf, DocumentOrigin.signed_pdf ].includes(currentDocument.value?.origin) || [ DocumentContentType.pdf ].includes(currentDocument.value?.content_type))

const openRevisionUpload = () => {
  if (isPdf.value) {
    setIsPdfImportModalOpen(true)
  } else {
    setIsDocxImportModalOpen(true)
  }
}

</script>

<template>
  <button
    id="revisionUploadButton"
    type="button"
    :disabled="disabled"
    class="flex items-center gap-2 text-gray-400 btn-plain btn-sm hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-200"
    @click="openRevisionUpload"
  >
    <CloudArrowUpIcon
      class="w-4 h-4 shrink-0"
      aria-hidden="true"
    />
    {{ $t('documents.uploadRevision') }}
  </button>
  <WordImportModal />
  <PdfImportModal />
</template>
