<script setup lang="ts">
// external
import { ref, watch, computed } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { TrashIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"

// internal
import { DialogModal, SpinLoader, MetadataValueInput, InfoBox } from "~/components"
import { Metadata, MetadataType } from "~/types"
import { sortByMetadataName, getMetadataDisplayName } from "~/utils"

interface Props {
  show: boolean
  loading?: boolean
  metadata?: Metadata[],
  editMetadataData?: any
  callback: (data) => void
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    editMetadataData: null,
    teams: () => [],
  },
)

const selectedMetadata = computed({
  get: () => null,
  set: (metadata) => {
    selectedMetadataValues.value.push({
      metadata,
      value: null,
    })
  },
})
const selectedMetadataValues = ref([])

const { t } = useI18n()

const options = computed(() => {
  if (!props.metadata?.length) return []

  return props.metadata.filter((entry) => ![ MetadataType.system_autofilled ].includes(entry.type))
})

const remainingOptions = computed(() => {
  if (!options.value.length) return []

  let metadata = [ ...options.value ]
  if (selectedMetadataValues.value.length) {
    const selectedMetadataUuids = selectedMetadataValues.value.map((entry) => entry.metadata.uuid)
    metadata = metadata.filter((entry) => {
      return !selectedMetadataUuids.includes(entry.uuid)
    })
  }
  return sortByMetadataName(metadata, t)
})

const payload = computed(() => {
  if (!selectedMetadataValues.value?.length) return null

  return selectedMetadataValues.value.map((entry) => {
    return {
      metadata_uuid: entry.metadata.uuid,
      value: entry.value,
    }
  })
})

defineEmits([ "update:show" ])

watch(() => props.show, (newVal) => {
  if (!newVal) {
    selectedMetadataValues.value = []
  }
})

watch(() => props.editMetadataData, (newVal) => {
  if (!newVal) {
    selectedMetadataValues.value = []
  } else {
    const metadata = props.metadata.find((entry) => entry.name === newVal.name)
    selectedMetadataValues.value.push({
      metadata,
      value: newVal.value,
    })
  }
})

const removeMetadata = (name:Metadata["name"]) => {
  const idx = selectedMetadataValues.value.findIndex((entry) => entry.metadata.name === name)

  if (idx !== - 1) {
    selectedMetadataValues.value.splice(idx, 1)
  }
}

const isDisabled = computed(() => {
  if (!props.editMetadataData) return false

  const metadata = props.metadata.find((entry) => entry.name === props.editMetadataData.name)
  return [ MetadataType.system_autofilled, MetadataType.system_computed ].includes(metadata.type)
})

</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="lg"
    :show-close-button="true"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <span>{{ $t('metadata.manageMetadata') }}</span>
    </template>
    <template #content>
      <Listbox
        v-if="!editMetadataData"
        v-model="selectedMetadata"
        :disabled="!remainingOptions?.length"
        as="div"
      >
        <div class="relative mt-1">
          <ListboxButton class="btn-listbox-plain">
            <span
              class="block text-gray-500 truncate"
            >{{ $t('metadata.selectMetadata') }}…</span>

            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="listbox-options">
              <ListboxOption
                v-for="entry in remainingOptions"
                :key="'metadata_' + entry.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="entry"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <div class="flex items-center">
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ getMetadataDisplayName(entry, $t) }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <InfoBox
        v-if="isDisabled"
      >
        {{ $t('metadata.inlineDisabledInfo') }}
      </InfoBox>
      <div
        v-else
        class="space-y-3"
        :class="{'mt-5' : !editMetadataData}"
      >
        <div
          v-for="entry in selectedMetadataValues"
          :key="entry.metadata.name"
          class="flex items-end justify-between space-x-3"
        >
          <MetadataValueInput
            :metadata="entry.metadata"
            :proposed-value="entry.value"
            :use-padding="false"
            :disabled="isDisabled"
            class="grow"
            @update:metadata-value="entry.value = $event"
          />
          <button
            v-if="!editMetadataData"
            type="button"
            class="mb-0.5 w-7 h-7 rounded-full hover:bg-red-100 hover:text-red-600 flex items-center justify-center"
            @click="removeMetadata(entry.metadata.name)"
          >
            <TrashIcon
              aria-hiden="true"
              class="w-4 h-4"
            />
            <span class="sr-only">{{ $t('common.remove') }}</span>
          </button>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="$emit('update:show', false)"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          type="button"
          class="flex items-center gap-2 btn-primary"
          :disabled="loading || isDisabled || !selectedMetadataValues?.length"
          @click.prevent="callback(payload)"
        >
          <SpinLoader
            v-if="loading"
            class="w-5 h-5 shrink-0"
          />
          <span>{{ $t('common.confirm') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
