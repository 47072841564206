<script setup lang="ts">
// external
import axios from "axios"
import { storeToRefs } from "pinia"
import { onBeforeMount, ref, watch, computed, onBeforeUnmount } from "vue"
import { isEqual } from "lodash-es"
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline"

// internal
import { LoadingPlaceholder } from "~/components"
import { useNotificationStore, useDashboardStore } from "~/stores"
import { emptyFilter } from "~/stores/dashboardStore"
import { NumberWidgetData, DocumentStageStat, ChartSeries, DashboardGridItem } from "~/types"

interface Props {
  title?: string,
  boxClasses?: string
  endpoint?: string
  href?: string
  extraParams?: any
  loading?: boolean
  editMode?: boolean
  item?: DashboardGridItem
  preventTotalLoading?: boolean
}
const props = withDefaults(
  defineProps<Props>(),
  {
    title: null,
    boxClasses: "",
    endpoint: null,
    href: null,
    extraParams: null,
    loading: false,
    editMode: false,
    item: null,
    preventTotalLoading: false,
  },
)

onBeforeMount(() => {
  fetchData()
})

let updateDebouncer = null

const { addToasterNotification } = useNotificationStore()
const dashboardStore = useDashboardStore()
const { dashboards, activeDashboardUuid } = storeToRefs(dashboardStore)

const activeDashboard = computed(() => dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value))
const dashboardFilter = computed(() => activeDashboard.value?.filter || emptyFilter)

const filterParams = computed(() => {
  const tempFilterParams = {}
  for (const filterKey in dashboardFilter.value) {
    if (Array.isArray(dashboardFilter.value[filterKey])) {
      tempFilterParams[filterKey] = dashboardFilter.value[filterKey].length ? dashboardFilter.value[filterKey].join(",") : null
    } else {
      tempFilterParams[filterKey] = dashboardFilter.value[filterKey]
    }
  }

  tempFilterParams["scope"] = activeDashboard.value.scope
  tempFilterParams["dashboardable_uuid"] = activeDashboard.value.dashboardable_uuid

  return tempFilterParams
})

const data = ref(null)
const isLoading = ref(props.loading || (!props.endpoint && !props.href))
const loadingError = ref(null)

const fetchDataAction = async (): Promise<NumberWidgetData | DocumentStageStat[]| ChartSeries[] | void> => {
  let payload = {
    ...filterParams.value,
  }

  if (props.extraParams) {
    payload = {
      ...payload,
      ...props.extraParams,
    }
  }

  const res = await axios.get<NumberWidgetData>(props.endpoint ? route(props.endpoint) : props.href, {
    params: payload,
  })
  return res.data
}

const fetchData = async () => {
  if (!props.endpoint && !props.href) {
    isLoading.value = false
    return
  }

  isLoading.value = true
  try {
    const res = await fetchDataAction()

    if (res) {
      loadingError.value = null
      data.value = res
      emit("loaded", res)
    }

    return res
  } catch (err) {
    emit("error", err)
    console.error(err)
    loadingError.value = err

    addToasterNotification(
      {
        title: "Error fetching data",
        message: err.response?.data?.message || err.message,
        type: "error",
      },
    )
  } finally {
    isLoading.value = false
  }
}

watch(filterParams, (newVal, oldVal) => {
  if (!isEqual(newVal, oldVal)) {
    emit("filter-changed", filterParams)
    clearTimeout(updateDebouncer)
    updateDebouncer = setTimeout(() => {
      fetchData()
    }, 300)
  }
})

watch([ () => props.href, () => props.endpoint ], (newVal, oldVal) => {
  if (newVal !== oldVal) {
    clearTimeout(updateDebouncer)
    updateDebouncer = setTimeout(() => {
      fetchData()
    }, 300)
  }
})

onBeforeUnmount(() => {
  clearTimeout(updateDebouncer)
})

defineExpose({ fetchData })

const emit = defineEmits([ "loaded", "filter-changed", "remove", "error" ])

</script>
<template>
  <div class="w-full h-full max-h-full flex flex-col @container">
    <div
      class="flex w-full items-center justify-between relative z-20"
    >
      <div class="flex items-center min-h-[40px] relative">
        <LoadingPlaceholder
          v-if="loading"
          class="rounded-lg w-32 h-5 shadow relative"
        />
        <slot
          v-else
          name="title"
          :data="data"
          :is-loading="isLoading"
        >
          <span class="dashboard-widget-title">{{ props.title }}</span>
        </slot>
      </div>
      <div class="relative grow pl-3 flex justify-end">
        <slot
          name="options"
          :data="data"
          :is-loading="isLoading"
        />
      </div>
    </div>
    <div
      class="relative content-card grow flex items-center justify-center z-10"
      :class="props.boxClasses"
    >
      <LoadingPlaceholder
        v-if="!preventTotalLoading && (isLoading || loading)"
        class="rounded-lg"
      />
      <div
        v-else-if="loadingError"
        class="flex flex-col items-center justify-center space-y-3"
      >
        <ExclamationCircleIcon
          aria-hidden="true"
          class="w-12 h-12 block text-gray-400"
        />
        <div class="text-sm text-gray-600">
          {{ $t('dashboard.error.text') }}
        </div>
        <button
          type="button"
          class="btn-white btn-sm"
          @click="fetchData"
        >
          {{ $t('dashboard.error.button') }}
        </button>
      </div>
      <slot
        v-else
        name="default"
        :data="data"
        :is-loading="isLoading"
        :filter-params="filterParams"
        :filter="dashboardFilter"
      />
    </div>
    <slot name="footer" />
  </div>
</template>
