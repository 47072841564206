<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref } from "vue"

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  MenuButton,
} from "@headlessui/vue"
import { PlusIcon, TrashIcon } from "@heroicons/vue/24/outline"
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  CheckIcon,
  ChevronDownIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/vue/24/solid"

// internal
import {
  DialogModal,
  Dropdown,
  DropdownLink,
  SpinLoader,
  StageDisplay,
  UserSelect,
  WarningBox,
} from "~/components"
import { useStages } from "~/composables"
import { useCheckpointStore, useSharedStore } from "~/stores"
import { AccountUser, CheckpointStep, CrudContext, Document, DocumentUser, Template } from "~/types"
import { getUserRepresentation } from "~/utils"

interface Props {
  entityUuid: Document["uuid"] | Template["uuid"]
  combinedUsers: (AccountUser|DocumentUser)[]
  show: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    show: false,
  },
)

const { stages } = useStages(null, null, true)

const emit = defineEmits([ "close", "update:checkpoint" ])

const close = () => emit("close")

const checkpointStore = useCheckpointStore()
const {
  activeCheckpoint,
  isCreatingCheckpointStep,
  isCreatingCheckpointStepApprover,
  updatingCheckpointUuid,
  updatingCheckpointStepUuid,
  removingCheckpointStepUuid,
  removingCheckpointStepApproverUuid,
} = storeToRefs(checkpointStore)
const {
  createCheckpointStep,
  createCheckpointStepApprover,
  removeCheckpointStep,
  removeCheckpointStepApprover,
  updateCheckpointStep,
} = checkpointStore

const addApproverTippy = ref(null)
const selectedUser = ref<AccountUser>()

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const handleUpdatePolicy = (checkpointStepUuid, value) => {
  updateCheckpointStep(crudContext.value, props.entityUuid, activeCheckpoint.value.uuid, checkpointStepUuid, { policy: value })
}

const moveCheckpointStep = (checkpointStepUuid: CheckpointStep["uuid"], direction: "up" | "down") => {
  const indexDirection = direction === "up" ? -1 : 1
  const search = activeCheckpoint.value?.checkpoint_steps.findIndex(
    (el) => el.uuid === checkpointStepUuid,
  )
  const step = activeCheckpoint.value?.checkpoint_steps[search]
  let weight, previousWeight
  if (step) {
    weight = step.order
    const previousStep =
      activeCheckpoint.value?.checkpoint_steps[search + indexDirection]
    if (previousStep) {
      previousWeight = previousStep.order
      step.order = previousWeight
      previousStep.order = weight

      updateCheckpointStep(
        CrudContext.template,
        props.entityUuid,
        activeCheckpoint.value.uuid,
        activeCheckpoint.value?.checkpoint_steps[search].uuid,
        {
          order: activeCheckpoint.value?.checkpoint_steps[search].order,
        },
      )
      updateCheckpointStep(
        CrudContext.template,
        props.entityUuid,
        activeCheckpoint.value.uuid,
        activeCheckpoint.value?.checkpoint_steps[search + indexDirection].uuid,
        {
          order: activeCheckpoint.value?.checkpoint_steps[search + indexDirection].order,
        },
      )

      activeCheckpoint.value.checkpoint_steps = activeCheckpoint.value.checkpoint_steps.sort(
        (a, b) => a.order - b.order,
      )
    } else {
      return false
    }
  }
}

const addNewCheckpointApprover = async (checkpointUuid, checkpointStepUuid, userUuid) => {
  await createCheckpointStepApprover(crudContext.value, props.entityUuid, checkpointUuid, checkpointStepUuid, userUuid)
  selectedUser.value = null
  addApproverTippy?.value?.hide()
}

const getStageByName = (stage) => {
  return stages.value.find((el) => el.name === stage)
}

</script>

<template>
  <DialogModal
    :show="show"
    :show-close-button="true"
    max-width="xl"
    @close="close"
  >
    <template #title>
      <div class="flex items-center">
        <span class="mr-1 font-medium">{{ $t('checkpoints.checkpointBeforeReaching') }}</span>
        <StageDisplay
          :stage="getStageByName(activeCheckpoint?.document_stage)"
          label-classes="ml-1 font-medium"
        />
      </div>
    </template>

    <template #content>
      <ul class="relative">
        <li
          v-for="(checkpointStep, checkpointStepIdx) in activeCheckpoint?.checkpoint_steps"
          :key="checkpointStep.uuid"
          class="relative flex flex-col"
        >
          <span
            class="absolute top-4 left-2 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="flex items-center justify-between">
            <div class="shrink-0">
              <span class="inline-block h-3 w-3 ml-0.5 rounded-full bg-gray-200 ring-4 ring-white mr-2" />
            </div>
            <div class="grow">
              <span class="flex items-center gap-2 px-2 text-sm font-semibold uppercase rounded-md">
                {{ $t('checkpoints.step', {number: checkpointStepIdx + 1}) }}
                <SpinLoader
                  v-if="(updatingCheckpointUuid === activeCheckpoint.uuid && updatingCheckpointStepUuid === checkpointStep.uuid) || removingCheckpointStepUuid === checkpointStep.uuid"
                  class="w-4 h-4 text-gray-400"
                />
              </span>
            </div>

            <Listbox
              v-if="activeCheckpoint"
              v-model="activeCheckpoint.checkpoint_steps[checkpointStepIdx].policy"
              as="div"
              @update:model-value="handleUpdatePolicy(checkpointStep.uuid, $event)"
            >
              <div class="relative">
                <ListboxButton
                  class="flex items-center gap-1 text-sm hover:bg-indigo-100 focus:ring-2 focus:ring-indigo-500 focus:outline-none py-0.5 pl-1 pr-6 rounded-md"
                >
                  <span class="flex items-center gap-1">
                    <span class="font-medium text-indigo-700">{{ $t('checkpoints.policy') }}:</span>
                    <span>{{ $t('checkpoints.policies.' + activeCheckpoint.checkpoint_steps[checkpointStepIdx].policy) }}</span>
                  </span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon
                      class="w-3 h-3"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="w-auto listbox-options">
                    <ListboxOption
                      v-slot="{ active, selected }"
                      as="template"
                      value="all"
                    >
                      <li
                        :class="active ? 'bg-gray-700' : ''"
                        class="relative flex items-center gap-2 py-2 pl-3 pr-12 text-sm cursor-default select-none group"
                      >
                        <UserGroupIcon
                          :class="[
                            active
                              ? 'text-white'
                              : 'text-gray-400 group-hover:text-white',
                          ]"
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >{{ $t('checkpoints.policies.all') }}</span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption
                      v-if="checkpointStep.checkpoint_step_approvers?.length > 1"
                      v-slot="{ active, selected }"
                      as="template"
                      value="two"
                    >
                      <li
                        :class="active ? 'bg-gray-700' : ''"
                        class="relative flex items-center gap-2 py-2 pl-3 pr-12 text-sm cursor-default select-none group"
                      >
                        <UsersIcon
                          :class="[
                            active
                              ? 'text-white'
                              : 'text-gray-400 group-hover:text-white',
                          ]"
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >{{ $t('checkpoints.policies.two') }}</span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption
                      v-slot="{ active, selected }"
                      as="template"
                      value="one"
                    >
                      <li
                        :class="active ? 'bg-gray-700' : ''"
                        class="relative flex items-center gap-2 py-2 pl-3 pr-12 text-sm cursor-default select-none group"
                      >
                        <UserIcon
                          :class="[
                            active
                              ? 'text-white'
                              : 'text-gray-400 group-hover:text-white',
                          ]"
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >{{ $t('checkpoints.policies.one') }}</span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          ]"
                        >
                          <CheckIcon
                            class="w-5 h-5 shrink-0"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

            <Dropdown
              v-if="activeCheckpoint?.checkpoint_steps?.length > 1"
              width="56"
              align="right"
              class="ml-2"
            >
              <template #trigger>
                <MenuButton
                  class="flex items-center gap-1 text-sm hover:bg-indigo-100 focus:ring-2 focus:ring-indigo-500 focus:outline-none py-0.5 px-1 rounded-md"
                >
                  <span class="flex items-center gap-1">
                    <span class="font-medium text-indigo-700">{{ $t('magicTable.columns.options') }}</span>
                  </span>
                  <ChevronDownIcon
                    class="w-3 h-3"
                    aria-hidden="true"
                  />
                </MenuButton>
              </template>

              <template #content>
                <DropdownLink
                  v-if="activeCheckpoint?.checkpoint_steps?.length > 1"
                  as="button"
                  :icon="true"
                  @click="moveCheckpointStep(checkpointStep.uuid, 'up')"
                >
                  <ArrowSmallUpIcon
                    class="w-4 h-4 mr-2 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ $t('checkpoints.moveUp') }}
                </DropdownLink>
                <DropdownLink
                  v-if="activeCheckpoint?.checkpoint_steps?.length > 1"
                  as="button"
                  :icon="true"
                  @click="moveCheckpointStep(checkpointStep.uuid, 'down')"
                >
                  <ArrowSmallDownIcon
                    class="w-4 h-4 mr-2 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ $t('checkpoints.moveDown') }}
                </DropdownLink>
                <DropdownLink
                  v-if="activeCheckpoint?.checkpoint_steps?.length > 1"
                  as="button"
                  :icon="true"
                  @click="removeCheckpointStep(CrudContext.template, props.entityUuid, activeCheckpoint.uuid, checkpointStep.uuid)"
                >
                  <TrashIcon
                    class="w-4 h-4 mr-2 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ $t('checkpoints.removeStep') }}
                </DropdownLink>
              </template>
            </dropdown>
          </div>
          <div class="pl-6 mt-1 mb-4">
            <div class="flow-root bg-gray-100 rounded-md">
              <ul
                v-if="
                  checkpointStep.checkpoint_step_approvers &&
                    checkpointStep.checkpoint_step_approvers.length
                "
                role="list"
                class="divide-y divide-gray-200"
              >
                <li
                  v-for="user in checkpointStep.checkpoint_step_approvers"
                  :key="user.uuid"
                  class="px-4 py-2"
                >
                  <div class="flex items-center space-x-4">
                    <div class="shrink-0">
                      <img
                        class="w-5 h-5 rounded-full"
                        :src="user.account_user?.profile_photo_url"
                        alt=""
                      >
                    </div>
                    <div class="flex-1 min-w-0">
                      <p class="text-sm font-medium text-gray-900 truncate">
                        {{ getUserRepresentation(user.account_user) }}
                      </p>
                    </div>
                    <div>
                      <div
                        v-if="removingCheckpointStepApproverUuid === user.uuid"
                        class="p-1"
                      >
                        <SpinLoader
                          class="w-4 h-4 text-gray-400"
                        />
                      </div>
                      <button
                        v-else
                        type="button"
                        class="inline-flex items-center p-1 text-sm text-gray-400 rounded-full hover:bg-red-200 hover:text-red-700"
                        @click.prevent="
                          removeCheckpointStepApprover(CrudContext.template, props.entityUuid, activeCheckpoint.uuid, checkpointStep.uuid, user.uuid)
                        "
                      >
                        <TrashIcon
                          class="w-3.5 h-3.5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                v-else
                class="px-4 py-2 text-sm text-gray-500"
              >
                <WarningBox :text="$t('checkpoints.warning')" />
              </div>
              <div class="flex items-center w-full px-4 py-2 border-t border-t-gray-200">
                <UserSelect
                  :auto-select="true"
                  :placeholder="$t('checkpoints.addApprover') + '…'"
                  :users="props.combinedUsers"
                  :already-selected-users="checkpointStep.checkpoint_step_approvers || []"
                  :loading="isCreatingCheckpointStepApprover"
                  @select="($event) => addNewCheckpointApprover(activeCheckpoint.uuid, checkpointStep.uuid, $event.uuid)"
                />
              </div>
            </div>

            <div class="flex justify-start pl-1">
              <button
                v-if="checkpointStepIdx + 1 === activeCheckpoint.checkpoint_steps.length"
                :disabled="isCreatingCheckpointStep"
                type="button"
                class="inline-flex justify-center px-2 py-1 mt-2 text-sm font-medium text-indigo-700 rounded hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:pointer-events-none disabled:opacity-50"
                @click.prevent="createCheckpointStep(crudContext, props.entityUuid, activeCheckpoint.uuid)"
              >
                <span
                  v-if="!isCreatingCheckpointStep"
                  class="flex items-center gap-2"
                >
                  <PlusIcon class="w-5 h-5 shrink-0" />
                  {{ $t('checkpoints.addNewStep') }}
                </span>
                <span
                  v-else
                  class="flex items-center gap-2"
                >
                  <SpinLoader class="w-5 h-5 shrink-0" />
                  {{ $t('common.adding') }}…
                </span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </template>

    <template #footer>
      <div class="flex items-center justify-between">
        <slot name="removeButton" />
        <button
          type="button"
          class="px-3 py-2 text-sm font-medium text-gray-700 transition bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 active:text-gray-800 active:bg-gray-50 disabled:opacity-25"
          @click.prevent="close"
        >
          {{ $t('common.close') }}
        </button>
      </div>
    </template>
  </DialogModal>
</template>
