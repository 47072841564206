<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { onMounted } from "vue"

import { IdentificationIcon } from "@heroicons/vue/24/outline"
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/solid"

// internal
import { EmptyState, OverlayScrollbar, PartyPopover, SkeletonLoader, UserList, SignatoryPopover } from "~/components"
import { useDocumentStore, usePartyStore, useSharedStore, useSignatureStore, useTemplateStore, useUserStore } from "~/stores"
import { CrudContext, Party, UiUser } from "~/types"
import { useTippies } from "~/composables"

interface Props {
  combinedUsers: UiUser[]
  isConfirmModal?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isConfirmModal: false,
  },
)

const documentStore = useDocumentStore()
const { currentDocument, mau, isLockedDocument, mdu } = storeToRefs(documentStore)

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const userStore = useUserStore()
const { setAddUserPartyUuid } = userStore
const {
  users,
  isLoadingUsers,
  addUserPartyUuid,
} = storeToRefs(userStore)

const signatureStore = useSignatureStore()
const { signatories } = storeToRefs(signatureStore)

const partyStore = usePartyStore()
const {
  parties,
  accountParties,
} = storeToRefs(partyStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

interface addNewUserParams {
  scope: "external" | "internal"
  partyUuid: Party["uuid"]
}

const addNewUser = (params: addNewUserParams) => {
  setAddUserPartyUuid(params.partyUuid)
}

const { setUpAddPartyTippies } = useTippies(crudContext.value)

onMounted(() => {
  if (!props.isConfirmModal) setUpAddPartyTippies()
})

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div
      :class="!props.isConfirmModal ? 'border-b border-b-gray-200' : ''"
      class="px-6 shrink-0"
    >
      <h3
        v-if="!isConfirmModal"
        class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase"
      >
        {{ $t('userSettings.title') }}
        <span
          data-tippy-help
          :data-tippy-content="$t('userSettings.hint')"
          data-placement="bottom"
        >
          <QuestionMarkCircleIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </h3>
    </div>
    <div
      v-if="isLoadingUsers"
      class="px-6"
    >
      <SkeletonLoader
        size="large"
      />
    </div>

    <template v-else>
      <OverlayScrollbar
        ref="partiesAndSignatoriesScrollContainer"
        tag="div"
        class="flex-1 overflow-y-auto max-h-max"
      >
        <UserList
          v-if="parties?.length || signatories?.length"
          :is-confirm-modal="isConfirmModal"
          @add-user="addNewUser($event)"
        />

        <EmptyState
          v-else
          :hide-button="true"
          class="my-6"
        >
          <template #icon>
            <IdentificationIcon
              aria-hidden="true"
            />
          </template>
          {{ $t('userSettings.empty') }}
        </EmptyState>

        <div
          v-if="!!mau && !currentDocument?.archived_at && !(parties?.length || signatories?.length) && !isConfirmModal && (crudContext === CrudContext.template || mdu?.permissions?.includes('party_create'))"
          class="mb-6 -mt-9"
        >
          <button
            type="button"
            class="add-party-button btn-plain mx-auto btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5"
            data-placement="left"
            data-template="addPartyForm"
          >
            <PlusIcon class="shrink-0 h-3.5 w-3.5" />
            {{ $t('userSettings.addParty') }}
          </button>
        </div>
      </OverlayScrollbar>
    </template>


    <div
      v-show="!isLockedDocument && (parties?.length || signatories?.length)"
      v-if="!!mau && !currentDocument?.archived_at && !isConfirmModal && (crudContext === CrudContext.template || mdu?.permissions?.includes('party_create'))"
      class="px-6 py-4 border-t border-gray-200 shrink-0"
    >
      <button
        :data-cy-sel="'add-party-button'"
        data-template="addPartyForm"
        data-placement="left"
        class="inline-flex items-center btn-primary add-party-button"
      >
        <span>{{ $t('userSettings.addParty') }}</span>
      </button>
    </div>

    <div
      v-if="!isConfirmModal"
      id="partyAndSignatoryPopovers"
      class="hidden"
    >
      <template
        v-for="user in users"
        :key="user.uuid"
      >
        <SignatoryPopover
          :user="user"
          :document="currentDocument"
          :template="currentTemplate"
          :hide-party-settings="true"
        />
      </template>
      <template
        v-for="party in parties"
        :key="party.uuid"
      >
        <PartyPopover
          :combined-users="combinedUsers"
          :document="currentDocument"
          :template="currentTemplate"
          :party="party"
          :document-users="users"
          :account-parties="accountParties"
        />
      </template>
      <SignatoryPopover
        :party-uuid="addUserPartyUuid"
        :document="currentDocument"
        :template="currentTemplate"
        :scope="!!mau ? 'internal' : null"
      />
      <PartyPopover
        :combined-users="combinedUsers"
        :document="currentDocument"
        :template="currentTemplate"
        :document-users="users"
        :account-parties="accountParties"
      />
    </div>
  </div>
</template>
