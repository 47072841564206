<script setup lang="ts">

</script>
<template>
  <div>
    <div class="mb-5">
      {{ $t('lexica.content.metadata.intro') }}
    </div>
  </div>
</template>
