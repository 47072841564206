<script setup lang="ts">

// internal
import { Stage } from "~/types"

interface Props {
  stages: Stage[]
  selectedStages?: string[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedStages: () => [],
  },
)

const emit = defineEmits([ "update:selected-stages" ])

const selectHandler = (stageName: Stage["name"]) => {
  let stages = [ ...props.selectedStages ]
  if (stages.includes(stageName)) {
    stages = stages.filter((stage) => stage !== stageName)
  } else {
    stages.push(stageName)
  }
  emit("update:selected-stages", stages)
}

</script>

<template>
  <ul class="space-y-1">
    <li
      v-for="stage in props.stages"
      :key="stage.name"
    >
      <label class="flex items-center space-x-2">
        <input
          type="checkbox"
          :name="'stage-filter-' + stage.name"
          class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          :checked="selectedStages.includes(stage.name)"
          @change="selectHandler(stage.name)"
        >
        <span class="text-sm text-gray-600">{{ $t(`documents.stages.${stage?.name}`) }}</span>
      </label>
    </li>
  </ul>
</template>
