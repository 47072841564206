<script setup lang="ts">
// external
import { computed } from "vue"
import { Field } from "vee-validate"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { storeToRefs } from "pinia"

// internal
import { DocumentNotification, DocumentNotificationType, NotificationType } from "~/types"
import { useMetadataStore, useAccountStore } from "~/stores"
import { formatDateLocalized } from "~/utils"

interface Props {
  notificationKey: DocumentNotification["key"]
  triggerType?: DocumentNotificationType
  hasError?: boolean
  selectable?: boolean
  availableTriggers: DocumentNotification["key"][]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    hasError: false,
    selectable: false,
    triggerType: null,
  },
)

const localKey = computed({
  get: () => props.notificationKey,
  set: (key) => emits("update:notification-key", key),
})

const emits = defineEmits([ "update:notification-key" ])

const { metadataValues } = storeToRefs(useMetadataStore())
const { mau } = storeToRefs(useAccountStore())

const relevantMetadataName = computed(() => {
  if (props.notificationKey === NotificationType.DOCUMENT_EFFECTIVE_REMINDER) {
    return "duration_start_date"
  }
  if (props.notificationKey === NotificationType.DOCUMENT_EXPIRING_REMINDER) {
    return "expiration_date"
  }
  if (props.notificationKey === NotificationType.DOCUMENT_CANCELLATION_NOTICE_PERIOD_REMINDER) {
    return "notice_date"
  }
  if (props.notificationKey === NotificationType.DOCUMENT_RENEWAL_REMINDER) {
    return "renewal_date"
  }
  return null
})

const relevantMetadataValue = computed(() => {
  if (relevantMetadataName.value) {
    return metadataValues.value.find((metadataValue) => metadataValue.metadata.name === relevantMetadataName.value)
  }
  return null
})

</script>

<template>
  <fieldset
    class="relative"
  >
    <h3 class="relative flex items-center w-full h-px px-4 mt-4 mb-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-white">
      <span class="px-2 py-0.5 text-indigo-500 bg-white">{{ $t('documentNotifications.trigger') }}</span>
    </h3>
    <div class="px-6 py-2">
      <Field
        v-if="selectable"
        v-slot="{ field }"
        v-model="localKey"
        name="key"
        rules="required"
      >
        <Listbox
          v-bind="field"
          v-model="localKey"
          as="div"
          class="relative"
        >
          <ListboxButton
            class="btn-listbox-plain"
            :class="{'error': hasError}"
          >
            <div
              v-if="localKey"
              class="flex items-center"
            >
              <span class="block truncate">
                {{ $t('documentNotifications.triggers.' + localKey) }}
              </span>
            </div>
            <div
              v-else
              class="text-gray-500"
            >
              <span class="block truncate">
                {{ $t('documentNotifications.selectTrigger') }}…
              </span>
            </div>
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="z-20 listbox-options">
              <ListboxOption
                v-for="trigger in availableTriggers"
                :key="trigger"
                v-slot="{ active, selected }"
                as="template"
                :value="trigger"
              >
                <li
                  :class="[
                    active ? 'bg-gray-700' : '',
                    'listbox-option',
                  ]"
                >
                  <div class="flex items-center">
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ $t('documentNotifications.triggers.' + trigger) }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </Listbox>
      </Field>
      <div
        v-else
        class="text-sm"
      >
        <div class="text-xs text-gray-500">
          {{ $t('documentNotifications.types.' + triggerType) }}
        </div>
        <div class="mt-1 font-medium">
          {{ $t('documentNotifications.triggers.' + notificationKey) }}
        </div>
        <div
          v-if="relevantMetadataValue?.value"
          class="mt-3 text-xs text-gray-500"
        >
          {{ $t('documentNotifications.dateSetTo', { date: $t(`metadata.system.${relevantMetadataName}.name`)}) }}: {{ formatDateLocalized(relevantMetadataValue.value, mau?.user?.locale) }}
        </div>
      </div>
    </div>
  </fieldset>
</template>
