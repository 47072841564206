<script setup lang="ts">
// external
import { ref, computed, onBeforeMount, watch, onMounted, onBeforeUnmount } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { XCircleIcon } from "@heroicons/vue/24/solid"
import axios from "axios"
import { storeToRefs } from "pinia"

// internal
import { SortIndicator, Pagination, LoadingPlaceholder } from "~/components"
import { MagicTableAllState, MagicTableColumn, Metadata, Pagination as PaginationType } from "~/types"
import MagicTableConfigurator from "./MagicTableConfigurator.vue"
import { useAccountStore } from "~/stores"
import { getMetadataDisplayNameByKey, isScrollableX } from "~/utils"


interface Props {
  storageKey?: string
  paginator?: PaginationType<any>
  items?: any[]
  columns: MagicTableColumn[]
  tableName?: string
  loading?: boolean
  hasFilters?: boolean
  sortAttribute?: string | null
  sortAttributes?: string[]
  sortDirection?: string | null
  filterData?: any
  allState?: MagicTableAllState
  selectedUuids?: number[]
  paginationProp?: string[]
  hidePerPage?: boolean
  tableClasses?: string
  outerClasses?: string
  useButtonPagination?: boolean
  metadata?: Metadata[]
  allowOverflow?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    sortAttribute: null,
    sortDirection: null,
    paginator: null,
    items: null,
    filterData () {
      return {}
    },
    selectedUuids () {
      return []
    },
    sortAttributes () {
      return []
    },
    hidePerPage: false,
    paginationProp () {
      return [ "pagination" ]
    },
    tableClasses: "",
    outerClasses: "",
    useButtonPagination: false,
    metadata () {
      return []
    },
    allowOverflow: false,
  },
)

const localColumns = ref([
  ...props.columns,
])

const { userCustomizations, mau } = storeToRefs(useAccountStore())

const emit = defineEmits([
  "update:all-state",
  "reset-filter",
  "entry-click",
  "update:sort-attribute",
  "update:sort-attributes",
  "update:sort-direction",
  "update:per-page",
  "reset-sorting",
  "switch-page",
  "clear-selection",
])

const activeColumns = computed(() => localColumns.value.filter((column) => column.active))
const configurableColumns = computed(() => localColumns.value.filter((column) => !column.required))

const activeColumnCount = computed(() => {
  return activeColumns.value.length
})
const entries = computed(() => {
  return props.paginator?.data || props.items || []
})

/* const entryClickHandler = (data) => {
  emit("entry-click", data)
} */
const changeSortAttribute = (attribute) => {
  emit("update:sort-attribute", attribute)
}
const changeSortDirection = (direction) => {
  emit("update:sort-direction", direction)
}
const changePerPage = (option) => {
  emit("update:per-page", option)
}
const resetSorting = () => {
  emit("reset-sorting")
}
const toggleColumn = (key) => {
  const columnIndex = localColumns.value.findIndex((column) => column.key === key)

  if (columnIndex !== -1) {
    storeConfig()
  }
}

let configDebouncer = null
const storeConfig = () => {
  if (props.storageKey && !!mau.value) {
    clearTimeout(configDebouncer)
    configDebouncer = setTimeout(() => {
      const activeInformation = localColumns.value.map((column) => {
        let columnData:MagicTableColumn = {
          key: column.key,
          active: column.active,
        }

        if (column.isMetadata) {
          columnData = {
            ...columnData,
            isMetadata: true,
            breakpoint: column.breakpoint,
          }
        }
        return columnData
      })

      const customizations = userCustomizations.value || {}

      if (!customizations.tables) {
        customizations.tables = {}
      }

      customizations.tables = {
        ...customizations.tables,
        [props.storageKey]: activeInformation,
      }

      axios.patch(route("api.account-users.update", mau.value.uuid), {
        customizations: customizations,
      })
    }, 200)
  }
}

const getConfig = () => {
  if (props.storageKey) {
    if (userCustomizations.value?.tables && userCustomizations.value?.tables[props.storageKey]) {
      return userCustomizations.value?.tables[props.storageKey]
    }
    return null
  }
}

const mergeConfigs = () => {
  const storedConfig = getConfig()

  if (storedConfig) {
    const needsUpdateAfterMerge = false
    localColumns.value = [ ...storedConfig ]

    for (let i = 0; i < localColumns.value.length; i++) {
      const originalColumn = props.columns.find((column) => column.key === localColumns.value[i].key)
      if (originalColumn) {
        localColumns.value[i] = {
          ...originalColumn,
          ...localColumns.value[i],
        }
      }
      if (localColumns.value[i].isMetadata) {
        localColumns.value[i].breakpoint = "@5xl"

        const referencedMetadata = props.metadata.find((entry) => entry.name === localColumns.value[i].key)

        if (referencedMetadata) {
          localColumns.value[i].sortAttribute = referencedMetadata.name
        }
      }
    }

    localColumns.value = localColumns.value.filter((column) => !column.needsRemoval)

    // store fixed config
    if (needsUpdateAfterMerge) {
      storeConfig()
    }
  }
}

onBeforeMount(() => {
  mergeConfigs()
})

watch(() => props.columns, (newVal) => {
  localColumns.value = newVal
})

watch(activeColumns, (newVal) => {
  const sortableMetadataColumnKeys = newVal.filter((column) => column.isMetadata).map((column) => column.key)

  if (sortableMetadataColumnKeys.length) {
    const uniqueEntries = Array.from(new Set([
      ...props.sortAttributes,
      ...sortableMetadataColumnKeys,
    ]))
    emit("update:sort-attributes", uniqueEntries)
  }
})

const getVisibilityClassesForColumn = (column) => {
  if (props.allowOverflow) return ""
  if (!column.breakpoint) {
    return ""
  }
  return `hidden ${column.breakpoint}:table-cell`
}

/* SCROLL HANDLING */
const wrapper = ref()
let resizeDecouncer = null
let scrollDecouncer = null

const initialDirection = ref(props.sortDirection)
onMounted(() => {
  window.addEventListener("resize", handleResize)
  wrapper.value.addEventListener("scroll", handleScroll)
  handleResize()
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize)
  if (wrapper.value) {
    wrapper.value.removeEventListener("scroll", handleScroll)
  }
})

const hasScrollbar = ref(false)
const scrollLeft = ref(0)
const isVeryLeft = ref(true)
const isVeryRight = ref(false)

const handleResize = () => {
  clearTimeout(resizeDecouncer)
  resizeDecouncer = setTimeout(() => {
    if (wrapper.value) {
      hasScrollbar.value = isScrollableX(wrapper.value)
    }
  }, 100)
}
const handleScroll = () => {
  clearTimeout(scrollDecouncer)
  scrollDecouncer = setTimeout(() => {
    if (wrapper.value) {
      scrollLeft.value = wrapper.value.scrollLeft
      isVeryLeft.value = scrollLeft.value === 0
      isVeryRight.value = Math.ceil(scrollLeft.value) === Math.ceil(wrapper.value.scrollWidth - wrapper.value.offsetWidth)
    }
  }, 100)
}

const selectedAllState = computed({
  get: () => props.allState,
  set: (allState) => emit("update:all-state", allState),
})
</script>

<template>
  <div
    class="flex-1 grow flex flex-col justify-between align-middle min-w-full @container"
  >
    <div
      class="flex flex-col justify-between flex-1 content-card"
      :class="outerClasses"
    >
      <div
        ref="wrapper"
        class="flex flex-col w-full h-full grow"
        :class="[
          {
            'overflow-auto min-h-[390px] relative' : allowOverflow,
            'overflow-y-hidden': selectedUuids.length,
            'has-scrollbar': hasScrollbar,
            'no-scrollbar': !hasScrollbar,
            'is-very-left': isVeryLeft,
            'is-very-right': isVeryRight,
          },
        ]"
      >
        <table
          class="min-w-full w-fit"
          :class="[tableClasses, !entries || !entries.length ? 'grow' : '']"
          :data-cy-sel="tableName"
        >
          <thead
            v-if="entries?.length"
            class="relative border-b border-gray-200 rounded-t-md bg-gradient-to-b from-slate-100 to-gray-50"
          >
            <tr>
              <th
                v-for="(column, index) in activeColumns"
                :key="column.key"
                scope="col"
                class="relative py-3 font-medium text-left transition-all duration-300"
                :class="[
                  { 'w-5 z-20' : column.key === 'select'},
                  { 'w-16' : column.key === 'options'},
                  getVisibilityClassesForColumn(column),
                  index === 0 ? 'rounded-tl-md px-4' : index === activeColumns.length - 1 ? 'rounded-tr-md px-2.5' : 'px-2.5',
                  column.extraClasses,
                  selectedUuids.length ? 'pt-[50px]' : 'pt-3',
                  allowOverflow && index === activeColumns.length - 1 ? 'sticky right-0 bg-gradient-to-b from-slate-100 to-gray-50' : '',
                  allowOverflow && index === 0 ? 'sticky left-0 bg-gradient-to-b from-slate-100 to-gray-50 z-50' : '',
                ]"
              >
                <div
                  v-if="column.key === 'select'"
                  class="flex items-center"
                >
                  <div
                    v-if="allowOverflow"
                    class="absolute top-0 bottom-0 w-3 pointer-events-none left-shadow left-full bg-gradient-to-r to-transparent from-gray-500/10"
                  />

                  <div class="absolute top-1 left-2 flex items-center z-50">
                    <Listbox
                      v-model="selectedAllState"
                      as="div"
                    >
                      <div
                        class="relative flex items-center z-60 rounded-md px-2 py-1 my-1"
                        :class="selectedUuids.length ? 'bg-white shadow-sm' : ''"
                      >
                        <ListboxButton
                          class="relative flex items-center"
                        >
                          <div class="absolute inset-0" />
                          <input
                            type="checkbox"
                            :indeterminate="(selectedUuids.length > 0 || allState === MagicTableAllState.PAGE) && allState !== MagicTableAllState.ALL"
                            class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 mr-2"
                            :checked="allState === MagicTableAllState.ALL"
                          >
                          <span
                            v-if="selectedUuids.length"
                            class="whitespace-nowrap text-sm text-gray-600"
                          >{{ $t('magicTable.selected', {number: allState === MagicTableAllState.ALL ? paginator.meta.total : selectedUuids.length}) }}</span>
                        </ListboxButton>
                        <button
                          v-if="selectedUuids.length"
                          class="text-gray-500 ml-2"
                          @click="$emit('clear-selection')"
                        >
                          <XCircleIcon
                            aria-hidden="true"
                            class="w-4 h-4"
                          />
                          <span class="sr-only">{{ $t('common.remove') }}</span>
                        </button>
                      </div>


                      <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <ListboxOptions class="listbox-options left-0 w-64 z-[100]">
                          <ListboxOption
                            v-for="allStateOption in MagicTableAllState"
                            :key="allStateOption"
                            v-slot="{ active, selected }"
                            as="template"
                            :value="allStateOption"
                          >
                            <li :class="[active ? 'bg-gray-700' : '', 'listbox-option pr-3 pl-0']">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                                <input
                                  type="checkbox"
                                  class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 mr-1.5 ml-3"
                                  :checked="selected"
                                >
                                <span>{{ $t('magicTable.allState.' + allStateOption) }}</span>
                                <span class="rounded-full text-xs bg-slate-500 px-1.5 py-0.5 ml-1.5">{{ allStateOption === MagicTableAllState.ALL ? paginator?.meta?.total : paginator?.data?.length }}</span>
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </Listbox>

                    <Transition name="slide-fade-sm">
                      <div
                        v-if="selectedUuids.length"
                        class="ml-2 z-50 transform-gpu flex items-center space-x-3 text-sm text-gray-600"
                      >
                        <slot name="bulk" />
                      </div>
                    </Transition>
                  </div>
                </div>
                <div
                  v-else-if="column.key === 'options'"
                  class="flex items-center justify-center"
                >
                  <div
                    v-if="allowOverflow"
                    class="absolute top-0 bottom-0 w-3 pointer-events-none right-shadow right-full bg-gradient-to-r from-transparent to-gray-500/10"
                  />
                  <MagicTableConfigurator
                    v-if="!!configurableColumns.length"
                    v-model:columns="localColumns"
                    :metadata="metadata"
                    :allow-overflow="allowOverflow"
                    class="-my-3 hidden sm:block ml-0.5"
                    @toggle="toggleColumn"
                    @update:columns="storeConfig"
                  />
                </div>
                <SortIndicator
                  v-else-if="column.sortAttribute"
                  :attribute="column.sortAttribute"
                  :active-attribute="sortAttribute"
                  :direction="sortDirection"
                  :initial-direction="initialDirection"
                  @change:direction="changeSortDirection"
                  @change:attribute="changeSortAttribute"
                  @reset="resetSorting"
                >
                  {{ column.isMetadata ? getMetadataDisplayNameByKey(column.key, metadata, $t) : (column.title || $t(`magicTable.columns.${column.key}`)) }}
                </SortIndicator>
                <span
                  v-else-if="column.isMetadata"
                  class="text-xs block relative truncate @5xl:max-w-[130px] @6xl:max-w-[160px] @9xl:max-w-[220px]"
                  :class="[!getMetadataDisplayNameByKey(column.key, metadata, $t) ? 'text-red-500' : '']"
                  :title="getMetadataDisplayNameByKey(column.key, metadata, $t) || undefined"
                >{{ getMetadataDisplayNameByKey(column.key, metadata, $t) || $t(`magicTable.columns.missingMetadata`) }}</span>
                <span
                  v-else
                  class="flex self-center text-xs whitespace-nowrap"
                  :class="[column.key === 'select' || column.key === 'options' ? 'sr-only' : '']"
                >{{ column.title || $t(`magicTable.columns.${column.key}`) }}</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <template v-if="!entries || !entries.length">
              <tr class="relative">
                <td
                  :colspan="activeColumnCount"
                  class="relative"
                >
                  <LoadingPlaceholder
                    v-if="loading"
                    class="z-0 rounded-md inset-2"
                  />
                  <slot
                    name="empty"
                    :has-filters="hasFilters"
                    :is-loading="loading"
                  />
                </td>
              </tr>
            </template>
            <template
              v-else
            >
              <tr
                v-for="(entry, n) in entries"
                :key="entry.uuid"
                class="relative hover:bg-gray-50 group focus-within:z-30"
                :data-cy-sel="tableName + '_row_' + String(n)"
              >
                <slot
                  :entry="entry"
                  :index="n"
                  :total="entries.length"
                  :active-columns="activeColumns"
                  :is-loading="loading"
                />
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="paginator && paginator.links"
        class="rounded-b-md relative z-20 px-5"
        :pagination="paginator"
        :filter-data="filterData"
        :only="paginationProp"
        :show-per-page="!hidePerPage"
        :use-buttons="useButtonPagination"
        @switch-page="(page) => $emit('switch-page', page)"
        @update:per-page="changePerPage"
      />
    </div>
  </div>
</template>

<style>
  .no-scrollbar .left-shadow,
  .no-scrollbar .right-shadow {
    display: none;
  }
  .is-very-left .left-shadow {
    display: none;
  }
  .is-very-right .right-shadow {
    display: none;
  }
</style>
