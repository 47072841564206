<script setup lang="ts">
// external
import { onMounted, onBeforeUnmount, computed } from "vue"
import { router } from "@inertiajs/vue3"

// internal
import { Account, AccountUser } from "~/types"
import { getUserRepresentation } from "~/utils"
import intercom from "~/integrations/intercom.js?url"
import { loadIntegration } from "~/integrations"

const INTERCOM_GLOBAL_APP_ID = "enba2ezt"
const INTERCOM_GLOBAL_API_BASE = "https://api-iam.eu.intercom.io"

interface Props {
  mau: AccountUser
  intercomUserHash?: string
  account: Account
  userLang: string
  preventBoot?: boolean
  subscriptionType?: string
  subscriptionPlan?: string
}

const props = withDefaults(defineProps<Props>(), {
  mau: null,
  intercomUserHash: null,
  account: null,
  userLang: "en",
  preventBoot: false,
  subscriptionType: null,
  subscriptionPlan: null,
})

declare const window: any

interface CompanyObject {
  company_id: string
  name: string
  created_at?: string
  monthly_spend?: number
  plan?: string
  size?: number
  website?: string
  industry?: string
  custom_attributes?: Record<string, any>
}

interface IntercomParams {
  name: string
  email: string
  user_id: string
  user_hash: string
  created_at: string
  app_id: string
  api_base: string
  avatar?: {
    type: string
    image_url: string
  }
  language_override?: string
  phone?: string
  company?: CompanyObject
  account_user_role?: string
  account_user_locale?: string
}

const initParams = computed(() => {
  if (!props.mau) return null
  // Join props.mau.roles name properties into a string
  const roles = props.mau.roles?.map((role) => role.name)?.join(", ")
  let returnParamsObject: IntercomParams = {
    name: getUserRepresentation(props.mau),
    email: props.mau.email,
    user_id: props.mau.uuid,
    user_hash: props.intercomUserHash,
    created_at: props.mau.created_at,
    app_id: INTERCOM_GLOBAL_APP_ID,
    api_base: INTERCOM_GLOBAL_API_BASE,
    language_override: props.userLang,
    phone: props.mau.mobile_phone,
    account_user_role: roles,
    account_user_locale: props.userLang,
  }
  if (props.mau.profile_photo_url) {
    returnParamsObject = { ...returnParamsObject, avatar: { type: "avatar", image_url: props.mau.profile_photo_url } }
  }
  if (props.account) {
    let companyObject: CompanyObject = {
      company_id: props.account.uuid,
      name: props.account.name,
      created_at: props.account.created_at,
    }
    if (props.subscriptionType) {
      companyObject = {
        ...companyObject,
        plan: props.subscriptionPlan,
        custom_attributes: { plan_type: props.subscriptionType },
      }
    }
    returnParamsObject = { ...returnParamsObject, company: companyObject }
  }
  return returnParamsObject
})


const boot = () => {
  // Check if window.Intercom exists
  if (!!initParams.value && typeof window.Intercom === "function") {
    window.Intercom("boot", initParams.value)
  }
}

onMounted(() => {
  // Boot Intercom
  if (!props.preventBoot) {
    if (!window.Intercom) {
      loadIntegration({
        src: intercom,
        callback: boot,
        blockInternally: false,
      })
    } else {
      boot()
    }
  }
})

onBeforeUnmount(() => {
  if (typeof window.Intercom === "function") {
    // Shutdown Intercom
    window.Intercom("shutdown")
  }
})

// On router-triggered URL changes, send Intercom update call
router.on("navigate", (event: any) => {
  if (!!initParams.value && typeof window.Intercom === "function") {
    const newRoute = event.detail.page.url
    window.Intercom("update", { url: newRoute, last_request_at: (new Date()).getTime() / 1000, ...initParams.value } )
  }
})

defineExpose({ boot })

</script>

<template>
  <slot />
</template>
