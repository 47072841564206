<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import dayjs from "dayjs"
import { ChartBarIcon } from "@heroicons/vue/24/outline"
import { router } from "@inertiajs/vue3"

// internal
import { DashboardGraphWidget, EmptyState } from "~/components"
import { useDashboardStore } from "~/stores"
import { DocumentCalendarEntry, DocumentCalendarEntryType } from "~/types"

const { t } = useI18n()

const dashboardStore = useDashboardStore()
const {
  mau,
} = storeToRefs(dashboardStore)

const locale = computed(() => mau.value.user.locale)

const upcomingWorkload = ref<DocumentCalendarEntry[]>(null)

const series = computed(() => {
  if (!upcomingWorkload.value) return []

  const series = [
    {
      "data": [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
      "name": t("dashboard.upcomingWorkload.renewing"),
    },
    {
      "data": [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
      "name": t("dashboard.upcomingWorkload.expiring"),
    },
  ]

  const now = new Date()
  for (let i = 0; i < upcomingWorkload.value.length; i++) {
    const seriesIndex = upcomingWorkload.value[i].type === DocumentCalendarEntryType.renewal ? 0 : 1
    const date = dayjs(upcomingWorkload.value[i].date, "YYYY-MM-DD")
    const entryMonth = date.month() // months are 0 to 11
    const currentMonth = now.getMonth()

    // adjust entries month relative to currentMonth, which is always index 0
    const dataIndex = (entryMonth - currentMonth + 12) % 12

    series[seriesIndex].data[dataIndex]++
  }

  return series
})

const monthNames = {
  en: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
  de: [ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
}

const labels = computed(() => {
  const labels = []
  const now = new Date()

  for (let i = now.getMonth(); i < (12 + now.getMonth()); i++) {
    labels.push(monthNames[locale.value][i % 12])
  }

  return labels
})

const setWorkload = (widgetData:DocumentCalendarEntry[]) => {
  if (!widgetData?.length) return

  upcomingWorkload.value = widgetData.filter((entry) => [ DocumentCalendarEntryType.expiration, DocumentCalendarEntryType.duration_end, DocumentCalendarEntryType.renewal ].includes(entry.type))
}

const fromDate = computed(() => dayjs().startOf("month").format("YYYY-MM-DD"))
const toDate = computed(() => dayjs().startOf("month").add(1, "year").format("YYYY-MM-DD"))

const handleClick = ({ config }) => {
  if (!isNaN(config.dataPointIndex)) {
    const now = new Date()
    const month = (now.getMonth() + 1 + config.dataPointIndex) % 12
    let year = now.getFullYear()

    if (month < (now.getMonth() + 1)) {
      year++
    }

    const lastDayOfMonth = (new Date(year, month, 0)).getDate()

    router.visit(route("documents.index") + `?duration_end_date=${year}-${addLeadingZeroForDateString(month)}-01%2C${year}-${addLeadingZeroForDateString(month)}-${addLeadingZeroForDateString(lastDayOfMonth)}`)
  }

  //console.log(event.target.parentElement.getAttribute("data:realIndex"))
}

const addLeadingZeroForDateString = (number) => {
  return number < 10 ? `0${number}` : `${number}`
}
</script>
<template>
  <DashboardGraphWidget
    :title="$t('dashboard.widgets.upcomingWorkload')"
    endpoint="api.data.documents.calendar-dates"
    :extra-params="{from_date: fromDate, to_date: toDate}"
    type="bar"
    :series="series"
    :options="{
      chart: {
        type: 'bar',
        width: '100%',
        height: '100%',
      },
      colors: ['#22C55E', '#EF4444'],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadiusApplication: 'end',
          borderRadius: 3,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: labels,
      },
      yaxis: {
        title: {
          text: $t('dashboard.upcomingWorkload.noOfDocuments')
        },
        labels: {
          formatter: (val) => {
            return val.toFixed(0)
          }
        },
      },
      fill: {
        opacity: 1
      },
    }"
    @click="handleClick"
    @loaded="setWorkload"
  >
    <template #empty>
      <EmptyState
        :hide-button="true"
      >
        <template #icon>
          <ChartBarIcon
            aria-hidden="true"
          />
        </template>
        <template #default>
          {{ $t('dashboard.upcomingWorkload.emptyTitle') }}
        </template>
        <template #call-to-action>
          <div class="text-sm px-5">
            {{ $t('dashboard.upcomingWorkload.emptyDescription') }}
          </div>
        </template>
      </EmptyState>
    </template>
  </DashboardGraphWidget>
</template>
