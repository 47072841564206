<script setup lang="ts">
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/20/solid"
import {
  CalendarDaysIcon,
  ListBulletIcon,
} from "@heroicons/vue/24/outline"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import { computed } from "vue"
import { useI18n } from "vue-i18n"


interface Props {
  date: Date | number
  viewMode?: string | null
}

const props = withDefaults(
  defineProps<Props>(),
  {
    viewMode: "year",
  },
)

const { locale } = useI18n()

const dateDisplay = computed(() => {
  if (props.viewMode === "month") {
    return new Date(props.date).toLocaleString(locale.value, { month: "long", year: "numeric" })
  } else {
    return new Date(props.date).toLocaleString(locale.value, { year: "numeric" })
  }
})

const emit = defineEmits([ "update:date", "update:view-mode" ])

const goToPrevious = () => {
  const date = new Date(props.date)
  if (props.viewMode === "month") {
    date.setMonth(date.getMonth() - 1)
  } else {
    date.setFullYear(date.getFullYear() - 1)
  }
  emit("update:date", new Date(date))
}

const goToNext = () => {
  const date = new Date(props.date)
  if (props.viewMode === "month") {
    date.setMonth(date.getMonth() + 1)
  } else {
    date.setFullYear(date.getFullYear() + 1)
  }
  emit("update:date", new Date(date))
}

const jumpToToday = () => {
  emit("update:date", new Date())
}

</script>

<template>
  <div class="flex items-center space-x-3">
    <time
      class="text-xs @2xl:text-sm font-medium text-right"
      :datetime="new Date(date).toLocaleString('default', {month:'numeric', year:'numeric'})"
    >{{ dateDisplay }}</time>
    <div class="relative flex items-center rounded-md shadow-sm @xl:items-stretch">
      <button
        type="button"
        class="btn-white rounded-r-none border-r-0 shadow-none px-2 btn-sm"
        @click="goToPrevious"
      >
        <span class="sr-only">{{ $t('pagination.previous') }}</span>
        <ChevronLeftIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </button>
      <button
        type="button"
        class="hidden btn-white shadow-none border-x-0 rounded-none @xl:block px-1 btn-sm"
        @click="jumpToToday"
      >
        <span>{{ $t('today') }}</span>
      </button>
      <button
        type="button"
        class="btn-white rounded-l-none border-l-0 shadow-none px-2 btn-sm"
        @click="goToNext"
      >
        <span class="sr-only">{{ $t('pagination.next') }}</span>
        <ChevronRightIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </button>
    </div>
    <div
      v-if="!!viewMode"
      class="hidden @xl:flex items-center"
    >
      <button
        type="button"
        :class="viewMode === 'month' ? 'btn-primary' : 'btn-white'"
        class="rounded-r-none btn-sm"
        data-tippy-context
        :data-tippy-content="$t('dashboard.documentCalendar.monthView')"
        data-placement="top"
        @click.prevent="$emit('update:view-mode', 'month')"
      >
        <CalendarDaysIcon
          class="shrink-0 w-5 h-5"
          aria-hidden="true"
        />
      </button>
      <button
        type="button"
        :class="viewMode === 'year' ? 'btn-primary' : 'btn-white'"
        class="-ml-px border-l-0 rounded-l-none btn-sm"
        data-tippy-context
        :data-tippy-content="$t('dashboard.documentCalendar.yearView')"
        data-placement="top"
        @click.prevent="$emit('update:view-mode', 'year')"
      >
        <ListBulletIcon
          class="shrink-0 w-5 h-5"
          aria-hidden="true"
        />
      </button>
    </div>
    <Menu
      as="div"
      class="relative ml-6 @xl:hidden"
    >
      <MenuButton class="-mx-2 flex items-center rounded-full border border-transparent px-2 text-gray-400 hover:text-gray-500 h-[34px]">
        <span class="sr-only">{{ $t('dashboard.documentCalendar.openMenu') }}</span>
        <EllipsisHorizontalIcon
          class="h-5 w-5"
          aria-hidden="true"
        />
      </MenuButton>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 z-20 w-40 overflow-hidden divide-y rounded-md divide-slate-800 bg-slate-900 focus:outline-none"
        >
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              :class="[
                active ? 'bg-slate-700 text-white' : 'text-slate-300',
                'group flex w-full items-center px-5 py-2 text-sm',
              ]"
              @click.prevent="jumpToToday"
            >
              {{ $t('dashboard.documentCalendar.goToToday') }}
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              :class="[
                active ? 'bg-slate-700 text-white' : 'text-slate-300',
                'group flex w-full items-center px-5 py-2 text-sm',
              ]"
              @click="$emit('update:view-mode', 'month')"
            >
              {{ $t('dashboard.documentCalendar.monthView') }}
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              :class="[
                active ? 'bg-slate-700 text-white' : 'text-slate-300',
                'group flex w-full items-center px-5 py-2 text-sm',
              ]"
              @click="$emit('update:view-mode', 'year')"
            >
              {{ $t('dashboard.documentCalendar.yearView') }}
            </button>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
