<script setup lang="ts">
// external
import { computed } from "vue"
import { DocumentTextIcon, ArrowRightIcon, XCircleIcon, FlagIcon, DocumentDuplicateIcon, PlayCircleIcon } from "@heroicons/vue/24/outline"
import { CheckCircleIcon, ArrowRightCircleIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"
import { Link } from "@inertiajs/vue3"

// internal
import { SignatureIcon, ImportIcon } from "~/icons"
import { Task, TaskableType, TaskType, CustomTask, AccountUser, DocumentUser, CustomTaskType } from "~/types"
import { isDateInPast, formatDateRelative } from "~/utils"
import { UserDisplay } from "~/components"

interface Props {
  task?: Task
  customTask?: CustomTask
  incoming?: boolean
  minimal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  incoming: true,
  task: null,
  customTask: null,
  minimal: false,
})


defineEmits([ "visit", "resolve", "cancel" ])

const { t } = useI18n()
const title = computed(() => {
  if (props.task?.custom_task?.title || props.customTask?.title) {
    return props.task?.custom_task?.title || props.customTask?.title
  } else if (props.task.type === TaskType.commentMention && props.incoming) {
    return t(`tasks.titles.${props.task.type}.incoming`)
  } else if (props.task.type === TaskType.commentMention && !props.incoming) {
    return t(`tasks.titles.${props.task.type}.outgoing`)
  } else {
    return t(`tasks.titles.${props.task.type}`)
  }
})
const description = computed(() => props.task?.custom_task?.description || props.customTask?.description)

const customTaskObject = computed(() => props.customTask || props.task?.custom_task)

const href = computed(() => {
  if (!props.customTask && props.task?.document_uuid) {
    let action = route("documents.show", props.task.document_uuid)

    if (props.task.type === TaskType.commentMention && props.task.comment_uuid) {
      action += "#comment-" + props.task.comment_uuid
    }
    return action
  }

  let endpoint = null

  switch (customTaskObject.value?.taskable_type) {
    case TaskableType.DOCUMENT:
      endpoint = "documents.show"
      break
    case TaskableType.TEMPLATE:
      endpoint = "templates.edit"
      break
    case TaskableType.AUTOMATION:
      endpoint = "automations.show"
      break
    case TaskableType.IMPORT:
      endpoint = "imports.show"
      break
    default:
      endpoint = null
      break
  }
  if (endpoint) {
    return route(endpoint, customTaskObject.value?.taskable_uuid)
  }

  return null
})

const assignees = computed<(AccountUser|DocumentUser)[]>(() => {
  if (!props.customTask) return []

  return props.customTask.subtasks.map((subtask) => subtask.document_user || subtask.account_user)
})

const taskToDisplay = computed(() => props.customTask || props.task)
const isOverdue = computed(() => taskToDisplay.value?.due_at ? (!taskToDisplay.value?.resolved_at && isDateInPast(new Date(taskToDisplay.value?.due_at))) : false)

const resolvedHighlights = computed(() => {
  if (!props.customTask?.subtasks?.length || props.incoming) return null

  if (!!props.customTask?.resolved_by_account_user && props.customTask?.custom_task_type === CustomTaskType.REQUIRE_ONE) {
    return props.customTask.subtasks.map((subtask) => subtask.account_user && subtask.account_user.uuid === props.customTask.resolved_by_account_user?.uuid)
  }

  return props.customTask.subtasks.map((subtask) => !!subtask.resolved_at)
})
</script>
<template>
  <div class="relative @container">
    <div class="flex items-center justify-between w-full max-w-full space-x-3">
      <div class="items-center w-full space-x-3 grow sm:flex">
        <div class="flex items-center space-x-3 grow">
          <UserDisplay
            v-if="!!taskToDisplay?.created_by_account_user"
            :users="[taskToDisplay.created_by_account_user]"
            :size-classes="'rounded-full shrink-0 h-5 w-5 ' + (minimal ? '' : '@sm:h-7 @sm:w-7')"
            placement="right"
          />
          <div
            class="relative text-sm bg-gray-100 rounded-lg grow"
            :class="isOverdue ? 'bg-gradient-to-r from-red-100 to-red-50' : !!taskToDisplay.resolved_at ? 'opacity-70' : ''"
          >
            <div class="flex items-center">
              <div
                v-if="!taskToDisplay?.created_by_account_user"
                class="relative flex items-center justify-center px-3"
              >
                <SignatureIcon
                  v-if="task?.type === TaskType.signatureRequired"
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-400 shrink-0"
                  :class="minimal ? '' : '@sm:h-5 @sm:w-5'"
                />
                <FlagIcon
                  v-else-if="task?.type === TaskType.checkpointApprovalRequired"
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-400 shrink-0"
                  :class="minimal ? '' : '@sm:h-5 @sm:w-5'"
                />
                <CheckCircleIcon
                  v-if="!!taskToDisplay.resolved_at"
                  aria-hidden="true"
                  class="absolute w-3 h-3 -mr-3.5 -mb-3 text-teal-500 shrink-0"
                />
              </div>
              <div class="border-l border-l-white">
                <div class="flex items-center gap-2">
                  <div class="py-1.5 px-3">
                    <div
                      class="relative z-10 font-medium overflow-hyphens"
                      :class="minimal? 'line-clamp-1 text-xs' : 'line-clamp-6'"
                    >
                      {{ title }}
                    </div>
                    <div
                      v-if="description && !minimal"
                      class="relative text-xs italic text-gray-500 line-clamp-6 overflow-hyphens"
                    >
                      {{ description }}
                    </div>
                    <div
                      v-if="!!taskToDisplay?.created_by_account_user"
                      class="absolute bg-gray-100 rotate-45 -left-1.5 top-1/2 w-3 h-3 -mt-1.5"
                      :class="isOverdue ? 'bg-red-200' : !!taskToDisplay.resolved_at ? 'bg-teal-100' : 'bg-gray-100'"
                    />
                    <Link
                      v-if="!!href"
                      :href="href"
                      class="w-auto max-w-[160px] @md:max-w-[320px] @lg:max-w-[480px] group inline-flex items-center space-x-1 rounded shadow-sm px-1.5 py-0.5 text-xs bg-white mt-1 transition-colors duration-100"
                      :class="isOverdue ? 'hover:bg-red-100 hover:text-red-500' : 'hover:bg-indigo-50 hover:text-indigo-500'"
                      @click="$emit('visit')"
                    >
                      <DocumentTextIcon
                        v-if="task?.document_uuid || customTaskObject?.taskable_type === TaskableType.DOCUMENT"
                        aria-hidden="true"
                        class="w-3 h-3 transition-colors duration-100 shrink-0"
                      />
                      <DocumentDuplicateIcon
                        v-else-if="customTaskObject?.taskable_type === TaskableType.TEMPLATE"
                        aria-hidden="true"
                        class="w-3 h-3 transition-colors duration-100 shrink-0"
                      />
                      <PlayCircleIcon
                        v-else-if="customTaskObject?.taskable_type === TaskableType.AUTOMATION"
                        aria-hidden="true"
                        class="w-3 h-3 transition-colors duration-100 shrink-0"
                      />
                      <ImportIcon
                        v-else-if="customTaskObject?.taskable_type === TaskableType.IMPORT"
                        aria-hidden="true"
                        class="w-3 h-3 transition-colors duration-100 shrink-0"
                      />
                      <div class="relative truncate">
                        {{ customTaskObject?.taskable_name || task?.document_name }}
                      </div>
                      <div
                        class="relative w-0 h-3 overflow-hidden transition-all duration-200 shrink-0 group-hover:w-3"
                      >
                        <ArrowRightIcon
                          aria-hidden="true"
                          class="w-3 h-3"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
                <div
                  v-if="!minimal"
                  class="flex items-start justify-between w-full px-3 pt-1 pb-2 space-x-5 text-xs text-gray-400"
                >
                  <div class="@sm:flex @sm:space-x-3">
                    <div>
                      {{ $t('tasks.created') }}: {{ formatDateRelative(taskToDisplay?.created_at) }}
                    </div>
                    <div
                      v-if="taskToDisplay?.due_at"
                      :class="isOverdue ? 'text-red-500 font-medium' : ''"
                    >
                      {{ $t('tasks.due') }}: {{ formatDateRelative(taskToDisplay.due_at) }}
                    </div>
                    <div
                      v-if="taskToDisplay?.resolved_at"
                      class="font-medium text-teal-500"
                    >
                      {{ $t('tasks.done') }}: {{ formatDateRelative(taskToDisplay.resolved_at) }}
                    </div>
                  </div>
                  <div class="flex items-center gap-3">
                    <div
                      v-if="!!customTask && assignees?.length > 1"
                      class="text-right"
                    >
                      {{ $t('tasks.customTaskTypes.' + customTask.custom_task_type) }}
                    </div>
                    <div
                      v-if="!!assignees?.length && !incoming"
                      class="relative flex items-center gap-2"
                    >
                      <UserDisplay
                        :users="assignees"
                        size-classes="h-5 w-5"
                        placement="left"
                        :limit="5"
                        :highlights="resolvedHighlights"
                        highlight-class="ring-teal-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link
        v-if="!task?.custom_task && !customTask && href && !task?.resolved_at"
        :href="href"
        class="text-gray-300 hover:text-indigo-500 shrink-0"
        data-tippy-help
        :data-tippy-content="$t('tasks.goToDocument')"
        data-placement="left"
        @click="$emit('visit')"
      >
        <ArrowRightCircleIcon
          aria-hidden="true"
          class="w-8 h-8"
        />
      </Link>
      <button
        v-else-if="!!task?.custom_task || task?.resolved_at"
        type="button"
        class="text-gray-300 hover:text-teal-500 shrink-0"
        :class="{'text-teal-500/40 pointer-events-none': !!task?.resolved_at}"
        :disabled="!!task?.resolved_at"
        data-tippy-help
        :data-tippy-content="$t('tasks.markAsDone')"
        data-placement="left"
        @click="$emit('resolve')"
      >
        <CheckCircleIcon
          aria-hidden="true"
          class="w-8 h-8"
        />
      </button>
      <button
        v-else-if="!!customTask && !customTask.canceled_at && !customTask.resolved_at"
        type="button"
        class="text-gray-300 hover:text-red-500 shrink-0"
        data-tippy-help
        :data-tippy-content="$t('tasks.cancelTask')"
        data-placement="left"
        @click="$emit('cancel')"
      >
        <XCircleIcon
          aria-hidden="true"
          class="w-8 h-8"
        />
      </button>
    </div>
  </div>
</template>
