<script setup lang="ts">
// external
import { onMounted, onBeforeUnmount, computed } from "vue"

// internal
import { Account, AccountUser } from "~/types"
import { getUserRepresentation } from "~/utils"
import { loadIntegration } from "~/integrations"
import pyne from "~/integrations/pyne?url"

interface Props {
  mau: AccountUser
  intercomUserHash?: string
  account: Account
  userLang: string
  preventBoot?: boolean
  subscriptionType?: string
  subscriptionPlan?: string
}

const props = withDefaults(defineProps<Props>(), {
  mau: null,
  intercomUserHash: null,
  account: null,
  userLang: "en",
  preventBoot: false,
  subscriptionType: null,
  subscriptionPlan: null,
})

declare const window: any

interface PyneParams {
  name: string
  email: string
  created_at: string
  account_user_role?: string
  account_user_locale?: string
  subscription_type?: string
  subscription_plan?: string
}

const initParams = computed(() => {
  if (!props.mau) return null
  // Join props.mau.roles name properties into a string
  const roles = props.mau.roles?.map((role) => role.name)?.join(", ")
  // TO DO: Add companies object
  const returnParamsObject: PyneParams = {
    name: getUserRepresentation(props.mau),
    email: props.mau.email,
    created_at: props.mau.created_at,
    account_user_role: roles,
    account_user_locale: props.userLang,
    subscription_type: props.subscriptionType,
    subscription_plan: props.subscriptionPlan,
  }
  return returnParamsObject
})


const boot = () => {
  // Check if window.pyne exists
  if (!!initParams.value && typeof window.pyne === "function") {
    window.pyne("identify", props.mau.uuid, initParams.value)
  }
}

onMounted(() => {
  // Boot Pyne
  if (!props.preventBoot) {
    if (!window.pyne) {
      loadIntegration({
        src: pyne,
        callback: boot,
      })
    } else {
      boot()
    }
  }
})

onBeforeUnmount(() => {
  if (typeof window.pyne === "function") {
    // Shutdown Pyne
    window.pyne("identify", null)
  }
})

defineExpose({ boot })

</script>

<template>
  <slot />
</template>
