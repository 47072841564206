<script setup lang="ts">
// external
import { ref, watch, computed, nextTick } from "vue"
import axios from "axios"
import { useI18n } from "vue-i18n"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"

// internal
import { DialogModal, SpinLoader } from "~/components"
import { DocumentExportType } from "~/types"
import { useNotificationStore } from "~/stores"

interface Props {
  show: boolean
  filterData?: any,
  all?: boolean
  uuids?: string[]
  total: number
}

const props = withDefaults(
  defineProps<Props>(),
  {
    filterData: null,
    all: false,
    uuids: () => [],
    total: 0,
  },
)

const { t, locale } = useI18n()

const emit = defineEmits([ "update:show" ])

watch(() => props.show, (newVal) => {
  if (newVal) {
    name.value = getDefaultName()
    nextTick(() => {
      nameInput.value.focus()
    })
  }
})

const getDefaultName = () => {
  const now = new Date()
  return `Export ${now.toLocaleDateString(locale.value)} ${now.toLocaleTimeString(locale.value)}`
}

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const name = ref(getDefaultName())
const type = ref(DocumentExportType.ARCHIVE)

const isExporting = ref(false)
const exportEntities = async () => {
  if (!isExporting.value && (props.uuids.length || props.all)) {
    isExporting.value = true
    try {
      await axios.post(route(`api.document-exports.store`), {
        name: name.value,
        entity_uuids: props.uuids,
        all: props.all,
        filter_data: props.filterData,
        type: type.value,
      })

      addToasterNotification({
        title: t("documents.actions.exportDocuments.success"),
        type: "success",
      })

      emit("update:show", false)
    } catch (e) {
      console.error(e)
      addToasterNotification({
        title: t("common.errorOccured"),
        type: "error",
      })
    } finally {

      isExporting.value = false
    }
  }
}

const count = computed(() => props.all ? props.total : props.uuids.length)
const nameInput = ref()
</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="lg"
    :show-close-button="true"
    @close="$emit('update:show', false)"
  >
    <template #title>
      <span>{{ $t('documents.actions.exportDocuments.title') }}</span>
    </template>
    <template #content>
      <div class="space-y-5">
        <div class="text-sm">
          {{ $t('documents.actions.exportDocuments.intro', count) }}:
        </div>
        <div>
          <div class="text-sm font-medium mb-1">
            {{ $t('magicTable.columns.name') }}
          </div>
          <input
            ref="nameInput"
            v-model="name"
            type="text"
            class="input-plain"
          >
        </div>
        <div>
          <div class="text-sm font-medium mb-1">
            {{ $t('magicTable.columns.content') }}
          </div>
          <Listbox
            v-model="type"
            as="div"
          >
            <div class="relative mt-1">
              <ListboxButton class="btn-listbox-plain">
                <span
                  class="block truncate"
                >{{ $t(`accountSettings.documentExports.types.${type}.title`) }}</span>

                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions class="listbox-options">
                  <ListboxOption
                    v-for="exportType in DocumentExportType"
                    :key="exportType"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="exportType"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                      <div>
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                          <span>{{ $t(`accountSettings.documentExports.types.${exportType}.title`) }}</span>
                        </span>
                        <div class="text-xs text-gray-400">
                          {{ $t(`accountSettings.documentExports.types.${exportType}.description`) }}
                        </div>
                      </div>


                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-indigo-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="w-5 h-5 shrink-0"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>

        <div class="text-sm">
          {{ $t('documents.actions.exportDocuments.outro') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <button
          type="button"
          class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
          @click.prevent="$emit('update:show', false)"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          type="button"
          class="flex items-center gap-2 btn-primary"
          :disabled="isExporting"
          @click="exportEntities"
        >
          <SpinLoader
            v-if="isExporting"
            class="w-5 h-5 shrink-0"
          />
          <span>{{ $t('common.export') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
