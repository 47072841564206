<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref, computed, watch } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon, ChevronDownIcon } from "@heroicons/vue/20/solid"
import { EllipsisHorizontalIcon } from "@heroicons/vue/24/solid"
import { DocumentTextIcon, DocumentDuplicateIcon, PlayCircleIcon } from "@heroicons/vue/24/outline"
import { Form, Field } from "vee-validate"

// internal
import { DateInput, AutocompleteInput, SpinLoader } from "~/components"
import { useAccountStore, useTaskStore } from "~/stores"
import { getUserRepresentation } from "~/utils"
import { AccountUser, CustomTaskType, CustomTask, TaskableType } from "~/types"
import { ImportIcon } from "~/icons"

interface Props {
  users: AccountUser[]
  customTask?: CustomTask
}

const props = withDefaults(defineProps<Props>(), {
  customTask: null,
})

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const taskStore = useTaskStore()
const { isCreatingTask, creationErrors, isFetchingAssignees, assigneeOptions } = storeToRefs(taskStore)
const { createCustomTask, fetchPotentialAssignees } = taskStore

/* ASSIGNEES */
const listOfAllowedUsers = computed(() => {
  let userList = [ ...props.users ]
  if (!!localTask.value.taskable_uuid) {
    userList = [ ...assigneeOptions.value ]
  }
  return userList
})
const remainingUserOptions = computed<AccountUser[]>(() => {

  return listOfAllowedUsers.value.filter((user) => {
    return !localTask.value.assignees.map((assignee) => assignee.account_user_uuid).includes(user.uuid) && user.uuid !== mau.value?.uuid
  })
})

const assigneesWithoutAccess = computed(() => {
  const assignedAccountUserUuids = localTask.value.assignees.map((assignee) => assignee.account_user_uuid)
  const assignedUsers = props.users.filter((user) => assignedAccountUserUuids.includes(user.uuid))

  const listOfAllowedUserUuids = listOfAllowedUsers.value.map((user) => user.uuid)

  return assignedUsers.filter((user) => !listOfAllowedUserUuids.includes(user.uuid))
})

const assigneesWithAccess = computed(() => {
  const assignedAccountUserUuids = localTask.value.assignees.map((assignee) => assignee.account_user_uuid)
  const assignedUsers = props.users.filter((user) => assignedAccountUserUuids.includes(user.uuid))

  const listOfAllowedUserUuids = listOfAllowedUsers.value.map((user) => user.uuid)

  return assignedUsers.filter((user) => listOfAllowedUserUuids.includes(user.uuid))
})

const emptyTask:CustomTask = {
  title: null,
  description: null,
  assignees: [],
  taskable_type: TaskableType.NONE,
  taskable_uuid: null,
  custom_task_type: CustomTaskType.REQUIRE_ONE,
}

const localTask = ref(props.customTask ? { ...props.customTask } : { ...emptyTask })

const selectedUser = computed({
  get: () => {
    return null
  },
  set: (user) => {
    localTask.value.assignees.push({
      account_user_uuid: user.uuid,
      document_user_uuid: null,
    })
  },
})

const removeUser = (user) => {
  const idx = localTask.value.assignees.map((assignee) => assignee.account_user_uuid).indexOf(user.uuid)

  if (idx !== -1) {
    localTask.value.assignees.splice(idx, 1)
  }
}

/* DUE DATE */
const setDueDate = (daysAdded) => {
  localTask.value.due_at = getFutureDateString(daysAdded)
}

const getFutureDateString = (daysAdded: number) => {
  const today = new Date()
  const futureDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysAdded)
  const day = futureDate.getDate()
  const month = futureDate.getMonth() + 1
  const year = futureDate.getFullYear()

  return `${year}-${(month < 10 ? "0" : "") + month}-${(day < 10 ? "0" : "") + day}`
}


/* CONTEXT */
const contextOptionResults = ref({
  [TaskableType.DOCUMENT]: [],
  [TaskableType.TEMPLATE]: [],
  [TaskableType.IMPORT]: [],
  [TaskableType.AUTOMATION]: [],
})

const contextOptions = computed(() => {
  const tempContextOptions = {
    [TaskableType.DOCUMENT]: contextOptionResults.value[TaskableType.DOCUMENT],
  }
  if (mau.value?.permissions.includes("template_manage")) {
    tempContextOptions[TaskableType.TEMPLATE] = contextOptionResults.value[TaskableType.TEMPLATE]
  }
  if (mau.value?.permissions.includes("import_manage")) {
    tempContextOptions[TaskableType.IMPORT] = contextOptionResults.value[TaskableType.IMPORT]
  }
  if (mau.value?.permissions.includes("automation_manage")) {
    tempContextOptions[TaskableType.AUTOMATION] = contextOptionResults.value[TaskableType.AUTOMATION]
  }
  return tempContextOptions
})

const autocompleteEndpoint = computed(() => {
  switch (localTask.value.taskable_type) {
    case TaskableType.DOCUMENT:
      return route("api.document-suggestions")
    case TaskableType.TEMPLATE:
      return route("api.template-suggestions")
    case TaskableType.AUTOMATION:
      return route("api.automation-suggestions")
    case TaskableType.IMPORT:
      return route("api.import-suggestions")
    default:
      return null
  }
})

const selectedContextEntity = ref(null)

watch(localTask, (newVal, oldVal) => {
  if (newVal.taskable_type !== oldVal.taskable_type) {
    localTask.value.taskable_uuid = null
  }
})

watch(selectedContextEntity, (newVal) => {
  if (newVal?.uuid) {
    localTask.value.taskable_uuid = newVal.uuid
    fetchPotentialAssignees(localTask.value.taskable_type, newVal.uuid)
  } else {
    localTask.value.taskable_uuid = null
  }
})


const hasValidAssignees = ref(true)
watch(assigneesWithAccess, (newVal) => {
  if (!!newVal.length) {
    hasValidAssignees.value = true
  }
})

const emits = defineEmits([ "success" ])


const submit = async () => {
  hasValidAssignees.value = !!assigneesWithAccess.value.length

  if (!hasValidAssignees.value) {
    return
  }

  // make sure only account users with access get submitted
  if (!!assigneesWithoutAccess.value.length) {
    localTask.value.assignees = assigneesWithAccess.value.map((assignee) => {
      return {
        account_user_uuid: assignee.uuid,
        document_user_uuid: null,
      }
    })
  }

  await createCustomTask(localTask.value)

  if (!creationErrors.value) {
    emits("success")
  }

  if (!!localTask.value.taskable_uuid) {
    localTask.value.taskable_type = null
    localTask.value.taskable_uuid = null
  }
}

const submitButton = ref()

defineExpose({ submitButton })

const removeContext = () => {
  localTask.value.taskable_type = null
  localTask.value.taskable_uuid = null
  selectedContextEntity.value = null
}

</script>
<template>
  <Form
    v-slot="{ errors }"
    class="px-6 mb-5 space-y-5"
    @submit="submit"
  >
    <div>
      <div class="mb-1 text-sm font-medium">
        {{ $t('tasks.form.title') }} <span class="text-teal-500">*</span>
      </div>
      <Field
        v-model="localTask.title"
        maxlength="180"
        type="text"
        name="title"
        class="input-plain focus:ring-teal-500"
        :placeholder="$t('tasks.form.titlePlaceholder') + '…'"
        rules="required"
        :disabled="isCreatingTask"
        :class="[errors.title ? 'error' : '']"
      />
    </div>
    <div>
      <div class="mb-1 text-sm font-medium">
        {{ $t('tasks.form.description') }}
      </div>
      <textarea
        v-model="localTask.description"
        class="input-plain focus:ring-teal-500"
        maxlength="255"
        :placeholder="$t('tasks.form.descriptionPlaceholder') + '…'"
        rows="3"
      />
    </div>

    <!-- CONTEXT -->
    <div
      v-if="selectedContextEntity"
    >
      <div class="mb-1 text-sm font-medium">
        {{ $t('tasks.form.context') }}
      </div>
      <div class="rounded bg-gray-100 text-sm px-3 py-1.5 flex items-center justify-between">
        <div class="flex items-center gap-1">
          <DocumentTextIcon
            v-if="localTask.taskable_type === TaskableType.DOCUMENT"
            aria-hidden="true"
            class="w-4 h-4 text-gray-400 shrink-0"
          />
          <DocumentDuplicateIcon
            v-else-if="localTask.taskable_type === TaskableType.TEMPLATE"
            aria-hidden="true"
            class="w-4 h-4 text-gray-400 shrink-0"
          />
          <PlayCircleIcon
            v-else-if="localTask.taskable_type === TaskableType.AUTOMATION"
            aria-hidden="true"
            class="w-4 h-4 text-gray-400 shrink-0"
          />
          <ImportIcon
            v-else-if="localTask.taskable_type === TaskableType.IMPORT"
            aria-hidden="true"
            class="w-4 h-4 text-gray-400 shrink-0"
          />
          <span>{{ $t('tasks.contextCategories.' + localTask.taskable_type) }}: {{ selectedContextEntity.name }}</span>
        </div>
        <button
          type="button"
          class="p-1"
          @click="removeContext"
        >
          <XMarkIcon
            aria-hidden="true"
            class="w-4 h-4 text-gray-400"
          />
        </button>
      </div>
    </div>
    <div
      v-else
      class="grid items-end grid-cols-2 gap-5"
    >
      <div>
        <div class="mb-1 text-sm font-medium">
          {{ $t('tasks.form.addContext') }}
        </div>
        <div>
          <Listbox
            v-model="localTask.taskable_type"
            as="div"
            class="max-w-sm grow"
          >
            <div class="relative">
              <ListboxButton
                class="btn-listbox-plain focus:ring-teal-500"
              >
                <span
                  v-if="localTask.taskable_type"
                  class="block truncate"
                >{{ $t('tasks.contextCategories.' + localTask.taskable_type) }}</span>
                <span
                  v-else
                  class="block text-gray-900 truncate"
                >{{ $t('tasks.contextCategories.none') }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions class="listbox-options">
                  <ListboxOption
                    v-slot="{ active, selected }"
                    as="template"
                    :value="null"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2 items-center']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ $t('tasks.contextCategories.none') }}</span>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-teal-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="w-4 h-4 -mr-2"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                  <ListboxOption
                    v-for="(option, key) in contextOptions"
                    :key="key"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="key"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2 items-center']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ $t('tasks.contextCategories.' + key) }}</span>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-teal-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="w-4 h-4 -mr-2"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div v-if="autocompleteEndpoint">
        <Field
          v-model="localTask.taskable_uuid"
          name="taskable_uuid"
          rules="required"
        >
          <AutocompleteInput
            v-model:entity="selectedContextEntity"
            :endpoint="autocompleteEndpoint"
            :placeholder="$t('tasks.form.searchByName')"
            input-extra-classes="focus:ring-teal-500"
            :has-error="!!errors.taskable_uuid"
          />
        </Field>
      </div>
    </div>

    <!-- ASSIGNEES -->
    <div class="grid gap-5 sm:grid-cols-4">
      <div class="col-span-2">
        <div class="mb-1 text-sm font-medium">
          {{ $t('tasks.form.assignees') }} <span class="text-teal-500">*</span>
        </div>
        <div class="flex items-center space-x-5">
          <Field
            v-model="localTask.assignees"
            name="assignees"
            rules="required"
          >
            <Listbox
              v-model="selectedUser"
              :disabled="(!remainingUserOptions.length && localTask.assignees.map((assignee) => assignee.account_user_uuid).includes(mau.uuid)) || isFetchingAssignees"
              as="div"
              class="max-w-sm grow"
            >
              <div class="relative">
                <ListboxButton
                  class="btn-listbox-plain focus:ring-teal-500"
                  :class="(errors.assignees && !localTask.assignees?.length) || !hasValidAssignees ? 'error' : ''"
                  :disabled="(!remainingUserOptions.length && localTask.assignees.map((assignee) => assignee.account_user_uuid).includes(mau.uuid)) || isFetchingAssignees"
                >
                  <span
                    class="block truncate"
                    :class="(errors.assignees && !localTask.assignees?.length) || !hasValidAssignees ? 'text-red-400' : 'text-gray-400'"
                  >{{ $t('documentNotifications.selectUser') }}…</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SpinLoader
                      v-if="isFetchingAssignees"
                      class="w-5 h-5"
                    />
                    <ChevronUpDownIcon
                      v-else
                      class="w-5 h-5"
                      :class="(errors.assignees && !localTask.assignees?.length) || !hasValidAssignees ? 'text-red-400' : 'text-gray-400'"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="listbox-options">
                    <ListboxOption
                      v-if="!localTask.assignees.map((assignee) => assignee.account_user_uuid).includes(mau.uuid)"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="mau"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2 items-center']">
                        <img
                          class="w-5 h-5 rounded-full max-w-none"
                          :src="mau.profile_photo_url"
                          :alt="getUserRepresentation(mau)"
                        >
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ getUserRepresentation(mau) }}</span>
                      </li>
                    </ListboxOption>
                    <ListboxOption
                      v-for="user in remainingUserOptions"
                      :key="user.uuid"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="user"
                    >
                      <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2 items-center']">
                        <img
                          class="w-5 h-5 rounded-full max-w-none"
                          :src="user.profile_photo_url"
                          :alt="getUserRepresentation(user)"
                        >
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ getUserRepresentation(user) }}</span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </Field>
        </div>


        <div
          v-if="!isFetchingAssignees"
          class="mt-3 bg-gray-100 divide-y divide-gray-200 rounded-md"
        >
          <div
            v-for="user in assigneesWithAccess"
            :key="user.uuid"
            class="flex items-center justify-between space-x-2 py-1 px-1.5"
          >
            <div class="flex items-center space-x-2">
              <img
                class="w-6 h-6 rounded-full max-w-none"
                :src="user.profile_photo_url"
                :alt="getUserRepresentation(user)"
              >
              <div class="text-sm">
                {{ getUserRepresentation(user) }}
              </div>
            </div>

            <button
              type="button"
              class="p-1"
              @click="removeUser(user)"
            >
              <XMarkIcon
                aria-hidden="true"
                class="w-4 h-4 text-gray-400"
              />
            </button>
          </div>

          <Listbox
            v-if="assigneesWithAccess?.length > 1"
            v-model="localTask.custom_task_type"
            as="div"
            class="px-1.5 py-1"
          >
            <div class="relative">
              <ListboxButton
                class="relative block text-sm hover:bg-teal-100 focus:ring-2 focus:ring-teal-500 focus:outline-none py-0.5 pl-1 pr-5 rounded-md w-full"
              >
                <span class="flex items-center w-full gap-1">
                  <span class="font-medium text-teal-700">{{ $t('checkpoints.policy') }}:</span>
                  <span>{{ $t('tasks.customTaskTypes.' + localTask.custom_task_type) }}</span>
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-1 text-gray-400 pointer-events-none">
                  <ChevronDownIcon
                    class="w-4 h-4"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions class="w-auto listbox-options">
                  <ListboxOption
                    v-for="customTaskType in CustomTaskType"
                    :key="customTaskType"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="customTaskType"
                  >
                    <li
                      :class="active ? 'bg-gray-700' : ''"
                      class="relative flex items-center gap-2 py-2 pl-3 pr-12 text-sm cursor-default select-none group"
                    >
                      <span
                        :class="[
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate',
                        ]"
                      >{{ $t('tasks.customTaskTypes.' + customTaskType) }}</span>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-teal-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="w-5 h-5 shrink-0"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>

        <div
          v-if="!isFetchingAssignees"
          class="relative mt-3 overflow-hidden divide-y divide-yellow-200 rounded-md"
        >
          <div
            v-if="assigneesWithoutAccess.length"
            class="text-xs px-1.5 py-1 bg-yellow-200 text-yellow-700"
          >
            {{ $t('tasks.form.noAccess', {name: selectedContextEntity.name}) }}:
          </div>
          <div
            v-for="user in assigneesWithoutAccess"
            :key="user.uuid"
            class="flex items-center justify-between space-x-2 py-1 px-1.5 bg-yellow-100"
          >
            <div class="flex items-center space-x-2">
              <img
                class="w-6 h-6 rounded-full opacity-50 max-w-none"
                :src="user.profile_photo_url"
                :alt="getUserRepresentation(user)"
              >
              <div class="text-sm text-yellow-600">
                {{ getUserRepresentation(user) }}
              </div>
            </div>

            <button
              type="button"
              class="p-1"
              @click="removeUser(user)"
            >
              <XMarkIcon
                aria-hidden="true"
                class="w-4 h-4 text-yellow-700"
              />
            </button>
          </div>
        </div>
      </div>
      <div v-if="false">
        <div class="mb-1 text-sm font-medium">
          Due date
        </div>
        <div class="flex items-center space-x-2">
          <DateInput
            v-model="localTask.due_at"
            class="flex min-h-[36px]"
            :show-day="true"
            :show-month="true"
            :show-year="true"
          />
          <Menu
            as="div"
            class="relative z-30 inline-block text-left"
          >
            <div>
              <MenuButton
                class="btn-menu"
                data-cy-sel="magic-table-options-button"
              >
                <div class="sr-only">
                  {{ $t('magicTable.columns.options') }}
                </div>
                <EllipsisHorizontalIcon
                  class="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 z-20 mt-1 overflow-hidden origin-top-right divide-y rounded-md w-36 divide-slate-800 bg-slate-900 focus:outline-none top-full"
                data-cy-sel="magic-table-options-items"
              >
                <div>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-slate-700 text-white' : 'text-slate-300',
                        'group flex w-full items-center px-5 py-2 text-sm',
                      ]"
                      @click="setDueDate(1)"
                    >
                      tomorrow
                    </button>
                  </MenuItem>
                </div>
                <div>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-slate-700 text-white' : 'text-slate-300',
                        'group flex w-full items-center px-5 py-2 text-sm',
                      ]"
                      @click="setDueDate(7)"
                    >
                      in a week
                    </button>
                  </MenuItem>
                </div>
                <div>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-slate-700 text-white' : 'text-slate-300',
                        'group flex w-full items-center px-5 py-2 text-sm',
                      ]"
                      @click="setDueDate(30)"
                    >
                      in a month
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <button
      id="taskSubmitButton"
      ref="submitButton"
      type="submit"
    />
  </Form>
</template>
