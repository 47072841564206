import { Editor, mergeAttributes, Node } from "@tiptap/core"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { Range, VueNodeViewRenderer } from "@tiptap/vue-3"

import DynamicFieldNode from "./DynamicFieldNode.vue"
import { useDynamicFieldStore } from "~/stores"
import { storeToRefs } from "pinia"
import { Mark } from "@tiptap/pm/model"
import { trackEventInJune } from "~/utils"
import { JuneEvents } from "~/types"

export const DynamicFieldsPluginKey = new PluginKey("dynamicFieldsSuggestions")

export const DynamicFieldsDropPluginKey = new PluginKey("dynamicFieldDropPlugin")

export const getDynamicFieldDropPlugin = (editor: Editor) => {
  return new Plugin(
    {
      key: DynamicFieldsDropPluginKey,
      props: {
        handleDrop (view, event) {
          if (!event || !editor.isEditable) return false

          const coordinates = view.posAtCoords(
            {
              left: event.clientX,
              top: event.clientY,
            },
          )

          let dynamicField = event.dataTransfer.getData("text")

          if (!dynamicField) return false

          let dynamicFieldJson: { isDynamicField: boolean, refUuid: string }

          try {
            dynamicFieldJson = JSON.parse(dynamicField)
          } catch {
            // No valid JSON, so we can't handle this drop
            // Lets try the storage instead
            const dynamicFieldStore = useDynamicFieldStore()
            const { dataTransferData } = storeToRefs(dynamicFieldStore)
            dynamicField = dataTransferData.value
            try {
              dynamicFieldJson = JSON.parse(dynamicField)
            } catch {
              return false
            }
          }

          if (!dynamicFieldJson?.isDynamicField) return false

          event.preventDefault()

          // editor.commands.insertContentAt(coordinates.pos, { type: "text", text: " " })
          // editor.commands.focus(coordinates.pos)

          // Check if the current position has a textStyle mark with attrs.colors or attrs.fontSize applied
          // If so, we want to apply the same to the dynamic field
          const marks = view.state.doc.resolve(coordinates.pos).marks() || []

          trackEventInJune(JuneEvents.EDITOR_DYNAMIC_FIELD_DROPPED)

          setTimeout(
            () => {
              editor
                .chain()
                .focus()
                .insertContentAt(
                  coordinates.pos,
                  [
                    {
                      type: "dynamicField",
                      attrs: {
                        refUuid: dynamicFieldJson.refUuid,
                      },
                      marks: marks?.map((mark) => ({ type: mark.type.name, attrs: mark.attrs })), // Map ProseMirror marks to the format expected by TipTap's insertContent
                    },
                  ],
                )
                .run()
            },
          )
          return true
        },
      },
    },
  )
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    dynamicField: {
      addDynamicField: (refUuid: string, range?: Range, marks?: Mark[]) => ReturnType,
    }
  }
}

export const DynamicFields = Node.create({
  name: "dynamicField",

  group: "inline",

  inline: true,

  selectable: true,

  atom: true,

  draggable: true,

  addOptions () {
    return {
      HTMLAttributes: {},

      renderLabel: ({ node }) => `~DF${node.attrs.refUuid}`,
    }
  },

  addAttributes () {
    return {
      refUuid: {
        default: null,
        parseHTML: (element) => parseInt(element.getAttribute("data-ref-uuid")),
        renderHTML: (attributes) => {
          if (!attributes.refUuid) return {}

          return {
            "data-ref-uuid": attributes.refUuid,
          }
        },
      },
    }
  },

  parseHTML () {
    return [
      {
        tag: "span[data-dynamic-field]",
      },
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        {
          "data-dynamic-field": "true",
        },
        this.options.HTMLAttributes,
        HTMLAttributes,
      ),
      this.options.renderLabel(
        {
          options: this.options,
          node,
        },
      ),
    ]
  },

  renderText ({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },

  addCommands () {
    return {
      addDynamicField: (refUuid, range, marks = []) => ({ chain }) => {
        const nodeObject: any = {
          type: this.name,
          attrs: { refUuid },
        }
        if (marks.length) nodeObject.marks = marks
        const content = [ nodeObject ]
        if (range) {
          return chain()
            .focus()
            .insertContentAt(range, content)
            .run()
        }

        trackEventInJune(JuneEvents.EDITOR_DYNAMIC_FIELD_INSERTED)

        return chain()
          .focus()
          .insertContent(content)
          .run()
      },
    }
  },

  addNodeView () {
    return VueNodeViewRenderer(DynamicFieldNode, {})
  },

  addProseMirrorPlugins () {
    const { editor } = this

    return [
      getDynamicFieldDropPlugin(editor),
    ]
  },
})

export default DynamicFields
