<script setup lang="ts">
// external
import { Link, router } from "@inertiajs/vue3"
import { PhoneField } from "~/components"
import { ref } from "vue"
import { Form, Field } from "vee-validate"
import axios from "axios"

// internal
import { PASSWORD_REGEX } from "~/utils"
import {
  InputError,
  PasswordScore,
} from "~/components"

interface Props {
  firstName?: string
  lastName?: string
  email?: string
  backendErrors?: any
}

const props = withDefaults(defineProps<Props>(), {
  firstName: null,
  lastName: null,
  email: null,
  backendErrors: null,
})

const urlSearchParams = new URLSearchParams(window.location.search)

const form = ref({
  first_name: props.firstName ?? "",
  last_name: props.lastName ?? "",
  account_name: "",
  email: props.email ?? "",
  mobile_phone: "",
  password: "",
  password_confirmation: "",
  terms: false,
  backendErrors: null,
})

const isSubmitting = ref(false)
const submit = async (values, { resetForm }) => {
  if (!isSubmitting.value) {
    await checkEmail()

    if (validEmail.value) {
      isSubmitting.value = true

      const payload = {
        ...values,
        utm_source: urlSearchParams.get("utm_source"),
        utm_medium: urlSearchParams.get("utm_medium"),
        utm_campaign: urlSearchParams.get("utm_campaign"),
        utm_term: urlSearchParams.get("utm_term"),
        utm_content: urlSearchParams.get("utm_content"),
        mtm_campaign: urlSearchParams.get("mtm_campaign"),
        mtm_kwd: urlSearchParams.get("mtm_kwd"),
        mtm_source: urlSearchParams.get("mtm_source"),
        mtm_medium: urlSearchParams.get("mtm_medium"),
        mtm_content: urlSearchParams.get("mtm_content"),
        mtm_cid: urlSearchParams.get("mtm_cid"),
        mtm_group: urlSearchParams.get("mtm_group"),
        mtm_placement: urlSearchParams.get("mtm_placement"),
        gclid: urlSearchParams.get("gclid"),
        gbraid: urlSearchParams.get("gbraid"),
        wbraid: urlSearchParams.get("wbraid"),
        li_fat_id: urlSearchParams.get("li_fat_id"),
        ucc: urlSearchParams.get("ucc"),
        muid: urlSearchParams.get("muid") ?? document.cookie.split("; ").find((row) => row.startsWith("_pk_id"))?.split("=")[1].split(".")[0],
      }

      router.post(route("register"), payload, {
        onFinish: () => {
          if (!props.backendErrors) {
            resetForm()
          }
          isSubmitting.value = false
        },
      })
    }
  }
}

const isCheckingEmail = ref(false)
const validEmail = ref(null)
const checkEmail = async () => {
  if (!isCheckingEmail.value) {
    isCheckingEmail.value = true
    validEmail.value = null
    try {
      await axios.post(route("api.email-check"), {
        email: form.value.email,
      })
      isCheckingEmail.value = false
      validEmail.value = true
    } catch (e) {
      console.error(e)
      isCheckingEmail.value = false
      validEmail.value = false
    }
  }
}
</script>

<template>
  <Form
    v-slot="{ errors }"
    v-cy="`register-form`"
    @submit="submit"
  >
    <div>
      <label
        class="block text-sm font-medium leading-6 text-gray-900"
        for="account_name"
      >
        {{ $t('auth.register.account') }}
      </label>
      <Field
        id="account_name"
        v-model="form.account_name"
        v-cy="`input-account-name`"
        name="account_name"
        type="text"
        class="block w-full mt-1 input-plain"
        required
        autofocus
        autocomplete="account_name"
        :placeholder="$t('auth.register.accountPlaceholder') + '…'"
        rules="required"
        :class="[errors.account_name ? 'error' : '']"
      />
    </div>
    <div class="grid lg:grid-cols-2 lg:space-x-5">
      <div class="mt-4">
        <label
          class="block text-sm font-medium leading-6 text-gray-900"
          for="first_name"
        >
          {{ $t('userForm.firstName') }}
        </label>
        <Field
          id="first_name"
          v-model="form.first_name"
          v-cy="`input-first-name`"
          name="first_name"
          type="text"
          class="block w-full mt-1 input-plain"
          required
          autofocus
          autocomplete="first_name"
          :placeholder="$t('userForm.firstNamePlaceholder') + '…'"
          rules="required"
          :class="[errors.first_name ? 'error' : '']"
        />
      </div>

      <div class="mt-4">
        <label
          class="block text-sm font-medium leading-6 text-gray-900"
          for="last_name"
        >
          {{ $t('userForm.lastName') }}
        </label>
        <Field
          id="last_name"
          v-model="form.last_name"
          v-cy="`input-last-name`"
          name="last_name"
          type="text"
          class="block w-full mt-1 input-plain"
          required
          autocomplete="last_name"
          :placeholder="$t('userForm.lastNamePlaceholder') + '…'"
          rules="required"
          :class="[errors.last_name ? 'error' : '']"
        />
      </div>
    </div>

    <div class="mt-4">
      <label
        class="block text-sm font-medium leading-6 text-gray-900"
        for="email"
      >
        {{ $t('auth.login.email') }}
      </label>
      <Field
        id="email"
        v-model="form.email"
        v-cy="`input-email`"
        type="email"
        name="email"
        class="block w-full mt-1 input-plain"
        required
        autocomplete="username"
        rules="required|email"
        :placeholder="$t('auth.login.emailPlaceholder') + '…'"
        :class="[(validEmail !== null && !validEmail) || errors.email || backendErrors?.email ? 'error' : '']"
      />
      <InputError
        v-if="errors.email"
        class="mt-2"
        :message="errors.email"
      />
      <InputError
        v-if="backendErrors?.email"
        class="mt-2"
        :message="backendErrors.email"
      />
      <InputError
        v-if="(validEmail !== null && !validEmail)"
        class="mt-2"
        :message="$t('auth.register.emailNotAllowed')"
      />
    </div>

    <div class="mt-4">
      <label
        class="flex items-center justify-between text-sm font-medium leading-6 text-gray-900"
        for="mobilePhone"
      >
        {{ $t('auth.register.mobilePhone') }}
        <span class="text-gray-400">{{ $t('auth.register.optional') }}</span>
      </label>
      <PhoneField
        id="mobile_phone"
        v-model="form.mobile_phone"
        :phone="form.mobile_phone"
        name="mobile_phone"
        placeholder="+43 664 1234 567"
        style-classes="vue-tel-input-plain vue-tel-input-sm"
        :disabled="false"
        :has-error="false"
        :autofocus="false"
        :required="false"
      />
      <InputError
        v-if="errors.mobile_phone"
        class="mt-2"
        :message="errors.mobile_phone"
      />
    </div>

    <div class="mt-4">
      <label
        class="block text-sm font-medium leading-6 text-gray-900"
        for="password"
      >
        {{ $t('auth.login.password') }}
      </label>
      <Field
        id="password"
        v-model="form.password"
        v-cy="`input-password`"
        name="password"
        type="password"
        class="block w-full mt-1 input-plain"
        required
        autocomplete="new-password"
        :placeholder="$t('auth.login.passwordPlaceholder') + '…'"
        :class="[errors.password || form.password?.length > 0 && form.password?.length < 8 ? 'error' : '']"
        :rules="{required: true, regex: PASSWORD_REGEX }"
      />
      <PasswordScore
        v-if="!!form.password"
        :value="form.password"
        class="max-w-xl mt-2"
      />
    </div>

    <div class="mt-4">
      <label
        class="block text-sm font-medium leading-6 text-gray-900"
        for="password_confirmation"
      >
        {{ $t('auth.register.confirmPassword') }}
      </label>
      <Field
        id="password_confirmation"
        v-model="form.password_confirmation"
        v-cy="`input-password-confirm`"
        type="password"
        class="block w-full mt-1 input-plain"
        name="password_confirmation"
        required
        rules="required|confirmed:@password"
        autocomplete="new-password"
        :placeholder="$t('auth.register.confirmPasswordPlaceholder') + '…'"
        :class="[errors.password_confirmation ? 'error' : '']"
      />
      <InputError
        v-if="errors.password_confirmation"
        class="mt-2"
        :message="errors.password_confirmation"
      />
    </div>

    <div class="mt-4 text-sm text-gray-500">
      <i18n-t
        keypath="auth.register.tosAgreement"
        tag="span"
      >
        <a
          :href="$t('auth.register.privacyLink')"
          class="link-primary underline"
          target="_blank"
        >{{ $t('plg.modal.privacyPolicy') }}</a>
        <a
          :href="$t('auth.register.termsLink')"
          class="link-primary underline"
          target="_blank"
        >{{ $t('plg.modal.terms') }}</a>
      </i18n-t>
    </div>

    <div class="block mt-6">
      <button
        v-cy="`register-submit-button`"
        type="submit"
        class="w-full btn-primary"
        :disabled="isSubmitting"
      >
        {{ $t('auth.register.register') }}
      </button>

      <p class="mt-6 text-sm text-center text-gray-500 lg:mt-12">
        {{ $t('auth.register.alreadyRegistered') }}
        {{ ' ' }}
        <Link
          :href="route('login')"
          class="font-semibold text-indigo-600 hover:text-indigo-700"
        >
          {{ $t('auth.login.login') }}
        </Link>
      </p>
    </div>
  </Form>
</template>
