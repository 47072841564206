<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, watch, ref } from "vue"
import { BoltIcon, InformationCircleIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"

// internal
import { MetadataOptionsPopover, SpinLoader, DurationDisplay } from "~/components"
import { useMetadataStore } from "~/stores"
import { MetadataType, MetadataValue, MetadataValueStatus, MultiFieldType } from "~/types"
import { convertCurrencyString, formatDateSimple } from "~/utils"


interface Props {
  metadataValue: MetadataValue
  entityUuid?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    entityUuid: null,
  },
)

defineEmits([ "set-value-source", "close-modal" ])

const { t, locale } = useI18n()

const metadataStore = useMetadataStore()
const { metadataValueLastSavedMap, uuidsOfUpdatingMetadataValue } = storeToRefs(metadataStore)


const lastSaved = computed<number | undefined>(() => metadataValueLastSavedMap.value[props.metadataValue?.uuid])
const isLoadingMetadataValue = computed<boolean>(() => uuidsOfUpdatingMetadataValue.value.includes(props.metadataValue?.uuid))
const isHighlightedLastSaved = ref<boolean>(false)

watch(lastSaved, () => {
  isHighlightedLastSaved.value = true
  setTimeout(() => { isHighlightedLastSaved.value = false }, 1500)
})

const name = computed(() =>  {
  const metadata = props.metadataValue?.metadata
  if (!metadata) return ""
  return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.display_name : t(`metadata.system.${metadata?.name}.name`)
})
const description = computed(() =>  {
  const metadata = props.metadataValue?.metadata
  if (!metadata) return ""
  return metadata.type === MetadataType.account || !!metadata.account_metadata_uuid ? metadata?.description : t(`metadata.system.${metadata?.name}.description`)
})

</script>

<template>
  <div
    class="relative pt-1.5 pb-2 px-6 text-sm transition-all duration-500"
  >
    <dt
      class="group text-gray-500 flex items-center gap-1.5 justify-between min-h-[28px]"
    >
      <div class="flex items-center gap-1.5 truncate">
        <span
          class="truncate"
          :title="name"
        >{{ name }}</span>
        <span
          v-if="description"
          data-tippy-help
          :data-tippy-content="description"
          data-placement="bottom"
        >
          <InformationCircleIcon
            class="w-3 h-3 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </div>

      <SpinLoader
        v-if="isLoadingMetadataValue"
        class="w-4 h-4"
      />
      <MetadataOptionsPopover
        v-else
        :metadata-value="metadataValue"
        :disabled="isLoadingMetadataValue"
        :entity-uuid="entityUuid"
        @set-value-source="$emit('set-value-source', $event)"
        @close-modal="$emit('close-modal')"
      />
    </dt>
    <div
      class="flex items-center justify-end w-full gap-1 min-h-[28px]"
    >
      <dd
        class="flex items-center justify-start w-full gap-1.5 pr-1 font-normal text-right"
        :class="[metadataValue.value ? 'text-gray-900' : 'text-gray-400']"
      >
        <div>
          <span
            v-if="metadataValue.computed_status && ![ MetadataValueStatus.COMPUTED ].includes(metadataValue.computed_status)"
            data-tippy-help
            :data-tippy-content="$t('metadata.computationError.' + metadataValue.computed_status)"
            data-placement="bottom"
          >
            <BoltIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </span>
          <span
            v-else
            data-tippy-help
            :data-tippy-content="$t('metadata.autoGenerated')"
            data-placement="bottom"
          >
            <BoltIcon
              class="w-4 h-4 shrink-0"
              :class="[metadataValue.value ? 'text-indigo-500' : 'text-gray-400']"
              aria-hidden="true"
            />
          </span>
        </div>
        <div>
          <template v-if="!metadataValue.value">
            {{ $t('common.n/a') }}
          </template>
          <template v-else-if="metadataValue.metadata.value_type === MultiFieldType.timestamp || metadataValue.metadata.value_type === MultiFieldType.date">
            {{ metadataValue.value ? formatDateSimple(metadataValue.value) : $t('common.n/a') }}
          </template>
          <template v-else-if="metadataValue.metadata.value_type === MultiFieldType.duration">
            <DurationDisplay
              v-if="metadataValue.value"
              :value="metadataValue.value"
            />
            <span v-else>{{ $t('common.n/a') }}</span>
          </template>
          <span
            v-else-if="[ MultiFieldType.currency, MultiFieldType.currency_duration ].includes(metadataValue.metadata.value_type)"
            class="whitespace-nowrap"
          >
            {{ convertCurrencyString(metadataValue.value, null, locale) }}
          </span>
          <template v-else-if="metadataValue.metadata.value_type === MultiFieldType.textarea">
            <div class="flex whitespace-pre-wrap">
              {{ metadataValue.value }}
            </div>
          </template>
          <template v-else>
            {{ metadataValue.value }}
          </template>
        </div>
      </dd>
    </div>
  </div>
</template>
