<script setup lang="ts">
interface Props {
  value: string
}
const props = defineProps<Props>()
</script>

<template>
  <label class="block font-medium text-sm text-gray-700">
    <span v-if="props.value">
      {{ value }}
    </span>
    <span v-else>
      <slot />
    </span>
  </label>
</template>
