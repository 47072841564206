<script setup lang="ts">
import * as CustomIcons from "~/icons"
import { StarIcon } from "~/icons"
import { Team } from "~/types"

interface Props {
  team?: Team
}

const props = withDefaults(defineProps<Props>(), {
  team: null,
})
</script>

<template>
  <component
    :is="props.team?.icon ? CustomIcons[props.team.icon] : StarIcon"
    aria-hidden="true"
    class="shrink-0"
  />
</template>
