<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { useConfirmationStore } from "~/stores"
import { DialogModal } from "~/components"
import { DocumentMagnifyingGlassIcon } from "@heroicons/vue/24/outline"

const confirmationStore = useConfirmationStore()
const { confirmOptions, showConfirmModal } = storeToRefs(confirmationStore)
const { setShowConfirmModal } = confirmationStore

</script>

<template>
  <DialogModal
    :show="showConfirmModal"
    :show-icon="true"
    :icon="confirmOptions.icon"
    :closeable="false"
    max-width="xl"
    @close="setShowConfirmModal(false)"
  >
    <template #title>
      {{ confirmOptions.title }}
    </template>

    <template #content>
      <p class="text-sm text-gray-500">
        {{ confirmOptions.description }}
      </p>
      <dl
        v-if="confirmOptions.data?.length"
        class="mt-1.5 space-y-1"
      >
        <div
          v-for="row, rowIdx in confirmOptions.data"
          :key="rowIdx"
          class="flex items-center justify-between px-3 py-2 bg-gray-100 rounded-md"
        >
          <div>
            <dt class="text-sm font-medium text-gray-500">
              {{ row.key }}:
            </dt>
            <dd class="text-sm text-gray-900">
              {{ row.value }}
            </dd>
          </div>
          <div v-if="confirmOptions?.hasActionButtons">
            <button
              type="button"
              class="flex items-center gap-1 bg-gray-200 btn-plain hover:bg-gray-300 focus:ring-gray-200 btn-sm"
              @click="row.callback"
            >
              <DocumentMagnifyingGlassIcon class="w-5 h-5" />
              {{ row.buttonText }}
            </button>
          </div>
        </div>
      </dl>
    </template>

    <template #footer>
      <div class="flex items-center justify-end gap-2">
        <div
          v-if="confirmOptions.secondaryButtonText && typeof confirmOptions.secondaryCallback === 'function'"
          class="flex items-center justify-start grow"
        >
          <button
            type="button"
            class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
            data-cy-sel="confirm-modal-confirm-button"
            @click="
              confirmOptions.secondaryCallback();
              setShowConfirmModal(false);
            "
          >
            {{ confirmOptions.secondaryButtonText }}
          </button>
        </div>
        <button
          ref="cancelButtonRef"
          type="button"
          class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
          @click="
            if (!!confirmOptions.cancelCallback) {
              confirmOptions.cancelCallback();
            }
            setShowConfirmModal(false);
          "
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          type="button"
          class="inline-flex items-center gap-2 text-white btn-plain"
          :class="confirmOptions.icon ? 'bg-indigo-500 hover:text-white hover:bg-indigo-600 focus:ring-indigo-600' : 'bg-red-500 hover:text-white hover:bg-red-600 focus:ring-red-600'"
          data-cy-sel="confirm-modal-confirm-button"
          @click="
            confirmOptions.callback();
            setShowConfirmModal(false);
          "
        >
          {{ confirmOptions.buttonText }}
        </button>
      </div>
    </template>
  </DialogModal>
</template>
