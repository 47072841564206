<script lang="ts" setup>
// external
import { toTypedSchema } from "@vee-validate/zod"
import { roundArrow, sticky } from "tippy.js"
import { Field, Form } from "vee-validate"
import { onBeforeMount, ref } from "vue"
import { z } from "zod"
import { TippyComponent } from "vue-tippy"
import { storeToRefs } from "pinia"
import { without } from "lodash-es"
import { watch } from "vue"

// internal
import { useDocumentStore, useUserStore } from "~/stores"
import { DocumentUser } from "~/types"
import { SpinLoader } from "~/components"
import { documentUserInvitationStatuses } from "~/utils"

interface Props {
  user: DocumentUser
  isSharingModal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  user: null,
  isSharingModal: false,
})

const documentStore = useDocumentStore()
const { currentDocument } = storeToRefs(documentStore)

const userStore = useUserStore()
const { userNotifications, uuidsOfIsLoadingSendInvitation } = storeToRefs(userStore)
const { sendUserInvitation } = userStore


const message = ref<string>("")

const lookup = documentUserInvitationStatuses

const populateMessageValue = () => {
  const orderedMessages = userNotifications.value?.filter((el) => lookup.includes(el.notification_type)).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
  return orderedMessages?.[0]?.message || ""
}

onBeforeMount(() => {
  message.value = populateMessageValue()
})

watch([ () => props.user, () => userNotifications.value ], () => {
  message.value = populateMessageValue()
})

const validationSchema = toTypedSchema(
  z.object({
    message: z.string().nullable(),
  }),
)

const initialData = ref({
  message: message.value || "",
})

const confirmResendInvitationRef = ref<TippyComponent>(null)

const handleSendUserInvitationAndClosePopover = async (userUuid: DocumentUser["uuid"]) => {
  const sendUserInvitationRes = await sendUserInvitation(currentDocument.value?.uuid, userUuid, message.value)
  if (sendUserInvitationRes) {
    uuidsOfIsLoadingSendInvitation.value = without(uuidsOfIsLoadingSendInvitation.value, userUuid)
    confirmResendInvitationRef.value?.hide()
  }
}

const triggerTippy = (e) => {
  confirmResendInvitationRef.value?.show()
  e.stopPropagation()
}

const appendElement = (props.isSharingModal ? document.getElementById("sharingModal") : document.body) || document.body

</script>
<template>
  <Tippy
    ref="confirmResendInvitationRef"
    :interactive="true"
    trigger="manual"
    placement="auto"
    :hide-on-click="true"
    :plugins="[
      sticky
    ]"
    class="flex items-center"
    :arrow="roundArrow"
    :append-to="() => appendElement"
  >
    <template #default>
      <button
        v-if="!uuidsOfIsLoadingSendInvitation.includes(user.uuid)"
        type="button"
        class="px-1 py-0 text-xs text-indigo-500 hover:text-indigo-700 btn-plain focus:ring-0 focus:bg-indigo-100"
        @click="triggerTippy"
      >
        {{ $t('documentSharingModal.invitationResend') }}
      </button>
      <SpinLoader
        v-else
        class="w-3 h-3 ml-1 text-gray-400 pointer-events-none"
      />
    </template>

    <template #content>
      <div class="popover popover-primary">
        <div class="md:min-w-[22rem] md:max-w-[26rem]">
          <div
            class="flex-1 h-full"
          >
            <div class="flex flex-col gap-3 px-4 pt-3 pb-5">
              <Form
                v-slot="{ errors }"
                ref="form"
                class="w-full space-y-4 text-left"
                :validation-schema="validationSchema"
                :initial-values="initialData"
                @submit="handleSendUserInvitationAndClosePopover(user.uuid)"
              >
                <label
                  for="confirm-resend-popover-message"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ $t('documentSharingModal.message') }}
                </label>
                <div
                  class="mt-1 grow-textarea"
                  :data-replicated-value="message"
                >
                  <Field
                    id="message"
                    v-model="message"
                    name="message"
                    type="text"
                    as="textarea"
                    class="max-w-xl input-plain"
                    :class="[errors.message ? 'input-has-errors' : '']"
                    rules="required"
                    :placeholder="$t('documentSharingModal.messagePlaceholder') + '…'"
                  />
                </div>
              </form>
            </div>
          </div>

          <div class="flex items-center justify-end gap-2 p-3 bg-gray-100 border-t border-gray-200 rounded-b-md">
            <button
              v-cy="`user-form-submit-button`"
              :disabled="uuidsOfIsLoadingSendInvitation.includes(user.uuid)"
              type="button"
              class="inline-flex items-center gap-2 btn-primary"
              @click.stop="handleSendUserInvitationAndClosePopover(user.uuid)"
            >
              <SpinLoader
                v-if="uuidsOfIsLoadingSendInvitation.includes(user.uuid)"
                class="w-5 h-5 shrink-0"
                aria-hidden="true"
              />
              <span v-if="uuidsOfIsLoadingSendInvitation.includes(user.uuid)">{{ $t('common.sending') }}…</span>
              <span v-else>{{ $t('common.send') }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </Tippy>
</template>
