<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon, PlusIcon } from "@heroicons/vue/24/solid"
import { AccountColorSettings } from "~/types"
import { ApplicationLogo } from "~/components"

interface Props {
  accountLogoImageUrl?: string
  ciColors: AccountColorSettings
  accountEmailSignature?: string
}

const props = withDefaults(defineProps<Props>(), {
  accountLogoImageUrl: null,
})
</script>

<template>
  <main
    id="wizardContainer"
    class="relative flex items-start py-40 h-full bg-transparent sm:items-center px-5"
    :style="`background-color: ${props.ciColors.bgColor}`"
  >
    <div
      class="fixed inset-0 sm:inset-auto sm:relative sm:flex sm:items-start w-full max-w-4xl mx-auto sm:pl-[20rem] sm:min-h-[34rem] sm:rounded-md sm:shadow-xl bg-gray-50"
    >
      <div class="sm:w-[20rem] flex items-center justify-between sm:justify-normal gap-4 sm:gap-0 sm:block sm:absolute sm:inset-y-0 sm:left-0 overflow-y-auto px-6 py-4 bg-white sm:rounded-l-md border-b sm:border-b-0 border-b-gray-200 sm:shadow-xl sm:px-12 sm:py-12 sm:pb-8">
        <img
          v-if="accountLogoImageUrl"
          :src="accountLogoImageUrl"
          alt="{{ $t('wizard.logo') }}"
          class="w-auto max-h-14"
        >
        <ApplicationLogo
          v-else
          class="h-12 text-gray-500 fill-current app-logo"
        />

        <div class="text-sm sm:mt-6">
          <div
            :data-cy-sel="'wizard-document-name'"
            class="font-semibold sm:mb-2"
            :style="`color: ${props.ciColors.primaryColor}`"
          >
            {{ $t('wizard.documentName') }}
          </div>
          <span
            class="hidden sm:inline overflow-hyphens"
          >
            {{ $t('wizard.invitedCollaboration', { inviterName: 'John Doe' }) }}
          </span>
        </div>

        <div class="hidden py-6 sm:block">
          <nav
            aria-label="Progress"
          >
            <ol
              role="list"
              class="overflow-hidden"
            >
              <li class="relative pb-6">
                <div
                  class="absolute left-3 top-4 -ml-px mt-0.5 h-full w-0.5"

                  :style="`background-color: ${props.ciColors.primaryColor}`"
                  aria-hidden="true"
                />
                <div
                  class="relative flex items-start group"
                >
                  <span class="flex items-center h-9">
                    <span
                      class="relative z-10 flex items-center justify-center w-6 h-6 rounded-full"

                      :style="`background-color: ${props.ciColors.primaryColor}`"
                    >
                      <CheckIcon
                        class="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span class="flex flex-col min-w-0 ml-4">
                    <span class="text-sm font-medium">{{ $t('checkpoints.step', {number: 1}) }}</span>
                    <span class="text-sm text-gray-500">{{ $t('wizard.steps.user') }}</span>
                  </span>
                </div>
              </li>
              <li class="relative pb-6">
                <div
                  class="absolute left-3 top-4 -ml-px mt-0.5 h-full w-0.5"

                  :style="`background-color: ${props.ciColors.primaryColor}`"
                  aria-hidden="true"
                />
                <div
                  class="relative flex items-start group"
                  aria-current="step"
                >
                  <span
                    class="flex items-center h-9"
                    aria-hidden="true"
                  >
                    <span
                      class="relative z-10 flex items-center justify-center w-6 h-6 bg-white border-2 rounded-full"

                      :style="`border-color: ${props.ciColors.primaryColor}`"
                    >
                      <span
                        class="h-2.5 w-2.5 rounded-full"
                        :style="`background-color: ${props.ciColors.primaryColor}`"
                      />
                    </span>
                  </span>
                  <span class="flex flex-col min-w-0 ml-4">
                    <span
                      class="text-sm font-medium"
                      :style="`color: ${props.ciColors.primaryColor}`"
                    >{{ $t('checkpoints.step', {number: 2}) }}</span>
                    <span class="text-sm text-gray-500">{{ $t('wizard.steps.party') }}</span>
                  </span>
                </div>
              </li>
              <li class="relative">
                <div
                  class="relative flex items-start group"
                >
                  <span
                    class="flex items-center h-9"
                    aria-hidden="true"
                  >
                    <span class="relative z-10 flex items-center justify-center w-6 h-6 bg-white border-2 border-gray-300 rounded-full">
                      <span class="h-2.5 w-2.5 rounded-full bg-transparent" />
                    </span>
                  </span>
                  <span class="flex flex-col min-w-0 ml-4">
                    <span class="text-sm font-medium text-gray-500">{{ $t('checkpoints.step', {number: 3}) }}</span>
                    <span class="text-sm text-gray-500">{{ $t('wizard.steps.done') }}</span>
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="fixed inset-0 w-full pt-16 sm:pt-0 sm:relative sm:inset-auto sm:h-full">
        <div class="w-full h-full overflow-x-hidden">
          <h1
            class="p-6 pb-0 font-semibold sm:p-12 sm:pb-0"
            :style="`color: ${props.ciColors.primaryColor}`"
          >
            {{ $t('checkpoints.step', {number: 2}) }}: {{ $t('wizard.steps.party') }}
          </h1>
          <div class="flex items-start wizard-step-container">
            <div class="flex items-start w-full h-full p-6 pt-4 pb-24 overflow-y-auto sm:p-12 sm:pt-4 sm:pb-32 justify-left">
              <form
                class="w-full space-y-4 text-left"
              >
                <div>
                  <label
                    class="block mb-1 text-sm font-medium truncate"
                  >
                    {{ $t('wizard.partyName') }}
                  </label>
                  <input
                    type="text"
                    class="input-primary"
                    :placeholder="$t('wizard.partyCompanyNamePlaceholder')+'…'"
                  >
                </div>
                <div>
                  <label
                    class="block mb-1 text-sm font-medium truncate"
                  >
                    {{ $t('partyForm.address') }}
                  </label>
                  <textarea
                    type="text"
                    :placeholder="$t('partyForm.addressPlaceholder')+'…'"
                    class="input-primary"
                  />
                </div><div>
                  <label
                    class="block mb-1 text-sm font-medium truncate"
                  >{{ $t('partyForm.signatories') }}</label><ul
                    role="list"
                    class="grid grid-cols-1 -mx-6 divide-y divide-y-gray-200"
                  >
                    <li class="relative mb-4 text-sm last:mb-6">
                      <ul role="list">
                        <div class="flex items-center px-3 py-1">
                          <button
                            type="button"
                            class="btn-plain border-0 hover:bg-gray-100 flex items-center py-1.5 px-3"
                          >
                            <span class="flex items-center justify-center w-8 h-8 text-gray-400 border-2 border-gray-300 border-dashed rounded-full">
                              <PlusIcon class="w-4 h-4 shrink-0" />
                            </span>
                            <span class="ml-3">{{ $t('userSettings.addSignatory') }}</span>
                          </button>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  class="sr-only"
                >
                  {{ $t('common.next') }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="justify-between bg-gray-50 border-t border-t-gray-200 sm:border-t-0 sm:bg-transparent fixed sm:absolute bottom-0 inset-x-0 flex items-center sm:pl-[23rem] py-6 sm:py-12 px-6 sm:px-12"
      >
        <button
          type="button"
          class="inline-flex items-center gap-2 btn-plain focus:ring-gray-200"
        >
          <ArrowLeftIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.back') }}
        </button>
        <div class="flex items-center space-x-2">
          <button
            type="button"
            class="inline-flex items-center gap-2 btn-primary"
            :style="`background-color: ${props.ciColors.primaryColor}; color: ${props.ciColors.textOnPrimaryColor};`"
            @mouseover="(e) => { (e.target as HTMLElement).style.filter = 'brightness(110%)' }"
            @mouseleave="(e) => { (e.target as HTMLElement).style.filter = 'none' }"
          >
            {{ $t('common.next') }}
            <ArrowRightIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

