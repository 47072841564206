<script setup lang="ts">
// external
import { computed, watch } from "vue"

// internal
import { usecases } from "~/utils"
import { OnboardingUsecase } from "~/types"
import { ContractTypeList } from "~/components"

interface Props {
  usecaseId?: OnboardingUsecase["id"]
  selectedUsecaseIds: OnboardingUsecase["id"][]
  selectedContractTypes: string[]
  collapsible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  usecaseId: null,
  collapsible: false,
})

const isSelected = computed(() => props.selectedUsecaseIds.includes(props.usecaseId))

const usecase = computed(() => usecases.find((usecase) => usecase.id === props.usecaseId))

const toggleUsecaseId = () => {
  const localSelectedUsecaseIds = [ ... props.selectedUsecaseIds ]
  const idx = localSelectedUsecaseIds.indexOf(props.usecaseId)

  if (idx !== -1) {
    localSelectedUsecaseIds.splice(idx, 1)

    const contractTypesUniqueToUsecase = usecase.value.contractTypes

    localSelectedContractTypes.value = localSelectedContractTypes.value.filter((selectedContractType) => !contractTypesUniqueToUsecase.includes(selectedContractType))
  } else {
    localSelectedUsecaseIds.push(props.usecaseId)

    // add all usecase specific contract types to selection
    for (const contractType of usecase.value.contractTypes) {
      if (!props.selectedContractTypes.includes(contractType)) {
        localSelectedContractTypes.value.push(contractType)
      }
    }
  }

  emits("update:selected-usecase-ids", localSelectedUsecaseIds)
}

const localSelectedContractTypes = computed({
  get: () => props.selectedContractTypes,
  set: (selectedContractTypes) => emits("update:selected-contract-types", selectedContractTypes),
})

const numberOfSelectedContractTypesInUsecase = computed(() => {
  return usecase.value.contractTypes.filter((value) => localSelectedContractTypes.value.includes(value)).length
})

const emits = defineEmits([ "update:selected-usecase-ids", "update:selected-contract-types" ])

watch(numberOfSelectedContractTypesInUsecase, (newVal) => {
  if (newVal === 0 && isSelected.value) {
    toggleUsecaseId()
  }
})

</script>
<template>
  <!--<button
    v-if="usecaseId"
    type="button"
    class="shrink-0"
    :class="[isSelected ? 'btn-remove' : 'btn-add']"
    @click="toggleUsecaseId"
  >
    <PlusIcon
      aria-hidden="true"
      class="w-3 h-3 transition-all duration-150"
      :class="[isSelected ? 'rotate-45' : '']"
    />
    <span>{{ isSelected ? $t('common.removeAll') : $t('common.addAll') }}</span>
  </button>-->
  <div
    class="relative w-full px-6 py-5 transition-colors rounded-md cursor-pointer group/item bg-gradient-to-r to-gray-100"
    :class="isSelected ? 'from-indigo-50 hover:from-indigo-100 hover:to-gray-100' : 'from-gray-100 hover:from-indigo-50 hover:to-gray-50'"
    @click="toggleUsecaseId"
  >
    <div class="flex items-center space-x-6">
      <div class="shrink-0">
        <component
          :is="usecase.icon"
          aria-hidden="true"
          class="w-10 h-10"
          :class="isSelected ? 'text-indigo-400 group-hover/item:text-indigo-500' : 'text-gray-400 group-hover/item:text-indigo-400'"
        />
      </div>
      <div class="grow">
        <div class="flex items-center mb-1 space-x-5">
          <h3 class="text-base font-medium grow">
            {{ $t('onboarding.usecases.' + usecaseId + '.title') }}
          </h3>
        </div>

        <div
          class="text-xs"
          :class="[isSelected ? 'text-indigo-950/80' : 'text-gray-500 text-indigo-950/80']"
        >
          {{ $t('onboarding.usecases.' + usecaseId + '.description') }}
        </div>
        <div
          v-if="!collapsible || (collapsible && isSelected)"
          class="mt-3"
        >
          <ContractTypeList
            v-model:selected-contract-types="localSelectedContractTypes"
            :contract-types="usecase.contractTypes"
          />
        </div>
      </div>
    </div>

    <div
      class="absolute top-0 bottom-0 left-0 transition-all duration-150 bg-indigo-500 rounded-l-md"
      :class="[isSelected ? 'w-1' : 'w-0']"
    />
  </div>
</template>
