// external
import { mergeAttributes, Node } from "@tiptap/core"
import { TextSelection } from "@tiptap/pm/state"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

// internal
import PageBreakNodeView from "./PageBreakNodeView.vue"
import { trackEventInJune } from "~/utils"
import { JuneEvents } from "~/types"

export interface PageBreakOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    pageBreak: {
      /**
       * Add a page break
       */
      setPageBreak: () => ReturnType
    }
  }
}

export const PageBreak = Node.create<PageBreakOptions>(
  {
    name: "pageBreak",

    atom: true,

    addOptions () {
      return {
        HTMLAttributes: {},
      }
    },

    parseHTML () {
      return [
        {
          tag: "div[data-page-break]",
        },
      ]
    },

    renderHTML ({ HTMLAttributes }) {
      return [
        "div",
        mergeAttributes(
          this.options.HTMLAttributes,
          HTMLAttributes,
          {
            "data-page-break": "",
          },
        ),
      ]
    },

    addNodeView () {
      return VueNodeViewRenderer(PageBreakNodeView)
    },

    addCommands () {
      return {
        setPageBreak:
        () => ({ chain }) => {
          trackEventInJune(JuneEvents.EDITOR_PAGE_BREAK_INSERTED)
          return (
            chain()
              .insertContent(
                {
                  type: this.name,
                },
              )
              // set cursor after page break
              .command(
                ({ tr, dispatch }) => {
                  if (dispatch) {
                    const { $to } = tr.selection
                    const posAfter = $to.end()

                    if ($to.nodeAfter) {
                      tr.setSelection(TextSelection.create(tr.doc, $to.pos))
                    } else {
                      // add node after page break if it’s the end of the document
                      const node = $to.parent.type.contentMatch.defaultType?.create()

                      if (node) {
                        tr.insert(posAfter, node)
                        tr.setSelection(TextSelection.create(tr.doc, posAfter))
                      }
                    }

                    tr.scrollIntoView()
                  }

                  return true
                },
              )
              .run()
          )
        },
      }
    },
  },
)
