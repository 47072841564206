import { useI18n } from "vue-i18n"
import { useNotificationStore } from "~/stores"
import { checkIfSvgHasLinearGradient } from "~/utils"

export const handleSvg = async (file: File) => {

  const { t } = useI18n()
  const notificationStore = useNotificationStore()

  const svgText = await file.text()

  if (checkIfSvgHasLinearGradient(svgText)) {
    notificationStore.notify(
      {
        title: t("common.errorOccured"),
        message: t("editor.errors.svgFilesWithLinearGradientsNotSupported"),
        type: "error",
      },
    )

    return true
  }

  return false
}
