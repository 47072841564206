<script setup lang="ts">
// external
import { ChevronDownIcon } from "@heroicons/vue/24/solid"
import { watch } from "vue"
import { nextTick } from "vue"
import { computed, ref, toRaw } from "vue"
import { VueTelInput, VueTelInputCountryOption } from "vue-tel-input"

// internal
import { SpinLoader } from "~/components"
import { phonePlaceholdersByCountry } from "~/utils"

import { useI18n } from "vue-i18n"
const { t } = useI18n()

interface Props {
  phone?: string
  type?: string
  disabled?: boolean
  required?: boolean
  autofocus?: boolean
  hasError?: boolean
  id?: string
  name?: string
  styleClasses?: string
  showLoader?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    phone: null,
    disabled: false,
    required: false,
    autofocus: false,
    hasError: false,
    id: "",
    name: "",
    styleClasses: "",
    showLoader: false,
  },
)

const emit = defineEmits( [ "update:phone", "validation-error", "validation-passed" ])

const isPassingValidation = ref<boolean>(true)

const initialPhone = ref<string>(toRaw(props.phone) || "")
const localPhone = ref<string>(toRaw(props.phone) || "")

const phone = computed({
  get: () => localPhone.value || props.phone || "",
  set: (value) => {
    if (value !== localPhone.value && !value) emit("update:phone", "")
    localPhone.value = value || ""
  },
})

const updateValidation = (phoneObject) => {
  if (!!(phoneObject.valid) || (!phoneObject.number && initialPhone.value)) {
    isPassingValidation.value = true
  } else {
    isPassingValidation.value = false
  }
  if (!phoneObject.valid && phoneObject.formatted) { emit("validation-error") }
  else { emit("validation-passed") }
}

watch(phone, async (newVal, oldVal) => {
  await nextTick()
  if (newVal !== oldVal && isPassingValidation.value) {
    emit("update:phone", newVal)
  }
})

const placeholder = ref<string>(phonePlaceholdersByCountry["AT"])

const updatePlaceholder = (countryObject: VueTelInputCountryOption) => {
  placeholder.value = phonePlaceholdersByCountry[countryObject.iso2] || phonePlaceholdersByCountry["US"]
}


const inputOptions = computed(() => {
  return {
    showDialCode: false,
    autocomplete: "off",
    required: props.required,
    placeholder: t("common.eg") + " " + placeholder.value,
    id: props.id,
    name: props.name,
    autofocus: props.autofocus,
  }
})

</script>

<template>
  <VueTelInput
    v-model="phone"
    mode="international"
    :auto-format="true"
    :placeholder="$t('common.eg') + ' ' + placeholder"
    :auto-default-country="true"
    :disabled="disabled"
    :input-options="inputOptions"
    :preferred-countries="['AT', 'DE', 'CH']"
    :style-classes="[hasError ? 'has-error' : '', styleClasses]"
    :valid-characters-only="true"
    @validate="updateValidation($event)"
    @country-changed="updatePlaceholder($event)"
  >
    <template #arrow-icon>
      <ChevronDownIcon
        class="w-4 h-4 shrink-0"
        aria-hidden="true"
      />
    </template>
    <template
      v-if="showLoader"
      #icon-right
    >
      <div class="p-2">
        <SpinLoader class="w-5 h-5 shrink-0" />
      </div>
    </template>
  </VueTelInput>
</template>

<style>
@import 'vue-tel-input/vue-tel-input.css';

.vue-tel-input {
  @apply rounded-md flex w-full text-sm text-left focus-within:border-indigo-500 focus-within:ring-indigo-500 focus-within:ring-1;
}
.vue-tel-input.disabled {
  @apply focus-within:ring-0
}
.vue-tel-input.vue-tel-input-plain {
  @apply bg-gray-100;
}
.vue-tel-input.vue-tel-input-plain.vue-tel-input-sm {
  @apply text-xs border-0;
}
.vue-tel-input.vue-tel-input-plain.vue-tel-input-sm input {
  @apply text-sm;
}
.vue-tel-input.has-error,
.vue-tel-input.vue-tel-input-plain.has-error {
  @apply border-red-300 focus-within:border-red-500 focus-within:ring-red-500;
}
.vue-tel-input.disabled .selection,
.vue-tel-input.disabled .dropdown,
.vue-tel-input.disabled input {
  @apply cursor-no-drop disabled:text-gray-400 disabled:bg-gray-50;
}
.vti__dropdown {
  @apply rounded-l-md px-2 py-2.5 flex flex-col content-center justify-center relative cursor-pointer focus:ring-2 focus:ring-indigo-600 focus:bg-indigo-50 focus:outline-none
}
.vti__dropdown svg {
  @apply transition-all
}
.vti__dropdown.show {
  @apply overflow-scroll max-h-[20rem]
}
.vti__dropdown.open {
  @apply bg-gray-100
}
.vti__dropdown.open svg {
  @apply rotate-180
}
.vue-tel-input.disabled .vti__dropdown {
  @apply cursor-no-drop outline-none bg-gray-50 focus:ring-0
}
.vti__dropdown:hover {
  @apply bg-gray-100
}
.vue-tel-input.vue-tel-input-plain .vti__dropdown:hover {
  @apply bg-gray-200
}
.vue-tel-input.vue-tel-input-plain.disabled .vti__dropdown:hover {
  @apply bg-gray-50
}

.vti__selection {
  @apply text-sm flex items-center
}
.vti__selection .vti__country-code {
  @apply text-gray-500
}
.vti__flag {
  @apply mx-2
}
.vti__dropdown-list {
  @apply p-0 m-0 rounded-md z-10 text-xs absolute w-96 mt-2 left-0 text-left list-none max-h-[20rem] shadow-lg bg-gray-900 bg-opacity-95 text-gray-400 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none
}
.vti__dropdown-list strong {
  @apply font-medium text-white
}
.vti__dropdown-list.below {
  top: 33px;
}
.vti__dropdown-list.above {
  top: auto;
  bottom: 100%;
}
.vti__dropdown-arrow {
  @apply scale-y-50 inline-block text-gray-500
}
.vti__dropdown-item {
  @apply cursor-pointer px-2 py-2 flex items-center gap-1 focus:bg-gray-100 focus:outline-none
}
.vti__dropdown-item.highlighted {
  @apply bg-gray-700
}
.vti__dropdown-item.last-preferred {
  @apply border-b border-gray-600
}
.vti__dropdown-item .vti__flag {
  @apply inline-block scale-90
}
.vti__dropdown-item .vti__flag.ch {
  @apply mr-[13px]
}
.vue-tel-input.vue-tel-input-plain .vti__input {
  @apply bg-gray-100
}
.vti__input {
  @apply border-0 rounded-r-md w-full outline-none pl-2 focus:ring-0
}
</style>
