<script lang="ts" setup>
import { SignatureIcon } from "~/icons"
import { SpinLoader } from "~/components"
import { useDocumentStore, useSignatureStore } from "~/stores"
import { Signatory } from "~/types"

interface Props {
  tippyText?: string;
  isDisabled: boolean;
  isLoading: boolean
  style?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tippyText: null,
  isDisabled: false,
  isLoading: false,
  style: "yellow",
})

const documentStore = useDocumentStore()
const { mdu } = documentStore

const signatureStore = useSignatureStore()
const { signatories } = signatureStore

const emit = defineEmits([ "click" ])

const click = () => {
  if (!props.isDisabled) {
    emit("click")
  }
}

</script>

<template>
  <button
    :data-cy-sel="`start-signing-phase-button`"
    type="button"
    :data-tippy-help="!!tippyText ? true : null"
    :data-tippy-content="tippyText"
    data-placement="top"
    :disabled="isDisabled"
    class="flex items-center justify-center btn-sm"
    :class="[
      isDisabled ? 'cursor-not-allowed' : '',
      style === 'green' ? 'btn-green' : 'btn-secondary',
      isDisabled && style === 'green' ? ' hover:bg-green-500 focus:ring-0 opacity-50' : '',
      isDisabled && style === 'yellow' ? ' hover:bg-yellow-200 focus:ring-0 opacity-50' : '',
    ]"
    @click.prevent="click"
  >
    <template v-if="isLoading">
      <SpinLoader
        class="w-4 h-4 mr-2"
      />
      {{ $t('common.pleaseWait') }}…
    </template>
    <template v-else>
      <template v-if="signatories.length === 1 && signatories.some((el: Signatory) => el.uuid = mdu?.uuid)">
        <SignatureIcon
          class="w-4 h-4 mr-2"
          aria-hidden="true"
        />
        {{ $t('documentStatusBanner.startSigningNow') }}
      </template>
      <template v-else>
        <SignatureIcon
          class="w-4 h-4 mr-2"
          aria-hidden="true"
        />
        {{ $t('documentStatusBanner.startSigningProcess') }}
      </template>
    </template>
  </button>
</template>
