<script setup lang="ts">
// external
import { computed } from "vue"
import { CheckCircleIcon } from "@heroicons/vue/24/solid"
import { storeToRefs } from "pinia"

// internal
import { Coupon, StripePrice, StripeProduct } from "~/types"
import { useAccountStore } from "~/stores"

interface Props {
  product?: StripeProduct
  interval?: string
  currency?: string
  couponCode?: string
  coupon?: Coupon
}

const props = withDefaults(
  defineProps<Props>(),
  {
    product: null,
    interval: "year",
    currency: "usd",
    couponCode: null,
    coupon: null,
  },
)

const accountStore = useAccountStore()
const { account, mau } = storeToRefs(accountStore)

const price = computed<StripePrice>(() => {
  if (!props.product) return null
  return props.product.prices.find((price) => price.recurring.interval === props.interval)
})

const amount = computed(() => {
  if (!props.product) return 0

  let tmpAmount = 0

  if (price.value) {
    const amount =  price.value.unit_amount / 100
    tmpAmount = props.interval === "year" ? amount / 12 : amount
  }

  return tmpAmount
})

const actualAmount = computed(() => {
  let tmpAmount = amount.value

  let discountPercentage = props.coupon?.percent_off || 0

  // Put special codes here
  if (props.couponCode === "PHLOVE50") {
    discountPercentage = 50
  }

  tmpAmount = Math.floor(tmpAmount * (1 - (discountPercentage / 100)))

  return tmpAmount
})

const features = computed(() => {
  if (!props.product) return {}

  const tmpMetadata = Object.keys(props.product.metadata).sort().reduce(
    (obj, key) => {
      const sanitizedKey = key.split("_")[1]
      if (props.product.metadata[key] !== false) {
        obj[sanitizedKey] = props.product.metadata[key]
      }
      return obj
    },
    {},
  )
  return tmpMetadata
})

const currencySymbol = computed(() => {
  return props.currency === "eur" ? "€" : "$"
})

const isRecommended = computed(() => {
  if (!props.product) return false

  return props.product.name === "Growth"
})

const couponCodeToUse = computed(() => props.coupon?.code || props.couponCode || null)

</script>

<template>
  <div
    class="rounded-xl p-[2px] relative @container/entry"
    :class="[isRecommended ? 'bg-gradient-to-r from-indigo-500 to-fuchsia-600' : 'bg-gray-200']"
  >
    <div
      class="rounded-[10px] p-5 h-full"
      :class="[isRecommended ? 'bg-gradient-to-br from-indigo-100 to-fuchsia-100' : 'bg-gray-100']"
    >
      <div class="flex items-center justify-between gap-x-4">
        <h2
          class="text-lg leading-8 font-bold"
          :class="isRecommended ? 'bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-fuchsia-600' : 'text-gray-600'"
        >
          <slot name="name">
            {{ product.name }}
          </slot>
        </h2>
        <div
          v-if="isRecommended"
          class="absolute -top-[15px] left-0 right-0 flex items-center justify-center"
        >
          <p
            class="w-full text-center rounded-t-xl bg-gradient-to-r from-indigo-500 to-fuchsia-600 px-2.5 py-1 text-xs font-semibold leading-5 text-white"
          >
            {{ $t('accountSettings.billing.mostPopular') }}
          </p>
        </div>
      </div>
      <div class="mt-6 flex items-center gap-x-2 relative">
        <div
          v-if="actualAmount !== amount"
          class="absolute bottom-full line-through text-sm"
          :class="isRecommended ? 'text-indigo-500' : 'text-gray-600'"
        >
          {{ amount }} {{ currencySymbol }}
        </div>
        <slot name="price">
          <span
            class="text-3xl @[200px]/entry:text-4xl font-bold tracking-tight"
            :class="isRecommended ? 'text-indigo-500' : 'text-gray-600'"
          >
            {{ actualAmount }} {{ currencySymbol }}
          </span>
          <div class="text-sm leading-4 text-gray-900">
            <div>{{ $t('accountSettings.billing.per') }}</div>
            <div>
              {{ $t('accountSettings.billing.intervals.month') }}<span class="text-indigo-500">*</span>
            </div>
          </div>
        </slot>
      </div>
      <slot name="button">
        <a
          :href="price?.payment_link + '?client_reference_id=' + account?.uuid + '&locale=' + mau?.user?.locale + (couponCodeToUse ? `&prefilled_promo_code=${couponCodeToUse}` : '')"
          class="block w-full mt-5 text-center"
          :class="[isRecommended ? 'btn-primary bg-gradient-to-r from-indigo-500 to-fuchsia-600 hover:from-indigo-600 hover:to-fuchsia-700' : 'btn-white']"
        >{{ $t('accountSettings.billing.selectPlan') }}</a>
      </slot>

      <ul
        role="list"
        class="mt-12 space-y-2 text-sm leading-6 text-gray-900"
      >
        <slot name="features">
          <li
            v-for="(featureValue, featureKey) in features"
            :key="featureKey"
            class="flex items-center"
          >
            <CheckCircleIcon
              class="h-5 w-5 flex-none text-indigo-500 mr-2"
              aria-hidden="true"
            />
            <span
              v-if="featureValue !== true && featureValue !== 0"
              class="mr-1"
            >
              {{ featureValue }}
            </span>
            <span>
              <span
                v-if="featureValue === 0"
              >
                {{ $t('common.unlimited') }}
              </span>
              {{ $t('accountSettings.billing.features.' + featureKey + '.title') }}</span>
          </li>
        </slot>
      </ul>
    </div>
  </div>
</template>
