<script setup lang="ts">
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { Account, Team } from "~/types"
import { TeamIconDisplay } from "~/components"
import { Link } from "@inertiajs/vue3"

interface Props {
  teams: Team[]
  account?: Account
  removable?: boolean
  clickable?: boolean
  layout?: string
  extraClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  removable: false,
  clickable: false,
  layout: "default",
  extraClasses: "",
})


defineEmits([ "remove" ])
</script>

<template>
  <div
    class="flex flex-wrap gap-1"
  >
    <component
      :is="props.clickable ? Link : 'span'"
      v-for="team in props.teams"
      :key="'team_' + team.uuid"
      :title="team.name"
      class="inline-flex items-center max-w-[160px] px-2 py-1 text-xs rounded-full md:max-w-xs lg:max-w-full"
      :class="[extraClasses, {'hover:bg-indigo-100 transition-colors duration-150': clickable}, layout === 'default' ? 'bg-gray-100' : 'bg-gray-800 group-hover:bg-gray-700']"
      :href="props.clickable ? route('account-settings.teams.show', {
        account: account.uuid,
        team: team.uuid
      }) : undefined"
    >
      <TeamIconDisplay
        :team="team"
        class="mr-1.5 h-4 w-4 -my-1"
      />
      <span class="truncate">{{ team.name }}</span>
      <button
        v-if="props.removable"
        type="button"
        class="ml-1.5 -mr-1 text-gray-300 hover:text-indigo-500 transition-colors duration-150"
        @click="$emit('remove', team)"
      >
        <XCircleIcon
          aria-hidden="true"
          class="w-4 h-4 shrink-0"
        />
        <span class="sr-only">{{ $t('common.remove') }}</span>
      </button>
    </component>
  </div>
</template>
