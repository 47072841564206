import { Extension, ChainedCommands } from "@tiptap/core"
import formatCopy, { TStateNotify } from "./formatCopy"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    formatPainter: {
      enableFormatPainter: (options: {
        once: boolean;
        getChain: () => ChainedCommands;
      }) => ReturnType;
      watchFormatPainterState: (fn: TStateNotify) => ReturnType;
    };
  }
}

export const FormatPainter = Extension.create<void>({
  name: "formatPainter",

  addCommands () {
    return {
      enableFormatPainter: ({ once, getChain }) => (props) => {
        if (formatCopy.isCopying) {
          formatCopy.Stop()
        } else {
          formatCopy.getChain = getChain
          formatCopy.Copy(once, props.editor.getJSON.bind(props.editor), props.editor)
        }
        return true
      },
      watchFormatPainterState: (fn: TStateNotify) => () => {
        formatCopy.stateNotifyList.push(fn)
        return true
      },
    }
  },
})
