<script setup lang="ts">
// external
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { NoSymbolIcon } from "@heroicons/vue/24/solid"
import { computed } from "vue"

// internal
import { CurrencyOption } from "~/types"
import { currencyOptions } from "~/utils"

interface Props {
  currency: string,
  removable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  removable: false,
})

const localCurrency = computed<CurrencyOption>(() => {
  return currencyOptions.find((el) => el.code === props.currency)
})

defineEmits([ "remove" ])
</script>
<template>
  <div
    class="inline-flex items-center justify-between px-2 py-0.5 space-x-1 text-xs rounded-full cursor-default bg-gray-200 group"
  >
    <span class="whitespace-nowrap">{{ localCurrency.code }} ({{ localCurrency.symbol }})</span>
    <button
      v-if="removable"
      type="button"
      class="text-gray-400 cursor-pointer group-hover:text-gray-900"
      @click="$emit('remove', localCurrency.code)"
    >
      <XCircleIcon
        aria-hidden="true"
        class="w-4 h-4 -mr-1"
      />
      <span class="sr-only">{{ $t('common.remove') }}</span>
    </button>
    <span
      v-else
      class="text-gray-400 cursor-not-allowed"
      data-tippy-help
      :data-tippy-content="$t('dynamicFields.currencyCannotBeRemoved')"
      data-placement="top"
    >
      <NoSymbolIcon
        aria-hidden="true"
        class="w-4 h-4 -mr-1"
      />
    </span>
  </div>
</template>
