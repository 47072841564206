<script setup lang="ts">
// external
import { ref, onBeforeMount } from "vue"

// internal
import { MetadataValueInput, ClauseInput, ReferenceQuotation } from "~/components"
import { BoundingBox, Metadata, MultiFieldType } from "~/types"
import { isoDurationToWords } from "~/utils"

interface Props {
  item: any
  options?: any[]
  metadata?: Metadata
  uuidOfIsActiveMetadata?: Metadata["uuid"]
  uuidOfIsActiveBoundingBox?: string
  boundingBox?: BoundingBox[]
}
const props = withDefaults(
  defineProps<Props>(),
  {
    metadata: null,
    options: () => [],
    uuidOfIsActiveMetadata: null,
    uuidOfIsActiveBoundingBox: null,
    boundingBox: null,
  },
)

const emit = defineEmits([ "select-bounding-box", "deselect-bounding-box", "update-value" ])

const selectedValue = ref(props.item.value)

const initialOptions = ref([])

const setValue = (value) => {
  if (value !== selectedValue.value) {
    emit("update-value", value)
    selectedValue.value = value
  }
}

const quotation = ref(props.item.quotation ?? null)

const setOption = (option) => {
  quotation.value = option.quotation
  setValue(option.value)
  emit("select-bounding-box", option)
}

onBeforeMount(() => {
  initialOptions.value = JSON.parse(JSON.stringify(props.options))
})

</script>
<template>
  <div
    v-if="metadata"
    class="relative"
    :class="[
      metadata?.value_type === MultiFieldType.clause ? 'hover:bg-purple-50' : 'hover:bg-gray-50', (props.boundingBox || props.uuidOfIsActiveBoundingBox !== props.uuidOfIsActiveMetadata) ? 'cursor-pointer' : '',
      (metadata?.value_type === MultiFieldType.clause || uuidOfIsActiveMetadata === metadata?.uuid) && item.quotation ? 'pb-4' : ''
    ]"
    @click="metadata?.value_type === MultiFieldType.clause || !initialOptions?.length || initialOptions?.length < 2 ? $emit('select-bounding-box', item) : () => {}"
  >
    <div
      v-if="uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === metadata?.uuid && (props.boundingBox || props.uuidOfIsActiveBoundingBox !== props.uuidOfIsActiveMetadata)"
      class="absolute rounded-md pointer-events-none inset-y-1 inset-x-4"
      :class="
        uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === metadata?.uuid ?
          metadata?.value_type === MultiFieldType.clause ? 'bg-purple-500/10 border-2 border-purple-700' : 'bg-indigo-500/10 border-2 border-indigo-700' : ''"
    />
    <ClauseInput
      v-if="metadata.value_type === MultiFieldType.clause"
      :metadata="metadata"
      :proposed-value="selectedValue"
      :is-extraction="true"
      @update:clause="setValue"
    />
    <MetadataValueInput
      v-else
      :metadata-value="null"
      :metadata="metadata"
      :proposed-value="selectedValue"
      :is-extraction="true"
      :is-highlighted="!!(uuidOfIsActiveMetadata && uuidOfIsActiveMetadata === metadata?.uuid) && !!(props.boundingBox || props.uuidOfIsActiveBoundingBox !== props.uuidOfIsActiveMetadata)"
      @update:metadata-value="setValue"
      @focus="$emit('select-bounding-box', item)"
      @blur="$emit('deselect-bounding-box')"
    />
    <div
      v-if="initialOptions.length > 1"
      class="px-6 mb-3"
    >
      <div class="mb-1 text-gray-500">
        {{ $t('aiAnalysis.suggestedValues') }}:
      </div>
      <div
        class="block w-full overflow-hidden bg-gray-100 divide-y divide-white rounded"
      >
        <button
          v-for="(entry, index) in initialOptions"
          :key="`${entry.name}_${index}`"
          type="button"
          class="flex w-full items-center py-1.5 px-2 text-sm"
          :class="entry.value === selectedValue ? 'font-medium bg-gradient-to-r from-indigo-100 to-purple-100' : 'hover:bg-gradient-to-r hover:from-indigo-100/60 to-gray-100'"
          @click="setOption(entry)"
        >
          <span
            class="flex items-center justify-center w-3 h-3 mr-2 border-2 rounded-full"
            :class="entry.value === selectedValue ? 'border-white bg-indigo-400 ring-1 ring-indigo-400' : 'border-gray-300 bg-white'"
          />
          <span>{{ metadata?.value_type === MultiFieldType.duration ? isoDurationToWords(entry.value, $t) : entry.value }}</span>
        </button>
      </div>
    </div>
    <ReferenceQuotation
      :metadata="metadata"
      :item="item"
      :uuid-of-is-active-bounding-box="uuidOfIsActiveMetadata"
      :quotation="quotation"
    />
  </div>
  <div
    v-else
    class="px-6 py-3 text-red-600"
  >
    Unknown metadata: {{ item.name }}
  </div>
</template>
