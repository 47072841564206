import { Mark, mergeAttributes } from "@tiptap/core"

export const Multiline = Mark.create({
  name: "multiline",
  parseHTML () {
    return [
      { tag: "span.multiline" },
    ]
  },
  renderHTML ({ HTMLAttributes }) {

    const customAttrs = {
      class: "multiline",
    }

    return [ "span", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttrs), 0 ]
  },
})
