<script setup lang="ts">
import {
  IdAustriaLogo,
  DTrustLogo,
  ZealIdLogo,
  EvrotrustLogo,
  EstonianMobileIdLogo } from "~/icons"
import { SpinLoader } from "~/components"

interface Props {
  isLoadingEidEasyMethod?: string
}

withDefaults(defineProps<Props>(), {
  isLoadingEidEasyMethod: null,
})

defineEmits([ "select-eid-easy-method" ])

const eidEasyMethods = [
  {
    id: 1,
    label: "ID Austria / HandySignatur",
    method: "austrian-handy-signatur",
    icon: IdAustriaLogo,
  },
  {
    id: 2,
    label: "D-Trust sign-me	",
    method: "d-trust-sign-me",
    icon: DTrustLogo,
  },
  {
    id: 3,
    label: "ZealiD",
    method: "zeal-id",
    icon: ZealIdLogo,
  },
  {
    id: 4,
    label: "Evrotrust",
    method: "evrotrust",
    icon: EvrotrustLogo,
  },
  {
    id: 5,
    label: "Mobile ID",
    method: "estonian-mobile-id",
    icon: EstonianMobileIdLogo,
  },
/*   {
    id: 2,
    label: "Finnish Trust Network / Mobile ID",
    method: "finnish-trust-network",
    icon: FinnishTrustNetworkLogo,
  },
  {
    id: 3,
    label: "Swedish BankID",
    method: "swedish-bank-id",
    icon: SwedishBankIdLogo,
  },
  {
    id: 4,
    label: "Norwegian BankID",
    method: "norwegian-bank-id",
    icon: NorwegianBankIdLogo,
  },
  {
    id: 5,
    label: "Estonian MobileID",
    method: "estonian-mobile-id",
    icon: EstonianMobileIdLogo,
  }, */
]

</script>
<template>
  <div class="grid grid-cols-1 -space-y-px bg-white rounded-md">
    <button
      v-for="option, optionIdx in eidEasyMethods"
      :key="option.method"
      :class="[optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', optionIdx === eidEasyMethods.length - 1 ? 'rounded-bl-md rounded-br-md' : '', 'focus:z-10 focus:border-indigo-200 transition-all hover:border-indigo-500 hover:ring-1 hover:ring-indigo-500 hover:z-10 focus:bg-indigo-50 border-gray-200 relative flex items-center cursor-pointer border p-3 focus:outline-none group', option.method === isLoadingEidEasyMethod ? 'pr-10' : '']"
      :disabled="option.method === isLoadingEidEasyMethod"
      @click="$emit('select-eid-easy-method', option.method)"
    >
      <span
        class="flex-none w-32 group-focus:text-indigo-600 text-gray-300 rounded-md flex items-center p-0.5 shrink-0 cursor-pointer border-0 justify-center"
        aria-hidden="true"
      >
        <component
          :is="option.icon"
          class="max-w-[8rem] max-h-[1.2rem] w-full h-full mx-auto"
        />
      </span>
      <span class="flex ml-3 text-sm font-medium text-gray-900 truncate group-focus:text-indigo-900">
        {{ option.label }}
      </span>
      <SpinLoader
        v-if="option.method === isLoadingEidEasyMethod"
        class="absolute inset-y-0 flex top-1/2 -mt-2.5 right-2 w-5 h-5"
      />
    </button>
  </div>
</template>
