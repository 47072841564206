<script setup lang="ts">
// external
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  RadioGroupDescription,
} from "@headlessui/vue"
import { HomeIcon } from "@heroicons/vue/24/outline"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { computed, onMounted, ref, watch } from "vue"
import axios from "axios"
import { z } from "zod"
import { useField, useForm } from "vee-validate"
import { toTypedSchema } from "@vee-validate/zod"

// internal
import { FormInputErrors } from "~/components"
import { Team, Document, Party, Template, DocumentType } from "~/types"
import { useNotificationStore } from "~/stores"
import { documentTypes } from "~/utils"

interface Props {
  accountParties: Party[]
  teams?: Team[]
  templates?: Template[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    teams: () => [],
    templates: () => [],
  },
)

const { notify } = useNotificationStore()

const templateFormSchema = z
  .object(
    {
      name: z.string(),
      description: z.number().nullable().optional(),
      document_type: z.nativeEnum(DocumentType),
      account_party_uuid: z.string(),
    },
  )

type TemplateFormValidatedType = z.infer<typeof templateFormSchema>

const templateFormValidator = toTypedSchema(templateFormSchema)

const { errors, setFieldValue, resetForm, validate } = useForm<TemplateFormValidatedType>(
  {
    validationSchema: templateFormValidator,
  },
)

const formValidationErrors = ref<Partial<Record<keyof TemplateFormValidatedType, string[]>>>({})
const backendErrors = ref<Partial<Record<keyof TemplateFormValidatedType, string[]>>>({})

const name = useField<string>("name")
const document_type = useField<DocumentType>("document_type")
const account_party_uuid = useField<Party["uuid"]>("account_party_uuid")

const accountPartySelected = computed<Party>(() => props.accountParties.find((el) => el.uuid === account_party_uuid?.value?.value) )

const emptyTemplateForm = Object.freeze<Partial<TemplateFormValidatedType>>({ account_party_uuid: props.accountParties[0]?.uuid })

const localTemplateForm = ref<Partial<TemplateFormValidatedType>>({ ...emptyTemplateForm })

const updateFieldValue = (fieldName: keyof TemplateFormValidatedType, val: any) => {
  setFieldValue(fieldName as any, val)
  localTemplateForm.value = {
    ...localTemplateForm.value || {},
    [fieldName]: val,
  }
}

const errorsToShow = computed<Partial<Record<keyof TemplateFormValidatedType, string[]>>>(
  () => {
    const errors: Partial<Record<keyof TemplateFormValidatedType, string[]>> = {}

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

watch(
  () => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof Document, string[]>>,
      )
  },
)

const handleCreateTemplate = async (): Promise<void> => {
  const isValid = await validate(
    {
      mode: "force",
    },
  )
  if (!isValid.valid) return
  const payload = {
    name: name.value.value,
    account_party_uuid: account_party_uuid.value.value,
    document_type: document_type.value.value,
  }
  const submitRoute = route("api.template-init.create-template-from-scratch")
  return axios.post(submitRoute, payload).then((res) => {
    const newUuid = res.data.data.uuid
    backendErrors.value = null
    return newUuid
  }).catch((err) => {
    backendErrors.value = err.response?.data?.errors
    notify({
      title: "Template could not be created",
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  })
}

const resetTemplateForm = () => {
  resetForm()
  setFieldValue("account_party_uuid", props.accountParties[0]?.uuid)
  localTemplateForm.value = { ...emptyTemplateForm }
}

onMounted(() => {
  resetTemplateForm()
})

const emit = defineEmits([ "submit" ])

// When enter is pressed, submit the form
const handleEnter = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    emit("submit")
  }
}

defineExpose({ resetTemplateForm, handleCreateTemplate })

</script>
<template>
  <div class="flex flex-col h-full px-6 pb-6 space-y-4 grow">
    <div>
      <label
        for="template-name"
        class="block text-sm font-medium text-gray-900"
      >
        {{ $t('dynamicFields.form.name') }} <span class="text-indigo-500">*</span>
      </label>
      <div class="mt-1">
        <input
          id="template-name"
          :value="name.value.value"
          :placeholder="$t('dynamicFields.form.namePlaceholder') + '…'"
          type="text"
          name="template-name"
          class="input-plain"
          :class="[errorsToShow?.name?.length ? 'input-has-errors' : '']"
          @input="($event) => updateFieldValue('name', ($event.target as HTMLInputElement).value)"
          @keydown="handleEnter"
        >
      </div>
      <FormInputErrors
        v-if="errorsToShow?.name?.length"
        :errors="errorsToShow?.name"
      />
    </div>

    <div>
      <Listbox
        :model-value="account_party_uuid.value.value"
        as="div"
        @update:model-value="($event) => updateFieldValue('account_party_uuid', $event)"
      >
        <ListboxLabel
          class="block text-sm font-medium text-gray-700"
        >
          {{ $t('documents.create.formFields.myParty') }} <span class="text-indigo-500">*</span>
        </ListboxLabel>
        <div class="relative mt-1">
          <ListboxButton
            class="btn-listbox-plain"
          >
            <div
              v-if="accountPartySelected"
              class="flex items-center"
            >
              <HomeIcon
                class="w-4 h-4 mr-2"
              />
              <span class="block truncate">
                {{ accountPartySelected?.entity_name }}
              </span>
            </div>
            <div
              v-else
              class="text-gray-500"
            >
              <span class="block truncate">
                {{ $t('partyForm.selectParty') }}…
              </span>
            </div>
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="z-20 listbox-options">
              <ListboxOption
                v-for="accountParty in accountParties"
                :key="accountParty.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="accountParty.uuid"
              >
                <li
                  :class="[
                    active ? 'bg-gray-700' : '',
                    'listbox-option',
                  ]"
                >
                  <div class="flex items-center">
                    <HomeIcon
                      class="w-4 h-4 mr-2 text-gray-500 shrink-0"
                    />
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ accountParty.entity_name }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <FormInputErrors
        v-if="errorsToShow?.account_party_uuid?.length"
        :errors="errorsToShow?.account_party_uuid"
      />
    </div>
    <div
      class="text-sm"
    >
      <RadioGroup
        :model-value="document_type.value.value"
        @update:model-value="($event) => updateFieldValue('document_type', $event)"
      >
        <RadioGroupLabel class="font-medium">
          {{ $t('documents.create.formFields.documentType') }}  <span class="text-indigo-500">*</span>
        </RadioGroupLabel>
        <div class="mt-1 -space-y-px bg-white rounded-md">
          <RadioGroupOption
            v-for="(documentType, documentTypeIdx) in documentTypes"
            :key="documentType.type"
            v-slot="{ checked, active }"
            as="template"
            :value="documentType.type"
            @keydown="handleEnter"
          >
            <div :class="[documentTypeIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', documentTypeIdx === documentTypes.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200', 'relative flex items-center cursor-pointer border p-3 focus:outline-none']">
              <span
                :class="[checked ? 'text-indigo-600' : 'text-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-600' : '', 'rounded-md flex items-center p-0.5 shrink-0 cursor-pointer border-0 justify-center']"
                aria-hidden="true"
              >
                <component
                  :is="documentType.icon"
                  class="w-10 h-10"
                />
              </span>
              <span class="flex flex-col ml-3">
                <RadioGroupLabel
                  as="span"
                  :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']"
                >{{ $t(`documentTypes.${documentType.type}.name`) }}</RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  :class="[checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm']"
                >{{ $t(`documentTypes.${documentType.type}.description`) }}</RadioGroupDescription>
              </span>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
      <FormInputErrors
        v-if="errorsToShow?.document_type?.length"
        :errors="errorsToShow?.document_type"
      />
    </div>
  </div>
</template>
