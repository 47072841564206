<script setup lang="ts">
// external
import { Form, Field } from "vee-validate"
import { ref, onBeforeMount, computed, watch } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, InformationCircleIcon } from "@heroicons/vue/20/solid"
import { storeToRefs } from "pinia"
import { TrashIcon, StarIcon } from "@heroicons/vue/24/outline"
import { StarIcon as StarIconSolid, BuildingOffice2Icon, UserIcon, UserGroupIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import { TeamIconDisplay, SpinLoader } from "~/components"
import { useConfirmationStore, useDashboardStore } from "~/stores"
import { Dashboard, DashboardScope } from "~/types"

interface Props {
  dashboard?: Dashboard
}

const props = withDefaults(
  defineProps<Props>(),
  {
    dashboard: null,
  },
)

const { t } = useI18n()

const dashboardStore = useDashboardStore()
const { mau, teamOptions, dashboards, showMenu } = storeToRefs(dashboardStore)
const { createOrUpdateDashboard, deleteDashboard, setActiveDashboardUuid, toggleEditMode } = dashboardStore

const scopeOptions = ref([ DashboardScope.personal, DashboardScope.team ])
const selectedScope = ref(props.dashboard?.scope ?? DashboardScope.personal)
const selectedTeam = ref(props.dashboard?.scope === DashboardScope.team ? teamOptions.value.find((team) => team.uuid === props.dashboard.dashboardable_uuid) : teamOptions.value[0])
const isSubmitting = ref(false)
const isDefault = ref(false)

const localDashboard = ref(props.dashboard ? {
  ...props.dashboard,
  name: !!props.dashboard ? (props.dashboard.name ? props.dashboard.name : t("dashboard.tabs." + props.dashboard.key)) : "",
} : null)

watch(() => props.dashboard, (newVal) => {
  localDashboard.value = {
    ...newVal,
    name: !!props.dashboard ? (props.dashboard.name ? props.dashboard.name : t("dashboard.tabs." + props.dashboard.key)) : "",
  }
})

const onSubmit = async (values, { resetForm }) => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    let payload

    if (!localDashboard.value || [ "account", "personal" ].includes(localDashboard.value.uuid)) {

      let dashboardableUuid = mau.value.uuid

      if (selectedScope.value === DashboardScope.account) {
        dashboardableUuid = mau.value.account_uuid
      } else if (selectedScope.value === DashboardScope.team) {
        dashboardableUuid = selectedTeam.value.uuid
      }

      payload = {
        ...values,
        scope: selectedScope.value,
        dashboardable_uuid: dashboardableUuid,
        layout: localDashboard.value?.layout ?? null,
        filter: localDashboard.value?.filter ?? null,
        is_default: !!isDefault.value,
      }
    } else {
      payload = {
        uuid: props.dashboard.uuid,
        scope: props.dashboard.scope,
        dashboardable_uuid: props.dashboard.scope === DashboardScope.team ? selectedTeam.value.uuid : props.dashboard.dashboardable_uuid,
        name: values.name,
        is_default: !!isDefault.value,
      }
    }

    const dashboard = await createOrUpdateDashboard(payload)

    if (dashboard) {
      setActiveDashboardUuid(dashboard.uuid)

      // new dashboard is default
      if (isDefault.value && (!props.dashboard || [ "account", "personal" ].includes(props.dashboard.uuid))) {
        mau.value.dashboard_uuid = dashboard.uuid

      // existing dashboard...
      } else if (props.dashboard && props.dashboard.uuid === dashboard.uuid) {
        // ...is no longer default -> remove default
        if (mau.value.dashboard_uuid === dashboard.id && !isDefault.value) {
          mau.value.dashboard_uuid = null

        // ...is the new default -> set default
        } else if (isDefault.value) {
          mau.value.dashboard_uuid = dashboard.uuid
        }
      }
    }

    resetForm()
    isSubmitting.value = false
    if (showMenu.value) {
      toggleEditMode()
    }
  }
}

const confirmationStore = useConfirmationStore()
const { setShowConfirmModal, setConfirmOptions } = confirmationStore

const confirmRemoval = () => {
  setConfirmOptions({
    title: t("dashboard.confirmRemoveTitle"),
    description: t("dashboard.confirmRemoveDescription"),
    buttonText: t("common.remove"),
    callback: removeDashboard,
  })
  setShowConfirmModal(true)
}

const removeDashboard = async () => {
  if (!!props.dashboard) {
    await deleteDashboard(props.dashboard.uuid)
    if (showMenu.value) {
      toggleEditMode()
    }
  }
}

const showRemoveButton = computed(() => {
  if (!props.dashboard) {
    return false
  }

  const numberOfAccountDashboards = dashboards.value.filter((dashboard) => dashboard.scope === DashboardScope.account).length
  const numberOfPersonalDashboards = dashboards.value.filter((dashboard) => dashboard.scope === DashboardScope.personal).length

  // dont allow to remove last personal/account dashboard
  if (props.dashboard.scope === DashboardScope.account && numberOfAccountDashboards === 1) {
    return false
  }
  if (props.dashboard.scope === DashboardScope.personal && numberOfPersonalDashboards === 1) {
    return false
  }

  return true
})

onBeforeMount(() => {
  if (mau.value?.roles[0].name === "account-owner") {
    scopeOptions.value.push(DashboardScope.account)
  }
  isDefault.value = !!mau.value?.dashboard_uuid && !!props.dashboard && mau.value?.dashboard_uuid === props.dashboard.uuid
})
</script>
<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 border-b shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('documentGeneralSettings.title') }}
      </h3>
    </div>

    <div class="space-y-6 px-6 py-4">
      <Form
        v-slot="{ errors }"
        class="space-y-5"
        @submit="onSubmit"
      >
        <div>
          <label
            for="dashboardName"
            class="block mb-1 text-sm"
          >{{ $t('dashboard.form.name') }} <span class="text-indigo-500">*</span></label>
          <Field
            id="dashboardName"
            v-model="localDashboard.name"
            name="name"
            type="text"
            class="input-plain"
            :class="[errors.name ? 'error' : '']"
            rules="required"
            :placeholder="$t('dashboard.form.namePlaceholder') + '…'"
            :disabled="isSubmitting"
          />
        </div>
        <div>
          <div
            class="block mb-1 text-sm"
          >
            {{ $t('dashboard.form.scope') }}
          </div>
          <span
            v-if="dashboard"
            class="flex items-center text-sm"
          >
            <BuildingOffice2Icon
              v-if="dashboard.scope === DashboardScope.account"
              aria-hidden="true"
              class="w-4 h-4 mr-2 shrink-0 text-indigo-400"
            />
            <UserGroupIcon
              v-if="dashboard.scope === DashboardScope.team"
              aria-hidden="true"
              class="w-4 h-4 mr-2 shrink-0 text-indigo-400"
            />
            <UserIcon
              v-if="dashboard.scope === DashboardScope.personal"
              aria-hidden="true"
              class="w-4 h-4 mr-2 shrink-0 text-indigo-400"
            />
            <div>
              <span>{{ $t('dashboard.scopes.' + dashboard.scope + '.title') }}</span>
            </div>
          </span>
        </div>
        <div v-if="selectedScope === DashboardScope.team">
          <div
            class="block mb-1 text-sm"
          >
            {{ $t('dashboard.form.team') }} <span class="text-indigo-500">*</span>
          </div>
          <Listbox
            v-model="selectedTeam"
            :disabled="isSubmitting"
            as="div"
          >
            <div class="relative mt-1">
              <ListboxButton class="btn-listbox-plain">
                <div
                  v-if="selectedTeam"
                  :disabled="isSubmitting"
                  class="flex items-center"
                >
                  <TeamIconDisplay
                    :team="selectedTeam"
                    class="w-4 h-4 mr-2"
                  />
                  <span>{{ selectedTeam.name }}</span>
                </div>
                <span
                  v-else
                  class="block text-gray-500 truncate"
                >{{ $t('dashboard.form.selectTeam') }}…</span>

                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions class="listbox-options">
                  <ListboxOption
                    v-for="team in teamOptions"
                    :key="'team_' + team.id"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="team"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center']">
                        <TeamIconDisplay
                          :team="team"
                          class="w-4 h-4 mr-2"
                        />
                        <span>{{ team.name }}</span>
                      </span>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-indigo-500',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        ]"
                      >
                        <CheckIcon
                          class="shrink-0 w-5 h-5"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
        <div>
          <button
            class="flex items-center text-sm disabled:opacity-50"
            type="button"
            :disabled="isSubmitting"
            @click="isDefault = !isDefault"
          >
            <StarIconSolid
              v-if="isDefault"
              aria-hidden="true"
              class="w-5 h-5 text-yellow-500"
            />
            <StarIcon
              v-if="!isDefault"
              aria-hidden="true"
              class="w-5 h-5 text-gray-400"
            />
            <span class="ml-2 mr-1">{{ $t('dashboard.isDefault') }}</span>
            <span
              data-tippy-help
              :data-tippy-content="$t('dashboard.defaultInfo')"
              data-placement="bottom"
            >
              <InformationCircleIcon
                class="w-4 h-4 text-indigo-400"
                aria-hidden="true"
              />
            </span>
          </button>
        </div>
        <div
          class="flex items-center space-x-2"
          :class="[showRemoveButton ? 'justify-between' : 'justify-end']"
        >
          <button
            v-if="showRemoveButton"
            type="button"
            class="flex items-center gap-2 text-gray-400 btn-plain btn-sm hover:text-red-500 hover:bg-red-100"
            @click.prevent="confirmRemoval"
          >
            <TrashIcon
              class="shrink-0 w-4 h-4"
              aria-hidden="true"
            />
            <span>
              {{ $t('common.remove') }}
            </span>
          </button>
          <button
            type="submit"
            :disabled="isSubmitting"
            class="btn-primary flex items-center gap-2"
          >
            <SpinLoader
              v-if="isSubmitting"
              class="shrink-0 w-5 h-5"
            />
            <span>{{ !!localDashboard ? $t('common.save') : $t('common.create') }}</span>
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>
