<script setup lang="ts">
// external
import { computed, ref, nextTick } from "vue"
import { MenuItem } from "@headlessui/vue"
import { Link } from "@inertiajs/vue3"
import axios from "axios"
import { useI18n } from "vue-i18n"
import {
  DocumentDuplicateIcon,
  PlayCircleIcon,
} from "@heroicons/vue/24/outline"

// internal
import { MagicTableEntry, StageDisplay, TeamList, UserDisplay, PartyEntityTypeDisplayIcon, TagList, MagicTableTimestamps } from "~/components"
import { useStages } from "~/composables"
import { DocumentSnippet, DocumentType, DocumentStage } from "~/types"
import { getTemplateByUuid, documentTypes, getUserRepresentation } from "~/utils"
import { PDFIcon, ImportFileIcon, ImportIcon } from "~/icons"
import { useAccountStore } from "~/stores"
import { storeToRefs } from "pinia"

interface Props {
  document: DocumentSnippet
  columns: any[]
  loading?: boolean
  selected?: boolean
  isArchive?: boolean
  hideTags?: boolean
  sortAttribute?: string
  allowOverflow?: boolean
  index: number
  total: number
}

const props = withDefaults(
  defineProps<Props>(),
  {
    loading: false,
    selected: false,
    isArchive: false,
    hideTags: false,
    allowOverflow: false,
    templates: null,
    sortAttribute: null,
  },
)

const { t } = useI18n()

const { stages } = useStages(null, null, true)

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

const stage = computed(() => {
  return stages.value.find((stage) => stage.name === props.document?.stage)
})

const template = computed(() => {
  return getTemplateByUuid(props.document?.template_uuid)
})

defineEmits([ "select", "delete", "restore", "archive", "edit-metadata" ])

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

const isLoadingSignatories = ref(false)
const signatories = ref(null)

const signatoryTippyContent = computed(() => {
  if (isLoadingSignatories.value) return t("common.loading") + "…"
  if (!signatories.value?.length || isLoadingSignatories.value) {
    return ""
  }
  let contentString = ""
  for (let i = 0; i < signatories.value.length; i++) {
    const signatory = signatories.value[i]
    contentString += getUserRepresentation(signatory) + (signatory.has_signed ? " ✓" : "") + "\n"
  }
  return contentString
})

const loadSignatories = async () => {
  if (!isLoadingSignatories.value && props.document.stage === DocumentStage.signing && !signatories.value?.length) {
    isLoadingSignatories.value = true
    try {
      const res = await axios.get(route("documents.get-signatories", props.document.uuid))

      if (res?.data?.data) {
        signatories.value = res.data.data
        const element = document.querySelector(`[data-document-table-entry="${props.document.uuid}"]`)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const instance = element._tippy
        nextTick(() => {
          instance.setContent(signatoryTippyContent.value)
        })
      }
    } catch (err) {
      console.error(err)
    } finally {
      isLoadingSignatories.value = false
    }
  }
}

</script>

<template>
  <MagicTableEntry
    :columns="columns"
    :loading="loading"
    :selected="selected"
    :entry="document"
    :total="total"
    :index="index"
    :allow-overflow="allowOverflow"
    @select="$emit('select', document.uuid)"
    @edit-metadata="(data) => $emit('edit-metadata', data)"
  >
    <template #name>
      <div
        class="flex items-center @xl:space-x-4"
        :class="loading ? 'opacity-0' : ''"
      >
        <div class="hidden @xl:block shrink-0 w-9 h-8 text-gray-300 relative">
          <component
            :is="getDocumentTypeInfo(document?.document_type || DocumentType.other)?.icon"
            class="w-8 h-8"
            aria-hidden="true"
          />
          <div
            v-if="document.origin === 'pdf'"
            class="w-4 h-4 absolute -left-0.5 -bottom-1 text-red-500 bg-white"
          >
            <PDFIcon
              aria-hidden="true"
            />
          </div>
          <div
            v-if="document.origin === 'signed_pdf'"
            class="w-4 h-4 absolute -left-0.5 -bottom-1 text-slate-500 bg-white"
          >
            <ImportFileIcon
              aria-hidden="true"
            />
          </div>


          <span
            v-if="document.is_expired"
            class="bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-45 shrink-0 rounded-full border border-orange-400 text-orange-500 px-1 text-[10px] h-[16px] flex items-center justify-center space-x-0.5 font-semibold select-none"
          >
            <span class="inline-block">{{ $t('common.expired') }}</span>
          </span>
        </div>
        <div class="grow">
          <Link
            v-cy="`document-table-document-${document.name.toLowerCase()}`"
            :href="route('documents.show', props.document.uuid)"
            class="hover:text-gray-600"
          >
            <div>
              <div
                class="text-xs @md:text-sm font-medium line-clamp-2 overflow-hyphens hover:text-indigo-700"
              >
                {{ document.name }}
              </div>

              <div
                v-if="document.parties?.length && document.parties.length < 5"
                class="@md:flex items-center @md:gap-2 mt-1 space-y-1 @md:space-y-0 min-w-0 max-w-full"
              >
                <span
                  v-for="(party, partyIdx) in document.parties"
                  :key="partyIdx"
                  class="flex items-center text-xs text-gray-500"
                  :class="[
                    {
                      'max-w-[33.333%]': document.parties.length === 3,
                      'max-w-[25%]': document.parties.length === 4,
                    }
                  ]"
                  :title="party.entity_name ? party.entity_name : party.name"
                >
                  <PartyEntityTypeDisplayIcon
                    :party="party"
                    class="w-4 h-4 mr-1 text-gray-300 shrink-0"
                  />
                  <span class="overflow-hyphens line-clamp-1">{{ party.entity_name ? party.entity_name : party.name }}</span>
                </span>
              </div>
            </div>
          </Link>
          <TagList
            v-if="document.tags?.length && !hideTags"
            class="hidden @lg:flex -mb-1 -mr-1 mt-1"
            :tags="document.tags"
          />
        </div>
      </div>
    </template>
    <template #stage>
      <StageDisplay
        v-bind="document.stage === DocumentStage.signing ? {
          'data-tippy-list': true,
          'data-tippy-content': signatoryTippyContent,
          'data-placement': 'bottom',
          'data-document-table-entry': document.uuid,
        } : {}"
        :stage="stage"
        @mouseover="loadSignatories"
      />
    </template>
    <template #teams>
      <TeamList
        v-if="document.teams?.length"
        :teams="document.teams"
      />
      <span
        v-else
        class="text-sm text-gray-400"
      >
        {{ $t('unassigned') }}
      </span>
    </template>
    <template #users>
      <UserDisplay
        v-if="document.document_users?.length"
        :users="document.document_users"
      />
    </template>
    <template #origin>
      <div class="space-y-1 text-gray-500">
        <div v-if="document.origin !== 'template'">
          {{ $t('documentOrigins.' + document.origin) }}
        </div>
        <div v-if="document.automation">
          <component
            :is="mau?.permissions.includes('automation_manage') ? Link : 'div'"
            :href="mau?.permissions.includes('automation_manage') ? route('automations.show', document.automation.uuid) : undefined"
            class="flex items-center space-x-1"
            :class="mau?.permissions.includes('automation_manage') ? 'link-primary' : ''"
            :title="$t('magicTable.columns.automation') + ': ' + document.automation.name"
          >
            <PlayCircleIcon
              aria-hidden="true"
              class="w-4 h-4"
            />
            <span class="truncate max-w-[200px]">{{ document.automation.name }}</span>
          </component>
        </div>
        <div v-if="document.import">
          <component
            :is="mau?.permissions.includes('import_manage') ? Link : 'div'"
            :href="mau?.permissions.includes('import_manage') ? route('imports.show', document.import.uuid) : undefined"
            class="flex items-center space-x-1"
            :class="mau?.permissions.includes('import_manage') ? 'link-primary' : ''"
            :title="$t('magicTable.columns.import') + ': ' + document.import.name"
          >
            <ImportIcon
              aria-hidden="true"
              class="w-4 h-4"
            />
            <span class="truncate max-w-[200px]">{{ document.import.name }}</span>
          </component>
        </div>
        <div v-if="template">
          <component
            :is="mau?.permissions.includes('template_manage') ? Link : 'div'"
            :href="mau?.permissions.includes('template_manage') ? route('templates.edit', document.template_uuid) : undefined"
            class="flex items-center space-x-1"
            :class="mau?.permissions.includes('template_manage') ? 'link-primary' : ''"
            :title="$t('magicTable.columns.template') + ': ' + template.name"
          >
            <DocumentDuplicateIcon
              aria-hidden="true"
              class="w-4 h-4"
            />
            <span class="truncate max-w-[200px]">{{ template.name }}</span>
          </component>
        </div>
      </div>
    </template>
    <template #timestamps>
      <MagicTableTimestamps
        :entry="document"
        :sort-attribute="sortAttribute"
      />
    </template>
    <template #options>
      <div>
        <MenuItem v-slot="{ active }">
          <Link
            :href="route('documents.show', document.uuid)"
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-t-md',
            ]"
          >
            {{ $t('common.show') }}<span class="sr-only">, {{ document.name }}</span>
          </Link>
        </MenuItem>
      </div>
      <div v-if="isArchive">
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
            ]"
            @click="$emit('restore', [document.uuid])"
          >
            {{ $t('common.restore') }}
          </button>
        </MenuItem>
      </div>
      <div v-else>
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-slate-700 text-white' : 'text-slate-300',
              'group flex w-full items-center px-5 py-2 text-sm rounded-t-md',
            ]"
            @click="$emit('archive', [document.uuid])"
          >
            {{ $t('common.archive') }}
          </button>
        </MenuItem>
      </div>
      <div
        v-if="mau?.permissions.includes('document_delete')"
      >
        <MenuItem v-slot="{ active }">
          <button
            :class="[
              active ? 'bg-red-950 text-red-400' : 'text-red-500',
              'group flex w-full items-center px-5 py-2 text-sm rounded-b-md',
            ]"
            @click="$emit('delete', [document.uuid])"
          >
            {{ $t('common.delete') }}
          </button>
        </MenuItem>
      </div>
    </template>
  </MagicTableEntry>
</template>
