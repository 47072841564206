<script setup lang="ts">
// external
import { MenuItem } from "@headlessui/vue"
import { Link } from "@inertiajs/vue3"
import { computed } from "vue"
import { SparklesIcon, NoSymbolIcon, BoltIcon } from "@heroicons/vue/24/outline"

// internal
import { MagicTableEntry, MetadataCategoryDisplay, MetadataTypeDisplay } from "~/components"
import { Metadata, MetadataType } from "~/types"

interface Props {
  metadata: Metadata
  columns: any[]
  loading?: boolean
  activeTab?: string
}

const props = withDefaults(defineProps<Props>(), {})

defineEmits([ "delete" ])

const isOverridden = computed(() => props.metadata.type === MetadataType.reference && !!props.metadata.account_metadata_uuid)

</script>

<template>
  <MagicTableEntry
    :columns="columns"
    :loading="loading"
  >
    <template #name>
      <Link
        :href="route('account-settings.metadata.show', metadata.uuid) + '#' + activeTab"
        class="flex items-center space-x-1"
      >
        <span>{{ (metadata.type === MetadataType.account || !!metadata.account_metadata_uuid) ? metadata.display_name : $t('metadata.system.' + metadata.name + '.name') }}</span>
        <span
          v-if="metadata.type === MetadataType.system_computed"
          data-tippy-help
          :data-tippy-content="$t('metadata.autoGenerated')"
          data-placement="bottom"
        >
          <BoltIcon
            class="w-4 h-4 text-gray-400 shrink-0"
            aria-hidden="true"
          />
        </span>
      </Link>
    </template>
    <template #type>
      <MetadataTypeDisplay
        :type="metadata.value_type"
        class="text-gray-500"
      />
    </template>
    <template #category>
      <MetadataCategoryDisplay
        :category="metadata.type"
      />
    </template>
    <template #documentTypes>
      <div v-if="metadata.relevant_document_types === null && !!metadata.relevant_contract_types?.length">
        <span
          class="rounded-full px-2 py-0.5 text-xs bg-gray-100"
        >{{ $t(`documentTypes.contract.name`) }}</span>
      </div>
      <div v-else-if="metadata.relevant_document_types === null">
        {{ $t('common.none') }}
      </div>
      <div
        v-else-if="metadata.relevant_document_types?.length"
        class="flex flex-wrap gap-1"
      >
        <span
          v-for="documentType in metadata.relevant_document_types"
          :key="documentType"
          class="rounded-full px-2 py-0.5 text-xs bg-gray-100"
        >{{ $t(`documentTypes.${documentType}.name`) }}</span>
      </div>
      <div v-else-if="metadata.relevant_document_types?.length === 0">
        {{ $t('common.all') }}
      </div>
    </template>
    <template #contractTypes>
      <div v-if="metadata.relevant_contract_types === null">
        {{ $t('common.none') }}
      </div>
      <div
        v-else-if="metadata.relevant_contract_types?.length"
        class="flex flex-wrap gap-1"
      >
        <span
          v-for="contractType in metadata.relevant_contract_types"
          :key="contractType"
          class="rounded-full px-2 py-0.5 text-xs bg-gray-100"
        >{{ $t(`contractTypes.${contractType}`) }}</span>
      </div>
      <div v-else-if="metadata.relevant_contract_types?.length === 0">
        {{ $t('common.all') }}
      </div>
    </template>
    <template #usedByAi>
      <span
        v-if="metadata.type === MetadataType.system || !!metadata.prompt || !!metadata.keywords?.length"
        class="flex items-center space-x-1 text-purple-500"
      >
        <SparklesIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <span>{{ $t('common.yes') }}</span>
      </span>
      <span
        v-else
        class="flex items-center space-x-1 text-gray-400"
      >
        <NoSymbolIcon
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <span>{{ $t('common.no') }}</span>
      </span>
    </template>
    <template #options>
      <MenuItem v-slot="{ active }">
        <Link
          :href="route('account-settings.metadata.show', {
            metadata: metadata.uuid,
          }) + '#' + activeTab"
          :class="[
            active ? 'bg-slate-700 text-white' : 'text-slate-300',
            'group flex w-full items-center px-5 py-2 text-sm',
          ]"
        >
          {{ $t('common.show') }}
        </Link>
      </MenuItem>

      <MenuItem
        v-if="metadata.type === MetadataType.account || metadata.type === MetadataType.reference"
        v-slot="{ active }"
      >
        <Link
          :href="route('account-settings.metadata.edit', {
            metadata: isOverridden ? metadata.account_metadata_uuid : metadata.uuid,
          }) + '#' + activeTab"
          :class="[
            active ? 'bg-slate-700 text-white' : 'text-slate-300',
            'group flex w-full items-center px-5 py-2 text-sm',
          ]"
        >
          {{ $t('common.edit') }}
        </Link>
      </MenuItem>

      <MenuItem
        v-if="metadata.type === MetadataType.account"
        v-slot="{ active }"
      >
        <button
          :class="[
            active ? 'bg-red-950 text-red-400' : 'text-red-500',
            'group flex w-full items-center px-5 py-2 text-sm',
          ]"
          @click="$emit('delete', metadata)"
        >
          {{ $t('common.remove') }}
        </button>
      </MenuItem>
    </template>
  </MagicTableEntry>
</template>
