<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, onMounted, ref } from "vue"
import { PlusIcon } from "@heroicons/vue/20/solid"
import { LockClosedIcon } from "@heroicons/vue/24/solid"
import { UserPlusIcon, TrashIcon } from "@heroicons/vue/24/outline"
import tippy, { Instance, Placement, Props as TippyProps, roundArrow, sticky } from "tippy.js"
import { useI18n } from "vue-i18n"

// internal
import { FormInputErrors, SignatureOrder, SignatureSecurityLevelSelector, SpinLoader, SignatoryPopover } from "~/components"
import { SignatureIcon } from "~/icons"
import { useDocumentStore, usePusherStore, useSignatureStore, usePartyStore, useUserStore } from "~/stores"
import { CrudContext, Document, DocumentUser, DocumentUserRoleEnum, SignatureSecurityLevel } from "~/types"
import { focusFirstFocusable } from "~/utils"
import { validateEmail } from "~/utils/helpers"

interface Props {
  document: Document
  isLoadingAccountUsers?: boolean
  isLoadingParties?: boolean
  documentUsers?: DocumentUser[]
  isLoadingDocumentUsers?: boolean
  signatureSecurityLevel?: SignatureSecurityLevel
  sequentialSigning?: boolean
  keysOfIsUpdatingDocumentField?: (keyof Document)[]
  keysOfLastSavedDocumentField?: (keyof Document)[]
  errorsToShow?: Partial<Record<keyof Document, string[]>>
  isActive?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isLoadingAccountUsers: false,
    isLoadingParties: false,
    documentUsers: () => [],
    isLoadingDocumentUsers: false,
    signatureSecurityLevel: null,
    sequentialSigning: null,
    keysOfIsUpdatingDocumentField: () => [],
    keysOfLastSavedDocumentField: () => [],
    errorsToShow: null,
    isActive: false,
  },
)

const { t } = useI18n()

const emit = defineEmits( [ "focus", "blur", "close", "start-signing-phase", "update:signature-security-level", "update:sequential-signing", "set-has-external-document-users-invited" ])

const documentStore = useDocumentStore()
const { mdu, isLockedDocument, mau } = storeToRefs(documentStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const signatureStore = useSignatureStore()
const { signatories } = storeToRefs(signatureStore)

const userStore = useUserStore()
const { users, documentUsersForSharing, idxOfIsCreatingUser, uuidsOfIsLoadingSendInvitation } = storeToRefs(userStore)
const { setDocumentUserForSharing, addDocumentUserForSharing, getUserBackendErrors, removeNewUser, setDocumentUsersForSharing, sendInvitations } = userStore

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const signatureSecurityLevelSelected = computed<SignatureSecurityLevel>({
  get: () => props.signatureSecurityLevel,
  set: (val) => emit("update:signature-security-level", val),
})

const emptyUser: Partial<DocumentUser> = Object.freeze({ roles: [ DocumentUserRoleEnum.collaborator ] })

const message = ref<string>(props.document.external_signatory_invitation_message ?? "")
const messageError = ref<string[]>(null)

const tippyInstance = ref<Instance<TippyProps>[]>(null)

const soleSignatory = computed(() => {
  const signatoryCount = props.documentUsers?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory))?.length || 0
  const selfSignatory = mdu.value.roles.includes(DocumentUserRoleEnum.signatory)
  return signatoryCount === 1 && selfSignatory ? true : false
})

const startSigningPhase = async (): Promise<void> => {
  let canProceed = true
  if (documentUsersForSharing.value?.length) {
    canProceed = false
    const sendInvitationsRes = await sendInvitations(null, CrudContext.document, props.document?.uuid, [], true)
    if (sendInvitationsRes) canProceed = true
  }
  if (canProceed) {
    emit("start-signing-phase", message.value)
    uuidsOfIsLoadingSendInvitation.value = []
    emit("close")
  }
}


onMounted(() => {
  tippyInstance.value = tippy("#addSignatoryFromShareModalButton", {
    content (reference) {
      const id = reference.getAttribute("data-template")
      return document.getElementById(id)
    },
    placement: "top",
    onMount (instance) {
      instance.popperInstance?.setOptions({
        placement: instance.reference.getAttribute("data-placement") as Placement,
      })
    },
    allowHTML: true,
    animation: "scale",
    arrow: roundArrow,
    appendTo: () => document.getElementById("startSigningPhaseModal"),
    theme: "indigo",
    trigger: "click",
    sticky: true,
    plugins: [ sticky ],
    hideOnClick: true,
    interactive: true,
    onShown (instance) {
      const template = instance.reference.getAttribute("data-template")
      const element = document.getElementById(template)
      focusFirstFocusable(element)
    },
  })

  if (!signatureSecurityLevelSelected.value) {
    signatureSecurityLevelSelected.value = SignatureSecurityLevel.SES
  }
})

const addUserFromShareModalPopover = ref<any>()
const resetSelectedUser = () => {
  addUserFromShareModalPopover.value?.resetForm()
}

// Helper to emit pusher relevant events
const handleFocus = (data: string) => emit("focus", data)
const handleBlur = (data: string) => emit("blur", data)

const validationErrorsOfUsersToBeShared = ref<Record<number, Partial<Record<keyof DocumentUser, string[]>>>>(null)

const handleSetDocumentUserForSharing = (event: Event, idx: number) => {
  // Check if e-mail is valid
  const isValidEmail = validateEmail((event.target as HTMLInputElement).value)
  const emailExists = users.value?.find((el) => el.email === (event.target as HTMLInputElement).value)
  // Set or reset validation errors for the specific idx
  if (!validationErrorsOfUsersToBeShared.value) validationErrorsOfUsersToBeShared.value = {}
  if (!validationErrorsOfUsersToBeShared.value[idx]) validationErrorsOfUsersToBeShared.value[idx] = {}
  if (!isValidEmail) validationErrorsOfUsersToBeShared.value[idx].email = [ t("documentSharingModal.emailInvalid") ]
  else if (emailExists) validationErrorsOfUsersToBeShared.value[idx].email = [ t("documentSharingModal.emailAlreadyExists") ]
  else validationErrorsOfUsersToBeShared.value[idx].email = []
  setDocumentUserForSharing({ email: (event.target as HTMLInputElement).value }, idx, null)
}

onMounted(() => setDocumentUsersForSharing([]))

</script>

<template>
  <div class="px-6 pb-2">
    <div>
      <div class="text-lg">
        {{ $t('startSigningPhaseModal.startSigningProcess') }}
      </div>
    </div>
  </div>
  <template v-if="!!mau">
    <div class="px-6">
      <div
        class="flex items-center justify-between px-6 py-3 -mx-6 text-sm text-indigo-500 bg-indigo-50"
        :class="!signatureSecurityLevel ? 'bg-indigo-500 text-white' : 'bg-indigo-50 text-indigo-500'"
      >
        <span class="flex items-center gap-2">
          <span
            class="flex items-center justify-center w-5 h-5 font-medium text-indigo-700 bg-indigo-200 rounded-full"
            :class="!signatureSecurityLevel ? 'bg-indigo-600 text-white' : 'bg-indigo-200 text-indigo-700'"
          >
            1
          </span>
          <span>{{ $t('startSigningPhaseModal.reviewSecurityLevel') }}:</span>
        </span>
      </div>
    </div>
    <div
      class="text-sm"
      :class="!signatureSecurityLevel ? 'border-b border-b-indigo-100' : ''"
    >
      <SignatureSecurityLevelSelector
        v-model="signatureSecurityLevelSelected"
        :is-highlighted="keysOfLastSavedDocumentField?.includes('signature_security_level')"
        :is-loading="keysOfIsUpdatingDocumentField?.includes('signature_security_level')"
        inline-button-classes="btn-listbox-plain"
        chevron-classes="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        placeholder-classes="block text-gray-500 truncate"
        :is-disabled="!!props.document?.template_signature_security_level || checkIfDisabledByPusher('documentSignatureSecurityLevelSelector')"
        @focus="handleFocus"
        @blur="handleBlur"
      >
        <template #label>
          <span
            :class="!signatureSecurityLevel ? 'text-gray-900' : ''"
          >
            {{ $t('signatures.signatureSecurity') }}:
          </span>
        </template>
      </SignatureSecurityLevelSelector>
      <FormInputErrors
        v-if="errorsToShow?.signature_security_level?.length"
        :errors="errorsToShow?.signature_security_level"
      />
    </div>
    <div
      class="px-6"
      :class="!signatureSecurityLevel ? 'opacity-40 pointer-events-none' : ''"
    >
      <div
        class="flex items-center justify-between px-6 py-3 -mx-6 text-sm text-indigo-500 bg-indigo-50"
      >
        <span class="flex items-center gap-2">
          <span class="flex items-center justify-center w-5 h-5 font-medium text-indigo-700 bg-indigo-200 rounded-full">
            2
          </span>
          <span>{{ $t('startSigningPhaseModal.confirmSignatories') }}:</span>
        </span>
      </div>
    </div>
  </template>
  <SignatureOrder
    :document="document"
    :document-users="documentUsers"
    :is-loading-document-users="isLoadingDocumentUsers"
    :parties="parties"
    :signature-security-level="signatureSecurityLevel"
    :sequential-signing="sequentialSigning"
    :is-loading-parties="isLoadingParties"
    :is-editable="mdu?.permissions?.includes('document_user_update_signing_order') && !!(mau)"
    :class="!signatureSecurityLevel ? 'opacity-50 pointer-events-none' : ''"
    @update:sequential-signing="emit('update:sequential-signing', $event)"
  >
    <template
      #addButton
    >
      <div
        class="px-6 py-3 -ml-2"
      >
        <span
          v-if="isLockedDocument"
          class="flex items-center gap-3 py-1.5 px-3 text-sm text-gray-400 text-xs"
        >

          <LockClosedIcon
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          {{ $t('startSigningPhaseModal.signatoriesCannotBeChangedAfterCheckpoint') }}
        </span>
        <button
          v-else-if="!isLockedDocument"
          id="addSignatoryFromShareModalButton"
          data-placement="top"
          data-template="addSignatoryFromShareModalForm"
          type="button"
          class="flex items-center add-signatory-button btn-plain btn-sm"
          :class="signatories?.length > 0 ? 'hover:bg-gray-100 gap-3' : '-mt-14 gap-1 mx-auto text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100'"
          @click.prevent="resetSelectedUser"
        >
          <span
            class="flex items-center justify-center rounded-full"
            :class="signatories?.length > 0 ? 'text-gray-400 border-2 border-gray-300 border-dashed w-8 h-8' : ''"
          >
            <PlusIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </span>
          <span>{{ $t('startSigningPhaseModal.addSignatory') }}</span>
        </button>
      </div>
    </template>
  </SignatureOrder>

  <div
    class="px-6"
    :class="!signatureSecurityLevel ? 'opacity-50 pointer-events-none' : ''"
  >
    <div
      class="flex items-center justify-between px-6 py-3 -mx-6 text-sm text-indigo-500 bg-indigo-50"
    >
      <span class="flex items-center gap-2">
        <span class="flex items-center justify-center w-5 h-5 font-medium text-indigo-700 bg-indigo-200 rounded-full">
          3
        </span>
        <span>{{ $t('startSigningPhaseModal.editMessage') }}:</span>
      </span>
    </div>
    <div class="mt-2 mb-4">
      <label
        class="flex items-center justify-between gap-2 text-sm font-medium"
        for="message"
      >
        {{ $t('documentSharingModal.message') }}
        <span class="font-normal text-indigo-500">
          {{ $t('documentSharingModal.optional') }}
        </span>
      </label>
      <div
        class="mt-1 grow-textarea"
        :data-replicated-value="message"
      >
        <textarea
          v-model="message"
          :placeholder="$t('documentSharingModal.addMessage') + '…'"
          data-cy-sel="share-message-input"
        />
      </div>
      <div
        v-if="messageError"
        class="flex"
      >
        <FormInputErrors
          :errors="messageError"
          align="right"
        />
      </div>
      <div>
        <ul
          role="list"
          class="space-y-2"
        >
          <li
            v-for="user, userIdx in documentUsersForSharing"
            :id="'ccRecipientsShareContainer_' + userIdx"
            :key="userIdx"
            :class="idxOfIsCreatingUser?.length && idxOfIsCreatingUser?.includes(userIdx) ? 'opacity-25' : ''"
          >
            <div class="text-sm2">
              <label
                v-if="userIdx === 0"
                class="flex items-center justify-between gap-2 mt-2 mb-1 text-sm font-medium"
                for="message"
              >
                {{ $t('documentSharingModal.ccRecipients') }}
              </label>
              <div class="relative flex items-center gap-2">
                <input
                  v-cy="`share-modal-cc-recipient-input`"
                  :value="user.email"
                  type="email"
                  :placeholder="$t('userToParty.email') + '…'"
                  class="pl-10 grow input-primary"
                  @input="($event) => handleSetDocumentUserForSharing($event, userIdx)"
                >
                <span
                  class="absolute flex items-center justify-center w-8 h-8 -mt-4 left-1.5 top-1/2"
                >
                  <UserPlusIcon
                    class="w-5 h-5 text-gray-400 shrink-0"
                  />
                </span>

                <button
                  class="p-0 text-gray-400 btn-plain hover:text-gray-600"
                  @click.prevent="removeNewUser(userIdx)"
                >
                  <TrashIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
            <FormInputErrors
              v-if="validationErrorsOfUsersToBeShared?.[userIdx]?.email?.length"
              :errors="validationErrorsOfUsersToBeShared?.[userIdx]?.email"
            />
          </li>
          <FormInputErrors
            v-if="getUserBackendErrors('0')?.email?.length"
            :errors="getUserBackendErrors('0')?.email"
          />
          <FormInputErrors
            v-if="getUserBackendErrors('0')?.party_uuid?.length"
            :errors="getUserBackendErrors('0')?.party_uuid"
          />
          <FormInputErrors
            v-if="getUserBackendErrors('0')?.roles?.length"
            :errors="getUserBackendErrors('0')?.roles"
          />
          <button
            v-cy="`share-modal-add-cc-recipient-button`"
            type="button"
            class="flex items-center gap-1 p-0 mt-2 text-xs text-indigo-500 btn-plain btn-sm hover:text-indigo-600"
            @click="addDocumentUserForSharing({ ...Object.assign(emptyUser), party_uuid: null })"
          >
            <PlusIcon class="w-3 h-3 shrink-0" />
            {{ $t('documentSharingModal.addCcRecipient') }}
          </button>
        </ul>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center px-6 py-4 space-x-4 bg-gray-100 sm:justify-end rounded-b-md">
    <button
      v-cy="`sharing-modal-send-invitation-button`"
      :disabled="!!(uuidsOfIsLoadingSendInvitation?.length) || (!signatureSecurityLevel || !signatories.length)"
      type="button"
      class="inline-flex items-center btn-primary"
      @click.prevent="startSigningPhase"
    >
      <div
        v-if="!!(uuidsOfIsLoadingSendInvitation?.length)"
        class="inline-flex items-center gap-x-2"
      >
        <span class="pointer-events-none">
          <SpinLoader class="w-5 h-5 shrink-0" />
        </span>
        <span>{{ $t('startSigningPhaseModal.sharing') }} ({{ uuidsOfIsLoadingSendInvitation?.length }} {{ $t('startSigningPhaseModal.remaining') }})…</span>
      </div>
      <span
        v-else
        class="inline-flex items-center"
      >
        <SignatureIcon
          class="mr-1.5 h-5 w-5"
        />
        <span>{{ soleSignatory ? $t('startSigningPhaseModal.startSigningNow') : $t('startSigningPhaseModal.startSigningProcess') }}</span>
      </span>
    </button>
  </div>

  <div class="hidden">
    <!-- Popover to add an external user -->
    <SignatoryPopover
      id="addSignatoryFromShareModalForm"
      ref="addSignatoryFromShareModalPopover"
      v-cy="`share-modal-add-signatory-input`"
      scope="internal"
      :document="document"
      :default-signatory="true"
    />
  </div>
</template>
