<script setup lang="ts">
// external
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue"
import { TrashIcon } from "@heroicons/vue/20/solid"
import { CheckIcon } from "@heroicons/vue/24/solid"
import { Editor } from "@tiptap/core"
import { uniq } from "lodash-es"
import { storeToRefs } from "pinia"

// internal
import {
  ResetColorIcon,
} from "~/icons"
import { useAccountStore } from "~/stores"
import { convertColorToHex } from "~/utils"

interface SuggestionProps {
  editor: Editor;
}

const props = defineProps<SuggestionProps>()

const accountStore = useAccountStore()
const { account } = storeToRefs(accountStore)

const predefinedColors = uniq([ account.value?.ci_colors?.primaryColor, "#312e81", "#4338ca", "#6366f1" ])

const escapeHandler = () => {
  props.editor.chain().focus().hideTableMenu().run()
}

const onKeyDown = ({ event }) => {
  const keyActionMap = {
    Escape: escapeHandler,
  }

  const action = keyActionMap[event.key]

  if (action) {
    event.preventDefault()
    event.stopPropagation()

    setTimeout(() => action?.())
  }

  return !!action
}


defineExpose(
  {
    escapeHandler,
    onKeyDown,
  },
)
</script>

<template>
  <section
    id="TableMenu"
    ref="TableMenuRef"
    v-cy="`table-menu`"
    class="relative flex rounded-md shadow-sm"
    :class="{
      'hidden': !!(!editor.isActive('table') || editor.isActive('resizableImage')),
    }"
  >
    <section class="flex items-center">
      <button
        v-if="editor.can().addColumnBefore()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.addColumnBefore')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none rounded-l-md"
        @click="editor.chain().focus().addColumnBefore().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M13,2A2,2 0 0,0 11,4V20A2,2 0 0,0 13,22H22V2H13M20,10V14H13V10H20M20,16V20H13V16H20M20,4V8H13V4H20M9,11H6V8H4V11H1V13H4V16H6V13H9V11Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().addColumnAfter()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.addColumnAfter')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().addColumnAfter().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M11,2A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H2V2H11M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M15,11H18V8H20V11H23V13H20V16H18V13H15V11Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().deleteColumn()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.deleteColumn')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().deleteColumn().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M4,2H11A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H4A2,2 0 0,1 2,20V4A2,2 0 0,1 4,2M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M17.59,12L15,9.41L16.41,8L19,10.59L21.59,8L23,9.41L20.41,12L23,14.59L21.59,16L19,13.41L16.41,16L15,14.59L17.59,12Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().addRowBefore()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.addRowBefore')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().addRowBefore().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M22,14A2,2 0 0,0 20,12H4A2,2 0 0,0 2,14V21H4V19H8V21H10V19H14V21H16V19H20V21H22V14M4,14H8V17H4V14M10,14H14V17H10V14M20,14V17H16V14H20M11,10H13V7H16V5H13V2H11V5H8V7H11V10Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().addRowAfter()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.addRowAfter')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().addRowAfter().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M22,10A2,2 0 0,1 20,12H4A2,2 0 0,1 2,10V3H4V5H8V3H10V5H14V3H16V5H20V3H22V10M4,10H8V7H4V10M10,10H14V7H10V10M20,10V7H16V10H20M11,14H13V17H16V19H13V22H11V19H8V17H11V14Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().deleteRow()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.deleteRow')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().deleteRow().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M9.41,13L12,15.59L14.59,13L16,14.41L13.41,17L16,19.59L14.59,21L12,18.41L9.41,21L8,19.59L10.59,17L8,14.41L9.41,13M22,9A2,2 0 0,1 20,11H4A2,2 0 0,1 2,9V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V9M4,9H8V6H4V9M10,9H14V6H10V9M16,9H20V6H16V9Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().mergeCells()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.mergeCells')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().mergeCells().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M5,10H3V4H11V6H5V10M19,18H13V20H21V14H19V18M5,18V14H3V20H11V18H5M21,4H13V6H19V10H21V4M8,13V15L11,12L8,9V11H3V13H8M16,11V9L13,12L16,15V13H21V11H16Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().splitCell()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.splitCells')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().splitCell().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 14H21V20H3V14H5V18H19V14M3 4V10H5V6H19V10H21V4H3M11 11V13H8V15L5 12L8 9V11H11M16 11V9L19 12L16 15V13H13V11H16Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().toggleHeaderColumn()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.toggleHeaderColumn')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().toggleHeaderColumn().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M8,2H16A2,2 0 0,1 18,4V20A2,2 0 0,1 16,22H8A2,2 0 0,1 6,20V4A2,2 0 0,1 8,2M8,10V14H16V10H8M8,16V20H16V16H8M8,4V8H16V4H8Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().toggleHeaderRow()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.toggleHeaderRow')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().toggleHeaderRow().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="M22,14A2,2 0 0,1 20,16H4A2,2 0 0,1 2,14V10A2,2 0 0,1 4,8H20A2,2 0 0,1 22,10V14M4,14H8V10H4V14M10,14H14V10H10V14M16,14H20V10H16V14Z"
          />
        </svg>
      </button>

      <button
        v-if="editor.can().fitTableToEditorWidth()"
        data-tippy-help
        :data-tippy-content="$t('commandMenu.fitTableToEditorWidth')"
        data-placement="top"
        class="inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        @click="editor.chain().focus().fitTableToEditorWidth().run()"
      >
        <svg
          class="w-4 h-4 mx-auto fill-current shrink-0"
          viewBox="0 0 24 24"
        >
          <path
            d="m17 17l-1.4-1.4l2.575-2.6H13v-2h5.175L15.6 8.4L17 7l5 5l-5 5ZM7 17l-5-5l5-5l1.4 1.4L5.825 11H11v2H5.825L8.4 15.6L7 17Z"
          />
        </svg>
      </button>

      <Popover
        v-if="editor.can().deleteTable()"
        class="relative flex items-center"
      >
        <PopoverButton
          data-tippy-help
          :data-tippy-content="$t('commandMenu.changeCellColor')"
          data-placement="top"
          class="relative inline-flex items-center p-1.5 text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
        >
          <span
            :style="{
              backgroundColor: editor.getAttributes('tableCell')?.bgColor || editor.getAttributes('tableHeader')?.bgColor || 'rgb(255, 255, 255)',
            }"
            class="inline-block w-4 h-4 rounded-full ring-2 ring-white ring-opacity-20"
          />
        </PopoverButton>

        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
        >
          <PopoverPanel
            class="absolute left-0 z-50 w-64 mt-1 transform border-2 border-white rounded-lg shadow-lg bg-slate-950 border-opacity-20 sm:px-0 lg:max-w-3xl"
          >
            <section class="flex flex-col gap-2 p-2">
              <section class="flex gap-2">
                <span
                  v-for="color in predefinedColors"
                  :key="color"
                  :style="{
                    backgroundColor: color,
                  }"
                  class="flex items-center justify-center border-2 border-white rounded-full cursor-pointer w-7 h-7 "
                  :class="
                    convertColorToHex(editor.getAttributes('tableCell')?.bgColor || editor.getAttributes('tableHeader')?.bgColor || 'rgb(255, 255, 255)') === color
                      ? ''
                      : 'border-opacity-20'
                  "
                  @click="editor.commands.setCellBgColor(color)"
                >
                  <CheckIcon
                    v-if="convertColorToHex(editor.getAttributes('tableCell')?.bgColor || editor.getAttributes('tableHeader')?.bgColor || 'rgb(255, 255, 255)') === color"
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </span>

                <span
                  class="flex items-center justify-center text-black rounded-full shadow-sm cursor-pointer w-7 h-7 bg-indigo-50 hover:bg-indigo-100"
                  data-tippy-context
                  :data-tippy-content="$t('commandMenu.resetCellBackgroundColor')"
                  data-placement="top"
                  @click="editor.commands.setCellBgColor(null)"
                >
                  <ResetColorIcon
                    class="w-4 h-4 shrink-0"
                    aria-hidden="true"
                  />
                </span>
              </section>

              <section>
                <label
                  for="colorPickerInput"
                  class="block mt-1 text-sm font-medium text-slate-400"
                >
                  {{ $t('commandMenu.pickColor') }}
                </label>

                <section>
                  <div class="flex mt-1 rounded-md shadow-sm">
                    <span
                      class="inline-flex items-center px-3 border border-r-0 text-slate-400 border-slate-800 bg-slate-800 rounded-l-md sm:text-sm"
                    >
                      <input
                        id="colorPickerInput"
                        type="color"
                        class="w-6 h-6 rounded-full bg-slate-800"
                        :value="convertColorToHex(editor.getAttributes('tableCell')?.bgColor || editor.getAttributes('tableHeader')?.bgColor || 'rgb(255,255,255)')"
                        @input="editor.commands.setCellBgColor(convertColorToHex(($event.target as HTMLInputElement).value))"
                      >
                    </span>
                    <input
                      id="colorHexInput"
                      type="text"
                      name="colorHexInput"
                      class="block w-full min-w-0 flex-1 bg-slate-800 rounded-none rounded-r-md border-0 py-1.5 text-slate-400 ring-1 ring-inset ring-slate-800 placeholder:text-slate-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                      :value="convertColorToHex(editor.getAttributes('tableCell')?.bgColor || editor.getAttributes('tableHeader')?.bgColor || 'rgb(255,255,255)')"
                      @input="editor.commands.setCellBgColor(($event.target as HTMLInputElement).value)"
                    >
                  </div>
                </section>
              </section>
            </section>
          </PopoverPanel>
        </transition>
      </Popover>

      <button
        v-if="editor.can().deleteTable()"
        data-tippy-help
        :data-tippy-content="$t('editor.deleteTable')"
        data-placement="top"
        class="inline-flex items-center p-1.5 border-l border-l-indigo-200 text-xs font-medium text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none rounded-r-md"
        @click="editor.chain().focus().deleteTable().run()"
      >
        <TrashIcon class="w-4 h-4 mx-auto" />
      </button>
    </section>
  </section>
</template>

