<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue"

interface Props {
  timestamp: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

let interval = null
const timer = ref(0)

const countdownDisplay = computed(() => {
  const minutes = ~~(timer.value / 60)
  const extraSeconds = timer.value % 60

  const minuteString = minutes < 10 ? `0${minutes}` : minutes
  const secondString = extraSeconds < 10 ? `0${extraSeconds}` : extraSeconds

  return `${minuteString}:${secondString}`
})

onMounted(() => {
  parseTimeToSeconds()
})

onBeforeUnmount(() => {
  clearInterval(interval)
})

const parseTimeToSeconds = () => {
  // depending on format of timestamp, use date parse or just convert to integer
  const parsedTime = Date.parse(props.timestamp) ? Date.parse(props.timestamp) : parseInt(props.timestamp)
  // get diff in seconds
  timer.value = Math.floor(Math.abs((parsedTime - Date.now()) / 1000))
  if (timer.value > 0) {
    startTimer()
  } else {
    timer.value = 0
  }
}

const startTimer = () => {
  interval = setInterval(() => {
    if (timer.value === 0) {
      clearInterval(interval)
    } else {
      timer.value--
      emit("update:seconds", timer.value)
    }
  }, 1000)
}

watch(() => props.timestamp, () => {
  parseTimeToSeconds()
})

const emit = defineEmits([ "update:seconds" ])
</script>
<template>
  <span>{{ countdownDisplay }}</span>
</template>
