<script setup lang="ts">
// external
import { XCircleIcon } from "@heroicons/vue/20/solid"

// internal
import { FilterBubble } from "~/types"
import { decodeFilterString } from "~/utils"

interface Props {
  filterBubbles: FilterBubble[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const isBooleanBubble = (bubble) => {
  return typeof bubble.value === "boolean"
}

defineEmits([ "clear", "reset" ])

const getDisplayString = (bubble:FilterBubble) => {
  return decodeFilterString(bubble.display.toString(), [ "external_parties" ].includes(bubble.key))
}

</script>
<template>
  <div>
    <div
      v-for="bubble in props.filterBubbles"
      :key="bubble.key"
      class="inline-flex bg-white rounded-full px-2 py-1 mr-2 mb-2 items-center text-xs shadow"
    >
      <span class="font-medium inline-block">{{ bubble.label || $t('filter.labels.' + bubble.key) }}{{ isBooleanBubble(bubble) ? '' : ':' }}</span>
      <span
        v-if="!isBooleanBubble(bubble)"
        class="ml-1"
      >{{ getDisplayString(bubble) }}</span>
      <button
        type="button"
        class="ml-1.5 -mr-1 text-gray-300 hover:text-indigo-500 transition-colors duration-150"
        @click="$emit('clear', bubble)"
      >
        <XCircleIcon
          aria-hidden="true"
          class="shrink-0 w-4 h-4"
        />
        <span class="sr-only">{{ $t('filter.removeFilter') }}</span>
      </button>
    </div>
    <button
      type="button"
      class="inline-flex bg-indigo-100 rounded-full px-2 py-1 mr-2 mb-2 items-center text-xs shadow-sm hover:bg-indigo-500 hover:text-white text-indigo-500 transition-colors duration-150"
      @click="$emit('reset')"
    >
      <span class="font-medium">{{ $t('filter.clearAll') }}</span>
      <XCircleIcon
        aria-hidden="true"
        class="ml-1.5 -mr-1 w-4 h-4"
      />
    </button>
  </div>
</template>
