<script setup lang="ts">
// external
import { computed } from "vue"
import { ChartBarIcon, ChartPieIcon, ListBulletIcon, CalendarDaysIcon, RectangleGroupIcon, WrenchScrewdriverIcon } from "@heroicons/vue/24/solid"

// internal
import { DragIndicatorIcon, TaskIcon } from "~/icons"

interface Props {
  widgetKey: string
  disabled?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    disabled: false,
  },
)

const highlightedKeys = [ "custom" ]

const widgetIcon = computed(() => {
  if (props.widgetKey.includes("custom")) {
    return WrenchScrewdriverIcon
  }
  switch (props.widgetKey) {
    case "tasks":
      return TaskIcon
    case "documentList":
      return ListBulletIcon
    case "documentsByStage":
    case "documentsByTeam":
    case "documentsByTemplate":
      return ChartPieIcon
    case "upcomingWorkload":
    case "contractValues":
      return ChartBarIcon
    case "documentCalendar":
      return CalendarDaysIcon
    default:
      return RectangleGroupIcon
  }
})

const isHighlighted = computed(() => {
  return highlightedKeys.includes(props.widgetKey)
})

defineEmits([ "click" ])

</script>

<template>
  <button
    type="button"
    class="inline-flex items-center max-w-full gap-1 transition-transform pl-1 pr-2 py-1 text-sm border rounded-md shadow-md group translate-x-0"
    :class="disabled ? 'bg-gray-100 text-gray-500 border-gray-200 cursor-default' : isHighlighted ? 'border-yellow-300 bg-yellow-100 hover:bg-yellow-200 text-yellow-600 cursor-pointer' : 'border-indigo-300 bg-indigo-50 hover:bg-indigo-100 text-indigo-700 cursor-pointer'"
    :disabled="disabled"
    @click="$emit('click', widgetKey)"
  >
    <div class="flex items-center justify-center gap-x-2">
      <DragIndicatorIcon
        v-if="!disabled"
        class="w-4 h-4 cursor-move shrink-0"
        :class="isHighlighted ? 'text-yellow-400 @group-hover:text-yellow-500' : 'text-indigo-300 @group-hover:text-indigo-500'"
      />
      <component
        :is="widgetIcon"
        aria-hidden="true"
        :class="disabled ? 'text-gray-300' : isHighlighted ? 'text-yellow-400 @group-hover:text-yellow-500' : 'text-indigo-300 @group-hover:text-indigo-500'"
        class="w-4 h-4 relative shrink-0"
      />
      <span
        class="text-sm grow text-left"
      >
        {{ widgetKey.includes('custom') ? $t(`dashboard.widgets.custom`) : $t(`dashboard.widgets.${widgetKey}`) }}
      </span>
    </div>
  </button>
</template>
