<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"

// internal
import { DocumentCalendarEntry } from "~/types"
import DocumentCalendarEntryLink from "./DocumentCalendarEntryLink.vue"
import { isToday } from "~/utils/helpers"
import { useDashboardStore } from "~/stores"

interface Props {
  viewMode?: string
  date: Date | number
  calendarDocuments: DocumentCalendarEntry[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    viewMode: "year",
  },
)

const dashboardStore = useDashboardStore()
const { mau } = storeToRefs(dashboardStore)

const locale = computed(() => mau.value.user.locale)

// Remember JS date months start at 0
const getDaysOfMonth = (monthIdx = 3, year = 2023) => {
  const daysInMonth = new Date(year, monthIdx + 1, 0).getDate()

  const days = []

  // days from current months
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, monthIdx, i)

    const documents = getDocumentsByDate(date)

    if (documents.length) {
      days.push({
        date: date,
        isToday: isToday(date),
        isSelected: false,
        documents: getDocumentsByDate(date),
      })
    }
  }

  return days
}

const months = computed(() => {
  const date = new Date(props.date)

  const today = new Date()

  const monthData = []

  for (let i = 0; i < 12; i++) {
    const monthDate = new Date(date.getFullYear(), i, 1)

    monthData.push({
      name: monthDate.toLocaleString(locale.value, { month: "long" }),
      isCurrentMonth: monthDate.getMonth() === today.getMonth() && monthDate.getFullYear() === today.getFullYear(),
      days: getDaysOfMonth(i, date.getFullYear()),
    })
  }

  return monthData
})

const hasAnyEventsInYear = computed(() => {
  const monthsWithEvents = months.value.filter((month) => !!month.days.length)
  return !!monthsWithEvents.length
})

const getDocumentsByDate = (date) => {
  if (props.calendarDocuments?.length) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    const dateString = date.toISOString().split("T")[0]

    return props.calendarDocuments.filter((entry) => {
      return entry.date === dateString
    })
  }
  return []
}

const setMonth = (monthIdx) => {
  emit("update:date", new Date(props.date).setMonth(monthIdx))
  emit("update:view-mode", "month")
}

const emit = defineEmits([ "update:date", "update:view-mode" ])
</script>

<template>
  <div class="w-full grow rounded-lg relative overflow-y-auto bg-gray-200 gap-px flex flex-col @4xl:grid @4xl:grid-cols-3 @4xl:grid-rows-4 @6xl:grid-cols-4 @6xl:grid-rows-3">
    <div
      v-show="!hasAnyEventsInYear"
      class="block @4xl:hidden px-4 py-3 bg-white grow"
    >
      <div
        class="w-full h-full flex items-center justify-center"
      >
        <span class="text-xs text-gray-400 py-7">{{ $t('dashboard.documentCalendar.noEvents') }}</span>
      </div>
    </div>
    <div
      v-for="(month, monthIdx) in months"
      :key="'month_' + monthIdx"
      class="@4xl:h-full flex-col bg-white"
      :class="month.days.length ? 'flex' : 'hidden @4xl:flex'"
    >
      <div
        class="px-4 py-2 border-b border-gray-200"
        :class="month.isCurrentMonth ? 'bg-indigo-50' : 'bg-white'"
      >
        <button
          type="button"
          class="text-xs font-semibold leading-6 text-gray-900 hover:text-indigo-600 cursor-pointer"
          @click="setMonth(monthIdx)"
        >
          {{ month.name }}
        </button>
      </div>
      <div
        class="px-4 py-3 grow space-y-1 relative @4xl:overflow-y-auto @4xl:overflow-x-hidden"
        :class="month.isCurrentMonth ? 'bg-indigo-50/30 text-indigo-900' : 'bg-white text-gray-400'"
      >
        <div
          v-if="!month.days.length"
          class="w-full h-full flex items-center justify-center"
        >
          <span class="text-xs py-7">{{ $t('dashboard.documentCalendar.noEvents') }}</span>
        </div>
        <div
          v-for="(day, dayIdx) in month.days"
          :key="'day_' + dayIdx"
          class="flex space-x-2"
        >
          <div
            v-if="day.documents.length"
            class="relative rounded-md w-6 h-6 shrink-0"

            :class="day.isToday ? 'bg-indigo-500 text-white' : 'bg-gray-100 text-gray-900'"
          >
            <div
              class="absolute top-[2px] left-[2px] right-[2px] h-1 rounded-t-md bg-gray-200"

              :class="day.isToday ? 'bg-indigo-300' : 'bg-gray-200'"
            />
            <div class="flex items-center justify-center mt-1.5">
              <span class="text-xs font-medium">{{ new Date(day.date).getDate() }}</span>
            </div>
          </div>
          <div class="pt-1 space-y-1 truncate">
            <DocumentCalendarEntryLink
              v-for="(entry, entryIdx) in day.documents"
              :key="'entry_' + entryIdx"
              :entry="entry"
              class="text-xs"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
