<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref, watch, computed, nextTick } from "vue"
import { ArrowPathIcon, TrashIcon, PlusIcon, MagnifyingGlassIcon, BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/vue/24/outline"
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/vue/24/solid"
import { XCircleIcon } from "@heroicons/vue/20/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { useI18n } from "vue-i18n"

// internal
import { DialogModal, LoadingPlaceholder, MultiFieldIcon, MetadataTypeDisplay, DashboardWidget, DashboardDataDisplay, TabPills, ColorInput } from "~/components"
import { useMetadataStore } from "~/stores"
import { CustomWidget, CustomWidgetConfig, DashboardGridItem, ChartType, MultiFieldType, WidgetNumberMetric, MetadataTab, MetadataType } from "~/types"
import { sortByMetadataName, filterByMetadataName, getMetadataDisplayName } from "~/utils"
import { DEFAULT_CHART_COLORS } from "~/utils/constants"

interface Props {
  show: boolean
  widget: CustomWidget
  item: DashboardGridItem
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const metadataStore = useMetadataStore()
const { metadata, isLoadingMetadata } = storeToRefs(metadataStore)
const { fetchMetadata } = metadataStore

const selectedMetadata = ref(null)
const selectedChartType = ref(props.widget.config?.chart_type || ChartType.donut)
const selectedNumberMetrics = ref(props.widget.config?.number_metrics || [])
const selectedTimeMetadata = ref(null)
const selectedTimeGroup = ref("month")

const timeGroups = [ "day", "week", "month", "quarter", "year" ]

const name = ref(props.widget.name || null)

const { t } = useI18n()

const metadataTabs = computed(() => [
  { id: MetadataTab.all, name: t("common.all") },
  { id: MetadataTab.account, name: t("accountSettings.metadata.tabs.account") },
  { id: MetadataTab.reference, name: t("accountSettings.metadata.tabs.reference") },
  { id: MetadataTab.system, name: t("accountSettings.metadata.tabs.system") },
])
const activeTab = ref(metadataTabs.value[0])
const query = ref("")
const sortDirection = ref<"asc"|"desc">("desc")

const isSwitchingDisplayMode = ref(false)

const showValuesOverTime = ref(false)

const numberMetrics:WidgetNumberMetric[] = Object.keys(WidgetNumberMetric) as WidgetNumberMetric[]

const colors = ref(props.widget.config?.colors ? [ ...props.widget.config?.colors ] : [])

const config = computed<CustomWidgetConfig>(() => {
  const c = {
    metadata_uuid: selectedMetadata.value?.uuid || null,
    metadata_name: selectedMetadata.value?.name || null,
    value_type: selectedMetadata.value?.value_type || null,
    chart_type: selectedChartType.value,
    number_metrics: selectedNumberMetrics.value.length ? selectedNumberMetrics.value : null,
    colors: colors.value.length ? [ ...colors.value ] : null,
  }

  if (showValuesOverTime.value && selectedTimeMetadata.value) {
    c["time_metadata_uuid"] = selectedTimeMetadata.value.uuid
    c["time_group"] = selectedTimeGroup.value
  }

  return c
})

const chartTypes = computed(() => {
  if (!selectedMetadata.value) return []

  if ((selectedMetadata.value.value_type === MultiFieldType.currency || selectedMetadata.value.value_type === MultiFieldType.currency_duration) && !!selectedTimeMetadata.value && showValuesOverTime.value) {
    return [ ChartType.line ]
  }

  switch (selectedMetadata.value.value_type) {
    case MultiFieldType.select:
    case MultiFieldType.text:
    case MultiFieldType.textarea:
    case MultiFieldType.email:
      return [ ChartType.donut, ChartType.pie, ChartType.bar ]
    case MultiFieldType.date:
    case MultiFieldType.timestamp:
      return [ ChartType.line ]
    case MultiFieldType.currency:
    case MultiFieldType.currency_duration:
      return [ ChartType.bar, ChartType.number ]
    case MultiFieldType.duration:
    case MultiFieldType.number:
      return [ ChartType.number ]
    default:
      return [ ChartType.donut ]
  }
})

const maxColors = computed(() => {
  if (!selectedChartType.value) return 0

  if (selectedChartType.value === ChartType.line && showValuesOverTime.value) return 0

  switch (selectedChartType.value) {
    case ChartType.donut:
    case ChartType.pie:
      return 0 // no limit
    default:
      return 1
  }
})

const metadataInTab = computed(() => metadata.value.filter((entry) => {
  if (activeTab.value.id === MetadataTab.account) {
    return entry.type === MetadataType.account
  }
  if (activeTab.value.id === MetadataTab.reference) {
    return entry.type === MetadataType.reference
  }
  if (activeTab.value.id === MetadataTab.system) {
    return entry.type !== MetadataType.account && entry.type !== MetadataType.reference
  }
  return true
}))
const sortedMetadata = computed(() => sortByMetadataName(metadataInTab.value, t, sortDirection.value))
const filteredMetadata = computed(() => filterByMetadataName(sortedMetadata.value, t, query.value))

const timeMetadataOptions = computed(() => sortedMetadata.value.filter((entry) => entry.value_type === MultiFieldType.date || entry.value_type === MultiFieldType.timestamp))

const emit = defineEmits([ "update:show", "submit" ])

const selectMetadata = (metadata) => {
  selectedMetadata.value = metadata
}

const selectChartType = (chartType) => {
  isSwitchingDisplayMode.value = true
  nextTick(() => {
    selectedChartType.value = chartType
    isSwitchingDisplayMode.value = false
  })
}

const addColor = () => {
  const newColor = DEFAULT_CHART_COLORS[(colors.value.length) % DEFAULT_CHART_COLORS.length]
  colors.value.push(newColor)
}
const removeColor = (idx) => {
  isSwitchingDisplayMode.value = true
  nextTick(() => {
    colors.value.splice(idx, 1)
    isSwitchingDisplayMode.value = false
  })
}

const toggleNumberMetric = (numberMetric:WidgetNumberMetric) => {
  if (selectedNumberMetrics.value.includes(numberMetric)) {
    selectedNumberMetrics.value.splice(selectedNumberMetrics.value.indexOf(numberMetric), 1)
  } else {
    selectedNumberMetrics.value.push(numberMetric)
  }
}

const toggleSortDirection = () => {
  sortDirection.value = sortDirection.value === "desc" ? "asc" : "desc"
}

const close = () => {
  emit("update:show", false)
}

const submit = () => {
  const payload = {
    ...props.widget,
  }

  payload.name = name.value || getMetadataDisplayName(selectedMetadata.value, t)
  payload.config = config.value

  emit("submit", payload)
  close()
}

watch(() => props.show, async (newVal) => {
  if (newVal) {
    await fetchMetadata()
    if (props.widget.config?.metadata_uuid) {
      selectedMetadata.value = metadata.value.find((md) => md.uuid === props.widget.config.metadata_uuid)
    }
    if (props.widget.config?.time_metadata_uuid) {
      showValuesOverTime.value = true
      selectedTimeMetadata.value = metadata.value.find((md) => md.uuid === props.widget.config.time_metadata_uuid)
      selectedTimeGroup.value = props.widget.config.time_group ?? "month"
    }
    selectedChartType.value = props.widget.config?.chart_type || chartTypes.value[0]
    colors.value = props.widget.config?.colors ? [ ...props.widget.config?.colors ] : []
  } else {
    query.value = ""
    colors.value = []
    metadataStore.$reset()
  }
})

watch(selectedMetadata, (newVal, oldVal) => {
  if (newVal && !oldVal) {
    name.value = getMetadataDisplayName(newVal, t)
    selectedChartType.value = chartTypes.value.includes(props.widget.config?.chart_type) ? props.widget.config?.chart_type : chartTypes.value[0]
  }
})

watch(showValuesOverTime, (newVal) => {
  if (newVal && !selectedTimeMetadata.value) {
    selectedTimeMetadata.value = metadata.value.find((entry) => entry.name === "duration_start_date")
  } else if (!newVal) {
    selectedTimeMetadata.value = null
  }

  selectedChartType.value = chartTypes.value[0]
})

const previewEndpoint = computed(() => {
  if (!selectedMetadata.value) return ""

  if (config.value.time_metadata_uuid) {
    return route("api.data.documents.valuesByTime", {
      metadata: config.value.metadata_uuid,
      time_metadata: config.value.time_metadata_uuid,
      time_group: config.value.time_group,
    })
  } else {
    return route("api.data.documents.metadataValues", config.value.metadata_uuid)
  }
})
</script>

<template>
  <DialogModal
    :show="props.show"
    max-width="3xl"
    :padding="false"
    :show-close-button="true"
    :show-footer="!!selectedMetadata"
    @close="close"
  >
    <template #title>
      <div class="px-6 pt-4">
        {{ $t('dashboard.configureWidget') }}
      </div>
    </template>
    <template #content>
      <div
        class="px-6 pb-6 space-y-6"
      >
        <div
          v-if="isLoadingMetadata"
        >
          <LoadingPlaceholder
            class="relative w-full h-[122px] rounded-lg mb-4"
          />
          <div
            class="grid gap-3 sm:grid-cols-2 lg:grid-cols-3"
          >
            <LoadingPlaceholder
              v-for="n in 27"
              :key="'tmp_'+n"
              class="relative w-full h-[44px] rounded-lg"
            />
          </div>
        </div>


        <div
          v-else-if="!selectedMetadata"
        >
          <div>
            <div class="text-sm font-medium">
              {{ $t('dashboard.selectMetadata') }}
            </div>
            <div class="my-4">
              <TabPills
                v-model:active-tab="activeTab"
                :tabs="metadataTabs"
                nav-classes="flex justify-center w-full"
              />
            </div>
            <div class="flex items-center justify-between mb-4 space-x-1">
              <div class="relative grow">
                <input
                  v-model="query"
                  type="search"
                  class="block w-full px-9 min-h-[38px] py-0 text-base sm:text-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none border-0 appearance-none rounded-l-md"
                  :class="[!!query ? 'bg-indigo-50 text-indigo-800' : 'bg-gray-100']"
                  :placeholder="$t('filter.search') + '…'"
                >
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon
                    class="w-4 h-4 text-gray-400"
                  />
                </div>
                <button
                  v-if="!!query"
                  type="button"
                  class="absolute inset-y-0 right-0 flex items-center pr-3"
                  @click="query = null"
                >
                  <XCircleIcon
                    class="w-4 h-4 text-indigo-500"
                  />
                </button>
              </div>
              <div>
                <button
                  type="button"
                  class="text-sm text-gray-500 min-h-[38px] px-3 h-full flex items-center rounded-r-md bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:z-10 focus:outline-none"
                  @click="toggleSortDirection"
                >
                  <BarsArrowDownIcon
                    v-if="sortDirection === 'desc' || !sortDirection"
                    aria-hidden="true"
                    class="w-5 h-5"
                  />
                  <BarsArrowUpIcon
                    v-else-if="sortDirection === 'asc'"
                    aria-hidden="true"
                    class="w-5 h-5"
                  />
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="!!query && !filteredMetadata.length"
            class="p-5 text-sm text-center text-gray-500"
          >
            {{ $t('accountSettings.metadata.noMatches') }}
          </div>
          <div
            v-else
            class="grid gap-3 sm:grid-cols-2 lg:grid-cols-3"
          >
            <button
              v-for="entry in filteredMetadata"
              :key="entry.uuid"
              type="button"
              class="container relative overflow-hidden text-center transition-all duration-150 border-2 rounded-lg bg-gradient-to-br group bg-pos-0 hover:bg-pos-50 bg-size-200"
              :class="'from-white to-indigo-50 border-indigo-100 hover:border-indigo-200'"
              @click="selectMetadata(entry)"
            >
              <div class="flex items-center justify-center p-2 space-x-2">
                <MultiFieldIcon
                  :type="entry.value_type"
                  class="relative mx-auto transition-colors duration-150 shrink-0 text-indigo-400 @group-hover:text-indigo-400"
                  size-classes="w-6 h-6"
                  ring-class="ring-white"
                />
                <span
                  class="text-xs font-medium text-left text-gray-600 grow"
                >
                  {{ entry.type === MetadataType.account || !!entry.account_metadata_uuid ? entry.display_name : $t('metadata.system.' + entry.name + '.name') }}
                </span>
              </div>
            </button>
          </div>
        </div>
        <div
          v-else
          class="space-y-5"
        >
          <div class="flex justify-between w-full space-x-5">
            <span class="flex items-center space-x-2 font-medium">
              <span>{{ getMetadataDisplayName(selectedMetadata, $t) }}</span>
              <button
                class="rounded-full"
                type="button"
                @click="selectedMetadata = null"
              >
                <ArrowPathIcon
                  aria-hidden="true"
                  class="w-4 h-4 text-indigo-500"
                />
              </button>
            </span>
            <MetadataTypeDisplay :type="selectedMetadata.value_type" />
          </div>
          <div>
            <div class="mb-1 text-sm font-medium">
              {{ $t('magicTable.columns.name') }}
            </div>
            <input
              v-model="name"
              type="text"
              class="input-plain"
              :placeholder="$t('dashboard.form.namePlaceholder') + '…'"
            >
          </div>
          <div v-if="!!selectedChartType">
            <div class="mb-1 text-sm font-medium">
              {{ $t('accountSettings.brandIdentity.preview') }}
            </div>
            <div class="flex items-center justify-center w-full p-10 bg-gray-100 rounded-md shadow-inner">
              <DashboardWidget
                :title="name || (selectedMetadata.type.includes('system') ? t('metadata.system.' + selectedMetadata.name + '.name') : selectedMetadata.display_name)"
                :loading="isSwitchingDisplayMode"
                class="max-w-[400px]"
                box-classes="p-2"
                :style="`height: ${item.h * 70}px`"
                :href="previewEndpoint"
              >
                <template #default="{data}">
                  <DashboardDataDisplay
                    :data="data"
                    :config="config"
                    :name="name || (selectedMetadata.type.includes('system') ? t('metadata.system.' + selectedMetadata.name + '.name') : selectedMetadata.display_name)"
                  />
                </template>
              </DashboardWidget>
            </div>
          </div>
          <div
            v-if="selectedMetadata.value_type === MultiFieldType.currency || selectedMetadata.value_type === MultiFieldType.currency_duration"
            class="grid grid-cols-2 gap-5"
          >
            <div>
              <label class="flex items-center mb-1 space-x-2 w-fit">
                <input
                  v-model="showValuesOverTime"
                  type="checkbox"
                  class="w-4 h-4 text-indigo-500 border-gray-300 rounded focus:ring-indigo-500"
                  :checked="showValuesOverTime"
                >
                <span class="text-sm font-medium">{{ $t('dashboard.showValuesOverTime') }}</span>
              </label>
              <div v-if="showValuesOverTime">
                <Listbox
                  v-model="selectedTimeMetadata"
                  as="div"
                >
                  <div class="relative">
                    <ListboxButton class="btn-listbox-plain">
                      <span v-if="selectedTimeMetadata">{{ selectedTimeMetadata.type.includes('system') ? $t('metadata.system.' + selectedTimeMetadata.name + '.name') : selectedTimeMetadata.display_name }}</span>
                      <span class="absolute inset-y-0 flex items-center pointer-events-none right-1">
                        <ChevronUpDownIcon
                          class="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions class="absolute z-10 mb-1 bottom-full listbox-options">
                        <ListboxOption
                          v-for="option in timeMetadataOptions"
                          :key="option.name"
                          v-slot="{ active, selected }"
                          as="template"
                          :value="option"
                        >
                          <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                            <div class="flex items-center">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ option.type.includes('system') ? $t('metadata.system.' + option.name + '.name') : option.display_name }}</span>
                            </div>
                            <span
                              v-if="selected"
                              :class="[
                                active ? 'text-white' : 'text-indigo-500',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon
                                class="w-5 h-5 shrink-0"
                                aria-hidden="true"
                              />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>
            <div v-if="showValuesOverTime">
              <span class="block mb-1 text-sm font-medium">{{ $t('dashboard.groupData') }}</span>
              <div v-if="showValuesOverTime">
                <Listbox
                  v-model="selectedTimeGroup"
                  as="div"
                >
                  <div class="relative">
                    <ListboxButton class="btn-listbox-plain">
                      <span v-if="selectedTimeGroup">{{ $t('dashboard.timeGroups.' + selectedTimeGroup) }}</span>
                      <span class="absolute inset-y-0 flex items-center pointer-events-none right-1">
                        <ChevronUpDownIcon
                          class="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions class="absolute z-10 mb-1 bottom-full listbox-options">
                        <ListboxOption
                          v-for="timeGroup in timeGroups"
                          :key="timeGroup"
                          v-slot="{ active, selected }"
                          as="template"
                          :value="timeGroup"
                        >
                          <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                            <div class="flex items-center">
                              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ $t('dashboard.timeGroups.' + timeGroup) }}</span>
                            </div>
                            <span
                              v-if="selected"
                              :class="[
                                active ? 'text-white' : 'text-indigo-500',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon
                                class="w-5 h-5 shrink-0"
                                aria-hidden="true"
                              />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>

          <div
            v-if="chartTypes.length > 1"
          >
            <div class="mb-1 text-sm font-medium">
              {{ $t('dashboard.availableChartTypes') }}
            </div>
            <div
              class="flex items-center justify-center space-x-2"
            >
              <button
                v-for="chartType in chartTypes"
                :key="chartType"
                type="button"
                :class="selectedChartType === chartType ? 'btn-primary' : 'btn-white'"
                @click="selectChartType(chartType)"
              >
                {{ $t('dashboard.chartTypes.' + chartType) }}
              </button>
            </div>
          </div>
          <div
            v-if="selectedChartType === ChartType.number"
            class="flex items-center justify-center space-x-2"
          >
            <button
              v-for="numberMetric in numberMetrics"
              :key="numberMetric"
              type="button"
              class="btn-sm"
              :class="selectedNumberMetrics.includes(numberMetric) ? 'btn-primary' : 'btn-white'"
              @click="toggleNumberMetric(numberMetric)"
            >
              {{ $t('dashboard.numbers.' + numberMetric) }}
            </button>
          </div>
          <div v-if="selectedChartType !== ChartType.number && selectedMetadata.value_type !== MultiFieldType.number && selectedMetadata.value_type !== MultiFieldType.duration">
            <div class="mb-1 text-sm font-medium">
              {{ $t('dashboard.customizeColors') }}
            </div>
            <div class="space-y-2">
              <div
                v-for="(color, idx) in colors"
                :key="'color_' + idx"
                class="flex items-center space-x-2"
              >
                <ColorInput
                  v-model:color="colors[idx]"
                  class="grow"
                />
                <button
                  type="button"
                  class="btn-plain btn-sm"
                  @click="removeColor(idx)"
                >
                  <TrashIcon
                    aria-hidden="true"
                    class="w-4 h-4"
                  />
                  <span class="sr-only">{{ $t('common.remove') }}</span>
                </button>
              </div>
              <button
                v-if="maxColors === 0 || colors.length < maxColors"
                type="button"
                class="flex items-center space-x-1 btn-plain btn-sm"
                @click="addColor"
              >
                <PlusIcon
                  aria-hidden="true"
                  class="w-4 h-4"
                />
                <span>{{ $t('dashboard.addColor') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end space-x-2">
        <button
          :disabled="!selectedMetadata"
          type="button"
          class="flex items-center space-x-2 btn-primary"
          @click.prevent="submit"
        >
          <span>{{ $t('common.save') }}</span>
        </button>
      </div>
    </template>
  </DialogModal>
</template>
