<script setup lang="ts">
// external
import { computed, ref } from "vue"
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { InformationCircleIcon, PlusIcon, ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline"
import { Link, router } from "@inertiajs/vue3"

// internal
import { SpinLoader, ColorInput } from "~/components"
import { DocumentUser, Tag, AccountUser } from "~/types"

interface Props {
  modelValue: Tag | null
  options: Tag[]
  isHighlighted?: boolean
  loading?: boolean
  disabled?: boolean
  mdu?: DocumentUser
  mau?: AccountUser
  clearable?: boolean
  inline?: boolean
  selectedTags?: Tag[]
}

const props = withDefaults(defineProps<Props>(), {
  mdu: null,
  mau: null,
  isHighlighted: false,
  loading: false,
  disabled: false,
  clearable: false,
  inline: true,
  selectedTags: null,
})

const emit = defineEmits([ "focus", "blur", "update:model-value" ])

const selectedValue = computed({
  get: () => {
    return props.modelValue
  },
  set: (val) => {
    emit("update:model-value", val)
  },
})

const sortedOptions = computed(() => {
  return [ ...props.options ].sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
})

const canManageTags = computed(() => !!props.mau?.permissions.includes("tag_manage"))
const isDisabled = computed(() => props.disabled || (!canManageTags.value && !sortedOptions.value.length))

const isCreationMode = ref(false)

const localTag = ref({
  name: "",
  color: "#789ABC",
})

const isValid = computed(() => !!localTag.value.name.length && localTag.value.color.length === 7)
const isSubmitting = ref(false)

const createTag = () => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    router.post(route("account-settings.tags.store"), {
      ...localTag.value,
      back: true,
    }, {
      only: [ "allTags" ],
      onSuccess: () => {
        isCreationMode.value = false
      },
      onFinish: () => {
        isSubmitting.value = false
      },
    })
  }
}

</script>
<template>
  <Listbox
    :id="'tagSelector'"
    v-model="selectedValue"
    as="div"
    class="px-6 py-2 text-sm transition-all duration-500"
    :class="[isHighlighted ? 'bg-teal-50' : '', inline ? 'flex justify-between items-center space-x-2' : '']"
  >
    <ListboxLabel
      :class="inline ? 'shrink-0 text-gray-500' : 'block font-medium'"
      class="flex items-center gap-2 py-2 pr-3 shrink-0"
    >
      <slot name="label">
        {{ $t('settings.sections.tags') }}
      </slot>
      <SpinLoader
        v-if="loading"
        class="w-3 h-3"
      />
      <span
        data-tippy-help
        :data-tippy-content="$t('documentGeneralSettings.tagHint')"
        data-placement="bottom"
      >
        <InformationCircleIcon
          class="w-3 h-3 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </ListboxLabel>
    <div
      v-if="!isDisabled"
      :class="inline ? 'grow max-w-fit' : 'w-full'"
      class="relative"
    >
      <ListboxButton
        :class="[!inline && (!selectedValue || clearable && selectedValue) ? 'pr-9' : '', inline ? 'group disabled:opacity-100 btn-plain btn-sm hover:bg-gray-100 focus:bg-gray-100 focus:ring-0 focus:ring-offset-0 text-gray-900 flex items-center gap-2' : 'group btn-listbox-plain']"
      >
        <div class="flex items-center">
          <span
            class="block font-normal text-gray-400 truncate"
          >
            {{ canManageTags || options.length ? $t('tags.addTag')+"…" : selectedTags?.length ? '' : $t('tags.noTags') }}
          </span>
        </div>
        <span
          v-if="clearable && selectedValue"
          :class=" inline ? '-right-5' : 'right-0 pr-2'"
          class="absolute inset-y-0 flex items-center transition-all duration-200 scale-0 opacity-0 pointer-events-none group-hover:scale-100 group-hover:opacity-100"
          @click.prevent="selectedValue = null"
        >
          <XMarkIcon
            class="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </span>
        <span
          v-else
          :class=" inline ? '-right-5' : 'right-0 pr-2'"
          class="absolute inset-y-0 flex items-center transition-all duration-200 scale-0 opacity-0 pointer-events-none group-hover:scale-100 group-hover:opacity-100"
        >
          <ChevronUpDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="listbox-options min-w-[240px]"
          @focus="
            $emit('focus', {
              target: { id: 'documentTagSelector' },
            })
          "
          @blur="
            $emit('blur', {
              target: { id: 'documentTagSelector' },
            })
          "
        >
          <li
            v-if="isCreationMode"
            class="px-2 pb-1 space-y-2"
          >
            <div class="pt-2 text-center">
              {{ $t('tags.createNewTag') }}
            </div>
            <input
              v-model="localTag.name"
              :maxlength="35"
              type="text"
              class="text-white placeholder-gray-400 bg-gray-700 input-plain"
              :placeholder="$t('accountSettings.tags.tagPlaceholder') + '…'"
              @keydown.stop
            >
            <ColorInput
              v-model:color="localTag.color"
              input-classes="bg-gray-700 text-white placeholder-gray-400"
            />
            <div class="grid grid-cols-2 gap-x-2">
              <button
                type="button"
                class="py-2 rounded hover:bg-gray-700"
                @click="isCreationMode = false"
              >
                {{ $t('common.cancel') }}
              </button>
              <button
                type="button"
                :disabled="!isValid"
                class="flex items-center justify-center py-2 rounded gap-x-1"
                :class="isValid ? 'bg-green-500/50 hover:bg-green-600/50' : 'opacity-50'"
                @click="createTag"
              >
                <SpinLoader
                  v-if="isSubmitting"
                  class="w-3 h-3"
                />
                <span>{{ $t('common.create') }}</span>
              </button>
            </div>
          </li>
          <li v-else>
            <ul class="max-h-[150px] overflow-y-auto">
              <ListboxOption
                v-for="tag in sortedOptions"
                :key="tag.uuid"
                v-slot="{ active, selected }"
                as="template"
                :value="tag"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <div class="flex items-center">
                    <span
                      class="block w-4 h-4 mr-2 border border-gray-500 rounded-full"
                      :style="`background-color: ${tag.color}`"
                    />
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{ tag.name }}
                    </span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ul>

            <ul v-if="canManageTags">
              <li
                class="listbox-option hover:bg-gray-700"
                :class="sortedOptions.length ? 'border-gray-700 border-t' : ''"
              >
                <button
                  type="button"
                  class="block w-full"
                  @click="isCreationMode = true"
                >
                  <div class="flex items-center">
                    <PlusIcon
                      aria-hidden="true"
                      class="w-4 h-4 mr-2"
                    />
                    <span
                      class="block truncate"
                    >
                      {{ $t('tags.createNewTag') }}
                    </span>
                  </div>
                </button>
              </li>
              <li
                class="listbox-option hover:bg-gray-700"
              >
                <Link
                  :href="route('account-settings.tags.index')"
                  class="block w-full"
                >
                  <div class="flex items-center">
                    <ArrowTopRightOnSquareIcon
                      aria-hidden="true"
                      class="w-4 h-4 mr-2"
                    />
                    <span
                      class="block truncate"
                    >
                      {{ $t('tags.manageTags') }}
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </li>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
