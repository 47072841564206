import { Node, mergeAttributes } from "@tiptap/core"

import ColumnNodeView from "./ColumnNodeView.vue"
import { VueNodeViewRenderer } from "@tiptap/vue-3"

export const Column = Node.create(
  {
    name: "column",

    group: "column",

    content: "(paragraph|block)+",

    isolating: true,

    selectable: false,

    parseHTML () {
      return [
        {
          tag: "div.custom-column",
        },
      ]
    },

    renderHTML ({ HTMLAttributes }) {
      const attrs = mergeAttributes(HTMLAttributes, { class: "custom-column" })

      return [
        "div",
        attrs,
        0,
      ]
    },

    addNodeView () {
      return VueNodeViewRenderer(ColumnNodeView)
    },
  },
)
