<script setup lang="ts">
// external
import { onBeforeMount, onBeforeUnmount, ref } from "vue"
import { NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"
import debounce from "lodash-es/debounce"

// internal
import SignatureBlock from "./SignatureBlock.vue"

const props = defineProps<NodeViewProps>()

const isEditable = ref(props.editor.isEditable)

const resetEditable = () => isEditable.value = props.editor.isEditable

const debouncedResetEditable = debounce(resetEditable, 500)

onBeforeMount(
  () => {
    props.editor.on(
      "transaction",
      debouncedResetEditable,
    )
  },
)

onBeforeUnmount(
  () => {
    props.editor.off(
      "transaction",
      debouncedResetEditable,
    )
  },
)
</script>

<template>
  <NodeViewWrapper
    class="inline-block my-4 mr-2 align-top"
  >
    <SignatureBlock
      :ref-uuid="props.node.attrs.refUuid"
      :is-editable="isEditable"
      @delete="props.deleteNode"
    />
  </NodeViewWrapper>
</template>
