<script setup lang="ts">
import {
  InformationCircleIcon,
} from "@heroicons/vue/24/outline"

interface Props {
  text?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)
</script>
<template>
  <div class="rounded-md bg-indigo-100 shadow-sm text-indigo-700 px-3 py-2 flex items-center space-x-2">
    <div class="shrink-0">
      <InformationCircleIcon
        class="w-5 h-5"
        aria-hidden="true"
      />
    </div>
    <div class="text-sm">
      <slot>{{ props.text }}</slot>
    </div>
  </div>
</template>
