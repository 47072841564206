import { toRaw, toRefs, watch } from "vue"

import { Extension } from "@tiptap/core"
import { Node as PMNode } from "@tiptap/pm/model"
import { Plugin, PluginKey } from "@tiptap/pm/state"
import { Decoration, DecorationSet } from "@tiptap/pm/view"

import { isEqual } from "lodash-es"

import UuidMarker from "~/editor/utils/uuidMarker"
import renderHighlight from "~/editor/utils/renderHighlight"
import { useCommentStore, useDocumentStore } from "~/stores"
import { Comment } from "~/types"

interface MarkCommentsAndProposalsProps {
  doc: PMNode
  comments: Comment[]
  activeCommentProsemirrorDataUuid: Comment["prosemirror_data_uuid"]
  highlightClickHandler: (prosemirrorDataUuid: Comment["prosemirror_data_uuid"]) => void
  commentStore: ReturnType<typeof useCommentStore>
  setCommentsAndProposalsDecoSet: (decoSet: DecorationSet) => void
}
const markCommentsAndProposals = ({
  doc,
  comments = [],
  activeCommentProsemirrorDataUuid,
  highlightClickHandler,
  commentStore,
  setCommentsAndProposalsDecoSet,
}: MarkCommentsAndProposalsProps) => {

  const decorations = []

  // Check if active element is already included in state storage
  const commentWithUuidExists = comments.some(({ prosemirror_data_uuid }) => prosemirror_data_uuid === activeCommentProsemirrorDataUuid)
  // Build query and if necessary concatenate active element
  const rawComments: Partial<Comment>[] = comments.slice()

  const query = commentWithUuidExists
    ? rawComments.map(
      (obj) =>
        obj.prosemirror_data_uuid === activeCommentProsemirrorDataUuid
          ? ({ ...obj, type: "active" })
          : obj,
    )
    : rawComments.concat({ prosemirror_data_uuid: activeCommentProsemirrorDataUuid, type: "active" })

  const results = new UuidMarker(doc).scan(query, [ "heading", "paragraph", "fineprint" ]).getResults()

  results.forEach(
    (result) => {
      let relatedComments: Comment[] = []

      if (
        result.item?.uuid
        && [ "comment", "proposal" ].includes(result.type)
      ) {
        relatedComments = commentStore.getCommentsByProsemirrorDataUuid(result.item.uuid) || []

        if (
          relatedComments.some((c) => commentStore.highlightedCommentUuids.includes(c.uuid))
        ) result.type = "active"
      }

      decorations.push(
        Decoration.widget(
          result.from,
          renderHighlight(
            result,
            () => highlightClickHandler?.(result.node.attrs.uuid),
            relatedComments,
          ),
          {
            key: `highlight-${result.node.attrs.uuid || result.number}`,
            side: -3,
            ignoreSelection: true,
          },
        ),
      )

      if ([ "comment", "proposal" ].includes(result.type)) {
        const [ from, to ] = [ result.from - 1, result.from - 1 + result.node.nodeSize ]
        const className = "has-highlight"

        decorations.push(Decoration.node(from, to, { class: className }))
      } else if (result.type === "active") {
        const [ from, to ] = [ result.from - 1, result.from - 1 + result.node.nodeSize ]
        const className = "has-highlight is-active"

        decorations.push(Decoration.node(from, to, { class: className }))
      }
    },
  )

  const decoSet = DecorationSet.create(doc, decorations)
  setCommentsAndProposalsDecoSet(decoSet)

  return decoSet
}

interface HighlightCommentsAndProposalsOptions {
  restricted: boolean
  setIsDeleteConfirmationModalOpen: (isOpen: boolean) => void
}

export const HighlightCommentsAndProposals = Extension.create<HighlightCommentsAndProposalsOptions>({
  name: "highlightCommentsAndProposals",

  addOptions () {
    return {
      restricted: false,
      setIsDeleteConfirmationModalOpen: () => {
        //
      },
    }
  },

  addStorage () {
    return {
      decoSet: DecorationSet.empty,
    }
  },

  addProseMirrorPlugins () {
    const { editor } = this
    const { setIsDeleteConfirmationModalOpen } = this.options

    const setCommentsAndProposalsDecoSet = (decoSet: DecorationSet) => {
      editor.storage.highlightCommentsAndProposals.decoSet = decoSet
    }

    const documentStore = useDocumentStore()
    const { mdu } = toRefs(documentStore)

    const commentStore = useCommentStore()
    const {
      comments,
      activeCommentProsemirrorDataUuid,
      highlightedCommentUuids,
    } = toRefs(commentStore)
    const {
      triggerCommentTippy,
      highlightCommentsInListByProsemirrorDataUuid,
      setActiveCommentProsemirrorDataUuid,
      setHighlightedCommentUuids,
      setIsHighlightedViaClick,
    } = useCommentStore()

    watch(
      [ () => comments.value, () => activeCommentProsemirrorDataUuid.value, () => highlightedCommentUuids.value ],
      (val, oldVal) => {
        if (isEqual(val, oldVal)) return
        editor.commands.directDecoration()
      },
    )

    const highlightClickHandler = (prosemirrorDataUuid: Comment["prosemirror_data_uuid"]) => {
      if (!prosemirrorDataUuid) return

      if (prosemirrorDataUuid === activeCommentProsemirrorDataUuid.value) {
        setActiveCommentProsemirrorDataUuid("")
        setHighlightedCommentUuids([])
        return
      }

      const commentWithUuidExists = comments.value.some((c) => c.uuid === prosemirrorDataUuid)

      if (!commentWithUuidExists) {
        setHighlightedCommentUuids([])
      } else {
        highlightCommentsInListByProsemirrorDataUuid(prosemirrorDataUuid)
      }

      if (mdu.value?.permissions?.includes("comment_create")) triggerCommentTippy(prosemirrorDataUuid)
      else setIsHighlightedViaClick(true)
    }

    return [
      new Plugin({
        key: new PluginKey(this.name),

        state: {
          init (_, { doc }) {
            const res = markCommentsAndProposals(
              {
                doc,
                comments: toRaw(comments.value || []),
                activeCommentProsemirrorDataUuid: activeCommentProsemirrorDataUuid.value,
                highlightClickHandler,
                commentStore,
                setCommentsAndProposalsDecoSet,
              },
            )
            return res
          },

          apply (transaction, oldDecoSet) {
            const directDecoration = transaction.getMeta("directDecoration")
            if (directDecoration) {
              const res = markCommentsAndProposals(
                {
                  doc: transaction.doc,
                  comments: toRaw(comments.value || []),
                  activeCommentProsemirrorDataUuid: activeCommentProsemirrorDataUuid.value,
                  highlightClickHandler,
                  commentStore,
                  setCommentsAndProposalsDecoSet,
                },
              )
              return res
            }
            if (transaction.docChanged) return DecorationSet.empty
            return oldDecoSet || editor.storage.highlightCommentsAndProposals.decoSet

          },
        },

        props: {
          decorations (state) {
            const decos = this.getState(state)
            setCommentsAndProposalsDecoSet(decos)
            return decos
          },
        },

        filterTransaction (tr, oldState) {
          if (!tr.docChanged || tr.getMeta("forceDeleteSelectionWithIssues")) return true

          interface NodeWithRange {
            from: number
            to: number
            uuid: string
            type: string
          }

          const oldStateNodes: NodeWithRange[] = []
          const newStateNodes: NodeWithRange[] = []

          oldState.doc.descendants(
            (node, pos) => {
              if (![ "heading", "paragraph", "fineprint", "list", "table", "columnBlock" ].includes(node.type.name)) return

              oldStateNodes.push(
                {
                  from: pos,
                  to: pos + node.nodeSize,
                  uuid: node.attrs.uuid,
                  type: node.type.name,
                },
              )
            },
          )

          tr.doc.descendants((node, pos) => {
            if (![ "heading", "paragraph", "fineprint", "list", "table", "columnBlock" ].includes(node.type.name)) return

            newStateNodes.push(
              {
                from: pos,
                to: pos + node.nodeSize,
                uuid: node.attrs.uuid,
                type: node.type.name,
              },
            )
          })

          // Check if oldStateNodes and newStatesNodes length mapped by type is identical
          const oldStateNodesMappedByType = oldStateNodes.reduce((acc, { type }) => {
            if (!acc[type]) acc[type] = 0
            acc[type] += 1
            return acc
          }, {})
          const newStateNodesMappedByType = newStateNodes.reduce((acc, { type }) => {
            if (!acc[type]) acc[type] = 0
            acc[type] += 1
            return acc
          }, {})

          if (oldStateNodes.length === newStateNodes.length && isEqual(oldStateNodesMappedByType, newStateNodesMappedByType)) return true

          const deletedNodes = []

          for (const nodeWithRange of oldStateNodes) {
            const { uuid } = nodeWithRange

            const isNodeInNewState = newStateNodes
              .findIndex(({ uuid: nodeUuid }) => uuid === nodeUuid) !== -1

            if (!isNodeInNewState) deletedNodes.push(nodeWithRange)
          }

          if (!deletedNodes.length) return true

          const deletedNodesWithComments = deletedNodes.filter(
            ({ uuid }) => comments.value?.some((c) => c.prosemirror_data_uuid === uuid),
          )

          const isEmpty = !deletedNodesWithComments.length

          if (isEmpty) return true

          setIsDeleteConfirmationModalOpen(true)

          return false
        },
      }),
    ]
  },
})
