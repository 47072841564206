<script lang="ts" setup>
// external
import { MenuButton } from "@headlessui/vue"
import { IdentificationIcon } from "@heroicons/vue/24/solid"
import { ChevronDownIcon, PlusIcon } from "@heroicons/vue/20/solid"
import { sortBy } from "lodash-es"
import { storeToRefs } from "pinia"
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"

// internal
import { Dropdown, DropdownLink, PartyEntityTypeDisplayIcon, SpinLoader } from "~/components"
import { useDocumentStore, useNotificationStore, usePartyStore, useSharedStore, useSignatureStore, useTemplateStore, useUserStore } from "~/stores"
import { CrudContext, Document, DocumentTab, DocumentUser, DocumentUserRoleEnum, Party, SignatureBlock, Template, TemplateTab, SignatureBlockStyle } from "~/types"
import { MinimalSignatureBlockIcon, DefaultSignatureBlockIcon } from "~/icons"

interface MappedSignatureBlock extends SignatureBlock {
  party: Party
}

interface Props {
  id?: string
  isEditorNode?: boolean
  availableSignatureBlocks?: MappedSignatureBlock[]
}

withDefaults(
  defineProps<Props>(),
  {
    id: null,
    isEditorNode: false,
    availableSignatureBlocks: null,
  },
)

const { t } = useI18n()

const { notify } = useNotificationStore()

const signatureStore = useSignatureStore()
const { signatureBlocks } = storeToRefs(signatureStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const documentStore = useDocumentStore()
const { mau } = storeToRefs(documentStore)

const templateStore = useTemplateStore()

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const signatureBlocksToShow = computed(
  () => signatureBlocks.value?.filter((el) => !el.deleted_at) || [],
)

// Calculate entity uuid for db calls
const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return crudContext.value === CrudContext.document
    ? documentStore.currentDocument?.uuid
    : templateStore.currentTemplate?.uuid
})

const isLoadingCreateSignatureBlock = ref<boolean>(false)

const createSignatureBlock = async (partyUuid: Party["uuid"], style: SignatureBlockStyle) => {
  isLoadingCreateSignatureBlock.value = true

  try {
    const createdSignatureBlock = await signatureStore.createSignatureBlock(crudContext.value, entityUuid.value, partyUuid, style)
    if (createdSignatureBlock) emit("insert-signature-block", createdSignatureBlock.ref_uuid)
    return createdSignatureBlock
  } catch (err) {
    notify({
      title: t("documentContent.errors.signatureBlock"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingCreateSignatureBlock.value = false
  }
}

const setHash = (hash: string) => location.hash = hash

const setTabKeyToParties = () => {
  if (crudContext.value === CrudContext.document) {
    documentStore.setActiveTabKey(DocumentTab.parties)
    setHash(DocumentTab.parties)
  } else if (crudContext.value === CrudContext.template) {
    templateStore.setActiveTabKey(TemplateTab.parties)
    setHash(TemplateTab.parties)
  }
}

const getSignatories = (partyUuid: Party["uuid"]): DocumentUser[] | null => {
  const filteredUsers = users.value?.filter(
    (el) => el.party_uuid === partyUuid && el.roles?.includes(DocumentUserRoleEnum.signatory),
  )
  const orderedUsers = sortBy(filteredUsers, "signing_order")
  return orderedUsers
}

const emit = defineEmits([ "insert-signature-block", "manage-signature-blocks" ])

const insertSignatureBlock = (refUuid: SignatureBlock["ref_uuid"]) => {
  emit("insert-signature-block", refUuid)
  if (overrideClose.value) overrideClose.value = false
}

const overrideClose = ref<boolean>(false)

const handleBlur = () => {
  // When an Intercom tour is present, we want to prevent the close event
  const intercomTour = document.querySelector("#intercom-positioner-tree")
  if (intercomTour) {
    overrideClose.value = true
  }
}

</script>
<template>
  <Dropdown
    v-if="!!mau || availableSignatureBlocks?.length"
    :id="id"
    :menu-classes="signatureBlocksToShow?.length ? 'relative text-left mr-6 z-30' : 'w-full relative text-left mr-6'"
    :width="availableSignatureBlocks?.length ? '72' : signatureBlocksToShow?.length ? 'full' : ''"
    :align="isEditorNode ? 'bottom' : signatureBlocksToShow?.length ? signatureBlocksToShow?.length > 1 ? 'bottom' : 'left' : 'center'"
    :content-classes="'overflow-y-auto max-h-[240px] py-2'"
    :override-close="overrideClose"
  >
    <template #trigger>
      <MenuButton
        :disabled="isLoadingCreateSignatureBlock"
        :class="signatureBlocksToShow?.length ? '' : 'mx-auto'"
        class="text-indigo-500 hover:text-indigo-600 group flex items-center gap-1.5"
        @blur="handleBlur"
      >
        <template
          v-if="!isLoadingCreateSignatureBlock"
        >
          <div class="btn-plain btn-sm text-indigo-500 hover:text-indigo-600 hover:bg-indigo-100 flex items-center gap-1.5">
            <PlusIcon
              v-if="!availableSignatureBlocks?.length"
              class="w-3.5 h-3.5 shrink-0"
              aria-hidden="true"
            />

            {{ availableSignatureBlocks?.length ? $t('documentContent.insertSignatureBlock') : $t('documentContent.generateSignatureBlock') }}

            <ChevronDownIcon
              v-if="availableSignatureBlocks?.length"
              class="w-3.5 h-3.5 shrink-0"
              aria-hidden="true"
            />
          </div>
        </template>

        <template
          v-else
        >
          <SpinLoader
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span>
            {{ $t('common.pleaseWait') }}…
          </span>
        </template>
      </MenuButton>
    </template>

    <template #content>
      <div class="menu-items-holder">
        <template v-if="availableSignatureBlocks?.length">
          <div
            v-if="!!mau"
            class="flex items-center justify-between px-4 text-xs text-gray-400"
          >
            {{ $t('documentContent.signatureBlocks') }}
            <button
              class="text-xs text-indigo-400 btn-plain btn-sm hover:text-indigo-300 focus:ring-0 focus:ring-offset-0"
              @click="emit('manage-signature-blocks')"
            >
              {{ $t('common.manage') }}
            </button>
          </div>

          <DropdownLink
            v-for="signatureBlock in availableSignatureBlocks"
            :key="signatureBlock.uuid"
            as="button"
            @click="insertSignatureBlock(signatureBlock.ref_uuid)"
          >
            <div
              class="flex items-center gap-2"
            >
              <PartyEntityTypeDisplayIcon
                :party="signatureBlock.party"
                class="w-4 h-4 shrink-0 text-slate-500"
              />

              <div class="flex flex-col">
                <span class="text-sm truncate">
                  {{ signatureBlock.party?.entity_name || signatureBlock.party?.name }}
                </span>
                <span
                  v-if="getSignatories(signatureBlock.party?.uuid)?.length"
                  class="text-xs text-indigo-400"
                >
                  {{ $t('documents.signatories', getSignatories(signatureBlock.party?.uuid)?.length) }}
                </span>
                <span
                  v-else
                  class="text-xs text-gray-400"
                >
                  {{ $t('documents.noSignatories') }}
                </span>
              </div>
            </div>
          </DropdownLink>
          <hr
            v-if="!!mau"
            class="my-2 border-gray-700"
          >
        </template>

        <template v-if="!!mau">
          <div class="block px-4 py-2 text-xs text-gray-400">
            {{ $t('documentContent.generateSignatureBlock') }}
          </div>

          <template v-if="parties?.length">
            <template
              v-for="party in parties"
              :key="party.uuid"
            >
              <DropdownLink
                v-for="style in SignatureBlockStyle"
                :key="style"
                as="button"
                @click="
                  !isLoadingCreateSignatureBlock ? createSignatureBlock(party.uuid, style) : null
                "
              >
                <div>
                  <div
                    class="flex items-center gap-2"
                    :class="isLoadingCreateSignatureBlock ? 'opacity-50' : ''"
                  >
                    <DefaultSignatureBlockIcon
                      v-if="style === SignatureBlockStyle.regular"
                      class="w-4 h-4 mr-0.5 shrink-0 text-slate-100"
                    />
                    <MinimalSignatureBlockIcon
                      v-if="style === SignatureBlockStyle.minimal"
                      class="w-4 h-4 mr-0.5 shrink-0 text-slate-100"
                    />

                    <div class="flex flex-col grow">
                      <span class="text-sm truncate">
                        {{ party.entity_name || party.name }}
                      </span>
                      <span
                        v-if="getSignatories(party.uuid)?.length"
                        class="text-xs text-indigo-400"
                      >
                        {{ $t('documents.signatories', getSignatories(party.uuid)?.length) }}
                      </span>
                      <span
                        v-else
                        class="text-xs text-gray-400"
                      >
                        {{ $t('documents.noSignatories') }}
                      </span>
                    </div>
                    <PartyEntityTypeDisplayIcon
                      v-if="!availableSignatureBlocks?.length"
                      :party="party"
                      class="w-4 h-4 shrink-0 text-slate-500"
                    />
                    <PlusIcon
                      v-else
                      class="w-4 h-4 shrink-0 text-slate-500"
                    />
                  </div>
                </div>
              </DropdownLink>
            </template>
          </template>
          <template v-else>
            <div class="px-4 pb-2 text-sm text-gray-300">
              {{ $t('documentContent.noPartiesFound') }}
            </div>
            <hr class="border-gray-700">
            <DropdownLink
              as="button"
              :icon="true"
              classes="px-4 py-2 text-sm text-gray-400"
              @click="setTabKeyToParties"
            >
              <IdentificationIcon
                class="w-4 h-4 mr-2 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentContent.manageParties') }}
            </DropdownLink>
          </template>
        </template>
      </div>
    </template>
  </Dropdown>
</template>
