<script setup lang="ts">
// external
import { computed } from "vue"
import { storeToRefs } from "pinia"

// internal
import { DashboardWidgetTile } from "~/components"
import { useDashboardStore } from "~/stores"

const dashboardStore = useDashboardStore()
const { dashboards, activeDashboardUuid, allWidgets, widgetsToAdd } = storeToRefs(dashboardStore)

const activeDashboard = computed(() => dashboards.value.find((dashboard) => dashboard.uuid === activeDashboardUuid.value))
const allowedWidgets = computed(() => !!allWidgets.value?.length && !!activeDashboard.value ? allWidgets.value.filter((widget) => widget.scopes.includes(activeDashboard.value.scope)) : [])
const layout = computed(() => activeDashboard.value?.layout || [])

const alreadyAddedWidgetKey = computed(() => {
  return layout.value.map((entry) => entry.i).filter((key) => key !== "drop")
})

const remainingWidgetKey = computed(() => {
  return allowedWidgets.value.filter((widget) => widget.key !== "drop" && !alreadyAddedWidgetKey.value.includes(widget.key)).map((widget) => widget.key)
})

const addWidget = (widgetKey) => {
  widgetsToAdd.value.push(widgetKey)
}

const emits = defineEmits([ "drag", "drag-end" ])

const drag = (widgetKey) => {
  emits("drag", widgetKey)
}

const dragEnd = (widgetKey) => {
  emits("drag-end", widgetKey)
}

</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 border-b shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('dashboard.tabs.widgets') }}
      </h3>
    </div>

    <div class="space-y-6 py-4">
      <div v-if="remainingWidgetKey.length">
        <div class="grid grid-cols-1 gap-2 px-6 pt-2">
          <DashboardWidgetTile
            v-for="widgetKey in remainingWidgetKey"
            :key="widgetKey"
            :widget-key="widgetKey"
            draggable="true"
            unselectable="on"
            @click="addWidget"
            @drag="drag(widgetKey)"
            @dragend="dragEnd(widgetKey)"
          />
        </div>
      </div>
      <div v-if="alreadyAddedWidgetKey.length">
        <h3 class="relative px-6 py-2 text-sm font-medium text-center text-gray-500">
          <div class="absolute left-0 right-0 h-[1px] top-1/2 bg-gray-200" />
          <span class="relative inline-flex items-center px-2 py-1 space-x-1 bg-white">
            <span>{{ $t('dashboard.alreadyAddedWidgets') }}</span>
          </span>
        </h3>
        <div class="grid grid-cols-1 gap-2 px-6">
          <DashboardWidgetTile
            v-for="widgetKey in alreadyAddedWidgetKey"
            :key="widgetKey"
            :widget-key="widgetKey"
            :disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
