<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import { router } from "@inertiajs/vue3"

// internal
import { ApplicationLogoColor, LocaleSelector } from "~/components"
import { AccountUser } from "~/types"
import { useOnboardingStore } from "~/stores"
import { setDayjsLocale } from "~/utils/dayjs"
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/vue/20/solid"

interface Props {
  mau: AccountUser
}

const props = withDefaults(defineProps<Props>(), {})

const { locale } = useI18n()

const isUpdatingLocale = ref(false)
const selectedLocale = computed({
  get: () => locale.value,
  set: (newLocale) => {
    isUpdatingLocale.value = true
    router.patch(route("update-onboarding-locale", props.mau.user.uuid), {
      locale: newLocale,
    }, {
      onSuccess: () => {
        if (locale.value !== newLocale) {
          locale.value = newLocale
          setDayjsLocale(newLocale)
        }
      },
      onFinish: () => {
        isUpdatingLocale.value = false
      },
    })
  },
})

const onboardingStore = useOnboardingStore()
const {
  currentStepIndex,
  steps,
} = storeToRefs(onboardingStore)

defineEmits([ "logout" ])

</script>
<template>
  <header class="sticky inset-x-0 top-0 z-40 flex justify-between px-3 py-3 bg-white border-b border-gray-200 sm:px-5">
    <ApplicationLogoColor class="w-auto h-6 sm:h-8 shrink-0" />

    <div class="flex items-center xl:hidden">
      <div
        v-for="(step, index) in steps"
        :key="step"
        class="relative flex items-center"
      >
        <div class="relative z-10 flex items-start space-x-4">
          <div
            class="flex items-center justify-center w-5 h-5 transition-all duration-300 border-2 rounded-full shrink-0 ring-2 ring-white"
            :class="[
              index === currentStepIndex ? 'border-indigo-500 text-indigo-500 bg-white' :
              index < currentStepIndex ? 'bg-green-300 border-green-300 text-white' :
              'border-gray-300 text-gray-400 bg-white'
            ]"
          >
            <span class="text-xs font-medium">{{ index < currentStepIndex ? '✓' : index + 1 }}</span>
          </div>
        </div>
        <div
          v-if="index !== steps.length - 1"
          class="h-0.5 relative bg-gray-200 w-4 sm:w-12"
        >
          <div
            class="absolute top-0 bottom-0 left-0 transition-all duration-300 bg-green-300"
            :class="index < currentStepIndex ? 'right-0' : 'right-full'"
          />
        </div>
      </div>
    </div>

    <div class="flex items-center gap-3">
      <LocaleSelector
        v-model="selectedLocale"
        position="right"
        class="-mr-2 sm:mr-0"
      />

      <button
        type="button"
        class="flex items-center gap-1 text-xs font-medium text-gray-600 btn-plain btn-sm hover:bg-gray-200 focus:ring-gray-200 whitespace-nowrap"
        @click="$emit('logout')"
      >
        <ArrowLeftStartOnRectangleIcon class="w-3 h-3" />
        <span>{{ $t('navigation.logout') }}</span>
      </button>
    </div>
  </header>
</template>
