import { mergeAttributes, Node } from "@tiptap/core"

export interface TableCellOptions {
  HTMLAttributes: Record<string, any>,
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableCell: {
      /**
       * Chnage bg color of table cell
       * @param color
       * @returns
      */
      setCellBgColor: (color: string) => ReturnType,
    }
  }
}

export const TableCell = Node.create<TableCellOptions>({
  name: "tableCell",

  addOptions () {
    return {
      HTMLAttributes: {},
    }
  },

  content: "block+",

  addAttributes () {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute("colwidth")
          const value = colwidth
            ? [ parseInt(colwidth, 10) ]
            : null

          return value
        },
      },
      bgColor: {
        default: null,
        parseHTML: (element) => element.style.backgroundColor?.replace(/['"]+/g, ""),
        renderHTML: (attributes) => {
          if (!attributes.bgColor) return {}

          return {
            style: `background-color: ${attributes.bgColor}`,
          }
        },
      },
    }
  },

  tableRole: "cell",

  isolating: true,

  parseHTML () {
    return [
      { tag: "td" },
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return [ "td", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0 ]
  },

  addCommands () {
    return {
      setCellBgColor: (color: string) => ({ chain }) => {
        return chain()
          .setCellAttribute("bgColor", color)
          .run()
      },
    }
  },
})
