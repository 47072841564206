<template>
  <div
    id="firework1"
    class="hidden firework md:inline-block"
  >
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
  </div>
  <div
    id="firework2"
    class="hidden firework md:inline-block"
  >
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
  </div>
  <div
    id="firework3"
    class="hidden firework md:inline-block"
  >
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
    <div class="explosion" />
  </div>
</template>

<style scoped>
.firework {
  position: absolute;
}
.explosion {
  position: absolute;
  left: -2px;
  bottom: 0;
  width: 4px;
  height: 80px;
  transform-origin: 50% 100%;
  /* background-color: rgba(0,0,0,.2); */
  overflow: hidden;
}
.explosion:nth-child(1) {
  transform: rotate(0deg) translateY(-15px);
}
.explosion:nth-child(2) {
  transform: rotate(30deg) translateY(-15px);
}
.explosion:nth-child(3) {
  transform: rotate(60deg) translateY(-15px);
}
.explosion:nth-child(4) {
  transform: rotate(90deg) translateY(-15px);
}
.explosion:nth-child(5) {
  transform: rotate(120deg) translateY(-15px);
}
.explosion:nth-child(6) {
  transform: rotate(150deg) translateY(-15px);
}
.explosion:nth-child(7) {
  transform: rotate(180deg) translateY(-15px);
}
.explosion:nth-child(8) {
  transform: rotate(210deg) translateY(-15px);
}
.explosion:nth-child(9) {
  transform: rotate(240deg) translateY(-15px);
}
.explosion:nth-child(10) {
  transform: rotate(270deg) translateY(-15px);
}
.explosion:nth-child(11) {
  transform: rotate(300deg) translateY(-15px);
}
.explosion:nth-child(12) {
  transform: rotate(330deg) translateY(-15px);
}

.explosion::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 40px;
  background-color: rgba(20, 184, 166, 0.25);
}
@keyframes explosion {
  0% {
    top: 100%;
  }
  33%,
  100% {
    top: -50%;
  }
}

#firework1 {
  left: 20%;
  top: 50%;
  transform: scale(0.7);
}
#firework1 .explosion::before {
  animation: explosion 2s ease-in-out;
  animation-iteration-count: 2;
}

#firework2 {
  left: 30%;
  top: 45%;
  transform: scale(0.5);
}
#firework2 .explosion::before {
  animation: explosion 2s 0.6s ease-in-out;
  animation-iteration-count: 2;
}

#firework3 {
  left: 80%;
  top: 65%;
  transform: scale(0.6);
}
#firework3 .explosion::before {
  animation: explosion 2s 0.8s ease-in-out;
  animation-iteration-count: 2;
}
</style>
