<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue"

import "overlayscrollbars/overlayscrollbars.css"
import { OverlayScrollbars, ScrollbarsVisibilityBehavior, ScrollbarsAutoHideBehavior } from "overlayscrollbars"

interface Props {
  tag?: keyof HTMLElementTagNameMap
  contentOverflowX?: boolean
  theme?: "light" | "dark"
  visibility?: ScrollbarsVisibilityBehavior
  autoHide?: ScrollbarsAutoHideBehavior
}

const props = withDefaults(
  defineProps<Props>(),
  {
    tag: "div",
    contentOverflowX: false,
    theme: "dark",
    visibility: "auto",
    autoHide: "scroll",
  },
)

const element = ref<HTMLElement | null>(null)

const instance = ref<OverlayScrollbars | null>(null)

onMounted(
  () => {
    if (!element.value) return
    instance.value = OverlayScrollbars(
      element.value,
      {
        scrollbars: {
          autoHide: props.autoHide,
          autoHideDelay: 200,
          theme: `os-theme-${props.theme}`,
          visibility: props.visibility,
        },
        overflow: {
          x: props.contentOverflowX ? "scroll" : "visible-hidden",
          y: "scroll",
        },
      },
    )
  },
)

onUnmounted(() => instance?.value?.destroy())

defineExpose(
  {
    instance,
    element,
  },
)
</script>

<template>
  <component
    :is="props.tag"
    ref="element"
    data-overlayscrollbars-initialize
  >
    <slot />
  </component>
</template>

<style>
.os-viewport {
  overscroll-behavior-block: contain;
}
</style>
