<script setup lang="ts">
// external
import { computed } from "vue"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid"

// internal
import { hexToGrayscaleValue, isValidHexColor } from "~/utils"
import { ColorInput } from "~/components"

interface Props {
  foreground: string,
  foregroundLabel?: string,
  background: string,
  backgroundLabel?: string,
  contrastThreshold?: number
  warnForContrastToWhite?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  foregroundLabel: "Text",
  backgroundLabel: "Background",
  contrastThreshold: 60,
  warnForContrastToWhite: false,
})

const emit = defineEmits([ "update:foreground", "update:background" ])

const fg = computed({
  get: () => props.foreground,
  set: (color) => emit("update:foreground", color),
})

const bg = computed({
  get: () => props.background,
  set: (color) => emit("update:background", color),
})

const hasFgError = computed(() => !isValidHexColor(fg.value))
const hasBgError = computed(() => !isValidHexColor(bg.value))

const contrast = computed(() => Math.abs(hexToGrayscaleValue(props.background) - hexToGrayscaleValue(props.foreground)))
const contrastToWhite = computed(() => Math.abs(hexToGrayscaleValue("#ffffff") - hexToGrayscaleValue(props.background)))

</script>
<template>
  <div class="items-center gap-5 sm:grid sm:grid-cols-3">
    <div class="col-span-2 mb-5 space-y-3 sm:mb-0">
      <div>
        <div class="mb-2 text-sm font-medium">
          {{ backgroundLabel }}
        </div>
        <ColorInput v-model:color="bg" />
      </div>
      <div>
        <div class="mb-2 text-sm font-medium">
          {{ foregroundLabel }}
        </div>
        <ColorInput v-model:color="fg" />
      </div>
    </div>

    <div
      v-if="!hasBgError && !hasFgError"
      class="flex flex-col h-full col-span-2 md:col-span-1"
    >
      <div
        class="flex items-center mb-2 space-x-1 text-sm font-medium"
        :class="contrast <= contrastThreshold || (warnForContrastToWhite && contrastToWhite <= contrastThreshold) ? 'text-yellow-500' : ''"
      >
        <span>{{ $t('accountSettings.brandIdentity.preview') }}</span>
        <div
          v-if="contrast <= contrastThreshold || (warnForContrastToWhite && contrastToWhite <= contrastThreshold)"
        >
          <div
            data-tippy-help
            :data-tippy-content="(warnForContrastToWhite && contrastToWhite <= contrastThreshold) ? $t('accountSettings.brandIdentity.contrastToWhiteWarning') : $t('accountSettings.brandIdentity.contrastWarning')"
            data-placement="bottom"
          >
            <ExclamationTriangleIcon
              aria-hidden="true"
              class="w-5 h-5 shrink-0"
            />
          </div>
        </div>
      </div>
      <div
        :style="`background-color: ${background}; color: ${foreground}`"
        class="flex items-center justify-center w-full rounded-lg grow"
      >
        <div class="py-3 text-center">
          <div class="text-6xl">
            Aa
          </div>
          <div class="text-xs">
            Lorem ipsum
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
