<script setup lang="ts">
import { ref } from "vue"
import * as CustomIcons from "~/icons/selectable"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { Team } from "~/types"

interface Props {
  icon: Team["icon"]
}

const props = withDefaults(defineProps<Props>(), {})

const isOpen = ref(false)

interface Component {
  name: string; // File name of the component without the extension
  component: any; // Component itself
}

const components: Component[] = Object.entries(CustomIcons).map(([ key, value ]) => {
  const name = key.slice(0) // Remove the file extension (.vue) from the key
  return { name, component: value }
})

const emit = defineEmits([ "update:icon" ])

const select = (selectedIcon: Component) => {

  isOpen.value = false

  emit("update:icon", selectedIcon.name)
}

</script>

<template>
  <div class="relative flex items-center justify-center">
    <Listbox
      as="div"
    >
      <div class="relative">
        <ListboxButton class="relative flex items-center w-full p-1 text-left bg-gray-100 border-0 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm disabled:bg-gray-50 disabled:cursor-not-allowed">
          <component
            :is="CustomIcons[props.icon] ?? 'UserGroup'"
            class="w-7 h-7"
            aria-hidden="true"
          />
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="absolute right-0 z-10 grid w-56 grid-cols-5 py-1 mt-1 overflow-auto text-white bg-gray-900 rounded-md shadow-lg max-h-60 bg-opacity-95 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <ListboxOption
              v-for="(customIcon, iconIdx) in components"
              :key="'icon' + iconIdx"
              as="button"
              type="button"
              class="p-3"
              @click="select(customIcon)"
            >
              <component
                :is="customIcon.component"
                class="shrink-0 w-5 h-5"
                aria-hidden="true"
              />
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>
