<script setup lang="ts">
// external
import { ForwardIcon, EnvelopeIcon, ArrowRightCircleIcon, UsersIcon, FlagIcon, HandThumbUpIcon, ClipboardDocumentIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/outline"
import {
  ArrowDownIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  CheckIcon,
  EllipsisHorizontalIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/vue/24/solid"
import axios from "axios"
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"

// internal
import { DocumentStagePopover, SpinLoader, StartSigningPhaseButton } from "~/components"
import { SignatureIcon, WorkingCopyIcon } from "~/icons"
import {
  useNotificationStore,
  useSignatureStore,
  useDocumentStore,
  useUserStore,
  usePartyStore,
  useCommentStore,
  useCheckpointStore,
  usePdfBrickStore,
  useConfirmationStore,
} from "~/stores"

import {
  CheckpointPass,
  CheckpointPassStep,
  CheckpointPassStepApproval,
  Comment,
  DynamicField,
  Party,
  DocumentStage,
  DocumentUserRoleEnum,
  DocumentTab,
  DocumentContentType,
  CrudContext,
  DocumentOrigin,
  DocumentUser,
  SignatureBlock,
} from "~/types"
import { getUserRepresentation } from "~/utils"
import { nextTick } from "vue"

interface Props {
  isLoadingRefreshSigningPhase?: boolean
  stage: DocumentStage
  pendingDynamicFields: DynamicField[]
  isLoadingDynamicFields: boolean
  isLoadingStartSigningPhase?: boolean
  isConfirmedParties?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isLoadingRefreshSigningPhase: false,
  pendingDynamicFields: null,
  isLoadingDynamicFields: false,
  isLoadingStartSigningPhase: false,
  isConfirmedParties: false,
})

const emit = defineEmits([
  "show-start-signing-phase-modal",
  "start-signing-phase",
  "start-review",
  "jump-to-party",
  "jump-to-dynamic-field",
  "jump-to-comment",
  "jump-to-signature-block",
  "hide-stage-tippy",
  "show-sharing-modal",
  "show-parties-confirm-modal",
  "set-has-external-document-users-invited",
])

const { t } = useI18n()

const { notify } = useNotificationStore()

const documentStore = useDocumentStore()
const {
  currentDocument,
  mdu,
  mau,
  isLoadingLockedState,
  isChangingStage,
  keysOfIsUpdatingDocumentField,
  isLoadingDocumentVariables,
  documentEditorSession,
  activeWorkingCopy,
  unplacedSignatureBlock,
  hasChangedStage,
  hasChangedCheckpoint,
  isLoadingRefreshDocumentWorkflowState,
  documentSigningCriteriaMet,
} = storeToRefs(documentStore)
const {
  setActiveTabKey,
  checkForMissingData,
  showMissingDataAlert,
  showUnplacedSignatureBlockAlert,
  toggleEditMode,
  handleSaveDocumentEditorContent,
  setHasChangedCheckpoint,
  refreshDocumentWorkflowState,
  showDocumentWorkflowStateAlert,
} = documentStore

const userStore = useUserStore()
const {
  users,
  isLoadingUsers,
} = storeToRefs(userStore)

const partyStore = usePartyStore()
const {
  parties,
  isLoadingParties,
  uuidsOfUpdatingParty,
  pendingParties,
} = storeToRefs(partyStore)

const commentStore = useCommentStore()
const {
  comments,
  isLoadingComments,
} = storeToRefs(commentStore)

const checkpointStore = useCheckpointStore()
const {
  checkpointPasses,
  isLoadingCheckpointPasses,
} = storeToRefs(checkpointStore)

const confirmationStore = useConfirmationStore()
const { setShowConfirmModal, setConfirmOptions } = confirmationStore

const signatureStore = useSignatureStore()
const {
  activeSigningPhase,
  isLoadingSignatures,
  isLoadingSignatureBlocks,
  signatureBlocks,
  signatories,
  signaturesRequiredByMdu,
} = storeToRefs(signatureStore)
const { checkAllSignaturesComplete, jumpToSignatureBlock } = signatureStore

const pdfBrickStore = usePdfBrickStore()
const { isLoadingPdfBricks } = storeToRefs(pdfBrickStore)

const isLoadingStartCheckpointPass = ref<boolean>(false)
const isReplyToCheckpointPassStepLoading = ref<boolean | "approved" | "rejected">(false)

// Filter checkpoints relevant for the status bar
const readyCheckpoints = computed<CheckpointPass[]>(() => {
  if (props.stage === DocumentStage.review || props.stage === DocumentStage.draft) {
    return checkpointPasses.value.filter((el) => el.status === "ready")
  } else {
    return null
  }
})

const activeCheckpoint = computed<CheckpointPass>(() => {
  return checkpointPasses.value.find((el) => el.status === "active")
})

const activeCheckpointPassStep = computed<CheckpointPassStep>(() => {
  if (activeCheckpoint.value?.checkpoint_pass_steps?.length) {
    return activeCheckpoint.value.checkpoint_pass_steps.find(
      (el) => el.status === "active",
    )
  } else {
    return null
  }
})

// Check if a checkpoint approval is required by the document user
const checkPointPassStepApprovalRequiredByMdu = computed<CheckpointPassStepApproval>(() => {
  if (activeCheckpointPassStep.value?.checkpoint_pass_step_approvals?.length) {
    const requiredCheck: CheckpointPassStepApproval = activeCheckpointPassStep.value?.checkpoint_pass_step_approvals?.find(
      (el) => el.account_user?.uuid === mau.value?.uuid && el.status !== "approved",
    )
    if (requiredCheck) {
      return requiredCheck
    }
  }
  return null
})

// Check if there are signature blocks without a signatory
const emptySignatureBlocks  = computed<Party["uuid"][]>(() => {
  return Array.from(
    new Set(
      signatureBlocks.value
        .filter((signatureBlock) =>
        {
          const check = !signatureBlock.deleted_at &&
          !users.value?.some(
            (user) =>
              user.roles?.includes(DocumentUserRoleEnum.signatory) &&
              user.party_uuid === signatureBlock.party_uuid,
          )
          const isMyParty = signatureBlock.party_uuid === mdu.value?.party_uuid
          if ((!!mau.value || isMyParty) && check) return true
          return false
        },
        )
        .map((signatureBlock) => signatureBlock.party_uuid),
    ),
  )
})

const getPartyByUuid = (uuid: Party["uuid"]) => parties.value?.find((el) => el.uuid === uuid)

const handleClickStartSigningPhaseButton = async () => {
  if (props.isLoadingStartSigningPhase) return

  await refreshDocumentWorkflowState()

  if (!mau.value) {
    if (!documentSigningCriteriaMet.value) {
      showDocumentWorkflowStateAlert()
      return
    }

    emit("start-signing-phase")
    return
  }

  const missingDataFound = await checkForMissingData()

  if (missingDataFound) {
    showMissingDataAlert()
    return
  }
  if (unplacedSignatureBlock.value) {
    showUnplacedSignatureBlockAlert()
    return
  }
  if (!documentSigningCriteriaMet.value) {
    showDocumentWorkflowStateAlert()
    return
  }

  emit("show-start-signing-phase-modal")

}

// Function to start a checkpoint
const startCheckpointPass = async (checkpointPassUuid: CheckpointPass["uuid"]) => {
  if (isLoadingStartCheckpointPass.value) return

  isLoadingStartCheckpointPass.value = true

  const isSigningCheckpoint = checkpointPasses.value.find((el) => el.uuid === checkpointPassUuid && el.checkpoint_document_stage === DocumentStage.signing)

  const missingDataFound = await checkForMissingData()

  if (isSigningCheckpoint && missingDataFound) {
    isLoadingStartCheckpointPass.value = false
    showMissingDataAlert()
    return
  }
  const apiUrl = route("api.documents.checkpoint-passes.update", {
    document: currentDocument.value?.uuid,
    checkpoint_pass: checkpointPassUuid,
  })
  try {
    setHasChangedCheckpoint(true)
    const startCheckpointPassRes = await axios.put<Promise<CheckpointPass>>(apiUrl, { status: "active" })
    setActiveTabKey(DocumentTab.checkpoints)
    return startCheckpointPassRes
  } catch (err) {
    notify({
      title: t("documentStatusBanner.errors.startCheckpoint"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isLoadingStartCheckpointPass.value = false
  }
}

// Function to approve or reject a checkpoint pass step
const replyToCheckpointPassStep = async (checkpointPassStepApprovalUuid: CheckpointPassStepApproval["uuid"], status: "approved" | "rejected") => {
  isReplyToCheckpointPassStepLoading.value = status
  const apiUrl = route("api.documents.checkpoint-pass-step-approvals.update", {
    document: currentDocument.value?.uuid,
    checkpoint_pass_step_approval: checkpointPassStepApprovalUuid,
  })
  try {
    setHasChangedCheckpoint(true)
    const startCheckpointPassRes = await axios.put<Promise<CheckpointPassStep>>(apiUrl, { status: status })
    return startCheckpointPassRes
  } catch (err) {
    notify({
      title: t("documentStatusBanner.errors.approveCheckpoint"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
  } finally {
    isReplyToCheckpointPassStepLoading.value = false
  }
}

// Filter checkpoints relevant for the status bar
const unresolvedProposals = computed<Comment[]>(() => {
  return comments.value.filter((el) => el.is_resolved !== true && !el.comment_uuid && el.prosemirror_data)
})

// Helper to switch edit mode on and show signature blocks
const handlePlaceSignatureBlocks = async () => {
  if (currentDocument.value?.content_type === DocumentContentType.prosemirror_data) {
    await toggleEditMode(true, handleSaveDocumentEditorContent)
  }
  setActiveTabKey(DocumentTab.documentContent)
  await nextTick()
  setTimeout(() => jumpToSignatureBlock(unplacedSignatureBlock.value?.ref_uuid, mdu.value?.uuid, false, false), 100)
}

// Helper for party jumps
const handleJumpToParty = (partyRefUuid: Party["ref_uuid"]) => {
  emit("jump-to-party", { partyRefUuid: partyRefUuid })
}

// Helper for DynamicField jumps
const handleJumpToDynamicField = (dynamicFieldRefUuid: DynamicField["ref_uuid"]) => {
  emit("jump-to-dynamic-field", { dynamicFieldRefUuid: dynamicFieldRefUuid })
}

// Helper for comment jumps
const handleJumpToComment = (commentUuid: Comment["uuid"], prosemirrorDataUuid: string) => {
  emit("jump-to-comment", { commentUuid, prosemirrorDataUuid })
}

// Helper for signature block jumps
const handleJumpToSignatureBlock = (
  refUuid: SignatureBlock["ref_uuid"],
  documentUserUuid: DocumentUser["uuid"],
  emptyState: boolean,
) => {
  emit("jump-to-signature-block", {
    refUuid,
    documentUserUuid,
    emptyState,
  })
}

const handleExternalReadyForSigning = (partyUuid: Party["uuid"]) => {
  partyStore.updateLocalParty({
    uuid: partyUuid,
    is_ready_for_signing: true,
  },
  CrudContext.document,
  currentDocument.value?.uuid)
}

const isLoadingPartiesConfirmed = computed<boolean>(() => keysOfIsUpdatingDocumentField.value?.includes("has_parties_confirmed"))
const isLoadingExternalUsersInvited = computed<boolean>(() => keysOfIsUpdatingDocumentField.value?.includes("has_external_document_users_invited"))

const showWrongSignatoryOptions = () => {
  // Show confirmation modal with options to change signatory
  setShowConfirmModal(true)
  setConfirmOptions({
    title: t("documentStatusBanner.wrongSignatory.title"),
    description: t("documentStatusBanner.wrongSignatory.explanation"),
    buttonText: t("documentStatusBanner.wrongSignatory.jumpToComments"),
    icon: QuestionMarkCircleIcon,
    callback: () => {
      // Open discussions tab
      setActiveTabKey(DocumentTab.discussions)
      // Close modal
      setShowConfirmModal(false)
    },
  })
}

</script>

<template>
  <div
    v-if="!currentDocument.archived_at"
    class="sticky top-0 shadow-md z-20 @container"
    :class="stage === DocumentStage.signed || (!mau && !!mdu && (stage === DocumentStage.review || stage === DocumentStage.approved_review) && !emptySignatureBlocks.length && !pendingDynamicFields.length) ? 'bg-green-50' : 'bg-gray-100'"
  >
    <div
      class="px-6 mx-auto text-sm flex flex-wrap items-center py-2 min-h-[48px]"
      :class="mau || stage === DocumentStage.signing || stage === DocumentStage.signed ? 'justify-center sm:space-x-2 sm:justify-between' : 'justify-center'"
    >
      <div
        v-if="stage !== DocumentStage.signing || !!mau"
      >
        <slot name="editStatus" />
      </div>
      <div v-if="documentEditorSession?.uuid">
        <slot name="saveStatus" />
      </div>
      <div
        v-else-if="
          isLoadingCheckpointPasses ||
            isLoadingUsers ||
            isLoadingSignatures ||
            isLoadingSignatureBlocks ||
            isLoadingComments ||
            isLoadingDynamicFields ||
            isLoadingParties ||
            isLoadingLockedState ||
            isChangingStage ||
            isLoadingRefreshSigningPhase ||
            isLoadingPdfBricks ||
            hasChangedStage ||
            hasChangedCheckpoint
        "
        class="flex items-center w-auto gap-3 mt-0 text-gray-900 shrink-0"
      >
        <SpinLoader class="w-4 h-4 text-gray-700" />
        {{ $t('common.pleaseWait') }}…
      </div>

      <!-- ACTIONS -->
      <template v-else-if="stage !== DocumentStage.signed">
        <!-- UNCONFIRMED PARTIES -->
        <div
          v-if="!!mau && !!mdu && !isConfirmedParties"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span
            v-if="!isConfirmedParties"
            class="hidden md:inline-block"
          >{{ $t('documentStatusBanner.confirmPartiesAndSignatories') }}:</span>
          <button
            type="button"
            class="flex items-center justify-center gap-2 btn-secondary btn-sm"
            data-cy-sel="review-parties-button"
            @click.prevent="
              $emit('show-parties-confirm-modal')
            "
          >
            <template v-if="!isConfirmedParties">
              <SpinLoader
                v-if="isLoadingPartiesConfirmed"
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              <UsersIcon
                v-else
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              <span>{{ $t('documentStatusBanner.managePartiesAndSignatories') }}</span>
            </template>
          </button>
        </div>

        <!-- ACTIVE CHECKPOINT REQUIRING ACTION -->
        <div
          v-else-if="!!mau && activeCheckpoint && checkPointPassStepApprovalRequiredByMdu"
          class="items-center justify-center w-full gap-3 space-y-2 text-center text-gray-900 shrink-0 md:flex md:space-y-0 sm:mt-0 sm:w-auto"
        >
          <span>{{ $t('documentStatusBanner.approvalRequired') }}:</span>
          <div class="flex items-center justify-center space-x-2">
            <button
              :data-cy-sel="`approve-checkpoint-button`"
              type="button"
              :disabled="!!isReplyToCheckpointPassStepLoading"
              class="relative flex items-center justify-center px-2 py-1 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
              @click.prevent="
                replyToCheckpointPassStep(
                  checkPointPassStepApprovalRequiredByMdu?.uuid,
                  'approved'
                )
              "
            >
              <template v-if="isReplyToCheckpointPassStepLoading === 'approved'">
                <SpinLoader
                  class="w-4 h-4 mr-2"
                />
                {{ $t('common.pleaseWait') }}…
              </template>
              <template v-else>
                <CheckIcon
                  class="w-4 h-4 mr-2"
                  aria-hidden="true"
                />
                {{ $t('common.approve') }}
              </template>
            </button>
            <button
              :data-cy-sel="`reject-checkpoint-button`"
              type="button"
              class="relative flex items-center justify-center px-2 py-1 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
              @click.prevent="
                replyToCheckpointPassStep(
                  checkPointPassStepApprovalRequiredByMdu?.uuid,
                  'rejected'
                )
              "
            >
              <template v-if="isReplyToCheckpointPassStepLoading === 'rejected'">
                <SpinLoader
                  class="w-4 h-4 mr-1.5"
                />
                {{ $t('common.pleaseWait') }}…
              </template>
              <template v-else>
                <XMarkIcon
                  class="w-4 h-4 mr-1.5"
                  aria-hidden="true"
                />
                {{ $t('common.reject') }}
              </template>
            </button>
          </div>
        </div>

        <!-- ACTIVE CHECKPOINT WAITING FOR OTHERS -->
        <div
          v-else-if="!!mdu && !!mau && activeCheckpoint"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span>{{ $t('documentStatusBanner.pendingApproval') }}:</span>
          <div class="flex -space-x-1 shrink-0">
            <div
              v-for="(user, userIdx) in activeCheckpointPassStep?.checkpoint_pass_step_approvals"
              :key="userIdx"
              class="relative"
            >
              <img
                class="rounded-full max-w-none h-7 w-7 ring-2 ring-gray-100"
                :src="user.account_user?.profile_photo_url"
                :alt="getUserRepresentation(user.account_user)"
                data-tippy-context
                :data-tippy-content="getUserRepresentation(user.account_user)"
                data-placement="bottom"
              >
              <span
                v-if="user.status === 'approved'"
                class="flex items-center absolute -bottom-1 left-0.5 rounded-full bg-green-500 p-0.5 ring-2 ring-gray-100"
              >
                <CheckIcon class="w-2 h-2 text-white" />
              </span>
              <span
                v-else-if="user.status === 'rejected'"
                class="flex items-center absolute -bottom-1 left-0.5 rounded-full bg-red-500 p-0.5 ring-2 ring-gray-100"
              >
                <XMarkIcon class="w-2 h-2 text-white" />
              </span>
            </div>
          </div>
        </div>

        <!-- REQUIRED INTERNAL DYNAMIC FIELDS -->
        <div
          v-else-if="!!mdu && pendingDynamicFields.length"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span
            v-if="pendingDynamicFields[0]?.scope === 'internal'"
            class="hidden md:inline-block"
          >{{ $t('documentStatusBanner.fillOutDynamicFields') }}:</span>
          <span
            v-else-if="pendingDynamicFields[0]?.scope === 'internal_and_external' && !!mau"
            class="hidden md:inline-block"
          >{{ $t('documentStatusBanner.fillOutExternalDynamicFields') }}:</span>
          <span
            v-else-if="pendingDynamicFields[0]?.scope === 'internal_and_external'"
            class="hidden md:inline-block"
          >{{ $t('documentStatusBanner.fillOutMissingInformation') }}:</span>
          <button
            :data-cy-sel="`show-next-dynamic-field-button`"
            type="button"
            class="flex items-center justify-center gap-1 btn-secondary btn-sm"
            @click.prevent="handleJumpToDynamicField(pendingDynamicFields[0]?.ref_uuid)"
          >
            <ArrowDownIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span class="inline-block md:hidden">{{ $t('documentStatusBanner.showNextDynamicField', {count: pendingDynamicFields.length}) }}</span>
            <span class="hidden md:inline-block">{{ $t('documentStatusBanner.showNext', {count: pendingDynamicFields.length}) }}</span>
          </button>
        </div>

        <!-- REQUIRED PARTY DETAILS -->
        <div
          v-else-if="!!mdu && pendingParties.length"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.provideMissingDetails') }}:</span>
          <button
            type="button"
            class="flex items-center justify-center gap-1 btn-secondary btn-sm"
            @click.prevent="handleJumpToParty(pendingParties[0].ref_uuid)"
          >
            <ArrowDownIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('documentStatusBanner.showParty', {count: pendingParties.length}) }}
          </button>
        </div>

        <!-- UNPLACED SIGNATURE BLOCKS -->
        <div
          v-else-if="!!mdu && !!mau && (stage === DocumentStage.draft || stage === DocumentStage.review) && unplacedSignatureBlock"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.unplacedSignatureBlocks') }}:</span>
          <button
            type="button"
            class="relative flex items-center justify-center gap-1 overflow-hidden btn-secondary btn-sm"
            data-cy-sel="place-signature-blocks-button"
            @click.prevent="handlePlaceSignatureBlocks"
          >
            <div
              class="z-0 w-full shimmer -left-full intense"
            />
            <span class="z-10 flex items-center gap-1">
              <ClipboardDocumentIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.placeNow') }}
            </span>
          </button>
        </div>

        <!-- READY TO BE SHARED -->
        <div
          v-else-if="!!mau && !!mdu && (stage === DocumentStage.review) && !currentDocument.has_external_document_users_invited"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.readyToShare') }}:</span>
          <button
            v-cy="`share-document-with-external-users-button`"
            type="button"
            class="flex items-center justify-center gap-1.5 btn-secondary btn-sm"
            @click.prevent="$emit('show-sharing-modal')"
          >
            <!-- TODO: Add loading state -->
            <EnvelopeIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('documentStatusBanner.inviteExternals') }}
          </button>
          <button
            v-cy="`skip-review-button`"
            type="button"
            class="relative flex items-center justify-center gap-1.5 btn-plain btn-sm text-gray-800 hover:text-gray-900 hover:bg-yellow-100 focus:ring-yellow-200"
            @click.prevent="$emit('set-has-external-document-users-invited')"
          >
            <template v-if="isLoadingExternalUsersInvited">
              <SpinLoader
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.pleaseWait') }}…
            </template>
            <template v-else>
              <ForwardIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.skip') }}
            </template>
          </button>
        </div>

        <!-- EMPTY SIGNATURE BLOCKS -->
        <div
          v-else-if="!!mdu && emptySignatureBlocks.length && stage === DocumentStage.review"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.assignSignatoryToBlock') }}:</span>
          <button
            :data-cy-sel="`assign-signatory-to-each-signature-block-button`"
            type="button"
            class="flex items-center justify-center gap-1 btn-secondary btn-sm"
            @click.prevent="
              () => {
                partyStore.setTriggerPartyPopoverTab('signatories')
                handleJumpToSignatureBlock(signatureBlocks.find((el: SignatureBlock) => el.party_uuid === emptySignatureBlocks[0] && !el.deleted_at)?.ref_uuid, mdu?.uuid, true)
              }
            "
          >
            <ArrowDownIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span class="inline-block md:hidden">{{ $t('documentStatusBanner.showNextSignatureBlock', {count: emptySignatureBlocks.length}) }}</span>
            <span class="hidden md:inline-block">{{ $t('documentStatusBanner.showNext', {count: emptySignatureBlocks.length}) }}</span>
          </button>
        </div>

        <!-- UNRESOLVED PROPOSALS -->
        <div
          v-else-if="mau?.permissions.includes('comment_resolve_proposal') && !!mdu && unresolvedProposals.length && (stage !== DocumentStage.draft && stage !== DocumentStage.approved_draft)"
          class="flex items-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span>{{ $t('documentStatusBanner.unresolvedProposals') }}:</span>

          <button
            type="button"
            class="flex items-center justify-center gap-1 btn-secondary btn-sm"
            @click.prevent="
              handleJumpToComment(unresolvedProposals[0].uuid, unresolvedProposals[0].prosemirror_data_uuid)
            "
          >
            <ArrowDownIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span class="inline-block md:hidden">{{ $t('documentStatusBanner.showNextProposal', {count: unresolvedProposals.length}) }}</span>
            <span class="hidden md:inline-block">{{ $t('documentStatusBanner.showNext', {count: unresolvedProposals.length}) }}</span>
          </button>
          <!--  <button
              v-if="mdu?.permissions.includes('comment_update')"
              type="button"
              class="relative flex items-center justify-center gap-1 px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              @click.prevent="true"
            >
              <CheckIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              Accept all
            </button>
            <button
              v-if="mdu?.permissions.includes('comment_update')"
              type="button"
              class="relative flex items-center justify-center gap-1 px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              @click.prevent="true"
            >
              <XMarkIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              Reject all
            </button>  -->
        </div>

        <!-- READY CHECKPOINT, BUT NO SIGNATORIES -->
        <div
          v-else-if="!!mau && ((readyCheckpoints?.length && readyCheckpoints?.[0]?.checkpoint_document_stage === DocumentStage.signing) || ((stage === DocumentStage.review || stage === DocumentStage.approved_review))) && !signatories?.length"
          class="flex items-center justify-center w-full shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="flex items-center gap-2 text-green-700">
            <CheckCircleIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('documentStatusBanner.noSignatoriesNothingToDo') }}
          </span>
          <button
            v-if="currentDocument?.origin === DocumentOrigin.pdf"
            type="button"
            class="flex items-center flex-shrink-0 gap-1 ml-2 text-green-700 btn-plain btn-sm hover:text-green-800 hover:bg-green-100 focus:ring-green-100"
            @click.prevent="$emit('show-parties-confirm-modal')"
          >
            <PlusIcon class="w-4 h-4" />
            {{ $t('documentStatusBanner.addSignatory') }}
          </button>
        </div>

        <!-- READY CHECKPOINT -->
        <div
          v-else-if="!!mau && readyCheckpoints?.length"
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.documentNeedsApproval') }}{{ `${mdu?.permissions.includes('start_checkpoint') ? ':' : '.'}` }}</span>
          <button
            v-if="mdu?.permissions.includes('start_checkpoint')"
            :data-cy-sel="`start-checkpoint-button`"
            type="button"
            :data-tippy-help="!!(activeWorkingCopy?.uuid) ? true : null"
            :data-tippy-content="!!(activeWorkingCopy?.uuid) ? $t('documentStatusBanner.notAllowedWhileWorkingCopyIsActive') : null"
            data-placement="top"
            :disabled="isLoadingStartCheckpointPass || (readyCheckpoints?.[0]?.checkpoint_document_stage === DocumentStage.signing && !signatories?.length)"
            class="flex items-center justify-center gap-1.5 btn-secondary btn-sm"
            :class="!!(activeWorkingCopy?.uuid) ? 'cursor-not-allowed hover:bg-yellow-200 focus:ring-0 opacity-50' : ''"
            @click.prevent="!!(activeWorkingCopy?.uuid) ? null : startCheckpointPass(readyCheckpoints?.[0]?.uuid)"
          >
            <template v-if="isLoadingStartCheckpointPass">
              <SpinLoader
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.pleaseWait') }}…
            </template>
            <template v-else>
              <FlagIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.startCheckpoint') }}
            </template>
          </button>
        </div>

        <!-- READY TO PROCEED TO REVIEW -->
        <div
          v-else-if="
            !!mau &&
              !!mdu &&
              (stage === DocumentStage.draft || stage === DocumentStage.approved_draft) &&
              !isLoadingPartiesConfirmed
          "
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.readyForReview') }}:</span>
          <button
            :data-cy-sel="`proceed-to-review-button`"
            type="button"
            class="flex items-center justify-center gap-1.5 btn-secondary btn-sm"
            :disabled="!mdu?.permissions?.includes('document_update')"
            :data-tippy-help="!!(activeWorkingCopy?.uuid) ? true : null"
            :data-tippy-content="!mdu?.permissions.includes('document_update') ? $t('documentStatusBanner.noPermissionToChangeStage') : null"
            data-placement="top"
            :class="!mdu?.permissions?.includes('document_update') ? 'cursor-not-allowed hover:bg-yellow-200 focus:ring-0 opacity-50' : ''"
            @click.prevent="$emit('start-review')"
          >
            <SpinLoader
              v-if="isChangingStage"
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <ArrowRightCircleIcon
              v-else
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span v-if="isChangingStage">{{ $t('common.pleaseWait') }}…</span>
            <span v-else>{{ $t('documentStatusBanner.proceedToReview') }}</span>
          </button>
        </div>

        <!-- READY TO PROCEED TO SIGNING (EXTERNAL) -->
        <div
          v-else-if="!mau && !!mdu && (stage === DocumentStage.review || stage === DocumentStage.approved_review)"
          class="flex flex-col items-center justify-center w-full gap-2 text-gray-900 lg:gap-3 lg:flex-row shrink-0 sm:mt-0 sm:w-auto"
        >
          <template v-if="mdu.party_uuid && signatories?.length">
            <span
              v-if="!documentSigningCriteriaMet"
              class="flex items-center gap-2 text-gray-900 bg-yellow-100 border-b border-yellow-400 rounded-md px-1 py-0.5"
            >
              <WorkingCopyIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.waitingForCounterparty') }}
            </span>
            <div
              v-if="!getPartyByUuid(mdu.party_uuid)?.ready_for_signing_at"
              class="flex items-center flex-grow gap-3"
            >
              <span class="hidden xs:inline-block">{{ $t('documentStatusBanner.readyToSignQuestion') }}</span>
              <button
                type="button"
                class="relative flex items-center justify-center px-2 py-1 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                @click.prevent="handleExternalReadyForSigning(mdu.party_uuid)"
              >
                <template v-if="uuidsOfUpdatingParty.includes(mdu.party_uuid)">
                  <SpinLoader
                    class="w-4 h-4 mr-2"
                  />
                  {{ $t('common.pleaseWait') }}…
                </template>
                <template v-else>
                  <HandThumbUpIcon
                    class="w-4 h-4 mr-2"
                    aria-hidden="true"
                  />
                  {{ $t('common.approveForSigning') }}
                </template>
              </button>
            </div>
            <span
              v-else
              class="flex items-center gap-2 text-green-700 flew-grow"
            >
              <CheckCircleIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.yourPartyApproved') }}
            </span>
            <StartSigningPhaseButton
              :is-disabled="isLoadingStartSigningPhase || !!documentEditorSession?.uuid || !mdu?.permissions.includes('signing_phase_create')"
              :is-loading="isLoadingStartSigningPhase || isLoadingDocumentVariables || isLoadingRefreshDocumentWorkflowState"
              :tippy-text="!!(activeWorkingCopy?.uuid) ? $t('documentStatusBanner.notAllowedWhileWorkingCopyIsActive') : !mdu?.permissions.includes('signing_phase_create') ? $t('documentStatusBanner.noPermissionToStartSigning') : null"
              :style="'green'"
              @click="handleClickStartSigningPhaseButton"
            />
          </template>
          <template v-else>
            <span class="flex items-center gap-2 text-green-700">
              <CheckCircleIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ signatories?.length ? $t('documentStatusBanner.nothingToDo') : $t('documentStatusBanner.noSignatoriesNothingToDo') }}
            </span>
          </template>
        </div>

        <!-- READY TO PROCEED TO SIGNING (INTERNAL USER) -->
        <div
          v-else-if="
            !!mdu &&
              !unresolvedProposals.length &&
              !pendingParties.length &&
              (stage === DocumentStage.review || stage === DocumentStage.approved_review)
          "
          class="flex items-center justify-center w-full gap-3 text-gray-900 shrink-0 sm:mt-0 sm:w-auto"
        >
          <span class="hidden md:inline-block">{{ $t('documentStatusBanner.readyForSigning') }}:</span>
          <StartSigningPhaseButton
            :is-disabled="isLoadingStartSigningPhase || !!documentEditorSession?.uuid || !mdu?.permissions.includes('signing_phase_create')"
            :is-loading="isLoadingStartSigningPhase || isLoadingDocumentVariables || isLoadingRefreshDocumentWorkflowState"
            :tippy-text="!!(activeWorkingCopy?.uuid) ? $t('documentStatusBanner.notAllowedWhileWorkingCopyIsActive') : !mdu?.permissions.includes('signing_phase_create') ? $t('documentStatusBanner.noPermissionToStartSigning') : null"
            @click="handleClickStartSigningPhaseButton"
          />
        </div>

        <!-- IN STAGE SIGNING -->
        <template v-else-if="stage === DocumentStage.signing">
          <div
            class="items-center w-auto gap-3 mt-0 shrink-0"
            :class="signaturesRequiredByMdu ? 'hidden md:flex' : 'flex'"
          >
            <span
              data-cy-sel="required-signatures"
            >{{ $t('documentStatusBanner.requiredSignatures') }}:</span>
            <div
              class="flex shrink-0"
              :class="activeSigningPhase?.sequential_signing || currentDocument.sequential_signing ? 'space-x-1' : '-space-x-1'"
            >
              <div
                v-for="user, userIdx in signatories"
                :key="user.uuid"
                class="relative"
              >
                <img
                  class="rounded-full max-w-none h-7 w-7 ring-2 ring-gray-100"
                  :src="user.profile_photo_url"
                  :alt="getUserRepresentation(user)"
                  data-tippy-context
                  :data-tippy-content="
                    getUserRepresentation(user)
                      + (checkAllSignaturesComplete(user.uuid, user.party_uuid)?.remainingCount > 0
                        ? ` (${ $t('documentStatusBanner.signaturesMissing', checkAllSignaturesComplete(user.uuid, user.party_uuid)?.remainingCount) })`
                        : '')
                  "
                  data-placement="bottom"
                >
                <span
                  v-if="
                    !checkAllSignaturesComplete(user.uuid, user.party_uuid)?.remainingCount
                  "
                  class="flex items-center absolute -bottom-1 left-0.5 rounded-full bg-teal-500 p-0.5 ring-2 ring-yellow-50 z-10"
                >
                  <CheckIcon class="w-3 h-3 text-white" />
                </span>
                <span
                  v-else-if="checkAllSignaturesComplete(user.uuid, user.party_uuid)?.active"
                  class="flex items-center absolute -bottom-1 left-0.5 rounded-full bg-yellow-500 p-0.5 ring-2 ring-yellow-50 z-10"
                >
                  <EllipsisHorizontalIcon class="w-3 h-3 text-white" />
                </span>
                <span
                  v-else-if="activeSigningPhase?.sequential_signing || currentDocument.sequential_signing"
                  class="flex items-center justify-center  absolute -bottom-1 -left-0.5 w-4 h-4 rounded-full text-[11px] font-medium bg-indigo-500 text-white ring-2 ring-yellow-50 z-10"
                >
                  {{ userIdx+1 }}
                </span>
                <span
                  v-if="(activeSigningPhase?.sequential_signing || currentDocument.sequential_signing) && userIdx+1 < signatories.length"
                  class="absolute bottom-0 h-1.5 bg-yellow-50 left-0 right-0 z-0"
                >
                  <span
                    v-if="(activeSigningPhase?.sequential_signing || currentDocument.sequential_signing) && userIdx+1 < signatories.length"
                    :class="!checkAllSignaturesComplete(user.uuid, user.party_uuid)?.nextInLine ? '' : 'opacity-30'"
                    class="absolute left-0 right-0 -mb-px border-t-2 border-dotted bottom-1 border-t-indigo-500"
                  />
                </span>
              </div>
            </div>
            <button
              v-if="!mau"
              class="text-xs text-gray-400 btn-plain btn-sm hover:bg-gray-200 hover:text-gray-700 focus:ring-gray-300"
              @click.prevent="showWrongSignatoryOptions"
            >
              {{ $t('documentStatusBanner.wrongSignatory.hint') }}
            </button>
          </div>
          <div
            v-if="!!mdu && signaturesRequiredByMdu && signaturesRequiredByMdu?.nextRefUuid"
            class="flex items-center w-auto gap-3 mt-0 shrink-0"
          >
            <button
              :data-cy-sel="`sign-now-button`"
              type="button"
              class="flex items-center justify-center gap-1.5 btn-secondary btn-sm"
              @click.prevent="handleJumpToSignatureBlock(signaturesRequiredByMdu?.nextRefUuid, mdu?.uuid, false)"
            >
              <SignatureIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.signNow', { count: signaturesRequiredByMdu.remainingCount }) }}
            </button>
          </div>
        </template>
      </template>
    </div>
  </div>
  <div
    id="documentStagePopoverContainer"
    class="hidden"
  >
    <DocumentStagePopover
      :document="currentDocument"
      :stage="stage"
      :ready-checkpoints="readyCheckpoints"
      :is-confirmed-parties="isConfirmedParties"
    >
      <template #checkpointActions>
        <div class="flex justify-between p-2 bg-indigo-50 rounded-b-md">
          <div />
          <button
            type="button"
            :disabled="isLoadingStartCheckpointPass || !!(documentEditorSession?.uuid) || !mdu?.permissions.includes('start_checkpoint')"
            class="relative flex items-center justify-center gap-1.5 btn-primary btn-sm"
            @click.prevent="
              startCheckpointPass(readyCheckpoints[0]?.uuid);
              $emit('hide-stage-tippy');
            "
          >
            <template v-if="isLoadingStartCheckpointPass">
              <SpinLoader
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.pleaseWait') }}…
            </template>
            <template v-else>
              <FlagIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.startCheckpoint') }}
            </template>
          </button>
        </div>
      </template>
      <template #draftReadyActions>
        <div class="flex justify-between p-2 bg-indigo-50 rounded-b-md">
          <div>
          <!--<button
            type="button"
            class="relative px-2 py-1 text-sm font-medium text-indigo-700 border border-transparent rounded-md bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300"
            @click.prevent="true"
          >
            Need help?
          </button>-->
          </div>
          <button
            v-if="!isConfirmedParties"
            :data-cy-sel="`manage-parties-and-signatories-stage-popover-button`"
            type="button"
            class="flex items-center justify-center gap-1.5 btn-primary btn-sm"
            @click.prevent="
              $emit('show-parties-confirm-modal');
              $emit('hide-stage-tippy');
            "
          >
            {{ $t('documentStatusBanner.managePartiesAndSignatories') }}
            <ArrowRightIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
          </button>
          <button
            v-else
            type="button"
            class="flex items-center justify-center gap-1.5 btn-primary btn-sm"
            @click.prevent="
              $emit('start-review');
              $emit('hide-stage-tippy');
            "
          >
            <SpinLoader
              v-if="isChangingStage"
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <ArrowRightCircleIcon
              v-else
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span v-if="isChangingStage || isLoadingDocumentVariables">{{ $t('common.pleaseWait') }}…</span>
            <span v-else>{{ $t('documentStatusBanner.proceedToReview') }}</span>
          </button>
        </div>
      </template>
      <template #sharingActions>
        <div class="flex justify-between p-2 bg-indigo-50 rounded-b-md">
          <div>
          <!--<button
            type="button"
            class="relative px-2 py-1 text-sm font-medium text-indigo-700 border border-transparent rounded-md bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300"
            @click.prevent="true"
          >
            Need help?
          </button>-->
          </div>
          <div class="flex items-center gap-2">
            <button
              type="button"
              class="flex items-center justify-center gap-1.5 btn-primary btn-sm"
              @click.prevent="
                $emit('show-sharing-modal');
                $emit('hide-stage-tippy');
              "
            >
              <EnvelopeIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('documentStatusBanner.inviteExternals') }}
            </button>
            <button
              type="button"
              class="relative flex items-center justify-center gap-1 px-2 py-1 text-sm font-medium text-indigo-700 border border-transparent rounded-md bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300"
              @click.prevent="
                $emit('set-has-external-document-users-invited');
                $emit('hide-stage-tippy');
              "
            >
              <ForwardIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.skip') }}
            </button>
          </div>
        </div>
      </template>
      <template #signingActions>
        <div class="flex justify-between p-2 bg-indigo-50 rounded-b-md">
          <div>
          <!--<button
            type="button"
            class="relative px-2 py-1 text-sm font-medium text-indigo-700 border border-transparent rounded-md bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300"
            @click.prevent="true"
          >
            Need help?
          </button>-->
          </div>
          <button
            type="button"
            class="flex items-center justify-center gap-2 btn-primary btn-sm"
            @click.prevent="
              () => {
                if (!activeWorkingCopy?.uuid) handleClickStartSigningPhaseButton()
                $emit('hide-stage-tippy')
              }
            "
          >
            <SignatureIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('documentStatusBanner.startSigningProcess') }}
          </button>
        </div>
      </template>
    </DocumentStagePopover>
  </div>
</template>
