import { JSONContent, generateHTML } from "@tiptap/core"
import { Document as DocumentExtension } from "@tiptap/extension-document"
import { Paragraph as ParagraphExtension } from "@tiptap/extension-paragraph"
import { Text as TextExtension } from "@tiptap/extension-text"

// internal
import { useImageStore } from "~/stores"
import { Mammoth, MammothComment, MammothJSON, MammothOptions } from "~/types"

import { convertMammothJsonToTiptapJson } from "../mammothJsonConverter"
import { convertMammothChildToTiptapJson } from "../mammothJsonConverter/convertJsonMammothToTiptap"
import TextStyle from "@tiptap/extension-text-style"
import HardBreak from "@tiptap/extension-hard-break"
import { orderBy } from "lodash-es"

export const wordFileTypeRegex = /^(application\/msword|application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/i

const updateImageAttrs = (json: JSONContent, uniqueIdToImageResMap: Record<string, any>) => {
  if (!Array.isArray(json.content)) return

  json.content.forEach(
    (item) => {
      if (item.type === "resizableImage") {
        if (!item.attrs.uuid) return

        const imageRes = uniqueIdToImageResMap[item.attrs.uuid]

        if (!imageRes) return

        item.attrs.refUuid = imageRes.ref_uuid
        item.attrs.alt = item.attrs.el.altText

        const isSvg = item.attrs.dataUrl.split(";")[0].split(":")[1] === "image/svg+xml"

        if (!isSvg) delete item.attrs.dataUrl
        delete item.attrs.el
      }

      updateImageAttrs(item, uniqueIdToImageResMap)
    },
  )
}

interface MammothData {
  lib?: Mammoth
  json?: MammothJSON
  options: MammothOptions
  import: () => void
}

const mammoth: MammothData = {
  options: {
    transformDocument: async (element) => {
      mammoth.json = element
    },
    styleMap: [
      "comment-reference => sup",
    ],
  },
  import: async () => mammoth.lib = await import("mammoth/mammoth.browser"),
}

interface GetTiptapJsonFromWordFileParams {
  file: File
  imageStore: ReturnType<typeof useImageStore>
  includeComments: boolean
  includeTrackedChanges: boolean
}

export const getTiptapJsonFromWordFile = async (
  {
    file,
    imageStore,
    includeComments,
    //includeTrackedChanges,
  }: GetTiptapJsonFromWordFileParams,
): Promise<JSONContent> => {
  if (!wordFileTypeRegex.test(file.type)) {
    throw new Error("file is not a word file")
  }

  if (!mammoth.lib) {
    await mammoth.import()
    if (!mammoth.lib) return
  }

  const fileReader = new FileReader()

  return new Promise(
    (resolve, reject) => {
      fileReader.onloadend = async () => {
        try {
          const arrayBuffer = fileReader.result as ArrayBuffer

          mammoth.json = null

          await mammoth.lib.convertToHtml({ arrayBuffer }, mammoth.options)

          if (!mammoth.json) {
            reject("mammoth did not save json")

            return
          }

          const {
            json: tiptapJsonWithChanges,
            jsonBeforeChanges: tiptapJsonBeforeChanges,
            jsonProposal: tiptapJsonProposal,
            idDataUriMap,
            changes,
            commentMarkers,
          } = await convertMammothJsonToTiptapJson(mammoth.json)

          const comments: MammothComment[] = orderBy(mammoth.json.comments, [ "date" ], [ "asc" ])
          const commentResults: MammothComment[] = []

          if (comments && includeComments) {
            // Match comment markers with comments to get uuids
            for (const comment of comments) {
              for (const marker of commentMarkers) {
                const isEndMarkerOrDuplicate = commentResults.some((c) => c.commentId === marker.id)
                if (isEndMarkerOrDuplicate) continue
                const commentTipTapJson = comment.body.map(convertMammothChildToTiptapJson).flat()
                const jsonContent = {
                  type: "doc",
                  content: commentTipTapJson,
                }
                const html = generateHTML(
                  jsonContent,
                  [
                    TextExtension,
                    ParagraphExtension,
                    DocumentExtension,
                    TextStyle,
                    HardBreak,
                  ])
                if (!html) continue
                if (comment.commentId === `${marker.id}`) {
                  let result = {
                    uuid: marker.uuid,
                    commentId: marker.id,
                    html: html,
                    ...comment,
                  }
                  const parent = commentResults.find((c) => c.uuid === marker.uuid)
                  if (parent) {
                    result = {
                      ...result,
                      parentId: parent.commentId,
                    }
                  }
                  commentResults.push(result)
                }
              }
            }
          }

          const data = await Promise.all(
            Object.entries(idDataUriMap)
              .map(
                async ([ uniqueId, dataUri ]) => {

                  const blob = await fetch(dataUri).then((res) => res.blob())

                  const type = dataUri.split(";")[0].split(":")[1]

                  const file = new File(
                    [ blob ],
                    `${uniqueId}.${type.split("/")[1]}`,
                    { type },
                  )

                  const vaporRes = await Vapor.store<{ uuid: string }>(file)

                  const imageRes = await imageStore.createImage(vaporRes.uuid)

                  return {
                    [uniqueId]: imageRes,
                  }
                },
              ),
          )

          const uniqueIdToImageResMap = Object.assign({}, ...data)

          updateImageAttrs(tiptapJsonProposal, uniqueIdToImageResMap)

          resolve({
            tiptapJsonWithChanges,
            tiptapJsonBeforeChanges,
            tiptapJsonProposal,
            changes,
            comments: commentResults,
          })
        } catch (e) {
          reject(e)

          return
        }
      }

      fileReader.readAsArrayBuffer(file)
    },
  )
}

