<script lang="ts" setup>
// external
import { computed, ref } from "vue"

import { createClient as createEidEasyClient } from "@eid-easy/eideasy-browser-client"
import { ArrowLeftIcon, DevicePhoneMobileIcon, GlobeEuropeAfricaIcon, KeyIcon, ShieldCheckIcon } from "@heroicons/vue/24/outline"
import { Field, Form } from "vee-validate"

// internal
import { Countdown, PhoneField, SignatureProviderSelector, SpinLoader } from "~/components"
import { SignatureQesProcess } from "~/types"
import { useI18n } from "vue-i18n"
import { useNotificationStore, useSignatureStore } from "~/stores"
import { storeToRefs } from "pinia"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/24/solid"
import { countryCodes } from "~/utils"
import { watch } from "vue"
const isProduction = computed<boolean>(() => window.location.origin === "https://app.fynk.com")

const { t, locale } = useI18n()

const { notify } = useNotificationStore()

const signatureStore = useSignatureStore()
const { signatureProcess, isLoadingCreateQesSignature } = storeToRefs(signatureStore)
const { setIsLoadingCreateQesSignature } = signatureStore

const selectEidEasyMethod = (method: string) => {

  const cc = method === "estonian-mobile-id" ? "EE" : method === "austrian-handy-signatur" ? "AT" : "DE"
  const lang = locale.value === "de" ? "de" : "en"
  const cid = isProduction.value ? "znDuNNHrIele9R3RAVAEZeLe9RVkIEPN" : "T08lrzgW6po0fcNWYm5VNycz9bZmuaLe"
  const isSandBox = isProduction.value ? false : true

  isLoadingEidEasyMethod.value = method

  eidEasyClient.value = createEidEasyClient({
    clientId: cid,
    docId: (signatureProcess.value as SignatureQesProcess).eid_easy_doc_id,
    countryCode: cc,
    language: lang,
    sandbox: isSandBox,
  })

  watch(selectedCountry, (newVal) => {
    eidEasyClient.value = createEidEasyClient({
      clientId: cid,
      docId: (signatureProcess.value as SignatureQesProcess).eid_easy_doc_id,
      countryCode: newVal?.Code || cc,
      language: lang,
      sandbox: isSandBox,
    })
  })

  if (method === "austrian-handy-signatur") {

    eidEasyClient.value?.signature.atHandySignature.start({
      redirect: (context: any) => {
        window.location = context.data.redirect_uri
        return {
          data: null,
        }
      },
      error: (err) => {
        isLoadingEidEasyMethod.value = null
        notify({
          title: t("signaturePad.errors.austrianHandySignaturFailed"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      },
    })

  } else if (method === "estonian-mobile-id") {
    isVisibleEstonianMobileIdForm.value = true
    isLoadingEidEasyMethod.value = null
  } else if (method === "d-trust-sign-me") {
    isVisibleDTrustForm.value = true
    isLoadingEidEasyMethod.value = null
  } else if (method === "zeal-id") {
    isVisibleZealIdForm.value = true
    isLoadingEidEasyMethod.value = null
  } else if (method === "evrotrust") {
    isVisibleEvrotrustForm.value = true
    isLoadingEidEasyMethod.value = null
  }
}

const handleDTrustFormSubmit = async () => {
  isSubmittingDTrustForm.value = true
  eidEasyClient.value?.signature.dTrustSignMeSignature.start({
    username: dTrustUserName.value, // required
    redirect: (context: any) => {
      window.location = context.data.redirect_uri
      return {
        data: null,
      }
    },
    error: (err) => {
      notify({
        title: t("signaturePad.errors.dTrustFailed"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
      isSubmittingDTrustForm.value = false
    },
  })
}

const handleEvrotrustFormSubmit = async () => {
  eidEasyClient.value?.signature.evroTrustSignature.start({
    phone: phone.value, // required
    ccountryCode: selectedCountry.value?.Code, // required
    started: (result) => {
      evrotrustTransactionId.value = result.data?.transaction_id
    },
    fail: (error) => {
      isLoadingEidEasyMethod.value = null
      console.error(error)
      // Notify user
      notify({
        title: t("signaturePad.errors.evrotrustFailed"),
        message: error.response?.data?.message || error.message,
        type: "error",
      })
    },
    success: () => {
      // Emit event to create signature
      emit("create-signature", signatureProcess.value?.uuid)
    },
    finished: () => {
      setIsLoadingCreateQesSignature(false)
    },
  })
}


const mobileIdSecurityChallengeCodeValidTimestamp = ref<string>("")
const mobileIdSecurityChallengeCodeValidSeconds = ref<number>(0)

const handleEstonianMobileIdFormSubmit = async () => {
  if (!phone.value) {
    phoneError.value = true
    return
  }
  // Phone number must be without spaces
  const cleanPhoneValue = phone.value.replace(/\s/g, "")
  phoneError.value = false
  setIsLoadingCreateQesSignature(true)
  phoneError.value = false
  eidEasyClient.value?.signature.mobileIdSignature.start({
    idcode: estonianMobileIdIdCode.value, // required
    phone: cleanPhoneValue, // required
    ccountryCode: "EE", // required
    started: (res) => {
      // Create timestamp for 60 seconds from now
      const timestamp = Date.now() + 120000
      mobileIdSecurityChallengeCodeValidTimestamp.value = timestamp.toString()
      mobileIdSecurityChallengeCode.value = res.data?.challenge
      // Set seconds to 60
      mobileIdSecurityChallengeCodeValidSeconds.value = 120
    },
    fail: (err) => {
      isLoadingEidEasyMethod.value = null
      notify({
        title: t("signaturePad.errors.mobileIdFailed"),
        message: err.response?.data?.message || err.message,
        type: "error",
      })
      mobileIdSecurityChallengeCodeValidSeconds.value = 0
      mobileIdSecurityChallengeCodeValidTimestamp.value = ""
      mobileIdSecurityChallengeCode.value = ""
    },
    success: async () => {
      emit("create-signature", signatureProcess.value?.uuid)
    },
    finished: () => {
      setIsLoadingCreateQesSignature(false)
    },
  })
}

const handleZealIdFormSubmit = async () => {
  eidEasyClient.value.signature.zealIdSignature.start({
    ccountryCode: selectedCountry.value?.Code, // required
    redirect: (context: any) => {
      window.location = context.data.redirect_uri
      return {
        data: null,
      }
    },
  })
}

const emit = defineEmits([ "create-signature" ])

const isVisibleDTrustForm = ref<boolean>(false)
const isVisibleEstonianMobileIdForm = ref<boolean>(false)
const isVisibleEvrotrustForm = ref<boolean>(false)
const isSubmittingDTrustForm = ref<boolean>(false)
const eidEasyClient = ref<any>(null)
const dTrustUserName = ref<string>(null)
const estonianMobileIdIdCode = ref<string>(isProduction.value ? "" : "60001017869")
const phone = ref<string>(isProduction.value ? "" : "+37268000769")
const phoneError = ref<boolean>(false)
const isLoadingEidEasyMethod = ref<string>(null)
const isVisibleZealIdForm = ref<boolean>(false)
const selectedCountry = ref<any>(countryCodes.find((c) => c.Code === "DE"))
const evrotrustTransactionId = ref<string>(null)
const mobileIdSecurityChallengeCode = ref<string>(null)
</script>

<template>
  <div class="-mt-4 bg-white rounded-lg ring-2 ring-offset-indigo-900 ring-offset-2 ring-indigo-500">
    <div v-if="isVisibleDTrustForm">
      <Form
        v-slot="{ errors }"
        @submit="handleDTrustFormSubmit"
      >
        <div class="px-6 pt-4 pb-2">
          <div class="mb-1.5 text-sm font-medium flex items-center gap-1">
            <KeyIcon
              class="w-5 h-5"
              aria-hidden="true"
            />
            {{ $t('signaturePad.dTrustUserName') }}
          </div>
          <Field
            v-model="dTrustUserName"
            name="dTrustUserName"
            type="text"
            :placeholder="$t('signaturePad.dTrustUserNamePlaceholder') + '…'"
            class="input-primary"
            :class="[errors.dTrustUserName ? 'error' : '']"
            rules="required"
          />
        </div>
        <div class="flex justify-between p-4 mt-4 space-x-4 bg-gray-100 border-t border-gray-200 rounded-b-lg">
          <button
            type="button"
            class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
            @click.prevent="isVisibleDTrustForm = false"
          >
            <ArrowLeftIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('common.back') }}
          </button>
          <button
            class="flex items-center space-x-2 btn-primary"
            type="submit"
            :disabled="isSubmittingDTrustForm"
          >
            <SpinLoader
              v-if="isSubmittingDTrustForm"
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('signaturePad.continueToDTrust') }}</span>
          </button>
        </div>
      </Form>
    </div>
    <div v-else-if="isVisibleEstonianMobileIdForm">
      <div
        v-if="mobileIdSecurityChallengeCode"
        class="flex flex-col items-center justify-center text-sm text-center min-w-[20rem]"
      >
        <div
          class="flex items-center pt-12 pb-6 space-x-2 px-18"
          :class="{'text-teal-500': mobileIdSecurityChallengeCodeValidSeconds >= 30, 'text-yellow-500': mobileIdSecurityChallengeCodeValidSeconds < 30 && mobileIdSecurityChallengeCodeValidSeconds > 0, 'text-red-600': mobileIdSecurityChallengeCodeValidSeconds === 0}"
        >
          <span>{{ $t('signaturePad.codeValid') }}:</span>
          <Countdown
            class="font-medium"
            :timestamp="mobileIdSecurityChallengeCodeValidTimestamp"
            @update:seconds="mobileIdSecurityChallengeCodeValidSeconds = $event"
          />
        </div>
        <div class="flex flex-col items-center justify-center w-full gap-2 pt-6 pb-12 border-t px-18 border-t-gray-200">
          <div class="font-medium text-center">
            {{ $t('signaturePad.yourVerificationCode') }}
          </div>
          <div class="text-3xl font-bold tracking-wider text-center">
            {{ mobileIdSecurityChallengeCode }}
          </div>
        </div>
      </div>
      <Form
        v-else
        v-slot="{ errors }"
        @submit="handleEstonianMobileIdFormSubmit"
      >
        <div class="px-6 pt-4 pb-2">
          <div class="mb-1.5 text-sm font-medium flex items-center gap-1">
            <KeyIcon
              class="w-5 h-5"
              aria-hidden="true"
            />
            {{ $t('signaturePad.estonianMobileIdId') }}
          </div>
          <Field
            v-model="estonianMobileIdIdCode"
            name="estonianMobileIdIdCode"
            type="text"
            :placeholder="$t('signaturePad.estonianMobileIdIdPlaceholder') + '…'"
            class="input-primary"
            :class="[errors.estonianMobileIdIdCode ? 'error' : '']"
            rules="required"
          />
        </div>
        <div class="px-6 pt-4 pb-2">
          <div class="mb-1.5 text-sm font-medium flex items-center gap-1">
            <DevicePhoneMobileIcon
              class="w-5 h-5"
              aria-hidden="true"
            />
            {{ $t('signaturePad.phoneNumber') }}
          </div>
          <PhoneField
            id="estonian-mobile-id-mobile-phone"
            :phone="phone"
            name="mobile-phone"
            placeholder="+43 664 1234 567"
            style-classes="vue-tel-input"
            :disabled="false"
            :has-error="false"
            :autofocus="false"
            :required="true"
            @update:phone="phone = $event"
            @validation-error="phoneError = true"
            @validation-passed="phoneError = false"
          />
          <div
            v-if="phoneError"
            class="mt-1 text-xs text-red-700 max-w-fit"
          >
            {{ $t('signatures.invalidPhoneNumber') }}
          </div>
        </div>
        <div class="flex justify-between p-4 mt-4 space-x-4 bg-gray-100 border-t border-gray-200 rounded-b-lg">
          <button
            type="button"
            class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
            @click.prevent="isVisibleEstonianMobileIdForm = false"
          >
            <ArrowLeftIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('common.back') }}
          </button>
          <button
            class="flex items-center space-x-2 btn-primary"
            type="submit"
            :disabled="isLoadingCreateQesSignature"
          >
            <SpinLoader
              v-if="isLoadingCreateQesSignature"
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('signaturePad.sign') }}</span>
          </button>
        </div>
      </Form>
    </div>
    <div v-else-if="isVisibleEvrotrustForm">
      <Form
        @submit="handleEvrotrustFormSubmit"
      >
        <div class="px-6 pt-4 pb-2">
          <div class="mb-1.5 text-sm font-medium flex items-center gap-1">
            <DevicePhoneMobileIcon
              class="w-5 h-5"
              aria-hidden="true"
            />
            {{ $t('signaturePad.phoneNumber') }}
          </div>
          <PhoneField
            id="estonian-mobile-id-mobile-phone"
            :phone="phone"
            name="mobile-phone"
            placeholder="+43 664 1234 567"
            style-classes="vue-tel-input"
            :disabled="false"
            :has-error="false"
            :autofocus="false"
            :required="true"
            @update:phone="phone = $event"
            @validation-error="phoneError = true"
            @validation-passed="phoneError = false"
          />
          <div
            v-if="phoneError"
            class="mt-1 text-xs text-red-700 max-w-fit"
          >
            {{ $t('signatures.invalidPhoneNumber') }}
          </div>
        </div>
        <div class="px-6 pt-4 pb-2">
          <div class="mb-1.5 text-sm font-medium flex items-center gap-1">
            <GlobeEuropeAfricaIcon
              class="w-5 h-5"
              aria-hidden="true"
            />
            {{ $t('signaturePad.selectCountry') }}
          </div>
          <Listbox
            v-model="selectedCountry"
            as="div"
          >
            <div class="relative mt-1 font-medium">
              <ListboxButton class="w-full btn-white">
                <div
                  v-if="selectedCountry"
                  class="flex items-center gap-2"
                >
                  <span>{{ selectedCountry?.Name }}</span>
                </div>
                <span
                  v-else
                  class="block text-gray-500 truncate"
                >{{ $t('signaturePad.selectCountry') }}…</span>

                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-1 transition-all duration-300 pointer-events-none group-hover:opacity-100 group-focus:opacity-100"
                >
                  <ChevronUpDownIcon
                    class="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions class="left-0 right-auto listbox-options">
                  <ListboxOption
                    v-for="localeOption in countryCodes"
                    :key="localeOption.Code"
                    v-slot="{ active, selected }"
                    as="template"
                    :value="localeOption"
                  >
                    <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center gap-2 pr-3']">
                        <span>{{ localeOption.Name }}</span>
                      </span>

                      <span
                        v-if="selected"
                        :class="[
                          active ? 'text-white' : 'text-gray-500',
                          'absolute inset-y-0 right-0 flex items-center pr-3',
                        ]"
                      >
                        <CheckIcon
                          class="w-5 h-5 shrink-0"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
        <div class="flex justify-between p-4 mt-4 space-x-4 bg-gray-100 border-t border-gray-200 rounded-b-lg">
          <button
            type="button"
            class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
            @click.prevent="isVisibleEvrotrustForm = false"
          >
            <ArrowLeftIcon
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            {{ $t('common.back') }}
          </button>
          <button
            class="flex items-center space-x-2 btn-primary"
            type="submit"
            :disabled="isLoadingCreateQesSignature"
          >
            <SpinLoader
              v-if="isLoadingCreateQesSignature"
              class="w-5 h-5 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('signaturePad.sign') }}</span>
          </button>
        </div>
      </Form>
    </div>
    <div v-else-if="isVisibleZealIdForm">
      <div class="px-6 pt-4 pb-2">
        <div class="mb-1.5 text-sm font-medium flex items-center gap-1">
          <GlobeEuropeAfricaIcon
            class="w-5 h-5"
            aria-hidden="true"
          />
          {{ $t('signaturePad.selectCountry') }}
        </div>
        <Listbox
          v-model="selectedCountry"
          as="div"
        >
          <div class="relative mt-1 font-medium">
            <ListboxButton class="w-full btn-white">
              <div
                v-if="selectedCountry"
                class="flex items-center gap-2"
              >
                <span>{{ selectedCountry?.Name }}</span>
              </div>
              <span
                v-else
                class="block text-gray-500 truncate"
              >{{ $t('signaturePad.selectCountry') }}…</span>

              <span
                class="absolute inset-y-0 right-0 flex items-center pr-1 transition-all duration-300 pointer-events-none group-hover:opacity-100 group-focus:opacity-100"
              >
                <ChevronUpDownIcon
                  class="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions class="left-0 right-auto listbox-options">
                <ListboxOption
                  v-for="localeOption in countryCodes"
                  :key="localeOption.Code"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="localeOption"
                >
                  <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate flex items-center gap-2 pr-3']">
                      <span>{{ localeOption.Name }}</span>
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-white' : 'text-gray-500',
                        'absolute inset-y-0 right-0 flex items-center pr-3',
                      ]"
                    >
                      <CheckIcon
                        class="w-5 h-5 shrink-0"
                        aria-hidden="true"
                      />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
      <div class="flex justify-between p-4 mt-4 space-x-4 bg-gray-100 border-t border-gray-200 rounded-b-lg">
        <button
          type="button"
          class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
          @click.prevent="isVisibleZealIdForm = false"
        >
          <ArrowLeftIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
          {{ $t('common.back') }}
        </button>
        <button
          class="flex items-center space-x-2 btn-primary"
          type="button"
          :disabled="isLoadingCreateQesSignature"
          @click="handleZealIdFormSubmit"
        >
          <SpinLoader
            v-if="isLoadingCreateQesSignature"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span>{{ $t('signaturePad.sign') }}</span>
        </button>
      </div>
    </div>
    <div v-else>
      <div class="py-6 px-6 text-indigo-700 border-b border-indigo-300 bg-indigo-100 rounded-t-lg text-sm font-medium flex items-center gap-1.5">
        <ShieldCheckIcon
          class="w-5 h-5"
          aria-hidden="true"
        />
        {{ $t('signaturePad.selectMethod') }}:
      </div>
      <div class="p-4">
        <SignatureProviderSelector
          :is-loading-eid-easy-method="isLoadingEidEasyMethod"
          @select-eid-easy-method="selectEidEasyMethod"
        />
      </div>
    </div>
  </div>
</template>
