<script setup lang="ts">
// internal
import { storeToRefs } from "pinia"
import { DocumentRenderedHtml, OverlayScrollbar, SkeletonLoader } from "~/components"
import { useDocumentStore, useSharedStore, useTemplateStore } from "~/stores"
import { CrudContext } from "~/types"

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const storeForPreview = crudContext.value === CrudContext.document ? useDocumentStore() : useTemplateStore()
const { previewContent, isLoadingPreviewContent } = storeForPreview

</script>

<template>
  <div class="-mt-4 bg-gray-200 rounded-md">
    <div class="px-6 py-4 bg-white rounded-t-md">
      <div class="text-lg">
        {{ $t('documents.previewRenderedDocument') }}
      </div>
    </div>
    <div class="overflow-y-hidden">
      <OverlayScrollbar
        tag="div"
        class="h-[70vh]"
      >
        <!-- eslint-disable vue/no-v-html vue/html-self-closing -->
        <DocumentRenderedHtml
          :id="'previewRenderedHtmlContainer'"
          class="p-6 pt-6"
          :is-loading="isLoadingPreviewContent"
          :html-content="previewContent"
        />
        <!-- eslint-enable vue/no-v-html vue/html-self-closing -->

        <SkeletonLoader
          v-if="isLoadingPreviewContent"
          size="large"
        />
      </OverlayScrollbar>
    </div>
  </div>
</template>
