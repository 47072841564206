<script setup lang="ts">
// external
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { storeToRefs } from "pinia"

import { TrashIcon } from "@heroicons/vue/24/outline"
import VuePdfEmbed from "vue-pdf-embed"

import "vue-pdf-embed/dist/styles/annotationLayer.css"
import "vue-pdf-embed/dist/styles/textLayer.css"

import { throttle, sortBy } from "lodash-es"

// internal
import { OverlayScrollbar, SignatureSignatoryDisplay, SpinLoader } from "~/components"
import { DefaultSignatureBlockIcon, DragIndicatorIcon, MinimalSignatureBlockIcon } from "~/icons"
import { useDocumentStore, useMetadataStore, useNotificationStore, usePartyStore, usePdfBrickStore, usePusherStore, useSharedStore, useSignatureStore, useUserStore } from "~/stores"
import { Document, DocumentUser, DocumentUserRoleEnum, DynamicField, Party, PdfBrick, SignatureBlock, SigningPhase, BoundingBox, Metadata, MultiFieldType, Signature, Coordinate, SignatureBlockStyle, CrudContext } from "~/types"
import { cyrb53, generateBoundingBoxOffsetLabel, getMetadataDisplayName, scrollTo } from "~/utils"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

interface UiBoundingBox {
  key: Metadata["name"]
  boundingBox: BoundingBox
}

interface Props {
  id?: string
  src: string
  componentKey: number
  signingPhase?: SigningPhase
  disabled?: boolean
  boundingBoxes?: UiBoundingBox[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    id: null,
    componentKey: 0,
    signingPhase: null,
    disabled: false,
    boundingBoxes: () => [],
  },
)

const emit = defineEmits([ "bind-signature-blocks" ])

const { notify } = useNotificationStore()

const pdfBrickStore = usePdfBrickStore()
const { uuidOfRemovingPdfBrick, pdfBricks } = storeToRefs(pdfBrickStore)

const metadataStore = useMetadataStore()
const { metadata } = storeToRefs(metadataStore)

const documentStore = useDocumentStore()
const { currentDocument, isLockedDocument, mdu } = storeToRefs(documentStore)

const sharedStore = useSharedStore()
const { pdfViewerTimeout } = storeToRefs(sharedStore)
const { setPdfViewerTimeout } = sharedStore

const signatureStore = useSignatureStore()
const { signatureBlocks, signatures, uuidsOfIsUpdatingSignatureBlock } = storeToRefs(signatureStore)
const { checkSignature, updateSignatureBlock } = signatureStore

const isActiveHiddenPdfBricks = ref<boolean>(true)

//const dynamicFieldStore = useDynamicFieldStore()
//const { dynamicFields } = storeToRefs(dynamicFieldStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const pdfViewer = ref<any>()

const isMountedPdfViewer = ref<boolean>(false)
const isRenderedPdfViewer = ref<boolean>(false)

const isLoadingCreatePdfBrick = ref<boolean>(false)

// TODO: How to get this for different formats on different pages
// const [ maxPdfX, maxPdfY ] = [ 595, 842 ]

const scaleFactor = ref<number>(1)

const getScaleFactor = (): number => {

  const canvasEl = document
    .querySelector(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed div > canvas`)

  if (!canvasEl) return 1

  const canvasClientRect =
    canvasEl.getBoundingClientRect()

  const maxAllowedWidth = 896

  const scaleX = Math.min(canvasClientRect.width / maxAllowedWidth, 1)

  scaleFactor.value = scaleX

  return scaleX
}

const setAndReturnCoordinates = (ev: DragEvent, originalElement: HTMLDivElement, offsetX = 0, offsetY = 0, pdfPage = 0, scaleFactor: number) => {
  if (pdfPage === 0) return

  if (!props.componentKey) throw new Error("No componentKey provided")

  const pdfViewerPageElementSelector = `#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed div > canvas`
  const pdfViewerPageElements = document.querySelectorAll(pdfViewerPageElementSelector)

  if (!pdfViewerPageElements.length) throw new Error("No pdfViewerPageElements found")

  const pdfViewerPage = pdfViewerPageElements[pdfPage - 1]

  const {
    width: maxHtmlX,
    height: maxHtmlY,
  } = pdfViewerPage.getBoundingClientRect()

  // const draggedT = originalElement.getBoundingClientRect()
  const draggedT = {
    left: ev.pageX - offsetX,
    top: ev.pageY - offsetY,
  }

  const dropzoneT = document
    .querySelectorAll(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed > div`)[pdfPage - 1]
    .getBoundingClientRect()

  const y = draggedT.top - dropzoneT.top
  const x = draggedT.left - dropzoneT.left

  const dimensions = pageDimensions.value?.find((el) => el.pageNumber === pdfPage)

  if (!dimensions) console.error("No dimensions found for page", pdfPage)

  const positionY = (y * dimensions?.height * scaleFactor) / (maxHtmlY * scaleFactor)
  const positionX = (x * dimensions?.width * scaleFactor) / (maxHtmlX * scaleFactor)

  const attributes: Record<string, string> = {
    "data-pdf-y": `${positionY}`,
    "data-pdf-x": `${positionX}`,
    "data-y": `${y}`,
    "data-x": `${x}`,
  }

  for (const [ key, val ] of Object.entries(attributes)) {
    originalElement.setAttribute(key, val)
  }

  return {
    pdfY: positionY,
    pdfX: positionX,
    domY: y,
    domX: x,
  }
}

const pageDimensions = ref<{ pageNumber: number, width: number, height: number }[]>([])

const onPdfLoaded = async (pdf: any) => {

  isMountedPdfViewer.value = true

  const pageCount: number = pdf.numPages
  for (let pageNumber = 1; pageNumber <= pageCount; pageNumber++) {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1 })
    pageDimensions.value?.push({
      pageNumber,
      width: viewport.width,
      height: viewport.height,
    })
  }

  setTimeout(() => positionPdfBricksInDom(false), 100)

}

const getHashByCoords = (boundingBoxCoords:Coordinate) => {
  const coordString = boundingBoxCoords.join("::")
  return btoa(coordString)
}

const generateBoundingBoxes = () => {
  if (!props.boundingBoxes) return

  // Remove all existing bounding boxes
  const boundingBoxElements = document.querySelectorAll(`#${props.id || "pdfViewerComponent" }_${props.componentKey} [data-key]`)
  boundingBoxElements.forEach((el) => el.remove())

  props.boundingBoxes?.forEach((boundingBox) => {

    const refMetadata = metadata.value?.find((el) => el.name === boundingBox.key)
    if (!refMetadata) return
    const isClause = refMetadata.value_type === MultiFieldType.clause
    const metadataLabel = getMetadataDisplayName(refMetadata, t)

    const canvas = document.querySelectorAll<HTMLCanvasElement>(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed div > canvas`)[boundingBox.boundingBox.page - 1]
    if (!canvas) return
    const canvasParent = canvas.parentElement
    // const position = calculateBoundingBoxPosition(boundingBox.boundingBox, canvas)

    // calculate percentage positions
    const [ positionYPercentage, positionXPercentage, width, height ] = [
      (boundingBox.boundingBox.bounding_box[0] * 100 - 1).toFixed(2),
      (boundingBox.boundingBox.bounding_box[1] * 100 - 1).toFixed(2),
      (boundingBox.boundingBox.bounding_box[2] * 100 + 2).toFixed(2),
      (boundingBox.boundingBox.bounding_box[3] * 100 + 2).toFixed(2),
    ]

    const colorSchema = isClause ? [ "bg-purple-500/10", "border-purple-700", "bg-purple-700" ] : [ "bg-indigo-500/10", "border-indigo-700", "bg-indigo-700" ]

    const boundingBoxElement = document.createElement("div")
    boundingBoxElement.classList.add("absolute", "invisible", "border-2", "rounded-md", colorSchema[0], colorSchema[1])
    boundingBoxElement.style.top = `${positionYPercentage}%`
    boundingBoxElement.style.left = `${positionXPercentage}%`
    boundingBoxElement.style.width = `${width}%`
    boundingBoxElement.style.height = `${height}%`
    boundingBoxElement.style.zIndex = "1"
    boundingBoxElement.setAttribute("data-key", boundingBox.key)
    boundingBoxElement.setAttribute("data-coords", getHashByCoords(boundingBox.boundingBox.bounding_box))

    generateBoundingBoxOffsetLabel(boundingBoxElement, metadataLabel, colorSchema[2])

    canvasParent.appendChild(boundingBoxElement)
  })
}

const scrollToBoundingBoxAndHighlight = async (key: Metadata["name"], scrollContainer: InstanceType<typeof OverlayScrollbar>, boundingBoxes = null, callback: any) => {
  if (!key || !scrollContainer || !props.boundingBoxes) return
  hideBoundingBoxes()

  const boundingBoxSelector = boundingBoxes?.length ? `#${props.id || "pdfViewerComponent" }_${props.componentKey} [data-coords="${getHashByCoords(boundingBoxes[0].bounding_box)}"][data-key="${key}"]` : `#${props.id || "pdfViewerComponent" }_${props.componentKey} [data-key="${key}"]`
  const boundingBoxElementsOfKey = scrollContainer.element?.querySelectorAll(boundingBoxSelector)
  const firstElement = boundingBoxElementsOfKey[0] as HTMLElement
  if (!firstElement) return
  scrollTo(scrollContainer, firstElement, () => {
    boundingBoxElementsOfKey.forEach((el) => {
      el.classList.remove("invisible")
      el.classList.add("bounce-enter-active")
    })
    if (callback) callback()
  })
}

const hideBoundingBoxes = () => {
  const boundingBoxElements = document.querySelectorAll(`#${props.id || "pdfViewerComponent" }_${props.componentKey} [data-key]`)
  boundingBoxElements.forEach((el) => {
    el.classList.add("invisible")
    el.classList.remove("bounce-enter-active")
  })
}

defineExpose({
  scrollToBoundingBoxAndHighlight,
  hideBoundingBoxes,
  generateBoundingBoxes,
})

const onPdfViewerReady = async () => {

  isMountedPdfViewer.value = true
  setTimeout(() => isRenderedPdfViewer.value = true, 100)

  await nextTick()

  // Number pages in PDF viewer via data attribute
  document.querySelectorAll(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed > div`)
    .forEach(
      (el, idx) => {

        el.setAttribute("data-pdf-page", `${idx + 1}`)

        if (props.disabled) return

        el.addEventListener("drop", drop)
        el.addEventListener("dragover", allowDrop)
        el.addEventListener("dragleave", unsetAllowDrop)
      },
    )

  setTimeout(() => {
    positionPdfBricksInDom(false)
    generateBoundingBoxes()
  }, 100)
}

const getCalculatedDomPositionOfPdfBrick = (pdfBrick: PdfBrick, canvas: Element) => {
  if (pdfBrick.page === 0) return

  const { width: domX, height: domY } = canvas.getBoundingClientRect()

  const dimensions = pageDimensions.value?.find((el) => el.pageNumber === pdfBrick.page)

  if (!dimensions) console.error("No dimensions found for page", pdfBrick.page)

  const positionX = pdfBrick.x / dimensions?.width * domX
  const positionY = pdfBrick.y  / dimensions?.height * domY

  return {
    x: positionX,
    y: positionY,
  }
}

const positionPdfBricksInDom = (noBind = false) => {
  const scaleFactor = getScaleFactor()

  pdfBricks.value?.forEach((pdfBrick) => {
    const pdfBrickDomElement = document.querySelector(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .pdf-brick-dom-node_${pdfBrick.uuid}`) as HTMLElement

    if (!pdfBrickDomElement) return

    const [ top, left ] = [
      parseInt(pdfBrickDomElement.style.top) || 0,
      parseInt(pdfBrickDomElement.style.left) || 0,
    ]

    const canvas = document.querySelectorAll<HTMLCanvasElement>(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed div > canvas`)[pdfBrick.page - 1]
    const domPositionOfPdfBrick = getCalculatedDomPositionOfPdfBrick(pdfBrick, canvas)

    const originalWidth = pdfBrickDomElement.offsetWidth
    const originalHeight = pdfBrickDomElement.offsetHeight

    const [ shiftX, shiftY ] = [
      (originalWidth * scaleFactor - originalWidth) / 2,
      (originalHeight * scaleFactor - originalHeight) / 2,
    ]

    const [ newIntLeft, newIntTop ] = [
      (domPositionOfPdfBrick?.x || 0) + shiftX,
      (domPositionOfPdfBrick?.y || 0) + shiftY,
    ]

    if (Math.floor(newIntLeft) === Math.floor(left) && Math.floor(newIntTop) === Math.floor(top)) return

    Object.assign(pdfBrickDomElement.style, {
      left: `${domPositionOfPdfBrick?.x + shiftX}px`,
      top: `${domPositionOfPdfBrick?.y + shiftY}px`,
      transform: `scale(${scaleFactor})`,
    })

    // Remove "hidden" class from pdfBrick (implemented to avoid flickering)
    isActiveHiddenPdfBricks.value = false

    const pdfViewerPageDomElement = document.querySelectorAll(`#${props.id || "pdfViewerComponent" }_${props.componentKey} .vue-pdf-embed div > canvas`)[pdfBrick.page - 1]
    pdfViewerPageDomElement?.parentNode?.prepend(pdfBrickDomElement)
  })

  if (!noBind) emit("bind-signature-blocks")
}

const allowDrop = (ev: DragEvent) => {
  ev.preventDefault();
  (ev.target as HTMLElement).classList.add("mark-allow-drop")
}

const unsetAllowDrop = (ev: DragEvent) => {
  ev.preventDefault();
  (ev.target as HTMLElement).classList.remove("mark-allow-drop")
}

const drop = (ev: DragEvent) => {
  ev.preventDefault();
  (ev.target as HTMLElement).classList.remove("mark-allow-drop")

  const textData = ev.dataTransfer.getData("text")
  if (!textData.trim()) return

  try {

    const dataJson = JSON.parse(textData)
    if (!dataJson) return

    const originalElement = document.getElementById(dataJson?.elementId) as HTMLDivElement
    if (!originalElement) return

    const pdfPage = parseInt((ev.currentTarget as HTMLElement)?.dataset?.pdfPage)
    if (!pdfPage) return

    const scaleFactor = getScaleFactor()
    const localScaleFactor = !scaleFactor || scaleFactor >= 1 ? 1 : scaleFactor

    // Abort when the screen size is too small and the document is scaline
    // Because the browser handles drag / drop coordinates wrong in this case
    /*if (scaleFactor < 1) {
    notify(
      {
        title: t("pdfViewer.signatureBlockPlacedErrorTitle"),
        message: t("pdfViewer.signatureBlockPlacedErrorDescription"),
        type: "error",
      },
    )
    return
    }*/

    const position = setAndReturnCoordinates(ev, originalElement, dataJson?.offsetX, dataJson?.offsetY, pdfPage, localScaleFactor)

    // if dataJson.pdfBrick is present, we are updating it's position
    if (dataJson.pdfBrick) {
      pdfBrickStore.updateLocalPdfBrick(
        currentDocument.value?.uuid,
        {
          ...dataJson.pdfBrick,
          ...(
            {
              x: Math.round(position.pdfX),
              y: Math.round(position.pdfY),
              page: pdfPage,
            }
          ),
        },
      )
    } else {
      createPdfBrick(dataJson?.documentUuid, dataJson?.pdfBrickableType, dataJson?.pdfBrickableUuid, position, pdfPage)
    }
  } catch (err) {
    console.error(err)
  }
}

const createPdfBrick = async (
  documentUuid: Document["uuid"],
  pdfBrickableType: PdfBrick["pdf_brickable_type"],
  pdfBrickableUuid: PdfBrick["pdf_brickable_uuid"],
  position: Record<string, number>,
  page: number,
) => {
  isLoadingCreatePdfBrick.value = true

  // Do nothing when the pdfBrick is a signatureBlock
  // and there is already an existing pdfBrick for this signatureBlock
  if (pdfBrickableType === "App\\Models\\SignatureBlock" && pdfBricks.value?.find((el) => el.pdf_brickable_type === "App\\Models\\SignatureBlock" && el.pdf_brickable_uuid === pdfBrickableUuid)) {
    notify(
      {
        title: t("pdfViewer.signatureBlockPlacedErrorTitle"),
        message: t("pdfViewer.signatureBlockPlacedErrorDescriptionExisting"),
        type: "error",
      },
    )
    // Force router reload (inertia)
    location.reload()
    return
  }

  try {
    const payload = {
      pdf_brickable_type: pdfBrickableType,
      page: page,
      x: Math.round(position.pdfX),
      y: Math.round(position.pdfY),
    }

    // Set the correct reference uuid
    if (pdfBrickableType === "App\\Models\\DynamicField") {
      payload["dynamic_field_ref_uuid"] = pdfBrickableUuid
    } else if (pdfBrickableType === "App\\Models\\SignatureBlock") {
      payload["signature_block_uuid"] = pdfBrickableUuid
    }

    const createdPdfBrick = await pdfBrickStore.createPdfBrick(documentUuid, payload)

    positionPdfBricksInDom(false)
    return createdPdfBrick
  } catch (err) {
    notify(
      {
        title: t("pdfViewer.savePdfBrickError"),
        message: err.response?.data?.message || err.message,
        type: "error",
      },
    )
  } finally {
    isLoadingCreatePdfBrick.value = false
  }
}

const getPartyByUuid = (uuid: Party["uuid"]) => {
  return parties.value?.find((el) => el.uuid === uuid)
}

const getSignatureBlockByUuid = (uuid: SignatureBlock["uuid"]) => {
  return signatureBlocks.value?.find((el: SignatureBlock) => el.uuid === uuid && !el.deleted_at)
}

const getSignatories = (partyUuid: Party["uuid"]): DocumentUser[] | null => {
  const filteredUsers = users.value?.filter(
    (el) => el.party_uuid === partyUuid && el.roles?.includes(DocumentUserRoleEnum.signatory),
  )
  const orderedUsers = sortBy(filteredUsers, "signing_order")
  return orderedUsers
}

interface PdfBrickToShow extends PdfBrick {
  dynamicField?: DynamicField
  party?: Party
  signatureBlock?: SignatureBlock
}

const pdfBricksToShow = computed<PdfBrickToShow[]>(
  () => {
    const mappedPdfBricks = pdfBricks.value?.map(
      (pdfBrick) => {
        const signatureBlock = getSignatureBlockByUuid(pdfBrick.pdf_brickable_uuid)
        if (signatureBlock?.deleted_at || !signatureBlock) return
        const party = getPartyByUuid(signatureBlock.party_uuid)
        const signatories = getSignatories(party?.uuid)
        let isFullySigned = false
        if (signatories?.length) {
          isFullySigned = !!(signatures.value?.filter((el: Signature) => el.signing_phase_uuid === props.signingPhase?.uuid && el.signature_block_uuid === signatureBlock.uuid)?.length === signatories?.length)
        }
        return {
          ...pdfBrick,
          signatureBlock: signatureBlock,
          party: party,
          isFullySigned: isFullySigned,
        }
      },
    )
    const filteredMappedPdfBricks = mappedPdfBricks.filter(
      (pdfBrickToShow) => {
        if (!pdfBrickToShow) return
        // Check if document uuid is correct
        if (pdfBrickToShow.document_uuid !== currentDocument.value?.uuid) return
        // Of course, we need a party assigned
        const hasParty = Boolean(pdfBrickToShow.party)
        // Without an active signingPhase, we want to show all bricks without a signing phase
        const signingNotActive = !pdfBrickToShow.signing_phase_uuid && !props.signingPhase?.is_active
        // We want to show all bricks that are in the same signing phase
        const matchingSigningPhase = pdfBrickToShow.signing_phase_uuid === props.signingPhase?.uuid
        // Confirm brickableType is correct
        const matchingBrickableType = pdfBrickToShow.pdf_brickable_type === "App\\Models\\SignatureBlock"
        // Fully signed means every signature was rendered into the PDF anyway
        const notFullySigned = !pdfBrickToShow.isFullySigned
        return hasParty && matchingBrickableType && notFullySigned && (signingNotActive || matchingSigningPhase)
      },
    )
    return filteredMappedPdfBricks
  },
)

const movingPdfBrick = ref<PdfBrick>(null)

const onBrickDragStart = (
  ev: DragEvent,
  pdfBrick: PdfBrickToShow,
) => {
  if (props.disabled) return
  if (isLockedDocument.value) return
  movingPdfBrick.value = pdfBrick

  const isDynamicField = pdfBrick.pdf_brickable_type === "App\\Models\\DynamicField"
  const isSignatureBlock = pdfBrick.pdf_brickable_type === "App\\Models\\SignatureBlock"

  const elementId = isDynamicField
    ? `draggableDynamicField_${pdfBrick.dynamicField.ref_uuid}`
    : `draggableSignatureBlock_${pdfBrick.signatureBlock.ref_uuid}`

  const data = {
    documentUuid: currentDocument.value?.uuid,
    pdfBrickableUuid: pdfBrick.pdf_brickable_uuid,
    pdfBrickableType: pdfBrick.pdf_brickable_type,
    elementId,
    offsetX: ev.offsetX,
    offsetY: ev.offsetY,
    uuid: pdfBrick.pdf_brickable_uuid,
    isDynamicField: isDynamicField,
    isSignatureBlock: isSignatureBlock,
    pdfBrick,
  }

  ev?.dataTransfer?.setData("text/plain", JSON.stringify(data))
}

const addDraggableToParent = (ev: MouseEvent) => {
  if (props.disabled) return
  const handle = ev.currentTarget as HTMLDivElement
  (handle?.parentNode as HTMLDivElement)?.setAttribute("draggable", "true")
}

const removeDraggable = (ev: MouseEvent) => {
  const draggable = ev.currentTarget as HTMLDivElement
  draggable?.removeAttribute("draggable")
}

const removePdfBrick = (pdfBrick: PdfBrick) => {
  if (props.disabled) return
  if (isLockedDocument.value) return
  pdfBrickStore.removePdfBrick(currentDocument.value?.uuid, pdfBrick.uuid)
}

const localSrc = computed<string>(() => props.src)

const handlePdfLoadError = (value: Error) => {
  console.error(value)
}

const toggleSignatureBlockStyle = async (signatureBlock: SignatureBlock) => {
  const currentStyle = signatureBlock?.style
  const newStyle = currentStyle === SignatureBlockStyle.minimal ? SignatureBlockStyle.regular : SignatureBlockStyle.minimal
  await updateSignatureBlock(CrudContext.document, currentDocument.value?.uuid, signatureBlock, newStyle)
  isActiveHiddenPdfBricks.value = false
}

const isMounted = ref<boolean>(false)

watch(
  () => pdfBricks,
  async () => {
    await nextTick()
    positionPdfBricksInDom(false)
  },
  { deep: true },
)

watch(
  () => localSrc,
  () => {
    isMountedPdfViewer.value = false
    isRenderedPdfViewer.value = false
    pageDimensions.value = []
  },
)

onMounted(() => {
  if (pdfViewerTimeout.value) clearTimeout(pdfViewerTimeout.value)
  setPdfViewerTimeout(setTimeout(() => {
    isMounted.value = true
    window.addEventListener("resize", throttle(() => positionPdfBricksInDom(true), 100))
  }, 300))
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", throttle(() => positionPdfBricksInDom(true), 100))
  window.onresize = null
  if (pdfViewerTimeout.value) clearTimeout(pdfViewerTimeout.value)
})

const hoverActive = ref<boolean>(false)
const hoverTimeout = ref<NodeJS.Timeout>(null)

const setHoverActive = () => {
  hoverActive.value = true
  if (hoverTimeout.value) clearTimeout(hoverTimeout.value)
}

const delayHoverInactive = () => {
  hoverTimeout.value = setTimeout(() => hoverActive.value = false, 300)
}

interface handlePasswordRequestObject {
  callback: (value: string) => void
  isWrongPassword: boolean
}

const handlePasswordRequest = ({ callback, isWrongPassword }: handlePasswordRequestObject) => {
  callback(prompt(isWrongPassword ?
    t("pdfViewer.wrongPassword") :
    t("pdfViewer.enterPassword"),
  ))
}

</script>

<template>
  <div :id="`${props.id || 'pdfViewerComponent'}_${props.componentKey}`">
    <template v-if="isMountedPdfViewer">
      <article
        v-for="pdfBrick in pdfBricksToShow"
        :id="`pdfBrickDomNode_${ pdfBrick.uuid }`"
        :key="pdfBrick.uuid"
        :data-party-button="pdfBrick.party?.ref_uuid"
        data-placement="top"
        :data-template="'partyForm_' + pdfBrick.party?.ref_uuid"
        :data-ref-uuid="pdfBrick.signatureBlock?.ref_uuid"
        :class="[
          `pdf-brick-dom-node_${ pdfBrick.uuid }`,
          pdfBrick.signatureBlock?.ref_uuid ? 'signature-block' : '',
          pdfBrick.signatureBlock?.uuid && (!checkIfDisabledByPusher('editPartyButton_' + pdfBrick.party?.ref_uuid) && mdu?.permissions?.includes('party_update') && !isLockedDocument) ? 'edit-party-button cursor-pointer' : '',
          pdfBrick.signatureBlock?.style === SignatureBlockStyle.minimal ? 'signature-block-minimal' : 'signature-block-regular',
          hoverActive ? 'hovered' : '',
          isActiveHiddenPdfBricks ? 'hidden' : '',
        ]"
        class="absolute z-10 mx-auto leading-snug border border-gray-500 hover:z-20 group/pdf-brick"
        @mouseenter="setHoverActive"
        @mouseleave="delayHoverInactive"
        @dragstart="onBrickDragStart($event, pdfBrick)"
        @dragend="removeDraggable"
      >
        <div
          v-if="!isLockedDocument"
          class="absolute p-1 text-indigo-600 transition-all duration-200 scale-0 bg-indigo-100 rounded-full shadow-xl opacity-0 cursor-move hover:bg-indigo-200 hover:text-indigo-700 group-hover/pdf-brick:scale-100 group-hover/pdf-brick:opacity-100 -left-7"
          :class="hoverActive ? 'scale-100 opacity-100' : ''"
          @mousedown="addDraggableToParent"
        >
          <DragIndicatorIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </div>

        <span
          v-if="uuidOfRemovingPdfBrick === pdfBrick.uuid && !isLockedDocument"
          class="absolute pl-2 text-gray-400 -right-6 top-2"
        >
          <SpinLoader
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </span>
        <span
          v-else-if="!isLockedDocument"
          :class="hoverActive ? 'scale-100 opacity-100' : ''"
          class="absolute p-1 text-red-600 transition-all duration-200 scale-0 bg-red-100 rounded-full shadow-xl opacity-0 cursor-pointer hover:bg-red-200 -right-7 group-hover/pdf-brick:scale-100 group-hover/pdf-brick:opacity-100 hover:text-red-700 top-7"
        >
          <TrashIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
            @click.prevent="removePdfBrick(pdfBrick)"
          />
        </span>

        <span
          v-if="!isLockedDocument"
          :class="hoverActive ? 'scale-100 opacity-100' : ''"
          class="absolute p-1 text-indigo-600 transition-all duration-200 scale-0 bg-indigo-100 rounded-full shadow-xl opacity-0 cursor-pointer hover:bg-indigo-200 hover:text-indigo-700 group-hover/pdf-brick:scale-100 group-hover/pdf-brick:opacity-100 -right-7"
        >
          <SpinLoader
            v-if="uuidsOfIsUpdatingSignatureBlock.includes(pdfBrick.signatureBlock?.uuid)"
            class="w-4 h-4 shrink-0"
          />
          <MinimalSignatureBlockIcon
            v-else-if="pdfBrick.signatureBlock?.style === SignatureBlockStyle.regular"
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
            @click.stop.prevent="toggleSignatureBlockStyle(pdfBrick.signatureBlock)"
          />
          <DefaultSignatureBlockIcon
            v-else
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
            @click.stop.prevent="toggleSignatureBlockStyle(pdfBrick.signatureBlock)"
          />
        </span>
        <template v-if="getSignatories(pdfBrick.party?.uuid).length">
          <SignatureSignatoryDisplay
            v-for="signatory in getSignatories(pdfBrick.party?.uuid)"
            :key="signatory.uuid"
            :signatory="signatory"
            :signature="checkSignature(signatory.uuid, pdfBrick.pdf_brickable_uuid)"
            :class="checkSignature(signatory.uuid, pdfBrick.pdf_brickable_uuid) ? 'opacity-0' : 'opacity-100'"
            :party-uuid="signatory.party_uuid"
            :is-editor-node="isLockedDocument ? true : false"
            :signature-block="pdfBrick.signatureBlock"
          />
        </template>
        <SignatureSignatoryDisplay
          v-else
          :party-uuid="pdfBrick.party?.uuid"
          :signature-block="pdfBrick.signatureBlock"
        />
      </article>
    </template>
    <div
      v-if="!isMountedPdfViewer || !isMounted || !isRenderedPdfViewer"
      class="absolute inset-0 flex flex-col items-center justify-center pointer-events-none"
    >
      <SpinLoader class="w-5 h-5 mx-auto" />
      <div class="mx-auto mt-2 text-sm font-medium text-center text-gray-500">
        {{ $t("pdfViewer.loading") }}…
      </div>
    </div>

    <div
      class="max-w-4xl py-6 mx-auto"
      :class="[
        isLoadingCreatePdfBrick ? 'opacity-50' : '',
        !isMountedPdfViewer || !isMounted || !isRenderedPdfViewer ? 'opacity-0 absolute' : '',
      ]"
    >
      <VuePdfEmbed
        v-if="isMounted"
        :key="cyrb53(localSrc)"
        ref="pdfViewer"
        :source="localSrc"
        :annotation-layer="true"
        :text-layer="true"
        :width="896"
        :scale="4"
        @loaded="onPdfLoaded"
        @rendered="onPdfViewerReady"
        @loading-failed="handlePdfLoadError"
        @password-requested="handlePasswordRequest"
      />
    </div>
  </div>
</template>

<style lang="scss">
.vue-pdf-embed {
  & > div {
    @apply mb-4 shadow-lg;

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  .mark-allow-drop {
    @apply ring-2 ring-offset-2 ring-indigo-700;
  }
}

.drop {
  &-active {
    @apply ring-2 ring-indigo-200;
  }

  &-target {
    @apply ring-2 ring-yellow-200;
  }
}
</style>
