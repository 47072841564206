<script setup lang="ts">
// external
import { EllipsisHorizontalIcon, TrashIcon, ExclamationTriangleIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"
import { MenuButton } from "@headlessui/vue"
import { LinkIcon } from "@heroicons/vue/20/solid"
import { storeToRefs } from "pinia"

// internal
import { ConditionIcon } from "~/icons"
import { useConditionStore, useDocumentStore, useEditorStore, useSharedStore, useTemplateStore } from "~/stores"
import { Condition, CrudContext, DocumentContentType } from "~/types"
import { parseRulesFromSymfonyExpression } from "~/utils"
import { ConditionRule, Dropdown, DropdownLink, SpinLoader } from "~/components"
import { onClickOutside } from "@vueuse/core"
import { ref } from "vue"

interface Props {
  condition: Condition
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const emit = defineEmits([ "update:conditions", "update:condition" ])

const conditionStore = useConditionStore()
const { conditionUuidBeingRemoved, uuidsOfUpdatingCondition, uuidOfLastHighlightedCondition } = storeToRefs(conditionStore)
const { jumpToCondition, removeConditionFromEntity } = conditionStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const templateStore = useTemplateStore()
const { currentTemplate } = storeToRefs(templateStore)

const documentStore = useDocumentStore()
const { currentDocument, htmlContent } = storeToRefs(documentStore)

const editorStore = useEditorStore()
const { checkIfUuidExistsInEditor } = editorStore

const condition = computed({
  get: () => props.condition,
  set: (value) => emit("update:condition", value),
})

const rules = computed(() => {
  return parseRulesFromSymfonyExpression(condition.value?.expression)
})

const filteredReferences = computed(() => {
  return condition.value?.uuids?.filter((uuid) => {
    if (crudContext.value === CrudContext.document && currentDocument.value?.content_type === DocumentContentType.html) {
      return htmlContent.value?.includes(uuid)
    }
    return checkIfUuidExistsInEditor(uuid)
  })
})

const isLoadingCondition = computed<boolean>(() => uuidsOfUpdatingCondition.value.includes(condition.value?.uuid))

const removeHighlight = (e: PointerEvent) => {
  if (uuidOfLastHighlightedCondition.value !== condition.value?.uuids[0]) return
  const popover = document.getElementById(`conditionPopover`)
  const nodes = document.querySelectorAll(`[data-dynamic-field-node="${condition.value?.uuids[0]}"]`)
  const nodeArray = Array.from(nodes)
  const allElements = [ ...nodeArray, popover ]
  const isInside = allElements.some((element) => element?.contains(e.target as Node) || element === e.target)
  if (isInside) return
  jumpToCondition(null)
}

const conditionInputWrapper = ref(null)

onClickOutside(conditionInputWrapper, removeHighlight)

</script>

<template>
  <div
    :id="'condition_input_'+condition.uuid"
    ref="conditionInputWrapper"
    class="flex flex-col py-4 transition-colors condition-input_wrapper hover:cursor-pointer"
    @click.prevent="jumpToCondition(condition.uuid, true)"
  >
    <div>
      <div class="flex items-center w-full px-6 font-medium text-teal-600">
        <ConditionIcon class="w-4 h-4 mr-1 shrink-0" />
        <div class="text-sm font-semibold grow">
          {{ condition.name }}
        </div>
        <span
          v-if="crudContext === CrudContext.document"
          :class="condition.is_active ? 'text-teal-800' : 'text-gray-500'"
          class="flex items-center ml-2 px-2 py-0.5 rounded-md text-sm font-medium shrink-0"
        >
          <svg
            :class="condition.is_active ? 'text-teal-500' : 'text-gray-500'"
            class="mr-1.5 h-2 w-2"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle
              cx="4"
              cy="4"
              r="3"
            />
          </svg>
          {{ condition.is_active ? $t('conditions.active') : $t('conditions.inactive') }}
        </span>

        <Dropdown
          v-else
          menu-classes="relative z-30"
          width="w-fit"
          align="right"
          @click.stop.prevent="true"
        >
          <template #trigger>
            <MenuButton
              :disabled="conditionUuidBeingRemoved === condition.uuid"
              class="text-gray-400 hover:text-gray-700"
            >
              <template
                v-if="conditionUuidBeingRemoved !== condition.uuid"
              >
                <EllipsisHorizontalIcon
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
              </template>
              <template
                v-else
              >
                <SpinLoader
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
              </template>
            </MenuButton>
          </template>

          <template #content>
            <DropdownLink
              ref="removeButton"
              as="button"
              @click="removeConditionFromEntity(currentTemplate?.uuid, condition.uuid)"
            >
              <div
                class="flex items-center gap-2"
                :class="conditionUuidBeingRemoved === condition.uuid ? 'opacity-50' : ''"
              >
                <TrashIcon
                  class="flex-none w-4 h-4 text-gray-500"
                  aria-hidden="true"
                />
                {{ $t('common.remove') }}
              </div>
            </DropdownLink>
          </template>
        </Dropdown>
      </div>
      <div class="px-4">
        <div
          v-for="rule, ruleIdx in rules"
          :key="ruleIdx"
        >
          <ConditionRule
            :condition-uuid="condition.uuid"
            :rule="rule"
            :rule-idx="ruleIdx"
            :is-loading-condition="isLoadingCondition"
            :read-only="true"
            :style="'gray'"
          />
        </div>
      </div>
      <div
        v-if="filteredReferences?.length"
        class="flex items-center px-3 py-2 mx-5 text-xs text-indigo-500 border border-t-0 border-indigo-200 bg-indigo-50 rounded-b-md"
      >
        <LinkIcon class="w-3 h-3 mr-1" />
        {{ $t('conditions.references', filteredReferences.length) }}
      </div>
      <div
        v-else
        class="flex items-center px-3 py-2 mx-5 text-xs text-black border border-t-0 border-red-200 bg-red-50 rounded-b-md"
      >
        <ExclamationTriangleIcon class="w-3 h-3 mr-1 text-red-500" />
        {{ $t('conditions.noReferences') }}
      </div>
    </div>
  </div>
</template>
