<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { watch } from "vue"
import { computed } from "vue"

// internal
import { useDocumentStore, usePartyStore, useSharedStore, useSignatureStore, useTemplateStore, useUserStore } from "~/stores"
import { CrudContext, DocumentStage, DocumentUser, DocumentUserRoleEnum, Party, PreemptiveSignature, Signature, SignatureBlock, SignatureBlockStyle } from "~/types"
import { formatDateAndTimeForSignatureDisplay, getUserRepresentation } from "~/utils"
import { SignatureBlockLayoutMinimal } from "~/components"

interface Props {
  signatureBlock?: SignatureBlock
  signatory?: DocumentUser
  signature?: Signature | PreemptiveSignature
  isEditorNode?: boolean
  partyUuid?: Party["uuid"]
  style?: SignatureBlockStyle
}

const props = withDefaults(
  defineProps<Props>(),
  {
    signatureBlock: null,
    signatory: null,
    signature: null,
    isEditorNode: false,
    partyUuid: null,
    style: SignatureBlockStyle.regular,
  },
)

const signatureStore = useSignatureStore()
const { activeSigningPhase, signatureBlocks } = storeToRefs(signatureStore)

const partyStore = usePartyStore()
const { parties } = storeToRefs(partyStore)

const userStore = useUserStore()
const { users } = storeToRefs(userStore)

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const documentStore = useDocumentStore()
const templateStore = useTemplateStore()

const documentLocale = computed(() => {
  const document = crudContext.value === CrudContext.document ? documentStore.currentDocument : templateStore.currentTemplate
  return document?.locale || "en"
})

import { useI18n } from "vue-i18n"

import en from "~/locales/signatures/en.json"
import de from "~/locales/signatures/de.json"

const locales = { en, de }

const i18nInstance = useI18n({
  locale: documentLocale.value,
  fallbackLocale: "en",
  messages: locales,
  useScope: "local",
  inheritLocale: false,
})

const { t, locale } = i18nInstance

const signatureBlock = computed<SignatureBlock>(() => {
  if (props.signatureBlock) return props.signatureBlock
  const signatureBlockFromUuid = signatureBlocks.value?.find((el: SignatureBlock) => el.uuid === props.signature?.signature_block_uuid)
  if (signatureBlockFromUuid) return signatureBlockFromUuid
  return {
    style: props.style || SignatureBlockStyle.regular,
  }
})

watch(
  () => documentLocale.value,
  () => {
    locale.value = documentLocale.value as "en" | "de"
  },
)

const signatoryParty = computed<Party>(() => parties.value?.find((el) => el.uuid === props.partyUuid))

const signatories = computed<DocumentUser[]>(
  () => {
    return users.value?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory))
  },
)

const signatoryName = computed<string>(() => {
  if (props.signature && "signatory_name" in props.signature) {
    return props.signature.signatory_name
  }
  return getUserRepresentation(props.signatory)
})

const getSignatureInfoRepresentation = (signature: Signature) => {
  let string = ""
  if (signature?.signatory_email) {
    const maskedEmail = "****" + signature.signatory_email.substring(signature.signatory_email.indexOf("@"))
    string += maskedEmail
  }
  if (signature?.signatory_email && signature?.signatory_mobile_phone) {
    string += " / "
  }
  if (signature?.signatory_mobile_phone) {
    const maskedPhoneNumber = signature.signatory_mobile_phone.slice(0, 4) + "****" + signature.signatory_mobile_phone.slice(-4)
    string += maskedPhoneNumber
  }
  return string
}

// Compute the position of the signatory in the signing order, use signing_order as indicator to count
const signatoryPosition = computed<number>(() => {
  if (signatories.value && props.signatory) {
    const signatory = props.signatory
    const signatoryIndex = signatories.value?.findIndex((el) => el.uuid === signatory.uuid)
    const signatoryOrder = signatory.signing_order
    if (signatoryOrder) {
      return signatories.value?.filter((el) => el.signing_order && el.signing_order < signatoryOrder)?.length + 1
    }
    return signatoryIndex + 1
  }
  return 0
})

</script>
<template>
  <component
    :is="!isEditorNode ? 'button' : 'div'"
    v-if="signatory"
    :key="signatory.uuid"
    type="button"
    :class="[
      signature ? 'signed' : '',
      !isEditorNode ? 'focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2 flex-col items-stretch' : '' ,
      signatureBlock?.style === SignatureBlockStyle.minimal ? 'signature-minimal' : 'signature-regular'
    ]"
    class="signature"
    :data-sign-here-label="t('signatures.clickToSign')"
    :data-signatory-uuid="signatory.uuid"
  >
    <template v-if="documentStore?.currentDocument?.stage === DocumentStage.signing && signatory.signing_order && (activeSigningPhase?.sequential_signing || documentStore?.currentDocument?.sequential_signing)">
      <span class="flex items-center justify-center absolute top-2 right-2 w-4 h-4 rounded-full text-[11px] font-medium bg-indigo-500 text-white ring-2 ring-yellow-50 z-20">
        {{ signatoryPosition }}
      </span>
    </template>
    <div
      v-if="signature"
      :class="signature.signature_seal?.type === 'draw' ? 'signature-image' : 'signature-typed'"
    >
      <div
        v-if="'secure_id' in signature && signatureBlock?.style === SignatureBlockStyle.regular"
        class="hash"
      >
        SecureID: {{ signature.secure_id }}
      </div>
      <div
        v-if="signature.signature_seal?.type === 'draw'"
        class="signature-image"
      >
        <img
          :src="signature.signature_seal?.signature_pad_drawing"
        >
      </div>
      <div
        v-else-if="signature.signature_seal?.type === 'photo'"
        class="signature-image"
      >
        <img
          :src="signature.signature_seal?.signature_pad_photo"
        >
      </div>
      <div
        v-else
        class="signature-text"
        :class="`font-['${signature.signature_seal?.signature_pad_font}']`"
        :style="`color: ${(signature.signature_seal?.signature_pad_color || '#000000')}`"
      >
        {{ signature.signature_seal?.signature_pad_text }}
      </div>

      <SignatureBlockLayoutMinimal
        v-if="signatureBlock?.style === SignatureBlockStyle.minimal"
      >
        <template #signatoryName>
          {{ signatoryName }}
        </template>
        <template #status>
          fynk.com [{{ 'signature_security_level' in signature ? signature.signature_security_level?.toUpperCase() : 'AES' }}] ·
          {{ (signature as Signature).secure_id ?? $t('signatures.preemptiveSignature') }}
        </template>
      </SignatureBlockLayoutMinimal>
    </div>
    <div
      v-else
      class="signature-pending"
    >
      <SignatureBlockLayoutMinimal
        v-if="signatureBlock?.style === SignatureBlockStyle.minimal"
      >
        <template #signatoryName>
          {{ signatoryName }}
        </template>
        <template #status>
          {{ t('signatures.pending') }}
        </template>
      </SignatureBlockLayoutMinimal>
      <div class="empty" />
    </div>
    <div
      v-if="signatureBlock?.style === SignatureBlockStyle.regular"
      class="signature-info"
    >
      <div class="block">
        <b :title="signatoryParty?.name">
          {{ signatoryParty?.name }}
        </b>
      </div>
      <div
        v-if="signatoryParty?.name !== signatoryParty?.entity_name"
        class="block"
      >
        <b :title="signatoryParty?.entity_name">
          {{ signatoryParty?.entity_name }}
        </b>
      </div>
      <div
        v-if="signatoryName !== signatoryParty?.name && signatoryName !== signatoryParty?.entity_name"
        class="block"
      >
        <b :title="signatoryName">
          {{ signatoryName }}
        </b>
      </div>
      <div
        class="block fine"
        style="margin-top: 2px"
        :title="signature && 'signatory_email' in signature ? getSignatureInfoRepresentation(signature) : ''"
      >
        <template
          v-if="signature && 'signatory_email' in signature"
        >
          {{ getSignatureInfoRepresentation(signature) }}
        </template>
        <div
          v-else-if="signature"
        >
          {{ t('signatures.preemptiveSignature') }}
        </div>
        <span
          v-else
        >
          {{ t('signatures.pendingLine1') }}
        </span>
      </div>
      <div
        class="block fine"
        :title="signature ? formatDateAndTimeForSignatureDisplay(signature.created_at) : ''"
      >
        <template v-if="signature">
          fynk.com [{{ 'signature_security_level' in signature ? signature.signature_security_level?.toUpperCase() : 'AES' }}] ·
          {{ formatDateAndTimeForSignatureDisplay(signature.created_at) }} UTC
        </template>
        <span
          v-else
        >
          {{ t('signatures.pendingLine2') }}
        </span>
      </div>
    </div>
  </component>
  <component
    :is="!isEditorNode ? 'button' : 'div'"
    v-else
    data-empty-message

    :class="[
      !isEditorNode ? 'focus:ring-2 focus:ring-yellow-200 focus:ring-offset-2 flex-col items-stretch' : '',
      signatureBlock?.style === SignatureBlockStyle.minimal ? 'signature-minimal' : 'signature-regular'
    ]"
    class="signature"
  >
    <div class="signature-pending">
      <SignatureBlockLayoutMinimal
        v-if="signatureBlock?.style === SignatureBlockStyle.minimal"
      >
        <template #signatoryName>
          {{ t('signatures.noSignatoryAssigned') }}
        </template>
        <template #status>
          {{ t('signatures.pending') }}
        </template>
      </SignatureBlockLayoutMinimal>
      <div class="empty" />
    </div>

    <div
      v-if="signatureBlock?.style === SignatureBlockStyle.regular"
      class="signature-info"
    >
      <div class="block">
        <b :title="signatoryParty?.name">
          {{ signatoryParty?.name }}
        </b>
      </div>
      <div
        v-if="signatoryParty?.name !== signatoryParty?.entity_name"
        class="block"
      >
        <b :title="signatoryParty?.entity_name">
          {{ signatoryParty?.entity_name }}
        </b>
      </div>
      <div
        class="block"
      >
        <span class="text-yellow-700">
          [ {{ t('signatures.noSignatoryAssigned') }} ]
        </span>
      </div>
      <div
        class="block fine"
        style="margin-top: 2px"
      >
        <span>
          {{ t('signatures.pendingLine1') }}
        </span>
      </div>
      <div class="block fine">
        <span>
          {{ t('signatures.pendingLine2') }}
        </span>
      </div>
    </div>
  </component>
</template>
