import { router } from "@inertiajs/vue3"
import { ref } from "vue"
import { useConfirmationStore, useNotificationStore } from "~/stores"
import { Import } from "~/types"

export function useImportActions () {
  const confirmationStore = useConfirmationStore()
  const { setShowConfirmModal, setConfirmOptions } = confirmationStore

  const notificationStore = useNotificationStore()
  const { addToasterNotification } = notificationStore

  const isSubmitting = ref(false)
  const localImportUuid = ref("")

  const confirmImportDeletion = (importUuid: Import["uuid"]) => {
    localImportUuid.value = importUuid

    setConfirmOptions({
      title: "Confirm import archiving",
      description: "Are you sure you want to archive this import?",
      buttonText: "Archive import",
      callback: archiveImport,
    })
    setShowConfirmModal(true)
  }

  const confirmImportRestoration = (importUuid: Import["uuid"]) => {
    localImportUuid.value = importUuid

    setConfirmOptions({
      title: "Confirm import restoration",
      description: "Are you sure you want to restore this import?",
      buttonText: "Restore import",
      callback: restoreImport,
    })
    setShowConfirmModal(true)
  }

  const restoreImport = () => {
    if (!isSubmitting.value && localImportUuid.value) {
      isSubmitting.value = true

      router.post(route("imports.restore", localImportUuid.value),
        {},
        {
          onSuccess: () => {
            addToasterNotification({
              title: "Import restored",
              message: "Import was restored successfully",
              type: "success",
            })
          },
          onError: () => {
            addToasterNotification({
              title: "Error occured",
              message: "Import could not be restored, please try again",
              type: "error",
            })
          },
          onFinish: () => {
            isSubmitting.value = false
            localImportUuid.value = null
          },
        })
    }
  }

  const archiveImport = () => {
    if (!isSubmitting.value && localImportUuid.value) {
      isSubmitting.value = true

      router.delete(route("imports.destroy", {
        import: localImportUuid.value,
      }),
      {
        onSuccess: () => {
          addToasterNotification({
            title: "Import archived",
            message: "Import was archived successfully",
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: "Error occured",
            message: "Import could not be archived, please try again",
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
          localImportUuid.value = null
        },
      })
    }
  }

  return {
    isSubmitting,

    confirmImportDeletion,
    confirmImportRestoration,
    archiveImport,
    restoreImport,
  }
}
