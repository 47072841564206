import { VueRenderer } from "@tiptap/vue-3"
import tippy, { Instance } from "tippy.js"
import MentionList from "./MentionList.vue"

export const renderMentionSuggestions = (elementId: string) => {
  let component: VueRenderer
  let popup: Instance[]

  return {
    onStart: (props) => {
      component = new VueRenderer(MentionList, {
        props,
        editor: props.editor,
      })

      popup = tippy("#" + elementId, {
        getReferenceClientRect: props.clientRect,
        appendTo: (ref) => { return ref.closest("#proposalModal") || document.getElementById("proposalContainer") || document.getElementById("mainContentContainer") },
        animation: "scale",
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
      })
    },

    onUpdate (props) {
      component.updateProps(props)

      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      })
    },

    onKeyDown (props) {
      if (props.event.key !== "Escape") return component?.ref?.onKeyDown(props)

      popup[0].hide()

      return true
    },

    onExit () {
      const elementsToDestroy = popup?.length ? [ popup[0], component ] : [ component ]
      elementsToDestroy.forEach((i) => i?.destroy())
    },
  }
}
