<script setup lang="ts">
// external
import { router } from "@inertiajs/vue3"
import { Form, Field } from "vee-validate"
import { ref, watch, computed } from "vue"
import { PencilIcon } from "@heroicons/vue/24/solid"
import { useI18n } from "vue-i18n"

// internal
import { useNotificationStore } from "~/stores"
import { AccountUser, AccountUserInvitation } from "~/types"
import { ProfilePictureModal, SpinLoader, TeamList } from "~/components"

interface Props {
  accountUser: AccountUser
  invitation?: AccountUserInvitation
  hideTeams?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hideTeams: false,
})

const { t } = useI18n()

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const localAccountUser = ref({
  ...props.accountUser,
})

const isEditing = ref(!!props.invitation)
const isSubmitting = ref(false)
const showProfilePictureModal = ref(false)
const profilePictureBlob = ref(null)

const accountName = computed(() => props.invitation?.account.name || props.accountUser?.account_name)
const accountRole = computed(() => props.invitation?.roles[0] || props.accountUser?.roles[0])

const toggleEditMode = () => {
  localAccountUser.value = {
    ...props.accountUser,
  }
  isEditing.value = !isEditing.value
}

const setProfilePicture = (blob) => {
  profilePictureBlob.value = blob
  const urlCreator = window.URL || window.webkitURL
  const imageUrl = urlCreator.createObjectURL(blob)
  localAccountUser.value.profile_photo_url = imageUrl
  setProfilePictureModal(false)
}

const removeProfilePicture = () => {
  profilePictureBlob.value = null
  localAccountUser.value.profile_photo_url = null
  setProfilePictureModal(false)
}

const setProfilePictureModal = (show) => {
  showProfilePictureModal.value = show
}

const onSubmit = async (values) => {
  if (!isSubmitting.value && isEditing.value) {
    isSubmitting.value = true

    const apiRoute = !!props.invitation ? route("user-profile.account.join", props.invitation.uuid) : route("user-profile.update-account-user", props.accountUser.uuid)

    const payload = {
      ...values,
    }

    let vaporRes = null

    if (profilePictureBlob.value) {
      const file = new File([ profilePictureBlob.value ], "profilePic")
      try {
        vaporRes = await Vapor.store<{ uuid: string }>(file)
        payload.profile_photo_uuid = vaporRes.uuid
      } catch (e) {
        isSubmitting.value = false
        addToasterNotification({
          title: t("common.errorOccured"),
          message: t("profileSettings.accounts.pictureError"),
          type: "error",
        })
        return
      }
    }

    if (!localAccountUser.value.profile_photo_url) {
      payload.profile_photo_uuid = null
    }

    if (!!props.invitation) {
      router.post(apiRoute, payload, {
        onSuccess: () => {
          addToasterNotification({
            title: t("accountSettings.users.updateSuccessTitle"),
            message: t("accountSettings.users.updateSuccessDescription"),
            type: "success",
          })
          isEditing.value = false
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t("accountSettings.users.updateError"),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
        },
      })
    } else {
      router.patch(apiRoute, payload, {
        onSuccess: () => {
          addToasterNotification({
            title: t("accountSettings.users.updateSuccessTitle"),
            message: t("accountSettings.users.updateSuccessDescription"),
            type: "success",
          })
          isEditing.value = false
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t("accountSettings.users.updateError"),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
        },
      })
    }


  }
}

watch(isEditing, (newVal) => {
  if (!newVal) {
    setProfilePictureModal(false)
    profilePictureBlob.value = null
  }
})

</script>

<template>
  <Form
    v-slot="{ errors }"
    class="content-card"
    @submit="onSubmit"
  >
    <div class="content-card-header flex justify-between px-5 py-2.5">
      <div class="flex items-center space-x-5">
        <h3 class="font-medium">
          {{ accountName }}
        </h3>
        <div
          v-if="accountRole?.display_name"
          class="capitalize text-xs bg-indigo-200 rounded-full px-2 py-0.5 text-indigo-700"
        >
          {{ accountRole.display_name }}
        </div>
      </div>

      <button
        v-if="!isEditing"
        class="-my-2 -mr-3 btn-header hover:bg-gray-900/5"
        type="button"
        @click="toggleEditMode"
      >
        <PencilIcon
          aria-hidden="true"
          class="w-4 h-4 shrink-0"
        />
      </button>
    </div>
    <div class="p-5">
      <div class="flex items-center space-x-3">
        <div class="relative overflow-hidden rounded-full shrink-0 group">
          <div
            v-if="!localAccountUser.profile_photo_url"
            class="flex items-center justify-center w-16 h-16 bg-indigo-200 rounded-full shrink-0 sm:w-24 sm:h-24"
          >
            <span class="text-2xl font-medium text-indigo-700 sm:text-4xl">{{ accountUser.first_name[0] }}{{ accountUser.last_name[0] }}</span>
          </div>
          <img
            v-else
            class="w-16 h-16 bg-gray-300 rounded-full shrink-0 sm:w-24 sm:h-24"
            :src="localAccountUser.profile_photo_url"
            :alt="`${accountUser.first_name} ${accountUser.last_name}`"
          >
          <button
            v-if="isEditing"
            type="button"
            class="absolute inset-0 flex items-center justify-center transition-opacity duration-150 opacity-0 group-hover:opacity-100 bg-gray-700/75"
            @click="setProfilePictureModal(true)"
          >
            <PencilIcon
              aria-hidden="true"
              class="w-8 h-8 text-white"
            />
          </button>
        </div>
        <div>
          <div
            v-if="isEditing"
            class="space-y-1 sm:space-y-0 sm:space-x-2 sm:flex"
          >
            <Field
              v-model="localAccountUser.first_name"
              name="first_name"
              type="text"
              :placeholder="$t('userForm.firstNamePlaceholder') + '…'"
              class="input-plain"
              :class="[errors.first_name ? 'error' : '']"
              rules="required"
            />
            <Field
              v-model="localAccountUser.last_name"
              name="last_name"
              type="text"
              :placeholder="$t('userForm.lastNamePlaceholder') + '…'"
              class="input-plain"
              :class="[errors.last_name ? 'error' : '']"
              rules="required"
            />
          </div>
          <h1
            v-else
            class="text-lg font-medium"
          >
            {{ accountUser.first_name }} {{ accountUser.last_name }}
          </h1>
          <div
            v-if="isEditing"
            class="mt-1"
          >
            <Field
              v-if="isEditing"
              v-model="localAccountUser.title"
              name="title"
              type="text"
              :placeholder="$t('userForm.titlePlaceholder') + '…'"
              class="input-plain"
            />
          </div>
          <div v-else>
            <span
              class="text-sm text-gray-500"
            >
              {{ accountUser.title }}
            </span>
          </div>
          <div
            v-if="!hideTeams"
            class="mt-2"
          >
            <TeamList
              :teams="accountUser?.teams"
              :clickable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEditing"
      class="content-card-footer"
    >
      <button
        v-if="!invitation"
        type="button"
        class="mr-2 btn-plain"
        @click="toggleEditMode"
      >
        {{ $t('common.cancel') }}
      </button>
      <button
        class="flex items-center space-x-2 btn-primary"
        type="submit"
        :disabled="isSubmitting"
      >
        <SpinLoader
          v-if="isSubmitting"
          class="w-5 h-5 shrink-0"
          aria-hidden="true"
        />
        <span>{{ !!invitation ? $t('common.join') : $t('common.save') }}</span>
      </button>
    </div>
    <ProfilePictureModal
      v-model:show="showProfilePictureModal"
      :account-user="accountUser"
      @save="setProfilePicture"
      @remove="removeProfilePicture"
    />
  </Form>
</template>
