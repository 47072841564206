<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed, ref, toRaw } from "vue"
import { InformationCircleIcon, SparklesIcon } from "@heroicons/vue/24/outline"
import { Link } from "@inertiajs/vue3"

// internal
import { AiAnalysisStatusDisplay, ContractTypeInput, FormInputErrors, OverlayScrollbar, SignatureSecurityLevelSelector, SpinLoader, TagList, TagSelector, TeamList, TeamSelector } from "~/components"
import { useAccountStore, useAiStore, useDocumentStore, useEditorStore, usePusherStore } from "~/stores"
import { Document, DocumentOrigin, DocumentStage, DocumentType, SignatureSecurityLevel, Tag, Team } from "~/types"
import { documentTypes } from "~/utils"

interface Props {
  teams: Team[]
  signatureSecurityLevel?: SignatureSecurityLevel
  stage: DocumentStage
  intro?: Document["intro"]
  keysOfIsUpdatingDocumentField?: (keyof Document)[]
  keysOfLastSavedDocumentField?: (keyof Document)[]
  errorsToShow?: Partial<Record<keyof Document, string[]>>
}
const props = withDefaults(defineProps<Props>(), {
  signatureSecurityLevel: null,
  contractType: null,
  intro: null,
  keysOfIsUpdatingDocumentField: () => [],
  keysOfLastSavedDocumentField: () => [],
  tags: () => [],
  errorsToShow: null,
})

const emit = defineEmits([ "focus", "blur", "update:signature-security-level", "update:contract-type", "update:intro", "update:review-message", "update:signing-message" ])

const documentStore = useDocumentStore()
const { mau, mdu, currentDocument, tags, allTags, allTeams, isUpdatingTags } = storeToRefs(documentStore)
const { addTag, removeTag, updateDocument } = documentStore

const editorStore = useEditorStore()
const { editor, isDirty } = storeToRefs(editorStore)

const accountStore = useAccountStore()
const { planFeatures, accountUsage } = storeToRefs(accountStore)

const pusherStore = usePusherStore()
const { checkIfDisabledByPusher } = pusherStore

const aiStore = useAiStore()
const { analysis, isLoadingGetAnalysis } = storeToRefs(aiStore)
const { createAiAnalysis, setShowAiAnalysisReviewModal } = aiStore

const localTeams = ref<Team[]>(null)

const selectedTeams = computed<Team[]>({
  get: () => {
    // Return localTeams.value if array
    if (Array.isArray(localTeams.value)) {
      return localTeams.value
    }
    return currentDocument.value.teams
  },
  set: (value) => {
    localTeams.value = value
  },
})

const signatureSecurityLevelSelected = computed<SignatureSecurityLevel>({
  get: () => props.signatureSecurityLevel,
  set: (val) => emit("update:signature-security-level", val),
})

const intro = computed<Document["intro"]>({
  get: () => props.intro,
  set: (val) => emit("update:intro", val),
})

// Helper to emit pusher relevant events
const handleFocus = (data: string) => emit("focus", data)
const handleBlur = (data: string) => emit("blur", data)

const selectedTag = computed({
  get: () => {
    return null
  },
  set: (tag) => {
    addTag(currentDocument.value.uuid, tag.uuid)
  },
})

const selectedTeam = computed({
  get: () => {
    return null
  },
  set: (team) => {
    toggleTeam(team)
  },
})

const disableAiAnalysisButton = computed<boolean>(() => {
  // Check if document origin is scratch or template, then check if editor textContent is empty
  const originCheck = [ DocumentOrigin.scratch, DocumentOrigin.template ].includes(currentDocument.value?.origin)
  if (!originCheck) return false
  const editorCheck = editor.value?.state?.doc?.textContent?.length < 300
  if (!editorCheck && !isDirty.value) return false
  return true
})

const remainingTagOptions = computed(() => {
  if (tags.value.length) {
    const selectedTagUuids = tags.value.map((tag: Tag) => tag.uuid)
    return allTags.value.filter((tag: Tag) => {
      return !selectedTagUuids.includes(tag.uuid)
    })
  }
  return allTags.value
})

const remainingTeamOptions = computed(() => {
  if (selectedTeams.value?.length) {
    const selectedTeamUuids = selectedTeams.value?.map((team: Team) => team.uuid)
    return allTeams.value?.filter((team: Team) => {
      return !selectedTeamUuids.includes(team.uuid)
    })
  }
  return allTeams.value
})

const removeTagFromDocument = (tag: Tag) => {
  removeTag(currentDocument.value.uuid, tag.uuid)
}

const toggleTeam = (team: Team) => {
  const copyOfSelectedTeams = toRaw(selectedTeams.value) || []
  const currentTeamUuids = copyOfSelectedTeams?.map((team) => team.uuid)
  const teamToToggleIndex = currentTeamUuids.indexOf(team.uuid)

  if (teamToToggleIndex !== -1) {
    currentTeamUuids.splice(teamToToggleIndex, 1)
  } else {
    currentTeamUuids.push(team.uuid)
  }

  updateDocument({
    uuid: currentDocument.value.uuid,
    team_uuids: currentTeamUuids.length ? currentTeamUuids : null,
  }, [ "team_uuids" ], Date.now())

  // Manipulate localTemplate so that teams are updated
  const newTeams = allTeams.value.filter((team: Team) => currentTeamUuids.includes(team.uuid))
  selectedTeams.value = newTeams || []
}

const getDocumentTypeInfo = (documentType: DocumentType) => documentTypes.find((el) => el.type === documentType)

const isAiFeatureLimitLocked = computed(() => accountUsage.value && planFeatures.value?.["ai-extraction"] !== 0 && accountUsage.value.aiAnalyses >= planFeatures.value?.["ai-extraction"])
</script>

<template>
  <div class="flex flex-col h-full max-h-full">
    <div class="flex items-center justify-between px-6 border-b shrink-0 border-b-gray-200">
      <h3 class="flex items-center gap-1 mt-6 mb-4 text-xs font-normal tracking-wider text-gray-500 uppercase">
        {{ $t('documentGeneralSettings.title') }}
      </h3>
    </div>
    <OverlayScrollbar
      class="flex-1 overflow-y-auto max-h-max"
    >
      <div class="border-b border-gray-200 divide-y divide-y-gray-200">
        <div class="flex items-center justify-between gap-2 px-6 py-2 text-sm">
          <label class="flex items-center gap-2 py-2 pr-3 text-gray-500 shrink-0">
            {{ $t('documentGeneralSettings.documentType') }}
          </label>
          <div
            class="relative flex items-center gap-2 pr-3 text-gray-900 grow max-w-fit"
            :class="stage === DocumentStage.signing || stage === DocumentStage.signed ? 'mr-2' : ''"
          >
            <component
              :is="getDocumentTypeInfo(currentDocument?.document_type || DocumentType.other)?.icon"
              class="w-4 h-4 shrink-0"
              aria-hidden="true"
            />
            <span
              class="block font-medium truncate"
            >
              {{ currentDocument?.document_type ? $t(`documentTypes.${currentDocument?.document_type}.name`) : $t(`documentTypes.${DocumentType.other}.name`) }}
            </span>
          </div>
        </div>

        <div>
          <ContractTypeInput />
        </div>

        <div v-if="currentDocument?.origin !== 'signed_pdf'">
          <SignatureSecurityLevelSelector
            v-model="signatureSecurityLevelSelected"
            :is-highlighted="keysOfLastSavedDocumentField?.includes('signature_security_level')"
            :is-loading="keysOfIsUpdatingDocumentField?.includes('signature_security_level')"
            :is-disabled="!!currentDocument?.template_signature_security_level || checkIfDisabledByPusher('documentSignatureSecurityLevelSelector') || stage === DocumentStage.signing || stage === DocumentStage.signed"
            :inline="true"
            @focus="handleFocus"
            @blur="handleBlur"
          />
          <FormInputErrors
            v-if="errorsToShow?.signature_security_level?.length"
            class="px-6 mb-2"
            :errors="errorsToShow?.signature_security_level"
          />
        </div>
        <div>
          <TeamSelector
            v-model="selectedTeam"
            :disabled="!mdu"
            :is-highlighted="keysOfLastSavedDocumentField?.includes('team_uuids')"
            :loading="keysOfIsUpdatingDocumentField?.includes('team_uuids')"
            :options="remainingTeamOptions"
            :selected-tags="tags"
            :mdu="mdu"
          >
            <TeamList
              v-if="selectedTeams?.length"
              class="px-6 pb-4 -mt-1"
              :teams="selectedTeams"
              :removable="!!mdu"
              @remove="toggleTeam"
            />
          </TeamSelector>
        </div>
        <div>
          <TagSelector
            v-model="selectedTag"
            :options="remainingTagOptions"
            :selected-tags="tags"
            :loading="isUpdatingTags"
            :disabled="!mdu"
            :mdu="mdu"
            :mau="mau"
          />
          <TagList
            v-if="tags?.length"
            class="px-6 mb-2 -mt-1"
            :tags="tags"
            :removable="!!mdu"
            @remove="removeTagFromDocument"
          />
        </div>
        <div>
          <div class="flex items-center justify-between px-6 py-2 space-x-2 text-sm">
            <div class="flex items-center gap-2 py-2 pr-3 text-gray-500 shrink-0">
              {{ $t('magicTable.columns.analysis') }}
            </div>
            <div>
              <component
                :is="mau?.permissions?.includes('account_manage') ? Link : 'div'"
                v-if="isAiFeatureLimitLocked"
                class="text-xs bg-reached text-white px-2 font-medium py-0.5 rounded-full"
                :href="mau?.permissions?.includes('account_manage') ? route('account-settings.billing.index') : undefined"
              >
                {{ $t('accountSettings.billing.upgradeRequired') }}
              </component>
              <AiAnalysisStatusDisplay
                v-else-if="analysis && mau"
                :ai-analysis="analysis"
                :hide-review="!mdu?.permissions?.includes('document_update')"
                :allow-restart="true"
                @review="setShowAiAnalysisReviewModal(true)"
                @restart="createAiAnalysis(currentDocument?.uuid)"
              />
              <button
                v-else-if="mdu?.permissions?.includes('document_update')"
                data-tippy-help
                :data-tippy-content="disableAiAnalysisButton ? $t('documentGeneralSettings.aiAnalysisDisabledHint') : ''"
                data-placement="top"
                class="inline-flex items-center gap-2 px-2 py-1 text-sm font-semibold text-purple-500 rounded-md ring-offset-2 focus:ring-2 focus:outline-none focus:ring-purple-200"
                :class="!!mdu && !disableAiAnalysisButton ? 'hover:text-purple-700 hover:bg-purple-50' : 'opacity-50 cursor-not-allowed focus:ring-opacity-0'"
                @click="!!mdu && !disableAiAnalysisButton ? createAiAnalysis(currentDocument?.uuid) : null"
              >
                <SpinLoader
                  v-if="isLoadingGetAnalysis"
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
                <SparklesIcon
                  v-else
                  class="w-4 h-4 shrink-0"
                  aria-hidden="true"
                />
                {{ $t('common.create') }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="px-6 py-2 text-sm transition-all duration-500"
          :class="keysOfLastSavedDocumentField.includes('intro') ? 'bg-teal-50' : ''"
        >
          <dt
            class="flex items-center justify-between gap-4 py-2 text-gray-500 grow"
          >
            <div class="flex items-center gap-1.5 truncate">
              <span
                class="truncate"
                :title="$t('documentGeneralSettings.intro')"
              >{{ $t('documentGeneralSettings.intro') }}</span>
              <SpinLoader
                v-if="keysOfIsUpdatingDocumentField.includes('intro')"
                class="w-3 h-3"
              />
              <span
                v-else-if="!!mdu"
                data-tippy-help
                :data-tippy-content="$t('documentGeneralSettings.introHint')"
                data-placement="bottom"
              >
                <InformationCircleIcon
                  class="w-3 h-3 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </div>
          </dt>
          <dd class="max-w-full mb-2">
            <div
              v-if="mdu && mdu?.permissions?.includes('document_update')"
              class="grow-textarea"
              :data-replicated-value="intro"
            >
              <textarea
                v-model="intro"
                :placeholder="$t('common.add') + '…'"
              />
            </div>
            <div v-else-if="intro">
              {{ intro }}
            </div>
            <div
              v-else
              class="text-gray-400"
            >
              {{ $t('common.notAvailable') }}
            </div>
            <div
              v-if="errorsToShow?.intro?.length"
              class="flex"
            >
              <FormInputErrors
                :errors="errorsToShow?.intro"
                align="right"
              />
            </div>
          </dd>
        </div>
      </div>
    </OverlayScrollbar>
  </div>
</template>
