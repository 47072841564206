<template>
  <div
    class="signature-block-minimal-border"
  />
  <div
    class="signature-block-minimal-info"
  >
    <div class="signature-block-minimal-top">
      <slot name="signatoryName" />
    </div>
    <div class="signature-block-minimal-bottom">
      <slot name="status" />
    </div>
  </div>
</template>
