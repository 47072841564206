import type { JSONContent, ChainedCommands, Editor } from "@tiptap/core"

const editorClass = "ProseMirror"

function eleIncludesTags (ele: HTMLElement, tags: string[]) {
  return tags.includes(ele.nodeName)
}
function eleIncludesClasses (ele: HTMLElement, classes: string[]) {
  if (!ele || !ele.classList) return false
  const classList = ele.classList
  return !!classes.find((classname) => classList?.contains(classname))
}
function getIndexInParentElement (ele: HTMLElement) {
  return Array.from(ele.parentElement?.childNodes || []).indexOf(ele)
}

interface EleDataType {
  uuid: string | undefined
  index: number
  isTable: boolean
  isLi: boolean
}
export type TStateNotify = (isEnable: boolean) => void

class FormatCopy {
  private _isCopying: boolean
  get isCopying (): boolean {
    return this._isCopying
  }
  private _cache: JSONContent | undefined
  get cacheData (): JSONContent | undefined {
    return this._cache
  }
  private _once: boolean
  private _selectionChangeCb

  public getChain?: () => ChainedCommands
  public stateNotifyList: TStateNotify[] = []

  constructor () {
    this._isCopying = false
    this._once = true
    this._cache = undefined
    this._selectionChangeCb = this.SelectionChange.bind(this)
  }

  private _getSelectionEle (): HTMLElement | null {
    const selection = window.getSelection()
    if (!selection || !selection.anchorNode) return null

    let selectEle = selection.anchorNode as HTMLElement
    while (
      selectEle !== document.body &&
      !selectEle.dataset?.uuid &&
      !selectEle.dataset?.listUuid
    ) {
      selectEle = selectEle.parentElement as HTMLElement
    }
    if (
      eleIncludesTags(selectEle, [ "UL", "OL", "TABLE", "TR", "CODE", "BODY" ]) ||
      eleIncludesClasses(selectEle, [ editorClass, "code-block" ])
    ) {
      return null
    }
    return selectEle
  }

  private _getSelectionMarks (editor: Editor) {

    /*     const selection = this.editor.view.state.selection
    this.editor.view.state.doc.nodesBetween(selection.from, selection.to, (node) => {
      if (node.type.name === "text" && node.marks.length > 0) {
        // do something with node.marks
      }
    }) */
    const { from, to } = editor.state.selection
    const { doc } = editor.state
    const marks = []
    doc.nodesBetween(from, to, (node) => {
      if (node.isText && node.marks.length > 0) {
        marks.push(node.marks)
      }
    })
    return marks
  }

  private _getSelectionEleDataUuid (anchorNode: HTMLElement): EleDataType | null {
    let selectEle = anchorNode
    let selectEleIndex = getIndexInParentElement(selectEle)
    let selectEleDataUuid = ""
    while (
      selectEle !== document.body &&
      !eleIncludesClasses(selectEle, [ editorClass ]) &&
      !eleIncludesTags(selectEle, [ "LI", "TH", "TD" ])
    ) {
      if (eleIncludesClasses(selectEle, [ "code-block" ])) return null

      if (selectEle.dataset?.uuid || selectEle.dataset?.listUuid) {
        selectEleDataUuid = selectEle.dataset.uuid || selectEle.dataset.listUuid
        break
      }
      selectEleIndex = getIndexInParentElement(selectEle)
      selectEle = selectEle.parentElement as HTMLElement
    }
    if (!selectEleDataUuid) return null
    selectEle = selectEle.parentElement as HTMLElement
    const isTable = eleIncludesTags(selectEle, [ "TH", "TD" ])
    const isLi = eleIncludesTags(selectEle, [ "LI" ])
    return { uuid: selectEleDataUuid, index: selectEleIndex, isTable, isLi }
  }

  Copy (once: boolean, getJSON: () => JSONContent, editor: Editor) {
    if (this._isCopying) return console.log("copying")

    const anchorNode = this._getSelectionEle()
    if (!anchorNode) return console.log("no selection element")

    const marks = this._getSelectionMarks(editor)
    if (!marks) return console.log("no marks")

    const selectEle = this._getSelectionEleDataUuid(anchorNode)
    if (!selectEle) return console.log("not available element", selectEle)

    const jsonList = getJSON()?.content || []
    let currJson = jsonList.find((item) => item?.attrs?.uuid === selectEle.uuid)
    // copy the style in the table
    if (!currJson && selectEle.isTable)
      currJson = this._findMarksInTable(jsonList, selectEle)
    // copy the style in ul, ol
    if (!currJson && selectEle.isLi)
      currJson = this._findMarksInLi(jsonList, selectEle)
    if (!currJson) return console.log("did not find marks", selectEle)

    /* const marks2 =
      currJson.content && currJson.content.length > selectEle.index
        ? currJson.content[selectEle.index].marks
        : currJson.marks */
    this._cache = { type: currJson.type, attrs: currJson.attrs, marks }
    console.log("cache marks:", this._cache)
    this._isCopying = true
    this._once = once
    this._listenSelectionChange()
    this._notifyState()
    document.body.querySelector(`.${editorClass}`)?.classList.add("formatPainting")
  }

  private _findMarksInTable (jsonList: JSONContent[], selectEle: EleDataType) {
    let currJson = undefined
    const tables = jsonList.filter((item) => item.type === "table")
    tables.find((item) =>
      item.content?.find((tr) =>
        tr.content?.find((td) =>
          td.content?.find((tdinn) => {
            if (tdinn?.attrs?.uuid === selectEle.uuid) {
              currJson = tdinn
              return true
            } else {
              return false
            }
          }),
        ),
      ),
    )
    return currJson
  }
  private _findMarksInLi (jsonList: JSONContent[], selectEle: EleDataType) {
    let currJson = undefined
    const uls = jsonList.filter((item) =>
      [ "bulletList", "orderedList" ].includes(item.type || ""),
    )
    uls.find((item) =>
      item.content?.find((li) =>
        li.content?.find((tdinn) => {
          if (tdinn?.attrs?.id === selectEle.uuid) {
            // console.log('-------------:', tdinn, item)
            currJson = tdinn
            return true
          } else {
            return false
          }
        }),
      ),
    )
    return currJson
  }

  SelectionChange () {
    if (!this.getChain) return this.Stop()
    let chain = this.getChain()
    chain = chain.focus().unsetAllMarks().clearNodes()
    // cmd.clearNodes()

    if (this._cache?.marks) {
      this._cache?.marks.forEach((mark) => {
        // If mark is also an array, we need to loop through it
        if (Array.isArray(mark)) {
          mark.forEach((m) => {
            chain = chain.setMark(m.type, m.attrs)
          })
          return
        } else {
          const { type, attrs } = mark
          chain = chain.setMark(type, attrs)
        }
      })
    }
    if (this._cache?.attrs) {
      const attrs = this._cache?.attrs
      if (attrs.level) {
        chain = chain.setHeading({ level: attrs.level })
        // console.log('Applied attribute: heading', attrs.level)
      }
      if (attrs.textAlign) {
        chain = chain.setTextAlign(attrs.textAlign)
        // console.log('Applied attribute： textAlign', attrs.textAlign)
      }
      chain.run()
      // Unprocessed formats
      // isActive('tableCell')
      // isActive('listItem')) {
      // isActive('bulletList', { class: opt })
      // isActive('bulletList')
      // isActive('orderedList', { class: opt })
      // isActive('orderedList')
    }

    if (this._once) this.Stop()
  }

  private _listenSelectionChange () {
    const options = this._once ? { once: true } : undefined
    document.body.addEventListener("mouseup", this._selectionChangeCb, options)
  }

  Stop () {
    console.log("formatPainter Stop")
    this._isCopying = false
    this._cache = undefined
    this._notifyState()
    document.body.querySelector(`.${editorClass}`)?.classList.remove("formatPainting")
    document.body.removeEventListener("mouseup", this._selectionChangeCb)
  }

  private _notifyState () {
    this.stateNotifyList.forEach((fn) => fn(this._isCopying))
  }
}

export default new FormatCopy()
