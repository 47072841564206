<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { Link } from "@inertiajs/vue3"

// internal
import { MagicTable, MagicTableEntry, MagicTableTimestamps } from "~/components"

interface Props {
  items: any[]
}

const props = withDefaults(defineProps<Props>(), {})

const ledgerColumns = [
  {
    key: "event",
    breakpoint: "@md",
    required: true,
    active: true,
  },
  {
    key: "changes",
    required: true,
    active: true,
  },
  {
    key: "modified_by",
    required: true,
    active: true,
  },
  {
    key: "created_at",
    required: true,
    active: true,
  },
]

const getDisplayValue = (value:string|string[]) => {
  if (Array.isArray(value)) {
    return value.join(", ")
  }
  return value
}

const filteredItems = computed(() => props.items.filter((item) => {
  return !(Array.isArray(item.changes) && item.changes.length === 0)
}))

const ledgerLimit = 5
const showAll = ref(false)
const hasMore = computed(() => filteredItems.value.length > ledgerLimit)

const itemsToShow = computed(() => {
  return showAll.value ? filteredItems.value : filteredItems.value.slice(0, ledgerLimit)
})

// we don't wanna show certain data to the user
const filterChanges = (changes) => {
  const tmpChanges = { ...changes }
  delete tmpChanges.account_id
  delete tmpChanges.user_id
  delete tmpChanges.uuid
  return tmpChanges
}
</script>

<template>
  <div>
    <MagicTable
      :items="itemsToShow"
      :columns="ledgerColumns"
    >
      <template #default="{ entry, activeColumns, isLoading }">
        <MagicTableEntry
          :columns="activeColumns"
          :loading="isLoading"
        >
          <template #event>
            <span class="text-xs font-mono bg-gray-100 rounded-md px-1 py-0.5">{{ $t('ledger.events.' + entry.event ) }}</span>
          </template>
          <template
            #changes
          >
            <div
              v-for="(changeValue, changeKey) in filterChanges(entry.changes)"
              :key="changeKey"
            >
              <span class="font-medium capitalize">{{ $t('formFields.' + changeKey) }}: </span>
              <span :class="changeKey.toString() === 'role' ? 'capitalize' : ''">{{ getDisplayValue(changeValue) }}</span>
            </div>
          </template>
          <template #modified_by>
            <Link
              v-if="entry.modifiedBy && !entry.modifiedBy.deleted_at"
              class="link-primary"
              :href="route('account-settings.account-users.show', entry.modifiedBy.uuid)"
            >
              {{ entry.modifiedBy.first_name + ' ' + entry.modifiedBy.last_name }}
            </Link>
            <span
              v-else-if="entry.modifiedBy"
              class="text-gray-400"
            >
              {{ entry.modifiedBy.first_name + ' ' + entry.modifiedBy.last_name }}
            </span>
            <span v-else>
              System
            </span>
          </template>
          <template #created_at>
            <MagicTableTimestamps
              :entry="entry"
            />
          </template>
        </MagicTableEntry>
      </template>
    </MagicTable>
    <div
      v-if="hasMore && !showAll"
      class="flex justify-end mt-2"
    >
      <button
        type="button"
        class="btn-white btn-sm"
        @click="showAll = true"
      >
        {{ $t('commandMenu.showAll') }}
      </button>
    </div>
  </div>
</template>
