<script setup lang="ts">
import { computed } from "vue"

import { SpinLoader } from "~/components"

interface Props {
  modelValue?: string | number | null
  loading?: boolean
  disabled?: boolean
  readonly?: boolean
  highlight?: boolean
  type?: string
  id?: string | undefined
  name?: string | undefined
  textClasses?: string | string[]
  placeholder?: string | undefined
  min?: number
  max?: number
  maxlength?: number
  wFit?: boolean
  stopPropagation?: boolean
  isMultiline?: boolean
}


const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: null,
    loading: false,
    disabled: false,
    readonly: false,
    highlight: false,
    type: "text",
    id: undefined,
    name: undefined,
    min: undefined,
    max: undefined,
    maxlength: 255,
    wFit: false,
    stopPropagation: false,
    isMultiline: false,
  },
)

const value = computed({
  get: () => props.modelValue,
  set: (newVal) => emit("update:model-value", newVal),
})


const emit = defineEmits([ "focus", "blur", "change", "input", "enter", "update:model-value" ])
</script>

<template>
  <div
    class="relative inline-flex items-center max-w-full truncate"
    :class="wFit ? 'w-fit' : 'w-full'"
  >
    <template v-if="!readonly">
      <input
        :id="id"
        v-model="value"
        :disabled="disabled"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :min="min"
        :max="max"
        :maxlength="type !== 'number' ? maxlength : undefined"
        class="placeholder-gray-400 absolute left-0 w-full hover:bg-gray-100 text-base sm:text-sm border-0 rounded-md bg-transparent focus:ring-0 focus:bg-gray-100 px-1 py-0.5 focus:outline-none disabled:bg-gray-50 disabled:text-gray-400 disabled:cursor-not-allowed"
        :class="[textClasses, {'bg-teal-50 focus:bg-teal-50 hover:bg-teal-50' : highlight}]"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        @keyup.enter="$emit('enter', $event)"
        @keypress.space="$event => {
          if (stopPropagation) $event.stopImmediatePropagation()
        }"
      >
      <span
        class="invisible max-w-full p-1 text-sm leading-relaxed truncate"
        :class="[isMultiline ? 'whitespace-pre' : '', textClasses]"
      >{{ value || placeholder }}</span>
    </template>
    <template v-else>
      <span
        class="p-1 text-base leading-relaxed truncate sm:text-sm"
        :class="textClasses"
      >
        {{ value || placeholder }}
      </span>
    </template>

    <SpinLoader
      v-if="loading"
      class="mx-2 h-3 w-3 absolute -right-6 top-1/2 -mt-1.5"
    />
  </div>
</template>
