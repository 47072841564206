<script setup lang="ts">
// external
import { computed, ref } from "vue"
import { router } from "@inertiajs/vue3"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { DocumentPlusIcon } from "@heroicons/vue/24/outline"

// internal
import { DashboardGraphWidget, EmptyState } from "~/components"
import { useStages } from "~/composables"
import defaultOptions from "../chartPresets"
import { useAccountStore } from "~/stores"

const { t } = useI18n()

const { stages } = useStages()

const stageLabels = computed(() => {
  return stages.value.map((stage) => t(`documents.stages.${stage?.name}`))
})

const stageColors = computed(() => {
  return stages.value.map((stage) => stage.colors.hex)
})

const setData = (widgetData) => {
  data.value = widgetData
}

const data = ref(null)

const series = computed(() => {
  if (!data.value) return []

  return stages.value.map((stage) => {
    let count = 0

    for (const dataEntry of data.value) {
      if (dataEntry.stage === stage.name) {
        count = dataEntry.count
      }
    }
    return count
  })
})

const handleDocumentsByStageGraphClick = ({ event }) => {
  const clickedStage = stages.value[event.target.parentElement.getAttribute("data:realIndex")]
  if (clickedStage) {
    router.visit(route("documents.index") + `?stages=${clickedStage.name}`)
  }
}

const accountStore = useAccountStore()
const { accountUsage } = storeToRefs(accountStore)

</script>

<template>
  <DashboardGraphWidget
    :title="$t('dashboard.widgets.documentsByStage')"
    endpoint="api.data.documents.byStage"
    type="donut"
    :series="series"
    :options="{
      ...defaultOptions.donut,
      labels: stageLabels,
      colors: stageColors,
    }"
    box-classes="p-2"
    @click="handleDocumentsByStageGraphClick"
    @loaded="setData"
  >
    <template #empty>
      <EmptyState
        v-if="!accountUsage.documents"
        :hide-button="true"
      >
        <template #icon>
          <DocumentPlusIcon
            aria-hidden="true"
          />
        </template>
        <template #call-to-action>
          {{ $t('documents.empty.description') }}
        </template>
        <template #default>
          {{ $t('documents.empty.title') }}
        </template>
      </EmptyState>
    </template>
  </DashboardGraphWidget>
</template>
