<script setup lang="ts">
// external
import { XMarkIcon } from "@heroicons/vue/24/solid"
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, ExclamationTriangleIcon } from "@heroicons/vue/24/outline"

// internal
import { Modal } from "~/components"
import { computed } from "vue"
import { ref } from "vue"

interface Props {
  show: boolean
  maxWidth?: string,
  closeable?: boolean
  modalClasses?: string
  id?: string
  padding?: boolean
  showHeader?: boolean
  showFooter?: boolean
  showCloseButton?: boolean
  showFullSizeButton?: boolean
  showIcon?: boolean
  mobileFullscreen?: boolean
  icon?: any
  forceVisibleSlot?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    id: "",
    show: false,
    maxWidth: "fit",
    modalClasses: "",
    closeable: true,
    padding: true,
    showHeader: true,
    showFooter: true,
    showIcon: false,
    showCloseButton: false,
    showFullSizeButton: false,
    mobileFullscreen: false,
    icon: null,
    forceVisibleSlot: false,
  },
)

const emit = defineEmits([ "close" ])

const isActiveFullSize = ref(false)

const icon = computed(() => {
  if (props.icon) {
    return props.icon
  }
  return ExclamationTriangleIcon
})

const close = () => emit("close")
</script>

<template>
  <Modal
    :id="props.id"
    :show="show"
    :extra-classes="modalClasses"
    :max-width="maxWidth"
    :fullsize="isActiveFullSize"
    :closeable="closeable"
    :mobile-fullscreen="mobileFullscreen"
    :force-visible-slot="forceVisibleSlot"
    @close="close"
  >
    <div
      :class="[padding ? 'px-6 py-4' : '', mobileFullscreen ? 'h-full lg:h-auto' : '', isActiveFullSize ? 'lg:flex-grow overflow-y-scroll' : '']"
      class="relative"
    >
      <div
        v-if="showCloseButton || showFullSizeButton"
        class="absolute z-10 flex items-center gap-2 top-2 right-2"
      >
        <button
          v-if="showFullSizeButton"
          type="button"
          class="hidden p-2 text-gray-400 bg-white rounded-full lg:block hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="isActiveFullSize = !isActiveFullSize"
        >
          <span class="sr-only">{{ $t('common.close') }}</span>
          <ArrowsPointingOutIcon
            v-if="!isActiveFullSize"
            class="w-5 h-5"
            aria-hidden="true"
          />
          <ArrowsPointingInIcon
            v-else
            class="w-5 h-5"
            aria-hidden="true"
          />
        </button>

        <button
          v-if="showCloseButton"
          data-cy-sel="modal-close-button"
          type="button"
          class="p-2 text-gray-400 bg-white rounded-full hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="close"
        >
          <span class="sr-only">{{ $t('common.close') }}</span>
          <XMarkIcon
            class="w-5 h-5"
            aria-hidden="true"
          />
        </button>
      </div>
      <div :class="[showIcon ? 'flex items-center gap-6' : '', mobileFullscreen ? 'h-full lg:h-auto' : '', isActiveFullSize ? 'h-full' : '']">
        <div
          v-if="showIcon"
          class="flex items-center justify-center w-12 h-12 mx-auto rounded-full shrink-0 sm:mx-0 sm:h-10 sm:w-10"
          :class="props.icon ? 'bg-indigo-100' : 'bg-red-100'"
        >
          <component
            :is="icon"
            class="w-6 h-6"
            :class="props.icon ? 'text-indigo-600' : 'text-red-600'"
            aria-hidden="true"
          />
        </div>
        <div :class="[ mobileFullscreen ? 'h-full lg:h-auto' : '', isActiveFullSize ? 'h-full flex flex-col' : '']">
          <div
            v-if="showHeader"
            class="text-lg font-medium text-gray-900"
            :class="showCloseButton ? 'pr-7' : ''"
          >
            <slot name="title" />
          </div>
          <div :class="[showIcon ? 'mt-2' : 'pt-4', isActiveFullSize ? 'flex-shrink max-h-[calc(100%-0.75rem)]' : '']">
            <slot
              name="content"
              :is-active-fullsize="isActiveFullSize"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showFooter"
      class="px-6 py-4 text-center bg-gray-100 rounded-b-md"
    >
      <slot name="footer" />
    </div>
  </Modal>
</template>
