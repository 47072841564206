<script lang="ts" setup>

// external
import { ref, computed, onMounted } from "vue"
import { storeToRefs } from "pinia"
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/vue/24/outline"
import { MinusIcon } from "@heroicons/vue/24/solid"
// internal
import { DialogModal, PdfViewer, SpinLoader } from "~/components"
import { Document, Template } from "~/types"
import { useFileStorageStore, useSharedStore } from "~/stores"
import { cyrb53 } from "~/utils"

interface Props {
  document?: Document
  template?: Template
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
  },
)

const fileStorageStore = useFileStorageStore()
const { uuidOfIsLoadingGetStoredFileUrl, activeStoredFileFilename, isViewableActiveStoredFile, storedFileUrl, activeStoredFileUuid } = storeToRefs(fileStorageStore)
const { handleShowStoredFile, fetchStoredFiles } = fileStorageStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(() => {
  return props?.[crudContext.value]?.uuid
})

const isPdfActiveStoredFile = computed<boolean>(
  () => {
    if (!activeStoredFileFilename.value) return false
    let extension = activeStoredFileFilename.value.split(".").pop()
    // Force lowercase
    extension = extension?.toLowerCase()
    return [ "pdf" ].includes(extension)
  },
)

const zoomHalf = ref<boolean>(false)

onMounted(async () => {
  fetchStoredFiles(crudContext.value, entityUuid.value)
})

</script>
<template>
  <!-- Dialog modal with PDF viewer for storedFiles -->
  <DialogModal
    v-if="!!(activeStoredFileUuid && isViewableActiveStoredFile)"
    :show="!!(activeStoredFileUuid && isViewableActiveStoredFile && !uuidOfIsLoadingGetStoredFileUrl)"
    :show-close-button="true"
    :show-footer="false"
    @close="activeStoredFileUuid = null"
  >
    <template #title>
      <div class="flex items-center gap-4">
        <span class="truncate">{{ activeStoredFileFilename }}</span>
        <button
          class="flex items-center gap-2 p-0 mr-4 text-indigo-700 btn-plain focus:ring-0 focus:ring-offset-0 focus:border-0 hover:text-indigo-900"
          @click.prevent="handleShowStoredFile(activeStoredFileUuid, true, crudContext, entityUuid)"
        >
          <ArrowDownTrayIcon class="w-4 h-4" /> {{ $t("documents.downloadAttachment") }}
        </button>
        <div
          v-if="!isPdfActiveStoredFile"
          class="z-10 flex items-center justify-end pr-3 -mt-1 -space-x-px grow"
        >
          <button
            class="btn-plain rounded-l-full px-1.5 py-0.5 flex items-center focus:z-10"
            :class="zoomHalf ? 'pointer-events-none bg-indigo-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'"
            type="button"
            @click.prevent="zoomHalf = true"
          >
            <MinusIcon
              class="w-4 h-4"
              aria-hidden="true"
            />
          </button>
          <button
            class="btn-plain rounded-r-full px-1.5 py-0.5 flex items-center focus:z-10"
            :class="!zoomHalf ? 'pointer-events-none bg-indigo-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-700'"
            type="button"
            @click.prevent="zoomHalf = false"
          >
            <PlusIcon
              class="w-4 h-4"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="-my-6 min-w-[20rem] min-h-[20rem] flex items-center justify-center">
        <PdfViewer
          v-if="storedFileUrl && isPdfActiveStoredFile && !uuidOfIsLoadingGetStoredFileUrl"
          :key="cyrb53(storedFileUrl)"
          :component-key="cyrb53(storedFileUrl)"
          :src="storedFileUrl"
        />
        <img
          v-else-if="storedFileUrl && !isPdfActiveStoredFile"
          :src="storedFileUrl"
          :alt="activeStoredFileFilename"
          class="max-h-full mt-4 mb-8 shadow-xl"
          :class="zoomHalf ? 'max-w-[50%]' : 'max-w-[100%]'"
        >
        <SpinLoader
          v-else
          class="w-6 h-6 text-gray-500"
          aria-hidden="true"
        />
      </div>
    </template>
  </DialogModal>
</template>
