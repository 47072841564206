<script setup lang="ts">

// internal
import { Team } from "~/types"
import { TeamIconDisplay } from "~/components"
import { StarIcon } from "~/icons"

interface Props {
  teams: Team[]
  selectedUuids?: Team["uuid"][]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    selectedUuids: () => [],
  },
)

const emit = defineEmits([ "update:selected-uuids" ])

const selectHandler = (teamUuid: Team["uuid"]) => {
  let uuids = [ ...props.selectedUuids ]
  if (uuids.includes(teamUuid)) {
    uuids = uuids.filter((uuid) => uuid !== teamUuid)
  } else {
    uuids.push(teamUuid)
  }
  emit("update:selected-uuids", uuids)
}

</script>

<template>
  <ul class="w-full space-y-px">
    <li
      v-for="team in props.teams"
      :key="team.uuid"
    >
      <button
        type="button"
        :class="selectedUuids.includes(team.uuid) ? 'bg-indigo-50 text-indigo-800' : 'hover:bg-gray-100 text-gray-600'"
        class="flex items-center w-full p-1 rounded-md cursor-pointer text-sm"
        @click="selectHandler(team.uuid)"
      >
        <TeamIconDisplay
          :team="team"
          class="w-4 h-4 mr-2"
        />
        <span class="text-left">{{ team.name }}</span>
      </button>
    </li>
    <li>
      <button
        type="button"
        :class="selectedUuids.includes('0') ? 'bg-indigo-50 text-indigo-800' : 'hover:bg-gray-100 text-gray-600'"
        class="flex items-center w-full p-1 text-sm rounded-md cursor-pointer"
        @click.prevent="selectHandler('0')"
      >
        <StarIcon class="w-4 h-4 mr-2 shrink-0" />
        <span>{{ $t('unassigned') }}</span>
      </button>
    </li>
  </ul>
</template>
