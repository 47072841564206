<script setup lang="ts">
// external
import { ref } from "vue"

// internal
import { LexicaIcon } from "~/icons"
import { DialogModal } from "~/components"
import {
  LexicaContentLexica,
  LexicaContentTeams,
  LexicaContentTags,
  LexicaContentRoles,
  LexicaContentAutomations,
  LexicaContentMetadata,
} from "./content"

interface Props {
  topic: string
  placement?: string
}

withDefaults(
  defineProps<Props>(),
  {
    placement: "bottom",
  },
)

const showModal = ref(false)

</script>
<template>
  <div class="flex items-center">
    <button
      type="button"
      data-tippy-help
      :data-tippy-content="$t('lexica.learnAbout', {topic: $t(`lexica.content.${topic}.topic`)})"
      :data-placement="placement"
      class="transition-colors duration-150 text-sky-500 hover:text-sky-400"
      @click="showModal = true"
    >
      <LexicaIcon
        class="shrink-0 w-4 h-4"
        aria-hidden="true"
      />
    </button>
    <DialogModal
      :show="showModal"
      max-width="2xl"
      :show-close-button="true"
      :padding="false"
      :force-visible-slot="true"
      modal-classes="ring-sky-300 ring-4 ring-opacity-75"
      @close="showModal = false"
    >
      <template #title>
        <div class="relative flex items-center px-6 pt-4 space-x-3">
          <LexicaIcon
            aria-hidden="true"
            class="w-6 h-6 text-sky-500"
          />
          <span>{{ $t(`lexica.content.${topic}.topic`) }}</span>
        </div>
      </template>
      <template #content>
        <div class="px-6 pb-5 text-sm">
          <slot />
          <LexicaContentLexica
            v-if="topic === 'lexica'"
          />
          <LexicaContentTeams
            v-else-if="topic === 'teams'"
          />
          <LexicaContentTags
            v-else-if="topic === 'tags'"
          />
          <LexicaContentRoles
            v-else-if="topic === 'roles'"
          />
          <LexicaContentAutomations
            v-else-if="topic === 'automations'"
          />
          <LexicaContentMetadata
            v-else-if="topic === 'metadata'"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-between gap-2">
          <div class="flex flex-col items-start">
            <div class="text-xs text-gray-500">
              {{ $t('lexica.needInfo') }}
            </div>
            <a
              href="mailto:support@fynk.com"
              class="block text-xs font-medium transition-colors duration-100 text-sky-500 hover:text-sky-700"
            >{{ $t('lexica.contactSupport') }}</a>
          </div>
          <button
            type="button"
            class="btn-plain hover:bg-gray-200 focus:bg-gray-200 focus:ring-gray-300"
            @click.prevent="showModal = false"
          >
            {{ $t('common.close') }}
          </button>
        </div>
      </template>
    </DialogModal>
  </div>
</template>
