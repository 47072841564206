<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { Link } from "@inertiajs/vue3"

// internal
import { useAccountStore } from "~/stores"
import { UpgradeIcon } from "~/icons"

interface Props {
  text: string
}

const accountStore = useAccountStore()
const { mau } = storeToRefs(accountStore)

withDefaults(
  defineProps<Props>(),
  {},
)

</script>

<template>
  <div
    class="z-10 flex items-center justify-center w-full"
  >
    <div class="absolute inset-0 bg-gradient-to-br from-indigo-500 to-fuchsia-600 opacity-60" />
    <div class="w-full max-w-3xl bg-gradient-to-br bg-reached border border-white/50 text-white rounded-lg px-5 py-4 m-5 shadow-xl z-10 @container">
      <div class="flex flex-col @md:flex-row justify-center items-center @md:space-x-5 space-y-3 @md:space-y-0">
        <div class="grow">
          <div class="flex items-center mb-2 font-bold">
            <UpgradeIcon
              class="w-4 h-4 mr-1 shrink-0"
              aria-hidden="true"
            />
            <span>{{ $t('accountSettings.billing.upgradeRequired') }}</span>
          </div>
          <slot name="text">
            <div class="text-sm">
              {{ text }}
            </div>
          </slot>
        </div>
        <Link
          v-if="mau?.permissions.includes('account_manage')"
          class="inline-block text-white border-0 btn-white focus:ring-white/50 focus:ring-offset-0 whitespace-nowrap bg-white/30 ring-0 hover:text-gray-900"
          :href="route('account-settings.billing.index')"
        >
          {{ $t('accountSettings.billing.manage') }}
        </Link>
      </div>
    </div>
  </div>
</template>
