<script setup lang="ts">
// external
import { storeToRefs } from "pinia"

import { NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3"

import { TrashIcon } from "@heroicons/vue/24/outline"

// internal
import { useEditorStore } from "~/stores"
import { usePage } from "@inertiajs/vue3"

const props = defineProps<NodeViewProps>()

const pageProps = usePage().props

const { isEditorEditable } = storeToRefs(useEditorStore())
</script>

<template>
  <NodeViewWrapper
    class="relative"
  >
    <hr
      class="my-10 border-t border-gray-300 page-break-hr"
      :class="{
        'outline outline-2 outline-blue-300': props.selected
      }"
    >

    <template
      v-if="pageProps.mau"
    >
      <span
        class="absolute flex items-center px-2 py-1 text-xs text-gray-400 transform -translate-x-1/2 bg-white left-1/2"
        :class="isEditorEditable ? '-top-[0.9rem]' : '-top-[0.75rem]'"
      >
        {{ $t('editor.pageBreak') }}
        <span
          v-show="isEditorEditable"
          class="p-1 -mt-0.5 ml-1 text-gray-400 transition-all duration-200 rounded-md cursor-pointer hover:text-red-400 hover:bg-slate-100"
          data-tippy-help
          data-placement="top"
          :data-tippy-content="$t('editor.removePageBreak')"
        >
          <TrashIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
            @click.stop.prevent="props.deleteNode()"
          />
        </span>
      </span>
    </template>
  </NodeViewWrapper>
</template>

