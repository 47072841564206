<script setup lang="ts">
// external
import { computed } from "vue"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import {
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/vue/24/solid"

// internal


interface Props {
  tabs: any[]
  activeTab?: Record<string, any>
  style?: string
  useSelectFallback?: boolean
  navClasses?:string
}
const props = withDefaults(
  defineProps<Props>(),
  {
    activeTab: null,
    style: "gray",
    useSelectFallback: false,
    navClasses: "",
  },
)

const emit = defineEmits([ "update:activeTab" ])

const activeTab = computed({
  get: () => props.activeTab,
  set: (value) => emit("update:activeTab", value),
})

const setActiveTab = (tabId: any) => {

  const tab = props.tabs.find((tab) => tab.id === tabId)

  if (tab) {
    if (tab.active === false) return
    activeTab.value = tab
  }
}

</script>

<template>
  <div class="@container">
    <Listbox
      v-if="useSelectFallback"
      v-model="activeTab"
      as="div"
      :class="{'@md:hidden' : useSelectFallback}"
    >
      <div class="relative">
        <ListboxButton class="btn-listbox-plain">
          <span>{{ activeTab.name }}</span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              class="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions class="listbox-options">
            <ListboxOption
              v-for="tab in tabs"
              :key="tab.id"
              v-slot="{ active, selected }"
              as="template"
              :value="tab"
            >
              <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex items-center']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ tab.name }}</span>
                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-indigo-500',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon
                    class="shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <div :class="[useSelectFallback ? 'hidden @md:block' : '']">
      <nav
        class="flex space-x-2"
        :class="navClasses"
      >
        <button
          v-for="(tab, tabIdx) in tabs"
          :key="tabIdx"
          type="button"
          :href="tab.href"
          :disabled="tab.active === false"
          :class="[
            activeTab?.id === tab.id
              ? 'bg-indigo-700 text-white focus:outline-indigo-300'
              : style === 'indigo'
                ? 'text-indigo-700 hover:text-indigo-800 hover:bg-indigo-100 group focus:outline-indigo-300'
                : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100 group focus:outline-gray-300',
            tab.active === false ? 'pointer-events-none' : '',
            'px-2 py-1 font-medium text-sm rounded-full flex items-center disabled:opacity-50',
          ]"
          :aria-current="activeTab?.id === tab.id ? 'page' : undefined"
          @click.prevent="setActiveTab(tab.id)"
        >
          <span class="truncate">{{ tab.name }}</span>
          <span
            v-if="tab.badge || tab.badge === 0"
            :class="[
              activeTab?.id === tab.id
                ? 'bg-indigo-500 text-white'
                : style === 'indigo'
                  ? 'bg-indigo-100 text-indigo-700 group-hover:bg-indigo-200'
                  : 'bg-gray-200 text-gray-900 group-hover:bg-gray-300',
              'min-w-[20px] px-0.5 h-5 ml-1 rounded-full text-xs font-medium flex items-center justify-center -mr-1',
            ]"
          >
            <span>{{ tab.badge }}</span>
          </span>
        </button>
      </nav>
    </div>
  </div>
</template>
