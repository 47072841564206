<script setup lang="ts">
import { onBeforeUnmount, onMounted, onUnmounted, ref, watch } from "vue"

import { useLocalStorage } from "@vueuse/core"

import { Editor } from "@tiptap/core"

import { VariableIcon } from "@heroicons/vue/24/outline"
import { ArrowDownIcon, ArrowUpIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import tippy, { Instance, Props as TippyProps, roundArrow } from "tippy.js"

import { ItemCombobox } from "~/components"

import { type SearchAndReplaceStorage } from "../extensions"
import { JSONContent } from "@tiptap/core"

interface Props {
  editor: Editor | null;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    editor: null,
  },
)

const searchTerm = ref<string>("")

const replaceTerm = ref<string>("")

const totalResults = ref<number>(0)

const currentResult = ref<number>(0)

const searchTermInputRef = ref<HTMLInputElement | null>()

const isVariableReplacingActive = useLocalStorage<boolean>("is-variable-replacing-active", false)

const selectedVariable = ref<JSONContent>()

const toggleIsVariableReplacingActive = () => {
  isVariableReplacingActive.value = !isVariableReplacingActive.value
}

const focusSearchTermInput = () => {
  if (!searchTermInputRef.value) return

  searchTermInputRef.value.focus()
}

const updateSearchReplace = (): void => {
  if (!props.editor) return

  let chain = props.editor
    .chain()
    .setSearchTerm(searchTerm.value)

  if (selectedVariable.value && isVariableReplacingActive.value) {
    chain = chain
      .setReplaceNode(selectedVariable.value.data)
      .setReplaceTerm("")
  } else {
    chain = chain
      .setReplaceTerm(replaceTerm.value)
      .setReplaceNode(null)
  }

  chain.run()
}

watch(
  [ () => searchTerm.value, () => replaceTerm.value, () => selectedVariable.value ],
  () => {
    if (searchTerm.value.length && searchTerm.value.length <= 3) return

    updateSearchReplace()
  },
)

const handleSearchInputKeyDown = (event: KeyboardEvent): void => {
  if (event.key === "Escape") {
    resetAndHideSearchPopover()

    return
  }

  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault()

    props.editor?.commands?.focusNextResult()
  }

  if (event.key === "Enter" && event.shiftKey) {
    event.preventDefault()

    setTimeout(() => props.editor?.commands?.focusPreviousResult())

    return
  }
}

const replace = (): boolean => props.editor?.commands?.replace()

const replaceAll = (): boolean => props.editor?.commands?.replaceAll()

const searchTippy = ref<Instance<TippyProps>[]>()

const popoverTemplateRef = ref<HTMLDivElement>()

const setupTippy = () => {
  updateSearchReplace()

  searchTippy.value = tippy(
    "#toolbar-search-and-replace-button",
    {
      content () {
        return popoverTemplateRef.value
      },
      trigger: "manual",
      offset: [ 0, 16 ],
      appendTo: () => document.getElementById("mainContentContainer"),
      animation: "shift-toward-subtle",
      allowHTML: true,
      theme: "slate",
      arrow: roundArrow,
      interactive: true,
      showOnCreate: false,
      placement: "bottom",
      onClickOutside (instance) {
        if (!searchTerm.value) instance.hide()
      },
      hideOnClick: false,
      onShow () {
        setTimeout(
          () => {
            focusSearchTermInput()

            if (props.editor.state.selection.empty) return

            const { from, to } = props.editor.state.selection

            const text = props.editor.state.doc.textBetween(from, to)

            if (text.trim().length === 0) return

            searchTerm.value = text
          },
        )
      },
    },
  )
}

onMounted(setupTippy)

const onDataUpdated = (data: SearchAndReplaceStorage) => {
  totalResults.value = data.results?.length || 0

  currentResult.value = data.activeResultIndex + 1
}

onMounted(
  () => {
    if (!props.editor) return

    props.editor.storage.searchAndReplace.setCallBackOnDataUpdate(onDataUpdated)
    props.editor.storage.searchAndReplace.setFocusCallback(focusSearchTermInput)
  },
)

onBeforeUnmount(
  () => {
    if (!props.editor) return

    props.editor.storage.searchAndReplace.callBackOnDataUpdate(() => {
      //
    })
  },
)

onUnmounted(
  () => {
    searchTippy.value?.[0]?.destroy()

    searchTippy.value = null
  },
)

const resetAndHideSearchPopover = () => {
  searchTerm.value = ""
  replaceTerm.value = ""
  searchTippy.value?.[0]?.hide()
}

const showSearchPopover = () => {
  searchTippy.value[0].show()
  focusSearchTermInput()
}

defineExpose(
  {
    showSearchPopover,
    resetAndHideSearchPopover,
    searchTippy,
  },
)
</script>

<template>
  <div
    ref="popoverTemplateRef"
    class="flex flex-col gap-2 p-2 pt-3 popover popover-slate"
  >
    <button
      class="absolute z-50 p-0.5 border rounded-full shadow-md -left-2 -top-2 bg-slate-900 border-slate-300 border-opacity-70 text-slate-200 hover:text-slate-100 flex gap-1 items-center"
      @click="resetAndHideSearchPopover"
    >
      <XMarkIcon class="w-3 h-3" />
    </button>

    <div class="flex flex-col gap-3 p-1">
      <section class="flex items-center gap-2">
        <div class="input-overlap-container">
          <label
            for="searchTerm"
            class="px-1 transition-all bg-indigo-800 rounded-md input-overlap-label"
            :class="searchTerm.length > 0 ? 'translate-y-0 opacity-1' : 'translate-y-full opacity-0'"
          >
            {{ $t('editor.searchFor') }}
          </label>

          <input
            id="searchTerm"
            ref="searchTermInputRef"
            v-model="searchTerm"
            type="text"
            name="Search"
            class="input-overlap-input w-80"
            :placeholder="$t('editor.searchFor') + '…'"
            @keydown="handleSearchInputKeyDown"
          >
        </div>

        <button
          type="button"
          class="btn-slate btn-sm"
          :title="$t('editor.go')"
          @click="updateSearchReplace"
        >
          {{ $t('editor.go') }}
        </button>

        <button
          type="button"
          class="h-[1.85rem] btn-slate btn-sm"
          :title="$t('editor.previousResult')"
          @click="props.editor.commands.focusPreviousResult()"
        >
          <ArrowUpIcon class="w-4 h-4" />
        </button>

        <button
          type="button"
          class="h-[1.85rem] btn-slate btn-sm"
          :title="$t('editor.nextResult')"
          @click="props.editor.commands.focusNextResult()"
        >
          <ArrowDownIcon class="w-4 h-4" />
        </button>

        <span
          class="text-sm font-semibold text-center text-slate-400 grow"
        >
          {{ `${totalResults ? currentResult : 0}` }} / {{ totalResults }}
        </span>
      </section>

      <div class="flex items-center gap-2">
        <div
          v-if="isVariableReplacingActive"
          class="flex-grow"
        >
          <ItemCombobox
            v-model:selected-item="selectedVariable"
            :cancelable="false"
            :include-parties="true"
            :use-nodes="true"
            layout="slate"
          />
        </div>

        <div
          v-else
          class="input-overlap-container"
        >
          <label
            for="replaceTerm"
            class="px-1 transition-all bg-indigo-800 rounded-md input-overlap-label"
            :class="replaceTerm.length > 0 ? 'translate-y-0 opacity-1' : 'translate-y-full opacity-0'"
          >
            {{ $t('editor.replaceWith') }}
          </label>

          <input
            id="replaceTerm"
            v-model="replaceTerm"
            type="text"
            name="Replace"
            :placeholder="$t('editor.replaceWith') + '…'"
            class="input-overlap-input w-80"
          >
        </div>

        <button
          class="btn-sm btn-slate h-[1.85rem]"
          :class="{
            'border-indigo-300 text-indigo-300 focus:border-indigo-200 hover:border-indigo-200': isVariableReplacingActive,
          }"
          type="button"
          data-tippy-help
          data-placement="bottom"
          :data-tippy-content="$t('editor.replaceWithVariable')"
          @click="toggleIsVariableReplacingActive"
        >
          <VariableIcon
            class="w-4 h-4 shrink-0"
            aria-hidden="true"
          />
        </button>

        <button
          class="btn-slate btn-sm"
          type="button"
          @click="replace"
        >
          {{ $t('editor.replace') }}
        </button>

        <button
          class="btn-slate btn-sm"
          type="button"
          @click="replaceAll"
        >
          {{ $t('editor.replaceAll') }}
        </button>
      </div>
    </div>
  </div>
</template>
