import { Node } from "@tiptap/pm/model"
import { storeToRefs } from "pinia"
import { usePartyStore, useSignatureStore, useUserStore } from "~/stores"
import { DocumentUser, DocumentUserRoleEnum } from "~/types"
import {
  IBorderOptions,
  IBordersOptions,
  BorderStyle,
  IXYFrameOptions,
  ShadingType,
  IShadingAttributesProperties,
  FrameAnchorType,
} from "docx"
import * as docx_1 from "docx"
import { DocxSerializerState } from "prosemirror-docx"

export const signatureBlockState = {
  counter: 0,
}

export const setSignatureBlockState = (counter: number) => {
  signatureBlockState.counter = counter
}

export const generateSignatureBlock = (state: DocxSerializerState, node: Node) => {

  signatureBlockState.counter++

  const signatureStore = useSignatureStore()
  const { signatureBlocks } = storeToRefs(signatureStore)

  const partyStore = usePartyStore()
  const { parties } = storeToRefs(partyStore)

  const userStore = useUserStore()
  const { users } = storeToRefs(userStore)

  // any type necessary for simple signature container handling
  const refUuid = node.attrs?.refUuid || (node as any).refUuid

  const signatureBlock = signatureBlocks.value?.find((el) => el.ref_uuid === refUuid)

  const signatoryParty = parties.value?.find((el) => el.uuid === signatureBlock?.party_uuid)

  const signatoryPartyName = signatoryParty?.name || "[ n/a ]"
  const signatoryPartyEntityName = signatoryParty?.entity_name || "[ n/a ]"

  const signatories = users.value?.filter((el) => el.roles?.includes(DocumentUserRoleEnum.signatory) && el.party_uuid === signatoryParty?.uuid)

  const getSignatoryInfoRepresentation = (signatory: DocumentUser) => {
    let string = ""
    if (signatory?.email) {
      const maskedEmail = "****" + signatory.email.substring(signatory.email.indexOf("@"))
      string += maskedEmail
    }
    if (signatory?.email && signatory?.mobile_phone) {
      string += " / "
    }
    if (signatory?.mobile_phone) {
      const maskedPhoneNumber = signatory.mobile_phone.slice(0, 4) + "****" + signatory.mobile_phone.slice(-4)
      string += maskedPhoneNumber
    }
    return string
  }

  const borderOptions: IBorderOptions = {
    color: "000000",
    space: 6,
    style: BorderStyle.SINGLE,
    size: 6,
  }
  const bordersOptions: IBordersOptions = {
    top: borderOptions,
    bottom: borderOptions,
    left: borderOptions,
    right: borderOptions,
  }
  const shadingOptions: IShadingAttributesProperties = {
    fill: "000000",
    color: "FFFFFF",
    type: ShadingType.SOLID,
  }
  const generateChildren = (signatories: DocumentUser[]) => {
    const children = []
    signatories?.forEach((signatory) => {
      children.push(new docx_1.TextRun({
        text: "______________________________________",
        bold: true,
        font: "Helvetica",
        size: 14,
        break: 3,
      }))
      children.push(new docx_1.TextRun({
        text: " ",
        font: "Helvetica",
        size: 1,
        break: 1,
      }))
      children.push(new docx_1.TextRun({
        text: signatoryPartyName,
        font: "Helvetica",
        size: 14,
        break: 1,
      }))
      children.push(new docx_1.TextRun({
        text: signatoryPartyEntityName,
        font: "Helvetica",
        size: 14,
        break: 1,
      }))
      children.push(new docx_1.TextRun({
        text: getSignatoryInfoRepresentation(signatory) || "[ n/a ]",
        font: "Helvetica",
        size: 14,
        break: 1,
      }))
    })
    return children
  }
  const signatoriesToRender = signatories?.length ? signatories : [ { id: 0 } as DocumentUser ]
  const textFrame = new docx_1.Paragraph({
    children: generateChildren(signatoriesToRender),
    frame: {
      type: "absolute",
      width: 3000,
      height: 1000,
      anchor: {
        horizontal: FrameAnchorType.TEXT,
        vertical: FrameAnchorType.TEXT,
      },
      position: {
        x: (signatureBlockState.counter - 1) * 3400 + 120,
        y: 0,
      },
      wrap: "notBeside",
    } as IXYFrameOptions,
    border: bordersOptions,
    shading: shadingOptions,
    spacing: {
      line: 300,
      after: 0,
      before: 0,
    },
  })
  state.children.push(textFrame)
}
