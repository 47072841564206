<script setup lang="ts">
// external
import {
  breakpointsTailwind,
  useBreakpoints,
} from "@vueuse/core"
import { storeToRefs } from "pinia"
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"

import { ArrowLeftIcon, DevicePhoneMobileIcon, ForwardIcon, KeyIcon, ShieldCheckIcon, TrashIcon } from "@heroicons/vue/24/outline"
import { XMarkIcon } from "@heroicons/vue/24/solid"
import { toTypedSchema } from "@vee-validate/zod"
import { omit } from "lodash-es"
import VPerfectSignature from "v-perfect-signature"
import { useField, useForm } from "vee-validate"
import VOtpInput from "vue3-otp-input"
import { z } from "zod"
import axios from "axios"
import { router } from "@inertiajs/vue3"

// internal
import { Countdown, EmptyState, FormInputErrors, PhoneField, SpinLoader, SignatureUpload, SignatureSignatoryDisplay, Checkbox, QESSignatureForms, SignatureSuccessExternal, SignatureSuccess } from "~/components"
import { EraserIcon, SignatureIcon } from "~/icons"
import { useDocumentStore, useNotificationStore, useSharedStore, useSignatureStore, useUserStore } from "~/stores"
import { Document, DocumentOrigin, DocumentUser, Signature, SignatureBlock, SignaturePayload, SignatureQesProcess, SignatureSealPayload, SignatureSecurityLevel, Template, UserSignatureSeal } from "~/types"
import { cropSignatureCanvas, copyCanvasToNewCanvas } from "~/utils"

interface Props {
  document?: Document
  template?: Template
  signatureQesProcess?: SignatureQesProcess
  signedHtml?: string
  signedPdfUrl?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    document: null,
    template: null,
    signatureQesProcess: null,
    signedHtml: null,
    signedPdfUrl: null,
  },
)

const emit = defineEmits( [ "update:document-content", "rebind-signature-blocks", "close", "jump-to-signature-block" ])

const { notify } = useNotificationStore()
const { t } = useI18n()

const documentStore = useDocumentStore()
const { currentDocument, mdu, mau, signedPdfSha256, showPlgModal } = storeToRefs(documentStore)

const signatureStore = useSignatureStore()
const { isLoadingCreateSignatureProcess, isLoadingCreateQesSignature, isLoadingUserSignatureSeals, signatureProcess, activeSignatureBlockUuid, activeSigningPhase, userSignatureSeals, uuidsOfIsRemovingUserSignatureSeal, savingSignatureStatus, signaturesRequiredByMdu } = storeToRefs(signatureStore)
const { createSignatureProcess, generateSignatureSeal, createUserSignatureSeal, fetchAllUserSignatureSeals, removeUserSignatureSeal, setSavingSignatureStatus, setSignatureProcess } = signatureStore

const sharedStore = useSharedStore()
const { crudContext } = storeToRefs(sharedStore)

const entityUuid = computed<Document["uuid"] | Template["uuid"]>(
  () => props?.[crudContext.value]?.uuid,
)

const colors = [ "#2d3748", "#2196F3", "#4f46e5" ]

const colorSelected = ref<string>("#4f46e5")

const verificationActive = ref<boolean>(false)

const phone = computed<string>(() => mdu.value?.mobile_phone || null)

const otpError = ref<string>(null)

const otpInput = ref<any>()

const otpInputValue = ref<string>(null)

const otpInputStore = ref<string>("")

/* const qesError = ref<string>(null) */

const initVPerfectSignatureComponent = ref<boolean>(false)

const activeTab = ref<"saved" | "draw" | "type" | "photo">("draw")

const signatureUpload = ref<any>()

const signaturePad = ref<any>()

const savingSignature = ref<boolean>(false)

const signatureSecurityLevel = computed<SignatureSecurityLevel>(() => activeSigningPhase.value.signature_security_level)

const isCreateSignatureProcessLoading = ref<boolean>(false)

const verificationCodeValidSeconds = ref(1000)

const strokeOptions = {
  size: 8,
  thinning: 0.5,
  smoothing: 0.5,
  streamline: 0.5,
  // simulatePressure: true,
  // last: true,
}

const signaturePadText = ref("")

const photoBlob = ref<Blob | null>(null)
const signaturePhotoUuid = ref<string>("")

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smallerOrEqual("lg")
const isPortrait = ref(false)
//const { orientation } = useScreenOrientation()
const toDataURL = () => {
  if (signaturePad.value?.toDataURL() && signaturePad.value?.toDataURL() !== "data:,") return signaturePad.value?.toDataURL()
  if (dataUrl.value && dataUrl.value !== "data:,") return dataUrl.value
  return ""
}

const clearSignature = () => {
  if (signaturePad.value) signaturePad.value?.clear()
  const inputElement = document.getElementById("SignatureInput") as HTMLInputElement
  if (inputElement) inputElement.value = ""
  signaturePadText.value = ""
  signatureUpload.value?.clear()
  photoBlob.value = null
}

const setPhotoBlob = (blob: Blob) => {
  photoBlob.value = blob
}

const mediaQueryListener = window.matchMedia("(orientation: portrait)")

const checkOrientation = () => {
  // If there are matches, we're in portrait
  if (mediaQueryListener.matches) {
    isPortrait.value = true
  } else {
    isPortrait.value = false
  }
  //isPortrait.value = orientation.value === "portrait-primary" || orientation.value === "portrait-secondary" || (orientation.value === "portrait" as any)
  /* if (!isPortrait.value) {
    // Prevent overflow behavior (drawing further than mouse)
    setTimeout(() => handleResizeCanvas(false), 300)
  } */
}

const dataUrl = ref("")

const handleResizeCanvas = (clear = true) => {
  // Do not trigger this event when active tab is not draw
  if (activeTab.value !== "draw") return
  if (signaturePad.value) {
    // Temporarily save signature as data URL
    dataUrl.value = signaturePad.value?.toDataURL()
    // Clear signature pad
    signaturePad.value?.resizeCanvas(clear)
    // Restore signature from data URL, when clear is false
    if (!clear && dataUrl.value && dataUrl.value !== "data:,") {
      signaturePad.value?.fromDataURL(dataUrl.value)
    }
  }
}

const focusInput = () => {
  setTimeout(() => {
    document.getElementById("SignatureInput").focus()
  }, 100)
}

const saveForFuture = ref<boolean>(true)

const selectedUserSignatureSeal = ref<UserSignatureSeal>(null)

const setSelectedUserSignatureSeal = (seal: UserSignatureSeal) => {
  if (selectedUserSignatureSeal.value?.uuid === seal.uuid) {
    selectedUserSignatureSeal.value = null
    return
  }
  selectedUserSignatureSeal.value = seal
}

const getSealPayload = (): SignatureSealPayload => {
  let sealPayload: SignatureSealPayload = {
    type: (activeTab.value === "saved" ? selectedUserSignatureSeal.value?.signature_seal?.type : activeTab.value),
  }
  if (activeTab.value === "draw") {
    // Get first <canvas> element from #signatureCanvasContainer
    const canvas = document.getElementById("signatureCanvasContainer").querySelector("canvas")
    // Trim canvas from toDataURL()
    const trimmedDataUrl = cropSignatureCanvas(canvas)
    // Copy to new canvas with correct aspect ratio
    const ratioAdjustedCanvas = copyCanvasToNewCanvas(trimmedDataUrl, 768, 168)
    // Read "newCanvas" as data URL
    let sealDrawingDataUrl = ratioAdjustedCanvas.toDataURL()
    // If ratioAdjustedCanvasDataUrl is empty, fall back to toDataURL()
    if (sealDrawingDataUrl === "data:," || !sealDrawingDataUrl) {
      sealDrawingDataUrl = toDataURL()
    }
    sealPayload = {
      ...sealPayload,
      signature_pad_drawing: sealDrawingDataUrl,
    }
  } else if (activeTab.value === "type") {
    sealPayload = {
      ...sealPayload,
      signature_pad_text: signaturePadText.value,
      signature_pad_color: colorSelected.value,
      signature_pad_font: "Kalam",
    }
  } else if (activeTab.value === "photo") {
    sealPayload = {
      ...sealPayload,
      photo_uuid: signaturePhotoUuid.value,
    }
  } else if (activeTab.value === "saved") {
    sealPayload = selectedUserSignatureSeal.value?.signature_seal
    // add isSaved property
    sealPayload = {
      ...sealPayload,
      isSaved: true,
    }
  }
  return sealPayload
}

const sealPayload = ref(null)

const handleCreateSignatureProcess = async () => {
  const sealPayload = getSealPayload()
  if (!sealPayload.type) return
  if (sealPayload?.type === "draw" && (!sealPayload?.signature_pad_drawing || sealPayload?.signature_pad_drawing === "data:,")) {
    notify({
      title: t("signaturePad.errors.noSignature"),
      message: t("signaturePad.errors.noSignatureDescription"),
      type: "error",
    })
    emit("close")
    return
  }
  await createSignatureProcess(currentDocument.value?.uuid, activeSigningPhase.value.uuid, signatureSecurityLevel.value, activeSignatureBlockUuid.value, sealPayload)
}

const resetSignatureProcess = () => {
  otpError.value = null
  signatureStore.setSignatureProcess(null)
}

const handleCreateSignature = async (otpValue?: string, signatureQesProcessUuid?: SignatureQesProcess["uuid"]): Promise<Signature | void> => {

  if (signatureSecurityLevel.value === SignatureSecurityLevel.AES && !signatureProcess.value && !signatureQesProcessUuid) return

  savingSignature.value = true
  otpError.value = null
  const otp = otpValue || otpInputValue.value
  const documentUuid = currentDocument.value?.uuid
  const signingPhaseUuid = activeSigningPhase.value.uuid

  let payload: Partial<SignaturePayload>

  if (currentDocument.value.origin === DocumentOrigin.pdf && !props.signedPdfUrl) return

  let localSignedPdfSha256 = null
  if (currentDocument.value.origin === DocumentOrigin.pdf) localSignedPdfSha256 = signedPdfSha256.value

  if (signatureQesProcessUuid) {

    payload = {
      signature_qes_process_uuid: signatureQesProcessUuid,
    }

  } else {

    payload = {
      signed_html: props.signedHtml,
      signed_pdf_sha256: localSignedPdfSha256,
      sms_challenge: otp,
    }

    if (signatureSecurityLevel.value === SignatureSecurityLevel.AES) {
      payload = {
        ...payload,
        ...{ signature_aes_process_uuid: signatureProcess.value.uuid },
      }
    }
    if (signatureSecurityLevel.value === SignatureSecurityLevel.SES) {
      sealPayload.value = getSealPayload()
      if (sealPayload.value?.type === "draw" && (!sealPayload.value?.signature_pad_drawing || sealPayload.value?.signature_pad_drawing === "data:,")) {
        notify({
          title: t("signaturePad.errors.noSignature"),
          message: t("signaturePad.errors.noSignatureDescription"),
          type: "error",
        })
        return
      }
      payload = { ...payload, ...{ signature_block_uuid: activeSignatureBlockUuid.value } }
    }

    const seal = await generateSignatureSeal(sealPayload.value)
    if (!seal) {
      savingSignature.value = false
      return
    }
    payload = { ...payload, ...{ signature_seal: seal } }

  }

  try {

    const createSignatureRes = await signatureStore.createSignature(documentUuid, signingPhaseUuid, payload, signatureSecurityLevel.value)
    if (createSignatureRes && signatureQesProcessUuid) {
      // Remove signature_qes_process_uuid from URL
      const url = new URL(window.location.href)
      url.searchParams.delete("signature_qes_process_uuid")
      window.history.replaceState({}, "", url.toString())
    }
    if (createSignatureRes && saveForFuture.value && activeTab.value !== "saved" && !!mau.value && !signatureQesProcessUuid) await createUserSignatureSeal({ signature_seal: payload.signature_seal || sealPayload.value })
    setSignatureProcess(null)
    setSavingSignatureStatus("success")
    clearOtpInput()
    otpError.value = null
    otpInputValue.value = null
    signatureStore.setSignatureProcess(null)
    verificationActive.value = false
    if ((!signaturesRequiredByMdu.value || !signaturesRequiredByMdu.value?.nextSignatureBlockUuid) && mau.value) {
      setTimeout(() => {
        emit("close")
        setSavingSignatureStatus(null)
        router.reload({
          only: [ "document" ],
          preserveScroll: true,
        })
      }, 5000)
    }
    if (!signatureQesProcessUuid) clearSignature()
    emit("rebind-signature-blocks")
    emit("update:document-content")

  } catch (err) {

    notify({
      title: t("signaturePad.errors.errorSavingSignature"),
      message: err.response?.data?.message || err.message,
      type: "error",
    })
    console.error(err)
    clearOtpInput()
    otpError.value = err.response?.data?.message || err.message

  } finally {

    savingSignature.value = false
    signatureStore.setIsLoadingCreateQesSignature(false)

  }
}

const signatureReady = computed(() => {
  if (activeTab.value === "draw") {
    if (!signaturePad.value) return false
    if (signaturePad.value?.isEmpty()) return false
    if (toDataURL() === "data:,") return false
    return true
  } else if (activeTab.value === "type") {
    return !!(signaturePadText.value)
  } else if (activeTab.value === "photo") {
    return !!(photoBlob.value)
  } else if (activeTab.value === "saved") {
    return !!(selectedUserSignatureSeal.value)
  }
  return false
})

const handleOnChange = (value) => {
  otpError.value = null
  otpInputValue.value = value
}

const handleOnComplete = (value) => {
  if (savingSignature.value) return
  otpInputValue.value = value
  handleCreateSignature(otpInputValue.value)
}

const storedToken = ref(null)

const handlePhotoUpload = async (): Promise<{ uuid: string } | void> => {

  if (photoBlob.value) {

    if (!mau.value && axios.defaults.params["token"]) {
      storedToken.value = axios.defaults.params["token"]
      axios.defaults.params = {}
    }

    const file = new File([ photoBlob.value ], "signaturePhoto", { type: photoBlob.value?.type })
    return Vapor.store<{ uuid: string }>(file)
      .then((res) => {
        signaturePhotoUuid.value = res.uuid
        return res
      })
      .catch((err) => {
        notify({
          title: t("signaturePad.errors.uploadingPhoto"),
          message: err.response?.data?.message || err.message,
          type: "error",
        })
      }).finally(() => {
        if (storedToken.value) {
          axios.defaults.params["token"] = storedToken.value
          storedToken.value = null
        }
      })
  }
}

const handleClickSaveSignatureButton = async () => {
  if (activeTab.value === "photo") {
    const res = await handlePhotoUpload()
    if (!res || !res?.uuid) return
  }
  switch (signatureSecurityLevel.value) {
    case SignatureSecurityLevel.QES:
      sealPayload.value = getSealPayload()
      handleCreateSignatureProcess()
      break
    case SignatureSecurityLevel.AES:
      sealPayload.value = getSealPayload()
      verificationActive.value = true
      break
    case SignatureSecurityLevel.SES:
      handleCreateSignature()
      break
  }
}

// Helper for signature block jumps
const handleJumpToSignatureBlock = (
  refUuid: SignatureBlock["ref_uuid"],
  documentUserUuid: DocumentUser["uuid"],
  emptyState: boolean,
) => {
  emit("jump-to-signature-block", {
    refUuid: refUuid,
    documentUserUuid: documentUserUuid,
    emptyState: emptyState,
  })

  if (!isMobile.value) emit("close")

  setSavingSignatureStatus(null)
  if (userSignatureSeals.value?.length > 0) {
    activeTab.value = "saved"
  } else {
    activeTab.value = "draw"
    // Prevent overflow behavior (drawing further than mouse)
    setTimeout(() => handleResizeCanvas(true), 300)
  }

}

const clearOtpInput = () => otpInput.value?.clearInput()


const userStore = useUserStore()
const { users, userErrorsMap, uuidsOfUpdatingUser, payloadKeys } = storeToRefs(userStore)

const userFromMdu = computed(() => users.value?.find((el) => el.uuid === mdu.value?.uuid))

const isLoadingUpdatePhoneNumber = computed<boolean>(() => uuidsOfUpdatingUser.value.includes(mdu.value?.uuid) && payloadKeys.value.includes("mobile_phone"))

const formValidationErrors = ref<Partial<Record<keyof DocumentUser, string[]>>>({})
const backendErrors = computed(() => userErrorsMap.value[mdu.value?.uuid])

const errorsToShow = computed<Partial<Record<keyof DocumentUser, string[]>>>(
  () => {
    const errors: Partial<Record<keyof DocumentUser, string[]>> = {}

    if (formValidationErrors.value) {
      Object.keys(formValidationErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(formValidationErrors.value[key] || []) ]
          },
        )
    }

    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            // Here we want only one error per field if there is a frontend error
            if (errors[key] && errors[key].length > 0) return
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }

    Object.keys(errors)
      .forEach(
        (key) => {
          if (errors[key].length === 0) delete errors[key]
        },
      )

    return errors
  },
)

const documentUserSchema = z
  .object(
    {
      mobile_phone: z.string().nullable().optional(),
    },
  )

type DocumentUserValidatedType = z.infer<typeof documentUserSchema>

const documentUserValidator = toTypedSchema(documentUserSchema)

const { errors, setFieldValue, validate } = useForm<DocumentUserValidatedType>(
  {
    validationSchema: documentUserValidator,
  },
)

const mobile_phone = useField<string>("mobile_phone")

setFieldValue("mobile_phone", mdu.value?.mobile_phone)

const handleUpdatePhoneNumber = (phone: DocumentUser["mobile_phone"]) => {
  setFieldValue("mobile_phone", phone)
  setTimeout(
    async () => {
      const isValid = await validate(
        {
          mode: "validated-only",
        },
      )
      if (!isValid.valid) return
      const payload = { uuid: mdu.value?.uuid, mobile_phone: mobile_phone.value.value }
      await userStore.updateLocalUser(payload, crudContext.value, entityUuid.value)
    },
  )
}

const handlePhoneValidationError = () => {
  formValidationErrors.value = {
    ...formValidationErrors.value,
    mobile_phone: [ t("signatures.invalidPhoneNumber") ],
  }
}

const handlePhoneValidationPassed = () => {
  formValidationErrors.value = omit(formValidationErrors.value, "mobile_phone")
}

const handleColorChange = (color: string) => {
  colorSelected.value = color
  handleResizeCanvas(true)
}

watch(userSignatureSeals, () => {
  if (savingSignature.value) return
  if (userSignatureSeals.value?.length > 0) {
    activeTab.value = "saved"
  } else {
    activeTab.value = "draw"
  }
})

watch(activeTab, (val) => {
  if (val === "saved" && userSignatureSeals.value?.length > 0) {
    selectedUserSignatureSeal.value = userSignatureSeals.value[0]
  } else if (val !== "photo" && photoBlob.value) {
    photoBlob.value = null
  } else if (val === "draw") {
    setTimeout(() => handleResizeCanvas(false), 300)
  }
})

watch(
  ()  => errors.value,
  (newVal) => {
    formValidationErrors.value = Object.keys(newVal)
      .reduce(
        (acc, key) => {
          acc[key] = [ newVal[key] ]

          return acc
        },
        {} as Partial<Record<keyof DocumentUser, string[]>>,
      )
  },
)

let lastReportedWidth = window.innerWidth
let resizeTimeout = null

const handleResize = (forceUpdate = false) => {
  const currentWidth = window.innerWidth
  if (lastReportedWidth !== currentWidth || forceUpdate) {
    lastReportedWidth = currentWidth
    handleResizeCanvas(true)
  }
}

const debouncedHandleResize = () => {
  clearTimeout(resizeTimeout)
  resizeTimeout = setTimeout(() => handleResize(), 300)
}

onMounted(() => {
  if (!!mau.value) fetchAllUserSignatureSeals()
  checkOrientation()
  window.addEventListener("resize", debouncedHandleResize)
  mediaQueryListener.addEventListener("change", () => {
    checkOrientation()
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => handleResize(true), 500)
  })
  setSavingSignatureStatus(null)
  if (props.signatureQesProcess && !props.signatureQesProcess?.canceled_at) {
    if (
      props.signatureQesProcess &&
      Date.parse(props.signatureQesProcess?.valid_till) > Date.now() &&
      !props.signatureQesProcess?.finished_at
    ) {
      handleCreateSignature(null, props.signatureQesProcess.uuid)
    } else {
      emit("close")
    }
  } else {
    initVPerfectSignatureComponent.value = true
    // Prevent overflow behavior (drawing further than mouse)
    setTimeout(() => handleResizeCanvas(true), 300)
  }
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", checkOrientation)
  mediaQueryListener.removeEventListener("change", checkOrientation)
})

</script>

<template>
  <div
    v-cy="`signature-pad`"
    class="relative w-full h-full lg:w-auto lg:h-auto"
    :class="verificationActive && !savingSignatureStatus ? 'lg:max-w-lg' : 'lg:max-w-3xl'"
  >
    <div
      class="absolute right-0 -top-2"
      :class="isMobile && !isPortrait ? 'hidden' : 'lg:hidden'"
    >
      <button
        class="text-gray-500 btn-plain hover:text-gray-600"
        @click.prevent="emit('close')"
      >
        <XMarkIcon class="w-6 h-6" />
      </button>
    </div>
    <div
      v-if="savingSignatureStatus"
    >
      <EmptyState
        v-if="!!mdu && signaturesRequiredByMdu && signaturesRequiredByMdu?.nextSignatureBlockUuid"
        :boxed="false"
        :hide-button="true"
        class="p-10"
      >
        <template #icon>
          <div class="relative w-[120%] h-[120%] bg-indigo-500 rounded-full animate-ping -z-10" />
          <div class="absolute flex items-center justify-center w-full h-full">
            <ForwardIcon class="w-3/4 h-3/4" />
          </div>
        </template>
        <div>{{ $t('signaturePad.signatureStored') }}</div>
        <div>{{ $t('signaturePad.furtherSignaturesRequired') }}</div>
        <div class="mt-8">
          <button
            v-cy="`signature-pad-success-continue-to-next-signature-button`"
            type="button"
            class="btn-primary"
            @click.prevent="handleJumpToSignatureBlock(signaturesRequiredByMdu?.nextRefUuid, mdu?.uuid, false)"
          >
            {{ $t('signaturePad.jumpToNextSignature') }}
          </button>
        </div>
      </EmptyState>
      <SignatureSuccessExternal v-else-if="false && !mau && !showPlgModal">
        <template #success>
          <SignatureSuccess @close="emit('close')" />
        </template>
      </SignatureSuccessExternal>
      <div
        v-else-if="!showPlgModal"
        class="flex flex-col items-center justify-center p-10 pb-20"
      >
        <SignatureSuccess @close="emit('close')" />
      </div>
    </div>

    <template v-if="signatureSecurityLevel === SignatureSecurityLevel.QES && signatureProcess">
      <QESSignatureForms @create-signature="$event => handleCreateSignature(null, $event)" />
    </template>

    <template
      v-else-if="
        isLoadingUserSignatureSeals ||
          (signatureSecurityLevel === SignatureSecurityLevel.QES &&
            (signatureQesProcess?.uuid || isLoadingCreateQesSignature) &&
            !signatureQesProcess?.canceled_at &&
            !savingSignatureStatus)
      "
    >
      <div class="p-10">
        <SpinLoader class="w-5 h-5 mx-auto" />
        <div class="mx-auto mt-2 text-sm font-medium text-center text-gray-500">
          {{ $t('common.pleaseWait') }}…
        </div>
      </div>
    </template>

    <template v-else>
      <div
        v-show="verificationActive && !savingSignatureStatus"
        class="h-full"
      >
        <div
          v-if="!signatureProcess?.uuid"
          class="flex flex-col justify-start h-full"
        >
          <div
            class="px-10 space-y-4 grow"
            :class="isMobile ? 'pt-10 pb-6' : 'pb-4'"
          >
            <div class="flex items-center gap-4">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ $t('signaturePad.confirmPhone') }}
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  {{ $t('signaturePad.verificationInfo') }}
                </p>
              </div>
              <DevicePhoneMobileIcon
                class="w-24 h-24 text-gray-300"
                aria-hidden="true"
              />
            </div>
            <div>
              <PhoneField
                id="mobile-phone"
                :phone="mobile_phone.value.value"
                name="mobile-phone"
                placeholder="+43 664 1234 567"
                style-classes="vue-tel-input-plain"
                :disabled="false"
                :has-error="false"
                :autofocus="false"
                :required="true"
                :show-loader="isLoadingUpdatePhoneNumber"
                @update:phone="($event) => handleUpdatePhoneNumber($event)"
                @validation-error="handlePhoneValidationError"
                @validation-passed="handlePhoneValidationPassed"
              />
              <FormInputErrors
                v-if="errorsToShow?.mobile_phone?.length && !!mobile_phone.value.value"
                :errors="errorsToShow?.mobile_phone"
              />
            </div>
          </div>
          <div
            class="flex items-center justify-between px-6 py-4 mt-6 bg-gray-100 rounded-b-md"
          >
            <button
              type="button"
              class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
              @click.prevent="verificationActive = false"
            >
              <ArrowLeftIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.back') }}
            </button>
            <button
              type="button"
              :disabled="!userFromMdu?.mobile_phone || !!(errorsToShow?.mobile_phone?.length) || isLoadingUpdatePhoneNumber"
              class="inline-flex items-center gap-2 btn-primary"
              @click.prevent="handleCreateSignatureProcess()"
            >
              <span v-if="!isLoadingCreateSignatureProcess">{{ $t('signaturePad.sendCode') }}</span>
              <template v-else>
                <SpinLoader class="w-5 h-5 pointer-events-none" />
                <span>{{ $t('signaturePad.sending') }}…</span>
              </template>
            </button>
          </div>
        </div>

        <div
          v-else-if="signatureProcess?.uuid && signatureProcess?.signature_block_uuid === activeSignatureBlockUuid && !savingSignatureStatus"
          class="flex flex-col justify-start h-full"
        >
          <div class="px-10 py-6 space-y-4 grow">
            <div class="flex items-center gap-4">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ $t('signaturePad.enterCode') }}
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  {{ $t('signaturePad.enterCodeDescription', {number: phone}) }}
                </p>
              </div>
              <KeyIcon
                class="w-24 h-24 text-gray-300"
                aria-hidden="true"
              />
            </div>

            <div
              id="otpInput"
              class="py-4 mx-10"
            >
              <VOtpInput
                ref="otpInput"
                v-model:value="otpInputStore"
                :input-classes="
                  otpError
                    ? 'w-full px-1.5 py-2 mr-2 text-xl rounded-md border-red-300 shadow-sm text-center appearance-none focus:outline-none focus:ring focus:border-red-500 focus:ring-1 focus:bg-red-50 focus:ring-red-500 placeholder:text-gray-400 text-red-900'
                    : 'w-full px-1.5 py-2 mr-2 text-xl rounded-md border-gray-300 shadow-sm text-center appearance-none focus:outline-none focus:ring focus:ring-1 focus:bg-indigo-50 focus:ring-indigo-500 placeholder:text-gray-400 text-gray-900'
                "
                separator=""
                :num-inputs="6"
                input-mode="numeric"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              />

              <div
                v-if="otpError"
                class="flex justify-center mt-4 text-sm text-red-600"
              >
                {{ $t('signaturePad.codeInvalid') || otpError }}
              </div>
              <div
                class="flex items-center justify-center mt-5 space-x-2 text-sm text-center"
                :class="{'text-teal-500': verificationCodeValidSeconds >= 30, 'text-yellow-500': verificationCodeValidSeconds < 30 && verificationCodeValidSeconds > 0, 'text-red-600': verificationCodeValidSeconds === 0}"
              >
                <span>{{ $t('signaturePad.codeValid') }}:</span>
                <Countdown
                  class="font-medium"
                  :timestamp="signatureProcess.valid_till"
                  @update:seconds="verificationCodeValidSeconds = $event"
                />
              </div>
              <div
                v-if="verificationCodeValidSeconds === 0"
                class="flex items-center justify-center mt-3"
              >
                <button
                  type="button"
                  class="btn-primary"
                  :disabled="isLoadingCreateSignatureProcess"
                  @click="handleCreateSignatureProcess"
                >
                  {{ $t('signaturePad.sendNewCode') }}
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between px-6 py-4 bg-gray-100 rounded-b-md">
            <button
              type="button"
              class="inline-flex items-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
              @click.prevent="resetSignatureProcess"
            >
              <ArrowLeftIcon
                class="w-4 h-4 shrink-0"
                aria-hidden="true"
              />
              {{ $t('common.back') }}
            </button>
            <button
              :disabled="isCreateSignatureProcessLoading || savingSignature || verificationCodeValidSeconds === 0"
              type="button"
              class="inline-flex items-center gap-2 btn-primary"
              @click.prevent="handleCreateSignature(otpInputValue)"
            >
              <span v-if="!savingSignature">
                {{ $t('signaturePad.validate') }}
              </span>
              <template v-else>
                <span class="pointer-events-none">
                  <SpinLoader class="w-5 h-5 shrink-0" />
                </span>
                {{ $t('signaturePad.validating') }}…
              </template>
            </button>
          </div>
        </div>
      </div>

      <div
        v-show="!verificationActive && !savingSignatureStatus"
        class="flex flex-col justify-start h-full"
      >
        <div
          class="flex-wrap items-center justify-start gap-2 px-6 md:flex-nowrap lg:flex-wrap"
          :class="isMobile && !isPortrait ? 'hidden' : 'flex lg:block'"
        >
          <div class="block md:hidden lg:pb-2 lg:block">
            <div class="text-lg font-medium text-gray-900">
              {{ $t('signaturePad.signDocument') }}
              {{ signaturesRequiredByMdu?.totalCount > 1 ? ` (${signaturesRequiredByMdu?.totalCount-signaturesRequiredByMdu?.remainingCount+1}/${signaturesRequiredByMdu?.totalCount})` : '' }}
            </div>
          </div>

          <div
            v-show="!verificationActive && !savingSignatureStatus"
            class="relative h-16 w-[calc(100%+3rem)] lg:w-auto px-6 lg:px-0 -mx-6 overflow-x-scroll lg:h-auto lg:-mx-0 lg:overflow-visible lg:border-b lg:border-gray-300"
          >
            <nav
              class="absolute flex items-start h-full gap-2 lg:relative md:grid lg:gap-0 lg:-mb-px"
              :class="userSignatureSeals?.length > 0 ? 'md:grid-cols-4' : 'md:grid-cols-3'"
              aria-label="Tabs"
            >
              <button
                v-if="userSignatureSeals?.length > 0"
                type="button"
                :class="[
                  activeTab === 'saved'
                    ? 'bg-indigo-600 text-white border-indigo-500 lg:text-indigo-600'
                    : 'border-transparent text-indigo-900 hover:text-indigo-950 lg:text-gray-500 lg:hover:text-gray-700 hover:border-gray-400',
                  'py-2 whitespace-nowrap px-3 lg:px-1 bg-indigo-100 lg:bg-transparent rounded-full lg:rounded-none text-center lg:border-b-2 font-medium text-sm',
                ]"
                @click="activeTab = 'saved'"
              >
                <span class="hidden md:inline-block">{{ $t('signaturePad.savedSignature') }}</span>
                <span class="inline-block md:hidden">{{ $t('signaturePad.saved') }}</span>
              </button>
              <button
                type="button"
                :class="[
                  activeTab === 'draw'
                    ? 'bg-indigo-600 text-white border-indigo-500 lg:text-indigo-600'
                    : 'border-transparent text-indigo-900 hover:text-indigo-950 lg:text-gray-500 lg:hover:text-gray-700 hover:border-gray-400',
                  'py-2 whitespace-nowrap px-3 lg:px-1 bg-indigo-100 lg:bg-transparent rounded-full lg:rounded-none text-center lg:border-b-2 font-medium text-sm',
                ]"
                @click="activeTab = 'draw'"
              >
                <span class="hidden md:inline-block">{{ $t('signaturePad.drawSignature') }}</span>
                <span class="inline-block md:hidden">{{ $t('signaturePad.draw') }}</span>
              </button>
              <button
                v-cy="`signature-input-tab`"
                type="button"
                :class="[
                  activeTab === 'type'
                    ? 'bg-indigo-600 text-white border-indigo-500 lg:text-indigo-600'
                    : 'border-transparent text-indigo-900 hover:text-indigo-950 lg:text-gray-500 lg:hover:text-gray-700 hover:border-gray-400',
                  'py-2 whitespace-nowrap px-3 lg:px-1 bg-indigo-100 lg:bg-transparent rounded-full lg:rounded-none text-center lg:border-b-2 font-medium text-sm',
                ]"
                @click="
                  activeTab = 'type';
                  focusInput();
                "
              >
                <span class="hidden md:inline-block">{{ $t('signaturePad.typeSignature') }}</span>
                <span class="inline-block md:hidden">{{ $t('signaturePad.type') }}</span>
              </button>
              <button
                v-cy="`signature-photo-tab`"
                type="button"
                :class="[
                  activeTab === 'photo'
                    ? 'bg-indigo-600 text-white border-indigo-500 lg:text-indigo-600'
                    : 'border-transparent text-indigo-900 hover:text-indigo-950 lg:text-gray-500 lg:hover:text-gray-700 hover:border-gray-400',
                  'py-2 whitespace-nowrap px-3 lg:px-1 bg-indigo-100 lg:bg-transparent rounded-full lg:rounded-none text-center lg:border-b-2 font-medium text-sm',
                ]"
                @click="
                  activeTab = 'photo';
                "
              >
                <span class="hidden md:inline-block">{{ $t('signaturePad.uploadSignature') }}</span>
                <span class="inline-block md:hidden">{{ $t('signaturePad.upload') }}</span>
              </button>
            </nav>
          </div>
        </div>

        <div
          class="px-6 pt-6 bg-gray-100 lg:p-6 grow lg:bg-transparent"
          :class="[isMobile && isPortrait ? 'pt-[6.5rem]' : 'pt-6', isMobile && !isPortrait ? '-mt-4' : '']"
        >
          <div
            v-if="activeTab === 'saved'"
            class="relative block min-h-full py-8 -mx-6 bg-gray-100 lg:py-12 lg:w-full lg:-mx-0 lg:px-12 lg:rounded-md"
            data-cy-sel="signature-saved-container"
          >
            <div
              v-show="activeTab === 'saved'"
              class=""
            >
              <div class="grid gap-6 md:grid-cols-2">
                <div
                  v-for="userSignatureSeal in userSignatureSeals"
                  :key="userSignatureSeal.uuid"
                  class="relative mx-auto rounded-md shadow-lg signature-block signature-block-regular"
                  :class="[selectedUserSignatureSeal?.uuid === userSignatureSeal?.uuid ? 'ring-2 ring-indigo-500' : '']"
                  @click="setSelectedUserSignatureSeal(userSignatureSeal)"
                >
                  <span
                    class="absolute z-50 p-1 text-gray-400 transition-all duration-200 rounded-md cursor-pointer pointer-events-auto right-2 bottom-2 hover:bg-gray-100"
                    :class="uuidsOfIsRemovingUserSignatureSeal.includes(userSignatureSeal.uuid) ? 'bg-gray-100' : 'hover:text-red-400'"
                    data-tippy-context
                    data-placement="right"
                    :data-tippy-content="$t('signaturePad.deleteSignature')"
                    :disabled="uuidsOfIsRemovingUserSignatureSeal.includes(userSignatureSeal.uuid)"
                    @click.stop.prevent="removeUserSignatureSeal(userSignatureSeal.uuid)"
                  >
                    <SpinLoader
                      v-if="uuidsOfIsRemovingUserSignatureSeal.includes(userSignatureSeal.uuid)"
                      class="w-4 h-4 shrink-0"
                    />
                    <TrashIcon
                      v-else
                      class="w-4 h-4 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                  <SignatureSignatoryDisplay
                    :is-editor-node="true"
                    :signatory="mdu"
                    :party-uuid="mdu?.party_uuid"
                    :signature="{
                      signature_seal: userSignatureSeal.signature_seal,
                      uuid: '',
                      created_at: '0',
                      signature_block_uuid: null,
                      signing_phase_uuid: null,
                      created_by_document_user_uuid: mdu?.uuid,
                      signatory_email: mdu?.email,
                      signature_security_level: signatureSecurityLevel
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            id="signatureCanvasContainer"
            class="relative block w-full bg-gray-100 lg:rounded-md"
            data-cy-sel="signature-canvas-container"
            :class="[isPortrait ? 'aspect-[2/1]' : 'aspect-[768/168]']"
          >
            <div
              v-show="activeTab === 'draw'"
              class="absolute inset-0 pointer-events-none"
            >
              <div class="z-0 block">
                <div
                  class="absolute inset-0 border-gray-300 lg:inset-x-6 lg:bottom-6 lg:mb-px"
                  :class="[isMobile ? 'border-2' : 'border-b-2' ]"
                />
                <div
                  v-if="activeTab === 'draw' && !(toDataURL())"
                  class="absolute inset-0 flex items-center justify-center pt-2 text-sm text-gray-300"
                >
                  {{ $t('signaturePad.drawHere') }}
                </div>
              </div>
            </div>
            <VPerfectSignature
              v-show="activeTab === 'draw'"
              v-if="initVPerfectSignatureComponent"
              ref="signaturePad"
              :pen-color="colorSelected"
              style="position: relative"
              class="z-10 w-full h-full"
              :stroke-options="strokeOptions"
              data-cy-sel="signature-canvas"
            />
            <div
              v-show="activeTab === 'type'"
              class="block"
              :class="isMobile && isPortrait ? 'pt-0' : 'pt-16 lg:pt-5 lg:pb-6 lg:px-6'"
            >
              <!-- Force Tailwind to compile font -->
              <span class="font-['Dancing_Script'] hidden" />
              <input
                id="SignatureInput"
                v-model="signaturePadText"
                v-cy="`signature-input`"
                type="text"
                :placeholder="$t('signaturePad.typeHere') + '…'"
                :style="'color: ' + colorSelected"
                class="border-0 placeholder-gray-300 bg-transparent border-gray-300 w-full h-28 text-center text-5xl font-['Kalam'] focus:outline-none focus:ring-0 focus:border-gray-500"
                :class="isPortrait ? 'border-2' : 'border-b-2'"
              >
            </div>
            <div
              v-show="activeTab === 'type' || activeTab === 'draw'"
              class="absolute z-20"
              :class="[isMobile && isPortrait ? '-mt-20 inset-x-0' : '', isMobile ? 'top-4 right-4' : 'top-2 right-2']"
            >
              <div class="flex flex-wrap items-center justify-center -mx-2">
                <template
                  v-for="(color, colorIdx) in colors"
                  :key="colorIdx"
                >
                  <div class="px-2">
                    <template v-if="colorSelected === color">
                      <div
                        class="inline-flex w-8 h-8 border-4 border-white rounded-full cursor-pointer"
                        :style="`background: ${color}; box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);`"
                      />
                    </template>

                    <template v-if="colorSelected !== color">
                      <div
                        role="checkbox"
                        tabindex="0"
                        :aria-checked="!!colorSelected"
                        class="inline-flex w-8 h-8 border-4 border-white rounded-full cursor-pointer focus:outline-none focus:shadow-outline"
                        :style="`background: ${color};`"
                        @click="handleColorChange(color)"
                        @keydown.enter="colorSelected = color"
                      />
                    </template>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="activeTab === 'photo'">
              <SignatureUpload
                ref="signatureUpload"
                :color="colorSelected"
                @change="setPhotoBlob"
              />
            </div>
          </div>
          <div
            v-if="!isMobile && !isPortrait"
            class="flex items-center gap-3 pt-6 text-sm text-gray-500"
          >
            <ShieldCheckIcon class="w-16 h-16 text-gray-300" />
            {{ $t('signaturePad.signingInfo') }}
          </div>
        </div>

        <div class="items-center gap-4 px-6 py-4 text-center bg-gray-100 md:flex md:justify-between rounded-b-md">
          <div
            class="flex flex-wrap items-center gap-2 mb-4 sm:flex-nowrap sm:justify-between md:mb-0"
            :class="activeTab === 'draw' ? 'justify-between' : 'justify-center'"
          >
            <button
              v-if="activeTab === 'draw'"
              type="button"
              class="flex items-center self-center gap-2 btn-plain hover:bg-gray-200 focus:ring-gray-200"
              @click.prevent="clearSignature()"
            >
              <EraserIcon class="w-5 h-5 shrink-0" />
              <span>{{ $t('signaturePad.clearSignature') }}</span>
            </button>
            <div
              v-if="!!mau && activeTab !== 'saved'"
              class="self-center block md:hidden"
            >
              <label class="flex items-center">
                <Checkbox
                  v-model:checked="saveForFuture"
                  value="1"
                  element-id="saveForFuture"
                  name="saveForFuture"
                />
                <span class="ml-2 text-sm text-gray-600">{{ $t('signaturePad.saveForFuture') }}</span>
              </label>
            </div>
          </div>
          <div class="flex items-center gap-4">
            <div
              v-if="!!mau && activeTab !== 'saved'"
              class="hidden md:block"
            >
              <label class="flex items-center">
                <Checkbox
                  v-model:checked="saveForFuture"
                  value="1"
                  element-id="saveForFuture"
                  name="saveForFuture"
                />
                <span class="ml-2 text-sm text-gray-600">{{ $t('signaturePad.saveForFuture') }}</span>
              </label>
            </div>
            <button
              v-cy="`submit-signature-button`"
              :disabled="savingSignature || !signatureReady"
              type="button"
              class="inline-flex items-center justify-center grow btn-primary md:grow-0"
              @click.prevent="handleClickSaveSignatureButton"
            >
              <div
                v-if="savingSignature || isLoadingCreateSignatureProcess"
                class="inline-flex items-center gap-x-2"
              >
                <span class="pointer-events-none">
                  <SpinLoader class="w-5 h-5 shrink-0" />
                </span>
                <span>{{ $t('common.saving') }}…</span>
              </div>
              <span
                v-else
                class="inline-flex items-center"
              >
                <SignatureIcon
                  class="-ml-1 mr-1.5 h-5 w-5"
                />
                <span v-if="signatureSecurityLevel === SignatureSecurityLevel.AES || signatureSecurityLevel === SignatureSecurityLevel.QES">{{ $t('signaturePad.confirmVerify') }}</span>
                <span v-else>{{ $t('signaturePad.confirmSign') }}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
