import { Extension } from "@tiptap/core"

import { Column } from "./column"
import { ColumnBlock } from "./columnBlock"

export interface ColumnExtensionOptions {
  column?: boolean;
  columnBlock?: boolean;
}

export const ColumnExtension = Extension.create<ColumnExtensionOptions>(
  {
    name: "columnExtension",

    addOptions () {
      return {
        column: true,
        columnBlock: true,
      }
    },

    addExtensions () {
      return [
        Column,
        ColumnBlock,
      ]
    },
  },
)

export { Column, ColumnBlock }

export default ColumnExtension
