<script setup lang="ts">
// external
import { computed } from "vue"
import dayjs from "dayjs"

// internal
import { DocumentCalendarEntry } from "~/types"
import DocumentCalendarEntryLink from "./DocumentCalendarEntryLink.vue"
import { isToday } from "~/utils/helpers"
import { useI18n } from "vue-i18n"

interface Props {
  date: Date | number
  calendarDocuments: DocumentCalendarEntry[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const perDayLimit = 100

// Remember JS date months start at 0
const getDaysOfMonth = (month = 3, year = 2023) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  let firstDayOfMonth = new Date(year, month, 1).getDay()
  let lastDayOfMonth = new Date(year, month, daysInMonth).getDay()

  if (firstDayOfMonth === 0) {
    firstDayOfMonth = 7
  }

  if (lastDayOfMonth === 0) {
    lastDayOfMonth = 7
  }

  const previousMonth = month === 0 ? 11 : month - 1
  const previousYear = month === 0 ? year - 1 : year
  const daysInPreviousMonth = new Date(previousYear, previousMonth + 1, 0).getDate()

  const nextMonth = month === 11 ? 0 : month + 1
  const nextYear = month === 11 ? year + 1 : year
  const numberOfNextMonthDays = 7 - lastDayOfMonth

  const days = []

  // days from previous month
  for (let i = 2; i <= firstDayOfMonth; i++) {
    const date = new Date(previousYear, previousMonth, daysInPreviousMonth - (firstDayOfMonth - i))

    days.push({
      date: date,
      isCurrentMonth: false,
      isToday: isToday(date),
      isSelected: false,
      documents: getDocumentsByDate(date),
    })
  }

  // days from current month
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i)

    days.push({
      date: date,
      isCurrentMonth: true,
      isToday: isToday(date),
      isSelected: false,
      documents: getDocumentsByDate(date),
    })
  }

  // days from next month
  for (let i = 1; i <= numberOfNextMonthDays; i++) {
    const date = new Date(nextYear, nextMonth, i)

    days.push({
      date: date,
      isCurrentMonth: false,
      isToday: isToday(date),
      isSelected: false,
      documents: getDocumentsByDate(date),
    })
  }

  return days
}

const days = computed(() => {
  const date = new Date(props.date)
  return getDaysOfMonth(date.getMonth(), date.getFullYear())
})

const getDocumentsByDate = (date) => {
  if (props.calendarDocuments.length) {
    return props.calendarDocuments.filter((entry) => {
      return entry.date === dayjs(date).format("YYYY-MM-DD")
    })
  }
  return []
}

const daysWithDocuments = computed(() => days.value.filter((day) => !!day.documents.length))

const gridRowClass = computed(() => {
  const rows = days.value.length / 7

  switch (rows) {
    case 4:
      return "grid-rows-4"
    case 5:
    default:
      return "grid-rows-5"
    case 6:
      return "grid-rows-6"
  }
})

const { locale } = useI18n()

const weekdays = computed(() => {
  const d = new Date()
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  const monday = new Date(d.setDate(diff))

  const weekdays = []
  for (let i = 1; i <= 7; i++) {
    weekdays.push(new Date(monday.setDate(i)).toLocaleString(locale.value, { weekday: "short" }))
  }

  return weekdays
})

</script>

<template>
  <div class="w-full grow flex flex-col rounded-lg relative overflow-hidden">
    <div
      class="hidden @4xl:grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 @4xl:flex-none"
    >
      <div
        v-for="weekday in weekdays"
        :key="weekday"
        class="bg-white py-2"
      >
        {{ weekday }}
      </div>
    </div>
    <div class="hidden @4xl:flex grow overflow-y-auto bg-gray-200 text-xs leading-6 text-gray-700">
      <div
        class="w-full grid grid-cols-7 gap-px"
        :class="gridRowClass"
      >
        <div
          v-for="day in days"
          :key="day.date"
          class="relative px-3 py-2 overflow-y-auto"
          :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500']"
        >
          <div>
            <time
              v-if="day.documents.length === 0"
              :datetime="day.date"
              :class="day.isToday ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white' : undefined"
            >{{ new Date(day.date).getDate() }}</time>
            <div
              v-else
              class="-mt-0.5 -ml-0.5 relative rounded-md w-6 h-6 shrink-0"
              :class="day.isToday ? 'bg-indigo-500 text-white' : 'bg-gray-100 text-gray-900'"
            >
              <div
                class="absolute top-[2px] left-[2px] right-[2px] h-1 rounded-t-md bg-gray-200"

                :class="day.isToday ? 'bg-indigo-300' : 'bg-gray-200'"
              />
              <div class="flex items-center justify-center pt-1.5">
                <span class="text-xs font-medium">{{ new Date(day.date).getDate() }}</span>
              </div>
            </div>
            <ol
              v-if="day.documents.length > 0"
              class="mt-1"
            >
              <li
                v-for="document in day.documents.slice(0, perDayLimit)"
                :key="document.uuid"
              >
                <DocumentCalendarEntryLink
                  :entry="document"
                />
              </li>
              <li
                v-if="day.documents.length > perDayLimit"
                class="text-gray-500"
              >
                + {{ day.documents.length - perDayLimit }} more
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grow @4xl:hidden px-4 py-3 bg-white space-y-1 overflow-y-auto"
    >
      <div
        v-if="!daysWithDocuments.length"
        class="w-full h-full flex items-center justify-center"
      >
        <span class="text-xs text-gray-400 py-7">{{ $t('dashboard.documentCalendar.noEvents') }}</span>
      </div>
      <div
        v-for="(day, dayIdx) in daysWithDocuments"
        :key="'day_' + dayIdx"
        class="flex space-x-2"
      >
        <div
          v-if="day.documents.length"
          class="relative rounded-md w-6 h-6 shrink-0"

          :class="day.isToday ? 'bg-indigo-500 text-white' : 'bg-gray-100 text-gray-900'"
        >
          <div
            class="absolute top-[2px] left-[2px] right-[2px] h-1 rounded-t-md bg-gray-200"

            :class="day.isToday ? 'bg-indigo-300' : 'bg-gray-200'"
          />
          <div class="flex items-center justify-center mt-1.5">
            <span class="text-xs font-medium">{{ new Date(day.date).getDate() }}</span>
          </div>
        </div>
        <div class="pt-1 space-y-1">
          <DocumentCalendarEntryLink
            v-for="(entry, entryIdx) in day.documents"
            :key="'entry_' + entryIdx"
            :entry="entry"
            class="text-xs"
          />
        </div>
      </div>
    </div>
  </div>
</template>
