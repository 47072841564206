import {
  writeDocx,
  defaultNodes,
  defaultMarks,
  DocxSerializerState,
  MarkSerializer,
  NodeSerializer,
} from "prosemirror-docx"
import { createShortId } from "prosemirror-docx/dist/esm/utils.js"
import { createNumbering, resetNumberingState } from "./numbering"
import { generateSignatureBlock, signatureBlockState, setSignatureBlockState } from "./signatureBlock"
import { generateToc } from "./toc"
import {
  IRunOptions,
  IParagraphOptions,
  HeadingLevel,
  UnderlineType,
  LineRuleType,
  Document,
  SectionType,
  File,
  IDocumentDefaultsOptions,
  IBaseParagraphStyleOptions,
  IBaseCharacterStyleOptions,
  convertInchesToTwip,
  ICommentOptions,
  ICommentsOptions,
} from "docx"
import * as docx_1 from "docx"
import { saveAs } from "file-saver"
import { Node, NodeType } from "@tiptap/pm/model"
import { useCommentStore, useDynamicFieldStore, useFileStorageStore, useImageStore, usePartyStore, useSectionRefStore, useUserStore } from "~/stores"
import { storeToRefs } from "pinia"
import { Comment, DocumentUser, DynamicField, JuneEvents, MultiFieldType, Party } from "~/types"
import { DelimiterStyle, NumberingOptions, NumberingStyle, NumberingType } from "../extensions/list/list"
import { configureDefaultRunOptionsForNode, configureDefaultParagraphOptionsForNode } from "./utils"
import { table } from "./table"
import { column } from "./column"
import { testImage } from "./testImage"
import { convertCurrencyString, formatDateLocalized, getUserInitials, getUserRepresentation, hexColor, isoDurationToWords, trackEventInJune } from "~/utils"
import { generateJSON } from "@tiptap/core"
import { Document as DocumentExtension } from "@tiptap/extension-document"
import { Paragraph as ParagraphExtension } from "@tiptap/extension-paragraph"
import { Text as TextExtension } from "@tiptap/extension-text"

interface IChangedAttributesProperties {
  readonly id: number;
  readonly author: string;
  readonly date: string;
}

interface IInsertedRunOptions extends IChangedAttributesProperties, IRunOptions {}

interface IDefaultStylesOptions {
  readonly document?: IDocumentDefaultsOptions;
  readonly title?: IBaseParagraphStyleOptions;
  readonly heading1?: IBaseParagraphStyleOptions;
  readonly heading2?: IBaseParagraphStyleOptions;
  readonly heading3?: IBaseParagraphStyleOptions;
  readonly heading4?: IBaseParagraphStyleOptions;
  readonly heading5?: IBaseParagraphStyleOptions;
  readonly heading6?: IBaseParagraphStyleOptions;
  readonly strong?: IBaseParagraphStyleOptions;
  readonly listParagraph?: IBaseParagraphStyleOptions;
  readonly hyperlink?: IBaseCharacterStyleOptions;
  readonly footnoteReference?: IBaseCharacterStyleOptions;
  readonly footnoteText?: IBaseParagraphStyleOptions;
  readonly footnoteTextChar?: IBaseCharacterStyleOptions;
}

interface CustomIInsertedRunOptions extends IInsertedRunOptions {
  uuid: string;
}

interface CustomOptions {
  includeComments: boolean
  includeTrackedChanges: boolean
  getImageBuffer: (uuid: string) => Buffer
}

class CustomDocxSerializer {
  nodes: NodeSerializer
  marks: MarkSerializer
  changes: CustomIInsertedRunOptions[]
  constructor (nodes: NodeSerializer, marks: MarkSerializer, changes: CustomIInsertedRunOptions[]) {
    this.nodes = nodes
    this.marks = marks
    this.changes = changes
  }
  serialize (content: Node, options: CustomOptions, comments: ICommentsOptions) {
    const state = new DocxSerializerState(this.nodes, this.marks, options)
    state.maxImageWidth = 1000
    state.renderContent(content)
    const doc = createDocFromState(state, options, comments)
    return doc
  }
}

const customCurrentNumberingState = {
  elementCounter: 0,
}

const localeHolder = {
  locale: "en",
  t: null,
}

const createDocFromState = (state: DocxSerializerState, options: CustomOptions, comments: ICommentsOptions) => {
  let documentOptions = {
    footnotes: state.footnotes,
    numbering: {
      config: state.numbering,
    },
    styles: {
      default: {
        document: {
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 300,
              after: 200,
              lineRule: LineRuleType.AT_LEAST,
            },
          },
        },
        heading1: {
          run: {
            font: "Helvetica",
            size: 52,
            bold: true,
          },
          paragraph: {
            spacing: {
              line: 400,
              after: 200,
            },
          },
        },
        heading2: {
          run: {
            font: "Helvetica",
            size: 30,
            bold: true,
          },
          paragraph: {
            spacing: {
              line: 300,
              after: 200,
            },
          },
        },
        heading3: {
          run: {
            font: "Helvetica",
            size: 20,
            bold: true,
          },
          paragraph: {
            spacing: {
              line: 300,
              after: 200,
            },
          },
        },
        fineprint: {
          run: {
            font: "Helvetica",
            size: 16,
          },
          paragraph: {
            spacing: { line: 250 },
          },
        },
        listParagraph: {
          paragraph: {
            spacing: {
              line: 300,
              after: 200,
            },
          },
        },
      } as IDefaultStylesOptions,
      paragraphStyles: [
        {
          id: "TOC1",
          name: "TOC 1",
          basedOn: "default",
          quickFormat: true,
          paragraph: {
            indent: { left: convertInchesToTwip(0), hanging: convertInchesToTwip(0.18) },
          },
        },
        {
          id: "TOC2",
          name: "TOC 2",
          basedOn: "default",
          quickFormat: true,
          paragraph: {
            indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
          },
        },
        {
          id: "TOC3",
          name: "TOC 3",
          basedOn: "default",
          quickFormat: true,
          paragraph: {
            indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.18) },
          },
        },
      ],
    },
    sections: [
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: state.children,
      },
    ],
  } as any
  if (options.includeTrackedChanges) {
    documentOptions = {
      ...documentOptions,
      features: {
        // do do: define if to make this configurable
        // trackRevisions: true,
        trackRevisions: true,
      },
    }
  }
  if (options.includeComments && comments.children?.length > 0) {
    documentOptions = {
      ...documentOptions,
      comments: comments,
    }
  }
  const doc = new Document(documentOptions)
  return doc
}

const trackedChangeText = (
  state: DocxSerializerState,
  text: string,
  opts: IInsertedRunOptions,
  type: "insertion" | "deletion",
  uuid: string,
) => {
  if (!text) return
  const { id, author, date } = docxSerializer.changes?.find((change) => change.uuid === uuid)
  if (type === "insertion") {
    state.current.push(new docx_1.InsertedTextRun(Object.assign(Object.assign({
      text,
      id,
      author,
      date,
    }, state.nextRunOpts), opts)))
  }
  else if (type === "deletion") {
    state.current.push(new docx_1.DeletedTextRun(Object.assign(Object.assign({
      text,
      id,
      author,
      date,
    }, state.nextRunOpts), opts)))
  }
  delete state.nextRunOpts
}

const renderList = (node: Node, style: string, state: DocxSerializerState, numberingOptions: NumberingOptions = null, runOptions: IRunOptions = {}, paragraphOptions: IParagraphOptions = {}) => {
  customCurrentNumberingState.elementCounter = 0
  if (!state.currentNumbering) {
    const nextId = (createShortId)()
    state.numbering.push((createNumbering)(nextId, style, numberingOptions, runOptions, paragraphOptions))
    state.currentNumbering = { reference: nextId, level: 0 }
  }
  else {
    const { reference, level } = state.currentNumbering
    state.currentNumbering = { reference, level: level + 1 }
  }
  state.renderContent(node)
  if (state.currentNumbering.level === 0) {
    delete state.currentNumbering
  }
  else {
    const { reference, level } = state.currentNumbering
    state.currentNumbering = { reference, level: level - 1 }
  }
}

const renderInline = (parent: Node, state: DocxSerializerState) => {
  // Pop the stack over to this object when we encounter a link, and closeLink restores it
  let currentLink
  const closeLink = () => {
    if (!currentLink)
      return
    const hyperlink = new docx_1.ExternalHyperlink({
      link: currentLink.link,
      // child: this.current[0],
      children: state.current,
    })
    state.current = [ ...currentLink.stack, hyperlink ]
    currentLink = undefined
  }
  const openLink = (href) => {
    const sameLink = href === (currentLink === null || currentLink === void 0 ? void 0 : currentLink.link)
    state.addRunOptions({ style: "Hyperlink" })
    // TODO: https://github.com/dolanmiu/docx/issues/1119
    // Remove the if statement here and oneLink!
    const oneLink = true
    if (!oneLink) {
      closeLink()
    }
    else {
      if (currentLink && sameLink)
        return
      if (currentLink && !sameLink) {
        // Close previous, and open a new one
        closeLink()
      }
    }
    currentLink = {
      link: href,
      stack: state.current,
    }
    state.current = []
  }
  const progress = (node, offset, index) => {
    const links = node.marks.filter((m) => m.type.name === "link")
    const hasLink = links.length > 0
    if (hasLink) {
      openLink(links[0].attrs.href)
    }
    else if (!hasLink && currentLink) {
      closeLink()
    }
    if (node.isText) {
      let marks = [ ...node.marks ]
      // Check if insertion or deletion mark is present, if so, mark as tracked change
      const isDeletion = marks.some((m) => m.type.name === "deletion")
      const isInsertion = marks.some((m) => m.type.name === "insertion")
      // strip out the deletion and insertion marks
      marks = marks.filter((m) => m.type.name !== "deletion" && m.type.name !== "insertion")
      // If there is no textStyle mark, add it
      if (!marks.some((m) => m.type.name === "textStyle")) {
        marks = [ { type: { name: "textStyle" }, attrs: {} } ]
      }
      const text = node.text || " "
      if (isDeletion || isInsertion) {
        trackedChangeText(state, node.text, state.renderMarks(node, marks) as IInsertedRunOptions, isDeletion ? "deletion" : "insertion", parent.attrs?.uuid)
      } else {
        state.text(text, state.renderMarks(node, marks))
      }
    }
    else {
      state.render(node, parent, index)
    }
  }
  parent.forEach(progress)
  // Must call close at the end of everything, just in case
  closeLink()
}

let commentRangeEnds = []

const createIndent = (level: number) => {
  return {
    indent: { left: convertInchesToTwip(level / 2), hanging: convertInchesToTwip(0.0) },
  }
}

const closeBlock = (state: DocxSerializerState, node: Node, props: IParagraphOptions) => {
  if (commentRangeEnds.length > 0) {
    commentRangeEnds.forEach((commentRangeEnd) => {
      state.current.push(commentRangeEnd)
    })
    // Clear comment range ends
    commentRangeEnds = []
  }
  const paragraph = new docx_1.Paragraph(Object.assign(Object.assign({ children: state.current }, state.nextParentParagraphOpts), props))
  state.current = []
  delete state.nextParentParagraphOpts
  state.children.push(paragraph)
}

const renderCommentMarkers = (state: DocxSerializerState, node: Node) => {
  if (node.attrs.uuid) {
    const commentStore = useCommentStore()
    const { comments } = storeToRefs(commentStore)
    const matchingComments = comments.value?.filter((el) => el.prosemirror_data_uuid === node.attrs.uuid && el.scope === "internal_and_external" && !el.is_resolved && (el.type === "comment" || (el.type === "proposal" && el.html)))
    if (matchingComments?.length > 0) {
      matchingComments.forEach((comment) => {
        insertCommentMarkers(state, comment)
        // Concat all child comments, identified by their comment_uuid
        const childComments = comments.value?.filter((el) => el.comment_uuid === comment.uuid && el.scope === "internal_and_external" && !el.is_resolved && (el.type === "comment" || (el.type === "proposal" && el.html)))
        childComments?.forEach((child) => {
          insertCommentMarkers(state, child)
        })
      })
    }
  }
}

const insertCommentMarkers = (state: DocxSerializerState, comment: Comment) => {
  const commentRangeStart = new docx_1.CommentRangeStart(comment.ref_uuid || comment.uuid)
  state.current.push(commentRangeStart)
  const commentRangeEnd = new docx_1.CommentRangeEnd(comment.ref_uuid || comment.uuid)
  commentRangeEnds.push(commentRangeEnd)
  const commentReference = new docx_1.CommentReference(comment.ref_uuid || comment.uuid)
  state.current.push(commentReference)
}

const nodeSerializer: NodeSerializer = {
  ...defaultNodes,
  heading (state: DocxSerializerState, node: Node) {
    renderCommentMarkers(state, node)
    state.addRunOptions(configureDefaultRunOptionsForNode(state, node, { bold: true }))
    let paragraphOptions: IParagraphOptions = {}
    customCurrentNumberingState.elementCounter++
    if (state.currentNumbering && customCurrentNumberingState.elementCounter < 2) {
      paragraphOptions = {
        numbering: state.currentNumbering,
      }
    } else if (state.currentNumbering) {
      paragraphOptions = createIndent((state.currentNumbering?.level + 1) / 2)
    }
    paragraphOptions = {
      ...paragraphOptions,
      spacing: {
        before: 300,
        after: 100,
        line: 400,
        lineRule: LineRuleType.AT_LEAST,
      },
    }
    state.addParagraphOptions(configureDefaultParagraphOptionsForNode(state, node, paragraphOptions))
    renderInline(node, state)
    const heading = [
      HeadingLevel.HEADING_1,
      HeadingLevel.HEADING_2,
      HeadingLevel.HEADING_3,
      HeadingLevel.HEADING_4,
      HeadingLevel.HEADING_5,
      HeadingLevel.HEADING_6,
    ][node.attrs.level - 1]
    closeBlock(state, node, { heading })
  },
  paragraph: (state: DocxSerializerState, node: Node) => {
    renderCommentMarkers(state, node)
    let paragraphOptions: IParagraphOptions = {}
    customCurrentNumberingState.elementCounter++
    if (state.currentNumbering && customCurrentNumberingState.elementCounter < 2) {
      paragraphOptions = {
        numbering: state.currentNumbering,
      }
    } else if (state.currentNumbering) {
      paragraphOptions = createIndent((state.currentNumbering?.level + 1) / 2)
    }
    state.addRunOptions(configureDefaultRunOptionsForNode(state, node))
    state.addParagraphOptions(configureDefaultParagraphOptionsForNode(state, node, paragraphOptions))
    renderInline(node, state)
    closeBlock(state, node, {})
  },
  columnBlock: (state: DocxSerializerState, node: Node) => {
    column(node, state)
  },
  hardBreak (state) {
    state.addRunOptions({ break: 1 })
  },
  dynamicField (state: DocxSerializerState, node: Node) {
    const dynamicFieldStore = useDynamicFieldStore()
    const { dynamicFields } = storeToRefs(dynamicFieldStore)
    const dynamicField = dynamicFields.value?.find((dynamicField: DynamicField) => dynamicField.ref_uuid === node.attrs.refUuid)
    let returnText = "[ n/a ]"
    if (dynamicField) {
      if (dynamicField.value) {
        let valueToReturn = dynamicField.value
        switch (dynamicField.type) {
          case MultiFieldType.date:
            valueToReturn = formatDateLocalized(dynamicField.value, localeHolder.locale)
            break
          case MultiFieldType.currency:
            valueToReturn = convertCurrencyString(dynamicField.value, dynamicField.format, localeHolder.locale, localeHolder.t)
            break
          case MultiFieldType.currency_duration:
            valueToReturn = convertCurrencyString(dynamicField.value, dynamicField.format, localeHolder.locale, localeHolder.t)
            break
          case MultiFieldType.duration:
            valueToReturn = isoDurationToWords(dynamicField.value, localeHolder.t)
            break
          default:
            break
        }
        returnText = valueToReturn
      } else {
        returnText = "[ " + dynamicField.name + " ]"
      }
    }
    state.addRunOptions(configureDefaultRunOptionsForNode(state, node))
    const marks = [ ...node.marks ]
    state.text(returnText, state.renderMarks(node, marks))
  },
  party: (state: DocxSerializerState, node: Node) => {
    const partyStore = usePartyStore()
    const { parties } = storeToRefs(partyStore)
    const party = parties.value?.find((party: Party) => party.ref_uuid === node.attrs.refUuid)
    let returnText = "[ n/a ]"
    if (party) {
      returnText = party.entity_name ? party.entity_name : "[ " + party.name + " ]"
    }
    state.addRunOptions(configureDefaultRunOptionsForNode(state, node))
    const marks = [ ...node.marks ]
    state.text(returnText, state.renderMarks(node, marks))
  },
  sectionref: (state: DocxSerializerState, node: Node) => {
    const sectionRefStore = useSectionRefStore()
    const { sections } = storeToRefs(sectionRefStore)
    const section = sections.value.find((section) => section.uuid === node.attrs.uuid)
    const sectionLabel = section && section?.title ? section.title : "[ n/a ]"
    state.addRunOptions(configureDefaultRunOptionsForNode(state, node))
    const marks = [ ...node.marks ]
    state.text(sectionLabel, state.renderMarks(node, marks))
  },
  toc: (state: DocxSerializerState, node: Node) => {
    generateToc(state, node)
    closeBlock(state, node, {})
  },
  signatureBlock: (state: DocxSerializerState, node: Node) => {
    if (state.children.length < 2) {
      signatureBlockState.counter = 0
    }
    generateSignatureBlock(state, node)
    if (signatureBlockState.counter === 2) {
      state.children.push(new docx_1.Paragraph(""))
      setSignatureBlockState(0)
    }
  },
  signatureContainer: (state: DocxSerializerState, node: Node) => {
    const signatureBlocks = node.attrs.signatureBlocks
    signatureBlocks.forEach((signatureBlock: any) => {
      if (state.children.length < 2) {
        signatureBlockState.counter = 0
      }
      generateSignatureBlock(state, signatureBlock)
      if (signatureBlockState.counter === 2) {
        state.children.push(new docx_1.Paragraph(""))
        setSignatureBlockState(0)
      }
    })
    if (signatureBlockState.counter === 1) {
      state.children.push(new docx_1.Paragraph(""))
      setSignatureBlockState(0)
    }
    closeBlock(state, node, { spacing: { after: 300 } } )
  },
  fineprint: (state: DocxSerializerState, node: Node) => {
    state.addRunOptions(configureDefaultRunOptionsForNode(state, node, { size: 16 }))
    renderInline(node, state)
    closeBlock(state, node, {})
  },
  list: (state: DocxSerializerState, node: Node) => {
    const numberingOptions = {
      delimiterStyle: node.attrs.delimiterStyle as DelimiterStyle,
      numberingStyle: node.attrs.numberingStyle as NumberingStyle,
      numberingType: node.attrs.numberingType as NumberingType,
      showNestedOrderRepresentation: node.attrs.showNestedOrderRepresentation as boolean,
      order: node.attrs.order as number,
      orderRepresentation: node.attrs.orderRepresentation as string,
      parentOrderRepresentation: node.attrs.parentOrderRepresentation as string,
      parentDelimiterStyle: node.attrs.parentDelimiterStyle as DelimiterStyle,
    }
    const style = node.attrs.kind === "ordered" ? node.attrs.showNestedOrderRepresentation ? "legal" : "numbered" : "bullets"
    renderList(node, style, state, numberingOptions)
  },
  resizableImage: (state: DocxSerializerState, node: Node) => {
    if (!node.attrs.uuid) return
    // TODO: Add image capabilities when we can provide them in non webp format
    //image(state, node.attrs.uuid, node.attrs.widthInPx, node.attrs.width, node.attrs.dataAlign, node.attrs.dataFloat)
  },
  table: (state: DocxSerializerState, node: Node) => {
    table(node, state)
  },
  pageBreak: (state: DocxSerializerState, node: Node) => {
    closeBlock(state, node, { pageBreakBefore: true })
  },
}

const markSerializer: MarkSerializer = {
  ...defaultMarks,
  bold: (state) => {
    return {
      ...state.nextRunOpts,
      bold: true,
      font: { name: "Helvetica" },
    }
  },
  italic: (state) => {
    return {
      ...state.nextRunOpts,
      italics: true,
      font: { name: "Helvetica" },
    }
  },
  underline: (state) => {
    return {
      ...state.nextRunOpts,
      underline: { type: UnderlineType.SINGLE },
      font: { name: "Helvetica" },
    }
  },
  strikethrough: (state) => {
    return {
      ...state.nextRunOpts,
      strike: true,
      font: { name: "Helvetica" },
    }
  },
  subscript (state) {
    return {
      ...state.nextRunOpts,
      subScript: true,
      font: { name: "Helvetica" },
    }
  },
  superscript (state) {
    return {
      ...state.nextRunOpts,
      superScript: true,
      font: { name: "Helvetica" },
    }
  },
  strike (state) {
    return {
      ...state.nextRunOpts,
      strike: true,
      font: { name: "Helvetica" },
    }
  },
  textStyle (state, node, mark) {
    // If the color is present in the form of an rgb value like rgb(109, 110, 113), convert it to hex
    const color = mark.attrs?.color ? hexColor(mark.attrs?.color) : null
    const textStyle = {
      ...state.nextRunOpts,
      color: color,
      font: { name: "Helvetica" },
    }
    if (mark.attrs?.fontSize) {
      // In Word, we use 20 as the base font size
      // In the editor, it is 12
      textStyle.size = mark.attrs.fontSize * (20 / 12)
    }
    return textStyle
  },
  link () {
    // Note, this is handled specifically in the serializer
    // Word treats links more like a Node rather than a mark
    return {}
  },
  deletion (state, node, mark) {
    // Read IChangedAttributesProperties from mark
    const { id, author, date } = mark.attrs as IChangedAttributesProperties
    return {
      ...state.nextRunOpts,
      id,
      author,
      date,
    }
  },
  insertion (state, node, mark) {
    // Read IChangedAttributesProperties from mark
    const { id, author, date } = mark.attrs as IChangedAttributesProperties
    return {
      ...state.nextRunOpts,
      id,
      author,
      date,
    }
  },

}

const enrichCommentsWithUserRepresentation = (html: any, mentionedDocumentUserUuids: DocumentUser["uuid"][]) => {
  if (!html) return
  const userStore = useUserStore()
  const { users } = storeToRefs(userStore)
  let content = html
  if (mentionedDocumentUserUuids?.length) {
    for (let i = 0; i < mentionedDocumentUserUuids.length; i++) {
      const mentionedUser = users.value.find((user) => user.uuid === mentionedDocumentUserUuids[i])
      if (mentionedUser) {
        content = content.replaceAll(`@${mentionedDocumentUserUuids[i]}`, getUserRepresentation(mentionedUser))
      }
    }
  }
  return content
}

const docxSerializer = new CustomDocxSerializer(nodeSerializer, markSerializer, [])

export const writeProsemirrorDataToDocx = async (doc: Node, includeComments = true, includeTrackedChanges = true, locale = "en", t = null) => {

  const commentStore = useCommentStore()
  const { comments } = storeToRefs(commentStore)

  const userStore = useUserStore()
  const { users } = storeToRefs(userStore)

  const imageStore = useImageStore()
  const { images } = storeToRefs(imageStore)

  const commentsForExport = []
  if (includeComments) {

    const commentsObject = comments.value?.filter((comment) =>
      (comment.type === "comment" || (comment.type === "proposal" && comment.html)) &&
      comment.scope === "internal_and_external" &&
      !comment.is_resolved &&
      ![ "approved", "rejected" ].includes(comment.proposal_status),
    )
    commentsObject.forEach((comment) => {
      const html = enrichCommentsWithUserRepresentation(comment.html, comment.mentioned_document_user_uuids)
      const jsonComment = generateJSON(html, [
        TextExtension,
        ParagraphExtension,
        DocumentExtension,
      ])
      const children = []
      jsonComment.content.forEach((child: Node) => {
        if (child.type === "paragraph" as any) {
          const paragraphChildren = []
          child.content.forEach((child) => {
            if (child.type === "text" as unknown as NodeType) {
              /* const textRunOptions: IRunOptions = {
                text: child.text,
              } */
              //paragraphChildren.push(new docx_1.TextRun(textRunOptions))
              paragraphChildren.push(child.text)
            }
          })
          // TODO: Update docx.js, make it run and then switch to this format for children
          /* const paragraphOptions = {
            children: paragraphChildren,
          } */
          children.push(paragraphChildren.join(" "))
          //children.push(new docx_1.Paragraph(paragraphOptions))
        }
      })
      const fileStorageStore = useFileStorageStore()
      const { storedFiles } = storeToRefs(fileStorageStore)
      const filesAttachedToComment = storedFiles.value?.filter((file) => file.comment_uuid === comment.uuid)
      if (filesAttachedToComment.length > 0) {
        const filesAttachedToCommentString = filesAttachedToComment.map((file) => file.filename).join(", ")
        children.push(`\n\n[Attached files in fynk:\n${filesAttachedToCommentString}]`)
      }
      const documentUser = users.value?.find((el) => el.uuid === comment.created_by_document_user_uuid)
      const author = documentUser ? getUserRepresentation(documentUser) : comment.created_by_document_user_first_name + " " + comment.created_by_document_user_last_name
      const initials = documentUser ? getUserInitials(documentUser) : `${comment.created_by_document_user_first_name[0]}${comment.created_by_document_user_last_name[0]}`
      const commentOptions: ICommentOptions = {
        // TODO: Change to enforce usage of ref_uuid
        //id: comment.ref_uuid || comment.uuid,
        id: comment.uuid,
        initials: initials,
        author,
        date: new Date(comment.created_at),
        text: children.join("\n\n"),
        //children: children,
      }
      //const commentNode = new docx_1.Comment(commentOptions)
      commentsForExport.push(commentOptions)
    })

  }
  const proposals = comments.value.filter(
    (comment) =>
      comment.type === "proposal" &&
      comment.prosemirror_data &&
      comment.proposal_status === "open" &&
      comment.scope === "internal_and_external" &&
      !comment.is_resolved,
  )
  const changesForExport = []
  if (includeTrackedChanges) {
    // Replace each corresponding uuid in doc with the proposal prosemirror_data
    // rebuild doc using descendants, replace node with matching uuid with the proposal prosemirror_data
    proposals.forEach((proposal) => {
      doc.descendants((node, pos) => {
        if (node.attrs.uuid !== proposal.prosemirror_data_uuid) return
        if (proposal && proposal?.prosemirror_data) {
          const proposalNode = Node.fromJSON(doc.type.schema, proposal.prosemirror_data).slice(0)
          doc = doc.replace(pos, pos + node.nodeSize, proposalNode)
          const documentUser = users.value?.find((el) => el.uuid === proposal.created_by_document_user_uuid)
          const author = documentUser ? getUserRepresentation(documentUser) : proposal.created_by_document_user_first_name + " " + proposal.created_by_document_user_last_name
          const initials = documentUser ? getUserInitials(documentUser) : `${proposal.created_by_document_user_first_name[0]}${proposal.created_by_document_user_last_name[0]}`
          let html = null
          let text = null
          if (proposal.html) {
            html = enrichCommentsWithUserRepresentation(html, proposal.mentioned_document_user_uuids)
            const jsonComment = generateJSON(html, [
              TextExtension,
              ParagraphExtension,
              DocumentExtension,
            ])
            const children = []
            jsonComment.content.forEach((child: Node) => {
              if (child.type === "paragraph" as any) {
                const paragraphChildren = []
                child.content.forEach((child) => {
                  if (child.type === "text" as unknown as NodeType) {
                  /* const textRunOptions: IRunOptions = {
                text: child.text,
              } */
                    //paragraphChildren.push(new docx_1.TextRun(textRunOptions))
                    paragraphChildren.push(child.text)
                  }
                })
                // TODO: Update docx.js, make it run and then switch to this format for children
                /* const paragraphOptions = {
            children: paragraphChildren,
          } */
                children.push(paragraphChildren.join(" "))
              //children.push(new docx_1.Paragraph(paragraphOptions))
              }
            })
            text = children.join("\n\n")
          }
          const trackedChange = {
            id: proposal.uuid,
            initials: initials,
            author,
            date: (new Date(proposal.created_at)).toISOString(),
            text: text,
            uuid: proposal.prosemirror_data_uuid,
          }
          changesForExport.push(trackedChange)
        }
      })
    })
    // Set changes object
    docxSerializer.changes = changesForExport
  }

  const opts: CustomOptions = {
    getImageBuffer (uuid: string) {
      return Buffer.from(testImage || images.value?.[uuid] || "", "base64")
    },
    includeComments,
    includeTrackedChanges,
  }

  localeHolder.locale = locale
  localeHolder.t = t

  resetNumberingState()

  const commentsExport = { children: commentsForExport }
  const wordDocument = docxSerializer.serialize(doc, opts, commentsExport)

  await writeDocx(wordDocument as unknown as File, (buffer) => {
    trackEventInJune(JuneEvents.DOCUMENT_SAVED_AS_DOCX)
    saveAs(new Blob([ buffer ]), "fynk-export.docx")
  })
}
