import { Node } from "@tiptap/pm/model"
import {
  IRunOptions,
  IParagraphOptions,
  AlignmentType,
} from "docx"
import { DocxSerializerState } from "prosemirror-docx"

export const configureDefaultRunOptionsForNode = (state: DocxSerializerState, node: Node, addedOptions: IRunOptions = {}) => {
  const color = node.attrs?.color?.replace("#", "")
  const runOptions: IRunOptions = {
    color: color || null,
    // TODO: One day we might want to support different languages, but we would need to bump docx version
    /* language: {
      value: "en-US",
      eastAsia: "en-US",
      bidirectional: "en-US",
    }, */
    ...addedOptions,
  }
  return runOptions
}

export const configureDefaultParagraphOptionsForNode = (state: DocxSerializerState, node: Node, addedOptions: IParagraphOptions = {}) => {
  let alignment = node.attrs?.textAlign
  if (alignment === "justify") alignment = AlignmentType.JUSTIFIED
  if (![ AlignmentType.CENTER, AlignmentType.LEFT, AlignmentType.RIGHT, "justify" ].includes(alignment)) alignment = AlignmentType.LEFT
  const paragraphOptions: IParagraphOptions = {
    alignment: alignment || "left",
    ...addedOptions,
  }
  return paragraphOptions
}
