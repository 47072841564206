<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { ref } from "vue"

// internal
import { useOnboardingStore } from "~/stores"

const onboardingStore = useOnboardingStore()
const {
  currentStepIndex,
  steps,
} = storeToRefs(onboardingStore)

const isActiveSupport = ref(false)
const triggerSupport = () => {
  isActiveSupport.value = true
  emit("trigger-support")
}

const emit = defineEmits([ "trigger-support" ])

</script>
<template>
  <aside class="flex-col justify-between hidden p-8 border-l border-gray-200 bg-gradient-to-br from-gray-100 to-gray-200 sm:p-12 xl:flex w-96">
    <div>
      <div
        v-for="(step, index) in steps"
        :key="step"
        class="relative"
      >
        <div class="relative z-10 flex items-center pb-8 space-x-4">
          <div
            class="flex items-center justify-center transition-all duration-300 border-2 rounded-full shrink-0 w-7 h-7 ring-4 ring-gray-100"
            :class="[
              index === currentStepIndex ? 'border-indigo-500 text-indigo-500 bg-white' :
              index < currentStepIndex ? 'bg-green-300 border-green-300 text-white' :
              'border-gray-300 text-gray-400 bg-white'
            ]"
          >
            <span class="text-sm font-medium">{{ index < currentStepIndex ? '✓' : index + 1 }}</span>
          </div>
          <div>
            <h4 class="text-sm font-medium">
              {{ $t(`onboarding.steps.${step}.title`) }}
            </h4>
          </div>
        </div>
        <div
          v-if="index !== steps.length - 1"
          class="w-0.5 absolute top-0 bottom-0 left-[13px] bg-gray-200"
        >
          <div
            class="absolute top-0 left-0 right-0 transition-all duration-300 bg-green-300"
            :class="index < currentStepIndex ? 'bottom-0' : 'bottom-full'"
          />
        </div>
      </div>
    </div>
    <div v-if="!isActiveSupport">
      <div class="text-sm font-medium">
        {{ $t('onboarding.wizard.helpBox.title') }}
      </div>
      <div class="mt-2 mb-5 text-sm text-gray-500">
        {{ $t('onboarding.wizard.helpBox.description') }}
      </div>


      <div
        class="flex items-center justify-between w-full mt-5"
      >
        <button
          type="button"
          class="btn-white"
          @click="triggerSupport"
        >
          {{ $t('onboarding.wizard.helpBox.button') }}
        </button>
      </div>
    </div>
  </aside>
</template>
