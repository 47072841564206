<script setup lang="ts">
// external
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import { BanknotesIcon } from "@heroicons/vue/24/outline"

// internal
import { DashboardGraphWidget, EmptyState } from "~/components"
import { abbreviate, addNumberSeparators } from "~/utils"

const { t } = useI18n()

const seriesTemplate = [
  {
    name: t("dashboard.contractValues.positiveContractValues"),
    group: "budget",
    data: [ ],
  },
  {
    name: t("dashboard.contractValues.positiveRecurringValues"),
    group: "actual",
    data: [ ],
  },
  {
    name: t("dashboard.contractValues.negativeContractValues"),
    group: "budget",
    data: [ ],
  },
  {
    name: t("dashboard.contractValues.negativeRecurringValues"),
    group: "actual",
    data: [ ],
  },
]

const widgetData = ref(null)

const categories = computed(() => {
  if (!widgetData.value?.length) return []

  const tempCategories = []

  for (let i = 0; i < widgetData.value.length; i++) {

    for (const value of widgetData.value[i].data) {
      const entry = value.toString().split(";")
      const currency = entry[0]

      if (!tempCategories.includes(currency)) {
        tempCategories.push(currency)
      }
    }
  }

  return tempCategories
})

const series = computed (() => {
  if (!widgetData.value?.length) return []
  if (!categories.value?.length) return []

  const tempSeries = [ ...seriesTemplate ]

  const emptyData = []

  // set inital value to zero
  for (let c = 0; c < categories.value.length; c++) {
    emptyData.push(0)
  }
  for (let s = 0; s < tempSeries.length; s++) {
    tempSeries[s].data = [ ...emptyData ]
  }

  for (let i = 0; i < widgetData.value.length; i++) {

    for (const value of widgetData.value[i].data) {
      const entry = value.toString().split(";")
      const currency = entry[0]
      const amount = Number(entry[1])

      const categoryIndex = categories.value.findIndex((category) => category === currency)
      let seriesIndex = 0

      // total values
      if (i === 0) {
        if (amount > 0) {
          seriesIndex = 0
        } else {
          seriesIndex = 2
        }
      // recurring values
      } else if (i === 1) {
        if (amount > 0) {
          seriesIndex = 1
        } else {
          seriesIndex = 3
        }
      }

      if (!tempSeries[seriesIndex].data[categoryIndex]) {
        tempSeries[seriesIndex].data[categoryIndex] = 0
      }

      tempSeries[seriesIndex].data[categoryIndex] += amount
    }
  }

  return tempSeries
})

const setData = (data) => {
  widgetData.value = data
}

</script>
<template>
  <DashboardGraphWidget
    :title="$t('dashboard.widgets.contractValues')"
    type="bar"
    :series="series"
    :options="{
      chart: {
        type: 'bar',
        width: '100%',
        height: '100%',
        stacked: true,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          borderRadiusWhenStacked: 'all'
        }
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: function (value) {
            return abbreviate(value)
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return addNumberSeparators(value)
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          return abbreviate(value)
        },
      },
      colors: ['#008FFB', '#80c7fd', '#dc2626', '#ef4444'],
    }"
    endpoint="api.data.documents.contractValues"
    @loaded="setData"
  >
    <template #empty>
      <EmptyState
        :hide-button="true"
      >
        <template #icon>
          <BanknotesIcon
            aria-hidden="true"
          />
        </template>
        <template #default>
          {{ $t('dashboard.contractValues.emptyTitle') }}
        </template>
        <template #call-to-action>
          <div class="text-sm px-5">
            {{ $t('dashboard.contractValues.emptyDescription') }}
          </div>
        </template>
      </EmptyState>
    </template>
  </DashboardGraphWidget>
</template>
