<script setup lang="ts">
// external
import { storeToRefs } from "pinia"
import { computed } from "vue"

import { InformationCircleIcon } from "@heroicons/vue/24/outline"
import { ArrowDownIcon } from "@heroicons/vue/24/solid"

// internal
import { useAttachmentStore, useDynamicFieldStore } from "~/stores"
import { DynamicField, MetadataValue } from "~/types"

interface Props {
  pendingDynamicFields?: DynamicField[]
  pendingMetadataValues?: MetadataValue[]
}

const props = withDefaults(
  defineProps<Props>(),
  {
    pendingDynamicFields: () => [],
    pendingMetadataValues: () => [],
  },
)

const dynamicFieldStore = useDynamicFieldStore()
const { buttonPopoverDynamicFieldUuid } = storeToRefs(dynamicFieldStore)
const { jumpToDynamicField } = dynamicFieldStore

const attachmentStore = useAttachmentStore()
const { setIsVisibleAttachments } = attachmentStore

const filledOutDynamicFields = computed(() => {
  const checkDynamicFields = props.pendingDynamicFields.every((el) => el.uuid !== buttonPopoverDynamicFieldUuid.value)
  return checkDynamicFields
})

const handleJump = (fieldType: "metadataValue" | "dynamicField", fieldRefUuid: string, event) => {
  setIsVisibleAttachments(false)
  if (event?.target) {
    const popper = event.target?.closest("[data-tippy-root]")
    popper?._tippy?.hide()
  }

  if (fieldType === "dynamicField") {
    jumpToDynamicField(
      fieldRefUuid,
    )
  }
}
</script>

<template>
  <div
    id="wizardButtonPopover"
    v-cy="`wizard-button-popover`"
    class="relative md:w-72"
  >
    <div
      v-if="pendingDynamicFields?.length"
      class="bg-gray-100 border-2 border-indigo-900 divide-y divide-gray-100 rounded-md shadow-lg ring ring-3 ring-indigo-500 ring-opacity-30"
    >
      <button
        type="button"
        :class="
          filledOutDynamicFields
            ? 'bg-indigo-600 hover:bg-indigo-700 text-white focus:ring-offset-2 focus:ring-2 focus:ring-indigo-500'
            : 'bg-gray-200 text-indigo-700'
        "
        class="w-full flex items-center gap-1 px-2 pt-2.5 pb-2.5 border border-transparent shadow-sm justify-center rounded relative text-sm font-medium outline-none focus:outline-none"
        @click.prevent="
          handleJump('dynamicField', pendingDynamicFields[0]?.ref_uuid, $event)
        "
      >
        <ArrowDownIcon
          v-if="filledOutDynamicFields"
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <InformationCircleIcon
          v-else
          class="w-4 h-4 shrink-0"
          aria-hidden="true"
        />
        <span v-if="filledOutDynamicFields && pendingDynamicFields?.length">
          {{ $t('documentStatusBanner.showNext', {count: pendingDynamicFields.length}) }}
        </span>
        <span v-else>
          {{ $t('dynamicFields.fillOutPrompt') }}
        </span>
      </button>
    </div>
  </div>
</template>
