// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { Editor } from "@tiptap/core"
import { Node as ProseMirrorNode } from "@tiptap/pm/model"
import { EditorView, NodeView } from "@tiptap/pm/view"

export function updateColumns (
  node: ProseMirrorNode,
  colgroup: Element,
  table: HTMLTableElement,
  cellMinWidth: number,
  overrideCol?: number,
  overrideValue?: any,
) {
  let totalWidth = 0
  let fixedWidth = true
  let nextDOM = colgroup.firstChild as HTMLDivElement
  const row = node.firstChild

  for (let i = 0, col = 0; i < row.childCount; i += 1) {
    const { colspan, colwidth } = row.child(i).attrs

    for (let j = 0; j < colspan; j += 1, col += 1) {
      const hasWidth = overrideCol === col ? overrideValue : colwidth && colwidth[j]
      const cssWidth = hasWidth ? `${hasWidth}px` : ""

      totalWidth += hasWidth || cellMinWidth

      if (!hasWidth) {
        fixedWidth = false
      }

      if (!nextDOM) {
        colgroup.appendChild(document.createElement("col")).style.width = cssWidth
      } else {
        if (nextDOM.style.width !== cssWidth) {
          nextDOM.style.width = cssWidth
        }

        nextDOM = nextDOM.nextSibling as HTMLDivElement
      }
    }
  }

  while (nextDOM) {
    const after = nextDOM.nextSibling

    nextDOM.parentNode.removeChild(nextDOM)
    nextDOM = after as HTMLDivElement
  }

  if (fixedWidth) {
    table.style.width = `${totalWidth}px`
    table.style.minWidth = ""
  } else {
    table.style.width = ""
    table.style.minWidth = `${totalWidth}px`
  }
}

export class TableView implements NodeView {
  node: ProseMirrorNode

  cellMinWidth: number

  dom: Element

  table: HTMLTableElement

  colgroup: Element

  contentDOM: any

  editor: Editor

  constructor (node: ProseMirrorNode, cellMinWidth: number, editorView: EditorView, editor: Editor) {
    this.node = node
    this.editor = editor
    this.cellMinWidth = cellMinWidth
    this.dom = document.createElement("div")
    this.dom.className = "tableWrapper"
    this.table = this.dom.appendChild(document.createElement("table"))
    this.colgroup = this.table.appendChild(document.createElement("colgroup"))
    updateColumns(node, this.colgroup, this.table, cellMinWidth)
    this.updateTableAttrs(node)
    this.contentDOM = this.table.appendChild(document.createElement("tbody"))
  }

  update (node: ProseMirrorNode) {
    if (node.type !== this.node.type) return false

    this.node = node

    updateColumns(node, this.colgroup, this.table, this.cellMinWidth)

    this.updateTableAttrs(node)

    return true
  }

  updateTableAttrs (node: ProseMirrorNode) {
    Object
      .entries(node.attrs)
      .forEach(
        ([ key, value ]) => {
          this.table.setAttribute(key, value)

          if (key.toLowerCase() === "isbordered") this.table.setAttribute(`data-${key}`, value)
          if (key.toLowerCase() === "uuid") this.table.setAttribute(`data-${key}`, value)
        },
      )
  }

  ignoreMutation (mutation: MutationRecord | { type: "selection"; target: Element }) {
    return (
      mutation.type === "attributes"
      && (mutation.target === this.table || this.colgroup.contains(mutation.target))
    )
  }
}
