<script setup lang="ts">
// external
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { CheckCircleIcon, Cog6ToothIcon, NoSymbolIcon, SparklesIcon } from "@heroicons/vue/24/outline"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import { useI18n } from "vue-i18n"
import { router, Link } from "@inertiajs/vue3"
import { ref, computed } from "vue"
import { Form, Field } from "vee-validate"
import axios from "axios"

// internal
import { MultiFieldType, SelectOption, Metadata, MetadataType, ContractType, DocumentType } from "~/types"
import { SpinLoader, MultiFieldIcon, InputToList, SelectToList, WarningBox, RadioGroupPills } from "~/components"
import { useNotificationStore } from "~/stores"
import { sanitize, documentTypes, isArrayEqual, goBack } from "~/utils"
import { watch } from "vue"


interface Props {
  metadata?: Metadata
  accountMetadata?: Metadata
  contractTypes: ContractType[]
}

const props = withDefaults(defineProps<Props>(), {
  metadata: null,
  accountMetadata: null,
})

const multiFieldTypes = Object.values(MultiFieldType)

const notificationStore = useNotificationStore()
const { addToasterNotification } = notificationStore

const { t } = useI18n()

// TODO: set "null" or "[]" accordingly
enum AutoAttachOptions {
  none = "none",
  some = "some",
  all = "all",
}

const autoAttachDocumentOptions = [
  {
    title: t("accountSettings.metadata.autoAttachDocumentOptions.none"),
    value: AutoAttachOptions.none,
    name: AutoAttachOptions.none,
    icon: NoSymbolIcon,
  },
  {
    title: t("accountSettings.metadata.autoAttachDocumentOptions.all"),
    value: AutoAttachOptions.all,
    name: AutoAttachOptions.all,
    icon: CheckCircleIcon,
  },
  {
    title: t("accountSettings.metadata.autoAttachDocumentOptions.some"),
    value: AutoAttachOptions.some,
    name: AutoAttachOptions.some,
    icon: Cog6ToothIcon,
  },
]

const autoAttachContractOptions = [
  {
    title: t("accountSettings.metadata.autoAttachContractOptions.all"),
    value: AutoAttachOptions.all,
    name: AutoAttachOptions.all,
    icon: CheckCircleIcon,
  },
  {
    title: t("accountSettings.metadata.autoAttachContractOptions.some"),
    value: AutoAttachOptions.some,
    name: AutoAttachOptions.some,
    icon: Cog6ToothIcon,
  },
]
const selectedDocumentTypeAutoAttachment = ref(autoAttachDocumentOptions[0])
const selectedContractTypeAutoAttachment = ref(autoAttachContractOptions[0])

const localMetadata = ref<Partial<Metadata>>({
  display_name: "",
  description: "",
  prompt: "",
  keywords: [],
  relevant_document_types: null,
  relevant_contract_types: null,
  select_values: [],
  value_type: MultiFieldType.text,
  is_contract_value: false,
})

const fillLocalMetadata = (metadata:Metadata, keys = null) => {
  const keysToFill = !!keys ? keys : Object.keys(localMetadata.value)

  for (const key of keysToFill) {
    if (Array.isArray(metadata[key])) {
      localMetadata.value[key] = [ ...metadata[key] ]
    } else {
      localMetadata.value[key] = metadata[key]
    }
  }

  if (metadata?.keywords?.length && typeof metadata.keywords === "string") {
    const removedTrailingDot = metadata.keywords.replace(/\.$/, "")
    localMetadata.value.keywords = removedTrailingDot.split(",")
    localMetadata.value.keywords.map((keyword) => keyword.trim())
  } else {
    localMetadata.value.keywords = []
  }

  if (localMetadata.value.relevant_document_types || localMetadata.value.relevant_contract_types) {
    // When relevant contract types are specified (empty array or array with items) we need to set relevant document types to contract
    if (localMetadata.value.relevant_contract_types?.length > 0 || localMetadata.value.relevant_contract_types || localMetadata.value.relevant_document_types?.length === 1) {
      selectedDocumentTypeAutoAttachment.value = autoAttachDocumentOptions[2]
    } else {
      selectedDocumentTypeAutoAttachment.value = autoAttachDocumentOptions[1]
    }
  } else {
    selectedDocumentTypeAutoAttachment.value = autoAttachDocumentOptions[0]
  }

  if (localMetadata.value.relevant_contract_types) {
    selectedContractTypeAutoAttachment.value = localMetadata.value.relevant_contract_types.length ? autoAttachContractOptions[1] : autoAttachContractOptions[0]
  } else {
    selectedContractTypeAutoAttachment.value = autoAttachContractOptions[0]
  }
}

if (props.metadata) {
  fillLocalMetadata(props.metadata)

  // set localized values for display name and description
  if (props.metadata.type === MetadataType.reference) {
    localMetadata.value.display_name = t(`metadata.system.${props.metadata.name}.name`)
    localMetadata.value.description = t(`metadata.system.${props.metadata.name}.description`)
  }
}
if (props.accountMetadata?.reference_overwrite_fields.length) {
  fillLocalMetadata(props.accountMetadata, props.accountMetadata.reference_overwrite_fields)
}

const documentTypeOptions = computed<SelectOption[]>(() => {
  return documentTypes.map((documentType) => {
    return {
      icon: documentType.icon,
      label: t(`documentTypes.${documentType.type}.name`),
      value: documentType.type,
      description: t(`documentTypes.${documentType.type}.description`),
    }
  }).sort((a, b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)
})

const contractTypeOptions = computed<SelectOption[]>(() => {
  return props.contractTypes.map((contractType) => {
    return {
      label: t(`contractTypes.${contractType.name}`),
      value: contractType.name,
    }
  }).sort((a, b) => (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0)
})

const isSubmitting = ref(false)

const validationHelper = async (values, validate) => {
  const { valid } = await validate()
  if (valid) {
    onSubmit()
  }
}

const selectableTypes = computed(() => {
  return multiFieldTypes.filter((type) => type !== MultiFieldType.list )
})

const isBeingUsedForAi = !!props.accountMetadata?.prompt || !!props.metadata?.prompt
const useForAiExtraction = ref(isBeingUsedForAi)

interface AiFillPayload {
  display_name: string
  description?: string
  prompt?: string
}

const getAiValues = async () => {
  if (useForAiExtraction.value && !isLoadingKeywords.value && !isLoadingPrompt.value) {
    isLoadingPrompt.value = true
    isLoadingKeywords.value = true
    await getAiPrompt()
    await getAiKeywords()
  }
}

const isLoadingPrompt = ref(false)
const getAiPrompt = async () => {

  const payload:AiFillPayload = {
    display_name: localMetadata.value.display_name,
  }
  if (!!localMetadata.value.description) {
    payload.description = localMetadata.value.description
  }

  const promptRes = await axios.post(route("api.ai-generate-metadata-prompt"), payload)
  isLoadingPrompt.value = false

  if (promptRes?.data?.text) {
    localMetadata.value.prompt = promptRes.data.text
  }
}

const isLoadingKeywords = ref(false)
const getAiKeywords = async () => {
  const payload:AiFillPayload = {
    display_name: localMetadata.value.display_name,
  }
  if (!!localMetadata.value.description) {
    payload.description = localMetadata.value.description
  }
  if (!!localMetadata.value.prompt) {
    payload.prompt = localMetadata.value.prompt
  }

  const keywordRes = await axios.post(route("api.ai-generate-metadata-keywords"), payload)
  isLoadingKeywords.value = false

  if (keywordRes?.data?.text) {
    localMetadata.value.keywords = keywordRes.data.text.split(",")
  }
}

const useUpdateEndpoint = props.metadata?.type === MetadataType.account || !!props.accountMetadata

const payload = computed(() => {
  const payload = {
    ...localMetadata.value,
  }

  // respect feature toggle
  if (!useForAiExtraction.value) {
    payload.prompt = null
    payload.keywords = []
  }

  // convert keyword array to keyword string
  if (localMetadata.value.keywords.length && Array.isArray(localMetadata.value.keywords)) {
    payload.keywords = localMetadata.value.keywords.join(", ").replace(/  +/g, " ")
  } else {
    payload.keywords = null
  }

  // only send select_values for selects
  if (payload.value_type !== MultiFieldType.select && props.metadata?.value_type !== MultiFieldType.select) {
    delete payload.select_values
  }

  // only send is_contract_value for currency fields
  if (localMetadata.value.value_type === MultiFieldType.currency || localMetadata.value.value_type === MultiFieldType.currency_duration) {
    payload.is_contract_value = localMetadata.value.is_contract_value
  }

  // set correct value for restrictions. "[]" = none, "null" = all
  if (selectedDocumentTypeAutoAttachment.value.value === AutoAttachOptions.some && localMetadata.value.relevant_document_types?.length === 0) {
    payload.relevant_document_types = null
  }

  if (selectedContractTypeAutoAttachment.value.value === AutoAttachOptions.some && localMetadata.value.relevant_contract_types?.length === 0) {
    payload.relevant_contract_types = null
  }

  // When relevant contract types is specified (empty array or array with items) we need to set relevant document types to null
  if (localMetadata.value.relevant_contract_types?.length >= 0) {
    payload.relevant_document_types = null
  }

  // only send changed values on edit
  if (props.metadata?.type === MetadataType.account) {
    for (const key in payload) {
      let isSame = false

      if (Array.isArray(payload[key]) && Array.isArray(props.metadata[key]) && isArrayEqual(payload[key], props.metadata[key])) {
        isSame = true
      } else if (payload[key] === props.metadata[key]) {
        isSame = true
      }
      if (isSame) {
        delete(payload[key])
      }
    }
  }
  // handle override data
  if (props.metadata?.type === MetadataType.reference) {

    const overrideFields = []
    for (const key in payload) {
      let isSame = false

      if (key === "display_name") {
        isSame = payload.display_name === t(`metadata.system.${props.metadata.name}.name`)
      }
      if (key === "description") {
        isSame = payload.description === t(`metadata.system.${props.metadata.name}.description`)
      }
      // handle arrays
      if (Array.isArray(payload[key]) && Array.isArray(props.metadata[key]) && isArrayEqual(payload[key], props.metadata[key])) {
        isSame = true
      // handle rest
      } else if (payload[key] === props.metadata[key]) {
        isSame = true
      }

      if (!isSame) {
        overrideFields.push(key)
      } else {
        delete(payload[key])
      }
    }

    payload.reference_overwrite_fields = overrideFields
  }


  if (!useUpdateEndpoint) {
    // create name attribute
    payload.name = !props.metadata ? sanitize(payload.display_name) : props.metadata.name
    if (nameCheckPassed.value === false) {
      const rand = Math.round(Math.random() * 10000)
      payload.name = payload.name + "_copy_" + rand
    }

    // set display name even if it's the same
    if (props.metadata && !props.accountMetadata && !payload.display_name) {
      payload.display_name = t(`metadata.system.${props.metadata.name}.name`)
    }

    if (!payload.value_type) {
      payload.value_type = props.metadata.value_type
    }
  }

  return payload
})

const onSubmit = () => {
  if (!isSubmitting.value) {
    isSubmitting.value = true

    if (useUpdateEndpoint) {
      const metadataToEditUuid = props.accountMetadata?.uuid ?? props.metadata.uuid
      router.patch(route("account-settings.metadata.update", metadataToEditUuid), payload.value, {
        onSuccess: () => {
          addToasterNotification({
            title: t("accountSettings.metadata.updateSuccessTitle"),
            message: t("accountSettings.metadata.updateSuccessDescription"),
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t("accountSettings.metadata.updateError"),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
        },
      })
    } else {
      router.post(route("account-settings.metadata.store"), payload.value, {
        onSuccess: () => {
          addToasterNotification({
            title: t("accountSettings.metadata.createSuccessTitle"),
            message: t("accountSettings.metadata.createSuccessDescription"),
            type: "success",
          })
        },
        onError: () => {
          addToasterNotification({
            title: t("common.errorOccured"),
            message: t("accountSettings.metadata.createError"),
            type: "error",
          })
        },
        onFinish: () => {
          isSubmitting.value = false
        },
      })
    }
  }
}

const handleBack = () => {
  if (!!window.location.hash && !!props.metadata) {
    router.visit(route("account-settings.metadata.index") + window.location.hash)
  } else {
    goBack()
  }
}

const nameCheckPassed = ref(useUpdateEndpoint ? true : null)
const isCheckingName = ref(false)
const potentialDuplicates = ref(null)
const checkMetadataName = async () => {
  if (!useUpdateEndpoint && !!localMetadata.value.display_name && !isCheckingName.value) {
    isCheckingName.value = true
    const res = await axios.post(route("account-settings.metadata.check-name"), {
      name: sanitize(localMetadata.value.display_name),
      display_name: localMetadata.value.display_name,
    })

    isCheckingName.value = false

    if (!!res?.data?.account_metadata || !!res?.data?.reference_metadata) {
      nameCheckPassed.value = false
      potentialDuplicates.value = res.data
    } else {
      nameCheckPassed.value = true
      potentialDuplicates.value = null
    }
  }

}

const examplePrompts = [
  {
    title: t("accountSettings.metadata.aiPrompts.indemnificationClausePrompt"),
    good: t("accountSettings.metadata.aiPrompts.indemnificationClauseGoodExamplePrompt"),
    bad: t("accountSettings.metadata.aiPrompts.indemnificationClauseBadExamplePrompt"),
  },
  {
    title: t("accountSettings.metadata.aiPrompts.contractualPenaltyAmountPrompt"),
    good: t("accountSettings.metadata.aiPrompts.contractualPenaltyAmountGoodExamplePrompt"),
    bad: t("accountSettings.metadata.aiPrompts.contractualPenaltyAmountBadExamplePrompt"),
  },
  {
    title: t("accountSettings.metadata.aiPrompts.slaGuaranteedAvailabilityPrompt"),
    good: t("accountSettings.metadata.aiPrompts.slaGuaranteedAvailabilityGoodExamplePrompt"),
    bad: t("accountSettings.metadata.aiPrompts.slaGuaranteedAvailabilityBadExamplePrompt"),
  },
]

const activePromptExample = ref(0)
const goingBack = ref(false)

const showNextPromptExample = () => {
  goingBack.value = false
  if (activePromptExample.value < examplePrompts.length - 1) {
    activePromptExample.value++
  } else {
    activePromptExample.value = 0
  }
}

const showPreviousPromptExample = () => {
  goingBack.value = true
  if (activePromptExample.value > 0) {
    activePromptExample.value--
  } else {
    activePromptExample.value = examplePrompts.length - 1
  }
}

const handleSelectContractTypeAutoAttachment = (option: AutoAttachOptions) => {
  selectedContractTypeAutoAttachment.value = autoAttachContractOptions.find((autoAttachOption) => autoAttachOption.value === option)
}

const handleSelectDocumentTypeAutoAttachment = (option: AutoAttachOptions) => {
  selectedDocumentTypeAutoAttachment.value = autoAttachDocumentOptions.find((autoAttachOption) => autoAttachOption.value === option)
}

const autoAttachDocumentType = computed(() => {
  if (localMetadata.value.relevant_contract_types) {
    return DocumentType.contract
  }
  return localMetadata.value.relevant_document_types?.[0] || null
})

const handleSetAutoAttachDocumentType = (option: string) => {
  if (option !== DocumentType.contract) {
    localMetadata.value.relevant_contract_types = null
  }
  localMetadata.value.relevant_document_types = [ option ]
}

watch(() => selectedDocumentTypeAutoAttachment.value, (newValue: any) => {
  // set correct value for restrictions. "[]" = none, "null" = all
  if (newValue.value === AutoAttachOptions.all || newValue.value === AutoAttachOptions.some) {
    // Auto-attached for all contract type
    localMetadata.value.relevant_document_types = []
  } else if (selectedDocumentTypeAutoAttachment.value.value === AutoAttachOptions.none) {
    // Auto-attached for no document type
    localMetadata.value.relevant_document_types = null
    // Set ai extraction to false
    useForAiExtraction.value = false
  }
})

</script>
<template>
  <Form
    v-slot="{ errors, values, validate }"
    as="div"
  >
    <div class="pb-5">
      <button
        class="flex items-center w-auto space-x-2 font-medium text-indigo-500"
        type="button"
        @click="handleBack"
      >
        <ArrowLeftIcon
          aria-hidden="true"
          class="w-4 h-4 shrink-0"
        />
        <span>{{ $t('common.cancel') }}</span>
      </button>
    </div>
    <div
      class="content-card"
    >
      <div
        class="pt-10 space-y-10 divide-y divide-indigo-300"
      >
        <fieldset
          class="relative py-5 border-t border-indigo-300 [&>*]:max-w-2xl px-5 sm:px-8"
        >
          <h3 class="absolute -top-[13px] h-6 left-3 sm:left-6 px-2 flex items-center justify-center bg-white text-indigo-500 text-sm font-medium">
            {{ $t('accountSettings.metadata.general') }}
          </h3>

          <div class="relative space-y-5">
            <div>
              <label
                for="metadataName"
                class="flex items-center justify-between w-full mb-1 text-sm"
              >
                <span>{{ $t('accountSettings.metadata.name') }} <span class="text-indigo-500">*</span></span>
                <SpinLoader
                  v-if="isCheckingName"
                  class="w-3 h-3 shrink-0"
                  aria-hidden="true"
                /></label>
              <Field
                id="metadataName"
                v-model="localMetadata.display_name"
                name="display_name"
                type="text"
                maxlength="255"
                :placeholder="$t('accountSettings.metadata.namePlaceholder') + '…'"
                class="input-plain"
                :class="[errors.display_name ? 'error' : '']"
                rules="required"
                @change="checkMetadataName"
              />
              <WarningBox
                v-if="nameCheckPassed === false"
                class="mt-2"
              >
                {{ $t('accountSettings.metadata.similarExists') }}: <Link
                  :href="route('account-settings.metadata.show', potentialDuplicates.reference_metadata?.uuid ?? potentialDuplicates.account_metadata?.uuid)"
                  class="font-medium underline"
                >
                  {{ potentialDuplicates.account_metadata?.display_name ?? $t(`metadata.system.${potentialDuplicates.reference_metadata?.name}.name`) }}
                </Link>
              </WarningBox>
            </div>

            <div>
              <div
                class="block mb-1 text-sm"
              >
                {{ $t('magicTable.columns.type') }} <span class="text-indigo-500">*</span>
              </div>
              <Field
                v-if="!metadata"
                v-slot="{ field }"
                v-model="localMetadata.value_type"
                name="value_type"
                rules="required"
              >
                <Listbox
                  v-bind="field"
                  v-model="localMetadata.value_type"
                  :disabled="!!metadata"
                  as="div"
                >
                  <div class="relative mt-1">
                    <ListboxButton
                      class="btn-listbox-plain"
                      :disabled="!!metadata"
                    >
                      <div class="flex items-center">
                        <MultiFieldIcon
                          v-if="localMetadata.value_type"
                          class="shrink-0 mr-1.5 text-indigo-500"
                          :type="localMetadata.value_type"
                        />
                        <span
                          class="block truncate"
                          :class="localMetadata.value_type ? '' : 'text-gray-500'"
                        >{{
                          localMetadata.value_type
                            ? $t('multiFieldTypes.' + localMetadata.value_type)
                            : $t('accountSettings.metadata.selectAnswerType') + "…"
                        }}</span>
                      </div>
                      <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          class="w-5 h-5 text-gray-400"
                          :class="{'opacity-50' : !!metadata}"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions class="listbox-options">
                        <ListboxOption
                          v-for="dynamicFieldAnswerType in selectableTypes"
                          :key="dynamicFieldAnswerType"
                          v-slot="{ active, selected }"
                          as="template"
                          :value="dynamicFieldAnswerType"
                        >
                          <li :class="[active ? 'bg-gray-700' : '', 'listbox-option py-1']">
                            <div class="flex items-center">
                              <MultiFieldIcon
                                :type="dynamicFieldAnswerType"
                                class="shrink-0 mr-1.5 text-indigo-300"
                              />
                              <span
                                :class="[
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                ]"
                              >
                                {{ $t('multiFieldTypes.' + dynamicFieldAnswerType) }}
                              </span>
                            </div>

                            <span
                              v-if="selected"
                              :class="[
                                active ? 'text-white' : 'text-indigo-500',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon
                                class="w-5 h-5 shrink-0"
                                aria-hidden="true"
                              />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </Field>
              <div v-else>
                <div class="flex items-center text-sm">
                  <MultiFieldIcon
                    v-if="localMetadata.value_type"
                    class="shrink-0 mr-1.5 text-indigo-500"
                    :type="localMetadata.value_type"
                  />
                  <span
                    class="block truncate"
                    :class="localMetadata.value_type ? '' : 'text-gray-500'"
                  >{{
                    localMetadata.value_type
                      ? $t('multiFieldTypes.' + localMetadata.value_type)
                      : $t('accountSettings.metadata.selectAnswerType') + "…"
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="localMetadata.value_type === MultiFieldType.select">
              <div
                class="block mb-1 text-sm"
              >
                {{ $t('accountSettings.metadata.selectOptions') }} <span class="text-indigo-500">*</span>
              </div>
              <Field
                v-model="localMetadata.select_values"
                name="select_values"
                rules="required"
              >
                <InputToList
                  v-model="localMetadata.select_values"
                  :error="!!errors.select_values"
                  required
                />
              </Field>
            </div>
            <div
              v-if="localMetadata.value_type === MultiFieldType.currency || localMetadata.value_type === MultiFieldType.currency_duration"
            >
              <SwitchGroup
                as="div"
                class="text-sm flex items-center min-h-[36px] p-3 rounded-md bg-gray-100"
              >
                <Switch
                  v-model="localMetadata.is_contract_value"
                  :class="[
                    localMetadata.is_contract_value ? 'bg-indigo-600' : 'bg-gray-300',
                    'relative inline-flex shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      localMetadata.is_contract_value ? 'translate-x-4' : 'translate-x-0',
                      'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
                <SwitchLabel class="ml-3 text-sm">
                  <span class="block -mb-1 font-medium ">
                    {{ $t('accountSettings.metadata.affectsContractValue') }}
                  </span>
                  <span class="text-xs">
                    {{ $t('accountSettings.metadata.affectsContractValueDescription') }}
                  </span>
                </SwitchLabel>
              </SwitchGroup>
            </div>

            <div>
              <div
                class="block mb-1 text-sm"
              >
                {{ $t('templateGeneralSettings.description') }}
              </div>
              <Field
                v-model="localMetadata.description"
                as="textarea"
                name="description"
                class="input-plain"
                maxlength="1024"
                rows="5"
                :placeholder="$t('templateGeneralSettings.descriptionPlaceholder') + '…'"
                :class="[errors.description ? 'error' : '']"
              />
            </div>
          </div>
        </fieldset>

        <fieldset
          class="relative py-5 border-t border-indigo-300 [&>*]:max-w-2xl px-5 sm:px-8"
        >
          <h3 class="absolute -top-[13px] h-6 left-3 sm:left-6 px-2 flex items-center justify-center bg-white text-indigo-500 text-sm font-medium">
            {{ $t('accountSettings.metadata.autoAttachment') }}
          </h3>
          <div class="relative space-y-5">
            <div>
              <div>
                <div
                  class="block mb-3 text-sm"
                >
                  <label>{{ $t('accountSettings.metadata.autoAttachmentDescription') }}</label>
                </div>
                <RadioGroupPills
                  :not-localized="true"
                  :sr-label="$t('accountSettings.metadata.autoAttachment')"
                  :disabled="false"
                  :grid-class="'grid grid-cols-3 gap-2 grow min-w-[9rem] max-w-lg'"
                  :options="autoAttachDocumentOptions"
                  :model-value="selectedDocumentTypeAutoAttachment.value"
                  @update:model-value="($event) => handleSelectDocumentTypeAutoAttachment($event)"
                />
              </div>
            </div>
            <RadioGroup
              v-if="selectedDocumentTypeAutoAttachment.value === AutoAttachOptions.some"
              :model-value="autoAttachDocumentType"
              @update:model-value="handleSetAutoAttachDocumentType"
            >
              <RadioGroupLabel class="block mb-3 text-sm text-gray-700">
                {{ $t('accountSettings.metadata.selectDocumentType') }}
              </RadioGroupLabel>
              <div class="mt-1 -space-y-px bg-white rounded-md">
                <RadioGroupOption
                  v-for="documentType, documentTypeIdx in documentTypeOptions"
                  :key="documentType.value"
                  v-slot="{ checked, active }"
                  as="template"
                  :value="documentType.value"
                >
                  <div :class="[documentTypeIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', documentTypeIdx === documentTypeOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200', 'cursor-pointer border p-3 focus:outline-none']">
                    <div class="relative flex items-center">
                      <span
                        :class="[checked ? 'text-indigo-600' : 'text-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-600' : '', 'rounded-md flex items-center p-0.5 shrink-0 cursor-pointer border-0 justify-center']"
                        aria-hidden="true"
                      >
                        <component
                          :is="documentType.icon"
                          class="w-10 h-10"
                        />
                      </span>
                      <div class="flex flex-col ml-3">
                        <RadioGroupLabel
                          as="span"
                          :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']"
                        >
                          {{ documentType.label }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="[checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm']"
                        >
                          {{ documentType.description }}
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <div
                      v-if="checked && documentType.value === DocumentType.contract"
                      class="px-4 pt-3 pb-3 mt-2 -mx-3 -mb-3 bg-indigo-100 border-t border-t-indigo-200"
                    >
                      <div
                        class="px-4 py-3 my-1 bg-white rounded-md shadow"
                      >
                        <div class="mb-2 text-sm font-medium">
                          {{ $t('accountSettings.metadata.specifyContractTypes') }}
                        </div>
                        <RadioGroupPills
                          :not-localized="true"
                          :sr-label="$t('accountSettings.metadata.specifyContractTypes')"
                          :disabled="false"
                          :grid-class="'grid grid-cols-2 gap-2 grow min-w-[9rem] max-w-sm'"
                          :options="autoAttachContractOptions"
                          :model-value="selectedContractTypeAutoAttachment.value"
                          @update:model-value="($event) => handleSelectContractTypeAutoAttachment($event)"
                        />
                        <Transition>
                          <div
                            v-if="selectedContractTypeAutoAttachment.value === AutoAttachOptions.some"
                          >
                            <Field
                              v-slot="{ field }"
                              v-model="localMetadata.relevant_contract_types"
                              name="relevant_contract_types"
                              rules="required"
                            >
                              <SelectToList
                                v-bind="field"
                                v-model="localMetadata.relevant_contract_types"
                                :options="contractTypeOptions"
                                :placeholder="$t('accountSettings.metadata.selectContractTypes')"
                                :error="!!errors.relevant_contract_types"
                              />
                            </Field>
                          </div>
                        </Transition>
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
          </div>
        </fieldset>

        <fieldset
          v-if="selectedDocumentTypeAutoAttachment.value !== AutoAttachOptions.none"
          class="relative px-5 py-5 border-t border-indigo-300 sm:px-8"
        >
          <h3 class="absolute -top-[13px] h-6 left-3 sm:left-6 px-2 flex items-center justify-center bg-white text-indigo-500 text-sm font-medium">
            {{ $t('accountSettings.metadata.aiSettings') }}
          </h3>
          <div class="relative space-y-2">
            <SwitchGroup
              as="div"
              class="flex items-center min-h-[36px] text-sm"
            >
              <Switch
                v-model="useForAiExtraction"
                :class="[
                  useForAiExtraction ? 'bg-indigo-500' : 'bg-gray-300',
                  'relative inline-flex shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    useForAiExtraction ? 'translate-x-4' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                />
              </Switch>
              <SwitchLabel class="ml-2 text-sm">
                {{ $t('accountSettings.metadata.useInAiAnalyses') }}
              </SwitchLabel>
            </SwitchGroup>
            <div
              v-if="useForAiExtraction"
              class="pl-11 space-y-5 @container w-full"
            >
              <div class="@lg:flex @lg:items-start @lg:space-x-8">
                <div class="@lg:grow">
                  <div>
                    <div
                      class="flex items-center justify-between mb-1 space-x-4 text-sm"
                    >
                      <span> {{ $t('formFields.prompt') }} <span class="text-indigo-500">*</span></span>

                      <button
                        v-if="useForAiExtraction"
                        type="button"
                        class="flex items-center space-x-1 text-purple-500 disabled:opacity-50"
                        :disabled="!localMetadata.display_name || isLoadingPrompt || isLoadingKeywords"
                        @click="getAiValues"
                      >
                        <SpinLoader
                          v-if="isLoadingPrompt"
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                        <SparklesIcon
                          v-else
                          class="w-4 h-4 shrink-0"
                          aria-hidden="true"
                        />
                        <span>{{ $t('accountSettings.metadata.fillWithAi') }}</span>
                      </button>
                    </div>
                    <div class="p-3 text-xs font-medium text-indigo-700 bg-indigo-100 border-b border-indigo-200 rounded-t-md">
                      {{ $t('accountSettings.metadata.aiPrompts.promptStartHelp', { name: localMetadata?.display_name || '{name}' }) }}:
                    </div>
                    <Field
                      v-model="localMetadata.prompt"
                      as="textarea"
                      name="prompt"
                      class="rounded-t-none input-plain"
                      maxlength="1024"
                      rows="5"
                      :placeholder="$t('accountSettings.metadata.aiPrompts.placeholder') + '…'"
                      :class="[errors.prompt ? 'error' : '']"
                      rules="required"
                      :disabled="isLoadingPrompt"
                    />
                  </div>
                  <div>
                    <div
                      class="mt-5 mb-1 text-sm"
                    >
                      <span> {{ $t('formFields.keywords') }} <span class="text-indigo-500">*</span></span>
                    </div>
                    <Field
                      v-slot="{ field }"
                      v-model="localMetadata.keywords"
                      name="keywords"
                      rules="required"
                    >
                      <InputToList
                        v-bind="{field}"
                        v-model="localMetadata.keywords"
                        :disabled="isLoadingKeywords"
                        :error="!!errors.keywords"
                        :placeholder="$t('accountSettings.metadata.enterKeyword') + '…'"
                      />
                    </Field>
                  </div>
                </div>
                <div class="@lg:w-[28rem] @lg:max-w-[28rem] @lg:min-w-[28rem] overflow-hidden shadow bg-purple-50 rounded-2xl @lg:shrink">
                  <div class="relative flex items-center justify-between w-full p-4 px-4 bg-purple-100 border-b border-purple-200 gap-x-3">
                    <button
                      type="button"
                      class="flex items-center justify-center w-8 h-8 text-purple-500 rounded-full left-2 hover:text-purple-950 hover:bg-purple-200"
                      @click="showPreviousPromptExample"
                    >
                      <ChevronLeftIcon class="w-5 h-5" />
                    </button>
                    <div class="grow">
                      <h3 class="mr-auto text-xs text-purple-900">
                        {{ $t('accountSettings.metadata.aiPrompts.howToWritePrompts') }}
                      </h3>
                      <div class="text-xs font-semibold text-purple-950">
                        {{ $t('accountSettings.metadata.aiPrompts.example') }}: {{ examplePrompts[activePromptExample].title }}
                      </div>
                    </div>
                    <button
                      type="button"
                      class="flex items-center justify-center w-8 h-8 text-purple-500 rounded-full left-2 hover:text-purple-950 hover:bg-purple-200"
                      @click="showNextPromptExample"
                    >
                      <ChevronRightIcon class="w-5 h-5" />
                    </button>
                  </div>
                  <div class="flex items-start">
                    <TransitionGroup
                      :name="goingBack ? 'slide-fade-back' : 'slide-fade'"
                    >
                      <template
                        v-for="prompt, promptIdx in examplePrompts"
                        :key="promptIdx"
                      >
                        <div
                          v-if="activePromptExample === promptIdx"
                          class="flex items-start w-full h-full group"
                        >
                          <div class="relative px-4 pt-4 pb-3">
                            <div class="relative min-h-[3.5rem] flex items-center overflow-x-auto text-xs text-green-700 pl-11">
                              <span class="absolute left-0 flex items-center justify-center w-8 h-8 -mt-4 text-white rounded-full shadow bg-gradient-to-br from-green-500 to-green-600 top-1/2">
                                <CheckIcon class="w-6 h-6" />
                              </span>
                              {{ prompt.good }}
                            </div>
                            <div class="relative min-h-[3.5rem] flex items-center p-4 overflow-x-auto text-xs text-red-700 pl-11">
                              <span class="absolute left-0 flex items-center justify-center w-8 h-8 -mt-4 text-white rounded-full shadow bg-gradient-to-br from-red-500 to-red-600 top-1/2">
                                <XMarkIcon class="w-6 h-6" />
                              </span>
                              {{ prompt.bad }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </TransitionGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="content-card-footer">
        <button
          class="flex items-center space-x-2 btn-primary"
          type="button"
          :disabled="isSubmitting"
          @click="validationHelper(values, validate)"
        >
          <SpinLoader
            v-if="isSubmitting"
            class="w-5 h-5 shrink-0"
            aria-hidden="true"
          />
          <span>{{ !!metadata ? $t('common.save') : $t('common.create') }}</span>
        </button>
      </div>
    </div>
  </Form>
</template>

<style>

.slide-fade-enter-active,
.slide-fade-leave-active,
.slide-fade-back-enter-active,
.slide-fade-back-leave-active {
  transition: all 0.4s ease-in-out;
  position: relative;
  overflow: hidden;
  min-width: 100%;
}

@media all and (max-width: 680px) {
    .slide-fade-enter-active,
    .slide-fade-leave-active,
    .slide-fade-back-enter-active,
    .slide-fade-back-leave-active {
        min-width: 100vw;
    }
}

.slide-fade-enter-from {
  transform: translateX(150%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-150%);
  opacity: 0;
}

.slide-fade-back-enter-from {
  transform: translateX(150%);
  opacity: 0;
}

.slide-fade-back-leave-to {
  transform: translateX(150%);
  opacity: 0;
}
</style>
