<script setup lang="ts">
// external
import { computed } from "vue"

// import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from "date-fns"

// internal
import { Metadata, MetadataType, MultiFieldType, SelectOption } from "~/types"
import { DateInput, MultiCheckbox } from "~/components"

interface Props {
  modelValue?: any
  metadata: Metadata
  options?: SelectOption[]
}

const props = withDefaults(
  defineProps<Props>(),
  {},
)

const emit = defineEmits([ "update:modelValue" ])

const value = computed({
  get: () => {
    switch (props.metadata.value_type) {
      case MultiFieldType.date:
      case MultiFieldType.timestamp:
      case MultiFieldType.select:
        return props.modelValue as string[]
      case MultiFieldType.number:
      case MultiFieldType.currency || MultiFieldType.currency_duration:
        return props.modelValue as number[]
      default:
        return props.modelValue
    }
  },
  set: (value) => emit("update:modelValue", value),
})

const sortedAndTranslatedOptions = computed(() => {
  if (!props.options?.length) return []

  const options = [ ...props.options ]

  options.sort((a, b) => {
    return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)
  })

  return options
})

</script>

<template>
  <div>
    <div
      v-if="[ MultiFieldType.number, MultiFieldType.currency, MultiFieldType.currency_duration ].includes(metadata.value_type)"
      class="flex items-center gap-2"
    >
      <div>
        <label class="text-xs">
          {{ $t('common.from') }}
        </label>
        <input
          v-model.lazy="value[0]"
          type="number"
          class="placeholder-gray-400 input-plain"
          placeholder="0"
          autocomplete="off"
        >
      </div>
      <div>
        <label class="text-xs">
          {{ $t('common.to') }}
        </label>
        <input
          v-model.lazy="value[1]"
          type="number"
          class="placeholder-gray-400 input-plain"
          placeholder="0"
          autocomplete="off"
        >
      </div>
    </div>

    <div
      v-else-if="metadata.value_type === MultiFieldType.select"
      class="mt-1 relative space-y-0.5"
    >
      <MultiCheckbox
        v-model="value"
        :options="sortedAndTranslatedOptions"
      />
    </div>
    <div
      v-else-if="metadata.value_type === MultiFieldType.date || metadata.value_type === MultiFieldType.timestamp"
      class="grid items-center grid-cols-2 gap-2"
    >
      <DateInput
        v-model="value[0]"
        :label="$t('common.after')"
        :style="'gray'"
        :width="'full'"
        :show-day="true"
        :show-month="true"
        :show-year="true"
      />
      <DateInput
        v-model="value[1]"
        :label="$t('common.before')"
        :style="'gray'"
        :width="'full'"
        :show-day="true"
        :show-month="true"
        :show-year="true"
      />
    </div>
    <div v-else-if="metadata.value_type === MultiFieldType.bool || metadata.value_type === MultiFieldType.clause">
      <label class="flex items-center mt-1 space-x-2">
        <input
          v-model="value"
          class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
          type="checkbox"
          :checked="value"
        >
        <span class="text-sm text-gray-600">{{ metadata.type === MetadataType.account || metadata.account_metadata_uuid ? metadata.display_name : $t(`metadata.system.${metadata.name}.name`) }}</span>
      </label>
    </div>
    <div v-else>
      <input
        v-model.lazy="value"
        type="text"
        class="mt-2 input-plain"
        :placeholder="$t('metadata.enterValue') + '…'"
      >
    </div>
  </div>
</template>
