<script setup lang="ts">
// external
import { ref, computed, onBeforeMount, onBeforeUnmount } from "vue"
import { useI18n } from "vue-i18n"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import { CheckCircleIcon } from "@heroicons/vue/24/solid"

// internal
import { Coupon, StripeProduct } from "~/types"
import { TabPills, PricingTableEntry } from "~/components"

interface Props {
  products: StripeProduct[]
  couponCode?: string
  coupon?: Coupon
}

const props = withDefaults(
  defineProps<Props>(),
  {
    couponCode: null,
    coupon: null,
  },
)

const { t } = useI18n()

const tabs = [
  { id: "year", name: t("accountSettings.billing.intervalTabs.year") },
  { id: "month", name: t("accountSettings.billing.intervalTabs.month") },
]

const selectedInterval = ref(tabs[0])

const currencyOptions = [ "eur", "usd" ]
const selectedCurrency = ref(currencyOptions[0])

const productsSortedByPrice = computed(() => {
  const tmpProducts = [ ...props.products ]
  return tmpProducts.sort((a, b) => getAmount(a) - getAmount(b))
})

const getAmount = (product: StripeProduct) => {
  const price = product.prices.find((price) => price.recurring.interval === selectedInterval.value.id)

  if (price) {
    const amount =  price.unit_amount / 100
    return selectedInterval.value.id === "year" ? amount / 12 : amount
  }

  return 0
}

const proFeatures = [
  "prioritySupport",
  "successManager",
  "onboardingSetup",
  "trainingSessions",
  "customSla",
  "integrations",
]


// LAUNCH PROMO CODE COUNTDOWN

const countDownDate = computed(() => {
  if (!!props.coupon?.expires_at) {
    return new Date(props.coupon?.expires_at).getTime()
  }
  return null
})
const timerDisplay = ref("")
let countDownInterval = null

onBeforeMount(() => {
  if (!!countDownDate.value) {
    countDownInterval = setInterval(function () {

      // Get today's date and time
      const now = new Date().getTime()

      // Find the distance between now and the count down date
      const distance = countDownDate.value - now

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // Display the result in the element with id="demo"
      timerDisplay.value = days + "d " + hours + "h " + minutes + "m " + seconds + "s "

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(countDownInterval)
        timerDisplay.value = ""
      }
    }, 1000)
  }

})

onBeforeUnmount(() => {
  clearInterval(countDownInterval)
})


</script>

<template>
  <div class="@container/wrapper">
    <div
      v-if="!!coupon || !!timerDisplay"
      class="text-sm px-3 py-2 rounded sm:flex items-center sm:space-x-3 justify-between bg-green-200 text-green-600 mb-5"
    >
      <div class="flex items-center space-x-3 mb-2 sm:mb-0">
        <span>{{ $t('accountSettings.billing.yourCouponCode') }}:</span><code class="bg-white px-1 py-0.5 rounded">{{ coupon?.code || couponCode }}</code>
      </div>
      <div
        v-if="!!coupon"
        class="font-bold"
      >
        {{ $t('accountSettings.billing.couponBenefits', {percentage: coupon.percent_off, months: coupon.duration_in_months}) }}
      </div>
      <div v-else-if="!!timerDisplay">
        {{ $t('accountSettings.billing.expiresIn') }}: <span class="inline-block min-w-[120px] text-right">{{ timerDisplay }}</span>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <TabPills
        v-model:active-tab="selectedInterval"
        :tabs="tabs"
        :use-select-fallback="false"
      />
      <Listbox
        v-model="selectedCurrency"
        as="div"
      >
        <div class="relative mt-1">
          <ListboxButton
            class="btn-listbox-plain"
          >
            <span class="block uppercase">{{ selectedCurrency }}</span>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                class="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions class="listbox-options">
              <ListboxOption
                v-for="currency in currencyOptions"
                :key="currency"
                v-slot="{ active, selected }"
                as="template"
                :value="currency"
              >
                <li :class="[active ? 'bg-gray-700' : '', 'listbox-option']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate uppercase']">{{ currency }}</span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon
                      class="w-5 h-5 shrink-0"
                      aria-hidden="true"
                    />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div class="isolate mt-10 grid grid-cols-1 @xl/wrapper:grid-cols-2 gap-8 @[830px]/wrapper:grid-cols-4">
      <PricingTableEntry
        v-for="product in productsSortedByPrice"
        :key="product.uuid"
        :product="product"
        :currency="selectedCurrency"
        :interval="selectedInterval.id"
        :coupon-code="couponCode || null"
        :coupon="coupon"
      />
      <PricingTableEntry>
        <template #name>
          <span>Pro</span>
        </template>
        <template #price>
          <div class="text-3xl @[200px]/entry:text-4xl font-bold tracking-tight text-gray-600 text-center w-full">
            {{ $t('accountSettings.billing.pro.price') }}
          </div>
        </template>
        <template #button>
          <a
            href="mailto:sales@fynk.com"
            class="block w-full mt-5 text-center btn-white"
          >{{ $t('lexica.contactSales') }}</a>
        </template>
        <template #features>
          <li class="-mt-8 text-center">
            <span class="font-medium">{{ $t('accountSettings.billing.pro.description') }}:</span>
          </li>
          <li
            v-for="feature in proFeatures"
            :key="feature"
            class="flex items-center"
          >
            <CheckCircleIcon
              class="flex-none w-5 h-5 mr-2 text-indigo-500"
              aria-hidden="true"
            />
            <span>{{ $t('accountSettings.billing.pro.' + feature) }}</span>
          </li>
        </template>
      </PricingTableEntry>
    </div>
    <div class="mt-3 text-xs text-right text-gray-500">
      <span class="text-indigo-500">*</span> {{ $t('accountSettings.billing.vatInfoLong') }}
    </div>
  </div>
</template>
