<script setup lang="ts">
// external
import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue"
import { InformationCircleIcon } from "@heroicons/vue/24/solid"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue"
import { CheckIcon, ChevronUpDownIcon, XCircleIcon, PlusIcon } from "@heroicons/vue/20/solid"
import Vapor from "laravel-vapor"
import axios from "axios"
import { router, usePage } from "@inertiajs/vue3"
import { storeToRefs } from "pinia"

// internal
import { formatBytes, formatDateAndTimeForSignatureDisplay, isFileEncrypted } from "~/utils"
import { PDFIcon, ImportIcon } from "~/icons"
import { FormInputErrors, OverlayScrollbar, SpinLoader, TeamIconDisplay, SubscriptionUpgradeBox } from "~/components"
import { SubscriptionType, Team } from "~/types"
import { XMarkIcon } from "@heroicons/vue/24/outline"
import { useAccountStore } from "~/stores"

interface Props {
  static: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    static: false,
  },
)

const accountStore = useAccountStore()
const { planFeatures, accountUsage, currentSubscription, account } = storeToRefs(accountStore)

const isAiFeatureLimitLocked = computed(() => accountUsage.value && planFeatures.value?.["ai-extraction"] !== 0 && accountUsage.value.aiAnalyses >= planFeatures.value?.["ai-extraction"])
const maxFiles = computed(() => isTrial.value ? 10 : planFeatures.value?.["import-count"] || 5)

const isTrial = computed(() => currentSubscription.value?.object === SubscriptionType.demo && account.value?.trial_ends_at)

const MAX_FILE_SIZE = 31457280 // 30MB

const emit = defineEmits([ "close" ])

const pageProps = computed(() => usePage().props)
const teams = computed<Team[]>(() => pageProps.value.teams as Team[])

const showOverlay = ref(false)

const lastTarget = ref(null)
const startedFromWithin = ref(false)

const fileLimit = computed(() => {
  if (planFeatures.value?.documents === 0) {
    return maxFiles.value
  }
  return Math.max(Math.min(maxFiles.value, planFeatures.value?.documents - accountUsage.value?.documents), 0)
})

const validFiles = ref<UiFile[]>([])
const exceededFiles = ref<UiFile[]>([])

const importedFiles = ref<ImportedFile[]>([])
const name = ref("")
const selectedTeam = ref<Team>(null)
const showNameError = ref(false)
const nameInput = ref()

const progressIndicator = ref<number[]>([])
const isImporting = ref(false)
const isCollecting = ref(false)
const isSubmitting = ref(false)
const finishedFiles = ref(0)

const showDuplicateInfo = ref(false)
const showEncryptedInfo = ref(false)
const showInvalidInfo = ref(false)
let duplicateTimer: NodeJS.Timeout
let encryptedTimer: NodeJS.Timeout
let invalidTimer: NodeJS.Timeout

const hasFiles = computed(() => !!validFiles.value.length || !!exceededFiles.value.length)
const dupeCheckFileStrings = computed(() => validFiles.value.map((file) => getFileNameSizeString(file)))

const isVisibleComponent = computed(() => {
  return showOverlay.value || props.static
})

interface UiFile extends File {
  path_components?: string
}

interface ImportedFile {
  uuid: string
  name: string
}

const validateFile = (file: File) => {
  const extension = file.name.split(".").pop()
  let valid = true
  if (file.type !== "application/pdf") {
    valid = false
  } else if (extension !== "pdf" && extension !== "PDF") {
    valid = false
  } else if (file.size > MAX_FILE_SIZE) {
    valid = false
  }
  return valid
}

const removeFile = (fileIdx: number) => {
  validFiles.value.splice(fileIdx, 1)

  if (!validFiles.value.length) {
    close()
  }
}

const handleDragStart = () => {
  startedFromWithin.value = true
}
const handleDragEnter = (e: DragEvent) => {
  if (!startedFromWithin.value) {
    lastTarget.value = e.target
    showOverlay.value = true
  }
}
const handleDragOver = (e: DragEvent) => {
  e.preventDefault()
}
const handleDragLeave = (e: DragEvent) => {
  if (e.target === lastTarget.value && !hasFiles.value) {
    showOverlay.value = false
  }
}

const supportsFileSystemAccessAPI = "getAsFileSystemHandle" in DataTransferItem.prototype
const supportsWebkitGetAsEntry = "webkitGetAsEntry" in DataTransferItem.prototype

const handleDrop = async (e: DragEvent) => {
  if (!e.dataTransfer) return
  startedFromWithin.value = false
  e.preventDefault()
  if (!isImporting.value && showOverlay.value) {
    isCollecting.value = true


    if (!supportsFileSystemAccessAPI && !supportsWebkitGetAsEntry) {
      console.warn("Cannot handle directories")
      return
    }


    const files = []

    // Prepare an array of promises…
    const fileHandlesPromises = [ ...Array.from(e.dataTransfer.items) ]
    // …by including only files (where file misleadingly means actual file _or_
    // directory)…
      .filter((item) => item.kind === "file")
    // …and, depending on previous feature detection…
      .map((item) =>
        supportsFileSystemAccessAPI
        // …either get a modern `FileSystemHandle`…
          ? (item as any).getAsFileSystemHandle()
        // …or a classic `FileSystemFileEntry`.
          : item.webkitGetAsEntry(),
      )
    // Loop over the array of promises.
    for await (const handle of fileHandlesPromises) {
    // This is where we can actually exclusively act on the directories.
      if (handle.kind === "directory" || handle.isDirectory) {

        if (handle.isDirectory) {
          alert("Your browser does not support dropping folders. Please use a different browser.")
        } else {
          const path = [ handle.name ]
          const contents: [] = []

          await getFilesRecursively(handle, contents, path)

          contents.forEach((file: any) => {
            file.path_components = file?.path_components?.join("/")
          })

          files.push(...contents)
        }


      } else if (handle.kind === "file" || handle.isFile) {
        let file = null

        if (typeof handle.getFile === "function") {
          file = await handle.getFile()
        } else {
          file = await getFileFallback(handle)
        }

        file.path_components = file.name // Adjust according to your UiFile structure
        files.push(file)
      }
    }

    // Process collected files
    for (const file of files) {
      if (file.type) { // Ensure it's not an empty file
        await addFile(file)
      }
    }

    // Finalization logic remains unchanged
    if (!validFiles.value.length) {
      if (fileLimit.value > 0) {
        close()
      }
    } else {
      name.value = formatDateAndTimeForSignatureDisplay(Date.now())
    }
    isCollecting.value = false
  }
}

async function getFileFallback (fileEntry) {
  try {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject))
  } catch (err) {
    console.log(err)
  }
}

const addFilesManually = (e) => {
  const files = e.target.files
  if (files.length) {
    showOverlay.value = true
    for (const file of files) {
      addFile(file)
    }
  }
}

const handleClickDropFile = () => {
  if (!hasFiles.value) {
    document.getElementById("importFileInput")?.click()
    name.value = formatDateAndTimeForSignatureDisplay(Date.now())
  }
}

const getFilesRecursively = async (
  entry: any,
  files: Array<File>,
  path: Array<string>,
) => {
  if (entry.kind === "file") {
    const file: any = await entry.getFile()
    file.path_components = path
    files.push(file)
  } else if (entry.kind === "directory") {
    for await (const handle of entry.values()) {
      path.push(handle.name)
      const newPath = path.map((p) => p)
      await getFilesRecursively(handle, files, newPath)
      path.pop()
    }
  }
}

const addFile = async (file: File) => {
  if (validateFile(file)) {
    const encrypted = await isFileEncrypted(file)
    if (encrypted) {
      triggerEncryptedInfo()
    } else if (dupeCheckFileStrings.value.includes(getFileNameSizeString(file))) {
      triggerDuplicateInfo()
    } else {
      if (validFiles.value.length < fileLimit.value) {
        validFiles.value.push(file)
      } else {
        exceededFiles.value.push(file)
      }
    }
  } else {
    triggerInvalidInfo()
  }
}

const close = () => {
  validFiles.value = []
  exceededFiles.value = []
  importedFiles.value = []
  showOverlay.value = false
  autoApplyAnalysis.value = false
  emit("close")
}

const getFileNameSizeString = (file) => {
  return `${file.name}_${file.size}`
}

const triggerDuplicateInfo = () => {
  showDuplicateInfo.value = true
  clearTimeout(duplicateTimer)
  duplicateTimer = setTimeout(() => {
    showDuplicateInfo.value = false
  }, 3000)
}

const triggerEncryptedInfo = () => {
  showEncryptedInfo.value = true
  clearTimeout(encryptedTimer)
  encryptedTimer = setTimeout(() => {
    showEncryptedInfo.value = false
  }, 3000)
}

const triggerInvalidInfo = () => {
  showInvalidInfo.value = true
  clearTimeout(invalidTimer)
  invalidTimer = setTimeout(() => {
    showInvalidInfo.value = false
  }, 3000)
}

const importFiles = () => {
  if (!fileLimit.value) return

  if (!name.value) {
    showNameError.value = true
    nameInput.value.focus()
    return
  }

  progressIndicator.value = []
  isImporting.value = true
  finishedFiles.value = 0

  for (const i in validFiles.value) {
    const file = validFiles.value[i]

    progressIndicator.value.push(0)

    Vapor.store(file, {
      progress: (progress) => {
        progressIndicator.value[i] = Math.round(progress * 100) || 100
      },
    })
      .catch((err) => {
        progressIndicator.value[i] = -1
        console.error(err)
      })
      .then((res) => {
        importedFiles.value.push({
          uuid: res.uuid,
          name: file.name,
        })
      })
      .finally(() => {
        finishedFiles.value++
      })
  }
}

const autoApplyAnalysis = ref(false)

const payload = computed(() => {
  if (!importedFiles.value.length) return null

  const tmpPayload = {
    files: {},
    name: name.value,
    team_uuid: selectedTeam.value?.uuid ?? null,
    ai_analysis_auto_apply: autoApplyAnalysis.value,
  }

  for (const file of importedFiles.value) {
    tmpPayload.files[file.uuid] = file.name
  }

  return tmpPayload
})

const backendErrors = ref<Record<string, string[]>>(null)

const submitFiles = async () => {
  isImporting.value = false
  isSubmitting.value = true

  try {
    const res = await axios.post(route("api.import-init.create-import"), payload.value)

    if (res.data?.data?.uuid) {
      router.visit(route("imports.show", res.data.data.uuid))
      close()
    }
  } catch (e) {
    backendErrors.value = e.response?.data?.errors ?? null
    importedFiles.value = []
  } finally {
    isSubmitting.value = false
  }
}

const errorsToShow = computed<Partial<Record<string, string[]>>>(
  () => {
    const errors: Partial<Record<string, string[]>> = {}
    if (backendErrors.value) {
      Object.keys(backendErrors.value)
        .forEach(
          (key) => {
            errors[key] = [ ...(errors[key] || []), ...(backendErrors.value[key] || []) ]
          },
        )
    }
    return errors
  },
)

onMounted(() => {
  window.addEventListener("dragstart", handleDragStart)
  window.addEventListener("dragenter", handleDragEnter)
  window.addEventListener("dragover", handleDragOver)
  window.addEventListener("dragleave", handleDragLeave)
  window.addEventListener("drop", handleDrop, false)
})

onBeforeUnmount(() => {
  window.removeEventListener("dragenter", handleDragEnter)
  window.removeEventListener("dragover", handleDragOver)
  window.removeEventListener("dragleave", handleDragLeave)
  window.removeEventListener("drop", handleDrop)
})

watch(finishedFiles, () => {
  if (finishedFiles.value && finishedFiles.value === validFiles.value.length) {
    submitFiles()
  }
})

</script>

<template>
  <div>
    <div
      class="transition-all duration-200 fixed inset-0 z-[69420] flex items-center justify-center w-full h-full"
      :class="[isVisibleComponent ? 'bg-black bg-opacity-70 opacity-100' : 'opacity-0', hasFiles || props.static ? '' : 'pointer-events-none']"
    >
      <input
        id="importFileInput"
        type="file"
        class="hidden"
        multiple
        accept="application/pdf"
        @change="addFilesManually"
      >
      <div
        class="fixed inset-0"
        @click="close"
      />
      <div
        v-if="!hasFiles"
        class="fixed flex flex-col items-center justify-center overflow-hidden border-2 border-dashed border-white/40 inset-10 md:inset-20 rounded-xl bg-gradient-to-br to-gray-800/40 from-gray-950/40"
        :class="!hasFiles ? 'cursor-pointer' : ''"
        @click.prevent="handleClickDropFile"
      >
        <div
          v-if="isImporting || showOverlay"
          class="loading-shimmer"
        />
        <button
          v-if="props.static && !hasFiles"
          type="button"
          class="absolute flex items-center justify-center w-12 h-12 text-white rounded-lg bg-black/0 hover:bg-black/20 right-2 top-2"
          @click.prevent.stop="close"
        >
          <XMarkIcon
            class="w-8 h-8"
            aria-hidden="true"
          />
        </button>
        <template v-if="isCollecting">
          <SpinLoader
            class="mb-2 text-gray-300 w-36 h-36"
          />
          <span class="font-medium text-white">{{ $t('imports.fileCollector.checkingFiles') }}…</span>
        </template>
        <template v-else>
          <ImportIcon
            aria-hidden="true"
            class="mb-2 text-gray-300 w-36 h-36"
            :class="!props.static || showOverlay ? 'animate-pulse' : ''"
          />
          <span class="font-medium text-white">{{ $t('imports.fileCollector.dropPdfInfo') }}</span>
        </template>
      </div>
      <div
        v-else
        class="relative m-5 w-full max-w-4xl bg-gray-900 divide-y divide-gray-500 shadow-2xl divide-opacity-20 rounded-xl flex flex-col max-h-[90vh]"
      >
        <div class="relative p-5 font-medium text-center text-gray-400">
          <span>{{ $t('imports.fileCollector.fileSelected', validFiles.length) }}</span>
          <label
            v-if="!isImporting && !isSubmitting && !!fileLimit"
            for="importFileInput"
            class="absolute px-2 py-1 text-sm transition-colors duration-100 border border-gray-400 rounded cursor-pointer top-4 right-4 hover:border-white hover:text-white"
          >
            <div class="flex items-center justify-center space-x-1">
              <PlusIcon
                aria-hidden="true"
                class="w-4 h-4 shrink-0"
              />
              <span>{{ $t('imports.fileCollector.addFiles') }}</span>
            </div>
          </label>
          <div class="absolute left-0 right-0 z-10 pointer-events-none top-full ">
            <div
              class="relative flex items-center justify-center overflow-hidden text-sm text-white transition-opacity duration-100 bg-red-500"
              :class="showInvalidInfo ? 'opacity-100' : 'opacity-0 h-0'"
            >
              <div class="px-5 py-1">
                {{ $t('imports.fileCollector.invalidFiles') }}
              </div>
            </div>
            <div
              class="relative flex items-center justify-center overflow-hidden text-sm text-white transition-opacity duration-100 bg-yellow-600"
              :class="showDuplicateInfo ? 'opacity-100' : 'opacity-0 h-0'"
            >
              <div class="px-5 py-1">
                {{ $t('imports.fileCollector.duplicateFiles') }}
              </div>
            </div>
            <div
              class="relative flex items-center justify-center overflow-hidden text-sm text-white transition-opacity duration-100 bg-yellow-600"
              :class="showEncryptedInfo ? 'opacity-100' : 'opacity-0 h-0'"
            >
              <div class="px-5 py-1">
                {{ $t('imports.fileCollector.encryptedFiles') }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isSubmitting"
          class="flex flex-col items-center justify-center py-8 space-y-5"
        >
          <SpinLoader class="w-24 h-24 text-gray-500" />
          <span class="text-sm text-gray-400">{{ $t('imports.fileCollector.loadingInfo') }}…</span>
        </div>
        <OverlayScrollbar
          v-else
          ref="fileCollectorScrollContainer"
          tag="div"
          class="relative overflow-y-auto max-h-max"
        >
          <div class="grid grid-cols-3 gap-5 p-5 lg:grid-cols-4 2xl:grid-cols-5 min-h-[100px]">
            <div
              v-for="(file, fileIdx) in validFiles"
              :key="fileIdx"
              class="relative"
            >
              <div class="relative flex items-center justify-center w-full mb-2">
                <PDFIcon
                  aria-hidden="true"
                  class="w-16 h-16 text-gray-500"
                />
                <div
                  v-if="progressIndicator[fileIdx]"
                  class="absolute h-2 overflow-hidden bg-gray-600 rounded-full -bottom-2 left-5 right-5"
                >
                  <div
                    v-if="progressIndicator[fileIdx] === -1"
                    class="absolute top-0 bottom-0 left-0 right-0 bg-red-500"
                  />
                  <div
                    v-else
                    class="absolute top-0 bottom-0 left-0 bg-green-400"
                    :style="`width: ${progressIndicator[fileIdx]}%`"
                  />
                </div>
              </div>
              <div class="text-sm text-center text-white break-all">
                {{ file.name }}
              </div>
              <div class="text-xs text-center text-gray-400">
                {{ formatBytes(file.size) }}
              </div>
              <button
                v-if="!isImporting"
                type="button"
                class="absolute top-0 right-0 text-gray-400 transition-colors duration-100 hover:text-white"
                @click="removeFile(fileIdx)"
              >
                <XCircleIcon
                  aria-hidden="true"
                  class="w-4 h-4 shrink-0"
                />
                <span class="sr-only">{{ $t('common.remove') }}</span>
              </button>
            </div>
          </div>
        </OverlayScrollbar>
        <SubscriptionUpgradeBox
          v-if="!fileLimit"
          :text="$t('accountSettings.billing.documentCreationNotPossible', {limit: planFeatures?.documents})"
          class="absolute inset-0 overflow-hidden rounded-xl"
        />
        <div
          v-if="!isSubmitting && !!fileLimit"
          class="p-5"
        >
          <div class="justify-center mb-5 space-y-3 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
            <div class="sm:w-1/3">
              <label
                class="block text-xs mb-0.5 text-slate-400"
                for="importName"
              >{{ $t('imports.importName') }} <span class="text-indigo-300">*</span></label>
              <input
                id="importName"
                ref="nameInput"
                v-model="name"
                name="importName"
                :placeholder="$t('imports.importNamePlaceholder') + '…'"
                type="text"
                class="w-full text-sm border-0 rounded-md shadow-none focus:ring-2"
                :class="showNameError ? 'bg-red-300 text-red-900 focus:ring-red-600 focus:border-red-600 placeholder:text-red-900' : 'bg-slate-700 text-white focus:ring-indigo-500 focus:border-indigo-500 placeholder:text-slate-400'"
                @input="() => showNameError = !name"
              >
            </div>
            <div class="sm:w-1/3">
              <div
                class="block text-xs mb-0.5 text-slate-400"
              >
                {{ $t('magicTable.columns.team') }}
              </div>
              <Listbox
                v-model="selectedTeam"
                :disabled="!teams.length"
                as="div"
                class="inline w-full"
              >
                <div class="relative">
                  <ListboxButton class="relative flex items-center w-full py-2 pl-3 pr-10 text-sm text-left transition-colors duration-150 border-0 rounded-md bg-slate-700 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-700 disabled:cursor-not-allowed">
                    <span
                      v-if="!selectedTeam"
                      class="block truncate text-slate-400"
                    >{{ $t('documentGeneralSettings.selectTeams') }}…</span>
                    <div
                      v-else
                      class="flex space-x-2 max-w-full"
                    >
                      <TeamIconDisplay
                        :team="selectedTeam"
                        class="w-5 h-5"
                      />
                      <span
                        class="block text-white truncate"
                      >
                        {{ selectedTeam.name }}
                      </span>
                    </div>

                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon
                        class="w-5 h-5 text-slate-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions class="listbox-options max-w-full">
                      <ListboxOption
                        v-for="team in teams"
                        :key="team.uuid"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="team"
                      >
                        <li :class="[active ? 'bg-gray-700' : '', 'listbox-option flex space-x-2']">
                          <TeamIconDisplay
                            class="w-5 h-5"
                            :team="team"
                          />
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ team.name }}</span>

                          <span
                            v-if="selected"
                            :class="[
                              active ? 'text-white' : 'text-indigo-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon
                              class="w-5 h-5 shrink-0"
                              aria-hidden="true"
                            />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
            <div
              v-if="false"
              class="w-1/3"
            >
              <div
                class="block text-xs mb-0.5 text-slate-400 uppercase"
              >
                {{ $t('magicTable.columns.tags') }}
              </div>
            </div>
          </div>
          <div
            v-if="!isAiFeatureLimitLocked"
            class="flex items-center justify-center w-auto mx-auto mb-4 space-x-2 text-sm"
            :class="[autoApplyAnalysis ? 'text-purple-400' : 'text-gray-400']"
          >
            <input
              id="autoApplyAnalysis"
              v-model="autoApplyAnalysis"
              type="checkbox"
              class="w-4 h-4 border-gray-500 rounded cursor-pointer bg-slate-900"
              :class="[autoApplyAnalysis ? 'text-purple-400 focus:ring-purple-500' : 'text-gray-400 focus:ring-gray-500']"
            >
            <label
              for="autoApplyAnalysis"
              class="font-medium"
            >{{ $t('imports.fileCollector.autoApplyAnalysis')
            }}</label>
          </div>
          <div class="flex justify-center">
            <FormInputErrors
              v-for="error, errorIdx in errorsToShow"
              :key="errorIdx"
              :errors="error"
            />
            <button
              class="flex items-center justify-center space-x-2 text-white border-0 shadow-lg text-shadow-lg focus:ring-offset-slate-900 focus:ring-purple-700 btn-white bg-gradient-to-br from-purple-500 to-purple-700"
              type="button"
              :disabled="isImporting || fileLimit === 0"
              @click="importFiles"
            >
              <template v-if="isImporting">
                <SpinLoader class="w-5 h-5 text-purple-300" />
                <span>
                  {{ $t('imports.fileCollector.importingFiles') }}…
                </span>
              </template>
              <template v-else>
                <ImportIcon
                  aria-hidden="true"
                  class="w-5 h-5 text-purple-300"
                />
                <span>
                  {{ $t('imports.fileCollector.importFiles') }}
                </span>
              </template>
            </button>
          </div>
        </div>

        <div class="flex items-center justify-center min-h-[60px] py-2">
          <div
            class="flex items-center justify-center px-5 transition-all duration-200 ease-in-out"
            :class="showInvalidInfo ? 'text-white text-sm' : 'text-xs text-gray-400'"
          >
            <InformationCircleIcon
              aria-hidden="true"
              class="w-4 h-4 mr-1 shrink-0"
            />
            <span>
              {{ $t('imports.fileCollector.fileRestrictions', {
                size: formatBytes(MAX_FILE_SIZE, 0), limit:
                  maxFiles
              }) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading-shimmer{
  @apply absolute -left-[200vw] h-full w-[200vw] bg-gradient-to-r from-black/0 via-black/20 to-black/0 pointer-events-none;
  animation: loading 2s infinite;
}

@keyframes loading {
  0%{
    left: -200%;
  }
  100%{
    left: 200%;
  }
}
</style>
