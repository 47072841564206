import { ReplaceStep, Step } from "@tiptap/pm/transform"
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state"
import { CommandProps, Editor, Extension, Mark, MarkRange, getMarkRange, getMarksBetween, isMarkActive, mergeAttributes } from "@tiptap/core"
import { TextSelection } from "@tiptap/pm/state"
import { Slice, Fragment, Node as ProseMirrorNode } from "@tiptap/pm/model"

export const MARK_DELETION = "deletion"
export const MARK_INSERTION = "insertion"
export const EXTENSION_NAME = "trackchange"

// Define the insertion mark used to highlight new content
export const InsertionMark = Mark.create({
  name: MARK_INSERTION,
  priority: 1,
  parseHTML () {
    return [ { tag: "insert" } ]
  },
  renderHTML ({ HTMLAttributes }) {
    return [ "insert", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0 ]
  },
})

// Define the deletion mark used to highlight removed content
export const DeletionMark = Mark.create({
  name: MARK_DELETION,
  priority: 1,
  parseHTML () {
    return [ { tag: "delete" } ]
  },
  renderHTML ({ HTMLAttributes }) {
    return [ "delete", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0 ]
  },
})

const getSelfExt = (editor: Editor) => editor.extensionManager.extensions.find((item) => item.type === "extension" && item.name === EXTENSION_NAME) as Extension

// Track Change Operations
export const TRACK_COMMAND_ACCEPT = "accept"
export const TRACK_COMMAND_ACCEPT_ALL = "accept-all"
export const TRACK_COMMAND_REJECT = "reject"
export const TRACK_COMMAND_REJECT_ALL = "reject-all"

export type TRACK_COMMAND_TYPE = "accept" | "accept-all" | "reject" | "reject-all"

/**
 * accept or reject tracked changes for all content or just the selection
 * @param opType operation to apply
 * @param param a command props, so we can get the editor, tr prop
 * @returns null
 */
export const changeTrack = (opType: TRACK_COMMAND_TYPE, editor: CommandProps): EditorState | boolean => {
  /**
   * get the range to deal, use selection default
   */
  const from = editor.state.selection.from
  const to = editor.state.selection.to
  /**
   * find all the mark ranges to deal and remove mark or remove content according by opType
   * if got accept all or reject all, just set 'from' to 0 and 'to' to content size
   * if got just a part range,
   */
  let markRanges: Array<MarkRange> = []
  /**
   * deal a part and no selection contents, need to recognize the left mark near by cursor
   */
  if ((opType === TRACK_COMMAND_ACCEPT || opType === TRACK_COMMAND_REJECT) && from === to) {
    // detect left mark
    const isInsertBeforeCursor = isMarkActive(editor.state, MARK_INSERTION)
    const isDeleteBeforeCursor = isMarkActive(editor.state, MARK_DELETION)
    let leftRange
    if (isInsertBeforeCursor) {
      leftRange = getMarkRange(editor.state.selection.$from, editor.state.doc.type.schema.marks.insertion)
    } else if (isDeleteBeforeCursor) {
      leftRange = getMarkRange(editor.state.selection.$from, editor.state.doc.type.schema.marks.deletion)
    }
    if (leftRange) {
      markRanges = getMarksBetween(leftRange.from, leftRange.to, editor.state.doc)
    }
  } else if (opType === TRACK_COMMAND_ACCEPT_ALL || opType === TRACK_COMMAND_REJECT_ALL) {
    // all editor content
    markRanges = getMarksBetween(0, editor.state.doc.content.size, editor.state.doc)
    // change the opType to normal
    opType = opType === TRACK_COMMAND_ACCEPT_ALL ? TRACK_COMMAND_ACCEPT : TRACK_COMMAND_REJECT
  } else {
    // just the selection
    markRanges = getMarksBetween(from, to, editor.state.doc)
  }
  // just deal the track change nodes
  markRanges = markRanges.filter((markRange) => markRange.mark.type.name === MARK_DELETION || markRange.mark.type.name === MARK_INSERTION)
  if (!markRanges.length) { return false }

  const currentTr = editor.state.tr
  /**
   * mark type and opType compose:
   * 1. accept with insert mark: remove insert mark
   * 2. accept with delete mark: remove content
   * 3. reject with insert mark: remove content
   * 4. reject with delete mark: remove delete mark
   * so
   * 1 and 4 need to remove mark
   * 2 and 3 need to remove content
   */
  // record offset when delete some content to find the correct pos for next range
  let offset = 0
  const removeInsertMark = editor.state.doc.type.schema.marks.insertion.create()
  const removeDeleteMark = editor.state.doc.type.schema.marks.deletion.create()
  markRanges.forEach((markRange) => {
    const isAcceptInsert = opType === TRACK_COMMAND_ACCEPT && markRange.mark.type.name === MARK_INSERTION
    const isRejectDelete = opType === TRACK_COMMAND_REJECT && markRange.mark.type.name === MARK_DELETION
    if (isAcceptInsert || isRejectDelete) {
      // 1 and 4: remove mark
      currentTr.removeMark(markRange.from - offset, markRange.to - offset, removeInsertMark)
      currentTr.removeMark(markRange.from - offset, markRange.to - offset, removeDeleteMark)
    } else {
      // 2 and 3 remove content
      currentTr.deleteRange(markRange.from - offset, markRange.to - offset)
      // change the offset
      offset += (markRange.to - markRange.from)
    }
  })
  if (currentTr.steps.length) {
    // set a custom meta to tail Our TrackChangeExtension to ignore this change
    // TODO: is there any official field to do this?
    currentTr.setMeta("trackManualChanged", true)
    // apply to current editor state and get a new state
    const newState = editor.state.apply(currentTr)

    // NOTE: removed because I wanna do my own thing with state in proposal modal
    // // update the new state to editor to render new content
    // editor.view.updateState(newState)

    return newState
  }

  return false
}

// Main extension for tracking changes in the editor
export const TrackChangeNew = Extension.create<{ enabled: boolean, onStatusChange?: (val: boolean) => void }>({
  name: EXTENSION_NAME,
  priority: 1,

  // Trigger an optional callback whenever the tracking status changes
  onCreate () {
    if (this.options.onStatusChange) {
      this.options.onStatusChange(this.options.enabled)
    }
  },

  addExtensions () {
    // Add insertion and deletion marks to the schema
    return [ InsertionMark, DeletionMark ]
  },


  addCommands: () => {
    return {
      setTrackChangeStatus: (enabled: boolean) => (param: CommandProps) => {
        const thisExtension = getSelfExt(param.editor)
        thisExtension.options.enabled = enabled
        if (thisExtension.options.onStatusChange) {
          thisExtension.options.onStatusChange(thisExtension.options.enabled)
        }
        return false
      },
      toggleTrackChangeStatus: () => (param: CommandProps) => {
        const thisExtension = getSelfExt(param.editor)
        thisExtension.options.enabled = !thisExtension.options.enabled
        if (thisExtension.options.onStatusChange) {
          thisExtension.options.onStatusChange(thisExtension.options.enabled)
        }
        return false
      },
      getTrackChangeStatus: () => (param: CommandProps) => {
        const thisExtension = getSelfExt(param.editor)
        return thisExtension.options.enabled
      },
      acceptChange: () => (param: CommandProps) => {
        changeTrack("accept", param)
        return false
      },
      acceptAllChanges: () => (param: CommandProps) => {
        changeTrack("accept-all", param)
        return false
      },
      rejectChange: () => (param: CommandProps) => {
        changeTrack("reject", param)
        return false

      },
      rejectAllChanges: () => (param: CommandProps) => {
        changeTrack("reject-all", param)
        return false
      },
    }
  },

  addProseMirrorPlugins () {
    return [
      new Plugin({
        key: new PluginKey<any>("trackChangePlugin"),

        // This function is called whenever a transaction is applied to the editor
        appendTransaction: (transactions, oldState, newState) => {
          const trackChangeEnabled = this.options.enabled
          if (!trackChangeEnabled) return // Skip if tracking is disabled
          const newChangeTr = newState.tr

          transactions.forEach((transaction) => {
            // Skip transactions that don't modify the document or are redo/undo operations
            if (!transaction.docChanged || transaction.getMeta("trackManualChanged") || transaction.getMeta("history$")) return

            // If there are no steps in the transaction, return early
            if (!transaction.steps.length) return

            // Copy the transaction steps to avoid modifying the original steps
            const allSteps = transaction.steps.map((step) =>
              Step.fromJSON(newState.doc.type.schema, step.toJSON()),
            )

            // Track the current cursor position
            const currentNewPos = transaction.selection.from
            let posOffset = 0
            let hasAddAndDelete = false

            // Iterate over the steps to calculate any necessary cursor adjustments
            allSteps.forEach((step: Step, index: number) => {
              if (step instanceof ReplaceStep) {
                let delCount = 0
                if (step.from !== step.to) {
                  // Calculate the size of content that will be deleted
                  const slice = transaction.docs[index].slice(step.from, step.to)
                  slice.content.forEach((node) => {
                    const isInsertNode = node.marks.find((m) => m.type.name === MARK_INSERTION)
                    if (!isInsertNode) {
                      delCount += node.nodeSize // Count the size of nodes without insertion marks
                    }
                  })
                }
                posOffset += delCount // Adjust the cursor position by the size of deleted content
                const newCount = step.slice ? step.slice.size : 0
                if (newCount && delCount) {
                  hasAddAndDelete = true // Flag that both addition and deletion occurred
                }
              }
            })

            // If there was no combination of add and delete, reset the cursor adjustment
            if (!hasAddAndDelete) {
              posOffset = 0
            }

            let reAddOffset = 0

            // Iterate over the steps again to handle adding back deleted content with the necessary marks
            allSteps.forEach((step: Step, index: number) => {
              if (step instanceof ReplaceStep) {
                const invertedStep = step.invert(transaction.docs[index])

                if (step.slice.size) {
                  const insertionMark = newState.doc.type.schema.marks.insertion.create()
                  const deletionMark = newState.doc.type.schema.marks.deletion.create()
                  const from = step.from + reAddOffset
                  const to = step.from + reAddOffset + step.slice.size

                  if (trackChangeEnabled) {
                    // Remove deletion marks before adding insertion marks to prevent nesting
                    newChangeTr.removeMark(from, to, deletionMark)
                    newChangeTr.addMark(from, to, insertionMark) // Add insertion mark
                  } else {
                    // If tracking is disabled, remove any automatic insertion marks
                    newChangeTr.removeMark(from, to, insertionMark)
                  }

                  // Ensure no nested deletion marks are left behind
                  newChangeTr.removeMark(from, to, deletionMark)
                }

                if (step.from !== step.to && trackChangeEnabled) {
                  // Handle content that is deleted and needs to be marked as such
                  const skipSteps: Array<ReplaceStep> = []
                  const cleanedSlice = cleanContent(invertedStep.slice, newState.doc.type.schema)
                  const cleanedSizeDifference = invertedStep.slice.size - cleanedSlice.size
                  reAddOffset -= cleanedSizeDifference

                  // Re-add the cleaned content with the deletion mark
                  const reAddStep = new ReplaceStep(
                    invertedStep.from,
                    invertedStep.to,
                    cleanedSlice,
                    (invertedStep as any).structure,
                  )

                  // Commented-out section: This block was previously used to skip content that was marked as inserted, but it's now handled during content cleaning
                  /* invertedStep.slice.content.forEach((node, offset) => {
                    const start = invertedStep.from + offset
                    const end = start + node.nodeSize
                    if (node.marks.find((m) => m.type.name === MARK_INSERTION)) {
                      skipSteps.push(new ReplaceStep(start, end, Slice.empty))
                      reAddOffset -= node.nodeSize
                    }
                  }) */

                  reAddOffset += cleanedSlice.size

                  // Apply the re-add step and mark the content as deleted
                  newChangeTr.step(reAddStep)
                  const { from } = reAddStep
                  const to = from + reAddStep.slice.size
                  newChangeTr.addMark(from, to, newChangeTr.doc.type.schema.marks.deletion.create())

                  // Apply any skip steps that were collected
                  skipSteps.forEach((step) => {
                    newChangeTr.step(step)
                  })
                }
              }
            })

            // Adjust the final cursor position after processing the transaction
            const finalNewPos = trackChangeEnabled ? (currentNewPos + posOffset) : currentNewPos

            // Ensure the final position is within document bounds
            if (trackChangeEnabled && finalNewPos >= 0 && finalNewPos <= newState.doc.content.size) {
              newChangeTr.setSelection(TextSelection.create(newChangeTr.doc, finalNewPos))
            }
          })

          // Return the modified transaction if changes were made, otherwise null
          return newChangeTr.steps.length ? newChangeTr : null
        },
      }),
    ]
  },
})

/**
 * Cleans the content of any insertion marks before re-adding it as a deletion mark.
 * @param slice - The Slice of content to be cleaned.
 * @param schema - The schema for creating text nodes.
 * @returns - A new Slice with cleaned content.
 */
const cleanContent = (slice: Slice, schema: any): Slice => {
  //let removalOffset = 0
  const cleanedContent = mapFragment(slice.content, (node) => {
    // If the node has an insertion mark, it will be removed
    if (node.marks.some((mark) => mark.type.name === MARK_INSERTION)) {
      //removalOffset += node.nodeSize
      return null // Remove nodes with insertion marks
    }
    // Remove both insertion and deletion marks from the content
    const cleanedMarks = node.marks.filter((mark) => mark.type.name !== MARK_INSERTION && mark.type.name !== MARK_DELETION)
    if (node.isText) {
      return schema.text(node.text, cleanedMarks)
    }
    return node.type.createChecked(node.attrs, node.content, cleanedMarks)
  })

  // Convert the cleaned nodes into a Fragment
  const fragment = Fragment.fromArray(cleanedContent.filter((node) => node !== null))
  return new Slice(fragment, slice.openStart, slice.openEnd)
}

/**
 * Maps over a Fragment and applies a callback to each node.
 * @param fragment - The Fragment to be mapped.
 * @param callback - The callback to apply to each node.
 * @returns - A new array of mapped nodes.
 */
const mapFragment = (fragment: Fragment, callback: (node: any) => any): ProseMirrorNode[] => {
  const mappedContent: ProseMirrorNode[] = []
  fragment.forEach((node) => {
    const mappedNode = callback(node)
    if (mappedNode !== null) {
      mappedContent.push(mappedNode)
    }
  })
  return mappedContent
}

export default TrackChangeNew
