<script setup lang="ts">
// external
import { DocumentArrowUpIcon, CheckCircleIcon } from "@heroicons/vue/24/outline"
import { ref, computed, watch } from "vue"
import Vapor from "laravel-vapor"
import { useI18n } from "vue-i18n"

// internal
import { FormInputErrors, SpinLoader } from "~/components"
import { FormErrors, FormErrorBag } from "~/types"
import { formatBytes, isFileEncrypted } from "~/utils"

const { t } = useI18n()

interface Props {
  uploadCallback?: (file_uuid: string, file_name: string, mime_type: string) => void
  formErrors?: FormErrors<any> | null
  multiple?: boolean
  placeholder?: string
  accept?: string
  allowedExtensions?: string
  global?: boolean
  emptyListAfterUpload?: boolean
  parentPending?: boolean
  fileTypeError?: string
  uploadFiles?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    uploadCallback: null,
    formErrors: null,
    multiple: false,
    placeholder: null,
    accept: "application/pdf",
    allowedExtensions: "/\.pdf$/i",
    global: false,
    emptyListAfterUpload: false,
    parentPending: false,
    fileTypeError: null,
    uploadFiles: true,
  },
)

const emit = defineEmits([ "completed", "set-files" ])

const dropFile = ref()

const triggerClick = () => {
  dropFile.value.click()
}

const localEmptyFormErrors = ref<FormErrorBag<any>>(null)

const filesToDo = ref(0)

const localFormErrors = computed<FormErrorBag<any>>({
  get: () => {
    return  localEmptyFormErrors.value || { message: "", errors: props.formErrors } },
  set: (val) => {
    localEmptyFormErrors.value = val
  },
})

const uploadProgress = ref<number[]>([])

const fileValidation = async (files: any) => {
  const allowedExtensions = new RegExp(props.allowedExtensions.slice(2, -2).replace(/\\\\/g, "\\"), "i")

  for (const file of files) {
    if (!allowedExtensions.test(file.name)) {
      const error: FormErrors<any> =  { file: [ props.fileTypeError ? props.fileTypeError :  t("fileInput.fileTypeError") ] }
      setError(error)
      return false
    }
    else if (file.size > MAX_FILE_SIZE) {
      const error: FormErrors<any> =  { file: [ t("fileInput.fileSizeError") ] }
      setError(error)
      return false
    }
    else if (await isFileEncrypted(file)) {
      const error: FormErrors<any> =  { file: [ t("fileInput.encryptionError") ] }
      setError(error)
      return false
    }
  }

  return true
}

const MAX_FILES = 200
const MAX_FILE_SIZE = 52500000

const isUploading = computed<boolean>(() => {
  return uploadProgress.value.length > 0 && uploadProgress.value.some((progress) => progress > 0 && progress < 100)
})

const isDeactivatedDrop = ref(false)

const handleDrop = async (e: DragEvent) => {
  if (isDeactivatedDrop.value) return
  // Reset eventCounter
  eventCounter.value = 0
  if (!props.multiple && uploadedFiles.value?.length > 0) {
    uploadedFiles.value = []
  }
  const dt = e.dataTransfer
  const files = dt?.files

  emit("set-files", files)

  if (files?.length) {
    if (await fileValidation(files)) {
      filesToDo.value = files.length
      initializeProgress(files.length)
      for (let i = 0; i < files.length; i++) {
        if (props.uploadFiles) {
          uploadFile(files[i], i, props.uploadCallback)
        } else {
          uploadedFiles.value.push({
            name: files[i].name,
            uuid: null,
          })
        }
      }

    }
  }
}

const setError = (error: FormErrors<any>) => {
  setTimeout(() => {
    localFormErrors.value = null
  }, 5000)
  localFormErrors.value = { message: "", errors: error }
}

const initializeProgress = (numFiles: number) => {
  const progressBar = document.getElementById("progressBar")
  if (progressBar) progressBar.style.width = "0%"
  uploadProgress.value = []
  for (let i = numFiles; i > 0; i--) {
    uploadProgress.value.push(0)
  }
}

const updateProgress = (fileNumber: number, percent: number) => {
  uploadProgress.value[fileNumber] = percent
  const total =
        uploadProgress.value.reduce((tot, curr) => tot + curr, 0) /
        uploadProgress.value.length

  const progressBar = document.getElementById("progressBar")
  if (progressBar) progressBar.style.width = total + "%"
}

const handleFiles = () => {
  const fileInput = dropFile.value

  const files = [ ...((fileInput as any).files as File[]) ]

  emit("set-files", files)

  if (props.uploadFiles) {
    if (!props.multiple && files.length > 1) {
      const fileError: FormErrors<any> =  { file: [ t("fileInput.onlyOneFileAllowed") ] }
      localFormErrors.value = { message: "", errors: fileError }
      return
    }
    if (!props.multiple && uploadedFiles.value?.length > 0) {
      uploadedFiles.value = []
    }
    filesToDo.value = files.length
    initializeProgress(files.length)
    files.forEach((file, i) => {
      uploadFile(file, i, props.uploadCallback)
    })
  } else {
    for (let i = 0; i < files.length; i++) {
      uploadedFiles.value.push({
        name: files[i].name,
        uuid: null,
      })
    }
  }
}

const uploadFile = async (file: File, i: number, callback: (file_uuid: string, file_name: string, mime_type: string) => void) => {
  isDeactivatedDrop.value = true
  try {
    const res = await Vapor.store(file, {
      progress: (progress) => {
        updateProgress(i, Math.round(progress * 100) || 100)
      },
    })
    if (res) {
      callback(res.uuid, file.name, file.type)
      pushUploadedFile(file.name, res.uuid)
      filesToDo.value--
    }
  } catch (err) {
    localFormErrors.value = { message: "", errors: { file: err } }
    updateProgress(i, 0)
  } finally {
    isDeactivatedDrop.value = false
  }
}

interface UploadedFile {
  name: string
  uuid: string
}

const uploadedFiles = ref<UploadedFile[]>([])

const pushUploadedFile = (fileName: string, fileUuid: string) => {
  uploadedFiles.value.push({ name: fileName, uuid: fileUuid })
}

// Check if all files have been successfully uploaded
const uploadProgressComplete = computed<boolean>(() => {
  return uploadProgress.value.length > 0 && uploadProgress.value.every((progress) => progress === 100) && filesToDo.value === 0
})

// When all files have been successfully uploaded, emit "completed" and reset uploadedFiles
watch(
  uploadProgressComplete,
  (val) => {
    if (val) {
      emit("completed")
      if (props.emptyListAfterUpload) uploadedFiles.value = []
    }
  },
)

const eventCounter = ref(0)
const isHighlighted = computed<boolean>(() => {
  return !!(eventCounter.value > 0)
})

const handleDragEnter = () => {
  eventCounter.value++
}

const handleDragLeave = () => {
  eventCounter.value--
}

defineExpose({ triggerClick })

</script>

<template>
  <label
    :class="[
      global ? 'absolute inset-0 bg-white rounded-md' : 'block px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none h-full relative',
      isHighlighted ? 'bg-yellow-50' : '',
      (isHighlighted || parentPending || isUploading) && global ? 'rounded-md z-20' : '',
    ]"
    @drop.prevent.stop="handleDrop($event)"
    @dragenter.prevent.stop="handleDragEnter()"
    @dragleave.prevent.stop="handleDragLeave()"
    @dragover.prevent.stop
  >
    <div
      v-if="!global || (isHighlighted || parentPending || isUploading)"
      class="flex items-center justify-center h-full py-6 pointer-events-none group"
      :class="parentPending && uploadProgressComplete ? 'opacity-10' : ''"
    >
      <div class="flex flex-col items-center gap-1 space-y-2">
        <div
          v-for="file, fileIdx in uploadedFiles"
          :key="fileIdx"
          class="flex items-center justify-center space-x-2"
        >
          <CheckCircleIcon class="w-6 h-6 text-green-600 shrink-0" />
          <span
            class="font-medium text-green-600 break-all line-clamp-2"
            data-cy-sel="uploaded-file"
          >
            {{ file.name }}
          </span>
        </div>
        <span
          v-if="!uploadedFiles?.length"
          class="flex items-center justify-center gap-2"
        >
          <DocumentArrowUpIcon class="w-8 h-8 text-gray-500 shrink-0" />
          <span v-if="uploadProgress.length && uploadProgress[0] > 0 && !uploadProgressComplete">
            {{ $t("fileInput.uploading") }}…
          </span>
          <div
            v-else
            class="flex flex-col leading-tight"
          >
            <span
              class="font-medium"
              :class="isHighlighted ? 'text-gray-600' : 'text-gray-500 group-hover:text-gray-600'"
            >
              {{ placeholder || $t("fileInput.placeholder", props.multiple ? MAX_FILES : 1) }}
            </span>

            <span class="text-gray-300">
              {{ $t('imports.fileCollector.fileInputRestrictions', {
                size: formatBytes(MAX_FILE_SIZE, 0), count:
                  props.multiple ? MAX_FILES : 1
              }) }}
            </span>
          </div>
        </span>
        <div
          v-if="(localFormErrors?.errors?.file && localFormErrors?.errors?.file?.length)"
          class="block text-center"
        >
          <FormInputErrors
            :errors="localFormErrors?.errors?.file"
          />
        </div>
        <div
          v-show="uploadProgress.length && uploadProgress[0] > 0 && !uploadProgressComplete"
          class="mt-2 w-full bg-gray-200 rounded-full h-2.5 mb-6 pointer-events-none"
        >
          <div
            id="progressBar"
            class="bg-indigo-600 h-2.5 rounded-full"
            style="width: 0%"
          />
        </div>
      </div>
    </div>
    <div
      v-if="parentPending && uploadProgressComplete"
      class="absolute inset-0 flex items-center justify-center pointer-events-none"
    >
      <SpinLoader class="w-5 h-5 text-gray-500" />
    </div>
    <input
      ref="dropFile"
      data-cy-sel="drop-file"
      type="file"
      name="drop-file"
      class="absolute top-0 left-0 opacity-0"
      :accept="accept"
      @change="handleFiles()"
    >
    <!-- accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" -->
  </label>
</template>
