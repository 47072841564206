<script setup lang="ts">
// external
import { computed } from "vue"
import { sanitizeHex } from "~/utils"

// internal

interface Props {
  color: string
  hasError?: boolean
  inputClasses?: string
}

const props = withDefaults(defineProps<Props>(), {
  hasError: false,
  inputClasses: "",
})

const localColor = computed({
  get: () => props.color,
  set: (color) => {
    color = sanitizeHex(color)
    emit("update:color", "#" + color)
  },
})

const emit = defineEmits([ "update:color" ])

</script>
<template>
  <div class="relative">
    <input
      v-model.lazy="localColor"
      type="text"
      maxlength="7"
      pattern="^#([A-Fa-f0-9]{6})$"
      :placeholder="$t('accountSettings.brandIdentity.hexPlaceholder') + '…'"
      class="w-full pr-12 input-plain grow"
      :class="[hasError ? 'error' : '', inputClasses]"
    >
    <input
      v-model.lazy="localColor"
      type="color"
      class="absolute p-0 rounded right-2 top-1 w-7 h-7 overflow-hidden bg-transparent"
    >
  </div>
</template>
