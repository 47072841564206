<script setup lang="ts">
import { ref, onBeforeUnmount } from "vue"
import { Cropper, RectangleStencil } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import { TrashIcon, ArrowUpTrayIcon } from "@heroicons/vue/24/solid"

import { DialogModal } from "~/components"
import { Account } from "~/types"

interface Props {
  show: boolean
  account: Account
  removeAccountLogoStatus: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    removeAccountLogoStatus: false,
  },
)

const image = ref(!props.removeAccountLogoStatus ? props.account.ci_logo_url ?? null : null)
const imageType = ref(null)
const cropper = ref(null)
const file = ref(null)

const getMimeType = (file, fallback = null) => {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ""
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case "89504e47":
      return "image/png"
    case "47494638":
      return "image/gif"
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg"
    default:
      return fallback
  }
}

const crop = () => {
  if (image.value) {
    const { canvas } = cropper.value.getResult()
    canvas.toBlob((blob) => {
    // Do something with blob: upload to a server, download and etc.
      emit("save", blob)
    }, imageType.value)
  }
}

const loadImage = (event) => {
  // Reference to the DOM input element
  const { files } = event.target
  // Ensure that you have a file before attempting to read it
  if (files && files[0]) {
    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (image.value) {
      URL.revokeObjectURL(image.value)
    }
    // 2. Create the blob link to the file to optimize performance:
    const blob = URL.createObjectURL(files[0])

    // 3. The steps below are designated to determine a file mime type to use it during the
    // getting of a cropped image from the canvas. You can replace it them by the following string,
    // but the type will be derived from the extension and it can lead to an incorrect result:
    //
    // this.image = {
    //    src: blob;
    //    type: files[0].type
    // }

    // Create a new FileReader to read this image binary data
    const reader = new FileReader()
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (e) => {
      // Note: arrow function used here, so that "this.image" refers to the image of Vue component
      image.value = blob
      imageType.value = getMimeType(e.target.result, files[0].type)
    }
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsArrayBuffer(files[0])
  }
}

onBeforeUnmount(() => {
  // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
  if (image.value) {
    URL.revokeObjectURL(image.value)
  }
})

const emit = defineEmits([ "update:show", "save", "remove" ])

const handleRemove = () => {
  image.value = null
  imageType.value = null
  emit("remove")
}

</script>
<template>
  <DialogModal
    :show="props.show"
    max-width="md"
    @close="$emit('update:show', false)"
  >
    <template #title>
      {{ $t('accountSettings.brandIdentity.updateAccountLogo') }}
    </template>

    <template #content>
      <div class="-mx-6 -mb-4 max-h-[80vh] max-w-[100vw]">
        <Cropper
          v-if="image"
          ref="cropper"
          class="cropper"
          :src="image"
          :stencil-component="RectangleStencil"
        />
        <div
          v-else
          class="flex items-center justify-center shrink-0 p-3 relative"
        >
          <label
            type="button"
            class="bg-indigo-200 p-6 border border-white outline-2 outline-dashed outline-indigo-200 rounded-lg"
          >
            <span class="text-2xl font-medium text-indigo-700">{{ $t('accountSettings.brandIdentity.noLogoSet') }}</span>
            <input
              type="file"
              accept="image/*"
              class="absolute inset-0 opacity-0"
              @change="loadImage($event)"
            >
          </label>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-center justify-center space-x-5">
        <button
          class="flex items-center gap-2 text-gray-400 btn-plain hover:text-red-500 hover:bg-red-100"
          @click="handleRemove"
        >
          <TrashIcon
            class="shrink-0 w-4 h-4"
            aria-hidden="true"
          />
          <span class="hidden xs:block">
            {{ $t('common.remove') }}
          </span>
        </button>
        <button
          class="flex items-center gap-2 text-gray-400 btn-plain hover:text-white hover:bg-indigo-500"
          @click="file.click()"
        >
          <ArrowUpTrayIcon
            class="w-4 h-4  shrink-0"
            aria-hidden="true"
          />
          <span class="hidden xs:block whitespace-nowrap">
            {{ $t('accountSettings.brandIdentity.uploadImage') }}
          </span>
        </button>
        <button
          class="btn-primary"
          @click="crop"
        >
          {{ $t('common.save') }}
        </button>
        <input
          ref="file"
          type="file"
          accept="image/*"
          class="absolute top-0 opacity-0 -left-96"
          @change="loadImage($event)"
        >
      </div>
    </template>
  </DialogModal>
</template>
